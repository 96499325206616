import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import { clientLookups, tblFieldsLookup, lookupVM, levelOptions, lookupDFVM, lookupSublistVM } from './viewModel'
import { ClientContext } from '../../../layout/clientContext'
import { ReactSelect, PopUpModal, ContentOverlay, TableView } from '../../../../components'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { collectionData, docData } from 'rxfire/firestore';

import AddLookup from './addLookup'
import AddLostReason from './addLostreason'
import AddDynamicOptions from './addDynamicOptions'

const Lookups = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 630) : (window.innerWidth - 725), windowHeight: (window.innerHeight - 330) })
    const [activeLookup, setActiveLookup] = useState('campaigns')
    const [selectedSettingsID, setSelectedSettingsID] = useState('')
    const [selectedOEM, setSelectedOEM] = useState('')
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedGroup, setSelectedGroup] = useState('')
    const [oemOptions, setOEMOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [allLookups, setAlllookups] = useState({});
    const [loader, setLoader] = useState(false)
    const [showLookupModel, setShowLookupModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [selectedLookup, setSelectedLookup] = useState(null)
    const [queryParam, setQueryParam] = useState({})
    const [regionOptions, setRegionOptions] = useState([]);
    const [showLostReasonModel, setShowLostReasonModel] = useState(false)
    const [showDynamicOptionModel, setShowDynamicOptionModel] = useState(false)
    const { regionLookups, clients, oemSettings } = useContext(ClientContext);

    useEffect(() => {
        if (_.isEmpty(selectedOEM))
            return
        setLoader(true)
        var alllookupsettings = combineLatest(docData(window.firebase.firestore().doc(`clientSettings/${selectedOEM.id}`)), collectionData(window.firebase.firestore().collection(`clientSettings/${selectedOEM.id}/regions`)), collectionData(window.firebase.firestore().collection(`clientSettings/${selectedOEM.id}/groups`)), collectionData(window.firebase.firestore().collection(`clientSettings/${selectedOEM.id}/clients`)))
            .pipe(
                map((lookups) => {
                    // Destructure the values to combine a single array.
                    var [oemlookups, regionLookups, groupLookups, clientLookups] = lookups;
                    return [
                        //spread the arrays out to combine as one array
                        { ...oemlookups, id: oemlookups.documentID },
                        _.map(regionLookups, function (obj) { return { ...obj, id: obj.documentID } }),
                        _.map(groupLookups, function (obj) { return { ...obj, id: obj.documentID } }),
                        _.map(clientLookups, function (obj) { return { ...obj, id: obj.documentID } })
                    ];

                }))
            .subscribe(allDocs => {
                var lookups = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => lookups.push(e)) : lookups.push(rec) })
                let settingsDoc = {};
                lookups.forEach(rec => {
                    clientLookups.forEach((key) => {
                        if (rec[key.value]) {
                            rec[key.value].forEach((_lookups) => {
                                if (settingsDoc[key.value]) {
                                    settingsDoc[key.value] = [
                                        ...settingsDoc[key.value],
                                        {
                                            ..._lookups,
                                            'strName': key.value === 'financeStatus' && _lookups.color ? (<div className={`badge badge-pill badge-status-empty`} style={{ background: _lookups.color }}>{_lookups.name}</div>) : _lookups.name,
                                            'levelID': rec.id,
                                            'levelName': rec.name,
                                            'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                            'documentID': _lookups.value,
                                            'disableEdit': (key.value === 'salesType' && (_lookups.value === 'New' || _lookups.value === 'Demo' || _lookups.value === 'Preowned')) ? true : false
                                        }
                                    ]
                                }
                                else {
                                    settingsDoc[key.value] = [{
                                        ..._lookups,
                                        'strName': key.value === 'financeStatus' && _lookups.color ? (<div className={`badge badge-pill badge-status-empty`} style={{ background: _lookups.color }}>{_lookups.name}</div>) : _lookups.name,
                                        'levelID': rec.id,
                                        'levelName': rec.name,
                                        'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                        'documentID': _lookups.value,
                                        'disableEdit': (key.value === 'salesType' && (_lookups.value === 'New' || _lookups.value === 'Demo' || _lookups.value === 'Preowned')) ? true : false
                                    }]
                                }
                            })
                        }
                    })
                })
                setAlllookups(settingsDoc)
                setLoader(false)
            });
        return () => {
            alllookupsettings && alllookupsettings.unsubscribe()
        }
    }, [selectedOEM])

    useEffect(() => {
        if (_.isEmpty(oemSettings))
            return

        let _oemSettings = [];
        oemSettings.forEach((rec) => {
            _oemSettings.push({ label: rec.name, value: rec.id, data: rec })
        })
        setOEMOptions(_.orderBy(_oemSettings, ['label'], ['asc']));
        if (_oemSettings.length > 0 && _.isEmpty(selectedOEM))
            setSelectedOEM(_oemSettings[0].data)
    }, [oemSettings])

    useEffect(() => {
        if (props.activeKey === 'lookups') {
            function handleResize() {
                setWindowSize({
                    windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 630) : (window.innerWidth - 725),
                    windowHeight: (window.innerHeight - 330)
                })
                //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 210) + 'px');
            }
            window.addEventListener('resize', handleResize);
            //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 210) + 'px');
            return () => {
                window.removeEventListener('resize', handleResize);
                window.OEMClientSnapshot && window.OEMClientSnapshot();
                window.OEMGroupSnapshot && window.OEMGroupSnapshot();
                window.OEMRegionSnapshot && window.OEMRegionSnapshot();
            }
        }
    }, [props.activeKey])

    useEffect(() => {
        if (!_.isEmpty(selectedOEM)) {
            window.OEMClientSnapshot = window.firebase.firestore().collection(`clientSettings/${selectedOEM.id}/clients`)
                .onSnapshot(querySnapshot => {
                    let _clients = [];
                    querySnapshot.forEach((rec) => {
                        _clients.push({ label: rec.data().name, value: rec.id, region: rec.data().region, data: { ...rec.data(), 'id': rec.id } })
                    })
                    setClientOptions(_clients)
                })
            window.OEMGroupSnapshot = window.firebase.firestore().collection(`clientSettings/${selectedOEM.id}/groups`)
                .onSnapshot(querySnapshot => {
                    let groups = [];
                    querySnapshot.forEach((rec) => {
                        groups.push({
                            label: rec.data().name,
                            value: rec.id,
                            data: { ...rec.data(), 'id': rec.id },
                            clientIDs: clients.filter(c => c.settingsID === selectedOEM.id && c.group === rec.documentID).map(v => _.pick(v, ['documentID']).documentID),
                            regionID: clients.find(c => c.settingsID === selectedOEM.id && c.group === rec.documentID) && !_.isEmpty(clients.find(c => c.settingsID === selectedOEM.id && c.group === rec.documentID).region) ? clients.find(c => c.settingsID === selectedOEM.id && c.group === rec.documentID).region : ''
                        })
                    })
                    setGroupOptions(groups)
                })
            window.OEMRegionSnapshot = window.firebase.firestore().collection(`clientSettings/${selectedOEM.id}/regions`)
                .onSnapshot(querySnapshot => {
                    let regions = [];
                    querySnapshot.forEach((rec) => {
                        regions.push({
                            label: rec.data().name,
                            value: rec.id,
                            data: { ...rec.data(), 'id': rec.id },
                            groupIDs: _.uniq(clients.filter(c => c.settingsID === selectedOEM.id && c.region === rec.code).map(v => _.pick(v, ['group']).group)),
                            clientIDs: clients.filter(c => c.settingsID === selectedOEM.id && c.region === rec.code).map(v => _.pick(v, ['documentID']).documentID)
                        })
                    })
                    setRegionOptions(regions)
                })
        }
    }, [selectedOEM])



    const handleOEMChange = (e, data) => {
        setSelectedOEM({ ...e.data, id: e.value })
        setSelectedGroup(null);
        setSelectedClient(null)
        setQueryParam({})
        // if (!_.isEmpty(e.data.settingsID)) {
        //     setSelectedSettingsID(e.data.settingsID)
        // let settingsDoc = {};
        // clientLookups.forEach(key => {
        //     if (e.data[key.value]) {
        //         e.data[key.value].forEach((lookups) => {
        //             if (settingsDoc[key.value]) {
        //                 settingsDoc[key.value] = [
        //                     ...settingsDoc[key.value],
        //                     {
        //                         ...lookups,
        //                         'levelID': e.value,
        //                         'levelName': e.data.name
        //                     }
        //                 ]
        //             }
        //             else {
        //                 settingsDoc[key.value] = [{
        //                     ...lookups,
        //                     'levelID': e.value,
        //                     'levelName': e.data.name
        //                 }]
        //             }
        //         })
        //     }
        //     else {
        //         settingsDoc[key] = [];
        //     }
        // })
        // let settingslevel = []
        // settingslevel.push({ name: 'groups' })
        // settingslevel.push({ name: 'clients' })
        // const promises = []
        // settingslevel.forEach(_rec => {
        //     promises.push(window.firebase.firestore().collection(`clientSettings/${e.value}/${_rec.name}`).get())
        // })
        // setLoader(true)
        // const snapshots = await Promise.all(promises)
        // snapshots.forEach(querySnapshot => {
        //     querySnapshot.forEach(snap => {
        //         const dataDoc = snap.data()
        //         clientLookups.forEach((key) => {
        //             if (dataDoc[key.value]) {
        //                 dataDoc[key.value].forEach((lookups) => {
        //                     settingsDoc[key.value] = [
        //                         ...settingsDoc[key.value],
        //                         {
        //                             ...lookups,
        //                             'levelID': snap.id,
        //                             'levelName': snap.data().name
        //                         }
        //                     ]
        //                 })
        //             }
        //         })
        //     })
        // })
        // setAlllookups(settingsDoc)
        // setLoader(false)
        // }
        // else {
        //     setSelectedSettingsID('')
        // }
    }


    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                {
                    data.disableEdit ? (<div style={{ cursor: 'pointer' }} className="mini-button mr-2">
                        <i className="ico icon-lock"></i>
                    </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                        hadleEditLookup(data)
                    }}>
                        <i className="ico icon-edit"></i>
                    </div>)
                }
                {
                    data.disableEdit ? (<div style={{ cursor: 'pointer' }} className="mini-button">
                        <i className="ico icon-lock"></i>
                    </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                        hadleDeleteLookup(data)
                    }}>
                        <i className="ico icon-delete"></i>
                    </div>)
                }
            </div>
        )
    }

    const hadleDeleteLookup = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                let activeLookupName = (_.find(clientLookups, { 'value': activeLookup }) ? _.find(clientLookups, { 'value': activeLookup }).name : 'Lookups')
                let querySet = `clientSettings/${selectedOEM.id}`;
                if (data.level !== 'oem') {
                    querySet = `clientSettings/${selectedOEM.id}/${data.level === 'group' ? 'groups' : 'clients'}/${data.levelID}`;
                }
                window.firebase.firestore().doc(querySet)
                    .set({
                        [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.value !== data.value && (_.isArray(data.levelID) ? data.levelID.some(a => item.levelID === a) : item.levelID === data.levelID)), function (obj) {
                            return activeLookup === 'enquiryOptionsDF' ? { ..._.pick(obj, _.keys(lookupDFVM)) } :
                                activeLookup === 'lostReasons' ? { ..._.pick(obj, _.keys(lookupSublistVM)) } :
                                    { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                    }, { merge: true })
                    .then(() => {
                        // _.remove(allLookups[activeLookup], { ...data });
                        // setAlllookups({
                        //     ...allLookups,
                        //     [activeLookup]: [...allLookups[activeLookup]]
                        // })
                        toast.notify(activeLookupName + ' deleted successfully', {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })

            }
        })
    }

    const hadleEditLookup = (data) => {
        setPopHeader('Edit ' + (_.find(clientLookups, { 'value': activeLookup }) ? _.find(clientLookups, { 'value': activeLookup }).name : 'Lookup'))
        setSelectedLookup(data)
        if (activeLookup === 'lostReasons') {
            setShowLostReasonModel(true)
        }
        else if (activeLookup === 'enquiryOptionsDF') {
            setShowDynamicOptionModel(true)
        }
        else {
            setShowLookupModel(true)
        }
    }

    const handleClientdetailsClick = (e, id, data) => {
        if (data.disableEdit)
            return
        hadleEditLookup(data)
    }

    const handleAddLookup = () => {
        setPopHeader('Add ' + (_.find(clientLookups, { 'value': activeLookup }) ? _.find(clientLookups, { 'value': activeLookup }).name : 'Lookup'))
        setSelectedLookup(null)
        if (activeLookup === 'lostReasons') {
            setShowLostReasonModel(true)
        }
        else if (activeLookup === 'enquiryOptionsDF') {
            setShowDynamicOptionModel(true)
        }
        else {
            setShowLookupModel(true)
        }
    }

    const handleModelClose = (data, isNew) => {
        setSelectedLookup(null)
        setShowLookupModel(false)
        setShowLostReasonModel(false)
        setShowDynamicOptionModel(false)
        // if (!_.isEmpty(data)) {
        //     if (isNew) {
        //         setAlllookups({
        //             ...allLookups,
        //             [activeLookup]: allLookups[activeLookup] ? [
        //                 ...(data.level === 'oem' ? allLookups[activeLookup].filter(item => item.value !== data.value) :
        //                     data.level === 'group' ? allLookups[activeLookup].filter(item => item.level === 'oem' || (item.level === 'client' && item.value !== data.value))
        //                         : allLookups[activeLookup]),
        //                 data
        //             ] : [data]
        //         })
        //     }
        //     else {
        //         setAlllookups({
        //             ...allLookups,
        //             [activeLookup]: [..._.map(allLookups[activeLookup], function (obj) {
        //                 return obj.value === data.value ? { ...data } : { ...obj };
        //             })]
        //         })
        //     }
        // }
    }

    const handleSwitchChange = (id, checked, data) => {
        if (_.isEmpty(data))
            return

        // setAlllookups([..._.map(allLookups[activeLookup].filter(item => item.level !== 'oem' ? (item.level === data.level && item.levelID === data.levelID) : item.level === data.level), function (obj) {
        //     return obj.value === data.value ? { ..._.pick({...data , 'active' : checked}, _.keys(lookupVM)) } : { ..._.pick(obj, _.keys(lookupVM)) };
        // })])
        let activeLookupName = (_.find(clientLookups, { 'value': activeLookup }) ? _.find(clientLookups, { 'value': activeLookup }).name : 'Lookups')
        let querySet = `clientSettings/${selectedOEM.id}`;
        if (data.level !== 'oem') {
            querySet = `clientSettings/${selectedOEM.id}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${data.levelID}`;
        }
        window.firebase.firestore().doc(querySet)
            .set({
                [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.level !== 'oem' ? (item.level === data.level && item.levelID === data.levelID) : item.level === data.level), function (obj) {
                    return obj.value === data.value ? { ..._.pick({ ...data, 'active': checked }, _.keys(lookupVM)) } : { ..._.pick(obj, _.keys(lookupVM)) };
                })]
            }, { merge: true })
            .then(snapshot => {
                window.firebase.firestore().collection(`clientSettings/${selectedOEM.id}/clients`)
                    .get()
                    .then(clientsCollection => {
                        if (clientsCollection.docs.length > 0) {
                            const batch = window.firebase.firestore().batch();
                            clientsCollection.docs.forEach(client => {
                                batch.set(window.firebase.firestore().doc(`clients/${client.id}`), {
                                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                    settingsUpdatePriority: 'normal',
                                    settingsUpdateBy: props?.dealersettings?.name || null,
                                    settingsUpdateText: null,
                                }, { merge: true });
                            })
                            batch.commit().catch((error) => {
                                console.error("Error updating document: ", error);
                            });
                        }
                    })

                setLoader(false)
                toast.notify(`${activeLookupName} updated successfully`, {
                    duration: 2000
                })
            })
            .catch(error => {
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    const lookupList = useMemo(() => allLookups[activeLookup] ? allLookups[activeLookup]
        .filter(
            ({ name, value, isActive, levelID, levelName }) => (!_.isEmpty(queryParam) ?
                (
                    (!_.isEmpty(queryParam.levelID) ? queryParam.levelID.some(item => item === levelID) : true) &&
                    (!_.isEmpty(queryParam.searchText) ? (name.toLowerCase().includes(queryParam.searchText.toLowerCase()) ||
                        value.toLowerCase().includes(queryParam.searchText.toLowerCase()) ||
                        (!_.isEmpty(levelName) ? levelName.toLowerCase().includes(queryParam.searchText.toLowerCase()) : false)) : true)
                )
                : true)) : [], [activeLookup, allLookups, queryParam])

    const clientsList = useMemo(() => !_.isEmpty(selectedGroup) ?
        [..._.filter(clientOptions, (v) => _.indexOf(selectedGroup.client_ids, v.value) >= 0)]
        : [...clientOptions], [clientOptions, selectedGroup])

    return (
        <>
            <h2>Dynamic Field Settings</h2>
            <div className="divider-line"></div>
            <div className="lookups-wrap">
                <div className="settings-head form-style">
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <ReactSelect
                                options={oemOptions}
                                name={"oem"}
                                placeholder={'select oem'}
                                onChange={handleOEMChange}
                                value={!_.isEmpty(selectedOEM) ? selectedOEM.id : ''}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>

                    </div>
                </div>
                <div className="settings-column-wraper">
                    <div className="settings-columns">
                        <div className="settings-column-left">
                            <div className="settings-column-title">
                                <h3>Dynamic Fields</h3>
                            </div>
                            <div className="settings-column-nav">
                                {
                                    !_.isEmpty(selectedOEM) ? (
                                        <ul>
                                            {
                                                clientLookups.map((rec, index) => {
                                                    return <li key={index} className={`${rec.value === activeLookup ? 'active' : ''}`}>
                                                        <a href="#" className="column-list-left" onClick={(e) => {
                                                            e.preventDefault();
                                                            setActiveLookup(rec.value)
                                                        }}>
                                                            {rec.name}
                                                        </a>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    ) : (<></>)
                                }
                            </div>
                        </div>
                        <div className="settings-column-right-large">
                            <div className="settings-column-title">
                                {
                                    !_.isEmpty(selectedOEM) ?
                                        (
                                            <>
                                                <div className="column-add-button"><a href="#" className="mini-button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddLookup(activeLookup);
                                                    }}> <i className="ico icon-add"></i></a></div>
                                                <h3>{_.find(clientLookups, { 'value': activeLookup }) ? _.find(clientLookups, { 'value': activeLookup }).name : ''}</h3>
                                            </>)
                                        : (<></>)
                                }

                            </div>
                            <div className="lookups-details">
                                {
                                    !_.isEmpty(selectedOEM) ?
                                        (<div className="settings-head form-style">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="filter-search search-icon w-100">
                                                        <input className="w-100" placeholder="Search" aria-label="Search" value={!_.isEmpty(queryParam.searchText) ? queryParam.searchText : ''} onChange={(e) => { setQueryParam({ ...queryParam, 'searchText': e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="row">
                                                        <div className="col-md-6 pl-0">
                                                            <ReactSelect
                                                                options={groupOptions}
                                                                name={"groupID"}
                                                                placeholder={'select group'}
                                                                onChange={(e) => {
                                                                    setSelectedClient(null);
                                                                    if (e) {
                                                                        setSelectedGroup(e.data);
                                                                        setQueryParam({ ...queryParam, 'levelID': [selectedOEM.id, e.regionID, e.value] })
                                                                    }
                                                                    else {
                                                                        setSelectedGroup(null);
                                                                        setQueryParam({ ...queryParam, 'levelID': '' })
                                                                    }
                                                                }}
                                                                value={!_.isEmpty(selectedGroup) ? selectedGroup.id : ''}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="col-md-6 pl-0">
                                                            <ReactSelect
                                                                options={clientsList}
                                                                name={"clientID"}
                                                                placeholder={'select client'}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setSelectedClient(e.data);
                                                                        setQueryParam({ ...queryParam, 'levelID': [selectedOEM.id, e.data.region, e.data.group, e.value] })
                                                                    }
                                                                    else {
                                                                        setSelectedClient(null);
                                                                        if (_.isEmpty(selectedGroup))
                                                                            setQueryParam({ ...queryParam, 'levelID': '' })
                                                                    }
                                                                }}
                                                                value={!_.isEmpty(selectedClient) ? selectedClient.id : ''}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>) : (<></>)
                                }
                                {
                                    !_.isEmpty(selectedOEM) ? (loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                        !_.isEmpty(allLookups[activeLookup]) && allLookups[activeLookup].length > 0 ? (
                                            <div className="common-table">
                                                <TableView
                                                    datalist={lookupList}
                                                    height={windowSize.windowHeight}
                                                    width={windowSize.windowWidth}
                                                    columns={tblFieldsLookup}
                                                    dynamicFields={tblFieldsLookup.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                    handleActionClick={handleActionClick}
                                                    handleRowSingleClick={handleClientdetailsClick}
                                                    isSettings={false}
                                                    isReorderable={false}
                                                    handleSwitchChange={handleSwitchChange}
                                                />
                                            </div>
                                        ) : (
                                                <div className="common-table">
                                                    <div className="text-center p-5">
                                                        <p>No {_.find(clientLookups, { 'value': activeLookup }) ? _.find(clientLookups, { 'value': activeLookup }).name.toLowerCase() : ''} found.</p>
                                                        <button className="btn btn-sm btn-default" onClick={() => { handleAddLookup() }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                                            Add {_.find(clientLookups, { 'value': activeLookup }) ? _.find(clientLookups, { 'value': activeLookup }).name : ''}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                    )
                                        : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopUpModal show={showLookupModel}>
                <AddLookup
                    show={showLookupModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    lookup={selectedLookup}
                    activeLookup={activeLookup}
                    allLookups={allLookups}
                    selectedOEM={selectedOEM}
                    groupOptions={groupOptions}
                    clientOptions={clientOptions}
                    regionOptions={regionOptions}
                />
            </PopUpModal>
            <PopUpModal show={showLostReasonModel}>
                <AddLostReason
                    {...props}
                    show={showLostReasonModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    lookup={selectedLookup}
                    activeLookup={activeLookup}
                    allLookups={allLookups}
                    selectedOEM={selectedOEM}
                    groupOptions={groupOptions}
                    clientOptions={clientOptions}
                    regionOptions={regionOptions}
                />
            </PopUpModal>
            <PopUpModal show={showDynamicOptionModel}>
                <AddDynamicOptions
                    {...props}
                    show={showDynamicOptionModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    lookup={selectedLookup}
                    activeLookup={activeLookup}
                    allLookups={allLookups}
                    selectedOEM={selectedOEM}
                    groupOptions={groupOptions}
                    clientOptions={clientOptions}
                    regionOptions={regionOptions}
                />
            </PopUpModal>
        </>
    )
}

export default Lookups