/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** PROVIDERS */
/** VIEW-MODELS */
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { enquiryStatus } from '../../../services/enum';

import { default as _images } from '../../../images';
import { firestoreDB } from '../../../services/helper';
const objOwnedVehicle = {
    make: '',
    model: '',
    type: '',
    group: '',
    year: '',
    regNo: '',
    vinNo: '',
    exteriorColor: '',
    transmission: '',
    warrantyStartDate: null,
    warrantyExp: null,
    registrationExpiry: null,
    currentlyOwned: true
};

const OwnedVehicles = (props) => {
    const [searchText, setSearchText] = useState('')

    const [ownedVehicles, setOwnedVehicles] = useState([])
    const [ownedEnquiryVehicles, setOwnedEnquiryVehicles] = useState([])

    useEffect(() => {
        if (_.isEmpty(props.contactID))
            return

        const ovSnapshot = firestoreDB(props.dealersettings).firestore().collection(`contactDetails/${props.contactID}/ownedVehicles`)
            .onSnapshot(querySnapshot => {
                let _ownedVehicles = [];
                querySnapshot.forEach((_owned) => {
                    const owned = Object.assign({}, objOwnedVehicle);
                    const objOwnedData = Object.assign({}, _owned.data());
                    for (let [key, value] of Object.entries(objOwnedData)) {
                        if (owned.hasOwnProperty(key))
                            owned[key] = value ? value : '';
                    }
                    //owned.documentID = _owned.id;
                    owned.extColor = objOwnedData.exteriorColor ? objOwnedData.exteriorColor : '';
                    owned.vehicleName = owned.make + ' ' + owned.model + ' ' + owned.year + ' ' + owned.regNo + ' ' + owned.vinNo;
                    _ownedVehicles.push(owned);
                })
                setOwnedVehicles(_ownedVehicles)
            })

        // const oevSnapshot = window.firebase.firestore().collection(`enquiries`)
        //     .where('contact.documentID', '==', props.contactID)
        //     .where('status', '==', enquiryStatus.DELIEVERD)
        //     .where('isDeleted', '==', false)
        //     .onSnapshot(querySnapshot => {
        //         const ownedEnquiries = [];
        //         querySnapshot.forEach((_enquiry) => {
        //             const enquiry = Object.assign({}, _enquiry.data());
        //             const ownedEnquiry = Object.assign({});

        //             if (enquiry.isVehicle && !_.isEmpty(enquiry.requirement)) {
        //                 //ownedEnquiry.enquiryID = enquiry.documentID;
        //                 ownedEnquiry.displayID = enquiry.displayID ? enquiry.displayID : '';
        //                 ownedEnquiry.regNo = enquiry.regNo ? enquiry.regNo : '';
        //                 ownedEnquiry.vinNo = enquiry.chassisNo ? enquiry.chassisNo : '';
        //                 ownedEnquiry.currentlyOwned = enquiry.requirement.currentlyNotOwned ? false : true;

        //                 if (!_.isEmpty(enquiry.requirement.stock)) {
        //                     ownedEnquiry.make = enquiry.requirement.stock.make ? enquiry.requirement.stock.make : '';
        //                     ownedEnquiry.model = enquiry.requirement.stock.model ? enquiry.requirement.stock.model : '';
        //                     ownedEnquiry.type = enquiry.requirement.stock.type ? enquiry.requirement.stock.type : '';
        //                     ownedEnquiry.year = enquiry.requirement.stock.year ? enquiry.requirement.stock.year : '';
        //                     ownedEnquiry.regNo = enquiry.requirement.stock.regNo ? enquiry.requirement.stock.regNo : ownedEnquiry.regNo;
        //                     ownedEnquiry.vinNo = enquiry.requirement.stock.vinNo ? enquiry.requirement.stock.vinNo : ownedEnquiry.vinNo;
        //                     ownedEnquiry.extColor = enquiry.requirement.stock.extColor ? enquiry.requirement.stock.extColor : '';
        //                     ownedEnquiry.intColor = enquiry.requirement.stock.intColor ? enquiry.requirement.stock.intColor : '';
        //                     ownedEnquiry.vehicleName = ownedEnquiry.make + ' ' + ownedEnquiry.model + ' ' + ownedEnquiry.year + ' ' + ownedEnquiry.regNo + ' ' + ownedEnquiry.vinNo;
        //                 }
        //                 else {
        //                     ownedEnquiry.make = enquiry.requirement.make ? enquiry.requirement.make : '';
        //                     ownedEnquiry.model = enquiry.requirement.model ? enquiry.requirement.model : '';
        //                     ownedEnquiry.type = enquiry.requirement.type ? enquiry.requirement.type : '';
        //                     ownedEnquiry.year = enquiry.requirement.year ? enquiry.requirement.year : '';
        //                     ownedEnquiry.regNo = enquiry.requirement.regNo ? enquiry.requirement.regNo : ownedEnquiry.regNo;
        //                     ownedEnquiry.vinNo = enquiry.requirement.vinNo ? enquiry.requirement.vinNo : ownedEnquiry.vinNo;
        //                     ownedEnquiry.extColor = enquiry.requirement.extColor ? enquiry.requirement.extColor : '';
        //                     ownedEnquiry.intColor = enquiry.requirement.intColor ? enquiry.requirement.intColor : '';
        //                     ownedEnquiry.vehicleName = ownedEnquiry.make + ' ' + ownedEnquiry.model + ' ' + ownedEnquiry.year + ' ' + ownedEnquiry.regNo + ' ' + ownedEnquiry.vinNo;
        //                 }

        //                 ownedEnquiries.push(ownedEnquiry)
        //             }
        //         })
        //         setOwnedEnquiryVehicles(ownedEnquiries)
        //     })
        return () => {
            ovSnapshot && ovSnapshot();
            //oevSnapshot && oevSnapshot();
        }
    }, [props.contactID])

    const divStyle = {
        height: '400px',
        overflow: 'auto',
        position: "relative",
        width: '100%'
    };

    //let _OwnedVehicles = ownedEnquiryVehicles.concat(ownedVehicles)
    let serviceAddOns = !_.isEmpty(ownedVehicles) && ownedVehicles.filter(item => (searchText ? item.vehicleName.toLowerCase().includes(searchText.toLowerCase()) : true));

    return (
        <>

            <Modal
                id="OwnedVehicles"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-packages">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Owned Vehicles'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="package-item-head pr-0">
                                    <input
                                        type="text"
                                        aria-label="Search"
                                        placeholder="Search..."
                                        value={searchText ? searchText : ''}
                                        autoComplete="off"
                                        onChange={e => {
                                            setSearchText(e.target.value);
                                        }}
                                    />
                                </div>

                                <div style={divStyle}>
                                    <div className="package-item-loop">
                                        {
                                            !_.isEmpty(serviceAddOns)
                                                ?
                                                <>
                                                    {
                                                        serviceAddOns.map((info, index) => {
                                                            return <div key={index} className="vehicle-item flex-vehicle" id={'veh_id_' + info.documentID}
                                                                onClick={(e) => { e.preventDefault(); props.handleClose(info); }}>
                                                                <div className="flex-vehicle">

                                                                    <div className="vehicle-item-image" >
                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                            <img src={CommonHelper.showBrandLogo(props.dealersettings, info.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                                        </a>
                                                                    </div>

                                                                    <div className="vehicle-data">
                                                                        <div className="vehicle-item-title mini-badge">{info.make} {info.model}</div>
                                                                        {/* {info.displayID ? <div className="vehicle-item-info">{info.displayID}</div> : <></>} */}
                                                                        <div className="vehicle-item-info">{info.exteriorColor ? `${info.exteriorColor},` : ''} {info.year ? info.year : ''}</div>
                                                                        <div className="vehicle-item-info"><Translate text={'regNo'} />: {info.regNo ? info.regNo : '--'} </div>
                                                                        <div className="vehicle-item-info"><Translate text={'vinNo'} />: {info.vinNo ? info.vinNo : '--'} </div>
                                                                        <div className="tradin-item-button">
                                                                            <div className={`badge badge-pill badge-${info.currentlyOwned ? 'available' : 'sold'}-fill`} >{info.currentlyOwned ? 'Currently Owned' : 'Previously Owned'}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        })
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-cafe-img">
                                                                <i className="ico icon-service-package"></i> </div>
                                                            <div className="no-data-txt mt-2"> <Translate text={'No vehicle owned'}/></div>
                                                        </div>

                                                    </div>
                                                </>
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OwnedVehicles