import React, { useState, useEffect } from 'react'
import "../../../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';

/** COMPONENTS */
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';

import { pipelineCountVM } from '../../../dashboard/viewModel'

const SalesProgressBMW = (props) => {
    const [loader, setLoader] = useState(true)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [client, setClient] = useState({});
    const [clientSettings, setClientSettings] = useState();
    const [refreshData, setRefreshData] = useState(true)
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [tempData, setTempData] = useState({enquiries: []})
    const [countsCalculated, setCountsCalculated] = useState(false)

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? _date : moment().format('YYYY-MM-DD');
    
    let monthStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('month')._d)
    let monthEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('month')._d)
    let yearStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('year')._d)
    let yearEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('year')._d)  

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('year')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('year')._d)
    })

    const [bmwNewCounts, setBmwNewCounts] = useState(pipelineCountVM)
    const [bmwUsedCounts, setBmwUsedCounts] = useState(pipelineCountVM)
    const [miniNewCounts, setMiniNewCounts] = useState(pipelineCountVM)
    const [miniUsedCounts, setMiniUsedCounts] = useState(pipelineCountVM)

    useEffect(() => {
        if (!refreshData)
            return

        setLoader(true)

        const getData = async () => {
            const allEnquiries = []
            const allTestDrives = []
            const allWalkins = []
            const allActivities = []

            const clientSnap = await window.firebase.firestore().collection('clients').doc(props.clientid).get()
            if (!clientSnap.exists) {
                return
            }
            const clientData = clientSnap.data()
            

            const clientSettingsSnap = await window.firebase.firestore().doc(`clients/${clientData.documentID}/currentSettings/${clientData.documentID}`).get()
            if (!clientSettingsSnap.exists) {
                return
            }
            const clientSettingsData = clientSettingsSnap.data()

            //console.log('clientData =', clientData)
            //console.log('clientSettingsData =', clientSettingsData)

            setClient(!_.isEmpty(clientData) ? Object.assign({}, clientData) : {})

            const searchCompanies = window.firebase.functions().httpsCallable('generic-getData');
            const resp = await searchCompanies({
                type: 'getSummaryReportbyUsers',
                params: JSON.stringify({
                    clientID: clientData.documentID,
                    date: moment(_date).format('YYYY-MM-DD')
                })
            })

            if (!resp.data.success) {
                return
            }

            const data = resp.data.data

            data.forEach(item => {
                if (item.col === 'enq') {
                    allEnquiries.push(item)
                } else if (item.col === 'td') {
                    allTestDrives.push(item)
                } else if (item.col === 'wk') {
                    allWalkins.push(item)
                } else if (item.col === 'act') {
                    allActivities.push(item)
                }
            })

            //console.log('function resp, ', resp)

            let enqWithTestDrives = allEnquiries.map(enq => {
                return {
                    ...enq,
                    testdrives: allTestDrives.filter(td => td.enquiryID === enq.documentID),
                    walkins: allWalkins.filter(w => w.enquiryID === enq.documentID),
                    activities: allActivities.filter(w => w.enquiryID === enq.documentID),
                }
            })
            setEnquiries(enqWithTestDrives)
            setClientSettings(clientSettingsData)
            setLoader(false)
            setRefreshClientData(true)
            setRefreshData(false)
            // if (_enquiries.length === 0) {
                setLoader(false)
            // }
        }        
        getData()
    }, [refreshData])


    useEffect(() => {
        if (!_.isArray(pipelineEnquiries) || _.isEmpty(pipelineEnquiries))
        return

        let eligibleEnq = pipelineEnquiries
        //.filter(enq => !_.isEmpty(enq.requirement) && enq.enquiryType)
        let bmwEnqNew = eligibleEnq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
             if (salesType.toLowerCase().includes('preowned')) {
                returnVal = false
            } else if (make && make.toLowerCase().includes('bmw')) {
                returnVal = true
            }
            return returnVal
        })

        let bmwEnqUsed = eligibleEnq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
            if (salesType.toLowerCase().includes('preowned') && make && make.toLowerCase().includes('bmw')) {
                returnVal = true
            }
            return returnVal
        })

        let miniEnqNew = eligibleEnq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
             if (salesType.toLowerCase().includes('preowned')) {
                returnVal = false
            } else if (make && make.toLowerCase().includes('mini')) {
                returnVal = true
            }
            return returnVal
        })

        let miniEnqUsed = eligibleEnq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
             if (salesType.toLowerCase().includes('preowned') && make && make.toLowerCase().includes('mini')) {
                returnVal = true
            }
            return returnVal
        })

        let bmwCountsNew = {
            yearAllEnq: bmwEnqNew.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= yearStart.seconds
                && a.addedDate.seconds <= yearEnd.seconds),
            monthAllEnq: bmwEnqNew.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
        }

        bmwCountsNew = {
            ...bmwCountsNew,
            yearWalkin: bmwCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'walk')),
            monthWalkin: bmwCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'walk')),
            yearPhoneIn: bmwCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'phone')),
            monthPhoneIn: bmwCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'phone')),
            yearHeadOffice: bmwCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'headoffice')),
            monthHeadOffice: bmwCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'headoffice')),
            yearAutogate: bmwCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'autogate')),
            monthAutogate: bmwCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'autogate')),
            yearInternet: bmwCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'internet')),
            monthInternet: bmwCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'internet')),
            yearOutbound: bmwCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'outbound')),
            monthOutbound: bmwCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'outbound')),
            yearOther: bmwCountsNew.yearAllEnq.filter(a => 
                   !checkEnqType(a.enquiryType,'outbound')
                && !checkEnqType(a.enquiryType,'internet')
                && !checkEnqType(a.enquiryType,'autogate')
                && !checkEnqType(a.enquiryType,'headoffice')
                && !checkEnqType(a.enquiryType,'walk')
                && !checkEnqType(a.enquiryType,'phone')),
            monthOther: bmwCountsNew.monthAllEnq.filter(a => 
                   !checkEnqType(a.enquiryType,'outbound')
                && !checkEnqType(a.enquiryType,'internet')
                && !checkEnqType(a.enquiryType,'autogate')
                && !checkEnqType(a.enquiryType,'headoffice')
                && !checkEnqType(a.enquiryType,'walk')
                && !checkEnqType(a.enquiryType,'phone')),
            yearTestdriveCount: bmwCountsNew.yearAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
            monthTestdriveCount: bmwCountsNew.monthAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
            yearSale: bmwCountsNew.yearAllEnq.filter(a => a.statusName === 'won' || a.statusName === 'delivered'),
            monthSale:bmwCountsNew.monthAllEnq.filter(a => a.statusName === 'won'|| a.statusName === 'delivered'),
            yearFaceToFace: bmwCountsNew.yearAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            monthFaceToFace:bmwCountsNew.monthAllEnq.filter(a => a.soldDate|| a.testdrives.length > 0 || a.walkins.length > 0),
            yearAllFollowups: calculateAllFollowups(bmwCountsNew.yearAllEnq),
            monthAllFollowups: calculateAllFollowups(bmwCountsNew.monthAllEnq),
            yearAllFollowupLeads: calculateAllFollowupLeads(bmwCountsNew.yearAllEnq),
            monthAllFollowupLeads: calculateAllFollowupLeads(bmwCountsNew.monthAllEnq),
        }

        let bmwCountsUsed = {
            yearAllEnq: bmwEnqUsed.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= yearStart.seconds
                && a.addedDate.seconds <= yearEnd.seconds),
            monthAllEnq: bmwEnqUsed.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
        }

        bmwCountsUsed = {
            ...bmwCountsUsed,
            yearWalkin: bmwCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'walk')),
            monthWalkin: bmwCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'walk')),
            yearPhoneIn: bmwCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'phone')),
            monthPhoneIn: bmwCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'phone')),
            yearHeadOffice: bmwCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'headoffice')),
            monthHeadOffice: bmwCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'headoffice')),
            yearAutogate: bmwCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'autogate')),
            monthAutogate: bmwCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'autogate')),
            yearInternet: bmwCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'internet')),
            monthInternet: bmwCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'internet')),
            yearOutbound: bmwCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'outbound')),
            monthOutbound: bmwCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'outbound')),
            yearOther: bmwCountsUsed.yearAllEnq.filter(a => 
                   !checkEnqType(a.enquiryType,'outbound')
                && !checkEnqType(a.enquiryType,'internet')
                && !checkEnqType(a.enquiryType,'autogate')
                && !checkEnqType(a.enquiryType,'headoffice')
                && !checkEnqType(a.enquiryType,'walk')
                && !checkEnqType(a.enquiryType,'phone')),
            monthOther: bmwCountsUsed.monthAllEnq.filter(a => 
                   !checkEnqType(a.enquiryType,'outbound')
                && !checkEnqType(a.enquiryType,'internet')
                && !checkEnqType(a.enquiryType,'autogate')
                && !checkEnqType(a.enquiryType,'headoffice')
                && !checkEnqType(a.enquiryType,'walk')
                && !checkEnqType(a.enquiryType,'phone')),
            yearTestdriveCount: bmwCountsUsed.yearAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
            monthTestdriveCount: bmwCountsUsed.monthAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
            yearSale: bmwCountsUsed.yearAllEnq.filter(a => a.statusName === 'won' || a.statusName === 'delivered'),
            monthSale:bmwCountsUsed.monthAllEnq.filter(a => a.statusName === 'won'|| a.statusName === 'delivered'),
            yearFaceToFace: bmwCountsUsed.yearAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            monthFaceToFace:bmwCountsUsed.monthAllEnq.filter(a => a.soldDate|| a.testdrives.length > 0 || a.walkins.length > 0),
            yearAllFollowups: calculateAllFollowups(bmwCountsUsed.yearAllEnq),
            monthAllFollowups: calculateAllFollowups(bmwCountsUsed.monthAllEnq),
            yearAllFollowupLeads: calculateAllFollowupLeads(bmwCountsUsed.yearAllEnq),
            monthAllFollowupLeads: calculateAllFollowupLeads(bmwCountsUsed.monthAllEnq),
        }

        let miniCountsNew = {
            yearAllEnq: miniEnqNew.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= yearStart.seconds
                && a.addedDate.seconds <= yearEnd.seconds),
            monthAllEnq: miniEnqNew.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
        }

        miniCountsNew =  {
            ...miniCountsNew,
            yearWalkin: miniCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'walk')),
            monthWalkin: miniCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'walk')),
            yearPhoneIn: miniCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'phone')),
            monthPhoneIn: miniCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'phone')),
            yearHeadOffice: miniCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'headoffice')),
            monthHeadOffice: miniCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'headoffice')),
            yearAutogate: miniCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'autogate')),
            monthAutogate: miniCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'autogate')),
            yearInternet: miniCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'internet')),
            monthInternet: miniCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'internet')),
            yearOutbound: miniCountsNew.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'outbound')),
            monthOutbound: miniCountsNew.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'outbound')),
            yearOther: miniCountsNew.yearAllEnq.filter(a => 
                   !checkEnqType(a.enquiryType,'outbound')
                && !checkEnqType(a.enquiryType,'internet')
                && !checkEnqType(a.enquiryType,'autogate')
                && !checkEnqType(a.enquiryType,'headoffice')
                && !checkEnqType(a.enquiryType,'walk')
                && !checkEnqType(a.enquiryType,'phone')),
            monthOther: miniCountsNew.monthAllEnq.filter(a => 
                   !checkEnqType(a.enquiryType,'outbound')
                && !checkEnqType(a.enquiryType,'internet')
                && !checkEnqType(a.enquiryType,'autogate')
                && !checkEnqType(a.enquiryType,'headoffice')
                && !checkEnqType(a.enquiryType,'walk')
                && !checkEnqType(a.enquiryType,'phone')),
            yearTestdriveCount: miniCountsNew.yearAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
            monthTestdriveCount: miniCountsNew.monthAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
            yearSale: miniCountsNew.yearAllEnq.filter(a => a.statusName === 'won' || a.statusName === 'delivered'),
            monthSale:miniCountsNew.monthAllEnq.filter(a => a.statusName === 'won'|| a.statusName === 'delivered'),
            yearFaceToFace: miniCountsNew.yearAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            monthFaceToFace:miniCountsNew.monthAllEnq.filter(a => a.soldDate|| a.testdrives.length > 0 || a.walkins.length > 0),
            yearAllFollowups: calculateAllFollowups(miniCountsNew.yearAllEnq),
            monthAllFollowups: calculateAllFollowups(miniCountsNew.monthAllEnq),
            yearAllFollowupLeads: calculateAllFollowupLeads(miniCountsNew.yearAllEnq),
            monthAllFollowupLeads: calculateAllFollowupLeads(miniCountsNew.monthAllEnq),
        }

        let miniCountsUsed = {
            yearAllEnq: miniEnqUsed.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= yearStart.seconds
                && a.addedDate.seconds <= yearEnd.seconds),
            monthAllEnq: miniEnqUsed.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
        }

        miniCountsUsed =  {
            ...miniCountsUsed,
            yearWalkin: miniCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'walk')),
            monthWalkin: miniCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'walk')),
            yearPhoneIn: miniCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'phone')),
            monthPhoneIn: miniCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'phone')),
            yearHeadOffice: miniCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'headoffice')),
            monthHeadOffice: miniCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'headoffice')),
            yearAutogate: miniCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'autogate')),
            monthAutogate: miniCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'autogate')),
            yearInternet: miniCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'internet')),
            monthInternet: miniCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'internet')),
            yearOutbound: miniCountsUsed.yearAllEnq.filter(a => checkEnqType(a.enquiryType,'outbound')),
            monthOutbound: miniCountsUsed.monthAllEnq.filter(a => checkEnqType(a.enquiryType,'outbound')),
            yearOther: miniCountsUsed.yearAllEnq.filter(a => 
                   !checkEnqType(a.enquiryType,'outbound')
                && !checkEnqType(a.enquiryType,'internet')
                && !checkEnqType(a.enquiryType,'autogate')
                && !checkEnqType(a.enquiryType,'headoffice')
                && !checkEnqType(a.enquiryType,'walk')
                && !checkEnqType(a.enquiryType,'phone')),
            monthOther: miniCountsUsed.monthAllEnq.filter(a => 
                   !checkEnqType(a.enquiryType,'outbound')
                && !checkEnqType(a.enquiryType,'internet')
                && !checkEnqType(a.enquiryType,'autogate')
                && !checkEnqType(a.enquiryType,'headoffice')
                && !checkEnqType(a.enquiryType,'walk')
                && !checkEnqType(a.enquiryType,'phone')),
            yearTestdriveCount: miniCountsUsed.yearAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
            monthTestdriveCount: miniCountsUsed.monthAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
            yearSale: miniCountsUsed.yearAllEnq.filter(a => a.statusName === 'won' || a.statusName === 'delivered'),
            monthSale:miniCountsUsed.monthAllEnq.filter(a => a.statusName === 'won'|| a.statusName === 'delivered'),
            yearFaceToFace: miniCountsUsed.yearAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
            monthFaceToFace:miniCountsUsed.monthAllEnq.filter(a => a.soldDate|| a.testdrives.length > 0 || a.walkins.length > 0),
            yearAllFollowups: calculateAllFollowups(miniCountsUsed.yearAllEnq),
            monthAllFollowups: calculateAllFollowups(miniCountsUsed.monthAllEnq),
            yearAllFollowupLeads: calculateAllFollowupLeads(miniCountsUsed.yearAllEnq),
            monthAllFollowupLeads: calculateAllFollowupLeads(miniCountsUsed.monthAllEnq),
        }

        setBmwNewCounts({
            ...bmwCountsNew
        })
        setMiniNewCounts({
            ...miniCountsNew
        })
        setBmwUsedCounts({
            ...bmwCountsUsed
        })
        setMiniUsedCounts({
            ...miniCountsUsed
        })
        setCountsCalculated(true)

    }, [pipelineEnquiries])

    const checkEnqType = (type, strToCheck) => {
        let returnVal = false
        
        let enqType = CommonHelper.getNameByValue(clientSettings.settings.enquiryTypes, type, '')
        
        if (enqType.toLowerCase().includes(strToCheck.toLowerCase())) {
            returnVal = true
        } else {
            returnVal = false
        }
        if (strToCheck === 'headoffice') {
            if (enqType.toLowerCase().includes(strToCheck.toLowerCase()) || 
            enqType.toLowerCase().includes('bmw australia') || 
            enqType.toLowerCase().includes('mini australia')|| 
            enqType.toLowerCase().includes('bmw group crm')|| 
            enqType.toLowerCase().includes('bmw head office')) {
                returnVal = true
            } else {
                returnVal = false
            }
        }
        return returnVal
    }   

    const calculatePercentage = (val1, val2) => {
        return (val2 > 0 && val1 > 0 ?
            parseFloat((100 / (val1) * val2).toFixed(2)) + "%" :
            "0.00%");
    }

    const calculateAllFollowups = (enquiries) => {
        let ArrOfActivitiesArr = enquiries.filter(a => a.activities.length).map(a => a.activities)
        let count = 0;
        ArrOfActivitiesArr.forEach(activities => {
            let allDates = [];
            activities.forEach(a => {
                let date = moment(a.addedDate.toDate()).format('YYYY/MM/DD')
                if (!allDates.includes(date)) {
                    allDates.push(date)
                }
            })
            count += allDates.length
        })
        return count
    }

    const calculateAllFollowupLeads = (enquiries) => {
        let ArrOfActivitiesArr = enquiries.filter(a => a.activities.length).map(a => {
            return {
                activities: a.activities,
                addedDate: a.addedDate,
            }
        })
        let count = 0;
        ArrOfActivitiesArr.forEach(activitiesObj => {
            let addedDate = moment(activitiesObj.addedDate.toDate()).format('YYYY/MM/DD')
            let allDates = [];
            activitiesObj.activities.forEach(a => {
                let date = moment(a.addedDate.toDate()).format('YYYY/MM/DD')
                if (!allDates.includes(date) && addedDate !== date) {
                    allDates.push(date)
                }
            })
            count += allDates.length
        })
        return count
    }

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('daily-page-landing');
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        document.querySelector('#top-nav-bar') && document.querySelector('#top-nav-bar').classList.add('d-none');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
            document.querySelector('#top-nav-bar') && document.querySelector('#top-nav-bar').classList.remove('d-none');
        }
      }, [])

    
    const generateDynamicRowsBySeries = (type, enquiries, seriesList) => {
        let seriesNames = {}
        seriesList.forEach(item => {
            seriesNames[item.Name] = {
                seriesName: item.Name,
                enquiries: []
            }
        })
        enquiries.forEach(enq => {
            let model = ''
            if (!_.isEmpty(enq.requirement.stock)) {
                model = enq.requirement.stock.model
            } else if (enq.requirement.model) {
                model = enq.requirement.model
            }
            seriesList.forEach(series => {
                let isModelIncludedInSeries = false
                series.StockKeyword.forEach(word => {
                    if (model === word) {
                        isModelIncludedInSeries = true
                    }
                })
                if (isModelIncludedInSeries) {
                    seriesNames[series.Name] = {
                        ...seriesNames[series.Name],
                        enquiries: [...seriesNames[series.Name].enquiries, enq]
                    }
                }
            })
        })
        seriesNames = Object.values(seriesNames).filter(a => a.enquiries.length)


        if (seriesNames.length === 0) {
            return (<></>)
        }

        const arrToRender = seriesNames.map(item => {
            let enqs = item.enquiries;
            let counts = {
                monthAllEnq: item.enquiries,
                monthWalkin: enqs.filter(a => checkEnqType(a.enquiryType,'walk')),
                monthPhoneIn: enqs.filter(a => checkEnqType(a.enquiryType,'phone')),
                monthHeadOffice: enqs.filter(a => checkEnqType(a.enquiryType,'headoffice')),
                monthAutogate: enqs.filter(a => checkEnqType(a.enquiryType,'autogate')),
                monthInternet: enqs.filter(a => checkEnqType(a.enquiryType,'internet')),
                monthOutbound: enqs.filter(a => checkEnqType(a.enquiryType,'outbound')),
                monthOther: enqs.filter(a => 
                       !checkEnqType(a.enquiryType,'outbound')
                    && !checkEnqType(a.enquiryType,'internet')
                    && !checkEnqType(a.enquiryType,'autogate')
                    && !checkEnqType(a.enquiryType,'headoffice')
                    && !checkEnqType(a.enquiryType,'walk')
                    && !checkEnqType(a.enquiryType,'phone')),
                monthTestdriveCount: enqs.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length).length,
                monthSale:enqs.filter(a => a.statusName === 'won'|| a.statusName === 'delivered'),
                monthFaceToFace:enqs.filter(a => a.soldDate|| a.testdrives.length > 0 || a.walkins.length > 0),
                monthAllFollowups: calculateAllFollowups(enqs),
                monthAllFollowupLeads: calculateAllFollowupLeads(enqs),
            }
            return {
               seriesName: item.seriesName,
               counts,
            }
        })

        // console.log('this is the ',arrToRender)
    
        return (
            <>
                {arrToRender.map((a, index) => (
                    <>
                    <tr>
                        <td className="td-head">{a.seriesName}</td>
                        <td className="count-large">{a.counts.monthWalkin.length}</td>
                        <td className="count-large">{a.counts.monthPhoneIn.length}</td>
                        <td className="count-large">{a.counts.monthHeadOffice.length}</td>
                        <td className="count-large">{a.counts.monthAutogate.length}</td>
                        <td className="count-large">{a.counts.monthInternet.length}</td>
                        <td className="count-large">{a.counts.monthOutbound.length}</td>
                        <td className="count-large">{a.counts.monthOther.length}</td>
                        <td className="count-large">{a.counts.monthAllEnq.length}</td>
                        <td className="count-large">{a.counts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                        <td className="count-large">{a.counts.monthTestdriveCount}</td> {/* TestDrive  */}
                        <td className="count-large">{a.counts.monthSale.length}</td> {/* Sale  */}
                        <td className="count-large">{calculatePercentage(a.counts.monthAllFollowups, a.counts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                        <td className="count-large">{calculatePercentage(a.counts.monthAllEnq.length, a.counts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                        <td className="count-large">{calculatePercentage(a.counts.monthTestdriveCount, a.counts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                        <td className="count-large">{calculatePercentage(a.counts.monthAllEnq.length, a.counts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                    </tr>
                    {arrToRender.length === (index+1) && (
                        <>
                            {/* @info: Below div is used for selector by puppeteer (backend)*/}
                            <div className="report-data-loaded report-data-load"/>
                        </>   
                    )}
                    </>
                ))}
            </>
        )
    }
    

    return (
        
        <>
            {
                (countsCalculated && !_.isEmpty(clientSettings))
                    ?
                    <>
                        <div className="A4 landscape">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }
                                                </div></td>

                                                <td width="40%"><div className="header-right"> {'SUMMARY REPORT'} <br></br>
                                                    <span> {moment(_date).format('DD MMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">

                                    {/* BMW NEW */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">BMW NEW ENQUIRY</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMM')}</td>
                                                <td className="count-large">{bmwNewCounts.monthWalkin.length}</td>
                                                <td className="count-large">{bmwNewCounts.monthPhoneIn.length}</td>
                                                <td className="count-large">{bmwNewCounts.monthHeadOffice.length}</td>
                                                <td className="count-large">{bmwNewCounts.monthAutogate.length}</td>
                                                <td className="count-large">{bmwNewCounts.monthInternet.length}</td>
                                                <td className="count-large">{bmwNewCounts.monthOutbound.length}</td>
                                                <td className="count-large">{bmwNewCounts.monthOther.length}</td>
                                                <td className="count-large">{bmwNewCounts.monthAllEnq.length}</td>
                                                <td className="count-large">{bmwNewCounts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{bmwNewCounts.monthTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{bmwNewCounts.monthSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(bmwNewCounts.monthAllFollowups, bmwNewCounts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(bmwNewCounts.monthAllEnq.length, bmwNewCounts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(bmwNewCounts.monthTestdriveCount, bmwNewCounts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(bmwNewCounts.monthAllEnq.length, bmwNewCounts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                <td className="count-large">{bmwNewCounts.yearWalkin.length}</td>
                                                <td className="count-large">{bmwNewCounts.yearPhoneIn.length}</td>
                                                <td className="count-large">{bmwNewCounts.yearHeadOffice.length}</td>
                                                <td className="count-large">{bmwNewCounts.yearAutogate.length}</td>
                                                <td className="count-large">{bmwNewCounts.yearInternet.length}</td>
                                                <td className="count-large">{bmwNewCounts.yearOutbound.length}</td>
                                                <td className="count-large">{bmwNewCounts.yearOther.length}</td>
                                                <td className="count-large">{bmwNewCounts.yearAllEnq.length}</td>
                                                <td className="count-large">{bmwNewCounts.yearFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{bmwNewCounts.yearTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{bmwNewCounts.yearSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(bmwNewCounts.yearAllFollowups, bmwNewCounts.yearAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(bmwNewCounts.yearAllEnq.length, bmwNewCounts.yearTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(bmwNewCounts.yearTestdriveCount, bmwNewCounts.yearSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(bmwNewCounts.yearAllEnq.length, bmwNewCounts.yearSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* BMW USED */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">BMW USED ENQUIRY&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMM')}</td>
                                                <td className="count-large">{bmwUsedCounts.monthWalkin.length}</td>
                                                <td className="count-large">{bmwUsedCounts.monthPhoneIn.length}</td>
                                                <td className="count-large">{bmwUsedCounts.monthHeadOffice.length}</td>
                                                <td className="count-large">{bmwUsedCounts.monthAutogate.length}</td>
                                                <td className="count-large">{bmwUsedCounts.monthInternet.length}</td>
                                                <td className="count-large">{bmwUsedCounts.monthOutbound.length}</td>
                                                <td className="count-large">{bmwUsedCounts.monthOther.length}</td>
                                                <td className="count-large">{bmwUsedCounts.monthAllEnq.length}</td>
                                                <td className="count-large">{bmwUsedCounts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{bmwUsedCounts.monthTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{bmwUsedCounts.monthSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(bmwUsedCounts.monthAllFollowups, bmwUsedCounts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(bmwUsedCounts.monthAllEnq.length, bmwUsedCounts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(bmwUsedCounts.monthTestdriveCount, bmwUsedCounts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(bmwUsedCounts.monthAllEnq.length, bmwUsedCounts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                <td className="count-large">{bmwUsedCounts.yearWalkin.length}</td>
                                                <td className="count-large">{bmwUsedCounts.yearPhoneIn.length}</td>
                                                <td className="count-large">{bmwUsedCounts.yearHeadOffice.length}</td>
                                                <td className="count-large">{bmwUsedCounts.yearAutogate.length}</td>
                                                <td className="count-large">{bmwUsedCounts.yearInternet.length}</td>
                                                <td className="count-large">{bmwUsedCounts.yearOutbound.length}</td>
                                                <td className="count-large">{bmwUsedCounts.yearOther.length}</td>
                                                <td className="count-large">{bmwUsedCounts.yearAllEnq.length}</td>
                                                <td className="count-large">{bmwUsedCounts.yearFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{bmwUsedCounts.yearTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{bmwUsedCounts.yearSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(bmwUsedCounts.yearAllFollowups, bmwUsedCounts.yearAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(bmwUsedCounts.yearAllEnq.length, bmwUsedCounts.yearTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(bmwUsedCounts.yearTestdriveCount, bmwUsedCounts.yearSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(bmwUsedCounts.yearAllEnq.length, bmwUsedCounts.yearSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                        </tbody>
                                    </table>

                                     {/* BMW NEW BY SERIES */}
                                     <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">BMW NEW ENQUIRY - By Series&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {generateDynamicRowsBySeries('bmw', bmwNewCounts.monthAllEnq, bmwList)}
                                        </tbody>
                                    </table>

                                    {/* MINI NEW  */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">MINI NEW ENQUIRY&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMM')}</td>
                                                <td className="count-large">{miniNewCounts.monthWalkin.length}</td>
                                                <td className="count-large">{miniNewCounts.monthPhoneIn.length}</td>
                                                <td className="count-large">{miniNewCounts.monthHeadOffice.length}</td>
                                                <td className="count-large">{miniNewCounts.monthAutogate.length}</td>
                                                <td className="count-large">{miniNewCounts.monthInternet.length}</td>
                                                <td className="count-large">{miniNewCounts.monthOutbound.length}</td>
                                                <td className="count-large">{miniNewCounts.monthOther.length}</td>
                                                <td className="count-large">{miniNewCounts.monthAllEnq.length}</td>
                                                <td className="count-large">{miniNewCounts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{miniNewCounts.monthTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{miniNewCounts.monthSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(miniNewCounts.monthAllFollowups, miniNewCounts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(miniNewCounts.monthAllEnq.length, miniNewCounts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(miniNewCounts.monthTestdriveCount, miniNewCounts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(miniNewCounts.monthAllEnq.length, miniNewCounts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                <td className="count-large">{miniNewCounts.yearWalkin.length}</td>
                                                <td className="count-large">{miniNewCounts.yearPhoneIn.length}</td>
                                                <td className="count-large">{miniNewCounts.yearHeadOffice.length}</td>
                                                <td className="count-large">{miniNewCounts.yearAutogate.length}</td>
                                                <td className="count-large">{miniNewCounts.yearInternet.length}</td>
                                                <td className="count-large">{miniNewCounts.yearOutbound.length}</td>
                                                <td className="count-large">{miniNewCounts.yearOther.length}</td>
                                                <td className="count-large">{miniNewCounts.yearAllEnq.length}</td>
                                                <td className="count-large">{miniNewCounts.yearFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{miniNewCounts.yearTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{miniNewCounts.yearSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(miniNewCounts.yearAllFollowups, miniNewCounts.yearAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(miniNewCounts.yearAllEnq.length, miniNewCounts.yearTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(miniNewCounts.yearTestdriveCount, miniNewCounts.yearSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(miniNewCounts.yearAllEnq.length, miniNewCounts.yearSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* MINI USED */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">MINI USED ENQUIRY&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMM')}</td>
                                                <td className="count-large">{miniUsedCounts.monthWalkin.length}</td>
                                                <td className="count-large">{miniUsedCounts.monthPhoneIn.length}</td>
                                                <td className="count-large">{miniUsedCounts.monthHeadOffice.length}</td>
                                                <td className="count-large">{miniUsedCounts.monthAutogate.length}</td>
                                                <td className="count-large">{miniUsedCounts.monthInternet.length}</td>
                                                <td className="count-large">{miniUsedCounts.monthOutbound.length}</td>
                                                <td className="count-large">{miniUsedCounts.monthOther.length}</td>
                                                <td className="count-large">{miniUsedCounts.monthAllEnq.length}</td>
                                                <td className="count-large">{miniUsedCounts.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{miniUsedCounts.monthTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{miniUsedCounts.monthSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(miniUsedCounts.monthAllFollowups, miniUsedCounts.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(miniUsedCounts.monthAllEnq.length, miniUsedCounts.monthTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(miniUsedCounts.monthTestdriveCount, miniUsedCounts.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(miniUsedCounts.monthAllEnq.length, miniUsedCounts.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                <td className="count-large">{miniUsedCounts.yearWalkin.length}</td>
                                                <td className="count-large">{miniUsedCounts.yearPhoneIn.length}</td>
                                                <td className="count-large">{miniUsedCounts.yearHeadOffice.length}</td>
                                                <td className="count-large">{miniUsedCounts.yearAutogate.length}</td>
                                                <td className="count-large">{miniUsedCounts.yearInternet.length}</td>
                                                <td className="count-large">{miniUsedCounts.yearOutbound.length}</td>
                                                <td className="count-large">{miniUsedCounts.yearOther.length}</td>
                                                <td className="count-large">{miniUsedCounts.yearAllEnq.length}</td>
                                                <td className="count-large">{miniUsedCounts.yearFaceToFace.length}</td> {/* FaceToFace  */}
                                                <td className="count-large">{miniUsedCounts.yearTestdriveCount}</td> {/* TestDrive  */}
                                                <td className="count-large">{miniUsedCounts.yearSale.length}</td> {/* Sale  */}
                                                <td className="count-large">{calculatePercentage(miniUsedCounts.yearAllFollowups, miniUsedCounts.yearAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                <td className="count-large">{calculatePercentage(miniUsedCounts.yearAllEnq.length, miniUsedCounts.yearTestdriveCount)}</td> {/* LEAD TO TESTDRIVE  */}
                                                <td className="count-large">{calculatePercentage(miniUsedCounts.yearTestdriveCount, miniUsedCounts.yearSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                <td className="count-large">{calculatePercentage(miniUsedCounts.yearAllEnq.length, miniUsedCounts.yearSale.length)}</td> {/* LEAD TO SALE   */}
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* MINI NEW BY SERIES */}
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%">MINI NEW ENQUIRY - By Series&nbsp;</th>
                                                <th width="5.3%">WALKIN</th>
                                                <th width="5.3%">PHONE-IN</th>
                                                <th width="5.3%">HEAD OFFICE</th>
                                                <th width="5.3%">AUTOGATE </th>
                                                <th width="5.3%">INTERNET  </th>
                                                <th width="5.3%">OUTBOUND</th>
                                                <th width="5.3%">OTHER</th>
                                                <th width="5.3%">TOTAL LEADS</th>
                                                <th width="5.3%">FACE TO FACE</th>
                                                <th width="5.3%">TEST DRIVE</th>
                                                <th width="5.3%">SALE</th>
                                                <th width="5.3%">FOLLOW UP RATE %</th>
                                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                <th width="5.3%">LEAD TO SALE %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {generateDynamicRowsBySeries('mini', miniNewCounts.monthAllEnq, miniList)}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>
                    </>
                    :
                    <>
                        <div className='loader-center-screen'>
                            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="report-loader spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                        </div>
                    </>
            }
        </>
    )
}
export default SalesProgressBMW;

const bmwList = [
    { Name: "1 Series", VehicleType: "1 Series", StockKeyword: [ "F20", "E82", "E88" ] },
    { Name: "2 Series", VehicleType: "2 Series", NotEquals: "Active Tourer", StockKeyword: [ "F22", "F23" ] },
    { Name: "2 Series Active Tourer", VehicleType: "2 Series", ModelsKeyword: "Active Tourer", StockKeyword: [ "F45" ] },
    { Name: "3 Series", VehicleType: "3 Series", StockKeyword: [ "F30", "F31", "F34", "E90", "E91", "E92", "E93" ] },
    { Name: "4 Series", VehicleType: "4 Series", StockKeyword: [ "F32", "F33", "F36" ] },
    { Name: "5 Series", VehicleType: "5 Series", StockKeyword: [ "G30", "G31", "F07", "F10", "F11" ] },
    { Name: "6 Series", VehicleType: "6 Series", StockKeyword: [ "F06", "F12", "F13" ] },
    { Name: "7 Series", VehicleType: "7 Series", StockKeyword: [ "G11", "G12", "F01", "F02" ] },
    { Name: "8 Series", VehicleType: "8 Series", StockKeyword: [ "G14", "G15" ] },
    { Name: "X1", VehicleType: "X Models", ModelsKeyword: "X1", StockKeyword: [ "F48", "E84" ] },
    { Name: "X2", VehicleType: "X Models", ModelsKeyword: "X2", StockKeyword: [ "F39" ] },
    { Name: "X3", VehicleType: "X Models", ModelsKeyword: "X3", StockKeyword: [ "F25", "G01" ] },
    { Name: "X4", VehicleType: "X Models", ModelsKeyword: "X4", StockKeyword: [ "F26" ] },
    { Name: "X5", VehicleType: "X Models", ModelsKeyword: "X5", StockKeyword: [ "F15", "F85", "E70" ] },
    { Name: "X6", VehicleType: "X Models", ModelsKeyword: "X6", StockKeyword: [ "F16", "E71" ] },
    { Name: "X7", VehicleType: "X Models", ModelsKeyword: "X7", StockKeyword: [ "G07" ] },
    { Name: "M Vehicles", VehicleType: "M Models", StockKeyword: [ "F87", "F80", "F82", "F83", "F90", "F86" ] },
    { Name: "Z Vehicles", VehicleType: "Z Models", StockKeyword: [ "E89" ] },
    { Name: "BMW i3", VehicleType: "i Series", ModelsKeyword: "i3", StockKeyword: [ "I0" ] },
    { Name: "BMW i8", VehicleType: "i Series", ModelsKeyword: "i8", StockKeyword: [ "I12" ] },
]

const miniList = [
    { Name: "Ray", VehicleType: "Ray", NotEquals: "COOPER", StockKeyword: [ "F55 RAY", "F56 RAY", "RAY" ] },
    { Name: "3-Door Hatch", VehicleType: "3-DOOR HATCH", NotEquals: "Ray", StockKeyword: [ "F56", "F56 COOPER" ] },
    { Name: "5-Door Hatch", VehicleType: "5-DOOR HATCH", NotEquals: "Ray", StockKeyword: [ "F55", "F55 COOPER" ] },
    { Name: "Convertible/Cabrio", VehicleType: "CONVERTIBLE", StockKeyword: [ "F57" ] },
    { Name: "Clubman", VehicleType: "CLUBMAN", StockKeyword: [ "F54" ] },
    { Name: "Countryman", VehicleType: "COUNTRYMAN", StockKeyword: [ "F60" ] },
]

