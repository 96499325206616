import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash'
import { PopUpModal } from '../../components';
import PreviewForm from '../settings/workflow/feedbackForms/preview';
import Translate from '../../constants/translate';
import SendFeedback from '../feedbackform/resendFeedback';
import { removeHTMLTags } from '../../services/helper';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

const FormFeedback = ({ formFeedback, clientUsers, dealersettings }) => {
	const [previewForm, setPreviewForm] = useState({
		show: false,
		title: '',
		formFields: null,
		data: null
	})
	const [sendForm, setSendForm] = useState({
		show: false,
		title: ''
	})
	const [editshow, setEditshow] = useState(false)
	const node = useRef();

	const eventDay = formFeedback.modifiedDate ? moment.unix(formFeedback.modifiedDate.seconds).format('DD') : moment.unix(formFeedback.sentDate.seconds).format('DD');
	const eventMonth = formFeedback.modifiedDate ? moment.unix(formFeedback.modifiedDate.seconds).format('MMM') : moment.unix(formFeedback.sentDate.seconds).format('MMM');
	const title = formFeedback.formFields && formFeedback.formFields.some(a => a.type === 'header') ? _.find(formFeedback.formFields, { type: 'header' }).label : ''

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);
	const handleClick = e => {

		if (node && node.current && node.current.contains(e.target)) {
			return;
		}
		setEditshow(false);
	};

	useEffect(() => {
		if (formFeedback.score && formFeedback.score >= 0) {
			var chart = am4core.create(`score-${formFeedback.documentID}`, am4charts.PieChart);
			chart.logo.disabled = true;
			chart.data = [{
				"category": "",
				"value": formFeedback.score,
				"color":"#4466f2"

			}, {
				"category": "",
				"value": 100 - formFeedback.score,
				"color":'#d3d9e6'
			}];

			chart.innerRadius = am4core.percent(90)
			//chart.radius = am4core.percent(90)

			var pieSeries = chart.series.push(new am4charts.PieSeries());
			pieSeries.tooltip.disabled = true;
			pieSeries.dataFields.value = "value";
			pieSeries.dataFields.category = "category";
			pieSeries.ticks.template.disabled = true;
			pieSeries.labels.template.disabled = true;
			var label = pieSeries.createChild(am4core.Label)
			label.text = `${formFeedback.score}%`;
			label.horizontalCenter = "middle";
			label.verticalCenter = "middle";
			label.fontSize = "15px";
			//chart.validateNow();

			pieSeries.slices.template.adapter.add("fill", function (color, target) {
				if (target.dataItem && target.dataItem.dataContext.color)
					return am4core.color(target.dataItem.dataContext.color);
				return color
			});

			window.feedbackchart = chart;
		}
		return () => {
			window.feedbackchart && window.feedbackchart.dispose()
		}
	}, [])

	return (<>
		<div
			className='event-app-item flex-event-app'
			id={'info_' + formFeedback.documentID}
			onClick={e => {
				e.preventDefault();
				if (!_.isEmpty(formFeedback.response))
					setPreviewForm({
						show: true,
						title: 'Customer Feedback',
						formFields: formFeedback.formFields,
						customerReview: formFeedback.response,
						data: formFeedback,
					});
				//window.open(`/customer/rating/${enquiryID}?preview=true`, '_blank').focus();
			}}
		>
			{
				_.isEmpty(formFeedback.response) ? (<div className="quickview-activity-more">
					<a href="#" onClick={(e) => {
						e.preventDefault();
						setEditshow(!editshow)
						if (editshow)
							document.addEventListener('click', setEditshow(false));
					}}><i className="ico icon-more"></i></a>
					<div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
						<ul className="more-dropdown">

							<li> <a href="#" onClick={(e) => {
								e.preventDefault();
								setEditshow(false);
								setSendForm({
									show: true,
									title: 'SEND FEEDBACK TO CUSTOMER'
								})
							}}> <i className="ico icon-email"></i><Translate text={'resend'} /> </a> </li>
						</ul>
					</div>
				</div>) : (<></>)
			}
			{
				formFeedback.score && formFeedback.score >= 0 ? (
					<div className='event-app-item-date'>
						<div id={`score-${formFeedback.documentID}`} style={{ width: "60px", height: '55px', marginLeft: '5px', marginTop: '2px' }}></div>
						<div className='event-app-item-submonth' style={{position:'absolute',bottom:'-2px'}}><Translate text={'Score'} /></div>
					</div>
				) : (
					<div className='event-app-item-date'>
						<div className='event-app-item-subdate'>{eventDay}</div>
						<div className='event-app-item-submonth'>{eventMonth}</div>
					</div>
				)
			}
			<div className='event-app-item-data'>
				<div className='event-app-item-title'><Translate text={title ? removeHTMLTags(title) : 'Owner Experience Survey'} /></div>
				{/* 	<div className='event-app-item-info w-100 d-inline-block mt-1'>
					<div className='feedbackform-answer-starrating'>
					<StarRatings
							rating={userRating.customerReview.satisfaction && Number(userRating.customerReview.satisfaction) ? Number(userRating.customerReview.satisfaction) : 0}
							isAggregateRating={true}
							changeRating={() => { }}
							numberOfStars={5}
							starRatedColor={'#ffc107'}
							starHoverColor={'#ffc107'}
							starDimension="20px"
							starSpacing="2px"
							name='satisfaction'
						/>
					</div>
				</div> */}
				<div className='event-app-item-info'><Translate text={'Sent on'} /> : {moment.unix(formFeedback.sentDate.seconds).format('DD/MM/YYYY h:mm A')}</div>
				{
					!_.isEmpty(formFeedback.response) ? (
						<div className='event-app-item-info green-date'><Translate text={'Response'} /> : {moment.unix(formFeedback.modifiedDate.seconds).format('DD/MM/YYYY h:mm A')}</div>
					) : (<div className='event-app-item-info red-date'><Translate text={'noResponse'} /></div>)
				}
			</div>
		</div>
		<PopUpModal show={previewForm.show}>
			<PreviewForm
				{...previewForm}
				dealersettings={dealersettings}
				handleClose={() => { setPreviewForm({ show: false, title: '', formFields: null, data: null }) }}
			/>
		</PopUpModal>
		<PopUpModal show={sendForm.show}>
			<SendFeedback
				{...sendForm}
				handleClose={() => { setSendForm({ show: false, title: '' }) }}
				contact={formFeedback.contact}
				content={formFeedback.content}
				documentID={formFeedback.documentID}
				dealersettings={dealersettings}
			/>
		</PopUpModal>
	</>);
};

export default FormFeedback;
