import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import _ from 'lodash'
import Translate from '../../constants/translate';
import CommonHelper from "../../services/common";

const ChatPopUp = (props) => {
    const fromUser = _.find(props.groupUsers, { id: props?.notification?.fromUserID });
    const [replyTxt, setReplyTxt] = useState()
    const [error, setError] = useState({})
    const [loader, setLoader] = useState(false)

    // useEffect(() => {
    //     if (!_.isEmpty(fromUser) || _.isEmpty(props?.notification?.fromUserID))
    //         return

    //     window.firebase
    //         .firestore()
    //         .doc(`users/${props.notification.fromUserID}`)
    //         .get(doc => {
    //             setfromUser(doc.data());
    //         });

    // }, [])

    const handleReply = async () => {
        if (_.trim(replyTxt).length === 0) {
            setError({ notes: 'input_error' })
            return;
        }
        setLoader(true)
        try {
            let alternateAddress = `${localStorage.uid}-${fromUser.id}`;
            let address = `${fromUser.id}-${localStorage.uid}`;
            let currentAddress = '';
            let firstRef = await window.firebase
                .firestore()
                .collection('messages')
                .doc(address)
                .collection(address).limit(1).get();
            if (firstRef.size > 0) {
                currentAddress = address
            } else {
                currentAddress = alternateAddress
            }


            const timestamp = moment()._d.getTime();

            const messageRef = window.firebase
                .firestore()
                .collection('messages')
                .doc(currentAddress)
                .collection(currentAddress)
                .doc(timestamp.toString());

            let messageModel = {
                content: replyTxt,
                idFrom: localStorage.uid,
                idTo: fromUser.id,
                idToAMSMe: false,
                timestamp,
                type: 'text',
                oemID: props.dealersettings.oemID,
                clientID: props.dealersettings.client.id,
                isRead: false,
                documentID: null,
                avatarFrom: CommonHelper.getUserImagebyId(props.clientUsers, localStorage.uid),
                addedFrom: 'web',
                nameFrom: props.dealersettings.name
            };

            let messageInfoModel = {
                clientID: props.dealersettings.client.id,
                isRead: false,
                lastMessage: {},
                oemID: props.dealersettings.oemID,
                participantInfo: [],
                timestamp,
            };

            if (fromUser.conversationType === 'group') {
                messageModel.idTo = fromUser.dbAddress
                messageModel.groupPendingReads = []
                fromUser.participants.forEach(participant => {
                    if (participant !== localStorage.uid) {
                        messageModel.groupPendingReads.push(participant)
                    }
                })
                messageInfoModel.groupPendingReads = []
                fromUser.participants.forEach(participant => {
                    if (participant !== localStorage.uid) {
                        messageInfoModel.groupPendingReads.push(participant)
                    }
                })
                messageInfoModel.lastMessage = { ...messageModel }
            } else {
                messageInfoModel.participantInfo.push({
                    avatar: CommonHelper.getUserImagebyId(props.clientUsers, localStorage.uid),
                    name: fromUser.name,
                    isAMSME: false,
                    id: localStorage.uid
                })
                messageInfoModel.participantInfo.push({
                    avatar: CommonHelper.getUserImagebyId(props.clientUsers, fromUser.id),
                    name: props.clientUsers.filter(a => a.id === fromUser.id).length ? props.clientUsers.filter(a => a.id === fromUser.id)[0].name : '',
                    isAMSME: false,
                    id: fromUser.id
                })
                messageInfoModel.lastMessage = { ...messageModel }
            }
            // console.log('ef1 messageModel', messageModel)
            // console.log('ef1 messageInfoModel', messageInfoModel)

            await messageRef.set(messageModel, { merge: true });
            const ref = window.firebase
                .firestore()
                .collection('messages')
                .doc(currentAddress)
            await ref.set(messageInfoModel, { merge: true });
            setLoader(false)
            props.handleClose();

        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    return fromUser ? <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-chatnotify-custom">
        <Modal.Header >
            <Modal.Title>
                <Translate text={`New Message from ${fromUser.name}`} />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="chatnotify-custom-wrap">
                <div>
                    <div className="chatnotify-owner">
                        <div className="chatnotify-owner-view"> <img src={CommonHelper.showUserAvatar(fromUser.profileImage, fromUser.name)} /></div>
                        <div>
                            <div>{fromUser.name}</div>
                            {
                                props?.notification?.addedDate?.seconds ? (
                                    <div className="chatnotify-time">{moment.unix(props.notification.addedDate.seconds).format('DD/MM/YYYY hh:mm A')}</div>
                                ) : (<div className="chatnotify-time">{moment().format('DD/MM/YYYY hh:mm A')}</div>)
                            }
                        </div>
                    </div>
                    <div className="chatnotify-text">{props?.notification?.body || ''}</div>
                </div>
            </div>
            <div className="chatnotify-reply-wrap">
                <h3> Reply</h3>
                <div className="chatnotify-notewrap form-style">
                    <textarea
                        className={`form-control notes ${error['notes']}`}
                        name={'newleadassignednotes'}
                        onChange={(e) => { e.preventDefault(); setReplyTxt(e.target.value); setError({}) }}
                        value={replyTxt}
                    >
                    </textarea>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">

            <button type="button"
                className={`btn btn-default float-left`}
                onClick={(e) => { e.preventDefault(); props.handleClose(); }} >
                <Translate text={'cancel'} />
            </button>
            <button type="button"
                className={`btn btn-primary float-right`}
                onClick={(e) => {
                    e.preventDefault();
                    handleReply();
                }}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={'Reply'} />
            </button>


        </Modal.Footer>
    </Modal > : (<></>)
}

export default ChatPopUp;