/** LIBRARIES */
import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import moment from "moment";
import Swal from 'sweetalert2';
/** PROVIDERS */
/** VIEW-MODELS */

/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { PopoverListLoader, ReactDatePicker, SidePanel } from '../../../components';

import { default as _images } from '../../../images';

import ContactListReducer from '../../contacts/contactlistReducer'
import { firestoreDB } from '../../../services/helper';
import QuickView from "../quickview/index";

const AppointmentList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [appointments, dispatch] = useReducer(ContactListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM-DD'))
    const [hasMore, setHasMoreData] = useState([])

    const [isPaging, setPaging] = useState(false)
    const [contactLoader, setContactLoader] = useState(true)
    const pageLimit = 100
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-service-quickview pac-container' })


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        //document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        const { dealersettings, appDate } = props;

        if (!checkDataLoad) {
            return;
        }

        let selectedDate = appDate ? appDate : searchDate ? searchDate : moment().format('YYYY-MM-DD');
        const startDate = window.firebase.firestore.Timestamp.fromDate(moment(selectedDate).startOf('day')._d);
        const endDate = window.firebase.firestore.Timestamp.fromDate(moment(selectedDate).endOf('day')._d);

        let contactlstIdxDataRef = firestoreDB(dealersettings).firestore().collection('serviceDailyRO')

        contactlstIdxDataRef = contactlstIdxDataRef
            .where('clientID', '==', dealersettings.client.id)
            .where('isDeleted', '==', false)
            .where('appointmentOn', '>=', startDate)
            .where('appointmentOn', '<=', endDate)
            .orderBy('appointmentOn', 'asc')


        if (_.trim(searchText) && _.trim(searchText).length > 2) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
            if (formattedSearchText.trim())
                contactlstIdxDataRef = contactlstIdxDataRef
                    .where('keywords', 'array-contains', formattedSearchText.trim().toLowerCase())
        }


        //contactlstIdxDataRef = contactlstIdxDataRef.orderBy('firstName', 'asc')

        if (hasMore.length > 0) {
            contactlstIdxDataRef = contactlstIdxDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            contactlstIdxDataRef = contactlstIdxDataRef
                .limit(pageLimit)
        }

        contactlstIdxDataRef
            //.onSnapshot(onCollectionUpdate);
            .get().then(querySnapshot => {
                const _appointments = [];

                if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                    setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
                }
                else {
                    setHasMoreData([]);
                }
                querySnapshot.docs.forEach((doc) => {
                    _appointments.push(convertContactVM({
                        ...doc.data(),
                        documentID: doc.id
                    }));
                });

                if (isPaging) {
                    dispatch({
                        type: "APPENDCONTACT",
                        data: _.orderBy(_appointments, ["status"], ["asc"]),
                    });
                }
                else {
                    dispatch({
                        type: "SUCCESS",
                        data: _.orderBy(_appointments, ["status"], ["asc"]),
                    });
                }

                setDataload(false)
                setContactLoader(false)
                setPaging(false)

            });
        //  return () => {
        //      window.unSubContactList();
        //  }

    }, [checkDataLoad])



    const convertContactVM = (doc) => {
        const objcontactData = Object.assign({}, doc);
        const { dealersettings } = props;
        let _appointment = Object.assign({});
        for (let [key, value] of Object.entries(objcontactData)) {
            _appointment[key] = value;
        }

        if (_appointment.clientID && (_appointment.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            _appointment.dealerName = CommonHelper.getOtherDealerName(dealersettings, _appointment.clientID);
        }

        let _contact = {
            title: CommonHelper.autoCaps(_appointment.title),
            firstName: _appointment.firstName,
            middleName: _appointment.middleName,
            lastName: _appointment.lastName,
            phone: _appointment.phone,
            businessContact: (_.isEmpty(_appointment.priv) || _appointment.priv === 'Y') ? false : true
        }
        Object.keys(_contact).forEach(key => _contact[key] === undefined ? delete _contact[key] : {});
        let _vehicle = {
            make: _appointment.make,
            model: _appointment.model,
            regNo: _appointment.regNo,
            rego: _appointment.regNo,
            vinNo: _appointment.vinNo,
            stockNo: _appointment.stockNo,
            regDate: _appointment.firstReg ? window.firebase.firestore.Timestamp.fromDate(moment(_appointment.firstReg)._d) : null,
        }
        Object.keys(_vehicle).forEach(key => _vehicle[key] === undefined ? delete _vehicle[key] : {});
        _appointment.contact = _contact;
        _appointment.vehicle = _vehicle;
        _appointment.isVehicle = true;

        return _appointment
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }


    const listenScrollEvent = (e) => {
        if (hasMore.length === 0) return;
        //console.log('listenScrollEvent', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)
        if (e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight) {
            //console.log('listenScrollEvent')
            //handlePagination();
        }
        return;
    }

    const handleSelect = (_appointment) => {
        if (_appointment.status !== 0) {
            Swal.fire('Job is already created for the selected appointment.', '', 'info')
            // Swal.fire({
            //     title: CommonHelper.showLocale(props, 'Job is already created for the selected appointment.'),
            //     text: CommonHelper.showLocale(props, 'Would you like to view job details?'),
            //     icon: 'info',
            //     showCancelButton: true,
            //     confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            //     cancelButtonText: CommonHelper.showLocale(props, 'No'),
            // }).then(result => {
            //     if (result.value) {
            //         viewJobDetails(_appointment.documentID);
            //     }
            // });
            return;
        }

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, `You want to create a job with the selected appointment`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {
                props.handleClose(props.status, _appointment);
            }
        });

    }

    const viewJobDetails = async (dailyroID, serviceID) => {

        if (serviceID) {
            sidepanelOpen(serviceID)
        }
        else {
            const enqDoc = await firestoreDB(props.dealersettings).firestore().collection(`serviceJobs`).where('dailyroID', '==', dailyroID).where('isDeleted', '==', false).limit(1).get();
            if (enqDoc.size > 0 && enqDoc.docs[0]?.id) {
                //props.history.push("/service/details/" + enqDoc.docs[0].id);
                sidepanelOpen(enqDoc.docs[0]?.id)
            }

            // Swal.fire({
            //     title: CommonHelper.showLocale(props, 'Are you sure?'),
            //     text: CommonHelper.showLocale(props, `You want to view job details?`),
            //     icon: 'info',
            //     showCancelButton: true,
            //     confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            //     cancelButtonText: CommonHelper.showLocale(props, 'No'),
            // }).then(async result => {
            //     if (result.value) {
            //     }
            // });
        }


    }

    const sidepanelClose = useCallback(() => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            serviceid: 0
        })
    }, [])

    const sidepanelOpen = useCallback((documentID) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            serviceid: documentID
        })
        //props.history.push("/service/details/" + documentID + '?back-url=/service');
    }, [])

    const handleDateChange = (value, name) => {
        setSearchDate(moment(value).format('YYYY-MM-DD'))
        setHasMoreData([]);
        setDataload(true)
        setContactLoader(true)
    }
    const dateFormat = val => {
        return CommonHelper.getdateFormat(val);
    };

    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative"
    };

    return (
        <>

            <Modal
                id="service-appointment-List"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog modal-dialog-centered modal-service-ro-list">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" >
                            <Translate text={props.title ? props.title : 'Select Appointment'} />
                            {props.appDate ? ' - ( ' + moment(props.appDate).format('DD MMM YYYY') + ' )' : ''}
                        </h5>
                    </Modal.Title>
                    {
                        !props.hideContinue && (<div className="ro-list-addbutton">
                            <button type="button" className="btn btn-secondary float-right" onClick={(e) => {
                                e.preventDefault();
                                props.handleClose(props.status);
                            }}><i className="ico icon-close"></i> <Translate text={'Skip & Continue'} /></button>
                        </div>)
                    }

                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className="ro-list-filterwrap mb-3">

                            <div className={`ro-list-search ${props.hideContinue ? 'w-100' : ''}`}>
                                <input
                                    type="text"
                                    aria-label="Search"
                                    placeholder="Search..."
                                    value={searchText ? searchText : ''}
                                    autoComplete="off"
                                    onChange={e => {
                                        let _value = e.target.value;
                                        if (!_value)
                                            setSearchText('');
                                        else
                                            setSearchText(_value);

                                        if (_.trim(_value) && _.trim(_value).length > 2)
                                            setContactLoader(true);

                                        setTimeout(() => {
                                            setHasMoreData([]);
                                            setDataload(true);
                                        }, 1000);


                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setSearchText(searchText)
                                            setHasMoreData([]);
                                            setDataload(true)
                                            setContactLoader(true)
                                        }
                                    }}
                                />
                            </div>
                            {
                                !props.hideContinue && (<div className="ro-list-datefilter form-style" title="Appointment Date">
                                    <div className="inner-addon left-addon"> <i className="bg-icons ico icon-date"></i>
                                        <ReactDatePicker
                                            value={searchDate ? searchDate : moment().format('YYYY-MM-DD')}
                                            name={'searchDate'}
                                            onChange={handleDateChange}
                                            format={dateFormat}
                                            placeholder='DD/MM/YYYY'
                                            className={`form-control pr-5`}
                                            isClearable={false}
                                            showMonthDropdown={false}
                                            showYearDropdown={false}
                                        />
                                    </div>

                                </div>)
                            }



                        </div>

                        <div style={divStyle} onScroll={listenScrollEvent}>
                            {
                                contactLoader ? (<PopoverListLoader />) :
                                    (appointments && appointments.length) > 0
                                        ?
                                        <>
                                            {appointments.map((appointment, index) =>
                                                <div key={appointment.documentID} id={appointment.documentID} className={`vehicle-popover-loop pb-0 ${appointment.status !== 0 ? 'overview-green' : ''}`} onClick={(e) => {
                                                    e.preventDefault();
                                                    if (appointment.status === 0)
                                                        handleSelect(appointment)

                                                }}>
                                                    {/* {
                                                        appointment.status !== 0
                                                            ?
                                                            <div className="mb-0"><div className="ribbon-wrapper-featured-appointment"><div className="featured"></div></div></div>
                                                            :
                                                            <></>
                                                    } */}


                                                    <div className="divTable">
                                                        <div className="divTableBody">
                                                            <div className="divTableRow">
                                                                <div className="divTableCell width100">

                                                                    <div className="flex-vehicle">

                                                                        <div className="vehicle-data">
                                                                            {
                                                                                appointment.status !== 0 && (<div className="quick-view-expand float-right" title="Go to detail view">
                                                                                    <a href="#" onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();
                                                                                        viewJobDetails(appointment.documentID, appointment.serviceID);
                                                                                    }}>
                                                                                        <i className="ico icon-expand" ></i>
                                                                                    </a>
                                                                                </div>)
                                                                            }



                                                                            {
                                                                                !_.isEmpty(appointment.contact)
                                                                                    ?
                                                                                    <>
                                                                                        <div className="vehicle-item-title mini-badge">
                                                                                            <i className='ico icon-Contacts mr-1'></i>
                                                                                            {CommonHelper.displayContactName(null, appointment.contact)}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }

                                                                            {
                                                                                (!_.isEmpty(appointment.vehicle))
                                                                                    ?
                                                                                    <>
                                                                                        {(() => {
                                                                                            const { vehicle } = appointment;
                                                                                            return <>
                                                                                                <div className="vehicle-item-title mini-badge">
                                                                                                    {(vehicle.make ? vehicle.make : '') + ' ' + (vehicle.model ? vehicle.model : '')}

                                                                                                </div>
                                                                                                <div className="vehicle-item-info"><Translate text={'reg'} upperCase={true} />#: {vehicle.rego ? vehicle.rego : (vehicle.regNo ? vehicle.regNo : '--')} </div>
                                                                                                {/* <div className="vehicle-item-info"><Translate text={'stock'} upperCase={true} />#: {vehicle.stockNo ? vehicle.stockNo : '--'} </div> */}
                                                                                                <div className="vehicle-item-info"><Translate text={'VIN'} upperCase={true} />#: {vehicle.vinNo ? vehicle.vinNo : '--'} </div>
                                                                                            </>
                                                                                        })()}
                                                                                    </>
                                                                                    :
                                                                                    <><div className="no-data-red"><Translate text={CommonHelper.showVehicleText(props.dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div></>
                                                                            }
                                                                            {
                                                                                appointment.appointmentOn
                                                                                    ?
                                                                                    <div className="mini-badge">
                                                                                        <Translate text={'appointmentDate'} />{': '}
                                                                                        {/* <i className="ico icon-timer mr-1 ml-1"></i> */}
                                                                                        {moment.unix(appointment.appointmentOn.seconds).format('DD MMM YYYY h:mm A')}
                                                                                    </div>
                                                                                    :
                                                                                    <></>
                                                                            }


                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}</>
                                        :
                                        <>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-cafe-img">
                                                        <i className="ico icon-service-settings"></i> </div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'noAppFound'} /></div>
                                                </div>

                                            </div>
                                        </>
                            }

                        </div>


                    </>
                </Modal.Body>
                {
                    hasMore && hasMore.length > 0 ? (
                        <Modal.Footer >
                            <div className="load-more-button pb-2"> <a href="#" className="mt-1" onClick={(e) => {
                                e.preventDefault();
                                handlePagination();
                            }}><Translate text={'Load More'} />
                                {
                                    !_.isEmpty(checkDataLoad) ? (
                                        <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                    ) : (<i className="fa fa-angle-down" aria-hidden="true"></i>)
                                }

                            </a></div>
                        </Modal.Footer>

                    ) : (<></>)
                }
            </Modal>

            <SidePanel
                clsActive={showpanel.clsActive}
                clsName={showpanel.clsName}
                sidepanelClose={sidepanelClose}
                title=''
            >
                <QuickView
                    {...props}
                    serviceid={showpanel.serviceid}
                    sidepanelClose={sidepanelClose}
                />
            </SidePanel>
        </>
    )
}

export default AppointmentList