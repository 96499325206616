import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { ContentOverlay, ReactSelect, AntDateRangePicker, ReactMultiSelect } from '../../../components'
import moment from 'moment'
import { combineLatest } from 'rxjs';
import { collectionData } from 'rxfire/firestore';
import images from '../../../images'
import NumberFormat from 'react-number-format'
import { Fragment } from 'react'
import CommonHelper from '../../../services/common'
import { ClientContext } from '../../layout/clientContext'
import { apisOptions } from '../pricing/viewModel';
import { collection, query, where, and, or, getFirestore, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import ReactExport from 'react-export-excel-xlsx-fix';
import { tableHeader } from './viewModel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]

const ApiLogs = (props) => {
    const [clientLoader, setClientLoader] = useState(true)
    const [loader, setLoader] = useState(true)
    const [selectedLevel, setSelectedLevel] = useState({})

    const [oemOptions, setOEMOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [allclient, setAllClient] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [dates, setDates] = useState([]);
    const [groupedApiLogs, setGroupedApiLogs] = useState([])
    const [allApiLogs, setAllApiLogs] = useState([])
    const [nestedTbl, setNestedTbl] = useState({});
    const [dateType, setDateType] = useState('thisMonth')
    const [selectedOEM, setSelectedOEM] = useState()
    const { oemSettings } = useContext(ClientContext);
    const [apiPrice, setApiPrice] = useState({})
    const [clientapiPrice, setClientApiPrice] = useState({})

    const [csvData, setCSVData] = useState([])
    const [sheetData, setSheetData] = useState([])
    const [sheet1, setSheet1] = useState()
    const [apiType, setApiType] = useState()
    const [objAmount, setObjAmount] = useState({})

    const [summatycsvData, setSummaryCSVData] = useState([])
    const [allsummaryLogs, setAllSummaryLogs] = useState([])
    const [summaryLogs, setSummaryLogs] = useState([])
    const [summaryAmount, setSummaryAmount] = useState({})
    const [tab, setTab] = useState('summary')

    const currencySymbol = '$ ';

    const dateOptions = [
        { active: true, value: 'today', label: 'Today' },
        { active: true, value: 'yesterday', label: 'Yesterday' },
        { active: true, value: 'thisWeek', label: 'This Week' },
        { active: true, value: 'lastWeek', label: 'Last Week' },
        { active: true, value: 'thisMonth', label: 'This Month' },
        { active: true, value: 'lastMonth', label: 'Last Month' },
        { active: true, value: 'thisQuarter', label: 'This Quarter' },
        { active: true, value: 'lastQuarter', label: 'Last Quarter' },
        { active: true, value: 'custom', label: 'Custom' }
    ]

    useEffect(() => {
        if (_.isEmpty(summaryLogs))
            return

        let _headers = [];
        let fontFamily = 'Calibri'

        let _headerStyle = {
            style: {
                font: {
                    sz: 10,
                    bold: false,
                    color: { rgb: "FFFFFF" },
                    name: fontFamily
                },
                fill: { patternType: "solid", fgColor: { rgb: "2a3042" } }
            }
        }
        let _cellStyle = {
            style: {
                font: {
                    sz: 10,
                    name: fontFamily
                },
            }
        };
        let _alternatecellStyle = {
            style: {
                font: {
                    sz: 10,
                    name: fontFamily
                },
                fill: { patternType: "solid", fgColor: { rgb: "f9f9f9" } },
            }
        };
        let _totalStyle = {
            style: {
                font: {
                    sz: 10,
                    name: fontFamily,
                    bold: true
                },
                fill: { patternType: "solid", fgColor: { rgb: "4466f2" } },
            }
        };

        tableHeader.forEach(rec => {
            _headers.push(
                {
                    title: rec.name,
                    dynValue: rec.value,
                    dynWidth: 10,
                    ..._headerStyle,
                    width: { wch: 25 }
                })
        })

        var _sheetData = []
        _.orderBy(summaryLogs, 'sortOrder', 'asc').forEach(alllogs => {
            let i = 1;
            let arrCols = []
            tableHeader.forEach(rec => {
                arrCols.push(
                    {
                        value: rec.currency ? `${currencySymbol}${Math.round((alllogs[rec.value] + Number.EPSILON) * 100) / 100}` : alllogs[rec.value],
                        ...(i % 2 === 0 ? _alternatecellStyle : _cellStyle),
                        dynValue: rec.value,
                        dynWidth: alllogs[rec.value] ? alllogs[rec.value].toString().length : 10
                    }
                )
            })
            i++;
            _sheetData.push(arrCols)
        })
        //subtotal
        const subtotal = _.sumBy(summaryLogs, (rec) => parseFloat(rec.totalAmount));
        const gst = 0.1 * subtotal; // Assuming GST is 10% of the Subtotal
        const total = subtotal + gst;
        _sheetData.push(emptyRow())
        _sheetData.push(getTotal('Subtotal', `${currencySymbol}${Math.round((subtotal + Number.EPSILON) * 100) / 100}`, _alternatecellStyle))
        _sheetData.push(getTotal('GST', `${currencySymbol}${Math.round((gst + Number.EPSILON) * 100) / 100}`, _alternatecellStyle))
        _sheetData.push(getTotal('Total Amount', `${currencySymbol}${Math.round((total + Number.EPSILON) * 100) / 100}`, _totalStyle));


        _sheetData.push(getDealer(`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`))

        setSummaryCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [..._sheetData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
        }])

    }, [summaryLogs])

    useEffect(() => {
        if (_.isEmpty(groupedApiLogs))
            return

        let _headers = [];
        let fontFamily = 'Calibri'

        let _headerStyle = {
            style: {
                font: {
                    sz: 10,
                    bold: false,
                    color: { rgb: "FFFFFF" },
                    name: fontFamily
                },
                fill: { patternType: "solid", fgColor: { rgb: "2a3042" } }
            }
        }
        let _cellStyle = {
            style: {
                font: {
                    sz: 10,
                    name: fontFamily
                },
            }
        };
        let _alternatecellStyle = {
            style: {
                font: {
                    sz: 10,
                    name: fontFamily
                },
                fill: { patternType: "solid", fgColor: { rgb: "f9f9f9" } },
            }
        };
        let _totalStyle = {
            style: {
                font: {
                    sz: 10,
                    name: fontFamily,
                    bold: true
                },
                fill: { patternType: "solid", fgColor: { rgb: "4466f2" } },
            }
        };

        tableHeader.forEach(rec => {
            _headers.push(
                {
                    title: rec.name,
                    dynValue: rec.value,
                    dynWidth: 10,
                    ..._headerStyle,
                    width: { wch: 25 }
                })
        })

        let allSheetData = {};
        groupedApiLogs.forEach(alllogs => {
            if (alllogs.logs.length > 0) {
                var sheetData = []
                let i = 1;
                alllogs.logs.forEach(obj => {
                    let arrCols = []
                    tableHeader.forEach(rec => {
                        arrCols.push(
                            {
                                value: rec.currency ? `${currencySymbol}${Math.round((obj[rec.value] + Number.EPSILON) * 100) / 100}` : obj[rec.value],
                                ...(i % 2 === 0 ? _alternatecellStyle : _cellStyle),
                                dynValue: rec.value,
                                dynWidth: obj[rec.value] ? obj[rec.value].toString().length : 10
                            }
                        )
                    })
                    i++;
                    sheetData.push(arrCols)
                })
                //subtotal
                const subtotal = _.sumBy(alllogs.logs, (rec) => parseFloat(rec.totalAmount));
                const gst = 0.1 * subtotal; // Assuming GST is 10% of the Subtotal
                const total = subtotal + gst;

                sheetData.push(getTotal('Subtotal', `${currencySymbol}${Math.round((subtotal + Number.EPSILON) * 100) / 100}`, _alternatecellStyle))
                sheetData.push(getTotal('GST', `${currencySymbol}${Math.round((gst + Number.EPSILON) * 100) / 100}`, _alternatecellStyle))
                sheetData.push(getTotal('Total Amount', `${currencySymbol}${Math.round((total + Number.EPSILON) * 100) / 100}`, _totalStyle));


                sheetData.push(getDealer(_.find(clientOptions, { value: alllogs.value })?.label))
                sheetData.push(getDealer(`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`))

                allSheetData[alllogs.value] = [{
                    columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
                    data: [...sheetData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
                }]
            }
        })

        let allKeys = Object.keys(allSheetData);
        setSheet1(allKeys[0])
        setCSVData(allSheetData[allKeys[0]])
        if (allKeys.slice(1, allKeys.length).length > 0) {
            //delete allSheetData[allKeys[0]]
            setSheetData(allSheetData)
        }

    }, [groupedApiLogs])

    const emptyRow = () => {
        let arrCols = []
        arrCols.push(
            {
                value: '',
                dynValue: 'type',
            }
        )
        arrCols.push(
            {
                value: '',
                dynValue: 'totalQty',
            }
        )
        arrCols.push(
            {
                value: '',
                dynValue: 'unitPrice',
            }
        )
        arrCols.push(
            {
                value: '',
                dynValue: 'totalAmount',
            }
        )
        return arrCols;
    }

    const getTotal = (title, value, style) => {
        let arrCols = []
        arrCols.push(
            {
                value: '',
                dynValue: 'type',
            }
        )
        arrCols.push(
            {
                value: '',
                dynValue: 'totalQty',
            }
        )
        arrCols.push(
            {
                value: title,
                dynValue: 'unitPrice',
                ...style
            }
        )
        arrCols.push(
            {
                value: value,
                dynValue: 'totalAmount',
                ...style
            }
        )
        return arrCols;
    }

    const getDealer = (val) => {
        let arrCols = []
        arrCols.push(
            {
                value: val,
                dynValue: 'type',
            }
        )
        arrCols.push(
            {
                value: '',
                dynValue: 'totalQty',
            }
        )
        arrCols.push(
            {
                value: '',
                dynValue: 'unitPrice'
            }
        )
        arrCols.push(
            {
                value: '',
                dynValue: 'totalAmount'
            }
        )
        return arrCols;
    }

    useEffect(() => {
        if (_.isEmpty(selectedOEM))
            return
        const fetchOptions = async () => {
            let _regions = [];
            let _groups = [];

            const regionSnapshots = await window.firebase.firestore()
                .collection(`clientSettings`)
                .doc(selectedOEM.id)
                .collection(`regions`)
                .get()
            regionSnapshots.docs.forEach(rec => {
                _regions.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })

            const groupSnapshots = await window.firebase.firestore()
                .collection(`clientSettings`)
                .doc(selectedOEM.id)
                .collection(`groups`)
                .get()
            groupSnapshots.docs.forEach(rec => {
                _groups.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })

            setRegionOptions(_regions)
            setGroupOptions(_groups)
            setClientOptions(allclient.filter(a => a?.data?.settingsID === selectedOEM.id))
            //setClientLoader(false)
        }

        fetchOptions()
    }, [selectedOEM])

    useEffect(() => {
        const fetchOptions = async () => {
            let _clients = [];


            const app = initializeApp(window.firebase.app().options);
            const db = getFirestore(app);
            // console.log(db);
            const snaps = query(collection(db, "clients"), or(
                and(
                    where('moduleSettings.autograb.active', '==', true),
                    where('moduleSettings.autograb.enabled', '==', true)
                ),
                and(
                    where('moduleSettings.ppsr.active', '==', true),
                    where('moduleSettings.ppsr.enabled', '==', true)
                )
            ));

            const querySnapshot = await getDocs(snaps);
            querySnapshot.forEach((doc) => {
                _clients.push({
                    label: doc.data().name,
                    value: doc.id,
                    data: doc.data()
                })
            });

            let oemPromise = [];
            let _oemSettings = [];
            _.uniqBy(_clients, 'data.settingsID').map(rec => {
                oemPromise.push(window.firebase.firestore().doc(`clientSettings/${rec.data.settingsID}`).get())
            })

            const snapshots = await Promise.all(oemPromise)
            snapshots.forEach(snap => {
                const dataDoc = snap.data()
                if (snap.exists) {
                    _oemSettings.push({
                        label: dataDoc.name,
                        value: snap.id,
                        data: {
                            ...dataDoc,
                            id: snap.id
                        }
                    })
                }
            });
            setOEMOptions(_.orderBy(_oemSettings, ['label'], ['asc']));


            const autograbDoc = await window.firebase.firestore().collection(`integrations`).where("type", "==", 'autograb')
                .limit(1).get()
                .then(async snapshot => {
                    if (snapshot && snapshot.size > 0) {
                        return snapshot.docs[0].data()
                    }
                    else return null;
                });

            const ppsrDoc = await window.firebase.firestore().collection(`integrations`).where("type", "==", 'ppsr')
                .limit(1).get()
                .then(async snapshot => {
                    if (snapshot && snapshot.size > 0) {
                        return snapshot.docs[0].data()
                    }
                    else return null;
                });

            let priceLog = {};
            if (autograbDoc?.price) {
                priceLog = {
                    ...priceLog,
                    ...autograbDoc.price
                }
            }
            if (ppsrDoc?.price) {
                priceLog = {
                    ...priceLog,
                    ...ppsrDoc.price
                }
            }
            let logPromise = [];
            let _clientPrice = [];
            _clients.forEach(rec => {
                logPromise.push(window.firebase.firestore().doc(`clients/${rec.value}/modulePricing/${rec.value}`).get());
            })

            const clientPriceSnap = await Promise.all(logPromise);
            clientPriceSnap.forEach(snap => {
                if (snap.exists)
                    _clientPrice.push({
                        ...snap.data().autograb,
                        ...snap.data().ppsr,
                        id: snap.id
                    })
            })
            setClientApiPrice(_clientPrice)
            setApiPrice(priceLog);
            setClientOptions(_clients)
            setAllClient(_clients)
            setClientLoader(false)
        }
        fetchOptions()

    }, [])

    useEffect(() => {
        if (!loader || clientLoader) {
            return
        }

        let logPromise = [];
        let clients = clientOptions;
        if (selectedLevel.level === 'region') {
            clients = clientOptions.filter(a => a.data.region === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'group') {
            clients = clientOptions.filter(a => a.data.group === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'individual') {
            clients = clientOptions.filter(a => a.value === selectedLevel.levelID);
        }

        clients.forEach(c => {
            logPromise.push(collectionData(window.firebase.firestore().collection('autoGrabLogs')
                .where('clientID', '==', c.value)
                .where('logDate', '>=', dateRange.startDate)
                .where('logDate', '<=', dateRange.endDate)
                .orderBy('logDate', 'desc')))
        })

        clients.forEach(c => {
            logPromise.push(collectionData(window.firebase.firestore().collection('ppsr')
                .where('clientID', '==', c.value)
                .where('logDate', '>=', dateRange.startDate)
                .where('logDate', '<=', dateRange.endDate)
                .orderBy('logDate', 'desc')))
        })

        var unsubApiLogs = combineLatest(logPromise)
            .subscribe(allDocs => {
                let logs = [];
                allDocs.forEach(docs => {
                    logs = [
                        ...logs,
                        ...docs
                    ]
                });
                logs = [
                    ...logs,
                    ...logs.filter(a => a.type === 'vehicleDetail').map(r => { return { ...r, type: 'vehicleUpstream' } })
                ]
                let clientLogs = _.chain(logs.filter(a => a.fetchFrom !== 'integration'))
                    .groupBy("clientID")
                    .map((value, key) => ({
                        value: key,
                        name: _.find(clientOptions, { value: key })?.label,
                        oemID: _.find(clientOptions, { value: key })?.data?.settingsID,
                        groupID: _.find(clientOptions, { value: key })?.data?.group,
                        regionID: _.find(clientOptions, { value: key })?.data?.region,
                        logs: _.chain(value)
                            .groupBy("type")
                            .map((value, key) => ({
                                key,
                                clientID: value[0]?.clientID,
                                type: _.find(apisOptions, { value: key })?.['name'] || key,
                                sortOrder: _.find(apisOptions, { value: key })?.['index'] || 0,
                                totalQty: value.length,
                                unitPrice: _.find(clientapiPrice, { id: value[0]?.clientID })?.[key] || apiPrice[key] || 0,
                                totalAmount: (value.length * (_.find(clientapiPrice, { id: value[0]?.clientID })?.[key] || apiPrice[key] || 0)),
                            }))
                            .value()
                    }))
                    .value();
                let allLogs = [];
                clientLogs.forEach(rec => {
                    allLogs.push({
                        ...rec,
                        totalQty: _.sumBy(rec.logs, 'totalQty'),
                        totalAmount: _.sumBy(rec.logs, 'totalAmount')
                    })
                });
                if (clientLogs.length > 0) {
                    setNestedTbl({
                        ...nestedTbl,
                        [clientLogs[0].value]: true
                    })
                }
                const _summaryLogs = _.chain(logs.filter(a => a.fetchFrom !== 'integration'))
                    .groupBy("type")
                    .map((value, key) => ({
                        value: key,
                        type: _.find(apisOptions, { value: key })?.['name'] || key,
                        sortOrder: _.find(apisOptions, { value: key })?.['index'] || 0,
                        totalQty: value.length,
                        unitPrice: _.find(apisOptions, { value: key })?.['amsPrice'] || 0,
                        totalAmount: (value.length * (_.find(apisOptions, { value: key })?.['amsPrice'] || 0)),
                        clients: _.chain(value)
                            .groupBy("clientID")
                            .map((cvalue, ckey) => ({
                                value: key,
                                clientID: ckey,
                                name: _.find(clientOptions, { value: ckey })?.label,
                                oemID: _.find(clientOptions, { value: ckey })?.data?.settingsID,
                                groupID: _.find(clientOptions, { value: ckey })?.data?.group,
                                regionID: _.find(clientOptions, { value: ckey })?.data?.region,
                                type: _.find(apisOptions, { value: key })?.['name'] || key,
                                sortOrder: _.find(apisOptions, { value: key })?.['index'] || 0,
                                totalQty: cvalue.length,
                                unitPrice: _.find(clientapiPrice, { id: ckey })?.[key] || apiPrice[key] || 0,
                                totalAmount: (cvalue.length * (_.find(clientapiPrice, { id: ckey })?.[key] || apiPrice[key] || 0)),
                            }))
                            .value()
                    })).value();
                setGroupedApiLogs(allLogs);
                setAllApiLogs(allLogs);
                setAllSummaryLogs(_summaryLogs)
                //setSummaryLogs(_summaryLogs)
                setLoader(false)
            });
        return () => {
            unsubApiLogs && unsubApiLogs.unsubscribe();
        }
    }, [loader, clientLoader])


    useEffect(() => {
        if (_.isEmpty(allApiLogs))
            return
        const _logs = allApiLogs.filter(({ oemID, groupID, regionID, value }) => (
            (selectedLevel?.level === 'oem' ? oemID === selectedOEM?.id :
                selectedLevel?.level === 'region' && selectedLevel?.levelID ? regionID === selectedLevel?.levelID :
                    selectedLevel?.level === 'group' && selectedLevel?.levelID ? groupID === selectedLevel?.levelID :
                        selectedLevel?.level === 'individual' && selectedLevel?.levelID ? value === selectedLevel?.levelID :
                            !_.isEmpty(selectedLevel) ? false :
                                true
            )))
        setGroupedApiLogs(_logs.map(r => {
            const _filterLogs = r.logs.filter(a => !_.isEmpty(apiType) ? apiType.includes(a.key) : true)
            return {
                ...r,
                logs: _filterLogs,
                totalQty: _.sumBy(_filterLogs, 'totalQty'),
                totalAmount: _.sumBy(_filterLogs, 'totalAmount'),
            }
        }));
        if (_logs.length > 0) {
            setNestedTbl({
                [_logs[0].value]: true
            })
        }
    }, [selectedOEM, selectedLevel, apiType])

    useEffect(() => {
        if (_.isEmpty(allsummaryLogs))
            return

        setSummaryLogs(allsummaryLogs.filter(a => !_.isEmpty(apiType) ? apiType.includes(a.value) : true).map(r => {
            const _filterLogs = r.clients.filter(({ oemID, groupID, regionID, clientID }) => (
                (selectedLevel?.level === 'oem' ? oemID === selectedOEM?.id :
                    selectedLevel?.level === 'region' && selectedLevel?.levelID ? regionID === selectedLevel?.levelID :
                        selectedLevel?.level === 'group' && selectedLevel?.levelID ? groupID === selectedLevel?.levelID :
                            selectedLevel?.level === 'individual' && selectedLevel?.levelID ? clientID === selectedLevel?.levelID :
                                !_.isEmpty(selectedLevel) ? false :
                                    true
                )))
            return {
                ...r,
                clients: _filterLogs,
                totalQty: _.sumBy(_filterLogs, 'totalQty'),
                totalAmount: _.sumBy(_filterLogs, 'totalAmount'),
            }
        }));
    }, [selectedOEM, selectedLevel, allsummaryLogs, apiType])

    useEffect(() => {
        const subtotal = _.sumBy(groupedApiLogs, (rec) => parseFloat(rec.totalAmount));
        const gst = 0.1 * subtotal; // Assuming GST is 10% of the Subtotal
        const total = subtotal + gst;
        const amountDue = total;
        setObjAmount({
            subtotal,
            gst,
            total,
            amountDue
        })

    }, [groupedApiLogs])

    useEffect(() => {
        const subtotal = _.sumBy(summaryLogs, (rec) => parseFloat(rec.totalAmount));
        const gst = 0.1 * subtotal; // Assuming GST is 10% of the Subtotal
        const total = subtotal + gst;
        const amountDue = total;
        setSummaryAmount({
            subtotal,
            gst,
            total,
            amountDue
        })

    }, [summaryLogs])


    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }

    const handleDateTypeChange = (e, data) => {
        let state = {};
        if (e) {
            state['type'] = e.value;
            state['date'] = e.value;
            const _dateRange = CommonHelper.handleDateRangeFilter({ date: state });
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment(_dateRange.startDate).startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment(_dateRange.endDate).endOf('day')._d)
            })
            setLoader(true);
            setDateType(e.value)
        }
    }

    return (
        <>

            <div className="settings-container">
                <div className="settings-container-sub">

                    <h2>APIs <div className="float-right">
                        {/* {
                            allApiLogs.filter(a => a.logs.length > 0).length > 0 ? (
                                <div className="filter-item-btn ml-2 float-right">
                                    <ExcelFile element={<button className='btn btn-primary' type='button'>Summary</button>} filename={`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`}>
                                        <ExcelSheet dataSet={summatycsvData} name={'Summary'} />
                                    </ExcelFile>
                                </div>
                            ) : (<></>)
                        } */}
                        <div class="tabber-nav">
                            <ul>
                                <li><a onClick={(e) => { e.preventDefault(); setTab('dealer') }} href="#" class={tab === 'dealer' ? 'active' : ''}>Dealer</a></li>
                                <li><a onClick={(e) => { e.preventDefault(); setTab('summary') }} href="#" class={tab === 'summary' ? 'active' : ''}>Summary</a></li>
                            </ul>
                        </div>
                    </div></h2>
                    <div className="divider-line"></div>

                    <div className="settings-api-billing-wrap mt-3">

                        <div className="settings-head form-style">
                            <div className="row">
                                <div className="col-md-12 settings-api-billing-padding">
                                    <div className="row">
                                        <div className="col-md-1 pr-0">
                                            <ReactSelect
                                                options={dateOptions}
                                                id={"date-type"}
                                                name={"date"}
                                                placeholder={'select date option'}
                                                onChange={handleDateTypeChange}
                                                value={dateType}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="col-md-2 pr-0">
                                            <div className="form-group">
                                                {/* <label for="">Date</label> */}
                                                <AntDateRangePicker
                                                    value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                                    id={"dashboard-date-range"}
                                                    name={'billing-date'}
                                                    format='DD/MM/YYYY'
                                                    onChange={(e) => { handleDateRangeChange(e, 'billing-date') }}
                                                    placeholder='DD/MM/YYYY'
                                                    onCalendarChange={(value) => {
                                                        if (value) {
                                                            const [start, end] = value;
                                                            setDates([start, end]);
                                                            //setLoader(true);
                                                        }
                                                        else {
                                                            setDates([])
                                                        }
                                                    }}
                                                    className={'ant-picker-billing'}
                                                //disabledDate={disabledDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1 pr-0">
                                            <button disabled={loader} type='button' className='btn btn-primary' onClick={(e) => {
                                                e.preventDefault();
                                                setLoader(true)
                                            }}>
                                                <i className="ico icon-search mt-1 mr-2"></i>
                                                Search
                                            </button>
                                        </div>

                                        <div className="col-md-2 pr-0">
                                            <ReactMultiSelect
                                                options={apisOptions.map(r => {
                                                    return {
                                                        label: r.name,
                                                        value: r.value
                                                    }
                                                })}
                                                id={"api-type"}
                                                name={"api"}
                                                placeholder={'select api'}
                                                onChange={(selectedOptions) => {
                                                    const value = [];
                                                    !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                        value.push(data.value)
                                                    })
                                                    setApiType(value)
                                                }}
                                                value={apiType || []}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        <div className="col-md-2 pr-0">
                                            <ReactSelect
                                                options={oemOptions}
                                                name={"oem"}
                                                placeholder={'select oem'}
                                                onChange={(e) => {
                                                    //setLoader(true)
                                                    //setClientLoader(true)
                                                    setSelectedOEM(e ? e.data : null)
                                                    setSelectedLevel(e ? { level: 'oem' } : {})
                                                }}
                                                value={!_.isEmpty(selectedOEM) ? selectedOEM.id : ''}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={false}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="col-md-1 pr-0">
                                            <div className="form-group">
                                                {/* <label for="">Level</label> */}
                                                <ReactSelect
                                                    options={levelOptions}
                                                    name={"level"}
                                                    placeholder={'select level'}
                                                    onChange={(e, data) => {
                                                        if (e) {
                                                            // if (e.value === 'oem')
                                                            //     setLoader(true)
                                                            setSelectedLevel(e ? {
                                                                level: e.value
                                                            } : {});
                                                            setGroupedApiLogs([])
                                                            setNestedTbl({})

                                                        }
                                                        else
                                                            setSelectedLevel({});
                                                    }}
                                                    value={selectedLevel ? selectedLevel.level : ''}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={false}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        <div className="col-md-2 pr-0">
                                            <div className="form-group">
                                                {/* <label for="">Dealer</label> */}
                                                {
                                                    (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ?
                                                        (
                                                            // <div className="col-md-3 pr-0">
                                                            <ReactSelect
                                                                options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                                                name={'levelID'}
                                                                placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                                                onChange={(e, data) => {
                                                                    if (e) {
                                                                        //setLoader(true)
                                                                        setSelectedLevel({
                                                                            ...selectedLevel,
                                                                            levelID: e.value
                                                                        });
                                                                    }
                                                                    else
                                                                        setSelectedLevel({});
                                                                }}
                                                                value={selectedLevel ? selectedLevel.levelID : ''}
                                                                classNamePrefix={`basic-select`}
                                                                removeClearable={false}
                                                            >
                                                            </ReactSelect>
                                                            //  </div>
                                                        ) : (<></>)
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-1">
                                            {
                                                groupedApiLogs.filter(a => a.logs.length > 0).length > 0 ? (
                                                    <div className="filter-item-btn ml-2 float-right">
                                                        <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i></a>} filename={`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`}>
                                                            <ExcelSheet dataSet={summatycsvData} name={'Summary'} />
                                                            {
                                                                Object.keys(sheetData).map((rec, index) => {
                                                                    return <ExcelSheet key={index} dataSet={sheetData[rec]} name={CommonHelper.removeUnicodeChars(_.find(clientOptions, { value: rec })?.label)} />
                                                                })
                                                            }
                                                        </ExcelFile>
                                                    </div>
                                                ) : (<></>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={tab === 'dealer' ? '' : 'hide'}>
                                {
                                    loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) :
                                        groupedApiLogs.filter(a => a.logs.length > 0).length > 0 ? (<>
                                            <div className="api-billing-table">
                                                <table className="table table-striped">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col" className="head-light" width="5%">#</th>
                                                            <th scope="col" className="head-light" width="35%">API</th>
                                                            <th scope="col" className="head-light" width="20%">Usage/Qty</th>
                                                            <th scope="col" className="head-light text-right" width="20%">Unit Cost</th>
                                                            <th scope="col" className="head-light text-right" width="20%">Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {groupedApiLogs.filter(a => a.logs.length > 0).map((rec, index) => (<Fragment key={index}>
                                                            {
                                                                groupedApiLogs.length > 1 || selectedLevel.level !== 'individual' ? (
                                                                    <>
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>
                                                                                <div className="dealership-tbl cursor-pointer position-relative"
                                                                                    onClick={() => {
                                                                                        setNestedTbl({
                                                                                            ...nestedTbl,
                                                                                            [rec.value]: nestedTbl[rec.value] && !_.isEmpty(nestedTbl[rec.value].toString()) ? !nestedTbl[rec.value] : true
                                                                                        })
                                                                                    }}>
                                                                                    {rec.name}
                                                                                </div>
                                                                            </td>
                                                                            <td className="usage-blue">{_.sumBy(rec.logs, 'totalQty')}</td>
                                                                            <td></td>
                                                                            <td className="text-right">
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    prefix={currencySymbol}
                                                                                    displayType={'text'}
                                                                                    value={rec.totalAmount.toFixed(2)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr className={`tr-hover-fix ${nestedTbl[rec.value] === true ? '' : 'hide'}`}>
                                                                            <td colSpan='5' style={{ padding: ` 0.5rem !important` }}>
                                                                                <div className="api-billing-table">
                                                                                    <table className="table table-striped">
                                                                                        <tbody>
                                                                                            {
                                                                                                _.orderBy(rec.logs, 'sortOrder', 'asc').map((r, i) => {
                                                                                                    return <tr key={i}>
                                                                                                        <td width="5%">{i + 1}</td>
                                                                                                        <td width="35%">{r.type}</td>
                                                                                                        <td width="20%" className="usage-blue">{r.totalQty}</td>
                                                                                                        <td width="20%" className="text-right">
                                                                                                            <NumberFormat
                                                                                                                thousandSeparator={true}
                                                                                                                prefix={currencySymbol}
                                                                                                                displayType={'text'}
                                                                                                                value={r.unitPrice}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td width="20%" className="text-right">
                                                                                                            <NumberFormat
                                                                                                                thousandSeparator={true}
                                                                                                                prefix={currencySymbol}
                                                                                                                displayType={'text'}
                                                                                                                value={r.totalAmount.toFixed(2)}
                                                                                                            />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ) : (<>
                                                                    {
                                                                        _.orderBy(rec.logs, 'sortOrder', 'asc').map((r, i) => {
                                                                            return <tr key={i}>
                                                                                <td width="5%">{i + 1}</td>
                                                                                <td width="35%">{r.type}</td>
                                                                                <td width="20%" className="usage-blue">{r.totalQty}</td>
                                                                                <td width="20%" className="text-right">
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        prefix={currencySymbol}
                                                                                        displayType={'text'}
                                                                                        value={r.unitPrice}
                                                                                    />
                                                                                </td>
                                                                                <td width="20%" className="text-right">
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        prefix={currencySymbol}
                                                                                        displayType={'text'}
                                                                                        value={r.totalAmount.toFixed(2)}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </>
                                                                )
                                                            }

                                                        </Fragment>))}

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-5">
                                                </div>
                                                <div className="col-lg-4 col-sm-5 ml-auto">
                                                    <table className="table table-clear">
                                                        <tbody>
                                                            <tr>
                                                                <td className="left">
                                                                    <strong>Subtotal</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={objAmount?.subtotal?.toFixed(2)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="left">
                                                                    <strong>GST 10%</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={objAmount?.gst?.toFixed(2)}
                                                                    />
                                                                </td>

                                                            </tr>

                                                            <tr>
                                                                <td className="left">
                                                                    <strong>Total</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={objAmount?.total?.toFixed(2)}
                                                                    />
                                                                </td>
                                                            </tr>

                                                            <tr className="billing-total-blue">
                                                                <td className="left">
                                                                    <strong>Total Amount ({currencySymbol.trim()})</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <strong>
                                                                        <NumberFormat
                                                                            thousandSeparator={true}
                                                                            prefix={currencySymbol}
                                                                            displayType={'text'}
                                                                            value={objAmount?.amountDue?.toFixed(2)}
                                                                        />
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>) : ((
                                            <div className="common-table" style={{ height: `${window.innerHeight - 340}px` }}>
                                                <div className='h-100  w-100'>
                                                    <div className='spinner-loader h-100'>
                                                        <div className='d-flex h-100 justify-content-center align-items-center text-primary' style={{ flexDirection: 'column' }}>
                                                            <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                                                            <div className="no-data-txt mt-2">
                                                                {
                                                                    !_.isEmpty(selectedLevel) && selectedLevel?.level !== 'oem' && _.isEmpty(selectedLevel?.levelID) ? (
                                                                        <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>

                                                                    ) : (
                                                                        <p>{`No data found`}</p>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                            <div className={tab === 'summary' ? '' : 'hide'}>
                                {
                                    loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) :
                                        summaryLogs.filter(a => a.clients.length > 0).length > 0 ? (<>
                                            <div className="api-billing-table">
                                                <table className="table table-striped">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col" className="head-light" width="5%">#</th>
                                                            <th scope="col" className="head-light" width="35%">API</th>
                                                            <th scope="col" className="head-light" width="20%">Usage/Qty</th>
                                                            <th scope="col" className="head-light text-right" width="20%">Unit Cost</th>
                                                            <th scope="col" className="head-light text-right" width="20%">Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            _.orderBy(summaryLogs, 'sortOrder', 'asc').map((r, i) => {
                                                                return <>
                                                                    <tr key={i}>
                                                                        <td width="5%">{i + 1}</td>
                                                                        <td width="35%">
                                                                            <div className="dealership-tbl cursor-pointer position-relative"
                                                                                onClick={() => {
                                                                                    setNestedTbl({
                                                                                        ...nestedTbl,
                                                                                        [r.value]: nestedTbl[r.value] && !_.isEmpty(nestedTbl[r.value].toString()) ? !nestedTbl[r.value] : true
                                                                                    })
                                                                                }}>
                                                                                {r.type}
                                                                            </div>
                                                                        </td>
                                                                        <td width="20%" className="usage-blue">{r.totalQty}</td>
                                                                        <td width="20%" className="text-right">
                                                                            {/* <NumberFormat
                                                                                thousandSeparator={true}
                                                                                prefix={currencySymbol}
                                                                                displayType={'text'}
                                                                                value={r.unitPrice}
                                                                            /> */}
                                                                        </td>
                                                                        <td width="20%" className="text-right">
                                                                            <NumberFormat
                                                                                thousandSeparator={true}
                                                                                prefix={currencySymbol}
                                                                                displayType={'text'}
                                                                                value={r.totalAmount.toFixed(2)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr className={`tr-hover-fix ${nestedTbl[r.value] === true ? '' : 'hide'}`}>
                                                                        <td colSpan='5' style={{ padding: ` 0.5rem !important` }}>
                                                                            <div className="api-billing-table">
                                                                                <table className="table table-striped">
                                                                                    <tbody>
                                                                                        {
                                                                                            _.orderBy(r.clients, 'name', 'asc').map((r, i) => {
                                                                                                return <tr key={i}>
                                                                                                    <td width="5%">{i + 1}</td>
                                                                                                    <td width="35%">{r.name}</td>
                                                                                                    <td width="20%" className="usage-blue">{r.totalQty}</td>
                                                                                                    <td width="20%" className="text-right">
                                                                                                        <NumberFormat
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={currencySymbol}
                                                                                                            displayType={'text'}
                                                                                                            value={r.unitPrice}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td width="20%" className="text-right">
                                                                                                        <NumberFormat
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={currencySymbol}
                                                                                                            displayType={'text'}
                                                                                                            value={r.totalAmount.toFixed(2)}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-5">
                                                </div>
                                                <div className="col-lg-4 col-sm-5 ml-auto">
                                                    <table className="table table-clear">
                                                        <tbody>
                                                            <tr>
                                                                <td className="left">
                                                                    <strong>Subtotal</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={summaryAmount?.subtotal?.toFixed(2)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="left">
                                                                    <strong>GST 10%</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={summaryAmount?.gst?.toFixed(2)}
                                                                    />
                                                                </td>

                                                            </tr>

                                                            <tr>
                                                                <td className="left">
                                                                    <strong>Total</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={summaryAmount?.total?.toFixed(2)}
                                                                    />
                                                                </td>
                                                            </tr>

                                                            <tr className="billing-total-blue">
                                                                <td className="left">
                                                                    <strong>Total Amount ({currencySymbol.trim()})</strong>
                                                                </td>
                                                                <td className="text-right">
                                                                    <strong>
                                                                        <NumberFormat
                                                                            thousandSeparator={true}
                                                                            prefix={currencySymbol}
                                                                            displayType={'text'}
                                                                            value={summaryAmount?.amountDue?.toFixed(2)}
                                                                        />
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>) : ((
                                            <div className="common-table" style={{ height: `${window.innerHeight - 340}px` }}>
                                                <div className='h-100  w-100'>
                                                    <div className='spinner-loader h-100'>
                                                        <div className='d-flex h-100 justify-content-center align-items-center text-primary' style={{ flexDirection: 'column' }}>
                                                            <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                                                            <div className="no-data-txt mt-2">
                                                                {
                                                                    !_.isEmpty(selectedLevel) && selectedLevel?.level !== 'oem' && _.isEmpty(selectedLevel?.levelID) ? (
                                                                        <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>

                                                                    ) : (
                                                                        <p>{`No data found`}</p>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApiLogs