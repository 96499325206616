import React, { useState, useEffect, useCallback, useRef, useReducer, useMemo, useContext } from 'react';
import moment from 'moment';
import { default as _images } from '../../../images';
import { Tab, Nav } from 'react-bootstrap';
import ActivityTag from '../../pipeline/details/activityTag'
import Translate from '../../../constants/translate';
import { enquiryActivities, enquirylogscompleted } from '../viewModel';
import CustomerEditLog from '../../pipeline/quickview/editLogs'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ContentOverlay, PopUpModal } from '../../../components'
import CommonHelper from '../../../services/common'
import _ from 'lodash'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection, doc } from 'rxfire/firestore';
import TemplatePreview from '../../settings/workflow/triggers/previewTemplate'
import { firestoreDB } from '../../../services/helper';

const AcitivitiesReducer = (state, action) => {
    function cancelEditable() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.notes = action.data.oldnotes
                item.callDuration = action.data.oldcallDuration
                item.callType = action.data.oldcallType
                item.callStatus = action.data.oldcallStatus
                item.addedDate = action.data.oldaddedDate
                return item;
            }
            return item;
        });
    }
    function updatechangelogs() {
        return state.map((item, index) => {
            if (item.documentID === action.id) {
                item[action.field] = action.value;
                return item;
            }
            return item;
        });
    }

    function updateCustomerlog() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.isNewlog = false;
                for (let [key, value] of Object.entries(action.data)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function editCustomerlog() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = true;
                item.oldnotes = item.notes
                item.oldcallDuration = item.callDuration
                item.oldcallType = item.callType
                item.oldcallStatus = item.callStatus
                item.oldaddedDate = item.addedDate
                return item;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data;
        }
        case "ADDNEWLOG": {
            return [action.data, ...state];
        }
        case "REMOVELOG": {
            return state.filter(item => item.documentID !== action.data.documentID);
        }
        case "CANCELLOG": {
            return cancelEditable(action.data);
        }
        case "CHANGELOG": {
            return updatechangelogs();
        }
        case "SAVELOG": {
            return [...state, action.data];
        }
        case "EDITLOG": {
            return editCustomerlog();
        }
        case "UPDATELOG": {
            return updateCustomerlog();
        }
        case "SUCCESS_EMAIL_LOG": {
            return action.data;
        }
        default:
            return state;
    }
};



const EnquiryActivity = (props) => {
    const [attendeeObj, setAttendeeObj] = useState(null);
    const [loadingActivity, setLoadingActivity] = useState(true)
    const [activitylogs, dispatch] = useReducer(AcitivitiesReducer, [])
    const [showActivity, setShowActivity] = useState(false);
    const [activeTab, setActivetab] = useState('note')
    const [newActivitylog, setNewActivitylog] = useState(null);
    const textareaRef = useRef(null);
    const [completedTab, setCompltedtab] = useState({ type: 'all', subtype: 'all' })

    const [activityHeader, setActivityHeader] = useState('Add New Activity')

    const [logLoader, setLogloader] = useState(false)


    const [showPreviewModel, setPreviewModel] = useState(false)
    const [emailLog, setEmaillog] = useState(null)
    const [showEDMsPreviewModel, setEDMsPreviewModel] = useState(false)

    let _enquirylogscompleted = enquirylogscompleted;

    const [sendLoading, setSendloading] = useState(false)
    let _allUser = _.union((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), Object.assign([], props.dealersettings.wholesalers));


    useEffect(() => {
        if (_.isEmpty(props.attendeeid))
            return

        let edmsData = firestoreDB(props.dealersettings).firestore().collection('triggersLog')
            .where('eventsProAttendeeID', '==', props.attendeeid)
            .where('isDeleted', '==', false)

        let changeLogData = window.firebase.firestore().collection('changeLogs')
            .where('recordId', '==', props.attendeeid)

        var activitiesCollection = combineLatest(
            collection(edmsData),
            collection(changeLogData),
            doc(firestoreDB(props.dealersettings).firestore().doc(`eventsProAttendees/${props.attendeeid}`))
        ).pipe(
            map(([edms, changelogs, attendeeSnapshot]) => {
                return [
                    _.map(edms, function (obj) { return { ...obj.data(), documentID: obj.id, iconType: obj.data().subType, subType: 'edms', addedDate: obj.data().startDate, collection: 'triggersLog' } }),
                    _.map(changelogs, function (obj) { return { ...obj.data(), documentID: obj.id, origin: obj.data().type, type: CommonHelper.getActivityType('log'), subType: 'log', isDone: true, startDate: obj.data().logDate, addedDate: obj.data().logDate, owner: obj.data().logBy } }),
                    attendeeSnapshot.data()
                ]
            }))
            .subscribe(async ([edms, changelogs, attendeeSnapshot]) => {
                let allNotes = [];
                setLoadingActivity(false)
                setAttendeeObj(attendeeSnapshot);
                if (!_.isEmpty(attendeeSnapshot?.notes)) {
                    allNotes = _.map(attendeeSnapshot?.notes, function (obj) {
                        return {
                            ...obj,
                            iconType: 'note',
                            type: 'log',
                            subType: 'note',
                            isDone: true,
                            startDate: obj.addedDate ? obj.addedDate : obj.modifiedDate ? obj.modifiedDate : null,
                            owner: obj.addedBy
                        }
                    });
                }

                dispatch({
                    type: "SUCCESS",
                    data: allNotes.concat(edms).concat(changelogs)
                });

            });
        return () => {
            activitiesCollection && activitiesCollection.unsubscribe()
        }

    }, [])


    useEffect(() => {
        let newlogVM = Object.assign({});

        newlogVM.type = 'log';
        newlogVM.subType = 'note';
        newlogVM.isDone = true;
        newlogVM.notes = '';
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), localStorage.uid);
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;

        setNewActivitylog(newlogVM)

    }, [activeTab, showActivity])


    const handleDateChangelog = useCallback((value, name, objLog) => {
        if (objLog.isEditable) {
            dispatch({
                type: "CHANGELOG",
                id: objLog.documentID,
                field: name,
                value: (name === 'callDuration' || name === 'callStatus' || name === 'callType') ? value : window.firebase.firestore.Timestamp.fromDate(moment(value)._d)
            });
        }
        else {
            setNewActivitylog({
                ...objLog,
                'subType': objLog.subType,
                [name]: (name === 'callDuration' || name === 'callStatus' || name === 'callType') ? value : window.firebase.firestore.Timestamp.fromDate(moment(value)._d)
            });
        }

    }, [])

    const activitypanelOpen = () => {

        setShowActivity(true)

    }

    const handlecancellog = useCallback((e, objLog) => {

        e.preventDefault();
        // setNewActivitylog(null)  
        if (objLog.isEditable) {
            dispatch({
                type: "CANCELLOG",
                data: objLog
            });
        }
        else {
            setShowActivity(false)
        }

    }, []);

    const handlechangelog = useCallback((e, objLog) => {
        var domTextref = document.getElementById('notes-tab-' + objLog.documentID)
        if (e.target.name === 'notes' && e.target.value.length === 0) {
            domTextref && domTextref.classList.add('input_error')
        }
        else {
            domTextref && domTextref.classList.remove('input_error')
        }
        e.preventDefault && e.preventDefault();
        if (objLog.isEditable) {
            dispatch({
                type: "CHANGELOG",
                id: objLog.documentID,
                field: e.target.name,
                value: e.target.value
            });
        }
        else {
            setNewActivitylog({
                ...objLog,
                [e.target.name]: e.target.value
            });
        }

    }, [])


    const handleRadioChange = useCallback((e, objLog) => {
        //e.preventDefault && e.preventDefault();
        setNewActivitylog({
            ...objLog,
            [e.target.name]: e.target.checked
        });

    }, [])


    const handlesavelog = useCallback((e, objLog, cTab, mentions, isSend, _contact, _enquiry, _tradeinPro, _service, _eventsPro, _attendee) => {
        e.preventDefault();

        var domTextref = document.getElementById('notes-tab-' + objLog.documentID)
        if (objLog.notes.length === 0 && domTextref) {
            domTextref.focus();
            domTextref.classList.add('input_error')
            return
        }

        if (_attendee?.documentID && objLog.notes) {
            setLogloader(true)
            const _userID = props.dealersettings ? props.dealersettings.id : '';
            const _currentDate = window.firebase.firestore.Timestamp.now();

            let convertedIDs = []
            if (!_.isEmpty(mentions)) {
                let rawIDs = mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            const objData = Object.assign({
                documentID: objLog.documentID ? objLog.documentID : window.firebase.firestore().collection('stock').doc().id,
                notes: objLog.notes,
                mentions: Object.assign([], convertedIDs),
                addedBy: _userID,
                addedDate: _currentDate,
                addedFrom: 'web',
                modifiedBy: _userID,
                modifiedDate: _currentDate,
                modifiedByName: props.dealersettings.name
            });

            let _notes = Object.assign([], _attendee.notes)
            _notes.push(objData);

            const updateRef = firestoreDB(props.dealersettings).firestore().collection('eventsProAttendees').doc(_attendee.documentID);
            updateRef.set({ notes: _notes }, { merge: true }).then((docRef) => {

                objLog.startDate = objLog.addedDate;

                setActivetab(objLog.subType)
                setLogloader(false)
                setShowActivity(false)
                setSendloading(false)

                toast.notify('Log updated successfully', {
                    duration: 2000
                })
                if (document.getElementById('enquiry-item-' + objData.documentID)) {
                    setTimeout(function () {
                        document.getElementById('enquiry-item-' + objData.documentID).scrollIntoView(
                            {
                                behavior: "smooth",
                                block: "center"
                            });
                    }, 1500);
                }

            }).catch((error) => {
                setLogloader(false)
                setSendloading(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
        }

    }, [])





    const alllogsList = useMemo(() =>

        activitylogs
            .filter(
                ({ type, isDone, subType, notes }) => isDone === true && (completedTab.type === 'all' ?
                    (!['log', 'salesforce'].includes(subType)) : (completedTab.type === 'allnotes' ?
                        ((['note', 'call'].includes(subType) || [CommonHelper.getActivityType('activity')].includes(type)) && !_.isEmpty(notes)) :
                        ((CommonHelper.getActivityType(completedTab.type) === CommonHelper.getActivityType('log') ? true : isDone === true) && type === CommonHelper.getActivityType(completedTab.type) && (completedTab.subtype ? subType === completedTab.subtype : true)))
                )
            )
            .sort(function (x, y) {
                return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
            }), [activitylogs, completedTab])

    const alllogsSorted = loadingActivity
        ? []
        : alllogsList;

    const checklength = (ftype, fsubtype) => {
        return fsubtype === 'log' && CommonHelper.getActivityType(ftype) === CommonHelper.getActivityType('log') ? 0 : activitylogs
            .filter(
                ({ type, isDone, subType, notes }) => isDone === true && (ftype === 'all' ?
                    (!['log', 'salesforce'].includes(subType)) : (ftype === 'allnotes' ?
                        ((['note', 'call'].includes(subType) || [CommonHelper.getActivityType('activity')].includes(type)) && !_.isEmpty(notes)) :
                        ((CommonHelper.getActivityType(ftype) === CommonHelper.getActivityType('log') ? true : isDone === true) && type === CommonHelper.getActivityType(ftype) && (fsubtype ? subType === fsubtype : true)))
                )
            ).length
    }


    const handleEDMsPreviewEmail = useCallback((e, objLog) => {
        setActivityHeader(objLog.title)
        setEmaillog({
            ...objLog,
            ['bodyHTML']: objLog.email && objLog.email.body ? objLog.email.body : ''
        })
        setEDMsPreviewModel(true)
    }, [])

    const handleModelClose = () => {
        setEmaillog(null)
        setEDMsPreviewModel(false)
    }


    return (
        <>
            <div className={`mid-column${props.isFinanceLayout ? '-finance' : ''}`}>
                {
                    props.companyView === true ? (<></>) : (<div className="mid-column-wrap">
                        <div className="activity-nav" id="activity-nav-new">
                            <Tab.Container activeKey={activeTab} >
                                <Nav as="ul" bsPrefix="ul-nav"
                                    onSelect={(k) => setActivetab(k)}>
                                    {
                                        enquiryActivities.map((rec, index) => {
                                            return <Nav.Item as="li" bsPrefix="li-nav" key={index}>
                                                <Nav.Link eventKey={rec.value} bsPrefix="li-a-nav">{<><i className={rec.ico}></i>
                                                    <Translate text={rec.name} />
                                                </>}
                                                </Nav.Link>
                                            </Nav.Item>
                                        })
                                    }
                                </Nav>

                            </Tab.Container>
                        </div>
                        {
                            showActivity ?
                                <>{
                                    (activeTab === "note" || activeTab === "call" || activeTab === "message") ?
                                        <div className="activity-nav-editmode pl-3 pr-3">
                                            <CustomerEditLog
                                                handlechangelog={handlechangelog}
                                                handlesavelog={handlesavelog}
                                                handlecancellog={handlecancellog}
                                                customerlog={newActivitylog}
                                                textareaRef={textareaRef}
                                                loading={logLoader}
                                                dealersettings={props.dealersettings}
                                                sendLoading={sendLoading}
                                                shouldFocusOnMount={true}
                                                eventsProAttendee={attendeeObj}
                                                handleRadioChange={handleRadioChange}
                                                handleFileChange={(e) => e.preventDefault()}
                                            />
                                        </div>
                                        :
                                        <></>
                                }
                                </>
                                :
                                <>
                                    <div className="activity-nav-subinput"
                                        onClick={() => {
                                            activitypanelOpen()
                                        }}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>
                                </>
                        }
                    </div>
                    )
                }
                {
                    loadingActivity ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                        <div className={`enquiry-flow mb-3 ${props.companyView === true ? '' : 'mt-3'}`}>
                            <div className="enquiry-flow-section">

                                <div className="enquiry-flow-nav">

                                    <ul>
                                        {
                                            _enquirylogscompleted.map((rec, index) => {
                                                return <li key={index}>
                                                    <a href="#"
                                                        id={rec.value}
                                                        className={`nav-completed-tabs nav-li-${rec.value} ${(completedTab.subtype === rec.value || (completedTab.type === rec.value && rec.value === CommonHelper.getActivityType('activity'))) ? 'active' : ''}`}
                                                        onClick={(event) => {
                                                            var sKey = _enquirylogscompleted.filter(e => e.value === event.target.id)[0]
                                                            if (sKey) {
                                                                setCompltedtab({
                                                                    type: sKey.type,
                                                                    subtype: sKey.subType
                                                                })
                                                            }
                                                            event.preventDefault();
                                                        }}
                                                    ><Translate text={rec.name} />
                                                        {
                                                            checklength(rec.type, rec.subType) > 0 ? (
                                                                <span className="count">({checklength(rec.type, rec.subType)})</span>
                                                            ) : (<></>)
                                                        }
                                                    </a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="enquiry-flow-wrap mt-4 ">
                                    <div className="flow-timelinebar"></div>
                                    {
                                        alllogsSorted.map((log, index) => {
                                            return <ActivityTag
                                                key={index}
                                                title={log.title}
                                                notes={log.notes}
                                                activityDate={log.type === CommonHelper.getActivityType('log') ? log.addedDate : log.startDate}
                                                id={log.documentID}
                                                type={log.subType}
                                                addedBy={log.addedByName ? log.addedByName : (log.owner || log.addedBy ? CommonHelper.getUserNamebyId(_allUser, (log.owner ? log.owner : log.addedBy)) : '')}
                                                modifiedBy={log.modifiedBy ? CommonHelper.getUserNamebyId(_allUser, log.modifiedBy) : log.addedByName}
                                                handleEditactivity={() => { }}
                                                handleComplete={() => { }}
                                                completed={log.isDone}
                                                activitylog={log}
                                                handleDeleteactivity={() => { }}
                                                scheduled={false}
                                                isEditable={log.isEditable}
                                                handlechangelog={handlechangelog}
                                                handlesavelog={handlesavelog}
                                                handlecancellog={handlecancellog}
                                                textareaRef={textareaRef}
                                                handleDateChangelog={handleDateChangelog}
                                                completedTab={completedTab}
                                                dealersettings={props.dealersettings}
                                                handlePreviewEmail={() => { }}
                                                handleUnlickEmail={() => { }}
                                                handleEDMsPreviewEmail={handleEDMsPreviewEmail}
                                                clientUsers={props.clientUsers}
                                                groupUsers={props.groupUsers}
                                                handleReplylog={() => { }}
                                                history={props.history}
                                                updateActivity={() => { }}
                                                eventsProAttendee={attendeeObj}
                                            >
                                            </ActivityTag>
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>
            <PopUpModal show={showEDMsPreviewModel}>
                <TemplatePreview
                    {...props}
                    show={showEDMsPreviewModel}
                    title={activityHeader}
                    handleClose={handleModelClose}
                    template={emailLog}
                />
            </PopUpModal>
        </>
    )
}

export default EnquiryActivity