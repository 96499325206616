import React from 'react';
import _ from 'lodash'
const CustomEvent = React.memo((props) => {
    const { event, onSelectEvent, searchText } = props;

    return (<div className="cal-slot-list">
        {
            event.slots.map((rec, index) => {
                if (rec.value === 'appointments') {
                    var _total = rec.services.length;
                    var _done = rec.services.filter(a => a.status !== 0).length;
                    return <div className={`cal-slot ${_done > 0 ? 'cal-active' : ''}`} key={index} onClick={() => onSelectEvent(rec, event.start)}>
                        <i style={{ padding: `4px`, backgroundColor: `#0071bc` }}></i>
                        <b>{rec.name} : </b><span className={`${_done === _total ? 'cal-slotcount-full' : _done > 0 ? 'cal-slotcount-occupied' : 'cal-slotcount-available'}`}>{searchText ? _total : `${_done} / ${_total}`}</span>
                    </div>
                }
                else {
                    return <div className={`cal-slot ${searchText && rec.services.length > 0 ? 'cal-active' : ''}`} key={index} onClick={() => onSelectEvent(rec, event.start)}>
                        <i style={{ padding: `4px`, backgroundColor: `${rec.color}` }}></i>
                        <b>{rec.name} : </b><span className={`${rec.services.length === rec.total ? 'cal-slotcount-full' : rec.services.length > 0 ? 'cal-slotcount-occupied' : 'cal-slotcount-available'}`}>{`${rec.services.length} / ${rec.total}`}</span>
                    </div>
                }
            })
        }
    </div>);
})

export default CustomEvent