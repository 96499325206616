import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { ContentOverlay, TableView } from '../../../../components';
import { reportContactFields } from '../viewModel';
import axios from 'axios';

const DisplayReportContacts = ({ campaign, title, show, handleClose, refresh_token, client_id, client_secret, apiUrl, api }) => {
  const [loader, setLoader] = useState(true);
  const [contacts, setContacts] = useState([])
  const [checkDataLoad, setDataload] = useState(true)
  const [pageNum, setpageNum] = useState(0)
  const [windowSize, setWindowSize] = useState({
    windowWidth: 750,
    windowHeight: window.innerHeight - 293,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: 750,
        windowHeight: window.innerHeight - 293,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubCampaigns && window.unSubCampaigns();
    };
  }, []);

  useEffect(() => {
    if (!checkDataLoad) {
      return;
    }
    const fetchData = async () => {
      const recipientsCount = await axios.post(
        `${apiUrl}mailup-api/api/mailup/message-contacts`,
        {
          messageID: campaign.messageID,
          refresh_token: refresh_token,
          client_id: client_id,
          client_secret: client_secret,
          api: api,
          pageNum
        },
      );
      if (recipientsCount?.data?.success) {
        setContacts([
          ...contacts,
          ...recipientsCount?.data?.data?.Items
        ])
      }
      setLoader(false)
      setDataload(false)
    }
    fetchData()
  }, [checkDataLoad])

  const handleActionClick = id => {
  };
  const handleRowSingleClick = () => {
  }

  const handlePagination = () => {
    setpageNum(pageNum + 1)
    setDataload(true)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        backdropClassName={show ? 'overlay-modal active' : ''}
        enforceFocus={false}
        dialogClassName='modal-dialog modal-dialog-centered modal-delivered-sms'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'>
              <Translate text={title} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="settings-head">
              </div>
              {loader ? (<div className="mt-5" style={{ width: "100%", minHeight: "40vh" }} ><ContentOverlay active={true} /></div>) : (
                <div className="common-table">
                  <TableView
                    isReorderable={false}
                    datalist={contacts}
                    height={windowSize.windowHeight}
                    width={windowSize.windowWidth}
                    columns={reportContactFields}
                    dynamicFields={reportContactFields
                      .filter(e => e.default === true)
                      .map(v => _.pick(v, ['value']).value)}
                    settingsLoader={false}
                    handleActionClick={handleActionClick}
                    handleRowSingleClick={handleRowSingleClick}
                    isSettings={false}
                    hasMore={[{ a: 1 }]}
                    handlePagination={handlePagination}
                    isPaging={true}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer'>
          <button
            type='button'
            className='btn btn-default float-right ml-2 '
            aria-label='Close'
            // disabled={}
            onClick={handleClose}
          >
            {' '}
            <Translate text={'Close'} />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DisplayReportContacts;
