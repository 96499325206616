import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import _images from '../../images'
import moment from 'moment'
import { PopoverwithFooter, FunnelChart, NoDataFunnelChart } from '../../components'
import FilterPanel from './filterPanel'
import { pipelinestatus } from './viewModel'
import Swal from 'sweetalert2';
import toast from 'toasted-notes'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';

const SalesFunnel = (props) => {
    const [data, setData] = useState([])
    const [selectedPipeline, setPipeline] = useState(null)
    const [loader, setLoader] = useState(true);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const [pipelineOptions, setPipelineOption] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [selectedUser, setUser] = useState([])
    const [viewOtherEnquiry, setOtherEnquiry] = useState(false);
    //const [loader, setLoader] = useState(true)
    const [refreshData, setRefresh] = useState(true)
    const [filterPopOver, setFilterPopOver] = useState({ show: false, target: null })
    const [filter, setFilter] = useState({ pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',') });
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? true : false);
    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-${props.id}`);
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const [dealersCount, setDealersCount] = useState([])
    const [oldDaterange, setOldDaterange] = useState({})

    useEffect(() => {
        return () => {
            window[`unsub${props.id}`] && window[`unsub${props.id}`]();
        }
    }, [])

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return

        setLoader(true)
        window[`unsub${props.id}`] = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setDealersCount(snap.data().counts);
                    setData(setFunnelData(snap.data().counts));
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])


    const fetchData = async (hideLoader) => {
        // console.log('lead by stage')
        let _filter = {
            clientID: CommonHelper.getAllDealers(props.dealersettings),
            type: 'status',
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
            isYTD: props.isMclaren === true && props.isYTD === true ? 'true' : 'false'
        }
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }

        if (!_.isEmpty(props.dateRange) && props.isYTD) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        else if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate && !props.isYTD) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        } else if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (!_.isEmpty(props.pipeline)) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        
        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }

        if (props.isYTD) {
            _filter.isYTD = 'true'
        }

        if (calcCurrentStatus) {
            _filter.calcCurrentStatus = 'true'
        }

        if (props.fromOEM) {
            _filter.inclInboundBuckeinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundBuckeinRpt;
            _filter.inclInboundLeadsinRpt = props?.dealersettings?.client?.clientSettings?.inclInboundLeadsinRpt;
        }

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getDashboardWidgetsV2" : "getDashboardWidgets",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp alternate', resp);
            setDealersCount(resp.data.data);
            const _data = setFunnelData(resp.data.data);
            setData(_data);
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: resp.data.data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setLoader(false)
            setFirstTimeLoaded(true)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            if (props.fromOEM) {
                if (JSON.stringify(oldDaterange) !== JSON.stringify(props?.dashboardFilter?.daterange)) {
                    setFirstTimeLoaded(false);
                    fetchData()
                    setOldDaterange(props?.dashboardFilter?.daterange)
                }
                else
                    setData(setFunnelData(dealersCount.filter(a => props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.includes(a.clientID) : true)));
            } else {
                window[`unsub${props.id}`] && window[`unsub${props.id}`]();
                setFirstTimeLoaded(false);
                fetchData();
            }
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])

    const setFunnelData = (data) => {
        let enquiries_ = _.chain(data)
            .groupBy("name")
            .map((value, key) => ({
                name: key,
                count: _.sumBy(value, 'count')
            }))
            .value().map(rec => {
                const value = rec.name
                let color = '#67b7dc'
                let _dynStatus = pipelinestatus;
                if (!_.isEmpty(props.dealersettings.client.settings.enquiryStatus) && props.dealersettings.client.settings.enquiryStatus.length > 0) {
                    _dynStatus = props.dealersettings.client.settings.enquiryStatus;
                }
                let _pipelinestatus = _dynStatus.filter(a => a.value === value)[0]
                if (_pipelinestatus && _pipelinestatus.color) {
                    color = value === 'open' ? '#67b7dc' : _pipelinestatus.color
                }
                return {
                    ...rec,
                    name: _pipelinestatus ? CommonHelper.autoCaps(_.toLower(_pipelinestatus.name)) : 'Open',
                    value,
                    color,
                    type: 'status',
                    startDate: moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'),
                    endDate: moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }
            })
        enquiries_ = _.orderBy(enquiries_, ['count'], ['asc'])
        if (props.enquiryStatus && props.enquiryStatus.length > 0) {
            let _sortStatus = [];
            props.enquiryStatus.filter(a => a.value !== 'prospectLost' && (props.isMclaren === true ? a.value !== 'lost' && a.value !== 'delivered' : true)).forEach(rec => {
                if (_.find(enquiries_, { value: rec.value })) {
                    _sortStatus.push(_.find(enquiries_, { value: rec.value }));
                }
                else {
                    _sortStatus.push({
                        ...rec,
                        color: rec.value === 'open' ? '#67b7dc' : rec.color,
                        count: 0,
                        type: "status",
                        startDate: moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'),
                        endDate: moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                    })
                }
            })
            enquiries_ = _sortStatus;
        }
        return enquiries_;
    }

    return (
        <>
            <div className="dashboard-box">
                {
                    loader && !firstTimeLoaded ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => {
                            e.preventDefault();
                            setFirstTimeLoaded(false);
                            fetchData()
                            //}
                        }}> <i className="ico icon-refresh"></i></a>
                        {/* <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setRefresh(true); }}> <i className="ico icon-refresh"></i></a>
                        <a href="#" className="mini-button ml-1" onClick={(e) => { setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a> 
                        <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a>*/}
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                    </div>
                    <h3>{props.selectedPipeline && props.isYTD ? `Funnel - ${props.selectedPipeline.label} - OVERALL` : props.selectedPipeline ? `Funnel - ${props.selectedPipeline.label}` : <Translate text={'Sales Funnel'} />}</h3>
                </div>
                {
                    data.some(item => item.count > 0) ? (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <FunnelChart
                                    id={props.isMclaren && !props.isYTD ? `sales-funnel-chart-${props.selectedPipeline.value}` : props.isMclaren && props.isYTD ? `sales-funnel-chart-${props.selectedPipeline.value}-OVERALL` : 'sales-funnel-chart'}
                                    height={'350px'}
                                    data={data}
                                    handleShowEnquiryList={(value, name, startDate, endDate) => {
                                        // console.log('value,startDate,endDate', value, startDate, endDate)
                                        // console.log('data', {
                                        //     ...filter,
                                        //     [name]: value,
                                        //     pipeline: props.selectedPipeline ? props.selectedPipeline.value : _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }),
                                        //     'startDate': startDate,
                                        //     'endDate': endDate,
                                        //     'dateType': value === 'delivered' ? 'deliveredDate' : value === 'won' ? 'wonDate' : value === 'lost' ? 'lostDate' : value === 'open' ? 'addedDate' : value
                                        // })
                                        const yrstartDate = window.firebase.firestore.Timestamp.fromDate(moment().startOf('year')._d);
                                        const yrendDate = window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d);
                                        props.handleShowEnquiryList({
                                            ...filter,
                                            [name]: value,
                                            pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }),
                                            'startDate': value === 'delivered' && props.isMclaren === true && props.isYTD === true ? yrstartDate : startDate,
                                            'endDate': value === 'delivered' && props.isMclaren === true && props.isYTD === true ? yrendDate : endDate,
                                            'dateType': value === 'delivered' ? 'deliveredDate' : value === 'won' ? 'wonDate' : value === 'lost' ? 'lostDate' : 'addedDate',
                                            'customPipeline': props.selectedPipeline && props.isMclaren ? props.selectedPipeline.value : ''
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataFunnelChart
                                    id={props.isMclaren && !props.isYTD ? `sales-funnel-chart-${props.selectedPipeline.value}` : props.isMclaren && props.isYTD ? `sales-funnel-chart-${props.selectedPipeline.value}-OVERALL` : 'sales-funnel-chart'}
                                    height={'350px'}
                                />
                            </div>

                        </div>
                    )
                }

            </div>
            {/* <PopoverwithFooter
                showpopover={filterPopOver.show}
                targetpopover={filterPopOver.target}
                title='Filter'
                position='left'
                className='popover-dashboard-filter'
                closepopover={() => {
                    setFilterPopOver({ show: false, target: '' })
                }}>
                <FilterPanel
                    userOptions={userOptions}
                    pipelineOptions={pipelineOptions}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    viewOtherEnquiry={viewOtherEnquiry}
                    setPipeline={(e) => {
                        if (e) {
                            setPipeline(e)
                            setFilter({
                                ...filter,
                                pipeline: e.value
                            })
                        }
                        else {
                            setPipeline(null)
                            setFilter({
                                ...filter,
                                pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',')
                            })
                        }

                    }}
                    setUser={(e) => {
                        if (e) {
                            setUser(e)
                            setFilter({
                                ...filter,
                                userId: e.value
                            })
                        }
                        else {
                            setUser(null)
                            setFilter({
                                ...filter,
                                userId: ''
                            })
                        }
                    }}
                    applyFilter={() => {
                        setRefresh(true);
                        setFilterPopOver({ show: false, target: '' })
                    }}
                    clearFilter={() => {
                        setFilterPopOver({ show: false, target: '' })
                    }}
                />
            </PopoverwithFooter> */}
        </>
    );
}
export default SalesFunnel;