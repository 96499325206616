import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Nav } from 'react-bootstrap'
import _ from 'lodash'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { collectionData } from 'rxfire/firestore';
import { collection } from 'rxfire/firestore';
import Templates from './templates'
import Triggers from './triggers'
import Lookups from './lookups/index'
import PipelineSettings from './pipeline'
import FieldSettings from './fieldSettings'
import Translate from '../../../constants/translate';
import SortEnquiryOptions from "./enquiryOptions";
import SortTradeinOptions from "./tradeinOptions"
import SortStockOptions from "./stockOptions"
import { TemplateContext } from './templateContext'
import CommonHelper from '../../../services/common';
import FeedbackForms from './feedbackForms'
import WebForms from './webForms';
import TVDevices from './tvDevices'
import CustomAlerts from './alerts'

const WorkFlowSettings = (props) => {
    const [active, setActive] = useState('pipelines')
    const [smsTemplates, setSmsTemplates] = useState([]);
    const [emailtemplates, setEmailTemplates] = useState([]);
    const [edmTemplates, setEdmTemplates] = useState([]);
    const [triggerSmsTemplates, setTriggerSmsTemplates] = useState([]);
    const [tvTemplates, setTvTemplates] = useState([]);
    const [loader, setLoader] = useState(true)
    const [clientGroups, setClientGroups] = useState([]);
    const [oemClients, setOEMClients] = useState([]);
    const [clientRegions, setClientRegions] = useState([])
    const [clientSettings, setClientSettings] = useState([])
    const [feedbackForms, setFeebackForms] = useState([]);
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) &&
        !Boolean(props.dealersettings.superAdmin) ? true : false);
    const tvModuleEnabled = ((!_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.moduleSettings) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings.tvModule) &&
        props.dealersettings.client.moduleSettings.tvModule.enabled) ? true : false);

    const accessToTVDisplay = ((!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.accessToTVDisplay) ? true : false);
    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])
    useEffect(() => {
        if (_.isEmpty(props.dealersettings)) {
            return;
        }
        let privateTemplatesRef = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/templates`).
            where('visibility', '==', 'private')
            .where('isDeleted', '==', false)
        privateTemplatesRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(privateTemplatesRef, props.dealersettings, null, true);

        var alltemplates = combineLatest(
            collectionData(privateTemplatesRef),
            collectionData(window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/templates`)
                .where('visibility', '==', 'shared')
                .where('isDeleted', '==', false))
        ).pipe(
            map((templates) => {
                var [dealer, shared] = templates;
                return [
                    [...dealer],
                    [...shared]
                ];
            }))
            .subscribe(allDocs => {
                let list = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
                let listVM = [];
                list.forEach(rec => {
                    if (!listVM.some(e => e.documentID === rec.documentID))
                        listVM.push(rec)
                })
                setEdmTemplates(listVM.filter(item => item.type === 'email'))
                setEmailTemplates(listVM.filter(item => item.type === 'emailTemplate'))
                setTriggerSmsTemplates(listVM.filter(item => item.type === 'sms'))
                setSmsTemplates(listVM.filter(item => item.type === 'smsTemplate'))
                setTvTemplates(listVM.filter(item => item.type === 'tvTemplate'))
                setLoader(false)
            });
        return () => {
            alltemplates && alltemplates.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings)) {
            return;
        }
        let privateFormRef = window.firebase.firestore().collection(`formTemplates`)
            .where('clientID', '==', props.dealersettings.client.id)
            .where('visibility', '==', 'private')
            .where('type', '==', 'sales')
            .where('isDeleted', '==', false);

        var allforms = combineLatest(
            collectionData(privateFormRef),
            collectionData(window.firebase.firestore().collection(`formTemplates`)
                .where('oemID', '==', props.dealersettings.oemID)
                .where('visibility', '==', 'shared')
                .where('type', '==', 'sales')
                .where('isDeleted', '==', false))
        ).pipe(
            map((forms) => {
                var [dealer, shared] = forms;
                return [
                    [...dealer],
                    [...shared]
                ];
            }))
            .subscribe(allDocs => {
                let list = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
                let listVM = [];
                list.forEach(rec => {
                    if (!listVM.some(e => e.documentID === rec.documentID))
                        listVM.push({
                            ...rec
                        })
                })
                setFeebackForms(listVM)
            });
        return () => {
            allforms && allforms.unsubscribe()
        }
    }, [])

    useEffect(() => {
        const groupSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/groups`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _groups = [];
                querySnapshot.forEach((rec) => {
                    _groups.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientGroups(_groups)
            })
        return () => {
            groupSnapshot && groupSnapshot();
        }
    }, [])

    useEffect(() => {
        const regionSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/regions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _regions = [];
                querySnapshot.forEach((rec) => {
                    _regions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientRegions(_regions)
            })
        return () => {
            regionSnapshot && regionSnapshot();
        }
    }, [])

    useEffect(() => {
        const clientsSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/clients`)
            .onSnapshot(querySnapshot => {
                let _client = [];
                querySnapshot.forEach((rec) => {
                    _client.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientSettings(_client)
            })
        return () => {
            clientsSnapshot && clientsSnapshot();
        }
    }, [])

    useEffect(() => {
        const oemclientSnapshot = window.firebase.firestore().collection('clients')
            .where('settingsID', '==', props.dealersettings.client.settingsID)
            .onSnapshot(querySnapshot => {
                let _clients = [];
                querySnapshot.forEach((rec) => {
                    _clients.push({ ...rec.data(), 'documentID': rec.id })
                })
                setOEMClients(_clients)
            })
        return () => {
            oemclientSnapshot && oemclientSnapshot();
        }
    }, [])

    const smsTemplatesMemo = useMemo(() => smsTemplates, [smsTemplates])
    const emailTemplatesMemo = useMemo(() => emailtemplates, [emailtemplates])
    const edmTemplatesMemo = useMemo(() => edmTemplates, [edmTemplates])
    const triggerSmsTemplatesMemo = useMemo(() => triggerSmsTemplates, [triggerSmsTemplates])
    const tvTemplatesMemo = useMemo(() => tvTemplates, [tvTemplates])
    const groupsMemo = useMemo(() => clientGroups, [clientGroups])
    const clientsMemo = useMemo(() => oemClients, [oemClients])
    const regionsMemo = useMemo(() => clientRegions, [clientRegions])
    const clientSettingsMemo = useMemo(() => clientSettings, [clientSettings])
    const feedbackFormsMemo = useMemo(() => feedbackForms, [feedbackForms])

    const getQueryparam = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (!key) {
            key = "pipelines"
        }
        return key
    }

    return (
        <>
            <Tab.Container defaultActiveKey={`${getQueryparam()}`} mountOnEnter={true}
                onSelect={(key) => {
                    setActive(key)
                }}>
                <div className="settings-submenu">
                    <h4> <Translate text={'workflow setup'} /></h4>
                    <Nav as="ul" bsPrefix="ul-nav">
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="pipelines" bsPrefix="li-a-nav"> <Translate text={'Pipeline Settings'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="fields" bsPrefix="li-a-nav"> <Translate text={'Mandatory Fields'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="templates" bsPrefix="li-a-nav"> <Translate text={'Templates'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="feedbackForms" bsPrefix="li-a-nav"> <Translate text={'Form Templates'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="webForms" bsPrefix="li-a-nav"> <Translate text={'Web Forms'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="triggers" bsPrefix="li-a-nav"> <Translate text={'Triggers'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="lookups" bsPrefix="li-a-nav"> <Translate text={'Dynamic Fields'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="enquiryOptions" bsPrefix="li-a-nav"> <Translate text={'enquiryOptions'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="tradeinOptions" bsPrefix="li-a-nav"> <Translate text={'tradeinOptions'} /></Nav.Link>
                        </Nav.Item> 
                        {/* <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="stockOptions" bsPrefix="li-a-nav"> <Translate text={'stockOptions'} /></Nav.Link>
                        </Nav.Item>*/}
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="alerts" bsPrefix="li-a-nav"> <Translate text={'alerts'} /></Nav.Link>
                        </Nav.Item>
                        {
                            tvModuleEnabled && accessToTVDisplay ? (
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="tvDevices" bsPrefix="li-a-nav"> <Translate text={'TV Devices'} /></Nav.Link>
                                </Nav.Item>
                            ) : (<></>)
                        }

                    </Nav>
                </div>
                <TemplateContext.Provider value={{ smsTemplateList: smsTemplatesMemo, tvTemplateList: tvTemplatesMemo, emailTemplateList: emailTemplatesMemo, edmTemplates: edmTemplatesMemo, triggerSmsTemplates: triggerSmsTemplatesMemo, loading: loader, groups: groupsMemo, clients: clientsMemo, regions: regionsMemo, clientSettings: clientSettingsMemo, feedbackForms: feedbackFormsMemo }}>
                    <div className={`settings-container mb-0 ${liteVersion ? '' : 'h-100'}`} style={liteVersion ? { overflow: 'hidden' } : {}}>
                        <div className="settings-container-sub" >
                            <Tab.Pane eventKey="pipelines">
                                <PipelineSettings activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fields">
                                <FieldSettings activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="templates">
                                <Templates activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="feedbackForms">
                                <FeedbackForms activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="webForms">
                                <WebForms activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="triggers">
                                <Triggers activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="lookups">
                                <Lookups activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="enquiryOptions">
                                <SortEnquiryOptions activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tradeinOptions">
                                <SortTradeinOptions activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="stockOptions">
                                <SortStockOptions activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tvDevices">
                                <TVDevices activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="alerts">
                                <CustomAlerts activeKey={active} {...props} />
                            </Tab.Pane>
                        </div>
                    </div>
                </TemplateContext.Provider>
            </Tab.Container>
        </>
    )
}

export default WorkFlowSettings;