/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
/** PROVIDERS */
import { ReactSelect } from '../../../components';
import { LayoutConsumer } from '../../layout/provider';
import { PipelineConsumer } from './PipelineProvider';
import { StageConsumer } from '../provider';
import Translate from '../../../constants/translate';
import FileDocuments from '../../files/documents';

class AfetrMarketTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'afterMarket'
        }
        this.scrollRef = React.createRef()
    }

    render() {
        const { activeTab } = this.state;
        return (
            <LayoutConsumer>
                {({ clientUsers, groupUsers }) => (
                    <StageConsumer>
                        {({ updateEnquiry, handleLeadConvertShow }) => (
                            <PipelineConsumer>
                                {({ enquiry, handleReactSelectChange, loading,
                                    allAfterMarketUsers, afterMarketStatuses, filesCategory, handleFileOpen, files
                                }) => loading ? (<div><Translate text={'Loading...'} /></div>) : (
                                    <>
                                        <div className="mid-quickview-finance-info">

                                            <div className="mid-quickview-finance-infowrap">

                                                <div className="mid-quickview-finance-subwrap">
                                                    <div className="mid-quickview-finance-infohead"><Translate text={'afterMarketBM'} /></div>
                                                    <div className={`form-style filter-item-btn filter-owner-btn w-180 ml-2`}>
                                                        <ReactSelect
                                                            options={allAfterMarketUsers}
                                                            name={"afterMarketBM"}
                                                            placeholder={'select aftermarket manager'}
                                                            onChange={handleReactSelectChange}
                                                            value={enquiry?.afterMarketBM ? enquiry.afterMarketBM : null}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            //removeClearable={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>


                                                <div className="mid-quickview-finance-subwrap">
                                                    <div className="mid-quickview-finance-infohead"><Translate text={'status'} /></div>
                                                    <div className={`form-style filter-item-btn filter-owner-btn w-180 ml-2`}>
                                                        <ReactSelect
                                                            options={afterMarketStatuses}
                                                            name={"afterMarketStatus"}
                                                            placeholder={'select aftermarket status'}
                                                            onChange={handleReactSelectChange}
                                                            value={enquiry?.afterMarketStatus ? enquiry.afterMarketStatus : null}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            //removeClearable={true}
                                                            isSearchable={false}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                (filesCategory.filter(e => e.module === activeTab)[0]) && (
                                                    <div className="quickview-column-finance-documents">
                                                        <h2>Documents</h2>
                                                        <FileDocuments
                                                            filesCategory={filesCategory}
                                                            activeTab={activeTab}
                                                            handleFileOpen={handleFileOpen}
                                                            files={files}
                                                        />
                                                        {/* <div className="quickview-column-finance-documents-inner">
                                                            {
                                                                filesCategory.filter(e => e.module === activeTab).map((_info, index) => (
                                                                    <div key={index} className="float-left mr-2 mb-2">
                                                                        <div className="checkbox icheck-success">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`${activeTab}${_info.value}`}
                                                                                name={`${activeTab}${_info.value}`}
                                                                                className="uncheck-activity"
                                                                                checked={files.filter(e => e.category === _info.value && !e.isDeleted)[0] ? true : false}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked)
                                                                                        handleFileOpen(null, _info.value, activeTab, filesCategory.filter(e => e.module === activeTab), _info.name)
                                                                                    else
                                                                                        handleFileOpen(e, _info.value, activeTab, filesCategory.filter(e => e.module === activeTab), _info.name)
                                                                                }} />
                                                                            <label htmlFor={`${activeTab}${_info.value}`}><Translate text={_info.name} /></label>
                                                                        </div>
                                                                    </div>))
                                                            }


                                                        </div> */}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                )}
                            </PipelineConsumer>
                        )}
                    </StageConsumer>
                )}
            </LayoutConsumer>

        );
    }
}

export default AfetrMarketTab;