import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { pipelineCountVM } from '../../dashboard/viewModel';
import CommonHelper from '../../../services/common';

const DealerSidePanel = (props) => {
	const [counts, setCounts] = useState(pipelineCountVM)
	const [windowSize, setWindowSize] = useState({
		windowHeight: window.innerHeight - 56,
	});

	useEffect(() => {
		if (props.countsLoader) {
			return
		}
		var _clients = _.map(props.clientsToRender, function (e) { return e.documentID; })
		let client = _clients[0]

		let _dynStatus = {};
		if (props.enqStatus.length > 0) {
			props.enqStatus.filter(a => !Boolean(a.default)).forEach(rec => {
				_dynStatus = {
					..._dynStatus,
					[`month${rec.value}`]: props.totalOEMCounts.filter(count => count.clientID === client)[0][`month${rec.value}`],
					[`today${rec.value}`]: props.totalOEMCounts.filter(count => count.clientID === client)[0][`today${rec.value}`],
				}
			})
		}

		setCounts({
			todayNew: props.totalOEMCounts.filter(count => count.clientID === client)[0][`todayNew`],
			monthNew: props.totalOEMCounts.filter(count => count.clientID === client)[0][`monthNew`],
			todayopen: props.totalOEMCounts.filter(count => count.clientID === client)[0][`todayOpen`],
			monthopen: props.totalOEMCounts.filter(count => count.clientID === client)[0][`monthOpen`],
			todaywon: props.totalOEMCounts.filter(count => count.clientID === client)[0][`todayWon`],
			monthwon: props.totalOEMCounts.filter(count => count.clientID === client)[0][`monthWon`],
			todaylost: props.totalOEMCounts.filter(count => count.clientID === client)[0][`todayLost`],
			monthlost: props.totalOEMCounts.filter(count => count.clientID === client)[0][`monthLost`],
			todaydelivered: props.totalOEMCounts.filter(count => count.clientID === client)[0][`todayDelivered`],
			monthdelivered: props.totalOEMCounts.filter(count => count.clientID === client)[0][`monthDelivered`],
			todayTestdrive: props.totalOEMCounts.filter(count => count.clientID === client)[0][`todayTestdrive`],
			monthTestdrive: props.totalOEMCounts.filter(count => count.clientID === client)[0][`monthTestdrive`],
			todayTradein: props.totalOEMCounts.filter(count => count.clientID === client)[0][`todayTradein`],
			monthTradein: props.totalOEMCounts.filter(count => count.clientID === client)[0][`monthTradein`],
			todayActivity: props.totalOEMCounts.filter(count => count.clientID === client)[0][`todayActivity`],
			monthActivity: props.totalOEMCounts.filter(count => count.clientID === client)[0][`monthActivity`],
			overdueActivity: props.totalOEMCounts.filter(count => count.clientID === client)[0][`overdueActivity`],
			..._dynStatus
		})

	}, [props.clientsToRender, props.countsLoader])

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowHeight: window.innerHeight - 56,
			});
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className='oem-dealer-wrap'>
			<div className='oem-rightbar-modules' style={{ minHeight: '500px', maxHeight: windowSize.windowHeight, overflow: 'auto' }}>
				{!props.countsLoader ? (
					<>
						<div className=' row '>
							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex'>
									<div className='oem-module-icon'>
										<i className='ico icon-new-leads'></i>
									</div>
									<div className='oem-module-label'> New Leads</div>

									<div className='oem-module-count-wraper'>
										<div className=' row'>
											<div className='col-lg-6'>
												<div className='oem-count-title'> Today</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayNew}</a>
												</div>
											</div>
											<div className='col-lg-6 oem-count-border-left'>
												<div className='oem-count-title'> MTD</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthNew}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{
								props.enqStatus.length > 0 ?
									props.enqStatus.filter(a => a.value !== 'prospectLost').map((rec, index) => {
										return <div className='col-lg-6' key={index}>
											<div className='oem-module-loop oem-module-flex'>
												<div className='oem-module-icon'>
													{
														rec.default === true ? <i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, `ico icon-${rec.value}${rec.value !== 'delivered' ? '-leads' : ''}`)}></i>
															: <i className='ico icon-show-leads' style={{ color: `${rec.color}` }}></i>
													}
												</div>
												<div className='oem-module-label'> {rec.name} Leads</div>

												<div className='oem-module-count-wraper'>
													<div className=' row'>
														<div className='col-lg-6'>
															<div className='oem-count-title'> Today</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts[`today${rec.value}`]}</a>
															</div>
														</div>
														<div className='col-lg-6 oem-count-border-left'>
															<div className='oem-count-title'> MTD</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts[`month${rec.value}`]}</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									}) : (<>
										<div className='col-lg-6'>
											<div className='oem-module-loop oem-module-flex'>
												<div className='oem-module-icon'>
													<i className='ico icon-open-leads'></i>
												</div>
												<div className='oem-module-label'> Open Leads</div>

												<div className='oem-module-count-wraper'>
													<div className=' row'>
														<div className='col-lg-6'>
															<div className='oem-count-title'> Today</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayopen}</a>
															</div>
														</div>
														<div className='col-lg-6 oem-count-border-left'>
															<div className='oem-count-title'> MTD</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthopen}</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='col-lg-6'>
											<div className='oem-module-loop oem-module-flex'>
												<div className='oem-module-icon'>
													<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-won-leads')}></i>
												</div>
												<div className='oem-module-label'> Won Leads</div>

												<div className='oem-module-count-wraper'>
													<div className=' row'>
														<div className='col-lg-6'>
															<div className='oem-count-title'> Today</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todaywon}</a>
															</div>
														</div>
														<div className='col-lg-6 oem-count-border-left'>
															<div className='oem-count-title'> MTD</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthwon}</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='col-lg-6'>
											<div className='oem-module-loop oem-module-flex'>
												<div className='oem-module-icon'>
													<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-lost-leads')}></i>
												</div>
												<div className='oem-module-label'> Lost Leads</div>

												<div className='oem-module-count-wraper'>
													<div className=' row'>
														<div className='col-lg-6'>
															<div className='oem-count-title'> Today</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todaylost}</a>
															</div>
														</div>
														<div className='col-lg-6 oem-count-border-left'>
															<div className='oem-count-title'> MTD</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthlost}</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className='col-lg-6'>
											<div className='oem-module-loop oem-module-flex'>
												<div className='oem-module-icon'>
													<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-delivered')}></i>
												</div>
												<div className='oem-module-label'> Delivered </div>

												<div className='oem-module-count-wraper'>
													<div className=' row'>
														<div className='col-lg-6'>
															<div className='oem-count-title'> Today</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todaydelivered}</a>
															</div>
														</div>
														<div className='col-lg-6 oem-count-border-left'>
															<div className='oem-count-title'> MTD</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthdelivered}</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>)
							}

							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex'>
									<div className='oem-module-icon'>
										<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-oem-testdrive')}></i>
									</div>
									<div className='oem-module-label'> Test Drive </div>

									<div className='oem-module-count-wraper'>
										<div className=' row'>
											<div className='col-lg-6'>
												<div className='oem-count-title'> Today</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayTestdrive}</a>
												</div>
											</div>
											<div className='col-lg-6 oem-count-border-left'>
												<div className='oem-count-title'> MTD</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthTestdrive}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex'>
									<div className='oem-module-icon'>
										<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-oem-tradein')}></i>
									</div>
									<div className='oem-module-label'> Trade-In </div>

									<div className='oem-module-count-wraper'>
										<div className=' row'>
											<div className='col-lg-6'>
												<div className='oem-count-title'> Today</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayTradein}</a>
												</div>
											</div>
											<div className='col-lg-6 oem-count-border-left'>
												<div className='oem-count-title'> MTD</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthTradein}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex'>
									<div className='oem-module-icon'>
										<i className='ico icon-activities '></i>
									</div>
									<div className='oem-module-label'> Activities </div>

									<div className='oem-module-count-wraper pt-2'>
										<div className=' row'>
											<div className='col-lg-6'>
												<div className='oem-count-title'> Today</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.todayActivity}</a>
												</div>
											</div>
											<div className='col-lg-6 oem-count-border-left'>
												<div className='oem-count-title'> MTD</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.monthActivity}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex'>
									<div className='oem-module-icon'>
										<i className='ico icon-overdue-activities '></i>
									</div>
									<div className='oem-module-label'> Overdue Activities </div>

									<div className='oem-module-count-wraper pt-2'>
										<div className=' row'>
											<div className='col-lg-12'>
												<div className='oem-count-title'> Total </div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.overdueActivity}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="spinner-loader h-100" style={{ paddingTop: '280px' }}><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
					</>
				)}

			</div>
		</div>
	);
};

export default DealerSidePanel;
