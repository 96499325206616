import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText } from '../../../../components'
import Translate from '../../../../constants/translate';
import { teamVM } from './viewModel'

const AddTeam = (props) => {
    const [team, setTeam] = useState(props.team);
    const [errorFields, setErrorFields] = useState({});
    const newTeam = !_.isEmpty(props.team) ? false : true
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.team)) {
            const newteamVM = Object.assign({}, teamVM);
            newteamVM.documentID = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/teams`).doc().id;
            newteamVM.addedBy = localStorage.uid;
            newteamVM.modifiedBy = localStorage.uid;
            newteamVM.addedDate = window.firebase.firestore.Timestamp.now();
            newteamVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            setTeam(newteamVM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setTeam({
                ...team,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setTeam({
                ...team,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const saveTeam = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(team['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)
        if (props.allTeams.some(item => item.name === team.name && item.documentID !== team.documentID)) {
            Swal.fire('Team with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        delete team.active;
        team.modifiedDate = window.firebase.firestore.Timestamp.now();
        team.modifiedBy = localStorage.uid;
        if (props.dealersettings.level === 'oem' && localStorage.defaultModule === 'oem')
            team.oemID = props.dealersettings.oemID
        else if (props.dealersettings.level === 'region' && localStorage.defaultModule === 'oem')
            team.regionID = props.dealersettings.regionID
        else if (props.dealersettings.level === 'group' && localStorage.defaultModule === 'oem')
            team.groupID = props.dealersettings.groupID
        else
            team.clientID = props.dealersettings.client.id

        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/teams/${team.documentID}`).set(team, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newTeam === true ? 'Team added successfully' : 'Team updated successfully'), {
                    duration: 2000
                })
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Teams Settings has been updated',
                    }, { merge: true })
                props.handleClose(team);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(team) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Team Name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={team.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveTeam(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddTeam;