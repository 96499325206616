import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import Translate from '../../constants/translate';
import { objActivitylog, objWalkIn } from '../pipeline/viewModel';
import { ContentOverlay, PopUpModal, TableView, UpgradeModal } from '../../components'
import AddActivity from '../activity'
import FilterPanel from './filterPanel'
import FilterType from './filterType'
import AcitivityReducer from './activityReducer'
import TransferOwner from '../common/transfer'
import ReAuthenticate from '../common/reAuthenticate';
import { allactivityFields, activityListVM } from './viewModel'
import CommonHelper from '../../services/common';
import ActivityStatus from '../activity/actvityStatusModal';
import { enqUnActionStatus, enquiryStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';

const defaultDataload = { "Overdue": true, "Today": true, "Upcoming": true, "Completed": true };
let _modules = ['tradeinPro', 'service']

const CalendarListView = (props) => {
    const [activetab, setActivetab] = useState(CommonHelper.isJSON(localStorage.eventFilter) && _.isArray(JSON.parse(localStorage.eventFilter)) ? JSON.parse(localStorage.eventFilter) : ['all']);
    const [activitylogs, dispatch] = useReducer(AcitivityReducer, { Overdue: [], Today: [], Upcoming: [], Completed: [] })
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [activityFields, setActivityFields] = useState(props.dealersettings.activityFields)
    const [showActivityModel, setShowActivityModel] = useState(false)
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const [newActivitylog, setNewActivitylog] = useState(null);
    const [enquiryObj, setEnquiryObj] = useState(null);
    const [tradeinProObj, setTradeinProObj] = useState(null);
    const [contactObj, setContactObj] = useState(null);
    const [stockObj, setStockObj] = useState(null);
    const [activeScheduledTab, setActiveScheduledTab] = useState('Overdue')
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState(defaultDataload)
    const [activityLoader, setLoader] = useState(defaultDataload)
    const [isPaging, setPaging] = useState(false)
    const [isNewActivity, setNewActivity] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.eventSearchText ? localStorage.eventSearchText : '')

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0)

    const [selectedIds, setSelectedIds] = useState({})
    const [modalTransferShow, setModalTransferShow] = useState(false)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const [csvHeader, setHeader] = useState([])
    const [showScheduleActivityModel, setShowScheduleActivityModel] = useState({
        show: false,
        activityLog: null,
        contact: null,
        enquiry: null
    })
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);
    const [showOutcomeModal, setShowOutcomeModal] = useState({
        show: false,
        clsActive: '',
        isMandatory: false,
        data: {}
    });

    const { dealersettings } = props;
    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);
    const viewOtherActivity = ((!_.isEmpty(_permissions) && _permissions.viewOtherActivity) ? true : false);
    const accessToFinanceActivity = ((!_.isEmpty(_permissions) && _permissions.accessToFinanceActivity) ? true : false);
    const accessToAfterMarketActivity = ((!_.isEmpty(_permissions) && _permissions.accessToAfterMarketActivity) ? true : false);

    const isOutlookCalendarExpired =
        !_.isEmpty(props.dealersettings)
            && !_.isEmpty(props.dealersettings.outlookCalendar)
            && props.dealersettings.outlookCalendar.refreshTokenExpired === true ? true : false

    const enableCustShowNoShow = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

    useEffect(() => {
        let headerFields = activityFields;
        let allHeaderFields = allactivityFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(CommonHelper.bindContactName(_headers, 'contactName'));
        //setHeader(_headers);

    }, [activityFields])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload({
                ...checkDataLoad,
                [activeScheduledTab]: false
            })
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setCampaigns(_campaigns);
            setOrigins(_origins);

            setTitles(_titles);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setDataload({
                ...checkDataLoad,
                [activeScheduledTab]: true
            })
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubactivitylist && window.unsubactivitylist();
        }
    }, [])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'activities')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad[activeScheduledTab]) {
            return;
        }

        //console.log('isFilterApplied', isFilterApplied, localStorage.activityFilter);
        if (localStorage.activityFilter && isValidFilter()) {
            let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
            let _activityFilter = JSON.parse(localStorage.activityFilter);
            let _filter = Object.assign({}, _activityFilter.value);

            // if (!_filter.owner)
            //     _filter.owner = localStorage.uid;

            _filter.type = CommonHelper.getActivityType('activity');

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                delete _filter['date'];
            }

            if (!viewOtherActivity)
                _filter.owner = localStorage.uid;

            if (!_.isEmpty(_filter.subType)) {
                setActivetab(_filter.subType.split(','))
                localStorage.eventFilter = JSON.stringify(_filter.subType.split(','));
            }
            else if (_.isEmpty(_filter.subType) && activetab && !_.isEmpty(activetab.some(e => e !== 'all'))) {
                _filter.subType = activetab.join(',');
            }

            if (_modules.includes(localStorage.defaultModule))
                _filter.module = localStorage.defaultModule
            else if (accessToFinanceActivity || accessToAfterMarketActivity) {
                let _type = ['activity']
                if (accessToFinanceActivity) _type.push('financeactivity')
                if (accessToAfterMarketActivity) _type.push('afterMarketactivity')
                _filter.type = _type.join(',');
            }

            _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
            let _searchObject = {
                "type": "searchActivities",
                "filters": JSON.stringify(_filter),
                "sortOrder": "startDate desc",
                "pageNum": 0,
                "pageLimit": 10000
            }
            if (localStorage.defaultModule === 'tradeinPro') {
                _searchObject.clientID = _filter.clientID ? _filter.clientID : CommonHelper.getTradeinProDealers(dealersettings, false, true);
            }
            else {
                _searchObject = CommonHelper.setLevelIDs(_searchObject, dealersettings, null, null);
            }
            //console.log('filter-searchActivities', _searchObject, clientID, pageNum, _filter);
            const searchActivities = window.firebase.functions().httpsCallable('generic-searchData');
            searchActivities(_searchObject).then((response) => {
                //console.log('generic-searchData', response);
                if (response.data.success && localStorage.activityFilter && isValidFilter()) {
                    onCollectionEnquiry(response.data);
                }
                else {
                    setDataload({
                        ...checkDataLoad,
                        [activeScheduledTab]: false
                    })
                    setLoader({
                        ...activityLoader,
                        [activeScheduledTab]: false
                    })
                    setPaging(false)
                }
            });

            window.unsubactivitylist && window.unsubactivitylist();
        }
        else {

            let searchKeywords = searchText;
            let searchOwners = [];
            let searchTypes = '';
            let searchActivityOutcome = '';

            if (localStorage.activityFilter) {
                let _activityFilter = JSON.parse(localStorage.activityFilter);
                let _filter = Object.assign({}, _activityFilter.value);

                searchTypes = _filter['subType'] ? _filter['subType'] : searchText;
                searchOwners = _filter['owner'] ? _filter['owner'] : '';
                searchActivityOutcome = _filter['activityOutcome'] ? _filter['activityOutcome'] : '';
                setSearchText(searchKeywords)
                localStorage.eventSearchText = searchKeywords;
                // console.log('search---types', searchTypes, searchOwners)
                // console.log('searchActivityOutcome', searchActivityOutcome)
            }

            let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('activities')
                .where('isDeleted', '==', false)

            if (!_modules.includes(localStorage.defaultModule) && (accessToFinanceActivity || accessToAfterMarketActivity)) {
                let _type = ['activity']
                if (accessToFinanceActivity) _type.push('financeactivity')
                if (accessToAfterMarketActivity) _type.push('afterMarketactivity')
                refActivitiesData = refActivitiesData.where('type', 'in', _type)
            }
            else
                refActivitiesData = refActivitiesData.where('type', '==', CommonHelper.getActivityType('activity'))

            if (localStorage.defaultModule === 'oem') {
                refActivitiesData = refActivitiesData
                    .where('levelID', '==', (props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
                        props.dealersettings.level === 'region' ? props.dealersettings.regionID :
                            props.dealersettings.level === 'group' ? props.dealersettings.groupID :
                                props.dealersettings.client.id))
            } else if (localStorage.defaultModule === 'tradeinPro') {
                refActivitiesData = refActivitiesData.where('clientID', 'in', CommonHelper.getTradeinProDealers(props.dealersettings, true))

            } else {
                refActivitiesData = refActivitiesData.where('clientID', '==', props.dealersettings.client.id)
            }

            if (searchKeywords && searchKeywords !== 'undefined') {
                let formattedSearchText = CommonHelper.parseSearchText(props.dealersettings, searchKeywords)

                if (formattedSearchText.trim())
                    refActivitiesData = refActivitiesData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            }

            if (!viewOtherActivity) {
                refActivitiesData = refActivitiesData
                    .where('searchUsers', 'array-contains', localStorage.uid)
            } else if (!_.isEmpty(searchOwners))
                refActivitiesData = refActivitiesData.where('searchUsers', 'array-contains', searchOwners)

            if (!_.isEmpty(searchActivityOutcome)) {
                refActivitiesData = refActivitiesData.where('activityOutcome', '===', searchActivityOutcome)
            }

            if (!_.isEmpty(activetab) && !activetab.some(e => e === 'all')) {
                if (localStorage.defaultModule === 'tradeinPro' || (!_modules.includes(localStorage.defaultModule) && accessToFinanceActivity)) {
                    //no specific subtype for this condition 
                }
                else
                    refActivitiesData = refActivitiesData.where('subType', 'in', activetab)
            }
            if (activeScheduledTab === 'Overdue') {
                refActivitiesData = refActivitiesData
                    .where('isDone', '==', false)
                    .where('startDate', '<', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
            }
            else if (activeScheduledTab === 'Upcoming') {
                refActivitiesData = refActivitiesData
                    .where('isDone', '==', false)
                    .where('startDate', '>', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
            }
            else if (activeScheduledTab === 'Today') {
                refActivitiesData = refActivitiesData
                    .where('isDone', '==', false)
                    .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                    .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
            }
            else {
                refActivitiesData = refActivitiesData
                    .where('isDone', '==', true)
            }


            if (activeScheduledTab === 'Completed') {
                refActivitiesData = refActivitiesData
                    .orderBy('startDate', 'desc')
            }
            else {
                refActivitiesData = refActivitiesData
                    .orderBy('startDate', 'desc')
            }

            //.limit(pageLimit)
            let lastRecord = hasMore.filter(e => e.type === activeScheduledTab);
            if (lastRecord.length > 0) {
                refActivitiesData = refActivitiesData
                    .startAfter(lastRecord[0].lastDoc)
                    .limit(pageLimit)
            }
            else {
                refActivitiesData = refActivitiesData
                    .limit(pageLimit)
            }

            window.unsubactivitylist = refActivitiesData
                .onSnapshot(onCollectionUpdate);

            if (!_.isEmpty(searchTypes)) {
                setActivetab(searchTypes.split(','))
                localStorage.eventFilter = JSON.stringify(searchTypes.split(','));
            }
            // const activities = collectionData(refActivitiesData)
            //     .pipe(
            //         switchMap(activitySnapshot => {
            //             const enquiryIds = _.uniq(activitySnapshot.filter(item => !_.isEmpty(item.enquiryID)).map(item => item.enquiryID))
            //             const contactIds = _.uniq(activitySnapshot.filter(item => !_.isEmpty(item.contactID)).map(item => item.contactID))
            //             const stockIds = _.uniq(activitySnapshot.filter(item => !_.isEmpty(item.stockID)).map(item => item.stockID))
            //             return combineLatest(
            //                 of(activitySnapshot),
            //                 combineLatest(
            //                     enquiryIds.length > 0 ? enquiryIds.map(enquiryID =>
            //                         collectionData(window.firebase.firestore().collection('enquiries').where('documentID', '==', enquiryID)).pipe(
            //                             map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
            //                         )
            //                     ) : of([])
            //                 ),
            //                 combineLatest(
            //                     contactIds.length > 0 ? contactIds.map(contactID =>
            //                         collectionData(firestoreDB(props.dealersettings).firestore().collection('contactDetails').where('documentID', '==', contactID)).pipe(
            //                             map(contactSnapshot => contactSnapshot[0] ? contactSnapshot[0] : {})
            //                         )
            //                     ) : of([])
            //                 ),
            //                 combineLatest(
            //                     stockIds.length > 0 ? stockIds.map(stockID =>
            //                         collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
            //                             map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
            //                         )
            //                     ) : of([])
            //                 ),
            //             )
            //         }),
            //         map(([activitySnapshot, enquirySnapshot, contactSnapshot, stockSnapshot]) => {
            //             return activitySnapshot.map(activityDoc => {
            //                 return {
            //                     ...activityDoc,
            //                     enquiry: enquirySnapshot.find(a => a.documentID === activityDoc.enquiryID),
            //                     contact: contactSnapshot.find(a => a.documentID === activityDoc.contactID),
            //                     stock: stockSnapshot.find(a => a.documentID === activityDoc.stockID),
            //                 }
            //             })
            //         })
            //     )
            //     .subscribe(activities => {
            //         onCollectionUpdate(activities)
            //     });
            return () => {
                //activities.unsubscribe()

            }
        }
    }, [checkDataLoad, activeScheduledTab])

    const isValidFilter = () => {
        if (localStorage.activityFilter) {
            let _activityFilter = JSON.parse(localStorage.activityFilter);
            let _filter = Object.assign({}, _activityFilter.value);

            if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('subType') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
                return false;

            if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('subType') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertLogVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        const _activities = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeScheduledTab),
                {
                    type: activeScheduledTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeScheduledTab)]);
            }
            querySnapshot.forEach(function (doc) {
                var logVM = convertLogVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _activities.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: actionType ? snapshotDoc : _activities.filter(a =>
                (!_.isEmpty(activetab) &&
                    !activetab.some(e => e === 'all')
                    && (localStorage.defaultModule === 'tradeinPro' || (!_modules.includes(localStorage.defaultModule) && accessToFinanceActivity))) ? activetab.includes(a.subType) : true),
            activetab: activeScheduledTab
        });
        setDataload({
            ...checkDataLoad,
            [activeScheduledTab]: false
        })
        setLoader({
            ...activityLoader,
            [activeScheduledTab]: false
        })
        setPaging(false)
    }

    const onCollectionEnquiry = (querySnapshot) => {

        let refActivitiesData = querySnapshot.data
        refActivitiesData.forEach(rec => {
            rec.startDate = rec.startDate && rec.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.startDate._seconds)._d) : rec.startDate;
            rec.endDate = rec.endDate && rec.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.endDate._seconds)._d) : rec.endDate;
            rec.addedDate = rec.addedDate && rec.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.addedDate._seconds)._d) : rec.addedDate;
            rec.modifiedDate = rec.modifiedDate && rec.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.modifiedDate._seconds)._d) : rec.modifiedDate;
        })
        if (!activetab.some(e => e === 'all')) {
            refActivitiesData = refActivitiesData
                .filter(e => activetab.some(a => a === e.subType))
        }
        if (activeScheduledTab === 'Overdue') {
            refActivitiesData = refActivitiesData
                .filter(e => e.isDone === 'false' &&
                    e.startDate < window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
        }
        else if (activeScheduledTab === 'Upcoming') {
            refActivitiesData = refActivitiesData
                .filter(e => e.isDone === 'false' &&
                    e.startDate > window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))

        }
        else if (activeScheduledTab === 'Today') {
            refActivitiesData = refActivitiesData
                .filter(e => e.isDone === 'false' &&
                    e.startDate >= window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d) &&
                    e.startDate <= window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
        }
        else {
            refActivitiesData = refActivitiesData
                .filter(e => e.isDone === 'true')
        }


        const _activities = [];
        refActivitiesData.forEach(function (doc) {
            var logVM = convertLogVM(doc);
            _activities.push(logVM)
        });

        dispatch({
            type: "SUCCESS_LIST",
            data: _activities,
            activetab: activeScheduledTab
        });
        setDataload({
            ...checkDataLoad,
            [activeScheduledTab]: false
        })
        setLoader({
            ...activityLoader,
            [activeScheduledTab]: false
        })
        setPaging(false)

    }

    const convertLogVM = (doc) => {

        doc.startDate = doc.startDate && doc.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
        doc.endDate = doc.endDate && doc.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate._seconds)._d) : doc.endDate;
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
        doc.completedDate = doc.completedDate && doc.completedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.completedDate._seconds)._d) : doc.completedDate;


        const objlogData = Object.assign({}, doc);
        const logVM = Object.assign({}, activityListVM);
        for (let [key, value] of Object.entries(objlogData)) {
            if (logVM.hasOwnProperty(key))
                logVM[key] = value;
        }

        let dealersettings = props.dealersettings;
        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            logVM.activityOutcomeName = CommonHelper.getNameByValue(dealersettings.client.settings.activityOutcome, doc.activityOutcome, '');
        }
        if (_.isString(doc.isDone)) {
            logVM.isDone = doc.isDone === 'true';
        }
        if (!doc.startDate) {
            doc.startDate = doc.addedDate
        }

        if (!doc.endDate) {
            doc.endDate = doc.startDate
        }
        logVM.activityOwner = CommonHelper.getUserNamebyId(allUsers, doc.owner);
        logVM.dueDate = moment.unix(doc.startDate.seconds).format('DD/MM/YYYY');
        logVM.dueTime = moment.unix(doc.startDate.seconds).format('hh:mm A') + ' - ' + moment.unix(doc.endDate.seconds).format('hh:mm A');

        logVM.activityCompletedDate = doc.completedDate ? moment.unix(doc.completedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        logVM.activityCompletedBy = CommonHelper.getUserNamebyId(allUsers, doc.completedBy);

        logVM.createdBy = CommonHelper.getUserNamebyId(allUsers, doc.addedBy);
        logVM.updatedBy = CommonHelper.getUserNamebyId(allUsers, doc.modifiedBy);
        logVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        logVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        if (!_.isEmpty(doc.enquiry)) {
            logVM.enquiryStatus = doc.enquiry.status;
            logVM.enqPipeline = CommonHelper.getNameByValue((props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.allPipelines : []), doc.enquiry.pipeline, '');
        }
        if (!_.isEmpty(doc.stock)) {
            logVM.testdriveModel = doc.stock.make + ' ' + doc.stock.model;
        }
        if (!_.isEmpty(doc.contact)) {
            doc.contact.addedDate = doc.contact.addedDate && doc.contact.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.contact.addedDate._seconds)._d) : doc.contact.addedDate;
            doc.contact.modifiedDate = doc.contact.modifiedDate && doc.contact.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.contact.modifiedDate._seconds)._d) : doc.contact.modifiedDate;

            logVM.contactName = CommonHelper.displayFullContact(titles, doc.contact);
            logVM.firstName = (doc.contact.firstName || '');
            logVM.middleName = (doc.contact.middleName || '');
            logVM.lastName = (doc.contact.lastName || '');
            logVM.contactNumber = doc.contact.displayID;
            logVM.phone = doc.contact.phone ? (doc.contact.phoneCode ? doc.contact.phoneCode : '') + doc.contact.phone : '';
            logVM.csvPhone = logVM.phone ? `${logVM.phone}` : ''
            logVM.contactPhone = CommonHelper.getFullPhone(doc.contact.phoneCode, doc.contact.phone);
            logVM.contactEmail = doc.contact.email;
            logVM.licenseNo = doc.contact.licenseNo;
            logVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
            logVM.contactType = doc.contact.contactType;
            logVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
            logVM.gender = doc.contact.gender;
            logVM.maritalStatus = doc.contact.maritalStatus;
            logVM.contactMethod = doc.contact.contactMethod;
            logVM.licenseType = doc.contact.licenseType;
            logVM.language = CommonHelper.getLabelByValue(languages, doc.contact.language, '');
            logVM.nationality = CommonHelper.getLabelByValue(nationalities, doc.contact.nationality, '');

            if (doc.contact.clientID && (doc.contact.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem' || localStorage.defaultModule === 'tradeinPro')) {
                logVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, doc.contact.clientID);
            }

            let dealersettings = props.dealersettings;
            let _setttings = doc.contact.clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[doc.contact.clientID] && dealersettings.group.clients[doc.contact.clientID].settings;
            if (_.isEmpty(_setttings))
                _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

            if (_setttings) {
                logVM.campaign = CommonHelper.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
                logVM.origin = CommonHelper.getNameByValue(_setttings.origins, doc.contact.origin, '');

                logVM.gender = CommonHelper.getNameByValue(_setttings.genders, doc.contact.gender, '');
                logVM.maritalStatus = CommonHelper.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
                logVM.contactMethod = CommonHelper.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
                logVM.interests = CommonHelper.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
                logVM.licenseType = CommonHelper.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');
            }

            logVM.address = doc.contact.address;
            logVM.companyName = doc.contact.companyName;
            logVM.designation = doc.contact.designation;

            logVM.licenseState = doc.contact.licenseState;
            logVM.secondaryContactName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
            logVM.secondaryPhone = doc.contact.secondaryPhone ? ((doc.contact.secondaryPhoneCode ? doc.contact.secondaryPhoneCode : '') + doc.contact.secondaryPhone) : '';
            logVM.secondaryContactPhone = CommonHelper.getFullPhone(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone);
            logVM.secondaryContactEmail = doc.contact.secondaryEmail;
            logVM.contactCreatedBy = CommonHelper.getUserNamebyId(allUsers, doc.contact.addedBy);
            logVM.contactModifiedby = CommonHelper.getUserNamebyId(allUsers, doc.contact.modifiedBy);
            logVM.contactCreatedOn = doc.contact.addedDate ? moment.unix(doc.contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            logVM.contactModifiedOn = doc.contact.modifiedDate ? moment.unix(doc.contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            logVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
            logVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
            logVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
            logVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
            logVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
            logVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
            logVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
        }
        return logVM
    }

    // const getActivitybyID = (id) => {
    //     window.clistSubscription = collectionData(firestoreDB(props.dealersettings).firestore().collection('activities')
    //         .where('documentID', '==', id))
    //         .pipe(
    //             switchMap(activitySnapshot => {
    //                 const enquiryIds = _.uniq(activitySnapshot.filter(item => !_.isEmpty(item.enquiryID)).map(item => item.enquiryID))
    //                 const contactIds = _.uniq(activitySnapshot.filter(item => _.isEmpty(item.enquiryID) && !_.isEmpty(item.contactID)).map(item => item.contactID))
    //                 const stockIds = _.uniq(activitySnapshot.filter(item => !_.isEmpty(item.stockID)).map(item => item.stockID))
    //                 return combineLatest(
    //                     of(activitySnapshot),
    //                     combineLatest(
    //                         enquiryIds.length > 0 ? enquiryIds.map(enquiryID =>
    //                             collectionData(window.firebase.firestore().collection('enquiries').where('documentID', '==', enquiryID)).pipe(
    //                                 map(enquirySnapshot => enquirySnapshot[0])
    //                             )
    //                         ) : of([])
    //                     ),
    //                     combineLatest(
    //                         contactIds.length > 0 ? contactIds.map(contactID =>
    //                             collectionData(firestoreDB(props.dealersettings).firestore().collection('contactDetails').where('documentID', '==', contactID)).pipe(
    //                                 map(contactSnapshot => contactSnapshot[0])
    //                             )
    //                         ) : of([])
    //                     ),
    //                     combineLatest(
    //                         stockIds.length > 0 ? stockIds.map(stockID =>
    //                             collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
    //                                 map(stockSnapshot => stockSnapshot[0])
    //                             )
    //                         ) : of([])
    //                     ),
    //                 )
    //             }),
    //             map(([activitySnapshot, enquirySnapshot, contactSnapshot, stockSnapshot]) => {
    //                 return activitySnapshot.map(activityDoc => {
    //                     return {
    //                         ...activityDoc,
    //                         enquiry: enquirySnapshot.find(a => a.documentID === activityDoc.enquiryID),
    //                         contact: contactSnapshot.find(a => a.documentID === activityDoc.contactID),
    //                         stock: stockSnapshot.find(a => a.documentID === activityDoc.stockID),
    //                     }
    //                 })
    //             })
    //         )
    //         .subscribe(activities => {
    //             onDocUpdate(convertLogVM(activities[0]));
    //         });
    // }
    const onDocUpdate = (activity) => {
        var tabtype = '';
        if (activity.isDone === true) {
            tabtype = "Completed"
        }
        else if (activity.startDate < window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)) {
            tabtype = "Overdue"
        }
        else if (activity.startDate > window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)) {
            tabtype = "Upcoming"
        }
        else {
            tabtype = "Today"
        }
        if (!_.isEmpty(activity)) {
            const oldLog = activitylogs[activeScheduledTab].find(item => item.documentID === activity.documentID);
            if (!_.isEmpty(oldLog)) {
                if (oldLog.subType !== activity.subType) {
                    dispatch({
                        type: "REMOVE_LIST",
                        data: activity,
                        activetab: activeScheduledTab
                    });
                }
                else if (activeScheduledTab !== tabtype) {
                    dispatch({
                        type: "REMOVE_LIST",
                        data: activity,
                        activetab: activeScheduledTab
                    });
                    dispatch({
                        type: "ADDNEW_LIST",
                        data: activity,
                        activetab: tabtype
                    });
                }
                else {
                    dispatch({
                        type: "UPDATE_LIST",
                        data: activity,
                        activetab: tabtype
                    });
                }
            }
            else {
                dispatch({
                    type: "ADDNEW_LIST",
                    data: activity,
                    activetab: tabtype
                });
            }
        }
        //window.clistSubscription && window.clistSubscription.unsubscribe()
    }

    const handlechangefilter = (type) => {
        setActivetab(type === 'all' ? ['all'] : [...activetab.filter(e => e !== 'all'), type]);
        // setDataload({
        //     ...checkDataLoad,
        //     [activeScheduledTab]: true
        // })
        // setHasMoreData([...hasMore.filter(e => e.type !== activeScheduledTab)]);
        setDataload({ "Overdue": true, "Today": true, "Upcoming": true, "Completed": true })
        setLoader({ "Overdue": true, "Today": true, "Upcoming": true, "Completed": true })
        setHasMoreData([])
        localStorage.eventFilter = JSON.stringify(type === 'all' ? ['all'] : [...activetab.filter(e => e !== 'all'), type]);

        if (localStorage.activityFilter && isValidFilter)
            handleActivityFilterApply();
    }

    const handleuncheckfilter = (type) => {
        if (activetab.filter(e => e !== type).length > 0)
            setActivetab(activetab.filter(e => e !== type));
        else
            setActivetab(['all'])
        // setDataload({
        //     ...checkDataLoad,
        //     [activeScheduledTab]: true
        // })
        // setHasMoreData([...hasMore.filter(e => e.type !== activeScheduledTab)]);
        setDataload({ "Overdue": true, "Today": true, "Upcoming": true, "Completed": true })
        setLoader({ "Overdue": true, "Today": true, "Upcoming": true, "Completed": true })
        setHasMoreData([])
        localStorage.eventFilter = JSON.stringify(activetab.filter(e => e !== type).length > 0 ? activetab.filter(e => e !== type) : ['all']);

        if (localStorage.activityFilter && isValidFilter)
            handleActivityFilterApply();
    }

    const handleAddActivity = () => {

        // const clientSettings = (!_.isEmpty(props.dealersettings) &&
        //     !_.isEmpty(props.dealersettings.client) &&
        //     !_.isEmpty(props.dealersettings.client.clientSettings)) ? props.dealersettings.client.clientSettings : null;
        // const liteVersion = (!_.isEmpty(clientSettings) && clientSettings.liteVersion) ? true : false;
        // if (liteVersion && localStorage.defaultModule !== 'oem') {
        //     setShowUpgradeModal(true);
        //     return;
        // }

        setNewActivitylog(null)
        setEnquiryObj(null)
        setTradeinProObj(null)
        setContactObj(null)
        setStockObj(null)
        setNewActivity(true)
        setActivityHeader('Add New Activity')
        setShowActivityModel(true)
    }

    const handleEditActivity = (id) => {
        const editLog = activitylogs[activeScheduledTab].find(item => item.documentID === id);
        const logVM = Object.assign({}, objActivitylog);
        for (let [key, value] of Object.entries(editLog)) {
            if (logVM[key] !== undefined)
                logVM[key] = value;
        }
        setNewActivitylog(logVM)
        setEnquiryObj(editLog.enquiry)
        setTradeinProObj(editLog.tradeinPro)
        setContactObj(editLog.contact)
        setStockObj(editLog.stock)
        setNewActivity(false)
        setActivityHeader('Edit Activity')
        setShowActivityModel(true)
    }

    const handleActivityModelDelete = (objLog) => {
        setEnquiryObj(null)
        setTradeinProObj(null)
        setContactObj(null)
        setNewActivitylog(null);
        setShowActivityModel(false)
        if (!_.isEmpty(objLog)) {
            dispatch({
                type: "REMOVE_LIST",
                data: objLog,
                activetab: activeScheduledTab
            });
        }
    }

    const handleActivityModelClose = (objLog, isActivtyAlreadyDone) => {
        setEnquiryObj(null)
        setTradeinProObj(null)
        setContactObj(null)
        setStockObj(null)
        setNewActivitylog(null);
        setShowActivityModel(false)
        // if (!_.isEmpty(objLog)) {
        //     const oldLog = activitylogs[activeScheduledTab].find(item => item.documentID === objLog.documentID);
        //     if (_.isEmpty(oldLog) || oldLog.enquiryID !== objLog.enquiryID || oldLog.contactID !== objLog.contactID || oldLog.stockID !== objLog.stockID) {
        //         getActivitybyID(objLog.documentID)
        //     }
        //     else {
        //         objLog.dueDate = moment.unix(objLog.startDate.seconds).format('DD/MM/YYYY');
        //         objLog.dueTime = moment.unix(objLog.startDate.seconds).format('hh:mm A') + ' - ' + moment.unix(objLog.endDate.seconds).format('hh:mm A');
        //         onDocUpdate(objLog)
        //     }
        // }
        if (objLog) {
            if (objLog.isDone === true && !isActivtyAlreadyDone) {
                if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                    Swal.fire({
                        title: CommonHelper.showLocale(props, 'Please select'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                        cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                            .set({
                                bdcStatus: result.value ? 'show' : 'noshow'
                            }, { merge: true })
                        shedulenextactivity(objLog);
                        if (result.value)
                            logWalkin(objLog.enquiryID)

                        CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, props.dealersettings);
                    });
                }
                else {
                    shedulenextactivity(objLog);
                }
            }
        }
    }


    const logWalkin = (enquiryID) => {
        const objData = Object.assign({}, objWalkIn);
        objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.clientID = props.dealersettings.client.id;
        objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        objData.enquiryID = enquiryID;
        objData.walkinDate = window.firebase.firestore.Timestamp.now();

        const refOnjData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData);
        CommonHelper.logFirstWalkin(enquiryID, objData.walkinDate, props.dealersettings);
    }

    const handleCompleteActivityContinue = (id, isDone, activityOutcome) => {
        const objLog = activitylogs[activeScheduledTab].find(item => item.documentID === id);

        // check mandActivityNotes
        if (CommonHelper.isValidActivityNotesCheck(objLog, isDone, props.dealersettings) === false) {

            const logVM = Object.assign({}, objActivitylog);
            for (let [key, value] of Object.entries({ ...objLog, 'isDone': isDone, 'isFromComplete': true })) {
                if (logVM[key] !== undefined)
                    logVM[key] = value;
            }
            setNewActivitylog(logVM)
            setEnquiryObj(objLog.enquiry)
            setTradeinProObj(objLog.tradeinPro)
            setContactObj(objLog.contact)
            setStockObj(objLog.stock)
            setNewActivity(false)
            setActivityHeader('Edit Activity')
            setShowActivityModel(true)

            return;
        }

        let previousIsDone = objLog.isDone
        delete objLog.searchUsers;
        if (!_.isEmpty(objLog)) {
            objLog.isDone = isDone
            if (activityOutcome) {
                objLog.activityOutcome = activityOutcome
            }
            if (!activityOutcome && previousIsDone === true && isDone === false) {
                objLog.activityOutcome = null
            }
            dispatch({
                type: "UPDATE_DONE_LIST",
                data: objLog,
                activetab: activeScheduledTab
            });
            // const logVM = Object.assign({}, objActivitylog);
            // for (let [key, value] of Object.entries(objLog)) {
            //     if (logVM[key] !== undefined)
            //         logVM[key] = value;
            // }
            firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`)
                .set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    isDone: isDone,
                    activityOutcome: objLog.activityOutcome ? objLog.activityOutcome : null
                }, { merge: true })
                .then(snapshot => {
                    // logVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                    // logVM.modifiedBy = localStorage.uid;
                    // logVM.isDone = isDone;
                    // logVM.dueDate = moment.unix(objLog.startDate.seconds).format('DD/MM/YYYY');
                    // logVM.dueTime = moment.unix(objLog.startDate.seconds).format('hh:mm A') + ' - ' + moment.unix(objLog.endDate.seconds).format('hh:mm A');
                    //onDocUpdate(logVM)

                    toast.notify('Log updated successfully', {
                        duration: 2000
                    })

                    //update enquiry modified date if activity is mark as done
                    if (!_.isEmpty(objLog.enquiryID) && objLog.isDone === true) {
                        let objUpdate = {
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                            unactionedNotify: enqUnActionStatus.COMPLETED,
                        }
                        // if (objLog.inbound === true) {
                        //     objUpdate = {
                        //         ...objUpdate,
                        //         bdcStatus: 'show'
                        //     }
                        // }
                        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                            .set(objUpdate, { merge: true })
                    }
                    if (objLog.isDone === true) {
                        if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                            Swal.fire({
                                title: CommonHelper.showLocale(props, 'Please select'),
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                                cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                                    .set({
                                        bdcStatus: result.value ? 'show' : 'noshow'
                                    }, { merge: true })
                                shedulenextactivity(objLog);
                                if (result.value)
                                    logWalkin(objLog.enquiryID)
                                CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, props.dealersettings);
                            });
                        }
                        else {
                            shedulenextactivity(objLog);
                        }
                    }

                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }

    }

    const handleCompleteActivity = (id, isDone) => {
        const objLog = activitylogs[activeScheduledTab].find(item => item.documentID === id);
        const { dealersettings } = props
        if (
            dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings &&
            !_.isEmpty(dealersettings.client.settings.activityOutcome) &&
            dealersettings.client.settings.activityOutcome.filter(item => item.active === true).length &&
            !objLog.isDone && isDone &&
            objLog.subType && objLog.subType === 'call'
        ) {
            const isMandatory = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.mandActivityOutcome) ? true : false);
            const activtyOutcomes = [];
            _.orderBy(
                dealersettings.client.settings.activityOutcome.filter(
                    item => item.active === true,
                ),
                ['name'],
                ['asc'],
            ).forEach((data, index) => {
                activtyOutcomes.push({
                    value: data.value,
                    name: data.name,
                    label: data.name,
                    price: data.price,
                });
            });
            // console.log('activtyOutcomes', activtyOutcomes)
            // console.log('isMandatory', isMandatory)
            setShowOutcomeModal({
                show: true,
                clsActive: 'overlay-modal active',
                isMandatory,
                activtyOutcomes,
                data: { id, isDone }
            });
        } else {
            handleCompleteActivityContinue(id, isDone);
        }
    }

    const handleCloseOutcomeModal = (isMandatory, selectedStatus, data) => {
        // console.log('handleCloseOutcomeModal', isMandatory, selectedStatus, data)
        if (isMandatory) {
            if (selectedStatus) {
                handleCompleteActivityContinue(data.id, data.isDone, selectedStatus)
            } else {
                setShowOutcomeModal({
                    show: false,
                    clsActive: '',
                    isMandatory: false,
                    activtyOutcomes: null,
                    data: {}
                });
                return
            }
        } else {
            if (selectedStatus) {
                handleCompleteActivityContinue(data.id, data.isDone, selectedStatus)
            } else {
                handleCompleteActivityContinue(data.id, data.isDone,)
            }
        }
        setShowOutcomeModal({
            show: false,
            clsActive: '',
            isMandatory: false,
            activtyOutcomes: null
        });
    }

    const shedulenextactivity = async (objLog) => {
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.clientSettings && props.dealersettings.client.clientSettings.isScheduleNextActivity) {
            let _isAlreadyScheduled = false;
            if (objLog.enquiryID || objLog?.enquiry?.documentID) {
                const responseMKA = await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'activity')
                    .where('isDone', '==', false)
                    .limit(1)
                    .get();
                if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
            }
            if (_isAlreadyScheduled) return;

            const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(props.dealersettings, objLog?.enquiry);
            
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Schedule next activity?'),
                text: CommonHelper.showLocale(props, 'Do you want to schedule next activity?'),
                icon: 'info',
                showCancelButton: mandScheduleNextActivity ? false : true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    setShowScheduleActivityModel({
                        activityLog: {
                            ...objLog,
                            inbound: null
                        },
                        show: true,
                        contact: objLog.contact ? objLog.contact : null,
                        enquiry: objLog.enquiry ? objLog.enquiry : null,
                    })
                }
            })
        }
    }

    const handleScheduledActivityModelClose = () => {
        setShowScheduleActivityModel({
            show: false,
            activityLog: null,
            contact: null,
            enquiry: null
        })
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ activityFields: fields }, { merge: true })
            .then(() => {
                setActivityFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('activityFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            handleEditActivity(id)
        }}>
            <i className="ico icon-edit"></i>
        </div>)
    }

    const handleContactdetailsClick = (e, id) => {
        e.preventDefault();
        handleEditActivity(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeScheduledTab]: true
        })
    }

    const handleActivityFilterApply = () => {
        if (!_.isEmpty(localStorage.eventFilter) && !_.isEmpty(localStorage.activityFilter)) {
            let response = JSON.parse(localStorage.activityFilter);
            let activeList = JSON.parse(localStorage.eventFilter);
            if (response.value && activeList) {
                if (activeList.some(e => e !== 'all'))
                    response.value['subType'] = JSON.parse(localStorage.eventFilter).join(',')
                else
                    response.value['subType'] = "";
                handleApplyFilter(response);
            }
        }
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('activityFilter', JSON.stringify(_filter));
            localStorage.eventSearchText = '';
            setSearchText('');
            setFilterApplied(true);
            setDataload({
                ...defaultDataload,
                [activeScheduledTab]: true
            })
            setLoader({
                ...defaultDataload,
                [activeScheduledTab]: true
            })
            setHasMoreData([])
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.activityFilter)) {
            localStorage.removeItem('activityFilter');
            localStorage.eventSearchText = '';
            setSearchText('');
            setFilterApplied(false);
            setDataload({
                ...checkDataLoad,
                [activeScheduledTab]: true
            })
            setHasMoreData([])
            setActivetab(['all']);
            localStorage.eventFilter = JSON.stringify(['all']);
        }
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(activitylogs[activeScheduledTab]) && activitylogs[activeScheduledTab].map(file => {
                let record = Object.assign({}, activitylogs[activeScheduledTab].filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, activitylogs[activeScheduledTab].filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleShowTransfer = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select activity to transfer.'));
            return;
        }
        else {
            setModalTransferShow(true)
        }
    }

    const handleCloseTransfer = async (owner) => {
        if (owner) {

            let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            let modifiedDate = window.firebase.firestore.Timestamp.now();
            const batch = window.firebase.firestore().batch();
            let _selectedIds = Object.assign({}, selectedIds);
            for (let [key] of Object.entries(_selectedIds)) {
                await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .doc(key)
                    .update({
                        owner: owner,
                        modifiedBy: modifiedBy,
                        modifiedDate: modifiedDate
                    }).then((docRef) => {

                    }).catch((error) => {
                        console.error("Error updating contactDetails: ", error);

                    });
            }

            batch.commit()
                .then(() => {
                    setSelectedIds({});
                    setModalTransferShow(false)
                    toast.notify('Activities transferred successfully', {
                        duration: 2000
                    })
                })
                .catch((error) => {
                    console.log(error);
                    setModalTransferShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalTransferShow(false);
        }
    }

    const excelDownload = async () => {
        const { dealersettings } = props;
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let _activityFilter = localStorage.activityFilter ? JSON.parse(localStorage.activityFilter) : {};
        let _filter = Object.assign({}, _activityFilter.value);
        _filter.type = CommonHelper.getActivityType('activity');

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
            delete _filter['date'];
        }

        if (!viewOtherActivity)
            _filter.owner = localStorage.uid;

        if (!_.isEmpty(_filter.subType)) {
            setActivetab(_filter.subType.split(','))
            localStorage.eventFilter = JSON.stringify(_filter.subType.split(','));
        }
        else if (_.isEmpty(_filter.subType) && activetab && !_.isEmpty(activetab.some(e => e !== 'all'))) {
            _filter.subType = activetab.join(',');
        }

        if (_modules.includes(localStorage.defaultModule))
            _filter.module = localStorage.defaultModule
        else if (accessToFinanceActivity || accessToAfterMarketActivity) {
            let _type = ['activity']
            if (accessToFinanceActivity) _type.push('financeactivity')
            if (accessToAfterMarketActivity) _type.push('afterMarketactivity')
            _filter.type = _type.join(',');
        }

        //_filter = CommonHelper.setLevelIDs(_filter, dealersettings, null, null);
        _filter.searchType = activeScheduledTab;
        _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
        let _searchObject = {
            "type": "searchActivities",
            "filters": JSON.stringify(_filter),
            "sortOrder": "startDate desc",
            "pageNum": 0,
            "pageLimit": 10000
        }
        if (localStorage.defaultModule === 'tradeinPro') {
            _searchObject.clientID = _filter.clientID ? _filter.clientID : CommonHelper.getTradeinProDealers(dealersettings, false, true);
        }
        else {
            _searchObject = CommonHelper.setLevelIDs(_searchObject, dealersettings, null, null);
        }
        //console.log('generic-searchData-export', _searchObject)

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        const searchContacts = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchContacts(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = convertLogVM(doc);
                _enquiries.push(logVM)
            });
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from calendar',
                type: 'excelExport',
                subType: 'activities',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
        }

    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                excelDownload();
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    // const activityList = useMemo(() => activitylogs
    //     .filter(
    //         ({ subType }) => (activetab !== 'all' ? subType === activetab : true)
    //     )
    //     .sort(function (x, y) {
    //         return (x.startDate.seconds < y.startDate.seconds ? -1 : 1);
    //     }), [activetab, activitylogs,activeScheduledTab])
    const activityList = Object.assign([], (activitylogs[activeScheduledTab] ? (activeScheduledTab === 'Completed' ?
        activitylogs[activeScheduledTab].sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }) :
        activitylogs[activeScheduledTab].sort(function (x, y) {
            return (x.startDate.seconds < y.startDate.seconds ? -1 : 1);
        })) : []));
    return (
        <div className="middle-wrapper">
            <FilterPanel
                handleAddActivity={handleAddActivity}
                searchText={searchText}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setDataload({
                        ...checkDataLoad,
                        [activeScheduledTab]: true
                    })
                    setHasMoreData([...hasMore.filter(e => e.type !== activeScheduledTab)]);
                }}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                handleTransfer={handleShowTransfer}
                showMore={true}
                csvHeader={csvHeader}
                datalist={activityList}
                csvData={csvData}
                excelDownload={excelDownload}
                csvBtn={csvBtn}
                history={props.history}
                isOutlookCalendarExpired={isOutlookCalendarExpired}
            />
            <div className="filter-sub-panel">
                <div className="float-left">
                    <FilterType activetab={activetab} handlechangefilter={handlechangefilter} handleuncheckfilter={handleuncheckfilter} dealersettings={props.dealersettings} />
                </div>
                <div className="float-right">
                    <div className="tabber-nav">
                        <ul>
                            <li><a className={`${activeScheduledTab === 'Overdue' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveScheduledTab('Overdue');
                                }}><Translate text={'overdue'} /> </a></li>
                            <li><a className={`${activeScheduledTab === 'Today' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveScheduledTab('Today')
                                }}
                            > <Translate text={'today'} /> </a></li>
                            <li><a className={`${activeScheduledTab === 'Upcoming' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveScheduledTab('Upcoming')
                                }}
                            > <Translate text={'upcoming'} /> </a></li>
                            <li><a className={`${activeScheduledTab === 'Completed' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveScheduledTab('Completed')
                                }}
                            > <Translate text={'completed'} /> </a></li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="calendar-panel">
                {
                    activityLoader[activeScheduledTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        activitylogs[activeScheduledTab] && activitylogs[activeScheduledTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={true}
                                    datalist={activityList}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={allactivityFields}
                                    handleSavesettings={handleSavesettings}
                                    dynamicFields={(activityFields && activityFields.length > 0) ? activityFields : allactivityFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleContactdetailsClick}
                                    isSettings={true}
                                    hasMore={hasMore.find(e => e.type === activeScheduledTab)}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    handleCompleteActivity={handleCompleteActivity}
                                    activityTab={activeScheduledTab}
                                    selectedIds={selectedIds}
                                    handleAllChecked={handleAllChecked}
                                    handleCheckChange={handleCheckChange}
                                    dealersettings={props.dealersettings}
                                    displayByValue={true}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'noActivitiesMsg'} /></p>
                                    <button className="btn btn-sm btn-default" onClick={() => { handleAddActivity(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                        <Translate text={'Schedule an activity'} />
                                    </button>
                                </div>  </div>
                        )
                }


            </div>
            <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={newActivitylog}
                    enquiry={enquiryObj}
                    tradeinPro={tradeinProObj}
                    contact={contactObj}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={isNewActivity}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showScheduleActivityModel.show}>
                <AddActivity
                    show={showScheduleActivityModel.show}
                    handleClose={handleScheduledActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={'Add New Activity'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={showScheduleActivityModel.activityLog}
                    tradeinPro={tradeinProObj ? tradeinProObj : showScheduleActivityModel.tradeinPro ? showScheduleActivityModel.tradeinPro : null}
                    enquiry={enquiryObj ? enquiryObj : showScheduleActivityModel.enquiry ? showScheduleActivityModel.enquiry : null}
                    contact={contactObj ? contactObj : showScheduleActivityModel.contact ? showScheduleActivityModel.contact : null}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={true}
                    isScheduleActivity={true}
                >
                </AddActivity>
            </PopUpModal>

            <PopUpModal show={modalTransferShow}>
                <TransferOwner
                    show={modalTransferShow}
                    handleClose={handleCloseTransfer}
                    title={'Transfer Activities'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </TransferOwner>
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
            <PopUpModal show={showOutcomeModal.show}>
                <ActivityStatus
                    show={showOutcomeModal.show}
                    isMandatory={showOutcomeModal.isMandatory}
                    activtyOutcomes={showOutcomeModal.activtyOutcomes}
                    data={showOutcomeModal.data}
                    handleClose={handleCloseOutcomeModal}
                    title={'Select Activity Outcome'}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
            <PopUpModal show={showUpgradeModal}>
                <UpgradeModal
                    show={showUpgradeModal}
                    handleClose={() => {
                        setShowUpgradeModal(false);
                    }}
                />
            </PopUpModal>
        </div>
    )
}

export default CalendarListView;