import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { InputCheckBox, ReactSelect, PopUpModal, ContentOverlay } from '../../../../components'
import Translate from '../../../../constants/translate';
import { default as _images } from "../../../../images";
import AddGroup from './addGroups'

/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../../../components/customdropdown';
import { ClientContext } from '../clientContext'
import CommonHelper from '../../../../services/common';
const GroupList = (props) => {
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groupLoader, setGrouploader] = useState(false)
    const [clientLoader, setClientloader] = useState(false)
    const [showAddGroupModel, setShowAddGroupModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [editGroup, setEditGroup] = useState(null)
    const [oemGroups, setOEMGroups] = useState([])

    const { groups, clients } = useContext(ClientContext);

    useEffect(() => {
        if (props.activeKey === 'clients') {
            function handleResize() {
                //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 204) + 'px');
                document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 344) + 'px');
            }
            window.addEventListener('resize', handleResize);
            //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 204) + 'px');
            document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 344) + 'px');
            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }
    }, [props.activeKey])

    useEffect(() => {
        if (!_.isEmpty(groups)) {
            var _groups = [];
            groups.forEach(rec => {
                _groups.push({
                    ...rec,
                    'active': (_.find(oemGroups, { 'documentID': rec.documentID }) ? _.find(oemGroups, { 'documentID': rec.documentID }).active : false)
                });
            })
            if (_groups.length > 0 && !_groups.some(item => item.active === true)) {
                _.find(_groups, { 'documentID': _groups[0].documentID }).active = true;

            }
            setSelectedGroup(_groups.find(item => item.active === true))
            setOEMGroups(_groups)
        }
    }, [groups])

    const handlegroupChange = (group) => {
        if (!_.isEmpty(group)) {
            setOEMGroups([..._.forEach(oemGroups, function (obj) {
                if (obj.documentID === group.documentID)
                    _.set(obj, 'active', true);
                else
                    _.set(obj, 'active', false);
            })]);
            setSelectedGroup(group)
        }
    }

    const handleModelClose = () => {
        setShowAddGroupModel(false);
    }

    const handleeditGroup = (group) => {
        setPopHeader('Edit Group')
        setEditGroup(group)
        setShowAddGroupModel(true)
    }

    const handledeleteGroup = (group) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${group.documentID}`).delete()
                    .then(snapshot => {
                        clients.filter(item => item.group === group.documentID).forEach(_client => {
                            window.firebase.firestore().doc(`clients/${_client.documentID}`).set({ 'group': null }, { merge: true })
                        })
                        toast.notify('Group deleted successfully', {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        //setSectionLoading(false)
                        toast.notify('Something went wrong', {
                            duration: 2000,
                            position: 'top-right'
                        })
                    });
            }
        })
    }

    const handleaddClient = (e, data) => {
        if (e) {
            window.firebase.firestore().doc(`clients/${e.value}`).set({ 'group': selectedGroup.documentID }, { merge: true })
            window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedGroup.documentID}`)
                .set({
                    'client_ids': selectedGroup.client_ids ? [...selectedGroup.client_ids.filter(a => a !== e.value), e.value] : [e.value],
                    'projectId': e?.doc?.projectId ? e.doc.projectId : null
                }, { merge: true })
        }
    }

    const handleswitchGroup = (group, client) => {
        window.firebase.firestore().doc(`clients/${client.documentID}`).set({ 'group': group.documentID }, { merge: true })
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedGroup.documentID}`)
            .set({ 'client_ids': selectedGroup.client_ids ? [...selectedGroup.client_ids.filter(a => a !== client.documentID)] : [] }, { merge: true })
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${group.documentID}`)
            .set({ 'client_ids': group.client_ids ? [...group.client_ids.filter(a => a !== client.documentID), client.documentID] : [client.documentID] }, { merge: true })
    }

    const handledeleteClientGroup = (client) => {
        window.firebase.firestore().doc(`clients/${client.documentID}`).set({ 'group': null }, { merge: true })
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedGroup.documentID}`)
            .set({ 'client_ids': selectedGroup.client_ids ? [...selectedGroup.client_ids.filter(a => a !== client.documentID)] : [] }, { merge: true })
    }

    const handleSettingsChange = (e, value) => {
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedGroup.documentID}`)
            .set({ [value]: e.target.checked }, { merge: true })
            .then(snapshot => {
                // //update settings date and priority
                selectedGroup.client_ids && selectedGroup.client_ids.forEach(rec => {
                    window.firebase.firestore().doc(`clients/${rec}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Group Settings has been updated',
                        }, { merge: true })
                })
                //end

                // setPermissionloader(false);
                //props.updateDealerSettings('rolePermissions', selectedRole);
                toast.notify('Settings updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000,
                    position: 'top-right'
                })
            });
    }

    return (
        <>
            <div className="settings-column-wraper">
                <div className="settings-columns">
                    <div className="settings-column-left">
                        <div className="settings-column-title">
                            <div className="column-add-button"><a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setEditGroup(null); setShowAddGroupModel(true); setPopHeader('Add Group') }}> <i className="ico icon-add"></i></a></div>
                            <h3><Translate text={'Groups'} /></h3>
                        </div>
                        <div className="settings-column-nav">
                            {
                                groupLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : groups.length > 0 ? (<ul>
                                    {
                                        oemGroups.map((rec, index) => {
                                            return <li key={index} className={`${rec.active === true ? 'active' : ''}`}>
                                                <Dropdown className="role-list-items-more" alignRight>
                                                    <Dropdown.Toggle as={CustomToggle} className="dropdown role-list-items-more-fix">
                                                        <i className="ico icon-more"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                        <Dropdown.Item eventKey="delete" onClick={(e) => { handleeditGroup(rec) }}><i className="ico icon-edit"></i>  <Translate text={'edit'} /></Dropdown.Item>
                                                        <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteGroup(rec) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <a href="#" className="column-list-left"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handlegroupChange(rec);
                                                    }}
                                                >{rec.name}
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>) : (<></>)
                            }
                        </div>
                    </div>
                    <div className="settings-column-middle">
                        <div className="settings-column-title">
                            <h3><Translate text={'Clients'} /></h3>
                        </div>
                        {
                            !_.isEmpty(selectedGroup) ? (
                                <>
                                    <div className="user-list">
                                        <p><Translate text={'Add clients to'} /> '{selectedGroup.name}'</p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ReactSelect
                                                    options={_.map(clients.filter(item => _.isEmpty(item.group)), function (c) {
                                                        return {
                                                            value: c.documentID,
                                                            doc: c,
                                                            label: c.name,
                                                            active: _.isBoolean(c.active) ? c.active : true,
                                                        };
                                                    })}
                                                    name={"clientID"}
                                                    placeholder={'select client'}
                                                    onChange={handleaddClient}
                                                    value={''}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !_.isEmpty(selectedGroup) ? clients.filter(item => item.group === selectedGroup.documentID).length === 0 ? (<><div className="common-table"><div className="text-center p-5"><p><Translate text={'select client'} /></p></div></div></>) : (<div className="user-list-items">
                                            <ul>
                                                {
                                                    clients.filter(item => item.group === selectedGroup.documentID).map((rec, index) => {
                                                        return <li key={index} className="user-list-box">
                                                            <Dropdown className="user-list-items-more" drop={'left'}>
                                                                <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                                                    <i className="ico icon-more"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                                    {
                                                                        oemGroups.filter(item => item.active === false).length > 0 ? (
                                                                            <Dropdown.Header className='nav-item-header nav-item text-left'>
                                                                                <Translate text={'Switch Group'} />
                                                                            </Dropdown.Header>
                                                                        ) : (<></>)
                                                                    }
                                                                    {
                                                                        oemGroups.filter(item => item.active === false).map((_group, index) => {
                                                                            return <Dropdown.Item key={index} eventKey={index} onClick={(e) => { handleswitchGroup(_group, rec) }}> {_group.name} </Dropdown.Item>
                                                                        })
                                                                    }
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteClientGroup(rec) }}><i className="ico icon-delete"></i> <Translate text={'remove'} /> </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <div className="user-list-items-info">
                                                                <h4>{rec.name}</h4>
                                                                <h5>{_.find(props.dealersettings.countries, { 'code': rec.country }) ? _.find(props.dealersettings.countries, { 'code': rec.country }).name : ''}</h5>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>) : (<></>)
                                    }
                                </>) : (<></>)
                        }
                    </div>
                    <div className="settings-column-right">
                        <div className="settings-column-title">
                            <h3><Translate text={'settings'} /></h3>
                        </div>
                        <div className={`permission-list roles-nav-left`}>
                            {
                                !_.isEmpty(selectedGroup) ? (<>
                                    <ul className={``}>
                                        <li>
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    id={'enableSharedContact'}
                                                    className={`switch`}
                                                    name={'enableSharedContact'}
                                                    checked={Boolean(selectedGroup.enableSharedContact)}
                                                    onChange={(e) => { handleSettingsChange(e, 'enableSharedContact') }} />
                                                <label htmlFor={`enableSharedContact`}></label>
                                            </span>
                                            <label htmlFor={`enableSharedContact`} className="cursor-pointer mb-0"><Translate text={'Enable Shared Contact Database'} /></label>
                                        </li>
                                        <li>
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    id={'enableSharedStock'}
                                                    className={`switch`}
                                                    name={'enableSharedStock'}
                                                    checked={Boolean(selectedGroup.enableSharedStock)}
                                                    onChange={(e) => { handleSettingsChange(e, 'enableSharedStock') }} />
                                                <label htmlFor={`enableSharedStock`}></label>
                                            </span>
                                            <label htmlFor={`enableSharedStock`} className="cursor-pointer mb-0"><Translate text={'Enable Shared Stock'} /></label>
                                        </li>
                                        <li>
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    id={'enableLeadTransfer'}
                                                    className={`switch`}
                                                    name={'enableLeadTransfer'}
                                                    checked={Boolean(selectedGroup.enableLeadTransfer)}
                                                    onChange={(e) => { handleSettingsChange(e, 'enableLeadTransfer') }} />
                                                <label htmlFor={`enableLeadTransfer`}></label>
                                            </span>
                                            <label htmlFor={`enableLeadTransfer`} className="cursor-pointer mb-0"><Translate text={'Enable Dealer Lead Transfer'} /></label>
                                        </li>
                                    </ul>
                                </>) : (<></>)
                            }
                        </div>
                    </div>

                </div>
            </div>
            <PopUpModal show={showAddGroupModel}>
                <AddGroup
                    {...props}
                    show={showAddGroupModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    group={editGroup}
                    allGroups={groups}
                />
            </PopUpModal>
        </>
    );
}

export default GroupList;