import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap'
import AddOns from "./addOns";

const ModuleSettings = () => {
    return (<>
        <Tab.Container defaultActiveKey="addons" mountOnEnter={true}>
            <div className="settings-submenu">
                <h4>Module Settings</h4>
                <Nav as="ul" bsPrefix="ul-nav">
                    <Nav.Item as="li" bsPrefix="li-nav">
                        <Nav.Link eventKey="addons" bsPrefix="li-a-nav">Add-ons</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div className="settings-container mb-0 h-100">
                <div className="settings-container-sub" >
                    <Tab.Pane eventKey="addons">
                        <AddOns />
                    </Tab.Pane>
                </div>
            </div>

        </Tab.Container>

    </>);
}

export default ModuleSettings;