/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** PROVIDERS */
/** VIEW-MODELS */

/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { PopUpModal, PopoverListLoader } from '../../../components';

import { default as _images } from '../../../images';


import ContactListReducer from '../../contacts/contactlistReducer'
import { firestoreDB } from '../../../services/helper';
import { eventStatus } from '../../../services/enum';

const EventsProList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [contacts, dispatch] = useReducer(ContactListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [hasMore, setHasMoreData] = useState([])

    const [isPaging, setPaging] = useState(false)
    const [contactLoader, setContactLoader] = useState(true)

    const pageLimit = 50

    const [titles, setTitles] = useState([])


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubContactList && window.unSubContactList();
            //console.log('pageUNLoad', myContact, props.dealersettings.client.id, searchText, hasMore)
        }
    }, [])

    useEffect(() => {
        const { dealersettings, clientID } = props;

        if (!checkDataLoad) {
            return;
        }

        if (!clientID) {
            setDataload(false)
            setContactLoader(false)
            setPaging(false)
            return;
        }

        let contactlstIdxDataRef = firestoreDB(dealersettings, true).firestore().collection('eventsPro')
            //.where('clientIDs', 'array-contains-any', [clientID])
            .where('status', 'in', [eventStatus.DRAFT, eventStatus.LIVE, eventStatus.PUBLISHED])
            .where('isDeleted', '==', false)

        if (_.trim(searchText) && _.trim(searchText).length > 2) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
            if (formattedSearchText.trim())
                contactlstIdxDataRef = contactlstIdxDataRef.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
        }
        else {
            contactlstIdxDataRef = contactlstIdxDataRef.where('clientIDs', 'array-contains-any', [clientID])
        }

        if (hasMore.length > 0) {
            contactlstIdxDataRef = contactlstIdxDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            contactlstIdxDataRef = contactlstIdxDataRef
                .limit(pageLimit)
        }

        contactlstIdxDataRef
            //.onSnapshot(onCollectionUpdate);
            .get().then(querySnapshot => {
                let _contacts = [];

                if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                    setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
                }
                else {
                    setHasMoreData([]);
                }
                querySnapshot.docs.forEach((doc) => {
                    var logVM = CommonHelper.convertEventsProVM({
                        ...doc.data(),
                        documentID: doc.id
                    }, props);
                    _contacts.push(logVM)
                });

                if (_.trim(searchText) && _.trim(searchText).length > 2) {
                    let formattedSearchText = CommonHelper.parseSearchText(props.dealersettings, searchText)
                    if (_.trim(formattedSearchText))
                        _contacts = _contacts.filter(a => a?.clientIDs?.includes(props.clientID))
                }

                if (isPaging) {
                    dispatch({
                        type: "APPENDCONTACT",
                        data: _contacts,
                        sortName: 'addedDate',
                        sortOrder: 'desc'
                    });
                }
                else {
                    dispatch({
                        type: "SUCCESS",
                        data: _contacts,
                        sortName: 'addedDate',
                        sortOrder: 'desc'
                    });
                }

                setDataload(false)
                setContactLoader(false)
                setPaging(false)

            });
        //  return () => {
        //      window.unSubContactList();
        //  }

    }, [checkDataLoad])

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }


    const listenScrollEvent = (e) => {
        if (hasMore.length === 0) return;
        //console.log('listenScrollEvent', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)
        if (e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight) {
            //console.log('listenScrollEvent')
            //handlePagination();
        }
        return;
    }

    const handleEventSelect = (_contact) => {
        props.handleClose(_contact.objDoc);

    }


    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative"
    };
    const eventsProStatus = props.dealersettings?.client?.eventsPro?.eventsProStatus;
    return (
        <>

            <Modal
                id="appraisal-List"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-terms">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Events'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className={`vehicle-popover-head pr-0`}>
                            <input
                                type="text"
                                aria-label="Search"
                                placeholder="Search..."
                                value={searchText ? searchText : ''}
                                autoComplete="off"
                                onChange={e => {
                                    let _value = e.target.value;
                                    if (!_value)
                                        setSearchText('');
                                    else
                                        setSearchText(_value);

                                    if (_.trim(_value) && _.trim(_value).length > 2)
                                        setContactLoader(true);

                                    setTimeout(() => {
                                        setHasMoreData([]);
                                        setDataload(true);
                                    }, 1000);


                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchText(searchText)
                                        setHasMoreData([]);
                                        setDataload(true)
                                        setContactLoader(true)
                                    }
                                }}
                            />

                        </div>

                        <Suspense>
                            <div style={divStyle} onScroll={listenScrollEvent}>
                                {
                                    contactLoader ? (<PopoverListLoader />) :
                                        (contacts && contacts.length) > 0
                                            ?
                                            <>
                                                {
                                                    _.orderBy(contacts, 'addedDate.seconds', 'desc').map((currentEvent, index) => {

                                                        return <div key={index} className='events-dashboard-livewrap mb-2' id={`attID-${currentEvent.documentID}`} onClick={(e) => {
                                                            e.preventDefault();
                                                            handleEventSelect(currentEvent);
                                                        }}>
                                                            {(() => {

                                                                let _objCurrentStatus = eventsProStatus.filter(e => e.value === currentEvent?.statusValue)[0];
                                                                return <div className="events-dashboard-live-flex" id={`evtID-${currentEvent.documentID}`}>
                                                                    <div className="events-dashboard-live-image">
                                                                        {
                                                                            currentEvent.imageURL ? (
                                                                                <img src={currentEvent.imageURL} alt="" width="70" height="70" />
                                                                            ) : (
                                                                                <img src={_images.noEvent} alt="" width="70" height="70" />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="events-dashboard-live-data">
                                                                        <div className="events-dashboard-card-title">{currentEvent.title}</div>
                                                                        <div className="events-dashboard-card-sub"><i className="ico icon-location"></i> {currentEvent.location} </div>
                                                                        <div className="events-dashboard-card-sub">
                                                                            <i className="ico icon-date"></i> {CommonHelper.showCombineDate(currentEvent.startDate, currentEvent.endDate)}
                                                                        </div>
                                                                        <div className="events-dashboard-card-sub">
                                                                            <i className="ico icon-time"></i> {CommonHelper.showCombineTime(currentEvent.startDate, currentEvent.endDate)}
                                                                        </div>
                                                                        <div className="events-dashboard-card-sub justify-content-between">

                                                                            {
                                                                                currentEvent.statusValue ? (
                                                                                    <>
                                                                                        {
                                                                                            _objCurrentStatus
                                                                                                ?
                                                                                                <>
                                                                                                    <div
                                                                                                        className={`badge badge-pill badge-empty`}
                                                                                                        style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                                                    >
                                                                                                        {_objCurrentStatus.name}
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <><div className={`badge badge-pill badge-status-${currentEvent.statusValue.toLowerCase()}`} >{currentEvent.status} </div></>
                                                                                        }
                                                                                    </>
                                                                                ) : (<></>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })()}
                                                        </div>

                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            {/* <i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, "ico icon-tradein-module-icon")}></i>  */}
                                                        </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'No Events Found'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div>
                        </Suspense>

                    </>
                </Modal.Body>
                {
                    hasMore && hasMore.length > 0 ? (
                        <Modal.Footer >
                            <div className="load-more-button pb-2"> <a href="#" className="mt-1" onClick={(e) => {
                                e.preventDefault();
                                handlePagination();
                            }}><Translate text={'Load More'} />
                                {
                                    !_.isEmpty(checkDataLoad) ? (
                                        <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                    ) : (<i className="fa fa-angle-down" aria-hidden="true"></i>)
                                }

                            </a></div>
                        </Modal.Footer>

                    ) : (<></>)
                }
            </Modal>


        </>
    )
}

export default EventsProList