export const objStock = {
    documentID: null,
    clientID: null,
    enquiryID: null,
    autograbID: null,
    //keywords: [],
    stockNo: '',
    make: '',
    model: '',
    modelDescription: '',
    saleType: '',
    type: '',
    group: '',
    year: '',
    regNo: '',
    regState: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    trim: '',
    miles: '',
    price: null,
    purchasePrice: null,
    location: '',
    regDate: null,
    regNoDate: null,
    regExp: null,
    warrantyStartDate: null,
    warrantyExp: null,
    inStockDate: null,
    stockInDate: null,
    buildDate: null,
    status: '', //stockStatus
    lastStatus: '',
    isLoan: false,
    isServiceLoan: false,
    isTestDrive: false,
    isSale: false,
    isStockArrived: false,
    images: [],
    testDriveDetail: null, //objTestDriveDetail
    fleetDetail: null, //objFleetDetail
    statusModifiedBy: '',
    tradePlate: '',
    usage: '',
    transmission: '',
    transportMethod: '',
    lastNote: '',
    lastNoteDate: null,
    soldDate: null,
    deliveredDate: null,
    etaDate: null,
    fokDate: null,
    marginValue: null,
    marginRetail: null,
    marginOrderFill: null,
    marginPayments: null,
    marginMarketing: null,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false,
    mclID: null,
    mclBuildSlot: null,
    mclSpecFreezeDate: null,
    mclCustomerName: null,
    priceDate: null
};


export const objRequirementStock = {
    documentID: null,
    clientID: null,
    autograbID: null,
    stockNo: '',
    make: '',
    model: '',
    modelDescription: '',
    saleType: '',
    type: '',
    year: '',
    regNo: '',
    regState: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    trim: '',
    miles: '',
    price: null,
    purchasePrice: null,
    location: '',
    regDate: null,
    regExp: null,
    warrantyStartDate: null,
    warrantyExp: null,
    stockInDate: null,
    buildDate: null,
    tradePlate: '',
    images: [],
    isLoan: false,
    isServiceLoan: false,
    isTestDrive: false,
    isSale: false,
    testDriveDetail: null, //objTestDriveDetail
    fleetDetail: null, //objFleetDetail
    //keywords: [],
};

export const objTestDriveDetail = {
    testdriveID: null,
    status: '', //testDriveStatus
    contact: null,
    addedBy: '',
    addedDate: null,
    startDate: null,
    expectedReturn: null
}

export const objFleetDetail = {
    fleetID: null,
    status: '', //fleetStatus
    contact: null,
    staff: null,
    addedBy: '',
    addedDate: null,
    startDate: null,
    expectedReturn: null
}

export const stockBasicVM = {
    documentID: null,
    clientID: null,
    enquiryID: null,
    autograbID: null,
    //keywords: [],
    images: [],
    stockNo: '',
    make: '',
    model: '',
    modelDescription: '',
    saleType: '',
    type: '',
    year: '',
    miles: '',
    regNo: '',
    regState: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    isLoan: false,
    isServiceLoan: false,
    isTestDrive: false,
    isSale: false,
    price: null,
    purchasePrice: null,
    location: '',
    regDate: null,
    regExp: null,
    warrantyStartDate: null,
    warrantyExp: null,
    stockInDate: null,
    buildDate: null,
    status: '', //stockStatus 
    lastStatus: '',
    statusModifiedBy: '',
    tradePlate: '',
    testDriveDetail: null, //objTestDriveDetail
    fleetDetail: null, //objFleetDetail
};

export const mandatoryFields = [
    'make',
    'model',
    //'type',
    'year',
    'saleType',
    'status',
    'stockNo',
];

export const allStockFieldsVM = [
    {
        name: 'Stock #',
        value: 'stockNo',
        group: 'Stock',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Make',
        value: 'make',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Model',
        value: 'model',
        group: 'Stock',
        flex: 1,
        default: true
    },
    // {
    //     name: 'Type',
    //     value: 'type',
    //     group: 'Stock',
    //     flex: 1,

    // },
    {
        name: 'Vehicle Group',
        value: 'group',
        group: 'Stock',
        flex: 1,

    },
    {
        name: 'Year',
        value: 'year',
        group: 'Stock',
        flex: 0.5,
        default: true
    },
    {
        name: 'Sale Type',
        value: 'saleType',
        group: 'Stock',
        flex: 0.5,
        default: true
    },
    {
        name: 'Reg #',
        value: 'regNo',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Available For',
        value: 'availability',
        group: 'Stock',
        elementName: 'availabileFor',
        flex: 1,
        default: true
    },
    {
        name: 'Status',
        value: 'status',
        group: 'Stock',
        elementName: 'statusName',
        subText: 'statusUpdatedBy',
        subTextTitle: 'Status Updated By',
        //nestedText: 'statusModifiedBy',
        //nestedTextTitle: 'Status Modified By',
        flex: 1,
        default: true
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Model Description',
        value: 'modelDescription',
        group: 'Stock',
        flex: 1,
        limit: 60
    },
    {
        name: 'Vehicle Type',
        value: 'type',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Exterior Color',
        value: 'extColor',
        group: 'Stock',
        flex: 1,
        limit: 60,
        default: true
    },
    {
        name: 'Interior Color',
        value: 'intColor',
        group: 'Stock',
        flex: 1,
        limit: 60,
        default: true
    },
    {
        name: 'Odometer',
        value: 'miles',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Price',
        value: 'price',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Purchase Price',
        value: 'purchasePrice',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'VBM Value',
        value: 'marginValue',
        group: 'VBM',
        flex: 1
    },
    {
        name: 'VBM Retail',
        value: 'marginRetail',
        group: 'VBM',
        flex: 1
    },
    {
        name: 'VBM Order Fill',
        value: 'marginOrderFill',
        group: 'VBM',
        flex: 1
    },
    {
        name: 'VBM Payments',
        value: 'marginPayments',
        group: 'VBM',
        flex: 1
    },
    {
        name: 'VBM Marketing',
        value: 'marginMarketing',
        group: 'VBM',
        flex: 1
    },
    {
        name: 'Location',
        value: 'location',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Usage',
        value: 'usage',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Transmission',
        value: 'transmission',
        flex: 1,
        group: 'Stock',
        default: true
    },
    {
        name: 'Transport Method',
        value: 'transportMethod',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Trade Plate',
        value: 'tradePlate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Registration Date',
        value: 'regDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Registration Expiry',
        value: 'regExp',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Warranty Start Date',
        value: 'warrantyStartDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExp',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Build Date',
        value: 'buildDate',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Stock Age',
        value: 'stockAge',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Stock Intake Date',
        value: 'stockInDate',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Sold Date',
        value: 'soldDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Delivered Date',
        value: 'deliveredDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Delivery Date',
        value: 'deliveryDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'ETA Date',
        value: 'etaDate',
        group: 'Stock',
        elementName: 'csvEtaDate',
        flex: 1
    },
    {
        name: 'Est Dealer FOK Date',
        value: 'fokDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Last Note',
        value: 'lastNote',
        group: 'Stock',
        flex: 1,
        limit: 190
    },
    {
        name: 'Created On',
        value: 'createdOn',
        group: 'Stock',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        group: 'Stock',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'Settings',
        value: 'settings',
        group: 'Stock',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allStockFieldsTakeVM = [
    {
        name: 'Stock #',
        value: 'stockNo',
        subText: 'clientName',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Make',
        value: 'make',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Model',
        value: 'model',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Year',
        value: 'year',
        group: 'Stock',
        flex: 0.5,
        default: true
    },
    {
        name: 'Sale Type',
        value: 'saleType',
        group: 'Stock',
        flex: 0.5,
        default: true
    },
    {
        name: 'Reg #',
        value: 'regNo',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Available For',
        value: 'availability',
        group: 'Stock',
        elementName: 'availabileFor',
        flex: 1,
        default: true
    },
    {
        name: 'Status',
        value: 'status',
        group: 'Stock',
        elementName: 'statusName',
        flex: 1,
        default: true
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Model Description',
        value: 'modelDescription',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Body Type',
        value: 'type',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Exterior Colour',
        value: 'extColor',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Interior Colour / Trim',
        value: 'intColor',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Odometer',
        value: 'miles',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Price',
        value: 'price',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Purchase Price',
        value: 'purchasePrice',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Location',
        value: 'location',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Registration Date',
        value: 'regDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Registration Expiry',
        value: 'regExp',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Warranty Start Date',
        value: 'warrantyStartDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExp',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Build Date',
        value: 'buildDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Stock Age',
        value: 'stockInDate',
        flex: 1
    },
    {
        name: 'Created On',
        value: 'createdOn',
        group: 'Stock',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        group: 'Stock',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 70,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const stockBasicDetailsVM = {
    documentID: null,
    stockNo: '',
    make: '',
    model: '',
    modelDescription: '',
    saleType: '',
    bodyType: '',
    year: '',
    regNo: '',
    regState: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    price: null,
    location: '',
    regDate: null,
    regExp: null,
    stockInDate: null,
    tradePlate: '',
    status: '', //stockStatus
    lastStatus: '',
    images: [],
    testDriveDetail: null, //objTestDriveDetail
    fleetDetail: null, //objFleetDetail
};

export const allStockNotesHistoryFields = [

    {
        name: 'Notes',
        value: 'notes',
        flex: 2,
        default: true,
        limit: 190

    },
    {
        name: 'Created on',
        value: 'createdOn',
        flex: 1,
        default: true
    },
    {
        name: 'Created By',
        value: 'createdBy',
        flex: 1,
        default: true
    }
]
