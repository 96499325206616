/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _, { isEmpty } from 'lodash'
import { default as _images } from '../../../images';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopoverListLoader } from '../../../components';
import CommonHelper from '../../../services/common';
import { objEnquiry } from '../viewModel'
import { enquiryStatus } from '../../../services/enum'
import { firestoreDB } from '../../../services/helper';

class PopoverEnquiryList extends Component {
    constructor(props) {
        super(props);
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.unsubscribeENQZ = null;
        this.state = {
            enquiries: [],
            filter: {
                iSearch: ''
            },
            loading: true
        };
    }

    static applyFilter = (listings, filter) => {
        const { iSearch } = filter
        let result = listings
        const filterForEnq = (item) => {
            let result = false;
            if (!_.isEmpty(item.contact)) {
                if ((!_.isEmpty(item.contact.firstName) && item.contact.firstName.toLowerCase().includes(iSearch.toLowerCase())) || (!_.isEmpty(item.contact.lastName) && item.contact.lastName.toLowerCase().includes(iSearch.toLowerCase()))) {
                    result = true
                }
            }
            if (!_.isEmpty(item.requirement) && (!_.isEmpty(item.requirement.make) || !_.isEmpty(item.requirement.stock))) {
                if (item.requirement.stock === null) {
                    if ((!_.isEmpty(item.requirement.make) && item.requirement.make.toLowerCase().includes(iSearch.toLowerCase())) || (!_.isEmpty(item.requirement.model) && item.requirement.model.toLowerCase().includes(iSearch.toLowerCase()))) {
                        result = true
                    }
                } else {
                    if (!_.isEmpty(item.requirement.stock)) {
                        if ((!_.isEmpty(item.requirement.stock.make) && item.requirement.stock.make.toLowerCase().includes(iSearch.toLowerCase())) || (!_.isEmpty(item.requirement.stock.model) && item.requirement.stock.model.toLowerCase().includes(iSearch.toLowerCase()))) {
                            result = true
                        }
                    }
                }
            }
            return result

        }
        if (iSearch) {
            result = result.filter(item => filterForEnq(item))
        }
        return result
    }

    onCollectionUpdate = (querySnapshot) => {
        const enquiries = [];
        querySnapshot.forEach((doc) => {
            //const _enq = doc.data()
            let _enq
            if (this.props.contactID === 'none') {
                _enq = Object.assign({}, objEnquiry);
            } else {
                _enq = Object.assign({}, objEnquiry);
            }
            for (let [key, value] of Object.entries(doc.data())) {
                if (_enq.hasOwnProperty(key))
                    _enq[key] = value;
            }
            _enq.documentID = doc.id;
            enquiries.push(_enq)
        });

        this.setState({
            enquiries: enquiries,
            loading: false
        });
    }

    componentDidMount() {
        if (this.props.dealersettings && this.props.contactID) {
            if (this.props.contactID === 'none') {
                this.unsubscribeENQZ = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
                    .where('isContact', '==', true)
                    .where('isDeleted', '==', false)
                this.unsubscribeENQZ = CommonHelper.whereClientOrGroupOrRegionOrOemClause(this.unsubscribeENQZ, this.props.dealersettings, null, true);
                //this.unsubscribeENQZ = this.unsubscribeENQZ.onSnapshot(this.onCollectionUpdate);
            } else {
                this.unsubscribeENQZ = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
                    .where('isContact', '==', true)
                    .where('contact.documentID', '==', this.props.contactID)
                    .where('isDeleted', '==', false)
                this.unsubscribeENQZ = CommonHelper.whereClientOrGroupOrRegionOrOemClause(this.unsubscribeENQZ, this.props.dealersettings, null, true);
                //this.unsubscribeENQZ = this.unsubscribeENQZ.onSnapshot(this.onCollectionUpdate);
            }

            this.unsubscribeENQZ.get().then(response => {
                const enquiries = [];
                response.docs.forEach((doc) => {
                    let _enq
                    if (this.props.contactID === 'none') {
                        _enq = Object.assign({}, objEnquiry);
                    } else {
                        _enq = Object.assign({}, objEnquiry);
                    }
                    for (let [key, value] of Object.entries(doc.data())) {
                        if (_enq.hasOwnProperty(key))
                            _enq[key] = value;
                    }
                    _enq.documentID = doc.id;
                    enquiries.push(_enq)
                });

                this.setState({
                    enquiries: _.orderBy(enquiries, ["modifiedDate"], ["desc"]),
                    loading: false
                });

            });
        }
        else {
            this.setState({
                loading: false
            });
        }
    }

    componentWillUnmount() {
        //this.unsubscribeENQZ && this.unsubscribeENQZ();
    }

    handleChange = (prop, value) => {
        let state = this.state.filter;
        state[prop] = value;
        this.setState({ filter: state });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = this.state.filter;
        state[name] = checked;
        this.setState({ filter: state });
    }

    handleEnquiryClose = (ID, objData) => {
        this.props.handleEnquiryClose(ID, objData);
    }

    render() {
        const { iSearch } = this.state.filter
        const { enquiries, filter, loading } = this.state
        const { show, showAdd, dealersettings, groupUsers, clientUsers } = this.props;
        const filteredList = PopoverEnquiryList.applyFilter(enquiries, filter)
        const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
        const divStyle = {
            height: '400px',
            overflow: 'auto'
        };
        const liteVersion = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            dealersettings.client.clientSettings.liteVersion) ? true : false);
        return (
            <Modal
                id="stockList"
                show={show}
                onHide={this.props.handleEnquiryClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-terms">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={this.props.title ? this.props.title : 'enquiries'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className={`vehicle-popover-head ${showAdd ? '' : 'pr-0'}`}>
                            <input
                                type="text"
                                id="isearch"
                                aria-label="Search"
                                placeholder="Search..."
                                value={iSearch}
                                autoComplete="off"
                                onChange={event =>
                                    this.handleChange('iSearch', event.target.value)
                                }
                            />
                        </div>
                        <div style={divStyle} >
                            {
                                loading ? (<PopoverListLoader />) :
                                    (filteredList && filteredList.length > 0)
                                        ?
                                        <>
                                            {filteredList.map((enquiry, index) =>
                                                <div key={index} className="vehicle-popover-loop" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.handleEnquiryClose(enquiry.documentID, enquiry)

                                                }}>
                                                    <div className="divTable">
                                                        <div className="divTableBody">
                                                            <div className="divTableRow">
                                                                <div className="divTableCell width70">
                                                                    <div className="customer-avatar-content">
                                                                        <div className="customer-avatar-image">
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={CommonHelper.showBrandLogo(dealersettings, (!_.isEmpty(enquiry?.requirement?.stock) ? enquiry.requirement.stock?.make : enquiry.requirement?.make))} className="img-object-fit-contain" alt="" width="70" height="70" /> </a>
                                                                        </div>
                                                                        {
                                                                            enquiry.displayID ?
                                                                                (<div className="input-readonly-sub"><i className="ico icon-enquiry mr-1"></i>{enquiry.displayID}</div>) :
                                                                                (<></>)
                                                                        }
                                                                        <div className="customer-avatar-title">

                                                                            {
                                                                                (!_.isEmpty(enquiry.requirement) && (enquiry.requirement.make || enquiry.requirement.stock))
                                                                                    ?
                                                                                    <>
                                                                                        {
                                                                                            (!_.isEmpty(enquiry.requirement.stock) && !_.isEmpty(enquiry.requirement.stock.make))
                                                                                                ?
                                                                                                <span>
                                                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                                                                    {(enquiry.requirement.stock.make ? enquiry.requirement.stock.make : '') + ' ' + (enquiry.requirement.stock.model ? enquiry.requirement.stock.model : '')}
                                                                                                    {enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                        {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                            enquiry.requirement.saleType, '')}
                                                                                                    </div>) : ''}

                                                                                                </span>
                                                                                                :
                                                                                                <span>
                                                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                                                                    {(enquiry.requirement.make ? enquiry.requirement.make : '') + ' ' + (enquiry.requirement.model ? enquiry.requirement.model : '')}
                                                                                                    {enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                        {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                            enquiry.requirement.saleType, '')}
                                                                                                    </div>) : ''}

                                                                                                </span>
                                                                                        }

                                                                                        {
                                                                                            enquiry.requirementCount && enquiry.requirementCount > 1
                                                                                                ?
                                                                                                <OverlayTrigger
                                                                                                    placement='bottom'
                                                                                                    overlay={
                                                                                                        <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <div className="badge badge-pill badge-blue badge-mini ml-1">
                                                                                                        +{enquiry.requirementCount - 1} <Translate text={'more'} /></div>
                                                                                                </OverlayTrigger>

                                                                                                :
                                                                                                <></>
                                                                                        }


                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            liteVersion && !_.isEmpty(enquiry.td) && !_.isEmpty(enquiry.td.make)
                                                                                                ?
                                                                                                <>
                                                                                                    <span>
                                                                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-activity-testdrive')}></i>
                                                                                                        {enquiry.td.make + ' ' + enquiry.td.model}
                                                                                                        {
                                                                                                            enquiry.td.saletype
                                                                                                                ?
                                                                                                                <>
                                                                                                                    <div className="badge badge-pill badge-white ml-1">
                                                                                                                        {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                            enquiry.td.saletype, '')}
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                </>
                                                                                                        }
                                                                                                    </span>

                                                                                                </>
                                                                                                :
                                                                                                <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                                                                                        }
                                                                                    </>

                                                                            }

                                                                        </div>


                                                                        {
                                                                            !_.isEmpty(enquiry.contact) ? (

                                                                                <div className="link-enquiry-owner" style={{ borderBottom: '0px' }}>
                                                                                    <div className="link-enquiry-owner-view">
                                                                                        {CommonHelper.displayContactIcon(enquiry.contact, 'mr-1')}
                                                                                    </div>{CommonHelper.displayContact([], enquiry.contact, '--')}</div>
                                                                            ) : (<></>)
                                                                        }
                                                                        {/* {
                                                                            enquiry.origin ?
                                                                                (<div className="input-readonly-sub">{CommonHelper.getNameByValue(((this.props.dealersettings && this.props.dealersettings.client && this.props.dealersettings.client.settings) ? this.props.dealersettings.client.settings.origins : []), enquiry.origin, '')}</div>) :
                                                                                (<></>)
                                                                        } */}
                                                                        <div className="vehicle-item-status">
                                                                            <div className="calllogs-vehicle-owner">
                                                                                <div className="owner-view">
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                        <img src={CommonHelper.getUserImagebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), enquiry.owner)} className="img-object-fit" />
                                                                                    </a>
                                                                                </div>
                                                                                {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), enquiry.owner)}
                                                                            </div>
                                                                            {
                                                                                enquiry.status ?
                                                                                    <>
                                                                                        {(() => {
                                                                                            let ___status = (enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : enquiry.status;
                                                                                            let _objCurrentStatus = enquiryAllStatus && enquiryAllStatus.filter(e => e.value === ___status)[0]

                                                                                            return <>
                                                                                                {
                                                                                                    _objCurrentStatus
                                                                                                        ?
                                                                                                        <>
                                                                                                            <div
                                                                                                                className={`status-${___status === 'open' ? 'open' : 'empty'}  float-right`}
                                                                                                                style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                                                            >
                                                                                                                {_objCurrentStatus.name}
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <></>
                                                                                                }
                                                                                            </>
                                                                                        })()}
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {/* {
                                                                                enquiry.status ? (
                                                                                    <span className={`status-${enquiry.status === 'open' ? 'open' : 'empty'} float-right`}
                                                                                        style={{ background: CommonHelper.getFieldByValue(enquiryAllStatus, enquiry.status, 'color', '#6b717f') }}>
                                                                                        <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, (enquiry.status === enquiryStatus.PENDINGLOST ? 'lost' : enquiry.status))} upperCase={true} />
                                                                                    </span>
                                                                                )
                                                                                    : (<></>)
                                                                            } */}

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}</>
                                        :
                                        <>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-cafe-img">
                                                        <i className="ico icon-enquiry"></i> </div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'noEnquiriesFound'} /></div>
                                                </div>

                                            </div>
                                        </>
                            }
                        </div>

                    </>
                </Modal.Body>
            </Modal>


        );
    }

}

export default PopoverEnquiryList;