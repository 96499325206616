/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer, Fragment, useRef } from "react";
import moment from "moment";
import _ from "lodash";

import { ContentOverlay, PopUpModal, TableView } from "../../components";
import Translate from "../../constants/translate";
import FilterPanel from "./filterPanel";
import CommonHelper from "../../services/common";
import { firestoreDB } from "../../services/helper";
import { fields } from "./viewModel";
import TemplatePreview from "../settings/tradeinProworkflow/triggers/previewTemplate";
import TriggersLogListReducer from "./triggersLogListReducer";
import TriggerHistoryModal from "./triggerHistoryModal";
import Swal from 'sweetalert2';
import toast from 'toasted-notes'
import ReAuthenticate from "../common/reAuthenticate";

const TriggerListView = (props) => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth - 30,
    windowHeight: window.innerHeight - 123,
  });
  const [showHistoryModal, setShowHistoryModal] = useState({
    show: false,
    data: {},
    title: "Stats Timeline",
  });

  const [data, dispatch] = useReducer(TriggersLogListReducer, []);
  const [checkDataLoad, setDataloader] = useState(true);
  const pageLimit = 20;
  const [hasMore, setHasMoreData] = useState([]);
  const [filterClient, setFilterClient] = useState();
  const [filterTitle, setFilterTitle] = useState();
  const [filterStatus, setFilterStatus] = useState("");
  const [filterDate, setFilterDate] = useState({
    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
  });
  const [isPaging, setPaging] = useState(false);
  const [searchText, setSearchText] = useState(
    localStorage.triggerLogSearchText ? localStorage.triggerLogSearchText : null
  );
  const [enquiryLoader, setEnquiryLoader] = useState(true);

  const [isFilterApplied, setFilterApplied] = useState(false);
  const [pageNum, setPageNum] = useState(0);

  const [csvHeader, setHeader] = useState([]);
  const [showPreviewModel, setShowPreviewModel] = useState({
    show: false,
    template: { bodyHTML: "" },
    title: data.title,
  });
  const [activeTitle, setActiveTitle] = useState([]);
  const [sortConfig, setSortConfig] = useState();
  const [tableLoader, setTableLoader] = useState(false)
  const [csvData, setCSVData] = useState([])
  const csvBtn = useRef();
  const [showReAuthentication, setReAuthentication] = useState(false);
  const [counts, setCounts] = useState({});

  useEffect(() => {
    let headerFields = fields.map(e => { return e.value });
    let allHeaderFields = fields;
    if (_.isEmpty(headerFields) || headerFields.length === 0) {
      headerFields = allHeaderFields.filter(e =>
        e.default === true
        && e.fixed !== true
        && e.fixedRight !== true
      ).map(v => _.pick(v, ['value']).value)
    }
    headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
    var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
      return {
        label: e.name,
        key: !_.isEmpty(e.elementName) ? e.elementName : e.value
      }
    });
    _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
      var index = _.findIndex(_headers, (e) => {
        return e.key == rec.value;
      }, 0)
      if (index >= 0) {
        _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
      }
      else {
        _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
      }
    })
    _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
      var index = _.findIndex(_headers, (e) => {
        return e.key == rec.value;
      }, 0)
      if (index >= 0) {
        _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
      }
      else {
        _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
      }
    })
    setHeader(_headers);

  }, [])

  useEffect(() => {
    getTriggers();
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth - 30,
        windowHeight: window.innerHeight - 123,
      });
    }
    window.addEventListener("resize", handleResize);
    document.getElementsByTagName("body")[0].classList.add("inner-bg");
    document.getElementById("top-nav-bar") &&
      document.getElementById("top-nav-bar").classList.add("fixed-top");
    return () => {
      window.removeEventListener("resize", handleResize);
      window.unsubtrigggerloglist && window.unsubtrigggerloglist();
    };
  }, []);

  useEffect(() => {
    if (localStorage.triggerFilter && !isFilterApplied) setFilterApplied(true);
  }, [localStorage.triggerFilter]);

  useEffect(() => {
    if (!checkDataLoad) {
      return;
    }

    const { dealersettings } = props;

    if (_.isEmpty(dealersettings)) {
      return;
    }

    if (isFilterApplied && pageNum === 0) {
      setEnquiryLoader(true);
    }
    let searchKeywords = searchText;
    let selectClient = filterClient;
    let searchTitle = filterTitle;

    if (localStorage.triggerFilter) {
      let _pipelineFilter = JSON.parse(localStorage.triggerFilter);
      let _filter = Object.assign({}, _pipelineFilter.value);

      searchKeywords = _filter["keywords"] ? _filter["keywords"] : searchText;

      setSearchText(searchKeywords);
      setFilterClient(selectClient);
      localStorage.triggerLogSearchText = searchKeywords;
    }

    if (sortConfig) {
      let _filter = getFilterData();
      let _searchObject = {
        "type": "searchTriggersLog",
        "filters": JSON.stringify(_filter),
        "sortOrder": !_.isEmpty(sortConfig) ? `${sortConfig.key} ${sortConfig.sort}` : "startDate desc",
        "pageNum": pageNum,
        "pageLimit": pageLimit
      }
      _searchObject.clientID = dealersettings?.client.id;
      const searchTriggerLogs = window.firebase.functions().httpsCallable('generic-searchData');
      searchTriggerLogs(_searchObject).then((response) => {
        //console.log('generic-searchData', _rec, response);
        if (response.data.success) {
          onCollectionTriggers(response.data);
          //getTriggerStats();
        }
        else {
          setEnquiryLoader(false)
          setDataloader(false)
          setTableLoader(false)
          setPaging(false)
        }
      });

      window.unsubtrigggerloglist && window.unsubtrigggerloglist();
    }
    else {
      let refEnquiryData = firestoreDB(props.dealersettings)
        .firestore()
        .collection("triggersLog")
        .where("clientID", "==", dealersettings?.client.id)
        .where("isDeleted", "==", false)
        .where("triggerType", "==", "email");

      let keywords = [];
      if (selectClient?.length) {
        keywords = [...selectClient];
      }
      if (searchTitle) {
        refEnquiryData = refEnquiryData.where("triggerID", "in", searchTitle);
      }
      if (searchKeywords) {
        let formattedSearchText = CommonHelper.parseSearchText(
          dealersettings,
          searchKeywords
        );
        if (formattedSearchText.trim())
          keywords.push(formattedSearchText.toLowerCase().trim());
      }

      if (keywords.length) {
        refEnquiryData = refEnquiryData.where(
          "keywords",
          "array-contains-any",
          keywords
        );
      }
      if (filterDate) {
        refEnquiryData = refEnquiryData.where(
          "startDate",
          ">=",
          filterDate.startDate
        );
        refEnquiryData = refEnquiryData.where(
          "startDate",
          "<=",
          filterDate.endDate
        );
      }
      if (filterStatus?.value) {
        if (filterStatus?.value === "pending") {
          refEnquiryData = refEnquiryData.where("isDone", "==", false);
        } else {
          refEnquiryData = refEnquiryData.where("isDone", "==", true);
        }
      }
      refEnquiryData = refEnquiryData.orderBy("startDate", "desc");

      let lastRecord = hasMore;
      if (lastRecord.length > 0) {
        refEnquiryData = refEnquiryData
          .startAfter(lastRecord[0])
          .limit(pageLimit);
      } else {
        refEnquiryData = refEnquiryData.limit(pageLimit);
      }
      window.unsubtrigggerloglist = refEnquiryData.onSnapshot(onCollectionUpdate);
      getTriggerStats();
    }
  }, [checkDataLoad]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchText && searchText.length > 2) {
        setDataloader(true);
      }

      if (searchText === "") setDataloader(true);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchText]);

  const getFilterData = () => {
    const { dealersettings } = props;
    let _filter = {};
    if (filterClient?.length) {
      _filter.stats = filterClient.join(',');
    }
    if (filterTitle?.length) {
      _filter.triggerID = filterTitle.join(',');
    }
    if (searchText) {
      let formattedSearchText = CommonHelper.parseSearchText(
        dealersettings,
        searchText
      );
      if (formattedSearchText.trim())
        _filter.keywords = formattedSearchText.toLowerCase().trim();
    }
    if (filterDate) {
      _filter.startDate = moment.unix(filterDate.startDate.seconds).format('YYYY-MM-DD');
      _filter.endDate = moment.unix(filterDate.endDate.seconds).format('YYYY-MM-DD');
      _filter.dateType = "startDate"
    }
    if (filterStatus?.value) {
      _filter.status = filterStatus?.value
    }
    _filter.timezone = !_.isEmpty(dealersettings?.client?.timezone) ? dealersettings.client.timezone : moment.tz.guess()
    _filter.triggerType = "email";
    return _filter;
  }

  const getTriggerStats = () => {
    let _filter = getFilterData();
    _filter.clientID = props.dealersettings.client.id
    let _searchObject = {
      type: "getTriggerLogsCount",
      params: JSON.stringify(_filter),
    }
    const searchTriggerLogs = window.firebase.functions().httpsCallable('generic-getData');
    searchTriggerLogs(_searchObject).then((response) => {
      //console.log('generic-searchData', _rec, response);
      if (response.data.success) {
        setCounts(response.data.data[0]);
      }
    });
  }

  const handleSearchText = (val) => {
    setSearchText(val);

    setEnquiryLoader(true);
    setPaging(false);
    setHasMoreData([]);
    setPageNum(0);
  };

  const handleApplyFilter = (_filter) => {
    setEnquiryLoader(true);
    setFilterApplied(false);
    setDataloader(true);
    setPaging(false);
    setHasMoreData([]);
    setPageNum(0);
  };

  const handleClearFilter = () => {
    setSortConfig(null)
    setFilterApplied(false);
    setDataloader(true);
    setPaging(false);
    setHasMoreData([]);
    setPageNum(0);
    setFilterClient("");
    setFilterTitle("");
    setFilterStatus("");
    //setFilterDate(null);
  };

  const handlePagination = () => {
    setPaging(true);
    setDataloader(true);
  };

  const handleActionClick = (id, obj) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-green float-left mr-2"
          style={{ padding: `3px 8px`, fontSize: `11px` }}
          onClick={(e) =>
            setShowPreviewModel({
              show: true,
              template: { bodyHTML: obj.email.body },
              title: data.title,
            })
          }
        >
          {" "}
          <i
            className="fa fa-eye custom-icon-preview"
            aria-hidden="true"
            style={{ paddingTop: "0px", marginRight: "4px" }}
          ></i>{" "}
          <span>
            <Translate text={"Preview"} />
          </span>{" "}
        </button>
      </>
    );
  };

  const handleDetailsClick = (e, id, obj) => {
    e.preventDefault();
    setShowHistoryModal({
      ...showHistoryModal,
      data: obj,
      show: true,
    });
    //handleAllocateData(id, true);
  };
  const onCollectionUpdate = async (querySnapshot) => {
    try {
      let _syncData = [];
      if (
        querySnapshot.docs.length > 0 &&
        querySnapshot.docs.length === pageLimit
      ) {
        setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]]);
        let _pageNum = pageNum + 1;
        setPageNum(_pageNum);
      } else {
        setHasMoreData([]);
      }
      querySnapshot.docs.forEach(function (doc) {
        let data = doc.data();
        let logVM = convertTriggerVM({ ...data, documentID: doc.id });
        if (filterClient?.length && searchText) {
          filterClient.forEach((element) => {
            let eventObj =
              data?.events && data?.events.find((x) => x.name === element);
            if (eventObj?.name) {
              _syncData.push(logVM);
            }
          });
        } else {
          _syncData.push(logVM);
        }
      });
      if (filterStatus?.value) {
        if (filterStatus?.value === "failed") {
          _syncData = _syncData.filter((x) => {
            if ((x.isDone && x?.email?.error) || (_.isArray(x?.events) && x?.events?.some(a => a.name === 'failed'))) {
              return true;
            }
            return false;
          });
        } else if (filterStatus?.value === "sent") {
          _syncData = _syncData.filter((x) => {
            if (x.isDone) {
              return true;
            }
            return false;
          });
        }
      }

      if (isPaging) {
        dispatch({
          type: "APPEND_TRIGGER_LOG",
          data: _syncData,
        });
      } else {
        dispatch({
          type: "SUCCESS",
          data: _syncData,
        });
      }
      if (isPaging) {
        _syncData = [...data, ..._syncData];
      }
      setEnquiryLoader(false);
      setPaging(false);
      setDataloader(false);
      setTableLoader(false)
    } catch (error) {
      console.log(error);
    }
  };

  const onCollectionTriggers = (querySnapshot) => {

    const _data = [];

    if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
      setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
      let _pageNum = pageNum + 1;
      setPageNum(_pageNum)
    }
    else {
      setHasMoreData([]);
    }
    querySnapshot.data.forEach(function (doc) {
      var logVM = convertTriggerVM(doc);
      _data.push(logVM)
    });

    dispatch({
      type: isPaging ? "APPEND_LIST" : "SUCCESS",
      data: _data,
    });
    setEnquiryLoader(false)
    setDataloader(false)
    setPaging(false)
    setTableLoader(false)
  }

  const convertTriggerVM = (doc) => {
    doc.startDate = doc.startDate && doc.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
    doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
    doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
    const stats = doc?.events?.length
      ? doc?.events?.map((item, index) => {
        if (!item?.name || item?.name === "accepted") {
          return (<Fragment key={index}></Fragment>);
        }
        return (
          <div key={index}
            className={`badge badge-pill badge-input-${item?.name} mr-1 text-capitalize`}
          >
            <Translate text={item?.name} />
          </div>
        );
      })
      : null;
    const strStats = doc?.events?.length
      ? doc.events.filter(a => a.name !== "accepted").map(r => { return r.name }).join(',')
      : null;
    const strStatus =
      doc?.isDone && doc?.email?.error ? 'Failed' : doc?.isDone ? 'Sent' : 'Pending';
    const status =
      doc?.isDone && doc?.email?.error ? (
        <div className="badge badge-pill badge-input-failed mr-1 text-capitalize">
          <Translate text={`Failed`} />
        </div>
      ) : doc?.isDone ? (
        <div className="badge badge-pill badge-input-delivered mr-1 text-capitalize">
          <Translate text={`Sent`} />
        </div>
      ) : (
        <div className="badge badge-pill badge-input-unsubscribed mr-1 text-capitalize">
          <Translate text={`Pending`} />
        </div>
      );
    return {
      ...doc,
      status,
      strStatus,
      stats,
      strStats,
      contactEmail: doc?.contact?.email || "",
      subject: doc?.email?.subject || "",
      toEmail: doc?.email?.to || "",
      name:
        doc?.contact?.firstName && doc?.contact?.lastName
          ? CommonHelper.displayContactName([], doc?.contact)
          : "",
      sentOn: doc.startDate
        ? moment.unix(doc.startDate.seconds).format("DD/MM/YYYY hh:mm A")
        : "",
    };
  };
  const getTriggers = () => {
    let triggers = [];
    let ref = firestoreDB(props.dealersettings)
      .firestore()
      .collection("triggers")
      .where("isActive", "==", true)
      .where("triggerType", "==", "email")
      .where("clientIDs", "array-contains-any", [
        props.dealersettings?.client.id,
      ])
      //.orderBy("addedDate", "desc")
      .get();
    ref.then((querySnapshot) => {
      querySnapshot.docs.forEach(function (doc) {
        let data = doc.data();
        triggers.push({
          label: data.title,
          value: doc.id,
        });
      });
    });
    setActiveTitle(triggers);
  };
  const handleDynamicSort = (sortConfig) => {
    setSortConfig(sortConfig);
    setPaging(false);
    setHasMoreData([]);
    setPageNum(0);
    setDataloader(true);
    setTableLoader(true)
  }

  const excelDownload = async (total) => {
    const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
    if (isPrivacyFileEnabled) {
      handleReAuthOpen();
      return;
    }

    const { dealersettings } = props;
    let _filter = getFilterData();
    let _searchObject = {
      "type": "searchTriggersLog",
      "filters": JSON.stringify(_filter),
      "sortOrder": !_.isEmpty(sortConfig) ? `${sortConfig.key} ${sortConfig.sort}` : "startDate desc",
      "pageNum": pageNum,
      "pageLimit": total > 9999 ? 10000 : (total ? total : 10000)
    }
    _searchObject.clientID = dealersettings?.client.id;
    toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
      {
        position: 'top',
        duration: null
      },
    )
    const searchTriggerLogs = window.firebase.functions().httpsCallable('generic-searchData');
    const resp = await searchTriggerLogs(_searchObject);
    //console.log('generic-searchData', _rec, response);
    if (resp.data.success) {
      let _enquiries = [];
      resp.data.data.forEach(function (doc) {
        var logVM = convertTriggerVM(doc);
        _enquiries.push(logVM)
      });
      setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
      setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
      toast.closeAll();
      Swal.fire({
        icon: 'success',
        title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
        showConfirmButton: false,
        timer: 1500
      })

      let _objLogData = {
        notes: 'Excel export from trigger logs',
        type: 'excelExport',
        subType: 'triggersLog',
        params: JSON.stringify(_filter),
      }
      _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
      CommonHelper.saveAuditLog(_objLogData);
    }
    else {
      toast.closeAll();
      Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
    }

  }

  //#region RE-AUTHENTICATION
  const handleReAuthOpen = () => {
    setReAuthentication(true);
  }

  const handleReAuthOpenClose = (data) => {
    if (!_.isEmpty(data)) {
      setTimeout(() => {
        excelDownload(counts?.total);
      }, 1000);
    }
    setReAuthentication(false);

  }
  //#endregion


  return (
    <div className="middle-wrapper">
      <FilterPanel
        activeTitle={activeTitle}
        pipeline={props.pipeline}
        dealersettings={props.dealersettings}
        clientUsers={props.clientUsers}
        groupUsers={props.groupUsers}
        handleApplyFilter={handleApplyFilter}
        handleClearFilter={handleClearFilter}
        csvHeader={csvHeader}
        view="list"
        isFilterApplied={isFilterApplied}
        searchText={searchText}
        handlesearchText={(val) => {
          handleSearchText(val);
        }}
        filterClient={filterClient}
        handleFilterClient={(val) => {
          setFilterClient(val);
        }}
        filterTitle={filterTitle}
        handleFilterTitle={(val) => {
          setFilterTitle(val);
        }}
        filterStatus={filterStatus}
        handleFilterStatus={(val) => {
          setFilterStatus(val);
        }}
        filterDate={filterDate}
        handleFilterDate={(val) => {
          if (val) {
            setFilterDate(val);
          } else {
            setFilterDate({
              startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
              endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
          }
          handleApplyFilter();
        }}
        csvData={csvData}
        excelDownload={excelDownload}
        csvBtn={csvBtn}
        counts={counts}
      />

      <div className="calendar-panel mt-3">
        {enquiryLoader ? (
          <div className="mt-5">
            <ContentOverlay active={true} />
          </div>
        ) : data.length > 0 ? (
          <div className="common-table">
            {
              tableLoader
                ?
                <>
                  <div className="dashboard-widget-loader h-100">
                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>

                  </div>
                </>
                :
                <>
                </>
            }
            <TableView
              isReorderable={false}
              datalist={_.uniqBy(data, 'documentID')}
              height={windowSize.windowHeight}
              width={windowSize.windowWidth}
              columns={fields}
              dynamicFields={fields
                .filter((e) => e.default === true)
                .map((v) => _.pick(v, ["value"]).value)}
              settingsLoader={false}
              handleActionClick={handleActionClick}
              handleRowSingleClick={handleDetailsClick}
              isSettings={false}
              hasMore={hasMore}
              handlePagination={handlePagination}
              isPaging={true}
              isSorting={true}
              dynamicSort={true}
              handleDynamicSort={handleDynamicSort}
              sortConfig={sortConfig}
            />
          </div>
        ) : (
          <div className="common-table">
            <div className="text-center p-5">
              <p>
                <Translate text={"No record found"} />
              </p>
            </div>
          </div>
        )}
      </div>
      <PopUpModal show={showPreviewModel.show}>
        <TemplatePreview
          {...props}
          {...showPreviewModel}
          handleClose={() => {
            setShowPreviewModel({ show: false });
          }}
        />
      </PopUpModal>
      <PopUpModal show={showHistoryModal.show}>
        <TriggerHistoryModal
          {...showHistoryModal}
          show={showHistoryModal.show}
          handleClose={() =>
            setShowHistoryModal({ ...showHistoryModal, show: false })
          }
        />
      </PopUpModal>
      <PopUpModal show={showReAuthentication}>
        <ReAuthenticate
          show={showReAuthentication}
          handleClose={handleReAuthOpenClose}
          dealersettings={props.dealersettings}
          privacy='file'
        />
      </PopUpModal>
    </div >
  );
};

export default TriggerListView;
