/** LIBRARIES */
import * as React from 'react'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2';
/** COMPONENTS */
import CommonHelper from '../../services/common'
import { PopUpModal } from '../../components';
import EnquiryFields from './enquiryFields'
/** VIEW-MODELS */
import { enquiryStatus, serviceStatus } from '../../services/enum';
import { objTradeIP } from './viewModel';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { docData } from 'rxfire/firestore';
import { firestoreDB } from '../../services/helper';


const StageContext = React.createContext()

let enquiryOptionsDF = [];
const fieldsData = {
    contact: '',
    businessContact: '',
    requirement: '',
    enquiry: '',
    company: ''
}

export const StageConsumer = StageContext.Consumer

export class StageProvider extends React.Component {
    constructor(props) {
        super(props);
        this.mandatoryFields = [];
        this.state = {
            filter: {},
            tradeins: {},
            pipeline: [],
            isLoading: false,
            columns: [],
            clientid: '',
            hasMore: [],
            pageNum: 0,
            pageLimit: 10,
            stageLoading: '',
            showNotifier: false,
            notifierMsg: '',
            notifierInfo: '',
            sectionloading: false,
            checkLoad: {},
            isFilterApplied: false,
            stageLoader: [],
            stageCounts: {},
            enquiryModal: {
                show: false,
                enquiry: {},
                errors: null,
                origin: {}
            },
            selectedEnquiry: {},
            searchText: (localStorage.tradeinSearchText ? localStorage.tradeinSearchText : ''),
        };

        //this.listenScrollEvent = this.listenScrollEvent.bind(this);
    }

    componentDidMount() {
        if (this.state.isLoading) {
            if (localStorage.tipPipelineFilter && this.state.isFilterApplied === false)
                this.setState({ isFilterApplied: true }, () => { this.loadTradeinPro() })
            else
                this.loadTradeinPro()

            const { dealersettings } = this.props;
            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.tradeinPro)) {
                const setttings = dealersettings.client.tradeinPro;

                enquiryOptionsDF = [];
                if (setttings.enquiryOptionsDF)
                    enquiryOptionsDF = setttings.enquiryOptionsDF;
            }
            this.refreshStageCount()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isLoading) {
            window?.unsubTradeinPipelineCount?.unsubscribe && window.unsubTradeinPipelineCount.unsubscribe();
            window?.unsubTradeinPipeline?.unsubscribe && window.unsubTradeinPipeline.unsubscribe();
            this.loadTradeinPro()
        }
        if (JSON.stringify(prevState.columns) !== JSON.stringify(this.state.columns)) {
            this.refreshStageCount()
        }
    }

    componentWillUnmount() {
        this.state.pipeline.forEach(p => {
            p.stages.forEach(s => {
                window['unsubTradein' + p.value + s.value] && window['unsubTradein' + p.value + s.value]();
            })
        })
        window?.unsubTradeinPipelineCount?.unsubscribe && window.unsubTradeinPipelineCount.unsubscribe();
        window?.unsubTradeinPipeline?.unsubscribe && window.unsubTradeinPipeline.unsubscribe();
    }

    refreshStageCount = () => {
        if (window.location.pathname !== '/tradeinpro')
            return

        const { pipeline } = this.state;
        const { dealersettings } = this.props;
        let _selectedPipeline = pipeline.find(item => item.default === true)
        var _dpl = _selectedPipeline ? _selectedPipeline.value : '';

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && _dpl) {
            try {

                // let _searchObject = {
                //     "pipeline": _dpl,
                //     "owner": localStorage.uid,
                //     "settingsID": dealersettings.oemID ? dealersettings.oemID : null
                // }
                // _searchObject.clientID = dealersettings.client.id;
                // //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, null, true);

                // const stageCountsBQ = window.firebase.functions().httpsCallable('tradeins-stageCountsBQ');
                // stageCountsBQ(_searchObject).then((response) => {
                //     console.log('stageCountsBQ', _dpl, response);
                // });
            }
            catch (error) {
                console.error(error)
            }
        }
    }

    isValidFilter = () => {
        if (localStorage.tipPipelineFilter) {
            let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    loadTradeinPro = async (_isPaging) => {
        try {
            if (window.location.pathname !== '/tradeinpro')
                return

            const { columns, pipeline, pageLimit, pageNum, searchText, stageLoading } = this.state;
            const { dealersettings } = this.props;
            let _selectedPipeline = pipeline.find(item => item.default === true);
            var _dpl = _selectedPipeline ? _selectedPipeline.value : '';

            const viewOtherAppraisal = true;
            // ((!_.isEmpty(dealersettings) &&
            //     !_.isEmpty(dealersettings.rolePermissions) &&
            //     !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions) &&
            //     dealersettings.rolePermissions.tradeinProPermissions.viewOtherAppraisal) ? true : false);

            let _pageNum = pageNum;

            //console.log('loadTradeinPro', _pageNum, isFilterApplied, hasMore);


            let clientID = dealersettings.client ? dealersettings.client.id : '';
            let tradeins = this.state.tradeins
            if (_.isEmpty(this.state.tradeins)
                || JSON.stringify(_.map(this.state.tradeins.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)
                || (this.isValidFilter() && _pageNum === 0)
            ) {
                //console.log('EMPTY tradeins', isFilterApplied, _pageNum, Object.keys(this.state.tradeins).length, JSON.stringify(_.map(this.state.tradeins.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns))
                tradeins = {}
                _pageNum = 0
                this.setState({ tradeins: tradeins, pageNum: 0 })
            }

            //get total pipeline count by stages
            if ((!searchText && !localStorage.tipPipelineFilter))
                this.getPipelineCount(viewOtherAppraisal, _dpl)

            //let _hasMore = false;
            let i = 0;
            let n = 0;
            let _chunkVal = 10;
            let pipelinePromise = []
            columns.forEach((_rec) => {

                let lastRecord = this.state.hasMore.filter(e => e.Stage === _dpl + _rec);
                if (JSON.stringify(_.map(this.state.tradeins.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)) {
                    lastRecord = []
                }


                if (this.isValidFilter()) {
                    let _pipelineFilter = {};

                    if (localStorage.tipPipelineFilter)
                        _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);

                    let _filter = Object.assign({}, _pipelineFilter.value);

                    _filter.pipeline = _dpl;

                    if (_.isEmpty(_filter.stage))
                        _filter.stage = _rec;



                    // if (lastRecord.length > 0 && _hasMore === false)
                    //     _hasMore = true;

                    if (_pageNum === 0 || lastRecord.length > 0) {
                        if ((_pageNum === 0 || lastRecord.length > 0) && i === 0) {
                            i++;
                            _pageNum = (pageNum + 1);
                            this.setState({ pageNum: _pageNum })
                        }
                    }

                    if (!viewOtherAppraisal)
                        _filter.owner = dealersettings.id;

                    /* DATE RANGE FILTER */
                    if (!_.isEmpty(_filter.date)) {
                        _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                        delete _filter['date'];
                    }

                    if (_filter.rottenDays &&
                        !_.isEmpty(dealersettings) &&
                        !_.isEmpty(dealersettings.client) &&
                        !_.isEmpty(dealersettings.client.tradeinPro) &&
                        !_.isEmpty(dealersettings.client.tradeinPro.pipelines)) {
                        _filter.pipelineSettings = JSON.stringify(dealersettings.client.tradeinPro.pipelines)
                    }

                    if (!_filter.clientID)
                        _filter.clientID = CommonHelper.getTradeinProDealers(dealersettings, false, true)

                    _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess();
                    //_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, null, null, true);

                    let _searchObject = {
                        "type": "searchTradeinpro",
                        "filters": JSON.stringify(_filter),
                        "sortOrder": "stageDate desc",
                        "pageNum": pageNum,
                        "pageLimit": pageLimit
                    }
                    _searchObject.clientID = _filter.clientID;
                    //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, null, true);
                    //console.log('filter-searchTradeinPlus', _searchObject, clientID, pageNum, _filter);
                    const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
                    searchEnquiries(_searchObject).then((response) => {
                        //console.log('generic-searchData', _rec, response);
                        if (response.data.success && this.isValidFilter()) {
                            this.setState({
                                stageCounts: {
                                    ...this.state.stageCounts,
                                    [_rec]: {
                                        total: response.data.total
                                    }
                                }
                            })
                            this.onCollectionEnquiry(response.data, _rec);
                            //     if (_pageNum === 0)
                            //         this.setState({ isLoading: columns[columns.length - 1] === _rec ? false : true, })
                        }
                    });

                    window['unsubTradein' + _dpl + _rec] && window['unsubTradein' + _dpl + _rec]();
                    window?.unsubTradeinPipelineCount?.unsubscribe && window.unsubTradeinPipelineCount.unsubscribe()
                    window?.unsubTradeinPipeline?.unsubscribe && window.unsubTradeinPipeline.unsubscribe();
                }
                else {
                    var enquiryQuery = '';
                    let searchKeywords = searchText;
                    let searchOwners = '';

                    if (localStorage.tipPipelineFilter) {
                        let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
                        let _filter = Object.assign({}, _pipelineFilter.value);

                        searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                        searchOwners = _filter['owner'] ? _filter['owner'] : null;

                        this.setState({ searchText: searchKeywords });
                        localStorage.tradeinSearchText = searchKeywords;
                        //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
                    }


                    // enquiryQuery = firestoreDB(dealersettings).firestore().collection('tradeinPro')
                    //     .where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
                    //     //.where('clientID', '==', clientID)
                    //     .where('isDeleted', '==', false)

                    // if (searchKeywords) {
                    //     let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                    //     if (formattedSearchText.trim())
                    //         enquiryQuery = enquiryQuery.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())

                    //     enquiryQuery = enquiryQuery
                    //         .orderBy('addedDate', 'desc');
                    // }
                    // else {
                    //     if (!viewOtherAppraisal)
                    //         enquiryQuery = enquiryQuery.where('owner', '==', localStorage.uid)
                    //     else if (!_.isEmpty(searchOwners))
                    //         enquiryQuery = enquiryQuery.where('owner', '==', searchOwners)

                    //     enquiryQuery = enquiryQuery
                    //         .where('pipeline', '==', _dpl)
                    //         .where('stage', '==', _rec)
                    //         .orderBy('stageDate', 'desc');
                    // }

                    // if (lastRecord.length > 0) {
                    //     enquiryQuery = enquiryQuery
                    //         .startAfter(lastRecord[0].lastDoc)
                    //         .limit(pageLimit)
                    // }
                    // else {
                    //     enquiryQuery = enquiryQuery
                    //         .limit(pageLimit)
                    // }


                    // pipelinePromise.push(collection(enquiryQuery))

                    let allTIProDealers = CommonHelper.getTradeinProDealers(dealersettings, true, true);
                    for (let k = 0; k < Math.ceil(allTIProDealers.length / _chunkVal); k++) {
                        let refEnquiryData = firestoreDB(dealersettings).firestore().collection('tradeinPro')
                            .where('clientID', 'in', CommonHelper.arrPaginate(allTIProDealers, _chunkVal, k + 1))
                            .where('isDeleted', '==', false)

                        if (searchKeywords) {
                            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                            if (formattedSearchText.trim())
                                refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())

                            refEnquiryData = refEnquiryData
                                .orderBy('addedDate', 'desc');
                        }
                        else {
                            if (!viewOtherAppraisal)
                                refEnquiryData = refEnquiryData.where('owner', '==', localStorage.uid)
                            else if (!_.isEmpty(searchOwners))
                                refEnquiryData = refEnquiryData.where('owner', '==', searchOwners)

                            refEnquiryData = refEnquiryData
                                .where('pipeline', '==', _dpl)
                                .where('stage', '==', _rec)
                                .orderBy('stageDate', 'desc');
                        }
                        lastRecord = this.state.hasMore.filter(e => e.Stage === `${_dpl}${_rec}_${n}`);
                        if (lastRecord.length > 0) {
                            refEnquiryData = refEnquiryData
                                .startAfter(lastRecord[0].lastDoc)
                                .limit(pageLimit)
                        }
                        else {
                            if (!_isPaging)
                                refEnquiryData = refEnquiryData.limit(pageLimit)
                            else
                                refEnquiryData = null;

                        }
                        pipelinePromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
                        n++;
                    }
                    //window['unsubTradein' + _dpl + _rec] = enquiryQuery.onSnapshot(this.onCollectionUpdate)
                }

            });

            if (pipelinePromise.length > 0) {
                window.unsubTradeinPipeline = combineLatest(pipelinePromise).pipe(
                    map((logs) => {
                        return logs;
                    }))
                    .subscribe((allEnquiries) => {
                        let allTIProDealers = CommonHelper.getTradeinProDealers(dealersettings, true, true);
                        let m = 0;
                        for (let i = 0; i < columns.length; i++) {
                            for (let k = 0; k < Math.ceil(allTIProDealers.length / _chunkVal); k++) {
                                let _data = allEnquiries[m];
                                this.onCollectionUpdateNew(_data, _dpl, `${columns[i]}_${m}`);
                                m++;
                            }
                        }

                    });
            }

            // if (_hasMore || (isFilterApplied && _pageNum === 0)) {
            //     _pageNum = (pageNum + 1);
            //     this.setState({ pageNum: _pageNum })
            // }
            //})
            if (Object.keys(tradeins).length === 0) {
                tradeins['tasks'] = {};
                let cols = {}
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];

                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    tradeins.tasks && Object.keys(tradeins.tasks).map(function (el) {
                        if (tradeins.tasks[el].stage === col.value) {
                            leadsids.push(tradeins.tasks[el].id)
                        }
                    })
                    cols[col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids
                    }
                })
                tradeins['columns'] = cols
            }

            this.setState({
                isLoading: false,
                checkLoad: {
                    ...this.state.checkLoad,
                    [_dpl]: true
                }
            }, () => {
                if (_isPaging) {
                    document.querySelector(".pipeline-panel").scrollTo({
                        top: document.querySelector(".pipeline-panel").scrollHeight,
                        behavior: "smooth"
                    });
                }
            })
        }
        catch (error) {
            this.setState({
                isLoading: false,
                stageLoading: ''
            })
            console.error(error)
        }
    }

    onCollectionUpdateNew = (querySnapshot, _pipeline, _stage) => {
        const { dealersettings } = this.props;
        let snapshotDoc;
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let _tradeins = this.state.tradeins
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => item.default === true)
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            var _dplStage = '';
            if (querySnapshot.length > 0)
                _dplStage = _pipeline + _stage;

            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.length > 0 && querySnapshot.length === pageLimit) {

                hasMoreData = [...hasMoreData.filter(e => e.Stage !== _dplStage), {
                    Stage: _dplStage,
                    docID: querySnapshot[querySnapshot.length - 1].data().documentID,
                    lastDoc: querySnapshot[querySnapshot.length - 1]
                }]
            }

            querySnapshot.forEach(doc => {
                let _data = doc.data()
                let _defaultPipeline = _data.pipeline;
                let _defaultStage = _data.stage;

                const _enq = {
                    ...doc.data(),
                    documentID: doc.id,
                    pipeline: _defaultPipeline,
                    stage: _defaultStage
                }
                result[doc.id] = this.convertDataVM(_enq)
                if (_tradeins.tasks) {
                    _tradeins['tasks'][doc.id] = { ...result[doc.id] }

                } else {
                    _tradeins['tasks'] = result
                }

                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }

                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value && _tradeins.tasks[el].pipeline === _dpl) {
                            leadsids.push(_tradeins.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = _tradeins.tasks[el].modifiedDate ? moment().diff(moment.unix(_tradeins.tasks[el].modifiedDate.seconds), 'days') : 0
                                _tradeins.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            })
            if (querySnapshot.length === 0) {
                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value) {
                            leadsids.push(_tradeins.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            }

            this.setState({
                tradeins: _tradeins,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dplStage), hasMoreData[0]] :
                    (querySnapshot.length > 0 ? [...hasMore.filter(item => item.Stage !== _dplStage)] : [...hasMore])
            })

        }
    }

    getPipelineCount = (viewOtherAppraisal, pipeline) => {
        const { dealersettings } = this.props;
        var querySet = [];

        CommonHelper.getTradeinProDealers(dealersettings, true, true).forEach(rec => {
            querySet.push(docData(window.firebase.firestore().doc(`clients/${rec}/counts/${rec}`)))
        })
        window.unsubTradeinPipelineCount = combineLatest(querySet)
            .subscribe(allDocs => {
                var stageCount = {};
                allDocs.forEach(doc => {
                    if (doc.tradeinPro && doc.tradeinPro[pipeline] && doc.tradeinPro[pipeline].stages) {
                        let _selectedPipeline = this.state.pipeline.find(item => item.default === true);
                        _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
                            stageCount = {
                                ...stageCount,
                                [stage.value]: {
                                    ...this.sumStages(doc.tradeinPro[pipeline].stages[stage.value], stageCount[stage.value]),
                                    ['total']: this.sumObj(doc.tradeinPro[pipeline].stages[stage.value], stageCount?.[stage.value]?.['total'] ? stageCount[stage.value]['total'] : 0)
                                }
                            }
                        })
                    }
                })
                this.setState({
                    stageCounts: stageCount
                })
            });
        // if (!viewOtherAppraisal) {
        //     querySet = `users/${localStorage.uid}/userClients/${dealersettings.client.id}`
        // }
        // window.unsubTradeinPipelineCount = window.firebase.firestore().doc(querySet)
        //     .onSnapshot(docSnapshot => {

        //         if (docSnapshot.exists && docSnapshot.data().tradeinPro && docSnapshot.data().tradeinPro[pipeline] && docSnapshot.data().tradeinPro[pipeline].stages) {
        //             var stageCount = {};
        //             let _selectedPipeline = this.state.pipeline.find(item => item.default === true);
        //             _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
        //                 stageCount = {
        //                     ...stageCount,
        //                     [stage.value]: {
        //                         ...docSnapshot.data().tradeinPro[pipeline].stages[stage.value],
        //                         ['total']: this.sumObj(docSnapshot.data().tradeinPro[pipeline].stages[stage.value])
        //                     }
        //                 }
        //             })
        //             this.setState({
        //                 stageCounts: stageCount
        //             })
        //         }
        //     })
    }

    sumObj = (obj, _stageCount) => {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum + _stageCount;
    }

    sumStages = (obj, _stageCount) => {
        var sum = _stageCount ? _stageCount : {};
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum = {
                    ...sum,
                    [el]: sum.hasOwnProperty(el) ? sum[el] += parseFloat(obj[el]) : parseFloat(obj[el])
                }
            }
        }
        return sum;
    }

    onCollectionUpdate = (querySnapshot) => {
        const { dealersettings } = this.props;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(async (change) => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                //console.log(change)
                let _data = change.doc.data()
                let _defaultPipeline = _data.pipeline;
                let _defaultStage = _data.stage;


                snapshotDoc = this.convertDataVM({
                    ...change.doc.data(),
                    documentID: change.doc.id,
                    pipeline: _defaultPipeline,
                    stage: _defaultStage
                });
                if (change.type === 'removed') {
                    const enqDoc = await firestoreDB(dealersettings).firestore().doc(`tradeinPro/${change.doc.id}`).get();
                    if (enqDoc.exists /*&& enqDoc.data().clientID === dealersettings.client.id*/) {
                        snapshotDoc = this.convertDataVM({ ...enqDoc.data(), documentID: enqDoc.id });
                    }
                    else {
                        snapshotDoc.isDeleted = true;
                    }
                }
                this.updateTradeinPro(snapshotDoc);
            }
        })
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let _tradeins = this.state.tradeins
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => item.default === true)
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            var _dplStage = '';
            if (querySnapshot.docs.length > 0)
                _dplStage = _dpl + querySnapshot.docs[0].data().stage;
            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {

                hasMoreData = [...hasMoreData.filter(e => e.Stage !== _dplStage), {
                    Stage: _dplStage,
                    docID: querySnapshot.docs[querySnapshot.docs.length - 1].data().documentID,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }]
            }

            querySnapshot.forEach(doc => {
                let _data = doc.data()
                let _defaultPipeline = _data.pipeline;
                let _defaultStage = _data.stage;

                const _enq = {
                    ...doc.data(),
                    documentID: doc.id,
                    pipeline: _defaultPipeline,
                    stage: _defaultStage
                }
                result[doc.id] = this.convertDataVM(_enq)
                if (_tradeins.tasks) {
                    _tradeins['tasks'][doc.id] = { ...result[doc.id] }

                } else {
                    _tradeins['tasks'] = result
                }

                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }

                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value && _tradeins.tasks[el].pipeline === _dpl) {
                            leadsids.push(_tradeins.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = _tradeins.tasks[el].modifiedDate ? moment().diff(moment.unix(_tradeins.tasks[el].modifiedDate.seconds), 'days') : 0
                                _tradeins.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            })
            if (querySnapshot.docs.length === 0) {
                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value) {
                            leadsids.push(_tradeins.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            }

            this.setState({
                tradeins: _tradeins,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dplStage),
                hasMoreData[0]] : (querySnapshot.docs.length > 0 ? [...hasMore.filter(item => item.Stage !== _dplStage)] : [...hasMore])
            })

        }
    }

    onCollectionEnquiry = (querySnapshot, _stage) => {
        //console.log('onCollectionEnquiry', _stage, querySnapshot)
        let snapshotDoc;
        const { dealersettings } = this.props;
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let _tradeins = this.state.tradeins
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => item.default === true)
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
                hasMoreData.push({
                    Stage: _dpl + querySnapshot.data[0].stage,
                    lastDoc: querySnapshot.data[querySnapshot.data.length - 1]
                })
            }
            querySnapshot.data.forEach(doc => {
                const _enq = doc;
                result[doc.documentID] = this.convertDataVM(_enq)
                if (_tradeins.tasks) {
                    _tradeins['tasks'][doc.documentID] = { ...result[doc.documentID] }

                } else {
                    _tradeins['tasks'] = result
                }

                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value && _tradeins.tasks[el].pipeline === _dpl) {
                            leadsids.push(_tradeins.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = _tradeins.tasks[el].modifiedDate ? moment().diff(moment.unix(_tradeins.tasks[el].modifiedDate.seconds), 'days') : 0
                                _tradeins.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }

                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            })
            if (querySnapshot.data.length === 0) {
                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value) {
                            leadsids.push(_tradeins.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            }

            this.setState({
                //isLoading: columns[columns.length - 1] === _stage ? false : true,
                tradeins: _tradeins,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dpl + querySnapshot.data[0].stage), hasMoreData[0]] : [...hasMore.filter(item => item.Stage !== _dpl + _stage)]

            })
        }
    }


    convertDataVM = (_enq) => {

        _enq.stageDate = _enq.stageDate && _enq.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.stageDate._seconds)._d) : _enq.stageDate;
        _enq.addedDate = _enq.addedDate && _enq.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.addedDate._seconds)._d) : _enq.addedDate;
        _enq.modifiedDate = _enq.modifiedDate && _enq.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.modifiedDate._seconds)._d) : _enq.modifiedDate;

        _enq.completedDate = _enq.completedDate && _enq.completedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.completedDate._seconds)._d) : _enq.completedDate;
        _enq.wonDate = _enq.wonDate && _enq.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.wonDate._seconds)._d) : _enq.wonDate;
        _enq.lostDate = _enq.lostDate && _enq.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.lostDate._seconds)._d) : _enq.lostDate;

        _enq.stageHistory = [..._.map(_enq.stageHistory, function (obj) {
            return obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]

        const { pipeline } = this.state;
        const { clientUsers, groupUsers, dealersettings } = this.props;
        var rottenDays = ''
        let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
        _selectedPipeline && _selectedPipeline.stages.forEach(col => {
            if (_enq.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = _enq.modifiedDate ? moment().diff(moment.unix(_enq.modifiedDate.seconds), 'days') : 0
                    rottenDays = enqage - col.rottenDays + 'Days'
                }
            }
        })

        const objData = Object.assign({}, _enq);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers        
        return {
            id: _enq.documentID,
            displayID: _enq.displayID ? _enq.displayID : _enq.documentID,
            contactID: _enq.contact ? _enq.contact.documentID : '',
            name: _enq.contact ? CommonHelper.displayContactName([], _enq?.contact) : '',
            businessContact: _enq.contact && _enq.contact.businessContact ? true : false,
            contactType: _enq.contact && _enq.contact.contactType ? _enq.contact.contactType : null,
            phone: (_enq.contact && _enq.contact.phone) ? _enq.contact.phone : '',
            phoneCode: (_enq.contact && _enq.contact.phoneCode ? (_enq.contact.phoneCode) : ''),
            email: _enq.contact ? (_enq.contact.email) : '',
            vehiclemodel: !_.isEmpty(_enq.tradein) ? `${_enq.tradein?.make || ''} ${_enq.tradein?.model || ''} ${_enq.tradein?.year || ''}` : '',
            vinNo: !_.isEmpty(_enq.tradein) ? (_enq.tradein.chassisNo) : '',
            regNo: !_.isEmpty(_enq.tradein) ? (_enq.tradein.regNo) : '',
            mileage: !_.isEmpty(_enq.tradein) ? (_enq.tradein.mileage) : '',
            stage: _enq.stage,
            status: _enq.status,
            pipeline: _enq.pipeline,
            label: _enq.label,
            isFav: _enq.isFav,
            isNewTradein: _enq.isNewTradein,
            ownerSales: _enq.ownerSales,
            ownerId: _enq.owner, owner: CommonHelper.getUserNamebyId(allUsers, _enq.owner),
            ownerSalesName: CommonHelper.getUserNamebyId(allUsers, _enq.ownerSales),
            addedby: CommonHelper.getUserNamebyId(allUsers, _enq.addedBy),
            modifiedby: CommonHelper.getUserNamebyId(allUsers, _enq.modifiedBy),
            enquiryage: (_enq.addedDate ? (moment.unix(_enq.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(_enq.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
            stageHistory: _enq.stageHistory,
            stageDate: _enq.stageDate ? _enq.stageDate : _enq.addedDate,
            modifiedDate: _enq.modifiedDate,
            counts: _enq.counts,
            rottenDays: rottenDays,
            isDeleted: _enq.isDeleted,
            addedDate: _enq.addedDate,
            enqFields: objData,
            clientID: _enq.clientID ? _enq.clientID : null,
            clientName: _enq.clientID ? CommonHelper.getUserNamebyId(dealersettings.clients, _enq.clientID) : null,
            tags: _enq.tags,
            origin: _enq.origin,
        }
    }

    handleEnquiryFav = (id, val, stage, oldstage, _objTradeIP, _fieldName) => {
        //    console.log('handleEnquiryFav', id, val, stage, oldstage, _objTradeIP, _fieldName)

        const { tradeins, pipeline } = this.state;
        const { dealersettings } = this.props;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);

        const viewOnlyAccess = ((!_.isEmpty(_permissions) && _permissions.viewOnlyAccess) ? true : false);
        // const isDeivered = (_objTradeIP.status === enquiryStatus.DELIEVERD || _objTradeIP.status === enquiryStatus.LOST || _objTradeIP.status === enquiryStatus.PENDINGLOST || _objTradeIP.status === enquiryStatus.PROSPECTLOST) ? true : false;
        // const canModifyEnquiry = viewOnlyAccess ? false : (((!_.isEmpty(_objTradeIP) && _objTradeIP.ownerId === dealersettings.id) ||
        //     (!_.isEmpty(_permissions) &&
        //         _permissions.modifyOtherEnquiry)) ? true : false);

        // const viewOtherAppraisal = !_.isEmpty(dealersettings) ? (((!_.isEmpty(_objTradeIP) && _objTradeIP.ownerId === dealersettings.id) ||
        //     (!_.isEmpty(_permissions) &&
        //         _permissions.viewOtherAppraisal)) ? true : false) : false;

        // const modifyStageAfterDeliveredLost = ((!_.isEmpty(_permissions) && _permissions.modifyStageAfterDeliveredLost) ? true : false);
        if (viewOnlyAccess) {
            Swal.fire('Modify access is denied.', '', 'warning')
            return
        }
        // if (!viewOtherAppraisal) {
        //     Swal.fire("Modify access is denied.", '', 'warning')
        //     //console.log('viewOtherAppraisal=>', viewOtherAppraisal, 'isDeivered=>', isDeivered, 'modifyStageAfterDeliveredLost=>', modifyStageAfterDeliveredLost)
        //     return
        // }
        // if ((canModifyEnquiry && isDeivered && !modifyStageAfterDeliveredLost) || !canModifyEnquiry) {
        //     Swal.fire('Modify access is denied.', '', 'warning')
        //     //console.log('canModifyEnquiry=>', canModifyEnquiry, 'isDeivered=>', isDeivered, 'modifyStageAfterDeliveredLost=>', modifyStageAfterDeliveredLost)
        //     return
        // }

        let _selectedPipeline = pipeline.find(item => item.default === true)
        let _dpl = _selectedPipeline ? _selectedPipeline.value : '';


        _objTradeIP.isFav = val;
        var inqUpdate = {
            isFav: Boolean(val),
            stage: stage
        };

        let setttings = dealersettings.client.tradeinPro;

        let _selPipeline = !_.isEmpty(setttings) && setttings.allPipelines.filter(e => e.value === _objTradeIP.pipeline)[0];
        let _defaulStages = _selPipeline && _selPipeline.stages
        let statusSettings = _selPipeline && _selPipeline.statusSettings;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === _objTradeIP.pipeline && e.stage === stage)[0];

        if (_statusMndry && _objTradeIP.status !== _statusMndry.status) {
            let __stage = _defaulStages.find(x => x.value === stage);
            Swal.fire(`${CommonHelper.showLocale(this.props, 'Please change appraisal status to')} ${_statusMndry.status.toUpperCase()} ${CommonHelper.showLocale(this.props, 'before moving to stage')}: ${__stage ? __stage.name : ''}`, '', 'info')
            return;
        }

        if (stage !== oldstage || _dpl !== _objTradeIP.pipeline) {

            var _objFav = {
                id: id,
                val: val,
                stage: stage,
                oldstage: oldstage
            }
            if (_.isEmpty(_fieldName) && !this.isValidForStageUpdate(stage, (!_.isEmpty(_objTradeIP.enqFields) ? _objTradeIP.enqFields : _objTradeIP), _objFav))
                return

            inqUpdate.stageDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.modifiedBy = localStorage.uid;
            inqUpdate.modifiedDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.modifiedFrom = 'web';
            if (_dpl !== _objTradeIP.pipeline && _.isEmpty(_fieldName)) {
                inqUpdate.pipeline = _dpl;
            }
            let newstagehistory = {
                pipeline: _dpl,
                stage: stage,
                date: window.firebase.firestore.Timestamp.now()
            };
            let arrstagehistory = _objTradeIP.stageHistory ? _objTradeIP.stageHistory : []
            let oldstagehistory = _.filter(arrstagehistory, { stage: oldstage, pipeline: _objTradeIP.pipeline })[0];
            if (oldstagehistory) {
                let stindex = _.findIndex(arrstagehistory, { stage: oldstage, pipeline: _objTradeIP.pipeline });
                let oldsittingDays = oldstagehistory.sittingDays ? oldstagehistory.sittingDays : 0
                arrstagehistory.splice(stindex, 1,
                    {
                        ...oldstagehistory,
                        sittingDays: oldsittingDays + moment().diff(moment.unix(oldstagehistory.date.seconds), 'days')
                    });
            }
            if (arrstagehistory.some(e => e.stage === stage && e.pipeline === _dpl)) {
                newstagehistory = _.filter(arrstagehistory, { stage: stage, pipeline: _dpl })[0]
                newstagehistory.date = window.firebase.firestore.Timestamp.now()
            }
            arrstagehistory = [...arrstagehistory.filter(e => e.stage !== stage), newstagehistory]
            inqUpdate.stageHistory = arrstagehistory;
            _objTradeIP.stageHistory = arrstagehistory;
            _objTradeIP.rottendays = 0
            _objTradeIP.stage = stage
            _objTradeIP.stageDate = window.firebase.firestore.Timestamp.now();
            _objTradeIP.modifiedBy = localStorage.uid;
            _objTradeIP.modifiedDate = window.firebase.firestore.Timestamp.now();
            _objTradeIP.modifiedFrom = 'web';
            if (_dpl !== _objTradeIP.pipeline && _.isEmpty(_fieldName)) {
                _objTradeIP.pipeline = _dpl;
            }
        }
        this.updateTradeinPro(_objTradeIP)
        firestoreDB(dealersettings).firestore().doc(`tradeinPro/${id}`).set(inqUpdate, { merge: true })
            .then(snapshot => {
                setTimeout(function () {
                    if (document.getElementById('tradeinpro-' + id)) {
                        document.getElementById('tradeinpro-' + id).scrollIntoView(
                            {
                                behavior: "smooth",
                                block: "center"
                            });
                    }
                }, 1000)
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    static getDerivedStateFromProps(props, state) {
        let pathname = window.location.pathname;
        if ((pathname.indexOf('tradeinpro') >= 0) && pathname.indexOf('tradeinpro/list') < 0) {
            document.getElementsByTagName('body')[0].className = '';
        }

        if (props.dealersettings.client.tradeinPro.pipelines.length === 0) {
            return {
                isLoading: false
            }
        }

        let _selectedPipeline = props.dealersettings.client.tradeinPro.pipelines.filter(item => item.default === true)[0];
        let nextColumns = Array.prototype.map.call((_selectedPipeline ? _selectedPipeline.stages : []), function (item) { return item.value; }).join(",").split(',')
        if ((JSON.stringify(nextColumns) !== JSON.stringify(state.columns)
            || props.dealersettings.client.id !== state.clientid)) {
            //props.dealersettings.ref = "";
            return {
                columns: Array.prototype.map.call((_selectedPipeline ? _selectedPipeline.stages : []), function (item) { return item.value; }).join(",").split(','),
                pipeline: props.dealersettings.client.tradeinPro.pipelines,
                clientid: props.dealersettings.client.id,
                isLoading: true
            }
        }
        else {
            return {
                isLoading: false
            }
        }

    }

    loadMoreData = () => {
        if (this.state.hasMore.length === 0) return;

        this.setState({
            stageLoading: this.state.hasMore[0].Stage
        })
        this.loadTradeinPro(true);
    }

    // listenScrollEvent(e) {
    //     if (this.state.hasMore.length === 0) return;

    //     if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
    //         this.setState({
    //             stageLoading: this.state.hasMore[0].Stage
    //         })
    //         this.loadTradeinPro()
    //     }
    //     return;
    // }

    updateTradeinPro = (_objTradeIP, forceUpdate) => {

        //console.log(_objTradeIP.id, _objTradeIP);
        const { tradeins, pipeline } = this.state;
        const { } = this.props;
        if (_.isEmpty(tradeins))
            return

        if (forceUpdate) {
            _objTradeIP = this.convertDataVM(_objTradeIP)
        }

        _objTradeIP.stageDate = _objTradeIP.stageDate && _objTradeIP.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objTradeIP.stageDate._seconds)._d) : (_objTradeIP.stageDate ? _objTradeIP.stageDate : _objTradeIP.addedDate);

        _objTradeIP.addedDate = _objTradeIP.addedDate && _objTradeIP.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objTradeIP.addedDate._seconds)._d) : _objTradeIP.addedDate;
        _objTradeIP.modifiedDate = _objTradeIP.modifiedDate && _objTradeIP.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objTradeIP.modifiedDate._seconds)._d) : _objTradeIP.modifiedDate;

        _objTradeIP.stageHistory = [..._.map(_objTradeIP.stageHistory, function (obj) {
            return obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]


        if (_objTradeIP.isDeleted === true && tradeins.tasks[_objTradeIP.id]) {
            const oldpipipeline = tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage] ? tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage].taskIds : []
            const index = oldpipipeline.indexOf(_objTradeIP.id)
            oldpipipeline.splice(index, 1);

            delete tradeins['tasks'][_objTradeIP.id]
        }
        else if (tradeins.tasks[_objTradeIP.id] && tradeins.tasks[_objTradeIP.id].pipeline === _objTradeIP.pipeline) {
            if (!Object.keys(tradeins.tasks).some(enq => enq === _objTradeIP.id)) {
                tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage].taskIds.splice(0, 0, _objTradeIP.id)
            }
            else if (tradeins.tasks[_objTradeIP.id].stage !== _objTradeIP.stage) {
                const oldsatge = tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage] ? tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage].taskIds : []
                const newstage = tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage] ? tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage].taskIds : []
                const index = oldsatge.indexOf(_objTradeIP.id)
                if (index > -1) {
                    oldsatge.splice(index, 1);
                }
                newstage.splice(0, 0, _objTradeIP.id)
            }

            let rDays = 0;
            let _selectedPipeline = pipeline.filter(item => item.default === true)[0]
            _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                if (_objTradeIP.stage === col.value) {
                    if (col.rottenDays) {
                        var enqage = _objTradeIP.modifiedDate ? moment().diff(moment.unix(_objTradeIP.modifiedDate.seconds), 'days') : 0
                        rDays = enqage - col.rottenDays
                    }
                }
            })

            tradeins['tasks'][_objTradeIP.id] = {
                ..._objTradeIP,
                enquiryage: (_objTradeIP.addedDate ? (moment.unix(_objTradeIP.addedDate.seconds).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '0 day' : moment.unix(_objTradeIP.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                rottendays: rDays
            }
        }
        else {
            let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
            if (_selectedPipeline && _selectedPipeline.value === _objTradeIP.pipeline && !_.isEmpty(tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage])) {
                const newstage = tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage].taskIds
                newstage.splice(0, 0, _objTradeIP.id)

                tradeins['tasks'][_objTradeIP.id] = {
                    ..._objTradeIP,
                    enquiryage: (_objTradeIP.addedDate ? (moment.unix(_objTradeIP.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(_objTradeIP.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                    rottendays: 0
                }
            }
            else if (tradeins.tasks[_objTradeIP.id]) {
                const oldpipipeline = tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage].taskIds
                const index = oldpipipeline.indexOf(_objTradeIP.id)
                oldpipipeline.splice(index, 1);

                delete tradeins['tasks'][_objTradeIP.id]
            }
        }

        this.setState({ tradeins })
    }


    handleApplyFilter = (_filter) => {
        //console.log('handleApplyFilter', _filter)
        let _selectedPipeline = this.state.pipeline.filter(item => item.default === true)[0];
        _selectedPipeline && _selectedPipeline.stages.forEach(s => {
            window['unsubTradein' + _selectedPipeline.value + s.value] && window['unsubTradein' + _selectedPipeline.value + s.value]();
        })
        window?.unsubTradeinPipelineCount?.unsubscribe && window.unsubTradeinPipelineCount.unsubscribe();
        window?.unsubTradeinPipeline?.unsubscribe && window.unsubTradeinPipeline.unsubscribe();

        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('tipPipelineFilter', JSON.stringify(_filter));
            localStorage.tradeinSearchText = '';
            this.setState({ isFilterApplied: true, isLoading: true, pageNum: 0, hasMore: [], tradeins: {}, stageCounts: {}, searchText: '' }, () => { this.loadTradeinPro() })
        }
        else {
            this.handleClearFilter();
        }

    }

    handleClearFilter = () => {
        //console.log('handleClearFilter',) 
        if (!_.isEmpty(localStorage.tipPipelineFilter)) {
            localStorage.removeItem('tipPipelineFilter');
            localStorage.tradeinSearchText = '';
            this.setState({ isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], tradeins: {}, stageCounts: {}, searchText: '' }, () => { this.loadTradeinPro() })
        }
    }

    handlesearchText = (val) => {
        localStorage.removeItem('tipPipelineFilter');
        this.setState({ searchText: val, isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], tradeins: {}, stageCounts: {} }, () => { this.loadTradeinPro() })
    }

    handlePageClear = () => {
        localStorage.removeItem('tipPipelineFilter');
        this.setState({ pageNum: 0, hasMore: [] })
    }

    //#region MANDATORY PERMISSIONstage, oldstage, _objTradeIP.enqFields
    isValidForStageUpdate = (_stage, enqFields, origin) => {

        const { pipeline, stage } = enqFields;
        const _oldStage = pipeline + '-' + stage;
        const _newStage = pipeline + '-' + _stage;
        const { dealersettings } = this.props;

        let _mandPiplines;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.tradeinPro) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.tradeinPro.mandatoryFields.pipeline))
            _mandPiplines = dealersettings.client.tradeinPro.mandatoryFields.pipeline;
        //console.log('isValidForStageUpdate', _oldStage, _newStage, enqFields, origin, _mandPiplines);
        if (!_.isEmpty(_mandPiplines)) {

            let __newPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];
            let __oldPipeline = _mandPiplines.filter(e => e.name === _oldStage)[0];

            if (!_.isEmpty(__newPipeline)) {

                if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                    for (let [key] of Object.entries(fieldsData)) {
                        __newPipeline[key] = ((__newPipeline[key] ? __newPipeline[key] : "") + (__oldPipeline[key] ? (__newPipeline[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
                    }
                }

                _isValid = this.handleMandatoryPermission(__newPipeline, enqFields, origin)
                //    console.log('isValidForStage------>Update', _newStage, _oldStage, 'ISVALID:' + _isValid, __newPipeline, __oldPipeline);
            }
            else if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                for (let [key] of Object.entries(fieldsData)) {
                    __oldPipeline[key] = (__oldPipeline[key] ? __oldPipeline[key] : "");
                }
                _isValid = this.handleMandatoryPermission(__oldPipeline, enqFields, origin)
                //    console.log('isValidForStage------>Update---->old', _oldStage, 'ISVALID:' + _isValid, __oldPipeline);
            }
        }

        return _isValid;
    }
    handleMandatoryPermission = (objFields, enqFields, origin) => {
        let isBreak = false;
        if (!_.isEmpty(objFields)) {
            const _fieldsData = Object.assign({}, fieldsData);
            for (let [key, value] of Object.entries(objFields)) {
                _fieldsData[key] = value;
            }

            const { contact } = enqFields;
            const _objTradeIP = Object.assign({}, objTradeIP);
            const ObjEnqFields = Object.assign({}, enqFields);
            for (let [key, value] of Object.entries(ObjEnqFields)) {
                _objTradeIP[key] = value;
            }

            for (let [key, value] of Object.entries(_fieldsData)) {
                if (key === 'contact' && !contact?.businessContact && !_.isEmpty(value)) {
                    if (!this.checkMandatoryContact(value, _objTradeIP)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'businessContact' && contact?.businessContact && !_.isEmpty(value)) {
                    if (!this.checkMandatoryContact(value, _objTradeIP)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'tradein' && !_.isEmpty(value)) {
                    if (!this.checkMandatoryTradeIn(value, _objTradeIP)) {
                        isBreak = true;
                        break;
                    }
                }

                if (key === 'tradeinPro' && !_.isEmpty(value)) {
                    if (!this.checkMandatoryTIP(value, _objTradeIP, origin)) {
                        isBreak = true;
                        break;
                    }
                }
            }
        }
        if (isBreak)
            return false;
        else
            return true;
    }

    handleOpenEnquiry = (documentID) => {
        this.props.history.push('/tradeinpro/details/' + documentID);
    }

    checkMandatoryContact = (value, enqFields) => {
        const { isContact, contact } = enqFields;
        let _mandatoryFields = value ? _.uniq(value.split(',')) : [];
        if (!isContact) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleOpenEnquiry(enqFields.documentID);
                }
            });

            return false;

        }
        else if (!_.isEmpty(_mandatoryFields)) {
            const ObjCustomer = Object.assign({}, contact);
            let msg;
            _mandatoryFields.some((el, index) => {
                if (el.trim() === 'licenseFrontURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license front image';
                        return true;
                    }
                } else if (el.trim() === 'licenseBackURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license back image';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemail') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                        msg = 'Please enter Phone or Email';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemailORlicenseNo') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                        msg = 'Please enter Phone or Email or License No.';
                        return true;
                    }
                }
                else if (!ObjCustomer[el]) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });
            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleOpenEnquiry(enqFields.documentID);
                    }
                });
                return false;
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    checkMandatoryTradeIn = (value, enqFields) => {
        const { isTradein, tradein } = enqFields;

        let _mandatoryFields = value ? _.uniq(value.split(',')) : [];

        //console.log('checkMandatoryContact', isContact, _mandatoryFields)
        if (!isTradein) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add tradein'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleOpenEnquiry(enqFields.documentID);
                }
            });
            // <SweetAlert title="Please add contact" onConfirm={this.handleContactOpen('addContact', _mandatoryFields)}  />
            return false;

        }
        else if (!_.isEmpty(_mandatoryFields)) {
            const ObjCustomer = Object.assign({}, tradein);
            let msg;
            _mandatoryFields.some((el, index) => {
                if (!ObjCustomer[el]) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });
            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in tradein'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleOpenEnquiry(enqFields.documentID);
                    }
                });
                return false;
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    checkMandatoryTIP = (value, enqFields, origin) => {
        let _mandatoryFields = !_.isEmpty(value) ? _.uniq(value.split(',')) : [];

        if (!_.isEmpty(this.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.mandatoryFields);

        if (!_.isEmpty(_mandatoryFields)) {
            const _objTradeIP = Object.assign({}, objTradeIP);
            const ObjEnqFields = Object.assign({}, enqFields);
            for (let [key, value] of Object.entries(ObjEnqFields)) {
                _objTradeIP[key] = value;
            }

            let msg;

            let _isbreak = false;

            let _specialKey = ['isContact', 'isTradein', 'isFiles']

            let _requiredObject = _.filter(_mandatoryFields, (v) => (_specialKey.indexOf(v) >= 0));

            let _requiredFields = _.filter(_mandatoryFields, (v) => (_specialKey.indexOf(v) < 0));

            //console.log('checkMandatory-Enquiry', value, _mandatoryFields, this.mandatoryFields, _requiredObject, _requiredFields)

            !_.isEmpty(_requiredObject) && _requiredObject.some((el, index) => {
                if (el.trim() === 'isContact') {
                    if (!_objTradeIP[el] && _.isEmpty(_objTradeIP['contact'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add contact'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID);
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isTradein') {
                    if (!_objTradeIP[el] && _.isEmpty(_objTradeIP['tradein'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add tradein'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID);
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isFiles') {
                    if (!_objTradeIP[el]) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add file'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID);
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                }
            });

            if (_isbreak)
                return false;

            !_.isEmpty(_requiredFields) && _requiredFields.some((el, index) => {
                if (!_objTradeIP[el]) {
                    if (_objTradeIP.hasOwnProperty(el)) {
                        msg = 'Please enter ' + el;
                        return true;
                    }
                    else if (
                        (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === el && m.active === true)[0])) &&
                        (_.isEmpty(_objTradeIP.dynamicFields) ||
                            (!_.isEmpty(_objTradeIP.dynamicFields) && _.isEmpty(_objTradeIP.dynamicFields[el]) && !_.isBoolean(_objTradeIP.dynamicFields[el]) && !_.isNumber(_objTradeIP.dynamicFields[el]))
                        )
                    ) {
                        msg = 'Please enter ' + el;
                        return true;
                    }
                }
            });

            if (msg) {
                let errors = {};
                let formIsValid = true;
                let errorClass = 'input_error';

                _requiredFields.forEach((key, index) => {
                    if (!_objTradeIP[key] && _requiredFields.indexOf(key) >= 0) {
                        if (_objTradeIP.hasOwnProperty(key)) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                        else if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                            (_.isEmpty(_objTradeIP.dynamicFields) ||
                                (!_.isEmpty(_objTradeIP.dynamicFields) && _.isEmpty(_objTradeIP.dynamicFields[key]) && !_.isBoolean(_objTradeIP.dynamicFields[key]) && !_.isNumber(_objTradeIP.dynamicFields[key]))
                            )) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                });

                if (formIsValid === true) {
                    return true;
                } else {
                    console.log('checkMandatory-tradeinpro-errors', errors)
                    this.setState({
                        enquiryModal: {
                            show: true,
                            enquiry: _objTradeIP,
                            errors: errors,
                            origin: origin
                        },
                    });
                    return formIsValid;
                }
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    handleEnquiryOptionClose = (status) => {
        const { enquiryModal, tradeins } = this.state;
        //   console.log('handleEnquiry-OptionClose', status, enquiryModal, tradeins);

        if (status && !_.isEmpty(enquiryModal) && !_.isEmpty(enquiryModal.origin)) {
            let _origin = Object.assign({}, enquiryModal.origin)
            const { id, val, stage, oldstage } = _origin;
            let objEnq = !_.isEmpty(tradeins['tasks']) && tradeins['tasks'][id] ? tradeins['tasks'][id] : enquiryModal.enquiry;
            if (!_.isEmpty(objEnq))
                this.handleEnquiryFav(id, val, stage, oldstage, objEnq);

        }
        this.setState({
            enquiryModal: {
                show: false,
                enquiry: {},
                errors: null,
                origin: {}
            },
        });
    };

    //#endregion

    handlePipelineChange = (value) => {
        this.props.updateDealerSettings('tradeinProPipeline', value);
        this.handlePageClear();
    }

    handleStatusFilter = (_status) => {
        if (_status) {
            if (!_.isEmpty(localStorage.tipPipelineFilter)) {
                let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
                if (!_.isEmpty(_pipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value['status'] = _status
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { 'status': _status }
                }
                this.handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'status': _status }, type: 'filter' })
                this.handleApplyFilter(_pipelineFilter);
            }
        }
        else if (!_.isEmpty(localStorage.tipPipelineFilter)) {
            let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);
            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('status'))
                this.handleClearFilter();
            else {
                delete _pipelineFilter.value['status'];
                this.handleApplyFilter(_pipelineFilter);
            }
        }
    }

    render() {

        const { children, dealersettings, clientUsers } = this.props
        const {
            columns, pipeline, tradeins, hasMore,
            isLoading, stageLoading, showNotifier, notifierMsg, notifierInfo,
            sectionloading, enquiryModal,
            pageNum, isFilterApplied, stageCounts, searchText,
        } = this.state


        //console.log('E N Q U I R I E S', stageCounts)
        return (
            <>
                <StageContext.Provider
                    value={{
                        tradeins,
                        pipeline,
                        columns,
                        isLoading,
                        handleEnquiryFav: this.handleEnquiryFav,
                        loadMoreData: this.loadMoreData,
                        stageLoading,
                        showNotifier,
                        notifierMsg,
                        notifierInfo,
                        updateTradeinPro: this.updateTradeinPro,
                        sectionloading,
                        handleApplyFilter: this.handleApplyFilter,
                        handleClearFilter: this.handleClearFilter,
                        handleStatusFilter: this.handleStatusFilter,
                        pageNum,
                        isFilterApplied,
                        stageCounts,
                        searchText,
                        hasMore,
                        handlesearchText: this.handlesearchText,
                        dealersettings: this.props.dealersettings,
                        handlePageClear: this.handlePageClear,
                        handlePipelineChange: this.handlePipelineChange
                    }}
                >
                    {children}
                </StageContext.Provider>

                <PopUpModal show={enquiryModal.show}>
                    <EnquiryFields
                        title='appraisalInfo'
                        show={enquiryModal.show}
                        errors={enquiryModal.errors}
                        newStatus={'stage'}
                        tradeinPro={enquiryModal.enquiry}
                        tradeinProID={enquiryModal.enquiry['documentID']}
                        contact={enquiryModal.enquiry?.contact}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        handleClose={this.handleEnquiryOptionClose}
                    />
                </PopUpModal>

            </>
        )
    }

}