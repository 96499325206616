/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { default as _images } from "../../../images";
import { PopoverListLoader } from '../../../components';
import CommonHelper from '../../../services/common'
/** VIEW-MODELS */


class StockQuotesList extends Component {
    constructor(props) {
        super(props);
        this.unsubscribeSQ = null;
        this.state = {
            stocks: [],
            filter: {
                iSearch: ''
            },
            loading: true
        };
    }

    static applyFilter = (listings, filter) => {
        const { iSearch } = filter
        let result = listings

        if (iSearch) {
            result = result.filter(item =>
                item.vehicle.model.toLowerCase().includes(iSearch) ||
                (item.vehicle.type ? item.vehicle.type.toLowerCase().includes(iSearch) : false) ||
                (item.vehicle.group ? item.group.stockNo.toLowerCase().includes(iSearch) : false))
        }

        return result
    }

    onCollectionUpdate = (querySnapshot) => {
        const stocks = [];
        querySnapshot.forEach((doc) => {
            const stock = Object.assign({}, doc.data());
            stock.documentID = doc.id;
            stocks.push(stock);
        });

        this.setState({
            stocks: stocks,
            loading: false
        });
    }

    componentDidMount() {
        const { dealersettings, enquiry, requirement } = this.props;
        if (dealersettings) {
            let cliendId = dealersettings.client ? dealersettings.client.id : '';
            //console.log('cliendId', cliendId)
            let _req = requirement.stock && requirement.stock.make ? requirement.stock : requirement;
            this.unsubscribeSQ = window.firebase.firestore().collection('stockQuotations')
                .where('clientID', '==', enquiry.clientID)
                .where('isDeleted', '==', false)
                .where('vehicle.make', '==', _req.make)
                .onSnapshot(this.onCollectionUpdate);
        }
    }
    componentWillUnmount() {
        this.unsubscribeSQ && this.unsubscribeSQ();
    }

    handleChange = (prop, value) => {
        let state = this.state.filter;
        state[prop] = value;
        this.setState({ filter: state });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = this.state.filter;
        state[name] = checked;
        this.setState({ filter: state });
    }

    render() {
        const { iSearch } = this.state.filter
        const { stocks, filter, loading } = this.state
        const filteredList = StockQuotesList.applyFilter(
            stocks,
            filter
        )

        const { show, dealersettings, currencySymbol, requirement, handleClose } = this.props;

        const divStyle = {
            height: '400px',
            overflow: 'auto'
        };
        return (
            <>
                <Modal
                    id="stockQuotesList"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'Select Quotation'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        <div className="vehicle-popover-head multilead-head">

                            <div className="multilead-addbutton">
                                <button type="button" className="btn btn-secondary float-right"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleClose('continue', requirement);
                                    }}>
                                    <i className="ico icon-close"></i> <Translate text={'Continue with selected vehicle'} /></button>
                            </div>


                            <input
                                //className="form-control"
                                type="text"
                                id="isearch"
                                aria-label="Search"
                                placeholder="search..."
                                value={iSearch}
                                autoComplete="off"
                                onChange={event =>
                                    this.handleChange('iSearch', event.target.value)
                                }
                            />
                        </div>

                        <Suspense>
                            <div style={divStyle} >
                                {
                                    loading ? (<PopoverListLoader />) :
                                        filteredList.length > 0
                                            ?
                                            <>
                                                {filteredList.map(quotation =>
                                                    <div key={quotation.documentID} className="vehicle-popover-loop" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleClose('select', requirement, quotation);
                                                    }}>
                                                        <div className="divTable">
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell">

                                                                        <div className='flex-quotation-sidebar'>

                                                                            <div className='quotation-sidebar-icon'>
                                                                                {' '}
                                                                                <i className='ico icon-contract'></i>{' '}
                                                                            </div>
                                                                            <div className='quotation-sidebar-data'>
                                                                                <div className='quotation-sidebar-text'>
                                                                                    <div className='quotation-sidebar-id'>
                                                                                        {' '}
                                                                                        <strong>{quotation.quotationStatus === 'contract' ? 'Contract' : 'Quote'} #{quotation.displayID}</strong>

                                                                                    </div>
                                                                                    <div className='quotation-sidebar-title'>
                                                                                        {' '}
                                                                                        <strong>{quotation.vehicle.make ? quotation.vehicle.make : ''}{quotation.vehicle.model ? ` ${quotation.vehicle.model}` : ''}</strong>
                                                                                    </div>
                                                                                    {/* <div className='quotation-sidebar-date'>
                                                                                            <span>{!isEmpty(quotation.addedDate) ? moment(quotation.addedDate.toDate()).format('DD/MM/YYYY h:mm A') : ''} </span>{` - ${!isEmpty(quotation.addedBy) ? CommonHelper.getUserNamebyId(clientUsers, quotation.addedBy) : ''}`}
                                                                                        </div> */}
                                                                                    <div className='quotation-sidebar-inline'>
                                                                                        <div className='quotation-sidebar-pricetag'>
                                                                                            {' '}
                                                                                            <NumberFormat
                                                                                                decimalScale={2}
                                                                                                thousandSeparator={true}
                                                                                                prefix={currencySymbol}
                                                                                                displayType={'text'}
                                                                                                value={quotation.payableOnDelivery ? quotation.payableOnDelivery : 0}
                                                                                            />
                                                                                            <div></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i> </div>
                                                        <div className="no-data-txt mt-2"> No Quotation found</div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div>
                        </Suspense>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}

export default StockQuotesList;