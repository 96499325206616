/** LIBRARIES */
import React, { PureComponent } from "react";
import _ from 'lodash';

export default class InputAutoText extends PureComponent {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            defaultVaue: "InputAutoText",
            focus: false
        };
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick);
    }

    handleOutsideClick = (e) => {
        const { id, name, value, disableOutsideClick } = this.props;

        if (!_.isEmpty(value) && disableOutsideClick) {
            return false;
        }
        else {
            if (e.target.id === (id ? id : name) || (this.myRef && this.myRef.current && this.myRef.current.contains(e.target))) {
                return;
            }

            this.handleSave();
        }

    }

    handleSave = () => {
        const { name, value, id, disableOutsideClick } = this.props;
        const { defaultVaue } = this.state;

        if (defaultVaue !== 'InputAutoText' && ((defaultVaue !== value) || (disableOutsideClick && defaultVaue === value)))
            this.props.onSaveField(name, value);

        document.getElementById(id ? id : name).classList.add('cursor-pointer')
        this.setState({ defaultVaue: 'InputAutoText', focus: false })
    }

    handleCancel = () => {
        const { name, value, id } = this.props;
        const { defaultVaue } = this.state;

        if (defaultVaue !== 'InputAutoText' && defaultVaue !== value)
            this.props.onCancelField(name, defaultVaue);
        document.getElementById(id ? id : name).classList.add('cursor-pointer')
        this.setState({ defaultVaue: 'InputAutoText', focus: false })
    }

    render() {
        const { id, name, type, value, onChange, placeholder, autoComplete, className, onKeyUp, maxLength } = this.props;
        const { focus } = this.state
        //('RENDER INPUT TEXT', name, value)
        return (
            <div className="input-focus-edit">
                <div ref={this.myRef} className={`input-focus-buttonwrap ${!focus ? "hide" : ""}`}>
                    <button type="button" className="btn btn-focus float-left" title="save"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleSave();
                        }}>
                        {" "}
                        <i className="ico icon-check"></i>
                    </button>

                    <button type="button" className="btn btn-focus float-left " title="cancel"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleCancel();
                        }}>
                        {" "}
                        <i className="ico icon-remove"></i>
                    </button>
                </div>

                <input
                    id={id ? id : name}
                    autoComplete={autoComplete ? autoComplete : "off"}
                    name={name}
                    type={type}
                    value={value ? value : ''}
                    placeholder={placeholder}
                    className={className}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    maxLength={maxLength ? maxLength : "150"}
                    onFocus={() => {
                        //console.log('onFocus', value);
                        document.getElementById(id ? id : name).classList.remove('cursor-pointer')
                        this.setState({
                            defaultVaue: value,
                            focus: true
                        })
                    }}
                />
            </div>

        );
    }
}