/** LIBRARIES */
import React from 'react'
import { Droppable } from "react-beautiful-dnd";
import { Spinner } from 'react-bootstrap'
/** COMPONENTS */
import OrderCell from './orderCell'

const OrderStages = ({ column, tasks, sidepanelOpen, stageLoading, clientUsers, groupUsers }) => {
    return (
        <div className="pipeline-board" >
            <div className="pipeline-board-header sticky-board-head">
                <h2>{column.title} </h2>
                <h5>{column.taskIds.length} {column.taskIds.length > 1 ? 'Orders' : 'Order'} </h5>
            </div>
            <Droppable droppableId={column.id} >
                {(provided, snapshot) => (
                    <div className={"pipeline-item-wraper " + (snapshot.isDraggingOver ? "pipeline-dragging-Over" : "")}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {tasks.map((task, index) => (
                            <OrderCell
                                key={index}
                                index={index}
                                id={task.id}
                                status={task.status}
                                task={task}
                                sidepanelOpen={sidepanelOpen}
                                clientUsers={clientUsers}
                                groupUsers={groupUsers}
                            />
                        ))}
                        {
                            stageLoading && stageLoading === column.id ?
                                (<div style={{ textAlign: 'center' }}>
                                    <Spinner animation="border" variant="primary" />
                                </div>)
                                : (<></>)
                        }
                        <span
                            style={{
                                display: "none"
                            }}
                        >
                            {provided.placeholder}
                        </span>
                    </div>
                )}
            </Droppable>
        </div>
    )
}

export default OrderStages