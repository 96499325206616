import React, { useState, useEffect, useContext, Fragment } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { InputText, ReactSelect, InputMultipleText, InputCheckBox, PopUpModal, ReactMultiSelect, AntDatePicker, ReactTimePicker } from '../../../components'
import { Modal } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react'
import Translate from '../../../constants/translate';
import images from '../../../images';
import { emailTo, frequencyOptions, tiggerFrequency, triggerDateOptions, createList, actionList, attendeeStatus } from '../viewModel';
import moment from 'moment';
import TemplateEditor from '../../eventsProTemplates/templateEditor';
import AddTemplateHTML from '../../eventsProTemplates/addTemplateHTML';
import { levelOptions, mentions, specialLinks } from '../../eventsProTemplates/viewModel';
import Swal from 'sweetalert2';



const ManageTemplates = (props) => {
    const [trigger, setTrigger] = useState(props.trigger)
    const [errorFields, setErrorFields] = useState({});
    const [currentClient, setCurrentClient] = useState(props.currentClientID)
    const [loader, setLoader] = useState(false)
    const [addHtml, setAddHtml] = useState({ show: false })
    const [showEdiorModel, setShowEdiorModel] = useState({ show: false })
    const [formMentions, setMentions] = useState(mentions);
    const statusIndex = props?.status?.some(a => a.value === props.event.status) ? _.find(props.status, { value: props.event.status }).index : 0
    const [lockTrigger, setLockTrigger] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.trigger)) {
            const newtriggerVM = {};
            newtriggerVM.documentID = window.firebase.firestore().collection(`eventProTriggers`).doc().id;
            newtriggerVM.eventsProID = props.eventsProID;
            newtriggerVM.addedBy = localStorage.uid;
            newtriggerVM.modifiedBy = localStorage.uid;
            newtriggerVM.category = props?.dealersettings?.client?.eventsPro?.categories ? props.dealersettings.client.eventsPro.categories[0].value : null;
            newtriggerVM.addedDate = window.firebase.firestore.Timestamp.now();
            newtriggerVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newtriggerVM.isActive = true;
            newtriggerVM.isDeleted = false;
            newtriggerVM.status = 'eventsProStatus'
            newtriggerVM.module = 'eventsPro'
            newtriggerVM.projectId = props?.event?.projectId ? props.event.projectId : null;
            if (!_.isEmpty(props.level)) {
                newtriggerVM[`${props.level}ID`] = props[`${props.level}ID`];
                newtriggerVM.level = props.level;
                newtriggerVM.oemID = props.dealersettings.oemID;
            }
            else {
                newtriggerVM.clientID = props.clientID;
            }
            setTrigger(newtriggerVM)
        }
        else {
            setTrigger({
                ...props.trigger,
                periodValue: trigger.frequencyValue === 'before' ? -(trigger.periodValue) : trigger.periodValue ? trigger.periodValue : null
            })
            const triggerIndex = props?.status?.some(a => a.value === props.trigger.eventsProStatus) ? _.find(props.status, { value: props.trigger.eventsProStatus }).index : 0
            if (statusIndex >= triggerIndex && !_.isEmpty(props.trigger.eventsProStatus)) {
                setLockTrigger(true)
            }
        }
    }, [])

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;


        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            if (currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn) {
                setTrigger({
                    ...trigger,
                    individual: trigger.individual ?
                        [
                            ...trigger.individual.filter(a => a.clientID !== currentClient),
                            {
                                ...(_.find(trigger?.individual, { clientID: currentClient }) ? _.find(trigger?.individual, { clientID: currentClient }) : {}),
                                [name]: newarray1.join(' '),
                                clientID: currentClient
                            }
                        ]
                        : [{
                            [name]: newarray1.join(' '),
                            clientID: currentClient
                        }]
                })
            }
            else {
                setTrigger({
                    ...trigger,
                    [name]: newarray1.join(' ')
                })
            }

        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            if (currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn) {
                setTrigger({
                    ...trigger,
                    individual: trigger.individual ?
                        [
                            ...trigger.individual.filter(a => a.clientID !== currentClient),
                            {
                                ...(_.find(trigger?.individual, { clientID: currentClient }) ? _.find(trigger?.individual, { clientID: currentClient }) : {}),
                                [name]: str,
                                clientID: currentClient
                            }
                        ]
                        : [{
                            [name]: str,
                            clientID: currentClient
                        }]
                })
            }
            else {
                setTrigger({
                    ...trigger,
                    [name]: str
                });
            }
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'fieldName') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['eventsProStatus']: e ? e.status : null
            })
        }
        else if ((data.name === 'templateID') && currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn) {
            setTrigger({
                ...trigger,
                individual: trigger.individual ?
                    [
                        ...trigger.individual.filter(a => a.clientID !== currentClient),
                        {
                            ...(_.find(trigger?.individual, { clientID: currentClient }) ? _.find(trigger?.individual, { clientID: currentClient }) : {}),
                            [data.name]: e ? e.value : '',
                            clientID: currentClient
                        }
                    ]
                    : [{
                        [data.name]: e ? e.value : '',
                        clientID: currentClient
                    }]
            })
        }
        else if (data.name === 'emailTo') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['eventsProAttendeeStatus']: null,
                ['status']: e ? e.status : trigger.status,
                ['period']: e?.status === 'eventsProAttendeeStatus' || e?.status === 'eventsProAttendeeCheckedIn' ? 'instant' : trigger.period,
            })
        }
        else if (data.name === 'eventsProAttendeeStatus') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['fieldName']: e ? `${e.value}Date` : null,
            })
        }
        else {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleOnChangeMultipleText = (val, name) => {
        if (currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn) {
            setTrigger({
                ...trigger,
                individual: trigger.individual ?
                    [
                        ...trigger.individual.filter(a => a.clientID !== currentClient),
                        {
                            ...(_.find(trigger?.individual, { clientID: currentClient }) ? _.find(trigger?.individual, { clientID: currentClient }) : {}),
                            [name]: !_.isEmpty(val) ? val.join(',') : '',
                            clientID: currentClient
                        }
                    ]
                    : [{
                        [name]: !_.isEmpty(val) ? val.join(',') : '',
                        clientID: currentClient
                    }]
            })
        }
        else {
            setTrigger({
                ...trigger,
                [name]: !_.isEmpty(val) ? val.join(',') : ''
            })
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setTrigger({
            ...trigger,
            [e.target.name]: e.target.checked
        })
    }

    const removeEmail = (index, name) => {
        if (currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn) {
            let arr = _.find(trigger.individual, { clientID: currentClient })[name] ? _.find(trigger.individual, { clientID: currentClient })[name].split(',') : []
            arr.splice(index, 1)
            setTrigger({
                ...trigger,
                individual: trigger.individual ?
                    [
                        ...trigger.individual.filter(a => a.clientID !== currentClient),
                        {
                            ...(_.find(trigger?.individual, { clientID: currentClient }) ? _.find(trigger?.individual, { clientID: currentClient }) : {}),
                            [name]: arr.join(','),
                            clientID: currentClient
                        }
                    ]
                    : [{
                        [name]: arr.join(','),
                        clientID: currentClient
                    }]
            })
        }
        else {
            let arr = trigger[name] ? trigger[name].split(',') : []
            arr.splice(index, 1)
            setTrigger({
                ...trigger,
                [name]: arr.join(',')
            })
        }

    }


    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setTrigger({
                ...trigger,
                [e.target.name]: number !== '' ? Number(number) : number
            });
        }

        setErrorFields({
            ...errorFields,
            [e.target.name]: ''
        });
    };

    const handleDateChange = (val, name) => {
        if (val) {
            val = moment(moment(val).format('YYYY-MM-DD') + ' ' + (trigger[name] ? moment.unix(trigger[name].seconds).format('HH:mm') : moment().format('HH:mm')))._d;

            setTrigger({
                ...trigger,
                [name]: window.firebase.firestore.Timestamp.fromDate(moment(val).toDate())
            });
            setErrorFields({
                ...errorFields,
                [name]: ''
            });
        }
    };

    const handleTimeChange = (date, name) => {


        setTrigger({
            ...trigger,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(date).toDate())
        });
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const saveTrigger = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (trigger.status === 'eventsProStatus') {
            if (_.isEmpty(trigger['period'])) {
                formIsValid = false;
                errors['period'] = errorClass;
            }
            if (_.isEmpty(trigger['fieldName'])) {
                formIsValid = false;
                errors['fieldName'] = errorClass;
            }
            if (_.isEmpty(trigger['eventsProStatus'])) {
                formIsValid = false;
                errors['eventsProStatus'] = errorClass;
            }
        }
        if (trigger.status === 'eventsProAttendeeStatus' || trigger.status === 'eventsProAttendeeCheckedIn') {
            if (_.isEmpty(trigger['eventsProAttendeeStatus'])) {
                formIsValid = false;
                errors['eventsProAttendeeStatus'] = errorClass;
            }
        }
        if (_.isEmpty(trigger['emailTo'])) {
            formIsValid = false;
            errors['emailTo'] = errorClass;
        }
        if (trigger.fieldName === 'customDate') {
            if (_.isEmpty(trigger['customDate'])) {
                formIsValid = false;
                errors['customDate'] = errorClass;
                errors['customTime'] = errorClass;
            }
        }
        if (trigger.period !== 'instant' && trigger.fieldName !== 'customDate') {
            if (!trigger['periodValue'] || trigger['periodValue'] === 0 || _.isEmpty(trigger['periodValue'].toString())) {
                formIsValid = false;
                errors['periodValue'] = errorClass;
            }
            if (_.isEmpty(trigger['frequencyValue'])) {
                formIsValid = false;
                errors['frequencyValue'] = errorClass;
            }
        }
        if (currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn) {
            if (_.isEmpty(_.find(trigger?.individual, { clientID: currentClient })['templateID'])) {
                formIsValid = false;
                errors['templateID'] = errorClass;
            }
            if (_.isEmpty(_.find(trigger?.individual, { clientID: currentClient })['subject'])) {
                formIsValid = false;
                errors['subject'] = errorClass;
            }
        }
        else {
            if (_.isEmpty(trigger['templateID'])) {
                formIsValid = false;
                errors['templateID'] = errorClass;
            }
            if (_.isEmpty(trigger['subject'])) {
                formIsValid = false;
                errors['subject'] = errorClass;
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true);
        if (trigger.eventsProAttendeeStatus === 'checkIn') {
            trigger.status = 'eventsProAttendeeCheckedIn';
        }
        if (trigger.emailTo === 'attendee' && trigger.eventsProAttendeeStatus !== 'checkIn') {
            trigger.status = 'eventsProAttendeeStatus';
        }
        trigger.individual = trigger.individual ? trigger.individual.map(rec => { return rec.useOwn && _.isEmpty(rec.templateID) ? { ...rec, useOwn: false } : { ...rec } }) : []
        if (trigger.frequencyValue === 'before') {
            trigger.periodValue = -(trigger.periodValue)
        }
        window.firebase.firestore().doc(`eventsProTriggers/${trigger.documentID}`)
            .set(trigger, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Trigger updated successfully', {
                    duration: 2000
                })
                //props.handleClose(trigger);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(trigger) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog fullscreenmodal-lg events-pop-template-fullscreen">
                <div className="modal-content modal-content-fullscreen">
                    <Modal.Header className="modal-header-fullscreen">
                        <div className="modal-title">  <Translate text={props.title} /></div>
                        <button type="button" className="close close-fullscreen float-right" onClick={() => { props.handleClose() }}>×</button>
                    </Modal.Header>
                    <Modal.Body className="modal-body-fullscreen">
                        <div className="popup-wrap-fullscreen h-100 ">
                            <div className="container-fluid ">
                                <div className="events-pop-template-wrapper">

                                    <div className="events-pop-template-column-wraper">
                                        <div className="events-pop-template-columns">
                                            <div className="events-pop-template-column-left">
                                                <div className="events-pop-template-column-title">
                                                    <h3>Category </h3>
                                                </div>
                                                <div className="events-pop-template-column-nav">
                                                    {
                                                        props?.dealersettings?.client?.eventsPro?.categories ? (
                                                            <ul>
                                                                {
                                                                    props.dealersettings.client.eventsPro.categories.map((rec, index) => {
                                                                        return <li key={index} className={`${trigger.category === rec.value ? 'active' : ''}`}>
                                                                            <a href="#" className="column-list-left" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (_.find(props?.triggers, { category: rec.value })) {
                                                                                    setTrigger(_.find(props?.triggers, { category: rec.value }))
                                                                                    const triggerIndex = props?.status?.some(a => a.value === _.find(props?.triggers, { category: rec.value }).eventsProStatus) ? _.find(props.status, { value: _.find(props?.triggers, { category: rec.value }).eventsProStatus }).index : 0
                                                                                    if (statusIndex >= triggerIndex && !_.isEmpty(_.find(props?.triggers, { category: rec.value }).eventsProStatus)) {
                                                                                        setLockTrigger(true)
                                                                                    }
                                                                                    else {
                                                                                        setLockTrigger(false)
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    const newtriggerVM = {
                                                                                        category: rec.value,
                                                                                        documentID: window.firebase.firestore().collection(`eventProTriggers`).doc().id,
                                                                                        addedBy: localStorage.uid,
                                                                                        modifiedBy: localStorage.uid,
                                                                                        addedDate: window.firebase.firestore.Timestamp.now(),
                                                                                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                                                                                        eventsProID: props.eventsProID,
                                                                                        isActive: true,
                                                                                        isDeleted: false,
                                                                                        status: 'eventsProStatus',
                                                                                        module: 'eventsPro',
                                                                                        projectId: props?.event?.projectId ? props.event.projectId : null
                                                                                    }
                                                                                    if (!_.isEmpty(props.level)) {
                                                                                        newtriggerVM[`${props.level}ID`] = props[`${props.level}ID`];
                                                                                        newtriggerVM.level = props.level;
                                                                                        newtriggerVM.oemID = props.dealersettings.oemID;
                                                                                    }
                                                                                    else {
                                                                                        newtriggerVM.clientID = props.clientID;
                                                                                    }
                                                                                    setTrigger(newtriggerVM)
                                                                                    setLockTrigger(false)
                                                                                }

                                                                            }}>
                                                                                {
                                                                                    _.find(props?.triggers, { category: rec.value }) ? (
                                                                                        <div className="events-pop-template-active-check">
                                                                                            {
                                                                                                statusIndex >= _.find(props.status, { value: _.find(props?.triggers, { category: rec.value }).eventsProStatus })?.index ? (
                                                                                                    <i className="ico icon-lock" style={{ fontSize: '16px' }}> </i>
                                                                                                ) : (
                                                                                                    <i className="ico icon-check"> </i>
                                                                                                )
                                                                                            }</div>
                                                                                    ) : (<></>)
                                                                                }
                                                                                <div className="events-pop-template-list-head"> {rec.name}</div>

                                                                            </a>
                                                                        </li>

                                                                    })
                                                                }
                                                            </ul>
                                                        ) : (<></>)
                                                    }

                                                </div>
                                            </div>


                                            <div className="events-pop-template-column-middle">
                                                <div className="events-pop-template-column-middlesub">

                                                    <div className={`events-pop-template-column-middle-left ${lockTrigger ? 'form-disable ' : ''}`} style={lockTrigger ? { opacity: '0.7' } : {}}>
                                                        <div className="events-pop-template-column-title">
                                                            <h3> Settings </h3>
                                                        </div>
                                                        {
                                                            !_.isEmpty(trigger.category) ? (<>
                                                                {
                                                                    props?.clientIDs?.length > 1 ? (<>
                                                                        <div className="events-template-dealer-list">
                                                                            <h4>Dealership</h4>
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-12">
                                                                                    <ReactSelect
                                                                                        options={props?.dealersettings?.clients ? props.dealersettings.clients.filter(a => props.clientIDs.includes(a.documentID)).map(rec => {
                                                                                            return {
                                                                                                label: (<>{rec.name}{
                                                                                                    rec.documentID === props.clientID ?
                                                                                                        <div className="badge badge-pill badge-white ml-2">Default</div> : (<></>)
                                                                                                }</>),
                                                                                                value: rec.documentID
                                                                                            }
                                                                                        }) : []}
                                                                                        name={"currentClient"}
                                                                                        placeholder={'select dealer'}
                                                                                        onChange={(e) => {
                                                                                            setCurrentClient(e.value)
                                                                                        }}
                                                                                        value={currentClient}
                                                                                        classNamePrefix={`${errorFields["currentClient"]} basic-select`}
                                                                                        removeClearable={true}
                                                                                    >
                                                                                    </ReactSelect>
                                                                                </div>
                                                                                {
                                                                                    currentClient !== props.clientID ? (
                                                                                        <span className="switch switch-sm float-left">
                                                                                            <InputCheckBox
                                                                                                className={`switch`}
                                                                                                id={`trigger-useOwn`}
                                                                                                name={'useOwn'}
                                                                                                checked={_.find(trigger?.individual, { clientID: currentClient }) ?
                                                                                                    _.find(trigger?.individual, { clientID: currentClient }).useOwn : false}
                                                                                                onChange={(e) => {
                                                                                                    setTrigger({
                                                                                                        ...trigger,
                                                                                                        individual: trigger.individual ?
                                                                                                            [
                                                                                                                ...trigger.individual.filter(a => a.clientID !== currentClient),
                                                                                                                {
                                                                                                                    ...(_.find(trigger?.individual, { clientID: currentClient }) ? _.find(trigger?.individual, { clientID: currentClient }) : {}),
                                                                                                                    useOwn: e.target.checked,
                                                                                                                    clientID: currentClient
                                                                                                                }
                                                                                                            ]
                                                                                                            : [{
                                                                                                                useOwn: e.target.checked,
                                                                                                                clientID: currentClient
                                                                                                            }]
                                                                                                    })
                                                                                                }} />
                                                                                            <label htmlFor={`trigger-useOwn`}><Translate text={'Use Own Template'} /></label>
                                                                                        </span>
                                                                                    ) : (<></>)
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </>) : (<></>)
                                                                }
                                                                <div className="events-pop-template-leftmenu">

                                                                    <div className="form-style events-pop-template-wrap">
                                                                        <div className="form-row">
                                                                            <h4>Template Settings</h4>
                                                                            <div className="form-group col-md-12">
                                                                                {
                                                                                    (_.isEmpty(trigger.templateID) ||
                                                                                        (_.find(trigger?.individual, { clientID: currentClient })?.useOwn &&
                                                                                            _.isEmpty(_.find(trigger?.individual, { clientID: currentClient })?.templateID))) ?
                                                                                        (
                                                                                            <Dropdown text={'create'} key={"1001"} className={`inputlink-addmore`} tabIndex={-1} >
                                                                                                <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
                                                                                                    {
                                                                                                        createList.map((data, idx) => (
                                                                                                            <Dropdown.Item className={'action-menu-fix'} text={data.label} key={idx} onClick={() => {
                                                                                                                if (data.value === 'html') {
                                                                                                                    setAddHtml({ show: true, title: 'Add Email HTML', template: null })
                                                                                                                }
                                                                                                                else {
                                                                                                                    setShowEdiorModel({ show: true, title: 'Add Email Template' })
                                                                                                                }
                                                                                                            }} />
                                                                                                        ))
                                                                                                    }
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        ) : (
                                                                                            <Dropdown text={'action'} key={"1001"} className={`inputlink-addmore`} tabIndex={-1} >
                                                                                                <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
                                                                                                    {
                                                                                                        actionList.map((data, idx) => {
                                                                                                            const templateInfo = _.find(props.templates, {
                                                                                                                documentID: currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn ? (_.find(trigger?.individual, { clientID: currentClient }) ?
                                                                                                                    _.find(trigger?.individual, { clientID: currentClient }).templateID : null) : trigger.templateID
                                                                                                            })
                                                                                                            let _userLevel = levelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(levelOptions, { value: props.dealersettings.level })?.index);
                                                                                                            return (data.value === 'edit' && templateInfo?.clientIDs?.some(a => props.dealersettings.clients.some(b => b.documentID === a))) || data.value === 'clone' ? <Dropdown.Item className={'action-menu-fix'} text={data.label} key={idx} onClick={() => {
                                                                                                                if (data.value === 'edit') {
                                                                                                                    if (!_.find(_userLevel, { value: templateInfo.level })) {
                                                                                                                        Swal.fire('Access Denied.', '', 'error')
                                                                                                                    }
                                                                                                                    else if (templateInfo?.templateJSON) {
                                                                                                                        setShowEdiorModel({ show: true, title: templateInfo.title, template: templateInfo })
                                                                                                                    } else if (templateInfo) {
                                                                                                                        setAddHtml({ show: true, title: templateInfo.title, template: templateInfo })
                                                                                                                    } else {
                                                                                                                        Swal.fire('No template found', '', 'error')
                                                                                                                    }
                                                                                                                }
                                                                                                                else {
                                                                                                                    if (templateInfo) {
                                                                                                                        let templateToBePassed = {
                                                                                                                            ...templateInfo,
                                                                                                                            'documentID': window.firebase.firestore().collection(`eventsProTemplates`).doc().id,
                                                                                                                            'projectId': props?.dealersettings?.client?.projectId ? props.dealersettings.client.projectId : null,
                                                                                                                            'clientID': props.dealersettings.client.id,
                                                                                                                            'clientIDs': [props.dealersettings.client.id],
                                                                                                                            'addedBy': localStorage.uid,
                                                                                                                            'modifiedBy': localStorage.uid,
                                                                                                                            'addedDate': window.firebase.firestore.Timestamp.now(),
                                                                                                                            'modifiedDate': window.firebase.firestore.Timestamp.now(),
                                                                                                                            'visibility': 'private',
                                                                                                                            'title': templateInfo.title + ' - Copy'
                                                                                                                        }
                                                                                                                        if (templateInfo.templateJSON) {
                                                                                                                            setShowEdiorModel({ show: true, title: templateInfo.title + ' - Copy', template: templateToBePassed })
                                                                                                                        } else {
                                                                                                                            setAddHtml({ show: true, title: templateInfo.title + ' - Copy', template: templateToBePassed })
                                                                                                                        }
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        Swal.fire('No template found', '', 'error')
                                                                                                                    }

                                                                                                                }
                                                                                                            }} /> : (<Fragment key={idx}></Fragment>)
                                                                                                        })
                                                                                                    }
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        )
                                                                                }
                                                                                <label> <Translate text={'Templates'} /></label>
                                                                                <ReactSelect
                                                                                    options={props.templates.filter(a => a.category === trigger.category &&
                                                                                        (_.isEmpty(currentClient) ? true : currentClient !== props.clientID && Boolean(_.find(trigger?.individual, { clientID: currentClient })?.useOwn) === false ? a.documentID === trigger.templateID : a.clientIDs.includes(currentClient)))
                                                                                        .map(rec => {
                                                                                            return {
                                                                                                label: rec.title,
                                                                                                value: rec.documentID
                                                                                            }
                                                                                        })}
                                                                                    name={"templateID"}
                                                                                    placeholder={'select template'}
                                                                                    onChange={handleSelectChange}
                                                                                    value={currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn ? (_.find(trigger?.individual, { clientID: currentClient }) ?
                                                                                        _.find(trigger?.individual, { clientID: currentClient }).templateID : null) : trigger.templateID}
                                                                                    classNamePrefix={`${errorFields["templateID"]} basic-select`}
                                                                                    removeClearable={true}
                                                                                >
                                                                                </ReactSelect>
                                                                            </div>
                                                                            <div className="form-group col-md-12">
                                                                                <label > <Translate text={'Subject'} /> </label>
                                                                                <InputText
                                                                                    autoComplete="off"
                                                                                    placeholder={`Subject`}
                                                                                    className={`form-control ${errorFields["subject"]}`}
                                                                                    name="subject"
                                                                                    onChange={handleOnChange}
                                                                                    value={currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn ? (_.find(trigger?.individual, { clientID: currentClient }) ?
                                                                                        _.find(trigger?.individual, { clientID: currentClient }).subject : null) : trigger.subject}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group col-md-12">
                                                                                <label> <Translate text={'Cc Emails'} /></label>
                                                                                <InputMultipleText
                                                                                    placeholder={`cc emails`}
                                                                                    className={`form-control react-multi-tag`}
                                                                                    name="mailCc"
                                                                                    onChange={(emails) => { handleOnChangeMultipleText(emails, 'mailCc') }}
                                                                                    value={currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn ? (_.find(trigger?.individual, { clientID: currentClient }).mailCc ?
                                                                                        _.find(trigger?.individual, { clientID: currentClient }).mailCc.split(',') : []) : (!_.isEmpty(trigger.mailCc) ? trigger.mailCc.split(',') : [])}
                                                                                    validateEmail={true}
                                                                                    getLabel={(email, index) => {
                                                                                        return <div data-tag key={index}>
                                                                                            {email}
                                                                                            <span data-tag-handle onClick={() => removeEmail(index, 'mailCc')}>
                                                                                                ×
                                                                                            </span>
                                                                                        </div>
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group col-md-12">
                                                                                <label> <Translate text={'Bcc Emails'} /></label>
                                                                                <InputMultipleText
                                                                                    placeholder={`bcc emails`}
                                                                                    className={`form-control react-multi-tag`}
                                                                                    name="mailBcc"
                                                                                    onChange={(emails) => { handleOnChangeMultipleText(emails, 'mailBcc') }}
                                                                                    value={currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn ? (_.find(trigger?.individual, { clientID: currentClient }).mailBcc ?
                                                                                        _.find(trigger?.individual, { clientID: currentClient }).mailBcc.split(',') : []) : (!_.isEmpty(trigger.mailBcc) ? trigger.mailBcc.split(',') : [])}
                                                                                    validateEmail={true}
                                                                                    getLabel={(email, index) => {
                                                                                        return <div data-tag key={index}>
                                                                                            {email}
                                                                                            <span data-tag-handle onClick={() => removeEmail(index, 'mailBcc')}>
                                                                                                ×
                                                                                            </span>
                                                                                        </div>
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="form-group col-md-12">
                                                                                <label> <Translate text={'Reply to Emails'} /></label>
                                                                                <InputMultipleText
                                                                                    placeholder={`reply to emails`}
                                                                                    className={`form-control react-multi-tag`}
                                                                                    name="replyTo"
                                                                                    onChange={(emails) => { handleOnChangeMultipleText(emails, 'replyTo') }}
                                                                                    value={currentClient !== props.clientID && _.find(trigger?.individual, { clientID: currentClient })?.useOwn ? (_.find(trigger?.individual, { clientID: currentClient }).replyTo ?
                                                                                        _.find(trigger?.individual, { clientID: currentClient }).replyTo.split(',') : []) : (!_.isEmpty(trigger.replyTo) ? trigger.replyTo.split(',') : [])}
                                                                                    validateEmail={true}
                                                                                    getLabel={(email, index) => {
                                                                                        return <div data-tag key={index}>
                                                                                            {email}
                                                                                            <span data-tag-handle onClick={() => removeEmail(index, 'replyTo')}>
                                                                                                ×
                                                                                            </span>
                                                                                        </div>
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="events-pop-template-leftmenu">
                                                                    <h4> Trigger Settings </h4>
                                                                    <div className="form-group col-md-12">
                                                                        <div className="event-temp-info">
                                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td width="50%">
                                                                                            <span className="subDept"> Event Start</span>
                                                                                            {moment.unix(props?.event?.startDate?.seconds).format('DD/MM/YYYY hh:mm A')}
                                                                                        </td>
                                                                                        <td width="50%" className="temp-info-border">
                                                                                            <span className="subDept">Event End</span>
                                                                                            {moment.unix(props?.event?.endDate?.seconds).format('DD/MM/YYYY hh:mm A')}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-style events-pop-template-wrap">

                                                                        <div className="form-row">
                                                                            <div className="form-group col-md-12">
                                                                                <label> <Translate text={'Email To'} /> </label>
                                                                                <ReactSelect
                                                                                    options={emailTo}
                                                                                    name={"emailTo"}
                                                                                    onChange={handleSelectChange}
                                                                                    value={trigger.emailTo}
                                                                                    classNamePrefix={`${errorFields["emailTo"]} basic-select`}
                                                                                    removeClearable={true}
                                                                                >
                                                                                </ReactSelect>
                                                                            </div>
                                                                            {
                                                                                trigger.emailTo === 'all' ? (
                                                                                    <div className="form-group col-md-12">
                                                                                        <label>  <Translate text={'Status'} /></label>
                                                                                        <ReactSelect
                                                                                            options={attendeeStatus.filter(a => a.value !== 'checkIn')}
                                                                                            name={"eventsProAttendeeStatus"}
                                                                                            onChange={handleSelectChange}
                                                                                            value={trigger.eventsProAttendeeStatus}
                                                                                            classNamePrefix={`${errorFields["eventsProAttendeeStatus"]} basic-select`}
                                                                                            removeClearable={false}
                                                                                        >
                                                                                        </ReactSelect>
                                                                                    </div>
                                                                                ) : (<></>)
                                                                            }
                                                                            {
                                                                                trigger.status === 'eventsProStatus' ? (
                                                                                    <div className="form-group col-md-12">
                                                                                        <label>  <Translate text={'Frequency'} /></label>
                                                                                        <ReactSelect
                                                                                            options={tiggerFrequency}
                                                                                            name={"period"}
                                                                                            onChange={handleSelectChange}
                                                                                            value={trigger.period}
                                                                                            classNamePrefix={`${errorFields["period"]} basic-select`}
                                                                                            removeClearable={true}
                                                                                        >
                                                                                        </ReactSelect>
                                                                                    </div>
                                                                                ) : (<></>)
                                                                            }
                                                                            {
                                                                                trigger.status === 'eventsProStatus' ? (
                                                                                    <>
                                                                                        <div className="form-group col-md-6">
                                                                                            <label>  <Translate text={'Date Field'} /></label>
                                                                                            <ReactSelect
                                                                                                options={triggerDateOptions.filter(a => lockTrigger ? true : a.index > statusIndex)}
                                                                                                name={"fieldName"}
                                                                                                onChange={handleSelectChange}
                                                                                                value={trigger.fieldName}
                                                                                                classNamePrefix={`${errorFields["fieldName"]} basic-select`}
                                                                                                removeClearable={true}
                                                                                            >
                                                                                            </ReactSelect>
                                                                                        </div>
                                                                                        <div className="form-group col-md-6">
                                                                                            <label>  <Translate text={'Status'} /></label>
                                                                                            <ReactSelect
                                                                                                options={props.status ? props.status.filter(a => lockTrigger ? true : props.event.status === 'closed' ? a.value === 'closed' : a.index > statusIndex && a.value !== 'draft') : []}
                                                                                                name={"eventsProStatus"}
                                                                                                onChange={handleSelectChange}
                                                                                                value={trigger.eventsProStatus}
                                                                                                classNamePrefix={`${errorFields["eventsProStatus"]} basic-select`}
                                                                                                removeClearable={true}
                                                                                                isDisabled={trigger.fieldName === 'publishedDate' || trigger.fieldName === 'liveDate' || trigger.fieldName === 'closedDate'}
                                                                                            >
                                                                                            </ReactSelect>
                                                                                        </div>
                                                                                        {
                                                                                            trigger.fieldName === 'customDate' ? (<>
                                                                                                <div className="form-group col-md-6">
                                                                                                    <label>  <Translate text={'Date'} /></label>
                                                                                                    <AntDatePicker
                                                                                                        value={trigger.customDate ? moment.unix(trigger.customDate.seconds).format('YYYY-MM-DD') : null}
                                                                                                        name={'customDate'}
                                                                                                        onChange={(e) => { handleDateChange(e, 'customDate') }}
                                                                                                        format='DD/MM/YYYY'
                                                                                                        placeholder='DD/MM/YYYY'
                                                                                                        className={`form-control ${errorFields["customDate"]}`}
                                                                                                        disabledDate={(current) => { return current && current < moment().startOf('day') }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='form-group col-md-6'>
                                                                                                    <label><Translate text={'time'} /></label>
                                                                                                    <div className='inner-addon right-addon'>
                                                                                                        <i className='bg-icons ico icon-time'></i>
                                                                                                        <ReactTimePicker
                                                                                                            value={trigger.customDate ? moment.unix(trigger.customDate.seconds).toDate() : null}
                                                                                                            name={'customDate'}
                                                                                                            onChange={handleTimeChange}
                                                                                                            timeIntervals={15}
                                                                                                            placeholder='h:mm aa'
                                                                                                            className={`form-control  ${errorFields["customTime"]}`}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            ) : (<></>)
                                                                                        }
                                                                                        {
                                                                                            trigger.period !== 'instant' ? (<>
                                                                                                <div className="form-group col-md-6">
                                                                                                    <label> <Translate text={'Frequency Value'} /> </label>
                                                                                                    <ReactSelect
                                                                                                        options={frequencyOptions.filter(a => (trigger.fieldName === 'publishedDate' || trigger.fieldName === 'liveDate' || trigger.fieldName === 'closedDate') ? a.value !== 'before' : true)}
                                                                                                        name={"frequencyValue"}
                                                                                                        onChange={handleSelectChange}
                                                                                                        value={trigger.frequencyValue}
                                                                                                        classNamePrefix={`${errorFields["frequencyValue"]} basic-select`}
                                                                                                        removeClearable={true}
                                                                                                    >
                                                                                                    </ReactSelect>
                                                                                                </div>
                                                                                                <div className="form-group col-md-6">
                                                                                                    <label>{trigger.period === 'afterMins' ? 'Mins' : trigger.period === 'afterHours' ? 'Hours' : 'Days'}</label>
                                                                                                    <InputText
                                                                                                        autoComplete="off"
                                                                                                        placeholder={`no. of ${trigger.period === 'afterMins' ? 'mins' : trigger.period === 'afterHours' ? 'hours' : 'days'}`}
                                                                                                        className={`form-control ${errorFields["periodValue"]}`}
                                                                                                        name="periodValue"
                                                                                                        onChange={handleNumberChange}
                                                                                                        value={trigger.periodValue}
                                                                                                        removeClearable={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </>) : (<></>)
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className="form-group col-md-12">
                                                                                            <label>  <Translate text={'Status'} /></label>
                                                                                            <ReactSelect
                                                                                                options={attendeeStatus}
                                                                                                name={"eventsProAttendeeStatus"}
                                                                                                onChange={handleSelectChange}
                                                                                                value={trigger.eventsProAttendeeStatus}
                                                                                                classNamePrefix={`${errorFields["eventsProAttendeeStatus"]} basic-select`}
                                                                                                removeClearable={true}
                                                                                            >
                                                                                            </ReactSelect>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }




                                                                            <div className="form-group col-md-12">
                                                                                <span className="switch switch-sm float-left">
                                                                                    <InputCheckBox
                                                                                        className={`switch`}
                                                                                        id={`trigger-active`}
                                                                                        name={'isActive'}
                                                                                        checked={trigger.isActive}
                                                                                        onChange={(e) => { handleCheckedChange(e) }} />
                                                                                    <label htmlFor={`trigger-active`}><Translate text={'active'} /></label>
                                                                                </span>
                                                                                <button type="submit" className="btn btn-primary float-right mt-2" onClick={() => { saveTrigger() }}>
                                                                                    {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                                    <Translate text={'save'} />
                                                                                </button>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>) : (<></>)
                                                        }
                                                    </div>

                                                    <div className="events-pop-template-column-middle-right">
                                                        <div className="events-pop-template-column-title">
                                                            <h3>Preview</h3>
                                                            {/* <div className="events-pop-template-common-button">


                                                                <button type="button" className="btn btn-default icon-font-fix float-right ml-2"><i
                                                                    className="ico icon-edit"></i> Edit</button>

                                                            </div> */}
                                                        </div>
                                                        <div className="events-pop-template-column-preview form-disable">
                                                            <div className="h-100">
                                                                {
                                                                    currentClient !== props.clientID
                                                                        && _.find(trigger?.individual, { clientID: currentClient })?.useOwn
                                                                        && _.find(props.templates, { documentID: _.find(trigger?.individual, { clientID: currentClient })?.templateID }) ?
                                                                        <div className={`${_.find(props.templates, { documentID: _.find(trigger?.individual, { clientID: currentClient })?.templateID }).templateJSON ? 'text-center' : 'text-left'}`}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: _.find(props.templates, { documentID: _.find(trigger?.individual, { clientID: currentClient })?.templateID }).bodyHTML,
                                                                            }}
                                                                        ></div>
                                                                        : _.find(props.templates, { documentID: trigger.templateID }) ? (
                                                                            <div className={`${_.find(props.templates, { documentID: trigger.templateID }).templateJSON ? 'text-center' : 'text-left'}`}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: _.find(props.templates, { documentID: trigger.templateID }).bodyHTML,
                                                                                }}
                                                                            ></div>
                                                                        ) : (

                                                                            <div className="h-100  w-100">
                                                                                <div className="spinner-loader h-100">
                                                                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary" style={{ flexDirection: 'column' }}>
                                                                                        <div className="no-data-img">
                                                                                            <img src={images.template} alt="" width="60" height="60" />
                                                                                        </div>
                                                                                        <div className="no-data-txt mt-2">   Please Select Template</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div >
            </Modal >
            <PopUpModal show={showEdiorModel.show}>
                <TemplateEditor
                    {...props}
                    {...showEdiorModel}
                    handleClose={() => { setShowEdiorModel({ show: false, title: '', template: null }) }}
                    formMentions={formMentions}
                />
            </PopUpModal>
            <PopUpModal show={addHtml.show}>
                <AddTemplateHTML
                    {...props}
                    {...addHtml}
                    handleClose={() => { setAddHtml({ show: false, title: '', template: null }) }}
                    dealersettings={props.dealersettings}
                    formMentions={[...formMentions, ...specialLinks.map(rec => { return { name: rec.label, value: rec.link } })]}
                />
            </PopUpModal>
        </>
    )
}

export default ManageTemplates;