/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import toast from 'toasted-notes';
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { tipStatus, tipOfferStatus } from '../../../services/enum';
import { objTradeIPOffers, tipBasicDetailsVM } from '../viewModel';
import { firestoreDB } from "../../../services/helper";


export default class RequestOffersList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            //wholesalers: [],
            selectedIds: [],
            errors: {},
            isLoading: false,
            selectedOffers: null,
            wholesalerNote: null,
            loading: false

        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { clientUsers, selectedOffers, dealersettings, tradeinpro } = this.props
        if (dealersettings.wholesalers) {
            this.setState({
                //wholesalers: dealersettings.wholesalers,
                users: dealersettings.wholesalers.filter(e => e.active && (!_.isEmpty(tradeinpro?.clientID) ? e.wholesalerClientID.some(e => e === tradeinpro.clientID) : true)),
                selectedOffers: Object.assign([], selectedOffers)
            });
        }
    }

    // componentDidUpdate() {
    //     if (this.state.loading) {
    //         this.loadData();
    //     }
    // }

    // static getDerivedStateFromProps(props, state) {

    //     if (!_.isEmpty(props.dealersettings.wholesalers) && _.isEmpty(state.users)) {
    //         return {
    //             loading: true
    //         }
    //     }
    //     else {
    //         return {
    //             loading: false
    //         }
    //     }

    // }

    handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        this.setState({
            wholesalerNote: value
        })

    }

    handleCheckChange = (e) => {

        const { name, checked } = e.target;
        let selectedIds = this.state.selectedIds;

        if (checked) {
            selectedIds.push(name);
        }
        else {
            let stindex = selectedIds.indexOf(name)
            selectedIds.splice(stindex, 1);

        }
        //console.log('handleCheckChange', name, checked, selectedIds)
        this.setState({ selectedIds: selectedIds });
    }

    handleValidFields = (isScroll) => {

        const { fields, selectedIds } = this.state;

        if (_.isEmpty(selectedIds)) {
            Swal.fire(CommonHelper.showLocale(this.props, 'Please select one or more wholesalers to send'));
            return;
        }
        return true;
    }


    handleSave = () => {
        if (this.handleValidFields(true) === true) {
            this.setState({ isLoading: true })
            this.saveWholesalersOffers();
        }
    }


    saveWholesalersOffers = () => {
        let userIds = this.state.selectedIds;

        if (!_.isEmpty(userIds)) {
            const { dealersettings, tradeinProID, tradeinpro } = this.props;
            const { wholesalerNote } = this.state;
            let objData = Object.assign({}, objTradeIPOffers)

            let _currentUser = dealersettings ? dealersettings.id : '';
            let _currentDate = window.firebase.firestore.Timestamp.now();

            objData.addedBy = _currentUser;
            objData.addedDate = _currentDate;
            objData.addedFrom = 'web';
            objData.modifiedBy = _currentUser;
            objData.modifiedDate = _currentDate;
            objData.modifiedFrom = 'web';
            objData.clientID = tradeinpro?.clientID ? tradeinpro.clientID : dealersettings.client.id;
            objData.projectId = tradeinpro?.projectId ? tradeinpro?.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
            objData.status = tipOfferStatus.PENDING;
            objData.tradeinProID = tradeinProID;

            objData.currency = dealersettings?.client?.currency;

            let _tradein = Object.assign({}, tipBasicDetailsVM)
            for (let [key, value] of Object.entries(tradeinpro)) {
                if (_tradein.hasOwnProperty(key))
                    _tradein[key] = value;
            }
            _tradein.documentID = tradeinProID;

            //Remove empty value from object
            for (var propName in _tradein) {
                if (_tradein[propName] === null || _tradein[propName] === 0 || _tradein[propName] === undefined || _tradein[propName] === '' || _tradein[propName] === [] || (_.isObject(_tradein[propName]) && _.isEmpty(_tradein[propName]))) {
                    delete _tradein[propName];
                }
            }

            objData.tradeinPro = _tradein;

            // objData.offersLog = [{
            //     addedBy: _currentUser,
            //     addedDate: _currentDate,
            //     message: 'Sent for appraisal'
            // }]

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === 0 || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }


            const batch = firestoreDB(this.props.dealersettings).firestore().batch();
            userIds && userIds.forEach(_id => {
                let newVM = {
                    ...objData,
                    wholeSellerId: _id,
                }
                newVM.documentID = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro').doc().id;
                batch.set(firestoreDB(this.props.dealersettings).firestore().doc(`tradeinPro/${newVM.tradeinProID}/offers/${newVM.documentID}`), newVM, { merge: true });

                let _objLogData = {
                    tradeinProID: tradeinProID,
                    offerID: newVM.documentID,
                    projectId: objData.projectId ? objData.projectId : null,
                    message: `An evaluation offer request has been sent to ${CommonHelper.getUserNamebyId(dealersettings.wholesalers, _id, 'the wholesaler')}.`
                }

                CommonHelper.saveTradeInProLog(dealersettings, _objLogData, batch);

                if (wholesalerNote && wholesalerNote.trim()) {
                    let _objNoteData = {
                        tradeinProID: tradeinProID,
                        offerID: newVM.documentID,
                        projectId: objData.projectId ? objData.projectId : null,
                        notes: wholesalerNote.trim()
                    }

                    CommonHelper.saveTradeInProNotes(dealersettings, _objNoteData, batch);
                }
            })

            batch.commit().then(snapshot => {

                toast.notify('Evaluation offer request sent successfully', { duration: 2000 });

                if (tradeinpro.status === tipStatus.NEW) {
                    // const updateRef = window.firebase.firestore().collection(`tradeinPro`).doc(tradeinProID)
                    // updateRef.
                    //     set({
                    //         status: tipStatus.INPROGRESS,
                    //         modifiedBy: _currentUser,
                    //         modifiedDate: _currentDate,
                    //         modifiedFrom: 'web'
                    //     }, { merge: true })
                    //     .then((docRef) => {
                    //         this.setState({ isLoading: false })
                    //         this.props.handleClose(userIds);
                    //     }).catch((error) => {
                    //         console.error("Error deleting Video: ", error);
                    //     });

                    this.props.handleSave('status', tipStatus.INPROGRESS, true)

                }

                this.setState({ isLoading: false })
                this.props.handleClose(userIds);


            }).catch((error) => {
                console.error("Error updating document: ", error);
            });


        }
        else
            this.setState({ isLoading: false })
    }


    render() {
        const divStyle = {
            maxHeight: '400px',
            overflow: 'auto'
        };
        const { show, title, dealersettings, clientUsers, groupUsers, tradeinpro } = this.props;
        const { users, selectedIds, isLoading, selectedOffers, wholesalerNote } = this.state;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);

        const requestOfferAccess = ((!_.isEmpty(_permissions) && _permissions.requestOfferAccess) ? true : false);


        return (
            <>
                <Modal
                    id="multi-list-evaluation"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-evaluation-list">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={title} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="modal-evaluation-list-wrap">

                                    {
                                        !_.isEmpty(users) && requestOfferAccess
                                            ?
                                            <>
                                                <h3 className="mb-1"><Translate text={'Wholesalers'} /> </h3>
                                                <div className="evaluation-cell-desc mb-2"><Translate text={'Selected wholesalers will get'} /> <strong>{CommonHelper.getNameAndModel(tradeinpro)}</strong> <Translate text={'detail report for evaluation'} /></div>
                                                <div style={divStyle}>
                                                    {
                                                        _.orderBy(users, ['name'], ['asc']).map((_user, index) => {
                                                            return <div className="modal-evaluation-cell-item" key={index} id={_user.documentID + '_' + index}>

                                                                <div className="modal-evaluation-cell-item-check">
                                                                    {
                                                                        !_.isEmpty(selectedOffers) && _.find(selectedOffers, { wholeSellerId: _user.documentID })
                                                                            ?
                                                                            <div className="checkbox icheck-success">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={_user.documentID}
                                                                                    name={_user.documentID}
                                                                                    className="uncheck-activity"
                                                                                    checked={true}
                                                                                    disabled={true}
                                                                                    onChange={(e) => {
                                                                                        this.handleCheckChange(e)
                                                                                    }} />
                                                                                <label htmlFor={_user.documentID}></label>
                                                                            </div>
                                                                            :
                                                                            <div className="checkbox icheck-success">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={_user.documentID}
                                                                                    name={_user.documentID}
                                                                                    className="uncheck-activity"
                                                                                    checked={(!_.isEmpty(selectedIds) && selectedIds.indexOf(_user.documentID) >= 0) ? true : false}
                                                                                    onChange={(e) => {
                                                                                        this.handleCheckChange(e, _user.documentID)
                                                                                    }} />
                                                                                <label htmlFor={_user.documentID}></label>
                                                                            </div>

                                                                    }

                                                                </div>

                                                                <div className="evaluation-cell-item">
                                                                    <div className="flex-evaluation-cell">
                                                                        <div className="evaluation-cell-item-thumb"> <a href="#">
                                                                            <img src={CommonHelper.showUserAvatar(_user.profileImage, _user.name)} alt="" className="rounded-circle img-object-fit" />
                                                                        </a> </div>
                                                                        <div className="evaluation-cell-data">
                                                                            <div className="evaluation-cell-item-title">{_user.name}</div>
                                                                            <div className="evaluation-cell-item-info">
                                                                                <ul className="w-100 d-inline-block">
                                                                                    <li> <i className="ico icon-call"></i> {_user.phone ? _user.phone : '--'}</li>
                                                                                    <li> <i className="ico icon-location"></i>{_user.address ? _user.address : '--'}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>

                                                <div className="tradein-section-selfevaluation">
                                                    <div className="tradein-section-selfevaluation-head">
                                                        <div className="float-left tradein-section-note-text">
                                                            <b><Translate text={'Notes'} /></b>
                                                        </div>
                                                    </div>
                                                    <div className="tradein-section-valuation-note">
                                                        <div className="form-style w-100">
                                                            <div className="form-row">
                                                                <div className="form-group col-md-12">

                                                                    <textarea
                                                                        className={`notes`}
                                                                        name="requestNotes" onChange={(e) => this.handleNotesChanges(e)}
                                                                        value={wholesalerNote ? wholesalerNote : ''}
                                                                        placeholder="notes"
                                                                        //cols="80"
                                                                        rows="1"
                                                                    ></textarea>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader h-100">
                                                    <div className="no-cafe-flex justify-content-center align-items-center h-100">
                                                        <div className="no-cafe-img">
                                                            <i className="ico icon-finance"></i> </div>
                                                    </div>
                                                </div>
                                            </>
                                    }

                                </div>
                            </div>


                        </div>
                    </Modal.Body>
                    {
                        !_.isEmpty(users) && (<Modal.Footer className={`${isLoading ? 'form-disable' : ''}`}>
                            <button type="button"
                                className="btn btn-default float-left"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleClose();
                                }}>
                                <Translate text={'cancel'} />
                            </button>
                            <button type="button"
                                className={`btn btn-common button-green float-right `}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.handleSave();
                                }}>
                                {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'send'} />
                            </button>
                        </Modal.Footer>)
                    }

                </Modal>
            </>


        );
    }
}

