/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import { Tab, Col, Nav, Row } from 'react-bootstrap'
/** COMPONENTS */
import Users from './users/index'
import Clients from './clients/index'
import Lookups from './lookups/index'
import OEMSetup from './oemSetup'
import PrivacyTerms from './terms'
import WholesalerList from './wholesaler'
import GenericTemplates from './templates'
import ErrorLogs from './errorLogs'

const CompanySettings = (props) => {
    const [active, setActive] = useState('clients')
    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <>
            <Tab.Container defaultActiveKey="oem" mountOnEnter={true}
                onSelect={(key) => {
                    setActive(key)
                }}>
                <div className="settings-submenu">
                    <h4>Clients Settings</h4>
                    <Nav as="ul" bsPrefix="ul-nav">
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="oem" bsPrefix="li-a-nav">OEM Setup</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="clients" bsPrefix="li-a-nav">Clients</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="users" bsPrefix="li-a-nav">Users</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="wholesaler" bsPrefix="li-a-nav">Wholesaler</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="lookups" bsPrefix="li-a-nav">Dynamic Fields</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="terms" bsPrefix="li-a-nav">Privacy Terms</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="templates" bsPrefix="li-a-nav">Generic Templates</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="errorLogs" bsPrefix="li-a-nav">Error Logs</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="settings-container mb-0 h-100">
                    <div className="settings-container-sub" >
                        <Tab.Pane eventKey="oem">
                            <OEMSetup activeKey={active} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="clients">
                            <Clients activeKey={active} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="users">
                            <Users activeKey={active} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="lookups">
                            <Lookups activeKey={active} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="terms">
                            <PrivacyTerms activeKey={active} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="wholesaler">
                            <WholesalerList activeKey={active} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="templates">
                            <GenericTemplates activeKey={active} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="errorLogs">
                            <ErrorLogs activeKey={active} />
                        </Tab.Pane>
                    </div>
                </div>

            </Tab.Container>
        </>
    )
}

export default CompanySettings;