/** LIBRARIES */
import React from 'react';
import moment from 'moment';
import _ from 'lodash'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
/** COMPONENTS */
import CommonHelper from "../../services/common";
import Translate from '../../constants/translate';
import { serviceStatus } from '../../services/enum';

const JobViewItem = React.memo(({ id, service, dealersettings, clientUsers, groupUsers, sidepanelOpen }) => {
    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
    const _jobStatus = service.status && service.status === serviceStatus.OWNERRESPONDED ? service.ownerResponse : service.status;
    const serviceAllStatus = Object.assign([], dealersettings?.client?.services?.serviceStatus)
    const technicianAllStatus = Object.assign([], dealersettings?.client?.services?.technicianStatus)
    let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0]
    return (
        (!_.isEmpty(dealersettings))
            ?
            <>
                <div className="dashboard-activity-loop" id={`today-jobs-${id}`}>
                    <div className="dashboard-activity-list" onClick={(e) => { e.preventDefault(); sidepanelOpen(service.documentID) }}>

                        {service.displayID ?
                            <div className="dashboard-activity-user"><i className="ico icon-service-settings"></i> {service.displayID}</div>
                            : <></>
                        }
                        {
                            (!_.isEmpty(service.contact))
                                ?
                                <>
                                    <div className="dashboard-activity-user">
                                        {CommonHelper.displayContactIcon(service.contact, 'mr-1')}
                                        {CommonHelper.displayContact(null, service.contact, '')}
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        (!_.isEmpty(service) && !_.isEmpty(service.engageNewContact))
                                            ?
                                            <>
                                                <div className="dashboard-activity-user">
                                                    {CommonHelper.displayContactIcon(service.contact, 'mr-1')}
                                                    {CommonHelper.displayContact(null, service.engageNewContact, '')}
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </>
                        }
                        {
                            (service.isVehicle && !_.isEmpty(service.vehicle)) ? (<>
                                <div className="pipeline-item-vehicle">
                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                    <strong>{(service.vehicle.make ? service.vehicle.make : '') + ' ' + (service.vehicle.model ? service.vehicle.model : '')}</strong>
                                </div>
                                {
                                    service.vehicle.rego
                                        ?
                                        <><div className="pipeline-item-source"><strong><Translate text={'regNo'} /></strong>: {service.vehicle.rego}</div></>
                                        :
                                        <>
                                            {
                                                service.vehicle.regNo
                                                    ?
                                                    <><div className="pipeline-item-source"><strong><Translate text={'regNo'} /></strong>: {service.vehicle.regNo}</div></>
                                                    :
                                                    <></>
                                            }
                                        </>
                                }
                            </>) : (<div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>)
                        }

                        <div className="pipeline-item-div w-100 mt-2">
                            {
                                service.owner ? (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{CommonHelper.getUserNamebyId(allUsers, service.owner)}</Tooltip>
                                    }
                                >
                                    <div className="float-right">
                                        <div className="pipeline-users pl-0">
                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                <img src={CommonHelper.getUserImagebyId(allUsers, service.owner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                            </a>
                                        </div>
                                    </div>
                                </OverlayTrigger>) : (<></>)
                            }
                            <div className="float-left">
                                {
                                    (_jobStatus)
                                        ?
                                        <>
                                            <div className='pipeline-button'>
                                                {
                                                    !_.isEmpty(_objCurrentStatus)
                                                        ?
                                                        <>
                                                            <span
                                                                className={`status-empty border-bottom-0`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                                <Translate
                                                                    text={_objCurrentStatus.name}
                                                                    upperCase={true}
                                                                />

                                                            </span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className={`status-${_jobStatus.toLowerCase()} border-bottom-0`}>
                                                                {_jobStatus.split(/(?=[A-Z])/).join(' ').toUpperCase()}
                                                            </span>
                                                        </>
                                                }

                                            </div>
                                            {
                                                service?.technicianStatus
                                                    ?
                                                    <>
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            overlay={<Tooltip><Translate text={'technicianStatus'} /> <br /> {CommonHelper.getNameByValue(technicianAllStatus, service?.technicianStatus, service?.technicianStatus)}</Tooltip>}
                                                        >
                                                            <div className="pipeline-button ml-1">
                                                                <i className={`ico icon-timer`} style={{ color: CommonHelper.getFieldByValue(technicianAllStatus, service?.technicianStatus, 'color', '#b0babe') }}></i>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>

                                }


                                {!_.isEmpty(service.slot) ? <>
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={<Tooltip>{service.slot.from}{'-'}{service.slot.to}</Tooltip>}
                                    >
                                        <div className='pipeline-button ml-1' style={{ color: `${service.slot.color ? service.slot.color : '#f47815 '}` }}>
                                            <i className="ico icon-service-timer"></i> {service.slot.name}
                                            {
                                                service.slot.isExpress ? <span className="express-inline-tag"><div className="badge red-ribbon ml-2 express-fix"><Translate text={'express'} upperCase={true} /></div></span> : <></>
                                            }
                                        </div>
                                    </OverlayTrigger>
                                </> : <></>}
                            </div>


                        </div>



                    </div>
                </div>
            </>
            :
            <></>

    )
})
export default JobViewItem;

