/** LIBRARIES */
import React, { PureComponent, Component } from "react";

class InputRadio extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const { id, name, value, onChange, className, checked, disabled } = this.props;
        //console.log('RENDER INPUT RADIO', name, value)
        return (
            <input
                id={id ? id : name}
                name={name}
                type="radio"
                value={value}
                className={className}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            
        );
    }
}

export default InputRadio;