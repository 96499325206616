
/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
//import toast from 'toasted-notes'
import { Dropdown } from 'react-bootstrap'
/** COMPONENTS */
import { CustomToggle, CustomMenu } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import Filters from './filters'

import ReactExport from 'react-export-excel-xlsx-fix';
import { ReactMultiSelect } from '../../components';
import cronstrue from 'cronstrue';
import images from '../../images';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const sortOrders = [
    { active: true, value: 'modifiedDate', label: 'Updated On' },
    { active: true, value: 'addedDate', label: 'Created On' }
]

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [stockFilter, setStockFilter] = useState({})
    const [searchText, setSearchText] = useState(props.searchText)

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {
        if (!_.isEmpty(localStorage.stockFilter) && Object.keys(JSON.parse(localStorage.stockFilter)).length > 0) {
            let _stockFilter = JSON.parse(localStorage.stockFilter);
            let _stockValue = _stockFilter.value;

            if (!_.isEmpty(_stockValue) && _stockValue.hasOwnProperty('keywords'))
                setSearchKeywords(_stockValue['keywords']);
            else
                setSearchKeywords('');

            setStockFilter(_stockFilter);
        }
        else {
            setStockFilter({});
            setSearchKeywords('');
        }

    }, [localStorage.stockFilter])

    //#region SEARCH KEYWORDS
    const handleKeywordsOnChange = (e) => {
        e.preventDefault()
        const { value } = e.target;

        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSearchKeywords(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSearchKeywords(str);
        }
    };

    const handleSearchKeywords = (_keywords) => {
        if (_keywords && _keywords.trim()) {
            if (!_.isEmpty(stockFilter)) {
                let _stockFilter = Object.assign({}, stockFilter)
                if (!_.isEmpty(stockFilter.value)) {
                    // update with the existing keyword
                    _stockFilter.value['keywords'] = _keywords.trim()
                }
                else {
                    // update with the new keyword
                    _stockFilter.value = { 'keywords': _keywords.trim() }
                }
                props.handleApplyFilter(_stockFilter);
            }
            else {
                // create new filter with keyword
                let _stockFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': _keywords.trim() }, type: 'filter' })
                props.handleApplyFilter(_stockFilter);
            }
        }
        else if (_keywords && !_keywords.trim()) {
            setSearchKeywords('');
        } else {
            if (!_.isEmpty(stockFilter)) {
                let _stockFilter = Object.assign({}, stockFilter)
                if (!_.isEmpty(stockFilter.value)) {
                    if (Object.keys(stockFilter.value).length > 1) {
                        delete _stockFilter.value['keywords']
                        props.handleApplyFilter(_stockFilter);
                    }
                    else {
                        props.handleClearFilter();
                    }
                }

            }
        }
    };

    const sumFields = (obj) => {
        return !_.isEmpty(obj) ? Object.values(obj).reduce((sum, value) => sum + value, 0) : 0;
    };
    //#endregion

    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    const accessOEMStock = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.accessOEMStock) ? true : false);

    const filterCounts = () => {

        var FilterCount = Object.keys(_.pickBy(props.objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && props.showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    const getRegions = () => {
        let regionsToReturn;
        let filteredRegions;

        filteredRegions = props.dealersettings.regions.filter(region => {
            if (props.dealersettings.clients.some(client => client.region === region.code)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredRegions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getSubregions = () => {
        let regionsToReturn;
        let filteredSubregions;

        filteredSubregions = props.dealersettings.subregions.filter(subregion => {
            if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
                (!_.isEmpty(props.objFilter['region']) ? props.objFilter['region'].includes(client.region) : true))) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredSubregions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getCountries = () => {
        let countriesToReturn;
        let filteredCountries;

        filteredCountries = props.dealersettings.countries.filter(country => {
            if (props.dealersettings.clients.filter(client => (
                (!_.isEmpty(props.objFilter['region']) ? props.objFilter['region'].includes(client.region) : true) &&
                (!_.isEmpty(props.objFilter['subregion']) ? props.objFilter['subregion'].includes(client.subregion) : true)
            )).some(client =>
                (client.country === country.code)
            )) {
                return true
            }
            return false;
        })

        countriesToReturn = filteredCountries.map(e => {
            return { label: e.name, value: e.code };
        })

        return countriesToReturn
    }

    const getDealers = () => {
        let clientsToReturn = props.dealersettings.clients;

        if (!_.isEmpty(props.objFilter['region'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                props.objFilter['region'].includes(client.region)
            );
        }

        if (!_.isEmpty(props.objFilter['subregion'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                props.objFilter['subregion'].includes(client.subregion)
            );
        }

        if (!_.isEmpty(props.objFilter['country'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                props.objFilter['country'].includes(client.country)
            );
        }

        clientsToReturn = clientsToReturn.map(client => {
            return {
                value: client.documentID,
                doc: client,
                label: client.name,
                active: _.isBoolean(client.active) ? client.active : true,
            };
        })

        return clientsToReturn
    }

    const stockCount = sumFields(props.stockCount);
    return (
        <>
            <div className="filter-panel">
                <div className="float-left">
                    {/* FILTER SEARCH KEYWORDS*/}
                    {
                        !Boolean(props.showOEMFilter) ? (
                            <div className="filter-search mr-3">
                                <div className="input-group ">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="isearch"
                                        placeholder="quick search..."
                                        value={searchText ? searchText : ''}
                                        onChange={(e) => {

                                            let _val = e.target.value
                                            setSearchText(_val);
                                            localStorage.stockSearchText = _val;

                                            if (_val && _val.length >= 2) {
                                                props.handlesearchText(_val)
                                            }

                                            if (!_val)
                                                props.handlesearchText('')
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                localStorage.stockSearchText = searchText;
                                                props.handlesearchText(searchText)
                                            }
                                        }}
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append input-search-clear">
                                        {
                                            searchText && searchText.length > 0
                                                ?
                                                <a href="#"
                                                    className="input-search-clear-icon"
                                                    title="Clear"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSearchText('');
                                                        localStorage.stockSearchText = '';
                                                        props.handlesearchText('')
                                                    }}
                                                >
                                                    <i className="ico icon-remove"></i>
                                                </a>
                                                :
                                                <>
                                                </>
                                        }

                                        <button
                                            className="btn btn-default"
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                localStorage.stockSearchText = searchText;
                                                props.handlesearchText(searchText)
                                            }}>
                                            <i className="ico icon-search-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (<></>)
                    }

                    <div className="tabber-nav">
                        <ul>
                            {
                                props.stockTabs && props.stockTabs.map((data, index) => {
                                    return <li key={index}><a href="#" className={`${props.activeTab === data.value ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.handleChangeTab(data.value);
                                        }}><Translate text={data.name} />

                                        {!_.isEmpty(props.stockCount) ? <span className="badge badge-pill badge-widget-counter ml-1">{props.stockCount[data.id]}</span> : <></>}
                                    </a></li>
                                })
                            }


                        </ul>
                    </div>
                </div >
                <div className="float-right">
                    {
                        props.readOnlyEnquiryView
                            ?
                            <>
                            </>
                            :
                            <>
                                {
                                    props.dealersettings?.rolePermissions?.permissions?.addStock
                                        ?
                                        <><div className="filter-add-button ml-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    props.handleAddStock();
                                                }}
                                            > <i className="ico icon-add"></i> <Translate text={'addStock'} /></button>
                                        </div></>
                                        :
                                        <></>
                                }

                            </>
                    }

                    {!['oem', 'service'].includes(localStorage.defaultModule) && accessOEMStock && !props.readOnlyEnquiryView ? (
                        <div className="filter-add-button ml-2">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.handleOEMOpen();
                                }}
                            > <i className="ico icon-search"></i> <Translate text={'OEM Stock'} /></button>
                        </div>
                    ) : <></>}

                    {/* {
                        (exportData && (props.stockALLCount > 0 || !_.isEmpty(localStorage.stockFilter)))
                            ?
                            <>
                                <div className="filter-item-btn ml-2">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        props.excelDownload(props.stockALLCount)
                                    }} title={'Excel Export'}>
                                        <i className="ico icon-excel-download"></i>
                                    </a>

                                    <ExcelFile ref={props.csvBtn}
                                        element={<a href="#" style={{ display: 'none' }} > <i className="ico icon-excel-download"></i></a>}
                                        filename={`stock_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                        <ExcelSheet dataSet={props.csvData} name={`All`} />
                                        {
                                            props.sheetData && props.stockTabs && props.stockTabs.map((rec, index) => {
                                                return <ExcelSheet key={index} dataSet={props.sheetData[rec.value]} name={`${rec.name}`} />
                                            })
                                        }
                                    </ExcelFile>

                                </div>
                            </>
                            :
                            <>
                            </>
                    } */}
                    {/* <div className="filter-item-btn ml-2">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    props.excelMcLarenDownload()
                                }} title={'Stock Report'}>
                                    <i className="ico icon-excel-download"> <spaan>Stock Report</spaan></i>
                                </a>

                                <ExcelFile ref={props.csvBtn}
                                    element={<a href="#" style={{ display: 'none' }} > <i className="ico icon-excel-download"></i></a>}
                                    filename={`stock_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                    <ExcelSheet dataSet={props.csvData} name={`All`} />
                                    {
                                        props.sheetData && props.stockTabs && props.stockTabs.map((rec, index) => {
                                            return <ExcelSheet key={index} dataSet={props.sheetData[rec.value]} name={`${rec.name}`} />
                                        })
                                    }
                                </ExcelFile>

                            </div> */}
                    {
                        props.dealersettings && props.dealersettings.isMclaren
                            ?
                            <Dropdown className='filter-view-more  ml-2'>
                                <Dropdown.Toggle
                                    as={CustomToggle}
                                    className='common-button'
                                >
                                    <i className='ico icon-more'></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    as={CustomMenu}
                                    ChildClass="more-dropdown"
                                    xplacement="bottom-end"
                                >
                                    {
                                        (exportData && (props.stockALLCount > 0 || !_.isEmpty(localStorage.stockFilter)))
                                            ?
                                            <>
                                                <Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-'}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        props.excelDownload(props.stockALLCount)
                                                    }} title={'Excel Export'}>
                                                        <i className="ico icon-excel-download"></i> Export Excel
                                                    </a>

                                                    <ExcelFile ref={props.csvBtn}
                                                        element={<a href="#" style={{ display: 'none' }} > <i className="ico icon-excel-download"></i></a>}
                                                        filename={`stock_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                                        <ExcelSheet dataSet={props.csvData} name={`All`} />
                                                        {
                                                            props.sheetData && props.stockTabs && props.stockTabs.map((rec, index) => {
                                                                return <ExcelSheet key={index} dataSet={props.sheetData[rec.value]} name={`${rec.name}`} />
                                                            })
                                                        }
                                                    </ExcelFile>
                                                </Dropdown.Item>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    {/* <Dropdown.Item eventKey="3" as={'div'} bsPrefix={'li-'}>

                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            props.excelMcLarenDownload()
                                        }} title={'Stock Report'}>
                                            <i className="ico icon-excel-download"></i> Stock Report
                                        </a>

                                        <ExcelFile ref={props.csvBtn}
                                            element={<a href="#" style={{ display: 'none' }} > <i className="ico icon-excel-download"></i></a>}
                                            filename={`stock_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                            <ExcelSheet dataSet={props.csvData} name={`All`} />
                                            {
                                                props.sheetData && props.stockTabs && props.stockTabs.map((rec, index) => {
                                                    return <ExcelSheet key={index} dataSet={props.sheetData[rec.value]} name={`${rec.name}`} />
                                                })
                                            }
                                        </ExcelFile>


                                    </Dropdown.Item> */}
                                    {
                                        props.pipeline ? (<>
                                            <Dropdown.Item eventKey="4" as={'div'} bsPrefix={'li-'}>

                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    let url = `/stock/pipeline/report/${props.dealersettings.client.id}`;
                                                    if (localStorage.defaultModule === 'oem') {
                                                        localStorage.setItem('allClients', JSON.stringify(props.dealersettings.clients));
                                                        url = `/stock/pipeline/report/${props.dealersettings[`${props.dealersettings.level}ID`]}`;
                                                        url += `?oemid=${props.dealersettings.oemID}&module=${props.dealersettings.level}`;
                                                        if (props?.objFilter) {
                                                            Object.keys(props?.objFilter).filter(a => a !== 'dealer').forEach(rec => {
                                                                if (props?.objFilter?.[rec]?.length > 0) {
                                                                    url += `&${rec}=${props.objFilter[rec].join(',')}`;
                                                                }
                                                            })
                                                        }
                                                        if (props?.filterClients?.length > 0) {
                                                            url += `&dealer=${props.filterClients.join(',')}`;
                                                        } else if (props?.dealersettings?.clients?.filter(a => a.settingsID === props.dealersettings.oemID)?.length > 0) {
                                                            url += `&dealer=${props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).map(a => { return a.id }).join(',')}`;
                                                        }
                                                        if (props?.objFilter?.dealer?.length > 0) {
                                                            url += `&showdealer=true`;
                                                        }
                                                    }
                                                    window.open(url, '_blank');
                                                }} title={'Pipeline Report'}>
                                                    <i className="ico icon-files-pdf"></i> Pipeline Report
                                                </a>


                                            </Dropdown.Item>
                                            {
                                                localStorage.defaultModule !== 'oem' ? (
                                                    <Dropdown.Item eventKey="4" onClick={(e) => {
                                                        props.setscheduleRptModel({
                                                            title: 'Schedule Report',
                                                            show: true,
                                                            hideRptOption: true,
                                                            hideMultiScheduler: true,
                                                            reportURL: `/stock/pipeline/report/${props.dealersettings.client.id}`,
                                                            report: {
                                                                documentID: `stockpipeline${props.dealersettings.client.id}`
                                                            }
                                                        })
                                                    }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>
                                                ) : (<></>)
                                            }
                                        </>) : (<></>)
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            (exportData && (stockCount > 0 || !_.isEmpty(localStorage.stockFilter) || localStorage.defaultModule === 'oem'))
                                ?
                                <>
                                    <div className="filter-item-btn ml-2">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            props.excelDownload(stockCount)
                                        }} title={'Excel Export'}>
                                            <i className="ico icon-excel-download"></i>
                                        </a>

                                        <ExcelFile ref={props.csvBtn}
                                            element={<a href="#" style={{ display: 'none' }} > <i className="ico icon-excel-download"></i></a>}
                                            filename={`stock_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                            <ExcelSheet dataSet={props.csvData} name={`All`} />
                                            {
                                                props.sheetData && props.stockTabs && props.stockTabs.map((rec, index) => {
                                                    return <ExcelSheet key={index} dataSet={props.sheetData[rec.value]} name={`${rec.name}`} />
                                                })
                                            }
                                        </ExcelFile>

                                    </div>
                                </>
                                :
                                <>
                                </>
                    }
                    {
                        !_.isEmpty(props.scheduleReport) && props.pipeline ? (
                            <div className="reportsuccess-item-btn ml-2 float-right cursor-pointer">
                                <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                                <div className="reportsuccess-inner-text">
                                    <div className="reportsuccess-inner-text-top"> Scheduled </div>
                                    <div className="reportsuccess-inner-text-sub">{`${props.scheduleReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(props.scheduleReport.cron)}`}</div>

                                </div>
                                <div className="reportsuccess-sent-right"><img src={images.meSent} alt="" width="22" /></div>
                            </div>
                        ) : (<></>)
                    }

                </div>

                {/**** FILTERS ****/}
                {
                    props.pipeline ? (<></>) : (
                        <Filters
                            searchKeywords={searchKeywords}
                            stockFilter={stockFilter}
                            customFilters={props.customFilters}
                            dealersettings={props.dealersettings}
                            handleApplyFilter={props.handleApplyFilter}
                            handleClearFilter={props.handleClearFilter}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                        </Filters>
                    )
                }
                {/* {
                    ((!_.isEmpty(props.stockCount) || props.stockCount > 0) && !localStorage.stockSearchText) ?
                        (<>
                            <div className="list-count-btn mr-3">
                                <span>  <Translate text={'All Stock'} /></span>
                                <span className="list-count"> {props.stockCount} </span>
                            </div>
                        </>)
                        : (<></>)
                } */}


            </div >
            {
                props.showOEMFilter === true ? (
                    <div className='filter-sub-panel'>
                        <div className="form-row">
                            <div className="filter-search form-group col-md-2">
                                <div className="input-group ">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="isearch"
                                        placeholder="quick search..."
                                        value={searchText ? searchText : ''}
                                        onChange={(e) => {

                                            let _val = e.target.value
                                            setSearchText(_val);
                                            localStorage.stockSearchText = _val;

                                            if (_val && _val.length > 2) {
                                                props.handlesearchText(_val)
                                            }

                                            if (!_val)
                                                props.handlesearchText('')
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                localStorage.stockSearchText = searchText;
                                                props.handlesearchText(searchText)
                                            }
                                        }}
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append input-search-clear">
                                        {
                                            searchText && searchText.length > 0
                                                ?
                                                <a href="#"
                                                    className="input-search-clear-icon"
                                                    title="Clear"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSearchText('');
                                                        localStorage.stockSearchText = '';
                                                        props.handlesearchText('')
                                                    }}
                                                >
                                                    <i className="ico icon-remove"></i>
                                                </a>
                                                :
                                                <>
                                                </>
                                        }

                                        <button
                                            className="btn btn-default"
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                localStorage.stockSearchText = searchText;
                                                props.handlesearchText(searchText)
                                            }}>
                                            <i className="ico icon-search-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-2">
                                <ReactMultiSelect
                                    options={getRegions()}
                                    name={'region'}
                                    placeholder={`select region`}
                                    onChange={(e) => {
                                        props.setMultibjFilter(e, 'region')
                                    }}
                                    value={props.objFilter['region']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                            <div className="form-group col-md-2">

                                <ReactMultiSelect
                                    options={getSubregions()}
                                    name={'subregion'}
                                    placeholder={`select subregion`}
                                    onChange={(e) => {
                                        props.setMultibjFilter(e, 'subregion')
                                    }}
                                    value={props.objFilter['subregion']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                            <div className="form-group col-md-2">

                                <ReactMultiSelect
                                    options={getCountries()}
                                    name={'country'}
                                    placeholder={`select country`}
                                    onChange={(e) => {
                                        props.setMultibjFilter(e, 'country')
                                    }}
                                    value={props.objFilter['country']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                            <div className="form-group col-md-2">

                                <ReactMultiSelect
                                    options={getDealers()}
                                    name={'dealer'}
                                    placeholder={`select dealer`}
                                    onChange={(e) => {
                                        props.setMultibjFilter(e, 'dealer')
                                    }}
                                    value={props.objFilter['dealer']}
                                    classNamePrefix={`basic-select`}
                                    isMulti={true}
                                    isSearchable={true}
                                >
                                </ReactMultiSelect>
                            </div>
                        </div>
                    </div>
                ) : (<></>)
            }
        </>
    )
}


export default FilterPanel
