import React from 'react';
import { useTranslation } from 'react-i18next';


const Translate = (props) => {
    const { t } = useTranslation();

    if (props.lowerCase === true)
        return (props.isString ? `${t(props.text).toLowerCase()}` : <>{t(props.text).toLowerCase()}</>)
    else if (props.upperCase === true)
        return (props.isString ? `${t(props.text).toUpperCase()}` : <>{t(props.text).toUpperCase()}</>)
    else
        return props.isString ? `${t(props.text).toString()}` : <>{t(props.text)}</>
}

export default Translate;