import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import CommonHelper from '../../services/common';
import { targetOptions, salesTypes, calculateOptions, activityOptions, targetmandatoryFields, dataQualityOptions, targetVM, levelOptions, enqStatus, inboundStatus, quotationOptions } from '../settings/company/dealerTargets/viewModel'

const TargetPopover = (props) => {

    const [dynSalesTypes, setDynSaalesTypes] = useState(salesTypes)

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            const setttings = props.dealersettings.client.settings;
            let _salesTypes = [];
            setttings.salesType && setttings.salesType.filter(a => a.active === true).forEach((saleType) => {
                enqStatus.forEach(status => {
                    _salesTypes.push({
                        searchlabel: `${saleType.value.toLowerCase()}${status.value}`,
                        value: `${saleType.value.toLowerCase()}AND${status.value}`,
                        label: <>{saleType.name} <i className={`status-${status.value} status-i-fix ml-1`}>{status.value}</i></>
                    })
                })
            });
            setDynSaalesTypes(_salesTypes)
        }
    }, [])

    const getsubTypeName = (type, subType) => {
        var result = [];
        if (!_.isEmpty(type) && !_.isEmpty(subType)) {
            subType.split(',').forEach(rec => {
                let subtypeOptions = (!_.isEmpty(props.dealersettings.client.settings[type])) ?
                    _.map(props.dealersettings.client.settings[type], function (c) {
                        return {
                            value: c.value,
                            label: c.name,
                            active: _.isBoolean(c.active) ? c.active : true,
                        };
                    }) : type === 'make' ? _.map(props.dealersettings.client.brands, function (c) {
                        return {
                            value: c.value,
                            label: c.name,
                            active: _.isBoolean(c.active) ? c.active : true,
                        };
                    }) : type === 'salesTypes' ? dynSalesTypes : type === 'status' ? enqStatus : type === 'inboundStatus' ? inboundStatus : type === 'activities' ? (CommonHelper.activityLogOptions(props.dealersettings, activityOptions)) : type === 'dataQuality' ? dataQualityOptions : type === 'quotation' ? quotationOptions : targetOptions.filter(item => item.value === type)

                result.push(_.find(subtypeOptions, { 'value': rec }) ? _.find(subtypeOptions, { 'value': rec }).label : '')
            });
        }
        return (<div className={'Vehicle-availability'}>{
            result.filter(e => !_.isEmpty(e)).map((rec, index) => {
                return <div key={index}><span>{rec}</span></div>;
            })
        }</div>);
    }

    return _.isEmpty(props.activeTarget) ? (<></>) : (
        <div className="target-popover-list">
            <ul>
                {
                    props.activeTarget.pipeline ? (
                        <li> <div className='float-left mr-1'>Pipeline : </div>{getsubTypeName('pipelines', props.activeTarget.pipeline)}</li>
                    ) : (<></>)
                }
                {
                    props.activeTarget.make ? (
                        <li> <div className='float-left mr-1'>Make :</div> {getsubTypeName('make', props.activeTarget.make)}</li>
                    ) : (<></>)
                }
                {
                    props.activeTarget.saleType ? (
                        <li> <div className='float-left mr-1'> Sale Type :</div> {getsubTypeName('salesType', props.activeTarget.saleType)}</li>
                    ) : (<></>)
                }
                <li> {_.find(targetOptions, { value: props.activeTarget.type }) ? _.find(targetOptions, { value: props.activeTarget.type }).label : props.activeTarget.type}
                    <div className="target-sublist">
                        {getsubTypeName(props.activeTarget.type, props.activeTarget.subType)}
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default TargetPopover