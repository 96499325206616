import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { default as _images } from '../../../images';
import DisplayReportContacts from './list/displayContacts'
import { PopUpModal } from '../../../components';

const Report = ({ contacts, data, setActiveTab, batch, batchStats, batchLinksStats, contactsLoader, complainedContacts }) => {
	const [activeSubTab, setActiveSubTab] = useState('overview');
	const [displayRecipients, setDisplayRecipients] = useState({
		show: false,
		title: '',
		type: ''
	});

	const handleShowContacts = (e, type, title) => {
		setDisplayRecipients({
			show: true,
			title,
			type
		})
	}

	return (
		<>
			<div className='campaign-view-wrapper-head'>
				<div className='float-left'>
					<div className='campaign-add-title'>{data ? data.nameText : ''} </div>
					<div className='campaign-add-sub-title'>
					<div className={`badge badge-pill float-left badge-${batch.send_status === 'completed'
									? 'available'
									: (batch.send_status === 'initialising' || batch.send_status === 'pending' || batch.send_status === 'sending' )
										? 'pending'
										: (batch.send_status === 'failed' || batch.send_status === 'paused' || batch.send_status === 'cancelled')
											? 'draft'
											: 'draft'
								}-fill `}
						>
							{batch.send_status ? batch.send_status : ''}
						</div>
						<span className='float-left campaign-add-date'>
							{' '}
							Delivered on {data ? data.lastSentOn : ''}{' '}
						</span>
					</div>
				</div>
				<div className='float-right campaign-tab-align'>
					<div className='campaign-tab-switch center-fix'>
						<ul className='campaign-tabs-list'>
							<li
								className='campaign-tab-item '
								onClick={() => setActiveTab('detail')}
							>
								Campaign Details
							</li>
							<li
								className='campaign-tab-item campaign-tab-active'
								onClick={() => setActiveTab('report')}
							>
								Report
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='campaign-view-wrapper' style={{ minHeight: '800px' }}>
				<div className='tabs'>
					<div className='tabs-wraper'>
						<nav>
							<Tabs
								transition={false}
								className='nav-fill'
								defaultActiveKey={activeSubTab}
								onSelect={k => setActiveSubTab(k)}
							>
								<Tab eventKey='overview' title='Overview'>
									<div className='campaign-view-tab-header'>
										<div className='float-left'>
											<h2>Campaign Overview</h2>
										</div>
									</div>

									<div className='campaign-view-tab-middle mt-3'>
										<div className='card campaign-custom-card'>
											<div className='card-header'>Activity</div>
											<div className='card-body'>
												<div className='row text-center'>
													<div className='col'>
														<div className='campaign-counter' onClick={e => handleShowContacts(e, 'recipients', 'Recipients')}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignRecipients}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Recipients</p>
															<h2 className='campaign-count-title campaign-count-number'>
																{batch.send_contact_count}
															</h2>
														</div>
													</div>

													<div className='col'>
														<div className='campaign-counter' onClick={e => handleShowContacts(e, 'opened', 'Opened')}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignOpen}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Opened</p>
															<h2 className='campaign-count-title'>{batchStats.opened_count}</h2>
														</div>
													</div>

													<div className='col'>
														<div className='campaign-counter' onClick={e => handleShowContacts(e, 'unsubscribed', 'Unsubscribed')}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignUnSubscribed}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Unsubscribed</p>
															<h2 className='campaign-count-title'>{batchStats.unsubscribe_count}</h2>
														</div>
													</div>

													<div className='col'>
														<div className='campaign-counter' onClick={e => handleShowContacts(e, 'unopened', 'Unopened')}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignUnOpen}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Unopened</p>
															<h2 className='campaign-count-title'>{batchStats.not_opened_count}</h2>
														</div>
													</div>

													<div className='col'>
														<div className='campaign-counter' onClick={e => handleShowContacts(e, 'profile', 'Profile Updated')}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignProfile}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Profile Updated</p>
															<h2 className='campaign-count-title campaign-count-number'>
																{batchStats.update_profile_count}
															</h2>
														</div>
													</div>
													<div className='col'>
														<div className='campaign-counter' onClick={e => handleShowContacts(e, 'complained', 'Complained')}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignComplain}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Complained</p>
															<h2 className='campaign-count-title campaign-count-number'>
																{batchStats.update_profile_count}
															</h2>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* <div className='card campaign-custom-card mt-4'>
											<div className='card-header'>Activity Over Time</div>
											<div className='card-body'>Graph here</div>
										</div> */}
									</div>

									<div className='campaign-sub-section-div mt-4'>
										<div className='row'>
											<div className='col-sm-6'>
												<div className='card campaign-custom-card h-100'>
													<div className='card-header'>
														Sends
														<span className='campaign-component-subtitle'>
															Last sent on {batchStats.last_sent_time ? moment.unix(batchStats.last_sent_time).format('DD/MM/YYYY hh:mm A') : ''}
														</span>
													</div>
													<div className='card-body'>
														<div className='card-group'>
															<div className='card'>
																<div className='card-body'>
																	<h5 className='card-title'>Delivered</h5>
																	<p className='card-text'>
																		<span className='campaign-percentage'>
																			{((100 * batchStats.email_count) / batch.send_contact_count)}%
																		</span>
																		<span className='campaign-fraction'>{batchStats.email_count} </span>
																	</p>
																</div>
															</div>
															<div className='card'>
																<div className='card-body'>
																	<h5 className='card-title'>Bounced</h5>
																	<p className='card-text'>
																		<span className='campaign-percentage'>
																		{((100 * batchStats.bounce_count) / batch.send_contact_count)}%
																		</span>
																		<span className='campaign-fraction'>{batchStats.bounce_count} </span>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='col-sm-6'>
												<div className='card campaign-custom-card h-100'>
													<div className='card-header'>Mail Clients</div>
													<div className='card-body'>
														<div className='card-group'>
															<div className='card'>
																<div className='card-body'>
																	{!_.isEmpty(batchStats.mail_clients) && Object.values(batchStats.mail_clients).map(client => (
																		<>
																			<h5 className='card-title'>{client.title}</h5>
																			<p className='card-text'></p>
																			<div className='progress'>
																				<div
																					className='progress-bar bg-success'
																					role='progressbar'
																					style={{width: `${client.percent}%`}}
																					aria-valuenow='25'
																					aria-valuemin='0'
																					aria-valuemax='100'
																				>
																					{client.percent}%
																				</div>
																			</div>
																			<p></p>
																		</>
																	))}
																	{/* Object.values($reactTemp0) */}
																	<p></p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											
										</div>
									</div>

									<div className='campaign-sub-section-div mt-4'>
										<div className='row'>
										<div className='col-sm-6'>
												<div className='card campaign-custom-card h-100'>
													<div className='card-header'>Devices</div>
													<div className='card-body'>
														<div className='card-group'>
															<div className='card'>
																<div className='card-body'>
																	<div className='campaign-card-icon'>
																		<img
																			src={_images.campaignMonitor}
																			alt=''
																			height='50'
																		/>
																	</div>
																	<div className='campaign-card-info'>
																		<h5 className='card-title card-title-platform'>Desktop</h5>
																		<p className='card-text d-flex justify-content-center'>
																			<span className='campaign-percentage'>
																				{!_.isEmpty(batchStats.device_statistics) ? batchStats.device_statistics.desktop.percentage : '100'}%
																			</span>
																		</p>
																	</div>
																</div>
															</div>
															<div className='card'>
																<div className='card-body'>
																	<div className='campaign-card-icon'>
																		<img
																			src={_images.campaignMobile}
																			alt=''
																			height='50'
																		/>
																	</div>
																	<div className='campaign-card-info'>
																		<h5 className='card-title card-title-platform'>Mobile</h5>
																		<p className='card-text d-flex justify-content-center'>
																			<span className='campaign-percentage'>
																			{!_.isEmpty(batchStats.device_statistics) ? batchStats.device_statistics.mobile.percentage : '100'}%
																			</span>
																		</p>
																	</div>
																</div>
															</div>
														</div>
														<div className='card-group'>
															<div className='card' style={{borderTop: '0px'}}>
																<div className='card-body'>
																	<div className='campaign-card-icon'>
																		<img
																			src={_images.campaignTablet}
																			alt=''
																			height='50'
																		/>
																	</div>
																	<div className='campaign-card-info'>
																		<h5 className='card-title card-title-platform'>Tablets</h5>
																		<p className='card-text d-flex justify-content-center'>
																			<span className='campaign-percentage'>
																			{!_.isEmpty(batchStats.device_statistics) ? batchStats.device_statistics.tablet.percentage : '100'}%
																			</span>
																		</p>
																	</div>
																</div>
															</div>
															<div className='card' style={{borderTop: '0px'}}>
																<div className='card-body'>
																	<div className='campaign-card-icon'>
																		<img
																			src={_images.campaignUnknown}
																			alt=''
																			height='50'
																		/>
																	</div>
																	<div className='campaign-card-info'>
																		<h5 className='card-title card-title-platform'>Unknown</h5>
																		<p className='card-text d-flex justify-content-center'>
																			<span className='campaign-percentage'>
																			{!_.isEmpty(batchStats.device_statistics) ? batchStats.device_statistics.unknown.percentage : '100'}%
																			</span>
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='col-sm-6'>
												<div className='card campaign-custom-card h-100'>
													<div className='card-header'>Links</div>
													<div className='card-body'>
														<div className='card-group'>
															<div className='card'>
																<div className='card-body'>
																	{!_.isEmpty(batchLinksStats) ? batchLinksStats.map(link => (
																		<>
																			<a href={link.link_url} target="_blank" rel="noopener noreferrer" >
																				<h5 className='card-title'>{link.link_name}</h5>
																			</a>
																			<p className='card-text'></p>
																			<div className='progress'>	
																				<div
																					className='progress-bar bg-success'
																					role='progressbar'
																					style={{width: `${link.contact_count/batch.send_contact_count*100}%`}}
																					aria-valuenow='25'
																					aria-valuemin='0'
																					aria-valuemax='100'
																				>
																					{/* /batch.send_contact_count */}
																					{link.contact_count/batch.send_contact_count*100}%
																				</div>
																			</div>
																			<p></p>
																		</>
																	)) : (
																		<h5 className="card-title text-center">There are no links for this report</h5>
																	)}
																	{/* Object.values($reactTemp0) */}
																	<p></p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											{/* <div className='col-sm-6'>
												<div className='card campaign-custom-card h-100'>
													<div className='card-header'>
														Domains
														<div className='bar_legend'>
															<div className='sort-by'>Sort By</div>
															<div
																className='legend'
																title='Sent'
																data-sort-name='sent'
																data-sort-direction='0'
															>
																<div className='legend-colour grey'></div>
																<div className='legend-text'>Sent</div>
															</div>
															<div
																className='legend'
																title='Opened'
																data-sort-name='opened'
																data-sort-direction='0'
															>
																<div className='legend-colour green'></div>
																<div className='legend-text'>Opened</div>
															</div>
															<div
																className='legend'
																title='Bounced'
																data-sort-name='bounced'
																data-sort-direction='0'
															>
																<div className='legend-colour red'></div>
																<div className='legend-text'>Bounced</div>
															</div>
														</div>
													</div>
													<div className='card-body'>
														<div className='card-group h-100'>
															<div className='card'>
																<div className='card-body'>
																	<h5 className='card-title'>fusionsd.ae</h5>
																	<p className='card-text'></p>
																	<div className='progress'>
																		<div
																			className='progress-bar bg-success'
																			role='progressbar'
																			style={{width: '80%'}}
																			aria-valuenow='80'
																			aria-valuemin='0'
																			aria-valuemax='100'
																		>
																			80%
																		</div>
																	</div>
																	<p></p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div> */}
										</div>
									</div>
								</Tab>
							</Tabs>
						</nav>
					</div>
				</div>
			</div>
			<PopUpModal show={displayRecipients.show}>
					<DisplayReportContacts
						contactsLoader={contactsLoader}
						show={displayRecipients.show}
						handleClose={() => { setDisplayRecipients({show: false, title: '', type: ''})}}
						title={displayRecipients.title}
						contacts={contacts}
						complainedContacts={complainedContacts}
						type={displayRecipients.type}
					/>
			</PopUpModal>
		</>
	);
};

export default Report;
