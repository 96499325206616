import _ from 'lodash'

const AcitivitylogReducer = (state, action) => {
    function editCustomerlog() {
        return state[action.activeTab].map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = true;
                item.oldnotes = item.notes
                item.oldcallDuration = item.callDuration
                item.oldcallStatus = item.callStatus
                item.oldaddedDate = item.addedDate
                return item;
            }
            return item;
        });
    }
    function updatechangelogs() {
        return state[action.activeTab].map((item, index) => {
            if (item.documentID === action.id) {
                item[action.field] = action.value;
                return item;
            }
            return item;
        });
    }
    function cancelEditable() {
        return state[action.activeTab].map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.notes = action.data.oldnotes
                item.callDuration = action.data.oldcallDuration
                item.callStatus = action.data.oldcallStatus
                item.addedDate = action.data.oldaddedDate
                return item;
            }
            return item;
        });
    }
    function updateDoneActivities() {
        return state[action.activeTab].map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isDone = action.data.isDone;
                return item;
            }
            return item;
        });
    }
    function updateActivities() {
        return state[action.activeTab].map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    function updateAllActivity() {
        return state[action.activetab].map((item, index) => {
            var logVM = action.data.find(e => e.documentID === item.documentID)
            if (logVM) {
                for (let [key, value] of Object.entries(logVM)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return {
                ...state,
                [action.activeTab]: [...state[action.activeTab], ..._.differenceBy(action.data, state[action.activeTab], 'documentID')]
            };
        }
        case "ACTIVITIES_APPEND_LIST": {
            return {
                ...state,
                [action.activetab]: [...updateAllActivity(), ..._.differenceBy(action.data, state[action.activetab], 'documentID')]
            };
        }
        case "ADDNEWLOG": {
            return {
                ...state,
                [action.activeTab]: [...state[action.activeTab], action.data]
            };
        }
        case "REMOVELOG": {
            return {
                ...state,
                [action.activeTab]: state[action.activeTab].filter(item => item.documentID !== action.data.documentID)
            };
        }
        case "UPDATELOG": {
            return {
                ...state,
                [action.activeTab]: [...state[action.activeTab].filter(item => item.documentID !== action.data.documentID), action.data]
            }
        }
        case "EDITLOG": {
            return {
                ...state,
                [action.activeTab]: editCustomerlog()
            };
        }
        case "CHANGELOG": {
            return {
                ...state,
                [action.activeTab]: updatechangelogs()
            };
        }
        case "CANCELLOG": {
            return {
                ...state,
                [action.activeTab]: cancelEditable()
            };
        }
        case "CHECKLOG": {
            let addlog = false;
            if (state[action.activeTab].length > 0) {
                if (action.activeTab === 'Completed') {
                    addlog = action.data.startDate.seconds >= state[action.activeTab][state[action.activeTab].length - 1].startDate.seconds
                }
                else {
                    addlog = action.data.startDate.seconds <= state[action.activeTab][state[action.activeTab].length - 1].startDate.seconds
                }
            }
            return addlog ? {
                ...state,
                [action.activeTab]: state[action.activeTab].some(item => item.documentID === action.data.documentID) ?
                    updateActivities() :
                    [...state[action.activeTab].filter(item => item.documentID !== action.data.documentID), action.data]
            } : {
                    ...state,
                    [action.activeTab]: state[action.activeTab]
                };
        }
        case "UPDATE_DONE": {
            return {
                ...state,
                [action.activeTab]: updateDoneActivities()
            };
        }
        case "UPDATE_ACTIVITY": {
            return {
                ...state,
                [action.activeTab]: updateActivities()
            };
        }
        case "CLEAR_ALL": {
            return {
                Overdue: [], 
                Today: [], 
                Upcoming: [],
                PendingTD: []
            };
        }
        default:
            return state;
    }
};

export default AcitivitylogReducer;