import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import { InputText } from '../../../../components'
import { mandSettingsVM, allModules, enquiryStatus } from './viewModel'
import 'antd/dist/antd.css';
import { Tree } from 'antd';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
const levels = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
];

const AddFieldSettings = (props) => {

    const [loader, setLoader] = useState(false)
    const [treeData, setTreeData] = useState([])
    const [checkedKeys, setCheckedKeys] = useState([])
    const [disabledKeys, setDisabledKeys] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true)
    const [selectedField, setSelectedField] = useState(props.selectedField)
    const [errorFields, setErrorFields] = useState({});
    const [levelOptions, setLevelOptions] = useState([])
    const mandFieldName = 'mandatoryFields'

    useEffect(() => {

        let _pipelines = [];
        let _status = [];
        let _finstatus = [];
        let _amstatus = [];
        if (!_.isEmpty(props.pipelines)) {
            props.pipelines.forEach(e => {
                _pipelines.push({
                    title: e.name,
                    key: e.value,
                    children: [..._.map(e.stages, function (s) {
                        return {
                            title: s.name,
                            key: e.value + '-' + s.value
                        };
                    })]
                })
            });
        }
        if (!_.isEmpty(props.enquiryStatus) && props.enquiryStatus.length > 0) {
            props.enquiryStatus.filter(a => a.value !== 'prospectLost').forEach(e => {
                _status.push({
                    title: e.name,
                    key: e.value
                })
            })
        }
        else {
            enquiryStatus.forEach(e => {
                _status.push({
                    title: e.name,
                    key: e.value
                })
            })
        }
        if (!_.isEmpty(props.financeStatus) && props.financeStatus.length > 0) {
            props.financeStatus.forEach(e => {
                _finstatus.push({
                    title: e.name,
                    key: `fin_${e.value}`
                })
            })
        }
        if (!_.isEmpty(props.afterMarketStatus) && props.afterMarketStatus.length > 0) {
            props.afterMarketStatus.forEach(e => {
                _amstatus.push({
                    title: e.name,
                    key: `am_${e.value}`
                })
            })
        }

        let _treedata = [{
            title: CommonHelper.showLocale(props, 'All Modules'),
            key: 'allModules',
            children: [
                {
                    title: CommonHelper.showLocale(props, 'All Pipeline & Stages'),
                    key: 'allPipeline',
                    children: _pipelines
                },
                {
                    title: CommonHelper.showLocale(props, 'status'),
                    key: 'allStatus',
                    children: _status
                },
                {
                    title: CommonHelper.showLocale(props, 'financeStatus'),
                    key: 'allFinStatus',
                    children: _finstatus
                },
                {
                    title: CommonHelper.showLocale(props, 'afterMarketStatus'),
                    key: 'allAMStatus',
                    children: _amstatus
                },
                {
                    title: CommonHelper.showLocale(props, 'status'),
                    key: 'lostStatus',
                    children: _status.filter(a => a.key === 'lost')
                },
                {
                    title: CommonHelper.showLocale(props, 'contact'),
                    key: 'contact',
                },
                {
                    title: CommonHelper.showLocale(props, 'businessContact'),
                    key: 'businessContact',
                },
                {
                    title: CommonHelper.showLocale(props, 'company'),
                    key: 'company',
                },
                {
                    title: CommonHelper.showLocale(props, 'Loan Vehicle'),
                    key: 'fleet',
                },
                {
                    title: CommonHelper.showLocale(props, 'stock'),
                    key: 'stock',
                },
                {
                    title: CommonHelper.showLocale(props, 'requirement'),
                    key: 'requirement',
                },
                {
                    title: CommonHelper.showLocale(props, 'Test Drive'),
                    key: 'testdrive',
                },
                {
                    title: CommonHelper.showLocale(props, 'tradeIn'),
                    key: 'tradein',
                },
                {
                    title: CommonHelper.showLocale(props, 'finance'),
                    key: 'finance',
                },
                {
                    title: CommonHelper.showLocale(props, 'Loan Vehicle'),
                    key: 'fleetContact',
                },
                {
                    title: CommonHelper.showLocale(props, 'Test Drive'),
                    key: 'testdriveContact',
                },
                {
                    title: CommonHelper.showLocale(props, 'tradeIn'),
                    key: 'tradeinContact',
                },
                {
                    title: CommonHelper.showLocale(props, 'finance'),
                    key: 'financeContact',
                },
                {
                    title: CommonHelper.showLocale(props, 'AMS Video'),
                    key: 'videoContact',
                },
                {
                    title: CommonHelper.showLocale(props, 'Front Desk'),
                    key: 'frontdesk',
                },
                {
                    title: CommonHelper.showLocale(props, 'Call Log'),
                    key: 'callLog',
                },
            ]
        }]
        var modules = ['allPipeline', 'allStatus', props.activeModule]
        if (props.activeModule === 'enquiry') {
            modules = ['allPipeline', 'allStatus'];
            if (_finstatus.length > 0) {
                modules.push('allFinStatus')
            }
            if (_amstatus.length > 0) {
                modules.push('allAMStatus')
            }
        }
        if (props.activeModule === 'enquiry' && props.selectedField && props.selectedField.value === 'lostNotes')
            modules = ['lostStatus']
        if (props.activeModule === 'contact')
            modules = ['allPipeline', 'allStatus', 'contact', 'fleetContact', 'testdriveContact', 'tradeinContact', 'financeContact', 'videoContact']
        if (props.activeModule === 'businessContact')
            modules = ['allPipeline', 'allStatus', 'businessContact', 'fleetContact', 'testdriveContact', 'tradeinContact', 'financeContact', 'videoContact']
        if (props.activeModule === 'fleet' || props.activeModule === 'stock' || props.activeModule === 'requirement' || props.activeModule === 'testdrive' || props.activeModule === 'tradein' || props.activeModule === 'finance' || props.activeModule === 'frontdesk' || props.activeModule === 'callLog')
            modules = [props.activeModule]
        if (props.activeModule === 'enquiry' && (props?.selectedField?.value?.includes('files_finance') || props?.selectedField?.value?.includes('financelog_')))
            modules = ['allFinStatus']
        if (props.activeModule === 'enquiry' && (props?.selectedField?.value?.includes('files_afterMarket') || props?.selectedField?.value?.includes('afterMarketlog_')))
            modules = ['allAMStatus']
        setTreeData([{
            ..._treedata[0],
            children: [..._.filter(_treedata[0].children, (c) => _.indexOf(modules, c.key) >= 0)]
        }])

        setExpandedKeys(modules.some(m => m === 'allPipeline') ? ['allModules', 'allPipeline'] : ['allModules'])
    }, [props])

    useEffect(() => {
        if (!_.isEmpty(props.selectedField) && !_.isEmpty(props.fields)) {
            let userlevelindex = _.find(levels, { value: selectedField.level }) ? _.find(levels, { value: selectedField.level }).index : 1;
            if (userlevelindex > 0)
                setLevelOptions(levels.filter(l => l.index >= userlevelindex))
            else
                setLevelOptions(levels)
            var checkmodules = []
            var checkdisable = []
            var modules = [];
            modules.push(props.activeModule)
            if (props.activeModule === 'contact') {
                modules = ['contact', 'fleetContact', 'testdriveContact', 'tradeinContact', 'financeContact', 'videoContact']
            }
            else if (props.activeModule === 'businessContact') {
                modules = ['businessContact', 'fleetContact', 'testdriveContact', 'tradeinContact', 'financeContact', 'videoContact']
            }
            props.fields.forEach(rec => {
                let fieldlevelindex = _.find(levels, { value: rec.level }) ? _.find(levels, { value: rec.level }).index : userlevelindex;
                modules.forEach(m => {
                    if (rec[m] && rec[m].split(',').some(t => t === props.selectedField.value)) {
                        checkmodules.push(m);
                        if (fieldlevelindex < userlevelindex)
                            checkdisable.push(m);
                    }

                })
                if (!_.isEmpty(rec['pipeline'])) {
                    rec['pipeline'].forEach(el => {
                        if (el[props.activeModule] && el[props.activeModule].split(',').some(t => t === props.selectedField.value)) {
                            checkmodules.push(el.name);
                            if (fieldlevelindex < userlevelindex)
                                checkdisable.push(el.name);
                        }

                    });
                }
                if (!_.isEmpty(rec['status'])) {
                    rec['status'].forEach(el => {
                        if (el[props.activeModule] && el[props.activeModule].split(',').some(t => t === props.selectedField.value)) {
                            checkmodules.push(el.name);
                            if (fieldlevelindex < userlevelindex)
                                checkdisable.push(el.name);
                        }

                    });
                }
                if (!_.isEmpty(rec['financeStatus'])) {
                    rec['financeStatus'].forEach(el => {
                        if (el[props.activeModule] && el[props.activeModule].split(',').some(t => t === props.selectedField.value)) {
                            checkmodules.push(`fin_${el.name}`);
                            if (fieldlevelindex < userlevelindex)
                                checkdisable.push(`fin_${el.name}`);
                        }

                    });
                }
                if (!_.isEmpty(rec['afterMarketStatus'])) {
                    rec['afterMarketStatus'].forEach(el => {
                        if (el[props.activeModule] && el[props.activeModule].split(',').some(t => t === props.selectedField.value)) {
                            checkmodules.push(`am_${el.name}`);
                            if (fieldlevelindex < userlevelindex)
                                checkdisable.push(`am_${el.name}`);
                        }

                    });
                }
            })
            setCheckedKeys(checkmodules)
            setDisabledKeys(checkdisable)
            //[{ ...treeData[0],children : [..._.map(treeData[0].children,function(e) { return disabledKeys.indexOf(e.key) >= 0 ? { ...e, disableCheckbox: true } : !_.isEmpty(e.children) ? { ...e,'children' : [..._.map(e.children,function(el) { return  !_.isEmpty(el.children) ? {...el,'children' : [..._.map(el.children,function(ele) { return disabledKeys.indexOf(ele.key) >= 0 ? { ...ele, disableCheckbox: true } : { ...ele }  } )]} : {...el}  })]} : { ...e }  })] }]
        }
    }, [selectedField])

    const onCheck = (keys) => {
        let checkedKeys = _.uniq(_.flatten([keys, disabledKeys]))
        setCheckedKeys(checkedKeys)
        // setSelectedField({
        //     ...selectedField,
        //     ['required']: checkedKeys.length > 0 ? true : false
        // })
    };

    const saveSettings = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(selectedField['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(selectedField.level) && selectedField.level !== 'oem' && _.isEmpty(selectedField['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        let mandSettings = {};
        let finalcheckedKeys = _.filter(checkedKeys, (c) => _.indexOf(disabledKeys, c) < 0)
        if (props.fields.find(item => item.levelID === selectedField.levelID)) {
            mandSettings = _.pick(props.fields.find(item => item.levelID === selectedField.levelID), _.keys(mandSettingsVM))
            if (_.isEmpty(mandSettings.pipeline)) mandSettings.pipeline = []
            if (_.isEmpty(mandSettings.status)) mandSettings.status = []
        }
        _.filter(finalcheckedKeys, (v) => _.indexOf(allModules, v) >= 0).forEach(key => {
            if (!_.isEmpty(mandSettings[key])) {
                if (!mandSettings[key].split(',').some(t => t === selectedField.value))
                    mandSettings[key] += "," + selectedField.value;
            }
            else {
                mandSettings[key] = selectedField.value
            }
        })
        _.filter(Object.keys(mandSettings), (v) => _.indexOf(finalcheckedKeys, v) < 0 && v.toLowerCase().includes(props.activeModule))
            .forEach(key => {
                mandSettings[key] = mandSettings[key].split(',').filter(e => e !== selectedField.value).join(',');
            })
        finalcheckedKeys.filter(e => e.indexOf('-') >= 0).forEach(e => {
            mandSettings['pipeline'] = _.isEmpty(mandSettings['pipeline']) ?
                [{
                    name: e,
                    [props.activeModule]: selectedField.value
                }] :
                mandSettings['pipeline'].some(item => item.name === e) ? [..._.map(mandSettings['pipeline'], function (obj) {
                    return obj.name === e ?
                        {
                            ...obj, [props.activeModule]: (obj[props.activeModule] ?
                                (!obj[props.activeModule].split(',').some(t => t === selectedField.value) ? obj[props.activeModule] += "," + selectedField.value : obj[props.activeModule])
                                : selectedField.value)
                        }
                        : { ...obj };
                })] : [...mandSettings['pipeline'], {
                    name: e,
                    [props.activeModule]: selectedField.value
                }]
        });
        mandSettings.pipeline.filter(e => e.name.indexOf('-') >= 0)
            .forEach(key => {
                if (!finalcheckedKeys.some(e => e === key.name) && key[props.activeModule]) {
                    key[props.activeModule] = key[props.activeModule].split(',').filter(e => e !== selectedField.value).join(',');
                }
            })

        //status
        _.filter(finalcheckedKeys, (v) => _.indexOf(_.map(((!_.isEmpty(props.enquiryStatus) && props.enquiryStatus.length > 0) ? props.enquiryStatus : enquiryStatus), function (e) { return e.value }), v) >= 0).forEach(e => {
            mandSettings['status'] = _.isEmpty(mandSettings['status']) ?
                [{
                    name: e,
                    [props.activeModule]: selectedField.value
                }] :
                mandSettings['status'].some(item => item.name === e) ? [..._.map(mandSettings['status'], function (obj) {
                    return obj.name === e ?
                        {
                            ...obj, [props.activeModule]: (obj[props.activeModule] ?
                                (!obj[props.activeModule].split(',').some(t => t === selectedField.value) ? obj[props.activeModule] += "," + selectedField.value : obj[props.activeModule])
                                : selectedField.value)
                        }
                        : { ...obj };
                })] : [...mandSettings['status'], {
                    name: e,
                    [props.activeModule]: selectedField.value
                }]
        });
        mandSettings.status
            .forEach(key => {
                if (!finalcheckedKeys.some(e => e === key.name) && key[props.activeModule]) {
                    key[props.activeModule] = key[props.activeModule].split(',').filter(e => e !== selectedField.value).join(',');
                }
            })

        //finance status
        if (props?.financeStatus?.length) {
            _.filter(finalcheckedKeys, (v) => _.indexOf(_.map(props.financeStatus, function (e) { return `fin_${e.value}` }), v) >= 0).forEach(e => {
                let _val = e.replace('fin_', '');
                mandSettings['financeStatus'] = _.isEmpty(mandSettings['financeStatus']) ?
                    [{
                        name: _val,
                        [props.activeModule]: selectedField.value
                    }] :
                    mandSettings['financeStatus'].some(item => item.name === _val) ? [..._.map(mandSettings['financeStatus'], function (obj) {
                        return obj.name === _val ?
                            {
                                ...obj, [props.activeModule]: (obj[props.activeModule] ?
                                    (!obj[props.activeModule].split(',').some(t => t === selectedField.value) ? obj[props.activeModule] += "," + selectedField.value : obj[props.activeModule])
                                    : selectedField.value)
                            }
                            : { ...obj };
                    })] : [...mandSettings['financeStatus'], {
                        name: _val,
                        [props.activeModule]: selectedField.value
                    }]
            });
            if (!_.isEmpty(mandSettings.financeStatus)) {
                mandSettings.financeStatus
                    .forEach(key => {
                        if (!finalcheckedKeys.some(e => e === `fin_${key.name}`) && key[props.activeModule]) {
                            key[props.activeModule] = key[props.activeModule].split(',').filter(e => e !== selectedField.value).join(',');
                        }
                    })
            }
        }

        //aftermarket status
        if (props?.afterMarketStatus?.length) {
            _.filter(finalcheckedKeys, (v) => _.indexOf(_.map(props.afterMarketStatus, function (e) { return `am_${e.value}` }), v) >= 0).forEach(e => {
                let _val = e.replace('am_', '');
                mandSettings['afterMarketStatus'] = _.isEmpty(mandSettings['afterMarketStatus']) ?
                    [{
                        name: _val,
                        [props.activeModule]: selectedField.value
                    }] :
                    mandSettings['afterMarketStatus'].some(item => item.name === _val) ? [..._.map(mandSettings['afterMarketStatus'], function (obj) {
                        return obj.name === _val ?
                            {
                                ...obj, [props.activeModule]: (obj[props.activeModule] ?
                                    (!obj[props.activeModule].split(',').some(t => t === selectedField.value) ? obj[props.activeModule] += "," + selectedField.value : obj[props.activeModule])
                                    : selectedField.value)
                            }
                            : { ...obj };
                    })] : [...mandSettings['afterMarketStatus'], {
                        name: _val,
                        [props.activeModule]: selectedField.value
                    }]
            });
            if (!_.isEmpty(mandSettings.afterMarketStatus)) {
                mandSettings.afterMarketStatus
                    .forEach(key => {
                        if (!finalcheckedKeys.some(e => e === `am_${key.name}`) && key[props.activeModule]) {
                            key[props.activeModule] = key[props.activeModule].split(',').filter(e => e !== selectedField.value).join(',');
                        }
                    })
            }
        }

        //console.log(mandSettings)
        setLoader(true)
        let querySet = `clientSettings/${props.dealersettings.client.settingsID}`;
        if (selectedField.level !== 'oem') {
            querySet = `clientSettings/${props.dealersettings.client.settingsID}/${selectedField.level === 'group' ? 'groups' : selectedField.level === 'region' ? 'regions' : 'clients'}/${selectedField.levelID}`;
        }
        window.firebase.firestore().doc(querySet).set({
            mandatoryFields: { ...mandSettings }
        }, { merge: true })
            .then(snapshot => {
                //update settings date and priority
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Mandatory Field Settings has been updated',
                    }, { merge: true })
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Mandatory Field Settings has been updated',
                        }, { merge: true })
                }

                //end
                setLoader(false)
                toast.notify('Field settings updated successfully.', {
                    duration: 2000
                })
                props.handleClose();
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    }

    // const handleRequiredChange = (e) => {
    //     setSelectedField({
    //         ...selectedField,
    //         [e.target.name]: e.target.checked
    //     })
    //     if (!e.target.checked) {
    //         setCheckedKeys([])
    //     }
    //     else {
    //         setCheckedKeys(['allModules'])
    //     }
    // }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setSelectedField({
                ...selectedField,
                [data.name]: e.value,
                ['levelID']: e.value === 'oem' ? props.dealersettings.client.settingsID : ''
            });
        }
        else {
            setSelectedField({
                ...selectedField,
                [data.name]: e.value
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    return _.isEmpty(selectedField) ? (<></>) : (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-fields"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="form-style w-100">
                        {
                            selectedField.level !== 'oem' ?
                                (<div className="alert alert-info field-alert" role="alert">
                                    <div className="field-alert-list"><strong>{selectedField.level === 'region' ? 'Region' : selectedField.level === 'group' ? 'Group' : 'Client'} : </strong> {selectedField.levelName} </div>
                                </div>) : (<></>)
                        }

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label > <Translate text={'Field Name'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'field name'}
                                    className={`form-control`}
                                    name="fieldName"
                                    onChange={() => { }}
                                    value={selectedField.name}
                                    readOnly={true}
                                />
                            </div>
                            {/* <div className="form-group col-md-12">
                                <label >Level</label>
                                <ReactSelect
                                    options={levelOptions}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={handleSelectChange}
                                    value={selectedField.level}
                                    classNamePrefix={`${errorFields["level"]} basic-select`}
                                    removeClearable={true}
                                    isDisabled={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                (!_.isEmpty(selectedField.level) && selectedField.level !== 'oem') ? (<div className="form-group col-md-12">
                                    <label >{selectedField.level === 'group' ? 'Group' : 'Client'}</label>
                                    <ReactSelect
                                        options={selectedField.level === 'group' ? props.groupOptions : selectedField.level === 'region' ? props.regionOptions : props.clientOptions}
                                        name={'levelID'}
                                        placeholder={`select ${selectedField.level === 'individual' ? 'client' : selectedField.level}`}
                                        onChange={handleSelectChange}
                                        value={selectedField.levelID}
                                        classNamePrefix={`${errorFields["levelID"]} basic-select`}
                                        removeClearable={true}
                                        isDisabled={true}
                                    >
                                    </ReactSelect>
                                </div>) : (<></>)
                            } */}
                        </div>
                        <div className="required-filed-wraper">
                            <div className="required-filed-header">
                                <div className="float-left">
                                    {/* <span className="switch switch-sm">
                                        <InputCheckBox
                                            className={`switch`}
                                            id={`required_${selectedField.value}`}
                                            name={'required'}
                                            checked={selectedField.required}
                                            onChange={(e) => { handleRequiredChange(e) }} />
                                        <label htmlFor={`required_${selectedField.value}`}></label>
                                    </span> */}
                                    <b> <Translate text={'Required'} /></b>
                                </div>
                                <div className="float-right"><i className="ico icon-info icon-grey mt-1 float-right"></i></div>
                            </div>

                            <div className="required-filed-items">
                                <Tree
                                    checkable
                                    expandedKeys={expandedKeys}
                                    checkedKeys={checkedKeys}
                                    onCheck={onCheck}
                                    treeData={treeData}
                                    onExpand={onExpand}
                                    autoExpandParent={autoExpandParent}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveSettings(e)}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>);
}

export default AddFieldSettings;
