import React from 'react'
import _ from 'lodash'
class ReportHelper {

    getChartData = (columnValues) => {
        return _.chain(columnValues).groupBy('title').map((value, key) => ({
            'name': key,
            'value': value.map(r => {
                return {
                    n: r.name,
                    v: r.value
                }
            })
        })).value()
    }

    getReportData = (stocks, customerUsage, reportModels) => {
        let listVM = _.chain(stocks)
            .groupBy("vehicleModel")
            .map((value, key) => ({
                name: key,
                modelValue: key.toLocaleLowerCase(),
                allPipeline: value.filter(a => a.mclBuildSlot && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
                pipelineNoWholesaled: value.filter(a => a.mclBuildSlot && _.isEmpty(a.stockInDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
                pipelineNoRetailed: value.filter(a => a.mclBuildSlot && !_.isEmpty(a.stockInDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
                customerPipeline: value.filter(a => a.mclBuildSlot && a.usageValue === customerUsage && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
                customerNoWholesaled: value.filter(a => a.mclBuildSlot && a.usageValue === customerUsage && _.isEmpty(a.stockInDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
                customerNoRetailed: value.filter(a => a.mclBuildSlot && a.usageValue === customerUsage && !_.isEmpty(a.stockInDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
                stockPipeline: value.filter(a => a.mclBuildSlot && a.usageValue !== customerUsage && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
                stockNoWholesaled: value.filter(a => a.mclBuildSlot && a.usageValue !== customerUsage && _.isEmpty(a.stockInDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
                stockNoRetailed: value.filter(a => a.mclBuildSlot && a.usageValue !== customerUsage && !_.isEmpty(a.stockInDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
            }))
            .value()
        let otherModels = listVM.map(r => { return r.name }).filter(a => !reportModels.some(b => a.toLocaleLowerCase() === b.toLocaleLowerCase()));
        let resultVM = [];
        reportModels.forEach(rec => {
            if (_.find(listVM, { modelValue: rec.toLocaleLowerCase() })) {
                resultVM.push(
                    _.find(listVM, { modelValue: rec.toLocaleLowerCase() })
                )
            } else {
                resultVM.push({
                    name: rec,
                    modelValue: rec.toLocaleLowerCase(),
                    allPipeline: [],
                    pipelineNoWholesaled: [],
                    pipelineNoRetailed: [],
                    customerPipeline: [],
                    customerNoWholesaled: [],
                    customerNoRetailed: [],
                    stockPipeline: [],
                    stockNoWholesaled: [],
                    stockNoRetailed: [],
                })
            }
        })
        // if (otherModels.length > 0) {
        //     resultVM.push({
        //         name: 'Others',
        //         modelValue: 'others',
        //         allPipeline: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'allPipeline'),
        //         pipelineNoWholesaled: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'pipelineNoWholesaled'),
        //         pipelineNoRetailed: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'pipelineNoRetailed'),
        //         customerPipeline: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'customerPipeline'),
        //         customerNoWholesaled: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'customerNoWholesaled'),
        //         customerNoRetailed: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'customerNoRetailed'),
        //         stockPipeline: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'stockPipeline'),
        //         stockNoWholesaled: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'stockNoWholesaled'),
        //         stockNoRetailed: this.arrayUnion(listVM.filter(a => otherModels.includes(a.name)), 'stockNoRetailed')
        //     })
        // }
        return resultVM;
    }


    getStockAge = (stocks, stockAge, reportModels) => {
        let listVM = _.chain(stocks.filter(a => !_.isEmpty(a.mclBuildSlot) && !_.isEmpty(a.etaDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'))
            .groupBy("vehicleModel")
            .map((value, key) => ({
                name: key,
                modelValue: key.toLocaleLowerCase(),
                total: value.length,
                ...this.getStockAgeCount(value, stockAge)
            }))
            .value();
        let otherModels = listVM.map(r => { return r.name }).filter(a => !reportModels.some(b => a.toLocaleLowerCase() === b.toLocaleLowerCase()));
        let resultVM = [];
        reportModels.forEach(rec => {
            if (_.find(listVM, { modelValue: rec.toLocaleLowerCase() })) {
                resultVM.push(
                    _.find(listVM, { modelValue: rec.toLocaleLowerCase() })
                )
            } else {
                resultVM.push({
                    name: rec,
                    modelValue: rec.toLocaleLowerCase(),
                    total: 0,
                    ...this.getStockAgeCount([], stockAge)
                })
            }
        })
        // if (otherModels.length > 0) {
        //     resultVM.push({
        //         name: 'Others',
        //         modelValue: 'others',
        //         total: listVM.filter(a => otherModels.includes(a.name)).length,
        //         ...this.getStockAgeOthers(listVM.filter(a => otherModels.includes(a.name)), stockAge)
        //     })
        // }
        return resultVM;
    }

    getRptStockAge = (stocks, stockAge, reportModels) => {
        return stockAge.map(rec => {
            return {
                ...rec,
                ['count']: stocks.filter(a => a.vehicleModel && reportModels.some(b => a.vehicleModel.toLocaleLowerCase() === b.toLocaleLowerCase())
                    && !_.isEmpty(a.mclBuildSlot) && !_.isEmpty(a.etaDate)
                    && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'
                    && a.stockAgeNo >= rec.min && (rec.max ? a.stockAgeNo <= rec.max : true))
            }
        })
    }

    getStockOverview = (stocks, reportModels) => {
        return {
            sold: stocks.filter(a => a.vehicleModel && reportModels.some(b => a.vehicleModel.toLocaleLowerCase() === b.toLocaleLowerCase()) && a.mclBuildSlot && a.statusValue === 'sold'),
            notWholesaled: stocks.filter(a => a.vehicleModel && reportModels.some(b => a.vehicleModel.toLocaleLowerCase() === b.toLocaleLowerCase()) && a.mclBuildSlot && _.isEmpty(a.stockInDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'),
            notRetailed: stocks.filter(a => a.vehicleModel && reportModels.some(b => a.vehicleModel.toLocaleLowerCase() === b.toLocaleLowerCase()) && a.mclBuildSlot && !_.isEmpty(a.stockInDate) && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed')
        }

    }

    getStockAgeCount = (data, stockAge) => {
        let obj = {};
        stockAge.forEach(rec => {
            obj = {
                ...obj,
                [rec.value]: data.filter(a => a.stockAgeNo >= rec.min && (rec.max ? a.stockAgeNo <= rec.max : true)).length
            }
        })
        return obj;
    }

    getStockAgeOthers = (data, stockAge) => {
        let obj = {};
        stockAge.forEach(rec => {
            obj = {
                ...obj,
                [rec.value]: _.sumBy(data, rec.value)
            }
        })
        return obj;
    }

    arrayUnion = (data, value) => {
        let arr = [];
        data.forEach(rec => {
            if (rec[value]?.length > 0) {
                arr = [
                    ...arr,
                    ...rec[value]
                ]
            }
        })
        return arr;
    }

}

export default new ReportHelper;