import React, { useState, useEffect } from 'react';
import { default as _images } from '../../../images';
import { ContentOverlay } from '../../../components';
import Translate from '../../../constants/translate';
import Swal from 'sweetalert2';
import _ from 'lodash'
import CommonHelper from '../../../services/common';
const ContactUnsubscribe = (props) => {

    const [contact, setContact] = useState();
    const [loader, setLoader] = useState(true);
    const [btnLoader, setBtnLoader] = useState();
    const [firestoreDB, setFirestoreDB] = useState();
    const [projectId, setProjectId] = useState()

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _email = params.get('email');

    useEffect(() => {
        if (!_.isEmpty(_email)) {
            window.firebase.firestore().doc(`clients/${props.contactid}`).get()
                .then(doc => {
                    if (doc?.data()?.projectId) {
                        setFirestoreDB(window.firebase2)
                        setProjectId(window.secondaryProjectId);
                    }
                    else {
                        setFirestoreDB(window.firebase)
                    }
                })
        } else {
            window.firebase.firestore().doc(`contactDetails/${props.contactid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase)
                    }
                    else {
                        setFirestoreDB(window.firebase2)
                        setProjectId(window.secondaryProjectId);
                    }
                })
        }

    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        if (!_.isEmpty(_email)) {
            firestoreDB.firestore().collection(`contactDetails`)
                .where('clientID', '==', props.contactid)
                .where('isDeleted', '==', false)
                .where('email', '==', _email)
                .get()
                .then((doc) => {
                    if (doc.size >= 1) {
                        setContact({
                            ...doc.docs[0].data(),
                            id: doc.docs[0].id
                        })
                    } else {
                        setContact({
                            optinEmail: false
                        })
                    }
                    setLoader(false)
                })
        } else {
            firestoreDB.firestore().doc(`contactDetails/${props.contactid}`).get()
                .then((doc) => {
                    if (doc.exists) {
                        setContact({
                            ...doc.data(),
                            id: doc.id
                        })
                    }
                    else {
                        setContact({
                            optinEmail: false
                        })
                    }
                    setLoader(false)
                })
        }
    }, [firestoreDB])

    const handleUnsubscribe = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to unsubscribe.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                setBtnLoader(true)
                try {
                    const unsubscribeContact = window.firebase.functions().httpsCallable('contact-unsubscribe');
                    unsubscribeContact({
                        contactID: contact.id,
                        projectId: projectId ? projectId : null
                    }).then((res) => {
                        setBtnLoader(false);
                        setContact({
                            ...contact,
                            optinEmail: false
                        })
                        Swal.fire(CommonHelper.showLocale(props, 'You have successfully unsubscribed'), '', 'success')
                    });
                }
                catch (error) {
                    setBtnLoader(false);
                    setContact({
                        ...contact,
                        optinEmail: false
                    })
                    Swal.fire(CommonHelper.showLocale(props, 'You have successfully unsubscribed'), '', 'success')
                }
            }
        })

    }

    return loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
        Boolean(contact.optinEmail) ? (
            <div className="unsubscribe-wrapper">
                <div className="unsubscribe-box">
                    <div className="unsubscribe-img">
                        <img src={_images.unsubscribe} alt="" width="150" /> </div>
                    <div className="unsubscribe-header">
                        {/* <h2>Please confirm your Unsubscribe </h2> */}
                        {/* <h3>To confirm your Unsubscribe, please click the link below.</h3> */}
                    </div>
                    <div className="unsubscribe-button-wrap"><a className="unsubscribe-button" href="#" onClick={(e) => {
                        e.preventDefault();
                        handleUnsubscribe();

                    }}>{
                            btnLoader ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }<span><Translate text={'Yes, unsubscribe me'} /></span></a></div>
                </div>
            </div>
        ) : (
            <div className="unsubscribe-wrapper">

                <div className="unsubscribe-box">
                    <div className="unsubscribe-img">
                        <img src={_images.unsubscribed} alt="" width="150" /> </div>

                    <div className="unsubscribed">
                        <h2><Translate text={"You've been unsubscribed."} /></h2>
                    </div>

                </div>
            </div>
        )

}

export default ContactUnsubscribe;