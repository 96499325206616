export const apisOptions = [
    { value: 'vehicleDetail', name: 'Vehicle Search API - Vehicle Registration and VIN Search', module: 'autograb', amsPrice: 0.47, index: 2 },
    { value: 'vehicleUpstream', name: 'Vehicle Search API - Vehicle Registration additional upstream data feature', module: 'autograb', amsPrice: 0.075, index: 7    },
    { value: 'vehicleSearch', name: 'Vehicle Search API - Vehicle Search', module: 'autograb', amsPrice: 0.10, index: 5 },
    { value: 'facetSearch', name: 'Vehicle Search API - Vehicle Facets', module: 'autograb', amsPrice: 0.01, index: 6 },
    { value: 'priceValuation', name: 'Valuation API - Current Valuation', module: 'autograb', amsPrice: 0.50, index: 1 },
    { value: 'marketOverlay', name: 'Vehicle Search API - Market Overlay', module: 'autograb', amsPrice: 1.03, index: 4 },
    { value: 'registrationCheck', name: 'Vehicle Search API - Vehicle Registration Status', module: 'autograb', amsPrice: 0.27, index: 3 },
    { value: 'ppsr', name: 'PPSR', module: 'ppsr', amsPrice: 2.00, index: 8 },
    { value: '5000', name: '5,000 Emails', module: 'mailjet' },
    { value: '15000', name: '15,000 Emails', module: 'mailjet' },
    { value: '50000', name: '50,000 Emails', module: 'mailjet' },
    { value: '100000', name: '100,000 Emails', module: 'mailjet' },
    { value: '250000', name: '250,000 Emails', module: 'mailjet' },
    { value: '500000', name: '500,000 Emails', module: 'mailjet' },
]

export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]