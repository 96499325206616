import React, { useState, useEffect } from 'react'
import "../../../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';

import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';

/** COMPONENTS */
import { ContentOverlay, FunnelChart, NoDataFunnelChart } from '../../../../components';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';

import { pipelineCountVM } from '../../../dashboard/viewModel'

const AdamasDailyActivityReport = (props) => {
    const [clientSettings, setClientSettings] = useState();
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [userTotalCounts, setUserTotalCounts] = useState(pipelineCountVM)
    const [counts, setCounts] = useState(pipelineCountVM)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [allActivities, setActivities] = useState([]);
    const [mtdTestdrives, setTestdrives] = useState([]);
    const [mtdTradeins, setTradeins] = useState([]);
    const [mtdWalkins, setWalkins] = useState([]);
    const [client, setClient] = useState({});
    const [origins, setOrigins] = useState([]);
    const [brandsSold, setBrandsSold] = useState([]);
    const [brandsNew, setBrandsNew] = useState([]);
    const [overdueActivities, setOverdueActivities] = useState([]);
    const [callActivities, setCallActivities] = useState([]);



    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? _date : moment().format('YYYY-MM-DD');

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('month')._d)
    })

    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('day')._d)
    let weekStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('week')._d)
    let weekEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('week')._d)
    const monthStart = dateRange.startDate
    const monthEnd = dateRange.endDate

    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: []
    })

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        if (props.clientid) {
            setLoader(true)

            //LOST PENDING APPROVAL
            let lostPendingApproval = firestoreDB.firestore().collection('enquiries')
                .where('clientID', '==', props.clientid)
                .where('status', '==', 'pendingLost')
                .where('isDeleted', '==', false)

            //ALL PENDING BUCKET  
            let refPendingBucketData = firestoreDB.firestore().collection('enquiries')
                .where('clientID', '==', props.clientid)
                .where('pipeline', '==', 'LeadsBucket')
                .where('isDeleted', '==', false)

            //MTD CONVERTED ENQUIRIES  
            let refConvertedEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('clientID', '==', props.clientid)
                .where('convertedDate', '>=', dateRange.startDate)
                .where('convertedDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
                .orderBy('convertedDate', 'desc')

            //MTD OPEN ENQUIRIES / CAMPAIGN /LEAD ORIGIN / ENQUIRY TYPE / LABEL  
            let refOpenEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('clientID', '==', props.clientid)
                .where('addedDate', '>=', dateRange.startDate)
                .where('addedDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)

            //MTD WON ENQUIRIES     
            let refWonEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('status', '==', 'won')
                .where('clientID', '==', props.clientid)
                .where('wonDate', '>=', dateRange.startDate)
                .where('wonDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)

            //MTD LOST ENQUIRIES    
            let refLostEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('status', '==', 'lost')
                .where('clientID', '==', props.clientid)
                .where('lostDate', '>=', dateRange.startDate)
                .where('lostDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)

            //MTD DELIVERED ENQUIRIES    
            let refDeliveredEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('status', '==', 'delivered')
                .where('clientID', '==', props.clientid)
                .where('deliveredDate', '>=', dateRange.startDate)
                .where('deliveredDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)

            //MTD TEST DRIVES    
            let refTestDriveData = firestoreDB.firestore().collection('testdrives')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('startDate', '>=', dateRange.startDate)
                .where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')


            //MTD TRADE IN COUNT    
            let refTradeinData = firestoreDB.firestore().collection('tradeins')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('addedDate', '>=', dateRange.startDate)
                .where('addedDate', '<=', dateRange.endDate)


            //MTD DONE ACTIVITIES
            let refCompletedActivityData = firestoreDB.firestore().collection('activities')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('isDone', '==', true)
                .where('type', '==', 'activity')
                .where('modifiedDate', '>=', dateRange.startDate)
                .where('modifiedDate', '<=', dateRange.endDate)

            //MTD OVERDUE ACTIVITIES
            let refScheduleActivityData = firestoreDB.firestore().collection('activities')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('isDone', '==', false)
                .where('type', '==', 'activity')
                .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .orderBy('startDate', 'desc')
                .limit(1000)

            //MTD WALKIN LEADS
            let refWalkinEnquiryData = firestoreDB.firestore().collectionGroup('walkins')
                .where('clientID', '==', props.clientid)
                .where('walkinDate', '>=', dateRange.startDate)
                .where('walkinDate', '<=', dateRange.endDate)

            let refClientData = window.firebase.firestore().collection('clients').where('documentID', '==', props.clientid)

            //MTD OVERDUE ACTIVITIES
            let refCallActivityData = firestoreDB.firestore().collection('activities')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('isDone', '==', true)
                .where('type', '==', 'log')
                .where('subType', '==', 'call')
                .where('startDate', '>=', dateRange.startDate)
                .where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')

            let refClientSettings = window.firebase.firestore().collection('clients').doc(props.clientid).collection('currentSettings')

            var unionPipelines = combineLatest(
                refPendingBucketData ? collection(refPendingBucketData) : of([]),
                refConvertedEnquiryData ? collection(refConvertedEnquiryData) : of([]),
                lostPendingApproval ? collection(lostPendingApproval) : of([]),
                refOpenEnquiryData ? collection(refOpenEnquiryData) : of([]),
                refWonEnquiryData ? collection(refWonEnquiryData) : of([]),
                refLostEnquiryData ? collection(refLostEnquiryData) : of([]),
                refDeliveredEnquiryData ? collection(refDeliveredEnquiryData) : of([]),
                collection(refTestDriveData),
                collection(refTradeinData),
                collection(refCompletedActivityData),
                collection(refScheduleActivityData),
                collection(refWalkinEnquiryData),
                collection(refClientData),
                collection(refCallActivityData),
                collection(refClientSettings),
            ).pipe(
                map(([pendinBucket, convertedLeads, lostPendingApproval,
                    openLeads, wonLeads, lostLeads, deliveredLeads,
                    testdriveLeads, tradeinLeads, doneActivity,
                    overdueActivity, walkins, clients, callActivity, clientsSetting]) => {
                    return [
                        _.map(pendinBucket, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(convertedLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(lostPendingApproval, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(openLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(wonLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(lostLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(deliveredLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(testdriveLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(tradeinLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(doneActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(overdueActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(walkins, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(clients, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(callActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(clientsSetting, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    ]
                }))
                .subscribe(allDocs => {
                    var _enquiries = [];
                    for (let i = 0; i < 7; i++) {
                        allDocs[i].forEach(el => {
                            if (!_enquiries.some(e => e.documentID === el.documentID) && !_.isEmpty(el.contact)) {
                                _enquiries.push({
                                    ...el,
                                    testdrives: allDocs[7].filter(a => a.enquiryID === el.documentID),
                                    walkins: allDocs[11].filter(a => a.enquiryID === el.documentID),
                                })
                            }
                        });
                    }

                    allDocs[7].forEach(el => {//testdrive array
                        if (!_enquiries.some(e => e.documentID === el.enquiryID) && !_.isEmpty(el.enquiry) && !_.isEmpty(el.enquiry.addedDate)) {
                            _enquiries.push({
                                ...el.enquiry,
                                'documentID': el.enquiryID,
                                testdrives: [el],
                                walkins: allDocs[11].filter(a => a.enquiryID === el.enquiryID),
                            })
                        }
                    });
                    var _activities = []
                    for (let i = 9; i < 10; i++) {
                        allDocs[i].forEach(el => {
                            if (!_activities.some(e => e.documentID === el.documentID)) {
                                _activities.push(el)
                            }
                        });
                    }

                    setOverdueActivities(allDocs[10].filter(item => !_.isEmpty(item.owner)))
                    setCallActivities(allDocs[13] ? allDocs[13].filter(item => !_.isEmpty(item.notes)) : [])
                    setClient(allDocs[12] ? Object.assign({}, allDocs[12][0]) : {})
                    setClientSettings(allDocs[14] ? Object.assign({}, allDocs[14][0]) : {})
                    setTestdrives(allDocs[7])
                    setTradeins(allDocs[8])//
                    setActivities(_activities)
                    setWalkins(allDocs[11])
                    setTempData({
                        enquiries: _enquiries,
                        walkins: allDocs[11]
                    })

                    if (allDocs[11].length === 0)
                        setLoader(false)
                });
            return () => {
                unionPipelines && unionPipelines.unsubscribe()
            }
        }

    }, [dateRange, firestoreDB])

    useEffect(() => {
        if (_.isEmpty(firestoreDB)) return;
        
        async function getWalkinEnq(walkins, enquiries) {
            const promises = []
            const walkinpromises = []
            _.uniq(_.map(walkins, function (e) { return e.enquiryID })).forEach(el => {
                walkinpromises.push(firestoreDB.firestore().collection(`enquiries/${el}/walkins`)
                    .orderBy('walkinDate', 'asc')
                    .limit(2)
                    .get())
                if (!enquiries.some(e => e.documentID === el)) {
                    promises.push(firestoreDB.firestore().doc(`enquiries/${el}`).get())
                }
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id };
                if (dataDoc.isDeleted === false && dataDoc.pipeline !== 'LeadsBucket' && !_.isEmpty(dataDoc.contact)) {
                    enquiries.push({
                        ...dataDoc,
                        walkins: walkins.filter(a => a.enquiryID === snap.id)
                    })
                }
            })
            const walkinsnapshots = await Promise.all(walkinpromises);
            let _walkins = []
            walkinsnapshots.forEach(snaps => {
                snaps.docs.forEach(snap => {
                    _walkins.push({
                        ...snap.data(),
                        documentID: snap.id,
                        strwalkinDate: snap.data().walkinDate ? moment.unix(snap.data().walkinDate.seconds).format('YYYY-MM-DD') : ''
                    });
                })

            })

            setEnquiries(enquiries.map(rec => {
                return {
                    ...rec,
                    beBack: _.uniqBy(_walkins.filter(a => a.enquiryID === rec.documentID), 'strwalkinDate').length > 1 ? true : false,
                    newWalkin: _.uniqBy(_walkins.filter(a => a.enquiryID === rec.documentID), 'strwalkinDate').length === 1 ? true : false,
                }
            }))
            setLoader(false)
        }
        if (tempData.walkins.length > 0)
            getWalkinEnq(tempData.walkins, tempData.enquiries);
        else
            setEnquiries(tempData.enquiries);



    }, [tempData, firestoreDB])

    useEffect(() => {
        if (!_.isArray(pipelineEnquiries) && !_.isArray(client) && !_.isEmpty(clientSettings))
            return

        setUserTotalCounts({
            todayNew: pipelineEnquiries.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            todayOpen: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            todayWon: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= todayStart.seconds
                && a.wonDate.seconds <= todayEnd.seconds),
            todayLost: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= todayStart.seconds
                && a.lostDate.seconds <= todayEnd.seconds),
            todayTestdrive: _.uniqBy(mtdTestdrives.filter(item => !_.isEmpty(item.enquiryID)), 'enquiryID').filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= todayStart.seconds
                && a.startDate.seconds <= todayEnd.seconds),
            todayShowroomWalkIn: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'new showroom walk in')
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            todayOutboundProspect: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'prospect call')
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            todayOutbound: callActivities.filter(a => a.owner && a.callType && a.callType.toLowerCase() === 'outbound'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            todayInbound: callActivities.filter(a => a.owner && a.callType && a.callType.toLowerCase() === 'inbound'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            todayOverdue: overdueActivities.filter(a => a.owner),
        })

        let groupedSold = _.chain(_.orderBy(Object.assign([], pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
            && !_.isEmpty(a.wonDate)
            && a.wonDate.seconds >= monthStart.seconds
            && a.wonDate.seconds <= monthEnd.seconds)), ['vehicleMake'], ['asc']).filter(item => !_.isEmpty(item.vehicleMake) && item.pipleine !== 'LeadsBucket'))
            .groupBy("vehicleMake")
            .map((value, key) => (
                {
                    make: key,
                    models: (_.chain(_.orderBy(Object.assign([], value), ['vehicleModel'], ['asc']).filter(item2 => !_.isEmpty(item2.vehicleModel)))
                        .groupBy("vehicleModel")
                        .map((value, key) => (
                            {
                                name: key,
                                count: value.length
                            }
                        ))
                        .value())
                }
            ))
            .value()

        let groupedNew = _.chain(_.orderBy(Object.assign([], pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.pipeline !== "LeadsBucket"
            && !_.isEmpty(a.addedDate)
            && a.addedDate.seconds >= monthStart.seconds
            && a.addedDate.seconds <= monthEnd.seconds)), ['vehicleMake'], ['asc']).filter(item => !_.isEmpty(item.vehicleMake) && item.pipleine !== 'LeadsBucket'))
            .groupBy("vehicleMake")
            .map((value, key) => (
                {
                    make: key,
                    models: (_.chain(_.orderBy(Object.assign([], value), ['vehicleModel'], ['asc']).filter(item2 => !_.isEmpty(item2.vehicleModel)))
                        .groupBy("vehicleModel")
                        .map((value, key) => (
                            {
                                name: key,
                                count: value.length
                            }
                        ))
                        .value())
                }
            ))
            .value()

        if (!_.isEmpty(client.weekStart)) {
            weekStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('week').add(moment(_date).day(client.weekStart).day(), 'day')._d)
            weekEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('week').add(moment(_date).day(client.weekStart).day(), 'day')._d)
        }
        const monthNewLeads = pipelineEnquiries.filter(a => a.pipeline !== "LeadsBucket"
            && !_.isEmpty(a.addedDate)
            && a.addedDate.seconds >= monthStart.seconds
            && a.addedDate.seconds <= monthEnd.seconds)
        setCounts({
            todayNew: pipelineEnquiries.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekNew: pipelineEnquiries.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthNew: monthNewLeads,
            todayOpen: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekOpen: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthOpen: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
            todayWon: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= todayStart.seconds
                && a.wonDate.seconds <= todayEnd.seconds),
            weekWon: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= weekStart.seconds
                && a.wonDate.seconds <= weekEnd.seconds),
            monthWon: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= monthStart.seconds
                && a.wonDate.seconds <= monthEnd.seconds),
            todayLost: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= todayStart.seconds
                && a.lostDate.seconds <= todayEnd.seconds),
            weekLost: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= weekStart.seconds
                && a.lostDate.seconds <= weekEnd.seconds),
            monthLost: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= monthStart.seconds
                && a.lostDate.seconds <= monthEnd.seconds),
            todayDelivered: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= todayStart.seconds
                && a.deliveredDate.seconds <= todayEnd.seconds),
            weekDelivered: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= weekStart.seconds
                && a.deliveredDate.seconds <= weekEnd.seconds),
            monthDelivered: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= monthStart.seconds
                && a.deliveredDate.seconds <= monthEnd.seconds),
            todayTestdrive: mtdTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= todayStart.seconds
                && a.startDate.seconds <= todayEnd.seconds),
            weekTestdrive: mtdTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= weekStart.seconds
                && a.startDate.seconds <= weekEnd.seconds),
            monthTestdrive: mtdTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= monthStart.seconds
                && a.startDate.seconds <= monthEnd.seconds),
            // New counts start
            todayShowroomWalkIn: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'new showroom walk in')
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekShowroomWalkIn: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'new showroom walk in')
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthShowroomWalkIn: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'new showroom walk in')
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
            todayOutboundProspect: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'prospect call')
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekOutboundProspect: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'prospect call')
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthOutboundProspect: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'prospect call')
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),

            todayOutbound: callActivities.filter(a => a.callType
                && a.callType.toLowerCase() === 'outbound'
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekOutbound: callActivities.filter(a => a.callType
                && a.callType.toLowerCase() === 'outbound'
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthOutbound: callActivities.filter(a => a.callType
                && a.callType.toLowerCase() === 'outbound'
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),

            todayInbound: callActivities.filter(a => a.callType
                && a.callType.toLowerCase() === 'inbound'
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekInbound: callActivities.filter(a => a.callType
                && a.callType.toLowerCase() === 'inbound'
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthInbound: callActivities.filter(a => a.callType
                && a.callType.toLowerCase() === 'inbound'
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
            // New counts end
            todayWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket'
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds
                && (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= todayStart.seconds
                    && item.walkinDate.seconds <= todayEnd.seconds))), 'documentID'),
            weekWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket'
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds
                && (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= weekStart.seconds
                    && item.walkinDate.seconds <= weekEnd.seconds))), 'documentID'),
            monthWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket'
                //&& a.addedDate.seconds >= monthStart.seconds
                //&& a.addedDate.seconds <= monthEnd.seconds
                && (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= monthStart.seconds
                    && item.walkinDate.seconds <= monthEnd.seconds))), 'documentID'),

            // New WALK IN counts end
            todayNewWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                a.newWalkin === true &&
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= todayStart.seconds
                    && item.walkinDate.seconds <= todayEnd.seconds))), 'documentID'),
            weekNewWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                a.newWalkin === true
                && (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= weekStart.seconds
                    && item.walkinDate.seconds <= weekEnd.seconds))), 'documentID'),
            monthNewWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                a.newWalkin === true
                && (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= monthStart.seconds
                    && item.walkinDate.seconds <= monthEnd.seconds))), 'documentID'),

            // REPEAT WALK IN counts end
            todayRepeatWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                a.beBack === true &&
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= todayStart.seconds
                    && item.walkinDate.seconds <= todayEnd.seconds))), 'documentID'),
            weekRepeatWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                a.beBack === true &&
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= weekStart.seconds
                    && item.walkinDate.seconds <= weekEnd.seconds))), 'documentID'),
            monthRepeatWalkin: _.uniqBy(pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                a.beBack === true &&
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= monthStart.seconds
                    && item.walkinDate.seconds <= monthEnd.seconds))), 'documentID'),

            todayTradein: mtdTradeins.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekTradein: mtdTradeins.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthTradein: mtdTradeins.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
            todayActivity: allActivities.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekActivity: allActivities.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthActivity: _.uniq(_.map(allActivities.filter(a => !_.isEmpty(a.enquiryID) && monthNewLeads.some(b => b.documentID === a.enquiryID)), function (e) {
                return e.enquiryID
            })),
        })

        setBrandsSold(groupedSold);
        setBrandsNew(groupedNew);

    }, [pipelineEnquiries, allActivities, mtdTestdrives, mtdTradeins, mtdWalkins, client, clientSettings])


    useEffect(() => {
        async function getClientSettings(clientID) {
            window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get().then(doc => {
                //console.log('currentSettings', doc.data())
                let settingsDoc = doc.data();
                setClientSettings(settingsDoc);

                if (!_.isEmpty(settingsDoc) && !_.isEmpty(settingsDoc.settings) && !_.isEmpty(settingsDoc.settings.origins)) {

                    setOrigins(_.chain(pipelineEnquiries.filter(a => !_.isEmpty(a.status) &&
                        (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                        && !_.isEmpty(a.origin)
                        && !_.isEmpty(a.wonDate)
                        && a.wonDate.seconds >= monthStart.seconds
                        && a.wonDate.seconds <= monthEnd.seconds))
                        .groupBy("origin")
                        .map((value, key) => ({
                            name: _.find(settingsDoc.settings.origins, { value: key }) ? _.find(settingsDoc.settings.origins, { value: key }).name : key,
                            count: value.length,
                            value: key,
                            startDate: monthStart,
                            endDate: monthEnd
                        }))
                        .value())


                }

            });
        }

        if (!_.isEmpty(client) && !_.isEmpty(client.documentID) && !_.isEmpty(pipelineEnquiries)) {
            getClientSettings(client.documentID)
        }
    }, [client, pipelineEnquiries])

    const convertEnquiryVM = (doc) => {
        let listVM = doc;

        if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
            listVM.vehicleMake = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.make : doc.requirement.make
            listVM.vehicleModel = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.model : doc.requirement.model
            listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;
            listVM.vehicleSaletype = doc.requirement.saleType;
            listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
        }
        return listVM
    }

    const calculatePercentage = (val1, val2) => {
        // const percentage =
        //     ((_.isNumber(val2) ? val2 : 0) / (_.isNumber(val1) ? val1 : 0)) * 100;
        //return parseFloat(percentage.toFixed(2)) + '%';
        return (val2 > 0 && val1 > 0 ?
            parseFloat((100 / (val1) * val2).toFixed(2)) + "%" :
            "0.00%");
    }

    useEffect(() => {
        if (_.isEmpty(counts) || counts.length === 0) {
            return
        }


        var groupbyStatus = []

        groupbyStatus.push({
            name: 'New Leads',
            count: counts.monthNew.length,
            value: 'New Leads',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })
        groupbyStatus.push({
            name: 'Appointments',
            count: counts.monthActivity.length,
            value: 'Appointments',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })
        groupbyStatus.push({
            name: 'Showroom Visits',
            count: counts.monthWalkin.length,
            value: 'Showroom Visits',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })
        groupbyStatus.push({
            name: 'Test Drives',
            count: counts.monthTestdrive.length,
            value: 'Test Drives',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })
        groupbyStatus.push({
            name: 'Sold Vehicles',
            count: counts.monthWon.length,
            value: 'Sold Vehicles',
            color: '#049b8c',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })


        setData(groupbyStatus)

    }, [counts])

    const showList = () => {

    }

    const generateTablesByUsers = () => {
        let allUsersCounts = []

        clientSettings.users.forEach(user => {
            let userNewCounts = {
                todayNew: pipelineEnquiries.filter(a => a.pipeline !== "LeadsBucket"
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= todayStart.seconds
                    && a.addedDate.seconds <= todayEnd.seconds
                    && user.id === a.owner),
                todayOpen: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= todayStart.seconds
                    && a.addedDate.seconds <= todayEnd.seconds
                    && user.id === a.owner),
                todayWon: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                    && !_.isEmpty(a.wonDate)
                    && a.wonDate.seconds >= todayStart.seconds
                    && a.wonDate.seconds <= todayEnd.seconds
                    && user.id === a.owner),
                todayLost: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                    && !_.isEmpty(a.lostDate)
                    && a.lostDate.seconds >= todayStart.seconds
                    && a.lostDate.seconds <= todayEnd.seconds
                    && user.id === a.owner),
                todayTestdrive: _.uniqBy(mtdTestdrives.filter(item => !_.isEmpty(item.enquiryID)), 'enquiryID').filter(a => !_.isEmpty(a.startDate)
                    && a.startDate.seconds >= todayStart.seconds
                    && a.startDate.seconds <= todayEnd.seconds
                    && user.id === a.addedBy),
                todayShowroomWalkIn: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'new showroom walk in')
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= todayStart.seconds
                    && a.addedDate.seconds <= todayEnd.seconds
                    && user.id === a.owner),
                todayOutboundProspect: pipelineEnquiries.filter(a => !_.isEmpty(a.origin) && checkOrigin(a.origin, 'prospect call')
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= todayStart.seconds
                    && a.addedDate.seconds <= todayEnd.seconds
                    && user.id === a.owner),
                todayOutbound: callActivities.filter(a => a.owner && a.owner === user.id && a.callType && a.callType.toLowerCase() === 'outbound'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= todayStart.seconds
                    && a.addedDate.seconds <= todayEnd.seconds),
                todayInbound: callActivities.filter(a => a.owner && a.owner === user.id && a.callType && a.callType.toLowerCase() === 'inbound'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= todayStart.seconds
                    && a.addedDate.seconds <= todayEnd.seconds),
                todayOverdue: overdueActivities.filter(a => a.owner && a.owner === user.id),
            }

            allUsersCounts.push({
                userID: user.id,
                name: user.name,
                userCounts: userNewCounts,
            })
        })


        allUsersCounts = _.orderBy(allUsersCounts, ['name'], ['asc'])

        return (
            <>
                {/* NEW  */}
                <table className="table-loop">
                    <thead>
                        <tr>
                            <th className="cell-head" width="15%">Users</th>
                            <th width="8.5%">New Leads</th>
                            <th width="8.5%">Open Leads</th>
                            <th width="8.5%">Sold Leads</th>
                            <th width="8.5%">Test Drive  </th>
                            <th width="8.5%">Lost Leads</th>
                            <th width="8.5%">Outbound Call Log</th>
                            <th width="8.5%">Inbound Call Log</th>
                            <th width="8.5%">New Showroom Walk In</th>
                            <th width="8.5%">Prospect Call</th>
                            <th width="8.5%">Overdue Activity</th>
                            {/* <th width="8.5%"> Activities </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allUsersCounts.map((user, index) => (
                                <React.Fragment key={user.id}>
                                    {user.userCounts.todayNew.length +
                                        user.userCounts.todayOpen.length +
                                        user.userCounts.todayWon.length +
                                        user.userCounts.todayTestdrive.length +
                                        user.userCounts.todayLost.length +
                                        user.userCounts.todayOutbound.length +
                                        user.userCounts.todayInbound.length +
                                        user.userCounts.todayShowroomWalkIn.length +
                                        user.userCounts.todayOutboundProspect.length +
                                        user.userCounts.todayOverdue.length === 0 ? (<></>) : (
                                        <tr>
                                            <td className="td-head">{user.name}</td>
                                            <td className="count-large">{user.userCounts.todayNew.length}</td>
                                            <td className="count-large">{user.userCounts.todayOpen.length}</td>
                                            <td className="count-large">{user.userCounts.todayWon.length}</td>
                                            <td className="count-large">{user.userCounts.todayTestdrive.length}</td>
                                            <td className="count-large">{user.userCounts.todayLost.length}</td>
                                            <td className="count-large">{user.userCounts.todayOutbound.length}</td>
                                            <td className="count-large">{user.userCounts.todayInbound.length}</td>
                                            <td className="count-large">{user.userCounts.todayShowroomWalkIn.length}</td>
                                            <td className="count-large">{user.userCounts.todayOutboundProspect.length}</td>
                                            <td className="count-large">{user.userCounts.todayOverdue.length}</td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        }
                        <tr>
                            <td className="td-head">Totals</td>
                            <td className="count-large">{userTotalCounts.todayNew.length}</td>
                            <td className="count-large">{userTotalCounts.todayOpen.length}</td>
                            <td className="count-large">{userTotalCounts.todayWon.length}</td>
                            <td className="count-large">{userTotalCounts.todayTestdrive.length}</td>
                            <td className="count-large">{userTotalCounts.todayLost.length}</td>
                            <td className="count-large">{userTotalCounts.todayOutbound.length}</td>
                            <td className="count-large">{userTotalCounts.todayInbound.length}</td>
                            <td className="count-large">{userTotalCounts.todayShowroomWalkIn.length}</td>
                            <td className="count-large">{userTotalCounts.todayOutboundProspect.length}</td>
                            <td className="count-large">{userTotalCounts.todayOverdue.length}</td>
                        </tr>
                    </tbody>
                </table>

            </>
        )
    }

    const checkEnqType = (type, strToCheck) => {
        let returnVal = false

        let enqType = CommonHelper.getNameByValue(clientSettings.settings.enquiryTypes, type, '')

        if (enqType.toLowerCase() === strToCheck.toLowerCase()) {
            returnVal = true
        } else {
            returnVal = false
        }
        return returnVal
    }

    const checkOrigin = (type, strToCheck) => {
        let returnVal = false

        let enqType = CommonHelper.getNameByValue(clientSettings.settings.origins, type, '')

        if (enqType.toLowerCase() === strToCheck.toLowerCase()) {
            returnVal = true
        } else {
            returnVal = false
        }
        return returnVal
    }

    return (
        <>
            {
                !_.isEmpty(client) && !_.isEmpty(clientSettings)
                    ?
                    <>
                        <div className="A4 landscape">
                            <>
                                {/* @info: Below div is used for selector by puppeteer (backend)*/}
                                <div className="report-data-loaded report-data-load" />
                            </>
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }


                                                </div></td>

                                                <td width="40%"><div className="header-right"> {'DAILY ACTIVITY REPORT'} <br></br>
                                                    <span> {moment(_date).format('DD MMMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">
                                    <table className="table-loop report-data-load">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="15%"></th>
                                                <th width="7.7%">New Leads</th>
                                                <th width="7.7%">Open Leads</th>
                                                <th width="7.7%">Sold Leads</th>
                                                <th width="7.7%">Test Drive  </th>
                                                <th width="7.7%">Lost Leads</th>
                                                <th width="7.7%">Outbound Call Log</th>
                                                <th width="7.7%">Inbound Call Log</th>
                                                <th width="7.7%">Prospect Call</th>
                                                <th width="7.7%">New Showroom Walk In</th>
                                                <th width="7.7%">New Walkin </th>
                                                <th width="7.7%">Repeat Walkin </th>
                                                {/* <th width="10%"> Activities </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">TODAY</td>
                                                <td className="count-large">{counts.todayNew.length}</td>
                                                <td className="count-large">{counts.todayOpen.length}</td>
                                                <td className="count-large">{counts.todayWon.length}</td>
                                                <td className="count-large">{counts.todayTestdrive.length}</td>
                                                <td className="count-large">{counts.todayLost.length}</td>
                                                <td className="count-large">{counts.todayOutbound.length}</td>
                                                <td className="count-large">{counts.todayInbound.length}</td>
                                                <td className="count-large">{counts.todayOutboundProspect.length}</td>
                                                <td className="count-large">{counts.todayShowroomWalkIn.length}</td>
                                                <td className="count-large">{counts.todayNewWalkin.length}</td>
                                                <td className="count-large">{counts.todayRepeatWalkin.length}</td>
                                                {/* <td>{counts.todayActivity.length}</td> */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS WEEK</td>
                                                <td className="count-large">{counts.weekNew.length}</td>
                                                <td className="count-large">{counts.weekOpen.length}</td>
                                                <td className="count-large">{counts.weekWon.length}</td>
                                                <td className="count-large">{counts.weekTestdrive.length}</td>
                                                <td className="count-large">{counts.weekLost.length}</td>
                                                <td className="count-large">{counts.weekOutbound.length}</td>
                                                <td className="count-large">{counts.weekInbound.length}</td>
                                                <td className="count-large">{counts.weekOutboundProspect.length}</td>
                                                <td className="count-large">{counts.weekShowroomWalkIn.length}</td>
                                                <td className="count-large">{counts.weekNewWalkin.length}</td>
                                                <td className="count-large">{counts.weekRepeatWalkin.length}</td>
                                                {/* <td>{counts.weekActivity.length}</td> */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS MONTH</td>
                                                <td className="count-large">{counts.monthNew.length}</td>
                                                <td className="count-large">{counts.monthOpen.length}</td>
                                                <td className="count-large">{counts.monthWon.length}</td>
                                                <td className="count-large">{counts.monthTestdrive.length}</td>
                                                <td className="count-large">{counts.monthLost.length}</td>
                                                <td className="count-large">{counts.monthOutbound.length}</td>
                                                <td className="count-large">{counts.monthInbound.length}</td>
                                                <td className="count-large">{counts.monthOutboundProspect.length}</td>
                                                <td className="count-large">{counts.monthShowroomWalkIn.length}</td>
                                                <td className="count-large">{counts.monthNewWalkin.length}</td>
                                                <td className="count-large">{counts.monthRepeatWalkin.length}</td>
                                                {/* <td>{counts.monthActivity.length}</td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                    {generateTablesByUsers()}
                                    <div className="table-head">NEW ENQUIRIES</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="30%">MAKE</th>
                                                <th width="50%">MODEL</th>
                                                <th width="20%">COUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {brandsNew && brandsNew.map((brand, i) => {
                                                return brand.models && brand.models.map((models, index) =>
                                                    index === 0 ? (
                                                        <tr key={'mk_' + i}>
                                                            <td rowSpan={brand.models.length}>
                                                                {brand.make}
                                                            </td>
                                                            <td rowSpan="1">
                                                                {models.name}
                                                            </td>
                                                            <td className="count-large">
                                                                {models.count}
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={'mo_' + index}>
                                                            <td rowSpan="1">
                                                                {models.name}
                                                            </td>
                                                            <td className="count-large">
                                                                {models.count}
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="table-head">SOLD ENQUIRIES</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="30%">MAKE</th>
                                                <th width="50%">MODEL</th>
                                                <th width="20%">COUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {brandsSold && brandsSold.map((brand, i) => {
                                                return brand.models && brand.models.map((models, index) =>
                                                    index === 0 ? (
                                                        <tr key={'mk_' + i}>
                                                            <td rowSpan={brand.models.length}>
                                                                {brand.make}
                                                            </td>
                                                            <td rowSpan="1">
                                                                {models.name}
                                                            </td>
                                                            <td className="count-large">
                                                                {models.count}
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={'mo_' + index}>
                                                            <td rowSpan="1">
                                                                {models.name}
                                                            </td>
                                                            <td className="count-large">
                                                                {models.count}
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                    <div className="table-head">LEAD ORIGIN</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="80%">ORIGIN</th>
                                                <th width="20%">COUNT</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                origins && origins.map((origin, index) => {
                                                    return <tr key={index}>
                                                        <td>{origin.name}</td>
                                                        <td className="count-large">{origin.count}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>

                                    <div className="graph-wrapper">

                                        <div className="graph-left">
                                            <div className="graph-head">Sales Funnel</div>
                                            <div className="graph-panel">
                                                {
                                                    data ? (
                                                        <div className="dashboard-box-body">
                                                            <div className="text-center">
                                                                <FunnelChart
                                                                    id={'sales-funnel-chart'}
                                                                    height={'350px'}
                                                                    data={data}
                                                                    handleShowEnquiryList={showList}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="dashboard-box-body" style={{ height: `350px` }}>
                                                            <div className="text-center">
                                                                <NoDataFunnelChart
                                                                    id={'sales-funnel-chart'}
                                                                    height={'350px'}
                                                                />
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="graph-right">
                                            <table className="table-loop table-sub-fix table-td-fix">
                                                <thead>
                                                    <tr>
                                                        <th width="80%">KPI's</th>
                                                        <th width="20%">CONV. RATIO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>NEW LEADS Vs APPOINTMENTS</td>
                                                        <td className="count-large">
                                                            {calculatePercentage(counts.monthNew.length, counts.monthActivity.length)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>APPOINTMENTS Vs SOLD</td>
                                                        <td className="count-large">
                                                            {calculatePercentage(counts.monthActivity.length, counts.monthWon.length)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>NEW SHOWROOM WALK INS Vs TEST DRIVES</td>
                                                        <td className="count-large">
                                                            {calculatePercentage(counts.monthShowroomWalkIn.length, counts.monthTestdrive.length)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                </div>

                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default AdamasDailyActivityReport;