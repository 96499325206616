import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'toasted-notes';
import _ from 'lodash';
import { InputText } from '../components';
import Translate from '../constants/translate';
import CommonHelper from '../services/common';
import moment from 'moment';
import Swal from 'sweetalert2';
import ImageCropHook from './imageCropHook';
import ReactSelect from './reactSelect';
import { Dropdown } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from './customdropdown';
import { default as _images } from "../images";
import ReactMultiSelect from './reactMultiSelect';

const GroupInfo = props => {
	const [group, setGroup] = useState(!props.newGroup ? props.groupData : {
		participants: [],
		name: '',
		addedBy: localStorage.uid,
		addedDate: window.firebase.firestore.Timestamp.now(),
		clientID: props.dealersettings.client.id,
		oemID: props.dealersettings.client.settingsID,
		documentID: window.firebase.firestore().collection(`messages`).doc().id,
		conversationType: 'group',
		isRead: false,
		groupImageURL: '',
	});
	const [newParticipants, setNewParticipants] = useState([]);
	const [errorFields, setErrorFields] = useState({});
	const [loader, setLoader] = useState(false);
	const [editName, setEditName] = useState(props.newGroup ? true : false);
	const [cropShow, setCropShow] = useState({
		show: false,
		clsActive: '',
		src: null,
		id: '',
		type: '',
		ext: '',
		title: '',
	});
	const [imageLoader, setImageLoader] = useState(false);

	const handleOnChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		var array1 = value.split(' ');
		var str = value;
		if (array1.length > 0) {
			var newarray1 = [];
			for (var x = 0; x < array1.length; x++) {
				newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
			}
			setGroup({
				...group,
				[name]: newarray1.join(' '),
			});
		} else {
			if (str && str.length >= 1) {
				var firstChar = str.charAt(0);
				var remainingStr = str.slice(1);
				str = firstChar.toUpperCase() + remainingStr;
			}
			setGroup({
				...group,
				[name]: str,
			});
		}
		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleParticipantChange = selectedOptions => {
		const value = [];

		!_.isEmpty(selectedOptions) &&
			selectedOptions.forEach((data, index) => {
				value.push(data.value);
			});

		setGroup({
			...group,
			participants: value,
		});
	};

	const saveGroup = e => {
		e.preventDefault();
		let formIsValid = true;
		let errorClass = 'input_error';
		let errors = {};
		if (_.isEmpty(group['name'])) {
			formIsValid = false;
			errors['name'] = errorClass;
		}
		if (group['participants'].length <= 0) {
			formIsValid = false;
			errors['participants'] = errorClass;
			toast.notify('Please select some participants.', {
				duration: 2000,
			});
		}
		setErrorFields(errors);
		if (!formIsValid) {
			return;
		}

		let objData = { ...group };
		if (props.newGroup) {
			objData.participants.push(localStorage.uid);
			objData.timestamp = moment()._d.getTime();
		}
		objData.participants = _.uniq(objData.participants);
		let dbAddress = objData['dbAddress'] ? objData['dbAddress'] : objData['documentID'];
		if (!props.newGroup) {
			objData.participants = _.uniq([...objData.participants, ...newParticipants]);
		}
		if (!objData.participants.includes(objData.addedBy) && objData.participants.length > 0) {
			objData.addedBy = objData.participants[0]
		}
		delete objData['dbAddress'];
		delete objData['documentID'];
		setLoader(true);
		window.firebase
			.firestore()
			.doc(`messages/${dbAddress}`)
			.set(objData, { merge: true })
			.then(() => {
				setLoader(false);
				toast.notify(props.newGroup ? 'Group chat created successfully' : 'Group chat updated successfully', {
					duration: 2000,
				});
				props.handleClose();
				if (props.newGroup) {
					let chatCloseIcon = document.querySelector('#chatApp-close-icon')
					if (chatCloseIcon) {
						chatCloseIcon.click()
					}
				}
			})
			.catch(error => {
				console.error(error);
				setLoader(false);
				toast.notify('Something went wrong', {
					duration: 2000,
				});
			});
	};

	const onSelectFile = (e, type, id, title) => {
		const img = e.target.files[0].size;

		if (!img) {
			return;
		}

		if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
			Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select image file.'), '', 'info',);
			return;
		}

		const imgsize = img / 1024 / 1024;
		if (imgsize > 10) {
			Swal.fire(CommonHelper.showLocale(props, 'Maximum file size exceeded.'), '', 'info');
			return;
		}

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			setCropShow({
				show: true,
				clsActive: 'overlay-modal active',
				src: reader.result,
				id: id,
				type: type,
				ext: file.name.substr(file.name.lastIndexOf('.') + 1),
				title: title,
			});
		};
		reader.readAsDataURL(file);
	};

	const handleCropClose = id => {
		if (id && document.getElementById(id)) document.getElementById(id).value = '';
		setCropShow({
			show: false,
			clsActive: '',
			src: null,
			id: '',
			type: '',
			ext: '',
			title: '',
		});
	};

	const handleCropSelect = cropShow => {
		const { src, id, type, ext } = cropShow;

		setGroup({
			...group,
			[id]: src,
		});

		saveImageToStorage(src, id, type, ext);

		setCropShow({
			show: false,
			clsActive: '',
			src: null,
			id: '',
			type: '',
			ext: '',
			title: '',
		});
	};

	const saveImageToStorage = (imageBase64, id, type, ext) => {
		let uid = moment()._d.getTime().toString() + '.' + ext;

		const storageRef = window.firebase
			.storage()
			.ref(`${props.dealersettings.client.id}/chat/${group.dbAddress}`)
			.putString(imageBase64, 'data_url');
		storageRef.on(
			'state_changed',
			snapshot => {
				const percentage =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				if (percentage > 0 && percentage < 99) {
					setImageLoader(true);
				} else {
					setImageLoader(false);
				}
			},
			error => {
				console.log(error);
			},
			() => {
				window.firebase
					.storage()
					.ref(`${props.dealersettings.client.id}/chat/${group.dbAddress}`)
					.child(uid)
					.getDownloadURL()
					.then(dataURL => {
						setGroup({
							...group,
							[id]: dataURL,
						});
					});
			},
		);
	};

	const addParticipant = ({ value }) => {
		let objData = { ...group }
		if (objData.participants.includes(value)) {
			return
		}
		objData.participants = [...objData.participants, value]
		setGroup(objData)
	}

	const removeParticipant = (id, isNewParticipant) => {
		if (!isNewParticipant) {
			let objData = { ...group }
			objData.participants = objData.participants.filter(participant => participant !== id)
			setGroup(objData)
		} else {
			let data = [...newParticipants]
			data = data.filter(participant => participant !== id)
			setNewParticipants(data)
		}
	}

	const deleteGroup = () => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to delete this group.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then((result) => {
			if (result.value) {
				window.firebase.firestore().doc(`messages/${group.dbAddress}`).delete()
					.then(() => {
						toast.notify(`Deleted group chat successfully`, {
							duration: 2000
						})
					})
					.catch(error => {
						console.error(error);
						toast.notify('Something went wrong', {
							duration: 2000
						})
					});
			}
		})
	}

	const handleReactMultiSelectChange = (selectedOptions) => {
		if (props.newGroup) {
			const value = [];
			!_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
				value.push(data.value)
			})
			setGroup({ ...group, participants: value });
		} else {
			let value = [];
			!_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
				value.push(data.value)
			})
			setNewParticipants(value);
		}
	}

	return _.isEmpty(group) ? (
		<></>
	) : (
		<>
			<Modal
				show={props.show}
				onHide={props.handleClose}
				backdrop='static'
				backdropClassName={props.show ? 'overlay-modal active' : ''}
				dialogClassName='modal-dialog-centered modal-groupchat-info'
				enforceFocus={false}
				style={{ zIndex: 1052 }}
			>
				<Modal.Header closeButton>
					<Modal.Title><Translate text={props.title} /></Modal.Title>
				</Modal.Header>
				<Modal.Body className='modal-body'>
					<div className='container-fluid'>
						<div className='row'>
							<div className='form-style w-100'>
								<div className='pop-layout form-style'>
									<div className='groupchat-profileimage'>
										{(props.newGroup || group.addedBy === localStorage.uid) && (
											<div className='groupchat-profileimage-upload'>
												<label htmlFor='groupImageURL'>
													<i className='ico icon-camera' htmlFor='groupImageURL'></i>
													<input
														className='fileInput'
														type='file'
														name='groupImageURL'
														id='groupImageURL'
														accept='image/*'
														style={{ display: 'none' }}
														onChange={e =>
															onSelectFile(e, 'users', 'groupImageURL', 'Group Image')
														}
													/>
												</label>
											</div>
										)}
										<img
											src={
												group.groupImageURL
													? group.groupImageURL
													: group.name.length > 0 ? CommonHelper.showNameAvatar(group.name) : _images.group
											}
											alt=''
											className=' rounded-circle'
										/>
									</div>
									{editName ? (
										<div className='form-group col-md-12 px-0'>
											<label>
												<Translate text={'Group chat name'} />
											</label>
											<InputText
												autoComplete='off'
												placeholder={'name'}
												className={`form-control ${errorFields['name']}`}
												name='name'
												onChange={handleOnChange}
												value={group.name}
											/>
										</div>
									) : (
										<div className='form-row groupchat-name-wraper'>
											<div className='form-group col-md-12'>
												<div className='groupchat-name'>
													{(props.newGroup || group.addedBy === localStorage.uid) && (
														<div className='groupchat-name-edit' onClick={() => setEditName(true)}>
															<a href='#' className='mini-button'>
																{' '}
																<i className='ico icon-edit'></i>
															</a>
														</div>
													)}
													{group.name}{' '}
												</div>
												<div className='groupcreated-date'>
													{' '}
													<Translate text={'created'} />:{' '}
													{moment(group.addedDate.toDate()).format(
														'DD/MM/YYYY hh:mm A',
													)}{' '}
                        &nbsp;
                      </div>
											</div>
										</div>
									)}
									<div className='groupchat-participant-wrap'>
										<div className='groupchat-participant-header'>
											<div className='float-left groupchat-participant-title'>
												{' '}
												<Translate text={'Group Members'} />
										</div>
											<div className='float-right groupchat-participant-count'>
												{' '}
												{_.uniq(group.participants).length} <Translate text={'participants'} />
										</div>
										</div>
										{(props.newGroup || group.addedBy === localStorage.uid) && (
											<div className='groupchat-user-list'>
												<p><Translate text={'Add participants to group'} /></p>
												<div className="row">
													<div className="col-md-12">
														<ReactMultiSelect
															// options={_.map(props.clientUsers.filter(item => item.documentID !== localStorage.uid && !group.participants.some(participants => item.documentID === participants)), function (c) {
															// 		return {
															// 				value: c.documentID,
															// 				doc: c,
															// 				searchlabel: c.name,
															// 				label: (
															// 						<div className='drop-image'>
															// 								<img src={CommonHelper.showUserAvatar(c.profileImage, c.name)} alt="" width="50" className="img-object-fit" />
															// 								{c.name}
															// 						</div>
															// 				),
															// 				active: _.isBoolean(c.active) ? c.active : true,
															// 		};
															// })}
															options={props.newGroup ? _.map(props.clientUsers, function (c) {
																return {
																	value: c.documentID,
																	doc: c,
																	searchlabel: c.name,
																	label: (
																		<div className='drop-image'>
																			<img src={CommonHelper.showUserAvatar(c.profileImage, c.name)} alt="" width="50" className="img-object-fit" />
																			{c.name}
																		</div>
																	),
																	active: _.isBoolean(c.active) ? c.active : true,
																};
															}) : _.map(props.clientUsers.filter(item => !group.participants.some(participant => item.documentID === participant)), function (c) {
																return {
																	value: c.documentID,
																	doc: c,
																	searchlabel: c.name,
																	label: (
																		<div className='drop-image'>
																			<img src={CommonHelper.showUserAvatar(c.profileImage, c.name)} alt="" width="50" className="img-object-fit" />
																			{c.name}
																		</div>
																	),
																	active: _.isBoolean(c.active) ? c.active : true,
																};
															})}
															name={'participants'}
															placeholder={'select user'}
															onChange={handleReactMultiSelectChange}
															value={props.newGroup ? group.participants : newParticipants}
															classNamePrefix={`basic-select`}
															isMulti={true}
															isSearchable={true}
														></ReactMultiSelect>
													</div>
												</div>
											</div>
										)}
										<div className='groupchat-user-list-items'>
											<ul>
												{!props.newGroup && props.clientUsers.filter(clientUser => newParticipants.includes(clientUser.documentID)).map((rec, index) => {
													return (<li className='groupchat-user-list-box' key={rec.documentID}>
														<div className='groupchat-tag'>
															{rec.id === group.addedBy && (
																<div className='badge badge-pill badge-online ml-1'>
																	{' '}
																	<Translate text={'admin'} />
																</div>
															)}
														</div>
														{/* <div className='groupchat-user-list-items-more dropleft'>
                          {' '}
                          <a href='#' className='dropdown dropdown-toggle'>
                            <i className='ico icon-more'></i>
                          </a>{' '}
                        </div> */}
														{(props.newGroup || group.addedBy === localStorage.uid) && (
															<Dropdown className="groupchat-user-list-items-more" drop={'left'}>
																<Dropdown.Toggle as={CustomToggle} className="dropdown">
																	<i className="ico icon-more"></i>
																</Dropdown.Toggle>
																<Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
																	<Dropdown.Item eventKey="remove" onClick={(e) => { removeParticipant(rec.id, true) }}><i className="ico icon-delete"></i> <Translate text={'Remove'} /> </Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														)}
														<div className='groupchat-user-list-items-image'>
															{' '}
															<img
																src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)}
																alt=''
																className='rounded-circle img-object-fit'
															/>{' '}
														</div>
														<div className='groupchat-user-list-items-info'>
															<h4>{rec.name}</h4>
															<h5>{rec.email ? rec.email : rec.phone}</h5>
														</div>
													</li>)
												})

												}
												{props.clientUsers.filter(clientUser => group.participants.includes(clientUser.documentID)).map((rec, index) => {
													return (<li className='groupchat-user-list-box' key={rec.documentID}>
														<div className='groupchat-tag'>
															{rec.id === group.addedBy && (
																<div className='badge badge-pill badge-online ml-1'>
																	{' '}
																	<Translate text={'admin'} />
																</div>
															)}
														</div>
														{/* <div className='groupchat-user-list-items-more dropleft'>
                          {' '}
                          <a href='#' className='dropdown dropdown-toggle'>
                            <i className='ico icon-more'></i>
                          </a>{' '}
                        </div> */}
														{(props.newGroup || group.addedBy === localStorage.uid) && (
															<Dropdown className="groupchat-user-list-items-more" drop={'left'}>
																<Dropdown.Toggle as={CustomToggle} className="dropdown">
																	<i className="ico icon-more"></i>
																</Dropdown.Toggle>
																<Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
																	<Dropdown.Item eventKey="remove" onClick={(e) => { removeParticipant(rec.id) }}><i className="ico icon-delete"></i> <Translate text={'Remove'} /> </Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														)}
														<div className='groupchat-user-list-items-image'>
															{' '}
															<img
																src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)}
																alt=''
																className='rounded-circle img-object-fit'
															/>{' '}
														</div>
														<div className='groupchat-user-list-items-info'>
															<h4>{rec.name}</h4>
															<h5>{rec.email ? rec.email : rec.phone}</h5>
														</div>
													</li>)
												})

												}

											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className='modal-footer'>
					{(props.newGroup || group.addedBy === localStorage.uid) ? (
						<>
							{!props.newGroup && (
								<button type="button" className="btn btn-red-outline float-left" onClick={deleteGroup}><i className="ico icon-delete marker-delete" ></i>Delete Group</button>
							)}
							<button
								type='button'
								className='btn btn-primary float-right ml-2'
								onClick={e => saveGroup(e)}
							>
								{loader ? (
									<span
										className='spinner-border spinner-button mr-1'
										role='status'
										aria-hidden='true'
									></span>
								) : (
									<></>
								)}
								<Translate text={'save'} />
							</button>
							<button
								type='button'
								className='btn btn-default float-right'
								onClick={() => props.handleClose()}
							>
								<Translate text={'cancel'} />
							</button>
						</>
					) : (
						<button
							type='button'
							className='btn btn-default float-right'
							onClick={() => props.handleClose()}
						>
							<Translate text={'close'} />
						</button>
					)}
				</Modal.Footer>
			</Modal>
			<ImageCropHook
				cropShow={cropShow}
				handleClose={handleCropClose}
				handleSelect={handleCropSelect}
				fromChat={true}
			></ImageCropHook>
		</>
	);
};

export default GroupInfo;
