import _ from 'lodash'

const TriggersLogListReducer = (state, action) => {
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_TRIGGER_LOG": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_TRIGGER_LOG": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_TRIGGER_LOG": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_TRIGGER_LOG": {
            return [...state.filter(item => item.documentID !== action.data.documentID), action.data]
        }
        default:
            return state;
    }
};


export default TriggersLogListReducer;