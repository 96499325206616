import React, { useState } from 'react';
import _ from 'lodash'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { activitylogTypes } from '../pipeline/viewModel';
const FilterType = (props) => {

    const { dealersettings } = props;
    let setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

    let activityTypes = [];
    setttings && setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
        activityTypes.push({
            ico: `ico icon-${doc.icon}`,
            name: doc.name,
            type: doc.value,
            value: doc.value
        });
    });

    if (_.isEmpty(activityTypes))
        activityTypes = activitylogTypes;

    return (
        <div className="activity-buttongroup">
            <a href="#" title="All" className={`${props.activetab.some(e => e === 'all') ? 'activity-active' : ''}`} onClick={(e) => { e.preventDefault(); props.handlechangefilter('all') }}> <Translate text={'all'} /></a>

            {activityTypes && activityTypes.map((activity, index) => {
                return (
                    <a key={index} href="#" title={activity.name}
                        className={`${props.activetab.some(e => e === activity.value) ? 'activity-active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            props.activetab.some(e => e === activity.value) ? props.handleuncheckfilter(activity.value) : props.handlechangefilter(activity.value)
                        }}>
                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, activity.ico, true, activity.icoVehicle)}></i>
                    </a>
                );
            })}

            {/* <a href="#" title="Appointment" className={`${props.activetab.some(e => e === 'appointment') ? 'activity-active' : ''}`} onClick={(e) => { e.preventDefault(); props.activetab.some(e => e === 'appointment') ? props.handleuncheckfilter('appointment') : props.handlechangefilter('appointment') }}> <i className="ico icon-activity-appointment"></i></a>
            <a href="#" title="Call" className={`${props.activetab.some(e => e === 'call') ? 'activity-active' : ''}`} onClick={(e) => { e.preventDefault(); props.activetab.some(e => e === 'call') ? props.handleuncheckfilter('call') : props.handlechangefilter('call') }}><i className="ico icon-call"></i></a>
            <a href="#" title="Email" className={`${props.activetab.some(e => e === 'email') ? 'activity-active' : ''}`} onClick={(e) => { e.preventDefault(); props.activetab.some(e => e === 'email') ? props.handleuncheckfilter('email') : props.handlechangefilter('email') }}><i className="ico icon-Mail"></i></a>
            <a href="#" title="Testdrive" className={`${props.activetab.some(e => e === 'testdrive') ? 'activity-active' : ''}`} onClick={(e) => { e.preventDefault(); props.activetab.some(e => e === 'testdrive') ? props.handleuncheckfilter('testdrive') : props.handlechangefilter('testdrive') }}><i className="ico icon-activity-testdrive"></i></a>
            <a href="#" title="Delivery" className={`${props.activetab.some(e => e === 'delivery') ? 'activity-active' : ''}`} onClick={(e) => { e.preventDefault(); props.activetab.some(e => e === 'delivery') ? props.handleuncheckfilter('delivery') : props.handlechangefilter('delivery') }}><i className="ico icon-activity-delivery"></i></a>
            <a href="#" title="Task" className={`${props.activetab.some(e => e === 'task') ? 'activity-active' : ''}`} onClick={(e) => { e.preventDefault(); props.activetab.some(e => e === 'task') ? props.handleuncheckfilter('task') : props.handlechangefilter('task') }}><i className="ico icon-activity"></i></a>
         */}
        </div>
    )
}

export default FilterType