export const customerLogFields = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        default: true,
        subText: 'contactPhone',
        subTextTitle: 'Contact Phone'
    },
    {
        name: 'Enquiry Number',
        value: 'enqNumber',
        flex: 1,
        default: true,
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleSaletype',
        subTextTitle: 'Sale Type'
    },
    {
        name: 'Notes',
        value: 'notes',
        flex: 0,
        default: true,
        width : 400,
        limit : 190
    },
    {
        name: 'Created On',
        value: 'activityAddedDate',
        flex: 1,
        default: true,
        subText: 'activityOwner',
        subTextTitle: 'Owner'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]