import React, { useState, useEffect, useMemo } from 'react';
import AddCampaign from './add';
import { PopUpModal } from '../../../components';
import _ from 'lodash';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData } from 'rxfire/firestore';
import { TemplateContext } from '../../settings/workflow/templateContext';
import { Tab, Nav } from 'react-bootstrap';
import CampaignsList from './list';
import axios from 'axios';
import moment from 'moment';
import toast from 'toasted-notes'
import CommonHelper from '../../../services/common';

const MailupCampaigns = ({ refresh_token, client_id, client_secret, dealersettings, clientUsers, updateDealerSettings, history }) => {
	const [showAddCampaign, setShowAddCampaign] = useState({
		show: false,
		title: '',
		campaignID: ''
	});
	const [campaignsLoader, setCampaignsLoader] = useState(true)
	const [oemClients, setOEMClients] = useState([]);

	const apiURL = process.env.NODE_ENV === 'development'
		? 'http://localhost:5001/ams-pro-qa/us-central1/'
		: dealersettings.apiURL

	const [activeTab, setActiveTab] = useState('all');
	const [campaigns, setCampaigns] = useState([]);
	const [emailtemplates, setEmailTemplates] = useState([]);
	const [loader, setLoader] = useState(true);

	const emailTemplatesMemo = useMemo(() => emailtemplates, [emailtemplates]);
	// const groupsMemo = useMemo(() => clientGroups, [clientGroups])
	const clientsMemo = useMemo(() => oemClients, [oemClients])

	const handleModelClose = () => {
		setShowAddCampaign({
			...showAddCampaign,
			show: false,
			title: '',
			campaignID: ''
		});
	};

	const onCreateCampaign = () => {
		setShowAddCampaign({
			...showAddCampaign,
			show: true,
			title: 'Create Campaign'
		})
	}

	useEffect(() => {
		const oemclientSnapshot = window.firebase.firestore().collection('clients')
			.where('settingsID', '==', dealersettings.client.settingsID)
			.onSnapshot(querySnapshot => {
				let _clients = [];
				querySnapshot.forEach((rec) => {
					_clients.push({ ...rec.data(), 'documentID': rec.id })
				})
				setOEMClients(_clients)
			})
		return () => {
			oemclientSnapshot && oemclientSnapshot();
		}
	}, [])

	useEffect(() => {
		if (_.isEmpty(dealersettings)) {
			return;
		}
		if (!_.isEmpty(emailtemplates)) {
			return;
		}

		let privateTemplatesRef = window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/templates`).
			where('visibility', '==', 'private')
			.where('isDeleted', '==', false)
		privateTemplatesRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(privateTemplatesRef, dealersettings, null, true);

		var alltemplates = combineLatest(
			collectionData(privateTemplatesRef),
			collectionData(window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/templates`)
				.where('visibility', '==', 'shared')
				.where('isDeleted', '==', false))
		).pipe(
			map((templates) => {
				var [dealer, shared] = templates;
				return [
					[...dealer],
					[...shared]
				];
			}))
			.subscribe(allDocs => {
				let list = [];
				allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
				let listVM = [];
				list.forEach(rec => {
					if (!listVM.some(e => e.documentID === rec.documentID))
						listVM.push(rec)
				})
				setEmailTemplates(listVM.filter(item => item.type === 'emailTemplate' || item.type === 'email'))
			});
		return () => {
			alltemplates && alltemplates.unsubscribe()
		}
	}, []);

	useEffect(() => {
		const campaignSnapshot = window.firebase.firestore().collection(`campaignRecipients`)
			.where('clientID', '==', dealersettings.client.id)
			.where('type', '==', 'mailup')
			.onSnapshot(querySnapshot => {
				let _campaign = [];
				querySnapshot.forEach((rec) => {
					_campaign.push(convertCampaignVM({
						...rec.data(),
						'documentID': rec.id,
						'id': rec.id,
						'status': rec.data()?.status === 'sent' ? 'sent' : rec.data()?.status === 'pending' ? 'sending' : rec.data()?.status === 'failed' ? 'failed' : rec.data().status
					}))
				})
				setCampaigns(_.orderBy(_campaign, ['addedDate'], ['desc']))
				setCampaignsLoader(false)
			})
		return () => {
			campaignSnapshot && campaignSnapshot();
		}
	}, []);

	const convertCampaignVM = doc => {
		let objToReturn = { ...doc };

		objToReturn.documentID = doc.id;
		objToReturn.nameText = doc.title ? doc.title : 'Untitled Message';
		objToReturn.name = (
			<div className='campaign-subject'>
				{doc.title ? doc.title : 'Untitled Campaign'}
			</div>
		);
		objToReturn.statusToShow = (
			<div
				className={`badge badge-pill badge-${doc.status === 'sent'
					? 'available'
					: doc.status === 'sending'
						? 'pending'
						: doc.status === 'save'
							? 'draft'
							: doc.status
					}-fill `}
			>
				{doc.status === 'save' ? 'draft' : doc.status}
			</div>
		);
		objToReturn.stats = (
			<div className='campaign-stat-box-wraper'>
				<div
					className={`campaign-stat-box ${_.isEmpty(doc.report_summary) ? 'no-stat' : ''
						}`}
				>
					<p className='campaign-stat-count w-100'>
						{!_.isEmpty(doc.report_summary) ? doc.report_summary.opens : '0'}
					</p>
					<p className='campaign-stat-title w-100'>Opens</p>
				</div>
				<div
					className={`campaign-stat-box ${_.isEmpty(doc.report_summary) ? 'no-stat' : ''
						}`} s
				>
					<p className='campaign-stat-count w-100'>
						{!_.isEmpty(doc.report_summary) ? doc.report_summary.clicks : '0'}
					</p>
					<p className='campaign-stat-title w-100'>Clicks</p>
				</div>
			</div>
		);
		objToReturn.createdOn = (
			<div className='user-list-items-info '>
				<h4>{moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A')}</h4>
				{/* <h5>Williams David</h5> */}
			</div>
		);
		objToReturn.date = moment.unix(doc.addedDate.seconds).toDate();

		return objToReturn;
	};

	return (
		<TemplateContext.Provider
			value={{
				emailTemplateList: emailTemplatesMemo,
				groups: [],
				clients: clientsMemo,
				loading: loader,
			}}
		>
			<div className='middle-wrapper'>
				<div className='filter-panel'>
					<div className='float-left campaign-head'>
						<h3>Email Campaigns</h3>
					</div>
					<div className='float-right'>
						<div className='filter-add-button'>
							<button
								type='button'
								className='btn btn-primary'
								onClick={onCreateCampaign}
							>
								{' '}
								<i className='ico icon-add'></i> Create Campaign
							</button>
						</div>
					</div>
				</div>
				<div className='campaign-panel'>
					<div className='campaign-main-container'>
						<div className='campaign-wrapper'>
							<Tab.Container
								defaultActiveKey={activeTab}
								mountOnEnter={true}
								onSelect={k => setActiveTab(k)}
							>
								<div className='campaign-left-panel'>
									<div className='campaign-left-menu'>
										<h4>Campaigns</h4>
										<div className='campaign-submenu'>
											<Nav as='ul' bsPrefix='ul-nav'>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='all' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-all'> </i>{' '}
														<span>All</span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='ongoing' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-ongoing'> </i>{' '}
														<span>Ongoing</span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='completed' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-completed'> </i>{' '}
														<span>Completed </span>{' '}
													</Nav.Link>
												</Nav.Item>
											</Nav>
										</div>
									</div>
								</div>
								<CampaignsList
									activeTab={activeTab}
									campaigns={campaigns}
									setCampaigns={setCampaigns}
									setShowAddCampaign={setShowAddCampaign}
									showAddCampaign={showAddCampaign}
									apiUrl={apiURL}
									onCreateCampaign={onCreateCampaign}
									loader={campaignsLoader}
									refresh_token={refresh_token}
									client_id={client_id}
									client_secret={client_secret}
								/>
							</Tab.Container>
						</div>
					</div>
				</div>
				<PopUpModal show={showAddCampaign.show}>
					<AddCampaign
						show={showAddCampaign.show}
						handleClose={handleModelClose}
						title={showAddCampaign.title}
						campaignID={showAddCampaign.campaignID}
						dealersettings={dealersettings}
						clientUsers={clientUsers}
						updateDealerSettings={updateDealerSettings}
						history={history}
						apiUrl={apiURL}
						isScheduleAllowed={true}
						refresh_token={refresh_token}
						client_id={client_id}
						client_secret={client_secret}
					/>
				</PopUpModal>
			</div>
		</TemplateContext.Provider>
	);
};

export default MailupCampaigns;
