
export const objCompany = {
    documentID: null,
    clientID: null,
    projectId: null,
    groupID: null,
    regionID: null,
    oemID: null,
    name: '',
    phoneCode: '',
    phone: '',
    email: '',
    phones: [],
    emails: [],
    website: '',
    type: '',
    address: '',
    state: '',
    areaCode: '',
    country: '',
    companyAbnAcn: '',
    companyACN: '',
    companyRMS: '',
    associatedCompany: {},
    companyOwner: '',
    owner: '',
    //keywords: [],
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};

export const companyBasicVM = {
    documentID: null,
    name: '',
    phoneCode: '',
    phone: '',
    email: '',
    owner: ''
};

export const mandatoryFields = [
    'name'
];

export const allcompanyFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Name',
        value: 'name',
        flex: 2,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'displayPhone'
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1,
        default: true
    },
    {
        name: 'Website',
        value: 'website',
        flex: 1,
        default: true
    },
    {
        name: 'Company Type',
        value: 'type',
        flex: 1,
        default: true
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2
    },
    {
        name: 'Company ABN',
        value: 'companyAbnAcn',
        flex: 1
    },
    {
        name: 'Company ACN',
        value: 'companyACN',
        flex: 1
    },
    {
        name: 'Company RMS',
        value: 'companyRMS',
        flex: 1
    },
    {
        name: 'Associated Company',
        value: 'associatedCompany',
        flex: 1
    },
    {
        name: 'Company Owner',
        value: 'companyOwner',
        flex: 1
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

