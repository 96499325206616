import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import Translate from '../../../../constants/translate';

const StagePopover = ({stage, enquiryStatus, allPipelines}) => {
  const [state, setState] = useState([])

    var statuses = [
      'InProgress',
      'Pending',
      'Completed',
      'Won',
      'Lost'
    ]

    var statusOptions = []
  
    statuses.forEach(a => {
      statusOptions.push({active: true, value: a, label: <div className={`badge badge-pill status-${a.toLowerCase()} ml-2`}> {a.split(/(?=[A-Z])/).join(' ').toUpperCase()} </div>})
    })
    statusOptions.push( { active: true, isModule: true, value: 'finalPrice', label: <div className={`badge badge-pill badge-status-open`}>Final Price</div> })
    //statusOptions.push( { active: true, isModule: true, value: 'valuerPrice', label: <div className={`badge badge-pill badge-status-open`}>Valuer Price</div> })
   
  useEffect(() => {
    let data = {
      'InProgress': [],
      'Pending': [],
      'Completed': [],
      'Won': [],
      'Lost': [],
      'finalPrice': [],
      'valuerPrice': []
    }
    let allStatusSettings = []
    allPipelines.forEach(rec => {
      if (!_.isEmpty(rec.statusSettings)) {
        let temp = rec.statusSettings.map(item => {
          return {
            ...item,
            pipelineName: rec.name
          }
        })
        allStatusSettings = [...allStatusSettings, ...temp]
      }
    })
    allStatusSettings = allStatusSettings.filter(rec => rec.stage === stage.value)
    allStatusSettings.forEach(item => {
        data[item.status] = [...data[item.status], item.pipelineName]
    })
    data = Object.entries(data).filter(a => a[1].length)
    setState(data)
  }, [])

  return !_.isEmpty(state) ? (
      <div className="target-popover-list">
          {state.map((item, index) => (
            <ul key={item[0]} style={index > 0 ? {marginTop: '64px'} : {}}>
                <li> <Translate text={'status'} /> : {statusOptions.filter(status => status.value === item[0])[0].label} </li>
                <li> <Translate text={'From Pipeline'} />:
                    <div className="target-sublist">
                      <div className="Vehicle-availability">
                        {item[1].map(rec => (
                          <div>
                            <span>{rec}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                </li>
            </ul>
          ))}
      </div>
  ) : (<></>)
}

export default StagePopover