import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import { InputText, InputMultipleText } from '../../../../components';
import { visibilities, templateVM } from './viewModel'
import Translate from '../../../../constants/translate';
import { levelOptions } from '../fieldSettings/viewModel'

const SendTestEmail = (props) => {

    const [template, setTemplate] = useState(props.template);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [params, setParams] = useState({});

    useEffect(() => {
        if (_.isEmpty(params)) {
            setParams({
                body: template.bodyHTML,
                to: props.dealersettings.email,
                clientID: props.dealersettings.client.id
            })
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;


        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setParams({
                ...params,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setParams({
                ...params,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }



    const handleOnChangeMultipleText = (val, name) => {
        setParams({
            ...params,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const removeEmail = (index, name) => {
        let arr = params[name] ? params[name].split(',') : []
        arr.splice(index, 1)
        setParams({
            ...params,
            [name]: arr.join(',')
        })
    }

    const handleSendEmail = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(params['subject'])) {
            formIsValid = false;
            errors['subject'] = errorClass;
        }

        if (_.isEmpty(params['to'])) {
            formIsValid = false;
            errors['to'] = errorClass;
        }

        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        try {
            const sendEmail = window.firebase.functions().httpsCallable('nylas-sendTestEmail');
            sendEmail(params).then((_data) => {
                setLoader(false)
                if (_data.data.success) {
                    toast.notify('Email sent successfully.', {
                        duration: 2000,
                    });
                    props.handleClose();
                }
                else {
                    console.log(_data.data.message);
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            console.log(error);
            Swal.fire('Something went wrong', '', 'error')
        }
    }




    return (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-groupchat-info">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={props.title} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">

                    <div className="form-style w-100">

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label >Subject</label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'subject'}
                                    className={`form-control ${errorFields["subject"]}`}
                                    name="subject"
                                    onChange={handleOnChange}
                                    value={params.subject}
                                />

                            </div>

                            <div className="form-group col-md-12">
                                <label>To Emails</label>
                                <InputMultipleText
                                    placeholder={`to emails`}
                                    className={`form-control react-multi-tag ${errorFields["to"]}`}
                                    name="to"
                                    onChange={(emails) => { handleOnChangeMultipleText(emails, 'to') }}
                                    value={!_.isEmpty(params.to) ? params.to.split(',') : []}
                                    validateEmail={true}
                                    getLabel={(email, index) => {
                                        return <div data-tag key={index}>
                                            {email}
                                            <span data-tag-handle onClick={() => removeEmail(index, 'to')}>
                                                ×
                                                                    </span>
                                        </div>
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={(e) => {
                handleSendEmail()
            }
            }>
                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                <Translate text={'send'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>);
}

export default SendTestEmail;