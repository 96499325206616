export const enqiuryOptions = [
    {
        name: 'Make',
        value: 'make',
        type: 'select'
    },
    {
        name: 'Model',
        value: 'model',
        type: 'select'
    },
    {
        name: 'Model Description',
        value: 'modelDescription',
        type: 'text'
    },
    {
        name: 'Body Type',
        value: 'type',
        type: 'select'
    },
    {
        name: 'Group',
        value: 'group',
        type: 'select'
    },
    {
        name: 'Year',
        value: 'year',
        type: 'select'
    },
    {
        name: 'VIN',
        value: 'vinNo',
        type: 'text'
    },
    {
        name: 'Reg No',
        value: 'regNo',
        type: 'text'
    },
    {
        name: 'Ext. Color',
        value: 'extColor',
        type: 'text'
    },
    {
        name: 'Int. Color',
        value: 'intColor',
        type: 'text'
    },
    {
        name: 'Mileage',
        value: 'mileage',
        type: 'text'
    },
    {
        name: 'Fleet Owner',
        value: 'fleetOwner',
        type: 'select'
    },
    {
        name: 'Status',
        value: 'status',
        type: 'select'
    },
    {
        name: 'De-Fleet Date',
        value: 'defleetDate',
        type: 'date'
    },
    {
        name: 'Country',
        value: 'country',
        type: 'select'
    },
    {
        name: 'Region',
        value: 'regionID',
        type: 'select'
    },
    {
        name: 'Subregion',
        value: 'subregionID',
        type: 'select'
    },
    {
        name: 'Group',
        value: 'groupID',
        type: 'select'
    }
]
