/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
/** PROVIDERS */
//import { LayoutConsumer } from '../layout/provider';
/** VIEW-MODELS */
import { allStockFieldsVM, objStock } from './viewModel';
/** COMPONENTS */
import CommonHelper from '../../../services/common';
import { ContentOverlay, PopUpModal, TableView } from '../../../components';
import FilterPanel from './filter'

//import { default as _images } from '../../images';
import AddStock from '../stock/add'
import StockHistory from './history'
import Files from './files'
import StockListReducer from './stockListReducer'
import SelectDealer from './selectDealer';

const StockList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [stocks, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [sortName, setSortName] = useState('modifiedDate')
    const [sortOrder, setSortOrder] = useState('desc')
    const [hasMore, setHasMoreData] = useState([])
    const [oemStockFields, setStockFields] = useState(props.dealersettings.oemStockFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [stockLoader, setStockLoader] = useState(true)
    const pageLimit = 50

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0)
    const [selectedIds, setSelectedIds] = useState({})
    const [csvHeader, setHeader] = useState([])
    const [searchText, setSearchText] = useState(localStorage.oemStockSearchText ? localStorage.oemStockSearchText : '')
    const [stockCount, setStockCount] = useState(0)
    const [modalAllocateShow, setModalAllocateShow] = useState(false)
    const [showFilesModel, setShowFilesModel] = useState(false);
    const [selectedStock, setSelectedStock] = useState(false);

    useEffect(() => {
        let headerFields = oemStockFields;
        let allHeaderFields = allStockFieldsVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [oemStockFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubOEMStocks && window.unSubOEMStocks();
        }
    }, [])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'stocks')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.pipelineFilter', isFilterApplied, localStorage.pipelineFilter)
        if (localStorage.oemStockFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.oemStockFilter])

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }

        if (isFilterApplied || localStorage.oemStockFilter) {
            let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
            let _stockFilter = JSON.parse(localStorage.oemStockFilter);
            let _filter = Object.assign({}, _stockFilter.value);
            let _sortOrder = sortName + ' ' + sortOrder;
            let _isWhere = false;
            _filter.stockType = 'oem'
            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
                delete _filter['date'];
            }
            // //console.log('generic-searchData', isFilterApplied, pageNum, _filter, localStorage.stockFilter)
            // const searchStock = window.firebase.functions().httpsCallable('generic-searchData');
            // searchStock({
            //     "type": "searchStock",
            //     "clientID": props.dealersettings.client.settingsID,
            //     "filters": JSON.stringify(_filter),
            //     "sortOrder": "modifiedDate desc",
            //     "pageNum": pageNum,
            //     "pageLimit": pageLimit
            // }).then((response) => {
            //     //console.log('generic-searchStock', response);
            //     if (response.data.success && localStorage.oemStockFilter) {
            //         onCollectionStocks(response.data);
            //     }
            //     else {
            //         setDataload(false)
            //         setStockLoader(false)
            //         setPaging(false)
            //     }
            // });

            // window.unSubOEMStocks && window.unSubOEMStocks();

            let searchStock = window.firebase.firestore().collection(`stock`)
                .where('isDeleted', '==', false)
                .where('settingsID', '==', props.dealersettings.client.settingsID);
            // debugger
            // if ((_filter.dateType === 'addedDate') && _filter.startDate && _filter.endDate) {
            //     _isWhere = true
            //     searchStock = searchStock
            //         .where(_filter.dateType, '>=', window.firebase.firestore.Timestamp.fromDate(moment(_filter.startDate).startOf('day')._d))
            //         .where(_filter.dateType, '<=', window.firebase.firestore.Timestamp.fromDate(moment(_filter.endDate).endOf('day')._d))
            // }
            // else {
            if (_filter.clientID) {
                _isWhere = true
                searchStock = searchStock
                    .where('clientID', '==', _filter.clientID)
            }
            if (_filter.keywords) {
                _isWhere = true
                searchStock = searchStock
                    .where('keywords', 'array-contains', _filter.keywords.toLowerCase())
            }
            if (_filter.status) {
                _isWhere = true
                if (_filter.status === 'allocated') {
                    searchStock = searchStock
                        .where('isAllocated', '==', true)
                }
                else {
                    searchStock = searchStock
                        .where('status', '==', _filter.status)
                        .where('isAllocated', '==', false)
                }
            }
            if (_filter.isAllocated === true) {
                _isWhere = true
                searchStock = searchStock
                    .where('isAllocated', '==', true)
            }
            if (_filter.isAllocated === false) {
                _isWhere = true
                searchStock = searchStock
                    .where('isAllocated', '==', false)
                    .where('status', '==', 'available')
            }
            if (_filter.saleType) {
                _isWhere = true
                searchStock = searchStock
                    .where('saleType', '==', _filter.saleType)
            }
            if (_filter.make) {
                _isWhere = true
                searchStock = searchStock
                    .where('make', '==', _filter.make)
            }
            if (_filter.model) {
                _isWhere = true
                searchStock = searchStock
                    .where('model', '==', _filter.model)
            }
            if (_filter.year) {
                _isWhere = true
                searchStock = searchStock
                    .where('year', '==', _filter.year)
            }
            if (_filter.isSale) {
                _isWhere = true
                searchStock = searchStock
                    .where('isSale', '==', _filter.isSale)
            }
            if (_filter.isTestDrive) {
                _isWhere = true
                searchStock = searchStock
                    .where('isTestDrive', '==', _filter.isTestDrive)
            }
            if (_filter.isLoan) {
                _isWhere = true
                searchStock = searchStock
                    .where('isLoan', '==', _filter.isLoan)
            }
            if (_filter.owner) {
                _isWhere = true
                searchStock = searchStock
                    .where('addedBy', '==', _filter.owner)
            }
            // }

            if (hasMore.length > 0) {
                searchStock = searchStock
                    .startAfter(hasMore[0])
                    .limit(_isWhere ? pageLimit : 500)
            }
            else {
                searchStock = searchStock
                    .limit(_isWhere ? pageLimit : 500)
            }

            searchStock
                .get()
                .then(docs => {
                    onQueryCollectionStocks(docs, _filter);
                })
        }
        else {
            let collectionDataRef = window.firebase.firestore().collection('stock')
                // .where('clientID', '==', props.dealersettings.client.id)
                .where('settingsID', '==', props.dealersettings.client.settingsID)
                .where('isDeleted', '==', false)

            if (searchText && searchText.trim()) {
                collectionDataRef = collectionDataRef
                    .where('keywords', 'array-contains', searchText.trim().toLowerCase())
            }

            collectionDataRef = collectionDataRef
                .orderBy('modifiedDate', 'desc')

            // collectionDataRef = collectionDataRef
            //     .orderBy(sortName, sortOrder)

            if (hasMore.length > 0) {
                collectionDataRef = collectionDataRef
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                collectionDataRef = collectionDataRef
                    .limit(pageLimit)
            }

            window.unSubOEMStocks = collectionDataRef
                .onSnapshot(onCollectionUpdate);
        }

        // return () => {
        //     window.unSubOEMStocks();
        // }
    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "_ADD";
                }
                else if (change.type === 'modified') {
                    actionType = "_UPDATE"
                }
                else if (change.type === 'removed') {
                    actionType = "_REMOVE"
                }
            }
        })
        const stocks = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                stocks.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }

        if (isPaging) {
            dispatch({
                type: "_APPEND",
                data: stocks,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "_SUCCESS",
                data: actionType ? snapshotDoc : stocks,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }

        setDataload(false)
        setStockLoader(false)
        setPaging(false)
    }

    const onQueryCollectionStocks = (querySnapshot, _filter) => {

        let _stocks = [];

        if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
            setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.docs.forEach(function (doc) {
            var dataVM = dataMappingVM({ ...doc.data(), 'documentID': doc.id });
            _stocks.push(dataVM)
        });

        _stocks = _stocks.filter(item => (
            ((_filter.dateType && _filter.startDate && _filter.endDate && item[`${_filter.dateType}Value`])
                ? (item[`${_filter.dateType}Value`].seconds >= moment(_filter.startDate).startOf('day').unix() && item[`${_filter.dateType}Value`].seconds <= moment(_filter.endDate).endOf('day').unix()) : true)
            && (_filter.keywords ? _.indexOf(item.keywords, _filter.keywords.toLowerCase()) >= 0 : true)
            && (_filter.owner ? item.addedByValue === _filter.owner : true)
            && (_filter.status === 'available' ? (item.statusValue === _filter.status && item.isAllocated === false) : true)
            && (_filter.status === 'allocated' ? item.isAllocated === true : true)
            && (_filter.saleType ? item.saleTypeValue === _filter.saleType : true)
            && (_filter.location ? item.locationValue === _filter.location : true)
            && (_filter.make ? item.make.toLowerCase().includes(_filter.make.toLowerCase()) : true)
            && (_filter.model ? item.model.toLowerCase().includes(_filter.model.toLowerCase()) : true)
            && (_filter.year ? item.year === _filter.year : true)
            && (_filter.isSale ? item.isSale === _filter.isSale : true)
            && (_filter.isTestDrive ? item.isTestDrive === _filter.isTestDrive : true)
            && (_filter.isLoan ? item.isLoan === _filter.isLoan : true)
        ))
        dispatch({
            type: isPaging ? "_APPEND" : "_SUCCESS",
            data: _stocks,
            sortName: '',
            sortOrder: ''
        });

        setStockLoader(false)
        setDataload(false)
        setPaging(false)
    }

    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    const dataMappingVM = (doc) => {
        let dealersettings = props.dealersettings;

        doc.regDate = doc.regDate && doc.regDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regDate._seconds)._d) : doc.regDate;
        doc.warrantyExp = doc.warrantyExp && doc.warrantyExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyExp._seconds)._d) : doc.warrantyExp;
        doc.warrantyStartDate = doc.warrantyStartDate && doc.warrantyStartDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyStartDate._seconds)._d) : doc.warrantyStartDate;
        doc.stockInDate = doc.stockInDate && doc.stockInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stockInDate._seconds)._d) : doc.stockInDate;
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        if (doc.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings) {
            doc.location = CommonHelper.getNameByValue(dealersettings.group.clients[doc.clientID].settings.docLocation, doc.location, '');
            doc.saleType = CommonHelper.getNameByValue(dealersettings.group.clients[doc.clientID].settings.salesType, doc.saleType, '');
        }
        else if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            doc.location = CommonHelper.getNameByValue(dealersettings.client.settings.docLocation, doc.location, '');
            doc.saleType = CommonHelper.getNameByValue(dealersettings.client.settings.salesType, doc.saleType, '');
        }

        const objStockData = Object.assign({}, doc);
        const stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objStockData)) {
            stock[key] = value;
        }

        stock.regDateValue = doc.regDate
        stock.warrantyExpValue = doc.warrantyExp
        stock.warrantyStartDateValue = doc.warrantyStartDate
        stock.stockInDateValue = doc.stockInDate
        stock.addedDateValue = doc.addedDate
        stock.modifiedDateValue = doc.modifiedDate
        stock.addedByValue = doc.addedBy
        stock.statusValue = doc.status
        stock.saleTypeValue = doc.saleType
        stock.locationValue = doc.location
        stock.createdOn = moment.unix(stock.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        stock.updatedOn = moment.unix(stock.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

        if (!_.isEmpty(stock.regDate))
            stock.regDate = moment.unix(stock.regDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyExp))
            stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyStartDate))
            stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.buildDate))
            stock.buildDate = moment(stock.buildDate, 'YYYY-MM-DD').format('MMM YYYY');

        stock.stockInDate = ((!_.isEmpty(stock.stockInDate))
            ?
            handleDiffDays(stock.stockInDate)
            :
            '');
        stock.statusName = stock.status ? stock.status : '';
        stock.status = (stock.status
            ?
            <div className={`badge badge-pill badge-${stock.status.trim().toLowerCase()}-fill `}>
                {stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
            </div>
            :
            <></>);
        stock.availability = (<div className="Vehicle-availability">
            {stock.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
            {stock.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
            {stock.isSale ? <div><span>{'Sale'}</span></div> : <></>}
        </div>);


        stock.availabileFor = ((stock.isTestDrive ? 'Test Drive, ' : '') +
            (stock.isLoan ? 'Loan, ' : '') +
            (stock.isSale ? 'Sale' : ''));

        if (stock.clientID && stock.oemID && stock.isAllocated) {
            stock.status = (<div className="badge badge-pill badge-allocated-fill ">ALLOCATED</div>)
            let _client = props.dealersettings.clients.filter(client => client.id === stock.clientID)[0];
            // stock.clientName = _client ? _client.name : '';
            stock.clientName = <span className="tbl-sub-dealer">{_client ? _client.name : ''}</span>
        }
        stock.addedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), stock.addedBy);
        stock.modifiedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), stock.modifiedBy);
        return stock
    }

    const handleApplyFilter = (_filter) => {
        //   console.log('this is the filter applied', _filter)
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('oemStockFilter', JSON.stringify(_filter));
            localStorage.oemStockSearchText = '';
            setFilterApplied(true);
            setStockLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.oemStockFilter)) {
            localStorage.removeItem('oemStockFilter');
            localStorage.oemStockSearchText = '';
            setFilterApplied(false);
            setStockLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ oemStockFields: fields }, { merge: true })
            .then(() => {
                setStockFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('oemStockFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id) => {
        return (
            <div style={{ width: '70px', float: 'left' }}>
                <div
                    style={{ cursor: 'pointer' }}
                    className='mini-button'
                    onClick={e => {
                        handleFilesOpen(id);
                    }}
                >
                    <i className='ico icon-attachment'></i>
                </div>

                <div
                    style={{ cursor: 'pointer' }}
                    className="mini-button ml-2 mr-2"
                    onClick={(e) => {
                        setEditDocument(id);
                        setModelShow(true);
                    }}>
                    <i className="ico icon-edit"></i>
                </div>

                {/* <div
                    style={{ cursor: 'pointer' }}
                    className="mini-button ml-2"
                >
                    <i className="ico icon-delete"></i>
                </div> */}
            </div>
        )
    }

    const handleFilesOpen = id => {
        const editStock = stocks.filter(item => item.documentID === id);
        if (editStock.length > 0) {
            setSelectedStock(editStock[0]);
            setShowFilesModel(true);
        }
    };

    const handleFilesClose = () => {
        setSelectedStock(null);
        setShowFilesModel(false);
    };

    const handleDetailsClick = (e, id) => {
        e.preventDefault();
        //props.history.push("/contacts/details/" + id);
        setEditDocument(id);
        setModelShow(true);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleClose = () => {
        setModelShow(false);
        setEditDocument(null);
    }
    const handleHistoryClose = () => {
        setModelHistoryShow(false);
        setEditDocument(null);
    }

    const updateStock = (objStock) => {
        if (!_.isEmpty(objStock)) {
            let _data = dataMappingVM(objStock);

            if (!_.isEmpty(_data)) {
                dispatch({
                    type: "_UPDATE",
                    data: _data,
                    sortName: 'modifiedDate',
                    sortOrder: 'desc'
                });
            }
        }

    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(stocks) && stocks.map(file => {
                let record = Object.assign({}, stocks.filter(e => e.documentID === file.documentID)[0]);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, stocks.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleCloseAllocateModal = async (clientID) => {
        //console.log('this is handleCloseAllocateModal clientID', clientID)
        if (!clientID) {
            setModalAllocateShow(false)
            return
        }

        const selectedClient = props.dealersettings.clients.filter(a => a.documentID === clientID)[0]
        if (!selectedClient) {
            return
        }
        let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();
        const batch = window.firebase.firestore().batch();
        let _selectedIds = Object.assign({}, selectedIds);
        for (let [key] of Object.entries(_selectedIds)) {
            let stockToUpdate = stocks.filter(st => st.documentID === key)[0]
            if (stockToUpdate.isAllocated) {
                // console.log('Already Allocated')
            } else {
                // console.log('Not Already Allocated')

                await window.firebase.firestore().collection('stock')
                    .doc(key)
                    .update({
                        status: 'available',
                        isAllocated: true,
                        clientID: clientID,
                        oemID: props.dealersettings.client.settingsID,
                        groupID: selectedClient.group ? selectedClient.group : '',
                        regionID: selectedClient.region ? selectedClient.region : '',
                        modifiedBy,
                        modifiedDate,
                        modifiedFrom: 'web',
                    }).then((docRef) => {

                    }).catch((error) => {
                        console.error("Error allocating", error);

                    });
            }
        }

        batch.commit()
            .then(() => {
                setSelectedIds({});
                setModalAllocateShow(false)
                toast.notify('Stock allocated successfully', {
                    duration: 2000
                })
            })
            .catch((error) => {
                console.log(error);
                setModalAllocateShow(false)
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    return (
        <>
            <FilterPanel
                sortOrder={sortOrder}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                sortName={sortName}
                handlesortOrder={(val) => {
                    setSortOrder(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleSortNamechange={(val) => {
                    setSortName(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleAddStock={() => {
                    setModelShow(true);
                    setEditDocument(null);
                }}
                csvHeader={csvHeader}
                datalist={stocks}
                searchText={searchText}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setFilterApplied(false);
                    setStockLoader(true)
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([])
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.oemStockFilter))
                        localStorage.removeItem('oemStockFilter');

                }}
                handleOpenAllocateModal={() => setModalAllocateShow(true)}
                selectedIds={selectedIds}
                history={props.history}
            />
            {
                stockLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    (
                        <div className="contact-panel" style={{ width: windowSize.windowWidth + 'px' }}>
                            <TableView
                                isReorderable={true}
                                datalist={stocks}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={allStockFieldsVM}
                                handleSavesettings={handleSavesettings}
                                dynamicFields={(oemStockFields && oemStockFields.length > 0) ? oemStockFields : allStockFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                settingsLoader={settingsLoader}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleDetailsClick}
                                isSettings={true}
                                hasMore={hasMore}
                                handlePagination={handlePagination}
                                isPaging={true}
                                selectedIds={selectedIds}
                                handleAllChecked={handleAllChecked}
                                handleCheckChange={handleCheckChange}
                            />
                        </div>
                    )
            }

            <PopUpModal show={modelShow}>
                <AddStock
                    docID={editDocumentID}
                    show={modelShow}
                    clsActive='overlay-modal active'
                    handleClose={handleClose}
                    title={editDocumentID ? 'stock' : 'addStock'}
                    enableSale={false}
                    enableTestDrive={false}
                    enableLoan={false}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isFilterApplied={isFilterApplied}
                    updateStock={updateStock}
                >
                </AddStock>
            </PopUpModal>

            {/* <PopUpModal show={modelHistoryShow}>
                <StockHistory
                    docID={editDocumentID}
                    show={modelHistoryShow}
                    clsActive='overlay-modal active'
                    handleClose={handleHistoryClose}
                    title={'stockHistory'}
                    dealersettings={props.dealersettings}
                    updateDealerSettings={props.updateDealerSettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    history={props.history}
                >
                </StockHistory>
            </PopUpModal> */}

            <PopUpModal show={modalAllocateShow}>
                <SelectDealer
                    show={modalAllocateShow}
                    handleClose={handleCloseAllocateModal}
                    title={'Select dealer to allocate'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </SelectDealer>
            </PopUpModal>
            <PopUpModal show={showFilesModel}>
                <Files
                    show={showFilesModel}
                    enquiryID={selectedStock && selectedStock.documentID}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    handleClose={handleFilesClose}
                    client={selectedStock}
                    title='Add a File'
                />
            </PopUpModal>
        </>
    )
}

export default StockList