import React from 'react';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';

export const NoDataDiv = () => (
	<>
		<div
			className='spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente'
			style={{ minHeight: '360px' }}
		>
			<div className='no-data-flex h-100 justify-content-center align-items-center '>
				<div className='no-data-img'>
					{' '}
					<img src={_images.nodata} width='60' height='60' alt='' />
				</div>
				<div className='no-data-txt mt-2'>
					{' '}
					<Translate
						text={'No results found, please enter different keywords'}
					/>
				</div>
			</div>
		</div>
	</>
);

export const LoadingDiv = () => (
	<div
		className='mt-5'
		style={{
			minHeight: '280px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		<div className='spinner-loader h-100 undefined '>
			<div className='d-flex h-100 justify-content-center align-items-center text-primary'>
				<div role='status' className='spinner-border text-primary'></div>
				<p className='text-muted mt-3'></p>
			</div>
		</div>
	</div>
);

export const cafeTabs = [
	{
		name: '',
		value: 'cafe',
		title: 'Cafe',
		ico: 'ico icon-menu-orders',
	},
];

export const frontDeskTabs = [
	{
		name: '',
		value: 'contacts',
		title: 'Contacts',
		ico: 'ico icon-Contacts',
	},
];

export const loanVehicleTabs = [
	{
		name: 'All',
		value: 'all',
		ico: '',
		title: 'All',
	},
	{
		name: '',
		value: 'stock',
		title: 'Stock',
		ico: 'ico icon-Stock',
		icoVehicle: true,
	},
	{
		name: '',
		value: 'loanVehicle',
		title: 'Loan Vehicle',
		ico: 'ico icon-tradein',
		icoVehicle: true,
	},
	{
		name: '',
		value: 'testDrive',
		title: 'Test Drive',
		ico: 'ico icon-activity-testdrive',
		icoVehicle: true,
	},
];

export const oemTabs = [
	{
		name: 'All',
		value: 'all',
		ico: '',
		title: 'All',
	},
	{
		name: '',
		value: 'enquiry',
		title: 'Enquiries',
		ico: 'ico icon-enquiry',
	},
	{
		name: '',
		value: 'contacts',
		title: 'Contacts',
		ico: 'ico icon-Contacts',
	},
	{
		name: '',
		value: 'company',
		title: 'Companies',
		ico: 'ico icon-company',
	},
	{
		name: '',
		value: 'stock',
		title: 'Stock',
		ico: 'ico icon-Stock',
		icoVehicle: true,
	},
	{
		name: '',
		value: 'dealers',
		title: 'Dealers',
		ico: 'ico icon-dealers',
	},
	{
		name: '',
		value: 'activity',
		title: 'Activities',
		ico: 'ico icon-date',
	},
];

export const salesTabs = [
	{
		name: 'All',
		value: 'all',
		ico: '',
		title: 'All',
	},
	{
		name: '',
		value: 'enquiry',
		title: 'Enquiries',
		ico: 'ico icon-enquiry',
	},
	{
		name: '',
		value: 'contacts',
		title: 'Contacts',
		ico: 'ico icon-Contacts',
	},
	{
		name: '',
		value: 'company',
		title: 'Companies',
		ico: 'ico icon-company',
	},
	{
		name: '',
		value: 'stock',
		title: 'Stock',
		ico: 'ico icon-Stock',
		icoVehicle: true,
	},
	{
		name: '',
		value: 'activity',
		title: 'Activities',
		ico: 'ico icon-date',
	},
	{
		name: '',
		value: 'service',
		title: 'Service',
		ico: 'ico icon-service-settings',
	},
	{
		name: '',
		value: 'tradein',
		title: 'Trade-In',
		ico: 'ico icon-tradein',
		icoVehicle: true,
	},
];


export const serviceTabs = [
	{
		name: 'All',
		value: 'all',
		ico: '',
		title: 'All',
	},
	{
		name: '',
		value: 'service',
		title: 'Service',
		ico: 'ico icon-service-settings',
	},
	{
		name: '',
		value: 'contacts',
		title: 'Contacts',
		ico: 'ico icon-Contacts',
	},
	{
		name: '',
		value: 'company',
		title: 'Companies',
		ico: 'ico icon-company',
	},
	{
		name: '',
		value: 'tradein',
		title: 'Trade-In',
		ico: 'ico icon-tradein',
		icoVehicle: true,
	},
];


export const stockTabs = [
	{
		name: '',
		value: 'stock',
		title: 'Stock',
		ico: 'ico icon-Stock',
		icoVehicle: true,
	},
];


export const offersTabs = [
	{
		name: '',
		value: 'offers',
		title: 'Offers',
		ico: 'ico icon-Stock',
		icoVehicle: true,
	},
];

export const tradeinProTabs = [
	{
		name: 'All',
		value: 'all',
		ico: '',
		title: 'All',
	},
	{
		name: '',
		value: 'appraisal',
		title: 'Appraisal',
		ico: 'ico icon-Stock',
		icoVehicle: true,
	},
	{
		name: '',
		value: 'contacts',
		title: 'Contacts',
		ico: 'ico icon-Contacts',
	},
	{
		name: '',
		value: 'company',
		title: 'Companies',
		ico: 'ico icon-company',
	},
	{
		name: '',
		value: 'activity',
		title: 'Activities',
		ico: 'ico icon-date',
	},
];

export const eventsProTabs = [
	{
		name: 'All',
		value: 'all',
		ico: '',
		title: 'All',
	},
	{
		name: '',
		value: 'eventsPro',
		title: 'Events',
		ico: 'ico icon-events-event',
		icoVehicle: true,
	},
	// {
	// 	name: '',
	// 	value: 'attendees',
	// 	title: 'Attendees',
	// 	ico: 'ico icon-events-attendees',
	// 	icoVehicle: true,
	// },
];

export const fleetProTabs = [
	{
		name: 'All',
		value: 'all',
		ico: '',
		title: 'All',
	},
	{
		name: '',
		value: 'fleetPro',
		title: 'Fleet',
		ico: 'ico icon-fleetpro-icon',
		icoVehicle: true,
	},
	{
		name: '',
		value: 'fleetProAssign',
		title: 'Fleet',
		ico: 'ico icon-fleetpro-icon',
		icoVehicle: true,
	},
	// {
	// 	name: '',
	// 	value: 'attendees',
	// 	title: 'Attendees',
	// 	ico: 'ico icon-events-attendees',
	// 	icoVehicle: true,
	// },
];