import React, { useState, useEffect, useReducer, useContext } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ContentOverlay, TableView, PopUpModal, ReactSelect } from '../../../../components'
import { usersVM, userlistFields, levelOptions } from './viewModel'
import { default as _images } from "../../../../images";
import AddUser from './addUser'
import CommonHelper from '../../../../services/common';
import toast from 'toasted-notes'
import { ClientContext } from '../../../layout/clientContext'
import Swal from 'sweetalert2';

const UserListReducer = (state, action) => {
    function updateUser() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateUser()
        }
        default:
            return state;
    }
};

const UserList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [users, dispatch] = useReducer(UserListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [loader, setLoader] = useState(true)
    const [isPaging, setPaging] = useState(false)
    const [hasMore, setHasMoreData] = useState([])
    const [showuserModel, setShowUserModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [selectedUser, setSelecteduser] = useState(null)
    const pageLimit = 1000;

    const { oemSettings } = useContext(ClientContext);
    const [selectedOEM, setSelectedOEM] = useState('')
    const [oemOptions, setOEMOptions] = useState([]);
    const [btnloader, setbtnloader] = useState({});

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 315)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubAdminUserList && window.unSubAdminUserList();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(oemSettings))
            return

        let _oemSettings = [];
        oemSettings.forEach((rec) => {
            _oemSettings.push({ label: rec.name, value: rec.id, data: rec })
        })
        setOEMOptions(_.orderBy(_oemSettings, ['label'], ['asc']));
        setSelectedOEM(_oemSettings[0])
    }, [oemSettings])

    useEffect(() => {
        if (!checkDataLoad || _.isEmpty(selectedOEM)) {
            return;
        }

        let userDataRef = window.firebase.firestore().collection('users')
            .where('settingsID', '==', selectedOEM.value);

        // if (searchText) {
        //     userDataRef = userDataRef
        //         .where('keywords', 'array-contains', searchText)
        // }
        // userDataRef = userDataRef
        //     .orderBy('modifiedDate', 'desc')

        if (hasMore.length > 0) {
            userDataRef = userDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            userDataRef = userDataRef
                .limit(pageLimit)
        }

        window.unSubAdminUserList = userDataRef
            .onSnapshot(onCollectionUpdate);

    }, [checkDataLoad, selectedOEM])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                //if (change.doc.data().email !== 'admin@fusionsd.com') {
                snapshotDoc = convertVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADD_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
                //}
            }
        })
        const contacts = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                //if (doc.data().email !== 'admin@fusionsd.com') {
                contacts.push(convertVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
                //}
            });
        }

        dispatch({
            type: isPaging ? "APPEND_LIST" : actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : contacts
        });


        setDataload(false)
        setLoader(false)
        setPaging(false)
    }

    const convertVM = (doc) => {
        const objuserData = Object.assign({}, doc);
        const listVM = Object.assign({}, usersVM);
        for (let [key, value] of Object.entries(objuserData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }

        listVM.userName = <><div className="table-list-users"><img src={CommonHelper.showUserAvatar(doc.profileImage, listVM.name)} alt="" className="img-object-fit" /></div> {listVM.name}</>
        listVM.roleName = <>{doc.role}<br /><span className="user-role-span ml-0">{_.find(levelOptions, { value: doc.level }) ? _.find(levelOptions, { value: doc.level }).label : doc.level}</span></>;
        listVM.lastlogin = doc.lastLoginTime ? moment.unix(doc.lastLoginTime.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.status = doc.tokkens && doc.tokkens.length > 0 ?
            (<div className="badge badge-pill badge-online ml-1">Online </div>)
            : (<div className="badge badge-pill badge-offline ml-1">Offline </div>);
        if (_.isEmpty(doc.arrayRegionID) && !_.isEmpty(doc.regionID)) {
            listVM.arrayRegionID = [doc.regionID]
        }
        if (_.isEmpty(doc.arrayGroupID) && !_.isEmpty(doc.groupID)) {
            listVM.arrayGroupID = [doc.groupID]
        }

        if (!_.isEmpty(doc.dmsKeys) && _.isArray(doc.dmsKeys)) {
            listVM.dmsTags = (<div className="Vehicle-availability">                {
                doc.dmsKeys.map((rec, index) => {
                    return <div key={index}><span>{rec.type}</span></div>
                })
            }
            </div>);
        }
        return listVM
    }

    const hadleEditUser = (id) => {
        const editUser = users.find(item => item.documentID === id);
        const userVM = Object.assign({}, usersVM);
        for (let [key, value] of Object.entries(editUser)) {
            if (userVM[key] !== undefined)
                userVM[key] = value;
        }
        setSelecteduser(userVM)
        setPopHeader('Edit User')
        setShowUserModel(true)
    }

    const handleActionClick = (id, data) => {
        return (<><div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            hadleEditUser(id)
        }}>
            <i className="ico icon-edit"></i>
        </div>
            <button
                type='button'
                className='btn btn-secondary float-left ml-2'
                style={{ padding: `3px 8px`, fontSize: `11px` }}
                onClick={() => handleremoveMFA(data)}
            >
                {
                    btnloader[data.documentID] ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                Disable MFA</button>
        </>)
    }

    const handleremoveMFA = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to disable MFA?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                setbtnloader({ [data.documentID]: true })
                const removeMFA = window.firebase.functions().httpsCallable('amsadmin-removeMFA');
                removeMFA({
                    "email": data.email
                }).then((data) => {
                    setbtnloader({})
                    if (data?.data?.success && data?.data?.data) {
                        window.firebase.firestore().doc(`users/${data.data.data}`).update({ enableMFA: false, enableTOTP: false });
                        toast.notify(`Multi-factor authentication disabled successfully.`, {
                            duration: 2000
                        })
                    } else {
                        Swal.fire(data.data.message, '', 'error')
                    }
                });
            }
        })
    }

    const handleUserdetailsClick = (e, id) => {
        e.preventDefault();
        hadleEditUser(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }


    const handleModelClose = () => {
        setSelecteduser(null)
        setShowUserModel(false)
    }

    const handleAddUser = () => {
        setSelecteduser(null)
        setPopHeader('Add User')
        setShowUserModel(true)
    }
    const handleOEMChange = (e, data) => {
        setLoader(true)
        setDataload(true)
        setSelectedOEM(e)
    }
    return (
        <>
            <div className="user-management">
                <div className="settings-head form-style">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="form-row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <ReactSelect
                                            options={oemOptions}
                                            name={"oem"}
                                            placeholder={'select oem'}
                                            onChange={handleOEMChange}
                                            value={!_.isEmpty(selectedOEM) ? selectedOEM.value : ''}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="float-right">
                                <button type="button" className="btn btn-primary float-right" onClick={() => { handleAddUser(); }}><i className="ico icon-add mr-2"></i> Add User</button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        users.length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    datalist={_.orderBy(users.filter(({ name, phone, email }) =>
                                    (!_.isEmpty(searchText) ? (
                                        (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(phone) ? phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(email) ? email.toLowerCase().includes(searchText.toLowerCase()) : false)
                                    ) : true)
                                    ), ['addedDate.seconds'], ['desc'])}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={userlistFields}
                                    dynamicFields={userlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleUserdetailsClick}
                                    isSettings={false}
                                    hasMore={hasMore}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    isReorderable={false}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p>No users found.</p>
                                    <button className="btn btn-sm btn-default" onClick={() => { handleAddUser(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                        Add User
                                    </button>
                                </div>
                            </div>
                        )
                }

            </div>
            <PopUpModal show={showuserModel}>
                <AddUser
                    show={showuserModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    user={selectedUser}
                    selectedOEM={selectedOEM}
                />
            </PopUpModal>
        </>
    );
}

export default UserList;