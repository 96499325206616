/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox,
    ReactSelect, InputText, ReactMultiSelect,
    AntDateRangePicker, InputMultipleText
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'
import { userLevelOptions } from './viewModel';

//#region  ASSIGN VARIABLES

const contact_type = [
    { active: true, value: 'Current Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Current Customer'} /></div> },
    { active: true, value: 'Potential Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Potential Customer'} /></div> }
]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'all', label: <Translate text={'all'} /> },
    { active: true, value: 'addedDate', label: <Translate text={'addedDate'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'modifiedDate'} /> },
    { active: true, value: 'startDate', label: <Translate text={'startDate'} /> },
    { active: true, value: 'endDate', label: <Translate text={'endDate'} /> },
    { active: true, value: 'closedDate', label: <Translate text={'closedDate'} /> },
    { active: true, value: 'publishedDate', label: <Translate text={'publishedDate'} /> },
    { active: true, value: 'liveDate', label: <Translate text={'liveDate'} /> },
]

const objDateField = {
    name: null,
    type: null,
    range: null
}

const yesNoOptions = [
    { active: true, value: 'Yes', label: 'Yes' },
    { active: true, value: 'No', label: 'No' }
]
//#endregion

const PipelineAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [dealers, setDealers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [regions, setRegions] = useState([]);
    const [subregions, setSubRegions] = useState([]);

    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const [eventsProAllStatus, setAllStatus] = useState([]);
    const { columnFields } = props;

    let _userLevelOptions = userLevelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(userLevelOptions, { value: props.dealersettings.level })?.index);
    let _userLevel = _userLevelOptions.map(r => { return r.value })
    let _userLevelName = _userLevelOptions.map(r => { return r.label === 'Individual' ? 'Client' : r.label }).join('/')
    //console.log('_userLevel',_userLevelName, _userLevel)
    useEffect(() => {
        const { pipelineFilter } = props;
        if (!_.isEmpty(pipelineFilter))
            setFilterFields(pipelineFilter.value);
        else
            setFilterFields({});

    }, [props.pipelineFilter])

    useEffect(() => {
        const { dealersettings, clientUsers, pipelineFilter } = props;

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.clients)) {
            let _dealers = [];
            dealersettings.clients.filter(c =>
                c.moduleSettings
                && c.moduleSettings.eventsPro
                && c.moduleSettings.eventsPro.enabled === true
                && c.moduleSettings.eventsPro.active === true
            ).forEach(rec => {
                _dealers.push({
                    ...rec,
                    label: rec.name,
                    value: rec.id,
                    active: true,
                })
            })

            let _subregions = [];
            dealersettings.subregions && dealersettings.subregions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.subregion === doc.id)) {
                    _subregions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _regions = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.region === doc.id)) {
                    _regions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _groups = [];
            dealersettings.groups && dealersettings.groups.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.group === doc.id)) {
                    _groups.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });
            setGroups(_groups);
            setRegions(_regions);
            setDealers(_dealers);
            setSubRegions(_subregions)
        }

        if (dealersettings && dealersettings.client && (dealersettings.client.settings || dealersettings.client.eventsPro)) {


            const _clientUsers = [];
            if (clientUsers) {
                let ___clientUsers = clientUsers;

                ___clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    });
                });
            }

            let _eventsProAllStatus = [];
            dealersettings?.client?.eventsPro?.eventsProStatus && dealersettings.client.eventsPro.eventsProStatus.forEach((doc) => {
                _eventsProAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'New' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });
            setAllStatus(_eventsProAllStatus);
            setClientUsers(_clientUsers);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = '';
        if (selectedOptions?.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            if (data.name === 'dealer')
                value = loadDealers().filter(e => e.value !== 'all').map(rec => { return rec.value }).join(',')
            else if (data.name === 'group')
                value = loadGroups().filter(e => e.value !== 'all').map(rec => { return rec.value }).join(',')
            else if (data.name === 'subregion')
                value = loadSubRegions().filter(e => e.value !== 'all').map(rec => { return rec.value }).join(',')
            else if (data.name === 'region')
                value = loadRegions().filter(e => e.value !== 'all').map(rec => { return rec.value }).join(',')
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        if (data.name === 'region') {
            delete state['subregion']
            delete state['group']
            state['dealer'] = ''
        }

        if (data.name === 'subregion') {
            delete state['group']
            state['dealer'] = ''
        }

        if (data.name === 'group') {
            state['dealer'] = ''
        }

        state[data.name] = value;

        setFilterFields(state);
    }


    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'region' && e) {
            state[data.name] = e.value;
            delete state['subregion']
            delete state['group']
            state['dealer'] = ''
            setFilterFields(state)
        }
        else {
            state[data.name] = e ? e.value : '';
            setFilterFields(state)
        }
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (name === 'region') {
                state[name] = null;
                //state['dealer'] = null;
            }
            else if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'region') {
                delete state['dealer'];
            }

            if (name === 'pipeline')
                delete state['stage'];
        }

        setFilterFields(state);

    }


    const handlePIDateSave = (val, name) => {
        let state = Object.assign({}, filterFields);
        if (val) val = moment(val).format('MMM YYYY');
        state[name] = val;
        setFilterFields(state);
    };

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state)
    }

    const onNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, filterFields);
        state[name] = e.value;
        setFilterFields(state)
    }

    const removeMultipleText = (index, name) => {
        let arr = filterFields[name] ? filterFields[name].split(',') : []
        arr.splice(index, 1)
        setFilterFields({
            ...filterFields,
            [name]: arr.join(',')
        })
    }

    const handleOnChangeMultipleText = (val, name) => {
        setFilterFields({
            ...filterFields,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
    }

    //#endregion

   
    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.pipelineFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {

        Object.keys(filterFields).forEach(key => (filterFields[key] === undefined || (_.isObject(filterFields[key]) && _.isEmpty(filterFields[key]))) ? delete filterFields[key] : {});
        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.pipelineFilter) && !_.isEmpty(props.pipelineFilter.value) && JSON.stringify(props.pipelineFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.pipelineFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }
    }

    const handleSave = () => {
        Object.keys(filterFields).forEach(key => (filterFields[key] === undefined || (_.isObject(filterFields[key]) && _.isEmpty(filterFields[key]))) ? delete filterFields[key] : {});
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };

    const loadDealers = () => {
        let _dealers = [];
        if (!_.isEmpty(filterFields?.group)) {
            let _sub = Object.assign([], filterFields.group.split(','))
            _dealers = dealers.filter(e => _sub.includes(e.group))
        }
        else if (!_.isEmpty(filterFields?.subregion)) {
            let _sub = Object.assign([], filterFields.subregion.split(','))
            _dealers = dealers.filter(e => _sub.includes(e.subregion))
        }
        else if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            _dealers = dealers.filter(e => _regs.includes(e.region))
        }
        else
            _dealers = dealers

        if (_dealers.length > 1) {
            _dealers = [{ label: 'All', value: 'all' }, ..._dealers];
        }
        return _dealers;
    }

    const loadGroups = () => {
        let _groups = [];
        if (!_.isEmpty(filterFields?.subregion)) {
            let _regs = Object.assign([], filterFields.subregion.split(','))
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && _regs.includes(client.subregion)))
        }
        else if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && _regs.includes(client.region)))
        }
        else
            _groups = groups

        if (_groups.length > 1) {
            _groups = [{ label: 'All', value: 'all' }, ..._groups];
        }
        return _groups;
    }

    const loadSubRegions = () => {
        let _subregions = [];
        if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            _subregions = subregions.filter(doc => dealers.some(client => client.subregion === doc.value && _regs.includes(client.region)))
        }
        else _subregions = subregions

        if (_subregions.length > 1) {
            _subregions = [{ label: 'All', value: 'all' }, ..._subregions];
        }
        return _subregions;
    }

    const loadRegions = () => {
        let _regions = regions;

        if (_regions.length > 1) {
            _regions = [{ label: 'All', value: 'all' }, ..._regions];
        }
        return _regions;
    }
    
    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader">   <Translate text={'events'} /> </div>

                <div className="advanced-filter-list">
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('dealer') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'dealer'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('dealer')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'dealer'}>{_userLevelName}</label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('dealer')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            {
                                                _userLevel.includes('region') && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <ReactMultiSelect
                                                                options={loadRegions()}
                                                                name={"region"}
                                                                placeholder={'select regions'}
                                                                onChange={handleMultiSelectChange}
                                                                value={filterFields.hasOwnProperty('region') && filterFields.region ? filterFields.region.split(',') : []}
                                                                classNamePrefix={`basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                _userLevel.includes('subregion') && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <ReactMultiSelect
                                                                options={loadSubRegions()}
                                                                name={"subregion"}
                                                                placeholder={'select sub regions'}
                                                                onChange={handleMultiSelectChange}
                                                                value={filterFields.hasOwnProperty('subregion') && filterFields.subregion ? filterFields.subregion.split(',') : []}
                                                                classNamePrefix={`basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                _userLevel.includes('group') && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <ReactMultiSelect
                                                                options={loadGroups()}
                                                                name={"group"}
                                                                placeholder={'select groups'}
                                                                onChange={handleMultiSelectChange}
                                                                value={filterFields.hasOwnProperty('group') && filterFields.group ? filterFields.group.split(',') : []}
                                                                classNamePrefix={`basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                _userLevel.includes('individual') && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <ReactMultiSelect
                                                                options={loadDealers()}
                                                                name={"dealer"}
                                                                placeholder={'select client'}
                                                                onChange={handleMultiSelectChange}
                                                                value={filterFields.hasOwnProperty('dealer') && filterFields.dealer ? filterFields.dealer.split(',') : []}
                                                                classNamePrefix={`basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            //menuPlacement={'top'}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0)
                            ?
                            <>
                                {/* STATUS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'status'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'status'}><Translate text={'status'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={eventsProAllStatus}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('status') && filterFields.status ? filterFields.status.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                </div>
            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            {props.noSaveAccess ? <></> : <><button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button></>}
                            <button type="button" className={`btn btn-default float-left ${props.noSaveAccess ? '' : 'ml-2'}`} onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }

            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.pipelineFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'eventsPro'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default PipelineAdvanceFilters;