import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import { AntDatePicker, ReactTimePicker } from '../../components';
import moment from 'moment'
import CommonHelper from '../../services/common';
import toast from 'toasted-notes'

const ScheduleCampaign = props => {
	const [errorFields, setErrorFields] = useState({});
	const [state, setData] = useState({
		scheduleDate: window.firebase.firestore.Timestamp.fromDate(moment().add(10, 'minutes')._d),
	});

	const onSchedule = () => {
		props.handleClose(state.scheduleDate);
	};

	const minStartDate = (current) => {
		// Can not select days before today and today
		return current && current < moment().startOf('day');
	}	

	const handleDateChange = (val, name) => {
		if (val) {
			val = moment(moment(val).format('YYYY-MM-DD') + ' ' + moment.unix(state.scheduleDate.seconds).format('HH:mm'))._d;
			let date = moment(val)
			setData({
				...state,
				[name]: window.firebase.firestore.Timestamp.fromDate(date._d),
			});

		}
	};

	const handleStartTimeChange = (val, name) => {
		let date = moment(val)
    var pastDate = moment();
    
    if (date.isBefore(pastDate)) {
			// console.log('Date is past');
			toast.notify('Scheduled date cannot be older than current date', {
				duration: 2000
			})
    } else{
			setData({
				...state,
				[name]: window.firebase.firestore.Timestamp.fromDate(date._d),
			});
			// console.log('Date is not past');
    }
	};

	return (
		<Modal
			show={props.show}
			onHide={props.handleClose}
			backdrop='static'
			backdropClassName={props.show ? 'overlay-modal active' : ''}
			dialogClassName='modal-dialog-centered modal-save-filter'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title'>
						{' '}
						<Translate text={'Schedule SMS Campaign'} />
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='form-style w-100'>
							<div className='form-row'>
								<div className='form-group col-md-6'>
									<label>
										<Translate text={'date'} />
									</label>
									<div className='inner-addon right-addon'>
                    <AntDatePicker
                      value={moment
                        .unix(state.scheduleDate.seconds)
                        .format('YYYY-MM-DD')}
                      name={'scheduleDate'}
                      onChange={e => {
                        handleDateChange(e, 'scheduleDate');
                      }}
                      format='DD/MM/YYYY'
                      placeholder='DD/MM/YYYY'
                      className={`form-control`}
											disabledDate={minStartDate}
                    />
									</div>
								</div>
								<div className='form-group col-md-6'>
									<label>
										<Translate text={'Time'} />
									</label>
									<div className='inner-addon right-addon'>
										<i className='bg-icons ico icon-time'></i>

										<ReactTimePicker
											value={moment.unix(state.scheduleDate.seconds)._d}
											name={'scheduleDate'}
											onChange={handleStartTimeChange}
											timeIntervals={15}
											placeholder='h:mm aa'
											className={`form-control`}
										/>
									</div>
								</div>
								{/* <div className='form-text'>You are not a paid user of mailchimp</div> */}
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className={`btn btn-primary float-right`}
					onClick={onSchedule}
				>
					<Translate text={'Schedule'} />
				</button>
				<button
					type='button'
					className='btn btn-default float-left'
					onClick={() => {
						props.handleClose();
					}}
				>
					<Translate text={'cancel'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ScheduleCampaign;
