/** LIBRARIES */
import React, { useState, useEffect, useRef, Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { ReactMultiSelect, AntDateRangePicker } from "../../components";
import { CustomToggle, CustomFilter } from "../../components/customdropdown";
import { EVENTS } from "./viewModel";
import ReactSelect from "react-select";
import ReactExport from 'react-export-excel-xlsx-fix';
import Translate from "../../constants/translate";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const FilterPanel = (props) => {
  const [searchText, setSearchText] = useState(props.searchText);
  const [filterClient, setFilterClient] = useState(props.filterClient);
  const [filterTitle, setFilterTitle] = useState(props.filterTitle);
  const [filterStatus, setFilterStatus] = useState(props.filterStatus);
  const [filterDate, setFilterDate] = useState(null);
  const [filterShow, setShow] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const node = useRef();

  const optionsStatus = [
    { label: "Sent", value: "sent" },
    { label: "Failed", value: "failed" },
    { label: "Pending", value: "pending" },
  ];

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      window.unsubpipelinestatsSnap && window.unsubpipelinestatsSnap();
    };
  }, []);

  const handleClick = (e) => {
    if (
      (node && node.current && node.current.contains(e.target)) ||
      e.target.classList.contains("ant-picker-cell-inner") ||
      (e?.target?.classList?.value &&
        e.target.classList.value.includes("ant-picker")) ||
      e.target.tagName === "path" ||
      e.target.tagName === "svg" ||
      e.target.classList.contains("basic-select__indicator")
    ) {
      return;
    }
    setShow(false);
  };
  useEffect(() => {
    setSearchText(props.searchText);
  }, [props.searchText]);

  useEffect(() => {
    setFilterClient(props.filterClient);
  }, [props.filterClient]);

  useEffect(() => {
    setFilterTitle(props.filterTitle);
  }, [props.filterTitle]);
  useEffect(() => {
    setFilterDate(props.filterDate);
  }, [props.filterDate]);
  useEffect(() => {
    setFilterStatus(props.filterStatus);
  }, [props.filterStatus]);
  const handleTriggerMultiSelectChange = (selectedOptions) => {
    const value = [];
    !_.isEmpty(selectedOptions) &&
      selectedOptions.map((data) => {
        return value.push(data.value);
      });
    if (value.length) {
      setFilterClient(value);
      localStorage.contactFilterClient = value;
      props.handleFilterClient(value);
    } else {
      setFilterClient("");
      localStorage.contactFilterClient = "";
      props.handleFilterClient(null);
    }
  };
  const handleTitleMultiSelectChange = (selectedOptions) => {
    const value = [];
    !_.isEmpty(selectedOptions) &&
      selectedOptions.map((data) => {
        return value.push(data.value);
      });
    if (value.length) {
      setFilterTitle(value);
      localStorage.contactFilterClient = value;
      props.handleFilterTitle(value);
    } else {
      setFilterTitle("");
      localStorage.contactFilterClient = "";
      props.handleFilterTitle(null);
    }
  };

  const filterCounts = () => {
    var FilterCount = 0;
    if (filterTitle) {
      FilterCount += 1;
    }
    if (filterClient) {
      FilterCount += 1;
    }
    // if (filterDate?.startDate) {
    //   FilterCount += 1;
    // }
    if (filterStatus?.value) {
      FilterCount += 1;
    }
    return FilterCount > 0 && showCount ? (
      <span className="badge badge-pill badge-advanced-filter">
        {FilterCount}
      </span>
    ) : (
      <></>
    );
  };
  const handleDateRangeChange = (selectedOptions) => {
    if (selectedOptions) {
      const val = {
        startDate: window.firebase.firestore.Timestamp.fromDate(
          selectedOptions[0].startOf("day")._d
        ),
        endDate: window.firebase.firestore.Timestamp.fromDate(
          selectedOptions[1].endOf("day")._d
        ),
      };
      setFilterDate(val);
      props.handleFilterDate(val);
    } else {
      setFilterDate(null);
      props.handleFilterDate(null);
    }
  };
  const handleStatusChange = (selectedOptions) => {
    if (selectedOptions) {
      setFilterStatus(selectedOptions);
      props.handleFilterStatus(selectedOptions);
    } else {
      setFilterStatus(null);
      props.handleFilterStatus(null);
    }
  };
  return (
    <div className="filter-panel">
      {/**** KEYWORDS SERACH ****/}
      <div className="float-left mr-2" style={{ width: '250px' }}>
        <AntDateRangePicker
          value={
            filterDate
              ? [
                moment.unix(filterDate.startDate.seconds),
                moment.unix(filterDate.endDate.seconds),
              ]
              : null
          }
          id={"dashboard-date-range"}
          name={"filterDate"}
          format="DD/MM/YYYY"
          onChange={(e) => {
            handleDateRangeChange(e, "daterange");
          }}
          placeholder="DD/MM/YYYY"
          className={'ant-picker-billing'}
        />
      </div>
      <div className="filter-search">
        <div className="input-group ">
          <input
            className="form-control"
            type="text"
            id="isearch"
            placeholder="search..."
            value={searchText ? searchText : ""}
            onChange={(e) => {
              let _val = e.target.value;

              setSearchText(_val);
              localStorage.setItem("triggerLogSearchText", _val);
              if (!_val) props.handlesearchText("");

              if (_val && _val.length > 2) props.handlesearchText(_val);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.handlesearchText(searchText);
              }
            }}
            autoComplete="off"
          />
          <div className="input-group-append input-search-clear">
            {searchText && searchText.length > 0 ? (
              <a
                href="#"
                className="input-search-clear-icon"
                title="Clear"
                onClick={(e) => {
                  e.preventDefault();
                  setSearchText("");
                  localStorage.removeItem("triggerLogSearchText");
                  props.handlesearchText("");
                }}
              >
                <i className="ico icon-remove"></i>
              </a>
            ) : (
              <></>
            )}

            <button
              className="btn btn-default"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                props.handlesearchText(searchText);
              }}
            >
              <i className="ico icon-search-fill"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="float-right ml-2">
        <div className="filter-item-btn">
          <a href="#" onClick={(e) => {
            e.preventDefault();
            props.excelDownload(props?.counts?.total)
          }} title={'Excel Export'}>
            <i className="ico icon-excel-download"></i>
          </a>
          <ExcelFile ref={props.csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`triggerlogs_${moment().format('DD_MM_YYYY_HH_mm')}`}>
            <ExcelSheet dataSet={props.csvData} name={`triggerlogs`} />
          </ExcelFile>
        </div>
      </div>
      <div className="float-right">
        <Dropdown ref={node} alignRight show={filterShow}>
          <div className="advanced-filter-btn mr-2 float-right">
            <Dropdown.Toggle
              as={CustomToggle}
              className="dropdown"
              onClick={() => {
                setShow(!filterShow);
              }}
            >
              {filterCounts()}
              <i className="ico icon-filter"></i>Filters
            </Dropdown.Toggle>
          </div>
          <Dropdown.Menu
            as={CustomFilter}
            id="table-reports-cols-settings-pipeline"
            className="dropdown-menu dropdown-menu-left"
            ChildClass="form-style quick-filter-wrap"
            xplacement="bottom-end"
          >
            <div className="quick-filter-body mt-1 h-100 overflow-inherit">
              <div className="report-filter-wraper form-style">
                <div className="form-row">
                  {/* <div className="form-group col-md-12">
                    <label>Date</label>
                    <AntDateRangePicker
                      value={
                        filterDate
                          ? [
                              moment.unix(filterDate.startDate.seconds),
                              moment.unix(filterDate.endDate.seconds),
                            ]
                          : null
                      }
                      id={"dashboard-date-range"}
                      name={"filterDate"}
                      format="DD/MM/YYYY"
                      onChange={(e) => {
                        handleDateRangeChange(e, "daterange");
                      }}
                      placeholder="DD/MM/YYYY"
                    />
                  </div> */}
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Stats</label>
                    <ReactMultiSelect
                      options={EVENTS}
                      isMulti={true}
                      isSearchable={true}
                      name={"filterClient"}
                      onChange={(option) =>
                        handleTriggerMultiSelectChange(option)
                      }
                      placeholder={"select stats"}
                      value={filterClient}
                      classNamePrefix={`basic-select`}
                      removeClearable={false}
                    ></ReactMultiSelect>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Triggers</label>
                    <ReactMultiSelect
                      options={props.activeTitle}
                      name={"filterTitle"}
                      placeholder={"select trigger"}
                      isMulti={true}
                      isSearchable={true}
                      onChange={(option) =>
                        handleTitleMultiSelectChange(option)
                      }
                      value={filterTitle}
                      classNamePrefix={`basic-select`}
                      removeClearable={false}
                    ></ReactMultiSelect>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Status</label>
                    <ReactSelect
                      options={optionsStatus}
                      name={"filterStatus"}
                      placeholder={"select status"}
                      onChange={(option) => handleStatusChange(option)}
                      value={filterStatus}
                      classNamePrefix={`basic-select`}
                      removeClearable={false}
                    ></ReactSelect>
                  </div>
                </div>
              </div>
            </div>
            <div className="quick-filter-footer">
              <button
                type="button"
                className="btn btn-default float-left"
                onClick={() => {
                  setShow(false);
                  setShowCount(false);
                  setFilterClient("");
                  setFilterTitle("");
                  //setFilterDate(null);
                  props.handleClearFilter();
                }}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-primary float-right"
                onClick={() => {
                  // setRefreshData(true)
                  setShow(false);
                  setShowCount(true);
                  props.handleApplyFilter();
                }}
              >
                Apply
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {
        props?.counts?.total > 0 ? (
          <div className="pipeline-counter mr-3">
            <div className="counter-nav cursor-pointer">
              <ul>
                {
                  Object.keys(props.counts).map((c, i) => {
                    return props?.counts[c] > 0 ? (
                      <li key={i}><a href="#" className={`counter-${c}`} onClick={(e) => {
                        e.preventDefault();
                        if (c === 'total') {
                          return;
                        } else if (c === 'sent' || c === 'pending' || c === 'failed') {
                          setFilterStatus(_.find(optionsStatus, { value: c }));
                          props.handleFilterStatus(_.find(optionsStatus, { value: c }));
                        } else {
                          setFilterClient([c]);
                          props.handleFilterClient([c]);
                        }
                        setShow(false);
                        setShowCount(true);
                        props.handleApplyFilter();
                      }}><Translate text={c} /> <span className="badge badge-pill badge-counter ml-1">{props.counts[c]}</span></a></li>
                    ) : (<Fragment key={i}></Fragment>)
                  })
                }
              </ul>
            </div>
          </div>
        ) : (<></>)
      }
    </div>
  );
};

export default FilterPanel;
