/** LIBRARIES */
import React, { useState, useContext } from 'react';
import _ from 'lodash';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import toast from 'toasted-notes'
import { ContentOverlay } from '../../../../components';
import { MainContext } from '../../../layout/provider';


const RingcentralSettings = props => {
	const [data, setData] = useState();
	const [loader, setloader] = useState(true);
	const { updateRingcentralModel } = useContext(MainContext)

	const handleSignIn = () => {
		// const callbackURL = (window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com") ? "https://app.fusionamspro.com/api/ringcentral/callback" : "https://qa.fusionamspro.com/api/ringcentral/callback";
		// const authURL = `${props.dealersettings.ringcentralConfig.endpoint}/restapi/oauth/authorize?client_id=${props.dealersettings.ringcentralConfig.client_id}&redirect_uri=${callbackURL}&state=${props.dealersettings.id}&response_type=code`
		// window.location.replace(authURL)
		updateRingcentralModel(true);

	}

	useEffect(() => {
		const ringCentralDoc = window.firebase
			.firestore()
			.doc(`users/${localStorage.uid}/ringcentral/${localStorage.uid}`)
			.onSnapshot(snap => {
				if (snap.exists) {
					setData(snap.data())
				}
				else {
					setData(null)
				}
				setloader(false)
			});
		return () => {
			ringCentralDoc && ringCentralDoc();
		}
	}, [])

	const handleDisable = (e) => {
		e.preventDefault();
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'You want to disable?.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then(result => {
			if (result.value) {
				if (document.querySelector("#rc-widget-adapter-frame")) {
					document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
						type: 'rc-adapter-logout'
					}, '*');
				}
				window.firebase
					.firestore()
					.doc(`users/${localStorage.uid}/ringcentral/${localStorage.uid}`)
					.delete()
					.then(snap => {
						toast.notify('Account disabled successfully', {
							duration: 2000
						})
						setData({});
					})
			}
		})
	}

	return (
		<>
			<div className='email-settings-inner form-style'>
				<div className='email-sync-wrap'>
					{
						loader ? (
							<div className='mt-5'>
								<ContentOverlay active={true} />
							</div>
						) : (
							<>
								<h1><Translate text={"Ringcentral Authentication"} /></h1>
								<p><Translate text={"Link or disable your ringcentral account below"} />
								</p>
								<div className='email-active-sync'>
									<div className='email-active-sync-item active-tab'>
										<div className='email-sync-status'>
											{
												data?.enabled === true ? (
													<button
														type='button'
														className='btn btn-common float-right'
														onClick={(e) => handleDisable(e)}
													>
														<Translate text={'disable'} />
													</button>
												) : (
													<button
														type='button'
														className='btn btn-common float-right'
														onClick={() =>
															handleSignIn()
														}
													>
														{
															_.isEmpty(data) ? <Translate text={'enable'} /> :
																<Translate text={'Reauthenticate'} />
														}

													</button>
												)
											}
										</div>
										<h4 className='d-flex align-items-center'>
											<span className='email-active-logo' style={{ width: '30px' }}>
												<i
													className="ico icon-call"
													style={{
														height: '40px',
														width: '40px',
														fontSize: '26px',
													}}
												></i>
											</span>
											{data?.phoneNumber ? data?.phoneNumber : props.dealersettings.name ? props.dealersettings.name : 'User has no name'}
										</h4>
									</div>
								</div>
							</>
						)
					}
				</div>
			</div>
		</>
	)
};

export default RingcentralSettings;
