export const templates = [
    { name: 'Enquiry Video', value: 'enquiryVideo', collection: 'genericTemplates' },
    { name: 'Lead Assign', value: 'leadAssign', collection: 'alerts' },
    { name: 'Finance Lead Assign', value: 'financeleadAssign', collection: 'alerts' },
    { name: 'After Market Lead Assign', value: 'aftermarketleadAssign', collection: 'alerts' },
    { name: 'On Mention', value: 'onMention', collection: 'genericTemplates' },
    { name: 'Lost Approve/Reject', value: 'lostApproval', collection: 'genericTemplates' },
    { name: 'On Tradein Link Respond', value: 'submitTradein', collection: 'genericTemplates' }
]

export const specialLinks = [
    {
        type: 'Enquiry Link',
        label: 'SpecialLink.EnquiryLink',
        link: '@ENQUIRY_LINK'
    },
    {
        type: 'Video Link',
        label: 'SpecialLink.VideoryLink',
        link: '@VIDEO_LINK'
    },
    {
        type: 'Facebook URL',
        label: 'Link.Facebook',
        link: '@FACEBOOK_URL'
    },
    {
        type: 'X URL',
        label: 'Link.X',
        link: '@X_URL'
    },
    {
        type: 'Instagram URL',
        label: 'Link.Instagram',
        link: '@INSTAGRAM_URL'
    },
    {
        type: 'Youtube URL',
        label: 'Link.Youtube',
        link: '@YOUTUBE_URL'
    }];

export const mentions = [{
    name: 'First Name',
    value: '@FIRST_NAME'
},
{
    name: 'Last Name',
    value: '@LAST_NAME'
},
{
    name: 'Preferred Name',
    value: '@PREF_NAME'
},
{
    name: 'Full Name',
    value: '@FULL_NAME'
},
{
    name: 'Customer Email',
    value: '@CUSTOMER_EMAIL'
},
{
    name: 'Customer Phone',
    value: '@CUSTOMER_PHONE'
},
{
    name: 'Company Name',
    value: '@COMPANY_NAME'
},
{
    name: 'User Name',
    value: '@USER_NAME'
},
{
    name: 'From User Name',
    value: '@FROM_USER_NAME'
},
{
    name: 'From User Profile',
    value: '@FROM_USER_PROFILE'
},
{
    name: 'User Email',
    value: '@USER_EMAIL'
},
{
    name: 'User Phone',
    value: '@USER_PHONE'
},
{
    name: 'User Profile',
    value: '@USER_PROFILE'
},
{
    name: 'Dealer Name',
    value: '@DEALER_NAME'
},
{
    name: 'Dealer Phone',
    value: '@DEALER_PHONE'
},
{
    name: 'Dealer Email',
    value: '@DEALER_EMAIL'
},
{
    name: 'Dealer Address',
    value: '@DEALER_ADDRESS'
},
{
    name: 'Facebook URL',
    value: '@FACEBOOK_URL'
},
{
    name: 'X URL',
    value: '@X_URL'
},
{
    name: 'Instagram URL',
    value: '@INSTAGRAM_URL'
},
{
    name: 'YouTube URL',
    value: '@YOUTUBE_URL'
},
{
    name: 'Dealer Logo',
    value: '@DEALER_LOGO'
},
{
    name: 'Enquiry Link',
    value: '@ENQUIRY_LINK'
},
{
    name: 'Video Link',
    value: '@VIDEO_LINK'
},
{
    name: 'Current Month',
    value: '@CURRENT_MONTH'
},
{
    name: 'Notes',
    value: '@NOTES'
},
{
    name: 'Title',
    value: '@TITLE'
},
{
    name: 'Vehicle Make',
    value: '@REQ_MAKE'
},
{
    name: 'Vehicle',
    value: '@REQ_VEHICLE'
},
{
    name: 'Vehicle Reg#',
    value: '@VEHICLE_REGO'
},
{
    name: 'Schedule Date',
    value: '@SCHEDULE_DATE'
},
{
    name: 'Schedule Time',
    value: '@SCHEDULE_TIME'
}]