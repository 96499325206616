import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import ReactSummernote from 'react-summernote';
import SummerEditor from '../../../mail/summereditor/summereditor';
import uuid from 'react-uuid';

const HTMLTextEditor = (props) => {
    const [item, setItem] = useState(props.item);
    const [text, setText] = useState(
        props.item ? props.item.text : '<div></div>',
    );
    const newItem = _.isEmpty(props.item) ? true : false;

    useEffect(() => {
        if (props.item) {
            var node = document.createElement('div');
            node.innerHTML = `${props.item.text}`;
            ReactSummernote.insertNode(node);
        }
    }, [props.item]);

    useEffect(() => {
        if (_.isEmpty(props.item)) {
            setItem({
                id: uuid(),
                text: text,
                type: 'text',
                defaultPos: { x: Math.round(props.displayWidth / 2), y: Math.round(props.displayHeight / 2) },
            })
        }
    }, [])

    const handleChange = obj => {
        setText(obj.replace(/(<p)/igm, '<div').replace(/<\/p>/igm, '</div>'))
    };

    return <Modal
        show={props.show}
        onHide={() => { props.handleClose(newItem ? null : item, newItem) }}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-add-emailtemplates tv-modal-editor-popup">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={'Add Text'} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">
                    <div className="form-style w-100">
                        <div className='form-group col-md-12 react-summer-editor tv-text-editor'>
                            <SummerEditor
                                value={text}
                                height={150 + 'px'}
                                handleChange={handleChange}
                                emailType={'compose'}
                                templateMentionsEnabled={true}
                                removeImage={true}
                                formMentions={[
                                    {
                                        name: 'Schedule Date',
                                        value: '@DD-MM-YYYY'
                                    },
                                    {
                                        name: 'Schedule Time',
                                        value: '@HH:MM'
                                    }, {
                                        name: 'Schedule Datetime',
                                        value: '@SCHEDULE_DATETIME'
                                    }]}
                                customFonts={props.dealersettings.customFonts}
                                tvTemplate={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={(e) => {
                props.handleClose({
                    ...item,
                    text
                }, newItem);
            }
            }>
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose(newItem ? null : item, newItem);
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>;
}

export default HTMLTextEditor;