import React, { useState, useEffect, useReducer, useMemo, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';


import { ContentOverlay, DealerSelect, PopUpModal, TableView, PpsrScan } from '../../components'
import Translate from '../../constants/translate';
import FilterPanel from './filterPanel'
import EnquiryListReducer from '../fleet/fleetReducer'
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import { allUnallocatedFields, allAllocatedFields } from './viewModel'
import { objEnquiryListVM } from '../pipeline/viewModel'



const PpsrListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, { unallocated: [], allocated: [] })
    const [checkDataLoad, setDataload] = useState({ "unallocated": true, "allocated": false })
    const [settingsLoader, setSettingsLoader] = useState(false);
    const [unallocatedFields, setUnallocatedFields] = useState([])
    const [allocatedFields, setAllocatedFields] = useState([])
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])

    const [activeTab, setActiveTab] = useState('unallocated')

    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.inBoundTIPSearchText ? localStorage.inBoundTIPSearchText : null)
    const [enquiryLoader, setEnquiryLoader] = useState({ "unallocated": true, "allocated": false })

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [pipelineCount, setPipelineCount] = useState(0)
    const [inboundCount, setInboundCount] = useState({ unallocated: 0, allocated: 0 })

    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showPpsrScan, setShowPpsrScan] = useState({ show: false, vinNo: null, regNo: null })

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const respMins = 15



    useEffect(() => {
        let headerFields = (activeTab === 'unallocated') ? unallocatedFields : allocatedFields;
        let allHeaderFields = (activeTab === 'unallocated') ? allUnallocatedFields : allAllocatedFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [unallocatedFields, activeTab])


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubPPSRAllocList && window.unsubPPSRAllocList();
            //window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        }
    }, [])

    useEffect(() => {
        if (localStorage.ppsrFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.ppsrFilter])



    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (props.pipeline.length === 0) {
            return
        }

        if (isFilterApplied && pageNum === 0) {
            setEnquiryLoader({
                ...enquiryLoader,
                [activeTab]: true
            })
        }


        // if ((isFilterApplied || localStorage.ppsrFilter) && isValidFilter()) {
        //     let _pipelineFilter = JSON.parse(localStorage.ppsrFilter);
        //     let _filter = Object.assign({}, _pipelineFilter.value);

        //     //_filter.pipeline = activeTab === 'unallocated' ? 'LeadsBucket' : null;
        //     _filter.inbound = true;
        //     _filter.inboundType = activeTab

        //     if (!viewOtherEnquiry)
        //         _filter.owner = dealersettings.id;

        //     /* DATE RANGE FILTER */
        //     if (!_.isEmpty(_filter.date)) {
        //         _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
        //         delete _filter['date'];
        //     }

        //     /* MAKE & MODEL FILTER */
        //     if (!_.isEmpty(_filter.make))
        //         _filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

        //     if (_filter.rottenDays &&
        //         !_.isEmpty(dealersettings) &&
        //         !_.isEmpty(dealersettings.client) &&
        //         !_.isEmpty(dealersettings.client.settings) &&
        //         !_.isEmpty(dealersettings.client.settings.pipelines)) {
        //         _filter.pipelineSettings = JSON.stringify(dealersettings.client.settings.pipelines)
        //     }

        //     _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, 'INBOUND', null, true);
        //     _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
        //     let _searchObject = {
        //         "type": "searchEnquiries",
        //         "filters": JSON.stringify(_filter),
        //         "sortOrder": activeTab === 'unallocated' ? "addedDate asc" : "stageDate desc",
        //         "pageNum": pageNum,
        //         "pageLimit": pageLimit
        //     }

        //     _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, 'INBOUND', null, true);

        //     console.log('filter-searchEnquiries', _searchObject, pageNum, _filter);
        //     const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        //     searchEnquiries(_searchObject).then((response) => {
        //         //console.log('generic-searchData', _rec, response);
        //         if (response.data.success && localStorage.ppsrFilter && isValidFilter()) {
        //             onCollectionEnquiry(response.data);
        //             setInboundCount({
        //                 [activeTab]: response.data.total
        //             })
        //             setPipelineCount({
        //                 advance: {
        //                     total: response.data.total
        //                 }
        //             })
        //         }
        //         else {
        //             setEnquiryLoader({
        //                 ...enquiryLoader,
        //                 [activeTab]: false
        //             })
        //             setDataload({
        //                 ...checkDataLoad,
        //                 [activeTab]: false
        //             })
        //             setPaging(false)
        //         }
        //     });

        //     window.unsubPPSRAllocList && window.unsubPPSRAllocList();
        //     window.unSubPipelineLstCount && window.unSubPipelineLstCount();
        // }
        // else {

        let searchKeywords = searchText;
        let searchOwners = [];

        if (localStorage.ppsrFilter) {
            let _pipelineFilter = JSON.parse(localStorage.ppsrFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
            searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

            setSearchText(searchKeywords);
            localStorage.inBoundTIPSearchText = searchKeywords;
        }

        let refEnquiryData = firestoreDB(props.dealersettings).firestore().collection('ppsr')
            .where('clientID', '==', dealersettings?.client.id)


        if (searchKeywords) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
            if (formattedSearchText.trim())
                refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
        }

        refEnquiryData = refEnquiryData.orderBy('logDate', 'desc');

        // if (activeTab === 'allocated') {
        //     refEnquiryData = refEnquiryData
        //         .where('status', '==', 1)
        //     //.orderBy('stageDate', 'desc');
        // }
        // else {
        //     refEnquiryData = refEnquiryData
        //         .where('status', '==', 0)
        //         .orderBy('addedDate', 'desc');

        // }

        let lastRecord = hasMore.filter(e => e.type === activeTab);
        if (lastRecord.length > 0) {
            refEnquiryData = refEnquiryData
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            refEnquiryData = refEnquiryData
                .limit(pageLimit)
        }

        window.unsubPPSRAllocList = refEnquiryData
            .onSnapshot(onCollectionUpdate);
        // }


    }, [checkDataLoad, activeTab])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertEnquiryVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })

        let _enquiries = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                {
                    type: activeTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]

                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            }
            querySnapshot.forEach((doc) => {
                _enquiries.push(convertEnquiryVM({
                    ...doc.data(),
                    documentID: doc.id,
                }));
            });
        }


        // if (activeTab === 'unallocated')
        //     _enquiries = _enquiries.filter(m => m.stage !== 'Archived')

        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: _enquiries,
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",//"SUCCESS_LIST",//"APPEND_LIST",
                data: actionType ? snapshotDoc : _enquiries,
                activetab: activeTab
            });
        }


        setEnquiryLoader({
            ...enquiryLoader,
            [activeTab]: false
        })
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setPaging(false)
    }



    const convertEnquiryVM = (doc) => {

        let dealersettings = props.dealersettings;
        doc.logDate = doc.logDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.logDate._seconds)._d) : doc.logDate;

        const listVM = Object.assign({}, doc);
        listVM.objDoc = Object.assign({}, doc);
        listVM.createdOn = doc.logDate ? moment.unix(doc.logDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.owner = CommonHelper.getUserNamebyId(allUsers, doc.owner);

        listVM.stolen = !_.isEmpty(listVM?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.StolenDetails?.StolenDetail) ? 'Yes' : (listVM.success ? 'No' : '--');
        listVM.wov = !_.isEmpty(listVM?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.WrittenOffDetails?.WrittenOffDetail) ? 'Yes' : (listVM.success ? 'No' : '--');

        listVM.status = listVM.success ? <span className={`status-won`}>{'Success'}</span> : <span className={`status-lost`}>{'Fail'}</span>;

        if (!_.isEmpty(listVM?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.VehicleDetail?.VehicleDescription)) {
            let _VehicleDescription = Object.assign({}, listVM?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.VehicleDetail?.VehicleDescription)
            let _VehicleRegistration = Object.assign({}, listVM?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.VehicleDetail?.Registration)

            listVM.regNo = _VehicleRegistration.PlateNumber ? _VehicleRegistration.PlateNumber : ''
            listVM.bodyType = _VehicleDescription.BodyType ? _VehicleDescription.BodyType : ''
            listVM.colour = _VehicleDescription.Colour ? _VehicleDescription.Colour : ''
            listVM.complianceYearMonth = _VehicleDescription.ComplianceYearMonth ? _VehicleDescription.ComplianceYearMonth : ''
            listVM.engineNumber = _VehicleDescription.EngineNumber ? _VehicleDescription.EngineNumber : ''
            listVM.make = _VehicleDescription.Make ? _VehicleDescription.Make : ''
            listVM.manufactureYear = _VehicleDescription.ManufactureYear ? _VehicleDescription.ManufactureYear : ''
            listVM.model = _VehicleDescription.Model ? _VehicleDescription.Model : ''
            listVM.vehicleType = _VehicleDescription.VehicleType ? _VehicleDescription.VehicleType : ''
            listVM.vehicleModel = listVM.make + ' ' + listVM.model + ' ' + listVM.manufactureYear + ' ' + listVM.bodyType
        }

        return listVM
    }

    const handleFavorite = (id, val) => {

    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchText && searchText.length > 2) {
                setDataload({
                    ...checkDataLoad,
                    [activeTab]: true
                })
            }

            if (searchText === '')
                setDataload({
                    ...checkDataLoad,
                    [activeTab]: true
                })
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    const handleSearchText = (val) => {
        setSearchText(val);

        setEnquiryLoader({
            ...enquiryLoader,
            [activeTab]: true
        });
        setPaging(false);
        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
        setPageNum(0);


    }

    const handleSetActiveTab = (val) => {
        if (activeTab !== val) {
            setActiveTab(val);
            setDataload({
                ...checkDataLoad,
                [val]: true
            })
            setHasMoreData([...hasMore.filter(e => e.type !== val)]);
            setPageNum(0);
            setEnquiryLoader({
                ...enquiryLoader,
                [val]: true
            });
            setSelectedIds({});
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('ppsrFilter', JSON.stringify(_filter));
            //localStorage.inBoundTIPSearchText = '';
            setFilterApplied(true);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            //setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.ppsrFilter)) {
            localStorage.removeItem('ppsrFilter');
            localStorage.inBoundTIPSearchText = '';
            setFilterApplied(false);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
            setPaging(false);
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            setPageNum(0);
            setSearchText('');
        }
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }

    const handleActionClick = (id, obj) => {

        return <>
            {
                obj.certURL
                    ?
                    <div className="fleet-btn-loan ppsr-width-fix">
                        <button className="btn btn-sm btn-secondary" style={{ cursor: 'pointer' }}
                            onClick={(e) => { e.preventDefault(); window.open(obj.certURL, '_blank'); }}> <i className="ico icon-mail-draft"></i> PPSR Report</button>
                    </div>
                    :
                    <div className="filter-item-btn ppsr-width-fix">
                        <a href="#" title="Generate New PPSR" style={{ width: '130px', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); handleOpenPPSR(obj); }}>
                            <i className="ico icon-refresh"></i>
                        </a>
                    </div>
            }

        </>

    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        //handleAllocateData(id, true);


    }
    const handleOpenPPSR = (obj) => {
        setShowPpsrScan({ show: true, vinNo: obj?.vinNo, regNo: null });
    }

    return (
        <div className="middle-wrapper">

            <FilterPanel
                pipeline={props.pipeline}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                stageCounts={pipelineCount}
                inboundCount={inboundCount}
                csvHeader={csvHeader}
                csvData={csvData}
                //datalist={enquiries}
                view="list"
                isFilterApplied={isFilterApplied}
                searchText={searchText}
                handlesearchText={(val) => {
                    handleSearchText(val);
                }}
                //excelDownload={excelDownload}
                csvBtn={csvBtn}
                pipelineView={null}
                activeTab={activeTab}
                handleSetActiveTab={handleSetActiveTab}
                selectedIds={selectedIds}
                handleOpenPPSR={handleOpenPPSR}
            />

            <div className="calendar-panel">
                {
                    enquiryLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        enquiries[activeTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={false}
                                    datalist={_.orderBy(enquiries[activeTab], ['sortDate.seconds'], ['desc'])}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={
                                        (activeTab === 'unallocated')
                                            ?
                                            allUnallocatedFields
                                            :
                                            allAllocatedFields
                                    }
                                    //handleSavesettings={handleSavesettings}
                                    dynamicFields={
                                        (activeTab === 'unallocated')
                                            ? (unallocatedFields && unallocatedFields.length > 0) ? [...unallocatedFields.filter(item => item !== 'checkbox'), 'checkbox'] : allUnallocatedFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                            : (allocatedFields && allocatedFields.length > 0) ? [...allocatedFields.filter(item => item !== 'checkbox')] : allAllocatedFields.filter(e => e.default === true && e.type !== 'checkbox').map(v => _.pick(v, ['value']).value)
                                    }
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    isSettings={false}
                                    hasMore={hasMore.find(e => e.type === activeTab)}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    activityTab={activeTab}
                                    selectedIds={selectedIds}

                                    isDisplayByValue={true}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
                }


            </div>

            <PopUpModal show={showPpsrScan.show}>
                <PpsrScan
                    show={showPpsrScan.show}
                    regNo={showPpsrScan.regNo}
                    chassisNo={showPpsrScan.vinNo}
                    clsActive='overlay-modal active'
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    fromList={true}
                    handleClose={() => { setShowPpsrScan({ show: false, vinNo: null, regNo: null }) }}
                >
                </PpsrScan>
            </PopUpModal>


        </div>
    )
}

export default PpsrListView;