/** LIBRARIES */
import React from 'react';
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import CommonHelper from "../../services/common";

const LoanBookingViewItem = React.memo(({ id, addedBy, LoanBookingTag, LoanBookingLog, dealersettings, handleLoanBookingOpen, clientUsers }) => {
    return (
        (!_.isEmpty(dealersettings))
            ?
            <>
                <div className="dashboard-activity-loop" id={`activity-${id}`}>
                    <div className="dashboard-activity-list">
                        {
                            LoanBookingLog && !_.isEmpty(LoanBookingLog.request) && (LoanBookingLog.request.Make || LoanBookingLog.request.Model) ? (<>
                                <div className="dashboard-activity-title">
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleLoanBookingOpen(e, LoanBookingLog, LoanBookingTag) }}>
                                    {!_.isEmpty(LoanBookingLog.request) && (LoanBookingLog.request.Make || LoanBookingLog.request.Model) ? `${LoanBookingLog.request.Make ? LoanBookingLog.request.Make : ''} ${LoanBookingLog.request.Model ? LoanBookingLog.request.Model : ''}` : ''}
                                    </a>
                                </div>
                            </>) : (<></>)
                        }
                        
                        {
                            LoanBookingLog && !_.isEmpty(LoanBookingLog.contact) ? (<>
                                <div className="dashboard-activity-user">
                                    <i className="ico icon-Contacts"></i> {CommonHelper.displayContactName([], LoanBookingLog.contact)}
                                </div>
                                {
                                    LoanBookingLog.contact.clientID && (LoanBookingLog.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (<span className="tbl-sub-dealer">{CommonHelper.getOtherDealerName(dealersettings, LoanBookingLog.contact.clientID)}</span>) : (<></>)
                                }
                            </>) : LoanBookingLog && !_.isEmpty(LoanBookingLog.staff) ? (<>
                                <div className="dashboard-activity-user">
                                    <i className="ico icon-Contacts"></i> {LoanBookingLog.staff.name ? LoanBookingLog.staff.name + ' (staff)': ''}
                                </div>
                            </>) : (<></>)
                        }

                        <div className="dashboard-activity-date w-100 d-inline-block">

                            <span className={`${LoanBookingTag === 'Overdue' ? '' : LoanBookingTag === 'Today' ? '' : ''}`}>
                                {
                                    LoanBookingLog.appointmentOn ? LoanBookingLog.appointmentOn : ''
                                }
                            </span> {LoanBookingLog.fleetAddedBy ? ` - ${CommonHelper.getUserNamebyId(clientUsers, LoanBookingLog.fleetAddedBy)}` : ``} </div>

                    </div>
                </div>
            </>
            :
            <></>

    )
})
export default LoanBookingViewItem;

