/** LIBRARIES */
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */

import CustomerReview from './customerReview'
import DynamicForm from './dynamicForm'
import CustomerDelivery from './customerDelivery'


class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
 
                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/customer/rating/:id" exact render={(match) => <CustomerReview enquiryID={match.match.params.id} />} />
                        <Route path="/customer/form/:id" exact render={(match) => <DynamicForm id={match.match.params.id} />} />
                        <Route path="/customer/delivery/:id" exact render={(match) => <CustomerDelivery id={match.match.params.id} />} />
                    </Switch>
                </Suspense>

         );
    }
}

export default MainContainer;