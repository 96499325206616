/** LIBRARIES */
import React, { Component } from 'react';
import moment from "moment";
import _ from 'lodash';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'
/** VIEW-MODELS */
import { cafeStatus } from '../../../services/enum'

export default class CafeOrderLIst extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cafePopOver: { showpopover: false, targetpopover: null },
            cafeTiming:
            {
                show: false,
                title: '',
                message: ''
            }
        }
    }

    handleCancel = (id) => {
        //console.log('handleCancel', id);
        this.props.handleCancel(id);
    }

    handleReOrder = (id) => {
        this.props.handleReOrder(id);
    }

    componentDidMount() {
        this.checkTiming();
    }

    checkTiming = () => {
        const { dealersettings } = this.props;
        let workingHours = dealersettings?.client?.cafeSettings?.workingHours ?? null;
        let _currentDay = moment().day();
        if (_.isEmpty(workingHours) || (!_.isEmpty(workingHours) && _.isEmpty(workingHours[_currentDay]))) {
            this.setState({ cafeTiming: { show: true } })
        }
        else if (!_.isEmpty(workingHours) && !_.isEmpty(workingHours[_currentDay]) && workingHours[_currentDay]?.from && workingHours[_currentDay]?.to) {
            let _workingHours = {
                ['startworkingHours']: moment(moment().format('YYYY-MM-DD' + ' ' + workingHours[_currentDay].from))._d,
                ['endworkingHours']: moment(moment().format('YYYY-MM-DD' + ' ' + workingHours[_currentDay].to))._d
            }
            let currentTime = moment()._d;
            if (_workingHours.startworkingHours <= currentTime && currentTime <= _workingHours.endworkingHours)
                this.setState({ cafeTiming: { show: true } })
            else {
                this.setState({ cafeTiming: { show: false, title: 'Sorry, We Are Closed.', message: `Working time is from ${moment(_workingHours.startworkingHours).format('h:mm A')} to ${moment(_workingHours.endworkingHours).format('h:mm A')}` } })
            }
        }
        else
            this.setState({ cafeTiming: { show: false, title: 'Sorry, We Are Closed.' } })
    }


    render() {
        const { cafe, clientUsers, isDeivered, groupUsers } = this.props;
        const { cafeTiming } = this.state;
        const divStyle = {
            maxHeight: '225px',
            overflow: 'auto'
        };
        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
        //console.log('this.props.cafe', cafe);
        return (
            <>
                {
                    this.props.cafe
                        ?
                        <>
                            < div className="popover-content" >
                                {/* <h3> Order To
                                    {
                                        (customer && typeof customer === 'object' && Object.keys(customer).length > 0)
                                            ?
                                            <span className="float-right">{customer.firstName + ' ' + customer.lastName}</span>
                                            :
                                            <>
                                                {
                                                    (staff && typeof staff === 'object' && Object.keys(staff).length > 0)
                                                        ?
                                                        <span className="float-right">{staff.name}</span>
                                                        :
                                                        <span className="float-right"></span>
                                                }
                                            </>

                                    }
                                </h3> */}
                                <div style={divStyle}>
                                    {
                                        cafe.orders && cafe.orders.map(order =>
                                            <div key={order.documentID} className="cafe-popover-loop">
                                                <div className="divTable">
                                                    <div className="divTableBody">
                                                        <div className="divTableRow">
                                                            <div className="divTableCell width90">
                                                                <div className="cafe-popover-content">
                                                                    <div className="cafe-popover-image">
                                                                        <img src={order.image} alt="" className="img-object-fit" /> {order.name} </div>
                                                                </div>
                                                            </div>
                                                            <div className="divTableCell width10">
                                                                <div className="cafe-popover-status float-right"> {order.quantity} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!_.isEmpty(order.subItems) && (<div className="cafe-item-note-custom mt-2 pl-2 pr-2 pb-1">
                                                    {order.subItems.map((item) => (
                                                        <div className={`cafe-item-multi-notes`} key={item.id}>{item.value}</div>
                                                    ))}
                                                </div>)}
                                            </div>
                                        )

                                    }
                                </div>
                                <div className="cafe-delivery-notes">
                                    <h3> <Translate text={'notes'} />
                                        <span className="float-right">
                                            {CommonHelper.getUserNamebyId(allUsers, cafe.addedBy, '--')}
                                            {/* {cafe.addedBy && clientUsers.filter(e => e.id === cafe.addedBy).length > 0 ? clientUsers.filter(m => m.id === cafe.addedBy)[0].name : '--'} */}
                                        </span>
                                    </h3>
                                    <textarea
                                        className={'notes cafe-popover-notewrap'}
                                        name={'orderNotes'}
                                        onChange={(e) => { e.preventDefault(); }}
                                        value={cafe.orderNotes ? cafe.orderNotes : ''}
                                        readOnly={true}
                                    >
                                    </textarea>
                                </div>
                                {
                                    cafe.deliveredNotes
                                        ?
                                        <div className="cafe-delivery-notes">
                                            <h3>
                                                <Translate text={(cafe.status === cafeStatus.DELIVERED || cafe.status === cafeStatus.READY) ? 'deliveryNotes' : 'cancelledNotes'} />
                                                <span className="float-right">
                                                    {CommonHelper.getUserNamebyId(allUsers, cafe.deliveredBy, '--')}
                                                    {/* {cafe.deliveredBy && clientUsers.filter(e => e.id === cafe.deliveredBy).length > 0 ? clientUsers.filter(m => m.id === cafe.deliveredBy)[0].name : '--'} */}
                                                </span>
                                            </h3>
                                            <textarea
                                                className={'notes cafe-popover-notewrap'}
                                                name={'deliveredNotes'}
                                                onChange={(e) => { e.preventDefault(); }}
                                                value={cafe.deliveredNotes ? cafe.deliveredNotes : ''}
                                                readOnly={true}
                                            >
                                            </textarea>
                                        </div>
                                        :
                                        <></>

                                }

                            </div >
                            <div className="popover-footer">
                                {
                                    cafe.status === cafeStatus.PENDING
                                        ?
                                        <button type="submit" className="btn btn-sm btn-red" onClick={(e) => { e.preventDefault(); this.handleCancel(cafe.documentID); }}><Translate text={'cancelOrder'} /></button>
                                        :
                                        <>
                                            {
                                                isDeivered
                                                    ?
                                                    <>
                                                    </>
                                                    :
                                                    <>
                                                        <button
                                                            type="submit"
                                                            className={`btn btn-sm btn-secondary ${cafeTiming.show ? '' : 'btn-disable'}`}
                                                            onClick={(e) => { e.preventDefault(); if (cafeTiming.show) this.handleReOrder(cafe.documentID); }}>
                                                            <Translate text={'reorder'} />
                                                        </button>

                                                        {
                                                            cafeTiming.show
                                                                ?
                                                                <></>
                                                                :
                                                                <div className="cafe-alert-reorder">{cafeTiming.title}</div>

                                                        }
                                                    </>
                                            }
                                        </>


                                }

                            </div>

                        </>
                        :
                        <></>

                }
            </>

        );
    }
}
