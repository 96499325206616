import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { default as _images } from '../../../images';
import DisplayReportContacts from './list/displayContacts'
import { PopUpModal } from '../../../components';

const Report = ({ contacts, data, setActiveTab, contactsLoader, complainedContacts, campaign, refresh_token, client_id, client_secret, apiUrl }) => {
	const [activeSubTab, setActiveSubTab] = useState('overview');
	const [displayRecipients, setDisplayRecipients] = useState({
		show: false,
		title: '',
		type: ''
	});

	const handleShowContacts = (e, type, title) => {
		setDisplayRecipients({
			show: true,
			title,
			type
		})
	}

	return (
		<>
			<div className='campaign-view-wrapper-head'>
				<div className='float-left'>
					<div className='campaign-add-title'>{data ? data.nameText : ''} </div>
					<div className='campaign-add-sub-title'>
						<div className={`badge badge-pill float-left badge-${campaign.status === 'completed'
							? 'available'
							: (campaign.status === 'initialising' || campaign.status === 'pending' || campaign.status === 'sending')
								? 'pending'
								: (campaign.status === 'failed' || campaign.status === 'paused' || campaign.status === 'cancelled')
									? 'draft'
									: 'draft'
							}-fill `}
						>
							{campaign.status ? campaign.status : ''}
						</div>
						<span className='float-left campaign-add-date'>
							{' '}
							Delivered on {moment(campaign.CreationDate).format('DD/MM/YYYY hh:mm A')}{' '}
						</span>
					</div>
				</div>
				<div className='float-right campaign-tab-align'>
					<div className='campaign-tab-switch center-fix'>
						<ul className='campaign-tabs-list'>
							<li
								className='campaign-tab-item '
								onClick={() => setActiveTab('detail')}
							>
								Campaign Details
							</li>
							<li
								className='campaign-tab-item campaign-tab-active'
								onClick={() => setActiveTab('report')}
							>
								Report
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='campaign-view-wrapper' style={{ minHeight: '800px' }}>
				<div className='tabs'>
					<div className='tabs-wraper'>
						<nav>
							<Tabs
								transition={false}
								className='nav-fill'
								defaultActiveKey={activeSubTab}
								onSelect={k => setActiveSubTab(k)}
							>
								<Tab eventKey='overview' title='Overview'>
									<div className='campaign-view-tab-header'>
										<div className='float-left'>
											<h2>Campaign Overview</h2>
										</div>
									</div>

									<div className='campaign-view-tab-middle mt-3'>
										<div className='card campaign-custom-card'>
											<div className='card-header'>Activity</div>
											<div className='card-body'>
												<div className='row text-center'>
													<div className='col'>
														<div className='campaign-counter' onClick={e => {
															setDisplayRecipients({
																show: true,
																api: 'Recipients',
																title: 'Recipients'
															})
														}}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignRecipients}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Recipients</p>
															<h2 className='campaign-count-title campaign-count-number'>
																{campaign.send_contact_count}
															</h2>
														</div>
													</div>

													<div className='col'>
														<div className='campaign-counter' onClick={e => {
															setDisplayRecipients({
																show: true,
																api: 'Views',
																title: 'Opened'
															})
														}}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignOpen}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Opened</p>
															<h2 className='campaign-count-title'>{campaign.opened_count}</h2>
														</div>
													</div>

													<div className='col'>
														<div className='campaign-counter' onClick={e => {
															setDisplayRecipients({
																show: true,
																api: 'Unsubscriptions',
																title: 'Unsubscriptions'
															})
														}}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignUnSubscribed}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Unsubscribed</p>
															<h2 className='campaign-count-title'>{campaign.unsubscribe_count}</h2>
														</div>
													</div>

													<div className='col'>
														<div className='campaign-counter' onClick={e => {
															setDisplayRecipients({
																show: true,
																api: 'Clicks',
																title: 'Clicks'
															})
														}}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignProfile}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Clicks</p>
															<h2 className='campaign-count-title campaign-count-number'>
																{campaign.clicks_count}
															</h2>
														</div>
													</div>

													<div className='col'>
														<div className='campaign-counter' onClick={e => {
															setDisplayRecipients({
																show: true,
																api: 'Bounces',
																title: 'Bounces'
															})
														}}>
															<div className='campaign-count-icon'>
																<img
																	src={_images.campaignComplain}
																	alt=''
																	height='70'
																/>
															</div>
															<p className='campaign-count-text'>Bounces</p>
															<h2 className='campaign-count-title campaign-count-number'>
																{campaign.bounces_count}
															</h2>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* <div className='card campaign-custom-card mt-4'>
											<div className='card-header'>Activity Over Time</div>
											<div className='card-body'>Graph here</div>
										</div> */}
									</div>

									<div className='campaign-sub-section-div mt-4'>
										<div className='row'>
											<div className='col-sm-12'>
												<div className='card campaign-custom-card h-100'>
													<div className='card-header'>
														Sends
													</div>
													<div className='card-body'>
														<div className='card-group'>
															<div className='card'>
																<div className='card-body'>
																	<h5 className='card-title'>Delivered</h5>
																	<p className='card-text'>
																		<span className='campaign-percentage'>
																			{Math.round((100 * (campaign.send_contact_count - campaign.bounces_count)) / campaign.send_contact_count).toFixed(2)}%
																		</span>
																	</p>
																</div>
															</div>
															<div className='card'>
																<div className='card-body'>
																	<h5 className='card-title'>Bounced</h5>
																	<p className='card-text'>
																		<span className='campaign-percentage'>
																			{Math.round((100 * campaign.bounces_count) / campaign.send_contact_count).toFixed(2)}%
																		</span>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</Tab>
							</Tabs>
						</nav>
					</div>
				</div>
			</div>
			<PopUpModal show={displayRecipients.show}>
				<DisplayReportContacts
					{...displayRecipients}
					handleClose={() => { setDisplayRecipients({ show: false, title: '', type: '' }) }}
					campaign={campaign}
					refresh_token={refresh_token}
					client_id={client_id}
					client_secret={client_secret}
					apiUrl={apiUrl}
				/>
			</PopUpModal>
		</>
	);
};

export default Report;
