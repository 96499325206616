import React, { useState, useEffect, useReducer, useCallback } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { ContentOverlay, PopUpModal } from '../../components'
import CommonHelper from '../../services/common'
import { default as _images } from '../../images';

import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';
import BookingListReducer from '../fleet/fleetReducer'
import { testDriveBookingStatus } from '../../services/enum';
import TestDriveBooking from '../testdrive/testDriveBookings';

const TestDriveBookings = (props) => {
    const [loadingData, setLoadingData] = useState(true)
    const [tdBookings, dispatch] = useReducer(BookingListReducer, { Requested: [], Booked: [] })
    const acitivtyTags = ["Requested", "Booked"]
    const [activeTab, setActiveTab] = useState('Requested')

    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState({ "Requested": true, "Booked": true })
    const [pagingLoader, setPagingloader] = useState(false)
    const pageLimit = 10

    const [loadingCount, setCountsLoader] = useState(false)
    const [bookingCounts, setCounts] = useState({})
    const [pageNum, setPageNum] = useState({ Requested: 0, Booked: 0, Upcoming: 0 });

    const [showBookingModal, setshowBookingModal] = useState({
        show: false,
        docID: null
    })

    useEffect(() => {
        return () => {
            window.unsubdashboardtdBookings && window.unsubdashboardtdBookings();
        }
    }, [])

    useEffect(() => {

        let dataload = { "Requested": true, "Booked": true }
        setHasMoreData([])
        dispatch({
            type: "CLEAR_ALL"
        });
        setPageNum({ Requested: 0, Booked: 0 })
        setDataload({
            ...dataload,
            [activeTab]: true
        })

    }, [])

    useEffect(() => {

        fetchCounts()
    }, [loadingCount])

    const fetchCounts = async () => {

        let _filter = {
            clientID: props.dealersettings.client.id,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        let _searchObject = {
            type: "getTestdriveBookingCount",
            params: JSON.stringify(_filter),
        }
        //setCountsLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp leadsBucket', resp)
            // console.log('getActivitesCount',resp)
            const data = resp.data.data[0]
            //console.log(`data of the counts`, data)
            setCounts(data)
            setCountsLoader(false)
        } else {
            setCountsLoader(false)
        }
    }

    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        setPagingloader(true)
        let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('testdriveBookings')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('status', '==', (activeTab === 'Requested' ? 'requested' : 'booked'))
        //.orderBy('startDate', 'asc')
        //.where('isDeleted', '==', false)

        //.limit(pageLimit)
        let lastRecord = hasMore.filter(e => e.type === activeTab);
        if (lastRecord.length > 0) {
            refActivitiesData = refActivitiesData
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            refActivitiesData = refActivitiesData
                .limit(pageLimit)
        }

        window.unsubdashboardtdBookings = refActivitiesData
            .onSnapshot(onCollectionUpdate);
    }, [checkDataLoad, activeTab])


    const onCollectionUpdate = async (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = {
                    ...change.doc.data(),
                    documentID: change.doc.id
                };
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        const _list = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                {
                    type: activeTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            }
            querySnapshot.forEach(function (doc) {
                const logVM = Object.assign({}, doc.data());
                logVM.documentID = doc.id;
                logVM.contact = !_.isEmpty(logVM?.contact) ? logVM.contact : logVM?.enquiry?.contact;
                _list.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : "SUCCESS_APPEND_LIST",
            data: actionType ? snapshotDoc : _list,
            activetab: activeTab
        });
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoadingData(false)
        setPagingloader(false)
    }

    const showDetails = (booking) => {

        if (booking.status === testDriveBookingStatus.BOOKED && booking.enquiryID && props.sidepanelOpen) {
            props.sidepanelOpen(booking.enquiryID)
        }
        else {
            setshowBookingModal({
                show: true,
                docID: booking.documentID
            });
        }

    }

    const closeBookingModal = (enqID) => {
        if (enqID) {
            setTimeout(() => {
                fetchCounts();
            }, 2000)
        }
        setshowBookingModal({ show: false, docID: null });
    }

    return (
        <>
            <div className="dashboard-box">
                {
                    loadingCount ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button mr-1" onClick={(e) => { e.preventDefault(); setCountsLoader(true); }}> <i className="ico icon-refresh"></i></a>
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }

                    </div>
                    <h3><Translate text={`Test Drive Bookings`} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="dashboard-activities-wrap" style={{ minHeight: `350px`, height: 'calc(100% - 0px)' }}>
                        {
                            loadingData ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (
                                    <>
                                        <div className="dashboard-activities-nav pl-0">
                                            <ul>
                                                {
                                                    acitivtyTags.map((rec, index) => {
                                                        return <li key={index}>
                                                            <a href="#"
                                                                id={rec}
                                                                className={`nav-li-${rec} ${activeTab === rec ? 'active' : ''}`}
                                                                onClick={(event) => {
                                                                    if (event.target.id) {
                                                                        setActiveTab(event.target.id)
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            >{<Translate text={rec} />}
                                                                {!_.isEmpty(bookingCounts) ?
                                                                    rec === 'Requested' ? (<span className="badge badge-pill badge-widget-counter ml-1">{bookingCounts.requested ? bookingCounts.requested : '0'}</span>)
                                                                        : rec === 'Booked' ? (<span className="badge badge-pill badge-widget-counter ml-1">{bookingCounts.booked ? bookingCounts.booked : '0'}</span>)
                                                                            : (<></>)
                                                                    : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            tdBookings[activeTab].length > 0 ? (
                                                <>
                                                    <div className="dashboard-activity-item pr-2" style={{ height: 'calc(100% - 90px)' }}>
                                                        {
                                                            tdBookings[activeTab].map((booking, index) => {
                                                                return <div key={index} className="dashboard-activity-loop booking-border" id={`testdriveBookings-${booking.documentID}`}>



                                                                    <div className="dashboard-testdrive-activity-list" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        showDetails(booking);
                                                                    }}>
                                                                        <div className="dashboard-testdrive-vehicleimage-wrap">
                                                                            <img src={CommonHelper.showBrandLogo(props.dealersettings, booking?.vehicle?.make)} className="img-object-fit-contain" />
                                                                        </div>
                                                                        <div className="dashboard-testdrive-activity-info w-100">
                                                                            {
                                                                                !_.isEmpty(booking?.vehicle) && (booking.vehicle.make || booking.vehicle.model) ? (<>
                                                                                    <h2>{!_.isEmpty(booking.vehicle) && (booking.vehicle.make || booking.vehicle.model) ?
                                                                                        `${booking.vehicle.make ? booking.vehicle.make : ''} ${booking.vehicle.model ? booking.vehicle.model : ''}` : ''}
                                                                                    </h2>
                                                                                </>) : (<></>)
                                                                            }

                                                                            {
                                                                                !_.isEmpty(booking?.contact) && (<>
                                                                                    <div className="dashboard-testdrive-activity-customer">
                                                                                        <i className="ico icon-Contacts"></i> {CommonHelper.displayContactName([], booking.contact)}
                                                                                    </div>
                                                                                    {
                                                                                        booking.contact.clientID && (booking.contact.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem') && props.dealersettings.group ? (<span className="tbl-sub-dealer">{CommonHelper.getOtherDealerName(props.dealersettings, booking.contact.clientID)}</span>) : (<></>)
                                                                                    }
                                                                                    <div className="dashboard-testdrive-activity-customer-email">
                                                                                        {booking?.contact.phone ? <> <i className="ico icon-call mr-2"></i>{CommonHelper.getFullPhone(booking?.contact.phoneCode, booking?.contact.phone)}</> : '--'}
                                                                                        {booking?.contact.email ? <> <i className="ico icon-email mr-2 ml-2"></i>{booking?.contact.email}</> : '--'}

                                                                                    </div>
                                                                                </>)}
                                                                            {
                                                                                !_.isEmpty(booking?.vehicle) ? (<>
                                                                                    <div className="dashboard-testdrive-activity-column">
                                                                                        Stock#: <div title={CommonHelper.showLocale(props, 'stockNo')}>{booking?.vehicle?.stockNo || '--'}</div>
                                                                                        {booking?.vehicle?.extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'exteriorColor')}>{booking?.vehicle?.extColor}</div></>)}
                                                                                        {booking?.vehicle?.year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'year')}>{booking?.vehicle?.year}</div></>)}
                                                                                        {booking?.vehicle?.regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale(props, 'registrationNumber')}>{booking?.vehicle?.regNo}</div></>)}
                                                                                        {booking?.vehicle?.vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale(props, 'vinNo')}>{booking?.vehicle?.vinNo}</div></>)}
                                                                                    </div>
                                                                                </>) : (<></>)
                                                                            }

                                                                            <div className="dashboard-testdrive-activity-date">
                                                                                {
                                                                                    booking.addedDate ? <span title={CommonHelper.showLocale(props, 'createdDate')}><i className="ico icon-date"></i> {moment.unix(booking.addedDate.seconds).format('DD/MM/YYYY hh:mm A')}</span> : <></>
                                                                                }
                                                                                {booking.owner ? <>  - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), booking.owner)}</> : <></>}
                                                                                {/* <span>11/05/2024 10:32 AM</span> - Hafeez Ahmed</div> */}

                                                                                {booking.status && _.isString(booking.status) && (
                                                                                    <div className="float-right">
                                                                                        <div className={`badge badge-pill badge-${booking.status.toLowerCase()}`}>{booking.status.split(/(?=[A-Z])/).join(' ')}</div>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            (!pagingLoader && tdBookings[activeTab].length == 0) ? (<><div className="dashboard-activity-item" >
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> <Translate text={activeTab === 'Requested' ? 'No Request' : 'No Data'} /> </div>
                                                </div>
                                            </div></>) : (<></>)
                                        }
                                        {
                                            pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                                hasMore.filter(e => e.type === activeTab).length > 0 ?
                                                    (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeTab]: true
                                                        })
                                                    }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                                    : (<></>)
                                        }
                                    </>)
                        }
                    </div>
                </div>
            </div>
            <PopUpModal show={showBookingModal.show}>
                <TestDriveBooking
                    {...props}
                    {...showBookingModal}
                    handleClose={closeBookingModal}
                />
            </PopUpModal>
            {/* <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={selectedActivitylog}
                    enquiry={enquiryObj}
                    contact={contactObj}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={isNewActivity}
                    originType={props.originType}
                >
                </AddActivity>
            </PopUpModal> */}

        </>
    );
}

export default TestDriveBookings;