/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { ReactSelect, InputText, EvalSearch, PopUpModal } from '../../components';

export default class RegStatus extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            fields: {
                regNum: (this.props.regNo ? this.props.regNo.toUpperCase() : ''),
                _state: (this.props.regState ? this.props.regState.toUpperCase() : ''),
            },
            errors: {},
            license_state: [],
            showInfo: { show: false, info: {} }
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = () => {
        const { dealersettings } = this.props;
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const client = dealersettings.client;

            let defaultCountry = client.countryCode ? client.countryCode.toUpperCase() : 'AU';

            let license_state = [];
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
            this.setState({ license_state: license_state })
        }
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.toUpperCase();
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name) });

    }


    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(data.name) });

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;

            }
        }
        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.searchEvalExpert();
        }
        else
            return formIsValid;
    };

    searchEvalExpert = () => {
        const { dealersettings } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';
        const objData = Object.assign({}, this.state.fields);
        this.isLoading = true;
        const evalXpertSearch = window.firebase.functions().httpsCallable(`autograb-regStatus`);
        evalXpertSearch({ clientID: clientID, regNo: objData.regNum, state: objData._state }).then((_response) => {
            this.isLoading = false;
            //console.log('autograb-regStatus', _response);
            //check for success response
            if (_response && _response.data && _response.data.success) {
                //this.props.handleClose(_response?.data?.data);
                this.setState({ showInfo: { show: true, info: Object.assign({}, _response?.data?.data) } })
            }
            else if (_response.data?.success === false) {
                if (_response.data?.message)
                    Swal.fire(_response.data.message, '', 'error')
                else
                    Swal.fire(CommonHelper.showLocale(this.props, 'Data not found!'), 'Search with different data.', 'error')
            }

        });

    }

    handleClose = () => {
        this.setState({ showInfo: { show: false, info: {} } })
    }

    handleApply = (_data) => {
        this.props.handleClose(_data);
    }


    render() {
        const { regNum, _state } = this.state.fields;
        const { show, isGenericLayout } = this.props;
        const { license_state, showInfo } = this.state;
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'Check Registration Status'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className={`container-fluid ${isGenericLayout ? 'pl-0 pr-0' : ''}`}>
                            <div className={`${isGenericLayout ? 'w-100 d-block border-0' : 'row'}`}>
                                <div className="search-pop form-style">
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'vehicleRegNo'} /></label>
                                            <InputText
                                                name="regNum"
                                                value={regNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'registration number'}
                                                className={`form-control ${this.state.errors["regNum"]}`}
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'state'} /></label>
                                            <ReactSelect
                                                options={license_state}
                                                name={"_state"}
                                                placeholder={'select state'}
                                                onChange={this.handleReactSelectChange}
                                                value={_state}
                                                classNamePrefix={`${this.state.errors["_state"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                            {this.isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'search'} />
                        </button>
                    </Modal.Footer>
                </Modal>

                <PopUpModal show={showInfo.show}>
                    <Modal
                        show={showInfo.show}
                        onHide={this.handleClose}
                        backdrop="static"
                        backdropClassName={showInfo.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-api-reg-popup">
                        <Modal.Header closeButton>
                            <Modal.Title><Translate text={'Registration Details'} /> </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="modal-api-reg-popup-body">
                                        <div className="modal-api-reg-popup-table">

                                            <table className="table-fill">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'vehicleRegNo'} /></td>
                                                        <td className="text-left">{showInfo?.info?.plate_number ? showInfo.info.plate_number : '--'} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'regState'} /></td>
                                                        <td className="text-left">{showInfo?.info?.state ? showInfo.info.state : '--'} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'chassisNo'} /></td>
                                                        <td className="text-left">{showInfo?.info?.vin ? showInfo.info.vin : '--'} </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'regStatus'} /></td>
                                                        <td className="text-left">{showInfo?.info?.registration_status ? showInfo.info.registration_status : '--'} </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'registrationExpiry'} /></td>
                                                        <td className="text-left">{showInfo?.info?.registration_expiry ? showInfo.info.registration_expiry : '--'} </td>
                                                    </tr>

                                                    {/* <tr>
                                                        <td className="text-left label-text">Manufacture Year</td>
                                                        <td className="text-left">2014</td>
                                                    </tr> */}

                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'complianceDate'} /></td>
                                                        <td className="text-left">{showInfo?.info?.compliance_plate ? showInfo.info.compliance_plate : '--'} </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                            <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleClose()}>
                                <Translate text={'cancel'} />
                            </button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); this.handleApply(showInfo.info) }}>
                                {this.isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'apply'} />
                            </button>
                        </Modal.Footer>

                    </Modal>
                </PopUpModal>
            </>


        );
    }
}

