import React, { useState, useEffect } from 'react';
import CommonHelper from '../../services/common'
import moment from 'moment'
import _ from 'lodash'
import Translate from '../../constants/translate';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import { Dropdown } from 'react-bootstrap'

const CustomAgendaView = (props) => {
    const { event, onSelectEvent, handleDeleteactivity } = props;
    const [eventType, setEventtype] = useState()

    useEffect(() => {
        if (event.isDone === true) {
            setEventtype('agenda-green')
        }
        else if (event.startDate < window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)) {
            setEventtype('agenda-red')
        }
        else {
            setEventtype('')
        }
    })

    return (
        (!_.isEmpty(props.dealersettings))
            ?
            <>
                <div className="calendar-activity-loop" >
                    <Dropdown className="calendar-activity-more" alignRight>
                        <Dropdown.Toggle as={CustomToggle} className="dropdown">
                            <i className="ico icon-more"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                            {
                                ((!_.isEmpty(event.searchUsers) && event.searchUsers.indexOf(props.dealersettings.id) >= 0) ||
                                    (!_.isEmpty(props.dealersettings.rolePermissions) &&
                                        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
                                        props.dealersettings.rolePermissions.permissions.modifyOtherActivity))
                                    ?
                                    <Dropdown.Item eventKey="1" onClick={(e) => { onSelectEvent(event) }}><i className="ico icon-edit"></i> <Translate text={'edit'} /> </Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="1" onClick={(e) => { onSelectEvent(event) }}><i className="ico icon-zoom"></i> <Translate text={'view'} /> </Dropdown.Item>

                            }
                            {
                                (!_.isEmpty(props.dealersettings.rolePermissions) &&
                                    !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
                                    props.dealersettings.rolePermissions.permissions.deleteActivity)
                                    ?
                                    <Dropdown.Item eventKey="2" onClick={(e) => { handleDeleteactivity(e, event) }}><i className="ico icon-delete"></i> <Translate text={'delete'} /> </Dropdown.Item>
                                    :
                                    <></>
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="calendar-activity-list">
                        <div className="calendar-activity-head">
                            <div className={`calendar-activity-title ${eventType}`}>
                                <div className="calendar-activity-icon">
                                    <i className={`ico ${CommonHelper.activityIconByValue(props.dealersettings, event.subType)}`}></i>
                                </div>
                                {
                                    ((!_.isEmpty(event.searchUsers) && event.searchUsers.indexOf(props.dealersettings.id) >= 0) ||
                                        (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.modifyOtherActivity))
                                        ?
                                        <a href="#" onClick={(e) => { e.preventDefault(); onSelectEvent(event) }}>{event.title}</a>
                                        :
                                        <>{event.title}</>
                                }
                            </div>
                            {
                                !_.isEmpty(event.contact) ? (<>
                                    <div className="calendar-activity-date font-weight-bold">  <a href="#" onClick={e => {
                                        e.preventDefault();
                                        props.history.push('/contacts/details/' + event.contact.documentID + '?back-url=/calendar');
                                    }}>{CommonHelper.displayContactIcon(event.contact, '')} {`${CommonHelper.displayContact([], event.contact, '--')}`}
                                    </a>
                                    </div>
                                    {
                                        event.contact.clientID && (event.contact.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem') && props.dealersettings.group ? (<span className="tbl-sub-dealer">{CommonHelper.getOtherDealerName(props.dealersettings, event.contact.clientID)}</span>) : (<></>)
                                    }
                                </>) : (<></>)
                            }

                            <div className="calendar-activity-date">{CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), event.owner)}</div>
                        </div>
                        {
                            event.notes && event.notes.trim().length > 0 ? (<div className="calendar-activity-notes text-area-space">{event.notes}</div>)
                                : (<></>)
                        }
                    </div>
                </div>
            </>

            :
            <></>


    )
}

export default CustomAgendaView