/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from 'moment';
import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any'
/** COMPONENTS */
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import { ReactSelect, InputTextArea, PopUpModal, ImageCropHook } from '../../components';
import { default as _images } from '../../images';
/** VIEW-MODELS */
import { objMark, mandatoryMark } from '../tradeIn/viewModel'
import { storageBucket } from "../../services/helper";

const markTypes = [
    { active: true, value: 'Info', label: <div><span className="badge badge-pill badge-pill-fix major-info ">i</span> <Translate text={'info'} /></div> },
    { active: true, value: 'MajorScratch', label: <div><span className="badge badge-pill badge-pill-fix major-scratch ">S</span> <Translate text={'major'} /> <Translate text={'scratch'} /></div> },
    { active: true, value: 'MinorScratch', label: <div><span className="badge badge-pill badge-pill-fix minor-scratch ">S</span> <Translate text={'minor'} /> <Translate text={'scratch'} /></div> },
    { active: true, value: 'MajorRust', label: <div><span className="badge badge-pill badge-pill-fix major-rust ">R</span> <Translate text={'major'} /> <Translate text={'rust'} /></div> },
    { active: true, value: 'MinorRust', label: <div><span className="badge badge-pill badge-pill-fix minor-rust ">R</span> <Translate text={'minor'} /> <Translate text={'rust'} /></div> },
    { active: true, value: 'MajorDent', label: <div><span className="badge badge-pill badge-pill-fix major-dent ">D</span> <Translate text={'major'} /> <Translate text={'dent'} /></div> },
    { active: true, value: 'MinorDent', label: <div><span className="badge badge-pill badge-pill-fix minor-dent ">D</span> <Translate text={'minor'} /> <Translate text={'dent'} /></div> },
    { active: true, value: 'MajorCrack', label: <div><span className="badge badge-pill badge-pill-fix major-crack ">C</span> <Translate text={'major'} /> <Translate text={'crack'} /></div> },
    { active: true, value: 'MinorCrack', label: <div><span className="badge badge-pill badge-pill-fix minor-crack ">C</span> <Translate text={'minor'} /> <Translate text={'crack'} /> </div> }
]

class ImageMarker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mark: Object.assign({}, objMark),
            errors: {},
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            imageURL: null,
            imageURLs: null
        }

        // this.handleCropSelect = this.handleCropSelect.bind(this);
        // this.handleCropClose = this.handleCropClose.bind(this);

    }

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            imageURL: null,
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropInsShow) => {

        const { src, id, ext } = cropInsShow;
        this.saveImageToStorage(src, id, ext);

        this.setState({
            imageURL: src,
            cropInsShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    // onSelectFile = (e, type, id, title) => {

    //     if (e.target.files && e.target.files.length > 0) {

    //         var _size = e.target.files[0].size;
    //         var filesize = _size / 1024 / 1024;
    //         if (filesize > 10) {
    //             Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
    //             return;
    //         }

    //         let reader = new FileReader();
    //         let file = e.target.files[0];
    //         //var extension = file.type
    //         reader.onloadend = () => {

    //             this.setState({
    //                 cropInsShow: {
    //                     show: true,
    //                     clsActive: 'overlay-modal active',
    //                     src: reader.result,
    //                     id: id,
    //                     type: type,
    //                     ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
    //                     title: title
    //                 }
    //             })
    //         }
    //         reader.readAsDataURL(file)
    //     }
    // }
    onSelectFile = async (e, type, id, title) => {
        const { isCompressedImage } = this.props;
        const files = e.target.files;

        if ((files && files.length > 1) || isCompressedImage) {
            this.setState({ imageURLs: Object.assign([], files) });
            const imageFile = []
            for (let [key, file] of Object.entries(files)) {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Maximum size of each file is 10MB'), '', 'info')
                    return;
                }

                if (file.name.toLowerCase().includes('.heic')) {
                    let _file = await CommonHelper.heic2Image(file);
                    imageFile.push(_file)
                } else if (filesize > 1) {
                    //console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

                    const options = {
                        maxSizeMB: 0.5,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    try {
                        const compressedFile = await imageCompression(file, options);
                        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                        imageFile.push(compressedFile)

                    } catch (error) {
                        console.log(error);
                    }
                }
                else
                    imageFile.push(file)
            }

            this.saveImagesToStorage(imageFile, id)

        } else if (files && files.length === 1) {

            var _size = files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = files[0];
            if (file.name.toLowerCase().includes('.heic')) {
                this.setState({ imageURL: _images.novehicle });
                file = await CommonHelper.heic2Image(file);
            }
            reader.onloadend = () => {
                this.setState({
                    cropInsShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }
    //#endregion

    //#region IMAGE UPLOAD

    saveImagesToStorage = async (filez, id) => {
        let promises = [];

        let files = [];
        for (let [key, file] of Object.entries(filez)) {
            files.push(file);
        }

        this.setState({ imageURLs: files });

        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        files.forEach((file, index) => {
            let uid = moment()._d.getTime().toString() + index + '.' + file.name.substr((file.name.lastIndexOf('.') + 1));
            let storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}/${uid}`);
            promises.push(
                storageRef.put(file).then((snapshot) => {
                    return snapshot.ref.getDownloadURL()
                })
            );
        });
        const snapshots = await Promise.all(promises)

        let state = Object.assign({}, this.state.mark);
        let fileURLS = Object.assign([], state[id]);


        snapshots.forEach(url => {
            fileURLS.push(url);
        })
        state[id] = fileURLS;

        this.setState({ mark: state, imageURLs: null })

    }

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        let state = Object.assign({}, this.state.mark);
                        let _data = Object.assign([], state[id]);
                        _data.push(dataURL);
                        state[id] = Object.assign([], _data);
                        this.setState({ mark: state, imageURL: null })
                    })
            })
    };

    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.mark);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                state.images = Object.assign([], _data);
                this.setState({ mark: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }


    //#endregion

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.mark);

        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ mark: fields }, () => { this.errorChange(name) });
        // var array1 = value.split(' ');
        // var str = value;

        // if (array1.length > 0) {
        //     var newarray1 = [];
        //     for (var x = 0; x < array1.length; x++) {
        //         newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
        //     }
        //     fields[name] = newarray1.join(' ');
        //     this.setState({ mark: fields });
        // } else {
        //     if (str && str.length >= 1) {
        //         var firstChar = str.charAt(0);
        //         var remainingStr = str.slice(1);
        //         str = firstChar.toUpperCase() + remainingStr;
        //     }
        //     fields[name] = str;
        //     this.setState({ mark: fields });
        // }
    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, this.state.mark);
        state[name] = e.floatValue;
        this.setState({ mark: state }, () => { this.errorChange(name) });
    }

    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.mark);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        this.setState({ mark: fields }, () => { this.errorChange(data.name) });
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.mark);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key] && mandatoryMark.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }
    componentDidMount() {
        const fields = Object.assign({}, objMark);
        const objData = Object.assign({}, this.props.mark);

        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }
        this.setState({ mark: fields })
    }

    bindMArkType = () => {
        const type = this.state.mark.markType;
        const { isReadOnly } = this.props;
        const title = isReadOnly ? <></> : <h5><Translate text={'addNotesImagesToMark'} /></h5>

        if (type === 'MajorScratch') {
            return <>
                <div className="marker-badge major-scratch">S</div>
                <div className="marker-title">
                    <h3 className="modal-title" ><Translate text={'major'} /> <Translate text={'scratch'} /></h3>
                    {title}
                </div>
            </>
        }
        else if (type === 'MinorScratch') {
            return <>
                <div className="marker-badge minor-scratch">S</div>
                <div className="marker-title">
                    <h3 className="modal-title" ><Translate text={'minor'} /> <Translate text={'scratch'} /></h3>
                    {title}
                </div>
            </>
        }
        else if (type === 'MajorRust') {
            return <>
                <div className="marker-badge major-rust">R</div>
                <div className="marker-title">
                    <h3 className="modal-title" ><Translate text={'major'} /> <Translate text={'rust'} /></h3>
                    {title}
                </div>
            </>
        }
        else if (type === 'MinorRust') {
            return <>
                <div className="marker-badge minor-rust">R</div>
                <div className="marker-title">
                    <h3 className="modal-title" ><Translate text={'minor'} /> <Translate text={'rust'} /></h3>
                    {title}
                </div>
            </>
        }
        else if (type === 'MajorDent') {
            return <>
                <div className="marker-badge major-dent">D</div>
                <div className="marker-title">
                    <h3 className="modal-title" ><Translate text={'major'} /> <Translate text={'dent'} /></h3>
                    {title}
                </div>
            </>
        }
        else if (type === 'MinorDent') {
            return <>
                <div className="marker-badge minor-dent">D</div>
                <div className="marker-title">
                    <h3 className="modal-title"><Translate text={'minor'} /> <Translate text={'dent'} /></h3>
                    {title}
                </div>
            </>
        }
        else if (type === 'MajorCrack') {
            return <>
                <div className="marker-badge major-crack">C</div>
                <div className="marker-title">
                    <h3 className="modal-title" ><Translate text={'major'} /> <Translate text={'crack'} /></h3>
                    {title}
                </div>
            </>
        }
        else if (type === 'MinorCrack') {
            return <>
                <div className="marker-badge minor-crack">C</div>
                <div className="marker-title">
                    <h3 className="modal-title" ><Translate text={'minor'} /> <Translate text={'crack'} /></h3>
                    {title}
                </div>
            </>
        }
        else {
            return <>
                <div className="marker-badge major-info">i</div>
                <div className="marker-title">
                    <h3 className="modal-title" ><Translate text={'info'} /></h3>
                    {title}
                </div>
            </>
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.mark);
        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key] && mandatoryMark.indexOf(key) >= 0) {
                formIsValid = false;
                errors[key] = errorClass;

            }
        }
        this.setState({ errors: errors });

        if (formIsValid === true) {
            const objData = Object.assign({}, fields);
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === 0 || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            this.props.handleClose(objData, this.props.index)
        }
        else
            return formIsValid;
    };

    handleDelete = (e) => {
        e.preventDefault();
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, "Do you want to delete this mark."),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                this.props.handleDelete(this.props.index)
            }
        })

    };


    render() {
        const { markType, notes, price, images } = this.state.mark;
        const { show, isReadOnly, dealersettings } = this.props;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const { imageURLs } = this.state;
        return (
            <>

                <Modal
                    id="add-marker"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-marker">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="marker-header">
                                {this.bindMArkType()}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    (isReadOnly && _.isEmpty(images))
                                        ?
                                        <></>
                                        :
                                        <div className="inspection-marker-wrap">
                                            <div className={`marker-image-wraper ${(_.isEmpty(images) && !this.state.imageURL && !imageURLs) ? 'inspection-none-image' : ''}`}>

                                                {
                                                    isReadOnly
                                                        ?
                                                        <></>
                                                        :
                                                        <div className="marker-item-image upload">
                                                            <label htmlFor="images2">
                                                                <img src={_images.addvehicle} alt="" htmlFor="images2" />
                                                                <input className="fileInput"
                                                                    type="file"
                                                                    name="images2"
                                                                    id="images2"
                                                                    accept="image/*"
                                                                    style={{ display: 'none' }}
                                                                    multiple={true}
                                                                    onChange={(e) => this.onSelectFile(e, 'inspection', 'images', 'Trade-In Inspection')} />
                                                            </label>
                                                        </div>
                                                }

                                                {images && images.map((data, index) => (
                                                    <div className="marker-item-image" key={index}>
                                                        {
                                                            isReadOnly
                                                                ?
                                                                <></>
                                                                :
                                                                <div className="image-delete img-delete-button" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.handleImageDel(index);
                                                                }}>
                                                                    <i className="ico icon-delete"></i>
                                                                </div>
                                                        }

                                                        <a data-fancybox={`mark_img`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                            <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                        </a>
                                                    </div>
                                                ))}
                                                {
                                                    (this.state.imageURL) ?
                                                        (
                                                            <div className="marker-item-image">
                                                                <div className="img-loader">
                                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                                            <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                    <img src={this.state.imageURL} width="70" height="70" alt="" />
                                                                </a>
                                                            </div>
                                                        )
                                                        :
                                                        <></>
                                                }
                                                {
                                                    (imageURLs) ?
                                                        <>
                                                            {
                                                                imageURLs.map((data, index) => (
                                                                    <div key={index} className="side-photos-item-image">
                                                                        <div className="img-loader">
                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                            <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                        </a>
                                                                    </div>
                                                                ))
                                                            }


                                                        </>
                                                        :
                                                        <></>
                                                }


                                            </div>
                                        </div>
                                }


                                <div className={`marker-form form-style ${(isReadOnly) ? 'form-disable' : ''}`}>
                                    {
                                        isReadOnly
                                            ?
                                            <></>
                                            :
                                            <div className="form-row ">
                                                <div className="form-group col-md-12">
                                                    <label ><Translate text={'markType'} />    </label>
                                                    <ReactSelect
                                                        options={markTypes}
                                                        name={"markType"}
                                                        placeholder={'select mark type'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={markType}
                                                        classNamePrefix={`${this.state.errors["markType"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                    }

                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'notes'} /></label>
                                            <InputTextArea
                                                className={`form-control notes ${this.state.errors["notes"]}`}
                                                name="notes"
                                                onChange={this.handleOnChange}
                                                value={notes}
                                                maxLength="280"
                                                rows="3">
                                            </InputTextArea>
                                        </div>
                                    </div>

                                    {
                                        this.props.showCost
                                            ?
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label ><Translate text={'damageCost'} /></label>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        allowNegative={true}
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        placeholder={currencySymbol}
                                                        className={`form-control ${this.state.errors["price"]}`}
                                                        onValueChange={this.onCurrencyChange.bind(this, 'price')}
                                                        value={price ? price : null} />
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {
                        isReadOnly
                            ?
                            <></>
                            :
                            <Modal.Footer className="modal-footer">
                                <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleDelete(e)}>
                                    <i className="ico icon-delete marker-delete"></i> <Translate text={'delete'} />
                                </button>

                                <button type="button"
                                    className={`btn btn-primary float-right ${(this.state.imageURL || imageURLs) ? 'form-disable' : ''}`}
                                    onClick={(e) => this.handleSubmit(e)}>
                                    <Translate text={'save'} />
                                </button>
                            </Modal.Footer>
                    }

                </Modal>

                <PopUpModal show={this.state.cropInsShow.show}>
                    <ImageCropHook
                        cropShow={this.state.cropInsShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>
                </PopUpModal>
            </>


        );
    }
}

export default ImageMarker;