import React from 'react';
import _ from 'lodash'
import CommonHelper from '../../services/common';

const NotifyToast = ({ payload, onClose, handleNotifyClick, currentClient, dealersettings }) => {

    return (<div className="notify-toast-wraper">
        <div className="notify-toast-close"><a href="#" onClick={onClose}><i className="ico icon-remove"></i> </a></div>
        <a className="notify-toast d-flex" href="#"
            onClick={(e) => {
                e.preventDefault();
                if (payload.data.type !== 'settings')
                    handleNotifyClick(payload.data.recordID, payload.data.type, payload.data.documentID, payload.data.enquiryID, payload.data.contactID, payload.data, dealersettings)
            }}>
            <div className="mr-3">
                <div className="notify-toast-circle"> <i className="ico icon-notification"></i> </div>
            </div>
            <div>
                <span className="notify-toast-header">{payload.notification.title}</span>
                <span className="notify-toast-info">{payload.notification.body}</span>
                {
                    (!_.isEmpty(currentClient)
                        && payload.notification.clientID !== currentClient
                        && !_.isEmpty(dealersettings)) ?
                        (<span className="notify-dealer">{CommonHelper.getDealerNamebyId(dealersettings.clients, payload.notification.clientID)}</span>) :
                        (<></>)
                }

            </div>
        </a>

    </div>)
}

export default NotifyToast