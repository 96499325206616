import moment from 'moment';
import _ from 'lodash'

// Date format will be
// 02 Aug 11:10 AM
export const convertMilisecondsTimeStampToDate = miliSecondsTime => {
	return moment(miliSecondsTime).format('DD MMM hh:mm A');
};

export const validateEmail = (email) => {
	let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return !_.isEmpty(email) ? regex.test(email) : false;
}

export const removeParamsFromURL = () => {
	const pathname = window.location.pathname
	if (window.history.pushState) {
		window.history.pushState(null, "title", `/settings`)
		window.history.pushState(null, "title", pathname)
	} else {
		window.history.replaceState(null, "", '/settings');
		window.history.replaceState(null, "", pathname);
	}
}

export const removeHTMLTags = html => {
	if (!html) {
		return ''
	}
	return html.replace(/<[^>]*>?/gm, '').replaceAll('&nbsp;', '')
}

export const firestoreDB = (dealersettings, isEvent) => {
	if (localStorage.defaultModule?.includes('eventsPro') || isEvent === true) {
		return window.firebase2;
	}
	else if (dealersettings?.client?.projectId === window.secondaryProjectId) {
		return window.firebase2;
	}
	return window.firebase;
}

export const storageBucket = (dealersettings, isEvent) => {
	if (localStorage.defaultModule?.includes('eventsPro') || isEvent === true) {
		return window.firebase.app().storage(`gs://${window.firebase.app().options.projectId}-eu`);
	}
	else if (dealersettings?.client?.projectId === window.secondaryProjectId) {
		return window.firebase.app().storage(`gs://${window.firebase.app().options.projectId}-eu`);
	}
	return window.firebase.storage();
}