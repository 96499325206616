import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ContentOverlay, TableView, PopUpModal } from "../../../../components";
import { vehicleMakeFields } from './viewModel'
import { ClientContext } from '../../../layout/clientContext'
import AddMake from "./addMake";
const Models = () => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
    const [loader, setLoader] = useState(true)
    const [searchText, setSearchText] = useState('');
    const [makes, setMakes] = useState([])
    const [showMakesPopup, setShowModel] = useState(false)
    const [selectedMake, setSelectedMake] = useState(null)
    const [popHeader, setPopHeader] = useState('')
    const { oemBrands } = useContext(ClientContext)

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 259)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])


    useEffect(() => {
        if (!_.isEmpty(oemBrands)) {
            let _makes = [];
            oemBrands.filter(({ name }) =>
                (!_.isEmpty(searchText) ? (
                    (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false)
                ) : true)
            ).forEach(rec => {
                _makes.push(rec)
            })
            setMakes(_.orderBy(_makes, ['addedDate.seconds'], ['desc']));
            setLoader(false);
        }
    }, [oemBrands, searchText])

    const hadleEditMake = (data) => {
        setSelectedMake(data)
        setPopHeader('Edit Make')
        setShowModel(true)
    }

    const handleAddMake = () => {
        setSelectedMake(null)
        setPopHeader('Add Make')
        setShowModel(true)
    }

    const handleClientdetailsClick = (e, id, data) => {
        e.preventDefault();
        hadleEditMake(data)
    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleEditMake(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>
                <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    hadleDeleteMake(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>
            </div>
        )
    }

    const handleModelClose = () => {
        setShowModel(false)
    }

    const hadleDeleteMake = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
               
                window.firebase.firestore().doc(`brands/${data.documentID}`)
                    .set({
                        isDeleted : true,
                        modifiedDate : window.firebase.firestore.Timestamp.now(),
                        modifiedBy : localStorage.uid
                    }, { merge: true })
                    .then(() => { 
                        toast.notify('Make deleted successfully', {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })

            }
        })
    }

    return (<>
        <div className="mt-2">
            <div className="settings-head">
                <div className="float-left">
                    <div className="filter-search search-icon">
                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                    </div>
                </div>
                <div className="float-right">
                    <div className="filter-add-button">
                        <button type="button" className="btn btn-primary" onClick={() => {
                            handleAddMake()
                        }}> <i className="ico icon-add"></i> Add Make</button>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    makes.length > 0 ? (
                        <div className="common-table">
                            <TableView
                                datalist={makes}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={vehicleMakeFields}
                                dynamicFields={vehicleMakeFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleClientdetailsClick}
                                isSettings={false}
                                isReorderable={false}
                            />
                        </div>
                    ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p>No makes found.</p>
                                    <button className="btn btn-sm btn-default" onClick={() => { handleAddMake(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                            Add Make
                            </button>
                                </div>
                            </div>
                        )
            }
        </div>
        <PopUpModal show={showMakesPopup}>
            <AddMake
                show={showMakesPopup}
                title={popHeader}
                handleClose={handleModelClose}
                make={selectedMake}
                makes={makes}
            />
        </PopUpModal>
    </>);
}

export default Models;