/** LIBRARIES */
import React, { Suspense, Component } from 'react';
import { NavLink, Redirect, Link } from 'react-router-dom';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'
import Swal from 'sweetalert2'
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import { default as _images } from "../../images";
import { AdminRoutes } from '../../constants/routes';


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clsActive: '',
            clsName: 'sidebar',

        }
    }


    handleLogout = () => {

        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to logout?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                window.firebase.auth().signOut()
                    .then(() => {
                        window.firebase2.auth().signOut();
                        localStorage.clear();
                        return <Redirect to="/login" />
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        })

    };

    sidepanelClose = () => {
        this.setState({
            clsActive: ''
        });
    };

    render() {
        const { clsActive, clsName } = this.state;

        return (
            <>

                <nav id="top-nav-bar" className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">

                    <NavLink to="/admin/dashboard">
                        <div className="navbar-logo">
                            <img src={_images.logo} width="56" height="25" alt="AMS" />
                        </div>
                    </NavLink>
                    <div className="collapse navbar-collapse">

 
                            <ul className="navbar-nav mr-auto nav-fix">
                                {
                                    AdminRoutes.map((item, index) => {
                                        return <li className="nav-item" key={index}>
                                            {
                                                item.route
                                                    ? (
                                                        <NavLink to={item.route} activeClassName={"active"}>
                                                            <div className="nav-link">
                                                                <i className={item.class}></i>
                                                                <span className="label">{item.name}</span>
                                                            </div>
                                                        </NavLink>
                                                    )
                                                    :
                                                    (<></>)
                                            }
                                        </li>
                                    })
                                }
                            </ul>
                   


                        <div className="float-right">


                            <ul className="profile-drop">
                                <Dropdown as="li" className="nav-item ml-3 profile" alignRight>
                                    <Dropdown.Toggle as={CustomToggle} className="nav-link dropdown-toggle user-profile">
                                        {/* <span className="badge badge-pill badge-que">1</span> */}
                                        <img src={_images.nouser} alt="User" />
                                        <div className="user-name">

                                            <div>{localStorage.userName}</div>
                                            <div className="dealership">{'Super Admin'}</div>


                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu} className="rm-pointers dropdown-menu-lg dropdown-menu-right" ChildClass="header-dropdown" xplacement="bottom-end">

                                        <Dropdown.Item eventKey="0" onClick={this.handleLogout}><i className="ico icon-logout"></i>{'Logout'}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ul>

                        </div>

                    </div>
                </nav>





            </>
        );
    }
}

export default Navbar;

