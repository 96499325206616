import React from 'react'
import _ from 'lodash'
import { Tabs, Tab } from 'react-bootstrap';
import ClientList from './list'
import GroupList from './groupList'
import Translate from '../../../../constants/translate';
import { UpgradePlan } from '../../../../components'
import CommonHelper from '../../../../services/common';

const Clients = (props) => {
  const liteVersion = ((!_.isEmpty(props.dealersettings) &&
    !_.isEmpty(props.dealersettings.client) &&
    !_.isEmpty(props.dealersettings.client.clientSettings) &&
    props.dealersettings.client.clientSettings.liteVersion) &&
    !Boolean(props.dealersettings.superAdmin) ? true : false);
  return (
    <>
      {
        liteVersion ? (<UpgradePlan />) : (<></>)
      }
      <h2><Translate text={'Company Settings'} /></h2>
      <div className={`settings-tab-wrap tabs ${liteVersion ? 'form-lite' : ''}`} style={liteVersion ? { height: `${window.innerHeight - 70}px` } : {}}>
        <div className='tabs-wraper'>
          <nav>
            <Tabs
              defaultActiveKey='clients'
              className='nav-fill'
              mountOnEnter={true}
            >
              <Tab eventKey='clients' title={CommonHelper.showLocale(props, 'Clients')}>
                <ClientList {...props} />
              </Tab>
              <Tab eventKey='groups' title={CommonHelper.showLocale(props, 'Groups')}>

                <GroupList {...props} />

              </Tab>
            </Tabs>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Clients