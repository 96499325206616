import React from 'react';
import StockPipeline from '../../stock/mclarenPipeline';

const OEMStockPipeline = ({
	dealersettings,
	clientUsers,
	groupUsers,
	history,
	updateDealerSettings,
}) => {
	return (
		<div className='middle-wrapper'>
			<StockPipeline
				clientUsers={clientUsers}
				groupUsers={groupUsers}
				history={history}
				dealersettings={dealersettings}
				updateDealerSettings={updateDealerSettings}
			/>
		</div>
	);
};

export default OEMStockPipeline;
