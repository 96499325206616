/** LIBRARIES */
import React, { PureComponent } from "react";

class InputTextArea extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const { id, name, value, onChange, placeholder, className, maxLength, rows, autoFocus } = this.props;
        //console.log('RENDER INPUT TEXT-AREA', name, value)
        return (
            <textarea
                id={id ? id : name}
                className={className}
                name={name}
                autoFocus={autoFocus ? autoFocus : false}
                onChange={onChange}
                placeholder={placeholder}
                value={value ? value : ''}
                onFocus={(e) => {
                    var temp_value = e.target.value
                    e.target.value = ''
                    e.target.value = temp_value
                }}
                maxLength={maxLength ? maxLength : "250"}
                rows={rows}
            >
                {value}
            </textarea>
        );
    }
}

export default InputTextArea;