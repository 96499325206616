
export const initialLayoutArr = [
    { x: 0, y: 0, w: 6, h: 11, i: 'MonthBookings', minW: 6, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 6, y: 0, w: 6, h: 11, i: 'PendingApproval', minW: 6, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 6, y: 0, w: 6, h: 11, i: 'FleetReturn', minW: 6, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
]

export const levelOptions = [
    { value: "region", label: "Region", index: 2 },
    { value: "subregion", label: "Subregion", index: 3 },
    { value: "group", label: "Group", index: 4 }
];