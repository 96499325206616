/** LIBRARIES */
import React, { Component, Suspense } from 'react';
/** PROVIDERS */
import { LayoutConsumer } from '../layout/provider';
/** COMPONENTS */
import List from './list'
import StockPipeline from './mclarenPipeline';

class Stock extends Component {

    render() {
        return (
            <LayoutConsumer>
                {({ dealersettings, clientUsers, groupUsers, updateDealerSettings }) => (

                    <Suspense >
                        <div className="middle-wrapper">
                            {
                                dealersettings.isMclaren === true ? (
                                    <StockPipeline
                                        clientUsers={clientUsers}
                                        groupUsers={groupUsers}
                                        history={this.props.history}
                                        dealersettings={dealersettings}
                                        updateDealerSettings={updateDealerSettings}
                                    />
                                ) : (
                                    <List
                                        clientUsers={clientUsers}
                                        groupUsers={groupUsers}
                                        history={this.props.history}
                                        dealersettings={dealersettings}
                                        updateDealerSettings={updateDealerSettings}
                                    />
                                )
                            }
                        </div>

                    </Suspense>
                )}
            </LayoutConsumer>
        );
    }

}
export default Stock;