import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

import { AntDatePicker, TextEditor, InputTextArea } from '../../../components';

import { optionsVM, genuineServicesVM, lessDepositVM } from '../viewModel'
import commonHelper from '../../../services/common'
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import Swal from 'sweetalert2';
import './quote.scss';
import { discount_type, service_type } from '../../../services/enum';

const QuoteForm = ({ history, serviceID, setActiveTab, setNewlySavedData, client, setClient, setClientSettings, clientSettings, currencySymbol, setCurrencySymbol, _type, _previewID, _stockID, newlySavedData }) => {
	const _useHistory = useHistory()
	const [errorFields, setErrorFields] = useState({});
	const [loader, setLoader] = useState(true);
	const [saveLoader, setSaveLoader] = useState(false);


	const [contact, setContact] = useState({});
	const [serviceData, setServieData] = useState({})
	const [contactData, setContactData] = useState()
	const [vehicleInfo, setVehicleInfo] = useState({});

	const [state, setState] = useState({
		gstPercentage: 0,
		subTotalExGST: 0,
		subTotalExGSTDiscounts: 0,
		gstPayable: 0,
		subTotalIncGST: 0,
		totalIncGST: 0,
		totalDeposit: 0,
		totalFreight: 0,
		totalDiscount: 0,
		payableOnDelivery: 0,
	});

	const [showservices, setShowservices] = useState(true)
	const [services, setservices] = useState([]);
	const [servicesInput, setservicesInput] = useState(genuineServicesVM);
	const [servicesEdit, setservicesEdit] = useState({
		editID: null,
		show: true,
		...genuineServicesVM
	})

	const [showdiscounts, setShowdiscounts] = useState(true)
	const [discounts, setdiscounts] = useState([]);
	const [discountsInput, setdiscountsInput] = useState(optionsVM);
	const [discountsEdit, setdiscountsEdit] = useState({
		editID: null,
		show: true,
		...optionsVM
	})

	const [showLessDeposit, setShowLessDeposit] = useState(true)
	const [lessDeposit, setLessDeposit] = useState([]);
	const [lessDepositInput, setLessDepositInput] = useState(lessDepositVM);
	const [LessDepositEdit, setLessDepositEdit] = useState({
		editID: null,
		show: true,
		...lessDepositVM
	})

	const [showFreight, setShowFreight] = useState(true)
	const [freight, setFreight] = useState([]);
	const [freightInput, setFreightInput] = useState(optionsVM);
	const [freightEdit, setfreightEdit] = useState({
		editID: null,
		show: true,
		...optionsVM
	})

	const [firestoreDB, setFirestoreDB] = useState()

	useEffect(() => {
		window.firebase.firestore().doc(`serviceJobs/${serviceID}`).get()
			.then(doc => {
				if (doc.exists) {
					setFirestoreDB(window.firebase)
				}
				else {
					setFirestoreDB(window.firebase2)
				}
			})
	}, [])

	const onEditorStateChange = (obj) => {
		setState({
			...state,
			notes: obj.value
		})
	}
	const handleOnChange = (e) => {
		e.preventDefault()
		const { name, value } = e.target;
		setState({
			...state,
			notes: value ? value : ''
		})
	}

	const parseIncomingDate = date => {
		// console.log(date)
		if (date.toDate) {
			return date
		} else {
			return window.firebase.firestore.Timestamp.fromDate(moment(date)._d)
		}
	};

	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return
		if (_.isEmpty(firestoreDB.auth().currentUser)) {
			firestoreDB.auth().signInWithEmailAndPassword('secondarydb@fusionamspro.com', 'secondaryDB@321')
				.catch((error) => {
					console.log('error on secondary firestore auth user', error)
				});
		}
	}, [firestoreDB])

	// fetch enqruiry details
	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return;
		const getData = async () => {
			// console.log('serviceID', serviceID);
			try {

				window.refCollectionData = docData(firestoreDB.firestore().doc(`serviceJobs/${serviceID}`))
					.pipe(
						switchMap(tradeIns => {
							//console.log(tradeIns);
							let _contactID = tradeIns.contactID ? tradeIns.contactID : tradeIns?.contact?.documentID
							const serviceIds = [serviceID];
							const enqIds = _contactID ? [_contactID] : [];
							const clientIds = [tradeIns.clientID];
							return combineLatest(
								of(tradeIns),
								combineLatest(
									enqIds.length > 0 ? enqIds.map(contactID =>
										collectionData(firestoreDB.firestore().collection('contactDetails').where('documentID', '==', contactID)).pipe(
											map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
										)
									) : of([])
								),
								combineLatest(
									serviceIds.length > 0 ? serviceIds.map(serviceID =>
										collectionData(firestoreDB.firestore().collection(`serviceJobs/${serviceID}/recommendations`)).pipe(
											map(recommendationSnapshot => recommendationSnapshot)
										)
									) : of([])
								),
								combineLatest(
									serviceIds.length > 0 ? serviceIds.map(serviceID =>
										collectionData(firestoreDB.firestore().collection(`serviceJobs/${serviceID}/servicePackages`)).pipe(
											map(servicePackagesSnapshot => servicePackagesSnapshot)
										)
									) : of([])
								),
								combineLatest(
									clientIds.length > 0 ? clientIds.map(clientID =>
										collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
											map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
										)
									) : of([])
								),
								combineLatest(
									clientIds.length > 0 ? clientIds.map(clientID =>
										docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
											map(clientSettingsSnapshot => clientSettingsSnapshot)
										)
									) : of([])
								),
							)
						}),
						map(([tradeIns, enquirySnapshot, recommendationSnapshot, servicePackagesSnapshot, clientSnapshot, clientSettingsSnapshot,]) => {
							let _contactID = tradeIns.contactID ? tradeIns.contactID : tradeIns?.contact?.documentID
							return {
								...tradeIns,
								contact: _contactID ? enquirySnapshot.find(a => a.documentID === _contactID) : tradeIns.contact,
								client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
								recommendations: recommendationSnapshot[0],
								servicePackages: servicePackagesSnapshot[0],
								currentSettings: clientSettingsSnapshot[0]
							}

						})
					)
					.subscribe(async (_serviceData) => {
						const contactData = _serviceData.contact;
						let clientData = _serviceData.client;
						const clientSettingsData = _serviceData.currentSettings;

						setServieData(_serviceData)
						setContactData(contactData)
						setCurrencySymbol((!_.isEmpty(clientData.currency) ? clientData.currency.symbol + ' ' : '$ '))
						setClient(clientData);
						setClientSettings(clientSettingsData);

						let stateToBeSet = {}



						if (_type === 'new' && _.isEmpty(newlySavedData)) {

							// IF SERVICE TYPE IS INTERNAL NO GST WILL BE CALCULATED
							const { serviceType, internalTaxExemption, taxPercentage, taxType, discount, discountType } = _serviceData;
							if ((serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType) {
								stateToBeSet = {
									...stateToBeSet,
									gstPercentage: taxPercentage,
									taxType: taxType
								}
							}
							else {
								stateToBeSet = {
									...stateToBeSet,
									gstPercentage: 0,
									taxType: 'GST'
								}
							}

							if (contactData.businessContact && !_.isEmpty(contactData?.company)) {
								let companyData = contactData?.company;
								setContact({
									name: companyData.name ? companyData.name : '',
									phone: companyData.phone ? companyData.phone : '',
									phoneCode: companyData.phoneCode ? companyData.phoneCode : '',
									email: companyData.email ? companyData.email : '',
									address: companyData.address ? companyData.address : '',
									businessContact: true,
								});
							} else {
								setContact({
									firstName: contactData.firstName ? contactData.firstName : '',
									middleName: contactData?.middleName || '',
									lastName: contactData.lastName ? contactData.lastName : '',
									phone: contactData.phone ? contactData.phone : '',
									phoneCode: contactData.phoneCode ? contactData.phoneCode : '',
									email: contactData.email ? contactData.email : '',
									dob: contactData.dob ? contactData.dob : '',
									licenseNo: contactData.licenseNo ? contactData.licenseNo : '',
									address: contactData.address ? contactData.address : '',
									language: contactData.language ? contactData.language : '',
									businessContact: false,
								});
							}
							let requirement = Object.assign({}, _serviceData.vehicle);

							let _vehicleReq = {
								make: requirement.make ? requirement.make : '',
								model: requirement.model ? requirement.model : '',
								type: requirement.type ? requirement.type : '',
								year: requirement.year ? requirement.year : '',
								rego: requirement.rego ? requirement.rego : (requirement.regNo ? requirement.regNo : ''),
								vinNo: requirement.vinNo ? requirement.vinNo : '',
								extColor: requirement.extColor ? requirement.extColor : '',
								odometer: requirement.odometer ? requirement.odometer : ''
							}
							setVehicleInfo(_vehicleReq);


							let _services = commonHelper.parseServiceInvoiceFromJob(_serviceData, _serviceData.recommendations, _serviceData.servicePackages, _serviceData.currentSettings?.services)
							setservices(_services);

							if (discount && discountType && !_.isEmpty(_services)) {
								let _totalCost = _services.reduce((accumulator, currentValue) => {
									return accumulator + (currentValue.amount ? currentValue.amount : 0);
								}, 0);

								if (_totalCost > 0) {
									let _actualDiscountAmount = parseFloat(discount)
									if (discountType === discount_type.percentage)
										_actualDiscountAmount = parseFloat((_totalCost * (discount)) / 100);

									setdiscounts([{
										id: `${new Date().valueOf().toString()}`,
										item: 'Discount',
										amount: _.round(_actualDiscountAmount, 2),
									}])

								}
							}

						} else if ((_type === 'edit' && _previewID) || (_type === 'new' && !_.isEmpty(newlySavedData))) {
							let quoteData = {}
							if (!_.isEmpty(newlySavedData)) {
								quoteData = newlySavedData
							} else {
								const quoteRef = firestoreDB.firestore().doc(`serviceJobs/${serviceID}/invoices/${_previewID}`);
								const quoteSnap = await quoteRef.get();
								quoteData = quoteSnap.data();
							}

							if (!_.isEmpty(quoteData.lessDeposit)) {
								quoteData.lessDeposit = quoteData.lessDeposit.map(item => {
									let _date = window.firebase.firestore.Timestamp.fromDate(new Date(item.date))
									return { ...item, date: _date }
								})
							}


							setContact(quoteData.contact);
							setVehicleInfo(quoteData.vehicle);
							stateToBeSet = {
								...stateToBeSet,
								gstPercentage: quoteData.gstPercentage,
								taxType: quoteData.taxType,
								subTotalExGST: quoteData.subTotalExGST,
								subTotalExGSTDiscounts: quoteData.subTotalExGSTDiscounts ? quoteData.subTotalExGSTDiscounts : 0,
								gstPayable: quoteData.gstPayable,
								subTotalIncGST: quoteData.subTotalIncGST,
								totalIncGST: quoteData.totalIncGST,
								totalDeposit: quoteData.totalDeposit,
								totalFreight: quoteData.totalFreight,
								totalDiscount: quoteData.totalDiscount,
								payableOnDelivery: quoteData.payableOnDelivery,
								displayID: quoteData.displayID,
								documentID: quoteData.documentID,
								serviceID: quoteData.serviceID,
								isDeleted: quoteData.isDeleted,
								addedBy: quoteData.addedBy,
								modifiedBy: quoteData.modifiedBy,
								addedDate: quoteData.addedDate,
								modifiedDate: quoteData.modifiedDate,
								notes: quoteData.notes ? quoteData.notes : " ",
								modifiedFrom: 'web',
							}
							setservices(quoteData.services ? quoteData.services : [])
							setLessDeposit(quoteData.lessDeposit ? quoteData.lessDeposit : [])
							setFreight(quoteData.freight ? quoteData.freight : [])
							//setTemplate({ bodyHTML: quoteData.notes ? quoteData.notes : ' ' })
							setdiscounts(quoteData.discounts ? quoteData.discounts : [])

						}

						setState(stateToBeSet)
						setLoader(false)

						window.refCollectionData && window.refCollectionData.unsubscribe()
						console.log('ServicePreview_tradeIns', _serviceData);

					});

			} catch (error) {
				console.error(error)
				setLoader(false)
			}
		};

		getData();
		// console.log('serviceID', serviceID);
	}, [serviceID, firestoreDB]);


	const loadCalculations = () => {
		//console.log('loadCalculations')

		let totalDiscount = 0
		let servicesTotal = 0
		let totalFreight = 0
		let totalDeposit = 0


		if (!_.isEmpty(discounts)) {
			totalDiscount = discounts.reduce((accumulator, currentValue) => {
				return accumulator + (currentValue.amount ? currentValue.amount : 0);
			}, 0);
		}

		if (!_.isEmpty(freight)) {
			totalFreight = freight.reduce((accumulator, currentValue) => {
				return accumulator + (currentValue.amount ? currentValue.amount : 0);
			}, 0);
		}

		if (!_.isEmpty(services)) {
			servicesTotal = services.reduce((accumulator, currentValue) => {
				return accumulator + (currentValue.amount ? currentValue.amount : 0);
			}, 0);
		}

		if (!_.isEmpty(lessDeposit)) {
			totalDeposit = lessDeposit.reduce((accumulator, currentValue) => {
				return accumulator + (currentValue.amount ? currentValue.amount : 0);
			}, 0);
		}


		totalDiscount = Math.round((totalDiscount + Number.EPSILON) * 100) / 100
		servicesTotal = Math.round((servicesTotal + Number.EPSILON) * 100) / 100
		totalFreight = Math.round((totalFreight + Number.EPSILON) * 100) / 100
		totalDeposit = Math.round((totalDeposit + Number.EPSILON) * 100) / 100

		let subTotalExGST = _.round(servicesTotal, 2);
		let subTotalExGSTDiscounts = subTotalExGST

		subTotalExGST = subTotalExGST - totalDiscount
		subTotalExGST = Math.round((subTotalExGST + Number.EPSILON) * 100) / 100

		let gstPercentage = state.gstPercentage
		let gstPayable = (gstPercentage / 100) * subTotalExGST;
		gstPayable = Math.round((gstPayable + Number.EPSILON) * 100) / 100

		let subTotalIncGST = gstPayable + subTotalExGST
		subTotalIncGST = Math.round((subTotalIncGST + Number.EPSILON) * 100) / 100

		let totalIncGST = subTotalIncGST + totalFreight
		totalIncGST = Math.round((totalIncGST + Number.EPSILON) * 100) / 100

		let payableOnDelivery = totalIncGST - totalDeposit
		payableOnDelivery = Math.round((payableOnDelivery + Number.EPSILON) * 100) / 100  // make it totalDeposit and totalTrades and store them in db

		setState({
			...state,
			subTotalExGST,
			subTotalExGSTDiscounts,
			gstPayable,
			subTotalIncGST,
			totalIncGST,
			totalDeposit,
			totalFreight,
			payableOnDelivery,
			totalDiscount,
		})
	}
	useEffect(() => {
		if (loader) {
			return
		}
		loadCalculations();
	}, [services, discounts, lessDeposit, freight, loader])

	const handleChange = (e, type) => {
		const value = e.target.value;
		if (type === 'contact') {
			setContact({ ...contact, [e.target.name]: value });
		} else if (type === 'vehicleInfo') {
			setVehicleInfo({ ...vehicleInfo, [e.target.name]: value });
		} else if (type === 'servicesEdit') {
			const errors = { ...errorFields }
			errors[`servicesEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setservicesEdit({ ...servicesEdit, [e.target.name]: value })
		} else if (type === 'services') {
			const errors = { ...errorFields }
			errors[`servicesInput${e.target.name}`] = ''
			setErrorFields(errors)
			setservicesInput({ ...servicesInput, [e.target.name]: value })
		} else if (type === 'discounts') {
			const errors = { ...errorFields }
			errors[`discountsInput${e.target.name}`] = ''
			setErrorFields(errors)
			setdiscountsInput({ ...discountsInput, [e.target.name]: value })
		} else if (type === 'discountsEdit') {
			const errors = { ...errorFields }
			errors[`discountsEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setdiscountsEdit({ ...discountsEdit, [e.target.name]: value })
		} else if (type === 'LessDepositEdit') {
			const errors = { ...errorFields }
			errors[`LessDepositEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setLessDepositEdit({ ...LessDepositEdit, [e.target.name]: value })
		} else if (type === 'lessDeposit') {
			const errors = { ...errorFields }
			errors[`lessDepositInput${e.target.name}`] = ''
			setErrorFields(errors)
			setLessDepositInput({ ...lessDepositInput, [e.target.name]: value })
		} else if (type === 'freight') {
			const errors = { ...errorFields }
			errors[`freightInput${e.target.name}`] = ''
			setErrorFields(errors)
			setFreightInput({ ...freightInput, [e.target.name]: value })
		} else if (type === 'freightEdit') {
			const errors = { ...errorFields }
			errors[`freightEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setfreightEdit({ ...freightEdit, [e.target.name]: value })
		}
	};

	const onAmountChange = (e, name, type) => {
		let _value = e.floatValue
		// console.log('onAmountChange value ', _value)
		if (_value === undefined) {
			_value = ''
		}
		// console.log('onAmountChange value after convert', _value)

		if (type === 'services') {
			const errors = { ...errorFields }
			errors[`servicesInput${name}`] = ''
			setErrorFields(errors)
			let amount
			if (name === 'quantity') {
				let quantity = Number(_value)
				let rate = Number(servicesInput['rate'])
				let discount = Number(servicesInput['discount'])

				let _amount = quantity * rate
				if (discount > 0) _amount = _amount - (((rate * quantity) * discount) / 100)
				amount = { ...servicesInput, [name]: _value, amount: _amount }
				setservicesInput(amount)
			} else if (name === 'rate') {
				let rate = Number(_value)
				let quantity = Number(servicesInput['quantity'])
				let discount = Number(servicesInput['discount'])

				let _amount = quantity * rate
				if (discount > 0) _amount = _amount - (((rate * quantity) * discount) / 100)
				amount = { ...servicesInput, [name]: _value, amount: _amount }
				setservicesInput(amount)
			}
			else if (name === 'discount') {
				let discount = Number(_value)
				if (discount <= 100) {

					let quantity = Number(servicesInput['quantity'])
					let rate = Number(servicesInput['rate'])

					let _amount = quantity * rate
					if (discount > 0) _amount = _amount - (((rate * quantity) * discount) / 100)
					amount = { ...servicesInput, [name]: _value, amount: _amount }
					setservicesInput(amount)
				}
			}

		} else if (type === 'servicesEdit') {
			const errors = { ...errorFields }
			errors[`servicesEdit${name}`] = ''
			setErrorFields(errors)
			let amount
			if (name === 'quantity') {
				let quantity = Number(_value)
				let rate = Number(servicesEdit['rate'])
				let discount = Number(servicesEdit['discount'])

				let _amount = quantity * rate
				if (discount > 0) _amount = _amount - (((rate * quantity) * discount) / 100)
				amount = { ...servicesEdit, [name]: _value, amount: _amount }
				setservicesEdit(amount)
			} else if (name === 'rate') {
				let rate = Number(_value)
				let quantity = Number(servicesEdit['quantity'])
				let discount = Number(servicesEdit['discount'])

				let _amount = quantity * rate
				if (discount > 0) _amount = _amount - (((rate * quantity) * discount) / 100)
				amount = { ...servicesEdit, [name]: _value, amount: _amount }
				setservicesEdit(amount)
			}
			else if (name === 'discount') {
				let discount = Number(_value)
				if (discount <= 100) {
					let quantity = Number(servicesEdit['quantity'])
					let rate = Number(servicesEdit['rate'])

					let _amount = quantity * rate
					if (discount > 0) _amount = _amount - (((rate * quantity) * discount) / 100)
					amount = { ...servicesEdit, [name]: _value, amount: _amount }
					setservicesEdit(amount)
				}
			}

		} else if (type === 'discounts') {
			const errors = { ...errorFields }
			errors[`discountsInput${name}`] = ''
			setErrorFields(errors)
			setdiscountsInput({ ...discountsInput, [name]: _value })
		} else if (type === 'discountsEdit') {
			const errors = { ...errorFields }
			errors[`discountsEditInput${name}`] = ''
			setErrorFields(errors)
			setdiscountsEdit({ ...discountsEdit, [name]: _value })
		} else if (type === 'LessDepositEdit') {
			const errors = { ...errorFields }
			errors[`LessDepositEditInput${name}`] = ''
			setErrorFields(errors)
			setLessDepositEdit({ ...LessDepositEdit, [name]: _value })
		} else if (type === 'lessDeposit') {
			const errors = { ...errorFields }
			errors[`lessDepositInput${name}`] = ''
			setErrorFields(errors)
			setLessDepositInput({ ...lessDepositInput, [name]: _value })
		} else if (type === 'freight') {
			const errors = { ...errorFields }
			errors[`freightInput${name}`] = ''
			setErrorFields(errors)
			setFreightInput({ ...freightInput, [name]: _value })
		} else if (type === 'freightEdit') {
			const errors = { ...errorFields }
			errors[`freightEditInput${name}`] = ''
			setErrorFields(errors)
			setfreightEdit({ ...freightEdit, [name]: _value })
		} else if (type === 'vehicleInfo') {
			//	console.log('odomter', _value ? _value : '')
			setVehicleInfo({ ...vehicleInfo, [name]: _value })
		}

	}

	const handleKeyPress = (event, type) => {
		const numberChecker = val => {
			let returnVal = true
			if (val === undefined || val === '') {
				returnVal = true
			} else {
				returnVal = false
			}
			return returnVal
		}
		let isValid = true
		let errors = {}
		const errorClass = 'input_error'
		if (event.key === 'Enter') {

			if (type === 'services') {
				if (!servicesInput.item.trim()) {
					isValid = false
					errors = { ...errors, servicesInputitem: errorClass }
				}
				// if (numberChecker(servicesInput.quantity)) {
				// 	isValid = false
				// 	errors = { ...errors, servicesInputquantity: errorClass }
				// }
				// if (numberChecker(servicesInput.rate)) {
				// 	isValid = false
				// 	errors = { ...errors, servicesInputrate: errorClass }
				// }
				if (isValid) {
					let quantity = Number(servicesInput.quantity);
					let rate = Number(servicesInput.rate);
					let discount = Number(servicesInput.discount)
					let amount = quantity * rate
					if (discount > 0) amount = amount - (((rate * quantity) * discount) / 100)
					setservices([...services, {
						itemNo: servicesInput.itemNo ? servicesInput.itemNo.trim() : '',
						item: servicesInput.item.trim(),
						quantity: quantity,
						rate: rate,
						discount: discount,
						discountType: discount_type.percentage,
						amount: amount,
						id: servicesInput.id ? servicesInput.id : new Date().valueOf().toString(),
						addedDate: window.firebase.firestore.Timestamp.now()
					}])
					setservicesInput(genuineServicesVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'servicesEdit') {
				if (!servicesEdit.item.trim()) {
					isValid = false
					errors = { ...errors, servicesEditInputitem: errorClass }
				}
				// if (numberChecker(servicesEdit.quantity)) {
				// 	isValid = false
				// 	errors = { ...errors, servicesEditquantity: errorClass }
				// }
				// if (numberChecker(servicesEdit.rate)) {
				// 	isValid = false
				// 	errors = { ...errors, servicesInputrate: errorClass }
				// }
				if (isValid) {

					let quantity = Number(servicesEdit.quantity);
					let rate = Number(servicesEdit.rate);
					let discount = Number(servicesEdit.discount)
					let amount = quantity * rate
					if (discount > 0) amount = amount - (((rate * quantity) * discount) / 100)

					let updatedAccessories = services.map(a => {
						if (a.id === servicesEdit.editID) {
							return {
								addedDate: servicesEdit.addedDate ? servicesEdit.addedDate : window.firebase.firestore.Timestamp.now(),
								id: servicesEdit.id,
								amount: amount,
								rate: rate,
								discount: discount,
								discountType: discount_type.percentage,
								quantity: quantity,
								item: servicesEdit.item.trim(),
								itemNo: servicesEdit.itemNo ? servicesEdit.itemNo.trim() : '',
							}
						} else {
							return a
						}
					})

					setservices(updatedAccessories)
					setservicesEdit({
						editID: null,
						show: true,
						...genuineServicesVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'discounts') {
				if (!discountsInput.item.trim()) {
					isValid = false
					errors = { ...errors, discountsInputitem: errorClass }
				}
				if (numberChecker(discountsInput.amount)) {
					isValid = false
					errors = { ...errors, discountsInputamount: errorClass }
				}
				if (isValid) {
					setdiscounts([...discounts, { item: discountsInput.item.trim(), amount: discountsInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setdiscountsInput(optionsVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'discountsEdit') {
				if (!discountsEdit.item.trim()) {
					isValid = false
					errors = { ...errors, discountsEditInputitem: errorClass }
				}
				if (numberChecker(discountsEdit.amount)) {
					isValid = false
					errors = { ...errors, discountsEditInputamount: errorClass }
				}
				if (isValid) {
					let updateddiscounts = discounts.map(a => {
						if (a.id === discountsEdit.editID) {
							return {
								addedDate: discountsEdit.addedDate ? discountsEdit.addedDate : window.firebase.firestore.Timestamp.now(),
								id: discountsEdit.id,
								amount: discountsEdit.amount,
								item: discountsEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setdiscounts(updateddiscounts)
					setdiscountsEdit({
						editID: null,
						show: true,
						...optionsVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'LessDepositEdit') {
				if (!LessDepositEdit.reference) {
					isValid = false
					errors = { ...errors, LessDepositEditInputreference: errorClass }
				}
				if (!LessDepositEdit.date) {
					isValid = false
					errors = { ...errors, LessDepositEditInputdate: errorClass }
				}
				if (numberChecker(LessDepositEdit.amount)) {
					isValid = false
					errors = { ...errors, LessDepositEditInputamount: errorClass }
				}
				if (isValid) {
					let updatedLessDeposit = lessDeposit.map(a => {
						if (a.id === LessDepositEdit.editID) {
							return {
								addedDate: LessDepositEdit.addedDate ? LessDepositEdit.addedDate : window.firebase.firestore.Timestamp.now(),
								id: LessDepositEdit.id,
								amount: LessDepositEdit.amount,
								date: LessDepositEdit.date,
								reference: LessDepositEdit.reference,
							}
						} else {
							return a
						}
					})

					setLessDeposit(updatedLessDeposit)
					setLessDepositEdit({
						editID: null,
						show: true,
						...lessDepositVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'lessDeposit') {
				// console.log('lessDeposit', { date: lessDepositInput.date, reference: lessDepositInput.reference, amount: lessDepositInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() })
				if (!lessDepositInput.date) {
					isValid = false
					errors = { ...errors, lessDepositInputdate: errorClass }
				}
				// if (!lessDepositInput.reference) {
				// 	isValid = false
				// 	errors = { ...errors, lessDepositInputreference: errorClass }
				// }
				if (numberChecker(lessDepositInput.amount)) {
					isValid = false
					errors = { ...errors, lessDepositInputamount: errorClass }
				}
				if (isValid) {
					setLessDeposit([...lessDeposit, { date: lessDepositInput.date, reference: lessDepositInput.reference, amount: lessDepositInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setLessDepositInput(lessDepositVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'freight') {
				if (!freightInput.item.trim()) {
					isValid = false
					errors = { ...errors, freightInputitem: errorClass }
				}
				if (numberChecker(freightInput.amount)) {
					isValid = false
					errors = { ...errors, freightInputamount: errorClass }
				}
				if (isValid) {
					setFreight([...freight, { item: freightInput.item.trim(), amount: freightInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setFreightInput(optionsVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'freightEdit') {
				if (!freightEdit.item.trim()) {
					isValid = false
					errors = { ...errors, freightEditInputitem: errorClass }
				}
				if (numberChecker(freightEdit.amount)) {
					isValid = false
					errors = { ...errors, freightEditInputamount: errorClass }
				}
				if (isValid) {
					let updatedFreight = freight.map(a => {
						if (a.id === freightEdit.editID) {
							return {
								addedDate: freightEdit.addedDate ? freightEdit.addedDate : window.firebase.firestore.Timestamp.now(),
								id: freightEdit.id,
								amount: freightEdit.amount,
								item: freightEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setFreight(updatedFreight)
					setfreightEdit({
						editID: null,
						show: true,
						...optionsVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			}

		}
	}

	const handleClearEdit = type => {
		if (type === 'services') {
			setservicesEdit({
				editID: null,
				show: true,
				...genuineServicesVM
			})
		} else if (type === 'discounts') {
			setdiscountsEdit({
				editID: null,
				show: true,
				...optionsVM
			})
		} else if (type === 'LessDeposit') {
			setLessDepositEdit({
				editID: null,
				show: true,
				...lessDepositVM
			})
		} else if (type === 'freight') {
			setfreightEdit({
				editID: null,
				show: true,
				...optionsVM
			})
		}

	}

	const handleRemoveItem = (type, data) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to delete this row.`,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then(async result => {
			if (result.value) {
				if (type === 'services') {
					let _services = services.filter(a => a.id !== data.id)
					setservices(_services)
				} else if (type === 'discounts') {
					let _discounts = discounts.filter(a => a.id !== data.id)
					setdiscounts(_discounts)
				} else if (type === 'lessDeposit') {
					let _lessDeposit = lessDeposit.filter(a => a.id !== data.id)
					setLessDeposit(_lessDeposit)
				} else if (type === 'freight') {
					let _freight = freight.filter(a => a.id !== data.id)
					setFreight(_freight)
				}

			}
		})
	}

	const onSave = async () => {
		if (saveLoader) {
			return
		}
		// convert dates before sending

		let _vehicleInfo = { ...vehicleInfo }
		let _contact = { ...contact }
		let _lessDeposit = [...lessDeposit]

		if (!_.isEmpty(_contact.dob)) {
			_contact.dob = moment(_contact.dob).format('YYYY-MM-DD')
		}

		if (!_.isEmpty(_lessDeposit)) {
			_lessDeposit = _lessDeposit.map(item => {
				let _date = moment(item.date.toDate()).format('YYYY-MM-DD')
				return { ...item, date: _date }
			})
		}

		// check if its new
		if (!state.documentID) {
			setSaveLoader(true)
			const documentID = window.firebase.firestore().collection('quotation').doc().id

			let objectToSave = {
				displayID: `INV-${new Date().valueOf().toString()}`,
				documentID,
				serviceID,
				contact: _contact,
				vehicle: _vehicleInfo,
				clientID: serviceData.clientID ? serviceData.clientID : '',
				contactID: contactData.documentID ? contactData.documentID : '',
				gstPercentage: state.gstPercentage,
				taxType: state.taxType,
				subTotalExGST: state.subTotalExGST,
				subTotalExGSTDiscounts: state.subTotalExGSTDiscounts,
				subTotalIncGST: state.subTotalIncGST,
				gstPayable: state.gstPayable,
				totalIncGST: state.totalIncGST,
				payableOnDelivery: state.payableOnDelivery,
				notes: state.notes ? state.notes : ' ',
				isDeleted: false,
				addedBy: localStorage.uid,
				modifiedBy: localStorage.uid,
				addedDate: window.firebase.firestore.Timestamp.now(),
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				addedFrom: 'web',
				modifiedFrom: 'web',
				lessDeposit: _lessDeposit,
				totalDeposit: state.totalDeposit,
				totalFreight: state.totalFreight,
				totalDiscount: state.totalDiscount,
				owner: serviceData && serviceData.owner ? serviceData.owner : '',
				projectId: client?.projectId ? client?.projectId : null
			}

			objectToSave = {
				...objectToSave,
				freight: freight,
				services,
				discounts,
			}
			// console.log('objectosave', objectToSave)

			const ref = firestoreDB.firestore().doc(`serviceJobs/${serviceID}/invoices/${documentID}`)
			// console.log(objectToSave)
			await ref.set(objectToSave, { merge: true })
			let _quotation = { id: objectToSave.documentID, amount: objectToSave.payableOnDelivery }
			const enqRef = firestoreDB.firestore().doc(`serviceJobs/${serviceID}`)
			let objData = {};
			if (!_.isEmpty(serviceData) && !serviceData.isInvoice) {
				objData.isInvoice = true;
				objData = handleMoveStageForStatusChange('isInvoice', objData);
			}
			objData.invoice = _quotation;
			objData.modifiedBy = localStorage.uid;
			objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			objData.modifiedFrom = 'web';

			await enqRef.set(objData, { merge: true })
			setNewlySavedData(objectToSave)
			//window.history.pushState("", "", `/service/invoice/${serviceID}?type=edit&previewID=${documentID}`);
			//setActiveTab('preview')

			_useHistory.push(`/serviceinvoice/preview/${documentID}?vbtn=y`);
			_useHistory.go();
			//add isQuotation True
			setSaveLoader(false)
		} else {
			setSaveLoader(true)

			let objectToSave = {
				displayID: state.displayID,
				documentID: state.documentID,
				serviceID: state.serviceID,
				clientID: serviceData.clientID ? serviceData.clientID : '',
				contactID: contactData.documentID ? contactData.documentID : '',
				contact: _contact,
				vehicle: _vehicleInfo,
				gstPercentage: state.gstPercentage,
				taxType: state.taxType,
				subTotalExGST: state.subTotalExGST,
				subTotalExGSTDiscounts: state.subTotalExGSTDiscounts,
				subTotalIncGST: state.subTotalIncGST,
				gstPayable: state.gstPayable,
				totalIncGST: state.totalIncGST,
				payableOnDelivery: state.payableOnDelivery,
				notes: state.notes ? state.notes : ' ',
				isDeleted: false,
				modifiedBy: localStorage.uid,
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				modifiedFrom: 'web',
				lessDeposit: _lessDeposit,
				totalDeposit: state.totalDeposit,
				totalFreight: state.totalFreight,
				totalDiscount: state.totalDiscount,
				owner: serviceData && serviceData.owner ? serviceData.owner : '',
				projectId: client?.projectId ? client?.projectId : null
			}
			objectToSave = {
				...objectToSave,
				freight: freight,
				services,
				discounts
			}

			Object.keys(objectToSave).forEach(key => objectToSave[key] === undefined ? delete objectToSave[key] : {});
			// console.log('objectToSave', objectToSave)
			const ref = firestoreDB.firestore().doc(`serviceJobs/${serviceID}/invoices/${objectToSave.documentID}`)
			// console.log(objectToSave)
			await ref.set(objectToSave, { merge: true })
			let _quotation = { id: objectToSave.documentID, amount: objectToSave.payableOnDelivery }
			const enqRef = firestoreDB.firestore().doc(`serviceJobs/${serviceID}`)
			let objData = {};
			if (!_.isEmpty(serviceData) && !serviceData.isInvoice) {
				objData.isInvoice = true;
				objData = handleMoveStageForStatusChange('isInvoice', objData);
			}
			objData.invoice = _quotation;
			objData.modifiedBy = localStorage.uid;
			objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			objData.modifiedFrom = 'web';

			await enqRef.set(objData, { merge: true })
			setNewlySavedData(objectToSave)
			_useHistory.push(`/serviceinvoice/preview/${state.documentID}?vbtn=y`);
			_useHistory.go();
			// if (objectToSave.documentID) {
			// 	_useHistory.push(`/serviceinvoice/preview/${objectToSave.documentID}`);
			// 	//return;
			// }
			//window.history.push(`/serviceinvoice/preview/${objectToSave.documentID}`);
			//setActiveTab('preview')
			//add isQuotation True
			setSaveLoader(false)
		}
	}

	const handleMoveStageForStatusChange = (_status, state) => {

		const { stageHistory } = serviceData;
		let statusSettings = null;

		if (serviceData && serviceData.pipeline && !_.isEmpty(clientSettings.services) && !_.isEmpty(clientSettings.services.allPipelines)) {
			let _selPipeline = clientSettings.services.allPipelines.filter(e => e.value === serviceData.pipeline)[0];
			statusSettings = Object.assign([], _selPipeline?.statusSettings);
		}

		let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
		if (!_.isEmpty(data) && data.pipeline && data.stage) {
			let _stageHistory = Object.assign([], stageHistory);
			let _stageData = commonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
			if (!_.isEmpty(_stageData)) {
				_stageHistory.push(_stageData);
				state.stageHistory = Object.assign([], _stageHistory);
				state.pipeline = data.pipeline;
				state.stage = data.stage;
				state.stageDate = window.firebase.firestore.Timestamp.now();
			}
			return state;
		}
		else
			return state;
	}

	const handleDateChange = (val, name) => {
		// if (name === 'orderDate') {
		// 	setOrderDetails({
		// 		...orderDetails,
		// 		orderDate: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
		// 	})
		// } else if (name === 'estDelivery') {
		// 	setOrderDetails({
		// 		...orderDetails,
		// 		estDelivery: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
		// 	})
		// } else if (name === 'regoExpiry') {
		// 	setVehicleInfo({
		// 		...vehicleInfo,
		// 		regoExpiry: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
		// 	})
		// } else if (name === 'buildDate') {
		// 	setVehicleInfo({
		// 		...vehicleInfo,
		// 		buildDate: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
		// 	})
		// } else 
		if (name === 'lessDepositInput.date') { // lessDeposit
			const errors = { ...errorFields }
			errors[`lessDepositInputdate`] = ''
			setErrorFields(errors)
			setLessDepositInput({
				...lessDepositInput,
				date: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
			})
		} else if (name === 'LessDepositEditInput.date') { // lessDeposit
			const errors = { ...errorFields }
			errors[`LessDepositEditInputdate`] = ''
			setErrorFields(errors)
			setLessDepositEdit({
				...LessDepositEdit,
				date: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
			})
		}
	};

	return !loader ? (
		<>
			<div className='dynamicreport-stage-container' >
				<div className='dynamicreport-stage-wraper' >
					<div className='dynamicreport-stage-loop active'>
						<div className='dynamicreport-stage-loop-box'>
							<div className='dynamicreport-stage-count'>1</div>
							<div className='dynamicreport-stage-title-wrap'>
								<span className='dynamicreport-stage-title'>Invoice Details</span>
							</div>
						</div>
					</div>

					<div className='dynamicreport-stage-seperator'>
						<svg
							stroke='currentColor'
							fill='none'
							strokeWidth='2'
							viewBox='0 0 24 24'
							strokeLinecap='round'
							strokeLinejoin='round'
							height='1em'
							width='1em'
							xmlns='http://www.w3.org/2000/svg'
						>
							<polyline points='9 18 15 12 9 6'></polyline>
						</svg>
					</div>

					<div className='dynamicreport-stage-loop'>
						<div className='dynamicreport-stage-loop-box'>
							<div className='dynamicreport-stage-count'>2</div>
							<div className='dynamicreport-stage-title-wrap'>
								<span className='dynamicreport-stage-title'>Preview </span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='button-wrap'>
				{!_.isEmpty(state.modifiedDate) && (
					<div id='updated-date' style={{ float: 'left', padding: '10px' }}>
						<div>Updated On : {moment(state.modifiedDate.toDate()).format('DD/MM/YYYY h:mm A')}</div>
					</div>
				)}
				<a
					className='update-btn'
					onClick={(e) => {
						e.preventDefault();
						onSave();
					}}
				// onclick=''
				>
					{
						saveLoader ?
							(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
							: (<></>)
					}
					Save &amp; Preview
				</a>
			</div>

			<div
				className='dynamic-report-print-wrap'
				id='print-section'
			>
				<div className='print-wrap'>


					<div className="invoice-sub-header border-invoice">
						<div className="invoice-sub-header-logo">
							{
								client?.servicelogoURL ? (
									<img src={client?.servicelogoURL} height="60" />
								) : client?.logoURL ? (
									<img src={client?.logoURL} height="60" />
								) : (<>{client?.name}</>)
							}
						</div>
						<div className="invoice-sub-header-company-address">
							{
								client?.address ? client?.address : ''
							}
							{
								client?.phone ? ` | PH: ${client?.phone}` : ''
							}
							{
								client?.email ? ` | Email: ${client?.email}` : ''
							}
						</div>
						{
							client?.abn ? (
								<div className="invoice-sub-header-abn">A.B.N {client?.abn}</div>
							) : client?.acn ? (
								<div className="invoice-sub-header-abn">A.C.N {client?.acn}</div>
							) : (<></>)
						}
					</div>
					{/* <div className='headerwrap-left'>
							<div className='header-left'>
								{
									client && client.logoURL
										?
										<div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
										:
										<>
											<div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
										</>
								}
							</div>
						</div>

						<div className='headerwrap-right'>
							<div className='header-right'>
								{' '}
								{client.name} <br />
								<span>
									{' '}
									{client.address ? client.address : ''}
									{client.phone ? (
										<>
											<br />
											{`Phone: ${client.phone}`}
										</>
									) : <></>}
									{client.email || client.website ? (
										<>
											<br />
											{client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
										</>
									) : <></>}
									<br />
								</span>
							</div>
						</div> */}


					<div className='clear'></div>


					<div className='section-wraper'>
						<div className='section-head'>
							<strong>Contact Information</strong>
						</div>
						<div className='section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<tbody>

									{contact.businessContact ? (
										<Fragment>
											<tr>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>Name</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='name'
															value={contact.name}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>Email</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='email'
															value={contact.email ? contact.email : ''}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>Phone</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='phone'
															value={contact.phone ? commonHelper.phoneWithZero(contact.phoneCode, contact.phone) : ''}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td colSpan="3">
													<div className="grid-one">
														<span>Address</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='address'
															value={contact.address ? contact.address : ''}
															onChange={e => handleChange(e, 'address')}
															disabled={true}
														/>
													</div>
												</td>
											</tr>
										</Fragment>
									) : (
										<Fragment>
											<tr>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>First Name</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='firstName'
															value={contact.firstName}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>Middle Name</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='middleName'
															value={contact.middleName}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>Last Name</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='lastName'
															value={contact.lastName}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>

											</tr>
											<tr>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>Phone</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='phone'
															value={contact.phone ? commonHelper.phoneWithZero(contact.phoneCode, contact.phone) : ''}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>Email</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='email'
															value={contact.email ? contact.email : ''}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>DOB</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='dob'
															value={contact.dob ? moment(contact.dob).format('DD/MM/YYYY') : ''}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>

											</tr>
											<tr>
												<td width='33%'>
													<div className='grid-one'>
														{' '}
														<span>License</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='licenseNo'
															value={contact.licenseNo ? contact.licenseNo : ''}
															onChange={e => handleChange(e, 'contact')}
															disabled={true}
														/>
													</div>
												</td>
												<td colSpan="2">
													<div className="grid-one">
														<span>Address</span>
														<input
															autoComplete="off"
															type='text'
															className='input-enq input-enq-autofill'
															name='address'
															value={contact.address ? contact.address : ''}
															onChange={e => handleChange(e, 'address')}
															disabled={true}
														/>
													</div>
												</td>
											</tr>
										</Fragment>
									)}


								</tbody>
							</table>
						</div>
					</div>

					<div className='section-wraper'>
						<div className='section-head'>
							<strong>Vehicle Information</strong>
						</div>
						<div className='section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<tbody>
									<tr>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Make</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq input-enq-autofill'
													name='make'
													value={
														vehicleInfo.make ? vehicleInfo.make : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
													disabled={true}
												/>
											</div>
										</td>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Model</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq input-enq-autofill'
													name='model'
													value={
														vehicleInfo.model ? vehicleInfo.model : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
													disabled={true}
												/>
											</div>
										</td>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Type</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq input-enq-autofill'
													name='type'
													value={
														vehicleInfo.type ? vehicleInfo.type : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
													disabled={true}
												/>
											</div>
										</td>
									</tr>
									<tr>

										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Year</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq'
													name='year'
													value={
														vehicleInfo.year ? vehicleInfo.year : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
												/>
											</div>
										</td>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Rego No.</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq'
													name='rego'
													value={
														vehicleInfo.rego ? vehicleInfo.rego : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
												/>
											</div>
										</td>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>VIN No.</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq'
													name='vinNo'
													value={
														vehicleInfo.vinNo ? vehicleInfo.vinNo : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
												/>
											</div>
										</td>
									</tr>

									<tr>

										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Color</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq'
													name='extColor'
													value={
														vehicleInfo.extColor ? vehicleInfo.extColor : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
												/>
											</div>
										</td>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Odometer</span>
												<NumberFormat
													allowNegative={false}
													thousandSeparator={true}
													className={`input-enq`}
													decimalScale={2}
													onValueChange={e =>
														onAmountChange(
															e,
															'odometer',
															'vehicleInfo',
														)
													}
													value={vehicleInfo.odometer}
												/>
											</div>
										</td>

									</tr>


								</tbody>
							</table>
						</div>
					</div>

					<div className='sub-section-wraper'>
						<div className='section-head'>
							<strong>SERVICES</strong>

							<div className='addmore-field'>
								<button className='addmore-field-btn' type='button' onClick={() => setShowservices(true)}>
									<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><g id='plus-square-outline' transform='translate(-.266 .217)'><g id='Rectangle_1143' fill='rgba(255,255,255,0)' stroke='#000' transform='translate(.266 -.217)'><rect width='16' height='16' stroke='none' rx='3'></rect><rect width='15' height='15' x='.5' y='.5' fill='none' rx='2.5'></rect></g><g id='Group_588' transform='translate(5.264 4.783)'><path id='Line_109' d='M0 0L0 6' stroke='#000' fill='none' strokeLinecap='round' transform='translate(3)'></path><path id='Line_110' d='M0 0L0 6' stroke='#000' fill='none' strokeLinecap='round' transform='rotate(90 1.5 4.5)'></path></g></g></svg>

									Add More
								</button>
							</div>
						</div>

						<div className='sub-section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<thead>
									<tr>
										<th width='5%'>S.N.</th>
										<th width='15%'>Item No.</th>
										<th width='25%'>Item</th>
										<th width='6%'>Qty</th>
										<th width='15%'>Rate</th>
										<th width='10%'>Disc(%)</th>
										<th width='15%'>Amount</th>
										<th width='10%'></th>
									</tr>
								</thead>
								<tbody>
									{services.length > 0 && services.map((_services, index) => (
										<Fragment key={_services.id}>
											{servicesEdit.show && servicesEdit.editID === _services.id ? (
												<tr className='items-list'>
													<td className='serial_no'>{services.length === 0 ? '1' : index + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['servicesEditInputitemNo']}`}
															name='itemNo'
															value={servicesEdit.itemNo}
															onChange={e => handleChange(e, 'servicesEdit')}
															placeholder='Add Item No.'
															onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
														/>
													</td>
													<td>
														{/* <input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['servicesEditInputitem']}`}
															name='item'
															value={servicesEdit.item}
															onChange={e => handleChange(e, 'servicesEdit')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
														/> */}
														<textarea
															style={{ minHeight: '36px' }}
															autoComplete="off"
															className={`form-control notes ${errorFields['servicesEditInputitem']}`}
															name="item"
															onChange={e => handleChange(e, 'servicesEdit')}
															//onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
															value={servicesEdit.item}
															placeholder='Add Item'
															//cols="80"
															rows="1"
														></textarea>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															placeholder='0'
															className={`input-enq ${errorFields['servicesEditInputquantity']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'quantity',
																	'servicesEdit',
																)
															}
															value={servicesEdit.quantity}
															onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['servicesEditInputrate']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'rate',
																	'servicesEdit',
																)
															}
															value={servicesEdit.rate}
															onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															decimalScale={2}
															allowNegative={false}
															//format="##%"
															suffix={'%'}
															placeholder={'discount %'}
															className={`input-enq ${errorFields['servicesEditInputdiscount']}`}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'discount',
																	'servicesEdit',
																)
															}
															value={servicesEdit.discount}
															onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq input-enq-autofill ${errorFields['servicesEditInputamount']}`}
															decimalScale={2}
															value={servicesEdit.amount}
															onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
															disabled={true}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'servicesEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																handleClearEdit('services')
															}}>
																<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
															</button>
														</div>
													</td>
												</tr>
											) : (

												<tr className='items-list' key={_services.id}>
													<td className='serial_no'>{index + 1}</td>
													<td>
														<span>{_services.itemNo}</span>
													</td>
													<td>
														<span>{_services.item}</span>
													</td>
													<td>
														<span>{_services.quantity > 0 ? _services.quantity : ''}</span>
													</td>
													<td>
														<span>
															{' '}
															{
																_services.rate > 0
																	?
																	<><strong>
																		<NumberFormat
																			decimalScale={2}
																			thousandSeparator={true}
																			prefix={currencySymbol}
																			displayType={'text'}
																			value={_services.rate ? _services.rate : 0}
																		/>
																	</strong></>
																	:
																	<></>
															}

														</span>
													</td>
													<td>
														<span>
															{' '}
															{
																_services.discount > 0
																	?
																	<><strong>
																		<NumberFormat
																			decimalScale={2}
																			thousandSeparator={true}
																			suffix={'%'}
																			displayType={'text'}
																			value={_services.discount ? _services.discount : 0}
																		/>
																	</strong></>
																	:
																	<></>
															}

														</span>
													</td>

													<td className='amount'>
														<span>
															{' '}
															{
																_services.amount > 0
																	?
																	<><strong>
																		<NumberFormat
																			decimalScale={2}
																			thousandSeparator={true}
																			prefix={currencySymbol}
																			displayType={'text'}
																			value={_services.amount ? _services.amount : 0}
																		/>
																	</strong></>
																	:
																	<></>
															}

														</span>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="edit-icon-input" onClick={() => setservicesEdit({ ...servicesEdit, show: true, editID: _services.id, ..._services })}><img src={_images.editIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={e => handleRemoveItem('services', _services)}>
																<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</Fragment>
									))}
									{showservices && (
										<tr className='items-list'>
											<td className='serial_no'>{services.length === 0 ? '1' : services.length + 1}</td>
											<td>
												<input
													autoComplete="off"
													type='text'
													className={`input-enq ${errorFields['servicesInputitemNo']}`}
													name='itemNo'
													value={servicesInput.itemNo}
													onChange={e => handleChange(e, 'services')}
													placeholder='Add Item No'
													onKeyPress={e => handleKeyPress(e, 'services')}
												/>
											</td>
											<td>
												{/* <input
													autoComplete="off"
													type='text'
													className={`input-enq ${errorFields['servicesInputitem']}`}
													name='item'
													value={servicesInput.item}
													onChange={e => handleChange(e, 'services')}
													placeholder='Add Item'
													onKeyPress={e => handleKeyPress(e, 'services')}
												/> */}
												<textarea
													style={{ minHeight: '36px' }}
													autoComplete="off"
													className={`form-control notes ${errorFields['servicesInputitem']}`}
													name="item"
													onChange={e => handleChange(e, 'services')}
													//onKeyPress={e => handleKeyPress(e, 'services')}
													value={servicesInput.item}
													placeholder='Add Item'
													//cols="80"
													rows="1"
												></textarea>
											</td>

											<td className='amount'>
												<NumberFormat
													allowNegative={false}
													thousandSeparator={true}
													placeholder='0'
													className={`input-enq ${errorFields['servicesInputquantity']}`}
													decimalScale={2}
													onValueChange={e =>
														onAmountChange(
															e,
															'quantity',
															'services',
														)
													}
													value={servicesInput.quantity}
													onKeyPress={e => handleKeyPress(e, 'services')}
												/>
											</td>

											<td className='amount'>
												<NumberFormat
													allowNegative={false}
													thousandSeparator={true}
													prefix={currencySymbol}
													placeholder={currencySymbol}
													className={`input-enq ${errorFields['servicesInputrate']}`}
													decimalScale={2}
													onValueChange={e =>
														onAmountChange(
															e,
															'rate',
															'services',
														)
													}
													value={servicesInput.rate}
													onKeyPress={e => handleKeyPress(e, 'services')}
												/>
											</td>
											<td className='amount'>
												<NumberFormat
													decimalScale={2}
													allowNegative={false}
													//format="#####%"
													suffix={'%'}
													placeholder={'discount %'}
													className={`input-enq ${errorFields['servicesInputdiscount']}`}
													onValueChange={e =>
														onAmountChange(
															e,
															'discount',
															'services',
														)
													}
													value={servicesInput.discount}
													onKeyPress={e => handleKeyPress(e, 'services')}
												/>
											</td>

											<td className='amount'>
												<NumberFormat
													decimalScale={2}
													allowNegative={false}
													thousandSeparator={true}
													prefix={currencySymbol}
													placeholder={currencySymbol}
													className={`input-enq input-enq-autofill ${errorFields['servicesInputamount']}`}
													// decimalScale={2}
													// onValueChange={e =>
													// 	onAmountChange(
													// 		e,
													// 		'amount',
													// 		'services',
													// 	)
													// }
													value={servicesInput.amount}
													onKeyPress={e => handleKeyPress(e, 'services')}
													disabled={true}
												/>
											</td>
											<td>
												<div className='form-controls-td'>
													<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'services')}><img src={_images.plusIcon} alt="" width="14" /></a>
													<button type='button' className='close-btn' onClick={() => {
														setShowservices(false)
														setservicesInput(optionsVM)
													}}>
														<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
													</button>
												</div>
											</td>
										</tr>
									)}

								</tbody>
							</table>
						</div>
						<div></div>
					</div>
					<div className='sub-section-wraper'>
						<div className='section-head'>
							<strong>Discounts</strong>

							<div className='addmore-field'>
								<button className='addmore-field-btn' type='button' onClick={() => setShowdiscounts(true)}>
									<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><g id='plus-square-outline' transform='translate(-.266 .217)'><g id='Rectangle_1143' fill='rgba(255,255,255,0)' stroke='#000' transform='translate(.266 -.217)'><rect width='16' height='16' stroke='none' rx='3'></rect><rect width='15' height='15' x='.5' y='.5' fill='none' rx='2.5'></rect></g><g id='Group_588' transform='translate(5.264 4.783)'><path id='Line_109' d='M0 0L0 6' stroke='#000' fill='none' strokeLinecap='round' transform='translate(3)'></path><path id='Line_110' d='M0 0L0 6' stroke='#000' fill='none' strokeLinecap='round' transform='rotate(90 1.5 4.5)'></path></g></g></svg>
									Add More
								</button>
							</div>
						</div>

						<div className='sub-section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<thead>
									<tr>
										<th width='5%'>S.N.</th>
										<th width='70%'>Item</th>
										<th width='15%'>Amount</th>
										<th width='10%'></th>
									</tr>
								</thead>
								<tbody>
									{discounts.length > 0 && discounts.map((_discount, index) => (
										<Fragment key={_discount.id}>
											{discountsEdit.show && discountsEdit.editID === _discount.id ? (
												<tr className='items-list'>
													<td className='serial_no'>{discounts.length === 0 ? '1' : index + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['discountsEditInputitem']}`}
															name='item'
															value={discountsEdit.item}
															onChange={e => handleChange(e, 'discountsEdit')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'discountsEdit')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															decimalScale={2}
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['discountsEditInputamount']}`}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'discountsEdit',
																)
															}
															value={discountsEdit.amount}
															onKeyPress={e => handleKeyPress(e, 'discountsEdit')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'discountsEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																handleClearEdit('discounts')
															}}>
																<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
															</button>
														</div>
													</td>
												</tr>
											) : (<tr className='items-list' key={_discount.id}>
												<td className='serial_no'>{index + 1}</td>
												<td>
													<span>{_discount.item}</span>
												</td>

												<td className='amount'>
													<span>
														{' '}
														<strong>
															<NumberFormat
																decimalScale={2}
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={_discount.amount ? _discount.amount : 0}
															/>
														</strong>
													</span>
												</td>
												<td>
													<div className='form-controls-td'>
														<a className="edit-icon-input" onClick={() => setdiscountsEdit({ ...discountsEdit, show: true, editID: _discount.id, ..._discount })}><img src={_images.editIcon} alt="" width="14" /></a>
														<button type='button' className='close-btn' onClick={e => handleRemoveItem('discounts', _discount)}>
															<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
														</button>
													</div>
												</td>
											</tr>)}
										</Fragment>
									))}
									{showdiscounts && (
										<tr className='items-list'>
											<td className='serial_no'>{discounts.length === 0 ? '1' : discounts.length + 1}</td>
											<td>
												<input
													autoComplete="off"
													type='text'
													className={`input-enq ${errorFields['discountsInputitem']}`}
													name='item'
													value={discountsInput.item}
													onChange={e => handleChange(e, 'discounts')}
													placeholder='Add Item'
													onKeyPress={e => handleKeyPress(e, 'discounts')}
												/>
											</td>

											<td className='amount'>
												<NumberFormat
													allowNegative={false}
													thousandSeparator={true}
													prefix={currencySymbol}
													placeholder={currencySymbol}
													className={`input-enq ${errorFields['discountsInputamount']}`}
													decimalScale={2}
													onValueChange={e =>
														onAmountChange(
															e,
															'amount',
															'discounts',
														)
													}
													value={discountsInput.amount}
													onKeyPress={e => handleKeyPress(e, 'discounts')}
												/>
											</td>
											<td>
												<div className='form-controls-td'>
													<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'discounts')}><img src={_images.plusIcon} alt="" width="14" /></a>
													<button type='button' className='close-btn' onClick={() => {
														setShowdiscounts(false)
														setdiscountsInput(optionsVM)
													}}>
														<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
													</button>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>

					</div>




					<div className='total-estimate'>
						<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
							<tbody>
								{state.gstPercentage > 0 ?
									<>
										<tr>
											<td className='costtxt'>{'Sub Total excluding '}{state.taxType === 'VAT' ? 'VAT' : state.taxType}</td>
											<td className='cost-box'><NumberFormat
												decimalScale={2}
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={state.subTotalExGST}
											/></td>
										</tr>
										<tr>
											<td className='costtxt'>{state.taxType === 'VAT' ? 'VAT' : state.taxType} payable</td>
											<td className='cost-box'><NumberFormat
												decimalScale={2}
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={state.gstPayable}
											/></td>
										</tr>
										<tr>
											<td className='costtxt'>{'Sub Total inclusive of '}{state.taxType === 'VAT' ? 'VAT' : state.taxType}</td>
											<td className='cost-box'>
												<strong><NumberFormat
													decimalScale={2}
													thousandSeparator={true}
													prefix={currencySymbol}
													displayType={'text'}
													value={state.subTotalIncGST}
												/></strong>
											</td>
										</tr>
									</>
									:
									<>
										<tr>
											<td className='costtxt'>{'Sub Total excluding '}{state.taxType === 'VAT' ? 'VAT' : state.taxType}</td>
											<td className='cost-box'><NumberFormat
												decimalScale={2}
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={state.subTotalExGST}
											/></td>
										</tr>
									</>
								}
							</tbody>
						</table>
					</div>



					<div className='sub-section-wraper'>
						<div className='section-head'>
							<strong>Freight</strong>

							<div className='addmore-field'>
								<button className='addmore-field-btn' type='button' onClick={() => setShowFreight(true)}>
									<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><g id='plus-square-outline' transform='translate(-.266 .217)'><g id='Rectangle_1143' fill='rgba(255,255,255,0)' stroke='#000' transform='translate(.266 -.217)'><rect width='16' height='16' stroke='none' rx='3'></rect><rect width='15' height='15' x='.5' y='.5' fill='none' rx='2.5'></rect></g><g id='Group_588' transform='translate(5.264 4.783)'><path id='Line_109' d='M0 0L0 6' stroke='#000' fill='none' strokeLinecap='round' transform='translate(3)'></path><path id='Line_110' d='M0 0L0 6' stroke='#000' fill='none' strokeLinecap='round' transform='rotate(90 1.5 4.5)'></path></g></g></svg>
									Add More
								</button>
							</div>
						</div>

						<div className='sub-section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<thead>
									<tr>
										<th width='5%'>S.N.</th>
										<th width='70%'>Item</th>
										<th width='15%'>Amount</th>
										<th width='10%'></th>
									</tr>
								</thead>
								<tbody>
									{freight.length > 0 && freight.map((_freight, index) => (
										<Fragment key={_freight.id}>
											{freightEdit.show && freightEdit.editID === _freight.id ? (
												<tr className='items-list'>
													<td className='serial_no'>{freight.length === 0 ? '1' : index + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['freightEditInputitem']}`}
															name='item'
															value={freightEdit.item}
															onChange={e => handleChange(e, 'freightEdit')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'freightEdit')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['freightEditInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'freightEdit',
																)
															}
															value={freightEdit.amount}
															onKeyPress={e => handleKeyPress(e, 'freightEdit')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'freightEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																handleClearEdit('freight')
															}}>
																<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
															</button>
														</div>
													</td>
												</tr>
											) : (<tr className='items-list' key={_freight.id}>
												<td className='serial_no'>{index + 1}</td>
												<td>
													<span>{_freight.item}</span>
												</td>

												<td className='amount'>
													<span>
														{' '}
														<strong>
															<NumberFormat
																decimalScale={2}
																thousandSeparator={true}
																prefix={currencySymbol}
																displayType={'text'}
																value={_freight.amount ? _freight.amount : 0}
															/>
														</strong>
													</span>
												</td>
												<td>
													<div className='form-controls-td'>
														<a className="edit-icon-input" onClick={() => setfreightEdit({ ...freightEdit, show: true, editID: _freight.id, ..._freight })}><img src={_images.editIcon} alt="" width="14" /></a>
														<button type='button' className='close-btn' onClick={e => handleRemoveItem('freight', _freight)}>
															<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
														</button>
													</div>
												</td>
											</tr>)}
										</Fragment>
									))}
									{showFreight && (
										<tr className='items-list'>
											<td className='serial_no'>{freight.length === 0 ? '1' : freight.length + 1}</td>
											<td>
												<input
													autoComplete="off"
													type='text'
													className={`input-enq ${errorFields['freightInputitem']}`}
													name='item'
													value={freightInput.item}
													onChange={e => handleChange(e, 'freight')}
													placeholder='Add Item'
													onKeyPress={e => handleKeyPress(e, 'freight')}
												/>
											</td>

											<td className='amount'>
												<NumberFormat
													allowNegative={false}
													thousandSeparator={true}
													prefix={currencySymbol}
													placeholder={currencySymbol}
													className={`input-enq ${errorFields['freightInputamount']}`}
													decimalScale={2}
													onValueChange={e =>
														onAmountChange(
															e,
															'amount',
															'freight',
														)
													}
													value={freightInput.amount}
													onKeyPress={e => handleKeyPress(e, 'freight')}
												/>
											</td>
											<td>
												<div className='form-controls-td'>
													<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'freight')}><img src={_images.plusIcon} alt="" width="14" /></a>
													<button type='button' className='close-btn' onClick={() => {
														setShowFreight(false)
														setFreightInput(optionsVM)
													}}>
														<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
													</button>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div></div>
					</div>
					<div className='total-estimate'>
						<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
							<tbody>
								<tr>
									<td className='costtxt'>{state.gstPercentage > 0 ? 'Total inclusive of ' : 'Total excluding '}{state.taxType === 'VAT' ? 'VAT' : state.taxType}</td>
									<td className='cost-box'>
										<strong><NumberFormat
											decimalScale={2}
											thousandSeparator={true}
											prefix={currencySymbol}
											displayType={'text'}
											value={state.totalIncGST}
										/></strong>
									</td>
								</tr>

							</tbody>
						</table>
					</div>

					<div className='sub-section-wraper'>
						<div className='section-head'>
							<strong> Less Deposit </strong>

							<div className='addmore-field'>
								<button className='addmore-field-btn' type='button' onClick={() => setShowLessDeposit(true)}>
									<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><g id='plus-square-outline' transform='translate(-.266 .217)'><g id='Rectangle_1143' fill='rgba(255,255,255,0)' stroke='#000' transform='translate(.266 -.217)'><rect width='16' height='16' stroke='none' rx='3'></rect><rect width='15' height='15' x='.5' y='.5' fill='none' rx='2.5'></rect></g><g id='Group_588' transform='translate(5.264 4.783)'><path id='Line_109' d='M0 0L0 6' stroke='#000' fill='none' strokeLinecap='round' transform='translate(3)'></path><path id='Line_110' d='M0 0L0 6' stroke='#000' fill='none' strokeLinecap='round' transform='rotate(90 1.5 4.5)'></path></g></g></svg>
									Add More
								</button>
							</div>
						</div>

						<div className='sub-section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<thead>
									<tr>
										<th width='5%'>S.N.</th>
										<th width='40%'>Date</th>
										<th width='30%'>Reference</th>

										<th width='15%'>Amount</th>
										<th width='10%'></th>
									</tr>
								</thead>
								<tbody>
									{lessDeposit.length > 0 && lessDeposit.map((_lessDeposit, index) => (
										<Fragment key={_lessDeposit.id}>
											{LessDepositEdit.show && LessDepositEdit.editID === _lessDeposit.id ? (
												<tr className='items-list'>
													<td className='serial_no'>{lessDeposit.length === 0 ? '1' : index + 1}</td>
													<td>
														<AntDatePicker
															value={LessDepositEdit.date ? moment.unix(LessDepositEdit.date.seconds).format('YYYY-MM-DD') : null}
															name={'LessDepositEditInput.date'}
															onChange={(e) => { handleDateChange(e, 'LessDepositEditInput.date') }}
															format='DD/MM/YYYY'
															placeholder='DD/MM/YYYY'
															className={`input-enq ${errorFields['LessDepositEditInputdate']}`}
														/>
													</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['LessDepositEditInputreference']}`}
															name='reference'
															value={LessDepositEdit.reference}
															onChange={e => handleChange(e, 'LessDepositEdit')}
															placeholder='Add Reference'
															onKeyPress={e => handleKeyPress(e, 'LessDepositEdit')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['LessDepositEditInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'LessDepositEdit',
																)
															}
															value={LessDepositEdit.amount}
															onKeyPress={e => handleKeyPress(e, 'LessDepositEdit')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'LessDepositEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																handleClearEdit('LessDeposit')
															}}>
																<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
															</button>
														</div>
													</td>
												</tr>
											) : (
												<tr className='items-list' key={_lessDeposit.id}>
													<td className='serial_no'>{index + 1}</td>
													<td>
														<span>{moment(_lessDeposit.date.toDate()).format('DD/MM/YYYY')}</span>
													</td>
													<td>
														<span>{_lessDeposit.reference ? _lessDeposit.reference : '--'}</span>
													</td>

													<td className='amount'>
														<span>
															{' '}
															<strong>
																<NumberFormat
																	decimalScale={2}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	displayType={'text'}
																	value={_lessDeposit.amount ? _lessDeposit.amount : 0}
																/>
															</strong>
														</span>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="edit-icon-input" onClick={() => setLessDepositEdit({ ...LessDepositEdit, show: true, editID: _lessDeposit.id, ..._lessDeposit })}><img src={_images.editIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={e => handleRemoveItem('lessDeposit', _lessDeposit)}>
																<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</Fragment>
									))}
									{showLessDeposit && (
										<tr className='items-list'>
											<td className='serial_no'>{lessDeposit.length === 0 ? '1' : lessDeposit.length + 1}</td>
											<td>
												<AntDatePicker
													value={lessDepositInput.date ? moment.unix(lessDepositInput.date.seconds).format('YYYY-MM-DD') : null}
													name={'lessDepositInput.date'}
													onChange={(e) => { handleDateChange(e, 'lessDepositInput.date') }}
													format='DD/MM/YYYY'
													placeholder='DD/MM/YYYY'
													className={`input-enq ${errorFields['lessDepositInputdate']}`}
												/>
											</td>
											<td>
												<input
													autoComplete="off"
													type='text'
													className={`input-enq ${errorFields['lessDepositInputreference']}`}
													name='reference'
													value={lessDepositInput.reference}
													onChange={e => handleChange(e, 'lessDeposit')}
													placeholder='Add Reference'
													onKeyPress={e => handleKeyPress(e, 'lessDeposit')}
												/>
											</td>

											<td className='amount'>
												<NumberFormat
													allowNegative={false}
													thousandSeparator={true}
													prefix={currencySymbol}
													placeholder={currencySymbol}
													className={`input-enq ${errorFields['lessDepositInputamount']}`}
													decimalScale={2}
													onValueChange={e =>
														onAmountChange(
															e,
															'amount',
															'lessDeposit',
														)
													}
													value={lessDepositInput.amount}
													onKeyPress={e => handleKeyPress(e, 'lessDeposit')}
												/>
											</td>
											<td>
												<div className='form-controls-td'>
													<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'lessDeposit')}><img src={_images.plusIcon} alt="" width="14" /></a>
													<button type='button' className='close-btn' onClick={() => {
														setShowLessDeposit(false)
														setLessDepositInput(lessDepositVM)
													}}>
														<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g id='x' transform='translate(8 8)'><path id='Line_109' d='M0 0L0.001 11.313' className='cls-1' data-name='Line 109' transform='rotate(135 2.343 5.656)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path><path id='Line_110' d='M0.001 0L0 11.313' className='cls-1' data-name='Line 110' transform='rotate(-135 1.658 4)' fill='none' stroke='#733dd9' strokeLinecap='round' strokeWidth='1'></path></g></svg>
													</button>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div></div>
					</div>
					<div className='total-estimate'>
						<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
							<tbody>

								<tr>
									<td className='costtxt'>Deposit</td>
									<td className='cost-box'>- <NumberFormat
										decimalScale={2}
										thousandSeparator={true}
										prefix={currencySymbol}
										displayType={'text'}
										value={state.totalDeposit}
									/></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div style={{ marginTop: '3rem' }}>
						<div className='section-head'>
							<strong> Notes </strong>
						</div>

						<div className='sub-section-details' style={{ border: '0px' }}>
							{/* <TextEditor
								name={'bodyHTML'}
								placeholder={'write here...'}
								value={state.notes ? state.notes : ''}
								onChange={onEditorStateChange}
								toolbarOptions={[]}
								height={350}
								enableMention={false}
							// mentions={mentions}
							/> */}
							<InputTextArea
								className={`form-control notes`}
								name="notes"
								placeholder={'write here...'}
								onChange={handleOnChange}
								value={state.notes ? state.notes : ''}
								rows="3">
							</InputTextArea>
						</div>
					</div>



					<div className='total-estimate'>
						<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
							<tbody>
								<tr>
									<td className='costtxt-blue'>Balance Payable</td>
									<td className='cost-box total'>
										<strong><NumberFormat
											decimalScale={2}
											thousandSeparator={true}
											prefix={currencySymbol}
											displayType={'text'}
											value={state.payableOnDelivery}
										/></strong>
									</td>
								</tr>

							</tbody>
						</table>
					</div>



					<div className='clear'></div>

					<div className='footer'>
						<div className="footer-bottom">
							<p>
								<span style={{ paddingLeft: 0 }} >
									{client.address ? <>{client.address}</> : <></>}
								</span>
								<span style={{ paddingLeft: 0 }}>
									{client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
									{client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
									{client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
									{client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
								</span>
							</p>
						</div>
						<div className='footer-cpy'>
							<div className='footer-left'>
								Powered by{' '}
								<a target='_blank' rel="noopener noreferrer" href='http://fusionsd.com.au/'>
									Fusion SD
								</a>
							</div>
							{!_.isEmpty(state.addedDate) && (
								<div className='footer-right'>Created On - {moment(state.addedDate.toDate()).format('DD/MM/YYYY h:mm A')}</div>
							)}
						</div>
						<div className='clear'></div>
					</div>

					<div className='clear'></div>
				</div>
				<div className='clear'></div>
			</div>

		</>
	) : (
		<>
			<div className='loader-center-screen'>
				<div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
			</div>
		</>)
};

export default QuoteForm;