/** LIBRARIES */
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap'
import _ from 'lodash';
/** PROVIDERS */
//import { PipelineProvider, PipelineConsumer } from './PipelineProvider';
import { LayoutConsumer } from '../../layout/provider';
import { RouteConsumer } from '../../layout/RouteProvider';
import { StageConsumer } from '../provider';
/** COMPONENTS */
//import { default as _images } from "../../../images";
import QuickInfo from './info'

class QuickView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            }
        }
    }

    render() {
        //console.log('RE RENDER QUICK VIEW')
        return (

            <RouteConsumer>
                {({ handleRouteChange, updatenavbar, handleRouteRemove }) => (
                    <>
                        <QuickInfo
                            offerid={this.props.offerid}
                            handleRouteChange={handleRouteChange}
                            dealersettings={this.props.dealersettings}
                            clientUsers={this.props.clientUsers}
                            groupUsers={this.props.groupUsers}
                            history={this.props.history}
                            //updateDealerSettings={updateDealerSettings}

                            updateEnquiry={this.props.updateEnquiry}
                            updateListEnquiry={this.props.updateListEnquiry}
                            isFilterApplied={this.props.isFilterApplied}
                            sidepanelClose={this.props.sidepanelClose}
                            reloadData={this.props.reloadData}
                            updatenavbar={updatenavbar}
                            handleRouteRemove={handleRouteRemove}
                            isReadOnlyView={this.props.isReadOnlyView}
                            handleModuleChange={this.props.handleModuleChange}
                        >
                        </QuickInfo>
                    </>
                )}
            </RouteConsumer>

        );
    }
}

export default withRouter(QuickView);