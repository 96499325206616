/** LIBRARIES */
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */
//const VideoDetails = lazy(() => import('../details'));
import AttendeeConfirmation from './confirmation';
import AttendeeConfirmationEOI from './confirmationEOI';
import AttendeeConfirmationSource from './confirmationSource';
import AttendeeConfirmationTD from './confirmationTD';
import AttendeeReport from './attendeeReport';
import TestdriveReport from './testdriveReport';
import CustomerConsent from './consentReport';


class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        return (
            <main role="main" className={`container-fluid  pl-0 pr-0`}>

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/attendee/confirmation/:id" exact render={(match) => <AttendeeConfirmation contactid={match.match.params.id} />} />
                        <Route path="/attendee/confirmation/eoi/:id" exact render={(match) => <AttendeeConfirmationEOI eventid={match.match.params.id} />} />
                        <Route path="/attendee/confirmation/source/:id" exact render={(match) => <AttendeeConfirmationSource sourceid={match.match.params.id} />} />
                        <Route path="/attendee/confirmation/testdrive/:id" exact render={(match) => <AttendeeConfirmationTD contactid={match.match.params.id} />} />
                        <Route path="/attendee/report/:id" exact render={(match) => <AttendeeReport eventid={match.match.params.id} />} />
                        <Route path="/attendee/report/testdrive/:id" exact render={(match) => <TestdriveReport contactid={match.match.params.id} />} />
                        <Route path="/attendee/report/consent/:id" exact render={(match) => <CustomerConsent contactid={match.match.params.id} />} />
                    </Switch>
                </Suspense>

            </main>
        );
    }
}

export default MainContainer;