import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { moduleNameValue, enquiryStatus } from './viewModel'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';

const RequiredFields = (props) => {
    const [requiredModule, setRequiredmodules] = useState([])

    useEffect(() => {
        var modules = []
        var checkmodules = []
        modules.push(props.activeModule)
        if (props.activeModule === 'contact') {
            modules = ['contact', 'fleetContact', 'testdriveContact', 'tradeinContact', 'financeContact', 'videoContact']
        }
        props.fields.forEach(rec => {
            if (!_.isEmpty(rec['pipeline'])) {
                rec['pipeline'].forEach(el => {
                    if (el[props.activeModule] && el[props.activeModule].split(',').some(t => t === props.fieldName)) {
                        var _pipeline = '';
                        var _stage = '';
                        if (props.pipelines.some(a => el.name.includes(a.value))) {
                            _pipeline = props.pipelines.filter(a => el.name.includes(a.value))[0]
                            if (_pipeline && _pipeline.stages.some(a => el.name.includes(a.value))) {
                                _stage = _pipeline.stages.filter(a => el.name.includes(a.value))[0]
                            }
                        }
                        if (_pipeline)
                            checkmodules.push(<>{_pipeline.name}<span className="required-sub-text"> <Translate text={'pipeline'} /> </span>{
                                _stage ? (<>{_stage.name}<span className="required-sub-text"> <Translate text={'stage'} /> </span></>) : (<></>)
                            }</>);
                    }
                });
            }
            if (!_.isEmpty(rec['status'])) {
                rec['status'].forEach(el => {
                    if (el[props.activeModule] && el[props.activeModule].split(',').some(t => t === props.fieldName)) {
                        var _status = _.find(((!_.isEmpty(props.serviceStatus) && props.serviceStatus.length > 0) ? props.serviceStatus : enquiryStatus), { value: el.name })
                        if (_status)
                            checkmodules.push(<>{_status.name}<span className="required-sub-text"> <Translate text={'status'} /> </span></>);
                    }
                });
            }
            modules.forEach(m => {
                if (rec[m] && rec[m].split(',').some(t => t === props.fieldName)) {
                    if (_.find(moduleNameValue, { value: m }))
                        checkmodules.push(CommonHelper.showLocale(props, m, _.find(moduleNameValue, { value: m }).name));
                }

            })
        })
        setRequiredmodules(checkmodules)
    }, [])

    return (
        <div className="popover-body popover-content p-0">

            <div className="required-popover-list">
                <ul>
                    {
                        requiredModule.map((rec, index) => {
                            return <li key={index}>{rec}</li>
                        })
                    }
                </ul>


            </div>

        </div>

    )
}

export default RequiredFields