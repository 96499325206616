/** LIBRARIES */
import React, { PureComponent } from "react";
import _ from 'lodash'

class InputText extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const { id, name, type, value, onChange, placeholder, autoComplete, className, onKeyUp, maxLength, readOnly, disabled,onKeyDown } = this.props;
        //('RENDER INPUT TEXT', name, value)
        return (
            <input
                id={id ? id : name}
                autoComplete={autoComplete ? autoComplete : "off"}
                name={name}
                type={type ? type : "text"}
                value={value || _.isNumber(value) ? value : ''}
                placeholder={placeholder}
                className={className}
                onChange={onChange}
                onKeyUp={onKeyUp}
                maxLength={maxLength ? maxLength : "250"}
                readOnly={readOnly ? readOnly : false}
                disabled={disabled ? disabled : false}
                onKeyDown={onKeyDown}
            />
        );
    }
}

export default InputText;