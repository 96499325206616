export const amsDescription =
{
    sales: "AMS Pro is a powerful Lead Management System (LMS) / Customer Relationship Management (CRM) platform. It streamlines lead management processes, improves conversions, and drives revenue growth. With its intuitive interface and customizable workflows, AMS Pro enables seamless lead data management, automated assignments, and real-time tracking. Advanced analytics provide valuable insights for sales trends and forecasting. Enhance sales operations and customer relationship management with AMS Pro.",
    tradeInPlus: "",
    video: "",
    finance: "",
    regScan: "",
    oem: "",
    licenseScan: "",
    frontDesk: "",
    service: "The AMS Service System is a specialized software solution for efficient service management in the automotive industry. It simplifies scheduling, parts inventory, work orders, and customer communication. With AMS Service, businesses can streamline workflows, track service history, and ensure timely completion. Integrated billing capabilities further enhance operational efficiency. Improve customer satisfaction and drive profitability with AMS Service in the automotive industry.",
    tradeInPro: "Trade-In Pro is a state-of-the-art vehicle appraisal system that simplifies and enhances the process of valuing and appraising vehicles for trade-ins. This user-friendly software provides accurate and up-to-date vehicle valuation data, market trends analysis, and comprehensive appraisal reports. With Trade-In Pro, automotive professionals can make informed decisions, maximize trade-in values, and improve overall operational efficiency. Elevate your vehicle trade-in operations with Trade-In Pro for increased customer satisfaction and profitability.",
    eventsPro: "Event Pro is a specialized event management system tailored for the automotive industry. It simplifies and enhances event planning and execution processes. With features like seamless registration, dynamic scheduling, exhibitor coordination, and real-time analytics, Event Pro ensures smooth operations and exceptional participant experiences. Drive brand awareness, customer engagement, and business growth in the automotive industry with Event Pro.",
    cafe: "",
    fusionEvents: "",
    tvDisplay: "",
    dailyPerformanceReview: "",
    roster: "",
    serviceInvoice: ""
}

export const autograbDynCols = [
    { name: 'Name', value: 'name', type: 'text', mandatory: true, width: 60, dWidth: 515 },
    { name: 'Cost', value: 'cost', type: 'price', mandatory: true, allowNegative: true, width: 25, dWidth: 215 }
]

export const revolutionDynCols = [
    { name: 'Department ID', value: 'deptID', type: 'text', mandatory: true, width: 25 },
    { name: 'Make', value: 'make', type: 'select', mandatory: true, width: 30 },
    { name: 'Sale Type', value: 'saleTypes', type: 'multi', width: 30 }
]