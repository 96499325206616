/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox,
    ReactSelect, InputText, ReactMultiSelect,
    AntDateRangePicker, InputMultipleText
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'

//#region  ASSIGN VARIABLES
let campaigns = [];
let origins = [];
let appointmentTypes = [];
let slots = [];
let nationalities = [];

let genders = [];
let enquiryOptionsDF = [];



const contact_type = [
    { active: true, value: 'Current Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Current Customer'} /></div> },
    { active: true, value: 'Potential Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Potential Customer'} /></div> }
]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'all', label: <Translate text={'all'} /> },
    { active: true, value: 'stageDate', label: <Translate text={'stageDate'} /> },
    { active: true, value: 'addedDate', label: <Translate text={'addedDate'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'modifiedDate'} /> },
    { active: true, value: 'completedDate', label: <Translate text={'completedDate'} /> },
    { active: true, value: 'wonDate', label: <Translate text={'wonDate'} /> },
    { active: true, value: 'lostDate', label: <Translate text={'lostDate'} /> },
]

const objDateField = {
    name: null,
    type: null,
    range: null
}

const yesNoOptions = [
    { active: true, value: 'Yes', label: 'Yes' },
    { active: true, value: 'No', label: 'No' }
]
//#endregion

const PipelineAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [dealers, setDealers] = useState([]);
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const [tradeinProAllStatus, setAllStatus] = useState([]);
    const [inboundSources, setInboundSources] = useState([])
    const [inboundSubTypes, setInboundSubTypes] = useState([])
    const { columnFields } = props;

    const [stages, setStages] = useState([])

    useEffect(() => {
        const { pipelineFilter } = props;
        if (!_.isEmpty(pipelineFilter))
            setFilterFields(pipelineFilter.value);
        else
            setFilterFields({});

    }, [props.pipelineFilter])


    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        //console.log('props.pipeline', props.pipeline)
        if (_.isEmpty(props.pipeline))
            return;

        let _stages = [];
        let defaultstages = props.pipeline.filter(e => e.default === true)[0].stages;
        defaultstages.forEach((doc) => {
            _stages.push({
                value: doc.value,
                label: doc.name
            });
        });

        setStages(_stages);

    }, [props.pipeline])

    useEffect(() => {
        const { dealersettings, clientUsers, pipelineFilter } = props;

        if (dealersettings && dealersettings.client && (dealersettings.client.settings || dealersettings.client.tradeinPro)) {
            const setttings = Object.assign({}, dealersettings.client.settings);
            const tipSettings = Object.assign({}, dealersettings.client.tradeinPro);
            const brands = dealersettings.allMakeModels;
            const _lostReasons = [];
            const _inboundSources = [];
            const _makes = [];
            const _stages = [];


            enquiryOptionsDF = [];
            if (tipSettings.enquiryOptionsDF)
                enquiryOptionsDF = tipSettings.enquiryOptionsDF;

            let _fields = !_.isEmpty(pipelineFilter) ? pipelineFilter.value : {};

            origins = [];
            tipSettings.origins && tipSettings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            tipSettings.lostReasons && tipSettings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            _inboundSources.push({ value: 'ams pro', active: true, label: 'AMS PRO', subList: [] })
            _inboundSources.push({ value: 'service', active: true, label: 'Service', subList: [] })
            dealersettings.client.integrations && dealersettings.client.integrations.filter(e => e.inbound === true && e.appraisal === true && e.enabled === true && e.active === true).forEach(doc => {
                _inboundSources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('lostReason') && _fields.lostReason &&
                !_.isEmpty(tipSettings.lostReasons.filter(e => e.value === _fields.lostReason)[0])) {
                const _lostSubReasons = [];
                let _subList = !_.isEmpty(tipSettings.lostReasons.filter(e => e.value === _fields.lostReason)[0].subList) ?
                    tipSettings.lostReasons.filter(e => e.value === _fields.lostReason)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    _lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
                setLostSubReasons(_lostSubReasons);
            }

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('inboundSource') && _fields.inboundSource &&
                !_.isEmpty(_inboundSources.filter(e => e.value === _fields.inboundSource)[0])) {
                const _inboundSubTypes = [];
                let _subList = !_.isEmpty(_inboundSources.filter(e => e.value === _fields.inboundSource)[0].subList) ?
                    _inboundSources.filter(e => e.value === _fields.inboundSource)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    _inboundSubTypes.push({
                        value: doc.Name,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.Name
                    });
                });
                setInboundSubTypes(_inboundSubTypes);
            }


            nationalities = [];
            dealersettings.nationalities && dealersettings.nationalities.forEach((doc) => {
                nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            genders = [];
            setttings.genders && setttings.genders.forEach(doc => {
                genders.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });


            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    models: doc.models
                });
            });

            if ((!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make)) {
                const models = [];
                let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                setModels(models);
            }

            let defaultstages = tipSettings.pipelines.filter(e => e.default === true)[0].stages;
            if (props.pipeline)
                defaultstages = props.pipeline.filter(e => e.default === true)[0].stages;

            defaultstages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead').forEach((doc) => {
                _stages.push({
                    value: doc.value,
                    label: doc.name
                });
            });

            const _clientUsers = [];
            if (clientUsers) {
                let ___clientUsers = clientUsers;

                ___clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    });
                });
            }

            let _dealers =CommonHelper.tradeinProClients(dealersettings);

            let _tradeinProAllStatus = [];
            dealersettings?.client?.tradeinPro?.tradeinProStatus && dealersettings.client.tradeinPro.tradeinProStatus.forEach((doc) => {
                _tradeinProAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'New' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });
            setAllStatus(_tradeinProAllStatus);

            dateTypes.forEach((obj) => {
                if (obj.value === 'wonDate') {
                    obj.label = CommonHelper.getNameByValue(_tradeinProAllStatus, 'Won', 'Won') + ' Date';
                }
                else if (obj.value === 'completedDate') {
                    obj.label = CommonHelper.getNameByValue(_tradeinProAllStatus, 'Completed', 'Completed') + ' Date';
                }
                else if (obj.value === 'lostDate') {
                    obj.label = CommonHelper.getNameByValue(_tradeinProAllStatus, 'Lost', 'Lost') + ' Date';
                }
            });

            setDealers(_dealers);
            setClientUsers(_clientUsers);
            setMakes(_makes);
            setStages(_stages);
            setLostReasons(_lostReasons);
            setInboundSources(_inboundSources);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        if (data.name === 'pipeline') {
            const { dealersettings } = props;
            let _pipeline = value;
            const _stages = [];
            if (dealersettings && dealersettings.client && dealersettings.client.sevices && _pipeline && !_pipeline.includes(',')) {
                const setttings = dealersettings.client.sevices;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                defaultstages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead').map((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }
            setStages(_stages);
            state[data.name] = _pipeline;
            if (state.hasOwnProperty('stage'))
                state['stage'] = null;
        }
        else {
            state[data.name] = value;
        }
        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const models = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    active: _.isBoolean(data.active) ? data.active : true,
                    label: CommonHelper.displayModelName(data),
                });
            });
            setModels(models);
            state[data.name] = e.value;
            if (state.hasOwnProperty('model'))
                state['model'] = null;
            setFilterFields(state)
        }
        else if (data.name === 'inboundSource' && e) {
            const _inboundSubTypes = [];
            if (!_.isEmpty(inboundSources.filter(m => m.value === e.value)[0].subList)) {
                inboundSources.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                    _inboundSubTypes.push({
                        value: doc.Name,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.Name
                    });
                });
            }
            setInboundSubTypes(_inboundSubTypes);
            state[data.name] = e.value;
            if (state.hasOwnProperty('inboundSubType'))
                state['inboundSubType'] = null;
            setFilterFields(state)
        }
        else if (data.name === 'pipeline' && e) {
            const { dealersettings } = props;
            let _pipeline = e.value;
            if (dealersettings && dealersettings.client && dealersettings.client.sevices) {
                const setttings = dealersettings.client.sevices;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                const _stages = [];
                defaultstages.forEach((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });

                state[data.name] = _pipeline;
                state['stage'] = null;
                setFilterFields(state)
                setStages(_stages);
            }
        }
        else {
            state[data.name] = e.value;
            setFilterFields(state)
        }


    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (name === 'make') {
                state[name] = null;
                //state['model'] = null;
            }
            else if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else if (name === 'pipeline') {
                state[name] = null;
                state['stage'] = null;
                setStages([]);
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'make') {
                delete state['model'];
            }

            if (name === 'inboundSource') {
                delete state['inboundSubType'];
            }

            if (name === 'pipeline')
                delete state['stage'];
        }

        setFilterFields(state);

    }


    const handlePIDateSave = (val, name) => {
        let state = Object.assign({}, filterFields);
        if (val) val = moment(val).format('MMM YYYY');
        state[name] = val;
        setFilterFields(state);
    };

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state)
    }

    const onNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, filterFields);
        state[name] = e.value;
        setFilterFields(state)
    }

    const removeMultipleText = (index, name) => {
        let arr = filterFields[name] ? filterFields[name].split(',') : []
        arr.splice(index, 1)
        setFilterFields({
            ...filterFields,
            [name]: arr.join(',')
        })
    }

    const handleOnChangeMultipleText = (val, name) => {
        setFilterFields({
            ...filterFields,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
    }

    //#endregion

    //#region DYNAMIC CHANGE EVENTS
    const handleDynamicCheckChange = (e, _type) => {
        const { name, checked } = e.target;
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        if (checked) {
            dynamicFields.push({
                'name': name, 'type': _type
            })
        }
        else {
            dynamicFields = dynamicFields.filter(e => e.name !== name)
        }
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === name) {
                obj.value = value ? value.trim() : null;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const onDynamicCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === name) {
                obj.value = e ? e.floatValue : null;
            }
        });

        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicMultiSelectChange = (selectedOptions, data) => {
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === data.name) {
                obj.value = value;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicSwitchOption = (e, data) => {

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === data.name) {
                obj.value = e ? (e.value === 'Yes' ? true : false) : null;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicDateTypeChange = (e, data) => {

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        if (e) {
            dynamicFields.filter(function (obj) {
                if (obj.name === data.name) {
                    obj.dateType = e.value;

                    if (e.value === 'custom') {
                        obj.range = null;
                        obj.startDate = null;
                        obj.endDate = null;
                    }
                    else {
                        let objData = CommonHelper.handleDynamicDateRangeFilter(obj, props.dealersettings)
                        for (let [key, value] of Object.entries(objData)) {
                            obj[key] = value;
                        }
                    }

                }
            });
            setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
        }
    }


    const handleDynamicDateRangeChange = (val, name) => {
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);

        dynamicFields.filter(function (obj) {
            if (obj.name === name) {

                if (obj.dateType === 'custom' && val) {
                    obj.range = val;
                    let objData = CommonHelper.handleDynamicDateRangeFilter(obj, props.dealersettings)
                    for (let [key, value] of Object.entries(objData)) {
                        obj[key] = value;
                    }
                }
                else {
                    obj.range = null;
                    obj.startDate = null;
                    obj.endDate = null;
                }
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });


    };
    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.pipelineFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {

        Object.keys(filterFields).forEach(key => (filterFields[key] === undefined || (_.isObject(filterFields[key]) && _.isEmpty(filterFields[key]))) ? delete filterFields[key] : {});
        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.pipelineFilter) && !_.isEmpty(props.pipelineFilter.value) && JSON.stringify(props.pipelineFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.pipelineFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }
    }

    const handleSave = () => {
        Object.keys(filterFields).forEach(key => (filterFields[key] === undefined || (_.isObject(filterFields[key]) && _.isEmpty(filterFields[key]))) ? delete filterFields[key] : {});
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };

    //#endregion

    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader">   <Translate text={'appraisals'} /> </div>

                <div className="advanced-filter-list">
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('clientID') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'clientID'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'clientID'}><Translate text={'dealerships'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={dealers}
                                                        name={"clientID"}
                                                        placeholder={'select dealer'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('clientID') && !_.isEmpty(filterFields.clientID) ? filterFields.clientID.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('owner') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'owner'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'owner'}><Translate text={'owner'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactMultiSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect> */}

                                                    <ReactSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('inboundSource') >= 0)
                            ?
                            <>
                                {/* ENQQUIRY TYPE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'inboundSource'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('inboundSource')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'inboundSource'}><Translate text={'Inbound Source'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('inboundSource')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={inboundSources}
                                                        name={"inboundSource"}
                                                        placeholder={'select inbound source'}
                                                        // onChange={handleMultiSelectChange}
                                                        // value={filterFields.hasOwnProperty('inboundSource') && !_.isEmpty(filterFields.inboundSource) ? filterFields.inboundSource.split(',') : []}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('inboundSource') ? filterFields.inboundSource : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    // isMulti={true}
                                                    // isSearchable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                !_.isEmpty(inboundSubTypes)
                                                    ?
                                                    <>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <ReactSelect
                                                                    options={inboundSubTypes}
                                                                    name={"inboundSubType"}
                                                                    placeholder={'select inbound Sub Type'}
                                                                    onChange={handleSelectChange}
                                                                    value={filterFields.hasOwnProperty('inboundSubType') ? filterFields.inboundSubType : null}
                                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('stage') >= 0)
                            ?
                            <>
                                {/* stage */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success">
                                        <InputCheckBox
                                            className="switch"
                                            name={'stage'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('stage')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'stage'}>{'Stages'}</label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('stage')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={stages}
                                                        name={"stage"}
                                                        placeholder={'select stage'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('stage') && filterFields.stage ? filterFields.stage.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0)
                            ?
                            <>
                                {/* STATUS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'status'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'status'}><Translate text={'status'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={tradeinProAllStatus}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('status') ? filterFields.status : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }



                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isFav') >= 0)
                            ?
                            <>
                                {/* isFav */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isFav'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFav')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isFav'}><Translate text={'Favourite Appraisals'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFav')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'Favourite Appraisals'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isFav"
                                                                checked={filterFields.hasOwnProperty('isFav') ? filterFields.isFav : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isFav') }} />
                                                            <label htmlFor="_isFav"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('origin') >= 0)
                            ?
                            <>
                                {/* ORIGIN */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'origin'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('origin')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'origin'}><Translate text={'origin'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('origin')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={origins}
                                                        name={"origin"}
                                                        placeholder={'select origin'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('origin') ? filterFields.origin : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('lostReason') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'lostReason'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('lostReason')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'lostReason'}><Translate text={'lostReason'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('lostReason')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={lostReasons}
                                                        name={"lostReason"}
                                                        placeholder={'select lost reason'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('lostReason') ? filterFields.lostReason : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                !_.isEmpty(lostSubReasons)
                                                    ?
                                                    <>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <ReactSelect
                                                                    options={lostSubReasons}
                                                                    name={"lostSubReason"}
                                                                    placeholder={'select lost sub reason'}
                                                                    onChange={handleSelectChange}
                                                                    value={filterFields.hasOwnProperty('lostSubReason') ? filterFields.lostSubReason : null}
                                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        enquiryOptionsDF && enquiryOptionsDF.filter(m => m.active === true).map((info, index) => {
                            return <Fragment key={index}>

                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={info.value}
                                            checked={(!_.isEmpty(filterFields) && filterFields.dynamicFields && _.find(filterFields.dynamicFields, { name: info.value })) ? true : false}
                                            onChange={(e) => { handleDynamicCheckChange(e, info.type) }} />
                                        <label htmlFor={info.value}><Translate text={info.name} /></label>
                                    </div>
                                </div>
                                {
                                    (!_.isEmpty(filterFields) && filterFields.dynamicFields && _.find(filterFields.dynamicFields, { name: info.value }))
                                        ?
                                        <>
                                            <div className="advanced-filter-box form-style">


                                                {(() => {
                                                    let dynamicfilterFields = _.find(filterFields.dynamicFields, { name: info.value })

                                                    if (info.type === 'toggle' || info.type === 'switch') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <ReactSelect
                                                                            options={yesNoOptions}
                                                                            id={index + '_' + info.value}
                                                                            name={info.value}
                                                                            placeholder={info.name}
                                                                            onChange={handleDynamicSwitchOption}
                                                                            value={_.isBoolean(dynamicfilterFields?.value) ? (dynamicfilterFields.value ? 'Yes' : 'No') : null}
                                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                                            removeClearable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                        {/* <div className="text-left" >
                                                                            <Translate text={info.name} />
                                                                            <span className="switch switch-sm float-right">
                                                                                <InputCheckBox
                                                                                    className={`switch`}
                                                                                    id={index + '_' + info.value}
                                                                                    name={info.value}
                                                                                    checked={dynamicfilterFields?.value ? dynamicfilterFields.value : false}
                                                                                    onChange={(e) => { handleDynamicSwitchOption(e, info.value) }} />
                                                                                <label htmlFor={index + '_' + info.value}></label>
                                                                            </span>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'text') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <InputText
                                                                            name={info.value}
                                                                            onChange={handleDynamicOnChange}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields.value : ''}
                                                                            autoComplete="off"
                                                                            placeholder={info.name}
                                                                            className={`form-control`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'number') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <NumberFormat
                                                                            id={info.value}
                                                                            name={info.value}
                                                                            allowNegative={false}
                                                                            thousandSeparator={false}
                                                                            placeholder={info.name}
                                                                            className={`form-control `}
                                                                            onValueChange={onDynamicCurrencyChange.bind(this, info.value)}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value : ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'price') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <NumberFormat
                                                                            id={info.value}
                                                                            name={info.value}
                                                                            allowNegative={true}
                                                                            decimalScale={2}
                                                                            thousandSeparator={true}
                                                                            prefix={currencySymbol}
                                                                            placeholder={currencySymbol}
                                                                            className={`form-control `}
                                                                            onValueChange={onDynamicCurrencyChange.bind(this, info.value)}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value : null}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                    else if (info.type === 'date') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <ReactSelect
                                                                            options={dateOptions}
                                                                            id={'_' + info.value}
                                                                            name={info.value}
                                                                            placeholder={'select date option'}
                                                                            onChange={handleDynamicDateTypeChange}
                                                                            value={
                                                                                (!_.isEmpty(dynamicfilterFields) && !_.isEmpty(dynamicfilterFields.dateType))
                                                                                    ? dynamicfilterFields.dateType :
                                                                                    null
                                                                            }
                                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                                            removeClearable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (!_.isEmpty(dynamicfilterFields) && !_.isEmpty(dynamicfilterFields.dateType) && dynamicfilterFields.dateType === 'custom')
                                                                        ?
                                                                        <>
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-12">
                                                                                    <AntDateRangePicker
                                                                                        value={
                                                                                            !_.isEmpty(dynamicfilterFields?.range)
                                                                                                ? dynamicfilterFields?.range :
                                                                                                null
                                                                                        }
                                                                                        id={index + '_' + info.value}
                                                                                        name={index + '_' + info.value}
                                                                                        format='DD/MM/YYYY'
                                                                                        onChange={(e) => { handleDynamicDateRangeChange(e, info.value) }}
                                                                                        placeholder='DD/MM/YYYY'
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }

                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'multiselect' || info.type === 'select') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <ReactMultiSelect
                                                                            options={
                                                                                !_.isEmpty(info.subList) ?
                                                                                    _.map(info.subList, function (e) { return { label: e, value: e } })
                                                                                    : []
                                                                            }
                                                                            name={info.value}
                                                                            placeholder={info.name}
                                                                            onChange={handleDynamicMultiSelectChange}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value.split(',') : []}
                                                                            classNamePrefix={`basic-select`}
                                                                            isMulti={true}
                                                                            isSearchable={true}
                                                                        >
                                                                        </ReactMultiSelect>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })()}

                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </Fragment>
                        })
                    }

                    <div className="advanced-filter-subheader"><Translate text={'tradein'} /></div>

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('make') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'make'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'make'}><Translate text={'make&model'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('make') ? filterFields.make : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('model') ? filterFields.model : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                    <div className="advanced-filter-subheader"><Translate text={'contact'} /></div>
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactType') >= 0)
                            ?
                            <>
                                {/* contactType */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactType'}><Translate text={'preferredContactType'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={contact_type}
                                                        name={"contactType"}
                                                        placeholder={'select contact Type'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('contactType') ? filterFields.contactType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isVIP') >= 0)
                            ?
                            <>
                                {/* isVIP */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isVIP'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVIP')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isVIP'}><Translate text={'vip'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVIP')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'vip'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isVIP"
                                                                checked={filterFields.hasOwnProperty('isVIP') ? filterFields.isVIP : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isVIP') }} />
                                                            <label htmlFor="_isVIP"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('gender') >= 0)
                            ?
                            <>
                                {/* GENDER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'gender'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('gender')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'gender'}><Translate text={'gender'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('gender')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={genders}
                                                        name={"gender"}
                                                        placeholder={'select gender'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('gender') ? filterFields.gender : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('nationality') >= 0)
                            ?
                            <>
                                {/* nationality */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'nationality'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('nationality')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'nationality'}><Translate text={'nationality'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('nationality')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={nationalities}
                                                        name={"nationality"}
                                                        placeholder={'select nationality'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('nationality') ? filterFields.nationality : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('areaCode') >= 0)
                            ?
                            <>
                                {/* areaCode */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'areaCode'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('areaCode')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'areaCode'}><Translate text={'areaCode'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('areaCode')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputMultipleText
                                                        placeholder={`area code`}
                                                        className={`form-control react-multi-tag`}
                                                        name="areaCode"
                                                        onChange={(m) => { handleOnChangeMultipleText(m, 'areaCode') }}
                                                        value={filterFields.hasOwnProperty('areaCode') && filterFields.areaCode ? filterFields.areaCode.split(',') : []}
                                                        validateEmail={false}
                                                        getLabel={(_data, index) => {
                                                            return <div data-tag key={index}>
                                                                {_data}
                                                                <span data-tag-handle onClick={() => removeMultipleText(index, 'areaCode')}>
                                                                    ×
                                                                </span>
                                                            </div>
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }



                </div>
            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            {props.noSaveAccess ? <></> : <><button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button></>}
                            <button type="button" className={`btn btn-default float-left ${props.noSaveAccess ? '' : 'ml-2'}`} onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }

            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.pipelineFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'tradeinPro'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default PipelineAdvanceFilters;