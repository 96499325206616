import React from 'react';
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import CommonHelper from '../../services/common';

const EventInfo = ({ userRating, handleEventOpen, clientUsers, enquiryID }) => {
	const {
		documentID,
		title,
	} = userRating;

	const eventDay = moment.unix(userRating.addedDate.seconds).format('DD');
	const eventMonth = moment.unix(userRating.addedDate.seconds).format('MMM');

	return (
		<div
			className='event-app-item flex-event-app'
			id={'info_' + documentID}
			onClick={e => {
				e.preventDefault();
				window.open(`/customer/rating/${enquiryID}?preview=true`, '_blank').focus();
			}}
		>
			<div className='event-app-item-date'>
				<div className='event-app-item-subdate'>{eventDay}</div>
				<div className='event-app-item-submonth'>{eventMonth}</div>
			</div>
			<div className='event-app-item-data'>
				<div className='event-app-item-title'>{title ? title : 'Owner Experience Survey'}</div>
				<div className='event-app-item-info w-100 d-inline-block mt-1'>
					<div className='feedbackform-answer-starrating'>
              <StarRatings
                rating={userRating.customerReview.satisfaction && Number(userRating.customerReview.satisfaction) ? Number(userRating.customerReview.satisfaction) : 0}
                isAggregateRating={true}
                changeRating={() => {}}
                numberOfStars={5}
                starRatedColor={'#ffc107'}
                starHoverColor={'#ffc107'}
                starDimension="20px"
                starSpacing="2px"
                name='satisfaction'
            />
					</div>
				</div>
				<div className='event-app-item-info'>{CommonHelper.getUserNamebyId(clientUsers, userRating.userID)}</div>
			</div>
		</div>
	);
};

export default EventInfo;
