/** LIBRARIES */
import React, { useState, useCallback } from 'react';
import _ from 'lodash';
//import { Dropdown } from 'react-bootstrap'
/** PROVIDERS */
import { LayoutConsumer } from '../../layout/provider';
import { StageProvider, StageConsumer } from './provider'
/** COMPONENTS */
//import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
//import Translate from '../../../constants/translate';
import { PopUpModal } from '../../../components';
import CafeOrder from '../order'
import OrderList from './orderList';
//import QuickView from "./quickview";
import StageLoader from "../../pipeline/stageloader";

const Pipeline = (props) => {
  const [searchText, setSearchText] = useState(props.searchText)
  const [showPanel, setShowPanel] = useState({ show: false, cafe: null })

  const sidepanelClose = useCallback(() => {
    setShowPanel({ show: false, cafe: null })
  }, [])

  const sidepanelOpen = useCallback((cafe) => {
    setShowPanel({ show: true, cafe: cafe })
  }, [])

  return (
    <LayoutConsumer>
      {({ dealersettings, clientUsers, groupUsers }) => dealersettings ? (

        <StageProvider dealersettings={dealersettings} clientUsers={clientUsers} history={props.history} groupUsers={groupUsers}>
          <StageConsumer>
            {({ cafeOrders, isLoading, columns, handleOrderStatus, handlesearchText, listenScrollEvent, stageLoading, sectionloading }) => (
              <div className="middle-wrapper middle-wrapper-pipeline mt-0">
                <div className="filter-panel">
                  {/* <div className="filter-switch"> <a href="" className="switch-active"> <i className="ico icon-pipeline-1"></i></a> <a href=""> <i className="ico icon-list"></i></a> </div> */}
                  {/* <div className="filter-item-btn ml-2">
                    <a href="#" onClick={(e) => { e.preventDefault();}}>
                      <i className="ico icon-filter"></i>
                      <span> <Translate text={'All Orders'} /></span>
                      <i className="ico icon-dropdown"></i>
                    </a>
                  </div> */}

                  <div className="filter-search">
                    <div className="input-group ">
                      <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          localStorage.kitchensearchText = e.target.value;

                          if (!e.target.value)
                            handlesearchText('')
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handlesearchText(searchText)
                          }
                        }}
                        autoComplete="off"
                      />
                      <div className="input-group-append input-search-clear">
                        {
                          searchText && searchText.length > 0
                            ?
                            <a href="#"
                              className="input-search-clear-icon"
                              title="Clear"
                              onClick={(e) => {
                                e.preventDefault();
                                setSearchText('');
                                localStorage.kitchensearchText = '';
                                handlesearchText('')
                              }}
                            >
                              <i className="ico icon-remove"></i>
                            </a>
                            :
                            <>
                            </>
                        }

                        <button
                          className="btn btn-default"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handlesearchText(searchText)
                          }}>
                          <i className="ico icon-search-fill"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>

                {
                  (isLoading && _.isEmpty(cafeOrders)) ? (<StageLoader columns={columns}></StageLoader>) :
                    <>
                          <OrderList
                            sidepanelOpen={sidepanelOpen}
                            cafeOrders={cafeOrders}
                            columns={columns}
                            handleOrderStatus={handleOrderStatus}
                            listenScrollEvent={listenScrollEvent}
                            stageLoading={stageLoading}
                            sectionloading={sectionloading}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                          >
                          </OrderList>                    
                    </> 
                }
                <PopUpModal show={showPanel.show}>
                  <CafeOrder
                    show={showPanel.show}
                    handleClose={sidepanelClose}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={groupUsers}
                    cafe={showPanel.cafe}
                    docID={showPanel.cafe ? showPanel.cafe.documentID : ''}
                  >
                  </CafeOrder>
                </PopUpModal>

              </div>
            )}
          </StageConsumer>
        </StageProvider>
      ) : (<></>)}
    </LayoutConsumer>
  );
}


export default Pipeline;