/** LIBRARIES */
import React, { Component } from 'react';
import Swal from 'sweetalert2'
import _ from "lodash";
/** COMPONENTS */
import Translate from '../../constants/translate';
import MentionEditor from '../../components/mentionEditor/mentionEditor';
import CommonHelper from '../../services/common';
import { default as _images } from '../../images';

const lostReasons = [];
const lostSubReasons = [];
export default class LostApprovalNotes extends Component {
    constructor(props) {
        super(props);
        this.isLoadingA = false;
        this.isLoadingR = false;
        this.state = {
            fields:
            {
                notes: ''
            },
            mentions: [],
            teamMentions: null,
            errors: {}
        }
        this.mentionsData = props.clientUsers.map(clientUser => {
            return {
                name: `@${clientUser.name}`,
                id: clientUser.id,
                avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
            };
        });
        props.dealersettings.client.teams.forEach(team => {
            this.mentionsData.push({
                name: `@${team.name}`,
                id: team.user_ids.join(','),
                avatar: _images.group
            })
        })
    }

    onMentionChange = notesText => {
        const e = {
            target: {
                name: 'notes',
                value: notesText,
            },
        };

        // this.handleOnAreaChange(e, true);
        const fields = Object.assign({}, this.state.fields);
        fields.notes = notesText
        this.setState({ fields: fields });

    };

    onMentionSelect = (option, ownerID) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            let idToPass = id
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            this.setState({
                teamMentions: idToPass
            })
        }

        // Check to avoid adding duplicate entries in mention
        const check = this.state.mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            this.setState({
                mentions: [...this.state.mentions, { name, id: idToPass }]
            })
        }
    };

    clearTeamMentions = () => {
        this.setState({
            teamMentions: null
        })
    }

    loadEnquiry = () => {
        const { notes, dealersettings, clientID, lostReason } = this.props;
        const _clientData = !_.isEmpty(clientID) && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            dealersettings.group.clients[clientID];

        let setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        if (_clientData)
            setttings = dealersettings.group.clients[clientID].settings;

        if (setttings) {
            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            if (lostReason && setttings.lostReasons && !_.isEmpty(setttings.lostReasons.filter(e => e.value === lostReason)[0])) {
                let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === lostReason)[0].subList) ?
                    setttings.lostReasons.filter(e => e.value === lostReason)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
            }
        }
        this.setState({
            fields:
            {
                notes: notes
            },
            errors: {}
        });
    }

    componentDidMount() {
        this.loadEnquiry();
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleAprove = (e) => {
        e.preventDefault();
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to Approve.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                const { notes } = this.state.fields;
                this.isLoadingA = true;
                this.forceUpdate();
                this.props.handleClose('approved', notes, this.state.mentions);

            }
        })

    };

    handleReject = (e) => {
        e.preventDefault();
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to Reject.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                const { notes } = this.state.fields;
                this.isLoadingR = true;
                this.forceUpdate();
                this.props.handleClose('rejected', notes, this.state.mentions);
            }
        })

    }


    render() {
        const { notes } = this.state.fields;

        return (
            <>

                <div className="popover-body popover-content">
                    <div className="form-style popover-middle-pipeline">
                        {!this.props.usedWithList && (this.props.lostReason || this.props.lostSubReason) && (
                            <div className="enquiry-detailed-info mt-0 mb-2">
                                <ul>
                                    {
                                        this.props.lostReason && _.find(lostReasons, { value: this.props.lostReason }) ? (
                                            <li> <span><Translate text={'lostReason'} />:</span> {_.find(lostReasons, { value: this.props.lostReason }).label}</li>
                                        ) : (<></>)
                                    }
                                    {
                                        this.props.lostSubReason && _.find(lostSubReasons, { value: this.props.lostSubReason }) ? (
                                            <li> <span><Translate text={'lostSubReason'} />:</span> {_.find(lostSubReasons, { value: this.props.lostSubReason }).label}</li>
                                        ) : (<></>)
                                    }
                                </ul>
                            </div>
                        )}
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                {/* <InputTextArea
                                    className={`form-control notes ${this.state.errors["notes"]}`}
                                    name="notes"
                                    autoFocus={true}
                                    onChange={this.handleOnChange}
                                    value={notes}
                                    maxLength="500"
                                    rows="3">
                                </InputTextArea> */}
                                <MentionEditor
                                    mentionsData={this.mentionsData}
                                    onMentionChange={this.onMentionChange}
                                    onMentionSelect={this.onMentionSelect}
                                    notes={notes ? notes : ''}
                                    focusOnMount={false}
                                    isNoteEmpty={false}
                                    teamMentions={this.state.teamMentions}
                                    clearTeamMentions={this.clearTeamMentions}
                                    clientUsers={this.props.clientUsers}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="popover-footer d-inline-block width100">
                        <button
                            type="button"
                            className="btn btn-primary float-right"
                            onClick={(e) => this.handleAprove(e)}
                        >
                            {this.isLoadingA ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'approve'} />
                        </button>
                        <button
                            type="button"
                            className="btn btn-red float-left"
                            onClick={(e) => this.handleReject(e)}
                        >
                            {this.isLoadingR ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'reject'} />
                        </button>
                    </div>


                </div>


            </>
        );
    }
}
