export const residenceStatusOptions = [
	{ active: true, value: 'Australian Citizen', label: 'Australian Citizen' },
	{ active: true, value: 'Permanent Citizen', label: 'Permanent Citizen' },
	{ active: true, value: 'Visa', label: 'Visa' },
];

export const residenceTypeOptions = [
	{ active: true, value: 'Home Owner', label: 'Home Owner' },
	{ active: true, value: 'Mortgage', label: 'Mortgage' },
	{ active: true, value: 'Living with relatives', label: 'Living with relatives' },
	{ active: true, value: 'Renting', label: 'Renting' },
];

export const residenceYearOptions = [
	{ active: true, value: 'Less than a year', label: 'Less than a year' },
	{ active: true, value: '1 year', label: '1 year' },
	{ active: true, value: '2 years', label: '2 years' },
	{ active: true, value: '3 years', label: '3 years' },
	{ active: true, value: '4 years', label: '4 years' },
	{ active: true, value: '5 years', label: '5 years' },
	{ active: true, value: '6 years', label: '6 years' },
	{ active: true, value: '7 years', label: '7 years' },
	{ active: true, value: '8 years', label: '8 years' },
	{ active: true, value: '9 years', label: '9 years' },
	{ active: true, value: '10 years', label: '10 years' },
	{ active: true, value: '11 years', label: '11 years' },
	{ active: true, value: '12 years', label: '12 years' },
	{ active: true, value: '13 years', label: '13 years' },
	{ active: true, value: '14 years', label: '14 years' },
	{ active: true, value: '15 years', label: '15 years' },
	{ active: true, value: '16 years', label: '16 years' },
	{ active: true, value: '17 years', label: '17 years' },
	{ active: true, value: '18 years', label: '18 years' },
	{ active: true, value: '19 years', label: '19 years' },
	{ active: true, value: '20 years', label: '20 years' },
	{ active: true, value: '21 years', label: '21 years' },
	{ active: true, value: '22 years', label: '22 years' },
	{ active: true, value: '23 years', label: '23 years' },
	{ active: true, value: '24 years', label: '24 years' },
	{ active: true, value: '25+ years', label: '25+ years' },
];

export const employmentPeriodOptions = [
	{ active: true, value: 'Less than a year', label: 'Less than a year' },
	{ active: true, value: '1 year', label: '1 year' },
	{ active: true, value: '2 years', label: '2 years' },
	{ active: true, value: '3 years', label: '3 years' },
	{ active: true, value: '4 years', label: '4 years' },
	{ active: true, value: '5 years', label: '5 years' },
	{ active: true, value: '6 years', label: '6 years' },
	{ active: true, value: '7 years', label: '7 years' },
	{ active: true, value: '8 years', label: '8 years' },
	{ active: true, value: '9 years', label: '9 years' },
	{ active: true, value: '10 years', label: '10 years' },
	{ active: true, value: '10+ years', label: '10+ years' },
];

export const employmentStatusOptions = [
	{ active: true, value: 'Permanent Full Time', label: 'Permanent Full Time' },
	{ active: true, value: 'Permanent Part Time', label: 'Permanent Part Time' },
	{ active: true, value: 'Casual', label: 'Casual' },
	{ active: true, value: 'Self Employed', label: 'Self Employed' },
	{ active: true, value: 'Currently Unemployed', label: 'Currently Unemployed' },
];

export const frequency = [
	{ active: true, value: 'Weekly', label: 'Weekly' },
	{ active: true, value: 'Fortnightly', label: 'Fortnightly' },
	{ active: true, value: 'Monthly', label: 'Monthly' },
	{ active: true, value: 'Annually', label: 'Annually' },
];

export const yesOrNo = [
	{ active: true, value: 'Yes', label: 'Yes' },
	{ active: true, value: 'No', label: 'No' },
];
