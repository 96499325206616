/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash';
/** COMPONENTS */
import { default as _images } from '../../images';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common'
import { enquiryStatus } from '../../services/enum'

const EnquiryInfo = ({ enquiry, clientUsers, sidepanelOpen, history, showMore, dealersettings, companyView, groupUsers, campaigns }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();

    const { documentID, status, requirement, isVehicle, addedBy, displayID, owner, dealerName, transferHistory, requirementCount, soldCount, isAfterSales, linkID } = enquiry;

    const _transferHistoryData = !_.isEmpty(transferHistory) ? CommonHelper.getLatestTransferHistory(transferHistory, dealersettings, true) : null;

    const _reqCount = requirementCount && requirementCount > 1 ? (requirementCount - 1) : null;

    const _wonCount = soldCount && soldCount > 1 ? (soldCount) : null;

    let ___status = (status && status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : status;
    const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
    let _objCurrentStatus = ___status && enquiryAllStatus.filter(e => e.value === ___status)[0]

    const liteVersion = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.clientSettings) &&
        dealersettings.client.clientSettings.liteVersion) ? true : false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };
    return (
        <div className="vehicle-item" id={'info_' + documentID} onClick={(e) => {
            e.preventDefault();
            sidepanelOpen(documentID);
        }}>
            <div className="flex-vehicle">
                {
                    showMore
                        ?
                        <div className="quickview-activity-more">
                            <a href="#" onClick={(e) => {
                                e.stopPropagation();
                                setEditshow(!editshow)
                                if (editshow)
                                    document.addEventListener('click', setEditshow(false));
                            }}><i className="ico icon-more"></i></a>
                            <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                <ul className="more-dropdown">
                                    <li> <a href="#" onClick={(e) => {
                                        history.push("/enquiry/details/" + documentID);
                                    }}> <i className="ico icon-expand"></i>  <Translate text={'view'} /></a> </li>
                                </ul>
                            </div>
                        </div>
                        :
                        <></>
                }


                <div className="vehicle-item-image">
                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                        <img src={CommonHelper.showBrandLogo(dealersettings, (!_.isEmpty(requirement?.stock) ? requirement.stock?.make : requirement?.make))} className="img-object-fit-contain" width="70" height="70" alt="" />
                    </a>
                </div>

                <div className="vehicle-data">
                    {
                        companyView && !_.isEmpty(enquiry?.contact)
                            ?
                            <>
                                <div className="vehicle-item-title mini-badge">
                                    <i className='ico icon-Contacts mr-1'></i>
                                    {CommonHelper.displayContactName(null, enquiry.contact)}
                                </div>
                            </>
                            :
                            <></>
                    }

                    {
                        displayID
                            ?
                            <>

                                <div className="vehicle-item-title mini-badge">

                                    {
                                        linkID && linkID !== displayID
                                            ?
                                            <i className="ico icon-sub-list mr-1" title='Sub Enquiry'></i>
                                            :
                                            <i className="ico icon-enquiry mr-1" title='Enquiry'></i>
                                    }
                                    {displayID}
                                </div>

                            </>
                            :
                            <></>
                    }

                    {(() => {

                        if (isVehicle && !_.isEmpty(requirement)) {
                            if (!_.isEmpty(requirement.stock)) {
                                return (
                                    <>
                                        <div className="vehicle-item-title mini-badge" >
                                            {(requirement.stock.make ? requirement.stock.make : '') + ' ' + (requirement.stock.model ? requirement.stock.model : '')}
                                            {requirement.saleType ? (<div className="badge badge-pill badge-white ml-1">
                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                    requirement.saleType, '')}
                                            </div>) : ''}


                                            {
                                                _reqCount
                                                    ?
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                        }
                                                    >
                                                        <div className="badge badge-pill badge-blue ml-1">
                                                            +{_reqCount} <Translate text={'more'} lowerCase={true} />
                                                        </div>
                                                    </OverlayTrigger>

                                                    :
                                                    <></>
                                            }

                                            {
                                                _wonCount
                                                    ?
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip><Translate text={'Won Requirements'} /></Tooltip>
                                                        }
                                                    >
                                                        <div className="badge badge-pill badge-available ml-1">
                                                            {_wonCount}  <Translate text={'won'} upperCase={true} />
                                                        </div>

                                                    </OverlayTrigger>

                                                    :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            requirement.stock.extColor || requirement.stock.intColor || requirement.stock.year
                                                ?
                                                <div className="enquiry-info-campaign w-100">
                                                    {requirement.stock.extColor ? `${requirement.stock.extColor + (requirement.stock.intColor ? ' - ' + requirement.stock.intColor : '')},` : ''} {requirement.stock.year ? requirement.stock.year : ''}
                                                </div>
                                                :
                                                <></>
                                        }

                                        {
                                            requirement.stock.stockNo || requirement.stock.regNo || requirement.stock.vinNo
                                                ?
                                                <div className="enquiry-info-campaign w-100 pb-2">
                                                    {requirement.stock.stockNo ? <><strong><Translate text={'stockNo'} />:</strong> {`${requirement.stock.stockNo}, `}</> : <></>}
                                                    {requirement.stock.regNo ? <><strong><Translate text={'regNo'} />:</strong> {`${requirement.stock.regNo}`}</> :
                                                        <>{requirement.stock.vinNo ? <><strong><Translate text={'vinNo'} />:</strong> ${requirement.stock.vinNo}</> : <></>}</>}
                                                </div>
                                                :
                                                <></>
                                        }

                                    </>
                                )
                            }
                            else {
                                return (
                                    <>
                                        <div className="vehicle-item-title mini-badge">
                                            {(requirement.make ? requirement.make : '') + ' ' + (requirement.model ? requirement.model : '')}
                                            {requirement.saleType ? (<div className="badge badge-pill badge-white ml-1">
                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                    requirement.saleType, '')}
                                            </div>) : ''}

                                            {
                                                _reqCount
                                                    ?
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                        }
                                                    >
                                                        <div className="badge badge-pill badge-blue ml-1">
                                                            +{_reqCount} <Translate text={'more'} lowerCase={true} />
                                                        </div>
                                                    </OverlayTrigger>

                                                    :
                                                    <></>
                                            }

                                            {
                                                _wonCount
                                                    ?
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip><Translate text={'Won Requirements'} /></Tooltip>
                                                        }
                                                    >
                                                        <div className="badge badge-pill badge-available ml-1">
                                                            {_wonCount}  <Translate text={'won'} upperCase={true} />
                                                        </div>

                                                    </OverlayTrigger>

                                                    :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            requirement.extColor || requirement.intColor || requirement.year
                                                ?
                                                <div className="enquiry-info-campaign w-100">
                                                    {requirement.extColor ? `${requirement.extColor + (requirement.intColor ? ' - ' + requirement.intColor : '')},` : ''} {requirement.year ? requirement.year : ''}
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            requirement.stockNo || requirement.regNo || requirement.vinNo
                                                ?
                                                <div className="enquiry-info-campaign w-100 pb-2">
                                                    {requirement.stockNo ? <><strong><Translate text={'stockNo'} />:</strong> {`${requirement.stockNo}, `}</> : <></>}
                                                    {requirement.regNo ? <><strong><Translate text={'regNo'} />:</strong> {`${requirement.regNo}`}</> :
                                                        <>{requirement.vinNo ? <><strong><Translate text={'vinNo'} />:</strong> ${requirement.vinNo}</> : <></>}</>}
                                                </div>
                                                :
                                                <></>
                                        }

                                    </>
                                )
                            }


                        }
                        else {
                            return <>
                                {
                                    liteVersion && !_.isEmpty(enquiry.td) && !_.isEmpty(enquiry.td.make)
                                        ?
                                        <>
                                            <div className="vehicle-item-title mini-badge">
                                                <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-activity-testdrive')}></i>
                                                {enquiry.td.make + ' ' + enquiry.td.model}
                                                {
                                                    enquiry.td.saletype
                                                        ?
                                                        <>
                                                            <div className="badge badge-pill badge-white ml-1">
                                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                    enquiry.td.saletype, '')}
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </div>

                                        </>
                                        :
                                        <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                                }

                            </>
                        }
                    })()}

                    {isAfterSales ? (<div className="badge badge-pill badge-white ml-1"> <Translate text={'afterSales'} upperCase={true} /></div>) : ''}
                    {
                        enquiry.campaign && !_.isEmpty(campaigns) && campaigns.filter(a => a.value === enquiry.campaign).length
                            ?
                            <>
                                <>
                                    <div className="enquiry-info-campaign w-100"><i className="ico icon-campaign"></i>{campaigns.filter(a => a.value === enquiry.campaign)[0].label} </div>
                                </>
                            </>
                            :
                            <></>
                    }

                    {!_.isEmpty(_transferHistoryData) ?
                        <div className="vehicle-item-title blue-color"><i className="ico icon-location"></i>{_transferHistoryData.name}
                            <span className="user-role-span ml-1">{_transferHistoryData.value}</span></div> :
                        <>{dealerName ? <div className="dealer-group-title blue-color">{dealerName}</div> : <></>}</>}





                </div>
            </div>
            <div className="vehicle-current-status pl-2 pr-2">
                {
                    owner ? (<OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>{CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)}</Tooltip>
                        }
                    >
                        <div className="float-right">
                            <div className="pipeline-users pl-0 pt-2">
                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                    <img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                </a>
                            </div>
                        </div>
                    </OverlayTrigger>) : (<></>)
                }
                <div className="float-left">

                    {
                        status ? (<div className="vehicle-item-owner " >
                            {
                                status === enquiryStatus.PENDINGLOST
                                    ?
                                    <>
                                        <div className={`badge badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip><Translate text={'Lost Pending Approval'} /></Tooltip>
                                            }
                                        >
                                            <div className="pipeline-pendinglost ml-1"> <span className="status-pendinglost-circle">PA</span> </div>
                                        </OverlayTrigger>
                                    </>
                                    :
                                    <>
                                        {
                                            _objCurrentStatus
                                                ?
                                                <>
                                                    <div
                                                        className={`badge badge-status-${status === 'open' ? 'open' : 'empty'}`}
                                                        style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                    >
                                                        <Translate
                                                            text={_objCurrentStatus.name}
                                                            upperCase={true}
                                                        />

                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                    </>
                            }

                        </div>) : (<></>)
                    }

                </div>


            </div>

        </div>

    )
};

export default EnquiryInfo;
