export const tableFields = [
  {
    name: 'Client',
    value: 'client',
    flex: 1,
    default: true
  },
  {
    name: 'Path',
    value: 'currentPath',
    flex: 1,
    default: true,
  },
  {
    name: 'Error',
    value: 'error',
    flex: 2,
    default: true,
  },
  {
    name: 'Description',
    value: 'strDescription',
    flex: 2,
    default: true,
  },
  {
    name: 'Log Date',
    value: 'logDate',
    flex: 1,
    default: true,
  }
]
