import $ from 'jquery';
import React, { useState, useEffect, createRef } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import toast from 'toasted-notes';
import Swal from 'sweetalert2';

import Translate from '../../../../constants/translate';
import '../../../../styles/formEditor.scss';
import { PopUpModal } from '../../../../components';
import CommonHelper from '../../../../services/common';
import RightPreview from './rightPreview';
import PublishForm from './publishModal';
import FormSettings from './formSettings';

import { formVM, defaultFields } from './viewModel';

window.jQuery = $;
window.$ = $;

require('jquery-ui-sortable');
require('formBuilder');
require('formBuilder/dist/form-render.min.js');

const AddForm = props => {
	const [feedbackForm, setFeedbackForm] = useState(props.feedbackForm);
	const [loader, setLoader] = useState(false);
	const [fbEditor, setFormEditor] = useState(null);
	const [errorFields, setErrorFields] = useState({});
	const [formFields, setFormFields] = useState({});
	const [publishForm, setPublishForm] = useState({
		show: false,
		title: '',
		feedbackForm: '',
	});
	const [showSettings, setShowSettings] = useState({
		show: false,
		title: 'Form Settings',
		fromPublish: false
	});
	const [embedClicked, setEmbedClicked] = useState(false);

	const fb = createRef();

	useEffect(() => {
		if (_.isEmpty(props.feedbackForm)) {
			let newformVM = Object.assign({}, formVM);
			newformVM.documentID = window.firebase
				.firestore()
				.collection(`webFormTemplates`)
				.doc().id;
			newformVM.clientID = props.dealersettings.client.id;
			newformVM.addedBy = localStorage.uid;
			newformVM.modifiedBy = localStorage.uid;
			newformVM.addedDate = window.firebase.firestore.Timestamp.now();
			newformVM.modifiedDate = window.firebase.firestore.Timestamp.now();
			newformVM.type = 'tradeinPro';
			newformVM.isDeleted = false;
			setFeedbackForm(newformVM);
		}
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (fbEditor !== null && fbEditor.actions.save) {
				const _formFields = fbEditor.actions.save();
				setFormFields(_formFields);
				if (_formFields
					&& _formFields.length > 0
					&& document.querySelectorAll('[data-type="pageBreak"]')) {
					document.querySelectorAll('[data-type="pageBreak"]').forEach(dom => {
						if (_formFields[_formFields.length - 1] && (_formFields[_formFields.length - 1].type === 'pageBreak' || _formFields[_formFields.length - 1].type === 'header')) {
							dom.classList.add('btn-disable');
						}
						else {
							dom.classList.remove('btn-disable');
						}
					})
				}
			}
		}, 500);
		return () => clearInterval(interval);
	}, [fbEditor]);

	const handleOnChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		// console.log('handleOnChange');
		// console.log('name', name);
		// console.log('value', value);
		var array1 = value.split(' ');
		var str = value;

		if (array1.length > 0) {
			var newarray1 = [];
			for (var x = 0; x < array1.length; x++) {
				newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
			}
			setFeedbackForm({
				...feedbackForm,
				[name]: newarray1.join(' '),
			});
		} else {
			if (str && str.length >= 1) {
				var firstChar = str.charAt(0);
				var remainingStr = str.slice(1);
				str = firstChar.toUpperCase() + remainingStr;
			}
			setFeedbackForm({
				...feedbackForm,
				[name]: str,
			});
		}

		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleColorChange = (color, name) => {
		// console.log('color name', color, name)
		setFeedbackForm({
			...feedbackForm,
			[name]: color.hex,
		});
	};

	const handleSelectChange = (e, data) => {
		// console.log('handleSelectChange');
		// console.log('data.name', data.name);
		if (e) {
			// console.log('e.value', e.value);
			if (data.name === 'addTo') {
				setFeedbackForm({
					...feedbackForm,
					[data.name]: e.value,
					owner: '',
					activityType: '',
					origin: '',
					enquiryType: '',
					campaign: '',
				});
			} else if (data.name === 'level') {
				setFeedbackForm({
					...feedbackForm,
					[data.name]: e.value,
					levelID: '',
					owner: '',
					activityType: '',
					origin: '',
					enquiryType: '',
					campaign: '',
				});
			} else if (data.name === 'addTo') {
				setFeedbackForm({
					...feedbackForm,
					[data.name]: e.value,
					owner: '',
					activityType: '',
					origin: '',
					enquiryType: '',
					campaign: '',
				});
			} else {
				setFeedbackForm({
					...feedbackForm,
					[data.name]: e.value,
				});
			}
		} else {
			setFeedbackForm({
				...feedbackForm,
				[data.name]: '',
			});
		}
		setErrorFields({
			...errorFields,
			[data.name]: '',
		});
	};

	const handlePublish = () => {
		if (_.isEmpty(formFields) || formFields.length === 0) {
			Swal.fire(
				CommonHelper.showLocale(props, 'Please add atleast one field to publish.'),
				'',
				'info',
			);
			return;
		}
		setEmbedClicked(true)
		handleSave(false)
	}

	const handleSave = (closeOnSave, handleSettingsClose) => {
		// console.log('handleSave');
		const formFields = fbEditor.actions.save();
		if (_.isEmpty(formFields) || formFields.length === 0) {
			Swal.fire(CommonHelper.showLocale(props, 'Please add atleast one field to embed form.'), '', 'info');
			return;
		}

		let formIsValid = true;
		let errorClass = 'input_error';
		let errors = {};
		if (_.isEmpty(feedbackForm['title'])) {
			formIsValid = false;
			errors['title'] = errorClass;
		}
		if (_.isEmpty(feedbackForm['level'])) {
			formIsValid = false;
			errors['level'] = errorClass;
		}
		if (!_.isEmpty(feedbackForm['level']) && feedbackForm['level'] !== 'oem' && _.isEmpty(feedbackForm['levelID'])) {
			formIsValid = false;
			errors['levelID'] = errorClass;
		}
		if (_.isEmpty(feedbackForm['addTo'])) {
			formIsValid = false;
			errors['addTo'] = errorClass;
		}
		if (!_.isEmpty(feedbackForm['addTo']) && feedbackForm['addTo'] === 'activity') {
			if (_.isEmpty(feedbackForm['activityType'])) {
				formIsValid = false;
				errors['activityType'] = errorClass;
			}
			if (_.isEmpty(feedbackForm['owner'])) {
				formIsValid = false;
				errors['owner'] = errorClass;
			}
		}

		setErrorFields(errors);
		if (!formIsValid) {
			setEmbedClicked(false)
			setShowSettings({
				show: true,
				title: 'Form Settings',
				fromPublish: closeOnSave ? false : true
			})
			return;
		}

		const iframe = document.querySelector('.dynamicform-embed-previewwrap')
		const form = document.querySelector('.dynamicform-embed-main')
		let iframeHeight = null
		let formHeight = null
		if (iframe && iframe.scrollHeight) {
			iframeHeight = iframe.scrollHeight
		}
		if (form && form.scrollHeight) {
			formHeight = form.scrollHeight
		}

		let _webFormObj = {
			...feedbackForm,
			fields: formFields.map((rec, i) => {
				return {
					...rec,
					name: rec.name ? rec.name : `${rec.type}-${i}`
				}
			}),
			modifiedBy: localStorage.uid,
			modifiedDate: window.firebase.firestore.Timestamp.now(),
			addedDate: window.firebase.firestore.Timestamp.now(),
			addedBy: localStorage.uid,
			iframeHeight,
			formHeight,
		}

		if (feedbackForm.level === 'oem') {
			_webFormObj.oemID = props.dealersettings.oemID
		} else if (feedbackForm.level === 'region') {
			_webFormObj.regionID = feedbackForm.levelID ? feedbackForm.levelID : ''
			_webFormObj.settingsID = props.dealersettings.oemID
		} else if (feedbackForm.level === 'group') {
			_webFormObj.groupID = feedbackForm.levelID ? feedbackForm.levelID : ''
			_webFormObj.settingsID = props.dealersettings.oemID
		} else if (feedbackForm.level === 'individual') {
			_webFormObj.clientID = feedbackForm.levelID ? feedbackForm.levelID : ''
		} else {
			_webFormObj.clientID = props.dealersettings.client.id
		}
		_webFormObj.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
		setLoader(true);
		window.firebase
			.firestore()
			.doc(`webFormTemplates/${feedbackForm.documentID}`)
			.set(
				{
					..._.pick(
						_webFormObj,
						_.keys(formVM),
					),
				},
				{ merge: true },
			)
			.then(snapshot => {
				setLoader(false);
				setEmbedClicked(false)
				// add hre
				props.setAddForm({
					...props.addForm,
					title: feedbackForm.title
				})
				if (closeOnSave) {
					toast.notify(
						_.isEmpty(props.feedbackForm)
							? 'Form added successfully'
							: 'Form updated successfully',
						{
							duration: 2000,
						},
					);
					// props.handleClose();
				} else {
					setPublishForm({
						feedbackForm: feedbackForm,
						show: true,
						title: 'Publish Form',
					});
				}
				handleSettingsClose && handleSettingsClose();

			})
			.catch(error => {
				console.error(error);
				Swal.fire('Something went wrong.', '', 'error');
			});
	};

	useEffect(() => {
		// Update the document title using the browser API
		let states = '';
		if (props?.dealersettings?.client?.country && props?.dealersettings?.states) {
			props.dealersettings.states.filter(item => item.country_code === props.dealersettings.client.country)
				.forEach(rec => {
					states += `<option value="${rec.code}">${rec.name}</option>`;
				})
		}


		let options2 = {
			//append: '<button>Submit</button>',
			disableFields: [
				'button',
				//'paragraph',
				'hidden',
				'file',
				'autocomplete',
				'number',
				'header',
				'starRating'
			],
			disabledFieldButtons: {
				header: ['remove', 'copy'], // disables the remove butotn for text fields
				makeAndModel: ['copy'],
				pageBreak: ['edit', 'copy'],
				// select: ['edit'] // disables the edit button for select fields
			},
			defaultFields: props.feedbackForm
				? props.feedbackForm.fields
				: [...defaultFields],
			disabledActionButtons: ['save', 'data', 'clear'],
			fields: [
				{
					label: 'Make and Model',
					attrs: {
						type: 'makeAndModel'
					},
					icon: '<i class="ico icon-Stock"></i>'
				},
				{
					label: 'Phone',
					attrs: {
						type: 'phone'
					},
					icon: '<i class="ico icon-call"></i>'
				},
				{
					label: 'Email',
					attrs: {
						type: 'email'
					},
					icon: '<i class="ico icon-Mail"></i>'
				},
				{
					label: 'Name',
					attrs: {
						type: 'firstAndLastName'
					},
					icon: '<i class="ico icon-Contacts"></i>'
				},
				{
					label: 'Reg No.',
					attrs: {
						type: 'regNo'
					},
					icon: '<i class="ico icon-reg-no"></i>'
				},
				{
					label: 'State',
					attrs: {
						type: 'state'
					},
					icon: '<i class="ico icon-activity-world"></i>'
				},
				{
					label: 'Page Break',
					attrs: {
						type: 'pageBreak'
					},
					icon: '<i class="ico icon-activity-scissor"></i>'
				},
			],
			// controlOrder: ['header', 'radio-group', 'checkbox-group'],
			onAddOption: (optionTemplate, optionIndex) => {
				optionTemplate.label = optionTemplate.label ? optionTemplate.label : `Option ${optionIndex.index + 1}`
				optionTemplate.value = optionTemplate.value ? optionTemplate.value : `option-${optionIndex.index + 1}`
				return optionTemplate;
			},
			onOpenFieldEdit: function (editPanel) {
				$(editPanel).find('.close-field').html('Save');
			},
			templates: {
				makeAndModel: function (fieldData) {
					return {
						field: `<span id="${fieldData.name}"><input
								placeholder="enter your make & model"
								class="form-control"
								name="last-name-preview"
								type="text"
								id="last-name-preview"
							/></span>`
					};
				},
				firstAndLastName: function (fieldData) {
					return {
						field: `<span id="${fieldData.name}"><input
								placeholder="enter name"
								class="form-control"
								name="name-preview"
								type="text"
								id="name-preview"
							/></span>`

					};
				},
				regNo: function (fieldData) {
					return {
						field: `<span id="${fieldData.name}"><input
								placeholder="enter reg no."
								class="form-control"
								name="reg-preview"
								type="text"
								id="reg-preview"
							/></span>`

					};
				},
				state: function (fieldData) {
					return {
						field: `<span id="${fieldData.name}"><select 
							class="form-control" 
							name="state-preview" 
							id="state-preview">
							${states}	 
						</select></span>`
					};
				},
				phone: function (fieldData) {
					return {
						field: `<span id="${fieldData.name}"><input
								placeholder="enter your phone"
								class="form-control"
								name="last-name-preview"
								type="text"
								id="last-phone-preview"
							/></span>`

					};
				},
				email: function (fieldData) {
					return {
						field: `<span id="${fieldData.name}"><input
								placeholder="enter email"
								class="form-control"
								name="last-name-preview"
								type="text"
								id="last-email-preview"
							/></span>`

					};
				},
				pageBreak: function (fieldData) {
					return {
						field: `<div id="${fieldData.name}" class="form-page-break"> <span>Page Break </span> </div>`
					};
				}
			},
			controlOrder: [
				'firstAndLastName',
				'email',
				'phone',
				'state',
				'makeAndModel',
				'regNo',
				'date',
				'text',
				'textarea',
				'select',
				'checkbox-group',
				'radio-group',
				'paragraph',
				'pageBreak'
			],
		};

		const formBuilder = $(fb.current).formBuilder(options2);
		setFormEditor(formBuilder);
	}, []);

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title && props.title === 'Add Form' ? 'Add Form' : props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='btn btn-secondary float-left mr-2'
								onClick={() => handlePublish()}
							>
								{' '}
								<i
									className='fa fa-link custom-icon-preview'
									aria-hidden='true'
									style={{ paddingTop: '0px', marginRight: '4px' }}
								></i>{' '}
								<Translate text={'Embed'} />{' '}
							</button>
							<button
								type='button'
								className='btn btn-default float-left d-flex align-items-center'
								onClick={() => {
									setShowSettings({
										show: true,
										title: 'Form Settings',
										fromPublish: false
									});
								}}
							>
								<i className="ico icon-settings" style={{ marginRight: '2px' }}></i>
								<Translate text={'Settings'} />
							</button>
							<button
								type='button'
								className={`btn btn-primary float-left ml-2 mr-2 email-send-btn `}
								onClick={() => handleSave(true)}
							>
								{loader && !embedClicked && !showSettings?.show ? (
									<span
										className='spinner-border spinner-button mr-1'
										role='status'
										aria-hidden='true'
									></span>
								) : (
									<></>
								)}
								<Translate text={'save'} />
							</button>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid pl-0 pr-0'>
								<div className='dynamicform-view-wrapper'>
									<div className='dynamicform-sub-section-div'>
										{/* Left Panel */}
										<div className='dynamicform-left-panel'>
											<div className='dynamicform-left-container'>
												<div className='card dynamicform-custom-card h-100'>
													<div className='card-header'><Translate text={'Add/Edit Form'} /></div>
													<div className='card-body'>
														<div className='card-group'>
															<div className='form-row w-100'>
																<div className='feedback-form-editor p-2 w-100'>
																	<div id='webform-editor' ref={fb}></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* Right Panel */}
										<RightPreview
											{...props}
											formFields={formFields}
											feedbackForm={feedbackForm}
											handleColorChange={handleColorChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div>
			</Modal>
			<PopUpModal show={publishForm.show}>
				<PublishForm
					{...publishForm}
					dealersettings={props.dealersettings}
					handleClose={() => {
						setPublishForm({ show: false, title: '', feedbackForm: null });
					}}
				/>
			</PopUpModal>
			<PopUpModal show={showSettings.show}>
				<FormSettings
					{...showSettings}
					{...props}
					dealersettings={props.dealersettings}
					handleSelectChange={handleSelectChange}
					feedbackForm={feedbackForm}
					setFeedbackForm={setFeedbackForm}
					handleOnChange={handleOnChange}
					handleSave={handleSave}
					errorFields={errorFields}
					setErrorFields={setErrorFields}
					loader={loader}
					handleClose={() => {
						setShowSettings({ show: false, title: '', fromPublish: false });
					}}
				/>
			</PopUpModal>
		</>
	);
};

export default AddForm;
