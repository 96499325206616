import React from 'react';
import { Modal } from 'react-bootstrap'
import images from "../../../../../images";
import Translate from '../../../../../constants/translate';


const UserExists = (props) => {


    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-custom-alert"
            enforceFocus={false}
        >
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="custom-alert-popup">
                            <div className="icon-placeholder"><img src={images.closecircular} alt="" /></div>
                            <h2><Translate text={`Please Remove ${props.isClient === true ? 'Clients' : 'Users'}`} /></h2>
                            <p className="custom-alert-info"><Translate text={`Already defined targets for the below ${props.isClient === true ? 'clients' : 'users'}`} /></p>
                            {
                                props.users && props.users.length > 0 ? (<div className="custom-alert-userlist w-100">
                                    {
                                        props.users.map((user, index) => {
                                            return <div key={index}> <span> {user}</span> </div>
                                        })
                                    }
                                </div>) : (<></>)
                            }
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="text-center">
                <button type="button" className="btn btn-primary" onClick={() => props.handleClose()} ><Translate text={'done'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default UserExists;