import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap'
import VehicleModels from "./vehicleModels";

const ProductSettings = () => {
    return (<>
        <Tab.Container defaultActiveKey="models" mountOnEnter={true}>
            <div className="settings-submenu">
                <h4>Products &amp; Data</h4>
                <Nav as="ul" bsPrefix="ul-nav">
                    <Nav.Item as="li" bsPrefix="li-nav">
                        <Nav.Link eventKey="models" bsPrefix="li-a-nav">Vehicle Models</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div className="settings-container mb-0 h-100">
                <div className="settings-container-sub" >
                    <Tab.Pane eventKey="models">
                        <VehicleModels />
                    </Tab.Pane>
                </div>
            </div>

        </Tab.Container>

    </>);
}

export default ProductSettings;