/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import { Tab, Col, Nav, Row } from 'react-bootstrap'
/** COMPONENTS */
import ApiLogs from './apis'
//import PPSR from './ppsr'

const BillingSettings = (props) => {
    const [active, setActive] = useState('apilogs')
    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <>
            <Tab.Container defaultActiveKey="apilogs" mountOnEnter={true}
                onSelect={(key) => {
                    setActive(key)
                }}>
                <div className="settings-submenu">
                    <h4>Billing Settings</h4>
                    <Nav as="ul" bsPrefix="ul-nav">
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="apilogs" bsPrefix="li-a-nav">APIs</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="ppsr" bsPrefix="li-a-nav">PPSR</Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                </div>
                <div className="settings-container mb-0 h-100">
                    <div className="settings-container-sub" >
                        <Tab.Pane eventKey="apilogs">
                            <ApiLogs activeKey={active}  {...props} />
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="ppsr">
                         </Tab.Pane> */}
                    </div>
                </div>

            </Tab.Container>
        </>
    )
}

export default BillingSettings;