/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
//import { default as _images } from '../../images';


const VideoInfo = ({ file, dealersettings, customerEmail, customerPhone, customerPhoneCode, enquiryID, contactID, isDeivered }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();
    const { documentID, name, url, type, createdOn, addedBy, isSent, size, modifiedBy, sentDate } = file;


    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const deleteFile = (fileID) => {
        setEditshow(false);
        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
            text: CommonHelper.showLocale({ dealersettings }, 'Do you want to delete this video.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
        }).then((result) => {
            if (result.value) {

                let modifiedBy = dealersettings ? dealersettings.id : '';
                let modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(dealersettings).firestore().collection(`enquiries/${file.enquiryID}/videos`).doc(fileID)
                updateRef.
                    set({
                        isDeleted: true,
                        modifiedBy,
                        modifiedDate
                    }, { merge: true })
                    .then((docRef) => {
                        toast.notify('Video successfully deleted.', {
                            duration: 2000
                        })

                        saveChangeLog('deleted');

                        let logNotes = 'Video deleted';
                        if (file.name)
                            logNotes = 'Video: ' + file.name + ' deleted';

                        let _objLogData = {
                            notes: logNotes,
                            type: 'videos',
                            subType: 'delete',
                            recordId: fileID,
                            enquiryID: file.enquiryID,
                            path: `enquiries/${file.enquiryID}/videos`,
                        }
                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                        CommonHelper.saveAuditLog(_objLogData);

                        //CommonHelper.saveChangeLog(file.clientID, file.enquiryID, file.contactID, logNotes, 'enquiry', 'videos', file.documentID);
                    }).catch((error) => {
                        console.error("Error deleting Video: ", error);
                    });

            }
        })

    }

    const sendVideo = (videoID) => {
        setEditshow(false);
        toast.notify((<div style={{ fontSize: `14px` }}>
            <span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span>
            {'sending...'}
        </div>),
            {
                position: 'top',
                duration: null
            },
        )
        const _sendVideo = window.firebase.functions().httpsCallable('enquiries-sendVideo');
        _sendVideo({
            "enquiryID": enquiryID,
            "email": customerEmail ? customerEmail : null,
            "phone": customerPhone ? customerPhone : null,
            "phoneCode": customerPhoneCode ? customerPhoneCode : null,
            "videoID": videoID,
            "projectId": dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null
        }).then((response) => {
            //console.log('_sendVideo_docRef', response);
            if (response && response.data) {
                saveChangeLog('sent');
                toast.closeAll();
                toast.notify(response.data.message, {
                    duration: 2000
                })
            }

        });
    }

    const showFileIcon = (type) => {
        if (type) {
            switch (type.toLowerCase()) {
                case 'pdf':
                    type = <i className="ico icon-files-pdf"></i>;
                    break;
                case 'docx':
                case 'doc':
                    type = <i className="ico icon-files-word"></i>;
                    break;
                case 'xls':
                case 'xlsx':
                    type = <i className="ico icon-files-excel"></i>;
                    break;
                case 'zip':
                case '7z':
                case 'rar':
                    type = <i className="ico icon-files-zip"></i>;
                    break;
                case 'mp3':
                case 'ogg':
                case 'wav':
                    type = <i className="ico icon-files-audio"></i>;
                    break;
                case 'mp4':
                case 'mpeg':
                case 'mov':
                case 'avi':
                    type = <i className="ico icon-files-video"></i>;
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'webp':
                    type = <i className="ico icon-files-image"></i>;
                    break;
                default:
                    type = <i className="ico icon-files-document"></i>;
            }

            return type;
        }
        else {
            return <i className="ico icon-files-document"></i>
        }
    }

    const isMediaFile = (type) => {
        if (type) {
            switch (type.toLowerCase()) {
                case 'pdf':
                case 'docx':
                case 'doc':
                case 'xls':
                case 'xlsx':
                case 'zip':
                case '7z':
                case 'rar':
                case 'mp3':
                case 'ogg':
                case 'wav':
                    type = false;
                    break;
                case 'mp4':
                case 'avi':
                case 'mpeg':
                case 'mov':
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'webp':
                    type = true;
                    break;
                default:
                    type = false;
            }

            return type;
        }
        else {
            return false;
        }


    }

    const saveChangeLog = (_txt) => {
        let _logNote = `Video: ${file.name ? file.name : ''} ${_txt}.`
        if (file.enquiryID)
            CommonHelper.saveChangeLog(file.clientID, null, file.enquiryID, _logNote, 'videos', 'enquiry');
        else if (file.contactID)
            CommonHelper.saveChangeLog(file.clientID, null, file.contactID, _logNote, 'videos', 'contact');
    }

    return (

        <div className="file-manager-wrap" id={'vid_' + documentID}>

            <div className="flex-file-manager" >

                {
                    isDeivered
                        ?
                        <></>
                        :
                        <>
                            <div className="file-manager-more">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    setEditshow(!editshow)
                                    if (editshow)
                                        document.addEventListener('click', setEditshow(false));
                                }}>
                                    <i className="ico icon-more"></i>
                                </a>
                                <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>

                                    <ul className="more-dropdown">
                                        {
                                            !isSent
                                                ?
                                                <li> <a href="#" onClick={(e) => { e.preventDefault(); sendVideo(documentID) }}> <i className="ico icon-email"></i> <Translate text={'send'} /> </a> </li>
                                                :
                                                <></>
                                        }
                                        <li> <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setEditshow(false);
                                            const url = `/video/details/${documentID}`;
                                            saveChangeLog('viewed');
                                            window.open(url, '_blank');
                                        }}> <i className="ico icon-zoom"></i> <Translate text={'view'} /> </a> </li>
                                        <li> <a href="#" onClick={(e) => { e.preventDefault(); deleteFile(documentID) }}> <i className="ico icon-delete"></i> <Translate text={'delete'} /> </a> </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                }


                <div className="file-manager-icon">
                    {
                        showFileIcon(type)
                    }
                </div>

                <div className="file-manager-data" >
                    <div className="file-manager-text">
                        <div className="file-manager-title">
                            {
                                (isMediaFile(type) === true)
                                    ?
                                    <a data-fancybox={`file_data${documentID}`} href={url} onClick={(e) => { e.preventDefault(); }}>
                                        {name}
                                        {' (' + CommonHelper.showFileSize(size) + ')'}

                                    </a>
                                    :
                                    <a href="#" onClick={(e) => { e.preventDefault(); window.open(url, '_blank'); }}>
                                        {name}
                                        {' (' + CommonHelper.showFileSize(size) + ')'}

                                    </a>
                            }
                        </div>
                        <div className="file-manager-date"><span>{createdOn} </span> - {addedBy}</div>
                    </div>
                </div>
            </div>
            {
                isSent
                    ?
                    <div className="video-wraper">
                        <div className="video-status"> <Translate text={'status'} />:  <span><i className="ico icon-sent"></i> <Translate text={'sent'} /></span></div>
                        <div className="video-details-list"> <span>{sentDate} </span> - {modifiedBy}</div>

                    </div>
                    :
                    <></>

            }


        </div>
    )
};

export default VideoInfo;
