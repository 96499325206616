import React, { useState } from "react";
import moment from "moment";
import _ from 'lodash'
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import StatusUpdate from '../../pipeline/quickview/statusUpdate'
import { StageProvider, StageConsumer } from '../../pipeline/provider'
import QuickView from "../../pipeline/quickview";
import { SidePanel } from "../../../components";

const EnquiryLink = (props) => {
    //const [activity, setActivity] = useState({})    
    //const [checkDataLoad, setDataload] = useState(true)
    //const node = useRef();
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const history = useHistory();
    const { selectedEnquiry, activityLog, dealersettings } = props;

    const liteVersion = dealersettings?.client?.clientSettings?.liteVersion ? true : false;
    const enquiryAccess = dealersettings?.rolePermissions?.modules?.enquiry ? true : false;

    const expandLink = () => {
        if (!Boolean(props.isNewEnquiry) && enquiryAccess) {
            if (props.isFromLayout) {
                const url = `/enquiry/details/${selectedEnquiry.documentID}`;
                Swal.fire({
                    title: CommonHelper.showLocale(props, 'Are you sure?'),
                    text: CommonHelper.showLocale(props, 'You want to view enquiry details'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                    cancelButtonText: CommonHelper.showLocale(props, 'No'),
                }).then(result => {
                    if (result.value) {
                        history.push(url);
                        props.handleClose(null, null, 'close');
                    }
                });
            }
            else if (enquiryAccess) {
                sidepanelOpen(selectedEnquiry.documentID)
            }

        }
    }
    const sidepanelClose = (objData) => {

        //handleStatusUpdateDone(objData);
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }



    return (<>
        <div className='form-readonly-wrap'>
            <div className='input-readonly'>
                {activityLog.owner === localStorage.uid ||
                    (activityLog.teammates &&
                        activityLog.teammates.some(
                            item => item === localStorage.uid,
                        )) ? (
                    <div className='input-readonly-close'>
                        <a
                            href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                props.removeLink('enquiry');
                            }}
                        >
                            <i className='ico icon-remove'></i>{' '}
                        </a>
                    </div>
                ) : (
                    <></>
                )}

                <div
                    className='input-readonly-inner cursor-pointer'
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        expandLink();
                    }}
                >
                    <div className='inner-left'>
                        <i className='ico icon-enquiry'></i>
                    </div>
                    <div className='inner-right'>
                        {selectedEnquiry.displayID
                            ?
                            <div className='input-readonly-head'>{selectedEnquiry.displayID}</div>
                            :
                            <></>}

                        {
                            selectedEnquiry.clientID && (selectedEnquiry.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                (<div className='input-readonly-head blue-color'>{CommonHelper.getOtherDealerName(dealersettings, selectedEnquiry.clientID)}</div>)
                                : (<></>)
                        }
                        {(!_.isEmpty(selectedEnquiry.requirement) && (!_.isEmpty(selectedEnquiry.requirement.make) || !_.isEmpty(selectedEnquiry.requirement.stock))) ? (
                            <>
                                <div className='input-readonly-head'>
                                    {!_.isEmpty(selectedEnquiry.requirement.stock)
                                        ? selectedEnquiry.requirement.stock.make +
                                        ' ' +
                                        selectedEnquiry.requirement.stock.model
                                        : selectedEnquiry.requirement.make +
                                        ' ' +
                                        selectedEnquiry.requirement.model}
                                    {selectedEnquiry.requirement.saleType && (<div className="badge badge-pill badge-white ml-1">
                                        {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.salesType, selectedEnquiry.requirement.saleType, '')}
                                    </div>)}
                                </div>
                            </>
                        ) : <>
                            {
                                liteVersion && !_.isEmpty(selectedEnquiry.td) && !_.isEmpty(selectedEnquiry.td.make)
                                    ?
                                    <>
                                        <div className="input-readonly-head">
                                            <i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'mr-1 ico icon-activity-testdrive')}></i>
                                            {selectedEnquiry.td.make + ' ' + selectedEnquiry.td.model}
                                            {
                                                selectedEnquiry.td.saletype && (<div className="badge badge-pill badge-white ml-1">
                                                    {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.salesType, selectedEnquiry.td.saletype, '')}
                                                </div>)
                                            }
                                        </div>

                                    </>
                                    :
                                    <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(props.dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                            }

                        </>}
                        {selectedEnquiry.origin && (
                            <div className='input-readonly-sub'>
                                {CommonHelper.getLabelByValue(props.dealersettings?.client?.settings?.origins, selectedEnquiry.origin, '')}
                            </div>
                        )}
                        {selectedEnquiry.status && _.isString(selectedEnquiry.status) ? <>
                            {(() => {
                                const enquiryAllStatus = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.enquiryStatus : [])
                                let _objCurrentStatus = enquiryAllStatus.filter(e => e.value === selectedEnquiry.status)[0]

                                return <div className='input-readonly-sub'>
                                    {
                                        _objCurrentStatus
                                            ?
                                            <>
                                                <div
                                                    className={`badge badge-pill badge-${selectedEnquiry.status === 'open' ? 'open' : 'empty'}`}
                                                    style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                >
                                                    {_objCurrentStatus.name}
                                                </div>
                                            </>
                                            :
                                            <>


                                            </>
                                    }
                                </div>
                            })()}

                        </> : selectedEnquiry.statusValue && _.isString(selectedEnquiry.statusValue) ? (<div className='input-readonly-sub'>
                            <div
                                className={`badge badge-pill badge-${selectedEnquiry.statusValue.toLowerCase()}`}
                            >
                                {selectedEnquiry.statusValue.split(/(?=[A-Z])/).join(' ')}
                            </div>
                        </div>) : (
                            <></>
                        )}


                    </div>
                </div>

                {
                    liteVersion || !enquiryAccess ? <></> :
                        <>
                            <StatusUpdate
                                enquiryid={selectedEnquiry.documentID}
                                dealersettings={props.dealersettings}
                                history={history}
                                handleStatusUpdateDone={props.handleStatusUpdateDone}
                                clientUsers={props.clientUsers}
                                groupUsers={props.groupUsers}
                            />
                        </>
                }

            </div>

            {
                showpanel.enquiryid
                    ?
                    <>
                        <StageProvider
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                            <StageConsumer>
                                {() => (

                                    <SidePanel
                                        clsActive={showpanel.clsActive}
                                        clsName={showpanel.clsName}
                                        sidepanelClose={sidepanelClose}
                                        title=''
                                    >
                                        <QuickView {...props}
                                            enquiryid={showpanel.enquiryid}
                                            isHideExpandView={false}
                                            isFilterApplied={true}
                                            updateListEnquiry={props.handleStatusUpdateDone}
                                            sidepanelClose={sidepanelClose}
                                        />
                                    </SidePanel>
                                )}
                            </StageConsumer>
                        </StageProvider>
                    </>
                    :
                    <></>
            }

        </div>
    </>)
}

export default EnquiryLink;