import React, { useState, useEffect } from "react";
import { isBoolean, isEmpty } from "lodash";
import { Modal } from "react-bootstrap";
import { InputCheckBox } from "../../../../components";
import Translate from "../../../../constants/translate";
import images from "../../../../images";
import _ from "lodash";
import uuid from "react-uuid";
import { Slider } from 'antd';
import NumberFormat from "react-number-format";
import { autograbDynCols } from "../addOns/viewModel";
import CustomTable from "../../../../components/customTable";

const Options = ({
  title,
  show,
  handleClose,
  dealersettings,
  enableAddons,
  moduleToChange,
  selectedLevel,
  isEdit,
  currentSettings,
  currentClient
}) => {
  const [addSubList, setAddSubList] = useState({
    saleType: "",
    make: "",
    id: "",
    value: "",
  });
  const [editSubList, setEditSubList] = useState();
  const [textEditor, setTexteditor] = useState({});
  const [state, setState] = useState({});
  const [data, setData] = useState({});
  const [makes, setMakes] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [modules, setModules] = useState({});
  const [phoneCodes, setphoneCodes] = useState([]);
  const [podiumLocations, setPodiumLocations] = useState([]);
  const [addleadSourceList, setAddleadSourceList] = useState({
    saleType: "",
    make: "",
    id: "",
    value: "",
  });
  const [editleadSourceList, setEditleadSourceList] = useState();

  const [addcampaignList, setAddcampaignList] = useState({
    saleType: "",
    make: "",
    id: "",
    value: "",
  });
  const [editcampaignList, setEditcampaignList] = useState();
  const [apiPrice, setApiPrice] = useState({})
  const [marks, setMarks] = useState({})
  const [markPrice, setMarkPrice] = useState({})
  const [loader, setLoader] = useState(true)
  const [currencyCode, setCurrencyCode] = useState()

  const currencySymbol =
    !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.currency)
      ? dealersettings.client.currency.symbol + " "
      : "$ ";


  useEffect(() => {
    let _phoneCodes = [];
    dealersettings.countries &&
      dealersettings.countries.forEach((doc) => {
        if (doc.phone_code) {
          _phoneCodes.push({
            country_code: doc.code,
            value: doc.phone_code,
            active: _.isBoolean(doc.active) ? doc.active : true,
            label:
              doc.name + " (" + (doc.phone_code ? doc.phone_code : "--") + ")",
          });
          setphoneCodes(_phoneCodes);
        }
      });
  }, []);

  useEffect(() => {
    if (!state.refresh_token || moduleToChange.module !== "podium") {
      return;
    }

    const getLocations = async () => {
      const getPodiumLocations = window.firebase
        .functions()
        .httpsCallable("podium-getLocations");
      const data = await getPodiumLocations({
        refreshToken: state.refresh_token,
      });

      console.log("getPodiumLocations", data);
      if (data.data.success === true && data.data.locations) {
        setPodiumLocations(
          data.data.locations.map((l) => {
            return {
              ...l,
              value: l.uid,
              label: l.name,
            };
          })
        );
      } else {
        console.log("getLocations failed", data);
      }
    };

    getLocations();
  }, [moduleToChange.module, state]);

  useEffect(() => {
    // first check if its a edit modal
    // if its edit modal load integrations
    // if its titan then just remove titan settin
    if (!isEdit) return;

    if (
      !(
        !_.isEmpty(selectedLevel) &&
        (selectedLevel.level === "oem" || !_.isEmpty(selectedLevel.levelID))
      )
    ) {
      return;
    }

    let integrationRef;
    let modulePriceRef;
    if (selectedLevel.level === "oem") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID);
      modulePriceRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection(`modulePricing`)
        .doc(dealersettings.client.settingsID);
    } else if (selectedLevel.level === "region") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID);
      modulePriceRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("regions")
        .doc(selectedLevel.levelID)
        .collection(`modulePricing`)
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "group") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID);
      modulePriceRef = window.firebase
        .firestore()
        .collection(`clientSettings`)
        .doc(dealersettings.client.settingsID)
        .collection("groups")
        .doc(selectedLevel.levelID)
        .collection(`modulePricing`)
        .doc(selectedLevel.levelID);
    } else if (selectedLevel.level === "individual") {
      integrationRef = window.firebase
        .firestore()
        .collection(`clients`)
        .doc(selectedLevel.levelID);
      modulePriceRef = window.firebase
        .firestore()
        .collection("clients")
        .doc(selectedLevel.levelID)
        .collection(`modulePricing`)
        .doc(selectedLevel.levelID);
    }

    integrationRef.get().then(async (querySnapshot) => {
      const apiPrice = await modulePriceRef.get()
      if (apiPrice?.data()?.[moduleToChange.module] && moduleToChange.module !== 'mailjet') {
        setApiPrice(apiPrice.data()[moduleToChange.module])
      }
      else {
        if (currentClient?.country === "AU" && _.find(dealersettings.integrations, { type: moduleToChange.module })?.priceAUD) {
          setCurrencyCode("AUD");
          setApiPrice(_.find(dealersettings.integrations, { type: moduleToChange.module })?.priceAUD)
        }
        else {
          setCurrencyCode("USD");
          setApiPrice(_.find(dealersettings.integrations, { type: moduleToChange.module })?.price)
        }
      }
      if (querySnapshot.exists) {
        let module_detail = querySnapshot.data().moduleSettings;
        setModules(module_detail ? module_detail : {});
        if (module_detail[moduleToChange.module]) {
          if (_.isEmpty(module_detail[moduleToChange.module])) {
            if (moduleToChange.module === 'mailjet') {
              setState({ mark: 0 });
            } else {
              setState({});
            }

            setLoader(false)
            return;
          }
          let _state = module_detail[moduleToChange.module];
          if (moduleToChange.module === 'mailjet' && _.isEmpty(_state?.mark?.toString())) {
            _state = {
              ..._state,
              mark: 0
            }
          }
          setState(_state);
          setLoader(false)
        }
      } else {
        if (moduleToChange.module === 'mailjet') {
          setState({ mark: 0 });
        }
        setModules({});
        setLoader(false)
      }
    });

    // return () => {
    //   snapModuleSettings && snapModuleSettings();
    // };
  }, [moduleToChange.module]);

  useEffect(() => {
    if (moduleToChange.module !== 'mailjet' || loader)
      return

    let _marks = {};
    let _markPrice = {};
    let i = 0;
    const step = 100 / (Object.keys(apiPrice).length - 1);
    const renge = _.range(0, 100 + step, step);
    Object.keys(apiPrice).forEach(r => {
      _marks = {
        ..._marks,
        [renge[i]]: <NumberFormat
          thousandSeparator={true}
          displayType={'text'}
          value={r}
        />
      }
      _markPrice = {
        ..._markPrice,
        [renge[i]]: {
          value: r,
          price: apiPrice[r],
          name: <NumberFormat
            thousandSeparator={true}
            displayType={'text'}
            value={r}
          />
        }
      }
      i++;
    })
    setMarks(_marks)
    setMarkPrice(_markPrice)
  }, [loader])

  useEffect(() => {
    if (isEmpty(dealersettings)) return;
    if (isEmpty(dealersettings.client)) return;
    if (isEmpty(dealersettings.client.brands)) return;

    const brands = dealersettings.client.brands;
    const makes = [];
    makes.push({
      value: "ALL",
      label: "ALL",
    });
    _.orderBy(brands, ["name"], ["asc"]).forEach((doc) => {
      makes.push({
        value: doc.value,
        label: doc.name,
        // models: doc.models,
        // active: _.isBoolean(doc.active) ? doc.active : true,
      });
    });

    if (makes.length == 1) {
      makes = [];
    }

    setMakes(makes);
  }, [dealersettings.client.brands]);

  useEffect(() => {
    if (isEmpty(currentSettings)) return;

    let _settings = {};

    if (!isEmpty(currentSettings.settings)) {
      _settings = { ...currentSettings.settings };
    } else if (!isEmpty(currentSettings) && isEmpty(currentSettings.settings)) {
      _settings = { ...currentSettings };
    }

    let _campaigns = [];
    let _origins = [];
    let _enquiryTypes = [];
    let _leadSource = [];
    let _saleTypeOptionsForSalesLogs = [];

    !isEmpty(_settings.campaigns) &&
      _settings.campaigns.forEach((campaign) => {
        _campaigns.push({
          label: campaign.name,
          value: campaign.value,
          data: campaign,
          active: isBoolean(campaign.active) ? campaign.active : true,
        });
      });

    !isEmpty(_settings.origins) &&
      _settings.origins.forEach((origin) => {
        _origins.push({
          label: origin.name,
          value: origin.value,
          data: origin,
          active: isBoolean(origin.active) ? origin.active : true,
        });
      });

    !isEmpty(_settings.enquiryTypes) &&
      _settings.enquiryTypes.forEach((enquiryType) => {
        _enquiryTypes.push({
          label: enquiryType.name,
          value: enquiryType.value,
          data: enquiryType,
          active: isBoolean(enquiryType.active) ? enquiryType.active : true,
        });
      });

    !isEmpty(_settings.salesType) &&
      _settings.salesType.forEach((saleType) => {
        _saleTypeOptionsForSalesLogs.push({
          label: saleType.name,
          value: saleType.value ? saleType.value.toLowerCase() : saleType.value,
          data: saleType,
          active: isBoolean(saleType.active) ? saleType.active : true,
        });
      });

    !isEmpty(_settings.leadSource) &&
      _settings.leadSource.forEach((leadSource) => {
        _leadSource.push({
          label: leadSource.name,
          value: leadSource.value,
          data: leadSource,
          active: isBoolean(leadSource.active) ? leadSource.active : true,
        });
      });

    _saleTypeOptionsForSalesLogs.unshift({
      value: "ALL",
      label: "ALL",
      active: true,
    });
    // console.log("currentSettings", {
    //   _origins,
    //   _enquiryTypes,
    //   _saleTypeOptionsForSalesLogs,
    // });

    setData({
      campaigns: _campaigns,
      origins: _origins,
      enquiryTypes: _enquiryTypes,
      leadSources: _leadSource,
      saleTypeOptionsForSalesLogs: _saleTypeOptionsForSalesLogs,
    });

    // const clientSnapshot = window.firebase.firestore().collection(`clients`)
    //     .where('documentID', 'in', dealersettings.clients.map(v => _.pick(v, ['id']).id))
    //     .onSnapshot(querySnapshot => {
    //         let _clients = [];
    //         querySnapshot.docs.forEach(rec => {
    //             _clients.push({
    //                 label: rec.data().name,
    //                 value: rec.id,
    //                 data: rec.data(),
    //                 active: _.isBoolean(rec.active) ? rec.active : true,
    //             })
    //         })
    //     })
  }, [currentSettings]);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      setErrorFields({
        ...errorFields,
        [e.target.name]: "",
      });
    }
  };

  const handleSliderChange = (value) => {
    setState({
      ...state,
      mark: value
    })
  }

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    console.log(name, checked);
    if (
      (name === "marketOverlay" ||
        name === "priceValuation" ||
        name === "priceHistory") &&
      checked
    ) {
      setState({ ...state, [name]: checked, vehicleDetail: true });
    } else if (name === "fetchRegStatus" && checked) {
      setState({
        ...state,
        [name]: checked,
        vehicleDetail: true,
        registrationCheck: true,
      });
    } else if (name === "registrationCheck" && !checked) {
      setState({
        ...state,
        [name]: checked,
        fetchRegStatus: false
      });
    } else if (name === "vehicleDetail" && !checked) {
      setState({
        ...state,
        [name]: checked,
        fetchRegStatus: false,
        marketOverlay: false,
        priceValuation: false,
        registrationCheck: false
      });
    } else {
      setState({ ...state, [name]: checked });
    }
  };

  return (
    <Modal
      id="add-cafe"
      show={show}
      onHide={handleClose}
      backdrop="static"
      backdropClassName={show ? "overlay-modal active" : ""}
      enforceFocus={false}
      dialogClassName="modal-dialog modal-dialog-centered modal-configure-integration"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title">
            <Translate text={`${title}`} />
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="form-style w-100">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <div className="configure-integration-logo">
                    {moduleToChange.module === "autograb" ? (
                      <img src={images.rego_search} alt="" />
                    ) : moduleToChange.module === "ppsr" ? (
                      <img src={images.ppsr} alt="" />
                    ) : moduleToChange.module === "mailjet" ? (
                      <img src={images.emailCampaign} alt="" />
                    ) : (
                      <></>
                    )}
                  </div>
                  {moduleToChange.module === "autograb" && (
                    <p className="configure-integration-text">
                      Experience seamless access to comprehensive and real-time
                      vehicle details, ensuring you get the information you
                      need, exactly when you need it.
                    </p>
                  )}
                </div>
              </div>
              <div className="form-row ">
                {moduleToChange.module === "autograb" ? (
                  <>
                    <div className="autograb-subscription-box-wrapper">
                      <div className="autograb-subscription-box-fullwidth">
                        <h4>Your Subscription</h4>

                        <p>
                          Explore our flexible Vehicle Search Subscription
                          options, allowing you to activate modules as needed.
                          Choose from a range of comprehensive features tailored
                          to your requirements. Monthly charges are based on the
                          activated vehicle search modules, giving you complete
                          control over your subscription and budget. See below
                          for pricing details.
                        </p>
                      </div>
                    </div>

                    <div className="autograb-subscription-user-tablehead mt-2">
                      Pricing
                    </div>

                    <div className="autograb-subscription-pricingwrap">
                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <div className="autograb-subscription-priceloop">
                            <div className="autograb-subscription-price-value">
                              <div>
                                <span className="switch switch-sm float-left">
                                  <InputCheckBox
                                    className={`switch`}
                                    id={`switch-vehicleDetail`}
                                    name={"vehicleDetail"}
                                    checked={Boolean(state.vehicleDetail)}
                                    onChange={(e) => {
                                      handleCheckChange(e);
                                    }}
                                  />
                                  <label htmlFor="switch-vehicleDetail"></label>
                                </span>
                              </div>

                              <p className="px-2">Vehicle Detail</p>
                            </div>
                            <div className="req-sub-text">
                              <h2 className="autograb-subscription-price-title">
                                {currencySymbol} {apiPrice['vehicleDetail']}
                              </h2>
                              <span className="req-sub-label"> Per Request</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mb-2">
                          <div className="autograb-subscription-priceloop">
                            <div className="autograb-subscription-price-value">
                              <div>
                                <span className="switch switch-sm float-left">
                                  <InputCheckBox
                                    className={`switch`}
                                    id={`switch-priceValuation`}
                                    name={"priceValuation"}
                                    checked={Boolean(state.priceValuation)}
                                    onChange={(e) => {
                                      handleCheckChange(e);
                                    }}
                                  />
                                  <label htmlFor="switch-priceValuation"></label>
                                </span>
                              </div>

                              <p className="px-2">Price Valuation</p>
                            </div>
                            <div className="req-sub-text">
                              <h2 className="autograb-subscription-price-title">
                                {currencySymbol} {apiPrice['priceValuation']}
                              </h2>
                              <span className="req-sub-label"> Per Request</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mb-2">
                          <div className="autograb-subscription-priceloop">
                            <div className="autograb-subscription-price-value">
                              <div>
                                <span className="switch switch-sm float-left">
                                  <InputCheckBox
                                    className={`switch`}
                                    id={`switch-marketOverlay`}
                                    name={"marketOverlay"}
                                    checked={Boolean(state.marketOverlay)}
                                    onChange={(e) => {
                                      handleCheckChange(e);
                                    }}
                                  />
                                  <label htmlFor="switch-marketOverlay"></label>
                                </span>
                              </div>

                              <p className="px-2">Market Overlay</p>
                            </div>
                            <div className="req-sub-text">
                              <h2 className="autograb-subscription-price-title">
                                {currencySymbol} {apiPrice['marketOverlay']}
                              </h2>
                              <span className="req-sub-label"> Per Request</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div className="autograb-subscription-priceloop">
                            <div className="autograb-subscription-price-value">
                              <div>
                                <span className="switch switch-sm float-left">
                                  <InputCheckBox
                                    className={`switch`}
                                    id={`switch-registrationCheck`}
                                    name={"registrationCheck"}
                                    checked={Boolean(state.registrationCheck)}
                                    onChange={(e) => {
                                      handleCheckChange(e);
                                    }}
                                  />
                                  <label htmlFor="switch-registrationCheck"></label>
                                </span>
                              </div>

                              <p className="px-2">Registration Check</p>
                            </div>
                            <div className="req-sub-text">
                              <h2 className="autograb-subscription-price-title">
                                {currencySymbol} {apiPrice['registrationCheck']}
                              </h2>
                              <span className="req-sub-label"> Per Request</span>
                            </div>

                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div className="autograb-subscription-priceloop">
                            <div className="autograb-subscription-price-value">
                              <div>
                                <span className="switch switch-sm float-left">
                                  <InputCheckBox
                                    className={`switch`}
                                    id={`switch-fetchRegStatus`}
                                    name={"fetchRegStatus"}
                                    checked={Boolean(state.fetchRegStatus)}
                                    onChange={(e) => {
                                      handleCheckChange(e);
                                    }}
                                  />
                                  <label htmlFor="switch-fetchRegStatus"></label>
                                </span>
                              </div>

                              <p className="px-2">  Include Reg Status check in vehicle search</p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="autograb-subscription-user-tablehead mt-2">
                      Additional Factors
                    </div>
                    <CustomTable
                      dealersettings={dealersettings}
                      cols={autograbDynCols}
                      subList={state.subList}
                      setSubList={(list) => { setState({ ...state, subList: list }) }}
                    ></CustomTable>
                  </>
                ) : moduleToChange.module === "ppsr" ? (
                  <>
                    <div className="subscription-box-wrapper">
                      <div className="subscription-box-left">
                        <h4> Your Subscription</h4>
                        <p>PPSR search will show you if the goods you want to buy are likely to be free from debt or other things that could put your money at risk. </p>
                      </div>
                      <div className="subscription-box-right">
                        <div className="subscription-price-wrapper">
                          <h3>{currencySymbol} {apiPrice['ppsr']}</h3>
                          <h5>Per Request</h5>
                        </div>
                      </div>
                    </div>
                  </>
                ) : moduleToChange.module === "mailjet" ? (
                  <>
                    {
                      Boolean(dealersettings.superAdmin) ? (
                        <>
                          <div className="form-group col-md-6">
                            <label>
                              <Translate text={"API Key"} />{" "}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errorFields["apiKey"]}`}
                              value={state.apiKey ? state.apiKey : ""}
                              placeholder={"api key"}
                              onChange={handleChange}
                              name="apiKey"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              <Translate text={"Secret Key"} />{" "}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errorFields["secretKey"]}`}
                              value={state.secretKey ? state.secretKey : ""}
                              placeholder={"secret key"}
                              onChange={handleChange}
                              name="secretKey"
                            />
                          </div>
                        </>
                      ) : (<></>)
                    }
                    <div className="mailjet-subscription-box-wrapper">
                      <div className="mailjet-subscription-box-title">Pricing</div>
                      <div className="mailjet-subscription-box-subtitle">How many emails do you want to send per month?</div>
                      <div className="mailjet-subscription-box-range">
                        {
                          !loader ? (
                            <div className="mailjet-rangeslider-custom">
                              <Slider marks={marks} step={null} defaultValue={state.mark} tooltip={{ formatter: null }} onAfterChange={handleSliderChange}
                                styles={{
                                  rail: {
                                    background: '#a8a8a8',
                                  },
                                  handle: {
                                    background: '#a8a8a8',
                                  },
                                  track: {
                                    background: '#4466f2',
                                  }
                                }} />
                            </div>
                          ) : (<></>)
                        }

                      </div>
                      {
                        markPrice ? (
                          <div className="mailjet-subscription-box-pricing">
                            <div className="mailjet-price">{currencyCode} $ {markPrice?.[state.mark]?.price}/month</div>
                            <div className="mailjet-price-info">Billed monthly</div>
                            <div className="mailjet-price-subinfo">{markPrice?.[state.mark]?.name} emails/month</div>
                          </div>
                        ) : (<></>)
                      }

                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {
        moduleToChange.module === "ppsr" ? (
          <></>
        ) : (
          <Modal.Footer className="modal-footer">
            <button
              type="button"
              className="btn btn-primary float-right ml-2 "
              aria-label="Save"
              disabled={
                moduleToChange.module === "driva" && !state.isTerms ? true : false
              }
              onClick={() => {
                if (moduleToChange.module === "autograb") {
                  setErrorFields({});
                  handleClose();
                  enableAddons(moduleToChange.module, true, state);
                } else if (moduleToChange.module === "mailjet") {
                  let errors = {};
                  if (!state.apiKey) {
                    errors.apiKey = "input_error";
                  }
                  if (!state.secretKey) {
                    errors.secretKey = "input_error";
                  }
                  setErrorFields(errors);
                  if (_.isEmpty(errors)) {
                    state.limit = markPrice?.[state.mark]?.value ? Number(markPrice?.[state.mark]?.value) : 5000;
                    state.price = markPrice?.[state.mark]?.price ? Number(markPrice?.[state.mark]?.price) : 10;
                    state.currency = currencyCode;
                    handleClose();
                    enableAddons(moduleToChange.module, true, state);
                  }
                } else {
                  if (isEmpty(errorFields)) {
                    handleClose();
                    enableAddons(
                      moduleToChange.module,
                      moduleToChange.checked,
                      state
                    );
                  }
                }
              }}
            >
              {" "}
              <Translate text={"Save"} />
            </button>

            <button
              type="button"
              className="btn btn-default float-left"
              onClick={() => handleClose(null)}
            >
              <Translate text={"close"} />
            </button>
          </Modal.Footer>
        )
      }

    </Modal>
  );
};

export default Options;
