import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'

import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { TableView, PopUpModal } from '../../components';
import { alleditrecipientFields } from './viewModel';
import AddRecipient from './addRecipient';

const ViewRecipients = ({ title, show, handleClose, recipients, setRecipients, apiUrl, API_KEY, SERVER_PREFIX, dealersettings }) => {
  const [selectedIds, setSelectedIds] = useState({})
  const [windowSize, setWindowSize] = useState({
    windowWidth: 750,
    windowHeight: window.innerHeight - 293,
  });
  const [showAddRecipient, setShowAddRecipient] = useState({
    show: false,
    title: '',
  });

  const handleShowAddRecipientOpen = (type, data) => {
    setShowAddRecipient({
      show: true,
      title: type === 'add' ? 'Add Recipient' : 'Edit Recipient',
      data: type === 'add' ? null : data,
    })
  }

  const handleShowAddRecipientClose = (recipient, action) => {
    // console.log('adding or editing a recipient', recipient)
    setShowAddRecipient({
      show: false,
      title: ''
    })

    if (!recipient) {
      return
    }

    if (action === 'add') {
      const recipientObj = {
        email: recipient.email ? recipient.email : '',
        firstName: recipient.firstName ? recipient.firstName : '',
        middleName: recipient?.middleName || '',
        lastName: recipient.lastName ? recipient.lastName : '',
        contactID: recipient.contactID ? recipient.contactID : null,
        documentID: recipient.documentID ? recipient.documentID : window.firebase.firestore().collection('campaignRecipients').doc().id,
      }
      if (recipient.amsproDocID) {
        recipientObj.amsproDocID = recipient.amsproDocID
      }
      setRecipients(_.uniqBy([...recipients, ...[recipientObj]], 'email'));
    } else if (action === 'update') {
      const allOther = recipients.filter(item => item.documentID !== recipient.documentID)
      let oneToBeUpdated = recipients.filter(item => item.documentID === recipient.documentID)
      if (oneToBeUpdated.length === 0) {
        return
      }
      oneToBeUpdated = oneToBeUpdated[0]
      oneToBeUpdated.email = recipient.email ? recipient.email : ''
      oneToBeUpdated.firstName = recipient.firstName ? recipient.firstName : ''
      oneToBeUpdated.middleName = recipient.middleName ? recipient.middleName : ''
      oneToBeUpdated.lastName = recipient.lastName ? recipient.lastName : ''
      oneToBeUpdated.documentID = recipient.documentID ? recipient.documentID : ''
      oneToBeUpdated.contactID = recipient.contactID ? recipient.contactID : ''

      setRecipients(_.uniqBy([oneToBeUpdated, ...allOther], 'email'));
    }
  }

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: 750,
        windowHeight: window.innerHeight - 293,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubCampaigns && window.unSubCampaigns();
    };
  }, []);

  const handleActionClick = id => {
    const selectedRecipient = recipients.filter(recipient => recipient.documentID === id)[0]
    return (
      <>
        <div
          style={{ cursor: 'pointer' }}
          className='mini-button mr-2'
          onClick={() => handleShowAddRecipientOpen('edit', selectedRecipient)}
        >
          <i className='ico icon-edit'></i>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          className='mini-button'
          onClick={e => {
            Swal.fire({
              title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
              text: CommonHelper.showLocale({ dealersettings }, 'Do you want to remove this recipient'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
              cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
            }).then((result) => {
              if (result.value) {
                let filteredRecipients = recipients.filter(contact => contact.documentID !== id)
                setRecipients(_.uniqBy([...filteredRecipients], 'email'));
                toast.notify('Removed recipient successfully', {
                  duration: 2000
                })
              }
            })
          }}
        >
          <i className='ico icon-delete'></i>
        </div>

      </>
    );
  };
  const handleRowSingleClick = () => {

  }

  const handleAllChecked = (event) => {
    let _selectedIds = {};
    if (event.target.checked) {
      !_.isEmpty(recipients) && recipients.forEach(file => {
        let record = Object.assign({}, recipients.filter(e => e.documentID === file.documentID)[0].objDoc);
        _selectedIds[file.documentID] = record;
      })
    }
    setSelectedIds(_selectedIds);
  }

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;

    let _selectedIds = Object.assign({}, selectedIds);
    if (checked) {
      let record = Object.assign({}, recipients.filter(e => e.documentID === name)[0]);
      if (!_.isEmpty(record))
        _selectedIds[name] = record.objDoc;
    }
    else {
      delete _selectedIds[name];
    }
    setSelectedIds(_selectedIds);
  }

  return (
    <Modal
      id='email-sync-model'
      show={show}
      onHide={handleClose}
      backdrop='static'
      backdropClassName={show ? 'overlay-modal active' : ''}
      enforceFocus={false}
      dialogClassName='modal-dialog modal-dialog-centered modal-update-password modal-edit-recipients'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className='modal-title'>
            <Translate text={title} />
          </h5>
        </Modal.Title>
        <button type="button" className="btn btn-primary float-right ml-2 ml-auto" aria-label="Close" onClick={() => handleShowAddRecipientOpen('add')}> Add Recipient</button>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="settings-head">

            </div>
            <div className="common-table">
              <TableView
                isReorderable={false}
                datalist={recipients}
                height={windowSize.windowHeight}
                width={windowSize.windowWidth}
                columns={alleditrecipientFields}
                dynamicFields={alleditrecipientFields
                  .filter(e => e.default === true)
                  .map(v => _.pick(v, ['value']).value)}
                settingsLoader={false}
                handleActionClick={handleActionClick}
                handleRowSingleClick={handleRowSingleClick}
                isSettings={false}
                hasMore={false}
                isPaging={false}
                selectedIds={selectedIds}
                handleAllChecked={handleAllChecked}
                handleCheckChange={handleCheckChange}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='modal-footer'>
        <button
          type='button'
          className='btn btn-default float-right ml-2 '
          aria-label='Close'
          // disabled={}
          onClick={handleClose}
        >
          {' '}
          <Translate text={'Close'} />
        </button>
        <button
          type='button'
          className={`btn btn-primary float-right ml-2 ${!_.isEmpty(selectedIds) ? '' : 'disabled'}`}
          aria-label='Close'
          // disabled={}
          onClick={() => {
            if (!_.isEmpty(selectedIds)) {
              Swal.fire({
                title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
                text: CommonHelper.showLocale({ dealersettings }, 'Do you want to remove selected recipients?'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
                cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
              }).then((result) => {
                if (result.value) {
                  let selectedIdsArr = Object.keys(selectedIds)
                  let filteredRecipients = recipients.filter(contact => !selectedIdsArr.includes(contact.documentID))
                  setRecipients(_.uniqBy([...filteredRecipients], 'email'));
                  setSelectedIds({})
                  toast.notify('Removed recipients successfully', {
                    duration: 2000
                  })
                }
              })
            }
          }
          }
        >
          {' '}
          <Translate text={'Remove'} />
        </button>

      </Modal.Footer>
      <PopUpModal show={showAddRecipient.show}>
        <AddRecipient
          show={showAddRecipient.show}
          handleClose={handleShowAddRecipientClose}
          title={showAddRecipient.title}
          data={showAddRecipient.data}
          dealersettings={dealersettings}
        />
      </PopUpModal>
    </Modal>
  );
};

export default ViewRecipients;
