import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { InputText, InputMultipleText, ReactMultiSelect, ReactSelect, ReactTimePicker, InputCheckBox } from '../../components';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import ReactSummernote from 'react-summernote';
import { frequencyOptions, dataOptions } from "./viewModel";
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'

const ScheduleReport = (props) => {
    const [report, setReport] = useState(props.report);
    const [errorFields, setErrorFields] = useState({});
    const [initialized, setInitialized] = useState(false);
    const [emailloader, setEmailLoader] = useState(false)
    const [emailHtml, setEmailHtml] = useState('')

    useEffect(() => {
        if (_.isEmpty(report))
            return
        if (!initialized) {
            // console.log('emailType', emailType)
            var node = document.createElement('div');
            node.innerHTML = `${emailHtml}`;
            ReactSummernote.insertNode(node);
            setInitialized(true);
        }

    }, [report]);



    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setReport({
                ...report,
                [name]: newarray1.join(' ')

            })
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setReport({
                ...report,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'team')
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['team_emails']: e.user_emails
            })
        else
            setReport({
                ...report,
                [data.name]: e ? e.value : ''
            })


        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleOnChangeMultipleText = (val, name) => {
        setReport({
            ...report,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setReport({
            ...report,
            [name]: value
        });
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const removeEmail = (index, name) => {
        let arr = report[name] ? _.isArray(report[name]) ? report[name] : report[name].split(',') : []
        arr.splice(index, 1)
        setReport({
            ...report,
            [name]: arr.join(',')
        })
    }

    const handleChange = obj => {
        //let htmlText = obj
        setEmailHtml(obj)
    };


    const handleSendEmail = () => {
        let rec = report;
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['subject'])) {
            formIsValid = false;
            errors['subject'] = errorClass;
        }
        if (_.isEmpty(rec['team_emails']) && _.isEmpty(rec['recipients']) && _.isEmpty(rec['otherRecipients'])) {
            formIsValid = false;
            Swal.fire(CommonHelper.showLocale(props, 'Please add recipients'), '', 'error');
        }

        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        let reportURL = report.url;
        let _recipients = [];
        if (!_.isEmpty(report.team_emails)) {
            _recipients = [..._recipients, ...report.team_emails]
        }
        if (!_.isEmpty(report.recipients)) {
            _recipients = [..._recipients, ...report.recipients]
        }
        if (!_.isEmpty(report.otherRecipients)) {
            _recipients = [..._recipients, ...report.otherRecipients.split(',')]
        }
        setEmailLoader(true)
        try {
            let paramObj = {
                clientID: props.dealersettings.client.id,
                url: reportURL,
                subject: report.subject,
                recipients: _recipients,
                emailBody: emailHtml
            }
            const sendEmail = window.firebase.functions().httpsCallable('reports-sendEmailNow');
            sendEmail(paramObj).then((response) => {
                setEmailLoader(false)
                if (response.data.success) {
                    toast.notify('Email sent successfully.', {
                        duration: 2000,
                    });
                    props.handleClose()
                }
                else {
                    console.log(response.data.message);
                    Swal.fire('Something went wrong', '', 'error')
                }
            })

        }
        catch (error) {
            console.log(error);
            Swal.fire('Something went wrong', '', 'error')
        }
    }


    return _.isEmpty(report) ? (<></>) : (

        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-company"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Email Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" pop-layout form-style">
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <label><Translate text={'Email Subject'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'subject'}
                                        className={`form-control ${errorFields["subject"]}`}
                                        name="subject"
                                        onChange={handleOnChange}
                                        value={report.subject}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label><Translate text={'Team'} /></label>
                                    <ReactSelect
                                        options={props.teams}
                                        name={"team"}
                                        placeholder={`select team`}
                                        onChange={handleSelectChange}
                                        value={report.team}
                                        classNamePrefix={`${errorFields["team"]} basic-select`}
                                        isSearchable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label><Translate text={'users'} /></label>
                                    <ReactMultiSelect
                                        options={(props.oemReport === true ? _.uniqBy(props.groupUsers, 'id') : props.clientUsers).filter(e => Boolean(e.superAdmin) === false && !_.isEmpty(e.email))
                                            .map(rec => {
                                                return {
                                                    value: rec.email,
                                                    searchlabel: rec.name,
                                                    active: _.isBoolean(rec.active) ? rec.active : true,
                                                    label: (
                                                        <div className='drop-image'>
                                                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                            {rec.name}
                                                        </div>
                                                    ),
                                                };
                                            })}
                                        name={"recipients"}
                                        placeholder={`select recipients`}
                                        onChange={(option) => handleReactMultiSelectChange(option, 'recipients')}
                                        value={report.recipients}
                                        classNamePrefix={`${errorFields["recipients"]} basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label><Translate text={'Other Recipients'} /></label>
                                    <InputMultipleText
                                        placeholder={`other recipients`}
                                        className={`form-control react-multi-tag`}
                                        name="otherRecipients"
                                        onChange={(emails) => { handleOnChangeMultipleText(emails, 'otherRecipients') }}
                                        value={!_.isEmpty(report.otherRecipients) ? report.otherRecipients.split(',') : []}
                                        validateEmail={true}
                                        getLabel={(email, index) => {
                                            return <div data-tag key={index}>
                                                {email}
                                                <span data-tag-handle onClick={() => removeEmail(index, 'otherRecipients')}>
                                                    ×
                                                </span>
                                            </div>
                                        }}
                                    />
                                </div>
                                <div className='form-group col-md-12 react-summer-editor'>
                                    <label><Translate text={'Email Body'} /></label>
                                    <ReactSummernote
                                        children={<div dangerouslySetInnerHTML={{ __html: `<div></div>` }}></div>}
                                        options={{
                                            height: 160,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ['style', ['style']],
                                                ['font', ['bold', 'underline', 'clear']],
                                                ['fontname', ['fontname']],
                                                ['para', ['paragraph']],
                                                ['color', ['color']],
                                                ['insert', ['link']],
                                                ['view', ['codeview']]
                                            ],
                                            disableResizeEditor: true
                                        }}
                                        onChange={handleChange}
                                    // onPaste={onEditorPaste}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button
                    type="button"
                    className={`btn btn-primary float-right ml-2`}
                    onClick={(e) => { handleSendEmail() }}
                >
                    {
                        emailloader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'Send Email'} />
                </button>
                <button
                    type="button"
                    className="btn btn-default float-right"
                    onClick={(e) => { props.handleClose() }}
                ><Translate text={'cancel'} />
                </button>
            </Modal.Footer>
        </Modal >
    )
}

export default ScheduleReport;