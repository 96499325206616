import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText, ReactSelect } from '../../../../components'
import { regionVM } from './viewModel'

const AddRegion = (props) => {
    const [region, setRegion] = useState(props.region);
    const [errorFields, setErrorFields] = useState({});
    const newRegion = !_.isEmpty(props.region) ? false : true
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.region)) {
            const newregionM = Object.assign({}, regionVM);
            newregionM.documentID = window.firebase.firestore().collection(`clientSettings/${props.selectedOEM.value}/regions`).doc().id;
            newregionM.addedBy = localStorage.uid;
            newregionM.modifiedBy = localStorage.uid;
            newregionM.addedDate = window.firebase.firestore.Timestamp.now();
            newregionM.modifiedDate = window.firebase.firestore.Timestamp.now();
            setRegion(newregionM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setRegion({
                ...region,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setRegion({
                ...region,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleReactSelectChange = (e, data) => {
        setRegion({
            ...region,
            [data.name]: e ? e.value : null,
        });
    }

    const saveRegion = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(region['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)
        if (props.allRegions.filter(item => item.value !== region.documentID).some(item => item.label === region.name)) {
            Swal.fire('Region with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        delete region.active;
        region.modifiedDate = window.firebase.firestore.Timestamp.now();
        region.modifiedBy = localStorage.uid
        window.firebase.firestore().doc(`clientSettings/${props.selectedOEM.value}/regions/${region.documentID}`).set(region, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newRegion === true ? 'Region added successfully' : 'Region updated successfully'), {
                    duration: 2000
                })
                props.handleClose(region);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(region) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >Region Name</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={region.name}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Data Region</label>
                                    <ReactSelect
                                        options={props.projects}
                                        name={"projectId"}
                                        placeholder={'select region'}
                                        onChange={handleReactSelectChange}
                                        value={region.projectId}
                                        classNamePrefix={`${errorFields["projectId"]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveRegion(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    Save
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddRegion;