/** LIBRARIES */
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import moment from 'moment';
/** COMPONENTS */
import { ReactMultiSelect, ReactSelect, ReactTimePicker } from '../../../../components';
import Translate from '../../../../constants/translate';
import { visibilities } from './viewModel';

const CafeWorkingHours = props => {
    const [state, setState] = useState({
        workingHours: props?.selectedClient?.cafeSettings?.workingHours ? props?.selectedClient?.cafeSettings?.workingHours : {},
        visibility: props?.selectedClient?.cafeSettings?.visibility ? props?.selectedClient?.cafeSettings?.visibility : '',
        sharedClientIDs: props?.selectedClient?.cafeSettings?.sharedClientIDs ? props?.selectedClient?.cafeSettings?.sharedClientIDs : [],

    })
    const [isLoading, setIsLoading] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [removeClient, setRemoveClient] = useState([]);
    const currentDate = moment().format('YYYY-MM-DD')

    const handleSubmit = (e) => {
        e.preventDefault();
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        _.range(0, 7).map((day) => {
            if (state[day]?.from && !state[day]?.to) {
                formIsValid = false;
                errors[`${day}-to`] = errorClass;
            }
            if (state[day]?.to && !state[day]?.from) {
                formIsValid = false;
                errors[`${day}-from`] = errorClass;
            }
        });
        if (state?.visibility === 'shared' && _.isEmpty(state[`sharedClientIDs`])) {
            formIsValid = false;
            errors[`sharedClientIDs`] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setIsLoading(true)
        const batch = window.firebase.firestore().batch();
        let clientIDs = [props.selectedClient.documentID];
        if (!_.isEmpty(state.sharedClientIDs)) {
            clientIDs = state.sharedClientIDs;
        }
        clientIDs.forEach(cid => {
            batch.set(window.firebase.firestore().doc(`clients/${cid}`), {
                cafeSettings: {
                    ...state,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                }

            }, { merge: true });
        })
        removeClient.forEach(cid => {
            batch.set(window.firebase.firestore().doc(`clients/${cid}`), {
                cafeSettings: {
                    ...state,
                    visibility: 'private',
                    sharedClientIDs: [],
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                }

            }, { merge: true });
        })
        batch.commit()
            .then(() => {
                window.firebase.firestore().doc(`clients/${props.selectedClient.documentID}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Cafe Settings has been updated',
                    }, { merge: true })

                setIsLoading(false)
                toast.notify('Settings updated successfully', {
                    duration: 2000
                })
                props.handleClose()
            })
            .catch((error) => {
                setIsLoading(false)
                Swal.fire('Something went wrong. Please contact support.', '', 'error')
            });

    };

    const handleHoursChange = (val, name, day) => {
        setState({
            ...state,
            ['workingHours']: {
                ...state['workingHours'] ? state['workingHours'] : {},
                [day]: {
                    ...state['workingHours'][day] ? state['workingHours'][day] : {},
                    [name]: moment(val).format('HH:mm')
                }
            }
        });
    }

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-cafe-hours"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">

                            <div className="form-style w-100">
                                {
                                    _.range(0, 7).map((day, index) => {
                                        return <div key={index} className="form-row form-row-line">
                                            <div className="form-group col-md-3">
                                                <label style={{ paddingTop: '8px' }}>{moment().day(day).format('dddd')}</label>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <div className="inner-addon right-addon">
                                                    <i className="bg-icons ico icon-time"></i>
                                                    <ReactTimePicker
                                                        value={state['workingHours'][day]?.from ? moment(`${currentDate} ${state['workingHours'][day]?.from}`)._d : null}
                                                        name={`${day}-startworkingHours`}
                                                        onChange={(val) => handleHoursChange(val, 'from', day)}
                                                        timeIntervals={30}
                                                        placeholder='h:mm aa'
                                                        className={`form-control ${errorFields[`${day}-from`]}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <div className="inner-addon right-addon">
                                                    <i className="bg-icons ico icon-time"></i>
                                                    <ReactTimePicker
                                                        value={state['workingHours'][day]?.to ? moment(`${currentDate} ${state['workingHours'][day]?.to}`)._d : null}
                                                        name={`${day}-endworkingHours`}
                                                        onChange={(val) => handleHoursChange(val, 'to', day)}
                                                        timeIntervals={30}
                                                        placeholder='h:mm aa'
                                                        className={`form-control ${errorFields[`${day}-to`]}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-1">
                                                <a href="#" className="mini-button mt-1" onClick={(e) => {
                                                    e.preventDefault();
                                                    setState({
                                                        ...state,
                                                        ['workingHours']: {
                                                            ...state['workingHours'] ? state['workingHours'] : {},
                                                            [day]: {}
                                                        }
                                                    })
                                                }}>
                                                    <i className="ico icon-remove"></i>
                                                </a>
                                            </div>
                                        </div>
                                    })
                                }
                                <div className="form-row mt-2">
                                    <div className="form-group col-md-12" >
                                        <label><Translate text={'visibility'} /></label>
                                        <ReactSelect
                                            options={visibilities}
                                            name={"visibility"}
                                            placeholder={'select visibility'}
                                            onChange={(e) => {
                                                setState({
                                                    ...state,
                                                    visibility: e ? e.value : null
                                                })
                                            }}
                                            value={state?.visibility ? state?.visibility : ''}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={true}
                                            isSearchable={false}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                                {
                                    state?.visibility === 'shared' ? (
                                        <div className="form-row">
                                            <div className="form-group col-md-12" >
                                                <label ><Translate text={'Client'} /></label>
                                                <ReactMultiSelect
                                                    options={props.clientOptions}
                                                    name={'sharedClientIDs'}
                                                    placeholder={'select client'}
                                                    onChange={(selectedOptions) => {
                                                        const value = [];
                                                        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                            value.push(data.value)
                                                        })
                                                        let difference = state.sharedClientIDs ? state.sharedClientIDs.filter(x => !(!_.isEmpty(selectedOptions) ? selectedOptions.map(r => { return r.value }) : []).includes(x)) : [];
                                                        setRemoveClient([
                                                            ...removeClient,
                                                            ...difference
                                                        ])
                                                        setState({
                                                            ...state,
                                                            ['sharedClientIDs']: value
                                                        })
                                                    }}
                                                    value={state?.sharedClientIDs ? state?.sharedClientIDs : []}
                                                    classNamePrefix={`${errorFields['sharedClientIDs']} basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>
                                    ) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-default float-left"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose();
                        }}
                    ><Translate text={'cancel'} />
                    </button>

                    <button
                        type="button"
                        className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                        <Translate text={'done'} />
                    </button>
                </Modal.Footer>
            </Modal>
        </>


    )
}

export default CafeWorkingHours