/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
//import { OverlayTrigger, Tooltip } from 'react-bootstrap'
/** COMPONENTS */
import { ReactSelect } from '../../components';
import Translate from '../../constants/translate';
//import { enquiryStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';

export default class LostReasons extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.state = {
            fields:
            {
                lostReason: '',
                lostSubReason: ''
            },
            lostReasons: [],
            lostSubReasons: [],
            errors: {}
        }
    }

    loadEnquiry = () => {
        const { dealersettings } = this.props;
        if (dealersettings && dealersettings.client && dealersettings.client.settings) {

            const lostReasons = [];

            const setttings = dealersettings.client.settings;
            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            this.setState({
                errors: {},
                lostReasons: lostReasons
            });
        }
    }

    componentDidMount() {
        this.loadEnquiry();
    }

    handleLostReasonSave = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        const { lostReasons } = this.state;
        const lostSubReasons = [];
        if (!_.isEmpty(lostReasons.filter(m => m.value === e.value)[0].subList)) {
            lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                lostSubReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
        }
        state.lostReason = e.value;
        state.lostSubReason = '';
        this.setState({ fields: state, lostSubReasons: lostSubReasons }, () => this.errorChange(data.name));
    };

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        if (e) {
            state[data.name] = e.value;
        }
        else {
            state[data.name] = '';
        }
        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key] && key === 'lostReason')
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (!fields[key] && key === 'lostReason') {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.handleSave();
        }
        else
            return formIsValid;

    };

    handleSave = () => {
        this.isLoading = true;
        const { enquiryID, dealersettings } = this.props;
        const { lostReason, lostSubReason } = this.state.fields;

        let modifiedBy = dealersettings ? dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();

        const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(enquiryID);
        updateRef
            .update({
                lostReason: lostReason,
                lostSubReason: lostSubReason,
                //status: enquiryStatus.LOST,
                modifiedBy: modifiedBy,
                modifiedDate: modifiedDate,
            })
            .then(docRef => {
                this.isLoading = false;
                this.props.handleClose(this.state.fields)
            })
            .catch(error => {
                this.isLoading = false;
                console.error('Error updating enquiries: ', error);
            });
    }

    handleCancel = (e) => {
        e.preventDefault();
        this.loadEnquiry();
    }


    render() {
        const { lostReason, lostSubReason } = this.state.fields;
        const { lostReasons, lostSubReasons } = this.state;
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title><Translate text={this.props.title} /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="search-pop form-style">

                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'lostReason'} /> </label>
                                            <ReactSelect
                                                options={lostReasons}
                                                name={'lostReason'}
                                                placeholder={'select lost reason'}
                                                onChange={this.handleLostReasonSave}
                                                value={lostReason}
                                                classNamePrefix={`cursor-pointer ${this.state.errors['lostReason']} basic-select`}
                                                removeClearable={true}
                                            ></ReactSelect>
                                        </div>
                                    </div>

                                    {
                                        !_.isEmpty(lostSubReasons)
                                            ?
                                            <>
                                                <div className="form-row ">
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'lostSubReason'} /> </label>
                                                        <ReactSelect
                                                            options={lostSubReasons}
                                                            name={'lostSubReason'}
                                                            placeholder={'select lost sub reason'}
                                                            onChange={this.handleReactSelectChange}
                                                            value={lostSubReason}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors['lostSubReason']} basic-select`}
                                                            removeClearable={true}
                                                        ></ReactSelect>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }

                                    

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        {/* <button
                                type="button"
                                className="btn btn-default float-right"
                                onClick={(e) => this.props.handleClose()}
                            ><Translate text={'cancel'} />
                            </button> */}
                        <button type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'done'} />
                        </button>
                    </Modal.Footer>
                </Modal>    

            </>
        );
    }
}
