import React from 'react'
import _ from 'lodash'
import { ReactSelect } from "../../components";
import { Popover } from 'react-bootstrap'

const FilterPanel = (props) => {
    return (
        <>
            <Popover.Content>
                <div className="form-style w-100 pipeline-form-style">
                    <div className="form-row">
                        {
                            props.pipelineOptions && props.pipelineOptions.length > 0 ? (
                                <div className="form-group col-md-12">
                                    <label > Pipeline </label>
                                    <ReactSelect
                                        options={props.pipelineOptions}
                                        name={"pipeline"}
                                        placeholder={'select pipeline'}
                                        onChange={(e, data) => {
                                            props.setPipeline(e)
                                        }}
                                        value={!_.isEmpty(props.selectedPipeline) ? props.selectedPipeline.value : ''}
                                        classNamePrefix={`cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                            ) : (<></>)
                        }

                        {
                            props.showSearchText ? (
                                <div className="form-group col-md-12">
                                    <label > Search </label>
                                    <input type="text" placeholder="search" className="form-control" value={props.searchText} onChange={(e) => { props.setSearchText(e) }} />
                                </div>
                            ) : (<></>)
                        }
                        {
                            props.viewOtherEnquiry ? (<div className="form-group col-md-12">
                                <label > User </label>
                                <ReactSelect
                                    options={props.userOptions}
                                    name={"user"}
                                    placeholder={'select user'}
                                    onChange={(e, data) => {
                                        props.setUser(e)
                                    }}
                                    value={!_.isEmpty(props.selectedUser) ? props.selectedUser.value : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={false}
                                >
                                </ReactSelect>
                            </div>) : (<></>)
                        }

                    </div>
                </div>
            </Popover.Content>
            <div className="popover-footer">
                <button type="button" className="btn btn-primary float-right" onClick={() => { props.applyFilter() }}>Apply</button>
                <button type="button" className="btn btn-default float-left" onClick={() => { props.clearFilter() }}>Cancel</button>
            </div>
        </>
    );
}

export default FilterPanel;