/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
/** VIEW-MODELS */


const Info = (props) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();


    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    return (<div className="enquiry-flow-item" id={'wLog_' + props.log.id}>
        <div className="flow-item ">
            <div className="flow-item-content">
                {
                    props.log.isEditable ? (<div className='form-row form-style activity-nav-editmode'>
                        <props.TextArea />
                    </div>) : (
                        <div className="flow-text-wrap">
                            <div className="flow-text text-area-space ">
                                <strong>{props.log.notes}</strong>
                                <div className="flow-activity-date">
                                    <span>{moment.unix(props.log.addedDate.seconds).format('DD/MM/YYYY hh:mm A')} </span> - {CommonHelper.getUserNamebyId(!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers, props.log.addedBy)}
                                </div>
                            </div>


                            {/* <div className="enquiry-flowitem-more"> <a href="#"><i className="ico icon-more"></i></a> </div> */}
                            {
                                props.accessToAddRosterNotes || props.accessToDeleteRosterNotes
                                    ?
                                    <>
                                        <div className="enquiry-flowitem-more">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setEditshow(!editshow)
                                                if (editshow) document.addEventListener('click', setEditshow(false));
                                            }}><i className="ico icon-more"></i></a>
                                            <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                                <ul className="more-dropdown">
                                                    {
                                                        props.accessToAddRosterNotes ? (
                                                            <li>
                                                                <a href="#" onClick={(e) => { e.preventDefault(); props.editNote(props.log) }}>
                                                                    <i className="ico icon-edit"></i>  <Translate text={'edit'} />
                                                                </a>
                                                            </li>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        props.accessToDeleteRosterNotes
                                                            ?
                                                            <li> <a href="#" onClick={(e) => { e.preventDefault(); props.deleteNote(props.log) }}>
                                                                <i className="ico icon-delete"></i>
                                                                <Translate text={'delete'} />
                                                            </a> </li>
                                                            :
                                                            <></>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    )
                }

            </div>
        </div>
        <span className="flow-icon-user">
            <img src={CommonHelper.getUserImagebyId(!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers, props.log.addedBy)} className="img-object-fit" width="300" height="300" />
        </span>
    </div>
    )
};

export default Info;
