import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { InputText, InputMultipleText, ReactMultiSelect, ReactSelect, ReactTimePicker, InputCheckBox } from '../../components';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import ReactSummernote from 'react-summernote';
import { frequencyOptions, dataOptions } from "./viewModel";
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import ContactFilters from './contactFilters'
import { firestoreDB } from '../../services/helper';

const ScheduleReport = (props) => {
    const [report, setReport] = useState(props.report);
    const [errorFields, setErrorFields] = useState({});
    const [initialized, setInitialized] = useState(false);
    const [loader, setLoader] = useState(false)
    const [emailloader, setEmailLoader] = useState(false)
    const [deleteloader, setDeleteLoader] = useState(false)
    const [emailHtml, setEmailHtml] = useState('')
    const [newSchedular, setnewSchedular] = useState(false);
    const [cron, setCron] = useState();
    const [dateOptions, setDateOptions] = useState()
    const [errorRules, setErrorRules] = useState({});

    useEffect(() => {
        if (_.isEmpty(report))
            return
        if (!initialized) {
            // console.log('emailType', emailType)
            var node = document.createElement('div');
            node.innerHTML = `${emailHtml}`;
            ReactSummernote.insertNode(node);
            setInitialized(true);
        }

    }, [report]);

    useEffect(() => {
        if (_.isEmpty(props.report))
            return

        window.firebase.firestore().doc(`schedular/${props.report.documentID}`)
            .get()
            .then(rec => {
                if (rec.exists) {
                    setEmailHtml(!_.isEmpty(rec.data().emailBody) ? rec.data().emailBody : '')
                    setInitialized(false);
                    let arrCron = [];
                    if (rec.data().cron && rec.data().cron !== '* * * * *') {
                        arrCron = rec.data().cron.split(' ');
                    }
                    setReport({
                        ...rec.data(),
                        enabled: rec.data().static === true ? Boolean(props.report.enabled) : rec.data().enabled,
                        schedulartime: arrCron.length > 0 ?
                            window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                    })
                    setnewSchedular(false)
                }
                else {
                    setEmailHtml('')
                    setReport({
                        ...report,
                        ['frequency']: 'daily',
                        ['fileType']: 'excel',
                        ['type']: 'contact',
                        ['documentID']: props.report.documentID,
                        ['clientID']: props.dealersettings.client.id,
                        ['addedBy']: localStorage.uid,
                        ['modifiedBy']: localStorage.uid,
                        ['addedDate']: window.firebase.firestore.Timestamp.now(),
                        ['modifiedDate']: window.firebase.firestore.Timestamp.now(),
                        ['filters']: [{
                            name: "date",
                            value: "addedDate",
                            type: "thisMonth",
                            id: window.firebase.firestore().collection('schedular').doc().id,
                            range: null
                        }]
                    })
                    setnewSchedular(true)
                }
            })

        let _date = []
        _date.push({ label: 'Last day of month', value: 'L' })
        setDateOptions(_date)
    }, [])

    useEffect(() => {
        if (_.isEmpty(report))
            return


        let cron = ['*', '*', '*', '*', '*']
        if (report.schedulartime) {
            cron[0] = moment.unix(report.schedulartime.seconds).format('m');
            cron[1] = moment.unix(report.schedulartime.seconds).format('H');
        }
        if (report.day) {
            cron[4] = report.day;
            cron[2] = '*';
        }
        if (report.date) {
            cron[2] = report.date;
            cron[4] = '*';
        }
        setCron(cron.join(' '));
    }, [report])

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setReport({
                ...report,
                [name]: newarray1.join(' ')

            })
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setReport({
                ...report,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'frequency')
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['day']: '',
                ['date']: ''
            })
        else
            setReport({
                ...report,
                [data.name]: e ? e.value : ''
            })


        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }


    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setReport({
            ...report,
            [name]: value
        });
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleOnChangeMultipleText = (val, name) => {
        setReport({
            ...report,
            [name]: val
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const removeEmail = (index, name) => {
        let arr = report[name] ? _.isArray(report[name]) ? report[name] : report[name].split(',') : []
        arr.splice(index, 1)
        setReport({
            ...report,
            [name]: arr
        })
    }

    const handleChange = obj => {
        //let htmlText = obj
        setEmailHtml(obj)
    };

    const handleCheckedChange = (e) => {
        setReport({
            ...report,
            [e.target.name]: e.target.checked
        })
    }

    const handleHoursChange = (val, name) => {
        setReport({
            ...report,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });
    }

    const handleSubmit = () => {
        let rec = report;
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['subject'])) {
            formIsValid = false;
            errors['subject'] = errorClass;
        }
        if (_.isEmpty(rec['recipients'])) {
            formIsValid = false;
            errors['recipients'] = errorClass;
        }

        if (rec.enabled === true) {
            if (_.isEmpty(rec['frequency'])) {
                formIsValid = false;
                errors['frequency'] = errorClass;
            }
            if (_.isEmpty(rec['schedulartime'])) {
                formIsValid = false;
                errors['schedulartime'] = errorClass;
            }
            if (rec['frequency'] === 'weekly' && _.isEmpty(rec['day'])) {
                formIsValid = false;
                errors['day'] = errorClass;
            }
            if (rec['frequency'] === 'monthly' && _.isEmpty(rec['date'])) {
                formIsValid = false;
                errors['date'] = errorClass;
            }
        }

        setErrorFields(errors)

        let _isValidFilters = isValidFilters();

        if (!formIsValid || !_isValidFilters) {
            return;
        }


        setLoader(true)
        window.firebase.firestore().doc(`schedular/${report.documentID}`).set({
            ...report,
            emailBody: emailHtml,
            cron: cron,
            url: 'ContactExcelReport'
        }, { merge: true }).then(rec => {
            setLoader(false)
            toast.notify('Report scheduled successfully.', {
                duration: 2000
            })
            props.handleClose()
        }).catch(error => {
            setLoader(false)
            console.error(error);
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong'), '', 'error')
        })
    }


    //#region RULE CHANGES

    const isValidFilters = (isScroll) => {
        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';


        let _filterLists = Object.assign([], report.filters);

        !_.isEmpty(_filterLists) && _filterLists.forEach(_data => {
            if (_.isEmpty(_data.name)) {
                formIsValid = false;
                errors['name_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;

            }
            else if (_.isEmpty(_data.value) && (!_.isObject(_data.value) && !_.isBoolean(_data.value))) {
                formIsValid = false;
                errors['value_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;
            }

        });

        setErrorRules(errors);
        //console.log('isValidFilters,', errors)

        // if (isScroll && _key && document.getElementById(_key) && !_.isEmpty(errors[_key])) {
        //     document.getElementById(_key).scrollIntoView({
        //         behavior: "smooth",
        //         block: "center"
        //     })
        // }


        return formIsValid;
    }

    const addRule = () => {
        let _lists = Object.assign([], report.filters);

        let _isValidFilters = isValidFilters();

        if (!_isValidFilters)
            return;

        _lists.push({ name: null, value: null, id: firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc().id })
        setReport({
            ...report,
            ['filters']: _lists
        });

    }

    const updateRule = (_data) => {
        if (!_.isEmpty(_data)) {
            const _reLists = Object.assign([], report.filters);

            _reLists.filter(function (obj) {
                //Update old data
                if (obj.id === _data.id) {
                    for (let [key, value] of Object.entries(_data)) {
                        obj[key] = value;
                    }
                }
            });
            //console.log('updateRule', _data, _reLists)

            setReport({
                ...report,
                ['filters']: _reLists
            });
        }
    }

    const deleteRule = (_id) => {
        if (!_.isEmpty(_id)) {
            const _reLists = Object.assign([], report.filters);
            //console.log('deleteRule', _id, _reLists.filter(m => m.id !== _id))

            setReport({
                ...report,
                ['filters']: _reLists.filter(m => m.id !== _id)
            });
        }
    }

    //#endregion

    return _.isEmpty(report) ? (<></>) : (

        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-company"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={'Schedule Report'} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" pop-layout form-style">
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <label><Translate text={'Email Subject'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'subject'}
                                        className={`form-control ${errorFields["subject"]}`}
                                        name="subject"
                                        onChange={handleOnChange}
                                        value={report.subject}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label><Translate text={'Recipients'} /></label>
                                    <ReactMultiSelect
                                        options={(props.oemReport === true ? _.uniqBy(props.groupUsers, 'id') : props.clientUsers).filter(e => Boolean(e.superAdmin) === false && !_.isEmpty(e.email))
                                            .map(rec => {
                                                return {
                                                    value: rec.email,
                                                    searchlabel: rec.name,
                                                    active: _.isBoolean(rec.active) ? rec.active : true,
                                                    label: (
                                                        <div className='drop-image'>
                                                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                            {rec.name}
                                                        </div>
                                                    ),
                                                };
                                            })}
                                        name={"recipients"}
                                        placeholder={`select recipients`}
                                        onChange={(option) => handleReactMultiSelectChange(option, 'recipients')}
                                        value={report.recipients}
                                        classNamePrefix={`${errorFields["recipients"]} basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label><Translate text={'Other Recipients'} /></label>
                                    <InputMultipleText
                                        placeholder={`other recipients`}
                                        className={`form-control react-multi-tag`}
                                        name="otherRecipients"
                                        onChange={(emails) => { handleOnChangeMultipleText(emails, 'otherRecipients') }}
                                        value={!_.isEmpty(report.otherRecipients) ? report.otherRecipients : []}
                                        validateEmail={true}
                                        getLabel={(email, index) => {
                                            return <div data-tag key={index}>
                                                {email}
                                                <span data-tag-handle onClick={() => removeEmail(index, 'otherRecipients')}>
                                                    ×
                                                </span>
                                            </div>
                                        }}
                                    />
                                </div>

                                <div className="settings-sub-container mt-4">
                                    <h3> <Translate text={'Add Rule Filter'} /> </h3>
                                    <div className="rule-filter-head">
                                        {
                                            !_.isEmpty(report.filters)
                                                ?
                                                <div className="float-left rule-filter-info"> <i className="ico icon-filter"></i>
                                                    {Object.keys(report.filters).length} <Translate text={Object.keys(report.filters).length > 1 ? 'Filters' : 'Filter'} /> <Translate text={'Applied'} />

                                                </div>
                                                :
                                                <></>
                                        }

                                        <div className="float-right rule-add-btn">
                                            <button type="button" className="btn btn-primary float-right ml-2"
                                                onClick={(e) => { e.preventDefault(); addRule() }}>
                                                <i className="ico icon-add mr-2"></i> <Translate text={'Add Rule'} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="rules-group-wrapper query-builder">
                                        <div className="rules-group-body">
                                            <div className="rules-list">

                                                {
                                                    !_.isEmpty(report.filters) && report.filters.map((_rec, index) => {
                                                        return <ContactFilters
                                                            {...props}
                                                            key={index}
                                                            docID={_rec ? _rec.id : ''}
                                                            fieldData={_rec}
                                                            errors={errorRules}
                                                            filterFields={report.filters}
                                                            updateRule={updateRule}
                                                            deleteRule={deleteRule}
                                                        ></ContactFilters>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group col-md-12 react-summer-editor'>
                                    <label><Translate text={'Email Body'} /></label>
                                    <ReactSummernote
                                        children={<div dangerouslySetInnerHTML={{ __html: `<div></div>` }}></div>}
                                        options={{
                                            height: 160,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ['style', ['style']],
                                                ['font', ['bold', 'underline', 'clear']],
                                                ['fontname', ['fontname']],
                                                ['para', ['paragraph']],
                                                ['color', ['color']],
                                                ['insert', ['link']],
                                                ['view', ['codeview']]
                                            ],
                                            disableResizeEditor: true
                                        }}
                                        onChange={handleChange}
                                    // onPaste={onEditorPaste}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className='form-group col-md-12'>
                                    <span className="switch switch-sm float-left">
                                        <InputCheckBox
                                            className="switch"
                                            name={'enabled'}
                                            checked={report.enabled === true ? true : false}
                                            onChange={(e) => { handleCheckedChange(e) }}
                                            id={'report-schedular'} />
                                        <label htmlFor={'report-schedular'}><Translate text={'Recurring Email'} /></label>
                                    </span>
                                </div>
                            </div>
                            {
                                report.enabled === true ? (<>

                                    <div className="form-row">
                                        <div className={`form-group ${report.frequency === 'daily' || _.isEmpty(report.frequency) ? 'col-md-6' : 'col-md-4'}`}>
                                            <label ><Translate text={'Frequency'} /></label>
                                            <ReactSelect
                                                options={frequencyOptions}
                                                name={"frequency"}
                                                placeholder={'select frequency'}
                                                onChange={handleSelectChange}
                                                value={report.frequency}
                                                classNamePrefix={`${errorFields["frequency"]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        {
                                            report.frequency === 'weekly' ? (
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'Select Day'} /></label>
                                                    <ReactSelect
                                                        options={moment.weekdays().map(rec => {
                                                            return {
                                                                label: rec,
                                                                value: moment.weekdays().indexOf(rec).toString()
                                                            }
                                                        })}
                                                        name={"day"}
                                                        placeholder={'select day'}
                                                        onChange={handleSelectChange}
                                                        value={report.day}
                                                        classNamePrefix={`${errorFields["day"]} cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            ) : report.frequency === 'monthly' ? (
                                                <div className="form-group col-md-4">
                                                    <label ><Translate text={'selectDate'} /></label>
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        name={"date"}
                                                        placeholder={'select date'}
                                                        onChange={handleSelectChange}
                                                        value={report.date}
                                                        classNamePrefix={`${errorFields["date"]} cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            ) : (<></>)
                                        }
                                        <div className={`form-group ${report.frequency === 'daily' || _.isEmpty(report.frequency) ? 'col-md-6' : 'col-md-4'}`}>
                                            <label ><Translate text={'time'} /></label>
                                            <div className="inner-addon right-addon">
                                                <i className="bg-icons ico icon-time"></i>
                                                <ReactTimePicker
                                                    value={report.schedulartime ? moment.unix(report.schedulartime.seconds)._d : null}
                                                    name={'schedulartime'}
                                                    onChange={handleHoursChange}
                                                    timeIntervals={30}
                                                    placeholder='h:mm aa'
                                                    className={`form-control ${errorFields["schedulartime"]}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>) : (<></>)
                            }
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="modal-footer">

                {/* {
                    Boolean(report.static) === false ? (<button
                        type="button"
                        className={`btn btn-primary float-left`}
                        onClick={(e) => { handleSendEmail() }}

                    >
                        {
                            emailloader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Send Email
                    </button>) : (<></>)
                } */}

                <button
                    type="button"
                    className="btn btn-primary float-right ml-2"
                    onClick={(e) => { handleSubmit() }}

                >
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={newSchedular ? 'Schedule' : 'Update'} />
                </button>
                <button
                    type="button"
                    className="btn btn-default float-right"
                    onClick={(e) => { props.handleClose() }}
                ><Translate text={'cancel'} />
                </button>


            </Modal.Footer>
        </Modal >
    )
}

export default ScheduleReport;