export const enqiuryOptions = [
    {
        name: 'Make',
        type: 'select',
        value: 'make'
    },
    {
        name: 'Group',
        type: 'select',
        value: 'group'
    },
    {
        name: 'Type',
        type: 'select',
        value: 'type'
    },
    {
        name: 'Model',
        type: 'select',
        value: 'model'
    },
    {
        name: 'Year',
        type: 'select',
        value: 'year'
    },
    {
        name: 'Model Description',
        type: 'text',
        value: 'modelDescription'
    },
	{
        name: 'Stock Number',
        type: 'text',
        value: 'stockNo'
    },
	{
        name: 'VIN Number',
        type: 'text',
        value: 'vinNo'
    },
	{
        name: 'Reg. Number',
        type: 'text',
        value: 'regNo'
    },
	{
        name: 'Registration Date',
        type: 'date',
        value: 'regDate'
    },
	{
        name: 'Registration Expiry',
        type: 'date',
        value: 'regExp'
    },
	{
        name: 'Exterior Colour',
        type: 'text',
        value: 'extColor'
    },
	{
        name: 'Interior Colour / Trim',
        type: 'text',
        value: 'intColor'
    },
	{
        name: 'Build Date',
        type: 'date',
        value: 'buildDate'
    },
	{
        name: 'Warranty Start Date',
        type: 'date',
        value: 'warrantyStartDate'
    },
	{
        name: 'Warranty Expiry',
        type: 'date',
        value: 'warrantyExp'
    },
	{
        name: 'Price',
        type: 'price',
        value: 'price'
    },
	{
        name: 'Purchase Price',
        type: 'price',
        value: 'purchasePrice'
    },
	{
        name: 'Odometer Reading',
        type: 'number',
        value: 'location'
    },
	{
        name: 'Location',
        type: 'select',
        value: 'location'
    },
	{
        name: 'Usage',
        type: 'select',
        value: 'usage'
    },
	{
        name: 'Transmission',
        type: 'select',
        value: 'transmission'
    },
	{
        name: 'Transport Method',
        type: 'select',
        value: 'transportMethod'
    },
	{
        name: 'Trade Plate',
        type: 'text',
        value: 'tradePlate'
    },
	{
        name: 'Sale Type',
        type: 'select',
        value: 'saleType'
    },
	{
        name: 'Stock Intake Date',
        type: 'date',
        value: 'stockInDate'
    },
	{
        name: 'Stock ETA Date',
        type: 'date',
        value: 'etaDate'
    },
	{
        name: 'Est Dealer FOK Date',
        type: 'date',
        value: 'stockFOKDate'
    },
	{
        name: 'Status',
        type: 'select',
        value: 'status'
    },
	{
        name: 'Sold Date',
        type: 'date',
        value: 'soldDate'
    },
	{
        name: 'Delivered Date',
        type: 'date',
        value: 'deliveredDate'
    },
	{
        name: 'VBM Value',
        type: 'text',
        value: 'marginValue'
    },
	{
        name: 'VBM Retail',
        type: 'text',
        value: 'marginRetail'
    },
	{
        name: 'VBM Order Fill',
        type: 'text',
        value: 'marginOrderFill'
    },
	{
        name: 'VBM Payments',
        type: 'text',
        value: 'marginPayments'
    },
	{
        name: 'VBM Marketing',
        type: 'text',
        value: 'marginMarketing'
    }   

]