/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import {
    InputText,
    ReactTimePicker,
    AntDatePicker,
    ReactSelect,
    ReactMultiSelect, DropDownMenu
} from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import {
    techList
} from '../viewModel';
import { firestoreDB } from '../../../services/helper';



export const objFields = {
    serviceAdvisor: '',
    partsInterpretor: '',
    workshopManager: '',
    technician: '',
    roNumber: '',
    appointmentType: '',
    completionDate: null,
    nextService: null,
    teammates: [],
    technicians: [],
    serviceType: '',
}

export default class ServiceDetails extends Component {
    constructor(props) {
        super(props);

        this.isLoading = false;
        this.mandatoryFields = [];
        this.state = {
            fields: Object.assign({}, objFields),
            errors: {}
        }
        const { dealersettings } = this.props;
        this.currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, serviceJob, errors } = props;
        const fields = Object.assign({}, objFields);


        if (show === true && serviceJob) {
            const objData = Object.assign({}, serviceJob);

            for (let [key, value] of Object.entries(objData)) {
                if (fields.hasOwnProperty(key))
                    fields[key] = value;
            }

            this.setState({ fields: fields });
        }
        else if (show === true) {
            this.setState({ fields: fields });
        }

        if (show === true && errors) {
            let _error = [];
            for (let [key, value] of Object.entries(errors)) {
                if (fields.hasOwnProperty(key))
                    _error.push(key)
            }
            this.mandatoryFields = _error;
            this.setState({ errors: errors });
        }
    }


    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT
    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = null;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }

    handleMultiSelectChange = (selectedOptions, data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        state[data.name] = value;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }

    handleTechChange = (evt, idx) => {
        let state = Object.assign({}, this.state.fields);
        const newEmail = !_.isEmpty(state.technicians) && state.technicians.map((data, sidx) => {
            if (idx.name !== sidx) return data;
            return { ...data, value: evt.value };
        });
        state['technicians'] = newEmail
        this.setState({ fields: state });
    };
    handleAddTech = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['technicians'] = Object.assign([], state.technicians).concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveTech = (idx) => () => {
        let state = Object.assign({}, this.state.fields);
        state['technicians'] = state.technicians.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };

    handleOnDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleTimeChange = (date, name) => {
        let state = Object.assign({}, this.state.fields);

        state[name] = window.firebase.firestore.Timestamp.fromDate(moment(date)._d);

        this.setState({ fields: state }, () => { });

    }

    handleTemmateChange = selectedOptions => {
        let state = Object.assign({}, this.state.fields);
        let _usersList = [];

        !_.isEmpty(selectedOptions) && selectedOptions.forEach((data, index) => {
            if (data.type === 'team') {
                _usersList = _.union(_usersList, Object.assign([], data.user_ids));
            }
            else
                _usersList = _.union(_usersList, [data.value]);
        });
        state['teammates'] = _usersList;
        this.setState({ fields: state }, () => { this.errorChange('teammates'); });
        //this.saveServiceField('teammates', _usersList);
    };

    handleCheckChange = (e) => {
        //console.log('handleCheckChange', e, name, e.target.checked, e.target.name);
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state });
    }

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                errors[key] = '';
            }

            this.setState({ errors: errors });
        }

        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }
    //#endregion

    handleSubmit = async (e) => {
        e.preventDefault();

        let _errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';
        const { errors, serviceID, dealersettings } = this.props;
        for (let [key, value] of Object.entries(fields)) {
            if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                if (!fields[key] && key !== 'totalCost') {
                    formIsValid = false;
                    _errors[key] = errorClass;
                }
            }

            if (key === 'roNumber' && !_.isEmpty(value) && (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['roNumber']))) {
                if (await CommonHelper.checkServiceROExist(dealersettings, dealersettings.client.id, serviceID, value) === true) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'RO Number already exists'), '', 'info');

                    formIsValid = false;
                    _errors[key] = errorClass;
                }
            }
        }

        this.setState({ errors: _errors });

        if (formIsValid === true) {
            this.handleSave();
        }
        else
            return formIsValid;

    };

    handleSave = async () => {
        this.isLoading = true;
        const { serviceID, dealersettings, newStatus, serviceJob } = this.props;

        const objData = Object.assign({}, this.state.fields);

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        for (var propName in objData) {
            if (
                objData[propName] === null || 
                objData[propName] === undefined ||
                objData[propName] === '' ||
                objData[propName] === [] ||
                (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
            ) {
                if (propName !== 'technicians')
                    delete objData[propName];
            }
        }


        //   console.log('handleSave', serviceID, objData, newStatus)
        const updateRef = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(serviceID);
        updateRef
            .set(objData, { merge: true })
            .then(docRef => {
                this.isLoading = false;
                this.handleUpdateOwnedVehicle(objData, serviceJob);

            })
            .catch(error => {
                this.isLoading = false;
                console.error('Error updating enquiries: ', error);
            });
    }

    handleUpdateOwnedVehicle = async (objData, serviceJob) => {
        const { dealersettings, contact } = this.props;
        let _contactID = serviceJob.contactID ? serviceJob.contactID : (serviceJob.contact ? serviceJob.contact.documentID : null);
        let _previousSDate = serviceJob.nextService ? moment.unix(serviceJob.nextService.seconds).format('YYYY-MM-DD HH:mm') : null;
        let _currentSDate = objData.nextService ? moment.unix(objData.nextService.seconds).format('YYYY-MM-DD HH:mm') : null;
        let _requirement = Object.assign({}, serviceJob.vehicle)

        if (_contactID &&
            _requirement && (_requirement.vinNo || _requirement.rego) &&
            _currentSDate && _previousSDate !== _currentSDate) {

            const ref = firestoreDB(dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`)
            const snapshots = await ref.get()
            if (snapshots.size > 0) {
                let ownedVehicles = []
                snapshots.docs.forEach((doc) => {
                    ownedVehicles.push({ ...doc.data(), documentID: doc.id })
                });

                let isExistsData = ownedVehicles && ownedVehicles.filter(m =>
                    (_requirement.vinNo ? m.vinNo === _requirement.vinNo : false) ||
                    (_requirement.rego ? m.regNo === _requirement.rego : false))[0];

                if (isExistsData) {
                    const _objData = Object.assign({});
                    let _meID = !_.isEmpty(contact) && !_.isEmpty(contact.amsMe) && !_.isEmpty(contact.amsMe.meID) ? contact.amsMe.meID : null;
                    _objData.nextService = objData.nextService;
                    _objData.meID = _meID;
                    _objData.modifiedBy = dealersettings ? dealersettings.id : '';
                    _objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                    _objData.modifiedFrom = 'web';
                    _objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

                    firestoreDB(dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`).doc(isExistsData.documentID).set(_objData, { merge: true });
                }
                this.props.handleClose('saved')

            }
            else this.props.handleClose('saved')
        }
        else this.props.handleClose('saved')
    }

    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };


    render() {
        const { roNumber, serviceAdvisor, technician, technicians, workshopManager, partsInterpretor, appointmentType, completionDate, teammates, serviceType, nextService } = this.state.fields;
        const { show, errors, dealersettings, allAdvisorUsers, allTechnicianUsers, allWorkshopUsers, allPartsUsers, appointmentTypes, allTeamUsers, service_Types } = this.props;

        return (
            <>
                <Modal
                    id="modal-add-service-DETAILS"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-confirm-quotation ${(_.isEmpty(errors) || (!_.isEmpty(errors) && Object.keys(errors).length > 5)) ? 'modal-dialog-scrollable' : ''} `}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={'Service Details'} upperCase={true} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">

                                    <div className="form-row">
                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['roNumber']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'roNumber'}/></label>
                                                        <InputText
                                                            type='text'
                                                            autoComplete='off'
                                                            placeholder={'ro number'}
                                                            className={`form-control cursor-pointer ${this.state.errors['roNumber']}`}
                                                            name='roNumber'
                                                            onChange={this.handleOnCapChange}
                                                            value={roNumber ? roNumber : ''}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['serviceType']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'serviceType'}/></label>
                                                        <ReactSelect
                                                            options={service_Types}
                                                            name={"serviceType"}
                                                            placeholder={'select service type'}
                                                            onChange={this.handleSelectSave}
                                                            value={serviceType ? serviceType : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["serviceType"]} basic-select`}
                                                            isSearchable={true}
                                                        //isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['appointmentType']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'appointmentType'} /></label>
                                                        <ReactSelect
                                                            options={appointmentTypes}
                                                            name={"appointmentType"}
                                                            placeholder={'select appointment types'}
                                                            onChange={this.handleSelectSave}
                                                            value={appointmentType ? appointmentType : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["appointmentType"]} basic-select`}
                                                            isSearchable={true}
                                                        //isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['serviceAdvisor']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'serviceAdvisor'} /> </label>
                                                        <ReactSelect
                                                            options={allAdvisorUsers}
                                                            name={"serviceAdvisor"}
                                                            placeholder={'select service advisor'}
                                                            onChange={this.handleSelectSave}
                                                            value={serviceAdvisor ? serviceAdvisor : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["serviceAdvisor"]} basic-select`}
                                                            isSearchable={true}
                                                        //isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['workshopManager']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'workshopManager'}/> </label>
                                                        <ReactSelect
                                                            options={allWorkshopUsers}
                                                            name={"workshopManager"}
                                                            placeholder={'select workshop manager'}
                                                            onChange={this.handleSelectSave}
                                                            value={workshopManager ? workshopManager : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["workshopManager"]} basic-select`}
                                                            isSearchable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['partsInterpretor']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'partsInterpretor'}/> </label>
                                                        <ReactSelect
                                                            options={allPartsUsers}
                                                            name={"partsInterpretor"}
                                                            placeholder={'select parts interpretor'}
                                                            onChange={this.handleSelectSave}
                                                            value={partsInterpretor ? partsInterpretor : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["partsInterpretor"]} basic-select`}
                                                            isSearchable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['technician']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'technician'} /> </label>
                                                        <ReactSelect
                                                            options={allTechnicianUsers}
                                                            name={"technician"}
                                                            placeholder={'select technician'}
                                                            onChange={this.handleSelectSave}
                                                            value={technician ? technician : ''}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["technician"]} basic-select`}
                                                            isSearchable={true}
                                                        //isDisabled={canModifyService ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>

                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'otherTechnicians'} /> </label>
                                                        <ReactMultiSelect
                                                            options={allTechnicianUsers}
                                                            name={"technicians"}
                                                            placeholder={'select technicians'}
                                                            onChange={this.handleMultiSelectChange}
                                                            value={technicians}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            menuPlacement='bottom'
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['completionDate']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-9">
                                                        <label ><Translate text={'completionDate'} /></label>
                                                        <AntDatePicker
                                                            value={completionDate ? moment.unix(completionDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'completionDate'}
                                                            onChange={(e) => { this.handleOnDateChange(e, 'completionDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["completionDate"]}`}
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-3">
                                                        <label ><Translate text={'Time'} /></label>
                                                        <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                            <ReactTimePicker
                                                                value={completionDate ? moment.unix(completionDate.seconds)._d : null}
                                                                name={'completionDate'}
                                                                onChange={this.handleTimeChange}
                                                                timeIntervals={15}
                                                                placeholder='HH:MM'
                                                                className={`form-control ${this.state.errors["completionDate"]}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['nextService']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-9">
                                                        <label ><Translate text={'Next Service Due Date'} /></label>
                                                        <AntDatePicker
                                                            value={nextService ? moment.unix(nextService.seconds).format('YYYY-MM-DD') : null}
                                                            name={'nextService'}
                                                            onChange={(e) => { this.handleOnDateChange(e, 'nextService') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["nextService"]}`}
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-3">
                                                        <label ><Translate text={'Time'} /></label>
                                                        <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                            <ReactTimePicker
                                                                value={nextService ? moment.unix(nextService.seconds)._d : null}
                                                                name={'nextService'}
                                                                onChange={this.handleTimeChange}
                                                                timeIntervals={15}
                                                                placeholder='HH:MM'
                                                                className={`form-control ${this.state.errors["nextService"]}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['teammates']))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'teammates'}/></label>
                                                        <ReactMultiSelect
                                                            options={allTeamUsers}
                                                            name={'teammates'}
                                                            placeholder={'select teammate'}
                                                            onChange={this.handleTemmateChange}
                                                            value={teammates}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            menuPlacement='bottom'
                                                            isGroup={true}
                                                        ></ReactMultiSelect>
                                                    </div>

                                                </>
                                                :
                                                <></>
                                        }

                                    </div>

                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right `}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'save'} />
                        </button>

                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}
