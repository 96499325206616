export const objUser = {
    documentID: null,
    name: '',
    profileImage: '',
    phone: '',
    email: '',
    workPhone: '',
    personelEmail: '',
    dob: null,
    roleID: '',
    department: '',
    address: '',
    state: '',
    areaCode: '',
    country: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseFrontURL: '',
    licenseBackURL: '',
    licenseType: '',
    licenseState: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};

export const objBasicUserInfo = {
    documentID: null,
    name: '',
    phone: '',
    email: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseFrontURL: '',
    licenseBackURL: '',
    licenseType: '',
    licenseState: ''
};