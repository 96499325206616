/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
/** PROVIDERS */
/** VIEW-MODELS */
/** COMPONENTS */
import Translate from '../../../constants/translate';
import Files from '../../files';
import { default as _images } from '../../../images';

const StockSpecifications = (props) => {
    const { dealersettings, clientUsers, groupUsers, stockID, isListView } = props;
    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

    const divStyle = {
        height: '400px',
        overflow: 'auto',
        position: "relative"
    };
    return (
        <>
            <Modal
                id="stockSpecificationsList"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-select-stock">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={'Specifications'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {
                        props.show
                            ?
                            <div style={divStyle}>
                                <Files
                                    stockID={stockID}
                                    dealersettings={dealersettings}
                                    clientUsers={clientUsers}
                                    groupUsers={groupUsers}
                                    isDeivered={readOnlyEnquiryView}
                                    filePath={`stock/${stockID}/files`}
                                    isListView={isListView}
                                    contact={props.contact}
                                >
                                </Files>
                            </div>
                            :
                            <></>
                    }


                </Modal.Body>
            </Modal>


        </>
    )
}

export default StockSpecifications