export const pipelineCountVMNEW = {
    monthActivity: 0,
    monthDelivered: 0,
    monthLost: 0,
    monthOpen: 0,
    monthNew: 0,
    monthTestdrive: 0,
    monthTradein: 0,
    monthWon: 0,
    monthWalkin: 0,
    monthClosed: 0,

    overdueActivity: 0,
    todayActivity: 0,
    todayDelivered: 0,
    todayLost: 0,
    todayOpen: 0,
    todayTestdrive: 0,
    todayTradein: 0,
    todayWon: 0,
    todayNew: 0,
    todayWalkin: 0,
    todayClosed: 0,

    weekActivity: 0,
    weekDelivered: 0,
    weekLost: 0,
    weekOpen: 0,
    weekNew: 0,
    weekTestdrive: 0,
    weekTradein: 0,
    weekWon: 0,
    weekWalkin: 0,
    weekClosed: 0,
}

export const initialLayoutArr = [
    { x: 0, y: 0, w: 4, h: 11, i: 'LeadsByServiceType', minW: 4, maxW: 12, minH: 16, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'Activities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'LeadsByOrigin', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 12, w: 4, h: 11, i: 'LeadsByEnquiryType', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 11, w: 4, h: 11, i: 'SalesFunnel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 11, w: 4, h: 11, i: 'LeadsByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 24, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
     {x: 4, y: 22, w: 4, h: 11, i: 'LeadsByLabels', minW: 4,maxW: 12,minH: 11,maxH: 16,isDraggable: true,isResizable: true,resizeHandles: false,isBounded: false,},  

]

export const widgetSettingsNames = [
    { name: 'Leads By Labels', value: 'LeadsByLabels' },
    { name: 'Activities', value: 'Activities' },
    { name: 'Leads By Origin', value: 'LeadsByOrigin' },
    { name: 'Leads By Enquiry Type', value: 'LeadsByEnquiryType' },
    { name: 'Sales Funnel', value: 'SalesFunnel' },
    { name: 'Leads By Campaign', value: 'LeadsByCampaign' },
    { name: 'Leads By Status', value: 'LeadsByStatus' },
     { name: 'Leads By Service Type', value: 'LeadsByServiceType' },

]

export const defaultWidgetSettings = [
    'LeadsByLabels',
    'Activities',
    'LeadsByOrigin',
    'LeadsByEnquiryType',
    'SalesFunnel',
    'LeadsByCampaign',
    'LeadsByStatus',
     'LeadsByServiceType'
]


export const pipelinestatus = [
    {
        name: "Open",
        value: "open",
        color: "#67b7dc"
    },
    {
        name: "Close Won",
        value: "closeWon",
        color: "#2bb673"
    },
    {
        name: "Lost",
        value: "lost",
        color: "#ef4136"
    }
]
