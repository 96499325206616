import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import UserList from './userList'
import TeamsList from './teamsList'
import UserRoles from './userRoles'
import UserDepartments from './userDepartments'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';

const Users = (props) => {

    const getQueryparam = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('item');
        if (!key) {
            key = "users"
        }
        return key
    }

    return (
        <>
            <h2><Translate text={'User Management'} /></h2>
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey={`${getQueryparam()}`} className="nav-fill" mountOnEnter={true}>
                            <Tab eventKey="users" title={CommonHelper.showLocale(props, "users")}>
                                <UserList {...props} />
                            </Tab>
                            <Tab eventKey="roles" title={CommonHelper.showLocale(props, "Roles")}>
                                <UserRoles {...props} />
                            </Tab>
                            <Tab eventKey="departments" title={CommonHelper.showLocale(props, "Departments")}>
                                <UserDepartments {...props} />
                            </Tab>
                            <Tab eventKey='teams' title={CommonHelper.showLocale(props, 'Teams')}>
                                <TeamsList {...props} />
                            </Tab>
                        </Tabs>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Users