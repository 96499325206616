import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';

const ActivitiesWidget = (props) => {
    const [counts, setCounts] = useState({})
    const [loader, setLoader] = useState(true);
    const [todayStart, setTodayStart] = useState(window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
    const [todayEnd, setTodayEnd] = useState(window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    let ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

    const fetchData = async (hideLoader) => {
        let _filter = {
            clientID: CommonHelper.getTradeinProDealers(props.dealersettings, false, true),
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (props.selectedClient) {
            _filter.clientID = props.selectedClient.value
        }
        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }
        if (ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props.dateRange) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }

        _filter.module = 'tradeinPro';
        _filter.leadsWithoutSales = true;
        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getActivitesCountV2" : "getActivitesCount",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp leadsBucket', resp)
            // console.log('getActivitesCount',resp)
            const data = resp.data.data[0]
            const _counts = {
                today: data.todayactivity,
                month: data.monthactivity,
                overdue: data.overdue,
                noactivity: data.noactivity,
            }
            setCounts(_counts)
            setLoader(false)
        } else {
            // toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.selectedUser, props.selectedClient, props.selectedPipeline, props.dateRange, props.refreshData])


    return (<>
        <div className="dashboard-box draggable-section">
            <div className="row h-100" style={{ minHeight: `165px` }}>
                <div className="col-md-6">
                    <div className="dashboard-box-inner d-flex align-items-center justify-content-center h-100">
                        <div className="dashboard-lost-wrap cursor-pointer" onClick={() => {
                            if (counts.overdue) {
                                props.handleShowActivityList({
                                    isDone: false,
                                    endDate: todayStart,
                                    dateType: 'startDate',
                                    type: 'tradeinProactivity',
                                    clientID: CommonHelper.getTradeinProDealers(props.dealersettings, false, true)
                                }, 'Overdue Activities', counts.overdue)
                            }
                        }}>
                            <Translate text={'overdueActivities'} />
                            <h2>{counts.overdue ? counts.overdue : '0'}</h2>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 border-left pl-0">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="dashboard-allocated-wrap " style={{ height: '110px' }}>
                                <h3><Translate text={'Completed Activities'} /></h3>
                                <div className="dashboard-module-count-wraper dashboard-cell-bg" style={{ marginTop: '12.5px', borderBottomRightRadius: '11px' }}>
                                    <div className="row">
                                        {
                                            props.applydateRange === false ? (
                                                <div className="col-lg-6" style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (counts.today) {
                                                        props.handleShowActivityList({
                                                            startDate: todayStart,
                                                            endDate: todayEnd,
                                                            isDone: true,
                                                            dateType: 'modifiedDate',
                                                            type: 'tradeinProactivity',
                                                            clientID: CommonHelper.getTradeinProDealers(props.dealersettings, false, true)
                                                        }, 'Today Activities', counts.today)
                                                    }
                                                }}>
                                                    <div className="dashboard-count-title"> Today</div>
                                                    <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.today ? counts.today : '0'}</a></div>
                                                </div>) : (<></>)
                                        }

                                        <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                            if (counts.month) {
                                                if (counts.month) {
                                                    props.handleShowActivityList({
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        isDone: true,
                                                        dateType: 'modifiedDate',
                                                        type: 'tradeinProactivity',
                                                        clientID: CommonHelper.getTradeinProDealers(props.dealersettings, false, true)
                                                    }, (props.applydateRange === false ? `MTD Activities` : 'Activities'), counts.month)
                                                }
                                            }
                                        }}>
                                            {
                                                props.applydateRange === false ?
                                                    (
                                                        <div className="dashboard-count-title">MTD</div>
                                                    ) : (<></>)
                                            }
                                            <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link">{counts.month ? counts.month : '0'}</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ cursor: 'pointer' }} onClick={() => {
                    if (counts.noactivity) {
                        props.handleShowEnquiryList({
                            activityOption: 'noactivity',
                        }, 'Appraisals without scheduled activity', counts.noactivity)
                    }
                }}>
                    <div className="dashboard-Pending-wrap"><span className="dashboard-Pending-txt"><Translate text={'Appraisals without scheduled activity'} /></span> <span className="float-right"><h2>{counts.noactivity}</h2></span></div>
                </div>
            </div>
        </div >
    </>);
}

export default ActivitiesWidget;