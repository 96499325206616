import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash'
import { ContentOverlay, PopUpModal } from '../../components'
import CommonHelper from '../../services/common'
import images, { default as _images } from '../../images';
import Translate from '../../constants/translate';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { defaultfleetAssignTypes, defaultfleetCategory } from '../../services/enum';
import FleetAssign from '../fleetPro/assign';

const acitivtyTags = [
    { name: "Pending Approvals", value: 'approval' },
    { name: "Fleet Request", value: 'request' }
]


const PendingApproval = (props) => {
    const [loader, setLoader] = useState(true)
    const [assignData, setAssignData] = useState({ approval: [], request: [] })
    const [activeAcitivtyTag, setActiveactivity] = useState('approval')
    const [fleetModal, setfleetModal] = useState({ show: false, docID: null })

    const _limit = 30

    useEffect(() => {
        return () => {
            window.unsubrefFleetAssignData && window.unsubrefFleetAssignData.unsubscribe();
        }
    }, [])

    useEffect(() => {

        var collectionPromise = [];
        let _levelIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, props.dealersettings.level, true, true);
        let _levelIDNAme = CommonHelper.levelBasedID(props.dealersettings.level);
        //console.log('firebase_level==>', level.value, _levelIDs)
        for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
            let refpendingApproval = window.firebase.firestore().collection('fleetProAssign')
                .where('isDeleted', '==', false)
                .where(`fleetPro.${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                .where('status', '==', 'pendingApproval')

            let refrequested = window.firebase.firestore().collection('fleetProAssign')
                .where('isDeleted', '==', false)
                .where(`fleetPro.${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                .where('status', '==', 'requested')

            collectionPromise.push(refpendingApproval ? collection(refpendingApproval) : of([]));
            collectionPromise.push(refrequested ? collection(refrequested) : of([]));
        }
        if (collectionPromise.length > 0) {
            window.unsubrefFleetAssignData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    var _fleetpro = []
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    querySnapshot.forEach(doc => {
                        _fleetpro.push({
                            ...doc.data(),
                            id: doc.id,
                            regionID: doc.data()?.fleetPro?.regionID || null,
                            subregionID: doc.data()?.fleetPro?.subregionID || null,
                            groupID: doc.data()?.fleetPro?.groupID || null,
                            categoryValue: doc.data()?.fleetPro?.category || null,
                        })
                    })
                    setLoader(false)
                    _fleetpro = _.uniqBy(_fleetpro, 'id');
                    setAssignData({
                        approval: _fleetpro.filter(a => a.status === 'pendingApproval'),
                        request: _fleetpro.filter(a => a.status === 'requested')
                    });
                });
        }
        else {
            setLoader(false)
        }

    }, [])

    const filterData = useMemo(() => ({
        approval: assignData.approval.filter(
            ({ regionID, subregionID, groupID, clientID, categoryValue }) =>
                (props?.dashboardFilter?.region?.length ? props.dashboardFilter.region.includes(regionID) : true) &&
                (props?.dashboardFilter?.subregion?.length ? _.isEmpty(subregionID) || props.dashboardFilter.subregion.includes(subregionID) : true) &&
                (props?.dashboardFilter?.group?.length ? _.isEmpty(groupID) || props.dashboardFilter.group.includes(groupID) : true) &&
                (props?.dashboardFilter?.dealer?.length ? props.dashboardFilter.dealer.includes(clientID) : true) &&
                (props?.dashboardFilter?.category?.length ? props.dashboardFilter.category.includes(categoryValue) : true)
        ),
        request: assignData.request.filter(
            ({ regionID, subregionID, groupID, clientID, categoryValue }) =>
                (props?.dashboardFilter?.region?.length ? props.dashboardFilter.region.includes(regionID) : true) &&
                (props?.dashboardFilter?.subregion?.length ? _.isEmpty(subregionID) || props.dashboardFilter.subregion.includes(subregionID) : true) &&
                (props?.dashboardFilter?.group?.length ? _.isEmpty(groupID) || props.dashboardFilter.group.includes(groupID) : true) &&
                (props?.dashboardFilter?.dealer?.length ? props.dashboardFilter.dealer.includes(clientID) : true) &&
                (props?.dashboardFilter?.category?.length ? props.dashboardFilter.category.includes(categoryValue) : true)
        )
    }), [assignData, props.dashboardFilter])


    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <h3><Translate text={'Pending Approval / Request'} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="dashboard-activities-wrap" style={{ minHeight: `350px`, height: 'calc(100% - 0px)' }}>
                        {
                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (
                                    <>
                                        <div className="dashboard-activities-nav pl-0">
                                            <ul>
                                                {
                                                    acitivtyTags.map((rec, index) => {
                                                        return <li key={index}>
                                                            <a href="#"
                                                                id={rec.value}
                                                                className={`nav-li-${rec.value} ${activeAcitivtyTag === rec.value ? 'active' : ''}`}
                                                                onClick={(event) => {
                                                                    if (event.target.id) {
                                                                        setActiveactivity(event.target.id)
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            >{<Translate text={rec.name} />}
                                                                {!_.isEmpty(filterData) ?
                                                                    rec.value === 'approval' ? (<span className="badge badge-pill badge-widget-counter ml-1">{filterData.approval.length}</span>)
                                                                        : rec.value === 'request' ? (<span className="badge badge-pill badge-widget-counter ml-1">{filterData.request.length}</span>)
                                                                            : (<></>)
                                                                    : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            filterData[activeAcitivtyTag].length > 0 ? (
                                                <>
                                                    <div className="dashboard-activity-item" style={{ height: 'calc(100% - 40px)' }}>
                                                        {
                                                            filterData[activeAcitivtyTag].map((log, index) => {
                                                                return <div key={index} id={log.id} className='events-dashboard-livewrap mb-2' onClick={(e) => {
                                                                    e.preventDefault();

                                                                    setfleetModal({
                                                                        show: true,
                                                                        docID: log.id,
                                                                        fleetProID: log.fleetProID
                                                                    })
                                                                }}>
                                                                    <div className="events-dashboard-live-flex">
                                                                        <div className="events-dashboard-live-image">
                                                                            {
                                                                                log?.fleetPro?.images?.length ? (
                                                                                    <img src={log.fleetPro.images[0]} alt="" width="70" height="70" />
                                                                                ) : (
                                                                                    <img src={images.novehicle} alt="" width="70" height="70" />
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="events-dashboard-live-data">
                                                                            <div className="events-dashboard-card-title">{log?.fleetPro?.make || ''} {log?.fleetPro?.model || ''}</div>
                                                                            {
                                                                                log.name ? (
                                                                                    <div className="events-dashboard-card-sub"><i className="ico icon-Contacts"></i> {log.name} </div>
                                                                                ) : (<></>)
                                                                            }
                                                                            {
                                                                                _.find(props.dealersettings.clients, { id: log.clientID }) ? (
                                                                                    <div className="events-dashboard-card-sub"><i className="ico icon-group mb-1"></i> <span className='blue-color'>{_.find(props.dealersettings.clients, { id: log.clientID }).name}</span> </div>
                                                                                ) : (<></>)
                                                                            }
                                                                            <div className="events-dashboard-card-sub">
                                                                                <i className="ico icon-date"></i>{CommonHelper.showCombineDate(log.startDate, log.endDate || log.expectedReturnDate)}
                                                                            </div>
                                                                            <div className="flow-notes-sub">
                                                                                {
                                                                                    _.find(defaultfleetCategory, { value: log?.fleetPro?.category }) ? (
                                                                                        <div className="badge badge-pill badge-input-white mr-1">{_.find(defaultfleetCategory, { value: log?.fleetPro?.category }).name}</div>
                                                                                    ) : (<></>)
                                                                                }
                                                                                {
                                                                                    _.find(defaultfleetAssignTypes, { value: log?.assignType }) ? (
                                                                                        <div className="badge badge-pill badge-input-white mr-1">{_.find(defaultfleetAssignTypes, { value: log?.assignType }).name}</div>
                                                                                    ) : (<></>)
                                                                                }
                                                                                {
                                                                                    log.status ?
                                                                                        <div className={`badge badge-pill badge-status-${log.status.trim().toLowerCase()} `}>
                                                                                            {log.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                                                                        </div>
                                                                                        : <></>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            (!loader && filterData[activeAcitivtyTag].length == 0) ? (<><div className="dashboard-activity-item" >
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> <Translate text={`No ${_.find(acitivtyTags, { value: activeAcitivtyTag }).name}`} /> </div>
                                                </div>
                                            </div></>) : (<></>)
                                        }
                                    </>)
                        }
                    </div>
                </div>
            </div>
            <PopUpModal show={fleetModal.show}>
                <FleetAssign
                    {...props}
                    {...fleetModal}
                    clsActive='overlay-modal active'
                    handleClose={() => {
                        setfleetModal({ show: false })
                    }}
                    title={fleetModal.docID ? 'fleet' : 'assignFleet'}
                >
                </FleetAssign>
            </PopUpModal>
        </>
    );
}

export default PendingApproval;