/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox,
    ReactSelect, InputText, ReactMultiSelect,
    AntDateRangePicker, AntMonthPicker
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'
import { tipOfferStatuses } from '../../services/enum'

//#region  ASSIGN VARIABLES

let dealers = [];
const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'all', label: <Translate text={'all'} /> },
    { active: true, value: 'addedDate', label: <Translate text={'addedDate'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'modifiedDate'} /> }
]

const objDateField = {
    name: null,
    type: null,
    range: null
}
//#endregion

const PipelineAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const { columnFields } = props;

    useEffect(() => {
        const { pipelineFilter } = props;
        if (!_.isEmpty(pipelineFilter))
            setFilterFields(pipelineFilter.value);
        else
            setFilterFields({});

    }, [props.pipelineFilter])


    useEffect(() => {
        const { dealersettings, clientUsers, pipelineFilter } = props;

        if (dealersettings) {

            const brands = dealersettings.allMakeModels;
            const _makes = [];


            let _fields = !_.isEmpty(pipelineFilter) ? pipelineFilter.value : {};

            dealers = [];
            dealersettings.wholesalerclients && dealersettings.wholesalerclients.forEach(doc => {
                dealers.push(
                    {
                        label: doc.name,
                        name: doc.name,
                        value: doc.documentID ? doc.documentID : doc.id,
                        active: true,
                    });
            });

            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    models: doc.models
                });
            });

            if ((!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make)) {
                const models = [];
                let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                setModels(models);
            }

            setMakes(_makes);
        }

    }, [props.dealersettings])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }
    }



    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const models = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    active: _.isBoolean(data.active) ? data.active : true,
                    label: CommonHelper.displayModelName(data),
                });
            });
            setModels(models);
            state[data.name] = e.value;
            if (state.hasOwnProperty('model'))
                state['model'] = null;
            setFilterFields(state)
        }
        else {
            state[data.name] = e.value;
            setFilterFields(state)
        }


    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (name === 'make') {
                state[name] = null;
                //state['model'] = null;
            }
            else if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'make') {
                delete state['model'];
            }

            if (name === 'pipeline')
                delete state['stage'];
        }

        setFilterFields(state);

    }


    const handlePIDateSave = (val, name) => {
        let state = Object.assign({}, filterFields);
        if (val) val = moment(val).format('MMM YYYY');
        state[name] = val;
        setFilterFields(state);
    };

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state)
    }

    const onNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, filterFields);
        state[name] = e.value;
        setFilterFields(state)
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.pipelineFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {
        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.pipelineFilter) && !_.isEmpty(props.pipelineFilter.value) && JSON.stringify(props.pipelineFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.pipelineFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }
    }

    const handleSave = () => {
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };

    //#endregion

    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader">   <Translate text={'appraisals'} /> </div>

                <div className="advanced-filter-list">
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('dealerID') >= 0)
                            ?
                            <>
                                {/* dealerID */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'dealerID'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('dealerID')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'dealerID'}><Translate text={'Dealers'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('dealerID')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dealers}
                                                        name={"dealerID"}
                                                        placeholder={'select dealer'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('dealerID') ? filterFields.dealerID : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }



                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0)
                            ?
                            <>
                                {/* STATUS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'status'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'status'}><Translate text={'status'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={tipOfferStatuses}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('status') ? filterFields.status : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('make') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'make'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'make'}><Translate text={'make&model'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('make') ? filterFields.make : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('model') ? filterFields.model : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                </div>
            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            {props.noSaveAccess ? <></> : <><button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button></>}
                            <button type="button" className={`btn btn-default float-left ${props.noSaveAccess ? '' : 'ml-2'}`} onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }

            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.pipelineFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'tradeinPro'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default PipelineAdvanceFilters;