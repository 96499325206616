export const clientsVM = {
    documentID: null,
    category: '',
    clientBrandIDs: [],
    address: '',
    state: '',
    country: '',
    location: '',
    name: '',
    region: '',
    group: '',
    settingsID: null,
    financeTC: '',
    fleetTC: '',
    insuranceTC: '',
    testdriveTC: '',
    customerConsent: '',
    timezone: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isActive: true,
    logoURL: '',
    workingHours: '',
    email: '',
    phone: '',
    website: '',
    fax: '',
    weekStart: '',
    latitude: '',
    longitude: '',
    markerURL: '',
    contactName:'',
    contactEmail:'',
    contactPhone:'',
    contactRoleID:'',
    subregion:''
}

export const groupVM = {
    documentID: null,
    client_ids: [],
    name: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null
}

export const regionVM = {
    documentID: null,
    name: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null
}

export const mandatoryFields = [
    'name',
    'region'
];

export const addClientOEMMandatoryFields = [
    'name',
    'region',
    'subregion',
    'clientBrandIDs',
    'website',
    'phoneORemail',
    'contactName',
    'contactEmail',
    'contactRoleID',
    'address'
];


export const clientlistFields = [
    {
        name: 'Name',
        value: 'name',
        sortValue: 'name',
        flex: 2,
        default: true,
        nestedText: 'settingsName',
        nestedTextTitle: 'OEM'
    },
    {
        name: 'Country',
        value: 'countryName',
        sortValue: 'countryName',
        flex: 1,
        default: true,
        //subText: 'regionName'
    },
    {
        name: 'Address',
        value: 'address',
        sortValue: 'address',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const dealerslistFields = [
    {
        name: 'Name',
        value: 'name',
        sortValue: 'name',
        flex: 2,
        default: true,
        nestedText: 'settingsName',
        nestedTextTitle: 'OEM',
    },
    {
        name: 'Country',
        value: 'countryName',
        sortValue: 'countryName',
        flex: 1,
        default: true,
        subText: 'regionName'
    },
    {
        name: 'Address',
        value: 'address',
        sortValue: 'address',
        flex: 1,
        default: true
    },
    {
        name: 'Created On',
        value: 'createdOn',
        sortValue: 'createdOnSec',
        flex: 1,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const timezone = [
    { active: true, value: 'Asia/Dubai', label: '(UTC+04:00) Abu Dhabi, Muscat' },
    { active: true, value: 'Asia/Karachi', label: '(UTC+05:00) Islamabad, Karachi' },
    { active: true, value: 'Asia/Kolkata', label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
    { active: true, value: 'Australia/Sydney', label: '(UTC+10:00) Canberra, Melbourne, Sydney' }
]

export const weekDays = [
    { active: true, value: 'sunday', label: 'Sunday' },
    { active: true, value: 'monday', label: 'Monday' },
    { active: true, value: 'tuesday', label: 'Tuesday' },
    { active: true, value: 'wednesday', label: 'Wednesday' },
    { active: true, value: 'thursday', label: 'Thursday' },
    { active: true, value: 'friday', label: 'Friday' },
    { active: true, value: 'saturday', label: 'Saturday' },
]

export const states = [
    { active: true, value: 'NSW', label: 'New South Wales' },
    { active: true, value: 'NT', label: 'Northern Territory' },
    { active: true, value: 'QLD', label: 'Queensland' },
    { active: true, value: 'SA', label: 'South Australia' },
    { active: true, value: 'TAS', label: 'Tasmania' },
    { active: true, value: 'QLD', label: 'Queensland' },
    { active: true, value: 'VIC', label: 'Victoria' },
    { active: true, value: 'WA', label: 'Western Australia' }
]

export const countries = [
    { active: true, value: 'AU', label: 'Australia' },
    { active: true, value: 'IND', label: 'India' },
    { active: true, value: 'PKN', label: 'Pakistan' }
]

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]