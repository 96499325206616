import React, { useState, useEffect } from 'react';
import EmbedForm from './embedForm'

const MainContainer = () => {
    const [id, setID] = useState('')
    useEffect(() => {
        if (window.location.href && window.location.href.includes('embed/form/') &&
        window.location.href.split('embed/form/') &&
        window.location.href.split('embed/form/').length > 1) {
            setID(window.location.href.split('embed/form/')[1])
        }
    }, [])
    return !id ? 'INVALID URL' : (
        <main role="main" className="container-fluid pl-0 pr-0">
            <EmbedForm id={id} />
        </main>
    );  
}
export default MainContainer;