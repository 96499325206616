import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import EnquiryInfo from './index';

const EnquiryModal = props => {
    const childRef = useRef();
    return (
        <>
            <Modal
                show={props.show}
                backdrop='static'
                backdropClassName={'overlay-modal active'}
                dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
            >
                <div className='modal-content modal-content-fullscreen'>
                    <Modal.Header className='modal-header-fullscreen'>
                        <div className='modal-title'>
                            {' '}
                            <Translate text={props.title} />
                            {/* <Translate text={enquiryInfo.lead ? 'addNewLead' : 'addNewEnquiry'} /> */}
                        </div>
                        <div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom mt-2 mr-2'>

                            <button type="button"
                                className="btn btn-common-fullscreen button-blue float-right"
                                onClick={(e) => { e.preventDefault(); childRef.current.handleSave(); }}>
                                <Translate text={'save'} />
                            </button>
                            {
                                props.isNewEnquiry
                                    ?
                                    <button type="button"
                                        className="btn btn-common-fullscreen btn-danger float-right ml-2"
                                        onClick={(e) => { e.preventDefault(); childRef.current.handleDeleteEnquiry(); }}>
                                        <Translate text={'delete'} />
                                    </button>
                                    :
                                    <></>
                            }

                        </div>

                    </Modal.Header>
                    <Modal.Body className='modal-body-fullscreen pt-0'>
                        {
                            props.show
                                ?
                                <div className='popup-wrap-fullscreen h-100'>
                                    <div className='container-fluid '>
                                        <EnquiryInfo {...props}
                                            history={props.history}
                                            isFromLayout={true}
                                            //showSave={showSave}
                                            ref={childRef}
                                            modalClose={props.handleClose}
                                        >
                                        </EnquiryInfo>
                                    </div>
                                </div>
                                :
                                <></>
                        }

                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
};

export default EnquiryModal;
