
/** LIBRARIES */
import React from "react";
/** COMPONENTS */
import { default as _images } from '../images';
import CommonHelper from '../services/common';

const { compose, withProps, lifecycle } = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    Polyline
} = require("react-google-maps");


const RouteMap = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyCSfc6kELIIrY-EJ-3pxXzMh2dojMNiyIc&libraries=places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    lifecycle({
        componentWillMount() {
            const { polylineCoordinates, dealersettings } = this.props;
            //console.log('RouteMap', polylineCoordinates)
            const refs = {};
            const arrCoords = [];
            let startLatLng = "";
            let endLatLng = "";
            polylineCoordinates.forEach(function (item, index) {
                if (index === 0) {
                    startLatLng = {
                        lat: item.geopoint.latitude ? item.geopoint.latitude : item.geopoint[0],
                        lng: item.geopoint.longitude ? item.geopoint.longitude : item.geopoint[1]
                    }; // add start postion
                }
                if (index === polylineCoordinates.length - 1) {
                    endLatLng = {
                        lat: item.geopoint.latitude ? item.geopoint.latitude : item.geopoint[0],
                        lng: item.geopoint.longitude ? item.geopoint.longitude : item.geopoint[1]
                    }; // add start postion
                }

                arrCoords.push({
                    lat: item.geopoint.latitude ? item.geopoint.latitude : item.geopoint[0],
                    lng: item.geopoint.longitude ? item.geopoint.longitude : item.geopoint[1]
                });
            });

            this.setState({
                bounds: null,
                polyLines: arrCoords,
                center: startLatLng,
                markers: [startLatLng, endLatLng],
                category: dealersettings?.client?.category,
                onMapMounted: ref => {
                    refs.map = ref;
                }
            });
        }

    }),
    withScriptjs,
    withGoogleMap
)(props => (

    <GoogleMap
        ref={map => {
            const bounds = new window.google.maps.LatLngBounds();
            props.polyLines.map(x => {
                return bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
            });
            map && map.fitBounds(bounds);
            props.onMapMounted(map);
        }}
        defaultZoom={8}
        center={props.center}
        defaultOptions={{
            mapTypeControl: false,
            // zoomControl: false,
            streetViewControl: false
        }}
    >
        <Polyline
            path={props.polyLines}
            options={{
                strokeColor: "#287ac6 ",
                strokeOpacity: 1,
                strokeWeight: 4
            }}
        />

        {
            props.markers
                ?
                props.markers.map((marker, index) => {
                    if (index === 0) return <Marker key={index} position={marker} />;
                    else return <Marker key={index} position={marker} icon={_images[CommonHelper.getNoVehicleIcons(props?.category, 'mapcar')]} />;
                })
                :
                <></>
        }
    </GoogleMap>
));

export default RouteMap;

