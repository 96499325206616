import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactSelect, PopUpModal } from '../../../../components';
import Translate from '../../../../constants/translate';
import Papa from 'papaparse'
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { Progress } from 'antd';
import toast from 'toasted-notes'
import CommonHelper from '../../../../services/common';
import Swal from 'sweetalert2'
import { useDropzone } from 'react-dropzone';
import moment from 'moment';

const ImportCSV = props => {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles: 1,
    });
    const [errorFields, setErrorFields] = useState({});
    const [targetsToBeSet, settargetsToBeSet] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [progress, setProgress] = useState(0);
    const [data, setData] = useState({
        name: '',
        size: '',
    });
    const [importLoader, setimportLoader] = useState(false)
    const [years, setYears] = useState([])
    const [state, setState] = useState({});
    const [types, setTypes] = useState({});


    useEffect(() => {
        let _years = []
        _years.push({
            label: moment().add(-1, 'year').format('YYYY'),
            value: moment().add(-1, 'year').format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().format('YYYY'),
            value: moment().format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().add(1, 'year').format('YYYY'),
            value: moment().add(1, 'year').format('YYYY'),
            active: true,
        })
        setYears(_years)

        const _types = [
            { value: 'target', label: 'Target' },
            //{ value: 'actual', label: 'Actual' }
        ]
        setTypes(_types)
    }, [])

    React.useEffect(() => {
        if (_.isEmpty(acceptedFiles))
            return

        const validateFiles = files => {
            // console.log('acceptedFiles', acceptedFiles)
            let filteredFiles = [];

            acceptedFiles.forEach(file => {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 10MB.'), '', 'info')
                } else {
                    filteredFiles.push(file)
                }
            })

            if (filteredFiles.length > 0) {
                onSelectFile(filteredFiles)
            }
        }

        validateFiles(acceptedFiles)
    }, [acceptedFiles])

    const handleFileRemove = () => {
        settargetsToBeSet([])
        setIsLoading(false)
        setIsFileUploaded(false)
        setProgress(0)
        setData({
            name: '',
            size: '',
        })
    };

    const onSelectFile = async (files) => {
        if (!files) {
            return
        }
        if (files && files.length > 0) {
            if (!files[0].name.match(/.(csv|xlsx|xls)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'File format not supported.'), 'Please upload valid file formats .csv, .xlsx, .xls only.', 'info')
                return;
            }
        }
        setIsLoading(true)
        let addedFile = files[0];
        let csvFile = files[0];

        // CONVERT EXCEL FILE TO CSV DATA
        if (addedFile.name.match(/.(xlsx|xls|xlsm)$/i)) {
            const _data = await addedFile.arrayBuffer();
            const workbook = XLSX.read(_data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            csvFile = XLSX.utils.sheet_to_csv(worksheet, {
                header: 1,
                defval: "",
            });
            //console.log("Data===>>>", csvFile, addedFile);
        }

        setData({ ...data, name: addedFile.name, size: addedFile.size })
        setIsFileUploaded(true)
        Papa.parse(csvFile, {
            complete: updateRecipientsWithCSVData,
            header: true
        });
    }

    const updateRecipientsWithCSVData = (result) => {
        if (!result) {
            return
        }
        var data = result.data;
        // const selectedContactsArr = data
        //     .map(contact => {
        //         return dataMappingVM(contact)
        //     });
        // if (data.length > 2000) {
        //     setFile(null)
        //     setIsFileUploaded(false)
        //     setData({
        //         name: '',
        //         size: '',
        //     })
        //     Swal.fire(CommonHelper.showLocale(props, 'A maximum of 2000 attendee(s) can be imported at once.'), '', 'info')
        //     return;
        // }

        settargetsToBeSet([...data]);
        setTimeout(() => {
            if (progress !== 100) {
                setProgress(progress + 50)
                setTimeout(() => {
                    setProgress(100)
                    setIsLoading(false)
                    //  toast.notify('File added successfully', {
                    //      duration: 2000
                    //  })
                }, 500)
            }

        }, 500)
    }

    const importTargets = () => {
        if (_.isEmpty(targetsToBeSet)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please upload file.'), '', 'info')
            return;
        }

        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(state.year)) {
            formIsValid = false;
            errors['year'] = errorClass;
        }
        if (_.isEmpty(state.type)) {
            formIsValid = false;
            errors['type'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        //console.log('import contact running');
        setimportLoader(true);
        const targetData = [];

        _.chain(targetsToBeSet).groupBy('Retailer').map((value, key) => ({ dealer: key, values: value })).value().forEach(r => {
            let _target = { [state.year]: {} };
            if (props?.dealersettings?.clients?.some(a => a.name.toLowerCase().includes(r?.dealer?.toLowerCase()))) {
                r.values.filter(a => (_.isEmpty(a.Model) || a.Model !== 'All Models') && a.Target).forEach(__target => {
                    moment.monthsShort().forEach(month => {
                        if (_.isEmpty(_target[state.year][month])) {
                            _target[state.year][month] = [];
                        }
                        _target[state.year][month].push({
                            calculationType: __target.Units === '%' ? 'percentage' : !_.isEmpty(__target.Units) ? 'amount' : 'count',
                            currency: __target.Units === '%' ? null : !_.isEmpty(__target.Units) ? __target.Units : null,
                            name: __target.Target,
                            ['target']: __target[month] ? Number(__target[month].replace(/\D/g, '')) : 0,
                            ['actual']: 0,
                            type: __target.Target === 'Retail' ? 'status' : null,
                            subType: __target.Target === 'Retail' ? 'delivered' : null,
                            model: __target.Model,
                            targetID: __target.Target.trim().toLowerCase().replace(/\s+/g, ""),
                        })
                    })
                })
                targetData.push({
                    ..._target,
                    clientID: props?.dealersettings?.clients?.filter(a => a.name.toLowerCase().includes(r?.dealer?.toLowerCase()))[0].id,
                    addedBy: localStorage.uid,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    isDeleted: false,
                    type: 'custom'
                })
            }
        })

        const batch = window.firebase.firestore().batch();
        targetData.forEach(rec => {
            batch.set(window.firebase.firestore().doc(`targets/retailer${rec.clientID}`),
                {
                    ...rec,
                    documentID: `retailer${rec.clientID}`
                }, { merge: true })
        })
        batch.commit()
            .then(snapshot => {
                setimportLoader(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Target Uploaded successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
                props.handleClose(true)
            })

    }

    const handleReactSelectChange = (e, data) => {
        setState({
            ...state,
            [data.name]: e?.value || null
        })

        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const baseStyle = {
        width: '100%',
        background: '#f2f7ff',
        border: '2px dashed #a7cbff',
        minHeight: '180px',
        float: 'left',
        textDecoration: 'none',
        color: '#4466f2',
    };

    const activeStyle = {
        border: '2px dashed #2bb673',
    };

    const acceptStyle = {
        borderColor: '#2bb673',
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = React.useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop='static'
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName='modal-dialog-centered modal-file-manager'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className='modal-title'>
                            {' '}
                            <Translate text={'Import Excel'} />
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='upload-file-box'>
                                <div {...getRootProps({ style, className: 'upload-iconwrap upload-box-dragndrop' })}>
                                    <input {...getInputProps()} />
                                    <i className="ico icon-files-upload"></i>
                                    <p>Drag 'n' drop excel file here, or click to upload file</p>
                                </div>

                                {isFileUploaded ? (
                                    <div className='uploaded-list-video'>
                                        <div className='flex-upload'>
                                            <div className='upload-clear'>
                                                <a
                                                    href='#'
                                                    onClick={e => { e.preventDefault(); handleFileRemove() }}
                                                //className={`${isLoading ? 'form-disable' : ''}`}
                                                >
                                                    <i className='fa fa-times' aria-hidden='true'></i>
                                                </a>
                                            </div>
                                            <div className='file-upload-icon'>
                                                <i className="ico icon-files-excel"></i>
                                            </div>
                                            <div className='upload-data'>
                                                {data.name}
                                                {' (' + CommonHelper.showFileSize(data.size) + ')'}
                                            </div>
                                        </div>
                                        {isLoading ? <Progress percent={progress} /> : <></>}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="pop-layout form-style mt-2">

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label > <Translate text={'Type'} /></label>
                                <ReactSelect
                                    options={types}
                                    name={"type"}
                                    placeholder={'select type'}
                                    onChange={handleReactSelectChange}
                                    value={state.type}
                                    classNamePrefix={`${errorFields["type"]} basic-select`}
                                >
                                </ReactSelect>
                            </div>

                            <div className="form-group col-md-6">
                                <label > <Translate text={'Years'} /></label>
                                <ReactSelect
                                    options={years}
                                    name={"year"}
                                    placeholder={'select year'}
                                    onChange={handleReactSelectChange}
                                    value={state.year}
                                    classNamePrefix={`${errorFields["year"]} basic-select`}
                                >
                                </ReactSelect>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-footer'>
                    <button
                        type='button'
                        className={`btn btn-primary float-right ${importLoader ? 'btn-disable' : ''}`}
                        onClick={(e) => { e.preventDefault(); importTargets(); }}
                    >
                        {
                            importLoader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'Import'} />
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImportCSV;
