/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox, AntDateRangePicker,
    ReactSelect, InputText, ReactMultiSelect
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'

//#region  ASSIGN VARIABLES

const _status = [
    { active: true, value: 'pending', name: 'Pending', label: <div className={`badge badge-pill status-pending`}><Translate text={'pending'} upperCase={true} /></div> },
    { active: true, value: 'complete', name: 'Complete', label: <div className={`badge badge-pill status-completed`}><Translate text={'complete'} upperCase={true} /></div> },

]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    //{ active: true, value: 'addedDate', label: 'Created Date' },
    //{ active: true, value: 'modifiedDate', label: 'Modified Date' },
    { active: true, value: 'startDate', label: 'Start Date' },
    { active: true, value: 'endDate', label: 'End Date' },
    { active: true, value: 'expectedReturn', label: 'Expected Return' }
]

const loanTypes = [
    { active: true, value: 'contact', label: 'Customer' },
    { active: true, value: 'staff', label: 'Staff' }
]

const objDateField = {
    name: null,
    type: null,
    range: null
}

let groupClients = [];
//#endregion
const TestDriveAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [years, setYears] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const { columnFields, dealersettings } = props;
    const stockLocations = dealersettings?.client?.clientSettings?.stockLocations;
    useEffect(() => {
        const { fleetFilter } = props;

        if (!_.isEmpty(fleetFilter))
            setFilterFields(fleetFilter.value);
        else
            setFilterFields({});

    }, [props.fleetFilter])

    useEffect(() => {
        const { dealersettings, clientUsers, fleetFilter } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            const brands = dealersettings.allMakeModels;
            let _fields = !_.isEmpty(fleetFilter) ? fleetFilter.value : {};
            const _years = [];
            const _makes = [];

            groupClients = []
            if ((localStorage.defaultModule === 'oem') && dealersettings?.group?.clients)
                groupClients = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: dealersettings.group.clients[cid].name } })

            if (!_.isEmpty(stockLocations) && !_.isEmpty(dealersettings.clients))
                groupClients = _.map(dealersettings.clients.filter(m => stockLocations.includes(m.documentID)), function (e) { return { label: e.name, value: e.documentID } });

            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                _years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });


            if ((!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make)) {
                const models = [];
                let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
                setModels(models);
            }

            const _clientUsers = [];
            if (clientUsers) {
                clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                    });
                });
            }

            setClientUsers(_clientUsers);
            setMakes(_makes);
            setYears(_years);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }
    }

    // const handleMultiSelectChange = (selectedOptions, data) => {
    //     let value = '';
    //     if (!_.isEmpty(selectedOptions)) {
    //         selectedOptions.map((data) => {
    //             value = (value ? value + ',' : '') + (data.value)
    //         })
    //     }

    //     let state = Object.assign({}, filterFields);
    //     state[data.name] = value;
    //     setFilterFields(state);
    // }

    // const handleSwitchChange = (e, name) => {
    //     const { checked } = e.target;
    //     let state = Object.assign({}, filterFields);
    //     state[name] = checked;
    //     setFilterFields(state);
    // }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const models = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.forEach((data) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
            setModels(models);
            state[data.name] = e.value;
            if (state.hasOwnProperty('model'))
                state['model'] = null;
            setFilterFields(state)
        }
        else {
            state[data.name] = e.value;
            setFilterFields(state)
        }
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        let state = Object.assign({}, filterFields);
        state[data.name] = value;
        setFilterFields(state);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (name === 'make') {
                state[name] = null;
                //state['model'] = null;
            }
            else if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'make') {
                delete state['model'];
            }
        }
        setFilterFields(state);
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.fleetFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }
    const handleApply = () => {

        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.fleetFilter) && !_.isEmpty(props.fleetFilter.value) && JSON.stringify(props.fleetFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.fleetFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }

    }

    const handleSave = () => {
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };
    //#endregion

    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader"> <Translate text={'Loan Vehicle'} /></div>

                <div className="advanced-filter-list">

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(groupClients) && !_.isEmpty(columnFields) && columnFields.indexOf('clientID') >= 0)
                            ?
                            <>
                                {/* clientID */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'clientID'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'clientID'}><Translate text={!_.isEmpty(stockLocations) ? 'stockLocations' : 'dealerships'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={_.orderBy(groupClients, ['label'], ['asc'])}
                                                        name={"clientID"}
                                                        placeholder={!_.isEmpty(stockLocations) ? 'select locations' : 'select dealer'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('clientID') && !_.isEmpty(filterFields.clientID) ? filterFields.clientID.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('addedBy') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'addedBy'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('addedBy')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'addedBy'}><Translate text={'owner'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('addedBy')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={clientUsers}
                                                        name={"addedBy"}
                                                        placeholder={'select owner'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('addedBy') && !_.isEmpty(filterFields.addedBy) ? filterFields.addedBy.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('staffID') >= 0)
                            ?
                            <>
                                {/* staffID */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'staffID'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('staffID')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'staffID'}><Translate text={'staff'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('staffID')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={clientUsers}
                                                        name={"staffID"}
                                                        placeholder={'select loaned staff'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('staffID') && !_.isEmpty(filterFields.staffID) ? filterFields.staffID.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('loanedBy') >= 0)
                            ?
                            <>
                                {/* loanedBy */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'loanedBy'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('loanedBy')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'loanedBy'}><Translate text={'loanedBy'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('loanedBy')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={loanTypes}
                                                        name={"loanedBy"}
                                                        placeholder={'select loaned by'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('loanedBy') ? filterFields.loanedBy : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0)
                            ?
                            <>
                                {/* status */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'status'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'status'}><Translate text={'status'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={_status}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('status') ? filterFields.status : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('make') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'make'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'make'}><Translate text={'make&model'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('make') ? filterFields.make : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('model') ? filterFields.model : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('year') >= 0)
                            ?
                            <>
                                {/* year */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'year'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('year')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'year'}><Translate text={'year'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('year')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={years}
                                                        name={"year"}
                                                        placeholder={'select vehicle year'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('year') ? filterFields.year : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                </div>

            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            {props.noSaveAccess ? <></> : <><button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button></>}
                            <button type="button" className="btn btn-default float-left ml-2" onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }



            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.fleetFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'fleet'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default TestDriveAdvanceFilters;