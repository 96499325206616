/** LIBRARIES */
import React, { Component } from "react";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class ReactMonthPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value !== this.props.value;
    }

    onDatepickerRef = (el) => { if (el && el.input) { el.input.readOnly = true; } }

    render() {
        const { name, onChange, placeholder, value, className } = this.props;
        //console.log('RENDER - React Month Picker', name, value)
        return (
            <DatePicker
                ref={el => this.onDatepickerRef(el)}
                placeholderText={placeholder}
                selected={value ? moment(value)._d : null}
                onChange={date => onChange(date, name)}
                dateFormat={"MMM yyyy"}
                className={className}
                showMonthYearPicker
                popperClassName="custom-month-pop"
                minDate={moment().startOf('month')._d}
            />
        );
    }
}