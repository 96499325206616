import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';

const Report = ({ setActiveTab, campaign, activeTab, }) => {
  const [activeSubTab, setActiveSubTab] = useState('overview')

	return (
		<>
			<div className='campaign-view-wrapper-head'>
				<div className='float-left'>
					<div className='campaign-add-title'>{campaign.settings.title} </div>
					<div className='campaign-add-sub-title'>
						<div className='badge badge-pill badge-available-fill float-left'>
							SENT
						</div>
						<span className='float-left campaign-add-date'>
							{' '}
							Delivered on{' '}
							{moment(campaign.send_time).format('DD/MM/YYYY hh:mm A')}{' '}
						</span>
					</div>
				</div>
				<div className='float-right campaign-tab-align'>
					<div className='campaign-tab-switch center-fix'>
						<ul className='campaign-tabs-list'>
							<li
								className='campaign-tab-item '
								onClick={() => setActiveTab('detail')}
							>
								Campaign Details
							</li>
							<li
								className='campaign-tab-item campaign-tab-active'
								onClick={() => setActiveTab('report')}
							>
								Report
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='campaign-view-wrapper' style={{ minHeight: '800px' }}>
				<div className='tabs'>
					<div className='tabs-wraper'>
						<nav>
							<Tabs
								transition={false}
								className='nav-fill'
								defaultActiveKey={activeSubTab}
								onSelect={k => setActiveSubTab(k)}
							>
								<Tab eventKey='overview' title='Overview'>
									<div className='campaign-view-tab-header'>
										<div className='float-left'>
											<h2>Campaign Overview</h2>
										</div>
									</div>

									<div className='campaign-view-tab-middle mt-3'>
										<div className='row text-center'>
											<div className='col'>
												<div className='campaign-counter'>
													<h2 className='campaign-count-title'>
														{campaign.recipients.recipient_count}
													</h2>
													<p className='campaign-count-text '> Recipients</p>
												</div>
											</div>

											<div className='col'>
												<div className='campaign-counter'>
													<h2 className='campaign-count-title '>
														{campaign.report_summary.opens}
													</h2>
													<p className='campaign-count-text '>Total opens</p>
												</div>
											</div>
											
											<div className='col'>
												<div className='campaign-counter'>
													<h2 className=' campaign-count-title campaign-count-number'>
														{campaign.report_summary.unique_opens}
													</h2>
													<p className='campaign-count-text '>Unique Opens</p>
												</div>
											</div>

											<div className='col'>
												<div className='campaign-counter'>
													<h2 className='campaign-count-title '>
														{campaign.report_summary.clicks}
													</h2>
													<p className='campaign-count-text '>Clicked</p>
												</div>
											</div>

											
										</div>

										<div className='common-table mt-4'>
											<div className='w-50 float-left'>
												<table className='table table-bordered'>
													<tbody>
														<tr>
															<th className='head-light' width='30%'>
																Successful deliveries
															</th>
															<td width='70%'>{campaign.emails_sent}</td>
														</tr>
														<tr>
															<th className='head-light'>Total opens</th>
															<td>{campaign.report_summary.opens}</td>
														</tr>

														{/* <tr>
															<th className='head-light'>Last opened</th>
															<td>N/A</td>
														</tr> */}
														{/* <tr>
																					<th className='head-light'>Forwarded</th>
																					<td>0</td>
																				</tr> */}
													</tbody>
												</table>
											</div>
											<div className='w-50 float-left'>
												<table className='table table-bordered'>
													<tbody>
														<tr>
															<th className='head-light' width='30%'>
																Clicks per unique opens
															</th>
															<td width='70%'>
																{campaign.report_summary.unique_opens}
															</td>
														</tr>
														<tr>
															<th className='head-light'>Total clicks</th>
															<td>{campaign.report_summary.clicks}</td>
														</tr>

														{/* <tr>
															<th className='head-light'>Last clicked</th>
															<td>N/A</td>
														</tr> */}
														{/* <tr>
																					<th className='head-light'>
																						Abuse reports
																					</th>
																					<td>0</td>
																				</tr> */}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</Tab>
								{/* <Tab eventKey='Activity' title='Activity'>
                            {' '}
															content here{' '}
                            </Tab> */}
							</Tabs>
						</nav>
					</div>
				</div>
			</div>
		</>
	);
};

export default Report;
