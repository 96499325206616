import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { SketchPicker } from 'react-color';
import uuid from 'react-uuid';

const LineThicknessEditor = (props) => {
    const [item, setItem] = useState(props.item);
    const [colorPicker, setColorPicker] = useState(false)
    const newItem = _.isEmpty(props.item) ? true : false;
    const styles = {
        colorPicker: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background:
                !_.isEmpty(item) && item.color
                    ? item.color
                    : '#000000',
        },
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    }

    useEffect(() => {
        if (_.isEmpty(props.item)) {
            setItem({
                id: uuid(),
                thickness: 2,
                type: 'line',
                defaultPos: { x: Math.round(props.displayWidth / 2), y: Math.round(props.displayHeight / 2) },
                color: '#000000',
                width: 200
            })
        }
    }, [])

    const handleChange = e => {
        const { name, value } = e.target;
        setItem({
            ...item,
            [name]: Number(value)
        })
    };

    return _.isEmpty(item) ? (<></>) : <Modal
        show={props.show}
        onHide={() => { props.handleClose(newItem ? null : item, newItem) }}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-customline-pop">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={'Border Thickness'} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="custom-brdr-line-wraper w-100">
                <div className="w-100 d-inline-block tv-campaign-label">Border Thickness</div>
                <div onClick={() => { setItem({ ...item, thickness: 2 }) }} className={`custom-brdr-line-loop cursor-pointer ${item.thickness === 2 ? 'selected' : ''}`}> 2px <span className="custom-brdr-line-2px"></span></div>
                <div onClick={() => { setItem({ ...item, thickness: 4 }) }} className={`custom-brdr-line-loop cursor-pointer ${item.thickness === 4 ? 'selected' : ''}`}> 4px <span className="custom-brdr-line-4px"></span></div>
                <div onClick={() => { setItem({ ...item, thickness: 6 }) }} className={`custom-brdr-line-loop cursor-pointer ${item.thickness === 6 ? 'selected' : ''}`}> 6px <span className="custom-brdr-line-6px"></span></div>
                <div onClick={() => { setItem({ ...item, thickness: 8 }) }} className={`custom-brdr-line-loop cursor-pointer ${item.thickness === 8 ? 'selected' : ''}`}> 8px <span className="custom-brdr-line-8px"></span></div>
                <div onClick={() => { setItem({ ...item, thickness: 10 }) }} className={`custom-brdr-line-loop cursor-pointer ${item.thickness === 10 ? 'selected' : ''}`}> 10px <span className="custom-brdr-line-10px"></span></div>
            </div>
            <div className="mt-3 w-100 d-inline-block">

                <div className="form-row ">
                    <div className="form-group col-md-8 mb-0">
                        <label className="tv-campaign-label">Custom Border Thickness </label>
                        <input
                            type="text"
                            autoComplete="off"
                            placeholder={'thickness'}
                            pattern="[1-9]*"
                            className={`form-control `}
                            name="thickness"
                            onChange={handleChange}
                            value={_.toString(item.thickness)}
                        />
                    </div>
                    <div className="form-group col-md-4 mb-0">
                        <label className="tv-campaign-label">Border Color </label>
                        <div className="w-100 d-inline-block">
                            <div>
                                <div
                                    style={styles.swatch}
                                    onClick={() => {
                                        setColorPicker(!colorPicker);
                                    }}
                                >
                                    <div
                                        className='custom-color-picker-form-template'
                                        style={styles.colorPicker}
                                    />
                                </div>
                                {colorPicker ? (
                                    <div style={styles.popover}>
                                        <div
                                            style={styles.cover}
                                            onClick={() => {
                                                setColorPicker(false);
                                            }}
                                        />
                                        <SketchPicker
                                            color={
                                                !_.isEmpty(item) && item.color
                                                    ? item.color
                                                    : '#000000'
                                            }
                                            onChange={color =>
                                                setItem({
                                                    ...item,
                                                    color: color.hex
                                                })
                                            }
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={(e) => {
                props.handleClose(item, newItem);
            }
            }>
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose(newItem ? null : item, newItem);
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>;
}

export default LineThicknessEditor;