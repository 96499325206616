import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import { MainContext } from '../../layout/provider';
import List from './list';

const OEMStock = ({ title, show, handleClose }) => {
	const { clientUsers, dealersettings, updateDealerSettings, groupUsers } = useContext(MainContext)

	return ( 
		<Modal 
			id='add-cafe'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
		>
			<Modal.Header className='modal-header-fullscreen'>
				<div className='modal-title'>
					{' '}
					<Translate text={`Add from OEM Stock`} />
				</div>
				<button
						type='button'
						className='close close-fullscreen float-right'
						onClick={handleClose}
				>
						×
				</button>
			</Modal.Header>
			<Modal.Body className='modal-body-fullscreen'>
				<div className='popup-wrap-fullscreen h-100'>
					<div className='container-fluid'>
						<div className='w-100 d-inline-block mt-0'>
							<List
								clientUsers={clientUsers}
								groupUsers={groupUsers}
								dealersettings={dealersettings}
								updateDealerSettings={updateDealerSettings}
								handleClose={handleClose}
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default OEMStock;
