/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** PROVIDERS */
/** VIEW-MODELS */

/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { PopUpModal, PopoverListLoader } from '../../../components';

import { default as _images } from '../../../images';

import TIPHistory from '../info';

import ContactListReducer from '../../contacts/contactlistReducer'
import { firestoreDB } from '../../../services/helper';

const TradeinProList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [contacts, dispatch] = useReducer(ContactListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [hasMore, setHasMoreData] = useState([])

    const [isPaging, setPaging] = useState(false)
    const [contactLoader, setContactLoader] = useState(true)

    const pageLimit = 20

    const [titles, setTitles] = useState([])


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubContactList && window.unSubContactList();
            //console.log('pageUNLoad', myContact, props.dealersettings.client.id, searchText, hasMore)
        }
    }, [])

    useEffect(() => {
        const { dealersettings, contactID } = props;

        if (!checkDataLoad) {
            return;
        }

        if (!contactID) {
            setDataload(false)
            setContactLoader(false)
            setPaging(false)
            return;
        }

        let contactlstIdxDataRef = firestoreDB(dealersettings).firestore().collection('tradeinPro')

        contactlstIdxDataRef = contactlstIdxDataRef
            .where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
            .where('isContact', '==', true)
            .where('contact.documentID', '==', contactID)
            .where('isDeleted', '==', false)

        if (_.trim(searchText) && _.trim(searchText).length > 2) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
            if (formattedSearchText.trim())
                contactlstIdxDataRef = contactlstIdxDataRef
                    .where('keywords', 'array-contains', formattedSearchText.trim().toLowerCase())
        }


        //contactlstIdxDataRef = contactlstIdxDataRef.orderBy('firstName', 'asc')

        if (hasMore.length > 0) {
            contactlstIdxDataRef = contactlstIdxDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            contactlstIdxDataRef = contactlstIdxDataRef
                .limit(pageLimit)
        }

        contactlstIdxDataRef
            //.onSnapshot(onCollectionUpdate);
            .get().then(querySnapshot => {
                const _contacts = [];

                if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                    setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
                }
                else {
                    setHasMoreData([]);
                }
                querySnapshot.docs.forEach((doc) => {
                    _contacts.push(convertContactVM({
                        ...doc.data(),
                        documentID: doc.id
                    }));
                });

                if (isPaging) {
                    dispatch({
                        type: "APPENDCONTACT",
                        data: _contacts,
                        sortName: 'firstName',
                        sortOrder: 'asc'
                    });
                }
                else {
                    dispatch({
                        type: "SUCCESS",
                        data: _contacts,
                        sortName: 'firstName',
                        sortOrder: 'asc'
                    });
                }

                setDataload(false)
                setContactLoader(false)
                setPaging(false)

            });
        //  return () => {
        //      window.unSubContactList();
        //  }

    }, [checkDataLoad])



    const convertContactVM = (doc) => {
        const objcontactData = Object.assign({}, doc);
        const { dealersettings } = props;
        const contact = Object.assign({});
        for (let [key, value] of Object.entries(objcontactData)) {
            contact[key] = value;
        }

        if (contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule !== 'tradeinPro')) {
            contact.dealerName = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);
        }

        return contact
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }


    const listenScrollEvent = (e) => {
        if (hasMore.length === 0) return;
        //console.log('listenScrollEvent', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)
        if (e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight) {
            //console.log('listenScrollEvent')
            //handlePagination();
        }
        return;
    }

    const handleTIPClose = (_contactID, _contact) => {
        props.handleClose(_contactID, _contact);

    }


    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative"
    };

    return (
        <>

            <Modal
                id="appraisal-List"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-terms">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'tradeIn'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className={`vehicle-popover-head pr-0`}>
                            <input
                                type="text"
                                aria-label="Search"
                                placeholder="Search..."
                                value={searchText ? searchText : ''}
                                autoComplete="off"
                                onChange={e => {
                                    let _value = e.target.value;
                                    if (!_value)
                                        setSearchText('');
                                    else
                                        setSearchText(_value);

                                    if (_.trim(_value) && _.trim(_value).length > 2)
                                        setContactLoader(true);

                                    setTimeout(() => {
                                        setHasMoreData([]);
                                        setDataload(true);
                                    }, 1000);


                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchText(searchText)
                                        setHasMoreData([]);
                                        setDataload(true)
                                        setContactLoader(true)
                                    }
                                }}
                            />

                        </div>

                        <Suspense>
                            <div style={divStyle} onScroll={listenScrollEvent}>
                                {
                                    contactLoader ? (<PopoverListLoader />) :
                                        (contacts && contacts.length) > 0
                                            ?
                                            <>
                                                {contacts.map((tradein, index) =>
                                                    <div key={tradein.documentID} id={tradein.documentID} className="vehicle-popover-loop pb-0" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleTIPClose(tradein.documentID, tradein)

                                                    }}>
                                                        <div className="divTable">
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell width100 pb-0">
                                                                        <TIPHistory
                                                                            key={index}
                                                                            fields={tradein}
                                                                            clientUsers={props.clientUsers}
                                                                            groupUsers={props.groupUsers}
                                                                            sidepanelOpen={() => { }}
                                                                            history={props.history}
                                                                            showMore={false}
                                                                            companyView={true}
                                                                            isList={true}
                                                                            dealersettings={props.dealersettings}
                                                                        ></TIPHistory>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}</>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, "ico icon-tradein-module-icon")}></i> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'noTradeinFound'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div>
                        </Suspense>

                    </>
                </Modal.Body>
                {
                    hasMore && hasMore.length > 0 ? (
                        <Modal.Footer >
                            <div className="load-more-button pb-2"> <a href="#" className="mt-1" onClick={(e) => {
                                e.preventDefault();
                                handlePagination();
                            }}><Translate text={'Load More'} />
                                {
                                    !_.isEmpty(checkDataLoad) ? (
                                        <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                    ) : (<i className="fa fa-angle-down" aria-hidden="true"></i>)
                                }

                            </a></div>
                        </Modal.Footer>

                    ) : (<></>)
                }
            </Modal>


        </>
    )
}

export default TradeinProList