import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import images, { default as _images } from '../../../images';
import DisplayReportContacts from './list/displayContacts'
import { PopUpModal } from '../../../components';

const Report = ({ setActiveTab, campaign, refresh_token, client_id, client_secret, apiUrl, timezone }) => {
	const [activeSubTab, setActiveSubTab] = useState('overview');
	const [displayRecipients, setDisplayRecipients] = useState({
		show: false,
		title: '',
		type: ''
	});
	//console.log(data, 'data')

	return (
		<>
			<div className='campaign-view-wrapper-head'>
				<div className='float-left'>
					<div className='campaign-add-title'>{campaign ? campaign.title : ''} </div>
					<div className='campaign-add-sub-title'>
						<div className={`badge badge-pill float-left badge-${_.toLower(campaign.status) === 'sent'
							? 'available'
							: (_.toLower(campaign.status) === 'pending' || _.toLower(campaign.status) === 'sending')
								? 'pending'
								: (_.toLower(campaign.status) === 'failed' || _.toLower(campaign.status) === 'cancelled')
									? 'draft'
									: 'draft'
							}-fill `}
						>
							{campaign.status ? campaign.status : ''}
						</div>
						{
							campaign.DeliveredAt ? (
								<span className='float-left campaign-add-date'>
									{' '}
									Delivered on{' '}
									{moment(campaign.DeliveredAt).tz(timezone).format('DD/MM/YYYY hh:mm A')}{' '}
								</span>
							) : (
								<span className='float-left campaign-add-date'>
									{' '}
									Created on{' '}
									{moment(campaign.CreatedAt).tz(timezone).format('DD/MM/YYYY hh:mm A')}{' '}
								</span>
							)
						}
					</div>
				</div>
				<div className='float-right campaign-tab-align'>
					<div className='campaign-tab-switch center-fix'>
						<ul className='campaign-tabs-list'>
							<li
								className='campaign-tab-item '
								onClick={() => setActiveTab('detail')}
							>
								Campaign Details
							</li>
							<li
								className='campaign-tab-item campaign-tab-active'
								onClick={() => setActiveTab('report')}
							>
								Report
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='campaign-view-wrapper' style={{ minHeight: '800px' }}>
				<div className='tabs'>
					<div className='tabs-wraper'>
						<nav>
							<Tabs
								transition={false}
								className='nav-fill'
								defaultActiveKey={activeSubTab}
								onSelect={k => setActiveSubTab(k)}
							>
								<Tab eventKey='overview' title='Overview'>
									<div className='campaign-view-tab-header'>
										<div className='float-left'>
											<h2>Campaign Overview</h2>
										</div>
									</div>








									<div className="campaign-view-tab-middle mt-3">

										<div className="card campaign-custom-card">
											<div className="card-header">
												Contact Engagement
											</div>
											<div className="card-body">
												<div className="row text-center mb-3">
													<div className="col">
														<div className="campaign-counter">
															<div className="campaign-count-icon"><img src={images.campaignOpen} alt="" height="70" /></div>
															<p className="campaign-count-text "> Opened</p>
															<h2 className="campaign-count-title" >{campaign?.overview?.MessageOpenedCount || 0}</h2>

														</div>
													</div>

													<div className="col">
														<div className="campaign-counter">
															<div className="campaign-count-icon"><img src={images.campaignClick} alt="" height="70" /></div>
															<p className="campaign-count-text ">Clicked</p>
															<h2 className="campaign-count-title " >{campaign?.overview?.MessageClickedCount || 0}</h2>

														</div>
													</div>

													<div className="col">
														<div className="campaign-counter">
															<div className="campaign-count-icon"><img src={images.campaignUnSubscribed} alt="" height="70" /></div>
															<p className="campaign-count-text ">Unsubscribed</p>
															<h2 className="campaign-count-title " >{campaign?.overview?.MessageUnsubscribedCount || 0}</h2>

														</div>
													</div>



													<div className="col">
														<div className="campaign-counter">
															<div className="campaign-count-icon"><img src={images.campaignComplain} alt="" height="70" /></div>
															<p className="campaign-count-text ">Marked as Spam</p>
															<h2 className=" campaign-count-title campaign-count-number" >{campaign?.overview?.MessageSpamCount || 0}</h2>

														</div>
													</div>






												</div>


											</div>
										</div>


									</div>


									<div className="campaign-sub-section-div mt-4">

										<div className="row">

											<div className="col-sm-6">
												<div className="card campaign-custom-card h-100">
													<div className="card-header ">
														Delivery Info
													</div>
													<div className="card-body">
														<div className="card-group">
															<div className="card ">

																<div className="card-body">
																	<div className="campaign-card-icon"><img src="images/campaign-email-sent.svg" alt="" height="50" /></div>
																	<div className="campaign-card-info">
																		<h5 className="card-title">Emails Total</h5>
																		<p className="card-text">
																			<span className="campaign-percentage">{campaign?.overview?.MessageSentCount || 0}</span>

																		</p>
																	</div>

																</div>
															</div>
															<div className="card">

																<div className="card-body">
																	<div className="campaign-card-icon"><img src="images/campaign-email-delivered.svg" alt="" height="50" /></div>
																	<div className="campaign-card-info">
																		<h5 className="card-title">Delivered </h5>
																		<p className="card-text">
																			<span className="campaign-percentage">{campaign?.perCalc?.delivered} </span>
																			<span className="campaign-fraction">{campaign?.overview?.Total || 0} Emails </span>

																		</p>
																	</div>

																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="card campaign-custom-card h-100">
													<div className="card-header ">
														Delivery Rates
													</div>
													<div className="card-body">
														<div className="card-group">
															<div className="card">

																<div className="card-body">
																	<h5 className="card-title">Blocked</h5>
																	<p className="card-text">
																		<span className="campaign-percentage">{campaign?.perCalc?.blocked}</span>

																	</p>

																</div>
															</div>
															<div className="card">

																<div className="card-body">
																	<h5 className="card-title">Soft-Bounced</h5>
																	<p className="card-text">
																		<span className="campaign-percentage">{campaign?.perCalc?.softbounced}</span>

																	</p>

																</div>
															</div>
															<div className="card">

																<div className="card-body">
																	<h5 className="card-title">Hard-Bounced</h5>
																	<p className="card-text">
																		<span className="campaign-percentage">{campaign?.perCalc?.hardbounced}</span>

																	</p>

																</div>
															</div>
															<div className="card">

																<div className="card-body">
																	<h5 className="card-title">Retrying</h5>
																	<p className="card-text">
																		<span className="campaign-percentage">{campaign?.perCalc?.retrying}</span>

																	</p>

																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</Tab>
							</Tabs>
						</nav>
					</div>
				</div>
			</div>
			<PopUpModal show={displayRecipients.show}>
				<DisplayReportContacts
					{...displayRecipients}
					handleClose={() => { setDisplayRecipients({ show: false, title: '', type: '' }) }}
					campaign={campaign}
					refresh_token={refresh_token}
					client_id={client_id}
					client_secret={client_secret}
					apiUrl={apiUrl}
				/>
			</PopUpModal>
		</>
	);
};

export default Report;
