import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Translate from '../../constants/translate';
import { reportDynColumns, reportFilters } from './viewModel'
import CommonHelper from '../../services/common'

const RearrangeColumns = (props) => {
    const [columns, setColumns] = useState(props.type === 'column' ? props.columns : props.type === 'model' ? props.report.model : props.filters);
    const [loader, setLoader] = useState(false)

    const saveColumns = () => {
        if (props.type === 'column')
            props.setColumns(columns)
        if (props.type === 'model')
            props.setReport('model', columns)
        else
            props.setFilters(columns)
        props.handleClose();
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const [removed] = columns.splice(result.source.index, 1);
        columns.splice(result.destination.index, 0, removed);
        setColumns(columns)
    }



    return _.isEmpty(columns) ? (<></>) : (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-rearrange-report"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid" >
                <div className="row " style={{ height: `${(columns.length * 34) + 30}px` }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="rearrange-report-wrap">
                                    {
                                        columns.map((rec, index) => {
                                            return <Draggable key={rec} draggableId={rec} index={index}>
                                                {(provided, snapshot) => (<div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="report-rearrange-box report-rearrange-flex-queue">
                                                    <div className="report-rearrange-queue-drag"> <i className="ico icon-drag"></i></div>
                                                    <div className="report-rearrange-detail">
                                                        <h3> {
                                                            props.type === 'column' ?
                                                                CommonHelper.getLabelByValue(props.reportColumns, rec)
                                                                : CommonHelper.getLabelByValue(reportFilters, rec)
                                                        } </h3>
                                                    </div>
                                                </div>
                                                )}
                                            </Draggable>
                                        })
                                    }
                                    <span
                                        style={{
                                            display: "none"
                                        }}
                                    >
                                        {provided.placeholder}
                                    </span>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveColumns(e)}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>
    );
}

export default RearrangeColumns;