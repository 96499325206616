/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import {
    objContact
} from '../../contacts/viewModel';
import { firestoreDB } from '../../../services/helper';
//#region  ASSIGN VARIABLES
let titles = [];
//#endregion
export default class NewSMS extends Component {
    constructor(props) {
        super(props);
        this.isLoading = true;
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.refNotifications = window.firebase.firestore().collection('notifications');

        this.state = {
            fields: Object.assign({}),
            contact: Object.assign({}),
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadEnquiry();
    }


    async loadEnquiry() {
        const { docID, dealersettings, notification } = this.props;

     //   console.log('loadEnquiry', docID, dealersettings, notification)
        if (docID) {


            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
                const setttings = dealersettings.client.settings;

                titles = [];
                setttings.titles && setttings.titles.forEach((doc) => {
                    titles.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }

            let contact = Object.assign({}, objContact);
            let fields = Object.assign({});
            if (!_.isEmpty(notification)) {
                fields = Object.assign({}, notification);
                if (fields.contactID && _.isEmpty(fields['contact'])) {
                    const refContactData = await this.refContact.doc(fields.contactID).get();
                    if (refContactData.exists) {
                        const objcontactData = Object.assign({}, refContactData.data());
                        for (let [key, value] of Object.entries(objcontactData)) {
                            contact[key] = value;
                        }
                        contact.documentID = fields.contactID;
                    }
                }
                else if (fields.contactID && !_.isEmpty(fields['contact'])) {
                    contact = Object.assign({}, fields['contact']);
                }
                this.isLoading = false;
                this.setState({
                    fields: fields,
                    contact: contact
                });

            }
            else {
                const refData = await this.refNotifications.doc(docID).get();

                if (refData.exists) {

                    const _objEnquiry = Object.assign({}, refData.data());
                    for (let [key, value] of Object.entries(_objEnquiry)) {
                        fields[key] = value;
                    }
                    fields.documentID = refData.id;

                    if (fields.contactID && _.isEmpty(fields['contact'])) {
                        const refContactData = await this.refContact.doc(fields.contactID).get();
                        if (refContactData.exists) {
                            const objcontactData = Object.assign({}, refContactData.data());
                            for (let [key, value] of Object.entries(objcontactData)) {
                                contact[key] = value;
                            }
                            contact.documentID = fields.contactID;
                        }
                    }
                    else if (fields.contactID && !_.isEmpty(fields['contact'])) {
                        contact = Object.assign({}, fields['contact']);
                    }
                    this.isLoading = false;
                    this.setState({
                        fields: fields,
                        contact: contact
                    });


                }
            }



        }
    }


    handleSubmit = (e) => {
        e.preventDefault();

        const { fields } = this.state;

        this.props.setNotifyClose(true)
        this.props.handleClose(fields)

        if (fields.enquiryID)
            this.props.history.push("/enquiry/details/" + fields.enquiryID + '?comments=' + fields.recordID);
        else if (fields.contactID)
            this.props.history.push("/contacts/details/" + fields.contactID + '?comments=' + fields.recordID);

    };


    render() {
        const { dealersettings, clientUsers } = this.props;
        const { fields, contact } = this.state;
    //    console.log('fields, contact', fields, contact)
        return (
            this.isLoading
                ?
                <></> :
                <>
                    <Modal
                        show={this.props.show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-newlead-assigned">
                        <Modal.Header >
                            <Modal.Title>
                                {this.props.title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <div className="newlead-assigned-head">
                                        <div className="vehicle-status-alert alert-user">
                                            {fields.body ? fields.body : ''}
                                        </div>

                                    </div> */}
                                    <div className="newlead-assigned-body">
                                        <div className="newlead-assigned-contact-details">
                                            <h3> <Translate text={'contactDetails'} /></h3>
                                            <div className="newlead-box">

                                                <div className="newlead-box-left">
                                                    <table className="table-newlead">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-left ">
                                                                    <div className="new-lead-contact-title">
                                                                        {CommonHelper.displayFullContact(titles, contact, '--')}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left">
                                                                    <i className="ico icon-enquiry" style={{ fontSize: '12px' }}></i>
                                                                    {contact && contact.displayID ? contact.displayID : '--'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left">
                                                                    <i className="ico icon-call" style={{ fontSize: '12px' }}></i>
                                                                    {contact && contact.phone ? ((contact.phoneCode ? contact.phoneCode : '') + contact.phone) : '--'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left">
                                                                    <i className="ico icon-Mail"></i>
                                                                    {contact && contact.email ? contact.email : '--'}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="newlead-assigned-notes">
                                            <h3> <Translate text={'Message'} /></h3>
                                            <textarea
                                                className={'form-control notes'}
                                                name={'newSMSnotes'}
                                                onChange={(e) => { e.preventDefault(); }}
                                                value={fields && fields.data ? fields.data : ''}
                                                readOnly={true}
                                            >
                                            </textarea>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <button type="button"
                                className={`btn btn-default float-left`}
                                onClick={(e) => { e.preventDefault(); this.props.handleClose(); }} >
                                <Translate text={'cancel'} />
                            </button>
                            <button type="button"
                                className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                                onClick={(e) => this.handleSubmit(e)} >
                                {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'view'} />
                            </button>
                        </Modal.Footer>
                    </Modal>

                </>
        );
    }
}
