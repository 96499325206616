export const objFleet = {
    documentID: null,
    clientID: null,
    projectId: null,
    stockID: null,
    serviceID: null,
    appointmentID: null,
    loanType: 'Service',
    stock: {},
    contact: {},
    staff: null,
    startDate: null,
    startOdometer: 0,
    endDate: null,
    endOdometer: 0,
    expectedReturn: null,
    beforeInspection: [],
    afterInspection: [],
    odometerHistory: [],
    purpose: '',
    roNumber: '',
    startFuelGauge: 0,
    endFuelGauge: 0,
    afterSignatureURL: '',
    notes: '',
    terms: {},
    insurance: {},
    status: '',
    //keywords: [],
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};

export const objOdometerHistory = {
    documentID: null,
    odometer: null,
    images: [],
    addedBy: '',
    addedDate: null,
    addedFrom: 'web',
    isDeleted: false
}

export const _mandatoryFields = [
    'stockID',
    'loanType',
    'startDate',
    'startOdometer',
    'expectedReturn',
    'terms',
    'roNumber',
    'purpose',
    'beforeInspection',
    'startFuelGauge',
];

export const _returnMandatoryFields = [
    'endDate',
    'endOdometer',
    'afterInspection',
    'afterSignatureURL',
    'endFuelGauge',
    'notes'
];

//#region FLEET => ON-LOAN / ON-TESTDRIVE STOCKS

export const objFleetStockVM = {
    documentID: null,
    enquiryID: null,
    stockNo: '',
    make: '',
    model: '',
    modelDescription: '',
    saleType: '',
    type: '',
    year: '',
    regNo: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    miles: '',
    price: null,
    purchasePrice: null,
    location: '',
    regDate: null,
    warrantyExp: null,
    inStockDate: null,
    stockInDate: null,
    status: '', //stockStatus
    isLoan: false,
    isTestDrive: false,
    isSale: false,
    isServiceLoan: false,
    testDriveDetail: null, //objTestDriveDetail
    fleetDetail: null, //objFleetDetail
    stockInDays: null,
    stockStatus: '',

    statusModifiedBy: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false,
    //CONTACT INFO
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    licenseNo: '',
    licenseExpiry: null,
    contactType: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    nationality: '',
    address: '',
    companyName: '',
    designation: '',
    contactMethod: '',
    licenseType: '',
    licenseState: '',
    secondaryContactName: '',
    secondaryContactPhone: '',
    secondaryContactEmail: '',
    contactCreatedBy: '',
    contactModifiedby: '',
    contactCreatedOn: null,
    contactModifiedOn: null,
    //FLEET INFO
    testDriveID: null,
    fleetID: null,
    startDate: null,
    startOdometer: 0,
    expectedReturn: null,
    fleetAddedBy: ''

};

export const allFleetStockFieldsVM = [
    {
        name: 'Stock #',
        value: 'stockNo',
        group: 'Stock',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Make',
        value: 'make',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Model',
        value: 'model',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Contact Name',
        value: 'contactName',
        group: 'Contact',
        flex: 1,
        default: true,
        elementName: 'contactFullName'
    },
    {
        name: 'Start Date',
        value: 'startDate',
        group: 'Fleet',
        flex: 1,
        default: true,
    },
    {
        name: 'Expected Return',
        value: 'expectedReturn',
        group: 'Fleet',
        flex: 1,
        default: true
    },
    {
        name: 'Loan Type',
        value: 'loanType',
        flex: 1,
        group: 'Fleet',
        default: true
    },
    {
        name: 'Status',
        value: 'stockStatus',
        group: 'Stock',
        flex: 1,
        default: true,
        elementName: 'statusName',
        subText: 'statusUpdatedBy',
        subTextTitle: 'Status Updated By',
    },
    {
        name: 'Loaned On',
        value: 'fleetAddedDate',
        subTextTitle: 'Loaned By',
        subText: 'fleetAddedBy',
        group: 'Fleet',
        flex: 1,
        default: false
    },
    {
        name: 'RO Number',
        value: 'roNumber',
        flex: 1,
        group: 'Fleet'
    },
    {
        name: 'Year',
        value: 'year',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Sale Type',
        value: 'saleType',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Reg #',
        value: 'regNo',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        group: 'Stock',
        flex: 1
    },

    {
        name: 'Body Type',
        value: 'type',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Odometer',
        value: 'miles',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        group: 'Stock',
        subText: 'modifiedBy'
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        group: 'Contact',
        flex: 1
    },
    {
        name: 'Contact Phone',
        value: 'contactPhone',
        group: 'Contact',
        flex: 1,
        elementName: 'phone'
    },
    {
        name: 'Digital Driving License',
        value: 'isDLScan',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License No',
        value: 'licenseNo',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Type',
        value: 'licenseType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License State',
        value: 'licenseState',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Birthday',
        value: 'dob',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Gender',
        value: 'gender',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Language',
        value: 'language',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Nationality',
        value: 'nationality',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Address',
        value: 'address',
        group: 'Contact',
        flex: 2,
        default: false
    },
    {
        name: 'Company Name',
        value: 'companyName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Designation',
        value: 'designation',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Name',
        value: 'secondaryContactName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Phone',
        value: 'secondaryContactPhone',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Email',
        value: 'secondaryContactEmail',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Created On',
        value: 'contactCreatedOn',
        subText: 'contactCreatedBy',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Updated On',
        value: 'contactModifiedOn',
        subText: 'contactModifiedby',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 140,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

//#endregion

//#region FLEET AVAILABLE STOCKS

export const allStockFleetFieldsVM = [
    {
        name: 'Stock #',
        value: 'stockNo',
        group: 'Stock',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Make',
        value: 'make',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Model',
        value: 'model',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Year',
        value: 'year',
        group: 'Stock',
        flex: 0.5,
        default: true
    },
    {
        name: 'Sale Type',
        value: 'saleType',
        group: 'Stock',
        flex: 0.5,
        default: true
    },
    {
        name: 'Reg #',
        value: 'regNo',
        group: 'Stock',
        flex: 1,
        default: true
    },
    {
        name: 'Available For',
        value: 'availability',
        group: 'Stock',
        flex: 1,
        default: true,
        elementName: 'availabileFor',
    },
    {
        name: 'Status',
        value: 'stockStatus',
        group: 'Stock',
        flex: 1,
        default: true,
        elementName: 'statusName',
        subText: 'statusUpdatedBy',
        subTextTitle: 'Status Updated By',
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Model Description',
        value: 'modelDescription',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Body Type',
        value: 'type',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Exterior Color',
        value: 'extColor',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Interior Color',
        value: 'intColor',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Odometer',
        value: 'miles',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Price',
        value: 'price',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Purchase Price',
        value: 'purchasePrice',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Location',
        value: 'location',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Registration Date',
        value: 'regDate',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExp',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Stock Age',
        value: 'stockInDays',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Created On',
        value: 'createdOn',
        group: 'Stock',
        flex: 1,
        default: true,
        subText: 'addedBy'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        group: 'Stock',
        flex: 1,
        subText: 'modifiedBy'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 130,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

//#endregion

//#region FLEET HISTORY => ON-LOAN STOCKS

export const objFleetHistoryVM = {
    documentID: null,
    clientID: null,
    projectId: null,
    stockID: null,
    stock: {},
    contact: null,
    staff: null,
    loanType: '',
    startDate: null,
    startOdometer: 0,
    endDate: null,
    endOdometer: 0,
    expectedReturn: null,
    roNumber: '',
    purpose: '',
    startFuelGauge: 0,
    endFuelGauge: 0,
    notes: '',
    status: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false,
    //CONTACT INFO
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    licenseNo: '',
    licenseExpiry: null,
    contactType: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    nationality: '',
    address: '',
    companyName: '',
    designation: '',
    contactMethod: '',
    licenseType: '',
    licenseState: '',
    secondaryContactName: '',
    secondaryContactPhone: '',
    secondaryContactEmail: '',
    contactCreatedBy: '',
    contactModifiedby: '',
    contactCreatedOn: null,
    contactModifiedOn: null,
    //STOCK INFO
    stockNo: '',
    make: '',
    model: '',
    modelDescription: '',
    saleType: '',
    type: '',
    year: '',
    regNo: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    miles: '',
    price: null,
    purchasePrice: null,
    location: '',
    regDate: null,
    warrantyExp: null,
    stockInDate: null,
    stockStatus: '',

    createdOn: null,
    UpdatedOn: null,
};

export const allFleetHistoryFieldsVM = [
    {
        name: 'Stock #',
        value: 'stockNo',
        flex: 1,
        group: 'Stock',
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Make',
        value: 'make',
        flex: 1,
        group: 'Stock',
        default: true
    },
    {
        name: 'Model',
        value: 'model',
        flex: 1,
        group: 'Stock',
        default: true
    },
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        group: 'Contact',
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'contactdealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Start Date',
        value: 'startDate',
        flex: 1,
        group: 'Loan',
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Expected Return',
        value: 'expectedReturn',
        flex: 1,
        group: 'Loan',
        default: true
    },
    {
        name: 'End Date',
        value: 'endDate',
        flex: 1,
        group: 'Loan',
        default: true,
        subText: 'modifiedBy',
        subTextTitle: 'Updated By'
    },
    {
        name: 'Loan Type',
        value: 'loanType',
        flex: 1,
        group: 'Loan',
        default: true
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        group: 'Loan',
        default: true,
        elementName: 'lvstatusName'
    },
    {
        name: 'Stock Status',
        value: 'stockStatus',
        flex: 1,
        group: 'Stock',
        default: true,
        elementName: 'stockStatusName',
        subText: 'statusUpdatedBy',
        subTextTitle: 'Stock Status Updated By',
    },
    {
        name: 'Start Odometer',
        value: 'startOdometer',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'End Odometer',
        value: 'endOdometer',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'Start Fuel Gauge',
        value: 'startFuelGauge',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'End Fuel Gauge',
        value: 'endFuelGauge',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'Purpose',
        value: 'purpose',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'RO Number',
        value: 'roNumber',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'Notes',
        value: 'notes',
        group: 'Loan',
        flex: 2,
        limit : 190
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        group: 'Loan',
        subText: 'addedBy',
        subTextTitle: 'Created By',

    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        group: 'Loan',
        subText: 'modifiedBy',
        subTextTitle: 'Updated By',

    },
    {
        name: 'Year',
        value: 'year',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Sale Type',
        value: 'saleType',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Reg #',
        value: 'regNo',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        group: 'Stock',
        flex: 1
    },

    {
        name: 'Body Type',
        value: 'type',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        group: 'Contact',
        flex: 1
    },
    {
        name: 'Contact Phone',
        value: 'contactPhone',
        group: 'Contact',
        flex: 1,
        elementName: 'phone'
    },
    {
        name: 'Digital Driving License',
        value: 'isDLScan',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License No',
        value: 'licenseNo',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Type',
        value: 'licenseType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License State',
        value: 'licenseState',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Birthday',
        value: 'dob',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Gender',
        value: 'gender',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Language',
        value: 'language',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Nationality',
        value: 'nationality',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Address',
        value: 'address',
        group: 'Contact',
        flex: 2,
        default: false
    },
    {
        name: 'Company Name',
        value: 'companyName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Designation',
        value: 'designation',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Name',
        value: 'secondaryContactName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Phone',
        value: 'secondaryContactPhone',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Email',
        value: 'secondaryContactEmail',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Created On',
        value: 'contactCreatedOn',
        subText: 'contactCreatedBy',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Updated On',
        value: 'contactModifiedOn',
        subText: 'contactModifiedby',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

//#endregion

//#region STOCK HISTORY => LOANED VEHICLES STOCKS

export const allFleetStockHistoryFields = [
    {
        name: 'Stock #',
        value: 'stockNo',
        flex: 1,
        group: 'Stock',
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Make',
        value: 'make',
        flex: 1,
        group: 'Stock',
        default: true
    },
    {
        name: 'Model',
        value: 'model',
        flex: 1,
        group: 'Stock',
        default: true
    },
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        group: 'Contact',
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'contactdealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Start Date',
        value: 'startDate',
        flex: 1,
        group: 'Loan',
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By',

    },
    {
        name: 'Expected Return',
        value: 'expectedReturn',
        flex: 1,
        group: 'Loan',
        default: true
    },
    {
        name: 'End Date',
        value: 'endDate',
        flex: 1,
        group: 'Loan',
        default: true,
        subText: 'modifiedBy',
        subTextTitle: 'Updated By',

    },
    {
        name: 'Loan Type',
        value: 'loanType',
        flex: 1,
        group: 'Loan',
        default: true
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        group: 'Loan',
        default: true
    },
    {
        name: 'Stock Status',
        value: 'stockStatus',
        flex: 1,
        group: 'Stock',
        default: true
    },
    {
        name: 'Start Odometer',
        value: 'startOdometer',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'End Odometer',
        value: 'endOdometer',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'Start Fuel Gauge',
        value: 'startFuelGauge',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'End Fuel Gauge',
        value: 'endFuelGauge',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'Purpose',
        value: 'purpose',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'RO Number',
        value: 'roNumber',
        group: 'Loan',
        flex: 1
    },
    {
        name: 'Notes',
        value: 'notes',
        group: 'Loan',
        flex: 2,
        limit : 190
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        group: 'Loan',
        subText: 'addedBy',
        subTextTitle: 'Created By',

    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        group: 'Loan',
        subText: 'modifiedBy',
        subTextTitle: 'Updated By',

    },
    {
        name: 'Year',
        value: 'year',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Sale Type',
        value: 'saleType',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Reg #',
        value: 'regNo',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        group: 'Stock',
        flex: 1
    },

    {
        name: 'Body Type',
        value: 'type',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        group: 'Contact',
        flex: 1
    },
    {
        name: 'Contact Phone',
        value: 'contactPhone',
        group: 'Contact',
        flex: 1,
        elementName: 'phone'
    },
    {
        name: 'Digital Driving License',
        value: 'isDLScan',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License No',
        value: 'licenseNo',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Type',
        value: 'licenseType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License State',
        value: 'licenseState',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Birthday',
        value: 'dob',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Gender',
        value: 'gender',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Language',
        value: 'language',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Nationality',
        value: 'nationality',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Address',
        value: 'address',
        group: 'Contact',
        flex: 2,
        default: false
    },
    {
        name: 'Company Name',
        value: 'companyName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Designation',
        value: 'designation',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Name',
        value: 'secondaryContactName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Phone',
        value: 'secondaryContactPhone',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Email',
        value: 'secondaryContactEmail',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Created On',
        value: 'contactCreatedOn',
        subText: 'contactCreatedBy',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Updated On',
        value: 'contactModifiedOn',
        subText: 'contactModifiedby',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]
//#endregion



export const allLoanBookingsFieldsVM = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true
    },
    {
        name: 'Vehicle',
        value: 'vehicle',
        flex: 1,
        subText: 'year',
        default: true,
    },
    {
        name: 'Reg. No.',
        value: 'regNo',
        flex: 1,
        default: true
    },
    {
        name: 'VIN No.',
        value: 'vinNo',
        flex: 1,
        default: true
    },
    {
        name: 'Appointment On',
        value: 'appointmentOn',
        flex: 1,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 130,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]