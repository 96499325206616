/** LIBRARIES */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { PopoverPanel } from "../../components";
/** COMPONENTS */
import Translate from '../../constants/translate';
import { default as _images } from '../../images';
import TargetPopover from '../reports/targetPopover'

const UserTargetModel = (props) => {
    const [targets, setTarget] = useState(props.usertarget ? props.usertarget : [])
    const [activeTarget, setActiveTarget] = useState(null)
    const [targetPopOver, setTargetPopOver] = useState({ show: false, target: null, field: null })

    const handleShowTargetDetails = (e, target) => {
        setActiveTarget(target)
        setTargetPopOver({
            show: true,
            target: e.target
        });
        e.preventDefault()
    }

    return (
        <Modal
            id="add-terms"
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-terms">

            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className="modal-title" ><Translate text={props.title} /></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className={`container-fluid `}>
                    <div className="row">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td className="head-light" width="30%">KPI</td>
                                    <td className="head-light text-center" width="10%">Target</td>
                                    <td className="head-light text-center" width="10%">Actual</td>
                                    <td className="head-light text-center" width="10%">To Target</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    targets.length > 0 ?
                                        targets.map((rec, index) => {
                                            return <tr key={index}>
                                                <td><div className="dealership-tbl cursor-pointer float-left" onClick={(e) => {
                                                    handleShowTargetDetails(e, rec)
                                                }}>{rec.name}</div></td>
                                                <td className="text-center">{rec.target}</td>
                                                <td className="text-center">
                                                    <div className={rec.showRed === "true"  ? 'down-ico' : 'up-ico'}>
                                                        {/* <i className={`ico icon-sort-${rec.showRed ? 'down' : 'up'}`}></i> */}
                                                        {rec.actual}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    {/*<span className={rec.showRed ? 'down-ico' : 'up-ico'}>
                                                             <i className={`ico icon-sort-${rec.showRed ? 'down' : 'up'}`}></i> 
                                                            {rec.percentage}
                                                        </span>*/}
                                                    {
                                                        rec.showRed === "true" ? (
                                                            <span className={'down-ico ml-2'}>
                                                                {/* <i className={`ico icon-sort-down`}></i> */}
                                                                {rec.totarget}
                                                            </span>
                                                        ) : (
                                                                <img src={_images.tickgreen} alt="" height="20" />
                                                            )
                                                    }
                                                </td>
                                            </tr>
                                        }) : (
                                            <tr>
                                                <td colSpan="4">
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                            <div className="no-data-txt mt-2"> <Translate text={'Target not set for selected user / month'} /></div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                    <PopoverPanel
                        showpopover={targetPopOver.show}
                        targetpopover={targetPopOver.target}
                        title={'Target Details '}
                        position="right"
                        className="popover-required-fields"
                        closepopover={() => {
                            setTargetPopOver({ show: false, target: '', field: '' });
                        }}>
                        <TargetPopover
                            {...props}
                            activeTarget={activeTarget}
                        />
                    </PopoverPanel>
                </div>
            </Modal.Body>
        </Modal>);
}

export default UserTargetModel;

