import React, { useState, useEffect, useReducer, useRef, Fragment } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

const FileDocuments = (props) => {

    //const [activeTab, setActiveTab] = useState('sales')
    const { filesCategory, activeTab, handleFileOpen, files } = props
    return (
        <>

            {
                !_.isEmpty(filesCategory) && activeTab && filesCategory.filter(e => e.module === activeTab).map((_info, index) => (
                    <div key={index} className="float-left mr-3 mb-2">
                        <div className="checkbox icheck-success">
                            <input
                                type="checkbox"
                                id={`${activeTab}${_info.value}`}
                                name={`${activeTab}${_info.value}`}
                                className="uncheck-activity"
                                checked={files.filter(e => e.category === _info.value && !e.isDeleted)[0] ? true : false}
                                onChange={(e) => {
                                    if (e.target.checked)
                                        handleFileOpen(null, _info.value, activeTab, filesCategory.filter(e => e.module === activeTab), _info.name)
                                    else
                                        handleFileOpen(e, _info.value, activeTab, filesCategory.filter(e => e.module === activeTab), _info.name)
                                }} />
                            <label htmlFor={`${activeTab}${_info.value}`}><Translate text={_info.name} /></label>
                        </div>
                    </div>))
            }




        </>

    )
}

export default FileDocuments;