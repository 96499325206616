
/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import toast from 'toasted-notes'
import moment from 'moment'

import { InputText, InputRadio, ReactSelect, ReactMultiSelect } from '../../components';
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

import { objFilter } from './viewModel'

const visibilities = [
    { active: true, value: 'private', label: <Translate text={'private'} /> },
    { active: true, value: 'shared', label: <Translate text={'shared'} /> }
]

const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'individual', label: 'Individual', index: 2 }
]

export default class SaveFilters extends Component {
    constructor(props) {
        super(props);
        this.refStock = window.firebase.firestore().collection('stock');
        this.state = {
            isLoading: false,
            isExistingFilter: true,
            filterName: '',
            filterVisibility: '',
            errorName: '',
            errors: {},
            filterFields: {},
            level: '',
            levelID: '',
            clientOptions: []
        }
    }


    componentDidMount() {
        const { activeFilter, dealersettings, filterFields } = this.props;

        let userID = dealersettings ? dealersettings.id : '';
        if (!_.isEmpty(activeFilter) && activeFilter.type === 'custom' && (activeFilter.addedBy === userID || dealersettings?.roleName === 'Administrator')) {

            this.setState({
                filterName: activeFilter.name,
                filterVisibility: activeFilter.visibility,
                filterFields: filterFields,
                level: activeFilter.level ? activeFilter.level : '',
                levelID: activeFilter.clientIDs ? activeFilter.clientIDs : []
            })
        }
        else {
            this.setState({ isExistingFilter: false, filterFields: filterFields })
        }

        if (!_.isEmpty(dealersettings)) {
            let _clients = [];
            dealersettings.clients && dealersettings.clients.forEach(rec => {
                _clients.push({
                    label: rec.name,
                    value: rec.id,
                    data: rec,
                    active: true,
                })
            })
            this.setState({ clientOptions: _clients })
        }

    }

    handleOnChange = (e) => {
        e.preventDefault();
        let { isExistingFilter } = this.state;
        const { value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }

            this.setState({ filterName: newarray1.join(' '), errorName: (!isExistingFilter && !newarray1.join(' ') ? 'input_error' : '') });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            this.setState({ filterName: str });
        }
    }

    handleReactSelectChange = (e, data) => {
        if (data.name === 'level') {
            this.setState({
                [data.name]: (e ? e.value : ''),
                ['levelID']: (data.name === 'level' && e.value === 'oem') ? [this.props.dealersettings.oemID] : '',
            });
        }
        else {
            this.setState({ [data.name]: (e ? e.value : '') });
        }
    }

    handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        this.setState({ [name]: value });
    }


    handleTypeChange = e => {
        const { value } = e.target;
        let val = (value === 'Exist' ? true : false);
        //let vis = (value === 'Exist' ? this.state.filterVisibility : 'private');
        this.setState({ isExistingFilter: val });
    };

    handleSaveFilter = () => {
        let { isExistingFilter, filterName, filterVisibility, level, levelID } = this.state;
        const { filtersList, activeFilter, dealersettings } = this.props;

        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';


        if (_.isEmpty(filterName)) {
            formIsValid = false;
            errors['filterName'] = errorClass;
        }

        if (filterVisibility === 'shared' && _.isEmpty(level)) {
            formIsValid = false;
            errors['level'] = errorClass;
        }

        if (filterVisibility === 'shared' && level === 'individual' && _.isEmpty(levelID)) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            let userID = dealersettings ? dealersettings.id : '';
            if (!_.isEmpty(activeFilter) && activeFilter.type === 'custom' && (activeFilter.addedBy === userID || dealersettings?.roleName === 'Administrator') && isExistingFilter) {
                let _filter = filtersList.filter(e => e.documentID === activeFilter.documentID)[0];
                this.handleUpdateFilter(_filter.documentID);
            }
            else {
                this.handleAddFilter();
            }
        }
        else
            return formIsValid;

    }

    handleAddFilter = () => {

        const { filterName, filterVisibility, filterFields, levelID, level } = this.state;
        const { dealersettings } = this.props;
        let settingsid = dealersettings.client.settings.id;

        //console.log('filters', settingsid, filterFields);
        if (settingsid && !_.isEmpty(filterFields)) {
            this.setState({ isLoading: true });
            let userID = dealersettings ? dealersettings.id : '';
            let currentDate = window.firebase.firestore.Timestamp.now();

            const objData = Object.assign({}, objFilter);

            objData.documentID = this.refStock.doc().id;
            objData.name = filterName.trim();
            objData.value = filterFields;
            objData.module = this.props.module;
            objData.visibility = filterVisibility ? filterVisibility : 'private';

            if (filterVisibility === 'shared') {
                if (level === 'oem') {
                    objData.level = level
                    objData.oemID = dealersettings.oemID ? dealersettings.oemID : ''
                    objData.clientIDs = [];
                } else if (level === 'individual') {
                    objData.level = level
                    objData.oemID = null;
                    objData.clientIDs = !_.isEmpty(levelID) ? levelID : [];
                }
            }

            objData.addedBy = userID;
            objData.modifiedBy = userID;
            objData.addedDate = currentDate;
            objData.modifiedDate = currentDate;

            const refData = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`).doc(objData.documentID);
            refData.set(objData).then((docRef) => {
                this.setState({ isLoading: false });
                //console.log('filters', settingsid, objData, docRef);
                toast.notify('Filter added successfully', {
                    duration: 2000
                });
                this.handleCloseFilter(objData);
            }).catch((error) => {
                console.error("Error adding filter_details: ", error);
            });
        }
    }

    handleUpdateFilter = (filterID) => {
        const { filterFields, filtersList, activeFilter, dealersettings } = this.props;
        const { filterName, filterVisibility, levelID, level } = this.state;

        let objData = filtersList.filter(e => e.documentID === activeFilter.documentID)[0];
        let settingsid = this.props.dealersettings.client.settings.id;
        objData.value = filterFields;
        objData.name = filterName ? filterName.trim() : objData.name;
        objData.visibility = filterVisibility ? filterVisibility : objData.visibility;
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();

        if (filterVisibility === 'shared') {
            if (level === 'oem') {
                objData.level = level
                objData.oemID = dealersettings.oemID ? dealersettings.oemID : ''
                objData.clientIDs = [];
            } else if (level === 'individual') {
                objData.level = level
                objData.oemID = null;
                objData.clientIDs = !_.isEmpty(levelID) ? levelID : [];
            }
        }

        if (settingsid && filterID) {
            this.setState({ isLoading: true });
            const refStockData = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`).doc(filterID);
            refStockData.set({
                value: objData.value,
                name: objData.name,
                visibility: objData.visibility,
                modifiedBy: objData.modifiedBy,
                modifiedDate: objData.modifiedDate,
                level: objData.level ? objData.level : '',
                oemID: objData.oemID ? objData.oemID : '',
                clientIDs: objData.clientIDs ? objData.clientIDs : null,
            }, { merge: true }).then((docRef) => {
                //console.log(docRef);
                this.setState({ isLoading: false });
                toast.notify('Filter updated successfully', {
                    duration: 2000
                });
                this.handleCloseFilter(objData);

            }).catch((error) => {
                console.error("Error updating filter_details: ", error);
            });
        }

    }

    handleCloseFilter = (objData) => {
        this.props.handleClose(objData);
    }

    render() {
        let { isExistingFilter, filterName, errorName, filterVisibility, isLoading, level, levelID, clientOptions, errors } = this.state;
        const { show, activeFilter, dealersettings, module } = this.props;
        let userID = dealersettings ? dealersettings.id : '';
        return (
            <>

                <Modal
                    id="save-filter"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-save-filter filter-dont-close">

                    <Modal.Header className="filter-dont-close">
                        <Modal.Title>
                            <h5 className="modal-title filter-dont-close" > <Translate text={'Save Filter'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="filter-dont-close">
                        <div className="container-fluid filter-dont-close">
                            <div className="row filter-dont-close">

                                <div className="form-style w-100 filter-dont-close">

                                    {
                                        (!_.isEmpty(activeFilter) && activeFilter.type === 'custom' && (activeFilter.addedBy === userID || dealersettings?.roleName === 'Administrator'))
                                            ?
                                            <div className="save-filter-inner filter-dont-close">
                                                <div className="radio icheck-success filter-dont-close">
                                                    <InputRadio
                                                        id="ExistFilter"
                                                        type="radio"
                                                        value="Exist"
                                                        name="isExistingFilter"
                                                        checked={isExistingFilter === true ? true : false}
                                                        onChange={this.handleTypeChange}
                                                    />
                                                    <label htmlFor="ExistFilter" className="filter-dont-close" ><Translate text={'Save changes to this filter'} /> "{activeFilter.name}"</label>
                                                </div>

                                                <div className="radio icheck-success filter-dont-close">
                                                    <InputRadio
                                                        id="NewFilter"
                                                        type="radio"
                                                        value="New"
                                                        name="isExistingFilter"
                                                        checked={isExistingFilter === false ? true : false}
                                                        onChange={this.handleTypeChange}
                                                    />
                                                    <label htmlFor="NewFilter" className="filter-dont-close"><Translate text={'Create new filter'} /></label>
                                                </div>
                                            </div>
                                            :
                                            <></>

                                    }

                                    <div className="form-row filter-dont-close">
                                        <div className="form-group col-md-12 filter-dont-close" >
                                            <label ><Translate text={'Filter Name'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'Enter new filter name'}
                                                className={`form-control ${errorName ? 'input_error' : ''} ${errors["filterName"]} filter-dont-close`}
                                                name="filterName"
                                                onChange={this.handleOnChange}
                                                value={filterName ? filterName : ''}
                                            />

                                        </div>

                                        <div className="form-group col-md-12 filter-dont-close">
                                            <label ><Translate text={'visibility'} /></label>
                                            <ReactSelect
                                                options={visibilities}
                                                name={"filterVisibility"}
                                                placeholder={'select visibility'}
                                                onChange={this.handleReactSelectChange}
                                                value={filterVisibility ? filterVisibility : 'private'}
                                                classNamePrefix={`basic-select filter-dont-close`}
                                                menuPlacement='top'
                                                removeClearable={true}
                                                isSearchable={false}
                                            >
                                            </ReactSelect>
                                        </div>

                                        {
                                            (filterVisibility === 'shared')
                                                ?
                                                <>
                                                    <div className="form-group col-md-12 filter-dont-close">
                                                        <label > <Translate text={'Level'} /></label>
                                                        <ReactSelect
                                                            options={(dealersettings.level === 'oem' || !_.find(levelOptions, { value: dealersettings.level })) ? levelOptions :
                                                                levelOptions.filter(e => e.index >= _.find(levelOptions, { value: dealersettings.level }).index)}
                                                            name={"level"}
                                                            placeholder={'select level'}
                                                            onChange={this.handleReactSelectChange}
                                                            value={level}
                                                            classNamePrefix={`${errors["level"]} basic-select filter-dont-close`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    {
                                                        (!_.isEmpty(level) && level !== 'oem') ? (<div className="form-group col-md-12 filter-dont-close">
                                                            <label >{'Client'}</label>
                                                            <ReactMultiSelect
                                                                options={clientOptions}
                                                                name={'levelID'}
                                                                placeholder={`select ${level === 'individual' ? 'client' : level}`}
                                                                onChange={(rec) => { this.handleReactMultiSelectChange(rec, 'levelID') }}
                                                                value={levelID ? _.isArray(levelID) ? levelID : [levelID] : []}
                                                                classNamePrefix={`${errors["levelID"]} basic-select filter-dont-close`}
                                                                removeClearable={true}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>) : (<></>)
                                                    }
                                                </>
                                                :
                                                <></>
                                        }

                                    </div>
                                    {
                                        (!_.isEmpty(activeFilter) && activeFilter.type === 'custom' && isExistingFilter && (activeFilter.addedBy === userID || dealersettings?.roleName === 'Administrator'))
                                            ?
                                            <div className="save-filter-details filter-dont-close">
                                                <ul className="filter-dont-close">
                                                    <li className="filter-dont-close"><Translate text={'Updated by'} />: {activeFilter.modifiedBy ? CommonHelper.getUserNamebyId(this.props.clientUsers, activeFilter.modifiedBy) : '--'}</li>
                                                    <li className="filter-dont-close"><Translate text={'Updated date'} />: {activeFilter.modifiedDate ? moment.unix(activeFilter.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--'}</li>
                                                </ul>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''} filter-dont-close`}>
                        <button type="button" className={`btn btn-primary float-right filter-dont-close`} onClick={(e) => {
                            this.handleSaveFilter();
                        }
                        }>
                            {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'save'} />
                        </button>
                        <button type="button" className="btn btn-default float-left filter-dont-close" onClick={() => {
                            this.props.handleClose();
                        }}>
                            <Translate text={'cancel'} />
                        </button>

                    </Modal.Footer>
                </Modal>


            </>


        );
    }
}

