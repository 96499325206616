import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import CommonHelper from '../../services/common';

import Translate from '../../constants/translate';
import { TableView, PopUpModal } from '../../components';
import { alleditrecipientFields } from './viewModel';
import AddRecipient from './addRecipient';

const ViewRecipients = ({ hasMore, fetchMoreData, data, setData, title, show, handleClose, recipients, setRecipients, apiUrl, API_KEY, dealersettings }) => {
  const [selectedIds, setSelectedIds] = useState({})
  const [windowSize, setWindowSize] = useState({
    windowWidth: 770,
    windowHeight: window.innerHeight - 293,
  });
  const [showAddRecipient, setShowAddRecipient] = useState({
    show: false,
    title: '',
  });

  const handleShowAddRecipientOpen = (type, data) => {
    setShowAddRecipient({
      show: true,
      title: type === 'add' ? 'Add Recipient' : 'Edit Recipient',
      data: type === 'add' ? null : data,
    })
  }

  const handleShowAddRecipientClose = async (recipient, action) => {
    // console.log('adding or editing a recipient', recipient)
    setShowAddRecipient({
      show: false,
      title: ''
    })

    if (!recipient) {
      return
    }

    if (action === 'add') {
      const recipientObj = {
        phone: recipient.phone ? recipient.phone : '',
        phoneCode: recipient.phoneCode ? recipient.phoneCode : '',
        firstName: recipient.firstName ? recipient.firstName : '',
        middleName: recipient?.middleName || '',
        lastName: recipient.lastName ? recipient.lastName : '',
        contactID: recipient.contactID ? recipient.contactID : null,
        documentID: recipient.documentID ? recipient.documentID : window.firebase.firestore().collection('campaignRecipients').doc().id,
        clientID: dealersettings.client.id,
        addedBy: localStorage.uid
      }
      if (recipient.contactID) {
        recipientObj.contactID = recipient.contactID
      }
      let all = [...recipients, ...[recipientObj]]
      all = _.uniqBy(all, 'phone')
      setRecipients(all);
      let docID
      if (data.documentID) {
        docID = data.documentID
      } else {
        docID = window.firebase.firestore().collection('smsCampaigns').doc().id
      }
      let smsCampaignRef = window.firebase.firestore().collection('smsCampaigns').doc(docID)
      let count = 0
      if (data.recipientsCount) {
        count = data.recipientsCount + 1
      } else {
        count = 1
      }
      await smsCampaignRef.set({ modifiedDate: window.firebase.firestore.Timestamp.now(), recipientsCount: count }, { merge: true })
      setData({
        ...data,
        documentID: docID,
        // recipientIDs: arrayToCalculateLength,
        recipientsCount: count
      })
      setRecipients(all)
      await window.firebase.firestore().collection(`smsCampaigns`).doc(docID).collection('smsRecipients').doc(recipientObj.documentID).set(recipientObj, { merge: true })
    } else if (action === 'update') {
      const allOther = recipients.filter(item => item.documentID !== recipient.documentID)
      let oneToBeUpdated = recipients.filter(item => item.documentID === recipient.documentID)
      if (oneToBeUpdated.length === 0) {
        return
      }
      oneToBeUpdated = oneToBeUpdated[0]
      oneToBeUpdated.phone = recipient.phone ? recipient.phone : ''
      oneToBeUpdated.phoneCode = recipient.phoneCode ? recipient.phoneCode : ''
      oneToBeUpdated.firstName = recipient.firstName ? recipient.firstName : ''
      oneToBeUpdated.middleName = recipient.middleName ? recipient.middleName : ''
      oneToBeUpdated.lastName = recipient.lastName ? recipient.lastName : ''
      oneToBeUpdated.documentID = recipient.documentID ? recipient.documentID : ''
      oneToBeUpdated.contactID = recipient.contactID ? recipient.contactID : ''

      let all = [oneToBeUpdated, ...allOther]
      all = _.uniqBy(all, 'phone')
      setRecipients(all);
      let docID
      if (data.documentID) {
        docID = data.documentID
      } else {
        docID = window.firebase.firestore().collection('smsCampaigns').doc().id
      }

      let count = data.recipientsCount
      let smsCampaignRef = window.firebase.firestore().collection('smsCampaigns').doc(docID)
      await smsCampaignRef.set({ modifiedDate: window.firebase.firestore.Timestamp.now(), recipientsCount: count }, { merge: true })
      setData({
        ...data,
        documentID: docID,
        // recipientIDs: arrayToCalculateLength,
        recipientsCount: count
      })
      setRecipients(all)
      await window.firebase.firestore().collection(`smsCampaigns`).doc(docID).collection('smsRecipients').doc(oneToBeUpdated.documentID).set(oneToBeUpdated, { merge: true })
    }
  }

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: 750,
        windowHeight: window.innerHeight - 293,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubCampaigns && window.unSubCampaigns();
    };
  }, []);

  const handleActionClick = id => {
    const selectedRecipient = recipients.filter(recipient => recipient.documentID === id)[0]
    return (
      <>
        <div
          style={{ cursor: 'pointer' }}
          className='mini-button mr-2'
          onClick={() => handleShowAddRecipientOpen('edit', selectedRecipient)}
        >
          <i className='ico icon-edit'></i>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          className='mini-button'
          onClick={e => {
            Swal.fire({
              title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
              text: CommonHelper.showLocale({ dealersettings }, 'Do you want to remove this recipient'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
              cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
            }).then(async (result) => {
              if (result.value) {
                let filteredRecipients = recipients.filter(contact => contact.documentID !== id)
                setRecipients(_.uniqBy([...filteredRecipients], 'phone'));
                toast.notify('Removed recipient successfully', {
                  duration: 2000
                })
                if (!data.documentID) {
                  return
                }
                let count = data.recipientsCount - 1
                await window.firebase.firestore().collection('smsCampaigns').doc(data.documentID).set({ recipientsCount: count }, { merge: true })
                const ref = window.firebase.firestore().collection('smsCampaigns').doc(data.documentID).collection('smsRecipients').doc(id)
                const snap = await ref.get()
                setData({
                  ...data,
                  recipientsCount: count
                })
                if (snap.exists) {
                  await ref.delete();
                }
              }
            })
          }}
        >
          <i className='ico icon-delete'></i>
        </div>

      </>
    );
  };
  const handleRowSingleClick = () => {

  }

  const handleAllChecked = (event) => {
    let _selectedIds = {};
    if (event.target.checked) {
      !_.isEmpty(recipients) && recipients.forEach(file => {
        let record = Object.assign({}, recipients.filter(e => e.documentID === file.documentID)[0].objDoc);
        _selectedIds[file.documentID] = record;
      })
    }
    setSelectedIds(_selectedIds);
  }

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;

    let _selectedIds = Object.assign({}, selectedIds);
    if (checked) {
      let record = Object.assign({}, recipients.filter(e => e.documentID === name)[0]);
      if (!_.isEmpty(record))
        _selectedIds[name] = record.objDoc;
    }
    else {
      delete _selectedIds[name];
    }
    setSelectedIds(_selectedIds);
  }

  const convertVM = recipients => {
    recipients = recipients.map(a => {
      return {
        ...a,
        _phone: CommonHelper.getFullPhone(a.phoneCode, a.phone)
      }
    })
    return recipients
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      backdropClassName={show ? 'overlay-modal active' : ''}
      enforceFocus={false}
      dialogClassName='modal-dialog modal-dialog-centered modal-update-password modal-edit-recipients'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className='modal-title'>
            <Translate text={title} />
          </h5>
        </Modal.Title>
        <button type="button" className="btn btn-primary float-right ml-2 ml-auto" aria-label="Close" onClick={() => handleShowAddRecipientOpen('add')}> Add Recipient</button>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="settings-head">

            </div>
            <div className="common-table">
              <TableView
                isReorderable={false}
                datalist={convertVM(recipients)}
                height={windowSize.windowHeight}
                width={windowSize.windowWidth}
                columns={alleditrecipientFields}
                dynamicFields={alleditrecipientFields
                  .filter(e => e.default === true)
                  .map(v => _.pick(v, ['value']).value)}
                settingsLoader={false}
                handleActionClick={handleActionClick}
                handleRowSingleClick={handleRowSingleClick}
                isSettings={false}
                hasMore={hasMore}
                isPaging={true}
                selectedIds={selectedIds}
                handleAllChecked={handleAllChecked}
                handleCheckChange={handleCheckChange}
                handlePagination={fetchMoreData}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='modal-footer'>
        <button
          type='button'
          className='btn btn-default float-right ml-2 '
          aria-label='Close'
          // disabled={}
          onClick={handleClose}
        >
          {' '}
          <Translate text={'Close'} />
        </button>
        <button
          type='button'
          className={`btn btn-primary float-right ml-2 ${!_.isEmpty(selectedIds) ? '' : 'disabled'}`}
          aria-label='Close'
          // disabled={}
          onClick={() => {
            if (!_.isEmpty(selectedIds)) {
              Swal.fire({
                title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
                text: CommonHelper.showLocale({ dealersettings }, 'Do you want to remove selected recipients?'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
                cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
              }).then(async (result) => {
                if (result.value) {
                  let selectedIdsArr = Object.keys(selectedIds)
                  let filteredRecipients = recipients.filter(contact => !selectedIdsArr.includes(contact.documentID))
                  let recipiensToRemove = recipients.filter(contact => selectedIdsArr.includes(contact.documentID))
                  filteredRecipients = _.uniqBy([...filteredRecipients], 'phone')
                  setRecipients(filteredRecipients);
                  setSelectedIds({})
                  toast.notify('Removed recipients successfully', {
                    duration: 2000
                  })
                  if (!data.documentID) {
                    return
                  }
                  let recipientsToRemoveIDs = recipiensToRemove.map(a => a.documentID)
                  let count = data.recipientsCount
                  count = count - recipientsToRemoveIDs.length

                  // arrayToCalculateLength = _.uniq(arrayToCalculateLength)
                  setData({
                    ...data,
                    // recipientIDs: ,
                    recipientsCount: count,
                  })
                  await window.firebase.firestore().collection('smsCampaigns').doc(data.documentID).set({ recipientsCount: count }, { merge: true })
                  recipiensToRemove = recipiensToRemove.map(a => a.documentID)

                  if (filteredRecipients.length === 0 && count > 0) {
                    fetchMoreData(true)
                  }
                  //deleting batches in parts start
                  const arrayOfRecipientsToRemove = []
                  while (recipiensToRemove.length > 0) {
                    arrayOfRecipientsToRemove.push(recipiensToRemove.splice(0, 300));
                  }
                  await Promise.all(
                    arrayOfRecipientsToRemove.map(async (arrayOfMessages, index) => {
                      const batch = window.firebase.firestore().batch();
                      arrayOfMessages.forEach(rec => {
                        batch.delete(window.firebase.firestore().collection('smsCampaigns').doc(data.documentID).collection('smsRecipients').doc(rec));
                      })
                      await batch.commit()
                    })
                  )

                  //deleting batches in parts end
                }
              })
            }
          }
          }
        >
          {' '}
          <Translate text={'Remove'} />
        </button>

      </Modal.Footer>
      <PopUpModal show={showAddRecipient.show}>
        <AddRecipient
          show={showAddRecipient.show}
          handleClose={handleShowAddRecipientClose}
          title={showAddRecipient.title}
          data={showAddRecipient.data}
          dealersettings={dealersettings}
        />
      </PopUpModal>
    </Modal>
  );
};

export default ViewRecipients;
