export const objCafe = {
    documentID: '',
    enquiryID: '',
    clientID: '',
    customer: null,
    contact: null,
    staff: null,
    orderNumber: '',
    orders: [],
    status: '',
    deliveredNotes: '',
    orderNotes: '',
    //keywords: [],
    addedBy: '',
    addedByName: '',
    modifiedBy: '',
    modifiedFrom: 'web',
    deliveredBy: '',
    addedDate: null,
    modifiedDate: null,
    deliveredDate: null,
    isDeleted: false
};

export const mandatoryFields = [
    'make',
    'bodyType',
    'model',
    'regNo'
];

export const objOrderItem = {
    documentID: '',
    name: '',
    image: '',
    quantity: 0,

};

export const objCafeItem = {
    documentID: '',
    name: '',
    image: '',
    category: '',
    isDeleted: false,
    isAvailable: false,
    sortOrder: 0

};

export const objCafeHistoryVM = {
    documentID: '',
    enquiryID: '',
    clientID: '',
    customer: null,
    contact: null,
    staff: null,
    orderNumber: '',
    orders: [],
    status: '',
    deliveredNotes: '',
    orderNotes: '',
    //keywords: [],
    addedBy: '',
    addedByName: '',
    modifiedBy: '',
    modifiedFrom: 'web',
    deliveredBy: '',
    addedDate: null,
    modifiedDate: null,
    deliveredDate: null,
    isDeleted: false,

    name: '',
    phoneCode: '',
    phone: '',
    email: '',
    orderQuantity: '',
    addedby: '',
    deliveredby: '',
    modifiedby: '',
    addeddate: '',
    modifieddate: '',
    delivereddate: '',
    cafeStatus: ''
};

export const allCafeHistoryFields = [
    {
        name: 'Order No.',
        value: 'orderNumber',
        flex: 1,
        group: 'Cafe',
        default: true,
        nestedText: 'cafeDealerName',
        nestedTextTitle: 'Cafe Dealer Name',

    },
    {
        name: 'Order For',
        value: 'name',
        flex: 1,
        group: 'Cafe',
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Order',
        value: 'addeddate',
        subText: 'addedby',
        flex: 1,
        group: 'Cafe',
        default: true
    },
    {
        name: 'Order Notes',
        value: 'orderNotes',
        flex: 1,
        group: 'Cafe',
        default: true
    },
    {
        name: 'Delivery/Cancelled',
        value: 'delivereddate',
        subText: 'deliveredby',
        flex: 1,
        group: 'Cafe',
        default: true
    },
    {
        name: 'Delivery/Cancelled Notes',
        value: 'deliveredNotes',
        flex: 1,
        group: 'Cafe',
        default: true
    },
    {
        name: 'Quantity',
        value: 'orderQuantity',
        flex: 1,
        group: 'Cafe',
        default: true
    },
    {
        name: 'Status',
        value: 'cafeStatus',
        flex: 1,
        group: 'Cafe',
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]