import React from 'react';
import moment from 'moment';
import _ from 'lodash'
const CustomEvent = React.memo((props) => {
  const { event, children, onSelectEvent, label } = props;
  let status = 'yellow';
  if (event.status === 'published') {
    status = 'blue';
  }
  else if (event.status === 'live') {
    status = 'green';
  }
  else if (event.status === 'closed') {
    status = 'red';
  }

  return (props.viewstate === 'month' || props.type === 'date') ?
    (<div title={`${moment.unix(event.startDate.seconds).format('hh:mm A')} ${event.title}`}
      data-id={event.documentID}
      className={`${children.props.className} calendar-event-cell day-event-${status}`}
      style={{ ...children.props.style }}
      onClick={(e) => onSelectEvent(e, event)}>
      <div className={`${children.props.children.props.className}`}>
        <div className="calendar-week-icon"><b>{event.title} </b></div>
        <div className="rbc-event-label">{event.location}</div>
      </div>
    </div>) :
    (<div title={`${children.props.title}`} className={`${children.props.className} calendar-event-cell day-event-${status}`}
      style={{ ...children.props.style }}
      onClick={(e) => onSelectEvent(e, event)}>
      <div className={`${children.props.children[1].props.className}`}>
        <div className="calendar-week-icon"><b>{event.title} </b></div>
        <div className="rbc-event-label">{event.location}</div>
      </div>
    </div>);

})

export default CustomEvent