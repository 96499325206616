import React from 'react';
import { Placeholder } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

function PopoverListLoader(props) {

    const divstyle = {
        padding: '10px'
    }
    return (
        <>
            <div  style={divstyle} >
                <Placeholder>
                    <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                </Placeholder>
            </div>
            <div  style={divstyle} >
                <Placeholder>
                    <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                </Placeholder>
            </div>
        </>
    )
}

export default PopoverListLoader;