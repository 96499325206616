import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import CustomToolbar from './toolbar';
import CustomEvent from './eventView';
import { PopUpModal } from '../../../components';
import toast from 'toasted-notes';
import AddScheduler from './addScheduler';
import BookingSchedular from './bookingSchedular';
//import Year from './year'
import { combineLatest, of } from 'rxjs';
import { collection, doc } from 'rxfire/firestore';
import CommonHelper from '../../../services/common';
import { defaultfleetAssignTypes, defaultfleetStatuses } from '../../../services/enum';

const localizer = momentLocalizer(moment);
localizer.formats.yearHeaderFormat = 'YYYY'

const VehicleBookings = (props) => {
    const [fleetdata, setFleetdata] = useState()
    const [multiView, setMultiView] = useState(false)
    const [schedulerLogs, setSchedulerLogs] = useState([])
    const [calendarHeight, setCaleandarHeight] = useState(window.innerHeight - 100)
    const [viewstate, setViewState] = useState('month')
    const [loader, setloader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').add(-7, 'days').toDate()),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').add(7, 'days').toDate()),
        date: moment().format('YYYY-MM')

    })
    const [schedulerModel, setschedulerModel] = useState({ show: false })
    const [schedulerBookingModal, setschedulerBookingModal] = useState({ show: false })
    const fleetProStatus = props.dealersettings?.client?.fleetPro?.fleetProStatus?.filter(a => a.scheduler === true) || [];
    const fleetOptionsDF = props.dealersettings?.client?.fleetPro?.fleetOptionsDF || [];
    const _schedulerStatus = _.map(fleetProStatus, function (e) { return e.value })
    const _limit = 30;
    useEffect(() => {
        function handleResize() {
            setCaleandarHeight(window.innerHeight - 100)
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    // useEffect(() => {
    //     setloader(true);
    //     window.firebase.firestore().doc(`fleetPro/${props.fleetProID}`)
    //         .onSnapshot(doc => {
    //             if (doc.exists) {
    //                 setFleetdata(doc.data());
    //                 loadData(doc.data());
    //             }
    //             setloader(false)
    //         });
    // }, [])

    useEffect(() => {

        var collectionPromise = [];
        let _levelIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, props.dealersettings.level, true, true);
        let _levelIDNAme = CommonHelper.levelBasedID(props.dealersettings.level);
        //console.log('firebase_level==>', level.value, _levelIDs)
        collectionPromise.push(doc(window.firebase.firestore().doc(`fleetPro/${props.fleetProID}`)));
        if (props.dealersettings.level === 'individual') {
            for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
                let refeventData = window.firebase.firestore().collection('fleetProAssign')
                    .where('isDeleted', '==', false)
                    .where(`clientID`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                    .where('startDate', '>=', dateRange.startDate)
                    .where('startDate', '<=', dateRange.endDate)
                    .orderBy('startDate', 'desc')

                collectionPromise.push(refeventData ? collection(refeventData) : of([]));
            }
        } else {
            for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
                let refeventData = window.firebase.firestore().collection('fleetProAssign')
                    .where('isDeleted', '==', false)
                    .where(`fleetPro.${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                    .where('startDate', '>=', dateRange.startDate)
                    .where('startDate', '<=', dateRange.endDate)
                    .orderBy('startDate', 'desc')

                collectionPromise.push(refeventData ? collection(refeventData) : of([]));
            }
        }

        if (collectionPromise.length > 0) {
            window.unsubrefMonthFleetData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var _bookings = [];
                    allDocs.forEach(doc => {
                        if (!_.isArray(doc)) {
                            setFleetdata(doc.data());
                            _bookings.push(...(loadData(doc.data())))
                        }
                        else {
                            doc.forEach(booking => {
                                const _bData = booking.data();
                                if (_bData.fleetProID === props.fleetProID) {
                                    _bookings.push({
                                        name: _.find(defaultfleetAssignTypes, { value: _bData?.assignType }).name || null,
                                        color: '#049b8c',
                                        dealer: _.find(props.dealersettings.clients, { id: _bData.clientID })?.name || null,
                                        start: _bData.startDate ? moment.unix(_bData.startDate.seconds).toDate() : null,
                                        end: _bData.startDate ? moment.unix(_bData.startDate.seconds).toDate() : null,
                                        endDate: _bData.endDate || _bData.expectedReturnDate ? moment.unix((_bData.endDate || _bData.expectedReturnDate).seconds).toDate() : null
                                    })
                                }
                            })
                        }
                    });
                    setloader(false)
                    setSchedulerLogs(_bookings);
                });
        }
        else {
            setloader(false)
        }
    }, [dateRange])

    const loadData = (doc) => {
        let _logs = [];
        fleetProStatus.filter(a => props.dealersettings.level === 'individual' ? a.value === 'operational' : true).forEach(rec => {
            if (doc?.statusDaterange?.[rec.value]?.startDate && doc?.statusDaterange?.[rec.value]?.endDate) {
                _logs.push({
                    ...rec,
                    start: moment.unix(doc.statusDaterange[rec.value].startDate.seconds).toDate(),
                    end: moment.unix(doc.statusDaterange[rec.value].startDate.seconds).toDate(),
                    endDate: moment.unix(doc.statusDaterange[rec.value].endDate.seconds).toDate()
                })
            }
        });

        if (!_.isEmpty(doc.statusBooking)) {
            doc.statusBooking.filter(a => props.dealersettings.level === 'individual' ? a.status === 'operational' : true).forEach(rec => {
                if (rec?.startDate && rec?.endDate) {
                    _logs.push({
                        ...(_.find(fleetProStatus, { value: rec.status }) || _.find(defaultfleetStatuses, { value: rec.status })),
                        start: moment.unix(rec?.startDate.seconds).toDate(),
                        end: moment.unix(rec?.startDate.seconds).toDate(),
                        endDate: moment.unix(rec?.endDate.seconds).toDate()
                    })

                }
            });
        }

        if (doc?.defleetDate?.seconds) {
            _logs.push({
                ...(_.find(fleetProStatus, { value: 'defleeted' }) || _.find(defaultfleetStatuses, { value: 'defleeted' })),
                start: moment.unix(doc.defleetDate.seconds).toDate(),
                end: moment.unix(doc.defleetDate.seconds).toDate(),
                endDate: moment.unix(doc.defleetDate.seconds).toDate()
            })
        }
        return _logs
    }

    const handleChangeviewstate = (view) => {
        setViewState(view);
    }

    const onSelectEvent = (eventData) => {

    }

    const handleScheduler = () => {
        setschedulerModel({
            show: true,
            title: 'Scheduler',
            fleetProStatus,
            fleetProID: props.fleetProID,
            fleetdata: fleetdata?.statusDaterange,
            fleetPro: fleetdata,
            fleetOptionsDF
        })
    }

    const handleBooking = () => {
        setschedulerBookingModal({
            show: true,
            title: 'Booking',
            fleetProStatus,
            fleetProID: props.fleetProID,
            statusBooking: fleetdata?.statusBooking,
            fleetPro: fleetdata,
            fleetOptionsDF
        })
    }

    return (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog fullscreenmodal-lg add-triggers-fullscreen">
                <div className="modal-content modal-content-fullscreen">
                    <Modal.Header className="modal-header-fullscreen">
                        <div className="modal-title"><Translate text={props.title} />
                            {
                                props.subTitle ? (
                                    <span className="fleetpro-vehicle-grey">{props.subTitle}</span>
                                ) : (<></>)
                            }
                        </div>
                        <button type="button" className="close close-fullscreen float-right" onClick={() => { props.handleClose() }}>×</button>
                    </Modal.Header>
                    <Modal.Body className="modal-body-fullscreen">
                        <div className="popup-wrap-fullscreen h-100">
                            <div className="container-fluid pl-0 ">
                                <div className="calendar-panel fleetpro-calendar">
                                    {
                                        loader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <Calendar
                                        popup={false}
                                        localizer={localizer}
                                        events={_.map(schedulerLogs, function (c) {
                                            return {
                                                ...c,
                                                end: !_schedulerStatus.includes(c.value) ? c.endDate : (multiView ? c.endDate : c.start)
                                            };
                                        })}
                                        style={{ height: calendarHeight }}
                                        views={{
                                            month: true,
                                            //year: Year
                                        }}
                                        defaultView={viewstate}
                                        components={{
                                            //toolbar: CustomToolbar,
                                            toolbar: propstb => (<CustomToolbar {...propstb}
                                                dealersettings={props.dealersettings}
                                                viewstate={viewstate}
                                                handleChangeviewstate={handleChangeviewstate}
                                                setDateRange={(_dateRange) => {
                                                    if (dateRange.date !== _dateRange.date) {
                                                        setDateRange(_dateRange)
                                                        setloader(true)
                                                    }
                                                }}
                                                handleScheduler={handleScheduler}
                                                handleBooking={handleBooking}
                                                accessToVehicleScheduler={props.accessToVehicleScheduler}
                                                accessToVehicleBooking={props.accessToVehicleBooking}
                                                multiView={multiView}
                                                MultiViewChecked={(_chked) => { setMultiView(_chked) }}
                                            />),
                                            eventWrapper: propsevt => (<CustomEvent {...propsevt}
                                                dealersettings={props.dealersettings}
                                                onSelectEvent={onSelectEvent}
                                                viewstate={viewstate} />)
                                        }}
                                        onShowMore={(events, date) => {
                                            setDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment(date).startOf('month').add(-7, 'days').toDate()),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment(date).endOf('month').add(7, 'days').toDate()),
                                            })
                                            //setViewState('day')
                                        }}
                                        selectable
                                        dayPropGetter={(date) => {
                                            return {
                                                className: 'special-day',
                                            }
                                        }
                                        }
                                        messages={{
                                            showMore: function showMore(total) {
                                                return `view more`;
                                            },
                                            year: 'Year'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>

            <PopUpModal show={schedulerModel.show}>
                <AddScheduler
                    {...schedulerModel}
                    handleClose={() => {
                        setschedulerModel({ show: false })
                    }}
                />
            </PopUpModal>

            <PopUpModal show={schedulerBookingModal.show}>
                <BookingSchedular
                    {...props}
                    {...schedulerBookingModal}
                    handleClose={() => {
                        setschedulerBookingModal({ show: false })
                    }}
                />
            </PopUpModal>
        </>
    )
}

export default VehicleBookings;