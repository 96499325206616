/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';
/** COMPONENTS */
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import { ContentOverlay } from '../../components'
import Translate from '../../constants/translate';
import parser from "cron-parser";


const RosterReport = (props) => {
    const [client, setClient] = useState({});
    const [clientUsers, setClientUsers] = useState([])
    const [rosterSettings, setRosterSettings] = useState()
    const [monthData, setMonthData] = useState([])
    const [loader, setLoader] = useState(true)
    const [selectedTeams, setSelectedTeams] = useState()
    const [tableFields, setTableFields] = useState([])
    const [monthHolidays, setMonthHolidays] = useState([])

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let teamid = params.get('id');
    let _date = params.get('date');
    _date = _date ? _date : moment().format('MMM-YYYY');

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setClient(doc.data());
                }
            })
    }, [])

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}/roster/${props.clientid}`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setRosterSettings(doc.data())
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(client))
            return;

        window.firebase.firestore().doc(`clientSettings/${client.settingsID}/teams/${teamid}`)
            .get()
            .then(doc => {
                if (doc.exists && doc.data().user_ids && doc.data().user_ids.length > 0) {
                    setSelectedTeams(doc.data());
                }
            });

    }, [client])

    useEffect(() => {
        if (_.isEmpty(selectedTeams))
            return;

        let userPromise = [];
        selectedTeams.user_ids.forEach(user => {
            if (user)
                userPromise.push(window.firebase.firestore().doc(`users/${user}`).get());
        })

        Promise.all(userPromise)
            .then(snapshot => {
                let users = [];
                snapshot.forEach(doc => {
                    users.push({
                        ...doc.data(),
                        id: doc.id
                    })
                })
                setClientUsers(users)
            })

    }, [selectedTeams])


    useEffect(() => {
        if (_.isEmpty(rosterSettings) || _.isEmpty(clientUsers))
            return;

        var startDate = moment(_date).startOf('month');
        var endDate = moment(_date).endOf('month');
        var _days = [{
            name: 'User',
            value: 'userName',
            default: true,
            flex: 0,
            width: 100,
            fixed: true
        }];
        for (var m = startDate; m.isBefore(endDate); startDate.add(1, 'days')) {
            _days.push({
                name: <div className='text-center'>{m.format('DD')}<span className="roster-day">{m.format('ddd')}</span></div>,
                value: m.format('YYYY-MM-DD'),
                default: true,
                flex: 0,
                width: Math.floor((1060 - 100) / moment(_date).daysInMonth())
            })
        }
        setTableFields(_days)
        window.firebase.firestore().collection('roster')
            .where('clientID', '==', props.clientid)
            .where('month', '==', _date)
            .get()
            .then(querySnapshot => {
                let snapshotData = [];
                querySnapshot.forEach((rec) => {
                    snapshotData.push({ ...rec.data(), 'id': rec.id })
                })
                let _data = [];
                let i = 1;
                _.orderBy(clientUsers, ['name'], ['asc']).forEach(rec => {
                    _data.push({
                        documentID: rec.id,
                        name: rec.name,
                        profileImage: rec.profileImage,
                        userName: <div className="roster-user-list-report">
                            <div className="roster-user-avatar-report">
                                <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} />
                            </div>
                            {rec.name}
                        </div>,
                        department: rec.department,
                        sortOrder: rec.department === 'Management' ? 0 : 1,
                        ...setMonthUserData(snapshotData, rec.documentID, _days, clientUsers.length > 4 ? i : 0)

                    })
                    i++;
                })
                let managers = _data.filter(a => a.sortOrder === 0);
                let managerslastindex = managers[managers.length - 1];
                _data = _data.map(rec => {
                    return managerslastindex && managerslastindex.documentID === rec.documentID ? {
                        ...rec,
                        borderClass: 'roster-border-cell-report'
                    } : {
                        ...rec
                    }
                })
                setMonthData(_.orderBy(_data, ['sortOrder'], ['asc']));
                setLoader(false)
                if (document.getElementsByClassName('table table-bordered-tbody')[0]) {
                    document.documentElement.style.setProperty('--verticalText', document.getElementsByClassName('table table-bordered-tbody')[0].clientHeight + 'px')
                }
            })
    }, [rosterSettings, clientUsers])

    const setMonthUserData = (_snapshotData, userid, _days, userIndex) => {
        let _data = {};
        let _monthHolidays = []
        _days.filter(a => !Boolean(a.fixed)).forEach(rec => {
            let _date = _.find(_snapshotData, { date: rec.value });
            let rosterData = _date ? _.find(rosterSettings?.rosterCategories, { value: _date[userid] }) : ''
            let _holiday = checkHoliday(rec.value);
            if (!_.isEmpty(_holiday)) {
                _monthHolidays = [
                    ..._monthHolidays.filter(a => a.value !== _holiday.value),
                    _holiday
                ];
            }
            _data = {
                ..._data,
                [`str-${rec.value}`]: rosterData ? rosterData.value : '',
                [rec.value]: rosterData ? (<><div className='roster-cell-select roster-cell-align-report' style={{ backgroundColor: rosterData.color }}>{rosterData.value}</div>
                </>) : !_.isEmpty(_holiday) ? (<>
                    {
                        userIndex === 1 && Boolean(_holiday.dealershipOff) ? (
                            <div className="dynamic-text-vertical-report" style={{ backgroundColor: _holiday.color }}>
                                <span>{_holiday.name}</span>
                            </div>
                        ) : (<></>)
                    }
                    <div className={`roster-cell-select roster-cell-align-report`} style={{ backgroundColor: _holiday.color, cursor: _holiday.clickable ? 'pointer' : 'default' }}></div>
                </>) : '',
            }
        })
        setMonthHolidays(_monthHolidays);
        return _data;
    }

    const checkHoliday = (_date) => {
        let result = {};
        if (rosterSettings?.rosterHolidays) {
            rosterSettings.rosterHolidays.forEach(rec => {
                if (rec.recurrence && rec.cron) {
                    const options = {
                        currentDate: new Date(`${_date} 00:00`),
                        iterator: true,
                        //tz: !_.isEmpty(client.timezone) ? client.timezone : moment.tz.guess()
                    };
                    const interval = parser.parseExpression(rec.cron, options);
                    const _ms = interval?.next()?.value?._date?.ts;
                    if (_ms && moment.unix(_ms / 1000).format('YYYY-MM-DD') === _date) {
                        result = rec;
                        return false;
                    }
                }
                else if (!rec.recurrence && rec.dateFrom && rec.dateTo && moment(_date).isBetween(`${rec.dateFrom} 00:00`, `${rec.dateTo} 23:59`, undefined, '[]')) {
                    result = rec;
                    return false;
                }
            })
        }
        return result;
    }

    return (
        <>
            {
                loader ? <>
                    <div className="mt-5"><ContentOverlay active={true} /></div>
                </>
                    :
                    <>
                        <div className="A4 landscape report-data-loaded report-data-load">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }
                                                </div></td>

                                                <td width="40%"><div className="header-right"> {`${selectedTeams?.name ? 'Roster - ' + selectedTeams.name.toUpperCase() : 'ROSTER REPORT'}`} <br></br>
                                                    <span> {moment().format('DD/MM/YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">
                                    <div className='filter-panel'>
                                        <div className='float-left fleet-head'>
                                            <h3 style={{ fontSize: '14px' }}>{moment(_date).format('MMMM YYYY')}</h3>
                                        </div>
                                        {
                                            monthHolidays && monthHolidays.length > 0 ? (<div className='float-right'>
                                                <div className="roster-pipeline-counter pl-2" style={{ borderLeft: '1px solid #ccc', fontSize: '9px' }}>
                                                    <div className="roster-counter-nav">
                                                        <ul>
                                                            {
                                                                monthHolidays.map((r, i) => {
                                                                    return <li key={i}><div className="roster-counter ml-2">
                                                                        <span className="d-inline-flex badge badge-pill roster-badge-counter-report p-2 mr-2" style={{ backgroundColor: r.color, fontSize: '8px' }}></span>
                                                                        {r.name} </div>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>) : (<></>)
                                        }
                                        {
                                            rosterSettings?.rosterCategories ?
                                                (<div className='float-right'><div className='float-right'>
                                                    <div className="roster-pipeline-counter mr-2 ">
                                                        <div className="roster-counter-nav" style={{ fontSize: '9px' }}>
                                                            <ul>
                                                                {
                                                                    rosterSettings?.rosterCategories.filter(a => a.active === true).map((r, i) => {
                                                                        return <li key={i}><div className="roster-counter ml-2">
                                                                            <span className="d-inline-flex badge badge-pill roster-badge-counter-report p-2 mr-2" style={{ backgroundColor: r.color, fontSize: '8px' }}>{r.value}</span>
                                                                            {r.name} </div>
                                                                        </li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                ) : (<></>)
                                        }
                                    </div>
                                    <div className="roster-calendar-panel">
                                        <div className="roster-common-table-report">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        {
                                                            tableFields.map((rec, index) => {
                                                                return <th key={index} scope="col" className={`roster-head-light ${rec.value === 'userName' ? 'user-head-fix' : 'text-center'}`}>{rec.name}</th>
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody className='table table-bordered-tbody'>
                                                    {
                                                        monthData.map((rec, index) => {
                                                            return <tr key={index}>
                                                                {
                                                                    tableFields.map((t, i) => {
                                                                        return <td className={`${rec.borderClass}`} key={i}>{rec[t.value]}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address}</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>


                    </>


            }
        </>



    )
};

export default RosterReport;
