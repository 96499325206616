/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopUpModal } from '../../../components';
import AddOnsInfo from './addOnsInfo';
import AddPackage from './addPackage'
import { default as _images } from '../../../images';
import { serviceStatus } from '../../../services/enum';
import { firestoreDB } from '../../../services/helper';

const AddOns = (props) => {
    const [searchText, setSearchText] = useState('')
    const [selectedAddOns, setAddOns] = useState(props.selectedAddOns ? props.selectedAddOns : [])
    const [showAddOns, setshowAddOns] = useState(false)
    const [selectedInfos, setSelectedInfos] = useState(null)
    const [showAddPackage, setshowAddPackage] = useState(false)

    useEffect(() => {
        setAddOns(props.selectedAddOns ? props.selectedAddOns : [])
    }, [props.selectedAddOns])

    // useEffect(() => {
    //     if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

    //         const setttings = props.dealersettings.client.settings;

    //         let _titles = [];
    //         setttings.titles && setttings.titles.forEach((doc) => {
    //             _titles.push({
    //                 value: doc.value,
    //                 label: doc.name
    //             });
    //         });

    //         //setTitles(_titles);

    //     }
    // }, [props.dealersettings])

    const handleAdd = (objData) => {
        let _dynamicFields = Object.assign([], selectedAddOns);
        const { serviceID, isOwnerResponsed, dealersettings } = props;
        objData.oKToDo = true;
        objData.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;
        objData.addedBy = dealersettings ? dealersettings.id : '';
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.serviceID = serviceID;

        objData.clientID = dealersettings?.client?.id ? dealersettings?.client?.id : null;
        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        const updateRef = firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/servicePackages/${objData.documentID}`);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            toast.notify('Package added successfully', {
                duration: 2000
            })

            _dynamicFields.push(objData);
            setAddOns(_dynamicFields);

            // if (isOwnerResponsed) {
            //     let _objData = {
            //         status: serviceStatus.ADVISORREQUESTED,
            //         ownerResponse: null,
            //         modifiedBy: dealersettings ? dealersettings.id : '',
            //         modifiedDate: window.firebase.firestore.Timestamp.now(),
            //         modifiedFrom: 'web'
            //     }

            //     const updateJobRef = window.firebase.firestore().collection('serviceJobs').doc(serviceID);
            //     updateJobRef.update(_objData).then((docRef) => {
            //     }).catch((error) => {
            //         console.error("Error updating enquiries: ", error);
            //     });
            // }
            if (props.handleStatusReScheduled)
                props.handleStatusReScheduled();

        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    }

    const handleRemove = (objData) => {
        const { serviceID, isOwnerResponsed, dealersettings, service } = props;
        const { value, documentID } = objData;
        let _dynamicFields = Object.assign([], selectedAddOns);
        let _selected = Object.assign({}, _dynamicFields.filter(m => m.value === value)[0]);
        _dynamicFields = _dynamicFields.filter(m => m.value !== value)
        setAddOns(_dynamicFields);

        firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/servicePackages/${_selected.documentID}`).delete();

        if (isOwnerResponsed) {
            let _objData = {
                status: serviceStatus.ADVISORREQUESTED,
                ownerResponse: null,
                responseDate: null,
                startDate: null,
                completeDate: null,
                jobAllocated: false,
                jobConfirmed: false,
                partsConfirmed: false,
                workshopConfirmed: false,
                modifiedBy: dealersettings ? dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }

            if (service) {
                let _statusHistory = Object.assign([], service.statusHistory);
                _statusHistory.push({
                    date: window.firebase.firestore.Timestamp.now(),
                    status: _objData.status,
                    user: localStorage.uid
                });
                _objData.statusHistory = Object.assign([], _statusHistory);
            }

            const updateJobRef = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(serviceID);
            updateJobRef.update(_objData).then((docRef) => {
            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }
    }

    const divStyle = {
        height: '400px',
        overflow: 'auto',
        position: "relative",
        width: '100%'
    };

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    let serviceAddOns = !_.isEmpty(props.serviceAddOns) && props.serviceAddOns.filter(item => (searchText ? item.name.toLowerCase().includes(searchText.toLowerCase()) : true));

    const checklists = _.chain(_.orderBy(serviceAddOns, ['category'], ['asc']))
        .groupBy("category")
        .map((value, key) => ({ category: key, sortOrder: value[0].sortOrder, lists: value }))
        .value()

    const handleClosePackage = (info) => {
        if (!_.isEmpty(info)) {
            //console.log(info)
            handleAdd(info);
        }
        setshowAddPackage(false); setSelectedInfos(null);
    }

    return (
        <>

            <Modal
                id="AddOns"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-packages">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Service Packages'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="package-item-head pr-0">
                                    <input
                                        type="text"
                                        aria-label="Search"
                                        placeholder="Search..."
                                        value={searchText ? searchText : ''}
                                        autoComplete="off"
                                        onChange={e => {
                                            setSearchText(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="package-item-sub-head">
                                    {
                                        !_.isEmpty(serviceAddOns) ? <div className="float-left package-item-sub-head-count"><strong>{serviceAddOns.length}</strong> <Translate text={'Packages'} /></div> : <></>
                                    }
                                    {
                                        !_.isEmpty(selectedAddOns) ? <div className="float-right">
                                            <div className="list-count-btn ml-3 float-left"><span><Translate text={'Selected Packages'} /></span><span className="list-count"> {selectedAddOns.length} </span></div>
                                        </div> : <></>
                                    }
                                </div>
                                <div style={divStyle}>
                                    <div className="package-item-loop">
                                        {
                                            !_.isEmpty(serviceAddOns)
                                                ?
                                                <>

                                                    {
                                                        checklists && _.orderBy(checklists, ['sortOrder'], ['asc']).map((data, index) => {
                                                            return <Fragment key={index}>
                                                                <div className="pop-subhead"><h4>{data.category}</h4></div>
                                                                {
                                                                    data.lists && _.orderBy(data.lists, ['name'], ['asc']).map((_info, index) => {
                                                                        return <div key={index} className="service-packages-box">
                                                                            {(() => {
                                                                                let info = !_.isEmpty(selectedAddOns) && _.find(selectedAddOns, { value: _info.value }) ? _.find(selectedAddOns, { value: _info.value }) : _info
                                                                                return (
                                                                                    <>
                                                                                        <div className="flex-package">
                                                                                            <div className="package-item-icon"> <i className="ico icon-service-package"> </i> </div>
                                                                                            <div className="package-data">
                                                                                                <div className="package-item-title"
                                                                                                    onClick={(e) => { e.preventDefault(); setshowAddOns(true); setSelectedInfos(info); }}>
                                                                                                    {info.name}</div>
                                                                                                <div className="package-item-info"> {info.price ?
                                                                                                    <NumberFormat value={info.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                    : ''}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="package-add-button">
                                                                                                <button className="btn btn-sm btn-default add-package-btn mr-2"
                                                                                                    onClick={(e) => { e.preventDefault(); setshowAddOns(true); setSelectedInfos(info); }}>
                                                                                                    <i className="ico icon-info mr-1"></i> <Translate text={'info'} />
                                                                                                </button>
                                                                                            </div>
                                                                                            <div className="package-add-button">
                                                                                                {
                                                                                                    !_.isEmpty(selectedAddOns) && _.find(selectedAddOns, { value: info.value })
                                                                                                        ?
                                                                                                        <button className="btn btn-sm selected-package-btn"
                                                                                                            onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                handleRemove(info);

                                                                                                            }}><i className="ico icon-check mr-1"> </i><Translate text={'Selected'} /></button>
                                                                                                        :
                                                                                                        <button className="btn btn-sm btn-default add-package-btn"
                                                                                                            onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                //handleAdd(info);
                                                                                                                //console.log('handleAdd', info)
                                                                                                                setshowAddPackage(true); setSelectedInfos(info);

                                                                                                            }}><i className="ico icon-add mr-1"></i> <Translate text={'add'} /></button>

                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })()}

                                                                        </div>
                                                                    })
                                                                }
                                                            </Fragment>
                                                        })
                                                    }

                                                </>
                                                :
                                                <>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-cafe-img">
                                                                <i className="ico icon-service-package"></i> </div>
                                                            <div className="no-data-txt mt-2"> <Translate text={'No packages available'} /></div>
                                                        </div>

                                                    </div>
                                                </>
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                </Modal.Body>
                {/* <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-default float-left"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose(null);

                        }}
                    ><Translate text={'cancel'} />
                    </button>

                    <button
                        type="button"
                        className="btn btn-primary float-right"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose(selectedAddOns, true);
                        }}
                    >
                        <Translate text={'done'} />
                    </button>

                </Modal.Footer> */}
            </Modal>

            <PopUpModal show={showAddOns}>
                <AddOnsInfo
                    show={showAddOns}
                    handleClose={() => { setshowAddOns(false); setSelectedInfos(null); }}
                    selectedInfos={selectedInfos}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>

            <PopUpModal show={showAddPackage}>
                <AddPackage
                    show={showAddPackage}
                    title={'Service Package'}
                    handleClose={handleClosePackage}
                    lookup={selectedInfos}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>

        </>
    )
}

export default AddOns