import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { InputText } from '../../../../components'
import Translate from '../../../../constants/translate';
import { validateEmail } from "../../../../services/helper";

const AddEmailAccount = (props) => {
    const [email, setEmail] = useState(props.dealer.email);
    const [loader, setLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setEmail(value);

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const connectNylas = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(email) || !validateEmail(email)) {
            formIsValid = false;
            errors['email'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        authenticateUser()
    }

    const authenticateUser = async () => {
		const nylasUrlForAuthentication = window.firebase
			.functions()
			.httpsCallable('nylas-urlForAuthentication');
		const authUrl = await nylasUrlForAuthentication({
			options: {
				loginHint: email,
				redirectURI: `${window.location.origin}/settings/company?key=email`,
				scopes: ['email.modify', 'email.send'],
			},
		});
		if (authUrl.data) {
			console.log(authUrl.data);
			window.location.replace(authUrl.data);
		}
	};


    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-email-account"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Email Address'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'email address'}
                                        className={`form-control ${errorFields["email"]}`}
                                        name="email"
                                        onChange={handleOnChange}
                                        value={email}
                                        id={'dealer-email-account'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => connectNylas(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'Connect'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEmailAccount;