/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { ReactSelect, InputText } from '../../components';




export default class EvalSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                year: '',
                make: '',
                model: '',
                modelDescription: ''
            },
            loader: {},
            years: [],
            makes: [],
            models: [],
            vehicleSource: [],
            isLoading: false
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = async () => {


        this.setState({ loader: { 'year': true } })
        let _response = await this.evalSearch();


        if (_response?.data?.success) {


            let years = [];
            _response?.data?.data[`field-values`] && _response.data.data[`field-values`].forEach((doc) => {
                years.push({
                    value: doc,
                    active: true,
                    label: doc
                });
            });

            this.setState({ years: years, loader: {} })
        }
        else if (_response?.data?.message) {

            Swal.fire(_response.data.message, '', 'info')
        }
        else {

            Swal.fire('No record found!', '', 'info')
            this.forceUpdate()
        }
    }


    evalSearch = async () => {
        const { dealersettings } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';

        const objData = Object.assign({}, this.state.fields);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        let _param = {
            ...objData,
            clientID: clientID,
            lookup: true
        }
        //console.log(`evalexpert-search-evalSearch`, _param)
        const evalXpertSearch = window.firebase.functions().httpsCallable(`evalexpert-search`);
        return await evalXpertSearch(_param)
    }




    handleYearChange = (e, data) => {
        let fields = Object.assign({});

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        this.setState({ fields: fields, makes: [], models: [], vehicleSource: [], loader: { 'make': true } }, async () => {
            let _response = await this.evalSearch();


            if (_response?.data?.success) {


                let makes = [];
                _response?.data?.data[`field-values`] && _response.data.data[`field-values`].forEach((doc) => {
                    makes.push({
                        value: doc,
                        active: true,
                        label: doc
                    });
                });

                this.setState({ makes: makes, loader: {} })
            }
            else if (_response?.data?.message) {

                Swal.fire(_response.data.message, '', 'info')
            }
            else {

                Swal.fire('No record found!', '', 'info')
                this.forceUpdate()
            }
        });

    }

    handleMakeChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        fields.model = null;
        fields.variant = null;
        fields.modelDescription = null;
        fields.body = null;
        fields.transmission = null;
        fields.variant = null;

        this.setState({ fields: fields, models: [], vehicleSource: [], loader: { 'model': true } }, async () => {

            let _response = await this.evalSearch();


            if (_response?.data?.success) {


                let models = [];
                _response?.data?.data[`field-values`] && _response.data.data[`field-values`].forEach((doc) => {
                    models.push({
                        value: doc,
                        active: true,
                        label: doc
                    });
                });

                this.setState({ models: models, loader: {} })
            }
            else if (_response?.data?.message) {

                Swal.fire(_response.data.message, '', 'info')
            }
            else {

                Swal.fire('No record found!', '', 'info')
                this.forceUpdate()
            }
        });

    }

    handleModelChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        fields.variant = null;
        fields.modelDescription = null;
        fields.body = null;
        fields.transmission = null;
        fields.variant = null;

        this.setState({ fields: fields, vehicleSource: [], loader: { 'variant': true } }, async () => {

            let _response = await this.evalSearch();
            //console.log('evalexpert-search-lookup', _response);

            if (_response?.data?.success) {
                if (_response.data.data[0]) {
                    let _data = _response.data.data[0];
                    let fields = Object.assign({}, this.state.fields);
                    fields['modelDescription'] = _data.Description ? _data.Description : null;
                    this.setState({ fields: fields, loader: {} })
                }
                else {
                    //console.log('evalexpert-search-lookup-', _response.data.data);
                    let _data = _response.data.data;
                    const _sources = this.state.vehicleSource;

                    let variants = [];
                    _response?.data?.data[`field-values`] && _response.data.data[`field-values`].forEach((doc) => {
                        variants.push({
                            value: doc,
                            active: true,
                            label: doc
                        });
                    });
                    _sources.push({
                        name: _data['field-required'],
                        message: _data['message'],
                        options: variants
                    })
                    this.setState({ vehicleSource: _sources, loader: {} })
                }

            }
            else if (_response?.data?.message) {

                Swal.fire(_response.data.message, '', 'info')
            }
            else {

                Swal.fire('No record found!', '', 'info')
                this.forceUpdate()
            }
        });

    }

    handleVariantChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';
        fields.modelDescription = null;

        this.setState({ fields: fields, loader: { 'modelDescription': true } }, async () => {

            let _response = await this.evalSearch();
            //console.log('evalexpert-search-lookup', _response);

            if (_response?.data?.success) {
                if (_response.data.data[0]) {
                    let _data = _response.data.data[0];
                    let fields = Object.assign({}, this.state.fields);
                    fields['modelDescription'] = _data.Description ? _data.Description : null;
                    this.setState({ fields: fields, loader: {} })
                }
                else {
                    //console.log('evalexpert-search-lookup-', _response.data.data);
                    let _data = _response.data.data;
                    const _sources = this.state.vehicleSource;

                    let variants = [];
                    _response?.data?.data[`field-values`] && _response.data.data[`field-values`].forEach((doc) => {
                        variants.push({
                            value: doc,
                            active: true,
                            label: doc
                        });
                    });
                    _sources.push({
                        name: _data['field-required'],
                        message: _data['message'],
                        options: variants
                    })

                    this.setState({ vehicleSource: _sources, loader: {} })
                }

            }
            else if (_response?.data?.message) {
                Swal.fire(_response.data.message, '', 'info')
            }
            else {
                Swal.fire('No record found!', '', 'info')
                this.forceUpdate()
            }
        });

    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ isLoading: true });
        const { dealersettings, vinNum } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';
        const objData = Object.assign({}, this.state.fields);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (vinNum)
            objData.vin = vinNum;

        const evalXpertSearch = window.firebase.functions().httpsCallable(`evalexpert-search`);
        evalXpertSearch({
            ...objData,
            clientID: clientID,
            manual: true
        }).then((_response) => {

            //console.log('evalexpert-search-handleSubmit', _response);
            //check for success response
            if (_response && _response.data && _response.data.success) {
                //console.log('evalexpert-search-handleSubmit-', _response.data.data[0]);
                this.props.handleClose(_response.data.data[0]);

            }
            else if (_response?.data?.message) {
                Swal.fire(_response.data.message, '', 'info')
            }
            else {
                Swal.fire('No record found!', '', 'info')
                this.forceUpdate()
            }
        });

    };

    render() {
        const { year, make, model, modelDescription } = this.state.fields;
        const { show, isGenericLayout, vinNum } = this.props;
        const { years, makes, models, loader, isLoading, vehicleSource, fields } = this.state;

        const divStyle = {
            minHeight: '225px'
        };
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'Select Vehicle'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className={`container-fluid ${isGenericLayout ? 'pl-0 pr-0' : ''} ${modelDescription ? '' : 'mb-5'}`}>
                            <div className={`row ${isGenericLayout ? 'w-100 d-block border-0' : ''}`}>
                                <div className="search-pop form-style mt-0" style={divStyle}>
                                    <div className="form-row">

                                        <div className="form-group col-md-12">
                                            <div className="vehicle-status-alert alert-custom" >
                                                {"Couldn't find vehicle. Please select from below to process."}

                                            </div>
                                        </div>

                                        {
                                            vinNum
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label > <Translate text={'chassisNo'} /></label>
                                                        <InputText
                                                            type="text"
                                                            name="__vinNum"
                                                            value={vinNum}
                                                            onChange={(e) => { e.preventDefault(); }}
                                                            autoComplete="off"
                                                            placeholder={'chassis number'}
                                                            className={`form-control`}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            !_.isEmpty(years)
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label > <Translate text={'year'} /></label>
                                                        <ReactSelect
                                                            options={years}
                                                            name={"year"}
                                                            placeholder={'Please select a year.'}
                                                            onChange={this.handleYearChange}
                                                            value={year}
                                                            classNamePrefix={`basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }


                                        {
                                            !_.isEmpty(makes)
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label > <Translate text={'make'} /></label>
                                                        <ReactSelect
                                                            options={makes}
                                                            name={"make"}
                                                            placeholder={'Please select a make.'}
                                                            onChange={this.handleMakeChange}
                                                            value={make}
                                                            classNamePrefix={`basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            !_.isEmpty(models)
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label > <Translate text={'model'} /></label>
                                                        <ReactSelect
                                                            options={models}
                                                            name={"model"}
                                                            placeholder={'Please select a model.'}
                                                            onChange={this.handleModelChange}
                                                            value={model}
                                                            classNamePrefix={`basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {

                                            vehicleSource && vehicleSource.map((_data, index) => {
                                                return <div key={index} className="form-group col-md-12">
                                                    <label > <Translate text={_data.name} /></label>
                                                    <ReactSelect
                                                        options={_data.options}
                                                        name={_data.name}
                                                        placeholder={_data.message}
                                                        onChange={this.handleVariantChange}
                                                        value={fields[_data.name]}
                                                        classNamePrefix={`basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            })

                                        }
                                        {
                                            modelDescription
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label > <Translate text={'vehicle'} /></label>
                                                        <div className="vehicle-status-alert alert-user" >
                                                            {modelDescription}

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            !_.isEmpty(loader)
                                                ?
                                                <><div className="form-group col-md-12 mt-4"><div style={{ textAlign: 'center' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                </div></div></>
                                                :
                                                <></>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {
                        modelDescription
                            ?
                            <>
                                <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                                    <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                                        {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                        <Translate text={'select'} />
                                    </button>
                                </Modal.Footer>
                            </>
                            :
                            <></>
                    }

                </Modal>
            </>


        );
    }
}

