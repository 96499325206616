/** LIBRARIES */
import React from 'react';
import { Spinner } from 'react-bootstrap'

function ContentOverlay(props) {

    return (
        <div className={`spinner-loader h-100 ${props.w100} ${props.active ? '' : 'hide'}`}>
            <div className={`d-flex h-100 justify-content-center align-items-center text-${props.isLight ? 'light' : 'primary'}`}>
                <Spinner animation="border" role="status" variant={props.isLight ? 'light' : 'primary'}>
                </Spinner>
                <p className="text-muted mt-3">{props.text}</p>
            </div>
        </div>
    )
}

export default ContentOverlay