/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import { enquiryStatus } from '../../../services/enum';

const OwnedVehicleInfo = ({ requirement, clientUsers, sidepanelOpen, noItemClass, handleEdit, isDeivered, dealersettings, hideMore }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();
    const {
        enquiryID, displayID, dealerName,
        make, model, saleType, type, status, currentlyNotOwned,
        year, extColor, owner, enquiry
    } = requirement;


    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };
    return (
        <div className={`${noItemClass ? '' : 'vehicle-item'} flex-vehicle`} id={`owned-vehicle-${enquiryID}`}>
            {
                hideMore
                    ?
                    <></>
                    :
                    <>
                        <div className="quickview-activity-more">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                setEditshow(!editshow)
                                if (editshow)
                                    document.addEventListener('click', setEditshow(false));
                            }}><i className="ico icon-more"></i></a>
                            <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                <ul className="more-dropdown">
                                    <li>
                                        <a href="#" onClick={(e) => { e.preventDefault(); handleEdit(requirement) }}>
                                            {
                                                (isDeivered)
                                                    ?
                                                    <><i className="ico icon-zoom"></i> <Translate text={'view'} /> </>
                                                    :
                                                    <><i className="ico icon-edit"></i> <Translate text={'edit'} /> </>
                                            }
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
            }

            <div className="vehicle-item-image" onClick={(e) => { e.preventDefault(); sidepanelOpen(enquiryID, enquiry) }}>
                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                    <img src={CommonHelper.showBrandLogo(dealersettings, make)} className="img-object-fit-contain" width="70" height="70" alt=""/>
                </a>
            </div>

            <div className="vehicle-data" onClick={(e) => { e.preventDefault(); sidepanelOpen(enquiryID, enquiry) }}>
                <div className="vehicle-item-title">{make ? <>{make + (model ? model : '')}
                    {saleType ? <div className="badge badge-pill badge-white ml-1 badge-sm">
                        {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                            saleType, '')}
                    </div> : <></>}</> : <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>} </div>
                {displayID ? <div className="vehicle-item-info">{displayID}</div> : <></>}
                {dealerName ? <div className="vehicle-item-info blue-color">{dealerName}</div> : <></>}
                <div className="vehicle-item-info mt-2">
                    {
                        owner
                            ?
                            <>
                                <div className="owner-view">
                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                        <img src={CommonHelper.getUserImagebyId(clientUsers, owner)} className="img-object-fit" />
                                    </a>
                                </div>
                                {CommonHelper.getUserNamebyId(clientUsers, owner)}
                            </>

                            :
                            <></>
                    }
                    <div className="tradin-item-button d-inline-block w-100">

                        <div className={`badge badge-pill badge-${!currentlyNotOwned ? 'available' : 'sold'}-fill`} ><Translate text={!currentlyNotOwned ? 'currentlyOwned' : 'Previously Owned'} /></div>
                        {
                            status ? <>
                                {(() => {
                                    let __status = (status && status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : status;
                                    const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
                                    let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

                                    return <>
                                        {
                                            _objCurrentStatus
                                                ?
                                                <>
                                                    <span
                                                        className={`status-${status === 'open' ? 'open' : 'empty'} float-right`}
                                                        style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                    >
                                                        <Translate
                                                            text={_objCurrentStatus.name}
                                                            upperCase={true}
                                                        />
                                                    </span>
                                                </>
                                                :
                                                <>


                                                </>
                                        }
                                    </>
                                })()}

                            </>
                                : (<></>)
                        }


                    </div>
                </div>


                {/* {status ?
                    <div className="tradin-item-button">
                        <div className={`badge badge-${status.toLowerCase()}`}>{status.toUpperCase()}</div>
                    </div>
                    :
                    <></>
                } */}
            </div>
        </div>
    )
};
export default OwnedVehicleInfo;


