import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import moment from 'moment'
import _images from '../../../images'
import { CustomToggle, CustomMenu } from '../../../components/customdropdown';
import Swal from 'sweetalert2'
import { BarChart, ContentOverlay, InputAutoNumber, PopUpModal, ReactMultiSelect } from '../../../components';
import EnquiryList from '../../dashboard/enquiryList'
import { targets, colors, saleType } from './viewModel'



const YearlySalesPerformance = (props) => {
    const [loader, setLoader] = useState(true)
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false
    })
    const [refreshData, setRefreshData] = useState(false)

    const [schedueReport, setSchedeReport] = useState({});
    const [multiSchedule, setMultiSchedule] = useState([]);
    const [allData, setAllData] = useState([]);
    const [reportData, setReportData] = useState([])
    const [reportDoc, setReportDoc] = useState({})
    const [makes, setMakes] = useState([])
    const [counts, setCounts] = useState([])
    const [filter, setFilter] = useState(null)
    const [objFilter, setObjFilter] = useState({})
    const [years, setYears] = useState([])
    const [textEditor, setTexteditor] = useState({})
    const [editFields, setEditFields] = useState({})

    useEffect(() => {
        if (_.isEmpty(props.dealersettings.clients))
            return

        const _makes = [];
        props.dealersettings.clients.forEach(client => {
            if (client?.brandIDs?.length) {
                _makes.push(...client.brandIDs.map(r => { return _.find(props.dealersettings.allMakeModels, { documentID: r }) }))
            }
        })
        setMakes(_.uniqBy(_makes.filter(a => a?.value), 'value'))
    }, [])

    useEffect(() => {
        window.firebase.firestore().doc(`reports/yealySales-${props.dealersettings.oemID}`)
            .onSnapshot(snap => {
                if (snap.exists) {
                    setReportDoc(snap.data())
                } else {
                    window.firebase.firestore().doc(`reports/yealySales-${props.dealersettings.oemID}`).set({
                        documentID: `yealySales-${props.dealersettings.oemID}`,
                        oemID: props.dealersettings.oemID,
                        name: 'Yearly Sales Performance Report'
                    }, { merge: true })
                }
            })
    }, [])

    useEffect(() => {
        const fiscalStart = props?.dealersettings?.client?.fiscalStart >= 0 ? props?.dealersettings?.client?.fiscalStart : 6;
        const currentfiscalStart = moment().month(fiscalStart).startOf('month').toDate();
        const currentfiscalEnd = moment().add(1, 'year').month(fiscalStart - 1).endOf('month').toDate();
        let years = [];
        for (let i = 3; i > 0; i--) {
            years.push({
                start: moment(currentfiscalStart).subtract(i, 'year').startOf('month').toDate(),
                end: moment(currentfiscalEnd).subtract(i, 'year').endOf('month').toDate()
            })
        }
        years.push({
            start: currentfiscalStart,
            end: currentfiscalEnd
        })
        years = years.filter(r => r.start < moment().endOf('day').toDate()).map(r => {
            return {
                ...r,
                title: moment(r.start).year() === moment(r.end).year() ? `${moment(r.start).year()}` : `${moment(r.start).year()}-${moment(r.end).year()}`
            }
        })
        years = years.map((r, i) => {
            return {
                ...r,
                name: r.title,
                value: r.title,
                color: years.length - 1 === i ? '#5c61d2' : colors[i],
                dummyData: years.length - 1 === i ? { width: 20, height: 20 } : { width: 25, height: 7, top: 8, right: 20 },
                lineChart: years.length - 1 !== i
            }
        })
        setYears(years)
        setRefreshData(true)
    }, [])

    useEffect(() => {

        const refScheduleReport = window.firebase.firestore().doc(`schedular/yealySales-${props.dealersettings.oemID}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                    if (doc.data().multiSchedule?.length > 0) {
                        let refPromise = [];
                        doc.data().multiSchedule.forEach(rpt => {
                            refPromise.push(window.firebase.firestore().doc(`schedular/${rpt}`).get())
                        })
                        Promise.all(refPromise)
                            .then(docs => {
                                let rpts = [];
                                docs.forEach(doc => {
                                    if (doc.exists) {
                                        let arrCron = [];
                                        if (doc.data().cron && doc.data().cron !== '* * * * *') {
                                            arrCron = doc.data().cron.split(' ');
                                        }
                                        rpts.push({
                                            ...doc.data(),
                                            schedulartime: arrCron.length > 0 ?
                                                window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                                        })
                                    }
                                })
                                setMultiSchedule([
                                    doc.data(),
                                    ...rpts
                                ]);
                            })
                    }
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])

    useEffect(() => {
        if (!refreshData)
            return

        setLoader(true)
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getOEMSummaryReport',
                params: JSON.stringify({
                    clientID: props.dealersettings.clients.map(client => client.id).join(','),
                    timezone: props?.dealersettings?.client?.timezone || moment.tz.guess(),
                    startDate: moment(years[0].start).format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                    wonLead: true,
                    hideWalkin: true
                })
            }).then((response) => {
                setLoader(false)
                setRefreshData(false)
                if (response.data.success) {
                    setAllData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }
    }, [refreshData])

    useEffect(() => {
        if (refreshData || _.isEmpty(years))
            return

        const _allData = allData.filter(a => (objFilter?.clientID?.length ? objFilter.clientID.includes(a.cid) : true));
        let _data = [];
        makes.filter(a => a?.value &&
            (objFilter?.make?.length ? objFilter.make.includes(a.value) : true)
        ).forEach(mk => {
            let i = 0;
            saleType.forEach(rec => {
                const monthData = [];
                years.forEach(year => {
                    const months = [];
                    for (var m = moment(year.start).startOf('day'); m.isSameOrBefore(moment(year.end).startOf('day')); m.add(1, 'month')) {
                        const _startDate = moment(m).startOf('month').unix();
                        const _endDate = moment(m).endOf('month').unix();
                        const wonCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.mk === mk.value), _startDate, _endDate, rec, 'sold', 'wd');
                        months.push(moment(m).month() <= moment().month() || moment(m).year() !== moment().year() ? {
                            wonCount: wonCount.actual,
                            arrwonCount: wonCount.arractual,
                            title: moment(m).format('MMM'),
                            name: `${year.title}-${moment(m).format('MMM')}`,
                            date: m
                        } : {
                            wonCount: null,
                            arrwonCount: [],
                            title: moment(m).format('MMM'),
                            name: `${year.title}-${moment(m).format('MMM')}`,
                            date: m
                        })
                    }
                    monthData.push({
                        name: year.title,
                        months
                    })
                })
                monthData.push({
                    name: `Target`,
                    months: monthData[0].months.map(r => {
                        return {
                            ...r,
                            name: 'Target',
                            wonCount: reportDoc?.target?.[mk.value.toLowerCase()]?.[rec.toLowerCase()] || 0,
                            arrwonCount: [],
                        }
                    })
                })
                _data.push({
                    title: `${mk.name.toUpperCase()} ${rec.toUpperCase()}`,
                    make: mk.value.toLowerCase(),
                    saleType: rec.toLowerCase(),
                    target: reportDoc?.target?.[mk.value.toLowerCase()]?.[rec.toLowerCase()] || 0,
                    monthData
                })
            });
        })
        if (_data.length) {
            const totalMonths = [];
            _data.forEach(rec => {
                rec.monthData.forEach(_months => {
                    totalMonths.push(..._months.months)
                })
            })
            const monthData = [];
            years.forEach(year => {
                const months = [];
                for (var m = moment(year.start).startOf('day'); m.isSameOrBefore(moment(year.end).startOf('day')); m.add(1, 'month')) {
                    months.push(moment(m).month() <= moment().month() || moment(m).year() !== moment().year() ? {
                        wonCount: _.sumBy(totalMonths.filter(a => a.name === `${year.title}-${moment(m).format('MMM')}`), 'wonCount'),
                        arrwonCount: arrayUnion(totalMonths.filter(a => a.name === `${year.title}-${moment(m).format('MMM')}`), 'arrwonCount'),
                        title: moment(m).format('MMM'),
                        name: `${year.title}-${moment(m).format('MMM')}`,
                        date: m
                    } : {
                        wonCount: null,
                        arrwonCount: [],
                        title: moment(m).format('MMM'),
                        name: `${year.title}-${moment(m).format('MMM')}`,
                        date: m
                    })
                }
                monthData.push({
                    name: year.title,
                    months
                })
            })
            monthData.push({
                name: 'Target',
                months: monthData[0].months.map(r => {
                    return {
                        ...r,
                        name: 'Target',
                        wonCount: _.sumBy(totalMonths.filter(a => a.name === 'Target' && a.title === moment(r.date).format('MMM')), 'wonCount'),
                        arrwonCount: [],
                    }
                })
            })
            _data.push({
                title: 'TOTAL',
                make: 'total',
                saleType: 'total',
                target: _.sumBy(totalMonths.filter(a => a.name === 'Target' && a.title === 'Jan'), 'wonCount'),
                monthData
            })
        }
        _data = _data.map(data => {
            const cData = [];
            data.monthData.forEach(rec => {
                cData.push(...rec.months.map(a => { return { ...a, [rec.name]: a.wonCount, [`arr${rec.name}`]: a.arrwonCount } }))
            })
            return {
                ...data,
                chartData: cData
            }
        })
        setReportData(_data);
    }, [allData, objFilter, reportDoc])

    const arrayUnion = (arr, val) => {
        const _arr = [];
        arr.forEach(rec => {
            _arr.push(...(rec[val] || []))
        })
        return _.uniq(_arr);
    }

    const checkDataFilter = (_data, startDt, endDt, saleType, status, dateType, newlead) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return {
            arractual: _.uniqBy(filterData, 'eid').map(r => { return r.eid }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, 'eid'), 'wc') : _.uniqBy(filterData, 'eid').length
        }
    }

    const handleModelClose = () => {
        setFilter(null)
        setModelShow({
            enquiry: false
        })
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        const brandIDs = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value);
            if (data?.brandIDs?.length) {
                brandIDs.push(...data.brandIDs);
            }
        })
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
        }, 1000);
        if (brandIDs?.length) {
            setObjFilter({
                ...objFilter,
                [name]: value,
                ['make']: _.uniq(brandIDs)
            });
        } else {
            setObjFilter({
                ...objFilter,
                [name]: value
            });
        }
    }

    const saveEnquiryField = (name, value) => {
        window.firebase.firestore().doc(`reports/${reportDoc.documentID}`).update({
            [`${name}.${textEditor.make}.${textEditor.saleType}`]: Number(value),
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now()
        }).then(snapshot => {
            setTexteditor({});
            setEditFields(null);
        });
    }

    const cancelEnquiryField = (name, value) => {
        setTexteditor({});
        setEditFields(null);
    }

    const handleNumberChange = (e) => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.floatValue;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setEditFields(number);
        }
    };

    return (
        <>
            <div className="middle-wrapper">
                <div className="filter-panel">
                    <div className="float-left fleet-head">
                        <h3>Yearly Sales Performance Report</h3>
                    </div>
                    <Dropdown className='ml-2 float-right'>
                        <Dropdown.Toggle as={CustomToggle} className='common-button'>
                            <i className='ico icon-more'></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={CustomMenu}
                            ChildClass="more-dropdown dropsub-fix"
                            xplacement="bottom-end"
                        >
                            <Dropdown.Item eventKey="4" as={'div'} bsPrefix={'li-'}>

                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    let url = `/dynamic/report/salesperformance/${props.dealersettings.client.id}`;
                                    url += `?id=${reportDoc.documentID}&clientids=${props.dealersettings.clients.filter(a => objFilter?.clientID?.length ? objFilter.clientID.includes(a.id) : true).map(client => client.id).join(',')}`;
                                    url += `&makes=${makes.filter(a => objFilter?.make?.length ? objFilter.make.includes(a.value) : true).map(client => client.documentID).join(',')}`;
                                    window.open(url, '_blank');
                                }} title={'Pdf Report'}>
                                    <i className="ico icon-files-pdf"></i> Pdf Report
                                </a>


                            </Dropdown.Item>
                            {/* <Dropdown.Item eventKey="4" onClick={(e) => {
                                setModelShow({
                                    ...modelShow,
                                    sheduleReport: true
                                })
                            }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>  */}
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="float-right col-2 pr-0 pl-0 mr-2">
                        <ReactMultiSelect
                            options={makes?.map(r => {
                                return {
                                    label: r.name,
                                    value: r.value
                                }
                            }) || []}
                            name={"make"}
                            placeholder={'select make'}
                            onChange={(option) => handleReactMultiSelectChange(option, 'make')}
                            value={objFilter?.make || []}
                            classNamePrefix={`basic-select`}
                            isMulti={true}
                            isSearchable={true}
                        >
                        </ReactMultiSelect>
                    </div>
                    <div className="float-right col-2 pr-0 pl-0 mr-2">
                        <ReactMultiSelect
                            options={props?.dealersettings?.clients?.map(r => {
                                return {
                                    label: r.name,
                                    value: r.id,
                                    brandIDs: makes?.filter(a => r?.brandIDs?.includes(a.documentID))?.map(a => { return a.value }) || []
                                }
                            }) || []}
                            name={"clientID"}
                            placeholder={'select dealer'}
                            onChange={(option) => handleReactMultiSelectChange(option, 'clientID')}
                            value={objFilter?.clientID || []}
                            classNamePrefix={`basic-select`}
                            isMulti={true}
                            isSearchable={true}
                        >
                        </ReactMultiSelect>
                    </div>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        <div className="conversion-performance-table-wrap">
                            {
                                reportData.map((data, i) => {
                                    return <div key={i} className={`common-table-outer`}>
                                        <div className='col-common-table-6'>
                                            <div className={`common-table`}>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="13" className="head-bg-main" scope="col">{data.title}</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="head-light" scope="col" width="16%">MONTH</th>
                                                            {
                                                                data.monthData[0].months.map((month, mi) => {
                                                                    return <th key={mi} scope="col" className="head-light text-center" width="7%">{month.title}</th>

                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.monthData.map((_monthdata, mdi) => {
                                                                return <tr key={mdi}>
                                                                    <td className="text-left performance-list-bg"><b>{
                                                                        _monthdata.name === 'Target' ? (
                                                                            <>{
                                                                                textEditor[`target-${data.make}-${data.saleType}`] ? (
                                                                                    <div className='form-style'><InputAutoNumber
                                                                                        id={'data-target'}
                                                                                        name={`target`}
                                                                                        allowNegative={false}
                                                                                        thousandSeparator={true}
                                                                                        placeholder={''}
                                                                                        className={`form-control mtdreport-input`}
                                                                                        onValueChange={(e) => handleNumberChange(e)}
                                                                                        value={editFields}
                                                                                        onSaveField={saveEnquiryField}
                                                                                        onCancelField={cancelEnquiryField}
                                                                                        returnDefault={true}
                                                                                    /></div>) :
                                                                                    (<>Target ({data?.target || 0})
                                                                                        {
                                                                                            data.title === 'TOTAL' ? (<></>) : (
                                                                                                <span className="mtdreport-edit-span" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    setEditFields(data.target)
                                                                                                    setTexteditor({
                                                                                                        ...data,
                                                                                                        [`target-${data.make}-${data.saleType}`]: true
                                                                                                    })
                                                                                                }}><i className="ico icon-edit"></i></span>)
                                                                                        }</>)
                                                                            }
                                                                            </>
                                                                        ) : (<>{_monthdata.name}</>)
                                                                    }</b></td>
                                                                    {
                                                                        _monthdata.months.map((month, mi) => {
                                                                            return <td key={mi} className="text-center cursor-pointer"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setFilter({
                                                                                        documentIDs: month.arrwonCount || []
                                                                                    })
                                                                                    setCounts(month?.arrwonCount?.length || 0)
                                                                                    if (month?.arrwonCount?.length) {
                                                                                        setModelShow({
                                                                                            ...modelShow,
                                                                                            enquiry: true
                                                                                        })
                                                                                    }
                                                                                }}>
                                                                                {month.wonCount}</td>
                                                                        })
                                                                    }
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-common-table-6 ">
                                            <div className="performance-col-report-box">
                                                <BarChart
                                                    id={`performance-${i}`}
                                                    height={'calc(100% - 0px)'}
                                                    data={data.chartData}
                                                    columns={years.filter(r => !r.lineChart).map(r => { return r.value })}
                                                    lineColumns={[...years.filter(r => r.lineChart).map(r => { return r.value }), 'Target']}
                                                    dynamicColumns={[...years, ...targets]}
                                                    xCategory={'title'}
                                                    calculationType={'count'}
                                                    handleShowEnquiryList={(value, rec) => {
                                                        setFilter({
                                                            documentIDs: rec[`arr${value}`] || []
                                                        })
                                                        setCounts(rec[`arr${value}`]?.length || 0)
                                                        if (rec[`arr${value}`]?.length) {
                                                            setModelShow({
                                                                ...modelShow,
                                                                enquiry: true
                                                            })
                                                        }
                                                    }}
                                                    //title={data.title}
                                                    dynColor={true}
                                                    labelMaxWidth={75}
                                                    hideBullet={true}
                                                    sameYAxis={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                }
            </div>
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={'Enquiry List'}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    isFromDashboard={true}
                ></EnquiryList>
            </PopUpModal>
        </>
    )
}

export default YearlySalesPerformance;