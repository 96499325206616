import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import toast from 'toasted-notes'
import { InputText, ReactSelect, ReactMultiSelect, InputCheckBox } from '../../../../components';
import { visibilities, templateVM } from './viewModel'
import Translate from '../../../../constants/translate';
import { levelOptions } from '../fieldSettings/viewModel'

const Addtemplate = (props) => {

    const [template, setTemplate] = useState(props.template);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [pushMailchimp, setPushMailchimp] = useState(props?.template?.mailchimpID ? true : false)
    //console.log(template)
    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (str && str.length >= 1) {
            var firstChar = str.charAt(0);
            var remainingStr = str.slice(1);
            str = firstChar.toUpperCase() + remainingStr;
        }
        setTemplate({
            ...template,
            [name]: str
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleTextChange = e => {
        const { name, value } = e.target;

        setTemplate({
            ...template,
            [name]: (value || ''),
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };

    const handleSelectChange = (e, data) => {
        if (data.name === 'visibility' && e.value === 'private') {
            setTemplate({
                ...template,
                [data.name]: e.value,
                level: '',
                levelID: '',
                subLevelIDs: '',
                regionIDs: [],
                groupIDs: [],
                clientIDs: []
            });
        } else {
            setTemplate({
                ...template,
                [data.name]: e.value,

            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleSavetemplate = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(template['title'])) {
            formIsValid = false;
            errors['title'] = errorClass;
        }
        // if (!template.level && template.visibility === 'shared') {
        //     formIsValid = false;
        //     errors['level'] = errorClass;
        // }
        // if (!_.isEmpty(template.level) && template.visibility === 'shared' && !template.subLevelIDs && template.level !== 'oem' && template.level !== 'individual') {
        //     formIsValid = false;
        //     errors['subLevelIDs'] = errorClass;
        // }
        if (template.level) {
            let arrToLooped = !_.isEmpty(template.subLevelIDs) ? template.subLevelIDs.split(',') : []
            let clientIDs = []
            let regionIDs = []
            let groupIDs = []
            let level = template.level ? template.level : ''
            template.level = level
            if (template.level === 'oem') {
                props.clientOptions.forEach(clientOption => {
                    clientIDs.push(clientOption.value)
                })
                template.clientIDs = clientIDs
            } else if (template.level === 'region') {
                regionIDs = arrToLooped
                arrToLooped.forEach(regionID => {
                    props.clientOptions.forEach(clientOption => {
                        if (clientOption.data.region && clientOption.data.region === regionID) {
                            clientIDs.push(clientOption.value)
                        }
                    })
                })
                template.regionIDs = regionIDs
                template.clientIDs = clientIDs
            } else if (template.level === 'group') {
                groupIDs = arrToLooped
                arrToLooped.forEach(groupID => {
                    props.clientOptions.forEach(clientOption => {
                        if (clientOption.data.group && clientOption.data.group === groupID) {
                            clientIDs.push(clientOption.value)
                        }
                    })
                })
                template.groupIDs = groupIDs
                template.clientIDs = clientIDs
            } else if (template.level === 'individual') {
                clientIDs = arrToLooped
                template.clientIDs = clientIDs
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (pushMailchimp && props.pushtoMailchimp) {
            await props.pushtoMailchimp(template.bodyHTML, template)
        }
        template.modifiedDate = window.firebase.firestore.Timestamp.now();
        template.modifiedBy = localStorage.uid
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/templates/${template.documentID}`)
            .set({ ..._.pick(template, _.keys(templateVM)) }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((props.newTemplate === true ? 'Template added successfully' : 'Template updated successfully'), {
                    duration: 2000
                })
                props.handleClose(template);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }


    const handlesetLevel = (e, data) => {
        if (data.name === 'level') {
            if (e.value === 'oem') {
                setTemplate({
                    ...template,
                    [data.name]: e.value,
                    ['levelID']: e.value === 'oem' ? props.dealersettings.client.settingsID : '',
                    ['subLevelIDs']: ''
                });
            } else {
                setTemplate({
                    ...template,
                    [data.name]: e.value,
                    ['levelID']: '',
                    ['subLevelIDs']: ''
                });
            }
            setErrorFields({
                ...errorFields,
                [data.name]: ''
            });
        }
    }

    const handleReactMultiSelectChange1 = (selectedOptions) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setTemplate({
            ...template,
            ['subLevelIDs']: value.join(',')
        });
        setErrorFields({
            ...errorFields,
            ['subLevelIDs']: ''
        });
    }

    return (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-save-filter">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={props.title} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">

                    <div className="form-style w-100">

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label ><Translate text={'title'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'title'}
                                    className={`form-control ${errorFields["title"]}`}
                                    name="title"
                                    onChange={handleOnChange}
                                    value={template.title}
                                    maxLength={150}
                                />

                            </div>
                            <div className="form-group col-md-12">
                                <label ><Translate text={'Subject'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'subject'}
                                    className={`form-control ${errorFields["subject"]}`}
                                    name="subject"
                                    onChange={handleTextChange}
                                    value={template.subject}
                                    maxLength={150}
                                />

                            </div>

                            <div className="form-group col-md-12">
                                <label ><Translate text={'visibility'} /></label>
                                <ReactSelect
                                    options={visibilities}
                                    name={"visibility"}
                                    placeholder={'select visibility'}
                                    onChange={handleSelectChange}
                                    value={template.visibility}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                    isSearchable={false}
                                >
                                </ReactSelect>
                            </div>
                            {
                                props.mailchimpEnabled === true ? (
                                    <div className="form-group col-md-12">
                                        <span className={`switch switch-sm float-left mt-2`}>
                                            <InputCheckBox
                                                className={`switch`}
                                                id={`push-to-mailchimp`}
                                                name={'active'}
                                                checked={pushMailchimp}
                                                onChange={(e) => { setPushMailchimp(e.target.checked) }} />
                                            <label htmlFor={`push-to-mailchimp`}><Translate text={'Push to Mailchimp'} /></label>
                                        </span>
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                        {/* {template.visibility === 'shared' ? (
                            <div className="form-row">
                                <div className={`form-group col-md-12`}>
                                    <label> <Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handlesetLevel}
                                        value={template.level}
                                        classNamePrefix={`${errorFields["level"]} cursor-pointer basic-select `}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(template.level) && template.level !== 'oem') ?
                                        (<div className={`form-group col-md-12`}>
                                            <label> <Translate text={template.level === 'group' ? 'Group' : template.level === 'region' ? 'Region' : 'Client'} /></label>
                                            <ReactMultiSelect
                                                options={template.level === 'group' ? props.groupOptions : template.level === 'region' ? props.regionOptions : props.clientOptions}
                                                name={'subLevelIDs'}
                                                placeholder={`select ${template.level === 'individual' ? 'client' : template.level}`}
                                                onChange={handleReactMultiSelectChange1}
                                                value={!_.isEmpty(template.subLevelIDs) ? template.subLevelIDs.split(',') : []}
                                                classNamePrefix={`${errorFields["subLevelIDs"]} basic-select`}
                                                isMulti={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>) : (<></>)
                                }   
                            </div>
                        ) : (<></>)} */}
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={(e) => {
                handleSavetemplate()
            }
            }>
                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>);
}

export default Addtemplate;