import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import moment from 'moment';
import { PopUpModal, ContentOverlay, TableView, UpgradePlan } from '../../../../components';
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import { deviceListFields, deviceVM } from './viewModel';
import AddDevice from './addDevice';
import ScheduleList from './scheduleListPopup';
import EmbedForm from './embedModel'
import ScheduleContact from './scheduleContact';
import { MainContext } from '../../../layout/provider';
import { calendarEventColor } from '../../../../services/enum';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { collectionData } from 'rxfire/firestore';
import TVPreview from './tvPreview';

const TVDevices = props => {
    const [windowSize, setWindowSize] = useState({
        windowWidth:
            window.innerWidth < 1450
                ? window.innerWidth - 410
                : window.innerWidth - 500,
        windowHeight: window.innerHeight - 255,
    });
    const [deviceList, setDeviceList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [addDevice, setAddDevice] = useState({
        show: false,
        title: '',
        device: null,
    });
    const [schedule, setSchedule] = useState({
        show: false,
        title: '',
        device: null,
    });
    const [embedForm, setEmbedForm] = useState({
        show: false,
        title: '',
        device: '',
    });
    const [scheduleContact, setScheduleContact] = useState({
        show: false,
        title: '',
        slotDate: null
    });
    const [previewDevice, setPreviewDevice] = useState({
        show: false
    });
    const [loading, setLoader] = useState(true);
    const { updateDealerSettings } = useContext(MainContext);

    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) &&
        !Boolean(props.dealersettings.superAdmin) ? true : false);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth:
                    window.innerWidth < 1450
                        ? window.innerWidth - 410
                        : window.innerWidth - 500,
                windowHeight: window.innerHeight - 255,
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {

        const refallDevices = combineLatest(
            collectionData(window.firebase.firestore().collection(`tvDevices`)
                .where('visibility', '==', 'private')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('isDeleted', '==', false)),
            collectionData(window.firebase.firestore().collection(`tvDevices`)
                .where('visibility', '==', 'shared')
                .where('oemID', '==', props.dealersettings.oemID)
                .where('isDeleted', '==', false))
        ).pipe(
            map((_devices) => {
                var [dealer, shared] = _devices;
                return [
                    [...dealer],
                    [...shared]
                ];
            }))
            .subscribe(allDocs => {
                let list = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
                let _devices = [];
                list.forEach(rec => {
                    if (!_devices.some(e => e.documentID === rec.documentID))
                        _devices.push(convertVM(rec))
                })
                _devices = _.orderBy(_devices, ['addedDate.seconds'], ['asc']).map((rec, index) => {
                    return {
                        ...rec,
                        resourceId: rec.documentID,
                        resourceTitle: rec.name,
                        wrapper: calendarEventColor[index] ? calendarEventColor[index].value : null
                    }
                })
                setDeviceList(_devices)
                updateDealerSettings('tvDevices', _devices);
                setLoader(false)
                setLoader(false)
            });
        return () => {
            refallDevices && refallDevices.unsubscribe()
        }
    }, []);


    const convertVM = (doc) => {
        const objData = Object.assign({}, doc);
        const listVM = Object.assign({}, deviceVM);
        for (let [key, value] of Object.entries(objData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }
        listVM.createdBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.addedBy);
        listVM.updatedBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.modifiedBy);
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.visibilityDOM = doc.visibility === 'private' ?
            (<div className="badge badge-pill badge-private" onClick={(e) => { console.log(e) }} >Private </div>)
            : (<div className="badge badge-pill badge-shared" onClick={(e) => { console.log(e) }}>Shared  </div>)
        if (_.isEmpty(listVM.shortURL)) {
            listVM.shortURL = `https://${window.location.host}/customer/delivery/${doc.documentID}?cid=${doc.clientID}`;
        }
        return listVM
    }




    const handleActionClick = (id, data) => {
        return (<>
            <div className=''>
                <button
                    type='button'
                    className='btn btn-green float-left mr-2'
                    style={{ padding: `3px 8px 4px`, fontSize: `11px` }}
                    onClick={(e) => {
                        e.preventDefault();
                        setSchedule({
                            show: true,
                            title: data.name,
                            device: data
                        })
                    }}
                >
                    {' '}
                    <i
                        className='ico icon-timer'
                        aria-hidden='true'
                    ></i>{' '}
                    <Translate text={'Schedule'} /> {' '}
                </button>
                <button
                    type='button'
                    className='btn btn-secondary float-left mr-2'
                    style={{ padding: `3px 8px`, fontSize: `11px` }}
                    onClick={e =>
                        setEmbedForm({
                            show: true,
                            device: data,
                            title: 'Website URL'
                        })
                    }
                >
                    {' '}
                    <i
                        className='fa fa-link custom-icon-preview'
                        aria-hidden='true'
                        style={{ paddingTop: '0px', marginRight: '4px' }}
                    ></i>{' '}
                    <span><Translate text={'Embed'} /></span>{' '}
                </button>
                <button
                    type='button'
                    className='btn btn-green float-left mr-2'
                    style={{ padding: `3px 8px`, fontSize: `11px` }}
                    onClick={e =>
                        setPreviewDevice({
                            show: true,
                            device: data,
                            title: data.name
                        })
                    }
                >
                    {' '}
                    <i
                        className='ico icon-monitor'
                        aria-hidden='true'
                        style={{ paddingTop: '0px', marginRight: '4px' }}
                    ></i>{' '}
                    <span><Translate text={'Live'} /></span>{' '}
                </button>
                <div
                    title='Edit template'
                    style={{ cursor: 'pointer' }}
                    className='mini-button float-left mr-2'
                    onClick={e => {
                        handleEditDevice(data)
                    }}
                >
                    <i className={`ico icon-edit`}></i>
                </div>
                <div
                    title='Delete template'
                    style={{ cursor: 'pointer' }}
                    className='mini-button'
                    onClick={e => {
                        handleDeleteDevice(data)
                    }}
                >
                    <i className={`ico icon-delete`}></i>
                </div>
            </div>
        </>);
    };

    const handleEditDevice = _device => {
        setAddDevice({
            show: true,
            title: 'Edit Device',
            device: _device
        })
    };
    const handledetailsClick = (e, id, data) => {
        e.preventDefault();
        handleEditDevice(data);
    };

    const handleDeleteDevice = data => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {
                window.firebase
                    .firestore()
                    .doc(`tvDevices/${data.documentID}`)
                    .set(
                        {
                            isDeleted: true,
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                        },
                        { merge: true },
                    )
                    .then(() => {
                        toast.notify(`Device deleted successfully`, {
                            duration: 2000,
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        Swal.fire('Something went wrong.', '', 'error');
                    });
            }
        });
    };

    return (
        <>
            {
                liteVersion ? (<UpgradePlan />) : (<></>)
            }
            <div className={`${liteVersion ? 'form-lite' : ''}`}>
                <div className='settings-head'>
                    <div className='float-left'>
                        <div className='filter-search search-icon'>
                            <input
                                placeholder='Search'
                                aria-label='Search'
                                value={searchText}
                                onChange={e => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className='float-right'>
                        <button
                            type='button'
                            className='btn btn-primary float-right mr-2'
                            onClick={() => {
                                setAddDevice({ show: true, title: 'Add Device', device: null });
                            }}
                        >
                            <i className='ico icon-add mr-2'></i>{' '}
                            <Translate text={'Add Device'} />
                        </button>
                        <button
                            type='button'
                            className='btn btn-secondary float-right mr-2'
                            onClick={() => {
                                //setScheduleContact({ show: true, title: 'Schedule Contact'  });
                                setSchedule({ show: true, title: 'Schedule Contact', device: null });
                            }}
                        >
                            <i className='ico icon-timer mr-2'></i>{' '}
                            <Translate text={'Schedule Contact'} />
                        </button>
                    </div>
                </div>
                {loading ? (
                    <div className='mt-5'>
                        <ContentOverlay active={true} />
                    </div>
                ) : deviceList.length > 0 ? (
                    <div className='common-table'>
                        <TableView
                            datalist={
                                deviceList.filter(({ name, createdBy, updatedBy }) =>
                                    !_.isEmpty(searchText)
                                        ? (!_.isEmpty(name)
                                            ? name.toLowerCase().includes(searchText.toLowerCase())
                                            : false) ||
                                        (!_.isEmpty(createdBy)
                                            ? createdBy
                                                .toLowerCase()
                                                .includes(searchText.toLowerCase())
                                            : false) ||
                                        (!_.isEmpty(updatedBy)
                                            ? updatedBy
                                                .toLowerCase()
                                                .includes(searchText.toLowerCase())
                                            : false)
                                        : true,
                                )
                            }
                            height={windowSize.windowHeight}
                            width={windowSize.windowWidth}
                            columns={deviceListFields}
                            dynamicFields={deviceListFields
                                .filter(e => e.default === true)
                                .map(v => _.pick(v, ['value']).value)}
                            handleActionClick={handleActionClick}
                            handleRowSingleClick={handledetailsClick}
                            isSettings={false}
                            isReorderable={false}
                            isSorting={true}
                            isTranslate={true}
                            dealersettings={props.dealersettings}
                        />
                    </div>
                ) : (
                    <div
                        className='common-table'
                        style={{ height: windowSize.windowHeight + 'px' }}
                    >
                        <div className='text-center p-5'>
                            <p>
                                {' '}
                                <Translate text={'No deviecs found.'} />
                            </p>
                            <button
                                className='btn btn-sm btn-default'
                                onClick={() => {
                                    setAddDevice({ show: true, title: 'Add Device', device: null });
                                }}
                            >
                                <i className='ico icon-add mr-2 pt-1 float-left'></i>
                                <Translate text={'Add Devie'} />
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <PopUpModal show={addDevice.show}>
                <AddDevice
                    {...props}
                    {...addDevice}
                    handleClose={() => {
                        setAddDevice({ show: false, title: '', device: null });
                    }}

                />
            </PopUpModal>
            <PopUpModal show={schedule.show}>
                <ScheduleList
                    {...props}
                    {...schedule}
                    allDevice={deviceList}
                    handleClose={() => {
                        setSchedule({ show: false, title: '', device: null });
                    }}

                />
            </PopUpModal>
            <PopUpModal show={embedForm.show}>
                <EmbedForm
                    {...embedForm}
                    dealersettings={props.dealersettings}
                    handleClose={() => {
                        setEmbedForm({ show: false, title: '' });
                    }}
                    isFromTableView={true}
                />
            </PopUpModal>
            <PopUpModal show={scheduleContact.show}>
                <ScheduleContact
                    {...props}
                    {...scheduleContact}
                    allDevice={deviceList}
                    handleClose={() => {
                        setScheduleContact({ show: false, title: '' });
                    }}

                />
            </PopUpModal>
            <PopUpModal show={previewDevice.show}>
                <TVPreview
                    {...props}
                    {...previewDevice}
                    handleClose={() => {
                        setPreviewDevice({ show: false, title: '' });
                    }}

                />
            </PopUpModal>
        </>
    );
};

export default TVDevices;
