/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import Swal from 'sweetalert2';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox, AntDateRangePicker,
    ReactSelect, InputText, ReactMultiSelect,
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'
import { firestoreDB } from '../../services/helper';


//#region  ASSIGN VARIABLES
let titles = [];
let languages = [];
let license_state = [];
let defaultCountry = '';
let campaigns = [];
let origins = [];
let nationalities = [];

let enquiryTypes = [];
let leadSources = [];
let genders = [];
let marital_status = [];
let license_type = [];
let interested = [];
let contact_method = [];
let groups = [];
let sale_type = [];
let labels = [];

// const labels = [
//     { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
//     { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
//     { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]
/*const enquiryStatus = [
    { active: true, value: 'open', label: <div className={`badge badge-pill badge-status-open`}>OPEN</div> },
    { active: true, value: 'won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
    { active: true, value: 'lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> }
]*/
const contact_type = [
    { active: true, value: 'Current Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Current Customer'} /></div> },
    { active: true, value: 'Potential Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Potential Customer'} /></div> }
]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'addedDate', label: <Translate text={'Created Date'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'Modified Date'} /> }
]

const objDateField = {
    name: null,
    type: null,
    range: null
}

const selectOptions = [
    //{ active: true, value: 'clientID', label: <Translate text={'dealerships'} /> },
    { active: true, value: 'date', label: <Translate text={'date'} /> },
    { active: true, value: 'owner', label: <Translate text={'owner'} /> },
    { active: true, value: 'contactType', label: <Translate text={'contactType'} /> },
    { active: true, value: 'company', label: <Translate text={'company'} /> },
    { active: true, value: 'isVIP', label: <Translate text={'isVIP'} /> },
    { active: true, value: 'banLoanVehicle', label: <Translate text={'banLoanVehicle'} /> },
    { active: true, value: 'businessContact', label: <Translate text={'businessContact'} /> },
    { active: true, value: 'gender', label: <Translate text={'gender'} /> },
    { active: true, value: 'maritalStatus', label: <Translate text={'maritalStatus'} /> },
    { active: true, value: 'nationality', label: <Translate text={'nationality'} /> },
    { active: true, value: 'country', label: <Translate text={'country'} /> },
    { active: true, value: 'licenseState', label: <Translate text={'licenseState'} /> },
    { active: true, value: 'licenseType', label: <Translate text={'licenseType'} /> },
    { active: true, value: 'contactcampaign', label: <Translate text={'campaign'} /> },
    { active: true, value: 'contactorigin', label: <Translate text={'origin'} /> },
    { active: true, value: 'contactMethod', label: <Translate text={'contactMethod'} /> },
    { active: true, value: 'language', label: <Translate text={'language'} /> },
    { active: true, value: 'make', label: <Translate text={'make'} /> },
    { active: true, value: 'saleType', label: <Translate text={'saleType'} /> },
    { active: true, value: 'pipeline', label: <Translate text={'pipeline'} /> },
    { active: true, value: 'label', label: <Translate text={'label'} /> },
    { active: true, value: 'status', label: <Translate text={'status'} /> },
    { active: true, value: 'enquiryType', label: <Translate text={'enquiryType'} /> },
    { active: true, value: 'lostReason', label: <Translate text={'lostReason'} /> },
    { active: true, value: 'inboundSource', label: <Translate text={'inboundSource'} /> },
    { active: true, value: 'noEnquiry', label: <Translate text={'Contact without Leads'} /> },
    { active: true, value: 'leadSource', label: <Translate text={'leadSource'} /> },

]

//#endregion
const ContactAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [companys, setCompanys] = useState([])
    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [enquiryAllStatus, setAllStatus] = useState([]);
    const { columnFields } = props;

    const [pipelines, setPipelines] = useState([])
    const [stages, setStages] = useState([])

    const enableSharedContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedContact) ? true : false);

    useEffect(() => {
        const userRefDoc = firestoreDB(props.dealersettings).firestore().collection('companies')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .limit(100)
            .onSnapshot((querySnapshot) => {
                const _companys = [];
                querySnapshot.forEach(doc => {
                    const { name } = doc.data();
                    _companys.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: name
                    });
                });
                setCompanys(_companys)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        const { fieldData } = props;
        setFilterFields(fieldData);
    }, [props.fieldData])

    useEffect(() => {
        const { dealersettings, clientUsers, fieldData } = props;

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const setttings = dealersettings.client.settings;
            const brands = dealersettings.allMakeModels;
            const _makes = [];
            const _lostReasons = [];
            const countries = []; const states = [];
            const _stages = [];

            const _pipelines = [];
            setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    label: doc.name
                });
            });


            if (dealersettings.group && dealersettings.group.enableSharedContact === true && dealersettings.group.clients)
                groups = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: props.dealersettings.group.clients[cid] ? props.dealersettings.group.clients[cid].name : '' } })


            defaultCountry = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';


            titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            languages = [];
            dealersettings.languages && dealersettings.languages.forEach((doc) => {
                languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            nationalities = [];
            dealersettings.nationalities && dealersettings.nationalities.forEach((doc) => {
                nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            genders = [];
            setttings.genders && setttings.genders.forEach(doc => {
                genders.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            marital_status = [];
            setttings.maritalStatus && setttings.maritalStatus.forEach(doc => {
                marital_status.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            contact_method = [];
            setttings.contactMethod && setttings.contactMethod.forEach(doc => {
                contact_method.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            labels = [];
            setttings.enquiryLabels && setttings.enquiryLabels.forEach(doc => {
                labels.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill`} style={{
                        color: `${doc.color ? doc.color : '#f47815 '}`,
                        backgroundColor: `${doc.color ? doc.color + '1f' : '#f478151f'}`,
                        boxShadow: `inset 0 0 0 1px ${doc.color ? doc.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                    }}>{doc.name.toUpperCase()}</div>,
                });
            });

            interested = [];
            setttings.interests && setttings.interests.forEach(doc => {
                interested.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            license_type = [];
            setttings.licenseType && setttings.licenseType.forEach(doc => {
                license_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            leadSources = [];
            setttings.leadSource && setttings.leadSource.forEach(doc => {
                leadSources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            sale_type = [];
            setttings.salesType && setttings.salesType.forEach(doc => {
                sale_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            if (!_.isEmpty(fieldData) && fieldData.name === 'country' && fieldData.value && !_.isEmpty(dealersettings.states)) {
                dealersettings.states.filter(e => e.country_code === fieldData.value).forEach((data) => {
                    states.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: data.name
                    });
                });
                setStates(states);
            }

            license_state = [];
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    models: doc.models
                });
            });

            if (!_.isEmpty(fieldData) && fieldData.name === 'make' && fieldData.value && !fieldData.value.includes(',')) {
                const models = [];
                let _models = !_.isEmpty(_makes.filter(m => m.value === fieldData.value)[0]) ? _makes.filter(m => m.value === fieldData.value)[0].models : [];

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                setModels(models);
            }

            if (!_.isEmpty(fieldData) && fieldData.name === 'pipeline' &&
                fieldData.value &&
                !fieldData.value.includes(',')) {
                let defaultstages = setttings.pipelines.filter(e => e.value === fieldData.value)[0].stages;
                defaultstages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead').forEach((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }

            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            if (!_.isEmpty(fieldData) && fieldData.name === 'lostReason' &&
                fieldData.value && !fieldData.value.includes(',') &&
                !_.isEmpty(setttings.lostReasons.filter(e => e.value === fieldData.value)[0])) {
                const _lostSubReasons = [];
                let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === fieldData.value)[0].subList) ?
                    setttings.lostReasons.filter(e => e.value === fieldData.value)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    _lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
                setLostSubReasons(_lostSubReasons);
            }

            // const viewOtherContact = ((!_.isEmpty(dealersettings) &&
            //     !_.isEmpty(dealersettings.rolePermissions) &&
            //     !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            //     dealersettings.rolePermissions.permissions.viewOtherContact) ? true : false);

            const _clientUsers = [];
            if (clientUsers) {
                let ___clientUsers = clientUsers;
                // if (!viewOtherContact)
                //     ___clientUsers = ___clientUsers.filter(m => m.id === localStorage.uid);

                ___clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    });
                });
            }

            let _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });
            setAllStatus(_enquiryAllStatus);

            setClientUsers(_clientUsers);
            setMakes(_makes);
            setCountries(countries);
            setLostReasons(_lostReasons);

            setPipelines(_pipelines);
            setStages(_stages);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const updateFilterFields = (_state) => {
        setFilterFields(_state);
        props.updateRule(_state);
    }

    const handleSelectNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        const { value } = e;
        state['name'] = value;

        if (value === 'isVIP' || value === 'banLoanVehicle' || value === 'businessContact' ||
            value === 'noEnquiry' || value === 'optinPhone' || value === 'optinSMS' ||
            value === 'optinEmail' || value === 'optinPost' || value === 'marketingOptinEmail' || value === 'marketingOptinSMS')
            state['value'] = false;
        else
            state['value'] = null;

        delete state['type'];
        delete state['range'];
        delete state['stage'];
        delete state['state'];
        delete state['model'];
        delete state['lostSubReason'];

        if (value === 'date') {
            state['value'] = 'addedDate';
            state['type'] = 'thisMonth';
        }

        updateFilterFields(state);
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        if (data.name === 'pipeline') {
            const { dealersettings } = props;
            let _pipeline = value;
            const _stages = [];
            if (dealersettings && dealersettings.client && dealersettings.client.settings && _pipeline && !_pipeline.includes(',')) {
                const setttings = dealersettings.client.settings;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                defaultstages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead').map((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }
            setStages(_stages);
            //setFilterFields({ ...filterFields, value: _pipeline });
            state['value'] = _pipeline;
            if (state.hasOwnProperty('stage'))
                state['stage'] = null;
        }
        else if (data.name === 'make') {
            let _make = value;
            const models = [];

            if (_make && !_make.includes(',')) {
                let _models = !_.isEmpty(makes.filter(m => m.value === _make)[0]) ? makes.filter(m => m.value === _make)[0].models : [];
                _models && _models.forEach((item, index) => {
                    models.push({
                        value: item.value,
                        active: _.isBoolean(item.active) ? item.active : true,
                        label: CommonHelper.displayModelName(item),
                    });
                });
            }

            setModels(models);
            //setFilterFields({ ...filterFields, value: _make });
            state['value'] = _make;
            if (state.hasOwnProperty('model'))
                state['model'] = null;
        }
        else if (data.name === 'lostReason') {
            let _lostReason = value;
            const lostSubReasons = [];

            if (_lostReason && !_lostReason.includes(',') && !_.isEmpty(lostReasons.filter(m => m.value === _lostReason)[0].subList)) {
                lostReasons.filter(m => m.value === _lostReason)[0].subList.forEach(item => {
                    lostSubReasons.push({
                        value: item.value,
                        active: _.isBoolean(item.active) ? item.active : true,
                        label: item.name
                    });
                });
            }

            setLostSubReasons(lostSubReasons);
            //setFilterFields({ ...filterFields, value: _lostReason });
            state['value'] = _lostReason;
            if (state.hasOwnProperty('lostSubReason'))
                state['lostSubReason'] = null;

        }
        else {
            //setFilterFields({ ...filterFields, value: value });
            if (data.name === 'lostSubReason' || data.name === 'model' || data.name === 'stage' || data.name === 'state')
                state[data.name] = value;
            else
                state['value'] = value;
        }

        updateFilterFields(state);
    }


    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const models = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    active: _.isBoolean(data.active) ? data.active : true,
                    label: CommonHelper.displayModelName(data),
                });
            });
            setModels(models);
            //setFilterFields({ ...filterFields, value: e.value });
            state['value'] = e.value;
            if (state.hasOwnProperty('model'))
                state['model'] = null;
        }
        else if (data.name === 'lostReason' && e) {
            const lostSubReasons = [];
            if (!_.isEmpty(lostReasons.filter(m => m.value === e.value)[0].subList)) {
                lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                    lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
            }
            setLostSubReasons(lostSubReasons);
            //setFilterFields({ ...filterFields, value: e.value });
            state['value'] = e.value;
            if (state.hasOwnProperty('lostSubReason'))
                state['lostSubReason'] = null;
        }
        else if (data.name === 'pipeline' && e.value) {
            const { dealersettings } = props;
            let _pipeline = e.value;
            if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                const setttings = dealersettings.client.settings;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                const _stages = [];
                defaultstages.forEach((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });

                state['value'] = _pipeline;
                //setFilterFields({ ...filterFields, value: _pipeline });
                state['stage'] = null;
                setStages(_stages);
            }
        }
        else {
            //setFilterFields({ ...filterFields, value: e.value });
            if (data.name === 'lostSubReason' || data.name === 'model' || data.name === 'stage' || data.name === 'state')
                state[data.name] = e.value;
            else
                state['value'] = e.value;
        }

        if (data.name === 'country') {
            if (e)
                handleCountryChange(e.value);
            else
                handleCountryChange('');

            if (state.hasOwnProperty('state'))
                state['state'] = '';
        }

        updateFilterFields(state);
    }


    const handleCountryChange = cCode => {
        const { dealersettings } = props;
        const states = [];
        if (cCode && !_.isEmpty(dealersettings)) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === cCode).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        setStates(states)
    }

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state['value'] = checked;
        //setFilterFields({ ...filterFields, value: checked });
        updateFilterFields(state);

    }

    //#endregion


    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {

            state['type'] = e.value;

            if (e.value !== 'custom')
                state.range = null;
            else {
                state.range = {
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                }
            }

            updateFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            state['value'] = e.value;
            updateFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        if (val) {
            state.range = {
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            }
        }
        else {
            state.range = null
        }
        updateFilterFields(state);

    };

    const deleteRequirement = (docID) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, `You want to delete the rule.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {
                props.deleteRule(docID)
            }

        });

    }

    //#endregion

    const _filter = props.filterFields.filter(m => !_.isEmpty(m.name) && (props.fieldData && props.fieldData.name) ? m.name !== props.fieldData.name : true)
    const checklistsMandatory = !_.isEmpty(_filter) ? Array.prototype.map.call(_filter, function (item) { return item.name; }) : []
    const { errors, docID } = props;
    return (
        <>

            <div className="rule-container">
                {
                    !_.isEmpty(props.filterFields) && Object.keys(props.filterFields).length <= 1
                        ?
                        <></>
                        :
                        <>
                            <div className="rule-actions">
                                <a href="#"
                                    className="mini-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteRequirement(docID);
                                    }}>
                                    <i className="ico icon-delete"></i>
                                </a>
                            </div>
                        </>
                }


                <div className="settings-head form-style mb-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-4">
                                    <ReactSelect
                                        options={selectOptions.filter(m => !_.isEmpty(checklistsMandatory) ? checklistsMandatory.indexOf(m.value) < 0 : true)}
                                        name={"fieldName"}
                                        placeholder={'select option'}
                                        onChange={handleSelectNameChange}
                                        value={filterFields.name ? filterFields.name : null}
                                        classNamePrefix={`${errors && errors["name_" + docID]} cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'date'
                                        ?
                                        <>
                                            <div className="col-4">
                                                <ReactSelect
                                                    options={dateTypes}
                                                    id={"date-name"}
                                                    name={"date"}
                                                    placeholder={'select date type'}
                                                    onChange={handleDateNameChange}
                                                    value={filterFields && filterFields.value ? filterFields.value : null}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                            <div className="col-4">
                                                <ReactSelect
                                                    options={dateOptions}
                                                    id={"date-type"}
                                                    name={"date"}
                                                    placeholder={'select date option'}
                                                    onChange={handleDateTypeChange}
                                                    value={filterFields && filterFields.type ? filterFields.type : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields) && !_.isEmpty(filterFields.type) && filterFields.type === 'custom')
                                                    ?
                                                    <>
                                                        <div className="col-4 mt-2">

                                                        </div>
                                                        <div className="col-8 mt-2">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields) && !_.isEmpty(filterFields.range))
                                                                        ? [moment.unix(filterFields.range.startDate.seconds), moment.unix(filterFields.range.endDate.seconds)] :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                                className={'date-range-ams-fix'}
                                                            />
                                                        </div>
                                                    </>

                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'pipeline'
                                        ?
                                        <>
                                            <div className={`col-${!_.isEmpty(stages) ? 4 : 8}`}>
                                                <ReactMultiSelect
                                                    options={pipelines}
                                                    name={"pipeline"}
                                                    placeholder={'select pipeline'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                            {
                                                !_.isEmpty(stages)
                                                    ?
                                                    <>
                                                        <div className="col-4">
                                                            <ReactMultiSelect
                                                                options={stages}
                                                                name={"stage"}
                                                                placeholder={'select stage'}
                                                                onChange={handleMultiSelectChange}
                                                                value={filterFields.hasOwnProperty('stage') && filterFields.stage ? filterFields.stage.split(',') : []}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }


                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'country'
                                        ?
                                        <>
                                            <div className="col-4">
                                                <ReactSelect
                                                    options={countries}
                                                    name={"country"}
                                                    placeholder={'select country'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields && filterFields.value ? filterFields.value : null}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>

                                            <div className="col-4">
                                                <ReactSelect
                                                    options={states}
                                                    name={"state"}
                                                    placeholder={'select state'}
                                                    onChange={handleSelectChange}
                                                    value={filterFields.hasOwnProperty('state') ? filterFields.state : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>

                                        </>
                                        :
                                        <></>
                                }


                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'make'
                                        ?
                                        <>
                                            <div className={`col-${!_.isEmpty(models) ? 4 : 8}`}>
                                                <ReactMultiSelect
                                                    options={makes}
                                                    name={"make"}
                                                    placeholder={'select make'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    menuPlacement={'top'}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                            {
                                                !_.isEmpty(models)
                                                    ?
                                                    <>
                                                        <div className="col-4">
                                                            <ReactMultiSelect
                                                                options={models}
                                                                name={"model"}
                                                                placeholder={'select model'}
                                                                onChange={handleMultiSelectChange}
                                                                value={filterFields.hasOwnProperty('model') && filterFields.model ? filterFields.model.split(',') : []}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                                menuPlacement={'top'}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'lostReason'
                                        ?
                                        <>
                                            <div className={`col-${!_.isEmpty(lostSubReasons) ? 4 : 8}`} >
                                                <ReactMultiSelect
                                                    options={lostReasons}
                                                    name={"lostReason"}
                                                    placeholder={'select lost reason'}
                                                    onChange={handleMultiSelectChange}
                                                    value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    menuPlacement={'top'}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                            {
                                                !_.isEmpty(lostSubReasons)
                                                    ?
                                                    <>
                                                        <div className="col-4">
                                                            <ReactMultiSelect
                                                                options={lostSubReasons}
                                                                name={"lostSubReason"}
                                                                placeholder={'select lost sub reason'}
                                                                onChange={handleMultiSelectChange}
                                                                value={filterFields.hasOwnProperty('lostSubReason') && filterFields.lostSubReason ? filterFields.lostSubReason.split(',') : []}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                                menuPlacement={'top'}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }


                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'owner'
                                        ?
                                        <div className="col-8">
                                            <ReactMultiSelect
                                                options={clientUsers}
                                                name={"owner"}
                                                placeholder={'select ownership'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(groups) && !_.isEmpty(filterFields) && filterFields.name === 'clientID'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={groups}
                                                name={"clientID"}
                                                placeholder={'select dealer'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'contactType'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={contact_type}
                                                name={"contactType"}
                                                placeholder={'select contact Type'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'gender'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={genders}
                                                name={"gender"}
                                                placeholder={'select gender'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'maritalStatus'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={marital_status}
                                                name={"maritalStatus"}
                                                placeholder={'select marital status'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'nationality'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={nationalities}
                                                name={"nationality"}
                                                placeholder={'select nationality'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'licenseState'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={license_state}
                                                name={"licenseState"}
                                                placeholder={'select license state'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'licenseType'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={license_type}
                                                name={"licenseType"}
                                                placeholder={'select license state'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }


                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'company'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={companys}
                                                name={"company"}
                                                placeholder={'select company'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }


                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'contactMethod'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={contact_method}
                                                name={"contactMethod"}
                                                placeholder={'select contact Method'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }



                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'language'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={languages}
                                                name={"language"}
                                                placeholder={'select language'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }


                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'contactcampaign'
                                        ?
                                        <div className="col-8">
                                            <ReactMultiSelect
                                                options={campaigns}
                                                name={"contactcampaign"}
                                                placeholder={'select campaign'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }



                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'contactorigin'
                                        ?
                                        <div className="col-8">
                                            <ReactMultiSelect
                                                options={origins}
                                                name={"contactorigin"}
                                                placeholder={'select origin'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }


                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'status'
                                        ?
                                        <div className="col-8">
                                            <ReactMultiSelect
                                                options={enquiryAllStatus}
                                                name={"status"}
                                                placeholder={'select status'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }



                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'inboundSource'
                                        ?
                                        <div className="col-8">
                                            <ReactSelect
                                                options={props.dealersettings.client.integrations ? _.map(props.dealersettings.client.integrations.filter(e => e.inbound === true && e.enabled === true && e.active === true), function (el) {
                                                    return {
                                                        label: el.name,
                                                        value: el.value
                                                    }
                                                }) : []}
                                                name={"inboundSource"}
                                                placeholder={'select inbound source'}
                                                onChange={handleSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value : null}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }



                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'enquiryType'
                                        ?
                                        <div className="col-8">
                                            <ReactMultiSelect
                                                options={enquiryTypes}
                                                name={"enquiryType"}
                                                placeholder={'select enquiry type'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }



                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'leadSource'
                                        ?
                                        <div className="col-8">
                                            <ReactMultiSelect
                                                options={leadSources}
                                                name={"leadSource"}
                                                placeholder={'select lead source'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }



                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'label'
                                        ?
                                        <div className="col-8">
                                            <ReactMultiSelect
                                                options={labels}
                                                name={"label"}
                                                placeholder={'select label'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }



                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'saleType'
                                        ?
                                        <div className="col-8">
                                            <ReactMultiSelect
                                                options={sale_type}
                                                name={"saleType"}
                                                placeholder={'select sale type'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value.split(',') : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                                menuPlacement={'top'}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'isVIP'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_isVIP"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'isVIP') }} />
                                                <label htmlFor="_isVIP"></label>
                                            </span>
                                            <Translate text={'VIP Contacts'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'banLoanVehicle'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_banLoanVehicle"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'banLoanVehicle') }} />
                                                <label htmlFor="_banLoanVehicle"></label>
                                            </span>
                                            <Translate text={'banLoanVehicle'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'businessContact'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_businessContact"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'businessContact') }} />
                                                <label htmlFor="_businessContact"></label>
                                            </span>
                                            <Translate text={'businessContact'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'optinPhone'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_optinPhone"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'optinPhone') }} />
                                                <label htmlFor="_optinPhone"></label>
                                            </span>
                                            <Translate text={'optinPhone'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'optinSMS'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_optinSMS"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'optinSMS') }} />
                                                <label htmlFor="_optinSMS"></label>
                                            </span>
                                            <Translate text={'optinSMS'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'optinEmail'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_optinEmail"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'optinEmail') }} />
                                                <label htmlFor="_optinEmail"></label>
                                            </span>
                                            <Translate text={'optinEmail'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'optinPost'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_optinPost"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'optinPost') }} />
                                                <label htmlFor="_optinPost"></label>
                                            </span>
                                            <Translate text={'optinPost'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'marketingOptinEmail'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_marketingOptinEmail"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'marketingOptinEmail') }} />
                                                <label htmlFor="_marketingOptinEmail"></label>
                                            </span>
                                            <Translate text={'marketingOptinEmail'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'marketingOptinSMS'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_marketingOptinSMS"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'marketingOptinSMS') }} />
                                                <label htmlFor="_marketingOptinSMS"></label>
                                            </span>
                                            <Translate text={'marketingOptinSMS'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'noEnquiry'
                                        ?
                                        <div className="col-8 mt-2">
                                            <span className="switch switch-sm mr-2">
                                                <InputCheckBox
                                                    className={`switch ${errors && errors["value_" + docID]}`}
                                                    name="_noEnquiry"
                                                    checked={filterFields && filterFields.value ? filterFields.value : false}
                                                    onChange={(e) => { handleSwitchChange(e, 'noEnquiry') }} />
                                                <label htmlFor="_noEnquiry"></label>
                                            </span>
                                            <Translate text={'Contact without Leads'} />
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'xxxxxxx'
                                        ?
                                        <div className="col-8">

                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>



            </div>



        </>
    )
}

export default ContactAdvanceFilters;