import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import toast from 'toasted-notes';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { objectPasswordModel } from '../viewModel';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...objectPasswordModel,
			errorFields: {},
			loader: false,
			isButtonDisable: true,
		};
	}

	handleLogout = async () => {
		try {
			const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/devices/${localStorage.browserKey}`)
			const logRef = window.firebase.firestore().collection('auditLogs')
			const auditLog = {
				clientID: this.props.dealersettings.client.id ? this.props.dealersettings.client.id : null,
				documentID: logRef.doc().id,
				groupID: this.props.dealersettings.groupID ? this.props.dealersettings.groupID : null,
				imageURL: null,
				logBy: localStorage.uid,
				logDate: window.firebase.firestore.Timestamp.now(),
				notes: null,
				oemID: this.props.dealersettings.oemID ? this.props.dealersettings.oemID : null,
				platform: 'web',
				recordID: localStorage.browserKey,
				regionID: this.props.dealersettings.regionID ? this.props.dealersettings.regionID : null,
				subtype: 'logout',
				type: 'authentication',
			}
			await logRef.doc(auditLog.documentID).set(auditLog, { merge: true })
			await userRef.set({
				logoutDate: window.firebase.firestore.Timestamp.now(),
				isLogin: false,
				forceLogout: false,
				modifiedDate: window.firebase.firestore.Timestamp.now()
			}, { merge: true })
			await window.firebase
				.auth()
				.signOut()
			await window.firebase2
				.auth()
				.signOut();
			toast.closeAll();
			const brandTheme = localStorage.brandTheme;
			localStorage.clear();
			return <Redirect to={`${brandTheme ? `/login?theme=${brandTheme}` : '/login'}`} />;
		} catch (error) {
			console.log(error)
		}
	};

	handleOnChange = e => {
		e.preventDefault();
		const { name, value } = e.target;

		this.setState({ [name]: value }, () => {
			this.checkPasswordsMatch();
		});
	};

	checkPasswordsMatch = () => {
		const { password, cpassword } = this.state;
		let errorClass = 'input_error';
		let errors = {};
		// errors['password'] = errorClass;
		// errors['cpassword'] = errorClass;
		// setErrorFields(errors);

		if (password.length >= 6) {
			if (password === cpassword) {
				console.log('passwords matching');

				this.setState({ isButtonDisable: false, errorFields: {} });
			} else {
				console.log('passwords not matching');
				errors['cpassword'] = errorClass;

				this.setState({ isButtonDisable: true, errorFields: errors });
			}
		} else {
			this.setState({ isButtonDisable: true });
			console.log('password is less than 6 characters');
		}
	};

	resetPassword = e => {
		const { password, cpassword, currentpassword } = this.state;

		if (currentpassword.length < 6) {
			toast.notify('Please enter valid current password', {
				duration: 2000,
			});
			return;
		}

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, 'You will be logged out after changing password!'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
		}).then(result => {
			if (result.value) {
				let formIsValid = true;
				let errorClass = 'input_error';
				let errors = {};
				if (_.isEmpty(password)) {
					formIsValid = false;
					errors['password'] = errorClass;
				}
				if (password !== cpassword) {
					formIsValid = false;
					errors['cpassword'] = errorClass;
				}
				this.setState({
					errorFields: errors,
				});
				if (!formIsValid) {
					return;
				}
				this.setState({
					loader: true,
				});

				const cred = window.firebase.auth.EmailAuthProvider.credential(
					window.firebase.auth().currentUser.email,
					currentpassword,
				);
				window.firebase
					.auth()
					.currentUser.reauthenticateWithCredential(cred)
					.then(() => {
						this.setState({
							loader: false,
						});

						window.firebase.auth().currentUser.updatePassword(password);
						toast.notify('Password updated successfully', {
							duration: 2000,
						});
						this.setState({
							loader: false,
							password: '',
							cpassword: '',
							currentpassword: '',
						});
						this.handleLogout();
						return;
					})
					.catch(error => {
						// Some error
						toast.notify(error.message, {
							duration: 2000,
						});
						this.setState({
							loader: false,
							password: '',
							cpassword: '',
							currentpassword: '',
						});
						console.log(error);
					});
			}
		});
	};

	render() {
		const {
			password,
			cpassword,
			currentpassword,
			errorFields,
			loader,
			isButtonDisable,
		} = this.state;

		const { handleOnChange, resetPassword } = this;

		return (
			<div className='tab-content'>
				<div
					className='tab-pane fade show active'
					id='nav-home'
					role='tabpanel'
					aria-labelledby='nav-home-tab'
				>
					<p><Translate text={'A strong password is important'} /></p>

					<div className='settings-password-wrap mt-3 form-style'>
						<div className='form-group'>
							<label > <Translate text={'Current Password'} /></label>
							<input
								type='password'
								autoComplete='off'
								placeholder={'Enter current password'}
								className={`form-control ${errorFields['password']}`}
								name='currentpassword'
								onChange={handleOnChange}
								value={currentpassword}
							/>
						</div>
						<div className='form-group'>
							<label > <Translate text={'New Password'} /></label>
							<input
								type='password'
								autoComplete='off'
								placeholder={'Enter new password'}
								className={`form-control ${errorFields['password']}`}
								name='password'
								onChange={handleOnChange}
								value={password}
							/>
							<small id='' className='form-text text-muted float-right'>
								<Translate text={'min 8 characters'} />
							</small>
						</div>
						<div className='form-group'>
							<label > <Translate text={'Confirm Password'} /></label>
							<input
								type={'password'}
								autoComplete='off'
								placeholder={'Confirm password'}
								className={`form-control ${errorFields['cpassword']}`}
								name='cpassword'
								onChange={handleOnChange}
								value={cpassword}
							/>
						</div>

						<button
							type='submit'
							className='btn btn-primary float-right mt-3'
							onClick={e => resetPassword(e)}
						// disabled={isButtonDisable}
						>
							{loader ? (
								<span
									className='spinner-border spinner-button mr-1'
									role='status'
									aria-hidden='true'
								></span>
							) : (
								<></>
							)}
							<Translate text={'save'} />
						</button>
					</div>
				</div>
				<div
					className='tab-pane fade'
					id='nav-profile'
					role='tabpanel'
					aria-labelledby='nav-profile-tab'
				>
					<Translate text={'Two Factor Authentication content here'} />
				</div>
			</div>
		);
	}
}

export default ChangePassword;
