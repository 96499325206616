/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import NumberFormat from 'react-number-format';
import _ from 'lodash'
/** COMPONENTS */
import { PopUpModal } from '../../components';
import Translate from '../../constants/translate';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import LoadConfiguration from './loadConfiguration';
/** VIEW-MODELS */
import { enquiryStatus } from '../../services/enum'

const RequirementInfo = ({ enqStatus, requirement, handleEdit, setPrimary, setSold, isPrimary, deleteRequirement, isDeivered, dealersettings, hideMore, isMultiple }) => {
    const [editshow, setEditshow] = useState(false)
    const [showLoadConfigModal, setShowLoadConfigModal] = useState({
        show: false,
    })
    const isMclaren = dealersettings.isMclaren === true;

    const node = useRef();
    const {
        documentID, addOns,
        make, model, saleType, isSold,
        regNo, vinNo, stockNo,
        rego, chassisNo, //for service & tradein pro info purpose only
        year, price, extColor
        } = requirement;

    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);

    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

    const modifyVehicleInfo = ((!_.isEmpty(_permissions) && _permissions.modifyVehicleInfo && localStorage.defaultModule !== 'oem') ? true : false);

    const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
    const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, enqStatus);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const showTotalCost = (currencySymbol) => {
        let _addOns = Object.assign([], addOns);
        if (!_.isEmpty(_addOns)) {
            var total = 0;
            for (var i = 0; i < _addOns.length; i++) {
                total = total + (_addOns[i].price ? _addOns[i].price : 0);
            }
            return <NumberFormat value={total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
        }
        else {
            return <NumberFormat value={0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
        }
    }

    return (<>
        <div className={`${isMultiple ? '' : 'vehicle-item'} flex-vehicle`} id={'veh_' + documentID}>
            {isSold ? <div className="primary-wrapper-featured"><div className="primary"></div></div> : <></>}
            {
                hideMore
                    ?
                    <></>
                    :
                    <>
                        <div className="quickview-activity-more">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                setEditshow(!editshow)
                                if (editshow)
                                    document.addEventListener('click', setEditshow(false));
                            }}><i className="ico icon-more"></i></a>
                            <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                <ul className="more-dropdown">
                                    {
                                        (isPrimary || isDeivered)
                                            ?
                                            <></>
                                            :
                                            <li>
                                                <a href="#" onClick={(e) => { e.preventDefault(); setPrimary(requirement); setEditshow(false) }}>
                                                    <i className="ico icon-primary"></i> <Translate text={'markAsPrimary'} />
                                                </a>
                                            </li>
                                    }

                                    {
                                        ((isPrimary && isStatusSold && isSold) || isDeivered || (!isStatusSold && enqStatus !== enquiryStatus.WON && enqStatus !== enquiryStatus.CLOSED))
                                            ?
                                            <></>
                                            :
                                            <li>
                                                {
                                                    isSold
                                                        ?
                                                        <a href="#" onClick={(e) => { e.preventDefault(); setSold(requirement, false); setEditshow(false) }}>
                                                            <i className="fas fa-check-circle"></i> <Translate text={'removeFromWon'} />
                                                        </a>
                                                        :
                                                        <a href="#" onClick={(e) => { e.preventDefault(); setSold(requirement, true); setEditshow(false) }}>
                                                            <i className="fas fa-check-circle"></i> <Translate text={'markAsWon'} />
                                                        </a>
                                                }

                                            </li>
                                    }

                                    <li>
                                        <a href="#" onClick={(e) => { e.preventDefault(); handleEdit(requirement) }}>
                                            {
                                                isDeivered && modifyVehicleInfo === false
                                                    ?
                                                    <>
                                                        <i className="ico icon-zoom"></i> <Translate text={'view'} />
                                                    </>
                                                    :
                                                    <>
                                                        <i className="ico icon-edit"></i> <Translate text={'edit'} />
                                                    </>
                                            }
                                        </a>
                                    </li>
                                    {
                                        (isDeivered)
                                            ?
                                            <></>
                                            :
                                            <li>
                                                <a href="#" onClick={(e) => { e.preventDefault(); handleEdit(requirement, true) }}>
                                                    <i className="fas fa-clone"></i> <Translate text={'Clone'} />
                                                </a>
                                            </li>
                                    }

                                    {
                                        isMclaren === true ? (<>
                                            <li>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowLoadConfigModal({
                                                        show: true,
                                                        title: 'Load Configuration',
                                                        url: 'https://configurator.mclaren.com/mclaren-ui/?model/artura'
                                                    })
                                                }}>
                                                    <i className="ico icon-Stock"></i> <Translate text={'loadConfiguration'} />
                                                </a>
                                            </li>
                                        </>) : (<></>)
                                    }
                                    {
                                        (isPrimary || isDeivered || isSold)
                                            ?
                                            <></>
                                            :
                                            <li>
                                                <a href="#" onClick={(e) => { e.preventDefault(); deleteRequirement(requirement, documentID) }}>
                                                    <i className="ico icon-delete"></i> <Translate text={'delete'} />
                                                </a>
                                            </li>
                                    }

                                </ul>
                            </div>
                        </div>
                    </>
            }


            <div className="vehicle-item-image">
                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                    <img src={CommonHelper.showBrandLogo(dealersettings, make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                </a>
            </div>
            <div className="vehicle-data" onClick={(e) => { e.preventDefault(); handleEdit(requirement) }} >
                <div className="vehicle-item-title">{make} {model}</div>
                <div className="dashboard-testdrive-activity-column">
                    Stock#: <div title={CommonHelper.showLocale({ dealersettings }, 'stockNo')}>{stockNo ? (<>{stockNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, stockNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div>
                    {extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'exteriorColor')}>{extColor}</div></>)}
                    {year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'year')}>{year}</div></>)}
                    {regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'registrationNumber')}>{regNo ? (<>{regNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, regNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div></>)}
                    {rego && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'registrationNumber')}>{rego ? (<>{rego} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, rego)}><i className="fas fa-copy"></i></span></>) : '--'}</div></>)}
                    {vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'vinNo')}>{vinNo ? (<>{vinNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, vinNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div></>)}
                    {chassisNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale({ dealersettings }, 'vinNo')}>{chassisNo ? (<>{chassisNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, chassisNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div></>)}

                </div>
                {/* <div className="vehicle-item-info">{extColor ? `${extColor + (intColor ? ' - ' + intColor : '')},` : ''} {year ? year : ''}</div>
                {stockNo ? <div className="vehicle-item-info"><Translate text={'stockNo'} />: {stockNo} </div> : <></>}
                {regNo ? <div className="vehicle-item-info"><Translate text={'regNo'} />: {regNo} </div> : <></>}
                {vinNo ? <div className="vehicle-item-info"><Translate text={'vinNo'} />: {vinNo} </div> : <></>} */}
                {
                    enableServicePackages || localStorage.defaultModule === 'afterSales'
                        ?
                        <>
                            <div className="vehicle-item-status">
                                {isPrimary ? <div className="badge badge-pill badge-primary-fill ml-1 mt-1"><Translate text={'primary'} /></div> : <></>}

                                {
                                    !_.isEmpty(addOns) ?
                                        <div className="vehicle-price pt-1 float-left">
                                            {showTotalCost(currencySymbol)}
                                        </div>
                                        :
                                        <></>
                                }

                            </div>

                        </>
                        :
                        <>
                            {/* {
                                isDeivered
                                    ?
                                    <>
                                        {
                                            hideMore
                                                ?
                                                <></>
                                                :
                                                <>
                                                    <div className="vehicle-item-info"><Translate text={'quantity'} />: <strong>{quantity ? quantity : 1}</strong></div>
                                                </>
                                        }

                                    </>
                                    :
                                    <>
                                        <div className="quantity-counter">
                                            <div className="quantity-title"><Translate text={'quantity'} />: </div>
                                            <div className="input-group number-quantity input-group-grey">
                                                <span className="input-group-prepend">
                                                    <button className="btn btn-default " data-dir="dwn" onClick={(e) => {
                                                        e.preventDefault();
                                                        decrementQuantity(documentID);
                                                    }}><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                </span>
                                                <NumberFormat
                                                    className="form-control text-center"
                                                    displayType={'text'}
                                                    value={quantity ? quantity : 1}
                                                />
                                                <span className="input-group-append">
                                                    <button className="btn btn-default" data-dir="up" onClick={(e) => {
                                                        e.preventDefault();
                                                        incrementQuantity(documentID);
                                                    }}> <i className="fa fa-plus" aria-hidden="true"></i> </button>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                            } */}
                            <div className="vehicle-item-status">

                                {saleType ? <div className="badge badge-pill badge-white">
                                    {CommonHelper.getNameByValue(dealersettings?.client?.settings?.salesType,
                                        saleType, '')}
                                </div> : <></>}
                                {isPrimary ? <div className="badge badge-pill badge-primary-fill ml-1"><Translate text={'primary'} /></div> : <></>}

                                {isSold ? <div className="badge badge-pill badge-status-won ml-1" title={'Requirement'}>{CommonHelper.getNameByValue(dealersettings?.client?.settings?.enquiryStatus, 'won', 'Won')}</div> : <></>}

                                {price ? <div className="vehicle-item-price"><NumberFormat value={price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></div> : <></>}
                            </div>

                        </>
                }



            </div>
        </div >
        <PopUpModal show={showLoadConfigModal.show}>
            <LoadConfiguration
                {...showLoadConfigModal}
                handleClose={() => {
                    setShowLoadConfigModal({ show: false })
                }}
            />
        </PopUpModal>
    </>)

};

export default RequirementInfo;
