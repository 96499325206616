import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SketchPicker } from 'react-color';

import {
	InputCheckBox,
	InputRadio,
	InputTextArea,
	ReactSelect,
	InputText,
	ReactMultiSelect,
	AntDatePicker,
} from '../../../../components';
import Translate from '../../../../constants/translate';
import { removeHTMLTags } from '../../../../services/helper';

const RightPreview = ({ formFields, feedbackForm, handleColorChange }) => {
	const [state, setState] = useState({
		primaryColorPicker: false,
		backgroundColorPicker: false,
		formColorPicker: false,
		fontColorPicker: false,
	});

	const handleClick = name => {
		setState({ ...state, [`${name}Picker`]: !state[`${name}Picker`] });
	};

	const handleClose = name => {
		setState({ ...state, [`${name}Picker`]: false });
	};

	const styles = {
		primaryColorPicker: {
			width: '36px',
			height: '14px',
			borderRadius: '2px',
			background:
				!isEmpty(feedbackForm) && feedbackForm.primaryColor
					? feedbackForm.primaryColor
					: '#000000',
		},
		backgroundColorPicker: {
			width: '36px',
			height: '14px',
			borderRadius: '2px',
			background:
				!isEmpty(feedbackForm) && feedbackForm.backgroundColor
					? feedbackForm.backgroundColor
					: '#000000',
		},
		fontColorPicker: {
			width: '36px',
			height: '14px',
			borderRadius: '2px',
			background:
				!isEmpty(feedbackForm) && feedbackForm.fontColor
					? feedbackForm.fontColor
					: '#000000',
		},
		formColorPicker: {
			width: '36px',
			height: '14px',
			borderRadius: '2px',
			background:
				!isEmpty(feedbackForm) && feedbackForm.formColor
					? feedbackForm.formColor
					: '#000000',
		},
		swatch: {
			padding: '5px',
			background: '#fff',
			borderRadius: '1px',
			boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
			display: 'inline-block',
			cursor: 'pointer',
		},
		popover: {
			position: 'absolute',
			zIndex: '2',
		},
		cover: {
			position: 'fixed',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
		},
		primaryColor: {
			background:
				!isEmpty(feedbackForm) && feedbackForm.primaryColor
					? feedbackForm.primaryColor
					: '#000000',
		},
		backgroundColor: {
			background:
				!isEmpty(feedbackForm) && feedbackForm.backgroundColor
					? feedbackForm.backgroundColor
					: '#000000',
		},
		formAndFontColor: {
			background:
				!isEmpty(feedbackForm) && feedbackForm.formColor
					? feedbackForm.formColor
					: '#000000',
			color:
				!isEmpty(feedbackForm) && feedbackForm.fontColor
					? feedbackForm.fontColor
					: '#000000',
		},
	};

	return (
		<div className='dynamicform-right-panel'>
			<div className='card dynamicform-custom-card h-100'>
				<div className='card-header'>Customize Preview</div>
				<div className='card-body'>
					<div className='pop-layout form-style mb-2'>
						<div className='form-row'>
							<div className='form-group col-md-3'>
								<label>
									<Translate text={'Button color'} />
								</label>
								<div>
									<div
										style={styles.swatch}
										onClick={() => handleClick('primaryColor')}
									>
										<div
											className='custom-color-picker'
											style={styles.primaryColorPicker}
										/>
									</div>
									{state.primaryColorPicker ? (
										<div style={styles.popover}>
											<div
												style={styles.cover}
												onClick={() => handleClose('primaryColor')}
											/>
											<SketchPicker
												color={
													!isEmpty(feedbackForm) && feedbackForm.primaryColor
														? feedbackForm.primaryColor
														: '#000000'
												}
												onChange={color =>
													handleColorChange(color, 'primaryColor')
												}
											/>
										</div>
									) : null}
								</div>
							</div>
							<div className='form-group col-md-3'>
								<label>
									<Translate text={'Background color'} />
								</label>
								<div>
									<div
										style={styles.swatch}
										onClick={() => handleClick('backgroundColor')}
									>
										<div
											className='custom-color-picker'
											style={styles.backgroundColorPicker}
										/>
									</div>
									{state.backgroundColorPicker ? (
										<div style={styles.popover}>
											<div
												style={styles.cover}
												onClick={() => handleClose('backgroundColor')}
											/>
											<SketchPicker
												color={
													!isEmpty(feedbackForm) && feedbackForm.backgroundColor
														? feedbackForm.backgroundColor
														: '#000000'
												}
												onChange={color =>
													handleColorChange(color, 'backgroundColor')
												}
											/>
										</div>
									) : null}
								</div>
							</div>
							<div className='form-group col-md-3'>
								<label>
									<Translate text={'Form color'} />
								</label>
								<div>
									<div
										style={styles.swatch}
										onClick={() => handleClick('formColor')}
									>
										<div
											className='custom-color-picker'
											style={styles.formColorPicker}
										/>
									</div>
									{state.formColorPicker ? (
										<div style={styles.popover}>
											<div
												style={styles.cover}
												onClick={() => handleClose('formColor')}
											/>
											<SketchPicker
												color={
													!isEmpty(feedbackForm) && feedbackForm.formColor
														? feedbackForm.formColor
														: '#000000'
												}
												onChange={color =>
													handleColorChange(color, 'formColor')
												}
											/>
										</div>
									) : null}
								</div>
							</div>
							<div className='form-group col-md-3'>
								<label>
									<Translate text={'Font color'} />
								</label>
								<div>
									<div
										style={styles.swatch}
										onClick={() => handleClick('fontColor')}
									>
										<div
											className='custom-color-picker'
											style={styles.fontColorPicker}
										/>
									</div>
									{state.fontColorPicker ? (
										<div style={styles.popover} className='fontColor-picker'>
											<div
												style={styles.cover}
												onClick={() => handleClose('fontColor')}
											/>
											<SketchPicker
												color={
													!isEmpty(feedbackForm) && feedbackForm.fontColor
														? feedbackForm.fontColor
														: '#000000'
												}
												onChange={color =>
													handleColorChange(color, 'fontColor')
												}
											/>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>

					<div
						className='dynamicform-embed-previewwrap'
						style={{
							background:
								!isEmpty(feedbackForm) && !isEmpty(feedbackForm.backgroundColor)
									? feedbackForm.backgroundColor
									: '#f4f7f8',
						}}
					>
						<div className='dynamicform-embed-main' style={styles.formAndFontColor}>
							<div className='container-fluid'>
								<div className='row'>
									<div className='pop-layout dynamicform-style'>
										{!isEmpty(formFields) &&
											formFields.some(a => a.type === 'header') ? (
											<div className='feedbackform-title text-center mb-4'>
												<h3 style={{ fontFamily: 'inherit' }}>
													{removeHTMLTags(formFields.find(a => a.type === 'header').label)}
												</h3>
											</div>
										) : (
											<div className='feedbackform-title'>
												<h3> </h3>
											</div>
										)}
										{!isEmpty(formFields) &&
											formFields.filter(a => a.type !== 'header').length > 0 ? (
											formFields
												.filter(a => a.type !== 'header')
												.map((field, index) => {
													return (
														<div
															key={index}
															className='feedbackform-question-loop'
															id={field.name}
														>
															{field.type === 'makeAndModel' ? (
																<>
																	<div className='row'>
																		<div className='col-6'>
																			<div
																				className={`feedbackform-question-container`}
																			>
																				{'Make'}
																				{field.required === true ? (
																					<span className='feedback-required-red'>
																						*{' '}
																					</span>
																				) : (
																					<></>
																				)}
																				{!_.isEmpty(field.description) ? (
																					<OverlayTrigger
																						placement='bottom'
																						overlay={
																							<Tooltip>
																								{field.description}
																							</Tooltip>
																						}
																					>
																						<i className='ico icon-info icon-grey'></i>
																					</OverlayTrigger>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																		<div
																			className='col-6'
																			style={{ paddingLeft: '6px' }}
																		>
																			<div
																				className={`feedbackform-question-container`}
																			>
																				{'Model'}
																				{field.required === true ? (
																					<span className='feedback-required-red'>
																						*{' '}
																					</span>
																				) : (
																					<></>
																				)}
																				{!_.isEmpty(field.description) ? (
																					<OverlayTrigger
																						placement='bottom'
																						overlay={
																							<Tooltip>
																								{field.description}
																							</Tooltip>
																						}
																					>
																						<i className='ico icon-info icon-grey'></i>
																					</OverlayTrigger>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																	</div>
																</>
															) : field.type === 'firstAndLastName' ? (
																<>
																	<div className='row'>
																		<div className='col-6'>
																			<div
																				className={`feedbackform-question-container`}
																			>
																				{'First Name'}
																				{field.required === true ? (
																					<span className='feedback-required-red'>
																						*{' '}
																					</span>
																				) : (
																					<></>
																				)}
																				{!_.isEmpty(field.description) ? (
																					<OverlayTrigger
																						placement='bottom'
																						overlay={
																							<Tooltip>
																								{field.description}
																							</Tooltip>
																						}
																					>
																						<i className='ico icon-info icon-grey'></i>
																					</OverlayTrigger>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																		<div
																			className='col-6'
																			style={{ paddingLeft: '6px' }}
																		>
																			<div
																				className={`feedbackform-question-container`}
																			>
																				{'Last Name'}
																				{field.required === true ? (
																					<span className='feedback-required-red'>
																						*{' '}
																					</span>
																				) : (
																					<></>
																				)}
																				{!_.isEmpty(field.description) ? (
																					<OverlayTrigger
																						placement='bottom'
																						overlay={
																							<Tooltip>
																								{field.description}
																							</Tooltip>
																						}
																					>
																						<i className='ico icon-info icon-grey'></i>
																					</OverlayTrigger>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																	</div>
																</>
															) : field.type === 'paragraph' ? (
																<div className={`feedbackform-question-container feedbackform-paragraph-scroll`} dangerouslySetInnerHTML={{ __html: `${field.label}` }}>
																</div>
															) : field.type === 'pageBreak' ? (
																<div id={field.name} className="form-page-break"> <span>Page Break </span> </div>
															) : (
																<div
																	className={`feedbackform-question-container`}
																>
																	{removeHTMLTags(field.label)}
																	{field.required === true ? (
																		<span className='feedback-required-red'>
																			*{' '}
																		</span>
																	) : (
																		<></>
																	)}
																	{!_.isEmpty(field.description) ? (
																		<OverlayTrigger
																			placement='bottom'
																			overlay={
																				<Tooltip>{field.description}</Tooltip>
																			}
																		>
																			<i className='ico icon-info icon-grey'></i>
																		</OverlayTrigger>
																	) : (
																		<></>
																	)}
																</div>
															)}
															{field.type === 'radio-group' ? (
																<div className='feedbackform-answer-container dynamic-radio'>
																	<div className='feedbackform-input-wrap'>
																		{field.values
																			.filter(a => !_.isEmpty(a.value))
																			.map((option, index) => {
																				return (
																					<div
																						key={index}
																						className='radio icheck-success'
																					>
																						<InputRadio
																							id={`${field.name}-${index}`}
																							type='radio'
																							value={option.value}
																							name={field.name}
																							disabled={true}
																						/>
																						<label
																							htmlFor={`${field.name}-${index}`}
																						>
																							{' '}
																							{option.label}
																						</label>
																					</div>
																				);
																			})}
																	</div>
																</div>
															) : field.type === 'checkbox-group' ? (
																<div className='feedbackform-answer-container dynamic-checkbox'>
																	<div className='feedbackform-input-wrap'>
																		<ul
																			className={`${field.toggle === true
																				? 'feedback-switch-ul'
																				: ''
																				}`}
																		>
																			{field.values
																				.filter(a => !_.isEmpty(a.value))
																				.map((option, index) => {
																					return (
																						<li key={index}>
																							<div
																								className={`${field.toggle === true
																									? 'switch switch-sm'
																									: 'checkbox icheck-success'
																									} form-disable`}
																							>
																								<InputCheckBox
																									className='switch'
																									id={`${option.value}`}
																									name={field.name}
																									onChange={e => { }}
																								/>
																								<label
																									htmlFor={`${option.value}`}
																								>
																									{' '}
																									{option.label}
																								</label>
																							</div>
																						</li>
																					);
																				})}
																		</ul>
																	</div>
																</div>
															) : field.type === 'select' ? (
																<div className='form-row dynamic-select'>
																	<div className='form-group col-md-12'>
																		{field.multiple === true ? (
																			<ReactMultiSelect
																				options={field.values.filter(
																					a => !_.isEmpty(a.value),
																				)}
																				name={field.name}
																				placeholder={'select'}
																				value={''}
																				classNamePrefix={`basic-select`}
																				isMulti={true}
																				isSearchable={true}
																				isDisabled={true}
																			></ReactMultiSelect>
																		) : (
																			<ReactSelect
																				options={field.values.filter(
																					a => !_.isEmpty(a.value),
																				)}
																				name={field.name}
																				placeholder={'select'}
																				value={''}
																				classNamePrefix={`cursor-pointer basic-select`}
																				isDisabled={true}
																			></ReactSelect>
																		)}
																	</div>
																</div>
															) : field.type === 'state' ? (
																<div className='form-row dynamic-select'>
																	<div className='form-group col-md-12'>
																		<ReactSelect
																			options={[]}
																			name={'state'}
																			placeholder={'select'}
																			value={''}
																			classNamePrefix={`cursor-pointer basic-select`}
																			isDisabled={true}
																		></ReactSelect>
																	</div>
																</div>
															) : field.type === 'text' ? (
																<div className='form-row'>
																	<div className='form-group col-md-12'>
																		<InputText
																			autoComplete='off'
																			className={`form-control  `}
																			name={field.name}
																			value={''}
																			disabled={true}
																			placeholder={
																				!isEmpty(field.placeholder)
																					? field.placeholder
																					: ''
																			}
																		/>
																	</div>
																</div>
															) : field.type === 'regNo' ? (
																<div className='form-row'>
																	<div className='form-group col-md-12'>
																		<InputText
																			autoComplete='off'
																			className={`form-control  `}
																			name={'regNo'}
																			value={''}
																			disabled={true}
																			placeholder={'enter reg no'}
																		/>
																	</div>
																</div>
															) : field.type === 'makeAndModel' ? (
																<div className='form-row'>
																	<div className='form-group col-md-6'>
																		<ReactSelect
																			options={[]}
																			name={'make'}
																			placeholder={'select make'}
																			onChange={(e, data) => {
																			}}
																			value={''}
																			classNamePrefix={`cursor-pointer basic-select`}
																			isDisabled={true}
																		/>
																	</div>
																	<div className='form-group col-md-6'>
																		<ReactSelect
																			options={[]}
																			name={'model'}
																			placeholder={'select model'}
																			onChange={(e, data) => {
																			}}
																			value={''}
																			classNamePrefix={`cursor-pointer basic-select`}
																			isDisabled={true}
																		/>
																	</div>
																</div>
															) : field.type === 'firstAndLastName' ? (
																<div className='form-row'>
																	<div className='form-group col-md-6'>
																		<InputText
																			autoComplete='off'
																			className={`form-control  `}
																			name={'first-name'}
																			value={''}
																			disabled={true}
																			placeholder={
																				!isEmpty(field.placeholder)
																					? field.placeholder
																					: 'enter first name'
																			}
																		/>
																	</div>
																	<div className='form-group col-md-6'>
																		<InputText
																			autoComplete='off'
																			className={`form-control  `}
																			name={'last-name'}
																			value={''}
																			disabled={true}
																			placeholder={
																				!isEmpty(field.placeholder)
																					? field.placeholder
																					: 'enter last name'
																			}
																		/>
																	</div>
																</div>
															) : field.type === 'phone' ? (
																<div className='form-row'>
																	<div className='form-group col-md-12'>
																		<InputText
																			autoComplete='off'
																			className={`form-control  `}
																			name={'phone'}
																			value={''}
																			disabled={true}
																			placeholder={
																				!isEmpty(field.placeholder)
																					? field.placeholder
																					: 'enter phone'
																			}
																		/>
																	</div>
																</div>
															) : field.type === 'email' ? (
																<div className='form-row'>
																	<div className='form-group col-md-12'>
																		<InputText
																			autoComplete='off'
																			className={`form-control  `}
																			name={'email'}
																			value={''}
																			disabled={true}
																			placeholder={
																				!isEmpty(field.placeholder)
																					? field.placeholder
																					: 'enter email'
																			}
																		/>
																	</div>
																</div>
															) : field.type === 'textarea' ? (
																<div className='form-row'>
																	<div className='form-group col-md-12'>
																		<InputTextArea
																			className={`form-control notes form-disable no-bg-textarea`}
																			name={field.name}
																			maxLength='280'
																			rows='3'
																			placeholder={
																				!isEmpty(field.placeholder)
																					? field.placeholder
																					: ''
																			}
																		></InputTextArea>
																	</div>
																</div>
															) : field.type === 'date' ? (
																<div className='form-row'>
																	<div className='form-group col-md-12'>
																		<AntDatePicker
																			value={''}
																			name={'dob'}
																			onChange={e => { }}
																			format='DD/MM/YYYY'
																			placeholder='DD/MM/YYYY'
																			className={`form-control`}
																		/>
																	</div>
																</div>
															) : (
																<></>
															)}
														</div>
													);
												})
										) : (
											<></>
										)}
										{/* <div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div>
										<div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div>
										<div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div> */}

										<div className='form-row'>
											<div className='form-group col-md-12'>
												<a
													href='#'
													className='btn btn-dynamic w-100 d-inline-block mt-2'
													style={{
														background:
															!isEmpty(feedbackForm) &&
																!isEmpty(feedbackForm.primaryColor)
																? feedbackForm.primaryColor
																: '#4466f2',
														color: '#fff',
														pointerEvents: 'none'
													}}
													onClick={e => e.preventDefault()}
												>
													Submit
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RightPreview;
