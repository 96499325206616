/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import Swal from 'sweetalert2';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    AntDateRangePicker,
    ReactSelect, ReactMultiSelect, InputMultipleText,
} from '../../components';
import { default as _images } from '../../images';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { collection } from 'rxfire/firestore';
import CommonHelper from '../../services/common'
import { callTypes, service_Types, serviceStatuses, tipStatus } from '../../services/enum'
import { clientLookups } from '../settings/workflow/lookups/viewModel'
import NumberFormat from 'react-number-format';

const leadTypes = [
    { value: 'new', label: 'New Leads Only' },
    { value: 'existing', label: 'Existing Leads Only' },
    { value: 'existingCustomer', label: 'Existing Customers Leads' },
    { value: 'newExistingSold', label: 'New Leads with Existing Sold / Test Drives' }
]

const vehicleBasedFilters = [
    { value: 'requirement', label: 'Requirement Model' },
    { value: 'testdrive', label: 'Test Drive Model' }
]

const dateOptions = [
    { active: true, value: 'today', label: 'Today' },
    { active: true, value: 'yesterday', label: 'Yesterday' },
    { active: true, value: 'thisWeek', label: 'This Week' },
    { active: true, value: 'lastWeek', label: 'Last Week' },
    { active: true, value: 'thisMonth', label: 'This Month' },
    { active: true, value: 'lastMonth', label: 'Last Month' },
    { active: true, value: 'thisQuarter', label: 'This Quarter' },
    { active: true, value: 'lastQuarter', label: 'Last Quarter' },
    { active: true, value: 'custom', label: 'Custom' },
    { active: true, value: 'overall', label: 'Overall' }
]


//#endregion
const CustomFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [dynOptions, setDynoptions] = useState([]);
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [lostReason, setLostreason] = useState([]);
    const [triggers, setTriggers] = useState([])
    const [integrations, setIntegrations] = useState([]);
    const [inbounduserOptions, setInboundUser] = useState([])
    const [dealerSettings, setDealersettings] = useState(props.dealersettings.client)
    const [fileCategory, setFileCategory] = useState([]);

    const { columnFields } = props;

    useEffect(() => {
        const { fieldData } = props;
        setFilterFields(fieldData);
    }, [props.fieldData])

    useEffect(() => {
        if (localStorage.defaultModule !== 'oem' || _.isEmpty(props.report.clientIDs))
            return
        async function getAllSettings() {
            let allPromise = [];
            let settingsDoc = {}
            props.report.clientIDs.forEach(rec => {
                allPromise.push(window.firebase.firestore().doc(`clients/${rec}/currentSettings/${rec}`).get())
            })
            const promiseSnapshot = await Promise.all(allPromise);
            promiseSnapshot.forEach((client) => {
                if (client.exists && client.data().settings)
                    settingsDoc = {
                        ...client.data(),
                        ['settings']: _.isEmpty(settingsDoc.settings) ? client.data().settings : {
                            ...combineSettings(settingsDoc.settings, client.data().settings)
                        }
                    }
            })
            setDealersettings(settingsDoc);
        }
        getAllSettings()

    }, [props.report.clientIDs])

    const combineSettings = (settings, docSettings) => {
        let _settings = {};
        clientLookups.forEach(rec => {
            _settings = {
                ..._settings,
                [rec.value]: _.uniqBy([...(_.isArray(settings[rec.value]) ? settings[rec.value] : []), ...(_.isArray(docSettings[rec.value]) ? docSettings[rec.value] : [])], 'value')
            }
        })
        return _settings;
    }

    useEffect(() => {
        const _categoty = [];
        _categoty.push({ active: true, value: 'quotation', label: 'Quotation' })
        _categoty.push({ active: true, value: 'invoice', label: 'Invoice' })
        _categoty.push({ active: true, value: 'contract', label: 'Sales Contract' })
        _categoty.push({ active: true, value: 'others', label: 'Others' })
        setFileCategory(_categoty)
    }, [])

    useEffect(() => {
        if (_.isEmpty(filterFields))
            return

        let options = [];
        if ((filterFields.name === 'enquiryTypes' || filterFields.name === 'campaigns' || filterFields.name === 'origins' || filterFields.name === 'salesType' || filterFields.name === 'lostReasons' || filterFields.name === 'servicePackages' || filterFields.name === 'leadSource' || filterFields.name === 'pipelines' || filterFields.name === 'callStatus')
            && !_.isEmpty(dealerSettings.settings[filterFields.name])) {
            dealerSettings.settings[filterFields.name].map(rec => {
                return options.push({
                    value: rec.value,
                    label: `${rec.name} ${rec.active === true ? '' : '(In Active)'}`,
                    subList: rec.subList ? rec.subList.map(r => {
                        return {
                            value: r.value,
                            label: r.name
                        }
                    }) : []
                });
            })

        }
        else if ((filterFields.name === 'appointmentTypes')
            && !_.isEmpty(dealerSettings.services[filterFields.name])) {
            dealerSettings.services[filterFields.name].map(rec => {
                return options.push({
                    value: rec.value,
                    label: `${rec.name} ${rec.active === true ? '' : '(In Active)'}`,
                    subList: rec.subList ? rec.subList.map(r => {
                        return {
                            value: r.value,
                            label: r.name
                        }
                    }) : []
                });
            })

        }
        else if (filterFields.name === 'status') {
            dealerSettings.settings['enquiryStatus'].filter(a => a.active === true).map(rec => {
                return options.push({
                    value: rec.value,
                    label: <div className={`badge badge-pill ${rec.value === 'open' ? 'badge-status-open' : 'badge-status-empty'}`} style={{ background: rec.color }}>{rec.name}</div>
                });
            })
            options.push({
                value: 'pendingLost',
                label: <div className={`badge badge-pill badge-status-lost`}>Pending Lost Approval</div>
            })
        }
        else if (filterFields.name === 'financeStatus') {
            dealerSettings.settings['financeStatus'].filter(a => a.active === true).map(rec => {
                return options.push({
                    value: rec.value,
                    label: <div className={`badge badge-pill ${rec.value === 'open' ? 'badge-status-open' : 'badge-status-empty'}`} style={{ background: rec.color }}>{rec.name}</div>
                });
            })
        }
        else if (filterFields.name === 'afterMarketStatus') {
            dealerSettings.settings['afterMarketStatus'].filter(a => a.active === true).map(rec => {
                return options.push({
                    value: rec.value,
                    label: <div className={`badge badge-pill ${rec.value === 'open' ? 'badge-status-open' : 'badge-status-empty'}`} style={{ background: rec.color }}>{rec.name}</div>
                });
            })
        }
        else if (filterFields.name === 'label') {
            if (dealerSettings.settings['enquiryLabels'])
                dealerSettings.settings['enquiryLabels'].filter(a => a.active === true).map(rec => {
                    return options.push({
                        value: rec.value,
                        label: <div className={`badge badge-pill`} style={{
                            color: `${rec.color ? rec.color : '#f47815 '}`,
                            backgroundColor: `${rec.color ? rec.color + '1f' : '#f478151f'}`,
                            boxShadow: `inset 0 0 0 1px ${rec.color ? rec.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                        }}>{rec.name.toUpperCase()}</div>
                    });
                })
            else {
                options = props.labels;
            }
        }
        else if ((filterFields.name === 'serviceType')
            && !_.isEmpty(service_Types)) {
            options = service_Types;
        }
        else if (filterFields.name === 'serviceStatus') {
            if (dealerSettings?.services['serviceStatus'])
                dealerSettings.services['serviceStatus'].filter(a => a.active === true).map(rec => {
                    return options.push({
                        value: rec.value,
                        label: <div className={`badge badge-pill ${rec.value === 'open' ? 'badge-status-open' : 'badge-status-empty'}`} style={{ background: rec.color }}>{rec.name}</div>
                    });
                })
            else {
                options = serviceStatuses;
            }

        }
        else if (filterFields.name === 'tradeinProStatus') {
            if (dealerSettings?.tradeinPro['tradeinProStatus'])
                dealerSettings.tradeinPro['tradeinProStatus'].filter(a => a.active === true).map(rec => {
                    return options.push({
                        value: rec.value,
                        label: <div className={`badge badge-pill ${rec.value === 'New' ? 'badge-status-open' : 'badge-status-empty'}`} style={{ background: rec.color }}>{rec.name}</div>
                    });
                })
            else {
                options = tipStatus;
            }

        } else {

        }
        if ((filterFields.name === 'origins' || filterFields.name === 'lostReasons' || filterFields.name === 'pipelines') && localStorage.defaultModule === 'tradeinPro') {
            options = [];
            dealerSettings.tradeinPro[filterFields.name] && dealerSettings.tradeinPro[filterFields.name].map(rec => {
                return options.push({
                    value: rec.value,
                    label: `${rec.name} ${rec.active === true ? '' : '(In Active)'}`,
                    subList: rec.subList ? rec.subList.map(r => {
                        return {
                            value: r.value,
                            label: r.name
                        }
                    }) : []
                });
            })
        }
        if (filterFields.name === 'client' && props.dealersettings.clients) {
            options = [];
            if (localStorage.defaultModule === 'tradeinPro') {
                Object.assign([], CommonHelper.tradeinProClients(props.dealersettings)).map(rec => {
                    return options.push({
                        value: rec.value,
                        label: rec.label,
                    });
                })
            }
            else {
                props.dealersettings.clients.map(rec => {
                    return options.push({
                        value: rec.id,
                        label: rec.name,
                    });
                })
            }

        }
        if (filterFields?.name?.includes('select-') || filterFields?.name?.includes('multiselect-')) {
            options = [];
            let enqDynOptions = _.find(props?.dealersettings?.client?.settings?.enquiryOptionsDF, { value: filterFields.dynValue })
            if (enqDynOptions?.subList?.length) {
                enqDynOptions.subList.map(rec => {
                    return options.push({
                        value: rec,
                        label: rec
                    });
                })
            }

        }
        if (filterFields?.name?.includes('text-') || filterFields?.name?.includes('number-')) {
            options = [];
            options.push({ value: 'is', label: 'is' })
            options.push({ value: 'empty', label: 'is empty' })
            options.push({ value: 'notempty', label: 'is not empty' })

        }
        if (filterFields?.name === 'includeStageHistory') {
            options = [];
            options.push({ value: 'true', label: 'Yes' })
            options.push({ value: 'false', label: 'No' })
        }
        if (filterFields?.name === 'files') {
            options = [];
            options.push({ value: 'contains', label: 'Contains' })
            options.push({ value: 'notcontains', label: 'Not Contains' })
        }
        if (filterFields?.name === 'dateType') {
            options = [];
            options.push({ value: 'addedDate', label: 'Added Date' })
            options.push({ value: 'stockInDate', label: 'Stock-In Date' })
        }
        if (filterFields?.name === 'stockStatus') {
            options = props?.dealersettings?.client?.settings?.stockStatus?.filter(a => a.value !== 'unavailable')?.map(r => {
                return {
                    ...r,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (r.color || '#6b717f') }}>{r.name.toUpperCase()}</div>,
                }
            })
        }
        if (filterFields?.name === 'stockEnquiries' || filterFields?.name === 'stockAge') {
            options = [];
            options.push({ value: '=', label: 'is' })
            options.push({ value: '>', label: 'greater than' })
            options.push({ value: '<', label: 'less than' })
        }
        setDynoptions(options);
        if (filterFields.name === 'lostReasons' && !_.isEmpty(filterFields.value)) {
            setLostreason(filterFields.value[filterFields.name])
        }
    }, [filterFields.name, dealerSettings]);

    useEffect(() => {
        if (lostReason.length === 1 && dynOptions.find(a => a.value === lostReason[0])) {
            setLostSubReasons(dynOptions.find(a => a.value === lostReason[0]).subList);
        }
        else {
            setLostSubReasons([]);
        }
    }, [lostReason])

    useEffect(() => {
        if (filterFields.name !== 'triggers')
            return
        let refOemTriggers = window.firebase.firestore().collection('triggers')
            .where('isDeleted', '==', false)
            .where('isActive', '==', true)
            .where('triggerType', '==', 'activity')
            .where('clientID', '==', props.dealersettings.client.id);
        let refclientTriggers = window.firebase.firestore().collection('triggers')
            .where('isDeleted', '==', false)
            .where('isActive', '==', true)
            .where('triggerType', '==', 'activity')
            .where('clientIDs', 'array-contains', props.dealersettings.client.id);

        const unSubTriggerCollectionzz = combineLatest(
            refOemTriggers ? collection(refOemTriggers) : of([]),
            refclientTriggers ? collection(refclientTriggers) : of([])
        ).pipe(
            map(([oemTriggers, clientTriggers]) => {
                return [
                    _.map(oemTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(clientTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } })
                ]
            }))
            .subscribe(([oemTriggers, clientTriggers]) => {
                let _triggers = [];
                oemTriggers.forEach((rec) => {
                    if (!_triggers.some(a => a.value === rec.title))
                        _triggers.push({
                            label: rec.title,
                            value: rec.title
                        })
                })
                clientTriggers.forEach((rec) => {
                    if (!_triggers.some(a => a.value === rec.title))
                        _triggers.push({
                            label: rec.title,
                            value: rec.title
                        })
                })
                setTriggers(_triggers)
            });

        return () => {
            unSubTriggerCollectionzz && unSubTriggerCollectionzz.unsubscribe()
        }
    }, [filterFields])

    useEffect(() => {
        if (props.fromOEM === true) {
            setIntegrations(props.integrations);
        }
        else {
            setIntegrations(props.dealersettings.client.integrations ? _.map(props.dealersettings.client.integrations
                .filter(e => e.inbound === true
                    && e.enabled === true
                    && e.active === true
                    && (localStorage.defaultModule === 'tradeinPro' ? Boolean(e.appraisal) : !Boolean(e.appraisal))
                    && e.value), function (el) {
                        return {
                            label: el.name ? el.name : el.value.toUpperCase(),
                            value: el.value
                        }
                    }) : [])
        }
    }, [props.integrations])

    useEffect(() => {
        if (filterFields.name !== 'makeModels')
            return
        let brands = props.dealersettings.client.brands;
        if (localStorage.defaultModule === 'tradeinPro') {
            brands = props.dealersettings.allMakeModels;
        }
        let makes = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (filterFields && filterFields.value && filterFields.value.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === filterFields.value.make)[0]) ? makes.filter(m => m.value === filterFields.value.make)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModels(models)
    }, [filterFields])

    useEffect(() => {
        if (filterFields.name === 'user' || filterFields.name === 'technician' || filterFields.name === 'partsInterpretor' || filterFields.name === 'workshopManager' || filterFields.name === 'serviceAdvisor' || filterFields.name === 'financeBM' || filterFields.name === 'afterMarketBM' || filterFields.name === 'enqAddedBy') {
            const roleServiceIds = [..._.map(props.dealersettings.client.roles.filter(e => e.modules && e.modules.service === true), function (obj) {
                return obj.documentID;
            })];

            const roleTradeinProIds = [..._.map(props.dealersettings.client.roles.filter(e => e.modules && e.modules.tradeinPro === true), function (obj) {
                return obj.documentID;
            })];

            const roleTechIds = [..._.map(props.dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.technician === true), function (obj) {
                return obj.documentID;
            })];

            const roleAdvIds = [..._.map(props.dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.serviceAdvisor === true), function (obj) {
                return obj.documentID;
            })];

            const rolePartIds = [..._.map(props.dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.partsInterpretor === true), function (obj) {
                return obj.documentID;
            })];

            const roleWorkIds = [..._.map(props.dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.workshopManager === true), function (obj) {
                return obj.documentID;
            })];

            const rolefinanceBM = [..._.map(props.dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToBusinessManager === true), function (obj) {
                return obj.documentID;
            })];

            const roleafterMarketBM = [..._.map(props.dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToAfterMarketBM === true), function (obj) {
                return obj.documentID;
            })];

            let _users = [];
            if (props.fromOEM === true) {
                props.groupUsers.forEach(rec => {
                    if (!_users.some(e => e.value === rec.id)) {
                        _users.push({
                            value: rec.id,
                            searchlabel: rec.name,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            clientIDs: rec.clientIDs,
                            label: (
                                <div className='drop-image'>
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            ),
                        });
                    }
                });
            }
            else {
                props.clientUsers.filter(a => Boolean(a.superAdmin) === false
                    && (localStorage.defaultModule === 'tradeinPro' && filterFields.name === 'user' ? _.includes(roleTradeinProIds, a.roleID) : true)
                    && (localStorage.defaultModule === 'service' && filterFields.name === 'user' ? _.includes(roleServiceIds, a.roleID) : true)
                    && (localStorage.defaultModule === 'service' && filterFields.name === 'technician' ? _.includes(roleTechIds, a.roleID) : true)
                    && (localStorage.defaultModule === 'service' && filterFields.name === 'partsInterpretor' ? _.includes(rolePartIds, a.roleID) : true)
                    && (localStorage.defaultModule === 'service' && filterFields.name === 'workshopManager' ? _.includes(roleWorkIds, a.roleID) : true)
                    && (localStorage.defaultModule === 'enquiry' && filterFields.name === 'financeBM' ? _.includes(rolefinanceBM, a.roleID) : true)
                    && (localStorage.defaultModule === 'enquiry' && filterFields.name === 'afterMarketBM' ? _.includes(roleafterMarketBM, a.roleID) : true)
                    && (localStorage.defaultModule === 'service' && filterFields.name === 'serviceAdvisor' ? _.includes(roleAdvIds, a.roleID) : true)).forEach(rec => {
                        _users.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>,
                            data: rec,
                            name: rec.name
                        })
                    })
            }

            setUserOptions(_users)
        }
    }, [filterFields.name])

    useEffect(() => {
        // if (_.isEmpty(props.clientUsers))
        //     return
        let _roleids = props.dealersettings.client.roles ? props.dealersettings.client.roles.filter(rec => rec.pipeline && rec.pipeline.some(p => p === 'LeadsBucket')).map(rec => { return rec.id }) : [];
        let _users = [];
        if (props.fromOEM === true) {
            _.filter(props.groupUsers, (v) => _roleids.length > 0 ? _.includes(_roleids, v.roleID) : true).forEach(rec => {
                if (!_users.some(e => e.value === rec.id)) {
                    _users.push({
                        value: rec.id,
                        searchlabel: rec.name,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        clientIDs: rec.clientIDs,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        ),
                    });
                }
            });
        }
        else {
            _.filter(props.clientUsers.filter(a => Boolean(a.superAdmin) === false), (v) => _roleids.length > 0 ? _.includes(_roleids, v.roleID) : true).forEach(rec => {
                _users.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>,
                    data: rec,
                    name: rec.name
                })
            })
        }

        setInboundUser(_users)
    }, [])

    //#region CHANGE EVENTS

    const updateFilterFields = (_state) => {
        setFilterFields(_state);
        props.updateFilter(_state);
    }

    const handleSelectNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        const { value, id } = e;
        state['name'] = value;
        state['dynValue'] = id || null;
        state['value'] = null;
        state['subList'] = null;
        // delete state['type'];
        // delete state['range'];
        // delete state['stage'];
        // delete state['state'];
        // delete state['model'];
        // delete state['lostSubReason'];

        if (value === 'date') {
            state['type'] = 'thisMonth';
        }

        updateFilterFields(state);
    }

    const handleMultiSelectChange = (selectedOptions, data, commaSeparate) => {
        let state = Object.assign({}, filterFields);
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        if (data.name === 'lostsubreason' || data.name === 'fileCategory') {
            state['subList'] = {
                [data.name]: value
            };
        }
        else {
            state['value'] = {
                ...state['value'],
                [data.name]: commaSeparate ? value.join(',') : value
            };
        }

        updateFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (data.name === 'make') {
            state['value'] = {
                ...state['value'],
                ['models']: [],
                [data.name]: e ? e.value : null
            };
        } else if (data?.name?.includes('text-') || data?.name?.includes('number-') || data?.name?.includes('includeStageHistory')) {
            state['value'] = {
                ...state['value'],
                ['values']: null,
                [data.name]: e ? e.value : null
            };
        } else if (data?.name?.includes('files')) {
            state['subList'] = [];
            state['value'] = {
                ...state['value'],
                [data.name]: e ? e.value : null
            };
        } else {
            state['value'] = {
                ...state['value'],
                [data.name]: e ? e.value : null
            };
        }

        updateFilterFields(state);
    }


    const deleteFilter = (docID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to delete the rule.`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(result => {
            if (result.value) {
                props.deleteFilter(docID)
            }

        });

    }

    //#endregion


    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            state['type'] = e.value;
            state['date'] = e.value;
            const _dateRange = CommonHelper.handleDateRangeFilter({ date: state }, props.dealersettings);
            state.value = {
                startDate: window.firebase.firestore.Timestamp.fromDate(moment(_dateRange.startDate).startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment(_dateRange.endDate).endOf('day')._d)
            }
            updateFilterFields(state);
        }
    }
    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        if (val) {
            state.type = 'custom';
            state.value = {
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            };
        }
        else {
            state.value = {
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d).format('YYYY-MM-DD'),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d).format('YYYY-MM-DD')
            }
        }
        updateFilterFields(state);

    };
    //#endregion

    const handleOnChangeMultipleText = (val, name) => {
        let state = Object.assign({}, filterFields);

        state['value'] = {
            ...state['value'],
            [name]: val.join(',')
        };
        updateFilterFields(state);

    }

    const onNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, filterFields);
        state['value'] = {
            ...state['value'],
            [name]: e.value
        };
        updateFilterFields(state)
    }

    const removeMultipleText = (index, name) => {
        let state = Object.assign({}, filterFields);
        let arr = state?.value?.areaCode ? state.value.areaCode.split(',') : []
        arr.splice(index, 1)
        state['value'] = {
            ...state['value'],
            [name]: arr.join(',')
        };
        updateFilterFields(state);
    }

    const _filter = props.filterFields.filter(m => !_.isEmpty(m.name) && (props.fieldData && props.fieldData.name) ? m.name !== props.fieldData.name : true)
    const checklistsMandatory = !_.isEmpty(_filter) ? Array.prototype.map.call(_filter, function (item) { return item.name; }) : []
    const { errors, docID } = props;
    return (
        <>

            <div className={`rule-container ${!_.isEmpty(filterFields)
                && filterFields.name !== 'date'
                && props.report.visibility === 'sharedView'
                && props.report.addedBy !== localStorage.uid
                && !props.report.ownerIDs?.some(a => a === localStorage.uid)
                && !Boolean(props.dealersettings.superAdmin) ? 'btn-disable' : ''}`}>
                {
                    ((!_.isEmpty(props.filterFields) && Object.keys(props.filterFields).length <= 1) || (!_.isEmpty(filterFields) && filterFields.name === 'date'))
                        ?
                        <></>
                        :
                        <>
                            <div className="rule-actions">
                                <a href="#"
                                    className="mini-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteFilter(docID);
                                    }}>
                                    <i className="ico icon-delete"></i>
                                </a>
                            </div>
                        </>
                }


                <div className="settings-head form-style mb-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-2">
                                    <ReactSelect
                                        options={props.filterOptions.filter(m =>
                                            (localStorage.defaultModule === 'oem' ? m.value !== 'triggers' : true)
                                            && (props.report.columnType === 'stock' ? m.type === 'stock' : true)
                                            && (!_.isEmpty(checklistsMandatory) ? checklistsMandatory.indexOf(m.value) < 0 : true)
                                        )
                                        }
                                        name={"fieldName"}
                                        placeholder={'select option'}
                                        onChange={handleSelectNameChange}
                                        value={filterFields.name ? filterFields.name : null}
                                        classNamePrefix={`${errors && errors["name_" + docID]} cursor-pointer basic-select`}
                                        removeClearable={true}
                                        isDisabled={(!_.isEmpty(filterFields) && filterFields.name === 'date') ? true : false}
                                    >
                                    </ReactSelect>
                                </div>

                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'date'
                                        ?
                                        <>
                                            <div className="col-2">
                                                <ReactSelect
                                                    options={dateOptions.filter(a => props.report.columnType === 'stock' ? true : a.value !== 'overall')}
                                                    id={"date-type"}
                                                    name={"date"}
                                                    placeholder={'select date option'}
                                                    onChange={handleDateTypeChange}
                                                    value={filterFields && filterFields.type ? filterFields.type : null}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                            {
                                                filterFields?.type === 'overall' ? (<></>) : (
                                                    <div className="col-4">
                                                        <AntDateRangePicker
                                                            value={
                                                                (!_.isEmpty(filterFields) && !_.isEmpty(filterFields.value))
                                                                    ? [moment.unix(filterFields.value.startDate.seconds), moment.unix(filterFields.value.endDate.seconds)] :
                                                                    null
                                                            }
                                                            id={"date-range"}
                                                            name={'date'}
                                                            format='DD/MM/YYYY'
                                                            onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                            placeholder='DD/MM/YYYY'
                                                            className={'date-range-ams-fix'}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && (filterFields.name === 'user' ||
                                        filterFields.name === 'technician' ||
                                        filterFields.name === 'partsInterpretor' ||
                                        filterFields.name === 'workshopManager' ||
                                        filterFields.name === 'serviceAdvisor' ||
                                        filterFields.name === 'financeBM' ||
                                        filterFields.name === 'afterMarketBM' ||
                                        filterFields.name === 'enqAddedBy')
                                        ?
                                        <div className="col-6">
                                            <ReactMultiSelect
                                                options={userOptions}
                                                name={filterFields.name}
                                                placeholder={`select user`}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value[filterFields.name] : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && filterFields.name === 'inboundUser'
                                        ?
                                        <div className="col-6">
                                            <ReactMultiSelect
                                                options={inbounduserOptions}
                                                name={"inboundUser"}
                                                placeholder={'select user'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields && filterFields.value ? filterFields.value['inboundUser'] : []}
                                                classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && (filterFields.name === 'enquiryTypes'
                                        || filterFields.name === 'campaigns'
                                        || filterFields.name === 'origins'
                                        || filterFields.name === 'salesType'
                                        || filterFields.name === 'lostReasons'
                                        || filterFields.name === 'servicePackages'
                                        || filterFields.name === 'leadSource'
                                        || filterFields.name === 'pipelines'
                                        || filterFields.name === 'status'
                                        || filterFields.name === 'financeStatus'
                                        || filterFields.name === 'afterMarketStatus'
                                        || filterFields.name === 'label'
                                        || filterFields.name === 'callStatus'
                                        || filterFields.name === 'appointmentTypes'
                                        || filterFields.name === 'serviceStatus'
                                        || filterFields.name === 'serviceType'
                                        || filterFields.name === 'tradeinProStatus'
                                        || filterFields.name === 'client'
                                        || filterFields.name === 'stockStatus'
                                        || (filterFields?.name?.includes('select-') || filterFields?.name?.includes('multiselect-'))
                                    )
                                        ?
                                        <>
                                            <div className={`${filterFields.name === 'lostReasons' ? 'col-4' : 'col-6'}`}>
                                                <ReactMultiSelect
                                                    options={dynOptions}
                                                    name={filterFields.name}
                                                    placeholder={`select ${_.find(props.reportFilters, { 'value': filterFields.name }) ? _.find(props.reportFilters, { 'value': filterFields.name }).displayValue : filterFields.name === 'servicePackages' ? 'service packages' : ''}`}
                                                    onChange={(e) => {
                                                        handleMultiSelectChange(e, filterFields);
                                                        if (filterFields.name === 'lostReasons') {
                                                            let value = [];
                                                            !_.isEmpty(e) && e.map((data) => {
                                                                value.push(data.value)
                                                            })
                                                            setLostreason(value)
                                                        }
                                                    }}
                                                    value={filterFields && filterFields.value ? filterFields.value[filterFields.name] : []}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                            {
                                                filterFields.name === 'lostReasons' ? (
                                                    <div className="col-4">
                                                        <ReactMultiSelect
                                                            options={lostSubReasons}
                                                            name={'lostsubreason'}
                                                            placeholder={`select lost sub reason`}
                                                            onChange={handleMultiSelectChange}
                                                            value={filterFields && filterFields.subList ? filterFields.subList['lostsubreason'] : []}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                ) : (<></>)
                                            }

                                        </>
                                        :
                                        <></>
                                }
                                {
                                    filterFields?.name?.includes('text-') || filterFields?.name?.includes('number-') || filterFields?.name?.includes('includeStageHistory') || filterFields?.name?.includes('files') || filterFields?.name?.includes('stockAge') | filterFields?.name?.includes('stockEnquiries') || filterFields?.name?.includes('dateType') ? (
                                        <>
                                            <div className={`col-${filterFields?.name?.includes('dateType') ? '6' : '1'}`}>
                                                <ReactSelect
                                                    options={dynOptions}
                                                    name={filterFields.name}
                                                    placeholder={`select`}
                                                    onChange={(e) => {
                                                        handleSelectChange(e, { ...filterFields });
                                                    }}
                                                    value={filterFields && filterFields.value ? filterFields.value[filterFields.name] : []}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>
                                            {
                                                filterFields?.value?.[filterFields.name] === 'is' ? (
                                                    <div className="col-6">
                                                        <InputMultipleText
                                                            placeholder={_.find(props.reportFilters, { 'value': filterFields.name }) ? _.find(props.reportFilters, { 'value': filterFields.name }).displayValue : ''}
                                                            className={`form-control react-multi-tag`}
                                                            name={`values`}
                                                            onChange={(data) => { handleOnChangeMultipleText(data, 'values') }}
                                                            value={filterFields?.value?.values ? filterFields.value['values'].split(',') : []}
                                                            validateEmail={false}
                                                            getLabel={(data, index) => {
                                                                return <div data-tag key={index}>
                                                                    {data}
                                                                    <span data-tag-handle onClick={() => removeMultipleText(index, 'values')}>
                                                                        ×
                                                                    </span>
                                                                </div>
                                                            }}
                                                        />
                                                    </div>
                                                ) : filterFields?.name?.includes('files') ? (
                                                    <div className="col-6">
                                                        <ReactMultiSelect
                                                            options={fileCategory}
                                                            name={'fileCategory'}
                                                            placeholder={`select category`}
                                                            onChange={handleMultiSelectChange}
                                                            value={filterFields && filterFields.subList ? filterFields.subList['fileCategory'] : []}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                ) : filterFields?.name?.includes('stockEnquiries') || filterFields?.name?.includes('stockAge') ? (
                                                    <div className="col-5">
                                                        <NumberFormat
                                                            thousandSeparator={false}
                                                            placeholder={filterFields?.name?.includes('stockEnquiries') ? 'no. of enquiries' : 'no. of days'}
                                                            className={`form-control`}
                                                            onValueChange={onNumberChange.bind(this, `${filterFields.name}Values`)}
                                                            value={filterFields?.value?.[`${filterFields.name}Values`] || 0}
                                                        />
                                                    </div>
                                                ) : (<></>)
                                            }

                                        </>
                                    ) : (<></>)
                                }
                                {
                                    !_.isEmpty(filterFields) && (filterFields.name === 'inboundSource')
                                        ?
                                        <>
                                            <div className={`col-6`}>
                                                <ReactMultiSelect
                                                    options={integrations}
                                                    name={'dmstype'}
                                                    placeholder={`select inbound source`}
                                                    onChange={(e) => {
                                                        handleMultiSelectChange(e, { ...filterFields, name: 'dmstype' });
                                                    }}
                                                    value={filterFields && filterFields.value ? filterFields.value['dmstype'] : []}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>

                                        </>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && (filterFields.name === 'makeModels')
                                        ?
                                        <>
                                            {
                                                localStorage.defaultModule === 'service' || localStorage.defaultModule === 'tradeinPro' ? (
                                                    <div className={`col-2`}>
                                                        <ReactSelect
                                                            options={makes}
                                                            name={"make"}
                                                            placeholder={'select make'}
                                                            onChange={(e, data) => {
                                                                handleSelectChange(e, { ...filterFields, name: 'make' });
                                                            }}
                                                            value={filterFields && filterFields.value ? filterFields.value['make'] : null}
                                                            classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                ) : (<div className={`col-3`}>
                                                    <ReactMultiSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={(e, data) => {
                                                            handleMultiSelectChange(e, { ...filterFields, name: 'make' }, true);
                                                        }}
                                                        value={filterFields?.value?.['make'] ? filterFields.value['make'].split(',') : null}
                                                        classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>)
                                            }

                                            <div className={`col-4`}>
                                                <ReactMultiSelect
                                                    options={models}
                                                    name={'model'}
                                                    placeholder={`select model`}
                                                    onChange={(e) => {
                                                        handleMultiSelectChange(e, { ...filterFields, name: 'model' });
                                                    }}
                                                    value={filterFields && filterFields.value ? filterFields.value['model'] : []}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                            {
                                                props.columns && props.columns.includes('testdrive') ? (
                                                    <div className="col-md-2">
                                                        <ReactSelect
                                                            options={vehicleBasedFilters}
                                                            name={"vehicleSearchOn"}
                                                            placeholder={'search based on'}
                                                            onChange={(e) => handleSelectChange(e, { ...filterFields, name: 'vehicleSearchOn' })}
                                                            value={filterFields && filterFields.value ? filterFields.value['vehicleSearchOn'] : null}
                                                            classNamePrefix={` basic-select`}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                ) : (<></>)
                                            }
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && (filterFields.name === 'leadType')
                                        ?
                                        <>
                                            <div className={`col-6`}>
                                                <ReactSelect
                                                    options={leadTypes}
                                                    name={"leadType"}
                                                    placeholder={'select lead type'}
                                                    onChange={(e, data) => {
                                                        handleSelectChange(e, { ...filterFields, name: 'leadType' });
                                                    }}
                                                    value={filterFields && filterFields.value ? filterFields.value['leadType'] : null}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && (filterFields.name === 'triggers')
                                        ?
                                        <>
                                            <div className={`col-6`}>
                                                <ReactMultiSelect
                                                    options={triggers}
                                                    name={"triggers"}
                                                    placeholder={'select triggers'}
                                                    onChange={(e, data) => {
                                                        handleMultiSelectChange(e, { ...filterFields, name: 'triggers' });
                                                    }}
                                                    value={filterFields && filterFields.value ? filterFields.value['triggers'] : []}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && (filterFields.name === 'callType')
                                        ?
                                        <>
                                            <div className={`col-6`}>
                                                <ReactSelect
                                                    options={callTypes}
                                                    name={"callType"}
                                                    placeholder={'select call type'}
                                                    onChange={(e, data) => {
                                                        handleSelectChange(e, { ...filterFields, name: 'callType' });
                                                    }}
                                                    value={filterFields && filterFields.value ? filterFields.value['callType'] : null}
                                                    classNamePrefix={`${errors && errors["value_" + docID]} cursor-pointer basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    !_.isEmpty(filterFields) && (filterFields.name === 'areaCode')
                                        ?
                                        <>
                                            <div className={`col-6`}>
                                                <InputMultipleText
                                                    placeholder={`other recipients`}
                                                    className={`form-control react-multi-tag`}
                                                    name="areaCode"
                                                    onChange={(data) => { handleOnChangeMultipleText(data, 'areaCode') }}
                                                    value={filterFields?.value?.areaCode ? filterFields.value['areaCode'].split(',') : []}
                                                    validateEmail={false}
                                                    getLabel={(data, index) => {
                                                        return <div data-tag key={index}>
                                                            {data}
                                                            <span data-tag-handle onClick={() => removeMultipleText(index, 'areaCode')}>
                                                                ×
                                                            </span>
                                                        </div>
                                                    }}
                                                />
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>



            </div>



        </>
    )
}

export default CustomFilters;