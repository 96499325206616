import React, { useEffect, useState } from 'react';
import EmailListBody from '../emailListBody';
import EmailList from '../list';
import _ from 'lodash';

const Sent = ({
	messages,
	setMessages,
	token,
	handleOpenComposeModal,
	filterThreads,
	updateMessage,
	selectedMessage,
	setSelectedMessage,
	shouldChildSetSelectedMsg,
	setShouldChildSetSelectedMsg,
	searchText,
	setSearchText,
	loadMoreThreads,
	loadingMore,
	refreshLoader,
	searchThreads,
	refreshThreads,
	structureType,
	handleMarkAs,
	selectThreads,
	userData,
	nylasAccountData,
	labels,
	folders,
	checkDataLoad,
	setDataload,
	isPaging,
	setPaging,
	hasMore,
	setHasMoreData,
	dispatch,
	setLoadingMore,
	isLimitReached,
	setIsLimitReached
}) => {
	const [filteredMessages, setFilteredMessages] = useState([]);
	const [threadsSelected, setThreadsSelected] = useState(false);
	const [threadsLoader, setThreadsLoader] = useState(true);
	const pageLimit = 30

	let folderID = null;
	if (structureType === 'labels') {
		labels.forEach(label => {
			if (label.name === 'sent') {
				folderID = label.id
			}
		})
	} else if (structureType === 'folders') {
		folders.forEach(folder => {
			if (folder.name === 'sent') {
				folderID = folder.id
			}
		})
	}

	useEffect(() => {
		return () => {
			window.unsubinboxlist && window.unsubinboxlist();
		}

	}, [])

	useEffect(() => {
		if (!checkDataLoad) {
			return;
		}


		let threadsRef = window.firebase.firestore().collection(`nylas-accounts/${userData.nylas.account_id}/nylas-threads`)
			.where('folderIDs', 'array-contains', folderID)
			.orderBy('last_message_timestamp', 'desc')

		if (hasMore.length > 0) {
			threadsRef = threadsRef
				.startAfter(hasMore[0])
				.limit(pageLimit)
		}
		else {
			threadsRef = threadsRef
				.limit(pageLimit)
		}

		window.unsubinboxlist = threadsRef
		.onSnapshot(onCollectionUpdate);

	}, [checkDataLoad])

	const onCollectionUpdate = (querySnapshot) => {
		let actionType;
		let snapshotDoc;
		let messagesArr = [];

		querySnapshot.docChanges().forEach(change => {
			if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
			{
				snapshotDoc = {
					...change.doc.data(),
				};
				if (change.type === 'added') {
					actionType = "ADD_LIST";
				}
				else if (change.type === 'modified') {
					actionType = "UPDATE_LIST"
				}
				else if (change.type === 'removed' && snapshotDoc.folderIDs.indexOf(folderID) < 0) {
					actionType = "REMOVE_LIST"
				}
			}
		})
		if (!actionType) {
			//console.log('querySnapshot.docs.length, pageLimit', querySnapshot.docs.length, pageLimit)
			if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
				setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
			}
			else {
				setHasMoreData([]);
				setIsLimitReached(true)
			}
			if (querySnapshot.size > 0) {
				querySnapshot.forEach(doc => {
					messagesArr.push(doc.data());
				});
				// setMessages(messagesArr)
			} else {
				messagesArr = [];
				// setMessages([])
				// return
			}
		}

		dispatch({
			type: isPaging ? "APPEND_LIST" : actionType ? actionType : "SUCCESS",
			data: actionType ? snapshotDoc : messagesArr
		});


		setDataload(false)
		// setLoader(false)
		setThreadsLoader(false)
		setPaging(false)
		setLoadingMore(false)
	}

	useEffect(() => {
		const filteringMessages = () => {
			if (messages && messages.length > 0) {
				let filteredMessages1 = messages.filter(message => {
					let result = false;
					let isTrashDetected = false;
					message[structureType].forEach(label => {
						// console.log('labels ===', message);

						if (isTrashDetected) {
							return;
						}

						if (label && label.name === 'sent') {
							result = true;
						}

						if (label && label.name === 'trash') {
							isTrashDetected = true;
							result = false;
						}
					});

					return result;
				});

				setFilteredMessages([...filteredMessages1]);
				if (shouldChildSetSelectedMsg) {
					// setSelectedMessage(filteredMessages1[0]);;
				} else if (!shouldChildSetSelectedMsg) {
					setShouldChildSetSelectedMsg(true);
				}
			}
		}
		filteringMessages();
	}, [messages]);



	return (
		<div className='email-list-wraper'>
			<EmailList
				messages={filteredMessages}
				setSelectedMessage={setSelectedMessage}
				selectedMessage={selectedMessage}
				filterThreads={filterThreads}
				selectThreads={selectThreads}
				threadsSelected={threadsSelected}
				searchText={searchText}
				setSearchText={setSearchText}
				updateMessage={updateMessage}
				loadMoreThreads={loadMoreThreads}
				type={'sent'}
				loadingMore={loadingMore}
				searchThreads={searchThreads}
				refreshThreads={refreshThreads}
				isLimitReached={isLimitReached}
				threadsLoader={threadsLoader}
				refreshLoader={refreshLoader}
				handleMarkAs={handleMarkAs}
				setThreadsSelected={setThreadsSelected}
				setFilteredMessages={setFilteredMessages}
				filteredMessages={filteredMessages}
			/>
			{filteredMessages.length > 0 ? (
				<EmailListBody
					selectedMessage={selectedMessage}
					setSelectedMessage={setSelectedMessage}
					token={token}
					handleOpenComposeModal={handleOpenComposeModal}
					updateMessage={updateMessage}
					threadsLoader={threadsLoader}
					refreshLoader={refreshLoader}
					type={'sent'}
					userData={userData}
				/>
			) : (
					<div className='email-list-body'></div>
				)}
		</div>
	);
};

export default Sent;
