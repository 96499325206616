import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';

import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { ContentOverlay, TableView } from '../../../components';
import ContactListReducer from '../../contacts/contactlistReducer';
import { allcontactFields, objContact } from '../../contacts/viewModel';
import FilterPanel from '../../contacts/filter';
import { firestoreDB } from '../../../services/helper';
import SlotOptions from './slot'
import { PopUpModal } from '../../../components';

const AddContact = props => {
	const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 140) })
	const [contacts, dispatch] = useReducer(ContactListReducer, [])
	const [checkDataLoad, setDataload] = useState(false)
	const [sortName, setSortName] = useState('firstName')
	const [sortOrder, setSortOrder] = useState('asc')
	const [hasMore, setHasMoreData] = useState([])
	const [contactFields, setContactFields] = useState()
	const [settingsLoader, setSettingsLoader] = useState(false)
	const [modelShow, setModelShow] = useState(false)
	const [editDocumentID, setEditDocument] = useState()
	const [isPaging, setPaging] = useState(false)
	const [contactLoader, setContactLoader] = useState(true)
	const pageLimit = 100

	const [customFilters, setCustomFilters] = useState([])
	const [isFilterApplied, setFilterApplied] = useState(false)
	const [pageNum, setPageNum] = useState(0);
	const [selectedIds, setSelectedIds] = useState({})

	const [modalMergeShow, setModalMergeShow] = useState({ show: false, mergeContacts: [] })
	const [modalTransferShow, setModalTransferShow] = useState(false)

	const [titles, setTitles] = useState([])
	const [languages, setLanguages] = useState([])
	const [nationalities, setNationalities] = useState([])
	const [campaigns, setCampaigns] = useState([])
	const [origins, setOrigins] = useState([])

	const [csvHeader, setHeader] = useState([])

	const [searchText, setSearchText] = useState(localStorage.contactSearchText ? localStorage.contactSearchText : '');
	const [filterClient, setFilterClient] = useState(props.clientID && props.dealersettings?.clients?.some(a => a.documentID === props.clientID) ? props.clientID : props.dealersettings?.client?.id)
	const [contactCount, setContactCount] = useState(0);
	const [importLoader, setimportLoader] = useState(false)

	const [showSlot, setShowSlot] = useState(false)

	useEffect(() => {
		let headerFields = contactFields;
		let allHeaderFields = allcontactFields;
		if (_.isEmpty(headerFields) || headerFields.length === 0) {
			headerFields = allHeaderFields.filter(e =>
				e.default === true
				&& e.fixed !== true
				&& e.fixedRight !== true
			).map(v => _.pick(v, ['value']).value)
		}
		headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
		var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
			return {
				label: e.name,
				key: !_.isEmpty(e.elementName) ? e.elementName : e.value
			}
		});
		_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
			var index = _.findIndex(_headers, (e) => {
				return e.key == rec.value;
			}, 0)
			if (index > 0) {
				_headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
			}
			else {
				_headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
			}
		})
		setHeader(_headers);

	}, [contactFields])

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: (window.innerWidth - 30),
				windowHeight: (window.innerHeight - 140)
			})
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCmpnContacts && window.unSubCmpnContacts();
		}
	}, [])

	useEffect(() => {
		if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings) && !isFilterApplied) {
			//setDataload(false)

			try {

				let _searchObject = {};
				const { dealersettings } = props;
				if (filterClient)
					_searchObject.clientID = filterClient
				else
					_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, filterClient);

				const addContact = window.firebase.functions().httpsCallable('contact-getContactCount');
				addContact(_searchObject).then((_data) => {
					if (!_.isEmpty(_data) && !_.isEmpty(_data.data) && _data.data.length > 0) {
						console.log('contact-getContactCount', _data.data[0])
						setContactCount(_data.data[0].contact_count);
					}

				});
			}
			catch (error) {
				console.error(error)
			}
			setDataload(true)
		}
	}, [props.dealersettings, isFilterApplied, filterClient])

	useEffect(() => {

		if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

			const setttings = props.dealersettings.client.settings;

			let _titles = [];
			setttings.titles && setttings.titles.forEach((doc) => {
				_titles.push({
					value: doc.value,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name
				});
			});

			let _languages = [];
			props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
				_languages.push({
					value: doc.code,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name
				});
			});

			let _nationalities = [];
			props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
				_nationalities.push({
					value: doc.value,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name
				});
			});

			let _campaigns = [];
			setttings.campaigns && setttings.campaigns.forEach(doc => {
				campaigns.push({
					value: doc.value,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name,
				});
			});

			let _origins = [];
			setttings.origins && setttings.origins.forEach(doc => {
				origins.push({
					value: doc.value,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name,
				});
			});

			setCampaigns(_campaigns);
			setOrigins(_origins);

			setTitles(_titles);
			setLanguages(_languages);
			setNationalities(_nationalities)
			setDataload(true);
		}
	}, [])

	useEffect(() => {
		let settingsid = props.dealersettings.client.settings.id;
		let userID = props.dealersettings.id;
		const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
			.where('module', '==', 'contacts')
			.onSnapshot((querySnapshot) => {
				let _customFilters = [];
				querySnapshot.forEach(doc => {
					const _filt = {
						...doc.data(),
						documentID: doc.id,
						clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
					}
					_customFilters.push(_filt);
				});
				_customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
					(m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
				setCustomFilters(_customFilters)

			});
		return () => {
			userRefDoc && userRefDoc();
		}
	}, [])

	useEffect(() => {
		//console.log('localStorage.contactFilter', isFilterApplied, localStorage.contactFilter)
		if (localStorage.contactFilter && !isFilterApplied)
			setFilterApplied(true);

	}, [localStorage.contactFilter])

	useEffect(() => {
		if (!checkDataLoad) {
			return;
		}
		const { dealersettings } = props;
		let clientID = props.clientID && dealersettings?.clients?.some(a => a.documentID === props.clientID) ? props.clientID : dealersettings.client.id;
		// const viewOtherContact = ((!_.isEmpty(dealersettings) &&
		//     !_.isEmpty(dealersettings.rolePermissions) &&
		//     !_.isEmpty(dealersettings.rolePermissions.permissions) &&
		//     dealersettings.rolePermissions.permissions.viewOtherContact) ? true : false);

		if (localStorage.contactFilter && isValidFilter()) {
			let _contactFilter = JSON.parse(localStorage.contactFilter);
			let _filter = Object.assign({}, _contactFilter.value);
			let _sortOrder = sortName + ' ' + sortOrder;

			// if (!viewOtherContact)
			//     _filter.owner = dealersettings.id;

			/* DATE RANGE FILTER */
			if (!_.isEmpty(_filter.date)) {
				_filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
				delete _filter['date'];
			}

			/* MAKE & MODEL FILTER */
			if (!_.isEmpty(_filter.make))
				_filter = CommonHelper.handleMakeModelFilter(_filter, dealersettings);

			_filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
			_filter.emailCampaign = true;

			if (filterClient)
				_filter.clientID = filterClient
			else
				_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, null, clientID);

			let _searchObject = {
				"type": "searchContacts",
				"filters": JSON.stringify(_filter),
				"sortOrder": _sortOrder,
				"pageNum": pageNum,
				"pageLimit": pageLimit
			}
			if (filterClient)
				_searchObject.clientID = filterClient
			else
				_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, clientID);
			console.log('generic-searchData', isFilterApplied, pageNum, _filter, localStorage.contactFilter)
			const searchContacts = window.firebase.functions().httpsCallable('generic-searchData');
			searchContacts(_searchObject).then((response) => {
				//console.log('generic-searchData', response);
				if (response.data.success) {
					onCollectionContacts(response.data);
					setContactCount(response.data.total)
				}
			});

			window.unSubCmpnContacts && window.unSubCmpnContacts();
		}
		else {

			let searchKeywords = searchText;
			let selectClient = filterClient;
			let searchOwners = [];

			if (localStorage.contactFilter) {
				let _contactFilter = JSON.parse(localStorage.contactFilter);
				let _filter = Object.assign({}, _contactFilter.value);

				searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
				searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

				setSearchText(searchKeywords);
				setFilterClient(selectClient);

				localStorage.contactSearchText = searchKeywords;
				localStorage.contactFilterClient = selectClient;
				console.log('search---Keywords', searchKeywords, searchOwners, searchText)
			}
			let secondaryDB = window.firebase;
			if (props.dealersettings?.clients?.some(a => a.documentID === selectClient && a.projectId === window.secondaryProjectId)) {
				secondaryDB = window.firebase2;
			}

			let campAddDataRef = secondaryDB.firestore().collection('contactDetails')
				//.where('clientID', '==', dealersettings.client.id)
				.where('isDeleted', '==', false)

			if (selectClient)
				campAddDataRef = campAddDataRef.where('clientID', '==', selectClient)
			else
				campAddDataRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(campAddDataRef, dealersettings, null, null, clientID);

			if (searchKeywords) {
				let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)

				if (formattedSearchText.trim())
					campAddDataRef = campAddDataRef.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
			}

			if (!_.isEmpty(searchOwners))
				campAddDataRef = campAddDataRef.where('owner', 'in', searchOwners)

			// if (!viewOtherContact)
			//     campAddDataRef = campAddDataRef.where('owner', '==', dealersettings.id)

			campAddDataRef = campAddDataRef
				.orderBy('firstName', 'asc')

			if (hasMore.length > 0) {
				campAddDataRef = campAddDataRef
					.startAfter(hasMore[0])
					.limit(pageLimit)
			}
			else {
				campAddDataRef = campAddDataRef
					.limit(pageLimit)
			}

			// window.unSubCmpnContacts = campAddDataRef
			// 	.onSnapshot(onCollectionUpdate);

			campAddDataRef.get().then(onCollectionUpdate);
			// return () => {
			//     window.unSubCmpnContacts();
			// }
		}

	}, [checkDataLoad])

	const onCollectionUpdate = (querySnapshot) => {
		let actionType;
		let snapshotDoc;
		// querySnapshot.docChanges().forEach(change => {
		// 	if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
		// 	{
		// 		snapshotDoc = convertContactVM(change.doc.data());
		// 		if (change.type === 'added') {
		// 			actionType = "ADDCONTACT";
		// 		}
		// 		else if (change.type === 'modified') {
		// 			actionType = "UPDATECONTACT"
		// 		}
		// 		else if (change.type === 'removed') {
		// 			actionType = "REMOVECONTACT"
		// 		}
		// 	}
		// })
		const contacts = [];
		if (!actionType) {
			if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
				setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
			}
			else {
				setHasMoreData([]);
			}
			querySnapshot.forEach((doc) => {
				contacts.push(convertContactVM({
					...doc.data(),
					documentID: doc.id
				}));
			});
		}
		if (isPaging) {
			dispatch({
				type: "APPENDCONTACT",
				data: contacts,
				sortName: 'firstName',
				sortOrder: 'asc'
			});
		}
		else {
			dispatch({
				type: actionType ? actionType : "SUCCESS",
				data: actionType ? snapshotDoc : contacts,
				sortName: 'firstName',
				sortOrder: 'asc'
			});
		}


		setDataload(false)
		setContactLoader(false)
		setPaging(false)
	}

	const onCollectionContacts = (querySnapshot) => {

		const _contacts = [];

		if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
			setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
			let _pageNum = pageNum + 1;
			setPageNum(_pageNum)
		}
		else {
			setHasMoreData([]);
		}
		querySnapshot.data.forEach(function (doc) {
			var logVM = convertContactVM(doc);
			_contacts.push(logVM)
		});

		dispatch({
			type: isPaging ? "APPENDCONTACT" : "SUCCESS",
			data: _contacts,
			sortName: 'firstName',
			sortOrder: 'asc'
		});

		setContactLoader(false)
		setDataload(false)
		setPaging(false)
	}

	const convertContactVM = (doc) => {

		doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
		doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

		const objcontactData = Object.assign({}, doc);
		const contact = Object.assign({}, objContact);
		for (let [key, value] of Object.entries(objcontactData)) {
			contact[key] = value;
		}
		contact.objDoc = Object.assign({}, contact);
		contact.fullName = CommonHelper.getFullName(titles, contact);
		contact.phone = CommonHelper.getFullPhone(doc.phoneCode, doc.phone);
		contact.displayPhone = doc.phone ? (doc.phoneCode ? doc.phoneCode : '') + doc.phone : '';
		contact.displayID = contact.displayID ? contact.displayID : '--';
		contact.licenseExpiry = contact.licenseExpiry ? moment(contact.licenseExpiry).format('DD/MM/YYYY') : '';
		contact.createdOn = moment.unix(contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
		contact.updatedOn = moment.unix(contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');
		contact.company = !_.isEmpty(contact.company) ? contact.company.name : '';
		contact.dob = contact.dob ? moment(contact.dob).format('DD/MM/YYYY') : '';
		contact.secondaryName = contact.secondaryFirstName ? (contact.secondaryFirstName + ' ' + contact.secondaryLastName) : '';
		contact.secondaryPhone = CommonHelper.getFullPhone(contact.secondaryPhoneCode, contact.secondaryPhone);
		contact.addedBy = CommonHelper.getUserNamebyId(props.clientUsers, contact.addedBy);
		contact.modifiedBy = CommonHelper.getUserNamebyId(props.clientUsers, contact.modifiedBy);
		contact.owner = contact.owner ? CommonHelper.getUserNamebyId(props.clientUsers, contact.owner) : '';
		contact.language = CommonHelper.getLabelByValue(languages, contact.language, '');
		contact.nationality = CommonHelper.getLabelByValue(nationalities, contact.nationality, '');
		contact.campaign = CommonHelper.getLabelByValue(campaigns, contact.campaign, '');
		contact.origin = CommonHelper.getLabelByValue(origins, contact.origin, '');

		let dealersettings = props.dealersettings;
		if (dealersettings && dealersettings.client && dealersettings.client.settings) {
			contact.gender = CommonHelper.getNameByValue(dealersettings.client.settings.genders, contact.gender, '');
			contact.maritalStatus = CommonHelper.getNameByValue(dealersettings.client.settings.maritalStatus, contact.maritalStatus, '');
			contact.contactMethod = CommonHelper.getNameByValue(dealersettings.client.settings.contactMethod, contact.contactMethod, '');
			contact.interests = CommonHelper.getLabelByMultipleValues(dealersettings.client.settings.interests, contact.interests, '', true);
			contact.licenseType = CommonHelper.getNameByValue(dealersettings.client.settings.licenseType, contact.licenseType, '');
		}

		contact.isVIP = doc.isVIP ? 'Yes' : 'No';
		contact.isGDPR = doc.gdprDeleted ? 'Yes' : 'No';
		contact.banLoanVehicle = doc.banLoanVehicle ? 'Yes' : 'No';
		contact.isDLScan = doc.isDLScan ? 'Yes' : 'No';
		contact.optinPhone = contact.optinPhone === null ? '' : (contact.optinPhone ? 'Yes' : 'No');
		contact.optinEmail = contact.optinEmail === null ? '' : (contact.optinEmail ? 'Yes' : 'No');
		contact.optinPost = contact.optinPost === null ? '' : (contact.optinPost ? 'Yes' : 'No');
		contact.optinSMS = contact.optinSMS === null ? '' : (contact.optinSMS ? 'Yes' : 'No');
		contact.marketingOptinEmail = contact.marketingOptinEmail === null ? '' : (contact.marketingOptinEmail ? 'Yes' : 'No');
		contact.marketingOptinSMS = contact.marketingOptinSMS === null ? '' : (contact.marketingOptinSMS ? 'Yes' : 'No');
		return contact
	}

	const isValidFilter = () => {
		if (localStorage.contactFilter) {
			let _contactFilter = JSON.parse(localStorage.contactFilter);
			let _filter = Object.assign({}, _contactFilter.value);

			// if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
			// 	return false;

			// if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
			// 	return false;

			if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
				return false;

			return true;
		}
		else {
			return false;
		}
	}

	const handleApplyFilter = (_filter) => {
		if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
			localStorage.setItem('contactFilter', JSON.stringify(_filter));
			localStorage.contactSearchText = '';
			setFilterApplied(true);
			setContactLoader(true)
			setDataload(true);
			setPaging(false);
			setHasMoreData([]);
			setPageNum(0);
			setSearchText('');
		}
		else {
			handleClearFilter();
		}
	}

	const handleClearFilter = () => {
		if (!_.isEmpty(localStorage.contactFilter)) {
			localStorage.removeItem('contactFilter');
			localStorage.contactSearchText = '';
			setFilterApplied(false);
			setContactLoader(true)
			setDataload(true);
			setPaging(false);
			setHasMoreData([]);
			setPageNum(0);
			setSearchText('');
		}
	}

	const handleSavesettings = (fields, showToast) => {
		setSettingsLoader(true)
		window.firebase.firestore().doc(`/users/${localStorage.uid}`)
			.set({ contactFields: fields }, { merge: true })
			.then(() => {
				setContactFields(fields)
				setSettingsLoader(false)
				props.updateDealerSettings('contactFields', fields)
				if (showToast)
					toast.notify('Settings updated successfully', {
						duration: 2000
					})

			}).catch(error => {
				setSettingsLoader(false)
				console.error(error)
			});

	}

	const handleActionClick = (id) => {
		return <></>
	}

	const handleContactdetailsClick = (e, id) => {
		e.preventDefault();
		// props.history.push("/contacts/details/" + id);
	}

	const handlePagination = () => {
		setPaging(true)
		setDataload(true)
	}
	const handleClose = (id) => {
		setModelShow(false);
		setEditDocument(null);
	}

	const updateContact = (objContact) => {
		if (!_.isEmpty(objContact)) {
			let _data = convertContactVM(objContact);

			if (!_.isEmpty(_data)) {
				dispatch({
					type: "UPDATECONTACT",
					data: _data,
					sortName: 'firstName',
					sortOrder: 'asc'
				});
			}
		}

	}

	const handleAddContact = () => {
		setEditDocument(null);
		setModelShow(true);
	}

	const RemoveContact = (docID) => {
		let objContact = Object.assign({}, contacts.filter(e => e.documentID === docID)[0].objDoc);
		if (!_.isEmpty(objContact)) {
			let _data = convertContactVM(objContact);

			if (!_.isEmpty(_data)) {
				dispatch({
					type: "REMOVECONTACT",
					data: _data,
					sortName: 'firstName',
					sortOrder: 'asc'
				});
			}
		}

	}

	const handleAllChecked = (event) => {
		let _selectedIds = {};
		if (event.target.checked) {
			!_.isEmpty(contacts) && contacts.map(file => {
				let record = Object.assign({}, contacts.filter(e => e.documentID === file.documentID)[0].objDoc);
				_selectedIds[file.documentID] = record;
			})
		}
		setSelectedIds(_selectedIds);
	}

	const handleCheckChange = (e) => {
		const { name, checked } = e.target;

		let _selectedIds = Object.assign({}, selectedIds);
		if (checked) {
			let record = Object.assign({}, contacts.filter(e => e.documentID === name)[0]);
			if (!_.isEmpty(record))
				_selectedIds[name] = record.objDoc;
		}
		else {
			delete _selectedIds[name];
		}
		setSelectedIds(_selectedIds);
	}

	const handleShowMergeContacts = () => {
		let _selectedIds = Object.assign({}, selectedIds);

		if (_.isEmpty(_selectedIds) || (!_.isEmpty(_selectedIds) && Object.keys(selectedIds).length !== 2)) {
			Swal.fire(CommonHelper.showLocale(props, 'Please select two contacts to merge.'));
			return;
		}
		else {
			const _mergeContacts = [];
			for (let [key, value] of Object.entries(_selectedIds)) {
				_mergeContacts.push(value);
			}

			if (_mergeContacts.length === 2)
				setModalMergeShow({ show: true, mergeContacts: _mergeContacts })
		}
	}

	const handleShowTransferContacts = () => {
		let _selectedIds = Object.assign({}, selectedIds);

		if (_.isEmpty(_selectedIds)) {
			Swal.fire(CommonHelper.showLocale(props, 'Please select contact to transfer.'));
			return;
		}
		else {
			setModalTransferShow(true)
		}
	}

	const onImport = async (_slot) => {
		if (Object.keys(selectedIds).length === 0) {
			const result = await Swal.fire({
				title: 'Are you sure?',
				text: contactCount ? 'Do you want to import all ' + contactCount + ' contacts?' : 'Do you want to import all contacts?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				allowOutsideClick: false,
				allowEscapeKey: false
			})

			if (!result.value) {
				return
			}
			setimportLoader(true)

			props.setFullPageLoader({
				show: true,
				title: 'Contacts are being imported...'
			})
			let clientID = props.clientID && props.dealersettings?.clients?.some(a => a.documentID === props.clientID) ? props.clientID : props.dealersettings.client.id;
			let _contactFilter = JSON.parse(localStorage.contactFilter ? localStorage.contactFilter : "{}");
			let _filter = Object.assign({}, _contactFilter.value);
			let _sortOrder = sortName + ' ' + sortOrder;

			/* DATE RANGE FILTER */
			if (!_.isEmpty(_filter.date)) {
				_filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
				delete _filter['date'];
			}

			/* MAKE & MODEL FILTER */
			if (!_.isEmpty(_filter.make))
				_filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

			//_filter.emailImport = 'true'
			_filter.emailCampaign = 'true'
			_filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
			// console.log('_filter', _filter)
			// console.log('_sortOrder', _sortOrder)
			// console.log('contactCount', contactCount)
			// console.log('clientID', clientID)
			if (filterClient)
				_filter.clientID = filterClient
			else
				_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, props.dealersettings, null, clientID);

			let _searchObject = {
				"type": "searchContacts",
				"filters": JSON.stringify(_filter),
				"sortOrder": _sortOrder,
				"pageNum": 0,
				"pageLimit": contactCount
			}
			if (filterClient)
				_searchObject.clientID = filterClient
			else
				_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings, null, clientID);
			const searchContacts = window.firebase.functions().httpsCallable('generic-searchData');
			searchContacts(_searchObject).then((response) => {
				// console.log('generic-searchData', response);
				if (response.data.success) {
					props.handleClose(response.data.data, _slot)
				}
			}).catch(err => {
				console.error(err)
				props.setFullPageLoader({
					show: false,
					title: 'Contacts are being imported...'
				})
			})
			return
		} else {
			setimportLoader(true)
			props.handleClose(selectedIds, _slot)
		}
	}

	const handleImportOpen = () => {
		if (!_.isEmpty(props.eventSlots)) {
			setShowSlot(true);
		}
		else {
			onImport();
		}
	}
	const handleSlotClose = (_slot, _optional) => {
		setShowSlot(false);
		if (_slot || _optional)
			onImport(_slot);
	}

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							{Object.keys(selectedIds).length > 0 ? (
								<div className="list-count-btn ml-3"><span>Selected Contacts</span><span className="list-count"> {Object.keys(selectedIds).length} </span></div>
							) : <></>}
							<button
								type='button'
								className={`btn btn-primary float-left ml-2 mr-2 email-send-btn ${(importLoader || (contactCount === 0 && Object.keys(selectedIds).length === 0)) ? 'btn-disable' : ''}`}
								//onClick={onImport}
								onClick={(e) => {
									e.preventDefault();
									handleImportOpen();
								}}
							>
								{
									importLoader ?
										(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
										: (<></>)
								}
								{
									Object.keys(selectedIds).length > 0 ? 'Import' : 'Import All'
								}
							</button>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid '>
								<FilterPanel
									sortOrder={sortOrder}
									customFilters={customFilters}
									dealersettings={props.dealersettings}
									clientUsers={props.clientUsers}
									handleApplyFilter={handleApplyFilter}
									handleClearFilter={handleClearFilter}
									handleMergeContacts={handleShowMergeContacts}
									sortName={sortName}
									handlesortOrder={(val) => {
										setSortOrder(val)
										setHasMoreData([]);
										setDataload(true)
									}}
									handleSortNamechange={(val) => {
										setSortName(val)
										setHasMoreData([]);
										setDataload(true)
									}}
									handleTransfer={handleShowTransferContacts}
									csvHeader={csvHeader}
									datalist={contacts}
									searchText={searchText}
									handlesearchText={(val) => {
										setSearchText(val)
										setFilterApplied(false);
										setContactLoader(true)
										setDataload(true);
										setPaging(false);
										setHasMoreData([]);
										setPageNum(0);
										if (!_.isEmpty(localStorage.contactFilter))
											localStorage.removeItem('contactFilter');

									}}
									handleAddContact={handleAddContact}
									contactCount={contactCount}
									isUsedInAudience={true}
									hideMyContacts={true}
									filterClient={filterClient}
									handleFilterClient={(val) => {
										setFilterClient(val)
										setFilterApplied(false);
										setContactLoader(true);
										setDataload(true);
										setPaging(false);
										setHasMoreData([]);
										setPageNum(0);
										if (!_.isEmpty(localStorage.contactFilter))
											localStorage.removeItem('contactFilter');

									}}
								/>
								{
									contactLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
										(
											<div className="contact-panel" style={{ width: windowSize.windowWidth + 'px' }}>
												<TableView
													isReorderable={true}
													datalist={contacts}
													height={windowSize.windowHeight}
													width={windowSize.windowWidth}
													columns={allcontactFields}
													handleSavesettings={handleSavesettings}
													dynamicFields={(contactFields && contactFields.length > 0) ? contactFields : allcontactFields.filter(e => e.default === true && e.value !== 'settings').map(v => _.pick(v, ['value']).value)}
													settingsLoader={settingsLoader}
													handleActionClick={handleActionClick}
													handleRowSingleClick={handleContactdetailsClick}
													isSettings={false}
													hasMore={hasMore}
													handlePagination={handlePagination}
													isPaging={true}
													selectedIds={selectedIds}
													handleAllChecked={handleAllChecked}
													handleCheckChange={handleCheckChange}
													displayByValue={true}
												/>
											</div>
										)
								}
							</div>
						</div>
					</Modal.Body>
				</div>
			</Modal>

			<PopUpModal show={showSlot}>
				<SlotOptions
					{...props}
					show={showSlot}
					title={'Slot Options'}
					buttonTitle={'import'}
					handleClose={handleSlotClose}
				/>
			</PopUpModal>
		</>
	);
};

export default AddContact;
