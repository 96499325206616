/** LIBRARIES */
import React, { PureComponent } from "react";
import Autocomplete from 'react-google-autocomplete';

class AutoComplete extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    /*shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value !== this.props.value;
    }*/
    render() {
        const { name, types, value, onChange, placeholder, componentRestrictions, className, bindAddress, id } = this.props;
        //console.log('RENDER - AUTOCOMPLETE', name, value)
        return (
            <Autocomplete
                className={className}
                onPlaceSelected={(place) => {
                    bindAddress(place);
                }}
                types={types}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                componentRestrictions={componentRestrictions}
                name={name}
                id={id ? id : name}
            />
        );
    }
}

export default AutoComplete;