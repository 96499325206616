/** LIBRARIES */
import React, { Component } from "react";
import { PopUpModal } from '../../components';
import Signature from '../../components/signature'
import {Modal} from "react-bootstrap";
import 'toasted-notes/src/styles.css';
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../constants/translate';
import { default as _images } from '../../images';
import { storageBucket } from "../../services/helper";

const _termsandCondition = 'Terms and conditions of use 1.	Introduction 1.1	These terms and conditions shall govern your use of our website. 1.2	By using our website, you accept these terms and conditions in full; accordingly, if you disagree with these terms and conditions or any part of these terms and conditions, you must not use our website. 1.3	If you [register with our website, submit any material to our website or use any of our website services], we will ask you to expressly agree to these terms and conditions. 1.4	You must be at least [18] years of age to use our website; by using our website or agreeing to these terms and conditions, you warrant and represent to us that you are at least [18] years of age. 1.5	Our website uses cookies; by using our website or agreeing to these terms and conditions, you consent to our use of cookies in accordance with the terms of our [privacy and cookies policy.';

const objAgreement = {
    signature: '',
    termsAndConditions: ''
};

export default class InsuranceWaiver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: Object.assign({}, objAgreement),
            errors: {},
            showSignature: false
        }

        //this.handleSignatureClose = this.handleSignatureClose.bind(this);
    }


    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        let state = Object.assign({}, this.state.fields);
                        state[id] = dataURL;
                        state['termsAndConditions'] = this.props.termsandCondition ? this.props.termsandCondition : _termsandCondition;
                        this.setState({ fields: state }, () => { this.errorChange(id) });
                        
                    })
            })
    };

    //#endregion

    //#region CHANGE EVENTS
    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        if (checked)
            state[name] = this.props.termsandCondition ? this.props.termsandCondition : _termsandCondition;
        else
            state[name] = null;
        this.setState({ fields: state }, () => { this.errorChange(name) });
        
    }

    handleSignatureClose = (imgURL) => {
        if (imgURL) {
            let state = Object.assign({}, this.state.fields);
            state.signature = imgURL;
            this.setState({ fields: state, showSignature: false }, () => { this.errorChange('signature') });            
            this.saveImageToStorage(imgURL, 'signature', 'png');
        }
        else {
            this.setState({ showSignature: false })
        }
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }
    //#endregion

    //#region RENDER & SUBMIT

    componentDidMount() {
        const fields = Object.assign({}, objAgreement);
        const objData = Object.assign({}, this.props.terms);

        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }

        this.setState({ fields: fields })
    }

    componentWillUnmount() {

    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (!fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;

            }
        }
        this.setState({ errors: errors });

        if (formIsValid === true) {
            //console.log('formIsValid')
            this.props.handleClose(fields)
        }
        else {
            this.setState({ showSignature: true })
            return formIsValid;
        }
    };
    //#endregion





    render() {
        const { termsAndConditions, signature } = this.state.fields;
        const { show, isDisable, termsandCondition, title } = this.props;
        return (
            <>

                <Modal
                    id="add-terms"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={title ? title : 'insuranceWaiver'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className={`container-fluid ${(isDisable) ? 'form-disable' : ''}`}>
                            <div className="row">
                                <div className="testdrive-terms-wrap">
                                    <div
                                        className="terms-list"
                                        dangerouslySetInnerHTML={{ __html: (termsAndConditions ? termsAndConditions : (termsandCondition ? termsandCondition : _termsandCondition)) }} />
                                    {/*<h3>Please read the following terms & conditions carefully</h3>*/}
                                    {/* <textarea
                                        className="terms-list"
                                        cols="20"
                                        columns=""
                                        maxLength="5000"
                                        value={termsAndConditions ? termsAndConditions : (termsandCondition ? termsandCondition : _termsandCondition)}
                                        readOnly
                                        rows="">
                                        {termsAndConditions ? termsAndConditions : (termsandCondition ? termsandCondition : _termsandCondition)}
                                    </textarea> 
                                    <div className={`testdrive-checkbox error-hidden-border ${this.state.errors["termsAndConditions"]}`}>
                                        <div className="checkbox icheck-success">

                                            <input
                                                type="checkbox"
                                                id="termsAndConditions"
                                                name="termsAndConditions"
                                                className="uncheck-activity"
                                                checked={termsAndConditions ? true : false}
                                                onChange={(e) => {
                                                    this.handleCheckChange(e, 'termsAndConditions')
                                                }} />
                                            <label htmlFor="termsAndConditions">I agree to insurance waiver terms & conditions </label>
                                        </div>
                                            </div>*/}
                                    <h2><Translate text={'signature'} /></h2>

                                    <div className={`signature-box ${this.state.errors["signature"]}`} onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ showSignature: true })
                                    }}>
                                        {
                                            (signature && signature.indexOf('base64') > 0)
                                                ?
                                                <div className="img-loader">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (signature && !isDisable && signature.indexOf('base64') <= 0)
                                                ?
                                                <div className="editsign-btn">
                                                    <a href="#" className="editsign mini-button" onClick={(e) => { e.preventDefault(); }}>
                                                        <i className="ico icon-edit" htmlFor="licBack"></i>
                                                    </a>
                                                </div>
                                                :
                                                <></>
                                        }

                                        <img src={signature ? signature : _images.nosign} alt="" width="75" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {
                        isDisable
                            ?
                            <></>
                            :
                            <>
                                <Modal.Footer className="modal-footer">
                                    <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose([]) }}><Translate text={'iDisagree'} /></button>
                                    <button type="button"
                                        className={`btn btn-primary float-right ${(signature && signature.indexOf('base64') > 0) ? 'form-disable' : ''}`}
                                        onClick={(e) => this.handleSubmit(e)}><Translate text={'iAgree'} /></button>
                                </Modal.Footer>
                            </>
                    }

                </Modal>

                <PopUpModal show={this.state.showSignature}>
                    <Signature
                        show={this.state.showSignature}
                        clsActive='overlay-modal active'
                        handleClose={this.handleSignatureClose}
                    ></Signature>
                </PopUpModal>


            </>


        );
    }
}

