import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { InputText, InputMultipleText, ReactMultiSelect, ReactSelect, ReactTimePicker, InputCheckBox } from '../../components';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import ReactSummernote from 'react-summernote';
import { frequencyOptions, dataOptions } from "./viewModel";
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import ScheduleMultiple from './scheduleMulti';

const ScheduleReport = (props) => {
    const [report, setReport] = useState();
    const [errorFields, setErrorFields] = useState({});
    const [initialized, setInitialized] = useState(false);
    const [loader, setLoader] = useState(false)
    const [emailloader, setEmailLoader] = useState(false)
    const [deleteloader, setDeleteLoader] = useState(false)
    const [emailHtml, setEmailHtml] = useState('')
    const [newSchedular, setnewSchedular] = useState(false);
    const [cron, setCron] = useState();
    const [dateOptions, setDateOptions] = useState()
    const [multiSchedule, setMultiSchedule] = useState([]);

    useEffect(() => {
        if (_.isEmpty(report))
            return
        if (!initialized) {
            // console.log('emailType', emailType)
            var node = document.createElement('div');
            node.innerHTML = `${emailHtml}`;
            ReactSummernote.insertNode(node);
            setInitialized(true);
        }

    }, [report]);

    useEffect(() => {
        if (_.isEmpty(props.report))
            return

        window.firebase.firestore().doc(`schedular/${props.report.documentID}`)
            .get()
            .then(rec => {
                if (rec.exists) {
                    setEmailHtml(!_.isEmpty(rec.data().emailBody) ? rec.data().emailBody : '')
                    setInitialized(false);
                    let arrCron = [];
                    if (rec.data().cron && rec.data().cron !== '* * * * *') {
                        arrCron = rec.data().cron.split(' ');
                    }
                    setReport({
                        ...rec.data(),
                        timezone: props.timezone || null,
                        enabled: rec.data().static === true ? Boolean(props.report.enabled) : Boolean(rec.data().enabled),
                        otherRecipients: rec.data().otherRecipients ? rec.data().otherRecipients.join(',') : '',
                        schedulartime: arrCron.length > 0 ?
                            window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                    })
                    if (rec.data().multiSchedule?.length > 0) {
                        let refPromise = [];
                        rec.data().multiSchedule.forEach(rpt => {
                            refPromise.push(window.firebase.firestore().doc(`schedular/${rpt}`).get())
                        })
                        Promise.all(refPromise)
                            .then(docs => {
                                let rpts = [];
                                docs.forEach(doc => {
                                    let arrCron = [];
                                    if (doc.data().cron && doc.data().cron !== '* * * * *') {
                                        arrCron = doc.data().cron.split(' ');
                                    }
                                    rpts.push({
                                        ...doc.data(),
                                        schedulartime: arrCron.length > 0 ?
                                            window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                                    })
                                })
                                setMultiSchedule(rpts);
                            })
                    }
                    setnewSchedular(false)
                }
                else {
                    setEmailHtml('')
                    setReport({
                        ...report,
                        ['frequency']: 'daily',
                        ['documentID']: props.report.documentID,
                        ['clientID']: props.clientID || props.dealersettings.client.id,
                        ['oemID']: props.oemID || null,
                        ['reportType']: props.reportType || null,
                        ['timezone']: props.timezone || null,
                        ['addedBy']: localStorage.uid,
                        ['modifiedBy']: localStorage.uid,
                        ['addedDate']: window.firebase.firestore.Timestamp.now(),
                        ['modifiedDate']: window.firebase.firestore.Timestamp.now(),
                        ['columns']: props.report.columns ? props.report.columns : [],
                        ['reportJSON']: props.reportJSON || null
                    })
                    setnewSchedular(true)
                }
            })

        let _date = []
        _date.push({ label: 'First day of month', value: '1' })
        _date.push({ label: 'Last day of month', value: 'L' })
        setDateOptions(_date)
    }, [])

    useEffect(() => {
        if (_.isEmpty(report))
            return


        let cron = ['*', '*', '*', '*', '*']
        if (report.schedulartime) {
            cron[0] = moment.unix(report.schedulartime.seconds).format('m');
            cron[1] = moment.unix(report.schedulartime.seconds).format('H');
        }
        if (report.day) {
            cron[4] = report.day;
            cron[2] = '*';
        }
        if (report.date) {
            cron[2] = report.date;
            cron[4] = '*';
        }
        setCron(cron.join(' '));
    }, [report])

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setReport({
                ...report,
                [name]: newarray1.join(' ')

            })
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setReport({
                ...report,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'frequency')
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['day']: '',
                ['date']: ''
            })
        else
            setReport({
                ...report,
                [data.name]: e ? e.value : ''
            })


        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }


    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setReport({
            ...report,
            [name]: value
        });
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleOnChangeMultipleText = (val, name) => {
        setReport({
            ...report,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const removeEmail = (index, name) => {
        let arr = report[name] ? _.isArray(report[name]) ? report[name] : report[name].split(',') : []
        arr.splice(index, 1)
        setReport({
            ...report,
            [name]: arr.join(',')
        })
    }

    const handleChange = obj => {
        //let htmlText = obj
        setEmailHtml(obj)
    };

    const handleCheckedChange = (e) => {
        setReport({
            ...report,
            [e.target.name]: e.target.checked
        })
    }

    const handleHoursChange = (val, name) => {
        setReport({
            ...report,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });
    }

    const handleSubmit = () => {
        let rec = report;
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['subject'])) {
            formIsValid = false;
            errors['subject'] = errorClass;
        }
        if (_.isEmpty(rec['recipients']) && _.isEmpty(rec['otherRecipients'])) {
            formIsValid = false;
            Swal.fire('Please add recipients', '', 'error')
        }

        if (rec.enabled === true) {
            if (_.isEmpty(rec['frequency'])) {
                formIsValid = false;
                errors['frequency'] = errorClass;
            }
            if (_.isEmpty(rec['schedulartime'])) {
                formIsValid = false;
                errors['schedulartime'] = errorClass;
            }
            if (rec['frequency'] === 'weekly' && _.isEmpty(rec['day'])) {
                formIsValid = false;
                errors['day'] = errorClass;
            }
            if (rec['frequency'] === 'monthly' && _.isEmpty(rec['date'])) {
                formIsValid = false;
                errors['date'] = errorClass;
            }
            multiSchedule.forEach(rpt => {
                if (_.isEmpty(rpt['frequency'])) {
                    formIsValid = false;
                    errors[`${rpt.documentID}-frequency`] = errorClass;
                }
                if (_.isEmpty(rpt['schedulartime'])) {
                    formIsValid = false;
                    errors[`${rpt.documentID}-schedulartime`] = errorClass;
                }
                if (rpt['frequency'] === 'weekly' && _.isEmpty(rpt['day'])) {
                    formIsValid = false;
                    errors[`${rpt.documentID}-day`] = errorClass;
                }
                if (rpt['frequency'] === 'monthly' && _.isEmpty(rpt['date'])) {
                    formIsValid = false;
                    errors[`${rpt.documentID}-date`] = errorClass;
                }
                if (rpt.dataParam === 'upcoming' && _.isEmpty(rpt.upcomingDays)) {
                    formIsValid = false;
                    errors[`${rpt.documentID}-upcomingDays`] = errorClass;
                }
            })
        }
        if (report.dataParam === 'upcoming' && _.isEmpty(report.upcomingDays)) {
            formIsValid = false;
            errors['upcomingDays'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }

        const reportURL = getReportURL(report);
        setLoader(true)
        window.firebase.firestore().doc(`schedular/${report.documentID}`).set({
            ...report,
            emailBody: emailHtml,
            cron: cron,
            url: reportURL,
            otherRecipients: !_.isEmpty(report.otherRecipients) ? report.otherRecipients.split(',') : []
        }, { merge: true }).then(async rec => {
            setLoader(false)
            toast.notify('Report scheduled successfully.', {
                duration: 2000
            })
            if (multiSchedule.length > 0) {
                const batch = window.firebase.firestore().batch();
                if (report.enabled === true) {
                    multiSchedule.forEach(rpt => {
                        const _reportURL = getReportURL({ ...rpt, documentID: report.documentID });
                        batch.set(window.firebase.firestore().doc(`schedular/${rpt.documentID}`),
                            {
                                ...rpt,
                                subject: report.subject,
                                emailBody: emailHtml,
                                url: _reportURL,
                                otherRecipients: !_.isEmpty(report.otherRecipients) ? report.otherRecipients.split(',') : [],
                                recipients: report.recipients || [],
                                parentID: report.documentID
                            }, { merge: true });
                    })
                    batch.set(window.firebase.firestore().doc(`schedular/${report.documentID}`),
                        {
                            multiSchedule: multiSchedule.map(r => { return r.documentID })
                        }, { merge: true });
                }
                else {
                    multiSchedule.forEach(rpt => {
                        batch.delete(window.firebase.firestore().doc(`schedular/${rpt.documentID}`));
                    })
                    batch.set(window.firebase.firestore().doc(`schedular/${report.documentID}`),
                        {
                            multiSchedule: null
                        }, { merge: true });
                }

                await batch.commit();
            }
            else {
                await window.firebase.firestore().doc(`schedular/${report.documentID}`).update({ multiSchedule: [] });
            }
            try {
                await window.firebase.firestore().doc(`reports/${report.documentID}`).update({ schedule: Boolean(report.enabled) });
            } catch (err) { console.log(err) }
            props.handleClose()
        }).catch(error => {
            setLoader(false)
            console.error(error);
            Swal.fire('Something went wrong', '', 'error')
        })
    }

    const getReportURL = (report) => {
        let reportURL = report.url;
        if (props.oemReport === true) {
            reportURL = `dynamic/report/oem/${report.documentID}?date=${report.dataParam}`
        }
        else if (props.dealerTarget === true) {
            reportURL = `dealertargets/report/${report.clientID}?pdf=true`;
        }
        else if (report.static === true) {
            reportURL = report.url;
            if (report.reportsaleType && !reportURL.includes('saletype=')) {
                reportURL = `${reportURL}?saletype=${report.reportsaleType}`
            }
        }
        else if (props.reportPrefix) {
            reportURL = `dynamic/report/${props.reportPrefix}/${report.clientID}?date=${report.dataParam}`;
        }
        else if (props.module) {
            reportURL = `dynamic/report/${props.module}/${report.documentID}?date=${report.dataParam}`;
        }
        else if (props.eventsPro) {
            reportURL = `attendee/report/${props.eventsProID}?date=today`;
        }
        else if (props.marketing) {
            reportURL = `dynamic/report/marketing/${report.documentID}?date=${report.dataParam}`
        }
        else if (props.reportURL) {
            reportURL = props.reportURL
        }
        else {
            reportURL = `dynamic/report/${report.documentID}?date=${report.dataParam}`
        }

        return reportURL;
    }

    const cancelShedular = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete shedular?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                setDeleteLoader(true)
                window.firebase.firestore().doc(`schedular/${report.documentID}`)
                    .delete()
                    .then(rec => {
                        setDeleteLoader(false)
                        toast.notify('Scheduler deleted successfully.', {
                            duration: 2000
                        })
                        props.handleClose()
                    }).catch(error => {
                        setDeleteLoader(false)
                        console.error(error);
                        Swal.fire('Something went wrong', '', 'error')
                    })
            }
        })
    }

    const handleSendEmail = () => {
        let rec = report;
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['subject'])) {
            formIsValid = false;
            errors['subject'] = errorClass;
        }
        if (_.isEmpty(rec['recipients']) && _.isEmpty(rec['otherRecipients'])) {
            formIsValid = false;
            Swal.fire('Please add recipients', '', 'error');
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        let reportURL = report.url;
        let _recipients = [];
        if (props.oemReport === true) {
            reportURL = `dynamic/report/oem/${report.documentID}?date=${report.dataParam}&startDate=${props.startDate || ''}&endDate=${props.endDate || ''}`
        }
        else if (props.dealerTarget === true) {
            reportURL = `dealertargets/report/${report.clientID}?pdf=true`;
        }
        else if (report.static === true) {
            reportURL = report.url;
            if (report.reportsaleType && !reportURL.includes('saletype=')) {
                reportURL = `${reportURL}?saletype=${report.reportsaleType}`
            }
        }
        else if (props.reportPrefix) {
            reportURL = `dynamic/report/${props.reportPrefix}/${report.clientID}?date=${report.dataParam}`;
        }
        else if (props.eventsPro) {
            reportURL = `attendee/report/${props.eventsProID}?date=today`;
        }
        else if (props.module) {
            reportURL = `dynamic/report/${props.module}/${report.documentID}?date=${report.dataParam}&startDate=${props.startDate || ''}&endDate=${props.endDate || ''}`;
        }
        else {
            reportURL = `dynamic/report/${report.documentID}?date=${report.dataParam}&startDate=${props.startDate || ''}&endDate=${props.endDate || ''}`
        }
        if (!_.isEmpty(report.recipients)) {
            _recipients = [..._recipients, ...report.recipients]
        }
        if (!_.isEmpty(report.otherRecipients)) {
            _recipients = [..._recipients, ...report.otherRecipients.split(',')]
        }
        setEmailLoader(true)
        try {
            let paramObj = {
                clientID: props.dealersettings.client.id,
                url: reportURL,
                subject: report.subject,
                recipients: _recipients,
                emailBody: emailHtml
            }
            if (localStorage.defaultModule === 'oem') {
                paramObj = {
                    url: reportURL,
                    subject: report.subject,
                    recipients: _recipients,
                    emailBody: emailHtml,
                    reportType: 'oem',
                    oemID: props.dealersettings.oemID
                }
            }
            const sendEmail = window.firebase.functions().httpsCallable('reports-sendEmailNow');
            sendEmail(paramObj).then((response) => {
                setEmailLoader(false)
                if (response.data.success) {
                    toast.notify('Email sent successfully.', {
                        duration: 2000,
                    });
                }
                else {
                    console.log(response.data.message);
                    Swal.fire('Something went wrong', '', 'error')
                }
            })

        }
        catch (error) {
            console.log(error);
            Swal.fire('Something went wrong', '', 'error')
        }
    }

    const deleteScheduler = async (id) => {
        try {
            await window.firebase.firestore().doc(`schedular/${id}`).delete();
        }
        catch { }
        setMultiSchedule(multiSchedule.filter(a => a.documentID !== id));
    }

    const updateReport = (rpt) => {
        setMultiSchedule(multiSchedule.map(rec => rec.documentID === rpt.documentID ? { ...rpt } : { ...rec }))
    }


    return _.isEmpty(report) ? (<></>) : (

        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-company"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Schedule Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" pop-layout form-style">
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <label>Email Subject</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'subject'}
                                        className={`form-control ${errorFields["subject"]}`}
                                        name="subject"
                                        onChange={handleOnChange}
                                        value={report.subject}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Recipients</label>
                                    <ReactMultiSelect
                                        options={(props.oemReport === true ? _.uniqBy(props.groupUsers, 'id') : props.clientUsers).filter(e => Boolean(e.superAdmin) === false && !_.isEmpty(e.email))
                                            .map(rec => {
                                                return {
                                                    value: rec.email,
                                                    searchlabel: rec.name,
                                                    active: _.isBoolean(rec.active) ? rec.active : true,
                                                    label: (
                                                        <div className='drop-image'>
                                                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                            {rec.name}
                                                        </div>
                                                    ),
                                                };
                                            })}
                                        name={"recipients"}
                                        placeholder={`select recipients`}
                                        onChange={(option) => handleReactMultiSelectChange(option, 'recipients')}
                                        value={report.recipients}
                                        classNamePrefix={`${errorFields["recipients"]} basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Other Recipients</label>
                                    <InputMultipleText
                                        placeholder={`other recipients`}
                                        className={`form-control react-multi-tag`}
                                        name="otherRecipients"
                                        onChange={(emails) => { handleOnChangeMultipleText(emails, 'otherRecipients') }}
                                        value={!_.isEmpty(report.otherRecipients) ? report.otherRecipients.split(',') : []}
                                        validateEmail={true}
                                        getLabel={(email, index) => {
                                            return <div data-tag key={index}>
                                                {email}
                                                <span data-tag-handle onClick={() => removeEmail(index, 'otherRecipients')}>
                                                    ×
                                                </span>
                                            </div>
                                        }}
                                    />
                                </div>
                                <div className='form-group col-md-12 react-summer-editor'>
                                    <label>Email Body</label>
                                    <ReactSummernote
                                        children={<div dangerouslySetInnerHTML={{ __html: `<div></div>` }}></div>}
                                        options={{
                                            height: 160,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ['style', ['style']],
                                                ['font', ['bold', 'underline', 'clear']],
                                                ['fontname', ['fontname']],
                                                ['para', ['paragraph']],
                                                ['color', ['color']],
                                                ['insert', ['link']],
                                                ['view', ['codeview']]
                                            ],
                                            disableResizeEditor: true
                                        }}
                                        onChange={handleChange}
                                    // onPaste={onEditorPaste}
                                    />
                                </div>
                            </div>
                            <div className="reportscheduler-multi-wrap">
                                <div className="form-row">
                                    <div className='form-group col-md-6'>
                                        <span className="switch switch-sm float-left pt-1">
                                            <InputCheckBox
                                                className="switch"
                                                name={'enabled'}
                                                checked={report.enabled === true ? true : false}
                                                onChange={(e) => { handleCheckedChange(e) }}
                                                id={'report-schedular'} />
                                            <label htmlFor={'report-schedular'}>Recurring Email</label>
                                        </span>
                                    </div>
                                    <div className="form-group col-md-6">
                                        {
                                            report.enabled === true && !Boolean(props.hideMultiScheduler) ? (
                                                <div className=" float-right reportscheduler-multi-add">
                                                    <button type="button" className="btn btn-primary float-right"
                                                        onClick={(e) => {
                                                            setMultiSchedule([
                                                                ...multiSchedule,
                                                                {
                                                                    ...report,
                                                                    documentID: window.firebase.firestore().collection(`schedular`).doc().id,
                                                                    dataParam: null,
                                                                    upcomingDays: null,
                                                                    frequency: null,
                                                                    day: null,
                                                                    date: null,
                                                                    schedulartime: null,
                                                                }
                                                            ])
                                                        }}
                                                    ><i className="ico icon-add"></i> Add Scheduler</button>
                                                </div>) : (<></>)
                                        }

                                    </div>
                                </div>
                            </div>
                            {
                                report.enabled === true ? (<>
                                    <fieldset className="reportscheduler-multi-border">
                                        <legend className="reportscheduler-multi-border">Schedule {CommonHelper.autoCaps(report.frequency)}</legend>
                                        <div className="form-row">
                                            {
                                                Boolean(report.static) || Boolean(props.hideRptOption) ? (<></>) : (
                                                    <div className={`form-group col-md-12`}>
                                                        <label >Report Data</label>
                                                        <ReactSelect
                                                            options={dataOptions.filter(a => props?.report?.columns?.some(a => a.includes('Scheduled')) ? true : a.value !== 'upcoming')}
                                                            name={"dataParam"}
                                                            placeholder={'select data option'}
                                                            onChange={handleSelectChange}
                                                            value={_.isEmpty(report.dataParam) ? 'MTD' : report.dataParam}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                )
                                            }
                                            {
                                                report.dataParam === 'upcoming' ? (
                                                    <div className={`form-group col-md-12`}>
                                                        <label >Select Days</label>
                                                        <ReactMultiSelect
                                                            options={moment.weekdays().map(rec => {
                                                                return {
                                                                    label: rec,
                                                                    value: moment.weekdays().indexOf(rec).toString()
                                                                }
                                                            })}
                                                            name={'upcomingDays'}
                                                            placeholder={'select days'}
                                                            onChange={(selectedOptions) => {
                                                                const value = [];
                                                                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                setReport({
                                                                    ...report,
                                                                    ['upcomingDays']: value
                                                                })
                                                            }}
                                                            value={report?.upcomingDays ? report?.upcomingDays : []}
                                                            classNamePrefix={`${errorFields['upcomingDays']} basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                ) : (<></>)
                                            }

                                            <div className={`form-group ${report.frequency === 'daily' || _.isEmpty(report.frequency) ? 'col-md-6' : 'col-md-4'}`}>
                                                <label >Frequency</label>
                                                <ReactSelect
                                                    options={frequencyOptions.filter(a => Boolean(props.eventsPro) ? a.value === 'daily' : true)}
                                                    name={"frequency"}
                                                    placeholder={'select frequency'}
                                                    onChange={handleSelectChange}
                                                    value={report.frequency}
                                                    classNamePrefix={`${errorFields["frequency"]} cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                            {
                                                report.frequency === 'weekly' ? (
                                                    <div className="form-group col-md-4">
                                                        <label >Select Day</label>
                                                        <ReactSelect
                                                            options={moment.weekdays().map(rec => {
                                                                return {
                                                                    label: rec,
                                                                    value: moment.weekdays().indexOf(rec).toString()
                                                                }
                                                            })}
                                                            name={"day"}
                                                            placeholder={'select day'}
                                                            onChange={handleSelectChange}
                                                            value={report.day}
                                                            classNamePrefix={`${errorFields["day"]} cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                ) : report.frequency === 'monthly' ? (
                                                    <div className="form-group col-md-4">
                                                        <label >Select Date</label>
                                                        <ReactSelect
                                                            options={dateOptions}
                                                            name={"date"}
                                                            placeholder={'select date'}
                                                            onChange={handleSelectChange}
                                                            value={report.date}
                                                            classNamePrefix={`${errorFields["date"]} cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`form-group ${report.frequency === 'daily' || _.isEmpty(report.frequency) ? 'col-md-6' : 'col-md-4'}`}>
                                                <label >Time</label>
                                                <div className="inner-addon right-addon">
                                                    <i className="bg-icons ico icon-time"></i>
                                                    <ReactTimePicker
                                                        value={report.schedulartime ? moment.unix(report.schedulartime.seconds)._d : null}
                                                        name={'schedulartime'}
                                                        onChange={handleHoursChange}
                                                        timeIntervals={30}
                                                        placeholder='h:mm aa'
                                                        className={`form-control ${errorFields["schedulartime"]}`}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </fieldset>
                                    {
                                        multiSchedule.map((rpt, index) => {
                                            return <ScheduleMultiple
                                                key={index}
                                                report={rpt}
                                                deleteScheduler={deleteScheduler}
                                                errorFields={errorFields}
                                                updateReport={updateReport}
                                            />
                                        })
                                    }
                                </>) : (<></>)
                            }
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="modal-footer">

                {/* {
                    (!newSchedular && Boolean(report.static) === false) ? (<><button
                        type="button"
                        className='btn btn-red float-left'
                        onClick={(e) => { cancelShedular() }}

                    >
                        {
                            deleteloader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<>
                                    <i className='ico icon-delete marker-delete'></i>
                                </>)
                        }
                        Delete Schedular
                    </button>
                    </>) : (<></>)
                } */}
                {
                    Boolean(report.static) || Boolean(props.hideTestEmail) ? (<></>) : (<button
                        type="button"
                        className={`btn btn-primary float-left`}
                        onClick={(e) => { handleSendEmail() }}

                    >
                        {
                            emailloader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Send Email
                    </button>)
                }



                <button
                    type="button"
                    className="btn btn-primary float-right ml-2"
                    onClick={(e) => { handleSubmit() }}

                >
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    {newSchedular ? <>Schedule</> : <>Update</>}
                </button>
                <button
                    type="button"
                    className="btn btn-default float-right"
                    onClick={(e) => { props.handleClose() }}
                ><Translate text={'cancel'} />
                </button>


            </Modal.Footer>
        </Modal >
    )
}

export default ScheduleReport;