import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import _ from 'lodash';
import toast from 'toasted-notes';
import ReactSummernote from 'react-summernote';
import { InputText, ReactSelect, PopUpModal } from '../../../../components';
import { visibilities, templateVM, mentions } from './viewModel';
import SummerEditor from '../../../mail/summereditor/summereditor';
import SendTestEmail from './sendTestEmail';

const AddTemplateHTML = props => {
	const [template, setTemplate] = useState(
		props.template
			? props.template
			: {
				title: '',
				visibility: 'private',
			},
	);
	const [errorFields, setErrorFields] = useState({});
	const [loader, setLoader] = useState(false);
	const [email, setEmail] = useState(
		props.template ? props.template.bodyHTML : '<p></p>',
	);
	const [sendEmailModel, setSendEmailModel] = useState(false);

	useEffect(() => {
		if (props.template) {
			// if (!initialized) {
			// console.log('emailType', emailType)
			var node = document.createElement('div');
			node.innerHTML = `${props.template.bodyHTML}`;
			ReactSummernote.insertNode(node);
			// setInitialized(true);
			// }
		}
	}, [props.template]);

	const handleOnChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		var str = value;

		if (str && str.length >= 1) {
			var firstChar = str.charAt(0);
			var remainingStr = str.slice(1);
			str = firstChar.toUpperCase() + remainingStr;
		}
		setTemplate({
			...template,
			[name]: str,
		});

		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleSelectChange = (e, data) => {
		if (data.name === 'visibility' && e.value === 'private') {
			setTemplate({
				...template,
				[data.name]: e.value,
				level: '',
				levelID: '',
				subLevelIDs: '',
				regionIDs: [],
				groupIDs: [],
				clientIDs: [],
			});
		} else {
			setTemplate({
				...template,
				[data.name]: e.value,
			});
		}
		setErrorFields({
			...errorFields,
			[data.name]: '',
		});
	};

	const handleSavetemplate = () => {
		let formIsValid = true;
		let errorClass = 'input_error';
		let errors = {};
		if (_.isEmpty(template['title'])) {
			formIsValid = false;
			errors['title'] = errorClass;
		}
		// if (!template.lev el && template.visibility === 'shared') {
		//     formIsValid = false;
		//     errors['level'] = errorClass;
		// }
		// if (!_.isEmpty(template.level) && template.visibility === 'shared' && !template.subLevelIDs && template.level !== 'oem' && template.level !== 'individual') {
		//     formIsValid = false;
		//     errors['subLevelIDs'] = errorClass;
		// }
		if (template.level) {
			let arrToLooped = !_.isEmpty(template.subLevelIDs)
				? template.subLevelIDs.split(',')
				: [];
			let clientIDs = [];
			let regionIDs = [];
			let groupIDs = [];
			let level = template.level ? template.level : '';
			template.level = level;
			if (template.level === 'oem') {
				props.clientOptions.forEach(clientOption => {
					clientIDs.push(clientOption.value);
				});
				template.clientIDs = clientIDs;
			} else if (template.level === 'region') {
				regionIDs = arrToLooped;
				arrToLooped.forEach(regionID => {
					props.clientOptions.forEach(clientOption => {
						if (
							clientOption.data.region &&
							clientOption.data.region === regionID
						) {
							clientIDs.push(clientOption.value);
						}
					});
				});
				template.regionIDs = regionIDs;
				template.clientIDs = clientIDs;
			} else if (template.level === 'group') {
				groupIDs = arrToLooped;
				arrToLooped.forEach(groupID => {
					props.clientOptions.forEach(clientOption => {
						if (
							clientOption.data.group &&
							clientOption.data.group === groupID
						) {
							clientIDs.push(clientOption.value);
						}
					});
				});
				template.groupIDs = groupIDs;
				template.clientIDs = clientIDs;
			} else if (template.level === 'individual') {
				clientIDs = arrToLooped;
				template.clientIDs = clientIDs;
			}
		}
		setErrorFields(errors);
		if (!formIsValid) {
			return;
		}
		setLoader(true);
		if (props.title.includes('Add')) {
			template.addedDate = window.firebase.firestore.Timestamp.now();
			template.addedBy = localStorage.uid;
			template.documentID = window.firebase
				.firestore()
				.collection(`emailTemplates`)
				.doc().id;
			template.clientID = props.dealersettings.client.id;
			template.isDeleted = false;
			template.templateJSON = null;
			template.type = props.type;
		}
		template.modifiedDate = window.firebase.firestore.Timestamp.now();
		template.modifiedBy = localStorage.uid;
		template.bodyHTML = email;

		// template.groupID = localStorage.uid
		// template.oemID = localStorage.uid
		// template.regionID = localStorage.uid

		//	console.log('template at save', template);
		window.firebase
			.firestore()
			.doc(
				`clientSettings/${props.dealersettings.client.settingsID}/tradeinProtemplates/${template.documentID}`,
			)
			.set({ ..._.pick(template, _.keys(templateVM)) }, { merge: true })
			.then(snapshot => {
				setLoader(false);
				toast.notify(
					props.newTemplate === true
						? 'Template added successfully'
						: 'Template updated successfully',
					{
						duration: 2000,
					},
				);
				props.handleClose(template);
			})
			.catch(error => {
				console.error(error);
				toast.notify('Something went wrong', {
					duration: 2000,
				});
			});
	};

	const handlesetLevel = (e, data) => {
		if (data.name === 'level') {
			if (e.value === 'oem') {
				setTemplate({
					...template,
					[data.name]: e.value,
					['levelID']:
						e.value === 'oem' ? props.dealersettings.client.settingsID : '',
					['subLevelIDs']: '',
				});
			} else {
				setTemplate({
					...template,
					[data.name]: e.value,
					['levelID']: '',
					['subLevelIDs']: '',
				});
			}
			setErrorFields({
				...errorFields,
				[data.name]: '',
			});
		}
	};

	const handleReactMultiSelectChange1 = selectedOptions => {
		const value = [];

		!_.isEmpty(selectedOptions) &&
			selectedOptions.map(data => {
				value.push(data.value);
			});

		setTemplate({
			...template,
			['subLevelIDs']: value.join(','),
		});
		setErrorFields({
			...errorFields,
			['subLevelIDs']: '',
		});
	};

	const handleChange = obj => {
		setEmail(obj);
	};

	const handleModelClose = template => {
		setSendEmailModel(false);
		console.log('email sent');
	};

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className={`btn btn-secondary float-left ml-2 `}
								onClick={() => setSendEmailModel(true)}
							>
								<Translate text={'Send Test'} />
							</button>
							<button
								type='button'
								className={`btn btn-primary float-left ml-2 mr-2 email-send-btn `}
								onClick={handleSavetemplate}
							>
								{loader ? (
									<span
										className='spinner-border spinner-button mr-1'
										role='status'
										aria-hidden='true'
									></span>
								) : (
									<></>
								)}
								<Translate text={'save'} />
							</button>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid'>
								<div className='form-style w-100'>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label><Translate text={'title'} /></label>
											<InputText
												autoComplete='off'
												placeholder={'title'}
												className={`form-control ${errorFields['title']}`}
												name='title'
												onChange={handleOnChange}
												value={template.title}
												maxLength={150}
											/>
										</div>

										<div className='form-group col-md-6'>
											<label>
												<Translate text={'visibility'} />
											</label>
											<ReactSelect
												options={visibilities}
												name={'visibility'}
												placeholder={'select visibility'}
												onChange={handleSelectChange}
												value={template.visibility}
												classNamePrefix={`basic-select`}
												removeClearable={true}
												isSearchable={false}
											></ReactSelect>
										</div>

										<div className='form-group col-md-12 react-summer-editor mt-3'>
											<SummerEditor
												value={email}
												height={window.innerHeight - 250 + 'px'}
												handleChange={handleChange}
												emailType={'compose'}
												templateMentionsEnabled={true}
												otherMentions={props.formMentions}
												customFonts={props?.dealersettings?.customFonts || []}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div>
			</Modal>
			<PopUpModal show={sendEmailModel}>
				<SendTestEmail
					{...props}
					show={sendEmailModel}
					title={'Send Test Email'}
					handleClose={handleModelClose}
					template={{
						...template,
						bodyHTML: email
					}}
					mentions={mentions}
				/>
			</PopUpModal>
		</>
	);
};

export default AddTemplateHTML;
