import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { clientsVM, clientlistFields } from '../../company/clients/viewModel'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { doc } from 'rxfire/firestore';
import { ContentOverlay, TableView } from '../../../../components'

const DealerInfo = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 200) })
    const [clientLst, setClientList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [loader, setLoader] = useState(true)

    const tradeinPro = (!_.isEmpty(props.dealersettings.client.moduleSettings)
        && !_.isEmpty(props.dealersettings.client.moduleSettings.tradeinPro)
        && props.dealersettings.client.moduleSettings.tradeinPro.enabled === true
        && props.dealersettings.client.moduleSettings.tradeinPro.active === true) ? true : false;

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 200)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubAdminClientList && window.unSubAdminClientList();
        }
    }, [])

    useEffect(() => {
        async function getdealers() {
            let clientPromise = []
            let allconverted = []
            props.dealersettings.wholesalerClientIDs && props.dealersettings.wholesalerClientIDs.forEach(recID => {
                clientPromise.push(window.firebase.firestore().doc(`clients/${recID}`).get());
            })
            if (clientPromise.length === 0) {
                setClientList([]);
                setLoader(false);
            }
            else {
                const clientsnapshots = await Promise.all(clientPromise);
                clientsnapshots.forEach(doc => {
                    if (doc.exists)
                        allconverted.push({
                            ...convertVM(doc.data()),
                            id: doc.id
                        });
                })
                setClientList(allconverted);
                setLoader(false);
            }
        }
        getdealers();
    }, [])

    const convertVM = (doc) => {
        const objclientData = Object.assign({}, doc);
        const listVM = Object.assign({}, clientsVM);
        for (let [key, value] of Object.entries(objclientData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }
        listVM.countryName = _.find(props.dealersettings.countries, { 'code': listVM.country }) ? _.find(props.dealersettings.countries, { 'code': listVM.country }).name : ''
        return listVM
    }
    const handleClientdetailsClick = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <h2><Translate text={'Dealers Info'} /></h2>
            <div className="mt-2">
                <div className="settings-head">
                    <div className="float-left">
                        <div className="filter-search search-icon">
                            <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                        </div>
                    </div>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        clientLst.length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    datalist={_.orderBy(clientLst.filter(({ name, address, phone, email }) =>
                                    (!_.isEmpty(searchText) ? (
                                        (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(address) ? address.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(phone) ? phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(email) ? email.toLowerCase().includes(searchText.toLowerCase()) : false)
                                    ) : true)
                                    ), ['addedDate.seconds'], ['desc'])}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={clientlistFields.filter(a => a.value !== 'settings')}
                                    dynamicFields={clientlistFields.filter(e => e.default === true && e.value !== 'settings').map(v => _.pick(v, ['value']).value)}
                                    isSettings={false}
                                    isSorting={true}
                                    handleRowSingleClick={handleClientdetailsClick}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No dealers found.'} /></p>
                                </div>
                            </div>
                        )
                }
            </div>
        </>
    )
}

export default DealerInfo