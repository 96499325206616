import React, { useRef } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { CustomToggle } from '../../components/customdropdown';
import { Dropdown } from 'react-bootstrap'
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common'
const TestdriveBookingDetails = (props) => {
    const dropdowmDOM = useRef();

    // useEffect(() => {
    //     document.addEventListener("mousedown", handleClick);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClick);
    //     };
    // }, []);
    // const handleClick = e => {

    //     if (dropdowmDOM && dropdowmDOM.current && dropdowmDOM.current.contains(e.target)) {
    //         return;
    //     }
    //     props.handledropdownshow(false)
    // };


    const { slot } = props;
    return (<Dropdown ref={dropdowmDOM} drop="down" >
        <Dropdown.Toggle as={CustomToggle}
            className={`testdrive-booked-slot ${slot.exists === true ? 'red-box' : ''}`}
        // onClick={() => {
        //     props.handledropdownshow(true)
        // }} 
        >
            <>{moment.unix(slot.startDate.seconds).format('hh:mm A')} - {moment.unix(slot.endDate.seconds).format('hh:mm A')}</>
        </Dropdown.Toggle>
        <Dropdown.Menu xplacement="bottom-end">
            <div className="form-style booking-field-drop">
                <div className="booking-field-drop-header"><Translate text={'bookingDetails'} />
                    {/* <div className="custom-pop-close">
                    <a href="#" onClick={(e)=>{ e.preventDefault();props.handledropdownshow(false) }}><i className="ico icon-remove"></i> </a>
                </div> */}
                </div>
                <div className="booking-field-drop-body mt-2">
                    <div className="testdrive-booking-slot-content">
                        <div className="testdrive-booking-slot-image">
                            <img src={CommonHelper.getUserImagebyId(props.clientUsers, slot.ownerID)} alt="" className="img-object-fit" /></div>
                        <div className="testdrive-booking-slot-title">{slot.owner}</div>
                        <div className="testdrive-booking-slot-titlesub"><Translate text={'salesPerson'} /> </div>
                    </div>
                    {
                        !_.isEmpty(slot.contact) ? (
                            <div className="booking-field-contact" onClick={(e) => {
                                e.preventDefault();
                                const url = `/contacts/details/${slot.contact.documentID}`;
                                window.open(url, '_blank');
                            }}>
                                <div className="booking-field-contact-inner">
                                    <div className="inner-left">{CommonHelper.displayContactIcon(slot.contact, '')}</div>
                                    <div className="inner-right">
                                        <div className="booking-field-contact-head">
                                            {CommonHelper.displayContact([], slot.contact, '--')}
                                        </div>
                                        {
                                            (slot.contact.phone || slot.contact.email) ? (
                                                <div className="booking-field-contact-sub">
                                                    {slot.contact.phone ? CommonHelper.getFullPhone(slot.contact.phoneCode, slot.contact.phone) : slot.contact.email}
                                                </div>
                                            ) : <>
                                                {
                                                    (slot.contact.businessContact &&
                                                        !_.isEmpty(slot.contact.company) &&
                                                        (slot.contact.company.phone || slot.contact.company.email))
                                                        ?
                                                        <div className="booking-field-contact-sub">
                                                            {slot.contact.company.phone ?
                                                                CommonHelper.getFullPhone(slot.contact.company.phoneCode, slot.contact.company.phone)
                                                                : slot.contact.company.email}
                                                        </div>
                                                        :
                                                        <div className="no-data-red"><Translate text={'contactDetailsMissing'} /></div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : (<></>)
                    }
                    <div className="booking-field-slot mt-2">
                        <span><i className="bg-icons ico icon-date"></i>{moment.unix(slot.startDate.seconds).format('DD/MM/YYYY')}</span>  <i className="bg-icons ico icon-time"></i>{moment.unix(slot.startDate.seconds).format('hh:mm A')} - {moment.unix(slot.endDate.seconds).format('hh:mm A')}
                    </div>
                </div>
            </div>
        </Dropdown.Menu>
    </Dropdown>)
}

export default TestdriveBookingDetails;