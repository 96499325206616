import React, { useContext, useEffect, useState } from 'react';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import moment from 'moment';
import $ from 'jquery';
import { MainContext } from '../../layout/provider';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import { mentions } from '../../settings/workflow/templates/viewModel';
import uuid from 'react-uuid';
import _ from 'lodash'
import { storageBucket } from '../../../services/helper';
window.jQuery = $;

const SummerEditor = ({ handleChange, value, height, emailType, templateMentionsEnabled, formMentions, removeImage, customFonts, tvTemplate, otherMentions }) => {
	const [initialized, setInitialized] = useState(false);
	const [imagesSize, setImagesSize] = useState(0);

	const { dealersettings } = useContext(MainContext);
	const [allMentions, setMentions] = useState(otherMentions ? otherMentions : formMentions ? [...mentions.filter(a => tvTemplate ? !a.name.includes('Schedule') : true), ...formMentions] : mentions);
	const [defaultFonts, setDefaultFonts] = useState([...['Arial', 'Arial Black', 'Calibri', 'Courier New', 'Comic Sans MS', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Source Sans Pro'],
	...(customFonts && customFonts.length > 0 ? customFonts : [])])

	useEffect(() => {
		if (emailType === 'reply' || emailType === 'forward') {
			if (!initialized) {
				// console.log('emailType', emailType)
				var node = document.createElement('div');
				node.innerHTML = `${value}`;
				ReactSummernote.insertNode(node);
				setInitialized(true);
			}
		}
	}, []);

	const handleImageUpload = async fileList => {
		try {
			// file sizes validation
			let fileListSize = 0;
			for (let i = 0; i < fileList.length; i++) {
				// console.log(fileList[i].size)
				fileListSize = fileListSize + fileList[i].size;
			}
			fileListSize = fileListSize / 1024 / 1024;
			let totalSize = imagesSize + fileListSize;

			if (totalSize > 25) {
				Swal.fire('Maximum files size exceeded.', '', 'info');
				return;
			} else {
				setImagesSize(totalSize);
			}
			toast.notify('Image is being uploaded...');

			for (let i = 0; i < fileList.length; i++) {
				// console.log(fileList[i])
				const imageURL = await saveFileToStorage(fileList[i]);
				// console.log('imageURL', imageURL);
				ReactSummernote.insertImage(imageURL);
			}
			toast.notify('Image has been added.');
		} catch (error) {
			console.error(error)
			toast.notify('There was an error in adding images...');
		}
	};

	const saveFileToStorage = async file => {
		let clientID = dealersettings ? dealersettings.client.id : '';
		let uid = moment()._d.getTime().toString();
		try {
			const res = await storageBucket(dealersettings).ref(
				`${clientID}/emailattachments/${localStorage.uid}/${uid}/${file.name}`,
			)
				.put(file);
			if (res.state === 'success') {
				const dataURL = storageBucket(dealersettings).ref(`${clientID}/emailattachments/${localStorage.uid}/${uid}`)
					.child(file.name)
					.getDownloadURL();
				return dataURL;
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className="react-summer-editor">
			<ReactSummernote
				children={
					<div
						id={uuid()}
						dangerouslySetInnerHTML={{ __html: `` }}
					></div>
				}
				options={{
					height,
					hint: {
						mentions: templateMentionsEnabled ? allMentions.map(mention => mention.name) : [],
						match: /\B@(\w*)$/,
						search: function (keyword, callback) {
							callback($.grep(this.mentions, function (item) {
								return item.toLowerCase().indexOf(keyword) == 0;
							}));
						},
						content: function (item) {
							let valueToBeInserted = allMentions.filter(mention => item === mention.name)[0].value;
							return `${valueToBeInserted} `;
						}
					},
					dialogsInBody: true,
					toolbar: [
						//['style', ['style']],
						['fontsize', ['fontsize']],
						['font', ['bold', 'italic', 'underline', 'clear']],
						['fontname', ['fontname']],
						['para', ['paragraph']],
						['color', ['color']],
						// ['para', ['ul', 'ol', 'paragraph']],
						// ['table', ['table']],
						['insert', removeImage === true ? [] : ['link', 'picture']],
						['view', ['codeview']]
					],
					disableResizeEditor: true,
					fontSizes: ['10', '11', '12', '13', '14', '16', '18', '20', '24', '28', '32', '38', '44', '48', '54', '58', '60', '70', '80', '90', '100', '120', '140', '160', '180', '200'],
					fontNames: defaultFonts,
					fontNamesIgnoreCheck: defaultFonts,
					addDefaultFonts: false
				}}
				onChange={handleChange}
				onImageUpload={handleImageUpload}
			/>
		</div>
	);
};

export default SummerEditor;
