/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import _ from 'lodash';
/** COMPONENTS */
import { AntDatePicker, ReactTimePicker } from '../../../components';
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import {
    objContact
} from '../../contacts/viewModel';
import {
    objEnquiry, objActivitylog
} from '../viewModel';
import { objRequirement } from '../../requirement/viewModel';
import Requirements from '../../requirement/list';
import { firestoreDB } from '../../../services/helper';
//#region  ASSIGN VARIABLES
let titles = [];
let enquiryTypes = [];
let enquiryOptions = [];
let campaigns = [];
let origins = [];
//#endregion
export default class AssignLead extends Component {
    constructor(props) {
        super(props);
        this.isLoading = true;
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.refActivities = firestoreDB(this.props.dealersettings).firestore().collection('activities');
        this.unsubscribeConfirmEnq = null;
        this.state = {
            fields: Object.assign({}, objEnquiry),
            contact: Object.assign({}, objContact),
            requirement: Object.assign({}, objRequirement),
            activity: Object.assign({}, objActivitylog)
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadEnquiry();
    }

    componentWillUnmount() {
        this.unsubscribeConfirmEnq && this.unsubscribeConfirmEnq();
    }

    async loadEnquiry() {
        const { docID, dealersettings, clientUsers } = this.props;
        if (docID && dealersettings) {
            if (dealersettings.client.settings.pipelines.length === 0) {
                this.props.history.push('/pipeline');
                return
            }
            const refData = await firestoreDB(dealersettings).firestore().collection('enquiries').doc(docID).get();

            if (refData.exists) {
                if (!dealersettings.client.settings.pipelines.some(item => item.value === refData.data().pipeline)) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have pipleine access. Please contact administrator.'), '', 'info')
                    this.props.history.push('/pipeline');
                    return
                }
                const enquiry = Object.assign({}, objEnquiry);
                const contact = Object.assign({}, objContact);
                const requirement = Object.assign({}, objRequirement);


                const _clientUsers = [];
                const _objEnquiry = Object.assign({}, refData.data());
                for (let [key, value] of Object.entries(_objEnquiry)) {
                    enquiry[key] = value;
                }
                enquiry.documentID = refData.id;

                if (enquiry.isContact && !_.isEmpty(enquiry['contact'])) {
                    const refContactData = await this.refContact.doc(enquiry['contact'].documentID).get();
                    if (refContactData.exists) {
                        const objcontactData = Object.assign({}, refContactData.data());
                        for (let [key, value] of Object.entries(objcontactData)) {
                            contact[key] = value;
                        }
                        contact.documentID = enquiry['contact'].documentID;
                    }
                }
                else if (!_.isEmpty(enquiry['engageNewContact'])) {
                    const objcontactData = Object.assign({}, enquiry['engageNewContact']);
                    for (let [key, value] of Object.entries(objcontactData)) {
                        contact[key] = value;
                    }
                }

                if (enquiry.isVehicle && !_.isEmpty(enquiry['requirement'])) {
                    const objReqData = Object.assign({}, enquiry['requirement']);
                    for (let [key, value] of Object.entries(objReqData)) {
                        requirement[key] = value;
                    }
                }

                if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
                    const setttings = dealersettings.client.settings;

                    titles = [];
                    setttings.titles && setttings.titles.forEach((doc) => {
                        titles.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });

                    origins = [];
                    setttings.origins && setttings.origins.forEach(doc => {
                        origins.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });

                    enquiryTypes = [];
                    setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                        enquiryTypes.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name,
                        });
                    });

                    campaigns = [];
                    setttings.campaigns && setttings.campaigns.forEach(doc => {
                        campaigns.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name,
                        });
                    });
                }

                if (clientUsers) {
                    clientUsers.forEach(doc => {
                        _clientUsers.push({
                            value: doc.id,
                            searchlabel: doc.name,
                            label: (
                                <div className='drop-image'>
                                    <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                                    {doc.name}
                                </div>
                            ),
                        });
                    });
                }

                this.isLoading = false;
                this.setState({
                    fields: enquiry,
                    requirement: requirement,
                    contact: contact,
                    clientUsers: _clientUsers
                });

                await this.refActivities
                    .where('enquiryID', '==', enquiry.documentID)
                    .where('clientID', '==', enquiry.clientID)
                    .where('type', '==', 'log')
                    .where('subType', '==', 'note')
                    .where('isDeleted', '==', false)
                    .orderBy('addedDate', 'desc')
                    .limit(1).get().then(response => {
                        response.docs.forEach((doc) => {
                            const activity = doc.data();
                            //console.log(activity);
                            this.setState({
                                activity: activity
                            });
                        });

                    });

                this.unsubscribeConfirmEnq = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
                    .doc(docID)
                    .onSnapshot(this.onCollectionUpdate);


            }
        }
    }

    onCollectionUpdate = (docSnapshot) => {

        if (!docSnapshot.exists) {
            return;
        }

        const fields = Object.assign({}, this.state.fields);
        const objData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }
        fields.documentID = docSnapshot.id;
        this.setState({ fields: fields });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isLoading = true;
        const { docID, dealersettings } = this.props;

        let userID = dealersettings ? dealersettings.id : '';
        let currenDate = window.firebase.firestore.Timestamp.now();

        const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(docID);
        updateRef
            .update({
                confirmedBy: userID,
                confirmedDate: currenDate,
                modifiedBy: userID,
                modifiedDate: currenDate,
                modifiedFrom: 'web'
            })
            .then(docRef => {
                this.isLoading = false;
                this.props.setNotifyClose(true)
                this.props.handleClose(this.state.fields)
                this.props.history.push(`/enquiry/details/${this.state.fields.documentID}`);
            })
            .catch(error => {
                this.isLoading = false;
                console.error('Error updating enquiries: ', error);
            });


    };

    handleEdit = () => {

    }

    render() {
        const { dealersettings, clientUsers, groupUsers, finance, afterMarket, docID } = this.props;
        const { fields, contact, requirement, activity } = this.state;
        return (
            this.isLoading
                ?
                <></> :
                <>
                    <Modal
                        show={this.props.show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-newlead-assigned">
                        <Modal.Header >
                            <Modal.Title>
                                <Translate text={this.props.title} />
                                {fields.displayID ? <div className="lead-source-badge"><div className="badge badge-pill badge-white">{fields.displayID}</div></div> : <></>}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="newlead-assigned-head">
                                        <div className="vehicle-status-alert alert-user">
                                            {
                                                finance === true ? (<>
                                                    A finance lead has been allocated to you.
                                                </>) : afterMarket === true ? (<>
                                                    A After-Market lead has been allocated to you.
                                                </>) : (<>
                                                    <b className="alert-link">{CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), fields.modifiedBy)}</b> Assigned you a new lead.
                                                </>)
                                            }
                                        </div>

                                    </div>
                                    <div className="newlead-assigned-body">
                                        <div className="newlead-assigned-contact-details">
                                            <h3> <Translate text={'contactDetails'} /></h3>
                                            <div className="newlead-box">

                                                <div className="newlead-box-left">
                                                    <table className="table-newlead">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-left ">
                                                                    <div className="new-lead-contact-title">
                                                                        {CommonHelper.displayFullContact(titles, contact, '--')}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left">
                                                                    <i className="ico icon-call" style={{ fontSize: '12px' }}></i>
                                                                    {contact && contact.phone ? ((contact.phoneCode ? contact.phoneCode : '') + contact.phone) : '--'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left">
                                                                    <i className="ico icon-Mail"></i>
                                                                    {contact && contact.email ? contact.email : '--'}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="newlead-box-right">
                                                    <table className="table-newlead">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-left label-text" width="40%"><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'enquiryType')} />:</td>
                                                                <td className="text-left" width="60%">{CommonHelper.getLabelByValue(enquiryTypes, fields.enquiryType, '--')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'origin')} />:</td>
                                                                <td className="text-left">{CommonHelper.getLabelByValue(origins, fields.origin, '--')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'campaign')} />:</td>
                                                                <td className="text-left">{CommonHelper.getLabelByValue(campaigns, fields.campaign, '--')}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                            {
                                                fields.isVehicle
                                                    ?
                                                    <>
                                                        <h3> <Translate text={'vehicleDetails'} /></h3>

                                                        <Requirements
                                                            requirement={requirement}
                                                            handleEdit={this.handleEdit()}
                                                            setPrimary={this.handleEdit()}
                                                            decrementQuantity={this.handleEdit()}
                                                            incrementQuantity={this.handleEdit()}
                                                            isPrimary={false}
                                                            enquiryID={fields.documentID}
                                                            isDeivered={true}
                                                            hideMore={true}
                                                            dealersettings={dealersettings}
                                                            clientUsers={clientUsers}
                                                            groupUsers={groupUsers}
                                                        >
                                                        </Requirements>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            !finance && !afterMarket && (<div className="newlead-assigned-notes">
                                                <h3> <Translate text={'notes'} /></h3>
                                                <textarea
                                                    className={'form-control notes'}
                                                    name={'newleadassignednotes'}
                                                    onChange={(e) => { e.preventDefault(); }}
                                                    value={activity && activity.notes ? activity.notes : ''}
                                                    readOnly={true}
                                                >
                                                </textarea>
                                            </div>)
                                        }

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            {
                                (finance || afterMarket) ?
                                    <>
                                        <button type="button"
                                            className={`btn btn-default float-left`}
                                            onClick={(e) => { e.preventDefault(); this.props.handleClose(); }} >
                                            <Translate text={'cancel'} />
                                        </button>
                                        <button type="button"
                                            className={`btn btn-info float-right`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.setNotifyClose(true)
                                                this.props.handleClose()
                                                this.props.history.push("/enquiry/details/" + docID);
                                            }}>
                                            <Translate text={'viewEnquiry'} />
                                        </button>
                                    </>
                                    : fields.confirmedBy
                                        ?
                                        <>
                                            <button type="button"
                                                className={`btn btn-default float-left`}
                                                onClick={(e) => { e.preventDefault(); this.props.handleClose(); }} >
                                                <Translate text={'close'} />
                                            </button>
                                            <button type="button"
                                                className={`btn btn-info float-right`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.setNotifyClose(true)
                                                    this.props.handleClose()
                                                    this.props.history.push("/enquiry/details/" + docID);
                                                }}>
                                                <Translate text={'viewEnquiry'} />
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button type="button"
                                                className={`btn btn-default float-left`}
                                                onClick={(e) => { e.preventDefault(); this.props.handleClose(); }} >
                                                <Translate text={'cancel'} />
                                            </button>
                                            <button type="button"
                                                className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                                                onClick={(e) => this.handleSubmit(e)} >
                                                {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'confirm'} />
                                            </button>
                                        </>
                            }

                        </Modal.Footer>
                    </Modal >

                </>
        );
    }
}
