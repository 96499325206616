/** LIBRARIES */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'



const MainContext = React.createContext()

export const FrontDeskConsumer = MainContext.Consumer

export function FrontDeskProvider({ children, clientUsers, dealersettings }) {

    const [usersList, setUsersList] = useState([]);
    const [loadingText, setLoadingText] = useState('');

    useEffect(() => {
        getdealerSettings();
    }, [clientUsers])

    async function getdealerSettings() {

        if (_.isEmpty(usersList))
            setLoadingText('Fetching users settings...')
            
        let clientid = dealersettings.client.id
        let settingsid = dealersettings.client.settings.id
        let users = clientUsers;
        //await asyncForEach(users, async (_user) => {
        users.forEach(async (_user) => {
            const userClientSnapshot = await window.firebase.firestore().doc(`users/${_user.id}/userClients/${clientid}`).get()
            const settingSnapshot = await window.firebase.firestore().doc(`clientSettings/${settingsid}`).get()
            let department = userClientSnapshot.exists ? userClientSnapshot.data().department : '';
            let departments = settingSnapshot.exists ? settingSnapshot.data().departments : null;
            let departmentName = department;
            if (department && !_.isEmpty(departments)) {
                var userDepartment = departments.filter(item => item.value === department).length > 0 ? departments.filter(item => item.value === department)[0] : null;
                if (userDepartment)
                    departmentName = userDepartment.name
            }
            if (userClientSnapshot.exists && !_.isEmpty(userClientSnapshot.data().roleID)) {
                const roleSnapshot = await window.firebase.firestore().doc(`clientSettings/${settingsid}/roles/${userClientSnapshot.data().roleID}`).get()
                users.map((item) => {
                    if (item.id === _user.id) {
                        item.role = roleSnapshot.exists ? roleSnapshot.data().name : '';
                        return item;
                    }
                    return item;
                });
            }
            users.map((item) => {
                if (item.id === _user.id) {
                    item.roleID = userClientSnapshot.exists ? userClientSnapshot.data().roleID : '';
                    //item.role = roleSnapshot.exists ? roleSnapshot.data().name : '';
                    item.queueNum = userClientSnapshot.exists ? userClientSnapshot.data().queueNum : '';
                    item.department = department;
                    item.departmentName = departmentName;
                    return item;
                }
                return item;
            });

            if (users[users.length - 1].id === _user.id)
                setLoadingText('')
        });
        setUsersList(users)

    };

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    return (
        <>
            <MainContext.Provider
                value={{
                    usersList,
                    loadingText
                }}
            >
                {children}
            </MainContext.Provider>

        </>
    )

}

