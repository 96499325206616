/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import NumberFormat from 'react-number-format';
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { PopUpModal } from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
/** VIEW-MODELS */
import { stockStatus, enquiryStatus } from '../../services/enum'
import Specifications from './add/specifications'
import StockHistory from './history'

const StockInfo = ({ enqStatus, stock, requirement, handleEdit, setPrimary, setSold, ShowFullDetails,
    deleteRequirement, enquiry, removeDelete,
    isPrimary, isMultiple, isDeivered, enquiryID, dealersettings, clientUsers, hideMore, groupUsers }) => {
    const [editshow, setEditshow] = useState(false)
    const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [modelSpecificationsShow, setModelSpecificationsShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()

    const node = useRef();
    const {
        documentID, stockNo, clientID, isDeleted,
        make, model, modelDescription, saleType, type,
        year, price, regNo, vinNo, extColor, intColor, trim, buildDate,
        miles, regDate, regExp, warrantyExp, status, statusModifiedBy, warrantyStartDate
    } = stock;

    const userID = dealersettings ? dealersettings.id : '';

    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

    const modelPrice = ((!_.isEmpty(_permissions) && _permissions.modelPrice) ? true : false);
    const modifyVehicleInfo = ((!_.isEmpty(_permissions) && _permissions.modifyVehicleInfo && localStorage.defaultModule !== 'oem') ? true : false);

    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;

    const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
    const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, enqStatus);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const handleStockStatusChange = (status) => {
        setEditshow(false);
        let modifiedDate = window.firebase.firestore.Timestamp.now();

        const refStockData = window.firebase.firestore().collection('stock').doc(documentID);
        refStockData.update(
            {
                status: status,
                enquiryID: status === stockStatus.RESERVED ? enquiryID : null,
                statusModifiedBy: userID,
                statusModifiedFrom: 'web',
                modifiedBy: userID,
                modifiedFrom: 'web',
                modifiedDate: modifiedDate
            }).then((docRef) => {
                //console.log(docRef);

            }).catch((error) => {
                console.error("Error updating handleStockStatusChange: ", error);
            });
    }

    const handleHistoryClose = (id) => {
        setModelHistoryShow(false);
        setEditDocument(null);
    }

    const handleSpecificationClose = (id) => {
        setModelSpecificationsShow(false);
    }
    return (
        //<div className="vehicle-list-view">
        <>
            <div className={`${isMultiple ? '' : 'vehicle-item'}`} id={'veh_' + documentID}>
                <div className="flex-vehicle">
                    {requirement.isSold ? <div className="primary-wrapper-featured"><div className="primary"></div></div> : <></>}

                    {
                        hideMore
                            ?
                            <></>
                            :
                            <>
                                <div className="quickview-activity-more">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setEditshow(!editshow)
                                        if (editshow)
                                            document.addEventListener('click', setEditshow(false));
                                    }}><i className="ico icon-more"></i></a>
                                    <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                        <ul className="more-dropdown">
                                            {
                                                (isPrimary || isDeivered || (ShowFullDetails && ShowFullDetails === true))
                                                    ?
                                                    <></>
                                                    :
                                                    <li>
                                                        <a href="#" onClick={(e) => { e.preventDefault(); setPrimary(requirement); setEditshow(false) }}>
                                                            <i className="ico icon-primary"></i> <Translate text={'markAsPrimary'} />
                                                        </a>
                                                    </li>
                                            }

                                            {
                                                ((isPrimary && isStatusSold && requirement.isSold) || isDeivered || (ShowFullDetails && ShowFullDetails === true) || (!isStatusSold && enqStatus !== enquiryStatus.WON && enqStatus !== enquiryStatus.CLOSED))
                                                    ?
                                                    <></>
                                                    :
                                                    <li>
                                                        {
                                                            requirement.isSold
                                                                ?
                                                                <a href="#" onClick={(e) => { e.preventDefault(); setSold(requirement, false); setEditshow(false) }}>
                                                                    <i className="fas fa-check-circle"></i> <Translate text={'removeFromWon'} />
                                                                </a>
                                                                :
                                                                <a href="#" onClick={(e) => { e.preventDefault(); setSold(requirement, true); setEditshow(false) }}>
                                                                    <i className="fas fa-check-circle"></i> <Translate text={'markAsWon'} />
                                                                </a>
                                                        }

                                                    </li>
                                            }


                                            {
                                                (!isDeivered && (status === stockStatus.AVAILABLE))
                                                    ?
                                                    <li>
                                                        <a href="#" onClick={(e) => { e.preventDefault(); handleStockStatusChange(stockStatus.RESERVED); }}>
                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i> <Translate text={'reserveStock'} />
                                                        </a>
                                                    </li>
                                                    :
                                                    <>
                                                        {
                                                            (!isDeivered && (status === stockStatus.RESERVED) && stock.enquiryID === enquiryID && statusModifiedBy === userID)
                                                                ?
                                                                <li>
                                                                    <a href="#" onClick={(e) => { e.preventDefault(); handleStockStatusChange(stockStatus.AVAILABLE); }}>
                                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i> <Translate text={'releaseStock'} />
                                                                    </a>
                                                                </li>
                                                                :
                                                                <>
                                                                </>

                                                        }
                                                    </>

                                            }

                                            {
                                                !isDeleted
                                                    ?
                                                    <>
                                                        <li>
                                                            <a href="#" onClick={(e) => { e.preventDefault(); handleEdit(requirement) }}>
                                                                {
                                                                    isDeivered && modifyVehicleInfo === false
                                                                        ?
                                                                        <>
                                                                            <i className="ico icon-zoom"></i> <Translate text={'view'} />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <i className="ico icon-edit"></i> <Translate text={'edit'} />
                                                                        </>
                                                                }
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" onClick={(e) => { e.preventDefault(); setEditDocument(documentID); setModelHistoryShow(true); }}>
                                                                <i className="ico icon-history"></i> <Translate text={'stockHistory'} />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" onClick={(e) => { e.preventDefault(); setModelSpecificationsShow(true); }}>
                                                                <i className="ico icon-contract-fill"></i> <Translate text={'Specifications'} />
                                                            </a>
                                                        </li>
                                                    </>
                                                    :
                                                    <></>
                                            }


                                            {
                                                (isPrimary || isDeivered || requirement.isSold || removeDelete)
                                                    ?
                                                    <></>
                                                    :
                                                    <li>
                                                        <a href="#" onClick={(e) => { e.preventDefault(); if (deleteRequirement) deleteRequirement(requirement, requirement.documentID) }}>
                                                            <i className="ico icon-delete"></i> <Translate text={'delete'} />
                                                        </a>
                                                    </li>
                                            }


                                        </ul>
                                    </div>
                                </div>

                            </>
                    }

                    <div className="vehicle-item-image">
                        {
                            (!_.isEmpty(stock.images))
                                ?
                                <>
                                    {
                                        stock.images.map((data, index) => (
                                            <a key={index} data-fancybox={`stock_info_img_slider${documentID}`} className={index === 0 ? '' : 'hide'} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                {index === 0 ?
                                                    <>
                                                        <div className="zoom-ico"><i className="ico icon-zoom"></i></div>
                                                        <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                    </>
                                                    : <></>}
                                            </a>
                                        ))
                                    }
                                </>
                                :
                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                    <img src={CommonHelper.showBrandLogo(dealersettings, make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                </a>
                        }
                    </div>
                    <div className="vehicle-data" onClick={(e) => {
                        e.preventDefault();
                        if (!isDeleted && e.target.tagName !== 'path') handleEdit(requirement)
                    }}>
                        <div className="vehicle-item-title">{make} {model}</div>
                        {
                            clientID && (clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ? (<div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getOtherDealerName(dealersettings, clientID)}</strong></div>) : (<></>)
                        }

                        <div className="dashboard-testdrive-activity-column">
                            Stock#: <div title={CommonHelper.showLocale({dealersettings}, 'stockNo')}>{stockNo ? (<>{stockNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, stockNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div>
                            {extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale({dealersettings}, 'exteriorColor')}>{extColor}</div></>)}
                            {year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale({dealersettings}, 'year')}>{year}</div></>)}
                            {regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale({dealersettings}, 'registrationNumber')}>{regNo ? (<>{regNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, regNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div></>)}
                            {vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale({dealersettings}, 'vinNo')}>{vinNo ? (<>{vinNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, vinNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div></>)}
                            {modelPrice && price && (<><span className="veh-details-seperator">|</span><Translate text={'price'} />:&nbsp;<NumberFormat value={price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></>)}
                        </div>
                        {/* <div className="vehicle-item-info">{extColor ? `${extColor}, ` : ''} {year ? year : ''}</div>
                        <div className="vehicle-item-info">STOCK#: {stockNo ? (<>{stockNo} <span onClick={(e) => CommonHelper.handleCopy(e, stockNo)}><i className="fas fa-copy"></i></span></>) : '--'} </div> */}

                        <div className="vehicle-item-status">
                            {
                                (ShowFullDetails && ShowFullDetails === true)
                                    ?
                                    <>{saleType ? <div className="badge badge-pill badge-white">
                                        {CommonHelper.getNameByValue(dealersettings?.client?.settings?.salesType,
                                            saleType, '')}
                                    </div> : <></>}</>
                                    :
                                    <>{requirement.saleType ? <div className="badge badge-pill badge-white">
                                        {CommonHelper.getNameByValue(dealersettings?.client?.settings?.salesType,
                                            requirement.saleType, '')}
                                    </div> : <></>}</>

                            }
                            {/* {status ? <div className={`badge badge-pill badge-${status.trim().toLowerCase()}-fill ml-1`}>{status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}</div> : <></>} */}

                            {status ? CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, status, '', 'ml-1') : <></>}
                            {isPrimary ? <div className="badge badge-pill badge-primary-fill ml-1"><Translate text={'primary'} /></div> : <></>}
                            {/* {requirement.isSold ? <div className="badge badge-pill badge-status-won ml-1" title={'Requirement'}>{CommonHelper.getNameByValue(dealersettings?.client?.settings?.enquiryStatus, 'won', 'Won')}</div> : <></>} */}
                            {isDeleted ? <div className="badge badge-pill badge-unavailable-fill ml-1"><Translate text={'deleted'} /></div> : <></>}
                            {/* {
                                modelPrice && price
                                    ?
                                    <>
                                        <div className="vehicle-item-price">
                                            {price ? <NumberFormat value={price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : ''}
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            } */}

                        </div>
                    </div>
                </div>
                {
                    status && status !== stockStatus.AVAILABLE && statusModifiedBy && allUsers //&& stock.enquiryID !== enquiryID
                        ?
                        <>
                            <div className="vehicle-current-status">
                                <div className="vehicle-status-alert alert-user" >
                                    {/* <b className="alert-link">{status.toUpperCase()}</b> */}
                                    <b className="alert-link">{CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, status)?.toUpperCase()}</b>
                                    {' by '}
                                    {CommonHelper.getUserNamebyId(allUsers, statusModifiedBy, '--')}
                                    {/* {statusModifiedBy && clientUsers.filter(e => e.id === statusModifiedBy).length > 0 ? clientUsers.filter(m => m.id === statusModifiedBy)[0].name + '.' : '--'} */}
                                    {
                                        (!isDeivered && (status === stockStatus.RESERVED) && stock.enquiryID === enquiryID && statusModifiedBy === userID)
                                            ?
                                            <button type="button" className="close" onClick={(e) => { e.preventDefault(); handleStockStatusChange(stockStatus.AVAILABLE); }}> <span aria-hidden="true">×</span> </button>
                                            :
                                            <>
                                            </>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <>
                        </>
                }

            </div>
            {
                (ShowFullDetails && ShowFullDetails === true)
                    ?
                    <>
                        <div className="info-table">
                            <table className="table-fill">
                                <tbody>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'modelDescription'} /></td>
                                        <td className="text-left">{modelDescription ? modelDescription : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'vinNo'} /></td>
                                        <td className="text-left">{vinNo ? vinNo : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'regNo'} /></td>
                                        <td className="text-left">{regNo ? regNo : '--'}</td>
                                    </tr>

                                    <tr>
                                        <td className="text-left label-text"><Translate text={'registrationDate'} /></td>
                                        <td className="text-left">{regDate ? moment(regDate, 'YYYY-MM-DD').format('DD MMM YYYY') : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'regExp'} /></td>
                                        <td className="text-left">{regExp ? moment(regExp, 'YYYY-MM-DD').format('DD MMM YYYY') : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'warrantyStartDate'} /></td>
                                        <td className="text-left">{warrantyStartDate ? moment(warrantyStartDate, 'YYYY-MM-DD').format('DD MMM YYYY') : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'warrantyExpiry'} /></td>
                                        <td className="text-left">{warrantyExp ? moment(warrantyExp, 'YYYY-MM-DD').format('DD MMM YYYY') : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'buildDate'} /></td>
                                        <td className="text-left">{buildDate ? moment(buildDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'interiorColor'} /></td>
                                        <td className="text-left">{intColor ? intColor : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left label-text"><Translate text={'odometerReading'} /></td>
                                        <td className="text-left">{miles ? miles : '--'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                    :
                    <>
                    </>
            }

            <PopUpModal show={modelHistoryShow}>
                <StockHistory
                    docID={editDocumentID}
                    show={modelHistoryShow}
                    clsActive='overlay-modal active'
                    handleClose={handleHistoryClose}
                    title={'stockHistory'}
                    dealersettings={dealersettings}
                    //updateDealerSettings={props.updateDealerSettings}
                    clientUsers={clientUsers}
                    groupUsers={groupUsers}
                >
                </StockHistory>
            </PopUpModal>
            <PopUpModal show={modelSpecificationsShow}>
                <Specifications
                    stockID={documentID}
                    show={modelSpecificationsShow}
                    clsActive='overlay-modal active'
                    handleClose={handleSpecificationClose}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={groupUsers}
                    isListView={true}
                    contact={enquiry ? enquiry.contact : null}
                >
                </Specifications>
            </PopUpModal>
        </>
        //</div>
    )
};

export default StockInfo;


