export const queueSettingsFields = [
    {
        name: 'Name',
        value: 'name',
        sortValue: 'name',
        flex: 1,
        default: true
    },
    {
        name: 'Queue Timeout',
        value: 'strqTimeout',
        sortValue: 'strqTimeout',
        flex: 1,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        subText : 'updatedBy',
        flex: 1,
        default: true
    },
    {
        name: 'Active',
        value: 'isQueue',
        sortValue: 'isQueue',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]