import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { InputText, ReactSelect, InputCheckBox, ReactMultiSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { lookupSublistVM, eventsProLookups, levelOptions } from './viewModel'
import CommonHelper from '../../../../services/common';

const AddLostReason = (props) => {
    const [lookup, setLookup] = useState(props.lookup);
    const [oldLookup, setOldLookup] = useState(props.lookup ? props.lookup : {})
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [subList, setSubList] = useState(!_.isEmpty(props.lookup) && !_.isEmpty(props.lookup.subList) ? props.lookup.subList : []);
    const [addSubList, setAddSubList] = useState({ name: '', value: '' })
    const [editSubList, setEditSubList] = useState()
    const [textEditor, setTexteditor] = useState({})
    const newLookup = !_.isEmpty(props.lookup) ? false : true

    const disableEdit = (newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
        (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false

    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, lookupSublistVM);
            setLookup(newlookupVM)
            setAddSubList([])
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleOnSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setAddSubList({
                ...addSubList,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setAddSubList({
                    ...addSubList,
                    [name]: newarray1.join(' '),
                    ['value']: _.isEmpty(textEditor) ? uuid() : addSubList.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setAddSubList({
                    ...addSubList,
                    [name]: str,
                    ['value']: _.isEmpty(textEditor) ? uuid() : addSubList.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }

    const handleOnEditSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setEditSubList({
                ...editSubList,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setEditSubList({
                    ...editSubList,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setEditSubList({
                    ...editSubList,
                    [name]: str
                });
            }
        }
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }
    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['levelID']: (data.name === 'level' && e.value === 'oem') ? [props.selectedOEM.id] : '',
                ['groupIDs']: '',
                ['clientIDs']: ''
            });
        }
        else {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['groupIDs']: e.groupIDs,
                ['clientIDs']: e.clientIDs
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setLookup({
            ...lookup,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setLookup({
            ...lookup,
            [e.target.name]: e.target.checked
        })
    }
    // const saveLookup = (e) => {
    //     let formIsValid = true;
    //     let errorClass = 'input_error';
    //     let errors = {};
    //     let activeLookupName = (_.find(clientLookups, { 'value': props.activeLookup }) ? _.find(clientLookups, { 'value': props.activeLookup }).name : 'Lookups')
    //     if (_.isEmpty(lookup['name'])) {
    //         formIsValid = false;
    //         errors['name'] = errorClass;
    //     }
    //     if (_.isEmpty(lookup['value'])) {
    //         formIsValid = false;
    //         errors['value'] = errorClass;
    //     }
    //     if (_.isEmpty(lookup['level'])) {
    //         formIsValid = false;
    //         errors['level'] = errorClass;
    //     }
    //     if (!_.isEmpty(lookup.level) && lookup.level !== 'oem' && _.isEmpty(lookup['levelID'])) {
    //         formIsValid = false;
    //         errors['levelID'] = errorClass;
    //     }
    //     setErrorFields(errors)
    //     if (newLookup && props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.value === lookup.value && lookup.levelID.some(a => item.levelID === a))) {
    //         Swal.fire(activeLookupName + ' with same name already exists', '', 'error')
    //         formIsValid = false;
    //     }
    //     if (!formIsValid) {
    //         return;
    //     }
    //     setLoader(true)
    //     const batch = window.firebase.firestore().batch();
    //     if (lookup.level === 'oem') {
    //         const lookupObj = {
    //             [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level), function (obj) { return { ..._.pick(obj, _.keys(lookupSublistVM)) }; }), _.pick(lookup, _.keys(lookupSublistVM))] :
    //                 [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level), function (obj) {
    //                     return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupSublistVM)) } : { ..._.pick(obj, _.keys(lookupSublistVM)) };
    //                 })]
    //         }
    //         batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
    //             lookupObj, { merge: true });
    //     }
    //     else if (_.isArray(lookup.levelID)) {
    //         lookup.levelID.forEach(rec => {
    //             const lookupObj = {
    //                 [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === rec), function (obj) { return { ..._.pick(obj, _.keys(lookupSublistVM)) }; }), _.pick(lookup, _.keys(lookupSublistVM))] :
    //                     [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && rec === item.levelID), function (obj) {
    //                         return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupSublistVM)) } : { ..._.pick(obj, _.keys(lookupSublistVM)) };
    //                     })]
    //             }
    //             batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${rec}`),
    //                 lookupObj, { merge: true });
    //         })
    //     }
    //     else {
    //         const lookupObj = {
    //             [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === lookup.levelID), function (obj) { return { ..._.pick(obj, _.keys(lookupSublistVM)) }; }), _.pick(lookup, _.keys(lookupSublistVM))] :
    //                 [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === lookup.levelID), function (obj) {
    //                     return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupSublistVM)) } : { ..._.pick(obj, _.keys(lookupSublistVM)) };
    //                 })]
    //         }
    //         batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${lookup.levelID}`),
    //             lookupObj, { merge: true });
    //     }

    //     batch.commit()
    //         .then(snapshot => {
    //             setLoader(false)
    //             //update settings date and priority
    //             if (!_.isEmpty(props.selectedClient)) {
    //                 window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
    //                     .set({
    //                         settingsUpdate: window.firebase.firestore.Timestamp.now(),
    //                         settingsUpdatePriority: 'normal'
    //                     }, { merge: true })
    //             }
    //             //end 
    //             toast.notify((newLookup === true ? activeLookupName + ' added successfully' : activeLookupName + ' updated successfully'), {
    //                 duration: 2000
    //             })
    //             props.handleClose(lookup, newLookup)
    //         })
    //         .catch((error) => {
    //             setLoader(false)
    //             console.log(error);
    //             Swal.fire('Something went wrong', '', 'error')
    //         });
    // }

    const saveLookup = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let _regionLookups = [];
        let _groupLookups = [];
        let _clientLookups = []
        let activeLookupName = (_.find(eventsProLookups, { 'value': props.activeLookup }) ? _.find(eventsProLookups, { 'value': props.activeLookup }).name : 'Lookups')
        if (_.isEmpty(lookup['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (!lookup['price'] && lookup['price'] !== 0 && props.activeLookup === 'servicePackages') {
            formIsValid = false;
            errors['price'] = errorClass;
        }
        if (_.isEmpty(lookup['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(lookup.level) && lookup.level !== 'oem' && _.isEmpty(lookup['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (newLookup && props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.name.toLowerCase() === lookup.name.toLowerCase() && lookup.levelID.some(a => item.levelID === a))) {
            Swal.fire(`${activeLookupName} ${CommonHelper.showLocale(props, 'with same name already exists')}`, '', 'error')
            formIsValid = false;
        }
        if (!lookup['color'] && (props.activeLookup === 'financeStatus' || props.activeLookup === 'enquiryStatus')) {
            formIsValid = false;
            errors['color'] = errorClass;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)

        const batch = window.firebase.firestore().batch();
        if (lookup.level === 'oem' || oldLookup.level === 'oem') {
            const lookupObj = {
                [props.activeLookup]: (newLookup || oldLookup.level !== 'oem') ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level), function (obj) { return { ..._.pick(obj, _.keys(lookupSublistVM)) }; }), _.pick(lookup, _.keys(lookupSublistVM))] :
                    [..._.map(props.allLookups[props.activeLookup].filter(item => ((oldLookup.level === 'oem' && lookup.level !== 'oem') ? (item.value !== oldLookup.value && item.level === oldLookup.level) : item.level === lookup.level)), function (obj) {
                        return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupSublistVM)) } : { ..._.pick(obj, _.keys(lookupSublistVM)) };
                    })]
            }
            console.log('oem', lookupObj)
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/eventsPro/${props.dealersettings.client.settingsID}`),
                lookupObj, { merge: true });
            if (_.isArray(oldLookup.levelID)) {
                oldLookup.levelID.forEach(rec => {
                    const updatelookupObj = {
                        [props.activeLookup]: [..._.map(props.allLookups[props.activeLookup].filter(item => item.value !== oldLookup.value && item.level === oldLookup.level && rec === oldLookup.levelID), function (obj) {
                            return { ..._.pick(obj, _.keys(lookupSublistVM)) };
                        })]
                    }
                    console.log(`${oldLookup.level}-${rec}`, updatelookupObj)
                    if (oldLookup.level !== 'oem')
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${oldLookup.level === 'group' ? 'groups' : oldLookup.level === 'region' ? 'regions' : 'clients'}/${rec}/eventsPro/${rec}`),
                            updatelookupObj, { merge: true });
                })
            }

        }
        if (_.isArray(lookup.levelID)) {
            lookup.levelID.forEach(rec => {
                const lookupObj = {
                    [props.activeLookup]: (newLookup || !oldLookup.levelID.includes(rec)) ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === rec), function (obj) { return { ..._.pick(obj, _.keys(lookupSublistVM)) }; }), _.pick(lookup, _.keys(lookupSublistVM))] :
                        [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && rec === item.levelID), function (obj) {
                            return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupSublistVM)) } : { ..._.pick(obj, _.keys(lookupSublistVM)) };
                        })]
                }
                console.log(`${lookup.level}-${rec}`, lookupObj)
                if (lookup.level !== 'oem')
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${rec}/eventsPro/${rec}`),
                        lookupObj, { merge: true });
            })
            if (_.isArray(oldLookup.levelID)) {
                (oldLookup.level === lookup.level ? _.difference(oldLookup.levelID, lookup.levelID) : oldLookup.levelID).forEach(rec => {
                    const updatelookupObj = {
                        [props.activeLookup]: [..._.map(props.allLookups[props.activeLookup].filter(item => item.value !== oldLookup.value && item.level === oldLookup.level && rec === item.levelID), function (obj) {
                            return { ..._.pick(obj, _.keys(lookupSublistVM)) };
                        })]
                    }
                    console.log(`${oldLookup.level}-${rec}`, updatelookupObj)
                    if (oldLookup.level !== 'oem')
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${oldLookup.level === 'group' ? 'groups' : oldLookup.level === 'region' ? 'regions' : 'clients'}/${rec}/eventsPro/${rec}`),
                            updatelookupObj, { merge: true });
                })
            }
        }
        // else {
        //     const lookupObj = {
        //         [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === lookup.levelID), function (obj) { return { ..._.pick(obj, _.keys(lookupSublistVM)) }; }), _.pick(lookup, _.keys(lookupSublistVM))] :
        //             [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === lookup.levelID), function (obj) {
        //                 return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupSublistVM)) } : { ..._.pick(obj, _.keys(lookupSublistVM)) };
        //             })]
        //     }
        //     console.log(`${lookup.level}`, lookupObj)
        //     batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${lookup.levelID}`),
        //         lookupObj, { merge: true });
        // } 

        batch.commit()
            .then(snapshot => {
                setLoader(false)
                //update settings date and priority 
                //console.log('addLookup props.selectedClient', props.selectedClient)
                // if (!_.isEmpty(props.selectedClient)) {
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Events Pro Dynamic Fields Settings has been updated',
                    }, { merge: true })
                // }
                //end 
                toast.notify((newLookup === true ? activeLookupName + ' added successfully' : activeLookupName + ' updated successfully'), {
                    duration: 2000
                })
                props.handleClose(lookup, newLookup)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });

    }

    const handleSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['name'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistname'] = errorClass;
            else
                errors['editsublistname'] = errorClass;
        }
        if (_.isEmpty(rec['value'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistvalue'] = errorClass;
            else
                errors['editsublistvalue'] = errorClass;
        }
        setErrorFields(errors)
        if (subList.filter(item => item.value !== rec.value).some(item => item.name === rec.name)) {
            Swal.fire('Lost sub reason with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj) {
                return obj.value === rec.value ? { ...rec } : { ...obj };
            })];
        setSubList(addList)
        setLookup({
            ...lookup,
            ['subList']: addList
        });
        setAddSubList({ name: '', value: '' })
        setEditSubList(null)
        setTexteditor({})
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleEditSubList = (rec) => {
        setEditSubList(rec)
        setTexteditor({
            [rec.value]: true
        })
    }
    const handleDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...subList.filter(item => item.value !== rec.value)];
                setSubList(deleteList)
                setLookup({
                    ...lookup,
                    ['subList']: deleteList
                });
            }
        })
    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = subList.splice(result.source.index, 1);
        subList.splice(result.destination.index, 0, removed);
        setSubList(subList)
        setLookup({
            ...lookup,
            ['subList']: subList
        });
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-lost-reasons"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={lookup.name}
                                        maxLength={150}
                                        disabled={disableEdit}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={lookup.level}
                                        classNamePrefix={` ${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                    //isDisabled={!newLookup}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(lookup.level) && lookup.level !== 'oem') ? (<div className="form-group col-md-12">
                                        <label ><Translate text={lookup.level === 'group' ? 'Group' : lookup.level === 'region' ? 'Region' : 'client'} /></label>
                                        <ReactMultiSelect
                                            options={lookup.level === 'group' ? props.groupOptions : lookup.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'levelID'}
                                            placeholder={`select ${lookup.level === 'individual' ? 'client' : lookup.level}`}
                                            onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
                                            value={lookup.levelID ? _.isArray(lookup.levelID) ? lookup.levelID : [lookup.levelID] : []}
                                            classNamePrefix={`  ${errorFields["levelID"]} basic-select`}
                                            removeClearable={true}
                                            //isDisabled={!newLookup}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                                }
                            </div>
                            <div className="settings-sub-divider">
                                <h3> <Translate text={'lostSubReason'} /></h3>
                            </div>
                            <DragDropContext onDragEnd={onDragEnd}>


                                <div className="common-table"
                                >
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="head-light" width="45%"> <Translate text={'name'} /></th>
                                                {/* <th scope="col" className="head-light" width="45%">Value</th> */}
                                                <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                            </tr>
                                            {
                                                disableEdit ? (<></>) : (
                                                    <tr>
                                                        <td><div className="table-input">
                                                            <InputText
                                                                id="sublistname"
                                                                autoComplete="off"
                                                                placeholder={'name'}
                                                                className={`form-control ${errorFields["sublistname"]}`}
                                                                name="name"
                                                                onChange={handleOnSubChange}
                                                                value={addSubList.name}
                                                                disabled={disableEdit}
                                                            />
                                                        </div></td>
                                                        <td className="border-right-0">
                                                            <div className="button-height-fix">
                                                                <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </thead>
                                        <Droppable droppableId="droppableLostReason">
                                            {(provided, snapshot) => (
                                                <tbody
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {
                                                        subList.length > 0 ?
                                                            subList.map((rec, index) => {
                                                                return <Draggable key={rec.value} draggableId={rec.value} index={index}>
                                                                    {(provided1, snapshot1) => (
                                                                        <tr
                                                                            ref={provided1.innerRef}
                                                                            {...provided1.draggableProps}
                                                                            {...provided1.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot1.isDragging,
                                                                                provided1.draggableProps.style
                                                                            )}
                                                                            onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}
                                                                        >
                                                                            <td style={{ width: '335px' }} >
                                                                                {
                                                                                    textEditor[rec.value] ? (<><InputText
                                                                                        id="editsublistname"
                                                                                        autoComplete="off"
                                                                                        placeholder={'name'}
                                                                                        className={`form-control ${errorFields["editsublistname"]}`}
                                                                                        name="name"
                                                                                        onChange={handleOnEditSubChange}
                                                                                        value={editSubList.name}
                                                                                    /></>) : (<>{rec.name}</>)
                                                                                }
                                                                            </td>
                                                                            <td className="border-right-0">
                                                                                <div className="action-fix">
                                                                                    {
                                                                                        textEditor[rec.value] ?
                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                            </>) :
                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                            </>)
                                                                                    }

                                                                                </div>
                                                                                <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                            </td>
                                                                            {provided1.placeholder}
                                                                        </tr>
                                                                    )}
                                                                </Draggable>
                                                            })
                                                            : (<></>)
                                                    }
                                                    {provided.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </table>
                                </div>

                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className={`switch switch-sm float-left ${disableEdit ? 'btn-disable' : ''}`}>
                    <InputCheckBox
                        className={`switch`}
                        id={`lookup-active`}
                        name={'active'}
                        checked={lookup.active ? lookup.active : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`lookup-active`}><Translate text={'active'} /></label>
                </span>
                <button type="button" className={`btn btn-primary float-right ml-2 ${!_.isEmpty(textEditor) ? 'btn-disable' : ''}`} onClick={(e) => saveLookup(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddLostReason;