import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactSelect } from "./../../components";
import images from '../../images';

const DealerListView = (props) => {
    const [selectedDealer, setSelectedDealer] = useState();

    useEffect(() => {
        if (props?.data?.clients?.length > 0) {
            setSelectedDealer(props.data.clients[0].value);
        }
    }, [props.data]);

    const handleSwitchDealer = (selectedOption) => {
        setSelectedDealer(selectedOption ? selectedOption.value : '');
    };

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog modal-dialog-centered modal-customize-pop"
            enforceFocus={false}
        >
            <div className="modal-content">
                <div className="modal-body customize-pop-body">
                    <div className="customize-pop-overlay">
                        <div className="spinner-loader h-100">
                            <div className="customize-pop-flex-wrap h-100  text-primary">
                                <div className="customize-pop-txt-wrap">
                                    <div className="customize-pop-img-wrap"><img src={images.alertInfo} height="80" /></div>
                                    <div className="customize-pop-content-head justify-content-center align-items-center">Are you sure? </div>
                                    <div className="customize-pop-content justify-content-center align-items-center">
                                        Do you want to switch dealer.
                                    </div>
                                    <div className="form-style w-100 mt-3">
                                        <div className="form-row">
                                            <div className="form-group col-md-12 align-left">
                                                <label htmlFor="">Dealers</label>
                                                <ReactSelect
                                                    options={props?.data?.clients ? props.data.clients : []}
                                                    onChange={handleSwitchDealer}
                                                    value={selectedDealer}
                                                    classNamePrefix={`basic-select`}
                                                    isSearchable={true}
                                                    removeClearable={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="customize-pop-content-btn justify-content-center align-items-center">
                                        <button
                                            type="button"
                                            className="btn btn-primary ml-1 mr-1"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.handleClose(props.data, selectedDealer)
                                            }}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-default ml-1 mr-1"
                                            onClick={() => props.handleClose()}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DealerListView;
