/** LIBRARIES */
import React, { Component, Fragment, Suspense } from 'react';
import { Modal, Accordion, Card } from 'react-bootstrap';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
import { Tooltip } from 'antd';
//import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2'
/** COMPONENTS */

import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { PopUpModal, ReactSelect } from '../../../components';
import { default as _images } from "../../../images";

import { firestoreDB } from '../../../services/helper';
import { enquiryStatus } from '../../../services/enum';

import CAPLeads from '../../pipeline/details/existinfCAPLeads'


let titles = [];
let languages = [];
let license_state = [];
export default class CapContact extends Component {

    constructor(props) {
        super(props);
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.unsubscribe = null;
        this.isLoading = false;
        this.state = {
            contact: {},
            activeLeftKey: '0',
            activeRightKey: '00',
            showCAPLeads: {
                show: false
            },

        };
    }



    componentDidMount() {

    }

    componentWillUnmount() {

    }
    handleLeftActiveKey = index => {
        this.setState({ activeLeftKey: index });
    };
    handleRightActiveKey = index => {
        this.setState({ activeRightKey: index });
    };

    handleSelect = (data) => {

        const { enquiry, enquiryID } = this.props;
        let leads = Object.assign([], data?.Leads?.filter(e => e.status === enquiryStatus.OPEN))

        if (leads?.length > 0 && enquiryID) {
            this.setState({
                showCAPLeads: {
                    show: true,
                    leads: leads,
                    contact: data,
                    title: 'CAP Leads',
                    enquiryID: enquiryID,
                    capTags: (!_.isEmpty(enquiry?.tags) || [])
                }
            });
        } else {
            this.props.handleClose(data);
        }

    }

    saveCAPDetails = async (contact) => {
        //console.log('saveCAPDetails', contact)
        const { enquiry, enquiryID, dealersettings } = this.props;
        this.setState({
            showCAPLeads: {
                show: false
            }
        })
        if (contact?.documentID && enquiryID) {

            await firestoreDB(dealersettings)
                .firestore()
                .doc(`enquiries/${enquiryID}`)
                .set({
                    'capBP': contact.capGuid || null,
                    'projectId': dealersettings?.client?.projectId || null
                }, { merge: true })

            this.props.handleClose(contact);
        }
    }

    handleCloseCAPLead = (contact, isEnquiryExist, _enquiry) => {
        this.setState({
            showCAPLeads: {
                show: false
            }
        })
        //console.log('handleCloseCAPLead', contact, isEnquiryExist, _enquiry)
        if (contact?.documentID)
            this.props.handleClose(contact, isEnquiryExist, _enquiry);
    }


    render() {
        const { show, contact, dealersettings, clientUsers, groupUsers } = this.props;

        const { displayID, contactType, clientID, preferredName, dob, isVIP, banLoanVehicle,
            prevPurchased, gender, maritalStatus, language, nationality, address, owner, company,
            contactMethod, licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL, phone, phoneCode,
            email, licenseState, secondaryLicenseFrontUrl, secondaryLicenseBackUrl, campaign, origin,
            licenseType, interests, optinPhone, optinPost, optinEmail, optinSMS, marketingOptinEmail,
            marketingOptinSMS, dependents, driverName, addedBy, addedDate, phones, emails, designation,
            secondaryTitle, secondaryPreferredName, secondaryPhoneCode, secondaryFirstName, secondaryLastName,
            secondaryPhones, secondaryEmails, secondaryPhone, secondaryEmail, visitingCardURL, isDeleted,
            amsMe, gdprDeleted, userImageURL, isDLScan, tags, capTags
        } = contact;

        const { activeLeftKey, activeRightKey, showCAPLeads } = this.state;
        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
        let dealerName = CommonHelper.getOtherDealerName(dealersettings, contact?.clientID)

        const isPrivacyEnabled = CommonHelper.isPrivacyEnabled(dealersettings);

        let _leads = Object.assign([], contact?.Leads);
        let _vehicles = Object.assign([], contact?.Vehicles);

        const _openLead = _leads.filter(e => e.status === enquiryStatus.OPEN);
        const _leadHistory = _leads.filter(e => e.status !== enquiryStatus.OPEN);

        const _activeVehicles = _vehicles.filter(e => e.owned === true);
        const _ownedVehicles = _vehicles.filter(e => e.owned !== true);

        //console.log(contact)
        return (
            <>
                <Modal
                    id="capContactInfo"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-customer-details-minipop">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'CAP Contact Info'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="cutomer-details-minipop-wrapper">

                            <div className="enquiry-detailswrap">

                                <div className="enquiry-detail-head">
                                    <div className="float-left">
                                        <div className="contactdetails-userthumb-img ">
                                            <img src={CommonHelper.showUserAvatar(userImageURL, CommonHelper.displayContactName(null, this.props.contact, ''))} alt=""
                                                className="rounded-circle img-object-fit" />
                                        </div>
                                    </div>

                                    <div className="float-left">
                                        <div className='enquiry-details-contact'>
                                            {CommonHelper.displayContactName(titles, this.props.contact, '--')}
                                        </div>

                                        {preferredName ? (
                                            <span className='enquiry-details-preferred'> ({preferredName})</span>
                                        ) : (
                                            <></>
                                        )}

                                        <div className="enquiry-details-optin">
                                            {
                                                optinPhone === false
                                                    ?
                                                    <span><Tooltip placement="bottom" title={<Translate text={'optoutPhone'} />}>
                                                        <i className={`ico icon-no-call`}></i>
                                                    </Tooltip></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                optinSMS === false
                                                    ?
                                                    <span><Tooltip placement="bottom" title={<Translate text={'optoutSMS'} />}>
                                                        <i className={`ico icon-no-sms`}></i>
                                                    </Tooltip></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                optinEmail === false
                                                    ?
                                                    <span><Tooltip placement="bottom" title={<Translate text={'optoutEmail'} />}>
                                                        <i className={`ico icon-no-email`}></i>
                                                    </Tooltip></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                optinPost === false
                                                    ?
                                                    <span><Tooltip placement="bottom" title={<Translate text={'optoutPost'} />}>
                                                        <i className={`ico icon-no-post`}></i>
                                                    </Tooltip></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                marketingOptinEmail === false
                                                    ?
                                                    <span><Tooltip placement="bottom" title={<Translate text={'marketingoptoutEmail'} />}>
                                                        <i className={`ico icon-no-email`}></i>
                                                    </Tooltip></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                marketingOptinSMS === false
                                                    ?
                                                    <span><Tooltip placement="bottom" title={<Translate text={'marketingoptoutSMS'} />}>
                                                        <i className={`ico icon-no-sms`}></i>
                                                    </Tooltip></span>
                                                    :
                                                    <></>
                                            }
                                            {
                                                banLoanVehicle ? <span><Tooltip placement="bottom" title={<Translate text={'banLoanVehicle'} />}>
                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i>
                                                </Tooltip></span> : <></>
                                            }
                                        </div>

                                        <div className="enquiry-subtitle">
                                            {displayID || ''}

                                            {
                                                tags && tags.length > 0 ? (<>
                                                    {
                                                        tags.map((r, i) => {
                                                            return r.subType ? (
                                                                <span key={i} className="lead-badge-border">
                                                                    <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>
                                                                    <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div>
                                                                </span>
                                                            ) : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
                                                        })
                                                    }
                                                </>) : (<></>)
                                            }

                                            {contactType ? (
                                                <div className='badge badge-pill badge-white ml-2'>
                                                    {contactType}
                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            {isDLScan ? (
                                                <div className="badge badge-pill badge-white ml-2">
                                                    <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                    {`Digital Driving Licence`}
                                                </div>
                                            ) : (
                                                <></>
                                            )}


                                        </div>
                                    </div>



                                </div>


                                <div className="enquiry-detail-bottom">
                                    <div className="float-left mt-2">
                                        <ul className="porsche-enquiry-details">
                                            {language ? (
                                                <li> <i className="ico icon-activity-world"></i> {language}</li>
                                            ) : (
                                                <></>
                                            )}
                                            {
                                                phone && (
                                                    <li> <i className="ico icon-call"></i> {phone ? CommonHelper.getFullPhone(phoneCode, phone) : '--'}</li>
                                                )
                                            }

                                            {email && (<li>
                                                <i className='ico icon-email'></i>
                                                {email}
                                            </li>)}

                                            {!_.isEmpty(company) ? (
                                                <li>
                                                    {' '}
                                                    <i className='ico icon-company'></i> {company?.name || '--'}
                                                </li>
                                            ) : (
                                                <></>
                                            )}

                                            {isVIP ? (
                                                <li> <i className="ico icon-star-active"></i> VIP</li>
                                            ) : (
                                                <></>
                                            )}
                                            {
                                                !_.isEmpty(capTags) && capTags.map((r, i) => {
                                                    return <li key={i}><i className="ico icon-Stock"></i>  {r}</li>
                                                })
                                            }

                                            <li><i className="ico icon-Contacts"></i>  Active BP</li>
                                        </ul>
                                    </div>
                                    {
                                        owner && (<div className="float-right">
                                            <div className="porsche-item-btn ml-3">
                                                <div className="porsche-designation">Sales Consultant</div>
                                                <div className='filter-item-btn filter-owner-btn ml-2'>
                                                    <ReactSelect
                                                        options={_.map(clientUsers, function (rec) {
                                                            return {
                                                                value: rec.id,
                                                                active: _.isBoolean(rec.active) ? rec.active : true,
                                                                searchlabel: rec.name,
                                                                label: <div className="drop-image">
                                                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                                    {rec.name}
                                                                </div>
                                                            }
                                                        })}
                                                        name={'owner'}
                                                        onChange={() => { }}
                                                        value={owner}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                        isDisabled={true}
                                                    ></ReactSelect>
                                                </div>
                                            </div>
                                        </div>)
                                    }


                                </div>

                                {/* <div className="porsche-detail-bottom">
                                    <div className="float-left">
                                        <ul className="porsche-list-details">
                                            {dealerName ? (
                                                <li> <i className="ico icon-dealers"></i><strong>{dealerName}</strong></li>
                                            ) : (
                                                <></>
                                            )}
                                        </ul>
                                    </div>

                                    <div className="float-right">
                                        <div className="porsche-item-btn ml-3">
                                            <div className="porsche-designation">Sales Consultant</div>
                                            <div className='filter-item-btn filter-owner-btn ml-2'>
                                                <ReactSelect
                                                    options={this.state.clientUsers}
                                                    name={'owner'}
                                                    placeholder={'select ownership'}
                                                    onChange={this.handleSelectSave}
                                                    value={owner ? owner : addedBy}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                    isSearchable={true}
                                                    isDisabled={true}
                                                ></ReactSelect>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}

                            </div>


                            <div className="clearfix"></div>

                            <div className='enquirydetail-grid flex-column mt-4'>
                                {/** LEFT TAB */}
                                <aside className='small-column mr-3'>
                                    <div className='enquiry-sidebar'>
                                        <Accordion defaultActiveKey='01'>

                                            {/** OPEN LEAD INFO */}
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey='01'
                                                    onClick={e => {
                                                        e.preventDefault();

                                                        if (activeRightKey === '01')
                                                            this.handleRightActiveKey(null);
                                                        else this.handleRightActiveKey('01');
                                                    }}
                                                >
                                                    <div className='mb-0'>

                                                        <div className='cardlink'>
                                                            <a
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                }}
                                                                className={`btn btn-link ${activeRightKey === '01' ? '' : 'collapsed'
                                                                    }`}
                                                            >
                                                                <i className="ico icon-enquiry" title="enquiry"></i>  Open Leads
                                                                {!_.isEmpty(_openLead) && _openLead?.length > 0 ? (
                                                                    <span className='badge badge-pill badge-cafe ml-1'>
                                                                        {_openLead.length}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                <i className='arrow-collapse'></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey='01'>
                                                    <Card.Body>
                                                        {!_.isEmpty(_openLead) &&
                                                            _openLead.length > 0 ? (
                                                            <>
                                                                {
                                                                    _openLead.map((lead, index) => {
                                                                        return <div key={index} className="vehicle-item" id={lead?.capGuid || index}>
                                                                            <div className="flex-vehicle">

                                                                                <div className="vehicle-data">
                                                                                    {/* <div className="vehicle-item-title mini-badge">
                                                                                        <i className="ico icon-enquiry mr-1" title="Enquiry"></i>PCD-00001036</div> */}
                                                                                    <div className="vehicle-item-title mini-badge">
                                                                                        {lead?.description || ''}

                                                                                        {
                                                                                            lead?.capID && (<div className="badge badge-pill badge-white ml-1">
                                                                                                <strong>CAP ID:</strong> {lead.capID}
                                                                                            </div>)
                                                                                        }
                                                                                    </div>

                                                                                    {
                                                                                        lead?.addedDate && (<div className="vehicle-item-info">
                                                                                            <strong>Created Date:</strong> {moment.unix(lead.addedDate._seconds).format('DD MMM YYYY')}
                                                                                        </div>)
                                                                                    }
                                                                                    {
                                                                                        lead?.modifiedDate && (<div className="vehicle-item-info">
                                                                                            <strong>Updated Date:</strong> {moment.unix(lead.modifiedDate._seconds).format('DD MMM YYYY')}
                                                                                        </div>)
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                            {
                                                                                lead?.status && (<div className="vehicle-current-status">
                                                                                    <div className="float-right">
                                                                                        {
                                                                                            lead?.statusdt && (<div className="pipeline-users pl-0 pt-2">
                                                                                                <strong>Status Date:</strong> {moment.unix(lead.statusdt._seconds).format('DD MMM YYYY')}
                                                                                            </div>)
                                                                                        }
                                                                                    </div>

                                                                                    <div className="float-left">
                                                                                        <div className="vehicle-item-owner ">
                                                                                            <div className={`badge badge-pill badge-status-${lead.status.trim().toLowerCase()}`}>
                                                                                                {lead.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>)
                                                                            }

                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='text-center p-3'>
                                                                    <p>
                                                                        <Translate text={'No Lead Available'} />
                                                                    </p>

                                                                </div>
                                                            </>
                                                        )}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            {/** LEAD HISTORY INFO */}
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey='02'
                                                    onClick={e => {
                                                        e.preventDefault();

                                                        if (activeRightKey === '02')
                                                            this.handleRightActiveKey(null);
                                                        else this.handleRightActiveKey('02');
                                                    }}
                                                >
                                                    <div className='mb-0'>

                                                        <div className='cardlink'>
                                                            <a
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                }}
                                                                className={`btn btn-link ${activeRightKey === '02' ? '' : 'collapsed'
                                                                    }`}
                                                            >
                                                                <i className="ico icon-history" title="Enquiry"></i>   Lead History
                                                                {!_.isEmpty(_leadHistory) && _leadHistory?.length > 0 ? (
                                                                    <span className='badge badge-pill badge-cafe ml-1'>
                                                                        {_leadHistory.length}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                <i className='arrow-collapse'></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey='02'>
                                                    <Card.Body>
                                                        {!_.isEmpty(_leadHistory) &&
                                                            _leadHistory.length > 0 ? (
                                                            <>
                                                                {
                                                                    _leadHistory.map((lead, index) => {
                                                                        return <div key={index} className="vehicle-item" id={lead?.capGuid || index}>
                                                                            <div className="flex-vehicle">

                                                                                <div className="vehicle-data">
                                                                                    {/* <div className="vehicle-item-title mini-badge">
                                                                                        <i className="ico icon-enquiry mr-1" title="Enquiry"></i>PCD-00001036</div> */}
                                                                                    <div className="vehicle-item-title mini-badge">
                                                                                        {lead?.description || ''}

                                                                                        {
                                                                                            lead?.capID && (<div className="badge badge-pill badge-white ml-1">
                                                                                                <strong>CAP ID:</strong> {lead.capID}
                                                                                            </div>)
                                                                                        }
                                                                                    </div>

                                                                                    {
                                                                                        lead?.addedDate && (<div className="vehicle-item-info">
                                                                                            <strong>Created Date:</strong> {moment.unix(lead.addedDate._seconds).format('DD MMM YYYY')}
                                                                                        </div>)
                                                                                    }
                                                                                    {
                                                                                        lead?.modifiedDate && (<div className="vehicle-item-info">
                                                                                            <strong>Updated Date:</strong> {moment.unix(lead.modifiedDate._seconds).format('DD MMM YYYY')}
                                                                                        </div>)
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                            {
                                                                                lead?.status && (<div className="vehicle-current-status">
                                                                                    <div className="float-right">
                                                                                        {
                                                                                            lead?.statusdt && (<div className="pipeline-users pl-0 pt-2">
                                                                                                <strong>Status Date:</strong> {moment.unix(lead.statusdt._seconds).format('DD MMM YYYY')}
                                                                                            </div>)
                                                                                        }
                                                                                    </div>

                                                                                    <div className="float-left">
                                                                                        <div className="vehicle-item-owner ">
                                                                                            <div className={`badge badge-pill badge-status-${lead.status.trim().toLowerCase()}`}>
                                                                                                {lead.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>)
                                                                            }

                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='text-center p-3'>
                                                                    <p>
                                                                        <Translate text={'No Lead Available'} />
                                                                    </p>

                                                                </div>
                                                            </>
                                                        )}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            {/** ACTIVE VEHICLES INFO */}
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey='03'
                                                    onClick={e => {
                                                        e.preventDefault();

                                                        if (activeRightKey === '03')
                                                            this.handleRightActiveKey(null);
                                                        else this.handleRightActiveKey('03');
                                                    }}
                                                >
                                                    <div className='mb-0'>

                                                        <div className='cardlink'>
                                                            <a
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                }}
                                                                className={`btn btn-link ${activeRightKey === '03' ? '' : 'collapsed'
                                                                    }`}
                                                            >
                                                                <i className="ico icon-Stock" title="Enquiry"></i>   Active Vehicles
                                                                {!_.isEmpty(_activeVehicles) && _activeVehicles?.length > 0 ? (
                                                                    <span className='badge badge-pill badge-cafe ml-1'>
                                                                        {_activeVehicles.length}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                <i className='arrow-collapse'></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey='03'>
                                                    <Card.Body>
                                                        {!_.isEmpty(_activeVehicles) &&
                                                            _activeVehicles.length > 0 ? (
                                                            <>
                                                                {
                                                                    _activeVehicles.map((lead, index) => {
                                                                        return <div key={index} className="vehicle-item" id={lead?.capGuid || index}>
                                                                            <div className="flex-vehicle">

                                                                                <div className="vehicle-data">
                                                                                    {/* <div className="vehicle-item-title mini-badge">
                                                                                        <i className="ico icon-enquiry mr-1" title="Enquiry"></i>PCD-00001036</div> */}
                                                                                    <div className="vehicle-item-title mini-badge">
                                                                                        {lead?.make || ''}&nbsp; {lead?.model || ''}

                                                                                        {
                                                                                            lead?.capID && (<div className="badge badge-pill badge-white ml-1">
                                                                                                <strong>CAP ID:</strong> {lead.capID}
                                                                                            </div>)
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        lead?.vin && (<div className="vehicle-item-info">
                                                                                            <strong>VIN#:</strong> {lead?.vin}
                                                                                        </div>)
                                                                                    }
                                                                                    {
                                                                                        lead?.addedDate && (<div className="vehicle-item-info">
                                                                                            <strong>Created Date:</strong> {moment.unix(lead.addedDate._seconds).format('DD MMM YYYY')}
                                                                                        </div>)
                                                                                    }
                                                                                    {
                                                                                        lead?.deliveryDate && (<div className="vehicle-item-info">
                                                                                            <strong>Delivery Date:</strong> {moment.unix(lead.deliveryDate._seconds).format('DD MMM YYYY')}
                                                                                        </div>)
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='text-center p-3'>
                                                                    <p>
                                                                        <Translate text={'No Vehicle Available'} />
                                                                    </p>

                                                                </div>
                                                            </>
                                                        )}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                            {/** OWNED VEHICLES INFO */}
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey='04'
                                                    onClick={e => {
                                                        e.preventDefault();

                                                        if (activeRightKey === '04')
                                                            this.handleRightActiveKey(null);
                                                        else this.handleRightActiveKey('04');
                                                    }}
                                                >
                                                    <div className='mb-0'>

                                                        <div className='cardlink'>
                                                            <a
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                }}
                                                                className={`btn btn-link ${activeRightKey === '04' ? '' : 'collapsed'
                                                                    }`}
                                                            >
                                                                <i className="ico icon-tradein" title="Enquiry"></i>   Ever Owned Vehicles
                                                                {!_.isEmpty(_ownedVehicles) && _ownedVehicles?.length > 0 ? (
                                                                    <span className='badge badge-pill badge-cafe ml-1'>
                                                                        {_ownedVehicles.length}
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                <i className='arrow-collapse'></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey='04'>
                                                    <Card.Body>
                                                        {!_.isEmpty(_ownedVehicles) &&
                                                            _ownedVehicles.length > 0 ? (
                                                            <>
                                                                {
                                                                    _ownedVehicles.map((lead, index) => {
                                                                        return <div key={index} className="vehicle-item" id={lead?.capGuid || index}>
                                                                            <div className="flex-vehicle">

                                                                                <div className="vehicle-data">
                                                                                    {/* <div className="vehicle-item-title mini-badge">
                                                                                        <i className="ico icon-enquiry mr-1" title="Enquiry"></i>PCD-00001036</div> */}
                                                                                    <div className="vehicle-item-title mini-badge">
                                                                                        {lead?.make || ''}&nbsp; {lead?.model || ''}

                                                                                        {
                                                                                            lead?.capID && (<div className="badge badge-pill badge-white ml-1">
                                                                                                <strong>CAP ID:</strong> {lead.capID}
                                                                                            </div>)
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        lead?.vin && (<div className="vehicle-item-info">
                                                                                            <strong>VIN#:</strong> {lead?.vin}
                                                                                        </div>)
                                                                                    }
                                                                                    {
                                                                                        lead?.addedDate && (<div className="vehicle-item-info">
                                                                                            <strong>Created Date:</strong> {moment.unix(lead.addedDate._seconds).format('DD MMM YYYY')}
                                                                                        </div>)
                                                                                    }
                                                                                    {
                                                                                        lead?.deliveryDate && (<div className="vehicle-item-info">
                                                                                            <strong>Delivery Date:</strong> {moment.unix(lead.deliveryDate._seconds).format('DD MMM YYYY')}
                                                                                        </div>)
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='text-center p-3'>
                                                                    <p>
                                                                        <Translate text={'No Vehicle Available'} />
                                                                    </p>

                                                                </div>
                                                            </>
                                                        )}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>
                                </aside>

                                {/** RIGHT TAB */}
                                <aside className='small-column'>
                                    <div className='enquiry-sidebar'>
                                        <Accordion defaultActiveKey='0'>
                                            {/** CONTACT INFO */}
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey='0'
                                                    onClick={e => {
                                                        e.preventDefault();

                                                        // if (activeLeftKey === '0')
                                                        //     this.handleLeftActiveKey(null);
                                                        // else this.handleLeftActiveKey('0');
                                                    }}
                                                >
                                                    <div className='mb-0'>
                                                        <div className='cardlink'>
                                                            <a
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                }}
                                                                className={`btn btn-link ${activeLeftKey === '0' ? '' : 'collapsed'
                                                                    }`}
                                                            >
                                                                <i className='ico icon-Contacts'></i>{' '}
                                                                <Translate text={'contactInfo'} />{' '}
                                                                <i className='arrow-collapse'></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Accordion.Toggle>


                                                <Accordion.Collapse eventKey='0'>
                                                    <Card.Body>
                                                        <div className='info-table'>
                                                            <div className="contact-info-section-head"> <Translate text={'contactInformation'} /></div>
                                                            <table className='table-fill'>
                                                                <tbody>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'Last source (header)'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {'--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'Last source (sub-header)'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {'--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td
                                                                            className='text-left label-text'
                                                                            width='35%'
                                                                        >
                                                                            <Translate text={'gender'} />
                                                                        </td>
                                                                        <td className='text-left' width='65%'>
                                                                            {gender ? gender : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td
                                                                            className='text-left label-text'
                                                                            width='35%'
                                                                        >
                                                                            <Translate text={'Mobile'} />
                                                                        </td>
                                                                        <td className='text-left' width='65%'>
                                                                            {phone ? CommonHelper.getFullPhone(phoneCode, phone) : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td
                                                                            className='text-left label-text'
                                                                            width='35%'
                                                                        >
                                                                            <Translate text={'email'} />
                                                                        </td>
                                                                        <td className='text-left' width='65%'>
                                                                            {email ? email : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'dateOfBirth'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {dob ? moment(dob).format('DD MMM YYYY') : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td
                                                                            className='text-left label-text'
                                                                            width='35%'
                                                                        >
                                                                            <Translate text={'Occupation'} />
                                                                        </td>
                                                                        <td className='text-left' width='65%'>
                                                                            {designation ? designation : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'Hobbies'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {interests ? interests : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'Religion'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {'--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'maritalStatus'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {maritalStatus ? maritalStatus : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'Address line 1'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {address ? address : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'Address line 2'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {'--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'Address line 3'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {'--'}
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                            <div className="contact-info-section-head"> <Translate text={'otherInformation'} /></div>
                                                            <table className='table-fill'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            className='text-left label-text'
                                                                            width='35%'
                                                                        >
                                                                            <Translate text={'primaryID'} />
                                                                        </td>
                                                                        <td className='text-left' width='65%'>
                                                                            <div className='contact-detail-license'>
                                                                                <div className='license-box'>
                                                                                    {licenseFrontURL ? (
                                                                                        <>
                                                                                            {
                                                                                                (isPrivacyEnabled)
                                                                                                    ?
                                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                                    :
                                                                                                    <a
                                                                                                        data-fancybox={`license-box`}
                                                                                                        href={licenseFrontURL}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            this.handleSaveAuditLog(licenseFrontURL);
                                                                                                        }}
                                                                                                    >
                                                                                                        <img src={licenseFrontURL} alt='' className="img-object-fit" />{' '}
                                                                                                    </a>
                                                                                            }
                                                                                        </>

                                                                                    ) : (
                                                                                        <a
                                                                                            href='#'
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                            }}
                                                                                        >
                                                                                            <img src={_images.nolicense} alt='' />{' '}
                                                                                        </a>
                                                                                    )}
                                                                                </div>
                                                                                <div className='license-box'>
                                                                                    {licenseBackURL ? (
                                                                                        <>
                                                                                            {
                                                                                                (isPrivacyEnabled)
                                                                                                    ?
                                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                                    :
                                                                                                    <a
                                                                                                        data-fancybox={`license-box`}
                                                                                                        href={licenseBackURL}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            this.handleSaveAuditLog(licenseBackURL);
                                                                                                        }}
                                                                                                    >
                                                                                                        <img src={licenseBackURL} alt='' className="img-object-fit" />{' '}
                                                                                                    </a>
                                                                                            }
                                                                                        </>

                                                                                    ) : (
                                                                                        <a
                                                                                            href='#'
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                            }}
                                                                                        >
                                                                                            <img src={_images.nolicense} alt='' />{' '}
                                                                                        </a>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                    {secondaryLicenseBackUrl ||
                                                                        secondaryLicenseFrontUrl ? (
                                                                        <>
                                                                            <tr>
                                                                                <td
                                                                                    className='text-left label-text'
                                                                                    width='35%'
                                                                                >
                                                                                    <Translate text={'seconadryID'} />
                                                                                </td>
                                                                                <td className='text-left' width='65%'>
                                                                                    <div className='contact-detail-license'>
                                                                                        <div className='license-box'>
                                                                                            {secondaryLicenseFrontUrl ? (
                                                                                                <>
                                                                                                    {
                                                                                                        (isPrivacyEnabled)
                                                                                                            ?
                                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                                            :
                                                                                                            <a
                                                                                                                data-fancybox={`license-box`}
                                                                                                                href={secondaryLicenseFrontUrl}
                                                                                                                onClick={e => {
                                                                                                                    e.preventDefault();
                                                                                                                    this.handleSaveAuditLog(secondaryLicenseFrontUrl);
                                                                                                                }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={secondaryLicenseFrontUrl}
                                                                                                                    alt=''
                                                                                                                    className="img-object-fit"
                                                                                                                />{' '}
                                                                                                            </a>
                                                                                                    }
                                                                                                </>

                                                                                            ) : (
                                                                                                <a
                                                                                                    href='#'
                                                                                                    onClick={e => {
                                                                                                        e.preventDefault();
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={_images.nolicense}
                                                                                                        alt=''
                                                                                                        className="img-object-fit"
                                                                                                    />{' '}
                                                                                                </a>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className='license-box'>
                                                                                            {secondaryLicenseBackUrl ? (
                                                                                                <>
                                                                                                    {
                                                                                                        (isPrivacyEnabled)
                                                                                                            ?
                                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                                            :
                                                                                                            <a
                                                                                                                data-fancybox={`license-box`}
                                                                                                                href={secondaryLicenseBackUrl}
                                                                                                                onClick={e => {
                                                                                                                    e.preventDefault();
                                                                                                                    this.handleSaveAuditLog(secondaryLicenseBackUrl);
                                                                                                                }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={secondaryLicenseBackUrl}
                                                                                                                    alt=''
                                                                                                                    className="img-object-fit"
                                                                                                                />{' '}
                                                                                                            </a>
                                                                                                    }
                                                                                                </>

                                                                                            ) : (
                                                                                                <a
                                                                                                    href='#'
                                                                                                    onClick={e => {
                                                                                                        e.preventDefault();
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={_images.nolicense}
                                                                                                        alt=''
                                                                                                        className="img-object-fit"
                                                                                                    />{' '}
                                                                                                </a>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}

                                                                    {visitingCardURL ? (
                                                                        <>
                                                                            <tr>
                                                                                <td
                                                                                    className='text-left label-text'
                                                                                    width='35%'
                                                                                >
                                                                                    <Translate text={'visitingCardURL'} />
                                                                                </td>
                                                                                <td className='text-left' width='65%'>
                                                                                    <div className='contact-detail-license'>
                                                                                        <div className='license-box'>
                                                                                            {visitingCardURL ? (
                                                                                                <>
                                                                                                    {
                                                                                                        (isPrivacyEnabled)
                                                                                                            ?
                                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                                            :
                                                                                                            <a
                                                                                                                data-fancybox={`visiting-box`}
                                                                                                                href={visitingCardURL}
                                                                                                                onClick={e => {
                                                                                                                    e.preventDefault();
                                                                                                                    this.handleSaveAuditLog(visitingCardURL);
                                                                                                                }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={visitingCardURL}
                                                                                                                    alt=''
                                                                                                                    className="img-object-fit"
                                                                                                                />{' '}
                                                                                                            </a>
                                                                                                    }
                                                                                                </>

                                                                                            ) : (
                                                                                                <a
                                                                                                    href='#'
                                                                                                    onClick={e => {
                                                                                                        e.preventDefault();
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={_images.nolicense}
                                                                                                        alt=''
                                                                                                        className="img-object-fit"
                                                                                                    />{' '}
                                                                                                </a>
                                                                                            )}
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'licenseNo'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {licenseNo ? licenseNo : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'licenseExpiry'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {licenseExpiry ? moment(licenseExpiry).format('DD MMM YYYY') : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'licenseState'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {licenseState ? licenseState : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'licenseType'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {licenseType ? licenseType : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'phones'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {(phones && !_.isEmpty(phones)) ? <>
                                                                                {
                                                                                    phones.map((data, idx) => (
                                                                                        data.value
                                                                                            ?
                                                                                            <div key={idx}>
                                                                                                {data.value}{' [' + data.name + ']'}<br />
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    ))
                                                                                }
                                                                            </> : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'emails'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {(emails && !_.isEmpty(emails)) ? <>
                                                                                {
                                                                                    emails.map((data, idx) => (
                                                                                        data.value
                                                                                            ?
                                                                                            <div key={idx}>
                                                                                                {data.value}{' [' + data.name + ']'}<br />
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    ))
                                                                                }
                                                                            </> : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'nationality'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {nationality ? nationality : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'preferredLanguage'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {language ? language : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'preferredContactMethod'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {contactMethod ? contactMethod : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'driverName'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {driverName ? driverName : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'dependents'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {dependents ? dependents : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'campaign'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {campaign ? campaign : '--'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'origin'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {origin ? origin : '--'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'banLoanVehicle'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {banLoanVehicle ? 'Yes' : 'No'}
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        prevPurchased
                                                                            ?
                                                                            <tr>
                                                                                <td className='text-left label-text'>
                                                                                    <Translate text={'prevPurchased'} />
                                                                                </td>
                                                                                <td className='text-left'>
                                                                                    {prevPurchased}
                                                                                </td>
                                                                            </tr>
                                                                            :
                                                                            <></>
                                                                    }

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'optinPhone'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {optinPhone ? 'Yes' : 'No'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'optinSMS'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {optinSMS === null ? '--' : (optinSMS ? 'Yes' : 'No')}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'optinEmail'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {optinEmail ? 'Yes' : 'No'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'optinPost'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {optinPost ? 'Yes' : 'No'}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'marketingOptinEmail'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {marketingOptinEmail === null ? '--' : (marketingOptinEmail ? 'Yes' : 'No')}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-left label-text'>
                                                                            <Translate text={'marketingOptinSMS'} />
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            {marketingOptinSMS === null ? '--' : (marketingOptinSMS ? 'Yes' : 'No')}
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>

                                                            {!_.isEmpty(company) ? (
                                                                <>
                                                                    <div className="contact-info-section-head"> <Translate text={'Organization'} /></div>
                                                                    <table className='table-fill'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className='text-left label-text'>
                                                                                    <Translate text={'Company name'} />
                                                                                </td>
                                                                                <td className='text-left'>
                                                                                    {company.name ? company.name : '--'}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='text-left label-text'>
                                                                                    <Translate text={'phone'} />
                                                                                </td>
                                                                                <td className='text-left'>
                                                                                    {company.phone ? company.phone : '--'}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='text-left label-text'>
                                                                                    <Translate text={'email'} />
                                                                                </td>
                                                                                <td className='text-left'>
                                                                                    {company.email ? company.email : '--'}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}

                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>
                                </aside>
                            </div>



                        </div>

                    </Modal.Body>
                    <Modal.Footer className={`${this.isLoading ? 'form-disable' : ''}`}>
                        <button
                            type="button"
                            className="btn btn-default float-left"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose();
                            }}
                        ><Translate text={'cancel'} />
                        </button>

                        <button
                            type="button"
                            className="btn btn-primary float-right"
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleSelect(contact);
                            }}
                        >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'select'} />
                        </button>
                    </Modal.Footer>
                </Modal>

                <PopUpModal show={showCAPLeads.show}>
                    <CAPLeads
                        {...this.props}
                        {...showCAPLeads}
                        saveCAPDetails={this.saveCAPDetails}
                        handleClose={this.handleCloseCAPLead}
                        isNewContact={true}
                    />
                </PopUpModal>


            </>

        );
    }

}

