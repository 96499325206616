import React, { useState } from "react";
import moment from "moment";
import _ from 'lodash'
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import ServiceQuickView from "../../service/quickview";
import { SidePanel } from "../../../components";

const ServiceLink = (props) => {
    //const [activity, setActivity] = useState({})    
    //const [checkDataLoad, setDataload] = useState(true)
    //const node = useRef();
    const [showservicepanel, setShowServicePanel] = useState({ clsActive: '', clsName: 'sidebar-service-quickview' })
    const history = useHistory();
    const { selectedService, activityLog, dealersettings } = props;

    const serviceAccess = dealersettings?.rolePermissions?.modules?.service ? true : false;

    const expandLink = () => {
        if (!serviceAccess) return;

        if (props.isFromLayout) {
            const url = `/service/details/${selectedService.documentID}`;
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'You want to view service details.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            }).then(result => {
                if (result.value) {
                    history.push(url);
                    props.handleClose(null, null, 'close');
                }
            });
        }
        else {
            sidepanelServiceOpen(selectedService.documentID)
        }
    }
    const sidepanelServiceClose = (objData) => {
        setShowServicePanel({
            ...showservicepanel,
            clsActive: '',
            serviceid: null
        })
    }

    const sidepanelServiceOpen = (val) => {
        setShowServicePanel({
            ...showservicepanel,
            clsActive: 'active',
            serviceid: val
        })
    }
    return (<>
        <div className='form-readonly-wrap'>
            <div className='input-readonly'>
                {(() => {

                    return activityLog.owner === localStorage.uid ||
                        (activityLog.teammates &&
                            activityLog.teammates.some(
                                item => item === localStorage.uid,
                            )) ? (
                        <div className='input-readonly-close'>
                            <a
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.removeLink('service');
                                }}
                            >
                                <i className='ico icon-remove'></i>{' '}
                            </a>
                        </div>
                    ) : (
                        <></>)
                })()}

                <div
                    className='input-readonly-inner cursor-pointer'
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        expandLink();
                    }}
                >

                    <div className='inner-right'>

                        <div className='input-readonly-head'> <i className="ico icon-service-settings"></i>{selectedService.displayID ? selectedService.displayID : (selectedService.documentID.substring(0, 8))}</div>
                        {
                            selectedService.clientID && (selectedService.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                (<div className='input-readonly-head blue-color'>{CommonHelper.getOtherDealerName(dealersettings, selectedService.clientID)}</div>)
                                : (<></>)
                        }
                        {(!_.isEmpty(selectedService.vehicle) && (!_.isEmpty(selectedService.vehicle.make))) ? (
                            <>
                                <div className='input-readonly-head'>
                                    {selectedService.vehicle.make +
                                        ' ' +
                                        selectedService.vehicle.model}
                                    {
                                        selectedService.vehicle.saleType
                                            ?
                                            <>
                                                <div className="badge badge-pill badge-white ml-1">
                                                    {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.salesType, selectedService.vehicle.saleType, '')}
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </div>
                            </>
                        ) : <>
                            <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(props.dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                        </>}
                        {
                            selectedService.appointmentDate
                                ?
                                <div className="pipeline-item-div w-100">
                                    <i className="ico icon-timer"></i>
                                    {moment(selectedService.appointmentDate).format('DD MMM YYYY h:mm A')}
                                </div>
                                :
                                <></>
                        }

                        {selectedService.status && _.isString(selectedService.status) ? (<div className='input-readonly-sub'>
                            <div
                                className={`badge badge-pill badge-service-${selectedService.status.toLowerCase()}`}
                            >

                                {CommonHelper.getNameByValue(props.dealersettings?.client?.services?.serviceStatus, selectedService.status, selectedService.status)}
                            </div>
                        </div>) : (
                            <></>
                        )}


                    </div>

                </div>

            </div>

            {
                showservicepanel.serviceid
                    ?
                    <>
                        <SidePanel
                            clsActive={showservicepanel.clsActive}
                            clsName={showservicepanel.clsName}
                            sidepanelClose={sidepanelServiceClose}
                            title=''
                        >
                            <ServiceQuickView {...props}
                                serviceid={showservicepanel.serviceid}
                                isHideExpandView={false}
                                isFilterApplied={true}
                                sidepanelClose={sidepanelServiceClose}
                            />
                        </SidePanel>

                    </>
                    :
                    <></>
            }

        </div>
    </>)
}

export default ServiceLink;