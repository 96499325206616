/** LIBRARIES */
import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import _ from 'lodash'
import Swal from 'sweetalert2'
import "react-big-calendar/lib/css/react-big-calendar.css";

import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';

import { serviceStatus } from '../../services/enum';
import CommonHelper from '../../services/common';
import { PopUpModal } from '../../components'
import CustomEvent from './eventView'
import CustomToolbar from './toolbar'
import SlotView from './slotView'
import CancelledList from './cancelledJobs'
import Translate from '../../constants/translate';
import { objService, objSlot } from '../service/viewModel';
import { firestoreDB } from '../../services/helper';
import AppointmentList from '../service/appointment/list'

const localizer = momentLocalizer(moment);



const ServiceCalendar = (props) => {
    const [calendarHeight, setCaleandarHeight] = useState(window.innerHeight - 123)
    const [appointments, setAppointments] = useState([])
    const [appointmentsRO, setAppointmentsRO] = useState([])
    const [events, setEvents] = useState([])
    const [viewstate, setViewState] = useState('month')
    //const [slotDate, setSlotdate] = useState(null)
    const [monthData, setMonthData] = useState(moment()._d.getMonth())
    const [yearData, setYearData] = useState(moment().year())
    const [searchText, setSearchText] = useState('')
    const [showSlotsView, setShowSlotsView] = useState({ show: false, slot: null, date: null });
    const [appointmentModal, setAppointmentModal] = useState({ show: false, status: null, date: null });

    //const [customFilters, setCustomFilters] = useState([])

    const [cancelledCount, setCancelledCount] = useState([])
    const [modelCancelledShow, setModelCancelledShow] = useState(false)

    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month').add(-7, 'day').startOf('day'),
        endDate: moment().endOf('month').add(7, 'day').endOf('day')
    })
    const [loader, setLoader] = useState(true);
    //const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers
    const slots = !_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.services) ? props.dealersettings.client.services.slots : [];

    const enableServiceDailyRO = (props.dealersettings?.client?.clientSettings?.enableServiceDailyRO) ? true : false;
    //console.log(events)
    useEffect(() => {
        function handleResize() {
            setCaleandarHeight(window.innerHeight - 123)
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unrefActivitiesData && window.unrefActivitiesData();
        }
    }, []);

    useEffect(() => {
        if (loader)
            return;
        let _events = [];
        const startDate = moment(dateRange.startDate);
        const endDate = moment(dateRange.endDate);
        for (var m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
            let _slots = _.orderBy(slots.filter(a => a.active), ['from'], ['asc']).map(rec => {
                return {
                    ...rec,
                    services: appointments
                        .filter(a =>
                            a.appointmentDate
                            && a.appointmentDate >= m.startOf('day').format('YYYY-MM-DD HH:mm:ss')
                            && a.appointmentDate <= m.endOf('day').format('YYYY-MM-DD HH:mm:ss')
                            && a.slot
                            && a.slot.value === rec.value
                            && (!_.isEmpty(searchText) ? (a.keywords && a.keywords.some(b => b.toLowerCase() === searchText.toLowerCase())) : true)
                        )
                }
            })
            if (enableServiceDailyRO) {
                //console.log('enableServiceDailyRO', appointmentsRO, m.startOf('day')._d, m.endOf('day')._d)
                _slots.push({
                    active: true,
                    color: "#0071bc",
                    from: "08:00",
                    isExpress: false,
                    level: "oem",
                    name: "Appointments",
                    to: "16:00",
                    total: 5,
                    value: "appointments",
                    services: appointmentsRO
                        .filter(a =>
                            a.appointmentOn
                            && a.appointmentOn >= window.firebase.firestore.Timestamp.fromDate(m.startOf('day')._d)
                            && a.appointmentOn <= window.firebase.firestore.Timestamp.fromDate(m.endOf('day')._d)
                            && (!_.isEmpty(searchText) ? (a.keywords && a.keywords.some(b => b.toLowerCase() === searchText.toLowerCase())) : true)
                        ),
                })
            }

            _events.push({
                start: m.toDate(),
                end: m.toDate(),
                title: 'test',
                slots: _slots
            })
        }
        //console.log('_events', _events)
        setEvents(_events)
    }, [appointments, searchText])

    useEffect(() => {
        if (!props.dealersettings)
            return;
        let clientID = props.dealersettings.client.id
        const clientUserSnapshot = firestoreDB(props.dealersettings).firestore().collection('serviceJobs')
            .where('clientID', '==', clientID)
            .where('status', '==', serviceStatus.CANCELLED)
            .where('isDeleted', '==', false)

        window.unSubRejectLogsList = clientUserSnapshot
            .onSnapshot(onRejectedCollectionUpdate);

        return () => {
            window.unSubRejectLogsList();
        }
    }, [])

    const onRejectedCollectionUpdate = (querySnapshot) => {
        let cancelledCount = [];
        querySnapshot.forEach((docSnapshot) => {
            cancelledCount.push(docSnapshot.id);
        });
        setCancelledCount(cancelledCount)
    }

    useEffect(() => {
        if (!props.dealersettings) {
            //console.log('!props.dealersettings')
            return;
        }
        const startDate = dateRange.startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = dateRange.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('serviceJobs')
            .where('appointmentDate', '>=', startDate)
            .where('appointmentDate', '<=', endDate)
            .where('isDeleted', '==', false)
            .where('clientID', '==', props.dealersettings.client.id)


        let tipNotes = ''
        if (enableServiceDailyRO) {
            const _startDate = window.firebase.firestore.Timestamp.fromDate(moment(dateRange.startDate).startOf('day')._d);
            const _endDate = window.firebase.firestore.Timestamp.fromDate(moment(dateRange.endDate).endOf('day')._d);
            tipNotes = firestoreDB(props.dealersettings).firestore().collection(`serviceDailyRO`)
                .where('appointmentOn', '>=', _startDate)
                .where('appointmentOn', '<=', _endDate)
                .where('isDeleted', '==', false)
                .where('clientID', '==', props.dealersettings.client.id)
        }


        var activitiesCollection = combineLatest(
            collection(refActivitiesData),
            tipNotes ? collection(tipNotes) : of([]),
        ).pipe(
            map(([appoitments, appointmentsRO]) => {
                return [
                    _.map(appoitments, function (obj) {
                        return {
                            ...obj.data(),
                            documentID: obj.id,
                            //startDate: (_.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate),
                            //owner: (!_.isEmpty(obj.data().owner) ? obj.data().owner : obj.data().addedBy)
                        }
                    }),
                    _.map(appointmentsRO, function (obj) {
                        return {
                            ...obj.data()
                        }
                    }),

                ]
            }))
            .subscribe(async ([appoitments, appointmentsRO]) => {
                //console.log(appoitments, appointmentsRO)
                setLoader(false)
                setAppointmentsRO(appointmentsRO);
                setAppointments(appoitments);


            });
        return () => {
            activitiesCollection && activitiesCollection.unsubscribe()
        }

    }, [monthData])

    // useEffect(() => {
    //     if (!props.dealersettings) {
    //         //console.log('!props.dealersettings')
    //         return;
    //     }
    //     // const { dealersettings } = props;
    //     // const viewOtherActivity = ((!_.isEmpty(dealersettings) &&
    //     //     !_.isEmpty(dealersettings.rolePermissions) &&
    //     //     !_.isEmpty(dealersettings.rolePermissions.permissions) &&
    //     //     dealersettings.rolePermissions.permissions.viewOtherActivity) ? true : false);
    //     const startDate = dateRange.startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    //     const endDate = dateRange.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

    //     //console.log('isFilterApplied-not', isFilterApplied)
    //     let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('serviceJobs')
    //         //.where('appointmentDate', '>=', window.firebase.firestore.Timestamp.fromDate(dateRange.startDate._d))
    //         //.where('appointmentDate', '<=', window.firebase.firestore.Timestamp.fromDate(dateRange.endDate._d))
    //         .where('appointmentDate', '>=', startDate)
    //         .where('appointmentDate', '<=', endDate)
    //         .where('isDeleted', '==', false)
    //         .where('clientID', '==', props.dealersettings.client.id)


    //     // if (localStorage.defaultModule === 'oem') {
    //     //     // refActivitiesData = refActivitiesData
    //     //     //     .where('clientID', '==', props.dealersettings.client.id)
    //     //     //     .where('isDeleted', '==', false)
    //     //     refActivitiesData = refActivitiesData
    //     //         .where('levelID', '==', (props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
    //     //             props.dealersettings.level === 'region' ? props.dealersettings.regionID :
    //     //                 props.dealersettings.level === 'group' ? props.dealersettings.groupID :
    //     //                     props.dealersettings.client.id))
    //     //     //.where('level', '==', props.dealersettings.level)
    //     // }
    //     // else {
    //     //     refActivitiesData = refActivitiesData
    //     //         .where('clientID', '==', props.dealersettings.client.id)
    //     //         .where('isDeleted', '==', false)
    //     // }


    //     // if (!viewOtherActivity) {
    //     //     refActivitiesData = refActivitiesData
    //     //         .where('searchUsers', 'array-contains', localStorage.uid)
    //     // }
    //     // else if (!_.isEmpty(searchOwners))
    //     //     refActivitiesData = refActivitiesData.where('searchUsers', 'array-contains', searchOwners.split(','))

    //     window.unrefActivitiesData = refActivitiesData
    //         .onSnapshot(onCollectionUpdate);




    // }, [monthData])

    // const onCollectionUpdate = (querySnapshot) => {
    //     const _appoitments = [];
    //     querySnapshot.forEach(function (doc) {
    //         //var logVM = convertLogVM(doc.data)
    //         _appoitments.push(doc.data())
    //     });
    //     console.log('appoitments,', _appoitments)
    //     setLoader(false)
    //     setAppointments(_appoitments);
    // }

    //#region SERVICE
    const loadNewService = (_appDate, _appSlot) => {

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, `You want to create new appointment.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {
                saveNewService(_appDate, _appSlot);
            }
        });

    }

    const saveNewService = async (_appDate, _appSlot, _dailyRO) => {
        const { dealersettings } = props;

        const _clientService = (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            !_.isEmpty(dealersettings.client.clientSettings.service)) ? dealersettings.client.clientSettings.service : null;

        const _taxType = (!_.isEmpty(_clientService) && _clientService.taxType) ? _clientService.taxType : null;
        const _taxPercentage = (!_.isEmpty(_clientService) && _clientService.tax) ? _clientService.tax : 0;
        const _internalTaxExemption = (!_.isEmpty(_clientService) && _clientService.internalTaxExemption) ? true : false;
        const setttings = dealersettings?.client?.services;

        let state = Object.assign({}, objService);
        if (_.isEmpty(state.addedBy)) {
            state.addedBy = dealersettings ? dealersettings.id : '';
            state.addedDate = window.firebase.firestore.Timestamp.now();
            state.owner = dealersettings ? dealersettings.id : '';
        }
        state.modifiedBy = dealersettings ? dealersettings.id : '';
        state.modifiedDate = window.firebase.firestore.Timestamp.now();
        //state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings)
        state.clientID = dealersettings ? dealersettings.client.id : '';
        state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
        state.status = serviceStatus.NEWSERVICE;
        state.internalTaxExemption = _internalTaxExemption;

        state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);

        if (_taxType && _taxPercentage) {
            state.taxType = _taxType;
            state.taxPercentage = _taxPercentage;
        }

        if (_appDate)
            state.appointmentDate = moment(_appDate).format('YYYY-MM-DD HH:mm:ss')

        if (!_.isEmpty(_appSlot)) {
            let slot = Object.assign({}, objSlot)
            for (let [key, value] of Object.entries(_appSlot)) {
                if (slot.hasOwnProperty(key))
                    slot[key] = value;
            }
            state.slot = slot;
        }

        if (!_.isEmpty(_dailyRO)) {

            const _allPipelines = dealersettings?.client?.services?.pipelines;
            const selectOptions = !_.isEmpty(_allPipelines) && _allPipelines.length > 1 ? _allPipelines.reduce((options, { name, value }) => {
                options[value] = name;
                return options;
            }, {}) : null;

            let selectedPipeline;
            if (!_.isEmpty(selectOptions)) {
                const { value: _selectedPipeline } = await Swal.fire({
                    title: 'Select Pipeline to Continue',
                    input: 'select',
                    inputOptions: selectOptions,
                    inputPlaceholder: 'select pipeline',
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Cancel',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to select an pipeline';
                        }
                    }
                });
                selectedPipeline = _selectedPipeline
            }


            state.dailyroID = _dailyRO.documentID;

            if (!_.isEmpty(_dailyRO.contact)) state.engageNewContact = _dailyRO.contact;
            if (!_.isEmpty(_dailyRO.vehicle)) state.vehicle = _dailyRO.vehicle;
            if (!_.isEmpty(_dailyRO.serviceTime)) state.timeOfDay = _dailyRO.serviceTime;

            if (_dailyRO.serviceDate && _dailyRO.serviceTime)
                state.appointmentDate = _dailyRO.serviceDate + ' ' + _dailyRO.serviceTime + ':00';
            else if (_dailyRO.appointmentOn) state.appointmentDate = moment.unix(_dailyRO.appointmentOn.seconds).format('YYYY-MM-DD HH:mm:ss');

            let _selectedSlot = await CommonHelper.getServiceSlotByTimeOfDay(state.appointmentDate, _dailyRO.serviceTime, setttings.slots, dealersettings);
            if (!_.isEmpty(_selectedSlot)) state.slot = _selectedSlot;

            state.status = serviceStatus.CHECKIN;

            if (!_.isEmpty(setttings)) {
                let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
                if (_.isEmpty(_selPipeline))
                    _selPipeline = Object.assign({}, setttings.pipelines[0]);
                if (selectedPipeline) _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.value === selectedPipeline)[0]);
                else if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline)) {
                    _selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline);
                }

                let statusSettings = _selPipeline && _selPipeline.statusSettings;

                let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === state.status)[0];
                if (!_.isEmpty(data) && data.pipeline && data.stage) {
                    state.pipeline = data.pipeline;
                    state.stage = data.stage;
                }
                else {
                    let _stages = Array.prototype.map.call(_selPipeline.stages, function (item) { return item.value; }).join(",").split(',');
                    state.pipeline = _selPipeline.value;
                    state.stage = _stages[0];
                }

                state.stageDate = window.firebase.firestore.Timestamp.now();
                state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
                state.statusHistory = Object.assign([], [{ date: state.stageDate, status: state.status, user: localStorage.uid }]);
            }
        }

        state.isDeleted = false;

        const objData = Object.assign({}, state);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!objData.documentID)
            objData.documentID = firestoreDB(props.dealersettings).firestore().collection('serviceJobs').doc().id;

        const refReqData = firestoreDB(props.dealersettings).firestore().collection('serviceJobs').doc(objData.documentID);
        refReqData.set(objData, { merge: true }).then((docRef) => {
            let documentID = objData.documentID;

            const _client = objData.clientID && !_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.group) &&
                !_.isEmpty(dealersettings.group.clients) &&
                dealersettings.group.clients[objData.clientID] ? dealersettings.group.clients[objData.clientID] : dealersettings.client;

            if (!_.isEmpty(_client) && !_.isEmpty(_client.services) && !_.isEmpty(_client.services.recommendations)) {

                const labourCost = (!_.isEmpty(_clientService) && _clientService.labourCost) ? _clientService.labourCost : 0;
                const _categoriesList = Object.assign([], setttings && setttings.categories);
                const batch = firestoreDB(dealersettings).firestore().batch();
                _client.services.recommendations.filter(a => a.active === true).forEach(data => {
                    if ((data.type === 'checklist' && _categoriesList.some(e => e.value === data.category && e.active === true)) || data.type !== 'checklist') {
                        if (data.title) {
                            data.name = data.title;
                            delete data['title'];
                        }
                        data.documentID = firestoreDB(props.dealersettings).firestore().collection('serviceJobs').doc().id;
                        data.serviceID = documentID;
                        data.clientID = dealersettings ? dealersettings.client.id : '';
                        data.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                        data.detail = Object.assign({}, {
                            option: 'customer',
                            status: 'urgent',
                            labourCost: labourCost ? labourCost : 0,
                            oKToDo: true,
                        })
                        batch.set(firestoreDB(dealersettings).firestore().doc(`serviceJobs/${documentID}/recommendations/${data.documentID}`), data, { merge: true });
                    }
                })

                if (objData.dailyroID) {
                    batch.set(firestoreDB(dealersettings).firestore().doc(`serviceDailyRO/${objData.dailyroID}`), {
                        status: 1,
                        serviceID: documentID,
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedFrom: 'web'
                    }, { merge: true });
                    CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, `Job created from inbound. \n (ref#: ${_dailyRO.refID})`, 'service', 'updated', batch);
                }

                batch.commit().then(snapshot => {
                    props.history.push("/service/details/" + documentID + '?back-url=/appointment');
                }).catch((error) => {
                    console.error("Error updating document: ", error);
                });
            }
            else {

                if (objData.dailyroID) {
                    firestoreDB(dealersettings).firestore().doc(`serviceDailyRO/${objData.dailyroID}`).set({
                        status: 1,
                        serviceID: documentID,
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedFrom: 'web'
                    }, { merge: true });
                    CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, `Job created from inbound. \n (ref#: ${_dailyRO.refID})`, 'service', 'updated');
                }

                props.history.push("/service/details/" + documentID + '?back-url=/appointment');
            }

        }).catch((error) => {
            console.error("Error adding enquiries: ", error);
        });



    }



    //#endregion

    const isValidFilter = () => {
        if (localStorage.activityFilter) {
            let _activityFilter = JSON.parse(localStorage.activityFilter);
            let _filter = Object.assign({}, _activityFilter.value);

            // if (Object.keys(_filter).length === 3 && (_filter.hasOwnProperty('subType') && _filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
            //     return false;

            if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('subType') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
                return false;

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
            //     return false;

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('subType') && _filter.hasOwnProperty('keywords')))
            //     return false;

            if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('subType') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    const onSelectEvent = useCallback(async (eventData, selectedDate) => {
        //console.log('onSelectEvent', eventData, selectedDate);
        if (eventData?.value === 'appointments')
            setAppointmentModal({ show: true, status: serviceStatus.NEWSERVICE, date: moment(selectedDate).format('YYYY-MM-DD') })
        else
            setShowSlotsView({ show: true, slot: eventData, date: moment(selectedDate).format('YYYY-MM-DD') })
    })

    const handleRejectClose = (id) => {
        setModelCancelledShow(false);

    }

    const handleRejectShow = (id) => {
        setModelCancelledShow(true);
    }

    const handleAppointmentClose = (_status, _data) => {
        if (!_.isEmpty(_data)) {
            saveNewService(null, null, _data);
        }
        setAppointmentModal({ show: false, status: null, date: null })
    }

    return (
        <>
            <div className="middle-wrapper">
                {/* <FilterPanel
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleAddActivity={(e) => { e.preventDefault(); }}
            /> */}
                <div className="calendar-panel">
                    {
                        loader
                            ?
                            <>
                                <div className="dashboard-widget-loader h-100">
                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only"><Translate text={'Loading...'} /></span>
                                        </div>
                                    </div>

                                </div>
                            </>
                            :
                            <>
                            </>
                    }
                    <Calendar
                        popup={false}
                        localizer={localizer}
                        events={events}
                        style={{ height: calendarHeight }}
                        views={['month']}
                        components={{
                            toolbar: props => (<CustomToolbar {...props}
                                viewstate={viewstate}
                                handlechangemonth={(month) => setMonthData(month)}
                                handlechangeyear={(year) => setYearData(year)}
                                handlesearchText={(val) => {
                                    setSearchText(val)
                                }}
                                searchText={searchText}
                                monthData={monthData}
                                setDateRange={(dateRange) => {
                                    setDateRange(dateRange)
                                }}
                                handleAddActivity={(e) => { e.preventDefault(); loadNewService(); }}
                                dealersettings={props.dealersettings}
                                handleRejectShow={handleRejectShow}
                                cancelledCount={cancelledCount}
                            />),
                            eventWrapper: props => (<CustomEvent {...props}
                                onSelectEvent={onSelectEvent}
                                searchText={searchText}
                                viewstate={viewstate} />)
                        }}
                    />
                </div>
            </div>

            <PopUpModal show={showSlotsView.show}>
                <SlotView
                    show={showSlotsView.show}
                    slot={showSlotsView.slot}
                    date={showSlotsView.date}
                    handleClose={() => { setShowSlotsView({ show: false, slot: null, date: null }); }}
                    loadNewService={loadNewService}
                    dealersettings={props.dealersettings}
                    groupUsers={props.groupUsers}
                    clientUsers={props.clientUsers}
                    history={props.history}
                />
            </PopUpModal>

            <PopUpModal show={modelCancelledShow}>
                <CancelledList
                    show={modelCancelledShow}
                    handleClose={handleRejectClose}
                    dealersettings={props.dealersettings}
                    groupUsers={props.groupUsers}
                    clientUsers={props.clientUsers}
                    cancelledCount={cancelledCount.length}
                    history={props.history}
                >
                </CancelledList>
            </PopUpModal>

            <PopUpModal show={appointmentModal.show}>
                <AppointmentList
                    {...props}
                    show={appointmentModal.show}
                    status={appointmentModal.status}
                    appDate={appointmentModal.date}
                    handleClose={handleAppointmentClose}
                    title={'Appointments'}
                    hideContinue={true}
                />
            </PopUpModal>
        </>
    )
}

export default ServiceCalendar;