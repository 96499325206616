/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { InputTextArea } from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import MentionEditor from '../../../components/mentionEditor/mentionEditor';

export default class AddNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields:
            {
                notes: '',
                isInternalNotes: false,
            },
            errors: {},
            mentions: [],
            teamMentions: null
        }
    }

    loadEnquiry = () => {
        const { notes } = this.props;
        this.setState({
            fields:
            {
                notes: notes,
                isInternalNotes: false,
            },
            errors: {}
        });
    }

    componentDidMount() {
        this.loadEnquiry();
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    onMentionChange = notesText => {
        // if (notesText)
        // notesText = notesText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        const e = {
            target: {
                name: 'notes',
                value: notesText,
            },
        };

        // this.handleOnAreaChange(e, true);
        const fields = Object.assign({}, this.state.fields);
        fields.notes = notesText
        this.setState({ fields: fields });

    };

    onMentionSelect = (option, ownerID) => {

        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            let idToPass = id
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            this.setState({
                teamMentions: idToPass
            })
        }

        // Check to avoid adding duplicate entries in mention
        const check = this.state.mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            this.setState({
                mentions: [...this.state.mentions, { name, id: idToPass }]
            })
        }
    };

    clearTeamMentions = () => {
        this.setState({
            teamMentions: null
        })
    }

    handleCheckChange = (e, name) => {

        const { checked } = e.target;
        let state = this.state.fields;

        state[name] = checked;
        this.setState({ fields: state });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key] && key !== 'isInternalNotes') {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.props.handleClose(fields.notes, this.state.mentions, fields.isInternalNotes);
        }
        else
            return formIsValid;

    };

    handleCancel = (e) => {
        e.preventDefault();
        //this.loadEnquiry();
        let fields = Object.assign({}, this.state.fields);
        fields.notes = null;
        this.setState({ fields: fields }, () => { this.errorChange('notes'); });

    }


    render() {
        const { notes, isInternalNotes } = this.state.fields;
        const { show, title, isDisable } = this.props;


        return (
            <>
                <Modal
                    id="add-notes"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-calllogs`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={title ? title : 'Add Note'} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                {/* <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <InputTextArea
                                                className={`form-control notes ${this.state.errors["notes"]}`}
                                                name="notes"
                                                autoFocus={true}
                                                onChange={this.handleOnChange}
                                                value={notes}
                                                maxLength="500"
                                                rows="3">
                                            </InputTextArea>
                                        </div>
                                    </div> */}
                                <div className="pop-layout form-style">
                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <MentionEditor
                                                mentionsData={[]}
                                                onMentionChange={this.onMentionChange}
                                                onMentionSelect={this.onMentionSelect}
                                                notes={notes ? notes : ''}
                                                focusOnMount={false}
                                                isNoteEmpty={this.state.errors.notes === 'input_error' && _.isEmpty(notes) ? true : false}
                                                teamMentions={this.state.teamMentions}
                                                clearTeamMentions={this.clearTeamMentions}
                                                clientUsers={[]}
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <div className="checkbox icheck-success">
                                                <input
                                                    type="checkbox"
                                                    id={'note_isInternalNotes'}
                                                    name={'note_isInternalNotes'}
                                                    className="uncheck-activity"
                                                    checked={isInternalNotes}
                                                    onChange={(e) => {
                                                        this.handleCheckChange(e, 'isInternalNotes')
                                                    }} />
                                                <label htmlFor={'note_isInternalNotes'}><Translate text={'internalNote'} /></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">
                        <button type="button"
                            className={`btn btn-primary float-right ml-2`}
                            onClick={(e) => this.handleSubmit(e)}><Translate text={'done'} /></button>
                        <button type="button" className="btn btn-default float-right"
                            onClick={(e) => { e.preventDefault(); this.handleCancel(e) }}><Translate text={'clear'} /></button>

                    </Modal.Footer>
                </Modal>


            </>
        );
    }
}
