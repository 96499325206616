import React, { useState } from "react";
import moment from "moment/moment";
import { firestoreDB } from "../../../services/helper";
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import Swal from 'sweetalert2'
import Translate from "../../../constants/translate";

import CommonHelper from '../../../services/common';

const CAPLeads = (props) => {
    const [searchText, setSearchText] = useState('')
    const [linkLoader, setLinkLoader] = useState({})


    const linkCAPLead = async (lead) => {
        const { contact, dealersettings, isNewContact, inbound } = props;
        setLinkLoader({ [lead.capID]: true });
        if (!isNewContact && contact?.documentID) {
            await firestoreDB(dealersettings)
                .firestore()
                .doc(`contactDetails/${contact.documentID}`)
                .set(contact, { merge: true });
        }

        if (inbound && lead?.capID) {
            const enqDoc = await firestoreDB(dealersettings).firestore().collection(`enquiries`)
                .where('capID', '==', lead?.capID)
                .where('clientID', '==', dealersettings.client.id)
                .where('isDeleted', '==', false).limit(1).get();
            if (enqDoc.size > 0) {
                let _snapshotDoc = { ...enqDoc.docs[0].data(), documentID: enqDoc.docs[0].id };
                capExistShow(_snapshotDoc);
            }
            else {
                saveCapToEnquiry(lead);
            }
        }
        else {
            saveCapToEnquiry(lead);
        }
    }

    const saveCapToEnquiry = async (lead) => {
        //console.log('saveCapToEnquiry', lead)
        const { contact, dealersettings, enquiryID, capTags } = props;
        if (enquiryID) {
            const _tags = capTags || [];
            _tags.push({
                type: 'cap',
                refID: lead?.capID || null
            })
            await firestoreDB(dealersettings)
                .firestore()
                .doc(`enquiries/${enquiryID}`)
                .set({
                    projectId: dealersettings?.client?.projectId || null,
                    capGuid: lead?.capGuid || null,
                    capID: lead?.capID || null,
                    capDt: moment().utc().toISOString(),
                    tags: _.uniqBy(_tags, 'type'),
                    dmsDate: lead?.dmsDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(lead?.dmsDate?._seconds)._d) : null
                }, { merge: true });
        }

        props.handleClose(contact);
    }

    const capExistShow = (_enquiry) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, `Enquiry already Exists in AMS Pro (${_enquiry.displayID})`),
            text: CommonHelper.showLocale(props, 'Do you want Link?.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                props.handleClose(props.contact, true, _enquiry);
            }
            else {
                setLinkLoader({});
            }
        })
        return
    }

    return (
        <Modal
            id="cap_Enquiries_List"
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-terms">

            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className="modal-title" ><Translate text={props.title} /></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <>
                    <div className="vehicle-popover-head multilead-head">

                        <div className="multilead-addbutton">
                            <button type="button"
                                className={`btn btn-secondary float-right ${!_.isEmpty(linkLoader) ? 'form-disable' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.saveCAPDetails(props.contact)
                                }}>
                                <i className="ico icon-close"></i> <Translate text={'Continue with current enquiry'} /></button>
                        </div>


                        <input
                            type="text"
                            id="searchText"
                            aria-label="Search"
                            placeholder="Search..."
                            value={searchText}
                            autoComplete="off"
                            onChange={event =>
                                setSearchText(event.target.value)
                            }
                        />
                    </div>
                    <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                        {
                            props.leads.filter(a => searchText && a.description ? a.description.toLowerCase().includes(searchText.toLowerCase()) : true).map((rec, index) => {
                                return <div key={index} className="popup-vehicleinfo-customloop">
                                    <div className="divTable">
                                        <div className="divTableBody">
                                            <div className="divTableRow">
                                                <div className="divTableCell width100">
                                                    <div className="customer-avatar-content">
                                                        <div className="popup-vehicleinfo-icon-circle"> <i className="ico icon-enquiry"></i> </div>
                                                        <div className="popup-vehicleinfo-title">{rec.description}</div>
                                                        {
                                                            rec?.modifiedDate?._seconds ? (
                                                                <div className="popup-vehicleinfo-sub"><i className="ico icon-date mr-1"></i>Modified Date: {moment.unix(rec.modifiedDate._seconds).format('DD/MM/YYYY hh:mm A')} </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            rec?.statusdt?._seconds ? (
                                                                <div className="popup-vehicleinfo-sub"><i className="ico icon-date mr-1"></i>Status Since: {moment.unix(rec.statusdt._seconds).format('DD/MM/YYYY hh:mm A')} </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            rec?.status ? (
                                                                <div className="popup-vehicleinfo-item-status">
                                                                    <span className={`status-${rec.status.toLowerCase()} float-left`} >{rec.status}</span>
                                                                </div>
                                                            ) : (<></>)
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popup-vehicleinfo-minibuttons pl-2 pr-2 mt-0">
                                        <div className="border-multilead">
                                            <button type="button"
                                                className={`btn btn-green float-right ${!_.isEmpty(linkLoader) ? 'form-disable' : ''}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    linkCAPLead(rec);
                                                }}>
                                                {
                                                    linkLoader[rec.capID] ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                Link</button></div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </>
            </Modal.Body>
        </Modal>
    )
}

export default CAPLeads