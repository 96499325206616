import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { leadBucketCountVM } from './viewModel'
import Translate from '../../constants/translate';
import { combineLatest } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { firestoreDB } from '../../services/helper';
import { map } from 'rxjs/operators';
import NewTestDrive from '../testdrive/new';
import EnquiryModal from '../pipeline/details/enquiryModal'
import { PopUpModal } from '../../components';
import { RouteConsumer } from '../layout/RouteProvider';

const TestDriveInbound = (props) => {
    const [counts, setCounts] = useState(leadBucketCountVM)
    const [loader, setLoader] = useState(true);
    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const [showTestDrive, setshowTestDrive] = useState({ show: false })
    const [showEnquiryModal, setshowEnquiryModal] = useState({
        show: false,
        enqID: null,
        title: '',
        isNewEnquiry: null
    })

    useEffect(() => {
        return () => {
            window.unsubtestdriveinboundSnap && window.unsubtestdriveinboundSnap.unsubscribe();
        }
    }, []);


    useEffect(() => {
        setLoader(true)
        window.unsubtestdriveinboundSnap = combineLatest(
            collection(firestoreDB(props.dealersettings).firestore().collection(`inboundTestdrive`)
                .where('clientID', '==', props.dealersettings.client.id)
                .where('status', '==', 0)
                .where('isDeleted', '==', false)),
            collection(firestoreDB(props.dealersettings).firestore().collection(`inboundTestdrive`)
                .where('clientID', '==', props.dealersettings.client.id)
                .where('status', '==', 1)
                .where('isDeleted', '==', false)
                .where('convertedDate', '>=', monthStart)
                .where('convertedDate', '<=', monthEnd)
            ),
        ).pipe(
            map(([pendinBucket, convertedLeads]) => {
                return [
                    _.map(pendinBucket, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(convertedLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } })
                ]
            }))
            .subscribe(allDocs => {
                const todayLeads = allDocs[1].filter(a => a.convertedDate.seconds >= todayStart.seconds
                    && a.convertedDate.seconds <= todayEnd.seconds);
                const mtdLeads = allDocs[1].filter(a => a.convertedDate.seconds >= monthStart.seconds
                    && a.convertedDate.seconds <= monthEnd.seconds);
                setCounts({
                    pendingInboundleads: allDocs[0],
                    pendingInboundCount: allDocs[0].length,
                    todayConvertedleads: todayLeads,
                    todayConverted: todayLeads.length,
                    monthConvertedleads: mtdLeads,
                    monthConverted: mtdLeads.length
                })
            })

    }, [])

    const handleNewTestDriveClose = (_enqID, isNew) => {
        console.log('handleNewTestDriveClose', _enqID, isNew)
        if (_enqID) {
            setshowEnquiryModal({
                show: true,
                enqID: _enqID,
                title: isNew ? 'addNewEnquiry' : 'Enquiry',
                isNewEnquiry: isNew
            });
        }
        setshowTestDrive({ show: false })
    };

    const handleEnquiryClose = () => {
        setshowEnquiryModal({
            show: false,
            enqID: null,
            title: '',
            isNewEnquiry: null
        });
    };

    return (<>
        <div className="dashboard-box draggable-section">
            <div className="row" style={{ minHeight: `165px` }}>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="dashboard-allocated-wrap ">
                                <h3><Translate text={'Test Drive Request'} /></h3>
                                <div className="dashboard-module-count-wraper dashboard-cell-bg">
                                    <div className=" row">
                                        {
                                            props.applydateRange === false ? (
                                                <div className="col-lg-6" style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (counts.todayConverted) {
                                                        props.handleShowEnquiryList({
                                                            documentIDs: counts.todayConvertedleads.map(r => { return r.enquiryID })
                                                        }, 'Today Test Drive Leads', counts.todayConverted)
                                                    }
                                                }}>
                                                    <div className="dashboard-count-title"> Today</div>
                                                    <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.todayConverted}</a></div>
                                                </div>) : (<></>)
                                        }
                                        <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                            if (counts.monthConverted) {
                                                props.handleShowEnquiryList({

                                                    documentIDs: counts.monthConvertedleads.map(r => { return r.enquiryID })
                                                }, `MTD Test Drive`, counts.monthConverted)
                                            }
                                        }}>
                                            <div className="dashboard-count-title"> MTD</div>
                                            <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.monthConverted}</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12" style={{ cursor: 'pointer' }} onClick={() => {
                            if (counts.pendingInboundleads) {
                                setshowTestDrive({ show: true })
                            }
                        }}>
                            <div className="dashboard-Pending-wrap"><span className="dashboard-Pending-txt"><Translate text={'Pending'} /></span> <span className="float-right"><h2>{counts.pendingInboundCount}</h2></span></div>
                        </div>
                    </div >
                </div >
            </div >
        </div >
        <PopUpModal show={showTestDrive.show}>
            <NewTestDrive
                {...props}
                {...showTestDrive}
                handleClose={handleNewTestDriveClose}
                isFromLayout={true}
                tdInboundList={counts.pendingInboundleads}
            />
        </PopUpModal>


        <RouteConsumer>
            {({ handleRouteRemove, resetnavbar, handleRouteChange, updatenavbar }) => (
                <>
                    <PopUpModal show={showEnquiryModal.show}>
                        <EnquiryModal
                            {...props}
                            show={showEnquiryModal.show}
                            handleClose={handleEnquiryClose}
                            title={showEnquiryModal.title}
                            enquiryid={showEnquiryModal.enqID}
                            isNewEnquiry={showEnquiryModal.isNewEnquiry}
                            handleRouteChange={handleRouteChange}
                            resetnavbar={resetnavbar}
                            updatenavbar={updatenavbar}
                            handleRouteRemove={handleRouteRemove}

                        ></EnquiryModal>
                    </PopUpModal>
                </>
            )}
        </RouteConsumer>


    </>);
}

export default TestDriveInbound;