import React, { useState } from 'react'
import _images from '../images'

const ForceUpdateAlert = (props) => {
    const [show, setShow] = useState(props.show)
    return (
        <div className={`fullscreen-alert text-center ${show ? '' : 'hide'}`}>
            <div className="fullscreen-alert-inner ">
                <div className="fullscreen-alert-box">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="fullscreen-alert-popup">
                                <div className="icon-placeholder"><img src={_images.deployment} alt="" />
                                </div>
                                <h2>Settings has been updated.</h2>
                                <p className="fullscreen-alert-box-info">Please click on update to apply the latest changes.</p>
                                <div className="fullscreen-alert-footer text-center">
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        localStorage.setItem('deploymentUpdatedOn', window.firebase.firestore.Timestamp.now().seconds)
                                        setShow(false)
                                        window.location.reload(true)
                                    }}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForceUpdateAlert;