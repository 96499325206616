/** LIBRARIES */
import React, { Component } from "react";
//import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from 'moment';
// import NumberFormat from 'react-number-format';

class AntDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.value !== this.props.value;
    // }
    //onDatepickerRef = (el) => { if (el && el.input) { el.input.readOnly = true; } }
    render() {
        const { name, onChange, format, placeholder, value, className, readOnly, disabledDate, disableContainer, allowClear, picker,
            defaultPickerValue } = this.props;
        return (
            <DatePicker
                //ref={el => this.onDatepickerRef(el)}
                getPopupContainer={disableContainer ? null : (triggerNode => triggerNode.parentNode)}
                id={name}
                value={value ? moment(moment(value)._d) : null}
                onChange={onChange}
                className={className}
                format={format}
                placeholder={placeholder}
                showToday={false}
                disabled={readOnly}
                disabledDate={disabledDate}
                allowClear={allowClear === false ? false : true}
                picker={picker ? picker : 'date'}
                defaultPickerValue={defaultPickerValue? moment(moment(defaultPickerValue)._d): null}
            />
        );
    }
}

export default AntDatePicker;