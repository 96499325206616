/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
/** COMPONENTS */
import { default as _images } from '../../images';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common'
import { serviceStatus } from '../../services/enum'


const EnquiryInfo = ({ service, clientUsers, sidepanelOpen, history, showMore, dealersettings, companyView, groupUsers, isList }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();

    const { documentID, status, vehicle, isVehicle, addedBy, displayID, owner, dealerName, ownerResponse, nextService, appointmentDate, contact } = service;

    const _jobStatus = status && status === serviceStatus.OWNERRESPONDED ? ownerResponse : status;
    const serviceAllStatus = Object.assign([], dealersettings?.client?.services?.serviceStatus)
    const technicianAllStatus = Object.assign([], dealersettings?.client?.services?.technicianStatus)
    let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0]
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };
    return (
        <div className={isList ? '' : `vehicle-item`} id={'info_' + documentID} onClick={(e) => {
            e.preventDefault();
            if (!isList) sidepanelOpen(documentID);
        }}>
            <div className="flex-vehicle">
                {
                    showMore
                        ?
                        <div className="quickview-activity-more">
                            <a href="#" onClick={(e) => {
                                e.stopPropagation();
                                setEditshow(!editshow)
                                if (editshow)
                                    document.addEventListener('click', setEditshow(false));
                            }}><i className="ico icon-more"></i></a>
                            <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                <ul className="more-dropdown">
                                    <li> <a href="#" onClick={(e) => {
                                        history.push("/service/details/" + documentID);
                                    }}> <i className="ico icon-expand"></i>  <Translate text={'view'} /></a> </li>
                                </ul>
                            </div>
                        </div>
                        :
                        <></>
                }


                {/* <div className="vehicle-item-image">
                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                        <img src={CommonHelper.showBrandLogo(dealersettings, make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                    </a>
                </div> */}

                <div className="vehicle-data">
                    {(() => {

                        if (isVehicle && !_.isEmpty(vehicle)) {
                            if (!_.isEmpty(vehicle.stock)) {
                                return (
                                    <>
                                        <div className="vehicle-item-title mini-badge" >
                                            {(vehicle.stock.make ? vehicle.stock.make : '') + ' ' + (vehicle.stock.model ? vehicle.stock.model : '')}
                                            {vehicle.saleType ? (<div className="badge badge-pill badge-white ml-1">
                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                    vehicle.saleType, '')}
                                            </div>) : ''}
                                        </div>
                                    </>
                                )
                            }
                            else {
                                return (
                                    <>
                                        <div className="vehicle-item-title mini-badge">
                                            {(vehicle.make ? vehicle.make : '') + ' ' + (vehicle.model ? vehicle.model : '')}
                                            {vehicle.saleType ? (<div className="badge badge-pill badge-white ml-1">
                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                    vehicle.saleType, '')}
                                            </div>) : ''}
                                        </div>
                                    </>
                                )
                            }


                        }
                        else {
                            return <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                        }
                    })()}
                    {
                        displayID
                            ?
                            <>
                                <>
                                    <div className="vehicle-item-title mini-badge">
                                        <i className="ico icon-service-settings"></i> {displayID}
                                    </div>
                                </>
                            </>
                            :
                            <></>
                    }



                    {dealerName ? <div className="dealer-group-title blue-color">{dealerName}</div> : <></>}

                    {
                        companyView && !_.isEmpty(contact)
                            ?
                            <>
                                <div className="vehicle-item-title mini-badge">
                                    <i className='ico icon-Contacts mr-1'></i>
                                    {CommonHelper.displayContactName(null, contact)}
                                </div>
                            </>
                            :
                            <>
                                {
                                    (!_.isEmpty(service) && !_.isEmpty(service.engageNewContact))
                                        ?
                                        <>
                                            <div className="pipeline-item-head">
                                                {CommonHelper.displayContactIcon(service.contact, 'mr-1')}
                                                {CommonHelper.displayContact(null, service.engageNewContact, '')}
                                            </div>
                                        </>
                                        :
                                        <>
                                        </>
                                }
                            </>
                    }

                    {
                        appointmentDate
                            ?
                            <div className="mini-badge">
                                <Translate text={'appointmentDate'} />{': '}
                                {/* <i className="ico icon-timer mr-1 ml-1"></i> */}
                                {moment(appointmentDate).format('DD MMM YYYY h:mm A')}
                            </div>
                            :
                            <></>
                    }

                    {
                        nextService
                            ?
                            <div className="mini-badge">
                                <Translate text={'Next Service Date'} />{': '}
                                {/* <i className="ico icon-timer mr-1 ml-1"></i> */}
                                {moment(nextService.seconds).format('DD MMM YYYY h:mm A')}
                            </div>
                            :
                            <></>
                    }


                    {/* <div className="vehicle-item-owner">
                        <div className="owner-view">
                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                <img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)} alt="" className="img-object-fit" /></a>
                        </div>
                        {owner ? CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner) : '--'}
                    </div> */}


                </div>
            </div>
            <div className="vehicle-current-status pl-2 pr-2">
                {
                    owner ? (<OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>{CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)}</Tooltip>
                        }
                    >
                        <div className="float-right">
                            <div className="pipeline-users pl-0 pt-2">
                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                    <img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                </a>
                            </div>
                        </div>
                    </OverlayTrigger>) : (<></>)
                }
                <div className="float-left">

                    {
                        _jobStatus ?
                            <>
                                <div className='vehicle-item-owner'>
                                    {
                                        !_.isEmpty(_objCurrentStatus)
                                            ?
                                            <>
                                                <div
                                                    className={`badge badge-service-empty`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                    <Translate
                                                        text={_objCurrentStatus.name}
                                                        upperCase={true}
                                                    />

                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className={`badge badge-service-${_jobStatus.toLowerCase()}`}>
                                                    {_jobStatus.split(/(?=[A-Z])/).join(' ').toUpperCase()}
                                                </div>
                                            </>
                                    }
                                    {
                                        service?.technicianStatus
                                            ?
                                            <>
                                                <OverlayTrigger
                                                    placement='bottom'
                                                    overlay={<Tooltip><Translate text={'technicianStatus'} /> <br /> {CommonHelper.getNameByValue(technicianAllStatus, service?.technicianStatus, service?.technicianStatus)}</Tooltip>}
                                                >

                                                    <i className={`ico icon-timer ml-2 mt-1`} style={{ color: CommonHelper.getFieldByValue(technicianAllStatus, service?.technicianStatus, 'color', '#b0babe') }}></i>

                                                </OverlayTrigger>
                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                            </> : <></>
                    }

                </div>


            </div>
        </div>

    )
};

export default EnquiryInfo;
