import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';

import CommonHelper from '../../../services/common';
import { ContentOverlay, PopUpModal } from '../../../components';

import Translate from '../../../constants/translate';
import { TableView } from '../../../components';
import { allLoanBookingsFieldsVM } from '../viewModel';
import LoanBookingsModal from './loanBookingsModal';
import { firestoreDB } from '../../../services/helper';

const LoanBookings = ({ type ,title, show, handleClose, data, dealersettings, clientUsers, groupUsers, setReloadLoanBookingCounts, reloadLoanBookingCounts }) => {
  const [loader, setLoader] = useState(true)
  const [loanBookings, setLoanBookings] = useState([])
  const [hasMore, setHasMoreData] = useState([])
  const [showLoanVehicle, setShowLoanVehicle] = useState({
    show: false,
    data: null,
  });

  const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })

  const [searchText, setSearchText] = useState('')
  const [pageNum, setPageNum] = useState(0);
  const [reloadData, setReloadData] = useState(0);

  useEffect(() => {
    function handleResize() {
        setWindowSize({
            windowWidth: (window.innerWidth - 190),
            windowHeight: (window.innerHeight - 190)
        })
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
    }
    window.addEventListener('resize', handleResize);
    document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
    return () => {
        window.removeEventListener('resize', handleResize);
    }
}, [])

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.unSubCampaigns && window.unSubCampaigns();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
			let loanBookingsToAdd = []

      try {
        let ref = firestoreDB(dealersettings).firestore().collection('loanBookings')
        .where('clientID', '==', dealersettings.client.id)
        .where('isDeleted', '==', false)
        
        if (type && type === 'completed') {
          ref = ref
          .where('status', '==', 1)
          .orderBy('appointmentOn', 'asc')
        } else if (type &&  type === 'total') {
          ref = ref
          .where('status', '==', 0)
          .orderBy('appointmentOn', 'asc')
        } else {
          ref = ref
          .where('status', '==', 0)
          .orderBy('appointmentOn', 'asc')
        }

        const snapshots = await ref.limit(50).get()
          if (!snapshots.empty) {
            snapshots.forEach((doc) => {
              loanBookingsToAdd.push(convertVM(doc.data()))
            });
            setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
          } 
        setLoanBookings(loanBookingsToAdd)
        setLoader(false)
      } catch (error) {
        console.log(error);
        setLoader(false)
      }
    } 

    fetchData()
  }, [reloadData])

  const fetchMoreData = async () => {
    if (searchText || !hasMore.length) {
      return
    }
		let loanBookingsToAdd = []
		let ref = firestoreDB(dealersettings).firestore().collection('loanBookings')
      .where('clientID', '==', dealersettings.client.id)

      if (type && type === 'completed') {
        ref = ref
        .where('status', '==', 1)
        .where('isDeleted', '==', false)
        .orderBy('appointmentOn', 'asc')
        .startAfter(hasMore[0])
        .limit(50)
      } else if (type &&  type === 'total') {
        ref = ref
        .where('status', '==', 0)
        .where('isDeleted', '==', false)
        .orderBy('appointmentOn', 'asc')
        .startAfter(hasMore[0])
        .limit(50)
      } else {
        ref = ref
        .where('status', '==', 0)
        .where('isDeleted', '==', false)
        .orderBy('appointmentOn', 'asc')
        .startAfter(hasMore[0])
        .limit(50)
      }
		const snapshots = await ref.get()
		if (!snapshots.empty) {
			snapshots.forEach((doc) => {
				loanBookingsToAdd.push(convertVM(doc.data()))
			});
			setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
			setLoanBookings([...loanBookings, ...loanBookingsToAdd])
		}	
	}

  const convertVM = (data, fromSearch) => {
    data.addedDate = data.addedDate && data.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(data.addedDate._seconds)._d) : data.addedDate;
    let objData = {...data}
    let appointmentOn = ''
    if (fromSearch && data.appointmentOn) {
      appointmentOn = moment.unix(data.appointmentOn._seconds).format('DD/MM/YYYY hh:mm A')
    } else if (!fromSearch && data.appointmentOn) {
       appointmentOn = moment(data.appointmentOn.toDate()).format('DD/MM/YYYY hh:mm A')
    }

    let convertedOn = ''
    if (fromSearch && data.convertedOn) {
      convertedOn = moment.unix(data.convertedOn._seconds).format('DD/MM/YYYY hh:mm A')
    } else if (!fromSearch && data.convertedOn) {
        convertedOn = moment(data.convertedOn.toDate()).format('DD/MM/YYYY hh:mm A')
    }

    objData.contactName = CommonHelper.displayContactName([], objData.contact)
    objData.vehicle = !_.isEmpty(objData.request) && (objData.request.Make || objData.request.Model) ? `${objData.request.Make ? objData.request.Make : ''} ${objData.request.Model ? objData.request.Model : ''}` : ''
    objData.appointmentOn = appointmentOn ? appointmentOn : ''
    objData.loanedOn = convertedOn ? convertedOn : ''

    objData.email = !_.isEmpty(objData.contact) && (objData.contact.email) ? objData.contact.email : ''
    objData.phone = !_.isEmpty(objData.contact) && (objData.contact.phone) ? objData.contact.phone : ''
    objData.vinNo = !_.isEmpty(objData.request) && (objData.request.VIN) ? objData.request.VIN : ''
    objData.regNo = !_.isEmpty(objData.request) && (objData.request.RegNum) ? objData.request.RegNum : ''
    objData.year = !_.isEmpty(objData.request) && (objData.request.Year) ? objData.request.Year : ''
    objData.licenseFront = !_.isEmpty(objData.request) && (objData.request.LicenseFront) ? `data:image/png;base64,${objData.request.LicenseFront}` : ''
    objData.licenseBack = !_.isEmpty(objData.request) && (objData.request.LicenseBack) ? `data:image/png;base64,${objData.request.LicenseBack}`: ''
    
    return objData
  }

  const handleActionClick = (id, data) => {
    return type === 'completed' ? (
      <div className="fleet-btn-loan  reassign-fix" style={{ width: '110px', float: 'left' }}>
            <button type="submit" className="btn btn-sm btn-primary float-left" onClick={(e) => {
                e.preventDefault();
                handleOpenLoanVehicle(data)
            }}><Translate text={'View Details'} /></button>
        </div>
    ) : (
        <div className="fleet-btn-loan  reassign-fix" style={{ width: '110px', float: 'left' }}>
            <button type="submit" className="btn btn-sm btn-primary float-left" onClick={(e) => {
                e.preventDefault();
                handleOpenLoanVehicle(data)
            }}><Translate text={'Loan Vehicle'} /></button>
        </div>
    )
  }

  const handleRowSingleClick = () => {
  }

  const handleOpenLoanVehicle = (data) => {
    setShowLoanVehicle({
      show: true,
      data: data,
    })
  }

  const handleCloseLoanVehicle = () => {
    setShowLoanVehicle({
      show: false,
      data: null,
    })
  }

  const handleUpdateLoanBooking = async (id, fleetID) => {
    if (fleetID) {
      setLoanBookings(loanBookings.filter(a => a.documentID !== id))
      setReloadLoanBookingCounts(!reloadLoanBookingCounts)
      await firestoreDB(dealersettings).firestore().collection('loanBookings').doc(id).set({
        status: 1,
        convertedOn: window.firebase.firestore.Timestamp.now(),
        fleetID: fleetID ? fleetID : '',
        fleetAddedBy: localStorage.uid,
        modifiedBy: localStorage.uid,
        modifiedDate: window.firebase.firestore.Timestamp.now(),
        modifiedFrom: 'web'
      }, { merge: true })
    }
  }

  const handlesearchText = (text) => {
    if (text) {
        fetchSearchData(text, 0)
        setPageNum(0);
    } else {
        setPageNum(0);
        setReloadData(!reloadData)
    }
  }

  const fetchSearchData = async (text, pageNum) => {
    if (!searchText) {
      return
    }
    let num = pageNum
    let pageLimit =  50

    let _filter = {
      keywords: text,
      timezone: !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
    }
    
    let _searchObject = {
        "type": "searchLoanBookings",
        "filters": _filter,
        "sortOrder": `appointmentOn desc`,
        "pageNum": num,
        pageLimit,
    }
    _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, null, true);
    setLoader(true)
    const searchLoanBookings = window.firebase.functions().httpsCallable('generic-searchData');
    const resp = await searchLoanBookings(_searchObject);
    if (resp.data.success) {
        let _pageNum = num + 1;
        let loanBookings_ = resp.data.data.map(a => convertVM(a, true))
        setPageNum(_pageNum)

        setLoanBookings(_.uniqBy(loanBookings_, 'documentID'))
        setLoader(false)
    }
    else {
        console.log('error: ', resp.data.message)
        toast.closeAll();
        Swal.fire(CommonHelper.showLocale({dealersettings}, 'Something went wrong.'));
        setLoader(false)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        backdropClassName={show ? 'overlay-modal active' : ''}
        enforceFocus={false}
        dialogClassName="modal-dialog-centered modal-dashboard-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'>
              <Translate text={title} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="settings-head">
              <div className="float-left">
              <div className={`filter-search`}>
                    <div className="input-group ">
                        <input
                            className="form-control"
                            type="text"
                            id="isearch"
                            placeholder="quick search..."
                            value={searchText ? searchText : ''}
                            onChange={(e) => {
                                let _val = e.target.value
                                setSearchText(_val);
                                if (_val && _val.length > 2) {
                                    handlesearchText(_val)
                                }

                                if (!_val) {
                                    handlesearchText('')
                                }

                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handlesearchText(searchText)
                                }
                            }}
                            autoComplete="off"
                        />
                        <div className="input-group-append input-search-clear">
                            {
                                searchText && searchText.length > 0
                                    ?
                                    <a href="#"
                                        className="input-search-clear-icon"
                                        title="Clear"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSearchText('');
                                            // localStorage.contactSearchText = '';
                                            handlesearchText('')
                                        }}
                                    >
                                        <i className="ico icon-remove"></i>
                                    </a>
                                    :
                                    <>
                                    </>
                            }

                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlesearchText(searchText)
                                }}>
                                <i className="ico icon-search-fill"></i>
                            </button>
                        </div>
                    </div>

                </div>
              </div>
              </div>
              {!loader && !_.isEmpty(loanBookings) ? (
                <div className="common-table">
                  <TableView
                    isReorderable={false}
                    datalist={loanBookings}
                    height={windowSize.windowHeight}
                    width={windowSize.windowWidth}
                    columns={allLoanBookingsFieldsVM}
                    dynamicFields={allLoanBookingsFieldsVM
                      .filter(e => e.default === true)
                      .map(v => _.pick(v, ['value']).value)}
                    settingsLoader={false}
                    handleActionClick={handleActionClick}
                    handleRowSingleClick={handleRowSingleClick}
                    isSettings={false}
                    hasMore={hasMore}
                    isPaging={true}
                    handlePagination={fetchMoreData}
                  />
                </div>
              ) : !loader && _.isEmpty(loanBookings) ? (
                <div className="common-table" style={{width: '100%', height: '582px'}}>
                    <div className="text-center p-5">
                        <p><Translate text={'No record found'} /></p>
                    </div>
                </div>
              ) : (
                <div className="mt-5" style={{width: '100%',  height: '565px'}}><ContentOverlay active={true} /></div>
              )}
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className='modal-footer'>
          <button
            type='button'
            className='btn btn-default float-right ml-2 '
            aria-label='Close'
            // disabled={}
            onClick={handleClose}
          >
            {' '}
            <Translate text={'Close'} />
          </button>
        </Modal.Footer> */}
      </Modal>
      <PopUpModal show={showLoanVehicle.show}>
					<LoanBookingsModal
						show={showLoanVehicle.show}
						title={'Loan Booking'}
            handleClose={handleCloseLoanVehicle}
						data={showLoanVehicle.data}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
            handleUpdateLoanBooking={handleUpdateLoanBooking}
            type={type}
						// campaignID={props.campaign_id}
					/>
			</PopUpModal>
    </>
  );
};

export default LoanBookings;
