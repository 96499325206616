/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import "../../../styles/tradeinCustomer.scss";
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'

import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'
import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any'
/** COMPONENTS */
import {
    PopUpModal, ImageCanvas, MarkingInfo, AutoGrabScan,
    RegScan, VinScan, ContentOverlay, VehicleScan
} from '../../../components';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import TipInfoInputs from '../add/infoInputs'
/** VIEW-MODELS */
import { objTradeIn, sidePhotosVM, tradeinSortOptions } from '../viewModel'
import { objBasicTradeInVM } from '../../tradeinplus/viewModel';
import { storageBucket } from '../../../services/helper';

//#region  ASSIGN VARIABLES
const vCondtions = [
    { active: true, value: 'Poor', label: 'Poor' },
    { active: true, value: 'Average', label: 'Average' },
    { active: true, value: 'Good', label: 'Good' },
    { active: true, value: 'V. Good', label: 'V. Good' },
    { active: true, value: 'New', label: 'New' }
]
const engineTypes = [
    { active: true, value: 'Petrol', label: 'Petrol' },
    { active: true, value: 'Diesel', label: 'Diesel' },
    { active: true, value: 'Electric', label: 'Electric' },
    { active: true, value: 'Hybrid', label: 'Hybrid' }
]
const transmissions = [
    { active: true, value: 'Automatic', label: 'Automatic' },
    { active: true, value: 'Manual', label: 'Manual' }
]
const vDoors = [
    { active: true, value: '2', label: '2' },
    { active: true, value: '3', label: '3' },
    { active: true, value: '4', label: '4' },
    { active: true, value: '5', label: '5' },
    { active: true, value: '6', label: '6' },
    { active: true, value: '7', label: '7' }
]
const wheelConditions = [
    { active: true, value: 'Good', label: 'Good' },
    { active: true, value: 'Average', label: 'Average' },
    { active: true, value: 'Poor', label: 'Poor' }
]
const wheelConditionsNA = [
    { active: true, value: 'N/A', label: 'N/A' },
    { active: true, value: 'Good', label: 'Good' },
    { active: true, value: 'Average', label: 'Average' },
    { active: true, value: 'Poor', label: 'Poor' }
]
const __status = [
    { active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}>IN PROGRESS</div> },
    { active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}>PENDING</div> },
    { active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> }
]
//#endregion

export default class TradeInDetails extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.ref = window.firebase.firestore().collection('tradeins');
        this.newDocID = null;
        this.mandatoryFields = [];
        this.statusSettings = null;
        this.state = {
            fields: Object.assign({}, objTradeIn),
            dealersettings: {},
            enquiry: {},
            service: {},
            tradeinPro: {},
            errors: {},
            errorPhotos: {},
            makes: [],
            models: [],
            types: [],
            groups: [],
            years: [],
            tradeinOptionsDF: [],
            showCanvas: false,
            showRegScan: false,
            showVinScan: false,
            showDamage: false,
            imageURL: null,
            imageLoader: Object.assign({}),
            imageURLs: null,
            isLoadingInspection: false,
            addMake: false,
            addType: false,
            addGroup: false,
            addModel: false,
            pagenotFound: false,
            vehicleScan: { show: false, type: null },
            firestoreDB: null,
            isTradeinDone: false,
            autograbSearch: false,
            skipImages: false
        }

    }

    //#region PAGE LOAD
    loadTradeIn = async (props, firestoreDB) => {


        window.refCollectionData = docData(firestoreDB.firestore().doc(`tradeins/${props.tradeinid}`)
        )
            .pipe(
                switchMap(tradeIns => {
                    //console.log(tradeIns);
                    const enqIds = tradeIns.enquiryID ? [tradeIns.enquiryID] : [];
                    const serviceIds = tradeIns.serviceID ? [tradeIns.serviceID] : [];
                    const tradeIds = tradeIns.tradeinProID ? [tradeIns.tradeinProID] : [];
                    const clientIds = [tradeIns.clientID];
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(enquiryID =>
                                collectionData(firestoreDB.firestore().collection('enquiries').where('documentID', '==', enquiryID)).pipe(
                                    map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            serviceIds.length > 0 ? serviceIds.map(serviceID =>
                                collectionData(firestoreDB.firestore().collection('serviceJobs').where('documentID', '==', serviceID)).pipe(
                                    map(serviceSnapshot => serviceSnapshot[0] ? serviceSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            tradeIds.length > 0 ? tradeIds.map(tradeinProID =>
                                collectionData(firestoreDB.firestore().collection('tradeinPro').where('documentID', '==', tradeinProID)).pipe(
                                    map(tipSnapshot => tipSnapshot[0] ? tipSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                    map(clientSettingsSnapshot => clientSettingsSnapshot)
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([tradeIns, enquirySnapshot, serviceSnapshot, tipSnapshot, clientSnapshot, clientSettingsSnapshot]) => {

                    return {
                        tradein: { ...tradeIns },
                        enquiry: tradeIns.enquiryID ? enquirySnapshot.find(a => a.documentID === tradeIns.enquiryID) : null,
                        service: tradeIns.serviceID ? serviceSnapshot.find(a => a.documentID === tradeIns.serviceID) : null,
                        tradeinPro: tradeIns.tradeinProID ? tipSnapshot.find(a => a.documentID === tradeIns.tradeinProID) : null,
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                        currentSettings: clientSettingsSnapshot[0]
                    }

                })
            )
            .subscribe(async (_tradeIns) => {


                if (!_.isEmpty(_tradeIns)) {

                    //8QkI6wfjUuGDwGp2g3Fe

                    const fields = Object.assign({}, objTradeIn);
                    for (let [key, value] of Object.entries(_tradeIns.tradein)) {
                        fields[key] = value;
                    }
                    if (fields.isDeleted) {
                        this.setState({ pagenotFound: true });
                    }
                    else {
                        this.newDocID = props.tradeinid;

                        let _dealerSettings = {};

                        const allBrandsSnapshot = await window.firebase.firestore().collection(`brands`).where('isDeleted', '==', false).get();
                        const modelpromises = []
                        let allMakeModels = []
                        allBrandsSnapshot.docs.forEach(brand => {
                            allMakeModels.push(brand.data());
                            modelpromises.push(window.firebase.firestore().doc(`brands/${brand.id}/models/${brand.id}`).get())
                        })
                        const modelSnapshots = await Promise.all(modelpromises)
                        modelSnapshots.forEach(snap => {
                            allMakeModels = _.map(allMakeModels, function (make) {
                                return (snap.exists && snap.id === make.documentID) ?
                                    {
                                        ...make,
                                        'models': snap.data().models
                                    } : {
                                        ...make
                                    }
                            })
                        })

                        const remoteConfig = window.firebase.remoteConfig();

                        _dealerSettings.allMakeModels = Object.assign([], allMakeModels);
                        try {
                            await remoteConfig.fetchAndActivate()
                                .then(() => {
                                    let apiURL = remoteConfig.getString('apiURL');
                                    let tradeinplusURL = remoteConfig.getString('tradeinplusURL');
                                    let baseURL = window.location.origin;//remoteConfig.getString('baseURL');                        
                                    const states = remoteConfig.getString('states');

                                    _dealerSettings.statesURL = states ? states : null;
                                    _dealerSettings.apiURL = apiURL ? apiURL : null;
                                    _dealerSettings.tradeinplusURL = tradeinplusURL ? tradeinplusURL : null;
                                    _dealerSettings.baseURL = baseURL ? baseURL : null;

                                    if (process.env.NODE_ENV === 'development')
                                        baseURL = 'http://localhost:3000/';


                                })
                                .catch((err) => {
                                    console.error(err);
                                });

                        } catch (error) {
                            console.log('lookups', error);
                        }
                        if (_dealerSettings.statesURL) {
                            const responseState = await fetch(window.location.origin + _dealerSettings.statesURL);
                            const statejson = await responseState.json();
                            _dealerSettings.states = statejson;
                        }

                        let _client = Object.assign({}, _tradeIns.client);
                        let _clientSettings = Object.assign({}, _tradeIns.currentSettings);
                        _dealerSettings.client = Object.assign({
                            ..._client,
                            id: fields.clientID,
                            name: _clientSettings.name,
                            timezone: _clientSettings.timezone,
                            phone: _clientSettings.phone,
                            logoURL: _clientSettings.logoURL,
                            address: _clientSettings.address,
                            moduleSettings: _clientSettings.moduleSettings,
                            currency: _clientSettings.settings && _clientSettings.settings.currency,
                            integrations: _clientSettings.integrations,
                            settings: _clientSettings
                        });

                        //console.log('_client', _client, _clientSettings);
                        let isTradeinDone = (fields.make && fields.tradeinProID && _tradeIns?.tradeinPro?.isTradein) ? true : false;
                        this.setState({
                            fields: fields,
                            isTradeinDone: isTradeinDone,
                            dealersettings: _dealerSettings,
                            enquiry: Object.assign({}, _tradeIns.enquiry),
                            service: Object.assign({}, _tradeIns.service),
                            tradeinPro: Object.assign({}, _tradeIns.tradeinPro)
                        }, () => { this.loadSettings(); });
                    }

                    window.refCollectionData && window.refCollectionData.unsubscribe()

                }


            });

    }

    loadSettings = () => {
        const { dealersettings, fields, enquiry, isTradeinDone, service } = this.state;
        const setttings = dealersettings.client.settings;
        const tradeinPro_setttings = dealersettings.client.tradeinPro;
        const service_setttings = dealersettings.client.services;
        const brands = dealersettings.allMakeModels;
        const isComplete = fields.linkStatus === 'sent' ? false : true;

        let tradeinOptionsDF = [];
        if (setttings.settings.tradeinOptionsDF)
            tradeinOptionsDF = setttings.settings.tradeinOptionsDF;

        if (!isComplete) {
            if (fields.serviceID && !_.isEmpty(setttings.services) && !_.isEmpty(setttings.services.mandatoryFields) && !_.isEmpty(setttings.services.mandatoryFields.tradein))
                this.mandatoryFields = setttings.services.mandatoryFields.tradein.split(',');
            else if (fields.tradeinProID && !_.isEmpty(setttings.tradeinPro) && !_.isEmpty(setttings.tradeinPro.mandatoryFields) && !_.isEmpty(setttings.tradeinPro.mandatoryFields.tradein))
                this.mandatoryFields = setttings.tradeinPro.mandatoryFields.tradein.split(',');
            else if (fields.enquiryID && !_.isEmpty(setttings.settings) && !_.isEmpty(setttings.settings.mandatoryFields) && !_.isEmpty(setttings.settings.mandatoryFields.tradein))
                this.mandatoryFields = setttings.settings.mandatoryFields.tradein.split(',');

            if (!_.isEmpty(enquiry) && enquiry.pipeline && !_.isEmpty(setttings.settings) && !_.isEmpty(setttings.settings.allPipelines)) {
                let _selPipeline = setttings.settings.allPipelines.filter(e => e.value === enquiry.pipeline)[0];
                this.statusSettings = _selPipeline && _selPipeline.statusSettings;
            }

            const defaultVINScan = (!_.isEmpty(dealersettings?.client?.clientSettings) &&
                dealersettings.client?.clientSettings?.defaultVINScan ? true : false);


            const _evalexpert = Object.assign({}, dealersettings &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0]);

            const evalexpertEnabled = fields.clientID && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;
            const _autograb = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
            const _autoGrabEnabled = _autograb && _autograb.active && _autograb.enabled ? true : false;
            const autograbEnabled = ((!_.isEmpty(dealersettings.client.moduleSettings) &&
                !_.isEmpty(dealersettings.client.moduleSettings.autograb) &&
                dealersettings.client.moduleSettings.autograb.enabled &&
                dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : _autoGrabEnabled);

            const regoScanEnabled = ((!_.isEmpty(dealersettings.client.moduleSettings) &&
                !_.isEmpty(dealersettings.client.moduleSettings.regoScan) &&
                dealersettings.client.moduleSettings.regoScan.enabled) ? true : false);

            if (!isComplete && !isTradeinDone) {
                if (evalexpertEnabled && (fields.enquiryID || fields.serviceID)) {
                    if (defaultVINScan)
                        setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'vin' } }), 500);
                    else
                        setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'reg' } }), 500);
                }
                else if (autograbEnabled)
                    setTimeout(() => this.setState({ autograbSearch: true }), 500);
                else if (regoScanEnabled) {
                    if (defaultVINScan)
                        setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'vin' } }), 500);
                    else
                        setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'reg' } }), 500);
                }
            }

            const years = [];
            const makes = [];
            const groups = [];
            const types = [];
            const models = [];
            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            if (fields.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === fields.make)[0]) ? makes.filter(m => m.value === fields.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }

                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });

            }

            this.setState({
                makes: makes,
                years: years,
                groups: _.orderBy(groups, ['label'], ['asc']),
                types: _.orderBy(types, ['label'], ['asc']),
                models: models,
                tradeinOptionsDF: tradeinOptionsDF
            });
        }
        else {
            this.setState({
                tradeinOptionsDF: tradeinOptionsDF
            });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            window.firebase.firestore().doc(`tradeins/${this.props.tradeinid}`).get()
                .then(doc => {
                    let _firestoreDB = window.firebase
                    if (!doc.exists) _firestoreDB = window.firebase2
                    if (_.isEmpty(_firestoreDB.auth().currentUser)) {
                        _firestoreDB.auth().signInWithEmailAndPassword('secondarydb@fusionamspro.com', 'secondaryDB@321')
                            .catch((error) => {
                                console.log('error on secondary firestore auth user', error)
                            });
                    }
                    this.setState({ firestoreDB: _firestoreDB }, () => { this.loadTradeIn(this.props, _firestoreDB); })
                })
        }


    }
    componentWillUnmount() {

        this.setState(
            {
                fields: Object.assign({}, objTradeIn),
                errors: {},
                errorPhotos: {},
                makes: [],
                models: [],
                types: [],
                years: [],
                showRegScan: false,
                showVinScan: false,
                showDamage: false,
                imageURL: null,
                imageLoader: Object.assign({}),
                imageURLs: null

            }
        )
    }
    //#endregion

    //#region CHANGE EVENT

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = value;
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str;
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    onValueChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let fields = Object.assign({}, this.state.fields);
        fields[name] = e.value;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let fields = Object.assign({}, this.state.fields);
        fields[name] = e.floatValue;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }


    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        const { tradeinOptionsDF, dealersettings } = this.state;
        let errorClass = 'input_error';

        if (errors !== {}) {
            let dynamicDetailList = (!_.isEmpty(dealersettings?.client?.settings?.settings?.sortTradeinOptions))
                ? dealersettings.client.settings.settings.sortTradeinOptions :
                Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
            dynamicDetailList = _.union(dynamicDetailList, ['images', 'sidePhotos', 'marks'])
            if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0 && dynamicDetailList.includes(key)) {
                if (objTradeIn.hasOwnProperty(key) && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {
                    if (['images'].includes(key)) {
                        if (_.isEmpty(CommonHelper.bindAllImages(fields.images, fields.sidePhotos))) {
                            errors[key] = errorClass;
                        }
                    }
                    else {
                        errors[key] = errorClass;
                    }
                }
                else if ((!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                    (_.isEmpty(fields.dynamicFields) ||
                        (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                    )) {

                    errors[key] = errorClass;
                }
                else
                    errors[key] = '';
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }

    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ fields: state }, () => { this.errorChange(data.name); });

    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ fields: state, models: models }, () => { this.errorChange(data.name); });

    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })
        }

        state['model'] = '';
        this.setState({ fields: state, models: models, types: _.orderBy(types, ['label'], ['asc']) }, () => { this.errorChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const types = [];
        const groups = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            fields: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorChange(data.name); });

    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state });
    }

    //#endregion

    //#region DATEPICKER
    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.fields);
        if (val)
            state[name] = moment(val).format('YYYY-MM-DD');
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleDateValueChange = (name, date) => {
        var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
        if (date.formattedValue !== null && date.formattedValue !== "" && pattern.test(date.formattedValue)) {
            let state = Object.assign({}, this.state.fields);
            state[name] = moment(date.formattedValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
            this.setState({ fields: state });
        }
        else if (!date.formattedValue) {
            let state = Object.assign({}, this.state.fields);
            state[name] = null;
            this.setState({ fields: state });
        }
    }


    limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    dateFormat = (val) => {
        let date = this.limit(val.substring(0, 2), '31');
        let month = this.limit(val.substring(2, 4), '12');
        let year = this.limit(val.substring(4, 8), (moment()._d.getFullYear() + 20).toString);
        let fullDate = date + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
        return fullDate
    }

    //#endregion

    //#region IMAGE UPLOAD

    saveInspectionToStorage = (imageBase64) => {
        this.setState({ isLoadingInspection: true })
        let clientID = this.state.dealersettings ? this.state.dealersettings.client.id : '';
        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
        }

        var storageRef = storageBucket(this.state.dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/inspections/snapshot.png`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', () => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.state.dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/inspections`).child('snapshot.png').getDownloadURL()
                    .then(dataURL => {
                        //console.log(dataURL);
                        let state = Object.assign({}, this.state.fields);
                        state['inspectionSnapshot'] = dataURL;
                        this.setState({ fields: state, isLoadingInspection: false })
                    })
            })
    };

    saveImagesToStorage = async (filez, id, fileID) => {
        let promises = [];

        let files = [];
        for (let [key, file] of Object.entries(filez)) {
            files.push(file);
        }
        if (fileID) {
            let imageLoader = Object.assign({}, this.state.imageLoader)
            imageLoader[fileID] = fileID;
            this.setState({ imageLoader: imageLoader });
        }
        else
            this.setState({ imageURLs: files });

        let clientID = this.state.dealersettings ? this.state.dealersettings.client.id : '';
        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
        }
        files.forEach((file, index) => {
            let uid = moment()._d.getTime().toString() + index + '.' + file.name.substr((file.name.lastIndexOf('.') + 1));
            let storageRef = storageBucket(this.state.dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/${uid}`);
            promises.push(
                storageRef.put(file).then((snapshot) => {
                    return snapshot.ref.getDownloadURL()
                })
            );
        });
        const snapshots = await Promise.all(promises)

        let state = Object.assign({}, this.state.fields);
        let fileURLS = Object.assign([], state.images);
        let sidePhotos = Object.assign({}, state.sidePhotos);
        let imageLoader = Object.assign({}, this.state.imageLoader)

        if (fileID) {
            sidePhotos[fileID] = snapshots ? snapshots[0] : null;
            state['sidePhotos'] = sidePhotos;
            imageLoader[fileID] = null;
        }
        else {
            snapshots.forEach(url => {
                fileURLS.push(url);
            })
            state.images = fileURLS
        }

        this.setState({ fields: state, imageURLs: null, imageLoader: imageLoader })
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

    }


    handleImageDel = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this image.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                state.images = Object.assign([], _data);
                this.setState({ fields: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    handleSideImageDel = (fileID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this image.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {

                let state = Object.assign({}, this.state.fields);
                let sidePhotos = Object.assign({}, state.sidePhotos);

                if (fileID)
                    sidePhotos[fileID] = null;

                state.sidePhotos = sidePhotos;
                this.setState({ fields: state })

                //console.log('handleImageDel', state, sidePhotos)
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }


    //#endregion

    //#region INSPECTION

    handleCanvasClose = (marks) => {
        this.setState({ showCanvas: false })
        let state = Object.assign({}, this.state.fields);
        if (!_.isEmpty(marks) || (!_.isEmpty(state.marks) && _.isEmpty(marks))) {

            if (JSON.stringify(Object.assign([], state.marks)) !== JSON.stringify(marks)) {
                //   console.log('handleCanvasClose', marks, state.marks)
                this.getInspectionSnapshot(marks);
            }
            state.marks = marks;
            this.setState({ fields: state })
        }
    }

    getInspectionSnapshot = async (_marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        var context = canvas.getContext('2d');

        var img = new Image();
        const { dealersettings } = this.state;
        if (dealersettings?.client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        img.onload = async (e) => {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await this.asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await this.loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            if (dataURL)
                this.saveInspectionToStorage(dataURL);

            return dataURL;
        }
    }

    asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    handleRegScanClose = (data) => {
        if (!_.isEmpty(data)) {
            const { dealersettings } = this.state;
            const { clientID } = this.state.fields;
            const _evalexpert = Object.assign({}, dealersettings &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0]);

            const evalexpertEnabled = clientID && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;

            if (evalexpertEnabled === true)
                this.bindEvalExpertTradeIn(data);
            else
                this.bindTradeIn(data);
        }
        this.setState({ showRegScan: false })
    }

    handleVinScanClose = (data) => {
        if (!_.isEmpty(data)) {
            const { dealersettings } = this.state;
            const { clientID } = this.state.fields;
            const _evalexpert = Object.assign({}, dealersettings &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0]);

            const evalexpertEnabled = clientID && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;

            if (evalexpertEnabled === true)
                this.bindEvalExpertTradeIn(data);
            else
                this.bindTradeIn(data);
        }
        this.setState({ showVinScan: false })
    }

    handleScanClose = (data) => {
        if (!_.isEmpty(data)) {
            const { dealersettings } = this.state;
            const { clientID } = this.state.fields;
            const _evalexpert = Object.assign({}, dealersettings &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0]);

            const evalexpertEnabled = clientID && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;

            if (evalexpertEnabled === true)
                this.bindEvalExpertTradeIn(data);
            else
                this.bindTradeIn(data);
        }
        this.setState({ vehicleScan: { show: false, type: null } })
    }

    bindEvalExpertTradeIn = (data) => {
        let state = Object.assign({}, this.state.fields);

        if (data.BuildMth && data.BuildYr && data.BuildMth !== '0' && data.BuildYr !== '0')
            state.buildDate = `${data.BuildYr}-${data.BuildMth}-01`
        if (data.CompMth && data.CompYr && data.CompMth !== '0' && data.CompYr !== '0')
            state.complianceDate = `${data.CompYr}-${data.CompMth}-01`
        if (data.SvcMth && data.SvcYear && data.SvcMth !== '0' && data.SvcYear !== '0')
            state.lastService = `${data.SvcYear}-${data.SvcMth}-01`
        if (data.WtyMth && data.WtyYear && data.WtyMth !== '0' && data.WtyYear !== '0')
            state.warrantyExpiry = `${data.WtyYear}-${data.WtyMth}-01`

        if (data.Doors) state.doors = data.Doors;
        if (data.EngineNo) state.engineNo = data.EngineNo;
        if (data.Litres) state.engineSize = data.Litres;
        if (data.Ext_Colour) state.exteriorColor = data.Ext_Colour;
        if (data.Int_Colour) state.interiorColor = data.Int_Colour;
        if (data.ServiceHistory && _.isString(data.ServiceHistory)) state.serviceHistory = data.ServiceHistory;
        if (data.SpareKey && _.isString(data.SpareKey)) state.spareKey = data.SpareKey;
        if (data.customer && _.isString(data.customer)) state.owners = data.customer;
        if (data.FinanceName) state.financeCo = data.FinanceName;
        if (data.KM) state.mileage = data.KM;

        if (data.Make) state.make = data.Make;
        if (data.Model) state.model = data.Model;
        if (data.Year) state.year = data.Year;
        if (data.Body) state.type = data.Body;
        if (data.Registration) state.regNo = data.Registration;
        if (data.RegExpiry) state.regDate = moment(data.RegExpiry).format('YYYY-MM-DD')
        if (data.VIN) state.chassisNo = data.VIN;
        if (data.Reconditioning) state.reconditioning = this.stringToBoolean(data.Reconditioning);

        if (data.FinalPrice) state.offerPrice = parseFloat(data.FinalPrice);
        if (data.Recon) state.reconditioningCost = parseFloat(data.Recon);

        state.modelDescription = this.bindEvalModelDescription(data);

        if (data.Transmission) {
            if (data.Transmission.toLowerCase().indexOf('auto') > -1)
                state.transmission = 'Automatic';
            else if (data.Transmission.toLowerCase().indexOf('manu') > -1)
                state.transmission = 'Manual';
        }

        if (data.Fuel) {
            if (data.Fuel.toLowerCase().indexOf('petrol') > -1)
                state.engineType = 'Petrol';
            else if (data.Fuel.toLowerCase().indexOf('diesel') > -1)
                state.engineType = 'Diesel';
            else if (data.Fuel.toLowerCase().indexOf('electric') > -1)
                state.engineType = 'Electric';
            else if (data.Fuel.toLowerCase().indexOf('hybrid') > -1)
                state.engineType = 'Hybrid';
        }

        // if (data.ImgURL && _.isString(data.ImgURL)) {
        //     const _images = Object.assign([], state.images);
        //     _images.push(data.ImgURL);
        //     state.images = _images ? _images : state.images;
        // }

        let _images = [];
        if (data.ImgURL && _.isString(data.ImgURL))
            _images.push(data.ImgURL);

        data.CarPics && data.CarPics.forEach((item) => {
            if (_.isString(item)) _images.push(item)
            else if (item.URL) _images.push(item.URL)
        })

        state.images = _images ? _images : state.images;

        if (data.EvalID) {
            state.evalexpertID = data.EvalID;
            const _tags = Object.assign([], state.tags);
            _tags.push({ "refID": data.EvalID, "type": "evalexpert" })
            state.tags = _tags;
        }

        if (data.Notes) {
            state.notes = `${data.Notes}${(state.notes ? '\n' + state.notes : '')}`;
        }

        if (data.CarSpecs) {
            state.specifications = this.bindEvalSpecifications(data);
        }

        const { dealersettings } = this.state;
        if (state.make) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            const groups = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ fields: state })
    }

    bindEvalSpecifications = (data) => {
        let _description = null;
        if (data.CarSpecs && data.CarSpecs.Specifications) {
            data.CarSpecs.Specifications.forEach((item) => {
                if (item.Description)
                    _description = (_description ? _description : '') + '\n' + item.Description;
            })
        }
        //console.log('_description', _description)
        return _description ? _.trim(_description) : null;
    }

    bindEvalModelDescription = (data) => {

        let _description = null;
        if (data.Make)
            _description = data.Make + ' '
        if (data.Series)
            _description = (_description ? _description : '') + data.Series + ' '
        if (data.Model)
            _description = (_description ? _description : '') + data.Model + ' '
        if (data.Variant)
            _description = (_description ? _description : '') + data.Variant + ' '
        if (data.Cylinders)
            _description = (_description ? _description : '') + data.Cylinders + 'cyl '
        if (data.Body)
            _description = (_description ? _description : '') + data.Body + ' '
        if (data.Transmission)
            _description = (_description ? _description : '') + data.Transmission + ' '
        if (data.Drive)
            _description = (_description ? _description : '') + data.Drive + ' '

        return _description ? _.trim(_description) : null;

    }

    stringToBoolean = (_data) => {
        switch (_data.toLowerCase().trim()) {
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(_data);
        }
    }

    bindTradeIn = (data) => {
        let state = Object.assign({}, this.state.fields);


        if (data.make) state.make = data.make;
        if (data.model) state.model = data.model;
        if (data.modelDescription) state.modelDescription = data.modelDescription;
        if (data.yearOfManufacturer) state.year = data.yearOfManufacturer.toString();
        if (data.bodyStyle) state.type = data.bodyStyle;
        if (data.rego) state.regNo = data.rego;
        if (data.vin) state.chassisNo = data.vin;
        if (data.primaryColour) state.exteriorColor = data.primaryColour;
        if (data.engineCapacity) state.engineSize = data.engineCapacity;
        if (data.secondaryColour) state.interiorColor = data.secondaryColour;

        if (data.transmission) {
            if (data.transmission.toLowerCase().indexOf('auto') > -1)
                state.transmission = 'Automatic';
            else if (data.transmission.toLowerCase().indexOf('manu') > -1)
                state.transmission = 'Manual';
        }

        const { dealersettings } = this.state;
        if (state.make) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ fields: state })
    }

    handleAutoGrabClose = (data, _fields) => {
        if (!_.isEmpty(data)) {
            this.bindAutoGrabTradeIn(data, _fields);
        }
        this.setState({ autograbSearch: false })
    }

    bindAutoGrabTradeIn = (_data, _fields) => {
        //console.log('bindAutoGrabTradeIn', _data)

        let state = Object.assign({}, this.state.fields);
        let data = Object.assign({}, _data?.vehicle);

        if (data.make) state.make = data.make;
        if (data.model) state.model = data.model;
        if (data.body_type) state.type = data.body_type;
        if (data.year) state.year = data.year;

        if (data.title)
            state.modelDescription = data.title;
        else state.modelDescription = CommonHelper.bindAutoGrabDescription(data);

        if (data.capacity_cc) state.engineSize = data.capacity_cc.toString();
        if (data.num_doors) state.doors = data.num_doors.toString();
        if (data.engine_type) state.engineType = data.engine_type;

        if (data.drive_type) state.driveType = data.drive_type;
        if (data.fuel_type) state.fuelType = data.fuel_type;
        if (data.num_cylinders) state.cylinders = data.num_cylinders;
        if (data.num_gears) state.gears = data.num_gears;
        if (data.num_seats) state.seats = data.num_seats;
        if (data.power_kw) state.powerKw = data.power_kw;
        if (data.range) state.range = data.range;
        if (data.series) state.series = data.series;
        if (data.torque_nm) state.torqueNm = data.torque_nm.toString();
        if (data.transmission) state.transmissionType = data.transmission;
        if (data.wheelbase) state.wheelbase = data.wheelbase;
        if (data.badge) state.badge = data.badge;
        if (data.region) state.region = data.region;
        // if (data.release_month && data.release_year) {
        //     state.buildDate = `${data.release_year}-${CommonHelper.limit(data.release_month, '12')}-01`
        // }

        if (data.regNo) state.regNo = data.regNo; else if (_fields?.regNum) state.regNo = _fields.regNum;
        if (data.vinNo) state.chassisNo = data.vinNo; else if (_fields?.vinNum) state.chassisNo = _fields.vinNum;
        if (_data.colour) state.exteriorColor = _data.colour;

        if (data.compliance_plate) state.complianceDate = data.compliance_plate + '-01';
        if (data.registration_expiry) state.regDate = data.registration_expiry;
        if (data.plate_number) state.regNo = data.plate_number;
        if (data.vin) state.chassisNo = data.vin;
        if (data.registration_status) state.regStatus = data.registration_status;
        if (data.state) state.regState = data.state;
        if (data.engineNo) state.engineNo = data.engineNo;

        if (data.transmission_type) {
            if (data.transmission_type.toLowerCase().indexOf('auto') > -1)
                state.transmission = 'Automatic';
            else if (data.transmission_type.toLowerCase().indexOf('manu') > -1)
                state.transmission = 'Manual';
        }

        //state.specifications = CommonHelper.bindAutoGrabSpecifications(data);

        if (data.id) {
            state.autograbID = data.id;
            // let _tags = Object.assign([], state.tags);
            // _tags = _tags.filter(e => e.type !== "autograb")
            // _tags.push({ "refID": data.id, "type": "autograb" })
            // state.tags = _tags;
        }

        const { dealersettings } = this.props;
        if (state.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            const groups = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }

                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ fields: state })
    }

    handleDamageClose = () => {
        this.setState({ showDamage: false })
    }
    //#endregion

    //#region IMAGE CROP


    onSelectFile = async (e, id, fileID) => {

        const files = e.target.files;

        if (files && files.length > 0) {

            if (files.length > 9) {
                Swal.fire('Maximum 9 files can be uploaded', '', 'info')
                return;
            }

            if (fileID) {
                let imageLoader = Object.assign({}, this.state.imageLoader)
                imageLoader[fileID] = fileID;
                this.setState({ imageLoader: imageLoader });
            }
            else
                this.setState({ imageURLs: Object.assign([], files) });

            const imageFile = []
            for (let [key, file] of Object.entries(files)) {

                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire('Maximum size of each file is 10MB', '', 'info')
                    return;
                }

                if (file.name.toLowerCase().includes('.heic')) {
                    let _file = await CommonHelper.heic2Image(file);
                    imageFile.push(_file)
                } else if (filesize > 1) {
                    //console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

                    const options = {
                        maxSizeMB: 0.5,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    try {
                        const compressedFile = await imageCompression(file, options);
                        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                        imageFile.push(compressedFile)

                    } catch (error) {
                        console.log(error);
                    }
                }
                else
                    imageFile.push(file)

            }
            this.saveImagesToStorage(imageFile, id, fileID)

        }
    }
    //#endregion

    //#region SAVE & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let errorPhotos = {}
        let formIsValid = true;

        let fields = Object.assign({}, this.state.fields);
        const { tradeinOptionsDF, dealersettings, skipImages } = this.state;
        let errorClass = 'input_error';

        let dynamicDetailList = (!_.isEmpty(dealersettings?.client?.settings?.settings?.sortTradeinOptions))
            ? dealersettings.client.settings.settings.sortTradeinOptions :
            Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
        dynamicDetailList = _.union(dynamicDetailList, ['images', 'sidePhotos', 'marks'])
        this.mandatoryFields && this.mandatoryFields.map(key => {
            if (dynamicDetailList.includes(key)) {
                if (objTradeIn.hasOwnProperty(key) && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {
                    if (['images'].includes(key)) {
                        if (_.isEmpty(CommonHelper.bindAllImages(fields.images, fields.sidePhotos))) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                    else {
                        formIsValid = false;
                        errors[key] = errorClass;
                    }
                }
                else if ((!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                    (_.isEmpty(fields.dynamicFields) ||
                        (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                    )) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        });

        !Boolean(skipImages) && sidePhotosVM && sidePhotosVM.filter(e => e.default).forEach((data) => {
            if (_.isEmpty(fields.sidePhotos) || (!_.isEmpty(fields.sidePhotos) && _.isEmpty(fields?.sidePhotos[data.value]))) {
                formIsValid = false;
                errorPhotos[data.value] = 'border-error';
            }
        });

        this.setState({ errors: errors, errorPhotos: errorPhotos });

        if (formIsValid === true) {
            this.addTradeIn(this);
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];

            if (_.isEmpty(id) && !_.isEmpty(errorPhotos))
                id = 'imageURL';

            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }

            return formIsValid;
        }

    };

    addTradeIn = () => {
        this.isLoading = true;
        const objData = Object.assign({}, this.state.fields);
        //if (objData.evalexpertID) objData.sent = true;
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        delete objData['contact'];
        delete objData['enquiry'];
        delete objData['service'];
        delete objData['tradeinPro'];

        objData.linkStatus = 'respond';
        let _reqObject = {
            "documentID": objData.documentID,
            "collection": "tradeins",
            "data": objData
        }


        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
        genericSaveData(_reqObject).then((response) => {
            //console.log(response);
            if (response.data.success) {
                this.isLoading = false;
                const fields = Object.assign({}, this.state.fields);
                fields.linkStatus = 'respond';
                this.setState({ fields: fields })
                toast.notify('Trade-In saved successfully', {
                    duration: 2000
                })
                this.saveIsTradeIn();
                this.sendNotification();
            }
            else {
                this.forceUpdate();
            }
        });

    };

    saveIsTradeIn = () => {
        const { enquiry, fields, tradeinPro, service } = this.state;

        let objData = {};
        objData.isTradeIn = true;
        objData.modifiedBy = fields.addedBy ? fields.addedBy : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (!_.isEmpty(enquiry) && !enquiry.isTradeIn && fields.enquiryID) {
            objData.projectId = enquiry.projectId ? enquiry.projectId : null;

            let _reqObject = {
                "documentID": fields.enquiryID,
                "collection": "enquiries",
                "data": objData
            }
            const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
            genericSaveData(_reqObject)
        }

        if (!_.isEmpty(service) && !service.isTradeIn && fields.serviceID) {
            objData.projectId = service.projectId ? service.projectId : null;

            let _reqObject = {
                "documentID": fields.serviceID,
                "collection": "serviceJobs",
                "data": objData
            }
            const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
            genericSaveData(_reqObject)
        }

        if (!_.isEmpty(tradeinPro) && !tradeinPro.isTradein && fields.tradeinProID) {
            objData.projectId = tradeinPro.projectId ? tradeinPro.projectId : null;

            if (!_.isEmpty(fields)) {
                const _vehicle = Object.assign({}, objBasicTradeInVM);
                for (let [key, value] of Object.entries(fields)) {
                    if (_vehicle.hasOwnProperty(key) && value)
                        _vehicle[key] = value;
                }
                objData['tradein'] = _vehicle;
            }

            let _reqObject = {
                "documentID": fields.tradeinProID,
                "collection": "tradeinPro",
                "data": objData
            }
            const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
            genericSaveData(_reqObject)
        }
    }

    // handleMoveStageForStatusChange = (_status, state) => {
    //     const { statusSettings } = this.state;
    //     const { stageHistory } = this.state.enquiry;

    //     let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
    //     if (!_.isEmpty(data) && data.pipeline && data.stage) {

    //         state.stageDate = window.firebase.firestore.Timestamp.now();
    //         let _stageHistory = Object.assign([], stageHistory);
    //         var hasRecord = false;

    //         _stageHistory.filter(function (obj) {
    //             //Update old stage data
    //             if (obj.stage === state.stage && obj.pipeline === state.pipeline) {
    //                 obj.sittingDays = (obj.sittingDays ? obj.sittingDays : 0) + parseInt(moment().diff(moment.unix(obj.date.seconds), 'days'));
    //             }

    //             // Update new stage data
    //             if (obj.stage === data.stage && obj.pipeline === data.pipeline) {
    //                 hasRecord = true;
    //                 obj.date = state.stageDate;
    //             }
    //         });

    //         if (hasRecord === false) {
    //             _stageHistory.push({
    //                 date: state.stageDate,
    //                 stage: data.stage,
    //                 pipeline: data.pipeline,
    //                 sittingDays: 0,
    //             });
    //         }
    //         state.stageHistory = Object.assign([], _stageHistory);
    //         state.pipeline = data.pipeline;
    //         state.stage = data.stage;
    //         return state;
    //     }
    //     else
    //         return state;
    // }

    sendNotification = () => {
        const { enquiry, fields, tradeinPro, service } = this.state;
        if (!_.isEmpty(enquiry) && enquiry.owner && fields.enquiryID) {
            let _name = CommonHelper.displayContactName(null, enquiry.contact, '')

            let _reqObject = {
                "userID": enquiry.owner,
                "notification": {
                    title: 'Trade-In',
                    body: `Customer ${_name} added Trade-In`,
                    type: 'tradein',
                    recordID: fields.enquiryID,
                    clientID: fields.clientID ? fields.clientID : ''
                }
            }
            //console.log('generic-notify', _reqObject)
            const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
            genericNotify(_reqObject)
        }

        if (!_.isEmpty(service) && service.owner && fields.serviceID) {
            let _name = CommonHelper.displayContactName(null, service.contact, '')

            let _reqObject = {
                "userID": service.owner,
                "notification": {
                    title: 'Trade-In',
                    body: `Customer ${_name} added Trade-In`,
                    type: 'tradein',
                    recordID: fields.serviceID,
                    clientID: fields.clientID ? fields.clientID : ''
                }
            }
            //console.log('generic-notify', _reqObject)
            const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
            genericNotify(_reqObject)
        }

        if (!_.isEmpty(tradeinPro) && tradeinPro.owner && fields.tradeinProID) {
            let _name = CommonHelper.displayContactName(null, tradeinPro.contact, '')

            let _reqObject = {
                "userID": tradeinPro.owner,
                "notification": {
                    title: 'Trade-In',
                    body: `Customer ${_name} added Trade-In Plus`,
                    type: 'tradeinPro',
                    recordID: fields.tradeinProID,
                    clientID: fields.clientID ? fields.clientID : ''
                }
            }
            //console.log('generic-notify', _reqObject)
            const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
            genericNotify(_reqObject)
        }
    }

    //#endregion

    handleCustomOption = e => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        if (e.target.value === '') {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [e.target.name]: ''
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name]: 'input_error'
                }
            })
            return
        }

        this.setState({
            fields: {
                ...this.state.fields,
                [e.target.name]: capitalizeFirstLetter(e.target.value)
            },
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }

    //#region DYNAMIC FIELDS

    handleDynamicCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = checked;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        if (value)
            _dynamicFields[name] = _.trim(value.toUpperCase());
        else
            _dynamicFields[name] = null;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    onDynamicNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        _dynamicFields[name] = e.floatValue;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleDynamicSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[data.name] = e.value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    };

    handleDynamicMultiSelectChange = (selectedOptions, _data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[_data.name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(_data.name); });
    }

    handleDynamicDateChange = (val, name) => {

        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = val;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    saveDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    cancelDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }
    //#endregion


    //#region  ADD VEHICLE MANUAL
    handleAddMakeEnable = () => {
        this.setState({
            addMake: true
        })
        setTimeout(() => {
            let elem = document.getElementById('make')
            elem.focus()
        }, 100)
    }

    handleAddMakeDisable = () => {
        this.setState({
            addMake: false,
            errors: {
                ...this.state.errors,
                ['make']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['make']: ''
            }
        })
    }

    handleAddGroupEnable = () => {
        this.setState({
            addGroup: true
        })
        setTimeout(() => {
            let elem = document.getElementById('group')
            elem.focus()
        }, 100)
    }

    handleAddGroupDisable = () => {
        this.setState({
            addGroup: false,
            errors: {
                ...this.state.errors,
                ['group']: 'input_error'
            },
            stock: {
                ...this.state.stock,
                ['group']: ''
            }
        })
    }

    handleAddTypeEnable = () => {
        this.setState({
            addType: true
        })
        setTimeout(() => {
            let elem = document.getElementById('type')
            elem.focus()
        }, 100)
    }

    handleAddTypeDisable = () => {
        this.setState({
            addType: false,
            errors: {
                ...this.state.errors,
                ['type']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['type']: ''
            }
        })
    }

    handleAddModelEnable = () => {
        this.setState({
            addModel: true
        })
        setTimeout(() => {
            let elem = document.getElementById('model')
            elem.focus()
        }, 100)
    }

    handleAddModelDisable = () => {
        this.setState({
            addModel: false,
            errors: {
                ...this.state.errors,
                ['model']: 'input_error'
            },
            fields: {
                ...this.state.fields,
                ['model']: ''
            }
        })
    }

    handleVehicleScan = (_type) => {
        const { dealersettings, fields } = this.state;
        const _evalexpert = Object.assign({}, dealersettings &&
            dealersettings.client &&
            dealersettings.client.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0]);

        const evalexpertEnabled = fields.clientID && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;
        const _autograb = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
        const _autoGrabEnabled = _autograb && _autograb.active && _autograb.enabled ? true : false;
        const autograbEnabled = ((!_.isEmpty(dealersettings.client.moduleSettings) &&
            !_.isEmpty(dealersettings.client.moduleSettings.autograb) &&
            dealersettings.client.moduleSettings.autograb.enabled &&
            dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : _autoGrabEnabled);
        const regoScanEnabled = ((!_.isEmpty(dealersettings.client.moduleSettings) &&
            !_.isEmpty(dealersettings.client.moduleSettings.regoScan) &&
            dealersettings.client.moduleSettings.regoScan.enabled) ? true : false);

        if (evalexpertEnabled && (fields.enquiryID || fields.serviceID))
            this.setState({ vehicleScan: { show: true, type: _type } })
        else if (autograbEnabled)
            this.setState({ autograbSearch: true });
        else if (regoScanEnabled)
            this.setState({ vehicleScan: { show: true, type: _type } })

    }



    render() {

        const {
            clientID,
            sidePhotos,
            images, marks, linkStatus } = this.state.fields;

        const { addMake, addType, addModel, addGroup, imageURL, imageURLs, imageLoader, autograbSearch,
            dealersettings, enquiry, errorPhotos, tradeinPro, vehicleScan, fields, tradeinOptionsDF, skipImages, service } = this.state;
        const isComplete = linkStatus === 'sent' ? false : true;

        const _moduleSettings = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const _evalexpert = Object.assign({}, dealersettings &&
            dealersettings.client &&
            dealersettings.client.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0]);

        const showDamageCost = true;//dealersettings?.rolePermissions?.tradeinProPermissions?.showDamageCost ? true: false;
        const evalexpertEnabled = clientID && _evalexpert && _evalexpert.active && _evalexpert.enabled ? true : false;
        const client = dealersettings?.client;

        let dynamicDetailList = (!_.isEmpty(dealersettings?.client?.settings?.settings?.sortTradeinOptions))
            ? dealersettings.client.settings.settings.sortTradeinOptions :
            Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
        //console.log('dynamicDetailList', dynamicDetailList, tradeinOptionsDF)
        //console.log('dealersettings?.states', dealersettings?.states)
        //console.log('enquiry', enquiry)
        //console.log('service', service)
        //console.log('tradeinPro', tradeinPro)
        return (
            <>
                {
                    !_.isEmpty(dealersettings?.states) && (!_.isEmpty(enquiry) || !_.isEmpty(service) || !_.isEmpty(tradeinPro))
                        ?
                        <>
                            <div className="cst-tradeinform-header">
                                <div className="container">
                                    <div className="cst-tradeinform-logo-wrap">
                                        <span className="cst-tradeinform-logo-img mr-3">
                                            {
                                                dealersettings && dealersettings.client && dealersettings.client.logoURL
                                                    ?
                                                    <img src={dealersettings.client.logoURL} height="60" />
                                                    :
                                                    <></>
                                            }

                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div className="container">

                                <div className="cst-tradeinform-wrap-bg">
                                    <div className="cst-tradeinform-wrap">

                                        <div className="cst-tradeinform-loop">
                                            <div className="cst-tradeinform-subhead">Let’s Get Your <span>Trade-In Value </span></div>

                                            {
                                                !_.isEmpty(enquiry)
                                                    ?
                                                    <div className="cst-tradeinform-customertitle">Dear {CommonHelper.displayContact(null, enquiry.contact, '--')},</div>
                                                    :
                                                    <>
                                                        {
                                                            !_.isEmpty(service)
                                                                ?
                                                                <div className="cst-tradeinform-customertitle">Dear {CommonHelper.displayContact(null, service.contact, '--')},</div>
                                                                :
                                                                <>
                                                                    {
                                                                        !_.isEmpty(tradeinPro)
                                                                            ?
                                                                            <div className="cst-tradeinform-customertitle">Dear {CommonHelper.displayContact(null, tradeinPro.contact, '--')},</div>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                            }


                                            <div className="cst-tradeinform-description">Getting a great trade-in value can make a big difference when buying or financing your new vehicle - every dollar counts. If you're new to this, don't worry. We've got you covered with some essential tips to help get the best trade-in value you can.</div>

                                            <div className="cst-tradeinform-vehiclehead">Trade-in Vehicle Info</div>

                                            {
                                                (isComplete && (!_.isEmpty(fields.images) || !_.isEmpty(fields.sidePhotos))) || !isComplete
                                                    ?
                                                    <>
                                                        <div className="cst-tradeinform-vehicleimg-wrap">
                                                            <div className="cst-tradeinform-imgwrap">

                                                                <div className="cst-tradeinform-upload mt-2">{isComplete ? 'Vehicle Images' : 'Upload Vehicle Images'}</div>
                                                                <div className="form-group col-md-12">

                                                                    <div className="cst-tradeinform-image-wraper" id="imageURL">

                                                                        {
                                                                            sidePhotosVM && sidePhotosVM.map((data, index) => {
                                                                                return <div key={index}>
                                                                                    {
                                                                                        !_.isEmpty(sidePhotos) && sidePhotos[data.value] && data.default
                                                                                            ?
                                                                                            <>
                                                                                                <div className="cst-tradeinform-item-image">
                                                                                                    {
                                                                                                        isComplete
                                                                                                            ?
                                                                                                            <></>
                                                                                                            :
                                                                                                            <>
                                                                                                                <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    this.handleSideImageDel(data.value);
                                                                                                                }}>
                                                                                                                    <i className="ico icon-delete" ></i>
                                                                                                                </div>
                                                                                                            </>
                                                                                                    }

                                                                                                    <a data-fancybox={`tradein_img_customer`} href={sidePhotos[data.value]} onClick={(e) => { e.preventDefault(); }}>
                                                                                                        <img src={sidePhotos[data.value]} width="70" height="70" alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                                    <div className="cst-tradeinform-item-label">{data.name}</div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    isComplete
                                                                                                        ?
                                                                                                        <></>
                                                                                                        :
                                                                                                        <>
                                                                                                            {
                                                                                                                (imageLoader && imageLoader[data.value]) ?
                                                                                                                    (
                                                                                                                        <div className="cst-tradeinform-item-image">
                                                                                                                            <div className="img-loader">
                                                                                                                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                                                                                                        <span className="sr-only">Loading...</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                                                <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                                                                            </a>
                                                                                                                            <div className="cst-tradeinform-item-label">{data.name}</div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                    :
                                                                                                                    <>

                                                                                                                        <div className={`cst-tradeinform-item-image upload ${errorPhotos[data.value]}`}>
                                                                                                                            <label htmlFor={`sidePhotos_${data.value}`}>
                                                                                                                                <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, data.ico)]} alt="" htmlFor={`sidePhotos_${data.value}`} />
                                                                                                                                <input className="fileInput"
                                                                                                                                    type="file"
                                                                                                                                    name={`sidePhotos_${data.value}`}
                                                                                                                                    id={`sidePhotos_${data.value}`}
                                                                                                                                    accept="image/*"
                                                                                                                                    style={{ display: 'none' }}
                                                                                                                                    multiple={data.default ? false : true}
                                                                                                                                    onChange={(e) => this.onSelectFile(e, `sidePhotos_${data.value}`, data.value)} />
                                                                                                                                {
                                                                                                                                    data.default
                                                                                                                                        ?
                                                                                                                                        <div className="cst-tradeinform-item-addwrap">
                                                                                                                                            <div className="cst-tradeinform-item-add"><i className="ico icon-add"></i></div>
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <></>
                                                                                                                                }

                                                                                                                            </label>

                                                                                                                            <div className="cst-tradeinform-item-label">{data.name}</div>
                                                                                                                        </div>


                                                                                                                    </>
                                                                                                            }
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            })
                                                                        }

                                                                        {
                                                                            isComplete && _.isEmpty(sidePhotos) && _.isEmpty(images)
                                                                                ?
                                                                                <>
                                                                                    <div className="cst-tradeinform-item-image">

                                                                                        <a href={'#'} onClick={(e) => { e.preventDefault(); }}>
                                                                                            <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" className="img-object-fit" />
                                                                                        </a>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>

                                                                                </>
                                                                        }




                                                                        {
                                                                            !_.isEmpty(images) && images.map((data, index) => {
                                                                                return <div className="cst-tradeinform-item-image" key={index}>
                                                                                    {isComplete ? <></> :
                                                                                        <>
                                                                                            <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.handleImageDel(index);
                                                                                            }}>
                                                                                                <i className="ico icon-delete" ></i>
                                                                                            </div>
                                                                                        </>
                                                                                    }

                                                                                    <a data-fancybox={`tradein_img_customer`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                        <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                                    </a>
                                                                                </div>
                                                                            })
                                                                        }


                                                                        {
                                                                            (imageURL) ?
                                                                                (
                                                                                    <div className="cst-tradeinform-item-image">
                                                                                        <div className="img-loader">
                                                                                            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                                                    <span className="sr-only">Loading...</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                            <img src={imageURL} width="70" height="70" alt="" />
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                <></>
                                                                        }

                                                                        {
                                                                            (imageURLs) ?
                                                                                <>
                                                                                    {
                                                                                        imageURLs.map((data, index) => (
                                                                                            <div key={index} className="cst-tradeinform-item-image">
                                                                                                <div className="img-loader">
                                                                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                                                                            <span className="sr-only">Loading...</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                                                </a>
                                                                                            </div>
                                                                                        ))
                                                                                    }


                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        {
                                                                            isComplete
                                                                                ?
                                                                                <></>
                                                                                :
                                                                                <>
                                                                                    <div title={'More Images'} className={`cst-tradeinform-item-image upload ${this.state.errors["images"] ? 'border-error' : ''}`}>
                                                                                        <label htmlFor="images">
                                                                                            <img src={_images.addvehicle} alt="" htmlFor="images" />
                                                                                            <input className="fileInput"
                                                                                                type="file"
                                                                                                name="images"
                                                                                                id="images"
                                                                                                accept="image/*"
                                                                                                style={{ display: 'none' }}
                                                                                                multiple={true}
                                                                                                onChange={(e) => this.onSelectFile(e, 'images')} />
                                                                                        </label>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            <div className="tradein-inspection-wrap">

                                                <button
                                                    type="button"
                                                    className="btn btn-inspection-multi float-left"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ showCanvas: true })
                                                    }}
                                                >
                                                    {this.state.isLoadingInspection ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-inspection')}></i>}

                                                    <Translate text={'vehicleInspection'} />
                                                    {
                                                        !_.isEmpty(marks) && marks.length > 0
                                                            ?
                                                            <>
                                                                <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                <div className="float-right"><span className="badge badge-inspection">{marks.length}</span></div>
                                                            </>

                                                            :
                                                            <>
                                                                <div className="float-right ml-2"><i className="ico icon-add" aria-hidden="true"></i></div>
                                                            </>

                                                    }
                                                </button>
                                                {
                                                    showDamageCost
                                                        ?
                                                        <>
                                                            <button
                                                                type="button"
                                                                className={`btn btn-inspection-cost float-right ${!_.isEmpty(marks) && marks.length > 0 ? '' : 'btn-inspection-cost-disable'}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showDamage: true })
                                                                }}
                                                            ><Translate text={'damageCost'} />
                                                                <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                <div className="float-right">
                                                                    {CommonHelper.showReconditioningCost(marks, currencySymbol)}
                                                                </div>
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            <button
                                                                type="button"
                                                                className={`btn btn-inspection-cost float-right btn-inspection-cost-disable`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            ><Translate text={'damageCost'} />
                                                            </button>
                                                        </>
                                                }




                                            </div>
                                            <div className="cst-tradeinform-field-wrap form-style">
                                                <div className="tabs-wraper">

                                                    <div className={`form-row ${(isComplete) ? 'form-disable' : ''}`}>

                                                        {
                                                            dynamicDetailList && dynamicDetailList.map((info, index) => (
                                                                <TipInfoInputs
                                                                    key={index}
                                                                    type={info}
                                                                    fields={this.state.fields}
                                                                    errors={this.state.errors}
                                                                    currencySymbol={currencySymbol}
                                                                    dealersettings={dealersettings}
                                                                    tradeinOptionsDF={tradeinOptionsDF}
                                                                    handleSelectSave={this.handleSelectSave}
                                                                    isComplete={isComplete}
                                                                    makes={this.state.makes}
                                                                    addMake={addMake}
                                                                    groups={this.state.groups}
                                                                    addGroup={addGroup}
                                                                    types={this.state.types}
                                                                    addType={addType}
                                                                    models={this.state.models}
                                                                    addModel={addModel}
                                                                    years={this.state.years}
                                                                    engineTypes={engineTypes}
                                                                    transmissions={transmissions}
                                                                    vCondtions={vCondtions}
                                                                    vDoors={vDoors}
                                                                    wheelConditions={wheelConditions}
                                                                    wheelConditionsNA={wheelConditionsNA}
                                                                    tradeinPlusEnabled={true}
                                                                    tinStatus={__status}
                                                                    handleAddMakeEnable={this.handleAddMakeEnable}
                                                                    handleAddMakeDisable={this.handleAddMakeDisable}
                                                                    handleMakeChange={this.handleMakeChange}
                                                                    handleAddGroupEnable={this.handleAddGroupEnable}
                                                                    handleAddGroupDisable={this.handleAddGroupDisable}
                                                                    handleGroupChange={this.handleGroupChange}
                                                                    handleAddTypeEnable={this.handleAddTypeEnable}
                                                                    handleAddTypeDisable={this.handleAddTypeDisable}
                                                                    handleTypeChange={this.handleTypeChange}
                                                                    handleAddModelEnable={this.handleAddModelEnable}
                                                                    handleAddModelDisable={this.handleAddModelDisable}
                                                                    handleOnChange={this.handleOnChange}
                                                                    handleVehicleScan={this.handleVehicleScan}
                                                                    handleOnCapChange={this.handleOnCapChange}
                                                                    handleDateChange={this.handleDateChange}
                                                                    onValueChange={this.onValueChange}
                                                                    handleCustomOption={this.handleCustomOption}
                                                                    handleReactSelectChange={this.handleReactSelectChange}
                                                                    onCurrencyChange={this.onCurrencyChange}
                                                                    handleCheckChange={this.handleCheckChange}
                                                                    handleOnAreaChange={this.handleOnAreaChange}
                                                                    handleDynamicCheckChange={this.handleDynamicCheckChange}
                                                                    handleDynamicOnChange={this.handleDynamicOnChange}
                                                                    saveDynamicField={this.saveDynamicField}
                                                                    cancelDynamicField={this.cancelDynamicField}
                                                                    onDynamicNumberChange={this.onDynamicNumberChange}
                                                                    handleDynamicDateChange={this.handleDynamicDateChange}
                                                                    handleDynamicSelectSave={this.handleDynamicSelectSave}
                                                                    handleDynamicMultiSelectChange={this.handleDynamicMultiSelectChange}

                                                                ></TipInfoInputs>
                                                            ))

                                                        }
                                                    </div>



                                                </div>
                                                <div className="cst-tradeinform-submitwrap">
                                                    {

                                                        isComplete
                                                            ?
                                                            <></>
                                                            :
                                                            <>
                                                                <div className="clear"></div>
                                                                <div
                                                                    className={`accordion-bottom approve-btn ${this.isLoading ? 'form-disable' : ''}`}>
                                                                    <a href="#" className="btn btn-common button-black float-right mt-3"
                                                                        onClick={(e) => this.handleSubmit(e)}>
                                                                        {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                        <Translate text={'submit'} />
                                                                    </a>
                                                                    <div className="clear"> </div>
                                                                </div>
                                                            </>

                                                    }

                                                    <footer>
                                                        {/* <div className="footer-bottom">

                                                            <p>
                                                                <span style={{ paddingLeft: 0 }} >
                                                                    {client.address ? <>{client.address}</> : <></>}
                                                                </span>
                                                                <span style={{ paddingLeft: 0 }}>
                                                                    {client?.phone ? <> &nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                                    {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                                    {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                                    {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                                                </span>
                                                            </p>
                                                        </div> */}
                                                        <div className="footer-cpy">
                                                            <div className="footer-bottom">
                                                                <div className="cpy-wrap"> <img src={_images.safeTag} width="120" alt="" onDoubleClick={(e) => { e.preventDefault(); this.setState({ skipImages: true, errorPhotos: {} }); }} /></div>
                                                                <div className="cpy-wrap">
                                                                    <span style={{ paddingLeft: 0 }} >
                                                                        {client.name ? <strong>{client.name}</strong> : <></>}
                                                                    </span>
                                                                    <span style={{ paddingLeft: 0 }}>
                                                                        {client?.address ? <> &nbsp; | &nbsp;<span style={{ paddingLeft: 0 }} >{client.address}</span></> : <></>}
                                                                        {client?.phone ? <> &nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                                        {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                                        {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                                        {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                                                    </span>
                                                                </div>
                                                                <div className="cpy-wrap"> Powered by <a href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
                                                                <div className="clear"></div>
                                                            </div>
                                                        </div>
                                                    </footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <PopUpModal show={this.state.showRegScan}>
                                <RegScan
                                    show={this.state.showRegScan}
                                    clsActive='overlay-modal active'
                                    regNo={fields.regNo}
                                    dealersettings={dealersettings}
                                    isEvalExpert={evalexpertEnabled}
                                    handleClose={this.handleRegScanClose}
                                    isGenericLayout={true}
                                >
                                </RegScan>
                            </PopUpModal>

                            <PopUpModal show={this.state.showVinScan}>
                                <VinScan
                                    show={this.state.showVinScan}
                                    clsActive='overlay-modal active'
                                    chassisNo={fields.chassisNo}
                                    dealersettings={dealersettings}
                                    isEvalExpert={evalexpertEnabled}
                                    handleClose={this.handleVinScanClose}
                                    isGenericLayout={true}
                                >
                                </VinScan>
                            </PopUpModal>

                            <PopUpModal show={vehicleScan.show}>
                                <VehicleScan
                                    show={vehicleScan.show}
                                    type={vehicleScan.type}
                                    clsActive='overlay-modal active'
                                    regNo={fields.regNo}
                                    chassisNo={fields.chassisNo}
                                    dealersettings={dealersettings}
                                    isEvalExpert={evalexpertEnabled}
                                    handleClose={this.handleScanClose}
                                    isGenericLayout={true}
                                    hideClose={evalexpertEnabled}
                                >
                                </VehicleScan>
                            </PopUpModal>

                            <PopUpModal show={this.state.showCanvas}>
                                <ImageCanvas
                                    show={this.state.showCanvas}
                                    clsActive='overlay-modal active'
                                    imagePath='tradein'
                                    marks={fields.marks}
                                    handleClose={this.handleCanvasClose}
                                    dealersettings={dealersettings}
                                    showCost={showDamageCost}
                                    isReadOnly={(isComplete) ? true : false}
                                    isCompressedImage={true}
                                ></ImageCanvas>
                            </PopUpModal>

                            <PopUpModal show={autograbSearch}>
                                <AutoGrabScan
                                    show={autograbSearch}
                                    clsActive='overlay-modal active'
                                    regNo={fields.regNo}
                                    chassisNo={fields.chassisNo}
                                    dealersettings={dealersettings}
                                    handleClose={this.handleAutoGrabClose}
                                >
                                </AutoGrabScan>
                            </PopUpModal>

                            <PopUpModal show={this.state.showDamage}>
                                <MarkingInfo
                                    show={this.state.showDamage}
                                    clsActive='overlay-modal active'
                                    marks={fields.marks}
                                    handleClose={this.handleDamageClose}
                                    dealersettings={dealersettings}
                                    showCost={true}
                                ></MarkingInfo>
                            </PopUpModal>
                        </>
                        :
                        <>
                            {
                                this.state.pagenotFound
                                    ?
                                    <>
                                        <div
                                            className='middle-wrapper h-100 mt-0'
                                            style={{
                                                minHeight: '90vh',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: 'floralwhite'
                                            }}
                                        >
                                            <div className='spinner-loader h-100'>
                                                <div className='no-data-flex-wrap h-100 justify-content-center align-items-center text-primary'>
                                                    <div className='no-data-img-wrap'>
                                                        <img src={_images.pagenotFound} height='190' alt='' />
                                                    </div>
                                                    <div className='no-data-txt-wrap'>
                                                        <div className='no-data-content-head'>
                                                            Page Not Found.
                                                        </div>
                                                        <div className='no-data-content'>
                                                            The page you requested could not be found or may be deleted.
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <><div className="mt-5"><ContentOverlay active={true} /></div></>
                            }

                        </>

                }
            </>
        );
    }
}
