
export const checkoutFields = [
    {
        name: 'User',
        label: 'User',
        value: 'user',
        flex: 0,
        width: 15,
        default: true,
        sortValue: 'userName',
    },
    {
        name: 'Performance',
        label: 'Performance',
        value: 'performance',
        flex: 0,
        width: 15,
        default: true,
        type: 'dropdown',
    }
]

export const dynColVM = {
    name: '',
    value: '',
    percentage: null,
    currency: null
}

export const dateTypes = [
    {
        label: 'Date',
        value: 'date',
        format: 'DD/MM/YYYY'
    },
    {
        label: 'Month',
        value: 'month',
        format: 'MMM YYYY'
    }
]