/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Swal from 'sweetalert2'
/** COMPONENTS */
import {
    ReactSelect, InputText, InputRadio, InputCheckBox, PopUpModal, AntDatePicker
} from '../../../components';
import Translate from '../../../constants/translate';
import AddStock from '../../stock/add'
import StockInfo from '../../stock/stockInfo'
import Stocks from '../../stock/list/index';
import AddOns from './addOns';
import OwnedVehicles from '../owned';
import CommonHelper from '../../../services/common';
/** VIEW-MODELS */
import { stockAvail, stockStatus, enquiryStatus } from '../../../services/enum'
import { objRequirement, objRequirementClone } from '../../requirement/viewModel'
import { objStock, stockBasicVM } from '../../stock/viewModel'
import { objOwnedVehicle } from '../../contacts/viewModel';
import { firestoreDB } from '../../../services/helper';

let sale_type = [];
let transmissions = [];

export default class AddRequirement extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.newDocID = null;
        this.unsubscribeStock = null;
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.refStock = window.firebase.firestore().collection('stock');
        this.mandatoryFields = [];
        this.state = {
            requirement: Object.assign({}, objRequirement),
            stock: Object.assign({}, objStock),
            errorsStock: {},
            errorsReq: {},
            makes: [],
            models: [],
            types: [],
            groups: [],
            years: [],
            showStocks: false,
            isMultiSelect: false,
            enableServicePackages: false,
            requirementOtherInfo: [],
            serviceAddOns: [],
            isStock: false,
            isReserved: false,
            stockModal:
            {
                show: false,
                ID: '',
                prefillData: null,
                title: '',
                clsActive: ''
            },
            showAddOns: false,
            showOwnedVehicles: false,
        }
    }

    //#region  LOAD DATA

    onStockCollectionUpdate = (docSnapshot) => {
        if (!docSnapshot.exists) {
            return;
        }
        const { dealersettings, isAfterSales } = this.props;
        const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);

        const objStockData = Object.assign({}, docSnapshot.data());
        const stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objStockData)) {
            if (stock.hasOwnProperty(key))
                stock[key] = value;
        }
        stock.documentID = docSnapshot.id;

        if (!_.isEmpty(stock.regDate))
            stock.regDate = moment.unix(stock.regDate.seconds).format('YYYY-MM-DD')

        if (!_.isEmpty(stock.regExp))
            stock.regExp = moment.unix(stock.regExp.seconds).format('YYYY-MM-DD')

        if (!_.isEmpty(stock.warrantyExp))
            stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('YYYY-MM-DD')

        if (!_.isEmpty(stock.warrantyStartDate))
            stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('YYYY-MM-DD')

        const requirement = Object.assign({}, this.state.requirement);
        requirement.stock = Object.assign({}, stock);

        if (stock.saleType && !requirement.saleType)
            requirement.saleType = stock.saleType;

        if ((enableServicePackages || isAfterSales) && !requirement.saleType)
            requirement.saleType = !_.isEmpty(sale_type[0]) ? sale_type[0].value : 'New';

        this.setState({ stock: stock, requirement: requirement });
    }

    async loadRequirement(props) {
        const { show, dealersettings, requirement, clone, mandatoryFields, isAfterSales, defaultMakes } = props;
        const _requirement = Object.assign({}, objRequirement);

        const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);

        if (show === true && !_.isEmpty(requirement)) {
            this.newDocID = requirement.documentID;
            const objReqData = Object.assign({}, requirement);

            for (let [key, value] of Object.entries(objReqData)) {
                _requirement[key] = value;
            }

            if (!_.isEmpty(_requirement.stock)) {
                this.unsubscribeStock = this.refStock
                    .doc(_requirement.stock.documentID)
                    .onSnapshot(this.onStockCollectionUpdate);

                this.setState({ isStock: true });
            }

            this.setState({ requirement: _requirement });
        }
        else if (show === true && !_.isEmpty(clone)) {
            _requirement.documentID = this.refStock.doc().id;
            _requirement.reqOtherInfo = [];
            this.newDocID = _requirement.documentID;

            for (let [key, value] of Object.entries(clone)) {
                if (objRequirementClone.hasOwnProperty(key))
                    _requirement[key] = value;
            }

            if (enableServicePackages || isAfterSales)
                _requirement.saleType = !_.isEmpty(sale_type[0]) ? sale_type[0].value : 'New';

            this.setState({ requirement: _requirement });
        }
        else if (show === true) {
            _requirement.documentID = this.refStock.doc().id;
            _requirement.reqOtherInfo = [];

            const brands = Object.assign([], dealersettings.client.brands);
            if (brands.length === 1)
                _requirement.make = brands[0].value

            this.newDocID = _requirement.documentID;

            if (enableServicePackages || isAfterSales)
                _requirement.saleType = !_.isEmpty(sale_type[0]) ? sale_type[0].value : 'New';

            this.setState({ requirement: _requirement })
        }

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;

            sale_type = [];
            setttings.salesType && setttings.salesType.forEach(doc => {
                sale_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            transmissions = [];
            setttings.transmissions && setttings.transmissions.forEach(doc => {
                transmissions.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.requirement))
                this.mandatoryFields = setttings.mandatoryFields.requirement.split(',');

            if (!_.isEmpty(mandatoryFields))
                this.mandatoryFields = _.union(this.mandatoryFields, mandatoryFields);

            //const brands = dealersettings.client.brands;

            const _requirementOtherInfo = setttings.reqOtherInfo;


            const years = [];
            // const makes = [];
            // const types = [];
            // const groups = [];
            // const models = [];
            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            // _.orderBy(brands.filter((doc) => !_.isEmpty(defaultMakes) ? defaultMakes.includes(doc.value) : true), ['name'], ['asc']).forEach((doc) => {
            //     makes.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //         models: doc.models
            //     });
            // });

            // if (_requirement && _requirement.make) {
            //     let _models = !_.isEmpty(makes.filter(m => m.value === _requirement.make)[0]) ? makes.filter(m => m.value === _requirement.make)[0].models : [];
            //     _models.filter((model, index) => {
            //         if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
            //             types.push({
            //                 value: model.type,
            //                 active: _.isBoolean(model.active) ? model.active : true,
            //                 label: model.type
            //             });
            //         }
            //         if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
            //             groups.push({
            //                 value: model.group,
            //                 active: _.isBoolean(model.active) ? model.active : true,
            //                 label: model.group
            //             });
            //         }
            //     })

            //     _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
            //         models.push({
            //             value: data.value,
            //             label: CommonHelper.displayModelName(data),
            //             active: _.isBoolean(data.active) ? data.active : true,
            //         });
            //     });
            // }

            const _serviceAddOns = [];
            !_.isEmpty(setttings.servicePackages) && _.orderBy(setttings.servicePackages.filter(item => item.active === true), ['name'], ['asc']).map((data, index) => {
                _serviceAddOns.push({
                    value: data.value,
                    name: data.name,
                    price: data.price,
                });
            });

            this.setState({
                //makes: makes,
                years: years,
                // types: _.orderBy(types, ['label'], ['asc']),
                // groups: _.orderBy(groups, ['label'], ['asc']),
                // models: models,
                requirementOtherInfo: _requirementOtherInfo,
                serviceAddOns: _serviceAddOns,
                enableServicePackages: enableServicePackages
            }, () => { this.errorOnLoad(); this.loadMakeAndModels(_requirement); });
        }
    }

    loadMakeAndModels = (_requirement) => {

        const { dealersettings, defaultMakes } = this.props;
        const { requirement } = this.state;

        let _fields = _requirement ? _requirement : requirement

        const brands = [_fields?.saleType].includes('Preowned') ? dealersettings.allMakeModels : dealersettings.client.brands;

        const makes = [];
        const types = [];
        const groups = [];
        const models = [];

        _.orderBy(brands.filter((doc) => !_.isEmpty(defaultMakes) ? defaultMakes.includes(doc.value) : true), ['name'], ['asc']).forEach((doc) => {
            makes.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name,
                models: doc.models
            });
        });

        if (_fields?.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === _fields.make)[0]) ? makes.filter(m => m.value === _fields.make)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.type
                    });
                }
                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });

            if (!_.find(makes, { 'value': _fields.make })) {
                let state = Object.assign({}, this.state.requirement);
                state['make'] = '';
                state['model'] = '';
                state['type'] = '';
                state['group'] = '';
                this.setState({ requirement: state });
            }
        }

        this.setState({
            makes: makes,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        });

    }

    componentDidMount() {
        this.isLoading = false;
        this._isMounted = true;
        if (this._isMounted)
            this.loadRequirement(this.props);

    }

    componentWillUnmount() {
        this._isMounted = false;
        let _req = Object.assign({}, objRequirement);
        _req.reqOtherInfo = [];
        this.setState({ requirement: _req });
        this.unsubscribeStock && this.unsubscribeStock();
    }

    errorOnLoad = () => {
        if (!_.isEmpty(this.props.mandatoryFields)) {
            let errors = {};
            let errorClass = 'input_error';
            let _mandatoryFields = this.mandatoryFields;

            if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
                _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

            if (this.state.isStock) {
                let fields = Object.assign({}, this.state.stock);
                for (let [key] of Object.entries(fields)) {
                    if ((_mandatoryFields && _mandatoryFields.indexOf(key) >= 0)) {
                        if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                            errors[key] = errorClass;
                        }
                    }
                }

                if (!_.isEmpty(errors)) {
                    errors['stock'] = 'input_error';
                    this.setState({ errorsStock: errors });
                    this.handleEditStock(this.state.requirement)
                }

            }
            else {
                let fields = Object.assign({}, this.state.requirement);
                for (let [key] of Object.entries(fields)) {
                    if ((_mandatoryFields && _mandatoryFields.indexOf(key) >= 0)) {
                        if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                            errors[key] = errorClass;
                        }
                    }
                }

                this.setState({ errorsReq: errors });
            }
        }
    }

    //#endregion


    //#region CHANGE EVENT STOCK    
    errorStockChange = (key) => {
        let errors = Object.assign({}, this.state.errorsStock);
        let fields = Object.assign({}, this.state.stock);
        let errorClass = 'input_error';
        if (!_.isEmpty(errors)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errorsStock: errors });
        }
    }
    handleStockChange = (e, data) => {
        const { value } = e.target;
        let val = (value === 'Stock' ? true : false);

        this.setState({ isStock: val });
    }

    handleEditStock = (req) => {
        //console.log('handleEditStock', req)
        if (!_.isEmpty(req.stock)) {
            this.setState({
                stockModal:
                {
                    show: true,
                    ID: req.stock.documentID,
                    prefillData: null,
                    title: 'stock',
                    clsActive: 'overlay-modal active'
                }
            });
        }

    }

    handleAddStock = () => {
        this.setState({
            showStocks: false,
            isMultiSelect: false,
            stockModal:
            {
                show: true,
                ID: '',
                prefillData: null,
                title: 'addStock',
                clsActive: 'overlay-modal active'
            }
        });
    }

    handleMultiStockClose = async (selectedIds) => {
        //console.log('handleMultiStockClose', selectedIds)
        if (!_.isEmpty(selectedIds)) {

            if (Object.keys(selectedIds).length === 1) {
                for (let [key, value] of Object.entries(selectedIds)) {
                    this.handleStockClose(key, value);
                }
            }
            else {
                this.isLoading = true;
                const { requirements, dealersettings, enquiryID, isVehicle } = this.props;
                const batch = firestoreDB(dealersettings).firestore().batch();
                let objData = Object.assign({});
                objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings);
                objData.addedBy = localStorage.uid;
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
                objData.isDeleted = false;
                objData.modifiedBy = localStorage.uid;
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                objData.enquiryID = enquiryID;
                objData.reqOtherInfo = [];
                objData.quantity = 1;
                let i = 0;
                for (let [key, value] of Object.entries(selectedIds)) {
                    const _stock = Object.assign({}, stockBasicVM);
                    for (let [key, data] of Object.entries(value)) {
                        if (_stock.hasOwnProperty(key))
                            _stock[key] = data;
                    }
                    let _objReq = Object.assign({}, objData);
                    _objReq.documentID = this.refStock.doc().id;
                    _objReq.stock = Object.assign({}, _stock)
                    _objReq.stockID = key;
                    _objReq.saleType = _stock.saleType;

                    //Remove empty value from object
                    for (var propName in _objReq) {
                        if (_objReq[propName] === null || _objReq[propName] === undefined || _objReq[propName] === '' || _objReq[propName] === Object.assign([]) || (_.isObject(_objReq[propName]) && _.isEmpty(_objReq[propName]))) {
                            delete _objReq[propName];
                        }
                    }

                    batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}/requirements/${_objReq.documentID}`), _objReq, { merge: true });

                    if (!isVehicle && i === 0) {
                        batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`), {
                            isVehicle: true,
                            requirement: _objReq,
                            modifiedBy: _objReq.modifiedBy,
                            modifiedFrom: 'web',
                            modifiedDate: _objReq.modifiedDate
                        }, { merge: true });
                    }
                    let _makeModel = !_.isEmpty(_objReq.stock) ? (_objReq.stock.make ? _objReq.stock.make + ' ' : '') + (_objReq.stock.model ? _objReq.stock.model + ' ' : '') + (_objReq.stock.year ? _objReq.stock.year : '') : '';
                    let _logNote = !_.isEmpty(_objReq.stock) ? 'Requirement stock added' : 'Requirement added'

                    CommonHelper.saveChangeLog(_objReq.clientID, _objReq.groupID, _objReq.enquiryID, _logNote + (_makeModel ? ' (' + _makeModel + ')' : ''), 'enquiry', 'updated', batch);
                    i++;
                }


                batch.commit().then(snapshot => {
                    this.isLoading = false;
                    this.setState({
                        showStocks: false,
                        isMultiSelect: false
                    }, () => { this.props.handleClose(objData); });

                }).catch((error) => {
                    console.error("Error updating document: ", error);
                });

            }

        }
        else {
            this.setState({
                showStocks: false,
                isMultiSelect: false,
                stockModal:
                {
                    show: false,
                    ID: '',
                    prefillData: null,
                    title: '',
                    clsActive: ''
                }
            });

        }

    }

    handleStockClose = (ID, objData) => {
        if (ID) {

            const { requirements, dealersettings, enquiryID } = this.props;
            const { requirement } = this.state;


            if (objData && objData.enquiryID !== enquiryID && (objData.status === stockStatus.SOLD || objData.status === stockStatus.DELIVERED)) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Selected Vehicle has already sold in stock'), '', 'warning')
                return
            }

            let alreadyExist = !_.isEmpty(requirements) && requirements.filter(e => e.documentID !== requirement.documentID &&
                !_.isEmpty(e.stock) &&
                e.stock.documentID === ID)[0];

            if (alreadyExist) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Selected stock has already added'), '', 'warning')
                return
            }

            const userID = dealersettings ? dealersettings.id : '';
            const _objOldStock = Object.assign({}, this.state.stock)

            // MARK OLD STOCK AS AVAILABLE. IF RESERVED BY THIS USER
            if (_objOldStock && _objOldStock.documentID !== ID &&
                (_objOldStock.status === stockStatus.RESERVED ||
                    ((_objOldStock.status === stockStatus.SOLD || _objOldStock.status === stockStatus.DELIVERED) && requirement.isSold && _objOldStock.enquiryID === this.props.enquiryID)) &&
                (_objOldStock.statusModifiedBy && _objOldStock.statusModifiedBy === userID)) {
                this.handleChangeStockStatus(_objOldStock.documentID, stockStatus.AVAILABLE);
            }

            // // MARK NEW STOCK AS SOLD. IF REQUIREMENT ALREADY MARK AS SOLD
            // if (objData && (objData.status === stockStatus.AVAILABLE || objData.status === stockStatus.INPRODUCTION) && requirement.isSold) {
            //     this.handleChangeStockStatus(objData.documentID, stockStatus.SOLD);
            // }

            this.unsubscribeStock = this.refStock
                .doc(ID)
                .onSnapshot(this.onStockCollectionUpdate);

            this.setState({ isStock: true });

        }

        this.setState({
            showStocks: false,
            isMultiSelect: false,
            stockModal:
            {
                show: false,
                ID: '',
                prefillData: null,
                title: '',
                clsActive: ''
            }
        });
    }

    handleChangeStockStatus = (stockID, status) => {
        let modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();

        let objData = {
            status: status,
            statusModifiedBy: modifiedBy,
            statusModifiedFrom: 'web',
            modifiedBy: modifiedBy,
            modifiedDate: modifiedDate,
            modifiedFrom: 'web'
        }
        if (status === stockStatus.SOLD || status === stockStatus.DELIVERED) {
            objData.enquiryID = this.props.enquiryID ? this.props.enquiryID : null;

            if (status === stockStatus.SOLD)
                objData.soldDate = modifiedDate;

            if (status === stockStatus.DELIVERED) {
                objData.deliveredDate = modifiedDate;
                objData.warrantyStartDate = modifiedDate;
            }
        }

        if (status !== stockStatus.SOLD && status !== stockStatus.DELIVERED) {
            objData.soldDate = null;
            objData.deliveredDate = null;
        }

        const refStockData = window.firebase.firestore().collection('stock').doc(stockID);
        refStockData.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);

        }).catch((error) => {
            console.error("Error updating handleChangeStockStatus: ", error);
        });
    }

    handleChangeStockSaleType = (stockID, saleType) => {
        let modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();
        if (stockID && saleType)
            window.firebase.firestore().collection('stock').doc(stockID).set({
                saleType: saleType,
                modifiedBy: modifiedBy,
                modifiedDate: modifiedDate,
                modifiedFrom: 'web'
            }, { merge: true })
    }

    discardStock = () => {
        const userID = this.props.dealersettings ? this.props.dealersettings.id : '';
        const _objOldStock = Object.assign({}, this.state.stock)
        const requirement = Object.assign({}, this.state.requirement);

        // MARK OLD STOCK AS AVAILABLE. IF RESERVED BY THIS USER
        if (_objOldStock &&
            (_objOldStock.status === stockStatus.RESERVED ||
                ((_objOldStock.status === stockStatus.SOLD || _objOldStock.status === stockStatus.DELIVERED) && requirement.isSold && _objOldStock.enquiryID === this.props.enquiryID)) &&
            (_objOldStock.statusModifiedBy && _objOldStock.statusModifiedBy === userID)) {
            this.handleChangeStockStatus(_objOldStock.documentID, stockStatus.AVAILABLE);
        }

        const objStk = Object.assign({}, objStock);
        requirement.stock = Object.assign({}, {});
        this.setState({
            stock: objStk,
            requirement: requirement,
            isStock: false
        });
    };


    handleSaveStockInfo = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.stock);
        const { requirements } = this.props;
        const { requirement } = this.state;

        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        };

        if (fields.stockNo) {
            let alreadyExist = !_.isEmpty(requirements) && requirements.filter(e => e.documentID !== requirement.documentID &&
                (e.stockNo === fields.stockNo || (e.stock && e.stock.stockNo === fields.stockNo)))[0];

            if (alreadyExist) {
                formIsValid = false;
                errors['stockNo'] = errorClass;
                Swal.fire(CommonHelper.showLocale(this.props, 'Stock no. has already added'), '', 'warning')
                return
            }
        }

        if (fields.regNo) {
            let alreadyExist = !_.isEmpty(requirements) && requirements.filter(e => e.documentID !== requirement.documentID &&
                (e.regNo === fields.regNo || (e.stock && e.stock.regNo === fields.regNo)))[0];

            if (alreadyExist) {
                formIsValid = false;
                errors['regNo'] = errorClass;
                Swal.fire(CommonHelper.showLocale(this.props, 'Reg no. has already added'), '', 'warning')
                return
            }
        }

        // if (fields.vinNo) {
        //     let alreadyExist = !_.isEmpty(requirements) && requirements.filter(e => e.documentID !== fields.documentID &&
        //         (e.vinNo === fields.vinNo || (e.stock && e.stock.vinNo === fields.vinNo)))[0];

        //     if (alreadyExist) {
        //         formIsValid = false;
        //         errors['vinNo'] = errorClass;
        //         Swal.fire('VIN no. has already added', '', 'warning')
        //         return
        //     }
        // }


        if (formIsValid === true) {
            const _requirementMOdel = Object.assign({}, this.state.requirement);
            if (_requirementMOdel.make || _requirementMOdel.model) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                    text: CommonHelper.showLocale(this.props, 'dicardSelectedModelMsg'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.value) {
                        this.saveStockInfo(this);
                    }
                    else {
                        this.discardStock();
                    }
                })
            }
            else
                this.saveStockInfo(this);
        }
        else {

            this.setState({
                stockModal:
                {
                    show: true,
                    ID: fields.documentID,
                    prefillData: null,
                    title: 'stock',
                    clsActive: 'overlay-modal active'
                }
            });

            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }

    };



    saveStockInfo = (e) => {
        this.isLoading = true;

        const { dealersettings, primaryID, requirements } = this.props;
        const stock = Object.assign({}, this.state.stock);
        let objData = Object.assign({}, this.state.requirement);
        objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings);

        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (!objData.enquiryID)
            objData.enquiryID = this.props.enquiryID;

        let requirementID = objData['documentID'];

        delete objData['make'];
        delete objData['model'];
        delete objData['group'];
        delete objData['type'];
        delete objData['year'];
        delete objData['extColor'];
        delete objData['intColor'];
        delete objData['warrantyStartDate'];
        delete objData['warrantyExp'];
        delete objData['regNo'];
        delete objData['vinNo'];
        delete objData['stockNo'];
        delete objData['price'];

        const _stock = Object.assign({}, stockBasicVM);
        for (let [key, value] of Object.entries(stock)) {
            if (_stock.hasOwnProperty(key))
                _stock[key] = value;
        }

        //copy the requirement saletype into stock saletype
        let _isTypeChanged = false;
        if (objData.saleType && _stock.saleType !== objData.saleType) {
            _isTypeChanged = true;
            _stock.saleType = objData.saleType
        }

        objData.stock = Object.assign({}, _stock)

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        //console.log('saveStockInfo', objData, this.props.enquiryID)
        const refReqData = firestoreDB(dealersettings).firestore().collection(`enquiries/${this.props.enquiryID}/requirements`).doc(requirementID);
        refReqData.set(objData).then((docRef) => {
            //console.log(docRef);
            this.isLoading = false;

            // MARK NEW STOCK AS SOLD. IF REQUIREMENT ALREADY MARK AS SOLD
            if (objData.stock && (objData.stock.status === stockStatus.AVAILABLE || objData.stock.status === stockStatus.MCRETAILED || objData.stock.status === stockStatus.INPRODUCTION) && objData.isSold) {
                let _status = this.props.enqStatus === enquiryStatus.DELIEVERD ? stockStatus.DELIVERED : stockStatus.SOLD
                this.handleChangeStockStatus(objData.stock.documentID, _status);
                objData.stock.status = _status;
            }

            if (_isTypeChanged)
                this.handleChangeStockSaleType(objData.stock.documentID, objData.stock.saleType);

            if (!this.props.isVehicle || (!primaryID && !_.isEmpty(requirements)) || (primaryID && primaryID === objData.documentID)) {
                this.handleSaveReqOnEnquiry(objData);
            }

            let _makeModel = !_.isEmpty(objData.stock) ? (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') : '';
            let _logNote = !_.isEmpty(objData.stock) ? 'Requirement stock added' : 'Requirement added'
            if (!_.isEmpty(this.props.requirement))
                _logNote = !_.isEmpty(objData.stock) ? 'Requirement stock updated' : 'Requirement updated'

            CommonHelper.saveChangeLog(dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote + (_makeModel ? ' (' + _makeModel + ')' : ''), 'enquiry', 'updated');

            this.props.handleClose(objData);
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };

    handleUpdateQuote = async (id, data) => {
        if (!id) {
            return
        }

        const parseIncomingDate = date => {
            if (date.toDate) {
                return date
            } else {
                return window.firebase.firestore.Timestamp.fromDate(moment(date)._d)
            }
        };

        const setttings = this.props.dealersettings.client.settings;

        const requirementQuoteObj = {
            make: data.make ? data.make : '',
            model: data.model ? data.model : '',
            type: data.type ? data.type : '',
            year: data.year ? data.year : '',
            saleType: data.saleType ? CommonHelper.getNameByValue(setttings && setttings.salesType,
                data.saleType, '') : '',
            rego: data.regNo ? data.regNo : '',
            regoExpiry: data.regDate ? parseIncomingDate(data.regDate) : '',
            vinNo: data.vinNo ? data.vinNo : '',
            engineNo: data.engineNo ? data.engineNo : '',
            extColor: data.extColor ? data.extColor : '',
            stockNo: data.stockNo ? data.stockNo : '',
            buildDate: data.buildDate ? parseIncomingDate(data.buildDate) : '',
            odometer: data.miles ? data.miles : '',
            purchasePrice: data.purchasePrice ? data.purchasePrice : data.price ? data.price : 0,
        }
        const ref = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries`).doc(this.props.enquiryID).collection(`quotations`)
            .where('requirementID', '==', id)

        const snapshots = await ref.get()

        if (snapshots.size > 0) {
            let quotations = []
            snapshots.docs.forEach((doc) => {
                quotations.push(doc.data())
            });

            const batch = firestoreDB(this.props.dealersettings).firestore().batch();

            quotations.forEach(quote => {
                batch.set(firestoreDB(this.props.dealersettings).firestore().doc(`enquiries/${quote.enquiryID}/quotations/${quote.documentID}`), {
                    vehicle: requirementQuoteObj
                }, { merge: true });
            })
            batch.commit().catch((error) => {
                console.error("Error updating document: ", error);
            });
        }
    }


    handleSaveReqOnEnquiry = (objData) => {
        //console.log('handleSaveReqOnEnquiry', objData);
        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiryID);
        updateRef.update(
            {
                isVehicle: true,
                requirement: objData,
                modifiedBy: objData.modifiedBy,
                modifiedFrom: 'web',
                modifiedDate: objData.modifiedDate
            }
        ).then((docRef) => {
            //this.setState({ stock: stock, fields: state, requirement: requirement, isReqChange: true });
        }).catch((error) => {
            console.error("Error updating enquiries: ", error);
        });
    }

    //#endregion

    //#region CHANGE EVENT REQUIREMENT
    errorReqChange = (key) => {
        let errors = Object.assign({}, this.state.errorsReq);
        let fields = Object.assign({}, this.state.requirement);
        let errorClass = 'input_error';
        if (!_.isEmpty(errors)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errorsReq: errors });
        }
    }

    handleOnReqChange = (e) => {
        let state = Object.assign({}, this.state.requirement);
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            state[name] = newarray1.join(' ');
            this.setState({ requirement: state }, () => { this.errorReqChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            state[name] = str;
            this.setState({ requirement: state }, () => { this.errorReqChange(name); });
        }
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.requirement);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ requirement: fields }, () => { this.errorReqChange(name); });

    }

    handleReqDateChange = (val, name) => {
        let state = Object.assign({}, this.state.requirement);
        if (val)
            state[name] = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        else
            state[name] = null;
        this.setState({ requirement: state }, () => { this.errorReqChange(name); });

    };

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let state = Object.assign({}, this.state.requirement);
        state[name] = e.floatValue;
        this.setState({ requirement: state }, () => { this.errorReqChange(name); });

    }

    handleReservedChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.stock);
        state[name] = checked;
        this.setState({ stock: state });
        if (checked) {
            this.handleChangeStockStatus(this.state.stock.documentID, stockStatus.RESERVED)
        }
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, this.state.requirement);
        let _reqOtherInfo = state.reqOtherInfo;

        if (checked)
            _reqOtherInfo.push(name)
        else
            _reqOtherInfo = _reqOtherInfo.filter(m => m !== name);

        state.reqOtherInfo = _reqOtherInfo
        this.setState({ requirement: state });
    }


    handleRequirementSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.requirement);
        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ requirement: state }, () => {
            this.errorReqChange(data.name);
            if (data.name === 'saleType') this.loadMakeAndModels();
        });

    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.requirement);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ requirement: state, models: models }, () => { this.errorReqChange(data.name); });

    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.requirement);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })
        }

        state['model'] = '';
        this.setState({ requirement: state, models: models, types: _.orderBy(types, ['label'], ['asc']) }, () => { this.errorReqChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.requirement);
        const types = [];
        const groups = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            requirement: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorReqChange(data.name); });

    }

    decrementCount = (id) => {
        let state = Object.assign({}, this.state.requirement);

        if (state.quantity && state.quantity > 1)
            state.quantity = parseInt(state.quantity) - 1;

        this.setState({ requirement: state });
    }

    incrementCount = (id) => {
        let state = Object.assign({}, this.state.requirement);
        state.quantity = (state.quantity ? parseInt(state.quantity) : 0) + 1;
        this.setState({ requirement: state });
    }


    handleSaveReqInfo = async (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.requirement);
        const { requirements } = this.props;

        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        };

        if (fields.stockNo) {
            let alreadyExist = !_.isEmpty(requirements) && requirements.filter(e => e.documentID !== fields.documentID &&
                (e.stockNo === fields.stockNo || (!_.isEmpty(e.stock) && e.stock.stockNo === fields.stockNo)))[0];

            if (alreadyExist) {
                formIsValid = false;
                errors['stockNo'] = errorClass;
                Swal.fire(CommonHelper.showLocale(this.props, 'Stock no. has already added'), '', 'warning')
                return
            }
        }

        if (fields.regNo) {
            let alreadyExist = !_.isEmpty(requirements) && requirements.filter(e => e.documentID !== fields.documentID &&
                (e.regNo === fields.regNo || (!_.isEmpty(e.stock) && e.stock.regNo === fields.regNo)))[0];

            if (alreadyExist) {
                formIsValid = false;
                errors['regNo'] = errorClass;
                Swal.fire(CommonHelper.showLocale(this.props, 'Reg no. has already added'), '', 'warning')
                return
            }
        }

        if (fields.vinNo) {
            let alreadyExist = !_.isEmpty(requirements) && requirements.filter(e => e.documentID !== fields.documentID &&
                (e.vinNo === fields.vinNo || (!_.isEmpty(e.stock) && e.stock.vinNo === fields.vinNo)))[0];

            if (alreadyExist) {
                formIsValid = false;
                errors['vinNo'] = errorClass;
                Swal.fire(CommonHelper.showLocale(this.props, 'VIN no. has already added'), '', 'warning')
                return
            }
        }

        if (fields.warrantyStartDate && fields.warrantyExp && (moment.unix(fields.warrantyStartDate.seconds)._d > moment.unix(fields.warrantyExp.seconds)._d)) {
            formIsValid = false;
            errors.warrantyExp = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'Warranty Expiry date should be greater than Start date'), '', 'info')
        }

        this.setState({ errorsReq: errors });

        if (formIsValid === true) {

            const _stockModel = Object.assign({}, this.state.stock);
            if (_stockModel.documentID) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                    text: CommonHelper.showLocale(this.props, 'dicardSelectedStockMsg'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.value) {
                        this.checkReqInfo();
                    }
                    else {
                        this.discardRequirement();
                    }
                })
            }
            else
                this.checkReqInfo();

        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }

    };


    checkReqInfo = async () => {
        const { enableServicePackages, requirement } = this.state
        const { isAfterSales } = this.props;

        if (localStorage.defaultModule !== 'oem' && !enableServicePackages && !isAfterSales && (requirement.stockNo || requirement.vinNo || requirement.regNo)) {
            let response = await this.handleCheckStock(requirement);
            //console.log("response", response)
            if (response && response.success) {

                if (response.data.status === stockStatus.SOLD || response.data.status === stockStatus.DELIVERED) {
                    Swal.fire('Entered ' + response.name + ' has already sold in stock.', '', 'warning')
                    return
                }

                const result = await Swal.fire({
                    title: response.message,
                    text: `Do you want to select stock with ${response.name}: ${response.value} as a requirement?`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Select'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
                })

                if (!result.value) {
                    return
                }

                this.setState({
                    stockModal:
                    {
                        show: true,
                        ID: response.data.documentID,
                        prefillData: null,
                        title: 'stock',
                        clsActive: 'overlay-modal active'
                    }
                });
                return false;
            }
            else {
                let _msg = ''
                if (requirement.stockNo)
                    _msg = 'Stock #'
                if (requirement.vinNo)
                    _msg = (_msg ? _msg + ', ' : '') + 'VIN #'
                if (requirement.regNo)
                    _msg = (_msg ? _msg + ' & ' : '') + 'Reg #'

                const result = await Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please add stock'),
                    text: `${_msg} has been provided.`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Stock'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
                })

                if (!result.value) {
                    return
                }

                this.setState({
                    stockModal:
                    {
                        show: true,
                        ID: null,
                        prefillData: requirement,
                        title: 'addStock',
                        clsActive: 'overlay-modal active'
                    }
                });
                return false;
            }
        }
        else {
            this.saveRequirement();
        }

    }

    handleCheckStock = async (reqData) => {
        const stockPromises = [];
        const { dealersettings } = this.props;

        if (reqData && (reqData.stockNo || reqData.vinNo || reqData.regNo)) {
            const enableSharedStock = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.group) &&
                dealersettings.group.enableSharedStock) ? true : false);

            let stkLstDataRef = window.firebase.firestore().collection('stock').where('isDeleted', '==', false);

            if (localStorage.defaultModule === 'oem') {
                stkLstDataRef = stkLstDataRef.where('settingsID', '==', dealersettings.client.settingsID)
            }
            else if (enableSharedStock && dealersettings.client.group) {
                stkLstDataRef = stkLstDataRef.where('groupID', '==', dealersettings.client.group)
            }
            else {
                stkLstDataRef = stkLstDataRef.where('clientID', '==', dealersettings.client.id)
            }

            if (reqData.stockNo)
                stockPromises.push(stkLstDataRef.where('stockNo', '==', reqData.stockNo).limit(1).get())

            if (reqData.regNo)
                stockPromises.push(stkLstDataRef.where('regNo', '==', reqData.regNo).limit(1).get())

            if (reqData.vinNo)
                stockPromises.push(stkLstDataRef.where('vinNo', '==', reqData.vinNo).limit(1).get())

            let isExist = false;
            let _response;
            const snapshots = await Promise.all(stockPromises)
            if (!_.isEmpty(snapshots)) {
                snapshots.some((snap) => {
                    if (snap.size > 0) {
                        isExist = true;
                        let _stocks = [];

                        snap.forEach((doc) => {
                            if (!_stocks.some(i => i.documentID === doc.id))
                                _stocks.push({ ...doc.data(), documentID: doc.id })

                        });

                        let _stock = Object.assign({}, _stocks[0]);
                        if (_stock.stockNo === reqData.stockNo)
                            return _response = { success: true, data: _stock, name: 'Stock No', id: 'stockNo', value: reqData.stockNo, message: 'Stock #: ' + reqData.stockNo + ' already exist in stock.' };
                        else if (_stock.regNo === reqData.regNo)
                            return _response = { success: true, data: _stock, name: 'Reg. No', id: 'regNo', value: reqData.regNo, message: 'Reg #: ' + reqData.regNo + ' already exist in stock.' };
                        else if (_stock.vinNo === reqData.vinNo)
                            return _response = { success: true, data: _stock, name: 'VIN No', id: 'vinNo', value: reqData.vinNo, message: 'VIN #: ' + reqData.vinNo + ' already exist in stock.' };

                        //console.log('reqData.stockNo ', _stock, _stocks)
                    }
                    else {
                        //console.log(reqData.stockNo + ': non-exists')
                    }
                })
            }
            else {

                //console.log(reqData.stockNo + ': non-exists-valid')
            }

            if (isExist)
                return _response;
            else
                return { success: false };
        }
        else {
            return { success: false };
        }


    }

    discardRequirement = () => {
        const objReq = Object.assign({}, objRequirement);
        const requirement = Object.assign({}, this.state.requirement)
        objReq.saleType = requirement.saleType;
        objReq.reqOtherInfo = requirement.reqOtherInfo;
        objReq.stock = requirement.stock;
        this.setState({
            requirement: objReq,
            isStock: true
        });
    };

    saveRequirement = () => {
        const { requirement } = this.state;
        if (_.isEmpty(this.props.requirement) && requirement.quantity > 1) {
            this.saveMultipleRequirements(requirement.quantity);
        }
        else {
            this.saveReqInfo();
        }
    }

    saveMultipleRequirements = (_quantity) => {
        this.isLoading = true;
        const { requirements, dealersettings, enquiryID, isVehicle } = this.props;
        const batch = firestoreDB(dealersettings).firestore().batch();
        let objData = Object.assign({}, this.state.requirement);
        objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings);
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.isDeleted = false;
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.enquiryID = enquiryID;

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        for (let i = 0; i < _quantity; i++) {
            let _objReq = Object.assign({}, { ...objData });
            _objReq.documentID = this.refStock.doc().id;
            _objReq.quantity = 1;

            batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}/requirements/${_objReq.documentID}`), _objReq, { merge: true });

            if (!isVehicle && i === 0) {
                batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`), {
                    isVehicle: true,
                    requirement: _objReq,
                    modifiedBy: _objReq.modifiedBy,
                    modifiedFrom: 'web',
                    modifiedDate: _objReq.modifiedDate
                }, { merge: true });
            }
            let _makeModel = (_objReq.make ? _objReq.make + ' ' : '') + (_objReq.model ? _objReq.model + ' ' : '') + (_objReq.year ? _objReq.year : '');
            let _logNote = 'Requirement added'

            CommonHelper.saveChangeLog(_objReq.clientID, _objReq.groupID, _objReq.enquiryID, _logNote + (_makeModel ? ' (' + _makeModel + ')' : ''), 'enquiry', 'updated', batch);

        }


        batch.commit().then(snapshot => {
            this.isLoading = false;
            this.props.handleClose(objData);

        }).catch((error) => {
            console.error("Error updating document: ", error);
        });
    }

    saveReqInfo = () => {
        this.isLoading = true;
        const { dealersettings, primaryID, requirements } = this.props;
        const userID = localStorage.uid;
        let objData = Object.assign({}, this.state.requirement);
        objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings);

        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = userID
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }
        objData.modifiedBy = userID
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!objData.enquiryID)
            objData.enquiryID = this.props.enquiryID;

        let requirementID = objData['documentID'];

        delete objData['stock'];
        const refReqData = firestoreDB(dealersettings).firestore().collection(`enquiries/${this.props.enquiryID}/requirements`).doc(requirementID);
        refReqData.set(objData).then((docRef) => {
            //console.log(docRef);
            this.isLoading = false;

            if (!this.props.isVehicle || (!primaryID && !_.isEmpty(requirements)) || (primaryID && primaryID === objData.documentID)) {
                this.handleSaveReqOnEnquiry(objData);
            }

            const _objOldStock = Object.assign({}, this.state.stock)
            // MARK OLD STOCK AS AVAILABLE. IF RESERVED BY THIS USER
            if (_objOldStock &&
                (_objOldStock.status === stockStatus.RESERVED ||
                    ((_objOldStock.status === stockStatus.SOLD || _objOldStock.status === stockStatus.DELIVERED) && objData.isSold && _objOldStock.enquiryID === this.props.enquiryID))
                && (_objOldStock.statusModifiedBy && _objOldStock.statusModifiedBy === userID)) {
                this.handleChangeStockStatus(_objOldStock.documentID, stockStatus.AVAILABLE);
            }

            let _makeModel = (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');
            let _logNote = 'Requirement added'
            if (!_.isEmpty(this.props.requirement))
                _logNote = 'Requirement updated'

            CommonHelper.saveChangeLog(this.props.dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote + (_makeModel ? ' (' + _makeModel + ')' : ''), 'enquiry', 'updated');

            if (!_.isEmpty(this.props.requirement))
                this.handleUpdateOwnedVehicle(objData);
            else
                this.handleSaveOwnedVehicle(objData);

            this.props.handleClose(objData);
            if (this.props.enquiryID) {
                this.handleUpdateQuote(requirementID, objData)
            }
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };

    handleSaveOwnedVehicle = (_requirement) => {

        const { enableServicePackages } = this.state;
        const { ownedVehicles, contactID, requirement, dealersettings } = this.props;
        if (enableServicePackages && contactID && _.isEmpty(requirement)) {

            let isExists = ownedVehicles && ownedVehicles.filter(m =>
                (_requirement.vinNo ? m.vinNo === _requirement.vinNo : false) ||
                (_requirement.regNo ? m.regNo === _requirement.regNo : false))[0];

            if (_.isEmpty(isExists)) {

                const objData = Object.assign({}, objOwnedVehicle);
                for (let [key, value] of Object.entries(_requirement)) {
                    if (objData.hasOwnProperty(key))
                        objData[key] = value;
                }
                objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
                objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                objData.documentID = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc().id;
                objData.currentlyOwned = true;
                objData.exteriorColor = _requirement.extColor ? _requirement.extColor : '';
                objData.requirementID = _requirement.documentID;
                objData.projectId = this.props.dealersettings?.client?.projectId ? this.props.dealersettings?.client?.projectId : null;

                const _dmsSettings = Object.assign({}, dealersettings?.client?.integrations?.filter(e => e.type === "cap")[0]);
                if (_dmsSettings?.active && _dmsSettings?.enabled) objData.owned = true;

                //Remove empty value from object
                for (var propName in objData) {
                    if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                        delete objData[propName];
                    }
                }

                const refStockData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${contactID}/ownedVehicles`).doc(objData.documentID);
                refStockData.set(objData, { merge: true }).then((docRef) => {
                    //console.log(docRef);				
                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });
            }
        }
    }

    handleUpdateOwnedVehicle = (_requirement) => {
        const { ownedVehicles, contactID } = this.props;
        if (contactID && !_.isEmpty(_requirement)) {

            let isExists = ownedVehicles && ownedVehicles.filter(m => m.requirementID === _requirement.documentID)[0];

            if (!_.isEmpty(isExists) && contactID) {
                const objData = Object.assign({}, isExists);
                for (let [key, value] of Object.entries(_requirement)) {
                    if (objData.hasOwnProperty(key) && key !== 'documentID' && key !== 'addedBy' && key !== 'addedDate' && key !== 'addedFrom')
                        objData[key] = value;
                }

                objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                objData.modifiedFrom = 'web';
                objData.exteriorColor = _requirement.extColor ? _requirement.extColor : '';
                objData.projectId = this.props.dealersettings?.client?.projectId ? this.props.dealersettings?.client?.projectId : null;

                //Remove empty value from object
                for (var propName in objData) {
                    if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                        delete objData[propName];
                    }
                }

                const refStockData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${contactID}/ownedVehicles`).doc(objData.documentID);
                refStockData.set(objData, { merge: true }).then((docRef) => {
                    //console.log(docRef);				
                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });
            }
            else {
                this.handleSaveOwnedVehicle(_requirement);
            }
        }
    }

    //#endregion


    //#region CHANGE EVENT ADD_ONS  
    handleAddOnsOpen = () => {
        this.setState({ showAddOns: true });
    }

    handleAddOnsClose = (data, isValid) => {
        if (isValid) {
            let state = Object.assign({}, this.state.requirement);
            state['addOns'] = Object.assign([], data);
            this.setState({ requirement: state });
        }
        this.setState({ showAddOns: false });
    }

    handleOwnedVehicleOpen = () => {
        this.setState({ showOwnedVehicles: true });
    }

    handleOwnedVehicleClose = (_selectedVehicle) => {
        if (!_.isEmpty(_selectedVehicle)) {
            const { dealersettings } = this.props;

            const _requirement = Object.assign({}, this.state.requirement);
            const objReqData = Object.assign({}, _selectedVehicle);
            for (let [key, value] of Object.entries(objReqData)) {
                if (_requirement.hasOwnProperty(key))
                    _requirement[key] = value ? value : '';
            }
            this.setState({ requirement: _requirement });

            if (_selectedVehicle.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
                const { makes } = this.state;
                const types = [];
                const models = [];
                const groups = [];
                if (_selectedVehicle.make) {
                    let _models = !_.isEmpty(makes.filter(m => m.value === _selectedVehicle.make)[0]) ? makes.filter(m => m.value === _selectedVehicle.make)[0].models : [];
                    _models && _models.filter((model, index) => {
                        if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                            types.push({
                                value: model.type,
                                label: model.type,
                                active: _.isBoolean(model.active) ? model.active : true,
                            });
                        }
                        if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                            groups.push({
                                value: model.group,
                                active: _.isBoolean(model.active) ? model.active : true,
                                label: model.group
                            });
                        }
                    })

                    _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                        models.push({
                            value: data.value,
                            label: CommonHelper.displayModelName(data),
                            active: _.isBoolean(data.active) ? data.active : true,
                        });
                    });
                }
                this.setState({
                    types: _.orderBy(types, ['label'], ['asc']),
                    groups: _.orderBy(groups, ['label'], ['asc']),
                    models: models
                });
            }
        }
        this.setState({ showOwnedVehicles: false });
    }

    handleRemove = (value) => {
        let state = Object.assign({}, this.state.requirement);
        let data = Object.assign([], state.addOns);
        data = data.filter(m => m.value !== value)
        state['addOns'] = data;
        this.setState({ requirement: state });
    }

    showTotalCost = (currencySymbol) => {
        let addOns = Object.assign([], this.state.requirement.addOns);
        if (!_.isEmpty(addOns)) {
            var total = 0;
            for (var i = 0; i < addOns.length; i++) {
                total = total + (addOns[i].price ? addOns[i].price : 0);
            }
            return <NumberFormat value={total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
        }
        else {
            return <NumberFormat value={0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
        }
    }

    //#endregion
    render() {

        const {

            make, model, saleType, type, quantity, addOns, group,
            year, price, extColor, intColor, stock, reqOtherInfo,
            vinNo, regNo, stockNo, warrantyExp, warrantyStartDate,
            transmission
        } = this.state.requirement;

        const { stockModal, isStock, requirement,
            errorsStock, errorsReq, serviceAddOns, groups,
            makes, types, models, years, enableServicePackages,
            isMultiSelect, showStocks, showAddOns, showOwnedVehicles
        } = this.state

        const { isDeivered, show, dealersettings, contactID, isAfterSales, primaryID } = this.props;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');


        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const modifyVehicleInfo = ((!_.isEmpty(_permissions) && _permissions.modifyVehicleInfo && localStorage.defaultModule !== 'oem') ? true : false);
        const addStock = ((!_.isEmpty(_permissions) && _permissions.addStock) ? true : false);

        const isReadOnlyAccess = isDeivered && modifyVehicleInfo === false ? true : false;

        return (
            this.newDocID
                ?
                <>
                    <Modal show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-requirement"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5 className="modal-title">
                                    <Translate text={isDeivered ? 'requirement' : this.props.title} />
                                    {
                                        ((enableServicePackages || isAfterSales) && !_.isEmpty(addOns))
                                            ?
                                            <>
                                                <div className="float-right damage-head"><Translate text={'Total Cost'} /><span className="badge badge-pill badge-offer-price">
                                                    {this.showTotalCost(currencySymbol)}
                                                </span>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </h5>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="container-fluid">
                                <div className={`row ${isReadOnlyAccess ? 'form-disable' : ''}`}>
                                    <div className=" pop-layout form-style requirement-pop">

                                        {
                                            localStorage.defaultModule === 'oem' || enableServicePackages || isAfterSales || isDeivered
                                                ?
                                                <></>
                                                :
                                                <>
                                                    <div className="requirement-pop-head">
                                                        <div className="radio-tab">
                                                            <div className="form-check">
                                                                <div className="form-radio-tabs">
                                                                    <InputRadio
                                                                        id="Model"
                                                                        type="radio"
                                                                        value="Model"
                                                                        name="isStock"
                                                                        className="form-check-input form-radio-tab-child"
                                                                        checked={isStock === false ? true : false}
                                                                        onChange={this.handleStockChange}
                                                                    />
                                                                    <label htmlFor="Model" className="radio-inline left-border"><Translate text={'model'} /></label>

                                                                    <InputRadio
                                                                        id="Stock"
                                                                        type="radio"
                                                                        value="Stock"
                                                                        name="isStock"
                                                                        className={`form-check-input form-radio-tab-child`}
                                                                        checked={isStock === true ? true : false}
                                                                        onChange={this.handleStockChange}
                                                                    //disabled={localStorage.defaultModule === 'oem' ? true : false}
                                                                    />
                                                                    <label htmlFor="Stock" className="radio-inline right-border" ><Translate text={'stock'} /></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                        }
                                        {
                                            enableServicePackages || isAfterSales
                                                ?
                                                <></>
                                                :
                                                <>
                                                    <div className="requirement-pop-inner mb-0">
                                                        <div className="requirement-section-head mt-3"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehicleSaleType', 'reqSaleType')} /></div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                {/* <label ><Translate text={'saleType'} /></label> */}
                                                                <ReactSelect
                                                                    options={sale_type}
                                                                    name={"saleType"}
                                                                    placeholder={'select sale type'}
                                                                    onChange={this.handleRequirementSelectChange}
                                                                    value={saleType}
                                                                    classNamePrefix={`${errorsReq["saleType"]} basic-select`}
                                                                    menuPlacement='bottom'
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                        }

                                        {
                                            (isStock)
                                                ?
                                                <>
                                                    {/* STOCK INFO */}

                                                    {
                                                        (!_.isEmpty(stock))
                                                            ?
                                                            <>
                                                                <div className="requirement-pop-middle">
                                                                    <div className="requirement-section-head"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, "vehicleInformation", "reqInformation")} />
                                                                        {
                                                                            isDeivered
                                                                                ?
                                                                                <>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div className="change-vehicle">
                                                                                        <button className="btn btn-sm btn-default" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.setState({
                                                                                                showStocks: true,
                                                                                                isMultiSelect: false,
                                                                                            });
                                                                                        }}><i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i> <Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'changeVehicle', 'changeRequirement')} /></button>
                                                                                    </div>
                                                                                </>
                                                                        }

                                                                    </div>
                                                                    <StockInfo
                                                                        requirement={requirement}
                                                                        stock={this.state.stock}
                                                                        reqOtherInfo={this.state.requirementOtherInfo}
                                                                        ShowFullDetails={true}
                                                                        handleEdit={this.handleEditStock}
                                                                        enquiryID={this.props.enquiryID}
                                                                        dealersettings={this.props.dealersettings}
                                                                        clientUsers={this.props.clientUsers}
                                                                        groupUsers={this.props.groupUsers}
                                                                        isDeivered={isDeivered}
                                                                        isPrimary={primaryID && primaryID === requirement?.documentID ? true : false}
                                                                        removeDelete={true}
                                                                    />

                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="vehicle-add-wrap-popup">
                                                                    <div className={`vechicle-stock-poup-wrap ${errorsStock["stock"]}`}>
                                                                        <div className="text-center pt-4 pb-4">
                                                                            <h3 className="mb-3"><Translate text={'noStockHasBeenSelected'} /></h3>
                                                                            <button
                                                                                className={`btn btn-sm btn-default`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({
                                                                                        showStocks: true,
                                                                                        isMultiSelect: true,
                                                                                    });
                                                                                }} >
                                                                                <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i> <Translate text={'searchFromExistingStocks'} />
                                                                            </button>
                                                                            {
                                                                                addStock
                                                                                    ?
                                                                                    <><button type="button"
                                                                                        className="btn btn-primary ml-2"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleAddStock();
                                                                                        }}>
                                                                                        <i className="ico icon-add"></i> <Translate text={'addStock'} />
                                                                                    </button></>
                                                                                    :
                                                                                    <></>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {/* MODEL INFO */}
                                                    <div className="requirement-pop-middle">
                                                        <div className="requirement-section-head"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, "vehicleInformation", "reqInformation")} />
                                                            {
                                                                (enableServicePackages || isAfterSales) && contactID && !isDeivered
                                                                    ?
                                                                    <>
                                                                        <div className=" float-right linked-activities-add">
                                                                            <button type="button" className={`btn btn-secondary float-right`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleOwnedVehicleOpen();
                                                                                }}>
                                                                                <i className="ico icon-search"></i> <Translate text={'Search From Customer Owned Vehicle'} />
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                            }
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label> <Translate text={'make'} /></label>
                                                                <ReactSelect
                                                                    options={makes}
                                                                    name={"make"}
                                                                    placeholder={'select make'}
                                                                    onChange={this.handleMakeChange}
                                                                    value={make}
                                                                    classNamePrefix={`${errorsReq["make"]} basic-select`}
                                                                    AddUnAvailable={true}
                                                                    menuPlacement='bottom'
                                                                //isDisabled={isDeivered && _.isEmpty(errorsReq["make"])}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label><Translate text={'group'} /></label>
                                                                <ReactSelect
                                                                    options={groups}
                                                                    name={"group"}
                                                                    placeholder={'select group'}
                                                                    onChange={this.handleGroupChange}
                                                                    value={group}
                                                                    classNamePrefix={`${errorsReq["group"]} basic-select`}
                                                                    AddUnAvailable={true}
                                                                    menuPlacement='bottom'
                                                                //isDisabled={isDeivered && _.isEmpty(errorsReq["group"])}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label><Translate text={'type'} /></label>
                                                                <ReactSelect
                                                                    options={types}
                                                                    name={"type"}
                                                                    placeholder={'select type'}
                                                                    onChange={this.handleTypeChange}
                                                                    value={type}
                                                                    classNamePrefix={`${errorsReq["type"]} basic-select`}
                                                                    AddUnAvailable={true}
                                                                    menuPlacement='bottom'
                                                                //isDisabled={isDeivered && _.isEmpty(errorsReq["type"])}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label> <Translate text={'model'} /></label>
                                                                <ReactSelect
                                                                    options={models}
                                                                    name={"model"}
                                                                    placeholder={'select model'}
                                                                    onChange={this.handleRequirementSelectChange}
                                                                    value={model}
                                                                    classNamePrefix={`${errorsReq["model"]} basic-select`}
                                                                    AddUnAvailable={true}
                                                                    menuPlacement='bottom'
                                                                //isDisabled={isDeivered && _.isEmpty(errorsReq["model"])}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={'year'} /></label>
                                                                <ReactSelect
                                                                    options={years}
                                                                    name={"year"}
                                                                    placeholder={'select year'}
                                                                    onChange={this.handleRequirementSelectChange}
                                                                    value={year}
                                                                    classNamePrefix={`${errorsReq["year"]} basic-select`}
                                                                    menuPlacement='bottom'
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label> <Translate text={'exteriorColor'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'exterior colour'}
                                                                    className={`form-control ${errorsReq["extColor"]}`}
                                                                    name="extColor"
                                                                    onChange={this.handleOnReqChange}
                                                                    value={extColor}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={'interiorColor'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'interior colour'}
                                                                    className={`form-control ${errorsReq["intColor"]}`}
                                                                    name="intColor"
                                                                    onChange={this.handleOnReqChange}
                                                                    value={intColor}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={'warrantyStartDate'} /></label>
                                                                <AntDatePicker
                                                                    value={warrantyStartDate ? moment.unix(warrantyStartDate.seconds).format('YYYY-MM-DD') : null}
                                                                    name={'warrantyStartDate'}
                                                                    onChange={(e) => { this.handleReqDateChange(e, 'warrantyStartDate') }}
                                                                    format='DD/MM/YYYY'
                                                                    placeholder='DD/MM/YYYY'
                                                                    className={`form-control ${errorsReq["warrantyStartDate"]}`}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={'warrantyExpiry'} /></label>
                                                                <AntDatePicker
                                                                    value={warrantyExp ? moment.unix(warrantyExp.seconds).format('YYYY-MM-DD') : null}
                                                                    name={'warrantyExp'}
                                                                    onChange={(e) => { this.handleReqDateChange(e, 'warrantyExp') }}
                                                                    format='DD/MM/YYYY'
                                                                    placeholder='DD/MM/YYYY'
                                                                    className={`form-control ${errorsReq["warrantyExp"]}`}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={'stockNo'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'stock no'}
                                                                    className={`form-control ${errorsReq["stockNo"]}`}
                                                                    name="stockNo"
                                                                    onChange={this.handleOnCapChange}
                                                                    value={stockNo}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={'vinNo'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'vin no'}
                                                                    className={`form-control ${errorsReq["vinNo"]}`}
                                                                    name="vinNo"
                                                                    onChange={this.handleOnCapChange}
                                                                    value={vinNo}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={'regNo'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'reg no'}
                                                                    className={`form-control ${errorsReq["regNo"]}`}
                                                                    name="regNo"
                                                                    onChange={this.handleOnCapChange}
                                                                    value={regNo}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label><Translate text={'transmission'} /></label>
                                                                <ReactSelect
                                                                    options={transmissions}
                                                                    name={"transmission"}
                                                                    placeholder={'select transmission'}
                                                                    onChange={this.handleRequirementSelectChange}
                                                                    value={transmission}
                                                                    classNamePrefix={`${errorsReq["transmission"]} basic-select`}
                                                                >
                                                                </ReactSelect>
                                                            </div>


                                                            {
                                                                enableServicePackages || isAfterSales
                                                                    ?
                                                                    <></>
                                                                    :
                                                                    <>
                                                                        <div className="form-group col-md-6">
                                                                            <label> <Translate text={'price'} /></label>
                                                                            <NumberFormat
                                                                                id={'price'}
                                                                                decimalScale={2}
                                                                                allowNegative={true}
                                                                                thousandSeparator={true}
                                                                                prefix={currencySymbol}
                                                                                placeholder={currencySymbol}
                                                                                className={`form-control ${errorsReq["price"]}`}
                                                                                onValueChange={this.onCurrencyChange.bind(this, 'price')}
                                                                                value={price ? price : null}
                                                                            />
                                                                        </div>
                                                                    </>
                                                            }


                                                            {
                                                                _.isEmpty(this.props.requirement) &&
                                                                (<>
                                                                    <div className="form-group col-md-6">
                                                                        <label> <Translate text={'quantity'} /></label>
                                                                        <div className="input-group input-group-grey">
                                                                            <span className="input-group-prepend">
                                                                                <button className="btn btn-default " data-dir="dwn"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.decrementCount();

                                                                                    }}>
                                                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                                                </button>
                                                                            </span>
                                                                            <NumberFormat
                                                                                id={'quantity'}
                                                                                isAllowed={(values) => {
                                                                                    const { floatValue } = values;
                                                                                    return floatValue >= 1 && floatValue <= 1000;
                                                                                }}
                                                                                className={`form-control text-center`}
                                                                                onValueChange={this.onCurrencyChange.bind(this, 'quantity')}
                                                                                value={quantity ? quantity : null}
                                                                            />
                                                                            <span className="input-group-append">
                                                                                <button className="btn btn-default" data-dir="up"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.incrementCount();

                                                                                    }}>
                                                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>)
                                                            }


                                                        </div>
                                                    </div>
                                                </>
                                        }

                                        {
                                            enableServicePackages || isAfterSales
                                                ?
                                                <></>
                                                :
                                                <>

                                                    <div className="requirement-pop-inner">
                                                        <div className="requirement-section-head"><Translate text={'otherInfo'} /></div>

                                                        <div className="form-row pt-2 pb-3">
                                                            {this.state.requirementOtherInfo && this.state.requirementOtherInfo.map(info => (
                                                                <div key={info.value} className="form-group col-md-6">
                                                                    <div className="text-left list-border" >
                                                                        {info.name}
                                                                        <span className="switch switch-sm float-right">
                                                                            <InputCheckBox
                                                                                className="switch"
                                                                                id={info.value}
                                                                                name={info.value}
                                                                                checked={(!_.isEmpty(reqOtherInfo) && reqOtherInfo.indexOf(info.value) >= 0) ? true : false}
                                                                                onChange={(e) => { this.handleCheckChange(e) }} />
                                                                            <label htmlFor={info.value}></label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </>
                                        }


                                        {
                                            (enableServicePackages || isAfterSales) && (!_.isEmpty(serviceAddOns) || !_.isEmpty(addOns)) && !isStock
                                                ?
                                                <>
                                                    <div className="requirement-pop-inner">
                                                        <div className="requirement-section-head">{'Service Packages'}
                                                            {
                                                                isDeivered
                                                                    ?
                                                                    <>
                                                                    </>
                                                                    :
                                                                    <><div className=" float-right linked-activities-add">
                                                                        <button type="button" className="btn btn-primary float-right"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.handleAddOnsOpen();
                                                                            }}>
                                                                            <i className="ico icon-add"></i> <Translate text={'Add Packages'} /></button>
                                                                    </div></>
                                                            }
                                                        </div>
                                                        <div className="service-packages-container">
                                                            {
                                                                !_.isEmpty(addOns)
                                                                    ?
                                                                    <>
                                                                        {
                                                                            addOns.map((info, index) => {
                                                                                return <div key={index} className="service-packages-box">
                                                                                    {
                                                                                        isDeivered
                                                                                            ?
                                                                                            <>
                                                                                            </>
                                                                                            :
                                                                                            <><div className="input-readonly-close"><a href="#" onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.handleRemove(info.value);
                                                                                            }}><i className="ico icon-remove"></i> </a></div></>
                                                                                    }
                                                                                    <div className="flex-package">
                                                                                        <div className="package-item-icon"> <i className="ico icon-service-package"> </i> </div>
                                                                                        <div className="package-data">
                                                                                            <div className="package-item-title">{info.name}</div>
                                                                                            <div className="package-item-info"> {(info.price || info.price === 0) ?
                                                                                                <NumberFormat value={info.price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                                                : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="spinner-loader">
                                                                            <div className="no-cafe-flex justify-content-center align-items-center ">
                                                                                <div className="no-cafe-img">
                                                                                    <i className="ico icon-service-package"></i> </div>
                                                                                <div className="no-data-txt mt-2"> <Translate text={'No packages added'} /></div>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                            }

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        {
                            isReadOnlyAccess
                                ?
                                <>
                                </>
                                :
                                <>
                                    <Modal.Footer className={`${this.isLoading ? 'form-disable' : ''}`}>
                                        <button
                                            type="button"
                                            className="btn btn-default float-left"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.handleClose();

                                            }}
                                        ><Translate text={'cancel'} />
                                        </button>
                                        {
                                            isStock
                                                ?
                                                <>
                                                    {
                                                        (!_.isEmpty(stock))
                                                            ?
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary float-right"
                                                                    onClick={(e) => this.handleSaveStockInfo(e)}
                                                                >
                                                                    {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                    <Translate text={'save'} />
                                                                </button>

                                                            </>
                                                            :
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary float-right"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();

                                                                        let errors = {};
                                                                        errors['stock'] = 'input_error';
                                                                        this.setState({ errorsStock: errors });
                                                                    }}
                                                                >
                                                                    <Translate text={'save'} />
                                                                </button>
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary float-right"
                                                        onClick={(e) => this.handleSaveReqInfo(e)}
                                                    >
                                                        {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                        <Translate text={'save'} />
                                                    </button>
                                                </>
                                        }

                                    </Modal.Footer>
                                </>
                        }
                    </Modal>

                    <PopUpModal show={stockModal.show}>
                        <AddStock
                            docID={stockModal.ID}
                            show={stockModal.show}
                            clsActive={stockModal.clsActive}
                            handleClose={this.handleStockClose}
                            title={stockModal.title}
                            prefillData={stockModal.prefillData}
                            enableSale={true}
                            enableTestDrive={false}
                            enableLoan={false}
                            dealersettings={this.props.dealersettings}
                            clientUsers={this.props.clientUsers}
                            groupUsers={this.props.groupUsers}
                            mandatoryFields={this.mandatoryFields}
                        >
                        </AddStock>
                    </PopUpModal>

                    <PopUpModal show={showStocks}>
                        <Stocks {...this.props}
                            show={showStocks}
                            handleClose={this.handleStockClose}
                            handleMultiStockClose={this.handleMultiStockClose}
                            handleAdd={this.handleAddStock}
                            showAdd={true}
                            displayPrice={true}
                            availableType={stockAvail.SALE}
                            fromRequirement={true}
                            isMultiStockSelect={isMultiSelect}
                        />
                    </PopUpModal>

                    <PopUpModal show={showAddOns}>
                        <AddOns
                            show={showAddOns}
                            dealersettings={this.props.dealersettings}
                            handleClose={this.handleAddOnsClose}
                            serviceAddOns={serviceAddOns}
                            selectedAddOns={addOns}
                        />
                    </PopUpModal>
                    <PopUpModal show={showOwnedVehicles}>
                        <OwnedVehicles
                            dealersettings={this.props.dealersettings}
                            show={showOwnedVehicles}
                            handleClose={this.handleOwnedVehicleClose}
                            contactID={contactID}
                        />
                    </PopUpModal>

                </>
                :
                <></>

        );
    }
}
