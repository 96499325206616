import React from 'react';
import moment from 'moment';
import _ from 'lodash'
import CommonHelper from '../../services/common'
const CustomEvent = React.memo((props) => {
    const { event, children, onSelectEvent, label } = props;
    var eventtype = 'todo';
    var teammates = false;
    var ownerName = event.ownerName;
    if (event.isDone === false && event.start < moment().startOf('day')._d) {
        eventtype = 'overdue';
    }
    else if (event.isDone === true) {
        eventtype = 'completed';
    }

    if (event.allUsers && event.allUsers.includes(localStorage.uid) && event.owner !== localStorage.uid) {
        teammates = true;
    }

    if (props.viewstate === 'day') {
        ownerName = event.createdByName || event.ownerName
    }

    return (props.viewstate === 'month' || props.type === 'date') ? (<div title={`${moment.unix(event.startDate.seconds).format('hh:mm A')} ${event.title}${!_.isEmpty(event.contact) ? ' - ' + CommonHelper.displayContactName([], event.contact) : ''} (${ownerName})`} data-id={event.documentID} className={`${children.props.className} calendar-event-cell day-${eventtype} ${teammates ? `border-left-${eventtype}` : ''}`} style={{ ...children.props.style }} onClick={() => onSelectEvent(event)}>
        <div className={`${children.props.children.props.className}`}>
            <i className={`ico ${CommonHelper.activityIconByValue(props.dealersettings, event.subType)}`}></i>{moment.unix(event.startDate.seconds).format('hh:mm A')}  {event.title}{!_.isEmpty(event.contact) ? ' - ' + CommonHelper.displayContactName([], event.contact) : ''} ({ownerName})
        </div>
    </div>) :
        (
            <div title={`${children.props.title}${!_.isEmpty(event.contact) ? ' - ' + CommonHelper.displayContactName([], event.contact) : ''} (${ownerName})`} className={`${children.props.className} calendar-event-cell day-${eventtype} ${teammates ? `border-left-${eventtype}` : ''}`} style={{ ...children.props.style }} onClick={() => onSelectEvent(event)}>
                <div className={`${children.props.children[0].props.className}`}>{label}</div>
                <div className={`${children.props.children[1].props.className}`}>
                    <div className="calendar-week-icon"><i className={`ico ${CommonHelper.activityIconByValue(props.dealersettings, event.subType)}`}></i> {event.title}{!_.isEmpty(event.contact) ? ' - ' + CommonHelper.displayContactName([], event.contact) : ''} ({ownerName})</div>
                </div>
            </div>
        );
})

export default CustomEvent