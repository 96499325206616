import React from 'react';

import Mailchimp from './index.js'
import Vision from './vision/index.js'
import Mailup from './mailup/index.js'
import Mailjet from './mailjet/index.js'
import toast from 'toasted-notes'
import _ from 'lodash'

const EmailCampaignContainer = (props) => {
	let isVisionEnabled = false
	let isMailchimpEnabled = false
	let isMailupEnabled = false
	let isMailjetEnabled = false
	let API_KEY = ''
	let mailupIntegrationSettings = {}

	const vision6Integration = props?.dealersettings?.client?.integrations?.filter(b => b.active && b.enabled && b.type === 'vision6')[0]
	const mailchimpIntegration = props?.dealersettings?.client?.integrations?.filter(b => b.active && b.enabled && b.type === 'mailchimp')[0]
	const mailupIntegration = props?.dealersettings?.client?.integrations?.filter(b => b.active && b.enabled && b.type === 'mailup')[0]
	const mailjetIntegration = props?.dealersettings?.client?.integrations?.filter(b => b.active && b.enabled && b.type === 'mailjet')[0]
	const mailjetModule = props?.dealersettings?.client?.moduleSettings?.mailjet?.active && props?.dealersettings?.client?.moduleSettings?.mailjet?.enabled;
	if (vision6Integration && !_.isEmpty(vision6Integration.settings) && vision6Integration.settings.api_key) {
		isVisionEnabled = true
		API_KEY = vision6Integration.settings.api_key
	}
	if (mailchimpIntegration && !_.isEmpty(mailchimpIntegration.settings) && mailchimpIntegration.settings.api_key) {
		isMailchimpEnabled = true
	}
	if (mailupIntegration && !_.isEmpty(mailupIntegration.settings) && mailupIntegration.settings.access_token) {
		isMailupEnabled = true;
		mailupIntegrationSettings = mailupIntegration.settings;
	}
	if (mailjetIntegration || mailjetModule) {
		isMailjetEnabled = true;
	}
	if (isVisionEnabled === false && isMailchimpEnabled === false && isMailupEnabled === false && isMailupEnabled) {
		props.history.push('/settings/integrations')
		toast.notify('Please integrate mailchimp or vision6 or mailup or mailjet.', {
			duration: 2000
		})
	}

	//console.log('isMailchimpEnabled', isMailchimpEnabled)
	//console.log('isVisionEnabled', isVisionEnabled)


	return (
		<div>
			{/* <Mailjet {...props} API_KEY={API_KEY} /> */}
			{isVisionEnabled && !isMailchimpEnabled ? (
				<Vision {...props} API_KEY={API_KEY} />
			) : !isVisionEnabled && isMailchimpEnabled ? (
				<Mailchimp {...props} />
			) : isVisionEnabled && isMailchimpEnabled ? (
				<Mailchimp {...props} />
			) : isMailupEnabled ? (
				<Mailup {...props} {...mailupIntegrationSettings} />
			) : isMailjetEnabled ? (
				<Mailjet {...props} />
			) : (
				<></>
			)
			}
		</div >
	);
};

export default EmailCampaignContainer;