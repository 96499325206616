import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment'
import _images from '../../../images'
import { ContentOverlay, PopoverPanel, BarChart, ReactSelect, AntMonthPicker } from "../../../components";
import TargetPopover from '../../reports/targetPopover'
import { dealertargetDynCols } from '../../reports/viewModel';
import Translate from '../../../constants/translate';
import { combineLatest } from 'rxjs';
import { doc } from 'rxfire/firestore';

const DealerTarget = (props) => {
    const [loader, setLoader] = useState(true)
    const [months, setMonths] = useState(moment().format("MMM"))
    const [year, setYear] = useState(moment().format("YYYY"));
    const [targets, setTargets] = useState([])
    const [cummulativeTargets, setCummulativeTargets] = useState([])
    const [selectedType, setSelectedType] = useState([])
    const [targetPopOver, setTargetPopOver] = useState({ show: false, target: null, field: null })
    const [activeTarget, setActiveTarget] = useState(null)
    // const mStartdate = props.dealersettings.isMclaren === true ? window.firebase.firestore.Timestamp.fromDate(moment().startOf('year')._d) :
    //     window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d);
    // const mEnddate = props.dealersettings.isMclaren === true ? window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d) :
    //     window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d);
    const [mStartdate, setStartDate] = useState(props.dealersettings.isMclaren === true ?
        window.firebase.firestore.Timestamp.fromDate(moment().startOf('year')._d) :
        window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d))
    const [mEnddate, setEndDate] = useState(props.dealersettings.isMclaren === true ?
        window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d) :
        window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d))

    const [refreshData, setRefreshData] = useState(false)
    const [selectedDealer, setSelectedDealer] = useState()
    const [clientOptions, setClientOptions] = useState()
    const [dealersCount, setDealersCount] = useState([])
    const [initialLoad, setInitialLoad] = useState(true)
    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');

    useEffect(() => {
        return () => {
            window.oemdealerTargetSnap && window.oemdealerTargetSnap.unsubscribe();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props?.dealersettings?.clients))
            return;

        let _clients = _.orderBy(props.dealersettings.clients.filter(a => a.settingsID === props?.dealersettings?.oemID), 'sortOrder', 'asc').map(rec => {
            return {
                label: rec.name,
                value: rec.id
            }
        });
        setClientOptions(_clients);
        setSelectedDealer(_clients[0])
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientOptions) || !Boolean(props.dealersettings.isMclaren) || !initialLoad)
            return

        const targetPromise = [];
        clientOptions.forEach(rec => {
            targetPromise.push(doc(window.firebase.firestore().doc(`clients/${rec.value}/dealerTarget/${rec.value}`)));
        });
        window.oemdealerTargetSnap = combineLatest(targetPromise)
            .subscribe(allDocs => {
                let data = [];
                allDocs.forEach(rec => {
                    if (rec.data()?.data?.length) {
                        data.push(...rec.data().data.map(r => {
                            return {
                                ...r,
                                clientID: rec.id,
                                groupKey: r?.type ? `${r.type}-${_.orderBy(r?.subType?.split(','))?.join(',')}` : r.targetID,
                                calculationType: r.calculationType === 'amount' ? r.calculationType : 'count'
                            }
                        }))
                    }
                })
                const dataResult = getSummaryData(data);
                setLoader(false);
                let _cummulativeTarget = getCummulativeTarget(dataResult);
                setSelectedType(_cummulativeTarget[0] ? _cummulativeTarget[0].targetID : null)
                setCummulativeTargets(_cummulativeTarget);
                setDealersCount(data);
                setInitialLoad(false)
            });

    }, [clientOptions, initialLoad])

    useEffect(() => {
        if (Boolean(props.dealersettings.isMclaren) || _.isEmpty(selectedDealer)) {
            return
        }
        getTargetData();
    }, [selectedDealer])

    useEffect(() => {
        if (!refreshData) {
            return
        }
        getTargetData();
    }, [mStartdate])


    const getSummaryData = (data) => {
        const dataFormat = data.map(r => {
            return {
                ...r,
                target: parseFloat(r.target.replace(/\D/g, '')),
                actual: parseFloat(r.actual.replace(/\D/g, '')),
                groupKey: r?.type ? `${r.type}-${_.orderBy(r?.subType?.split(','))?.join(',')}` : r.targetID
            }
        });
        const dataResult = [];
        _.chain(dataFormat)
            .groupBy('groupKey')
            .map((value) => {
                return dataResult.push(..._.chain(value).groupBy('month').map((v) => {
                    return {
                        ...v[0],
                        target: _.sumBy(v, 'target'),
                        actual: _.sumBy(v, 'actual'),
                        totarget: _.sumBy(v, 'target') - _.sumBy(v, 'actual')
                    }
                }).value())
            }).value();
        return dataResult;
    }

    const getTargetData = (refreshData, hideLoader) => {
        if (!hideLoader)
            setLoader(true);
        const reportData = window.firebase.functions().httpsCallable(refreshData === true ? 'generic-widgetData' : 'generic-getData');
        reportData({
            path: refreshData === true ? `clients/${selectedDealer.value}/dealerTarget/${selectedDealer.value}` : null,
            type: props?.dealersettings?.mvwReport ? "getDealerTargetV2" : "getDealerTarget",
            params: JSON.stringify({
                clientID: selectedDealer.value,
                startDate: moment.unix(mStartdate.seconds).format('YYYY-MM-DD'),
                endDate: moment.unix(mEnddate.seconds).format('YYYY-MM-DD'),
                pipeline: !_.isEmpty(props.selectedPipeline) ? props.selectedPipeline.value : '',
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                calcCurrentStatus: calcCurrentStatus
            })
        }).then((response) => {
            setLoader(false);
            if (response.data.success && !Boolean(refreshData)) {
                setTargets(response.data.data)
                let _cummulativeTarget = getCummulativeTarget(response.data.data);
                setSelectedType(_cummulativeTarget[0] ? _cummulativeTarget[0].targetID : null)
                setCummulativeTargets(_cummulativeTarget);
            }
        }).catch(err => {
            console.log(err)
            setLoader(false);
        })
    }

    const getCummulativeTarget = (data) => {
        let _target = _.chain(data)
            .groupBy('groupKey')
            .map((value, key) => {
                return {
                    targetName: value[0].name,
                    calculationType: value[0].calculationType,
                    targetID: key,
                    chartKey: `${key}-${value[0].targetID}`,
                    counts: value.map((rec, index) => {
                        return {
                            ...rec,
                            delta: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.totarget ? -(parseInt(rec.totarget)) : 0 : null,
                            target: parseInt(rec.target),
                            actual: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.actual ? parseInt(rec.actual) : 0 : null,
                            index
                        }
                    })
                }
            }).value();
        let _cummulativeTarget = _target.map(rec => {
            return {
                ...rec,
                counts: rec.counts.map((r, i) => {
                    return {
                        ...r,
                        cummulativeTarget: _.sumBy(rec.counts.filter(a => a.index <= i), 'target'),
                        cummulativeActual: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.actual : _.sumBy(rec.counts.filter(a => a.index <= i), 'actual') : null,
                        cummulativeDelta: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.delta : _.sumBy(rec.counts.filter(a => a.index <= i), 'delta') : null
                    }
                })
            }
        })
        return _cummulativeTarget;
    }

    const handleShowTargetDetails = (e, target) => {
        setActiveTarget(target)
        setTargetPopOver({
            show: true,
            target: e.target
        });
        e.preventDefault()
    }

    useEffect(() => {
        if (initialLoad)
            return;

        const dataResult = getSummaryData(dealersCount.filter(a => props?.dashboardFilter?.clients?.length > 0 ? props.dashboardFilter.clients.includes(a.clientID) : true));
        let _cummulativeTarget = getCummulativeTarget(dataResult);
        setSelectedType(_cummulativeTarget[0] ? _cummulativeTarget[0].targetID : null)
        setCummulativeTargets(_cummulativeTarget);

    }, [props.dashboardFilter])


    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    {
                        props.dealersettings.isMclaren === true ? (<>
                            {/* <div className="form-group col-md-2 dashboard-widget-target-picker">
                                <AntMonthPicker
                                    value={moment().month(months).year(year)._d}
                                    name={'year'}
                                    onChange={val => {
                                        setStartDate(window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('year')._d))
                                        setEndDate(window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('year')._d))
                                        setMonths(moment(val).format("MMM"))
                                        setYear(moment(val).format("YYYY"))
                                        setRefreshData(true)
                                    }}
                                    placeholder='select year'
                                    className={`form-control cursor-pointer`}
                                    format={'YYYY'}
                                    picker={'year'}
                                />
                            </div> */}
                            <div className="form-group col-md-2 dashboard-widget-target-select-fix">
                                <ReactSelect
                                    options={cummulativeTargets.map(rec => {
                                        return {
                                            label: rec.targetName,
                                            value: rec.targetID
                                        }
                                    })}
                                    name={"targetType"}
                                    placeholder={'select type'}
                                    onChange={(e) => {
                                        setSelectedType(e ? e.value : null)
                                    }}
                                    value={selectedType}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                        </>) : (<>
                            <div className={`form-group dashboard-widget-target-dealer-fix`}>
                                <ReactSelect
                                    options={clientOptions}
                                    name={"client"}
                                    placeholder={'select client'}
                                    onChange={(e, data) => {
                                        setLoader(true);
                                        setSelectedDealer(e);
                                    }}
                                    value={selectedDealer?.value}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                        </>)
                    }

                    <div className="dashboard-toolbox">
                        {
                            props.dealersettings.isMclaren === true ? (<>
                                <a href="#" className="mini-button ml-1" onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.setItem('allClients', JSON.stringify(props.dealersettings.clients));
                                    let url = `/dealertargets/report/${props.dealersettings.oemID}?year=${year}&fromOEM=true`;
                                    if (props?.dashboardFilter) {
                                        Object.keys(props?.dashboardFilter).filter(a => a !== 'dealer').forEach(rec => {
                                            if (props?.dashboardFilter?.[rec]?.length > 0) {
                                                url += `&${rec}=${props.dashboardFilter[rec].join(',')}`;
                                            }
                                        })
                                    }
                                    if (props?.dashboardFilter?.clients?.length > 0) {
                                        url += `&dealer=${props.dashboardFilter.clients.join(',')}`;
                                    } else if (clientOptions?.length > 0) {
                                        url += `&dealer=${clientOptions.map(a => { return a.value }).join(',')}`;
                                    }
                                    if (props?.dashboardFilter?.dealer?.length > 0) {
                                        url += `&showdealer=true`;
                                    }
                                    window.open(url, '_blank');
                                }}> <i className="ico icon-mail-print" style={{ fontSize: '18px' }}></i></a>
                            </>) : (<a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); getTargetData(); }}> <i className="ico icon-refresh"></i></a>)
                        }   {/* <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a> */}
                    </div>
                    {
                        props.dealersettings.isMclaren === true ?
                            <h3><Translate text={'dealerTarget'} /> - {year}</h3> :
                            <h3><Translate text={'dealerTarget'} /> - {months} {year}</h3>
                    }
                </div>
                <div className="dashboard-box-body" style={{ minHeight: `${props.dealersettings.isMclaren === true ? '800px' : '390px'}` }}>
                    {
                        (loader || props.loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : props.dealersettings.isMclaren === true ? (
                            <div className="h-100" style={{ maxHeight: `800px`, minHeight: `800px`, overflow: `auto`, paddingRight: `15px` }}>
                                {
                                    !_.isEmpty(cummulativeTargets) ? cummulativeTargets.map((cTarget, index) => {
                                        return (<div key={index} className={`${selectedType === cTarget.targetID ? '' : 'hide'}`}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <td className="head-light" width="20%"></td>
                                                        {
                                                            cTarget.counts.map((_rec, _i) => {
                                                                return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="head-light" width="20%"><span className="th-blue-clr">TARGET</span></td>
                                                        {
                                                            cTarget.counts.map((_rec, _i) => {
                                                                return <td key={_i} className="count-large text-center">{`${_rec.target !== null ? `${_rec.target}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                            })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="head-light" width="20%"><span className="th-blue-clr">ACTUAL</span></td>
                                                        {
                                                            cTarget.counts.map((_rec, _i) => {
                                                                return <td key={_i} className="count-large text-center">{`${_rec.actual !== null ? `${_rec.actual}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                            })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="head-light" width="20%"><span className="th-blue-clr">DELTA</span></td>
                                                        {
                                                            cTarget.counts.map((_rec, _i) => {
                                                                return <td key={_i} className={`count-large text-center ${_rec.showRed === "true" && _rec.calculationType === 'percentage' ? 'down-ico' : ''}`}>
                                                                    {
                                                                        _rec.showRed === "false" && _rec.calculationType === 'percentage' ? <img src={_images.tickgreen} alt="" height="20" /> :
                                                                            `${_rec.delta ? `${_rec.delta}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`
                                                                    }
                                                                </td>
                                                            })
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {
                                                cTarget.calculationType === 'count' ? (<table className="table table-bordered mt-2">
                                                    <thead>
                                                        <tr>
                                                            <td className="head-light" width="20%"></td>
                                                            {
                                                                cTarget.counts.map((_rec, _i) => {
                                                                    return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="head-light" width="20%"><span className="th-blue-clr">TARGET - CUMMULATIVE</span></td>
                                                            {
                                                                cTarget.counts.map((_rec, _i) => {
                                                                    return <td key={_i} className="text-center">{_rec.cummulativeTarget}</td>
                                                                })
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="head-light" width="20%"><span className="th-blue-clr">ACTUAL - CUMMULATIVE</span></td>
                                                            {
                                                                cTarget.counts.map((_rec, _i) => {
                                                                    return <td key={_i} className="text-center">{_rec.cummulativeActual}</td>
                                                                })
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="head-light" width="20%"><span className="th-blue-clr">DELTA - CUMMULATIVE</span></td>
                                                            {
                                                                cTarget.counts.map((_rec, _i) => {
                                                                    return <td key={_i} className="text-center">{_rec.cummulativeDelta}</td>
                                                                })
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>) : (<></>)
                                            }
                                            <div className="text-center">
                                                <BarChart
                                                    id={cTarget.chartKey}
                                                    height={`400px`}
                                                    data={cTarget.counts}
                                                    columns={['target', 'actual']}
                                                    lineColumns={cTarget.calculationType === 'count' ? ['cummulativeTarget', 'cummulativeActual'] : []}
                                                    dynamicColumns={dealertargetDynCols}
                                                    xCategory={'month'}
                                                    calculationType={cTarget.calculationType}
                                                    handleShowEnquiryList={(value, startDate, endDate) => {

                                                    }}
                                                    hideBullet={true}
                                                    dynColor={true}
                                                />
                                            </div>
                                        </div>)
                                    }) : (<div className="spinner-loader h-100 p-5">
                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                            <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                            <div className="no-data-txt mt-2"><Translate text={'Target not set for selected dealer'} /> </div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        ) : (<div className="h-100" style={{ maxHeight: `390px`, minHeight: `390px`, overflow: `auto` }}>
                            {

                                !_.isEmpty(targets) ? (<table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <td className="head-light" width="30%">KPI</td>
                                            <td className="head-light text-center" width="10%">Target</td>
                                            <td className="head-light text-center" width="10%">Actual</td>
                                            <td className="head-light text-center" width="10%">To Target</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            targets.length > 0 ?
                                                targets.map((rec, index) => {
                                                    return <tr key={index}>
                                                        <td><div className="dealership-tbl cursor-pointer float-left" onClick={(e) => {
                                                            handleShowTargetDetails(e, rec)
                                                        }}>{rec.name}</div></td>
                                                        <td className="text-center">{rec.target}</td>
                                                        <td className="text-center">
                                                            <div className={rec.showRed === "true" ? 'down-ico' : 'up-ico'}>
                                                                {rec.actual}
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                rec.showRed === "true" ? (
                                                                    <span className={'down-ico ml-2'}>
                                                                        {rec.totarget}
                                                                    </span>
                                                                ) : (
                                                                    <img src={_images.tickgreen} alt="" height="20" />
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                }) : (
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="spinner-loader h-100 p-5">
                                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                    <div className="no-data-txt mt-2"> <Translate text={'Target not set for selected user / month'} /></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                        }
                                    </tbody>
                                </table>) : (<div className="spinner-loader h-100 p-5">
                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2"> <Translate text={'Target not set for selected user / month'} /></div>
                                    </div>
                                </div>)
                            }
                        </div>)
                    }
                </div>
            </div>
            <PopoverPanel
                showpopover={targetPopOver.show}
                targetpopover={targetPopOver.target}
                title={'Target Details '}
                position="right"
                className="popover-required-fields"
                closepopover={() => {
                    setTargetPopOver({ show: false, target: '', field: '' });
                }}>
                <TargetPopover
                    {...props}
                    activeTarget={activeTarget}
                />
            </PopoverPanel>
        </>
    );
}

export default DealerTarget;