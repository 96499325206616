import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { leadBucketCountVM } from './viewModel'
import Translate from '../../constants/translate';
import CommonHelper from "../../services/common";
const LeadsBucket = (props) => {
    const [counts, setCounts] = useState(leadBucketCountVM)
    const [loader, setLoader] = useState(true);
    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)

    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);


    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-leadsBucket`);

    useEffect(() => {
        return () => {
            window.unsubenquiryleadsbucketSnap && window.unsubenquiryleadsbucketSnap();
        }
    }, []);

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchData(true);
            }
        }, 900000);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchData();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return

        setLoader(true)
        window.unsubenquiryleadsbucketSnap = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setLoader(false);
                    setInitialLoad(false)
                    setCounts(snap.data().counts);
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        fetchData(true);
                    }
                }
                else {
                    fetchData();
                }
            })
    }, [initialLoad])

    const fetchData = async (hideLoader) => {
        let _filter = {
            clientID: props.dealersettings.client.id,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        // if (props.selectedPipeline) {
        //     _filter.pipeline = ''
        // }
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (!_.isEmpty(props.pipeline)) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getInboundleadsCountsV2" : "getInboundleadsCounts",
            params: JSON.stringify(_filter),
        }
        if (!hideLoader)
            setLoader(true);
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp leadsBucket', resp)
            const data = resp.data.data[0]
            const _counts = {
                todayConverted: data.todayConverted,
                monthConverted: data.monthConverted,
                pendingInboundleads: data.pendingLeads,
                lostApprovalList: data.pendingLost,
            }
            setCounts(_counts)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: _counts, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setLoader(false)
        } else {
            // toast.closeAll();
            // Swal.fire('Something went wrong.');
            setLoader(false)
        }
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            window.unsubenquiryleadsbucketSnap && window.unsubenquiryleadsbucketSnap();
            fetchData();
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchData();
    }, [props.refreshData])

    // useEffect(() => {
    //     if (!_.isArray(props.pipelineEnquiries))
    //         return

    //     var _pipelines = _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
    //     if (!_.isEmpty(props.selectedPipeline)) {
    //         _pipelines = [props.selectedPipeline.value]
    //     }

    //     setCounts({
    //         todayConverted: props.pipelineEnquiries.filter(a =>
    //             _pipelines.some(e => e === a.pipelineValue)
    //             && !_.isEmpty(a.convertedDate)
    //             && a.isConverted === true
    //             && a.convertedDate.seconds >= todayStart.seconds
    //             && a.convertedDate.seconds <= todayEnd.seconds
    //             && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
    //         monthConverted: props.pipelineEnquiries.filter(a =>
    //             _pipelines.some(e => e === a.pipelineValue)
    //             && !_.isEmpty(a.convertedDate)
    //             && a.isConverted === true
    //             && a.convertedDate.seconds >= monthStart.seconds
    //             && a.convertedDate.seconds <= monthEnd.seconds
    //             && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
    //         pendingInboundleads: props.pipelineEnquiries.filter(a =>
    //             a.pipelineValue === 'LeadsBucket'
    //             && (a.stageValue !== 'Archived')
    //             && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
    //         lostApprovalList: props.pipelineEnquiries.filter(a =>
    //             a.statusValue === 'pendingLost'
    //             && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true))
    //     })

    // }, [props.pipelineEnquiries, props.selectedUser, props.selectedPipeline])


    return (<>
        <div className="dashboard-box draggable-section">
            <div className="row" style={{ minHeight: `165px` }}>
                {
                    (Boolean(props.clientLostApproval) && Boolean(props.managerLostApproval) && props.pipeline.some(item => item.value === 'LeadsBucket')) ? (
                        <>
                            <div className="col-md-6">
                                <div className="dashboard-box-inner d-flex align-items-center justify-content-center h-100">
                                    <div className="dashboard-lost-wrap cursor-pointer" onClick={() => {
                                        if (counts.lostApprovalList) {
                                            props.handleShowEnquiryList({
                                                status: 'pendingLost',
                                                enableMulticheck: true,
                                                pipeline: _.map(props.dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                            }, 'Pending Lost Approval', counts.lostApprovalList)
                                        }
                                    }}>
                                        <Translate text={'lostApprovals'} />
                                        <h2>{counts.lostApprovalList ? counts.lostApprovalList : '0'}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 border-left pl-0">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="dashboard-allocated-wrap ">
                                            <h3><Translate text={'allocated'} /></h3>
                                            <div className="dashboard-module-count-wraper dashboard-cell-bg">
                                                <div className=" row">
                                                    {
                                                        props.applydateRange === false ? (
                                                            <div className="col-lg-6" style={{ cursor: 'pointer' }} onClick={() => {
                                                                if (counts.todayConverted) {
                                                                    props.handleShowEnquiryList({
                                                                        startDate: todayStart,
                                                                        endDate: todayEnd,
                                                                        dateType: 'convertedDate',
                                                                        status: 'all',
                                                                        pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                                    }, 'Today Allocated Leads', counts.todayConverted)
                                                                }
                                                            }}>
                                                                <div className="dashboard-count-title"> Today</div>
                                                                <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.todayConverted}</a></div>
                                                            </div>) : (<></>)
                                                    }

                                                    <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                                        if (counts.monthConverted) {
                                                            props.handleShowEnquiryList({
                                                                startDate: monthStart,
                                                                endDate: monthEnd,
                                                                dateType: 'convertedDate',
                                                                status: 'all',
                                                                pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                            }, (props.applydateRange === false ? `MTD Allocated` : 'Allocated'), counts.monthConverted)
                                                        }
                                                    }}>
                                                        {
                                                            props.applydateRange === false ?
                                                                (
                                                                    <div className="dashboard-count-title">MTD</div>
                                                                ) : (<></>)
                                                        }
                                                        <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link">{counts.monthConverted}</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ cursor: 'pointer' }} onClick={() => {
                                        if (counts.pendingInboundleads) {
                                            props.handleShowEnquiryList({
                                                pipeline: _.map(props.pipeline.filter(item => item.value === 'LeadsBucket'), function (e) { return e.value; }),
                                                status: 'pending',
                                                inbound: true,
                                                enableMulticheck: true,
                                            }, 'Pending Lead Bucket', counts.pendingInboundleads)
                                        }
                                    }}>
                                        <div className="dashboard-Pending-wrap"><span className="dashboard-Pending-txt">Pending</span> <span className="float-right"><h2>{counts.pendingInboundleads}</h2></span></div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (Boolean(props.clientLostApproval) && Boolean(props.managerLostApproval)) ? (
                        <div className="col-md-12">
                            <div className="dashboard-box-inner d-flex align-items-center justify-content-center h-100">
                                <div className="dashboard-lost-wrap cursor-pointer" onClick={() => {
                                    if (counts.lostApprovalList) {
                                        props.handleShowEnquiryList({
                                            status: 'pendingLost',
                                            enableMulticheck: true,
                                            pipeline: _.map(props.dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                        }, 'Pending Lost Approval', counts.lostApprovalList)
                                    }
                                }}>
                                    <Translate text={'lostApprovals'} />
                                    <h2>{counts.lostApprovalList}</h2>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="dashboard-allocated-wrap ">
                                        <h3><Translate text={'allocated'} /></h3>
                                        <div className="dashboard-module-count-wraper dashboard-cell-bg">
                                            <div className=" row">
                                                {
                                                    props.applydateRange === false ? (
                                                        <div className="col-lg-6" style={{ cursor: 'pointer' }} onClick={() => {
                                                            if (counts.todayConverted) {
                                                                props.handleShowEnquiryList({
                                                                    startDate: todayStart,
                                                                    endDate: todayEnd,
                                                                    dateType: 'convertedDate',
                                                                    status: 'all',
                                                                    pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                                }, 'Today Allocated Leads', counts.todayConverted)
                                                            }
                                                        }}>
                                                            <div className="dashboard-count-title"> Today</div>
                                                            <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.todayConverted}</a></div>
                                                        </div>) : (<></>)
                                                }
                                                <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (counts.monthConverted) {
                                                        props.handleShowEnquiryList({
                                                            startDate: monthStart,
                                                            endDate: monthEnd,
                                                            dateType: 'convertedDate',
                                                            status: 'all',
                                                            pipeline: _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                                        }, (props.applydateRange === false ? `MTD Allocated` : `Allocated`), counts.monthConverted)
                                                    }
                                                }}>
                                                    <div className="dashboard-count-title"> MTD</div>
                                                    <div className="dashboard-count-value"> <a href="#" className="blue-link">{counts.monthConverted}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ cursor: 'pointer' }} onClick={() => {
                                    if (counts.pendingInboundleads) {
                                        props.handleShowEnquiryList({
                                            pipeline: _.map(props.pipeline.filter(item => item.value === 'LeadsBucket'), function (e) { return e.value; }),
                                            status: 'pending',
                                            inbound: true,
                                            enableMulticheck: true,
                                        }, 'Pending Lead Bucket', counts.pendingInboundleads)
                                    }
                                }}>
                                    <div className="dashboard-Pending-wrap"><span className="dashboard-Pending-txt"><Translate text={'Pending'} /></span> <span className="float-right"><h2>{counts.pendingInboundleads}</h2></span></div>
                                </div>
                            </div >
                        </div >
                    )
                }

            </div >
        </div >
    </>);
}

export default LeadsBucket;