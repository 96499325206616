import React, { useState, useEffect, useMemo, Fragment } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment'
import { ContentOverlay, PopUpModal, BarChart, PieChart, LineChart, NoDataPieChart } from "../../components";
import CommonHelper from '../../services/common'
import _images from '../../images'
import { reportGroupBy, reportDynColumns, defaultFilters, filterOptions, reportFilters } from './viewModel'
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import AddReport from './addReport'
import AppraisalList from '../dashboardTradeinPro/appraisalList'
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';
import ReactExport from 'react-export-excel-xlsx-fix';
import ScheduleReport from "../reports/scheduleReport";
import CustomFilters from '../reports/customFilters';
import uuid from 'react-uuid'
import cronstrue from 'cronstrue';
import NumberFormat from 'react-number-format';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const styles = StyleSheet.create({
    page: {
        padding: '10mm'
    },
    header: {
        width: '100%',
        borderBottomColor: '#4466f2',
        borderBottomWidth: 3,
        display: 'inline-block',
        paddingBottom: 10,
        float: 'left',
        paddingTop: 30
    },
    headerwrapLeft: {
        width: '60%',
        float: 'left',
        position: 'absolute',
        left: 0,
        paddingTop: 15
    },
    headerwrapRight: {
        width: '40%',
        position: 'absolute',
        right: 0,
        textAlign: 'right'
    },
    reportImage: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        maxHeight: 600
    },
    image: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        maxHeight: 500,
        marginTop: 10
    },
    reporHeader: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        paddingTop: 10,
    },
    footer: {
        width: '100%',
        paddingTop: 8,
        marginTop: 0,
        display: 'inline-block'
    },
    footerCpy: {
        width: '100%',
        fontSize: 8,
        borderTopColor: '#d3d9e6',
        borderTopWidth: 1,
        paddingTop: 8,
        marginTop: 8
    }
});

const ViewDynamicReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), tblWidth: (window.innerWidth - 695), windowHeight: (window.innerHeight - 75), tblHeight: (window.innerHeight - 200) })
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [report, setReport] = useState(props.report);
    const [progressReport, setProgressReport] = useState([])
    const [clientReport, setClientReport] = useState([])
    const [sortReport, setSortReport] = useState([])
    const [refreshData, setRefreshData] = useState(false)
    const [sortConfig, setSortConfig] = useState();
    const [nestedsortConfig, setNestedSortConfig] = useState();

    const [showModel, setShowmodel] = useState(false)
    const [modelShow, setModelShow] = useState({
        tradeinPro: false,
        sheduleReport: false
    })
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)

    const [csvData, setCSVData] = useState([])
    const [sheetData, setSheetData] = useState([])

    const [chartData, setChartData] = useState()
    const [chartImg, setChartImg] = useState()
    const [filterShow, setShow] = useState(true)
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [counts, setCounts] = useState([])
    const [errorFilters, setErrorFilter] = useState({});
    const [schedueReport, setSchedeReport] = useState({});
    const [dynReportGroupBy, setReportGroupBy] = useState(reportGroupBy);
    const [nestedTbl, setNestedTbl] = useState({});
    const [subListTbl, setSubListTbl] = useState({});

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 75),
                tblHeight: (window.innerHeight - 200),
                tblWidth: (window.innerWidth - 695)
            })
        }
        //document.addEventListener("mousedown", handleClick);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            //document.removeEventListener("mousedown", handleClick);
        }
    }, [])
    useEffect(() => {
        setReport(props.report);
    }, [props.report])

    useEffect(() => {
        let _leadSource = []
        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.tradeinPro &&
            props.dealersettings.client.tradeinPro.pipelines
            && props.dealersettings.client.tradeinPro.pipelines.find(a => a.value === props.report.pipeline[0])) {
            props.dealersettings.client.tradeinPro.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    _leadSource.push({
                        label: `${rec.name} (Stage)`,
                        searchlabel: `${rec.name} Stage`,
                        value: `stage-${rec.value}`,
                        name: `${rec.name} (Stage)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Stage',
                    })
                }
            })
        }

        props.dealersettings?.client?.tradeinPro?.tradeinProStatus &&
            props.dealersettings.client.tradeinPro.tradeinProStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `status-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'TradeinPro Status'
                    })
                    if (rec.value === 'Won') {
                        _leadSource.push({
                            label: `${rec.name} Conv. Ratio`,
                            value: `status-${rec.value}Conv`,
                            name: `${rec.name} Conv. Ratio`,
                            view: ['table', 'column', 'pie', 'target'],
                            calculationType: ['percentage'],
                            percentage: true,
                            color: rec.color,
                            sourceType: 'TradeinPro Status'
                        })
                    }
                })

        setReportColumns([...reportColumns.filter(a => _.isEmpty(a.sourceType)), ..._leadSource]);
        setRefreshData(true)
    }, [])


    useEffect(() => {
        if (props.activeKey === props.report.documentID)
            setRefreshData(true)
    }, [props.report.columns])


    // useEffect(() => {
    //     if (props.activeKey === props.report.documentID)
    //         setRefreshData(true)
    // }, [props.report.objFilters])

    useEffect(() => {
        if (_.isEmpty(report))
            return;

        const refScheduleReport = window.firebase.firestore().doc(`schedular/${report.documentID}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])


    useEffect(() => {
        if (_.isEmpty(sortReport) || props.report.reportType !== 'table')
            return

        let _headers = [];
        let _borderStyle = {
            border: {
                top: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                bottom: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                left: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                right: {
                    style: "thin",
                    color: { rgb: "000000" }
                }
            }
        }
        let _headerStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false,
                    color: { rgb: "FFFFFF" }
                },
                fill: { patternType: "solid", fgColor: { rgb: "000000" } },
                ..._borderStyle

            }
        }
        let _headerStyleCenter = {
            style: {
                ..._headerStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        }
        let _subheaderStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false
                },
                fill: { patternType: "solid", fgColor: { rgb: "dddddd" } },
                ..._borderStyle
            }
        }
        let _subheaderStyleCenter = {
            style: {
                ..._subheaderStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        }
        let _cellStyle = {
            style: {
                font: { sz: 10 },
                ..._borderStyle
            }
        };
        let _cellStyleCenter = {
            style: {
                ..._cellStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        };
        let _numStyle = { style: { font: { sz: 10 } }, numFmt: "0" };
        let _footerStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false
                },
                fill: { patternType: "solid", fgColor: { rgb: "ffeede" } },
                ..._borderStyle
            }
        };
        let _footerStyleCenter = {
            style: {
                ..._footerStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        };
        _headers.push(
            {
                title: (_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'DEALER' : `${props.report.level.toUpperCase()}`,
                dynValue: 'displayName',
                dynWidth: 10,
                ..._headerStyle
            })

        props.report.columns.forEach((val) => {
            var cols = reportColumns.find(item => item.value === val)
            if (cols) {
                _headers.push({ title: cols.name.toUpperCase(), dynValue: val, dynWidth: cols.name.length, ..._headerStyleCenter })
            }
            else {
                _headers.push({ title: val.toUpperCase(), dynValue: val, dynWidth: val.toString().length, ..._headerStyleCenter })
            }
        })
        if (props?.report?.dynColumns?.length > 0) {
            props.report.dynColumns.forEach((val) => {
                _headers.push({ title: val.name, dynValue: val.id, dynWidth: val.name.toString().length, ..._headerStyleCenter })
            })
        }

        var exportData = []
        var totals = getTotalRow(sortReport);

        sortReport.forEach(rec => {
            let obj = rec;
            let arrCols = [{ value: obj.clientName, ..._cellStyle, dynValue: 'clientName', dynWidth: obj.clientName.length }]
            props.report.columns.forEach(col => {
                var cols = reportColumns.find(item => item.value === col)
                arrCols.push({ value: cols && cols.percentage === true ? `${obj[col]}%` : cols?.currency === true ? `${currencySymbol} ${obj[col]}` : obj[col], ..._cellStyleCenter, dynValue: col, dynWidth: obj[col] ? obj[col].toString().length : 0 })
            })
            if (props?.report?.dynColumns?.length > 0) {
                props.report.dynColumns.forEach((col) => {
                    arrCols.push({ value: `${col.currency ? `${currencySymbol} ` : ''}${obj[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: col.id, dynWidth: obj[col.id] ? obj[col.id].toString().length : 0 })
                })
            }
            exportData.push(arrCols)
        })
        if (exportData.length > 0) {
            let arrCols = [{ value: 'Totals', ..._footerStyle, dynValue: 'clientName', dynWidth: 6 }]
            props.report.columns.forEach(col => {
                arrCols.push({ value: totals[col], ..._footerStyleCenter, dynValue: col, dynWidth: totals[col] ? totals[col].toString().length : 0 })
            })
            if (props?.report?.dynColumns?.length > 0) {
                props.report.dynColumns.forEach((col) => {
                    arrCols.push({ value: `${totals[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: col.id, dynWidth: totals[col.id] ? totals[col.id].toString().length : 0 })
                })
            }
            exportData.push(arrCols)
        }
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })

        let allSheetData = {};
        sortReport.forEach(rec => {
            var sheetData = []
            var clienttotals = getTotalRow(progressReport.filter(e => e[`${((_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`)}`] === rec.id));

            progressReport.filter(e => e[`${((_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`)}`] === rec.id).forEach(rec => {
                let obj = rec;
                let arrCols = [{ value: obj.displayName, ..._cellStyle, dynValue: 'displayName', dynWidth: obj.displayName.length }]
                props.report.columns.forEach(col => {
                    var cols = reportColumns.find(item => item.value === col)
                    arrCols.push({ value: cols && cols.percentage === true ? `${obj[col]}%` : cols?.currency === true ? `${currencySymbol} ${obj[col]}` : obj[col], ..._cellStyleCenter, dynValue: col, dynWidth: obj[col] ? obj[col].toString().length : 0 })
                })
                if (props?.report?.dynColumns?.length > 0) {
                    props.report.dynColumns.forEach((col) => {
                        arrCols.push({ value: `${col.currency ? `${currencySymbol} ` : ''}${obj[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: col.id, dynWidth: obj[col.id] ? obj[col.id].toString().length : 0 })
                    })
                }
                sheetData.push(arrCols)
            })
            if (sheetData.length > 0) {
                let arrCols = [{ value: 'Totals', ..._footerStyle, dynValue: 'displayName', dynWidth: 6 }]
                props.report.columns.forEach(col => {
                    arrCols.push({ value: clienttotals[col], ..._footerStyleCenter, dynValue: col, dynWidth: clienttotals[col] ? clienttotals[col].toString().length : 0 })
                })
                if (props?.report?.dynColumns?.length > 0) {
                    props.report.dynColumns.forEach((col) => {
                        arrCols.push({ value: `${col.currency ? `${currencySymbol} ` : ''}${clienttotals[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: col.id, dynWidth: clienttotals[col.id] ? clienttotals[col.id].toString().length : 0 })
                    })
                }
                sheetData.push(arrCols)
            }
            _headers.forEach(rec => {
                var w = _.maxBy(sheetData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
                _headers = _headers.map(h => {
                    return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                        ...h,
                        'width': { wch: w.dynWidth + 2 }
                    } : {
                        ...h,
                        'width': { wch: h.dynWidth + 2 }
                    }
                })
            })

            allSheetData[rec.id] = [{
                columns: [..._headers.map((rec, i) => {
                    let r = i === 0 ? {
                        ...rec,
                        title: CommonHelper.getCustomValue(dynReportGroupBy, props.report.groupBy, 'tableHeader', 'value')
                    } : rec
                    return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 }
                })],
                data: [...sheetData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
            }]
        })

        let allCSVData = {};
        sortReport.forEach(rec => {
            var sheetData = []
            var _subHeader = []
            var clienttotals = getTotalRow(progressReport.filter(e => e['clientID'] === rec.id));



            _subHeader = [..._headers.map((h, i) => {
                return i === 0 ? {
                    ...h,
                    ..._subheaderStyle,
                    title: `${_.find(clientReport, { id: rec.id }) ? _.find(clientReport, { id: rec.id }).clientName : 'Name'}`
                } : {
                    ...h,
                    ..._subheaderStyleCenter,
                }
            })]

            progressReport.filter(e => e['clientID'] === rec.id).forEach(rec => {
                let obj = rec;
                let arrCols = [{ value: obj.displayName, ..._cellStyle, dynValue: 'displayName', dynWidth: obj.displayName.length }]
                props.report.columns.forEach(col => {
                    var cols = reportColumns.find(item => item.value === col)
                    arrCols.push({ value: cols && cols.percentage === true ? `${obj[col]}%` : cols?.currency === true ? `${currencySymbol} ${obj[col]}` : obj[col], ..._cellStyleCenter, dynValue: col, dynWidth: obj[col] ? obj[col].toString().length : 0 })
                })
                if (props?.report?.dynColumns?.length > 0) {
                    props.report.dynColumns.forEach((col) => {
                        arrCols.push({ value: `${col.currency ? `${currencySymbol} ` : ''}${obj[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._cellStyleCenter, dynValue: col.id, dynWidth: obj[col.id] ? obj[col.id].toString().length : 0 })
                    })
                }
                sheetData.push(arrCols)
            })

            if (sheetData.length > 0) {
                let arrCols = [{ value: 'Totals', ..._footerStyle, dynValue: 'displayName', dynWidth: 6 }]
                props.report.columns.forEach(col => {
                    arrCols.push({ value: clienttotals[col], ..._footerStyleCenter, dynValue: col, dynWidth: clienttotals[col] ? clienttotals[col].toString().length : 0 })
                })
                if (props?.report?.dynColumns?.length > 0) {
                    props.report.dynColumns.forEach((col) => {
                        arrCols.push({ value: `${col.currency ? `${currencySymbol} ` : ''}${clienttotals[col.id]}${col.action === 'percentage' ? '%' : ''}`, ..._footerStyleCenter, dynValue: col.id, dynWidth: clienttotals[col.id] ? clienttotals[col.id].toString().length : 0 })
                    })
                }
                sheetData.push(arrCols)
            }

            _subHeader.forEach(_rec => {
                var w = _.maxBy(sheetData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === _rec.dynValue) } }), 'dynWidth')
                _subHeader = _subHeader.map(h => {
                    return h.dynValue === _rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                        ...h,
                        'width': { wch: w.dynWidth + 2 }
                    } : _.isEmpty(h.width) ? {
                        ...h,
                        'width': { wch: h.dynWidth + 2 }
                    } : {
                        ...h
                    }
                })
            })

            allCSVData[rec.id] = [{
                columns: [..._subHeader.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
                data: [...sheetData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
            }]
        })

        const _csvData = [{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
        }];
        if (props.dealersettings.isMclaren === true) {
            Object.keys(allCSVData).map((rec, index) => {
                _csvData.push({
                    ySteps: 1,
                    ...allCSVData[rec][0]
                });
            })
        }
        setCSVData(_csvData)
        setSheetData(allSheetData)
    }, [sortReport])

    const getTotalRow = (reportData) => {
        return {
            totalAppraisal: _.sumBy(reportData, "totalAppraisal"),
            dealerPrice: `${currencySymbol}${Math.round((_.sumBy(reportData, "dealerPrice") + Number.EPSILON) * 100) / 100}`,
            ...getLeadsByEnquiryTypeTotal(reportData)
        };
    }


    useEffect(() => {
        if (!refreshData)
            return
        setLoader(true)
        let _filters = {};
        if (!_.isEmpty(props.report.objFilters) && props.report.objFilters.length > 0) {
            props.report.objFilters.forEach(rec => {
                _filters = rec.name === 'date' ? {
                    ..._filters,
                    startDate: moment.unix(rec.value.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(rec.value.endDate.seconds).format('YYYY-MM-DD')
                } : {
                    ..._filters,
                    ...rec.value,
                    ...(rec.subList ? rec.subList : {})
                }
            })
        }
        else {
            _filters = {
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d).format('YYYY-MM-DD'),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d).format('YYYY-MM-DD')
            }
        }
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getTradeinProDynamicReport',
                params: JSON.stringify({
                    clientID: props.dealersettings.client.id,
                    reportJSON: JSON.stringify(props.report),
                    ..._filters
                    // startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    // endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    // ...objFilter
                })
            }).then((response) => {
                setLoader(false)
                setRefreshData(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }

    }, [refreshData])

    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'] : []
        let reports = [];
        _report.forEach(el => {
            props.dealersettings.clients.forEach(client => {
                if (el[client.id]) {
                    el[client.id].filter(a => props.report.groupBy === 'client' ? a.id === client.id : true).forEach(rec => {
                        if (Object.keys(rec).filter(r => Boolean(props.report.includeZero) ? true : _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                            reports.push({
                                ...rec,
                                clientID: client.id,
                                clientName: client.name,
                                profileImage: props.report.groupBy === 'user' && _.find(props.clientUsers, { id: rec.id }) ? _.find(props.clientUsers, { id: rec.id }).profileImage : '',
                                totalAppraisal: rec.arrtotalAppraisal ? rec.arrtotalAppraisal.length : 0,
                                //revenue
                                arrdealerPrice: rec.arrdealerPrice ? _.uniq(rec.arrdealerPrice.map(r => { return r.documentID })) : [],
                                dealerPrice: rec.arrdealerPrice ? Math.round((_.sumBy(rec.arrdealerPrice, item => Number(item.price)) + Number.EPSILON) * 100) / 100 : 0,
                                ...getDynamicColumnLength(rec)
                            })
                        }
                    })
                }
            })
        });

        props.report.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        if (reports.length === 0) {
            setClientReport([]);
            setSortReport([])
        }
        setProgressReport(reports)
    }

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return
        let reports = _.chain(progressReport)
            .groupBy('clientID')
            .map((value, key) => (
                {
                    ...getDocumentIDs(value),
                    id: key,
                    clientID: key,
                    name: value[0].clientName,
                    displayName: value[0].clientName,
                    clientName: value[0].clientName,
                    ...sumByValues(value, value[0].clientID)
                }
            )).value();

        props.report.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setClientReport(reports);
        setSortReport(reports);
        if (reports.length > 0) {
            setNestedTbl({
                ...nestedTbl,
                [reports[0].clientID]: true
            })
        }
    }, [progressReport])


    const getDocumentIDs = (data) => {
        let _result = {};
        Object.keys(data[0]).filter(r => r.startsWith('arr')).forEach(val => {
            _result = {
                ..._result,
                [val]: data.map(r => { return _.join(r[val]) })
                    .join(',')
                    .split(',')
                    .filter(r => !_.isEmpty(r))
            }
        })
        return _result;
    }

    const sumByValues = (value, clientID) => {
        return {
            totalAppraisal: _.some(value, 'arrtotalAppraisal') ? _.sum(value.map(r => r.arrtotalAppraisal.length)) : 0,
            dealerPrice: _.some(value, 'arrdealerPrice') ? Math.round((_.sum(value.map(r => r.dealerPrice)) + Number.EPSILON) * 100) / 100 : 0,
            ...getDynamicTotalLength(value)
        }
    }

    const getDynamicTotalLength = (data) => {
        let leadSourceData = {};
        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.tradeinPro &&
            props.dealersettings.client.tradeinPro.pipelines
            && props.dealersettings.client.tradeinPro.pipelines.find(a => a.value === props.report.pipeline[0])) {
            let _leadSource = []
            let _sourceFields = []
            props.dealersettings.client.tradeinPro.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    leadSourceData = {
                        ...leadSourceData,
                        [`stage-${rec.value}`]: data[`arrstage-${rec.value}`] ? data[`arrstage-${rec.value}`].length : 0
                    }
                }
            })
        }

        props?.dealersettings?.client?.tradeinPro?.tradeinProStatus &&
            props.dealersettings.client.tradeinPro.tradeinProStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = _.some(data, `status-${rec.value}`) ? _.sum(data.map(r => r[`status-${rec.value}`])) : 0;
                    let _totalCount = _.some(data, `arrtotalAppraisal`) ? _.sum(data.map(r => r[`arrtotalAppraisal`].length)) : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`status-${rec.value}`]: _stsCount,
                        [`status-${rec.value}Conv`]: _totalCount > 0 ? Math.round((100 / _totalCount) * _stsCount) : 0,
                    }
                })

        return leadSourceData;
    }
    const getDynamicColumnLength = (data) => {
        let leadSourceData = {};
        props?.dealersettings?.client?.tradeinPro?.tradeinProStatus &&
            props.dealersettings.client.tradeinPro.tradeinProStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = data[`arrstatus-${rec.value}`] ? data[`arrstatus-${rec.value}`].length : 0;
                    let _totalCount = data[`arrtotalAppraisal`] ? data[`arrtotalAppraisal`].length : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrstatus-${rec.value}`]: data[`arrstatus-${rec.value}`] ? data[`arrstatus-${rec.value}`] : [],
                        [`status-${rec.value}`]: _stsCount,
                        [`status-${rec.value}Conv`]: _totalCount > 0 ? Math.round((100 / _totalCount) * _stsCount) : 0,
                    }
                })

        return leadSourceData;
    }

    const getLeadsByEnquiryTypeTotal = (_progressReport) => {
        let leadSourceData = {};
        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.tradeinPro &&
            props.dealersettings.client.tradeinPro.pipelines
            && props.dealersettings.client.tradeinPro.pipelines.find(a => a.value === props.report.pipeline[0])) {
            props.dealersettings.client.tradeinPro.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    leadSourceData = {
                        ...leadSourceData,
                        [`stage-${rec.value}`]: _.sumBy(_progressReport, `stage-${rec.value}`)
                    }
                }
            })
        }
        props?.dealersettings?.client?.tradeinPro?.tradeinProStatus &&
            props.dealersettings.client.tradeinPro.tradeinProStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = _.sumBy(_progressReport, `status-${rec.value}`);
                    leadSourceData = {
                        ...leadSourceData,
                        [`status-${rec.value}`]: _stsCount,
                        [`status-${rec.value}Conv`]: `${_.sumBy(_progressReport, `totalAppraisal`) > 0 ? Math.round((100 / _.sumBy(_progressReport, `totalAppraisal`)) * _stsCount) : 0}%`,
                    }
                })

        return leadSourceData;
    }

    const handleModelClose = (rec, isNew) => {
        if (rec)
            setReport(rec);
        setCounts(0)
        setShowmodel(false)
        setFilter(null)
        setModelShow({
            tradeinPro: false,
            scheduleActivity: false
        })
        if (isNew) {
            props.setKey(rec.documentID)
        }
    }

    const deleteReport = () => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`tradeinProReports/${props.report.documentID}`).delete()
                    .then(snapshot => {
                        toast.notify('Report deleted successfully', {
                            duration: 2000
                        })
                        window.firebase.firestore().doc(`schedular/${props.report.documentID}`).delete();
                        props.setKey('salesprogress')
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })
    }

    const cloneReport = () => {
        setReport({
            ...props.report,
            documentID: window.firebase.firestore().collection(`tradeinProReports`).doc().id,
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid,
            owner: localStorage.uid,
            name: `${props.report.name} - Copy`
        })
        setShowmodel(true)
    }

    useEffect(() => {
        if (_.isEmpty(chartData))
            return
        try {
            chartData.exporting && chartData.exporting.getImage("png").then(rec => {
                setChartImg(rec)
            })
        }
        catch (error) {
            console.log(error)
        }
    }, [chartData])

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setNestedTbl({});
        setSubListTbl({});
        setSortConfig({ key, direction });
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...clientReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        setSortReport(sortableItems)
        return sortableItems;
    }, [clientReport, sortConfig]);

    const requestNestedSort = (key) => {
        let direction = 'ascending';
        if (
            nestedsortConfig &&
            nestedsortConfig.key === key &&
            nestedsortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setNestedSortConfig({ key, direction });
    };

    const nestedsortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(nestedsortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[nestedsortConfig.key] < b[nestedsortConfig.key]) {
                    return nestedsortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[nestedsortConfig.key] > b[nestedsortConfig.key]) {
                    return nestedsortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [progressReport, nestedsortConfig]);


    const ReportPdf = () => (
        <Document>
            <Page style={styles.page} size="A4" orientation="lanscape">
                <View style={styles.header}>
                    <View style={styles.headerwrapLeft}>
                        <Text style={{ fontSize: 13 }}>{props.dealersettings.client.name}</Text>
                    </View>
                    <View style={styles.headerwrapRight}>
                        <Text style={{ fontSize: 13 }}>{props.report.name}</Text>
                        <Text style={{ fontSize: 10, color: '#6b717f', paddingTop: 5 }}>{moment().format('DD/MM/YYYY hh:mm A')}</Text>
                    </View>
                </View>
                <View style={styles.reportImage}>
                    {
                        chartImg ? (<Image style={styles.image} src={chartImg} />) : (<></>)
                    }

                </View>
                <View style={styles.footer}>
                    <View style={styles.footerCpy}>
                        <View style={styles.footerLeft}>
                            <Text>Powered by <Link src="http://fusionsd.com.au/" style={{ color: '#666', textDecoration: 'none' }}>Fusion SD</Link></Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
    const DownloadPdf = () => {
        return useMemo(
            () => (
                <PDFDownloadLink document={<ReportPdf />} fileName={`${props.report.name.toLowerCase().replace(/ /g, '-')}.pdf`}>
                    {({ loading }) => ((loading || _.isEmpty(chartImg)) ? (<></>) : (<> <i className="ico icon-files-pdf"></i> Export PDF </>))}
                </PDFDownloadLink>
            ),
            [chartImg],
        )
    }

    const handleShowEnquiryList = (val, rec) => {
        var cols = reportColumns.find(item => item.value === val)
        if (cols && rec[`arr${cols.value}`] && rec[`arr${cols.value}`].length > 0 && cols.percentage !== true) {
            setCounts(rec[`arr${cols.value}`] ? rec[`arr${cols.value}`].length : 0)
            setFilter({
                documentIDs: rec[`arr${cols.value}`]
            })
            setPopHeader('Appraisal')
            setModelShow({
                ...modelShow,
                tradeinPro: true
            })

        }
    }

    const isValidFilters = (isScroll) => {
        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';


        let _filterLists = Object.assign([], report.objFilters);

        !_.isEmpty(_filterLists) && _filterLists.forEach(_data => {
            if (_.isEmpty(_data.name)) {
                formIsValid = false;
                errors['name_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;

            }
            else if (_.isEmpty(_data.value)) {
                formIsValid = false;
                errors['value_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;
            }

        });

        setErrorFilter(errors);
        console.log('isValidFilters,', errors)
        return formIsValid;
    }

    const addFilter = () => {
        let _lists = Object.assign([], report.objFilters);
        let _isValidFilters = isValidFilters();
        if (!_isValidFilters)
            return;

        _lists.push({ name: null, value: null, id: uuid() })
        setReport({
            ...report,
            ['objFilters']: _lists
        });

    }

    const updateFilter = (_data) => {
        if (!_.isEmpty(_data)) {
            const _reLists = Object.assign([], report.objFilters);

            _reLists.filter(function (obj) {
                //Update old data
                if (obj.id === _data.id) {
                    for (let [key, value] of Object.entries(_data)) {
                        obj[key] = value;
                    }
                }
            });
            //console.log('updateRule', _data, _reLists)

            setReport({
                ...report,
                ['objFilters']: _reLists
            });
        }
    }

    const deleteFilter = (_id) => {
        if (!_.isEmpty(_id)) {
            const _reLists = Object.assign([], report.objFilters);
            setReport({
                ...report,
                ['objFilters']: _reLists.filter(m => m.id !== _id)
            });
        }
    }

    const saveFilter = () => {
        setLoader(true)
        window.firebase.firestore().doc(`tradeinProReports/${report.documentID}`).set(report, { merge: true })
            .then(snapshot => {
                setTimeout(() => {
                    setRefreshData(true)
                }, 1000);
                // toast.notify('Report updated successfully', {
                //     duration: 2000
                // });
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const toggleFilter = (e, show) => {
        e.preventDefault();
        setShow(show);
        const reportWrapper = window.jQuery(`#${report.documentID}-report-collapse`);
        if (reportWrapper) {
            if (show)
                reportWrapper.fadeIn();
            else
                reportWrapper.fadeOut();
        }
    }

    return (<>
        <div className="report-page-head">
            <h2>{props.report.name}  </h2>


            <Dropdown className='enquiry-view-more float-right mt-0'>
                <Dropdown.Toggle as={CustomToggle} className='common-button'>
                    <i className='ico icon-more'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    as={CustomMenu}
                    ChildClass="more-dropdown dropsub-fix"
                    xplacement="bottom-end"
                >
                    {
                        props.report.addedBy === localStorage.uid || props.dealersettings.roleName === 'Administrator' ? (
                            <Dropdown.Item eventKey="1" onClick={(e) => { setReport(props.report); setShowmodel(true) }}><i className="ico icon-edit"></i> Edit </Dropdown.Item>
                        ) : (<></>)
                    }
                    {
                        props.report.reportType === 'table' ? (<Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                            <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`${props.report.name.toLowerCase().replace(/ /g, '-')}`}>
                                <ExcelSheet dataSet={csvData} name={`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`} />
                                {
                                    Object.keys(sheetData).map((rec, index) => {
                                        return <ExcelSheet key={index} dataSet={sheetData[rec]} name={`${_.find(clientReport, { id: rec }) ? CommonHelper.removeUnicodeChars(_.find(clientReport, { id: rec }).clientName) : rec}`} />
                                    })
                                }
                            </ExcelFile>
                        </Dropdown.Item>) : (<Dropdown.Item eventKey="3" as={'div'} bsPrefix={'li-'}>
                            <DownloadPdf />
                        </Dropdown.Item>)
                    }
                    {/* {
                        props.report.addedBy === localStorage.uid || props.dealersettings.roleName === 'Administrator' ? (<>
                            <Dropdown.Item eventKey="3" onClick={(e) => { deleteReport() }}><i className="ico icon-delete"></i> Delete </Dropdown.Item>
                            <Dropdown.Item eventKey="4" onClick={(e) => {
                                setModelShow({
                                    ...modelShow,
                                    sheduleReport: true
                                })
                            }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>
                            <Dropdown.Item eventKey="5" onClick={(e) => { cloneReport() }}><i className="ico icon-rearrange"></i> Clone Report </Dropdown.Item>
                        </>) : (<></>)
                    } */}
                </Dropdown.Menu>
            </Dropdown>
            {
                !_.isEmpty(schedueReport) ? (
                    <div className="reportsuccess-item-btn mr-2 float-right">
                        <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                        <div className="reportsuccess-inner-text">
                            <div className="reportsuccess-inner-text-top"> Scheduled </div>
                            <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                        </div>
                        <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                    </div>
                ) : (<></>)
            }

        </div>
        <div className="divider-line"></div>
        <div className="report-content-head pl-3 pr-3">
            <div className="row">
                <div className="rule-filter-head pl-3 pr-3">
                    {
                        !_.isEmpty(report.objFilters)
                            ?
                            <div className="float-left rule-filter-info cursor-pointer" onClick={(e) => { toggleFilter(e, !filterShow) }}> <i className="ico icon-filter"></i>
                                {Object.keys(report.objFilters).length} {Object.keys(report.objFilters).length > 1 ? ' filters' : ' filter'} applied
                            </div>
                            :
                            <></>
                    }
                    {
                        (report.visibility !== 'sharedView' || props.report.addedBy === localStorage.uid || Boolean(props.dealersettings.superAdmin)) ? (<div className="float-right rule-add-btn">
                            <div className="float-right ml-2 filter-collapse-btn">
                                <div className="filter-collapse-report"> <a href="#" onClick={(e) => { toggleFilter(e, !filterShow) }} className={`mini-button ${!filterShow ? 'collapsed' : ''}`}><i className="arrow-collapse"></i></a> </div>
                            </div>
                            <button type="button" className="btn btn-primary float-right ml-2"
                                onClick={(e) => { toggleFilter(e, true); addFilter(); }}>
                                <i className="ico icon-add mr-2"></i> Add Filter
                            </button>
                        </div>) : (<></>)
                    }
                </div>
                <div className={`rules-group-wrapper query-builder`} id={`${report.documentID}-report-collapse`}>
                    <div className="rules-group-body">
                        <div className="rules-list">
                            {
                                !_.isEmpty(report.objFilters) && report.objFilters.map((_rec, index) => {
                                    return <CustomFilters
                                        {...props}
                                        key={index}
                                        docID={_rec ? _rec.id : ''}
                                        fieldData={_rec}
                                        errors={errorFilters}
                                        filterFields={report.objFilters}
                                        updateFilter={updateFilter}
                                        deleteFilter={deleteFilter}
                                        columns={report.columns}
                                        report={report}
                                        filterOptions={filterOptions}
                                        reportFilters={filterOptions}
                                    ></CustomFilters>
                                })
                            }

                        </div>
                    </div>
                    <div className="filter-collapse-report-footer">
                        <button type="button" className="btn btn-green float-left ml-3" onClick={() => saveFilter()}>Apply</button>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) : progressReport && progressReport.length === 0 ? (
                <div className="common-table">
                    <div className="text-center p-5">
                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                        <div className="no-data-txt mt-2"> No records found</div>
                    </div>
                </div>
            ) : props.report.reportType === 'table' ? (<div className="common-table" style={{ overflowX: 'auto' }}>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <td className="head-light" width="10%">
                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                    e.preventDefault()
                                    requestSort('clientName')
                                }}>
                                    <div className="table-sort"><a href="#">
                                        {
                                            sortConfig && sortConfig.key === 'clientName' ?
                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                        }
                                    </a>
                                    </div>
                                    Dealer
                                </div>
                            </td>
                            {
                                props.report.columns.map((rec, index) => {
                                    return <td key={index} className={`head-light text-center ${reportColumns.find(item => item.value === rec) && reportColumns.find(item => item.value === rec).cls ? reportColumns.find(item => item.value === rec).cls : ''}`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {
                                                reportColumns.find(item => item.value === rec) ?
                                                    (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                    (<span className="pipeline-inactive-span">(In Active)</span>)
                                            }
                                        </div>
                                    </td>
                                })
                            }
                            {
                                props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.map((rec, index) => {
                                    return <td key={index} className={`head-light text-center`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec.id)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec.id ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {rec.name}
                                        </div>
                                    </td>
                                }) : (<></>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedItems.length > 0 ?
                                sortedItems.map((rec, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <tr key={index}>
                                                <td>
                                                    <div className="dealership-tbl cursor-pointer position-relative"
                                                        onClick={() => {
                                                            setNestedTbl({
                                                                ...nestedTbl,
                                                                [rec.id]: nestedTbl[rec.id] && !_.isEmpty(nestedTbl[rec.id].toString()) ? !nestedTbl[rec.id] : true
                                                            })
                                                        }}>
                                                        {rec.clientName}
                                                        <div className="table-sort"><a href="#"><i className={`ico icon-sort-${nestedTbl[rec.id] === true ? 'asc' : 'desc'}`}></i></a></div>
                                                    </div>
                                                </td>
                                                {
                                                    props.report.columns.map((val, index) => {
                                                        return <td key={index} className={`text-center cursor-pointer ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}
                                                            onClick={(e) => {
                                                                handleShowEnquiryList(val, rec);
                                                            }}>{
                                                                reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).currency ? (
                                                                    <NumberFormat
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        displayType={'text'}
                                                                        value={rec[val]}
                                                                    />
                                                                )
                                                                    : `${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                            }
                                                        </td>
                                                    })
                                                }
                                                {
                                                    props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.map((val, index) => {
                                                        return <td key={index} className={`text-center cursor-pointer`}
                                                            onClick={() => {
                                                                handleShowEnquiryList(val.id, rec);
                                                            }}>
                                                            {rec[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                        </td>
                                                    }) : (<></>)
                                                }
                                            </tr>
                                            <tr className={`tr-hover-fix ${nestedTbl[rec.id] === true ? '' : 'hide'}`}>
                                                <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)} style={{ padding: ` 0.5rem !important` }}>
                                                    <div className="table-treeview">
                                                        <table className="table table-bordered mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <td className="head-light" width="10%">
                                                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                            e.preventDefault()
                                                                            requestNestedSort('displayName')
                                                                        }}>
                                                                            <div className="table-sort"><a href="#">
                                                                                {
                                                                                    nestedsortConfig && nestedsortConfig.key === 'displayName' ?
                                                                                        (<i className={`ico icon-sort-${nestedsortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                                }
                                                                            </a>
                                                                            </div>
                                                                            {CommonHelper.getCustomValue(dynReportGroupBy, props.report.groupBy, 'tableHeader', 'value')}
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        props.report.columns.map((rec, index) => {
                                                                            return <td key={index} className={`text-center cursor-pointer ${reportColumns.find(item => item.value === rec) && reportColumns.find(item => item.value === rec).cls ? reportColumns.find(item => item.value === rec).cls : ''}`}>
                                                                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    requestNestedSort(rec)
                                                                                }}>
                                                                                    <div className="table-sort"><a href="#">
                                                                                        {
                                                                                            nestedsortConfig && nestedsortConfig.key === rec ?
                                                                                                (<i className={`ico icon-sort-${nestedsortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                                        }
                                                                                    </a>
                                                                                    </div>
                                                                                    {
                                                                                        reportColumns.find(item => item.value === rec) ?
                                                                                            (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                                                            (<span className="pipeline-inactive-span">(In Active)</span>)
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        })
                                                                    }
                                                                    {
                                                                        props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.map((rec, index) => {
                                                                            return <td key={index} className={`head-light text-center`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                                                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    requestSort(rec.id)
                                                                                }}>
                                                                                    <div className="table-sort"><a href="#">
                                                                                        {
                                                                                            sortConfig && sortConfig.key === rec.id ?
                                                                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                                        }
                                                                                    </a>
                                                                                    </div>
                                                                                    {rec.name}
                                                                                </div>
                                                                            </td>
                                                                        }) : (<></>)
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    nestedsortedItems.filter(e => e[(_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`] === rec.id).length > 0 ?
                                                                        nestedsortedItems.filter(e => e[(_.isEmpty(props.report.level) || props.report.level === 'individual') ? 'clientID' : `${props.report.level}ID`] === rec.id).map((data, index) => {
                                                                            return (<Fragment key={index}>
                                                                                <tr>
                                                                                    <td>
                                                                                        {
                                                                                            (props.report.groupBy === 'user' || props.report.groupBy === 'enqAddedBy') ? (<div className="table-user-list">
                                                                                                <a href="#">
                                                                                                    <div className="table-user-avatar">
                                                                                                        <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(data.profileImage, data.displayName)} /></div>
                                                                                                    {data.displayName}
                                                                                                </a>
                                                                                            </div>) : props.report.groupBy === 'lostReasons' ? (
                                                                                                <div className="dealership-tbl cursor-pointer position-relative" onClick={() => {
                                                                                                    setSubListTbl({
                                                                                                        ...subListTbl,
                                                                                                        [data.id]: subListTbl[data.id] && !_.isEmpty(subListTbl[data.id].toString()) ? !subListTbl[data.id] : true
                                                                                                    })
                                                                                                }}>
                                                                                                    {data.displayName}
                                                                                                    <div className="table-sort"><a href="#"><i className={`ico icon-sort-${subListTbl[data.id] === true ? 'asc' : 'desc'}`}></i></a></div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className="dealership-tbl">
                                                                                                    {data.displayName}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    {
                                                                                        props.report.columns.map((val, index) => {
                                                                                            return <td key={index} className={`text-center cursor-pointer ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}
                                                                                                onClick={(e) => {
                                                                                                    handleShowEnquiryList(val, data)
                                                                                                }}>
                                                                                                {
                                                                                                    reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).currency ? (
                                                                                                        <NumberFormat
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={currencySymbol}
                                                                                                            displayType={'text'}
                                                                                                            value={data[val]}
                                                                                                        />
                                                                                                    )
                                                                                                        : `${data[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                                                                }
                                                                                            </td>
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.map((val, index) => {
                                                                                            return <td key={index} className={`text-center cursor-pointer`}
                                                                                                onClick={(e) => {
                                                                                                    handleShowEnquiryList(val.id, data)
                                                                                                }}>
                                                                                                {data[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                                                            </td>
                                                                                        }) : (<></>)
                                                                                    }
                                                                                </tr>
                                                                                {
                                                                                    props.report.groupBy === 'lostReasons' && data.subList && data.subList.length > 0 ? (
                                                                                        <tr className={`tr-hover-fix ${subListTbl[data.id] === true ? '' : 'hide'}`}>
                                                                                            <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)} style={{ padding: ` 0.5rem !important` }}>
                                                                                                <div className="table-treeview">
                                                                                                    <table className="table table-bordered mb-0">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <td className="head-light" width="16%">
                                                                                                                    <div className="sort-cell cursor-pointer">
                                                                                                                        <div className="table-sort">
                                                                                                                        </div>
                                                                                                                        Lost Sub Reasons
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                {
                                                                                                                    props.report.columns.map((subrec, index) => {
                                                                                                                        return <td key={index} className="head-light text-center" width="7%">
                                                                                                                            <div className="sort-cell cursor-pointer">
                                                                                                                                <div className="table-sort">
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    reportColumns.find(item => item.value === subrec) ?
                                                                                                                                        (<>{CommonHelper.getLabelByValue(reportColumns, subrec)}</>) :
                                                                                                                                        (<span className="pipeline-inactive-span">(In Active)</span>)
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    })
                                                                                                                }
                                                                                                                {
                                                                                                                    props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.map((subrec, index) => {
                                                                                                                        return <td key={index} className="head-light text-center" width="7%">
                                                                                                                            <div className="sort-cell cursor-pointer">
                                                                                                                                <div className="table-sort">
                                                                                                                                </div>
                                                                                                                                {subrec.name}
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    }) : (<></>)
                                                                                                                }
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                data.subList.map((subdata, index) => {
                                                                                                                    return <tr key={index}>
                                                                                                                        <td>
                                                                                                                            {
                                                                                                                                (props.report.groupBy === 'user' || props.report.groupBy === 'enqAddedBy') ? (<div className="table-user-list">
                                                                                                                                    <a href="#">
                                                                                                                                        <div className="table-user-avatar">
                                                                                                                                            <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(subdata.profileImage, subdata.displayName)} /></div>
                                                                                                                                        {subdata.displayName}
                                                                                                                                    </a>
                                                                                                                                </div>) : (
                                                                                                                                    <div className="dealership-tbl">
                                                                                                                                        {subdata.displayName}
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                        {
                                                                                                                            props.report.columns.map((val, index) => {
                                                                                                                                return <td key={index} className={`text-center cursor-pointer ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}
                                                                                                                                    onClick={(e) => {
                                                                                                                                        handleShowEnquiryList(val, subdata)
                                                                                                                                    }}>{`${subdata[`arr${val}`].length}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`}</td>
                                                                                                                            })
                                                                                                                        }
                                                                                                                        {
                                                                                                                            props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.map((val, index) => {
                                                                                                                                return <td key={index} className={`text-center cursor-pointer`}
                                                                                                                                    onClick={(e) => {
                                                                                                                                        handleShowEnquiryList(val.id, subdata)
                                                                                                                                    }}>{`${subdata[`arr${val.id}`].length}${val.action === 'percentage' ? '%' : ''}`}</td>
                                                                                                                            }) : (<></>)
                                                                                                                        }
                                                                                                                    </tr>
                                                                                                                })
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ) : (
                                                                                        <tr className={`tr-hover-fix ${subListTbl[data.id] === true ? '' : 'hide'}`}>
                                                                                            <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)}>
                                                                                                <div className="spinner-loader h-100 p-5">
                                                                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                                                        <div className="no-data-txt mt-2"> No data</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </Fragment>)
                                                                        }) : (
                                                                            <tr>
                                                                                <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)}>
                                                                                    <div className="spinner-loader h-100 p-5">
                                                                                        <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                                            <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                                            <div className="no-data-txt mt-2"> No data</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>)
                                }) : (
                                    <tr>
                                        <td colSpan={props.report.columns.length + 1 + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0)}>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> No data</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                    {
                        sortedItems.length > 0 ?
                            (<tfoot>
                                <tr>
                                    <td className="table-sub-total"><div className="dealership-tbl cursor-pointer float-left">Totals</div></td>
                                    {
                                        props.report.columns.map((val, index) => {
                                            return <td key={index} className="text-center table-sub-total cursor-pointer"
                                                onClick={(e) => {
                                                    var cols = reportColumns.find(item => item.value === val)
                                                    if (cols && cols.percentage !== true) {
                                                        var IDs = [];
                                                        var name = `arr${cols.value}`;
                                                        clientReport.forEach(rec => {
                                                            IDs = [...IDs, ...rec[`arr${cols.value}`]]
                                                        })
                                                        handleShowEnquiryList(val, { [name]: IDs, [`arr${cols.value}`]: IDs })
                                                    }
                                                }}>
                                                {
                                                    (val === 'status-WonConv' ? (_.sumBy(clientReport, 'totalAppraisal') > 0 ? Math.round((100 / _.sumBy(clientReport, 'totalAppraisal')) * _.sumBy(clientReport, "status-Won")) > 100 ? 100 : Math.round((100 / _.sumBy(clientReport, 'totalAppraisal')) * _.sumBy(clientReport, "status-Won")) : 0) :
                                                        val === 'dealerPrice' ? <NumberFormat
                                                            thousandSeparator={true}
                                                            prefix={currencySymbol}
                                                            displayType={'text'}
                                                            value={Math.round((_.sumBy(clientReport, val) + Number.EPSILON) * 100) / 100}
                                                        /> : _.sumBy(clientReport, val))
                                                }
                                                {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                            </td>
                                        })
                                    }
                                    {
                                        props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.map((val, index) => {
                                            let ptg = val.columns ? _.sumBy(val.columns.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                            let total = val.columns1 ? _.sumBy(val.columns1.map(r => { return _.sumBy(clientReport, `${r}`) })) : 0;
                                            let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                            return <td key={index} className="text-center table-sub-total cursor-pointer"
                                                onClick={() => {
                                                    var IDs = [];
                                                    var name = `arr${val.id}`;
                                                    clientReport.forEach(rec => {
                                                        IDs = [...IDs, ...rec[`arr${val.id}`]]
                                                    })
                                                    handleShowEnquiryList(val.id, { [name]: IDs, [`arr${val.id}`]: IDs })
                                                }}>
                                                {
                                                    val.action === 'percentage' ? (
                                                        <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                    ) : (<>{_.sumBy(clientReport, val.id)}</>)
                                                }
                                            </td>
                                        }) : (<></>)
                                    }
                                </tr>
                            </tfoot>
                            ) : (<></>)
                    }
                </table>
            </div>
            ) : props.report.reportType === 'column' ?
                (
                    <div className="text-center">
                        <BarChart
                            id={`view-report-bar-chart-${props.report.documentID}`}
                            height={`${windowSize.tblHeight}px`}
                            data={progressReport}
                            columns={props.report.columns ? _.isArray(props.report.columns) ? props.report.columns : [props.report.columns] : []}
                            dynamicColumns={reportColumns}
                            xCategory={'displayName'}
                            setChartData={(data) => { setChartData(data) }}
                            calculationType={props.report.calculationType}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                        />
                    </div>
                ) : props.report.reportType === 'pie' ? (<>
                    {
                        progressReport.some(item => item[props.report.columns] > 0) ? (<PieChart
                            id={`view-report-pie-chart-${props.report.documentID}`}
                            height={`${windowSize.tblHeight}px`}
                            data={progressReport}
                            dynamicReport={true}
                            columns={_.isArray(props.report.columns) ? props.report.columns[0] : props.report.columns}
                            setChartData={(data) => { setChartData(data) }}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                        />) : (
                            <NoDataPieChart
                                id={'no-data-pie-chart'}
                                height={`${windowSize.tblHeight}px`}
                            />)
                    }
                </>) : props.report.reportType === 'target' ? (
                    <LineChart
                        id={`view-report-line-chart-${props.report.documentID}`}
                        height={`${windowSize.tblHeight}px`}
                        data={progressReport.map(rec => { return { ...rec, 'name': rec.displayName } })}
                        dynamicColumns={reportColumns}
                        xCategory={'displayName'}
                        //columns={props.report.target > 0 ? [props.report.columns, 'target'] : [props.report.columns]}
                        columns={props.report.target > 0 ? (_.isArray(props.report.columns) ? [...props.report.columns, 'target'] : [props.report.columns, 'target']) : (_.isArray(props.report.columns) ? props.report.columns : [props.report.columns])}
                        setChartData={(data) => { setChartData(data) }}
                        setChartImg={(img) => { setChartImg(img) }}
                        calculationType={props.report.calculationType}
                        handleShowEnquiryList={(val, rec) => {
                            handleShowEnquiryList(val, rec)
                        }}
                    />
                ) : (<></>)
        }
        <PopUpModal show={showModel}>
            <AddReport
                {...props}
                show={showModel}
                title={'Edit Report'}
                handleClose={handleModelClose}
                report={report}
            />
        </PopUpModal>
        <PopUpModal show={modelShow.tradeinPro}>
            <AppraisalList
                {...props}
                show={modelShow.tradeinPro}
                handleClose={handleModelClose}
                title={popHeader}
                history={props.history}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></AppraisalList>
        </PopUpModal>
        <PopUpModal show={modelShow.sheduleReport}>
            <ScheduleReport
                {...props}
                show={modelShow.sheduleReport}
                handleClose={handleModelClose}
                title={popHeader}
            ></ScheduleReport>
        </PopUpModal>
    </>)
}

export default ViewDynamicReport;