import React from 'react';
import moment from "moment";
import Translate from '../../../constants/translate';
import { InputCheckBox } from '../../../components';
const CustomToolbar = (toolbar) => {



    const goToDayView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'day')

        toolbar.onView("day");
        toolbar.handleChangeviewstate("day");
    };
    const goToWeekView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'week')

        toolbar.onView("week");
        toolbar.handleChangeviewstate("week");
    };
    const goToMonthView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'month')

        toolbar.onView("month");
        toolbar.handleChangeviewstate("month");
    };

    const goToYearView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'year')

        toolbar.onView("year");
        toolbar.handleChangeviewstate("year");
    };
    const goToAgendaView = () => {
        let mDate = toolbar.date;
        let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), 1);
        setDateRange(newDate, 'agenda')

        toolbar.onView("agenda");
        toolbar.onNavigate("next", newDate);
        toolbar.handleChangeviewstate("agenda");
    };
    const goToBack = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.viewstate === "month" || toolbar.viewstate === "agenda") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
        } else if (toolbar.viewstate === "week") {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7,
                1
            );
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 1,
                1
            );
        }
        setDateRange(newDate, toolbar.viewstate)

        toolbar.onNavigate("prev", newDate);
    };
    const goToNext = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.viewstate === "month" || toolbar.viewstate === "agenda") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
        } else if (toolbar.viewstate === "week") {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 7,
                1
            );
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 1,
                1
            );
        }
        setDateRange(newDate, toolbar.viewstate)
        toolbar.onNavigate("next", newDate);
    };

    const goToToday = () => {
        const now = moment()._d;
        toolbar.date.setDate(now.getDate());
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        setDateRange(now, toolbar.viewstate)
        toolbar.onNavigate("current");
    };

    const setDateRange = (date, view) => {
        toolbar.setDateRange({
            startDate: window.firebase.firestore.Timestamp.fromDate(moment(date).startOf('month').startOf('day').add(-7, 'day').toDate()),
            endDate: window.firebase.firestore.Timestamp.fromDate(moment(date).endOf('month').endOf('day').add(7, 'day').toDate()),
            date: moment(date).format('YYYY-MM')
        })
    }


    return (
        <div className="filter-sub-panel">
            <div className="d-flex justify-content-between ">
                <div className="float-left ml-4">                   
                    <div className="form-style">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <div className="text-left" >
                                    <label htmlFor="multiView"> <Translate text={'Scheduler Range View'} /></label>
                                    <span className="switch switch-sm float-right pl-2">
                                        <InputCheckBox
                                            className={`switch`}
                                            id="multiView"
                                            name="multiView"
                                            checked={toolbar.multiView ? toolbar.multiView : false}
                                            onChange={(e) => { toolbar.MultiViewChecked(e.target.checked); }} />
                                        <label htmlFor="multiView"></label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="calendar-year">{toolbar.label}</div>
                <div className="">
                    <div className="calendar-switch-buttons">
                        <div className="btn-group">
                            <button className="btn btn-default" onClick={goToToday}><Translate text={'today'} /></button>
                            <button type="button" className="btn btn-default btn-sm" onClick={goToBack}> <i className="fa fa-chevron-left"></i></button>
                            <button type="button" className="btn btn-default btn-sm" onClick={goToNext}> <i className="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                    {
                        (toolbar.accessToVehicleScheduler || toolbar.accessToVehicleBooking) ? (
                            <div className="tabber-nav">

                                {toolbar.accessToVehicleBooking && (<div className="float-right ml-2"><button type="button" className="btn btn-secondary float-right" onClick={toolbar.handleBooking}><i className="ico icon-add mr-2"></i> Booking</button></div>)}

                                {toolbar.accessToVehicleScheduler && (<div className="float-right ml-2"><button type="button" className="btn btn-primary float-right" onClick={toolbar.handleScheduler}><i className="ico icon-add mr-2"></i> Scheduler</button></div>)}
                            </div>
                        ) : (<></>)
                    }
                </div>
            </div>
        </div>
    );
};

export default CustomToolbar;