/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import { TextEditor } from '../../../../components'
import toast from 'toasted-notes'
import Translate from '../../../../constants/translate';
import Swal from 'sweetalert2'

const PrivacyTerms = (props) => {
    const [state, setState] = useState();
    const [loader, setLoader] = useState();
    const [key, setKey] = useState('driva');

    useEffect(() => {
        window.firebase.firestore().doc(`terms/${key}`)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.exists) {
                    setState(querySnapshot.data());
                }
            });
    }, [key])


    const onEditorStateChange = (obj) => {
        setState({
            ...state,
            [obj.name]: obj.value
        });
    }

    const saveTerms = () => {
        setLoader(true);
        window.firebase.firestore().doc(`terms/${key}`).set({
            ...state,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid
        }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Terms updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                setLoader(false);
                Swal.fire('Something went wrong.', '', 'error')
            });
    }
    return (
        <>
            <h2>Privacy Terms</h2>
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey="driva" transition={false} className="nav-fill" onSelect={(val) => {
                            setState(null);
                            setKey(val);
                        }}>
                            <Tab eventKey="driva" title="Driva Disclaimer">
                                <div className="settings-terms-wrap">
                                    <div className="form-group">
                                        <TextEditor
                                            name={'terms'}
                                            placeholder={'driva disclaimer'}
                                            value={state ? state.terms : ''}
                                            onChange={onEditorStateChange}
                                            toolbarOptions={['inline', 'list', 'textAlign']}
                                            height={450}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            {/* <Tab eventKey="test" title="Test Disclaimer">
                                <div className="settings-terms-wrap">
                                    <div className="form-group">
                                        <TextEditor
                                            name={'terms'}
                                            placeholder={'test disclaimer'}
                                            value={state ? state.terms : ''}
                                            onChange={onEditorStateChange}
                                            toolbarOptions={['inline', 'list', 'textAlign']}
                                            height={450}
                                        />
                                    </div>
                                </div>
                            </Tab> */}
                        </Tabs>
                    </nav>
                    <div className="settings-footer mt-3">
                        <button type="button" className="btn btn-primary float-right" onClick={() => saveTerms()}>
                            {
                                loader ?
                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                    : (<></>)
                            }
                            <Translate text={'save'} />
                        </button>
                    </div>
                </div>
            </div>

        </>
    );
}

export default PrivacyTerms;