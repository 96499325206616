import React, { useState, useEffect } from 'react'
import _ from 'lodash'


const EmailViewBrowser = (props) => {
    const [template, setTemplate] = useState();
    const [loader, setLoader] = useState(false)
    const [firestoreDB, setFirestoreDB] = useState()

    useEffect(() => {
        window.firebase.firestore().doc(`triggersLog/${props.id}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        setLoader(true);
        firestoreDB.firestore().doc(`triggersLog/${props.id}`)
            .get()
            .then(snap => {
                setLoader(false);
                if (snap.exists) {
                    document.getElementsByTagName('body')[0].classList.remove('daily-page-landing')
                    setTemplate(snap.data());
                }
            })

    }, [firestoreDB])

    return loader ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="report-loader spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) :
        !_.isEmpty(template) ? (
            <div className='container-fluid'>
                <div
                    dangerouslySetInnerHTML={{
                        __html: template.email.body.replace('>View Online<', '><'),
                    }}
                ></div>
            </div>
        ) : (<div className="unsubscribe-wrapper" style={{ paddingTop: '50px' }}>
            <div className="unsubscribe-box">
                <div className="unsubscribe-error">
                    <h3>Looks like this page doesn't exist.</h3>
                </div>
            </div>
        </div>)
};

export default EmailViewBrowser;