export const enqiuryOptions = [
    {
        name: 'Lead Orign',
        type: 'select',
        value: 'origin'
    },
    {
        name: 'Lost Reason',
        type: 'select',
        value: 'lostReason'
    },
    {
        name: 'Lost Sub Reason',
        type: 'select',
        value: 'lostSubReason'
    }
]