import React, { useState, useEffect } from 'react'
import "../../../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';

import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection, collectionData } from 'rxfire/firestore';

/** COMPONENTS */
import { default as _images } from '../../../../images';
import CommonHelper from '../../../../services/common';
import { ContentOverlay, FunnelChart, NoDataFunnelChart } from '../../../../components';
import Translate from '../../../../constants/translate';

import { pipelineCountVM } from '../../../dashboard/viewModel'
import { clientLookups } from '../../../settings/workflow/lookups/viewModel'

const UnionDailyActivityReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const [counts, setCounts] = useState(pipelineCountVM)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [allActivities, setActivities] = useState([]);
    const [mtdTestdrives, setTestdrives] = useState([]);
    const [mtdTradeins, setTradeins] = useState([]);
    const [mtdContacts, setContacts] = useState([]);
    const [mtdWalkins, setWalkins] = useState([]);
    const [client, setClient] = useState({});
    const [origins, setOrigins] = useState([]);
    const [brands, setBrands] = useState([]);
    const [clientSettings, setClientSettings] = useState();
    const [loadFirestore, setLoadFirestore] = useState(false);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? _date : moment().format('YYYY-MM-DD');

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('month')._d)
    })

    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('day')._d)
    let weekStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('week')._d)
    let weekEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('week')._d)
    const monthStart = dateRange.startDate
    const monthEnd = dateRange.endDate

    //moment().day("sunday").day()

    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: []
    })
    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])

    useEffect(() => {
        try {
            const getreportData = window.firebase.functions().httpsCallable('generic-getData');
            getreportData({
                type: 'getSummaryReport',
                params: JSON.stringify({
                    clientID: props.clientid,
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD')
                })
            }).then(async (response) => {
                if (response.data.success) {
                    let _data = response.data.data;
                    _data = _data.map(rec => {
                        return {
                            ...rec,
                            pipeline: rec.plnv,
                            stage: rec.stgv,
                            clientID: rec.cid,
                            vehicleMake: rec.mk,
                            vehicleModel: rec.ml,
                            vehicleSaletype: rec.st,
                            enquiryType: rec.etv,
                            origin: rec.orgv,
                            campaign: rec.cpgv,
                            owner: rec.uid,
                            status: rec.sts,
                            walkinDate: { seconds: Number(rec.dt) },
                            startDate: { seconds: Number(rec.dt) },
                            addedDate: { seconds: Number(rec.dt) },
                            wonDate: { seconds: Number(rec.wd) },
                            lostDate: { seconds: Number(rec.ld) },
                            deliveredDate: { seconds: Number(rec.dd) },
                            enquiryID: rec.eid,
                            documentID: rec.eid,
                        }
                    })
                    const clientData = await window.firebase.firestore().doc(`clients/${props.clientid}`).get();
                    if (clientData.exists) {
                        setClient(clientData.data())
                    }
                    let _enquiries = _data.filter(a => a.col === 'enq').map(rec => {
                        return {
                            ...rec,
                            testdrives: _data.filter(a => a.col === 'td' && a.eid === rec.eid),
                            walkins: _data.filter(a => a.col === 'wk' && a.eid === rec.eid)
                        }
                    });

                    _.uniqBy(_data.filter(a => a.col === 'wk'), 'enquiryID').forEach(rec => {
                        if (!_enquiries.some(a => a.eid === rec.enquiryID)) {
                            _enquiries.push({
                                ...rec,
                                addedDate: null,
                                wonDate: null,
                                lostDate: null,
                                deliveredDate: null,
                                testdrives: _data.filter(a => a.col === 'td' && a.eid === rec.enquiryID),
                                walkins: _data.filter(a => a.col === 'wk' && a.eid === rec.enquiryID)
                            })
                        }
                    })

                    _data.filter(a => a.col === 'enq' && a.wc > 1).forEach(rec => {
                        for (let i = 1; i < rec.wc; i++) {
                            _enquiries.push({
                                ...rec,
                                addedDate: null,
                                lostDate: null,
                                deliveredDate: null
                            })
                        }
                    })

                    setTestdrives(_data.filter(a => a.col === 'td'))
                    setTradeins(_data.filter(a => a.col === 'trd'))
                    setActivities(_data.filter(a => a.col === 'act'))
                    setEnquiries(_enquiries)
                    setWalkins(_data.filter(a => a.col === 'wk'))
                    setLoader(false)
                }
                else {
                    setLoadFirestore(true)
                }
            })
        }
        catch (err) {
            setLoadFirestore(true)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        if (props.clientid && loadFirestore) {

            setLoader(true)

            //LOST PENDING APPROVAL
            let lostPendingApproval = firestoreDB.firestore().collection('enquiries')
                .where('clientID', '==', props.clientid)
                .where('status', '==', 'pendingLost')
                .where('isDeleted', '==', false)

            //ALL PENDING BUCKET  
            let refPendingBucketData = firestoreDB.firestore().collection('enquiries')
                .where('clientID', '==', props.clientid)
                .where('pipeline', '==', 'LeadsBucket')
                .where('isDeleted', '==', false)

            //MTD CONVERTED ENQUIRIES  
            let refConvertedEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('clientID', '==', props.clientid)
                .where('convertedDate', '>=', dateRange.startDate)
                .where('convertedDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)
                .orderBy('convertedDate', 'desc')

            //MTD OPEN ENQUIRIES / CAMPAIGN /LEAD ORIGIN / ENQUIRY TYPE / LABEL  
            let refOpenEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('clientID', '==', props.clientid)
                .where('addedDate', '>=', dateRange.startDate)
                .where('addedDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)

            //MTD WON ENQUIRIES    
            let refWonEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('status', '==', 'won')
                .where('clientID', '==', props.clientid)
                .where('wonDate', '>=', dateRange.startDate)
                .where('wonDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)

            //MTD LOST ENQUIRIES    
            let refLostEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('status', '==', 'lost')
                .where('clientID', '==', props.clientid)
                .where('lostDate', '>=', dateRange.startDate)
                .where('lostDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)

            //MTD DELIVERED ENQUIRIES    
            let refDeliveredEnquiryData = firestoreDB.firestore().collection('enquiries')
                .where('status', '==', 'delivered')
                .where('clientID', '==', props.clientid)
                .where('deliveredDate', '>=', dateRange.startDate)
                .where('deliveredDate', '<=', dateRange.endDate)
                .where('isDeleted', '==', false)

            //MTD TEST DRIVES    
            let refTestDriveData = firestoreDB.firestore().collection('testdrives')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('startDate', '>=', dateRange.startDate)
                .where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')

            //MTD TRADE IN COUNT    
            let refTradeinData = firestoreDB.firestore().collection('tradeins')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('addedDate', '>=', dateRange.startDate)
                .where('addedDate', '<=', dateRange.endDate)


            //MTD DONE ACTIVITIES
            let refCompletedActivityData = firestoreDB.firestore().collection('activities')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('isDone', '==', true)
                .where('type', '==', 'activity')
                .where('modifiedDate', '>=', dateRange.startDate)
                .where('modifiedDate', '<=', dateRange.endDate)

            //MTD OVERDUE ACTIVITIES
            let refScheduleActivityData = firestoreDB.firestore().collection('activities')
                .where('clientID', '==', props.clientid)
                .where('isDeleted', '==', false)
                .where('isDone', '==', false)
                .where('type', '==', 'activity')
                .where('startDate', '>=', dateRange.startDate)
                .where('startDate', '<=', dateRange.endDate)
                .orderBy('startDate', 'desc')

            //MTD WALKIN LEADS
            let refWalkinEnquiryData = firestoreDB.firestore().collectionGroup('walkins')
                .where('clientID', '==', props.clientid)
                .where('walkinDate', '>=', dateRange.startDate)
                .where('walkinDate', '<=', dateRange.endDate)

            let refClientData = window.firebase.firestore().collection('clients').where('documentID', '==', props.clientid)


            var unionPipelines = combineLatest(
                refPendingBucketData ? collection(refPendingBucketData) : of([]),
                refConvertedEnquiryData ? collection(refConvertedEnquiryData) : of([]),
                lostPendingApproval ? collection(lostPendingApproval) : of([]),
                refOpenEnquiryData ? collection(refOpenEnquiryData) : of([]),
                refWonEnquiryData ? collection(refWonEnquiryData) : of([]),
                refLostEnquiryData ? collection(refLostEnquiryData) : of([]),
                refDeliveredEnquiryData ? collection(refDeliveredEnquiryData) : of([]),
                collection(refTestDriveData),
                collection(refTradeinData),
                collection(refCompletedActivityData),
                of([]),
                collection(refWalkinEnquiryData),
                collection(refClientData)
            ).pipe(
                map(([pendinBucket, convertedLeads, lostPendingApproval,
                    openLeads, wonLeads, lostLeads, deliveredLeads,
                    testdriveLeads, tradeinLeads,
                    doneActivity, scheduleActivity, walkins, clients]) => {
                    return [
                        _.map(pendinBucket, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(convertedLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(lostPendingApproval, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(openLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(wonLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(lostLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(deliveredLeads, function (obj) { return { ...convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }) } }),
                        _.map(testdriveLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(tradeinLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(doneActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(scheduleActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(walkins, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        _.map(clients, function (obj) { return { ...obj.data(), 'documentID': obj.id } })
                    ]
                }))
                .subscribe(allDocs => {
                    var _enquiries = [];
                    for (let i = 0; i < 7; i++) {
                        allDocs[i].forEach(el => {
                            if (!_enquiries.some(e => e.documentID === el.documentID)) {
                                _enquiries.push({
                                    ...el,
                                    testdrives: allDocs[7].filter(a => a.enquiryID === el.documentID),
                                    walkins: allDocs[11].filter(a => a.enquiryID === el.documentID)
                                })
                            }
                        });
                    }

                    allDocs[7].forEach(el => {//testdrive array
                        if (!_enquiries.some(e => e.documentID === el.enquiryID) && !_.isEmpty(el.enquiry) && !_.isEmpty(el.enquiry.addedDate)) {
                            _enquiries.push({
                                ...el.enquiry,
                                'documentID': el.enquiryID,
                                testdrives: [el],
                                walkins: allDocs[11].filter(a => a.enquiryID === el.documentID),
                            })
                        }
                    });
                    var _activities = []
                    for (let i = 9; i < 11; i++) {
                        allDocs[i].forEach(el => {
                            if (!_activities.some(e => e.documentID === el.documentID)) {
                                _activities.push(el)
                            }
                        });
                    }

                    setClient(allDocs[12] ? Object.assign({}, allDocs[12][0]) : {})
                    setTestdrives(allDocs[7])
                    setTradeins(allDocs[8])
                    setActivities(_activities)
                    //setEnquiries(_enquiries)
                    //setContacts(allDocs[10])
                    setWalkins(allDocs[11])
                    setTempData({
                        enquiries: _enquiries,
                        walkins: allDocs[11]
                    })

                    if (allDocs[11].length === 0) {
                        setLoader(false)
                        setLoadFirestore(false)
                    }
                });
            return () => {
                unionPipelines && unionPipelines.unsubscribe()
            }
        }

    }, [dateRange, loadFirestore, firestoreDB])



    useEffect(() => {
        async function getWalkinEnq(walkins, enquiries) {
            const promises = []
            walkins.forEach(el => {
                if (!enquiries.some(e => e.documentID === el.enquiryID)) {
                    promises.push(firestoreDB.firestore().doc(`enquiries/${el.enquiryID}`).get())
                }
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id };
                if (dataDoc.isDeleted === false && dataDoc.pipeline !== 'LeadsBucket') {
                    enquiries.push({
                        ...dataDoc,
                        walkins: walkins.filter(a => a.enquiryID === snap.id)
                    })
                }
            })

            setEnquiries(enquiries)
            setLoader(false)
            setLoadFirestore(false)

        }
        if (tempData.walkins.length > 0)
            getWalkinEnq(tempData.walkins, tempData.enquiries);
        else
            setEnquiries(tempData.enquiries);



    }, [tempData])

    useEffect(() => {
        if (!_.isArray(pipelineEnquiries) && !_.isArray(client))
            return

        if (!_.isEmpty(client.weekStart)) {
            weekStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('week').add(moment(_date).day(client.weekStart).day(), 'day')._d)
            weekEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('week').add(moment(_date).day(client.weekStart).day(), 'day')._d)
        }

        setCounts({
            todayNew: pipelineEnquiries.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekNew: pipelineEnquiries.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthNew: pipelineEnquiries.filter(a => a.pipeline !== "LeadsBucket"
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
            todayOpen: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekOpen: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthOpen: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
            todayWon: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= todayStart.seconds
                && a.wonDate.seconds <= todayEnd.seconds),
            weekWon: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= weekStart.seconds
                && a.wonDate.seconds <= weekEnd.seconds),
            monthWon: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= monthStart.seconds
                && a.wonDate.seconds <= monthEnd.seconds),
            todayLost: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= todayStart.seconds
                && a.lostDate.seconds <= todayEnd.seconds),
            weekLost: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= weekStart.seconds
                && a.lostDate.seconds <= weekEnd.seconds),
            monthLost: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= monthStart.seconds
                && a.lostDate.seconds <= monthEnd.seconds),
            todayDelivered: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= todayStart.seconds
                && a.deliveredDate.seconds <= todayEnd.seconds),
            weekDelivered: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= weekStart.seconds
                && a.deliveredDate.seconds <= weekEnd.seconds),
            monthDelivered: pipelineEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= monthStart.seconds
                && a.deliveredDate.seconds <= monthEnd.seconds),
            todayTestdrive: mtdTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= todayStart.seconds
                && a.startDate.seconds <= todayEnd.seconds),
            weekTestdrive: mtdTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= weekStart.seconds
                && a.startDate.seconds <= weekEnd.seconds),
            monthTestdrive: mtdTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= monthStart.seconds
                && a.startDate.seconds <= monthEnd.seconds),
            todayTradein: mtdTradeins.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekTradein: mtdTradeins.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthTradein: mtdTradeins.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= monthStart.seconds
                && a.addedDate.seconds <= monthEnd.seconds),
            todayActivity: allActivities.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= todayStart.seconds
                && a.addedDate.seconds <= todayEnd.seconds),
            weekActivity: allActivities.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= weekStart.seconds
                && a.addedDate.seconds <= weekEnd.seconds),
            monthActivity: _.uniq(_.map(allActivities.filter(a => !_.isEmpty(a.enquiryID)), function (e) {
                return e.enquiryID
            })),
            todayWalkin: pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= todayStart.seconds
                    && item.walkinDate.seconds <= todayEnd.seconds))),
            weekWalkin: pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= weekStart.seconds
                    && item.walkinDate.seconds <= weekEnd.seconds))),
            monthWalkin: pipelineEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket' &&
                (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= monthStart.seconds
                    && item.walkinDate.seconds <= monthEnd.seconds))),
        })

        let grouped = _.chain(_.orderBy(Object.assign([], pipelineEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
            && !_.isEmpty(a.wonDate)
            && a.wonDate.seconds >= monthStart.seconds
            && a.wonDate.seconds <= monthEnd.seconds)), ['vehicleMake'], ['asc']).filter(item => !_.isEmpty(item.vehicleMake) && item.pipleine !== 'LeadsBucket'))
            .groupBy("vehicleMake")
            .map((value, key) => (
                {
                    make: key,
                    models: (_.chain(_.orderBy(Object.assign([], value), ['vehicleModel'], ['asc']).filter(item2 => !_.isEmpty(item2.vehicleModel)))
                        .groupBy("vehicleModel")
                        .map((value, key) => (
                            {
                                name: key,
                                count: value.length
                            }
                        ))
                        .value())
                }
            ))
            .value()

        setBrands(grouped);

    }, [pipelineEnquiries, allActivities, mtdTestdrives, mtdTradeins, mtdWalkins, client])


    useEffect(() => {
        async function getClientSettings(clientID) {
            window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get().then(doc => {
                //console.log('currentSettings', doc.data())
                let settingsDoc = doc.data();
                setClientSettings(settingsDoc);

                if (!_.isEmpty(settingsDoc) && !_.isEmpty(settingsDoc.settings) && !_.isEmpty(settingsDoc.settings.origins)) {

                    setOrigins(_.chain(pipelineEnquiries.filter(a => !_.isEmpty(a.status) &&
                        (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                        && !_.isEmpty(a.origin)
                        && !_.isEmpty(a.wonDate)
                        && a.wonDate.seconds >= monthStart.seconds
                        && a.wonDate.seconds <= monthEnd.seconds))
                        .groupBy("origin")
                        .map((value, key) => ({
                            name: _.find(settingsDoc.settings.origins, { value: key }) ? _.find(settingsDoc.settings.origins, { value: key }).name : key,
                            count: value.length,
                            value: key,
                            startDate: monthStart,
                            endDate: monthEnd
                        }))
                        .value())


                }

            });
        }

        if (!_.isEmpty(client) && !_.isEmpty(client.documentID) && !_.isEmpty(pipelineEnquiries)) {
            getClientSettings(client.documentID)
        }
    }, [client, pipelineEnquiries])


    const convertEnquiryVM = (doc) => {
        let listVM = doc;

        if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
            listVM.vehicleMake = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.make : doc.requirement.make
            listVM.vehicleModel = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.model : doc.requirement.model
            listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;
            listVM.vehicleSaletype = doc.requirement.saleType;
            listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
        }
        return listVM
    }

    const calculatePercentage = (val1, val2) => {
        // const percentage =
        //     ((_.isNumber(val2) ? val2 : 0) / (_.isNumber(val1) ? val1 : 0)) * 100;
        //return parseFloat(percentage.toFixed(2)) + '%';
        return (val2 > 0 && val1 > 0 ?
            parseFloat((100 / (val1) * val2).toFixed(2)) + "%" :
            "0.00%");
    }

    useEffect(() => {
        if (_.isEmpty(counts) || counts.length === 0) {
            return
        }


        var groupbyStatus = []

        groupbyStatus.push({
            name: 'New Leads',
            count: counts.monthNew.length,
            value: 'New Leads',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })
        groupbyStatus.push({
            name: 'Appointments',
            count: counts.monthActivity.length,
            value: 'Appointments',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })
        groupbyStatus.push({
            name: 'Showroom Visits',
            count: counts.monthWalkin.length,
            value: 'Showroom Visits',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })
        groupbyStatus.push({
            name: 'Test Drives',
            count: counts.monthTestdrive.length,
            value: 'Test Drives',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })
        groupbyStatus.push({
            name: 'Sold Vehicles',
            count: counts.monthWon.length,
            value: 'Sold Vehicles',
            color: '#049b8c',
            type: 'status',
            startDate: monthStart,
            endDate: monthEnd
        })


        setData(groupbyStatus)

    }, [counts])

    const showList = () => {

    }

    // console.log('pipelineEnquiries', pipelineEnquiries)
    // console.log('allActivities', allActivities)
    // console.log('mtdTestdrives', mtdTestdrives)
    // console.log('mtdTradeins', mtdTradeins)
    // console.log('mtdContacts', mtdContacts)
    // console.log('mtdWalkins', mtdWalkins)
    // console.log('client', client)
    // console.log('clientSettings', clientSettings)
    // console.log('origins', origins)
    // console.log('brands', brands)

    return (
        <>
            {
                !_.isEmpty(client)
                    ?
                    <>
                        <div className="A4 landscape">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }


                                                </div></td>

                                                <td width="40%"><div className="header-right"> {'DAILY ACTIVITY REPORT'} <br></br>
                                                    <span> {moment(_date).format('DD MMMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">

                                    <table className="table-loop report-data-loaded report-data-load">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="10%">&nbsp;</th>
                                                <th width="10%">New Leads</th>
                                                <th width="10%">Open Leads</th>
                                                <th width="10%">Sold Leads</th>

                                                <th width="10%">Delivered </th>
                                                <th width="10%">Test Drive  </th>
                                                <th width="10%"> Trade-In </th>

                                                <th width="10%">Lost Leads</th>
                                                <th width="10%"> Walkin </th>
                                                {/* <th width="10%"> Activities </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">TODAY</td>
                                                <td className="count-large">{counts.todayNew.length}</td>
                                                <td className="count-large">{counts.todayOpen.length}</td>
                                                <td className="count-large">{counts.todayWon.length}</td>
                                                <td className="count-large">{counts.todayDelivered.length}</td>
                                                <td className="count-large">{counts.todayTestdrive.length}</td>
                                                <td className="count-large">{counts.todayTradein.length}</td>
                                                <td className="count-large">{counts.todayLost.length}</td>
                                                <td className="count-large">{counts.todayWalkin.length}</td>
                                                {/* <td>{counts.todayActivity.length}</td> */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS WEEK</td>
                                                <td className="count-large">{counts.weekNew.length}</td>
                                                <td className="count-large">{counts.weekOpen.length}</td>
                                                <td className="count-large">{counts.weekWon.length}</td>
                                                <td className="count-large">{counts.weekDelivered.length}</td>
                                                <td className="count-large">{counts.weekTestdrive.length}</td>
                                                <td className="count-large">{counts.weekTradein.length}</td>
                                                <td className="count-large">{counts.weekLost.length}</td>
                                                <td className="count-large">{counts.weekWalkin.length}</td>
                                                {/* <td>{counts.weekActivity.length}</td> */}
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS MONTH</td>
                                                <td className="count-large">{counts.monthNew.length}</td>
                                                <td className="count-large">{counts.monthOpen.length}</td>
                                                <td className="count-large">{counts.monthWon.length}</td>
                                                <td className="count-large">{counts.monthDelivered.length}</td>
                                                <td className="count-large">{counts.monthTestdrive.length}</td>
                                                <td className="count-large">{counts.monthTradein.length}</td>
                                                <td className="count-large">{counts.monthLost.length}</td>
                                                <td className="count-large">{counts.monthWalkin.length}</td>
                                                {/* <td>{counts.monthActivity.length}</td> */}
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="table-head">SOLD ENQUIRIES</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="30%">MAKE</th>
                                                <th width="50%">MODEL</th>
                                                <th width="20%">COUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {brands && brands.map((brand, i) => {
                                                return brand.models && brand.models.map((models, index) =>
                                                    index === 0 ? (
                                                        <tr key={'mk_' + i}>
                                                            <td rowSpan={brand.models.length}>
                                                                {brand.make}
                                                            </td>
                                                            <td rowSpan="1">
                                                                {models.name}
                                                            </td>
                                                            <td className="count-large">
                                                                {models.count}
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={'mo_' + index}>
                                                            <td rowSpan="1">
                                                                {models.name}
                                                            </td>
                                                            <td className="count-large">
                                                                {models.count}
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                    <div className="table-head">SOLD ENQUIRIES LEAD ORIGIN</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="80%">ORIGIN</th>
                                                <th width="20%">COUNT</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                origins && origins.map((origin, index) => {
                                                    return <tr key={index}>
                                                        <td>{origin.name}</td>
                                                        <td className="count-large">{origin.count}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>


                                    <div className="graph-wrapper">

                                        <div className="graph-left">
                                            <div className="graph-head">Sales Funnel</div>
                                            <div className="graph-panel">
                                                {
                                                    data ? (
                                                        <div className="dashboard-box-body">
                                                            <div className="text-center">
                                                                <FunnelChart
                                                                    id={'sales-funnel-chart'}
                                                                    height={'350px'}
                                                                    data={data}
                                                                    handleShowEnquiryList={showList}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="dashboard-box-body" style={{ height: `350px` }}>
                                                            <div className="text-center">
                                                                <NoDataFunnelChart
                                                                    id={'sales-funnel-chart'}
                                                                    height={'350px'}
                                                                />
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="graph-right">
                                            <table className="table-loop table-sub-fix table-td-fix">
                                                <thead>
                                                    <tr>
                                                        <th width="80%">KPI's</th>
                                                        <th width="20%">CONV. RATIO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>NEW LEADS Vs APPOINTMENTS</td>
                                                        <td className="count-large">
                                                            {calculatePercentage(counts.monthNew.length, counts.monthActivity.length)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>APPOINTMENTS Vs SOLD</td>
                                                        <td className="count-large">
                                                            {calculatePercentage(counts.monthActivity.length, counts.monthWon.length)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>TEST DRIVES Vs SOLD</td>
                                                        <td className="count-large">
                                                            {calculatePercentage(counts.monthTestdrive.length, counts.monthWon.length)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>


                                </div>
                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default UnionDailyActivityReport;