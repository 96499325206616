/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    InputText
} from '../../components';
import CommonHelper from '../../services/common';
import { default as _images } from '../../images';
import { firestoreDB, validateEmail } from '../../services/helper';


const emailList = [
    { active: true, value: 'cc', label: 'Add CC' }
]

export const objCustomer = {
    fullName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    phoneCode: '',
    phone: '',
    email: '',
    emails: [],
    sendEmail: true
}

export default class SendFeedback extends Component {
    constructor(props) {
        super(props);

        this.isLoading = false;
        this.mandatoryFields = [];
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('formFeedback');
        this.state = {
            fields: Object.assign({}, objCustomer),
            errors: {},
            showParts: false,
            searchText: '',
            phoneCodes: [],
        }
    }

    loadRecomm(props) {
        const { show, contact } = props;
        const fields = Object.assign({}, objCustomer);

        if (show === true && contact) {
            const objData = Object.assign({}, contact);

            for (let [key, value] of Object.entries(objData)) {
                if (fields.hasOwnProperty(key))
                    fields[key] = value;
            }
            fields.fullName = CommonHelper.getFullName(null, objData);
            this.setState({ fields: fields });
        }
    }



    componentDidMount() {
        this.loadRecomm(this.props);
    }



    handleEmailChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        const newEmail = !_.isEmpty(state.emails) && state.emails.map((data, sidx) => {
            if (idx !== sidx) return data;
            return { ...data, value: evt.target.value };
        });
        state['emails'] = newEmail
        this.setState({ fields: state });
    };
    handleAddEmail = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveEmail = idx => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };



    handleOnEmailChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        fields[name] = value;

        if (value) {
            let lastAtPos = value.lastIndexOf('@');
            let lastDotPos = value.lastIndexOf('.');

            if (lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') === -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)
                fields['sendEmail'] = true
            else
                fields['sendEmail'] = false
        }
        else
            fields['sendEmail'] = false

        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleOnChange = e => {
        const { name, value } = e.target;
        const { fields, errors } = this.state;
        this.setState({
            fields: {
                ...fields,
                [name]: value
            },
            errors: {
                ...errors,
                [name]: ''
            }
        });

    };

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }

        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }
    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();
        let errorClass = 'input_error';
        const { fields } = this.state;
        let formIsValid = true;
        let errors = Object.assign({}, this.state.errors);
        if (_.isEmpty(fields.email) || !validateEmail(fields.email)) {
            formIsValid = false;
            errors['email'] = errorClass;
        }
        if (_.isEmpty(fields.subject)) {
            formIsValid = false;
            errors['subject'] = errorClass;
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.isLoading = true;
            const sendEmail = window.firebase.functions().httpsCallable('generic-sendEmail');
            sendEmail({
                subject: fields.subject,
                recipients: [fields.email],
                emailBody: this.props.content,
                clientID: this.props.dealersettings.client.id,
            }).then((response) => {
                if (response.data.success) {
                    firestoreDB(this.props.dealersettings).firestore().doc(`formFeedback/${this.props.documentID}`).set({
                        sentDate: window.firebase.firestore.Timestamp.now(),
                        modifiedDate: window.firebase.firestore.Timestamp.now()
                    }, { merge: true })
                    toast.notify('Email sent successfully.', {
                        duration: 2000,
                    });
                    this.props.handleClose();
                }
                else {
                    this.isLoading = false;
                    //console.log(response.data.message);
                    Swal.fire(CommonHelper.showLocale(this.props, 'Something went wrong'), '', 'error')
                }
            })
        }

    };

    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };


    render() {
        const { fullName, email, subject } = this.state.fields;
        const { show } = this.props;



        return (
            <>
                <Modal
                    id="modal-add-service-part"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-confirm-quotation`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={this.props.title} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">

                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'Customer Name'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'full name'}
                                                className={`form-control ${this.state.errors["fullName"]}`}
                                                name="fullName"
                                                onChange={(e) => { e.preventDefault(); }}
                                                value={fullName}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            {/* <DropDownMenu
                                                text='+ add CC Email'
                                                keyVal="1002"
                                                tIndex="-1"
                                                className={`inputlink-addmore ${this.state.errors["emails"] ? 'label-error' : ''}`}
                                                menu={emailList}
                                                handleChange={this.handleAddEmail}
                                            >
                                            </DropDownMenu> */}
                                            <label ><Translate text={'email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'email'}
                                                className={`form-control ${this.state.errors["email"]}`}
                                                name="email"
                                                onChange={this.handleOnEmailChange}
                                                value={email}
                                            //readOnly={true}
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'Subject'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'subject'}
                                                className={`form-control ${this.state.errors["subject"]}`}
                                                name="subject"
                                                onChange={this.handleOnEmailChange}
                                                value={subject}
                                            //readOnly={true}
                                            />
                                        </div>

                                        {/* {!_.isEmpty(emails) && emails.map((email, idx) => (

                                            <div className="form-group col-md-12" key={idx}>
                                                <a href="#" className="inputlink-remove" onClick={this.handleRemoveEmail(idx)}>- <Translate text={'remove'} /></a>
                                                <label >{email.name} <Translate text={'email'} /> </label>
                                                <InputText
                                                    type="text"
                                                    className="form-control"
                                                    id={idx}
                                                    placeholder={`${email.name.toLowerCase()} email `}
                                                    value={email.value}
                                                    onChange={this.handleEmailChange(idx)}
                                                />
                                            </div>
                                        ))} */}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'send'} />
                        </button>

                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}
