import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ContentOverlay, TableView, ReactSelect, PopUpModal, InputCheckBox } from "../../../../components";
import { cafeSettingsFields, objCafeItem } from './viewModel'
import AddCafeItem from './addCafeItem'
import { default as _images } from "../../../../images";
import Translate from '../../../../constants/translate';
import { ClientContext } from '../clientContext'
import CommonHelper from '../../../../services/common';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CafeWorkingHours from './workingHours'

const CafeSettings = (props) => {

    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
    const [loader, setLoader] = useState(true)
    const [searchText, setSearchText] = useState('');
    const [selectedClient, setSelectedClient] = useState([])
    const [clientOptions, setClientOptions] = useState([]);
    const [popheader, setPopheader] = useState('');
    const [showModel, setShowModel] = useState(false)
    const [cafeItems, setCafeItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [cafeCategories, setCafeCategories] = useState([])
    const [workingHoursModel, setWorkingHoursModel] = useState({})
    const { clients } = useContext(ClientContext);


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 259)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true, })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            }
            else {
                setSelectedClient(_.find(clients, { 'documentID': selectedClient.documentID }))
            }
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(selectedClient))
            return
        setLoader(true)
        window.firebase.firestore().collection(`cafeItems`)
            .where('clientID', '==', selectedClient.documentID)
            .where('isDeleted', '==', false)
            .onSnapshot(querySnapshot => {
                let _items = [];
                querySnapshot.docs.forEach(doc => {
                    const _userdoc = {
                        ...doc.data(),
                        documentID: doc.id
                    };
                    _items.push(convertVM(_userdoc))
                })
                // console.log('_items', _items)
                _items = _items.map((item, index) => {
                    // console.log(index, item.sortIndex, 'cafeItemName ', item.name)
                    return { ...item, sortIndex: (item.sortIndex || item.sortIndex === 0) ? item.sortIndex : index }
                })
                _items = _.orderBy(_items, ['sortIndex'], ['asc'])
                setCafeItems(_items)
                setLoader(false)
            })
    }, [selectedClient])

    useEffect(() => {
        const { dealersettings } = props;
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings) && !_.isEmpty(dealersettings.client.settings.cafecategory)) {
            //console.log('dealersettings.client.settings.cafecategory', dealersettings.client.settings.cafecategory)
            // @memo: filter out with active status as well
            const _cafeCategories = dealersettings.client.settings.cafecategory.map(a => {
                return { ...a, label: a.name }
            })
            setCafeCategories(_cafeCategories)
        } else {
            setCafeCategories([])
        }
    }, [props.dealersettings])

    const convertVM = (doc) => {
        const objuserData = Object.assign({}, doc);
        const listVM = Object.assign({}, objCafeItem);
        for (let [key, value] of Object.entries(objuserData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }

        listVM.sortIndex = doc.sortIndex;
        listVM.cafeName =
            <>
                <div className="media cafe-add-box">
                    <div className="float-left" >
                        <img src={doc.image ? doc.image : _images.addcafe} alt="" className="img-fluid img-thumbnail img-object-fit" />
                    </div>
                    <div className="media-body">
                        <div className="cafe-add-item"><strong>{listVM.name} </strong></div>
                    </div>
                </div>
            </>
        return listVM
    }

    const handleSwitchChange = (e, i, data) => {
        if (_.isEmpty(data))
            return

        setCafeItems(_.map(cafeItems, function (obj) {
            return obj.documentID === data.documentID ? { ...obj, 'isAvailable': e.target.checked ? true : false } : { ...obj }
        }))

        const updateRef = window.firebase.firestore().doc(`cafeItems/${data.documentID}`);
        updateRef.update({ 'isAvailable': e.target.checked ? true : false }).then((docRef) => {
            toast.notify('Cafe Item updated successfully', {
                duration: 2000
            });
        }).catch((error) => {
            console.error("Error updating enquiries: ", error);
        });
    }

    const handleClientdetailsClick = (e, id, data) => {
        e.preventDefault();
        handleEditSettings(data)
    }

    const handleActionClick = (id, data) => {

        return (
            <div className="action-fix" >
                <div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    handleEditSettings(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>
                <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    handleDeleteItem(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>
            </div>
        )


    }

    const handleAddUser = () => {
        setSelectedItem(null)
        setPopheader('Add Cafe Item')
        setShowModel(true)
    }

    const handleEditSettings = (data) => {
        const item = Object.assign({}, objCafeItem);
        for (let [key, value] of Object.entries(data)) {
            if (item.hasOwnProperty(key))
                item[key] = value;
        }
        setSelectedItem(item);
        setPopheader('Edit Cafe Item')
        setShowModel(true)
    }
    const handleDeleteItem = (data) => {

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                // console.log('data delete', data)
                window.firebase.firestore().doc(`cafeItems/${data.documentID}`)
                    .set({ isDeleted: true }, { merge: true })
                    .then(() => {
                        setLoader(false);
                        toast.notify(`Cafe Item deleted successfully`, {
                            duration: 2000
                        })
                        if (data.sortIndex === cafeItems.length - 1) {
                            // console.log('no need to update any sortindex')
                        } else {
                            cafeItems.forEach(a => {
                                if (a.sortIndex !== data.sortIndex && a.sortIndex > data.sortIndex) {
                                    // console.log(a.sortIndex, 'cafeItemName ', a.name)
                                    const updateRef = window.firebase.firestore().doc(`cafeItems/${a.documentID}`);
                                    updateRef.update({ sortIndex: a.sortIndex - 1 }).then((docRef) => {

                                    }).catch((error) => {
                                        console.error("Error updating enquiries: ", error);
                                    });
                                }
                            })
                        }

                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })
            }
        })
    }

    const handleModelClose = () => {
        setSelectedItem(null)
        setShowModel(false)
    }

    const onDragEnd = (result) => {
        if (_.isEmpty(result))
            return

        // console.log('onDragEnd result', result)

        const sourceIndex = result.source.index
        const destinationIndex = result.destination.index

        if (sourceIndex === destinationIndex) {
            return
        }

        let _cafeItems = [...cafeItems]

        if (destinationIndex > sourceIndex) {
            // console.log('move down')
            // console.log('destinationIndex', destinationIndex)
            // console.log('sourceIndex', sourceIndex)

            _cafeItems = _cafeItems.map((item, ind) => {
                if (ind >= sourceIndex && ind <= destinationIndex) {
                    //   console.log('index', ind)
                    if (ind === sourceIndex) {
                        //   console.log('1A')
                        return { ...item, sortIndex: destinationIndex }
                    } else {
                        // console.log('2A')
                        return { ...item, sortIndex: ind - 1 }
                    }
                } else {
                    return item
                }
            })
        } else if (sourceIndex > destinationIndex) {
            // console.log('move up')
            // console.log('destinationIndex', destinationIndex)
            // console.log('sourceIndex', sourceIndex)

            _cafeItems = _cafeItems.map((item, ind) => {
                if (ind >= destinationIndex && ind <= sourceIndex) {
                    if (ind === sourceIndex) {
                        // console.log('1S', ind, item.name)
                        return { ...item, sortIndex: destinationIndex }
                    } else {
                        // console.log('2C', ind, item.name)
                        return { ...item, sortIndex: ind + 1 }
                    }
                } else {
                    return item
                }
            })
        }

        _cafeItems = _.orderBy(_cafeItems, ['sortIndex'], ['asc'])

        setCafeItems(_cafeItems)
        _cafeItems.forEach(a => {
            // console.log(a.sortIndex, 'cafeItemName ', a.name)
            const updateRef = window.firebase.firestore().doc(`cafeItems/${a.documentID}`);
            updateRef.update({ sortIndex: a.sortIndex }).then((docRef) => {

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        })

    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    return (<>
        <h2><Translate text={'Cafe Settings'} /></h2>
        <div className='divider-line'></div>
        <div className="mt-4">
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={clientOptions}
                                    name={"client"}
                                    placeholder={'select client'}
                                    onChange={(e) => {
                                        setLoader(true)
                                        setSelectedClient(e.data)
                                    }}
                                    value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            <div className="filter-search search-icon col-md-3">
                                <input placeholder="search..." aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="float-right">
                            <button type="button" className="btn btn-primary float-right" onClick={() => { handleAddUser(); }}><i className="ico icon-add mr-2"></i><Translate text={'addItem'} /> </button>
                        </div>
                        <div className="float-right mr-2">
                            <button type="button" className="btn btn-green float-right" onClick={() => { setWorkingHoursModel({ show: true, title: 'Cafe Working Hours' }) }}><i className="bg-icons ico icon-time mr-2 mt-1"></i><Translate text={'Working Hours'} /> </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    cafeItems.length > 0 ? (
                        <div className="common-table">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <table
                                    className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="head-light" style={{ width: '20px' }}><Translate text={'SNo'} /></th>
                                            <th scope="col" className="head-light" width="60%"><Translate text={'name'} /></th>
                                            <th scope="col" className="head-light" width="20%"><Translate text={'Category'} /></th>
                                            <th scope="col" className="head-light border-right-0" width="20%"><Translate text={'available'} /></th>
                                            <th scope="col" className="head-light border-left-0 border-right-0" style={{ width: '60px' }}>&nbsp;</th>
                                            <th scope="col" className="head-light border-left-0" style={{ width: '20px' }}>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <Droppable droppableId="table">
                                        {(provided, snapshot) => (
                                            <tbody  {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                {
                                                    _.orderBy(cafeItems.filter(({ name }) =>
                                                    (!_.isEmpty(searchText) ? (
                                                        (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false)
                                                    ) : true)
                                                    ), ['sortIndex'], ['asc'])
                                                        .map((rec, index) => {
                                                            return (
                                                                <Draggable key={rec.documentID} draggableId={rec.documentID} index={index} >
                                                                    {(provided, snapshot) => (
                                                                        <tr
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <td id={`tr-${rec.documentID}`} style={{ width: '20px' }}>
                                                                                {index + 1}
                                                                            </td>
                                                                            <td width="60%">
                                                                                <div className="float-left"><img style={{ width: '65px', height: '50px' }} src={rec.image ? rec.image : _images.addcafe} alt="" className="img-fluid img-thumbnail img-object-fit" /> <span>{rec.name}</span></div>

                                                                            </td>
                                                                            <td width="20%">
                                                                                <div className="float-left"><span>{cafeCategories.filter(a => a.value === rec.category).length ? cafeCategories.filter(a => a.value === rec.category)[0].label : ''}</span></div>

                                                                            </td>
                                                                            <td width="20%" className=" border-right-0"><div className="float-left">
                                                                                <span className={`switch switch-sm mr-2 ${rec.disableEdit === true ? 'btn-disable' : ''}`}>
                                                                                    <InputCheckBox
                                                                                        className={`switch`}
                                                                                        id={`${rec.documentID}_${index}`}
                                                                                        name={rec.documentID}
                                                                                        checked={Boolean(rec.isAvailable)}
                                                                                        onChange={(e) => { handleSwitchChange(e, index, rec) }} />
                                                                                    <label htmlFor={`${rec.documentID}_${index}`}></label>
                                                                                </span>
                                                                            </div></td>
                                                                            <td style={{ width: '60px' }} className=" border-left-0 border-right-0">
                                                                                <div className="action-fix">
                                                                                    {
                                                                                        (rec.disableEdit === true || rec.level === 'default') ? (<div style={{ cursor: 'pointer' }} className="mini-button mr-2">
                                                                                            <i className="ico icon-lock"></i>
                                                                                        </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            // hadleEditLookup(rec)
                                                                                            handleEditSettings(rec)
                                                                                        }}>
                                                                                            <i className="ico icon-edit"></i>
                                                                                        </div>)
                                                                                    }
                                                                                    {
                                                                                        (rec.disableEdit === true || rec.level === 'default') ? (<div style={{ cursor: 'pointer' }} className="mini-button">
                                                                                            <i className="ico icon-lock"></i>
                                                                                        </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            handleDeleteItem(rec)
                                                                                        }}>
                                                                                            <i className="ico icon-delete"></i>
                                                                                        </div>)
                                                                                    }

                                                                                </div>
                                                                            </td>
                                                                            <td style={{ width: '20px' }} className=" border-left-0"><div className="field-drag"> <i className="ico icon-drag"></i></div></td>

                                                                            {provided.placeholder}
                                                                        </tr>)}
                                                                </Draggable>)
                                                        })
                                                }
                                                {provided.placeholder}
                                            </tbody>
                                        )}
                                    </Droppable>
                                </table>
                            </DragDropContext>
                        </div>
                    ) : (
                        <div className="common-table">
                            <div className="text-center p-5">
                                <p><Translate text={'No cafe items found'} /></p>
                            </div>
                        </div>
                    )
            }
        </div>
        <PopUpModal show={showModel}>
            <AddCafeItem
                {...props}
                show={showModel}
                title={popheader}
                handleClose={handleModelClose}
                clientID={selectedClient.documentID ? selectedClient.documentID : ''}
                Item={selectedItem}
                cafeItems={cafeItems}
                cafeCategories={cafeCategories}
            />
        </PopUpModal>
        <PopUpModal show={workingHoursModel.show}>
            <CafeWorkingHours
                {...props}
                {...workingHoursModel}
                handleClose={() => {
                    setWorkingHoursModel({ show: false })
                }}
                selectedClient={selectedClient}
                clientOptions={clientOptions}
            />
        </PopUpModal>
    </>);
}

export default CafeSettings;