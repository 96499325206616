import React, { useState, useEffect, useMemo, useCallback } from 'react';
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import CommonHelper from "../../services/common";
// import _images from '../../images'
import { PopoverwithFooter, PieChart, NoDataPieChart } from '../../components'
// import FilterPanel from './filterPanel'

const ServiceTypeCounts = (props) => {
    const [data, setData] = useState([])
    // const [selectedPipeline, setPipeline] = useState(null)
    // const [pipelineOptions, setPipelineOption] = useState([])
    // const [userOptions, setUserOptions] = useState([])
    // const [selectedUser, setUser] = useState([])

    //const [loader, setLoader] = useState(true)
    // const [refreshData, setRefresh] = useState(true)
    // const [filterPopOver, setFilterPopOver] = useState({ show: false, target: null })
    // const [filter, setFilter] = useState({ pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',') });

    const [monthStart, setMonthStart] = useState()
    const [monthEnd, setMonthEnd] = useState()
    const [loader, setLoader] = useState(true);
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);


    useEffect(() => {
        setMonthStart(props.dateRange.startDate)
        setMonthEnd(props.dateRange.endDate)
    }, [props.dateRange])

    const fetchData = async () => {
        let _filter = {
            clientID: props.dealersettings.client.id,
            type: 'serviceType',
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props.dateRange) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }
        if (props.selectedPipeline) {
            _filter.pipeline = props.selectedPipeline.value
        } else if (!_.isEmpty(props.pipeline)) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }

        if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);
        
        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getDashboardWidgetsV2" : "getDashboardWidgets",
            params: JSON.stringify(_filter),
        }
        setLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData2');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            let serviceTypes_ = resp.data.data.map(serviceType => {
                let name = serviceType.name
                if (!_.isEmpty(props.dealersettings.client.settings.serviceTypes) && props.dealersettings.client.settings.serviceTypes.length > 0) {
                    if (props.dealersettings.client.settings.serviceTypes.filter(a => a.value === serviceType.name).length > 0)
                        name = props.dealersettings.client.settings.serviceTypes.filter(a => a.value === serviceType.name)[0].name
                    else name = 'Other/Blank';
                }
                return {
                    ...serviceType,
                    name: (name === 'others' ? 'Other/Blank' : name),
                    value: serviceType.name,
                    startDate: moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD'),
                    endDate: moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }
            })
            serviceTypes_ = _.orderBy(serviceTypes_, ['count'], ['desc'])
            setData(serviceTypes_)
            setFirstTimeLoaded(true)
            setLoader(false)
        } else {
            toast.closeAll();
            // Swal.fire('Something went wrong.');
            setFirstTimeLoaded(true)
            setLoader(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.selectedUser, props.selectedPipeline, props.dateRange])




    useEffect(() => {
        if (_.isEmpty(props.dealersettings.client.settings.serviceTypes) || props.pipelineEnquiries.length === 0) {
            //setLoader(false)
            return
        }



        var _pipelines = _.map(props.pipeline, function (e) { return e.value; })
        if (!_.isEmpty(props.selectedPipeline)) {
            _pipelines = [props.selectedPipeline.value]
        }


    }, [props.pipelineEnquiries, props.selectedUser, props.selectedPipeline])


    const reportData = useMemo(() => data.filter(item => item.count > 0), [data])

    return (
        <>
            <div className="dashboard-box">
                {
                    loader && !firstTimeLoaded ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setFirstTimeLoaded(false); fetchData(); }}> <i className="ico icon-refresh"></i></a>
                        {/* <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a> 
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); }}> <i className="ico icon-more"></i></a>*/}
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                    </div>
                    <h3>Leads By Service Types</h3>
                </div>
                {
                    reportData.some(item => item.count > 0) ? (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <PieChart
                                    id={'servicetype-pie-chart'}
                                    height={'350px'}
                                    data={reportData}
                                    dateRange={props.dateRange}
                                    handleShowEnquiryList={(value, startDate, endDate) => {
                                        // console.log('campagins clicksed', value, startDate, endDate)
                                        props.handleShowEnquiryList({
                                            'serviceType': value,
                                            'status': 'all',
                                            'pipeline': _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }),
                                            'startDate': startDate,
                                            'endDate': endDate,
                                            'dateType': 'addedDate'
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                            <div className="text-center">
                                <NoDataPieChart
                                    id={'servicetype-pie-chart'}
                                    height={'370px'}
                                />
                            </div>

                        </div>
                    )
                }
            </div >
            {/* <PopoverwithFooter
                showpopover={filterPopOver.show}
                targetpopover={filterPopOver.target}
                title='Filter'
                position='left'
                className='popover-dashboard-filter'
                closepopover={() => {
                    setFilterPopOver({ show: false, target: '' })
                }}>
                <FilterPanel
                    userOptions={userOptions}
                    pipelineOptions={pipelineOptions}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    viewOtherEnquiry={viewOtherEnquiry}
                    setPipeline={(e) => {
                        if (e) {
                            setPipeline(e)
                            setFilter({
                                ...filter,
                                pipeline: e.value
                            })
                        }
                        else {
                            setPipeline(null)
                            setFilter({
                                ...filter,
                                pipeline: props.pipeline.filter(item => item.value !== 'LeadsBucket').map(v => _.pick(v, ['value']).value).join(',')
                            })
                        }

                    }}
                    setUser={(e) => {
                        if (e) {
                            setUser(e)
                            setFilter({
                                ...filter,
                                userId: e.value
                            })
                        }
                        else {
                            setUser(null)
                            setFilter({
                                ...filter,
                                userId: ''
                            })
                        }
                    }}
                    applyFilter={() => {
                        setRefresh(true);
                        setFilterPopOver({ show: false, target: '' })
                    }}
                    clearFilter={() => {
                        setFilterPopOver({ show: false, target: '' })
                    }}
                />
            </PopoverwithFooter> */}
        </>
    );
}

export default ServiceTypeCounts;