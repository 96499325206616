export const tradeinOptions = [
    {
        name: 'Make',
        type: 'select',
        value: 'make'
    },
    {
        name: 'Group',
        type: 'select',
        value: 'group'
    },
    {
        name: 'Type',
        type: 'select',
        value: 'type'
    },
    {
        name: 'Model',
        type: 'select',
        value: 'model'
    },
    {
        name: 'Year',
        type: 'select',
        value: 'year'
    },
    {
        name: 'Model Description',
        type: 'text',
        value: 'modelDescription'
    },
    {
        name: 'Registration Number',
        type: 'text',
        value: 'regNo'
    },
    {
        name: 'Registration Expiry',
        type: 'date',
        value: 'regDate'
    },
    {
        name: 'Drive Type',
        value: 'driveType',
        type: 'text'
    },
    {
        name: 'Fuel Type',
        value: 'fuelType',
        type: 'text'
    },
    {
        name: 'Cylinders',
        value: 'cylinders',
        type: 'number'
    },
    {
        name: 'Gears',
        value: 'gears',
        type: 'number'
    },
    {
        name: 'Seats',
        value: 'seats',
        type: 'number'
    },
    {
        name: 'Power kW',
        value: 'powerKw',
        type: 'number'
    },
    {
        name: 'Range',
        value: 'range',
        type: 'number'
    },
    {
        name: 'Series',
        value: 'series',
        type: 'text'
    },
    {
        name: 'Torque Nm',
        value: 'torqueNm',
        type: 'text'
    },
    {
        name: 'Transmission Type',
        value: 'transmissionType',
        type: 'text'
    },
    {
        name: 'Wheelbase',
        value: 'wheelbase',
        type: 'text'
    },
    {
        name: 'Registration State',
        value: 'regState',
        type: 'text'
    },
    {
        name: 'Registration Status',
        value: 'regStatus',
        type: 'text'
    },
    {
        name: 'Badge',
        value: 'badge',
        type: 'text'
    },
    {
        name: 'Region',
        value: 'region',
        type: 'text'
    },
    {
        name: 'Chassis No.',
        type: 'text',
        value: 'chassisNo'
    },
    {
        name: 'Mileage',
        type: 'number',
        value: 'mileage'
    },
    {
        name: 'Status',
        type: 'select',
        value: 'status'
    },
    {
        name: 'Compliance Date',
        type: 'date',
        value: 'complianceDate'
    },
    {
        name: 'Build Date',
        type: 'date',
        value: 'buildDate'
    },
    {
        name: 'Colour',
        type: 'text',
        value: 'exteriorColor'
    },
    {
        name: 'Interior Colour / Trim',
        type: 'text',
        value: 'interiorColor'
    },
    {
        name: 'Trim',
        type: 'text',
        value: 'trim'
    },
    {
        name: 'Engine No.',
        type: 'text',
        value: 'engineNo'
    },
    {
        name: 'Engine Capacity-Litres',
        type: 'text',
        value: 'engineSize'
    },
    {
        name: 'Engine Type',
        type: 'select',
        value: 'engineType'
    },
    {
        name: 'Transmission',
        type: 'select',
        value: 'transmission'
    },
    {
        name: 'Vehicle Condition',
        type: 'select',
        value: 'vehicleCondition'
    },
    {
        name: 'Doors',
        type: 'select',
        value: 'doors'
    },
    {
        name: 'Spare Key',
        type: 'text',
        value: 'spareKey'
    },
    {
        name: 'Log Books',
        type: 'text',
        value: 'logBooks'
    },
    {
        name: 'Service History',
        type: 'text',
        value: 'serviceHistory'
    },
    {
        name: 'Cargo Blind',
        type: 'text',
        value: 'cargoBlind'
    },
    {
        name: 'Head Rests',
        type: 'text',
        value: 'headRests'
    },
    {
        name: 'SD Card',
        type: 'text',
        value: 'sdCard'
    },
    {
        name: 'Front Right Wheel',
        type: 'select',
        value: 'frontRightWheel'
    },
    {
        name: 'Front Left Whee',
        type: 'select',
        value: 'frontLeftWheel'
    },
    {
        name: 'Rear Right Wheel',
        type: 'select',
        value: 'rearRightWheel'
    },
    {
        name: 'Rear Left Wheel',
        type: 'select',
        value: 'rearLeftWheel'
    },
    {
        name: 'Spare Wheel',
        type: 'select',
        value: 'spareTyre'
    },
    {
        name: 'Owners',
        type: 'text',
        value: 'owners'
    },
    {
        name: 'Service Cont. From',
        type: 'date',
        value: 'serviceContFrom'
    },
    {
        name: 'Service Cont. To',
        type: 'date',
        value: 'serviceContTo'
    },
    {
        name: 'Last Service',
        type: 'date',
        value: 'lastService'
    },
    {
        name: 'Warranty Expiry',
        type: 'date',
        value: 'warrantyExpiry'
    },
    {
        name: 'Finance Co.',
        type: 'text',
        value: 'financeCo'
    },
    {
        name: 'Tyre Age',
        type: 'text',
        value: 'tyreAge'
    },
    {
        name: 'Reconditioning Cost',
        type: 'price',
        value: 'reconditioningCost'
    },
    {
        name: 'Massage Ventilated',
        type: 'toggle',
        value: 'massageVentilated'
    },
{
        name: 'Contrast Stiching',
        type: 'toggle',
        value: 'contrastStiching'
    },
    {
        name: 'Sunroof',
        type: 'toggle',
        value: 'sunroof'
    },
    {
        name: 'Camera',
        type: 'toggle',
        value: 'camera'
    },
    {
        name: 'Wood Steering',
        type: 'toggle',
        value: 'woodSteering'
    },
    {
        name: 'Warranty',
        type: 'toggle',
        value: 'warranty'
    },
    {
        name: 'Reconditioning',
        type: 'toggle',
        value: 'reconditioning'
    },
    {
        name: 'Financed',
        type: 'toggle',
        value: 'financed'
    },
    {
        name: 'Outstanding Payments',
        type: 'toggle',
        value: 'outstandingPayments'
    },
    {
        name: 'Specifications',
        type: 'text',
        value: 'specifications'
    },
    {
        name: 'Notes',
        type: 'text',
        value: 'notes'
    },

]