import React from 'react';
import _ from 'lodash';
import { Tabs, Tab } from 'react-bootstrap'
import { TextEditor } from '../../../../components'
import CommonHelper from '../../../../services/common';

const PrivacyTerms = ({ dealer, onEditorStateChange, sales, service, dealersettings, servicefleetproModule }) => {


    return dealer ? (
        <>
            <Tabs defaultActiveKey={`customerconsent`} className="nav-fill">
                <Tab eventKey="customerconsent" title={CommonHelper.showLocale({ dealersettings }, "Customer Consent")}>
                    <div className="settings-terms-wrap p-2">
                        <div className="form-group">
                            <TextEditor
                                name={'customerConsent'}
                                placeholder={'customer consent'}
                                value={dealer.customerConsent}
                                onChange={onEditorStateChange}
                                toolbarOptions={['inline', 'list', 'textAlign']}
                                height={450}
                            />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="digitalconsent" title={CommonHelper.showLocale({ dealersettings }, "Digital Consent")}>
                    <div className="settings-terms-wrap p-2">
                        <div className="form-group">
                            <TextEditor
                                name={'digitalConsent'}
                                placeholder={'digital consent'}
                                value={dealer.digitalConsent}
                                onChange={onEditorStateChange}
                                toolbarOptions={['inline', 'list', 'textAlign']}
                                height={450}
                            />
                        </div>
                    </div>
                </Tab>
                {
                    sales ? (
                        <Tab eventKey="testdrivetc" title={CommonHelper.showLocale({ dealersettings }, "Test Drive")}>
                            <div className="settings-terms-wrap p-2">
                                <div className="form-group">
                                    <TextEditor
                                        name={'testdriveTC'}
                                        placeholder={'test drive terms & conditions'}
                                        value={dealer.testdriveTC}
                                        onChange={onEditorStateChange}
                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                        height={450}
                                    />
                                </div>
                            </div>
                        </Tab>

                    ) : (<></>)
                }
                {
                    (sales || service) ? (
                        <Tab eventKey="loanvehicletc" title={CommonHelper.showLocale({ dealersettings }, "Loan Vehicle")}>
                            <div className="settings-terms-wrap p-2">
                                <div className="form-group">
                                    <TextEditor
                                        name={'fleetTC'}
                                        placeholder={'loan vehicle terms & conditions'}
                                        value={dealer.fleetTC}
                                        onChange={onEditorStateChange}
                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                        height={450}
                                    />
                                </div>
                            </div>
                        </Tab>


                    ) : (<></>)
                }
                {
                    sales ? (
                        <Tab eventKey="insurancewaiver" title={CommonHelper.showLocale({ dealersettings }, "insuranceWaiver")}>
                            <div className="settings-terms-wrap p-2">
                                <div className="form-group">
                                    <TextEditor
                                        name={'insuranceTC'}
                                        placeholder={'insurance waiver terms & conditions'}
                                        value={dealer.insuranceTC}
                                        onChange={onEditorStateChange}
                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                        height={450}
                                    />
                                </div>
                            </div>
                        </Tab>
                    ) : (<></>)
                }
                {
                    sales ? (
                        <Tab eventKey="financetc" title={CommonHelper.showLocale({ dealersettings }, "finance")}>
                            <div className="settings-terms-wrap p-2">
                                <div className="form-group">
                                    <TextEditor
                                        name={'financeTC'}
                                        placeholder={'finance terms & condition'}
                                        value={dealer.financeTC}
                                        onChange={onEditorStateChange}
                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                        height={450}
                                    />
                                </div>
                            </div>
                        </Tab>) : (<></>)
                }
                {
                    sales ?
                        (
                            <Tab eventKey="creditScoreTC" title={CommonHelper.showLocale({ dealersettings }, "Finance Credit Score")}>
                                <div className="settings-terms-wrap p-2">
                                    <div className="form-group">
                                        <TextEditor
                                            name={'creditScoreTC'}
                                            placeholder={'credit score terms & condition'}
                                            value={dealer.creditScoreTC}
                                            onChange={onEditorStateChange}
                                            toolbarOptions={['inline', 'list', 'textAlign']}
                                            height={450}
                                        />
                                    </div>
                                </div>
                            </Tab>
                        ) : (<></>)
                }
                {
                    sales ?
                        (
                            <Tab eventKey="quotationtc" title={CommonHelper.showLocale({ dealersettings }, "quotation")}>
                                <div className="settings-terms-wrap p-2">
                                    <div className="form-group">
                                        <TextEditor
                                            name={'quotationTC'}
                                            placeholder={'quotation terms & condition'}
                                            value={dealer.quotationTC}
                                            onChange={onEditorStateChange}
                                            toolbarOptions={['inline', 'list', 'textAlign']}
                                            height={450}
                                        />
                                    </div>
                                </div>
                            </Tab>
                        ) : (<></>)
                }
                {
                    service ? (
                        <Tab eventKey="serviceDisclaimer" title={CommonHelper.showLocale({ dealersettings }, "Service Disclaimer")}>
                            <div className="settings-terms-wrap p-2">
                                <div className="form-group">
                                    <TextEditor
                                        name={'serviceDisclaimer'}
                                        placeholder={'service disclaimer'}
                                        value={dealer.serviceDisclaimer}
                                        onChange={onEditorStateChange}
                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                        height={450}
                                    />
                                </div>
                            </div>
                        </Tab>
                    ) : (<></>)
                }
                {
                    servicefleetproModule ? (
                        <Tab eventKey="serviceFleetProTC" title={CommonHelper.showLocale({ dealersettings }, "Service Fleet Pro")}>
                            <div className="settings-terms-wrap p-2">
                                <div className="form-group">
                                    <TextEditor
                                        name={'serviceFleetProTC'}
                                        placeholder={'service disclaimer'}
                                        value={dealer.serviceFleetProTC}
                                        onChange={onEditorStateChange}
                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                        height={450}
                                    />
                                </div>
                            </div>
                        </Tab>
                    ) : (<></>)
                }
            </Tabs>
        </>
    ) : (<></>);
}

export default PrivacyTerms;