/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { useHistory } from 'react-router-dom';

import CommonHelper from '../../../services/common';
import { AutoComplete, InputText, InputTextArea, PopUpModal, ReactSelect, InputRadio, Terms } from '../../../components';
import "../../../styles/attendeeConfirmation.scss";
import _ from 'lodash'
import AddGuest from './addGuest';
import images from '../../../images';
import Swal from 'sweetalert2';
import { validateEmail } from '../../../services/helper';
import { defaultTitles } from '../../../admin/settings/company/oemSetup/viewModel';
import moment from 'moment';
import { eventStatus } from '../../../services/enum';

const defaultContact =
{
    title: null,
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    phone: null,
    address: null,
    dietaryPreference: null,
    preferredRetailer: null,
    areaCode: null,
    ownedVehicle: {
        make: null,
        model: null,
        year: null
    },
    clientID: null
}

const AttendeeConfirmationSource = props => {
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _type = params.get('type');
    const [loader, setLoader] = useState(true);

    const [events, setEvents] = useState([])
    const [source, setSource] = useState({})
    const [client, setClient] = useState({})

    useEffect(() => {
        if (props.sourceid) {
            window.firebase.firestore().doc(`eventsProTPSource/${props.sourceid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setSource({
                            ...doc.data(),
                            id: doc.id
                        })
                    }
                    else {
                        setSource({
                            isDeleted: true
                        })
                    }
                })
        }
        else {
            setSource({
                isDeleted: true
            })
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(source))
            return
        if (source?.isDeleted || !source?.active) {
            setLoader(false)
        } else {
            loadEvents();
        }

    }, [source])

    const loadEvents = async () => {

        if (source?.oemID) {
            const clientDoc = await window.firebase.firestore().collection(`clientSettings`).doc(source?.oemID).get();
            if (clientDoc.exists) {
                setClient(clientDoc.data());
            }
        }

        const collectionPromise = [];
        if (source?.eventsProIDs?.length) {
            source.eventsProIDs.forEach(rec => {
                collectionPromise.push(window.firebase2.firestore().doc(`eventsPro/${rec}`).get());
            })
        }
        if (collectionPromise.length > 0) {
            Promise.all(collectionPromise)
                .then(docs => {
                    let _events = [];
                    docs.forEach(doc => {
                        if (doc.data().status !== 'closed' && doc.data().isDeleted === false)
                            _events.push({
                                ...doc.data(),
                                id: doc.id
                            })
                    })
                    _events = _.orderBy(_.uniqBy(_events, 'id'), ["addedDate"], ["desc"])
                    if (_events.length === 1) {
                        history.push(`/attendee/confirmation/eoi/${_events[0].documentID}?source=${source.id}&type=${_type}`);
                    }
                    setEvents(_events);
                    setLoader(false)
                })
        } else {
            setLoader(false)
        }
    }

    return loader ? (
        <>
            <div className='loader-center-screen'>
                <div className='spinner-loader h-100 '>
                    <div className='d-flex h-100 justify-content-center align-items-center'>
                        <div role='status' className='spinner-border'></div>
                        <p className='text-muted mt-3'></p>
                    </div>
                </div>
            </div>
        </>
    ) : source.isDeleted || !source.active ? (
        <>
            <main role="main">
                <div className="container">
                    <div className="eventclosed-wrap-bg">
                        <div className="eventclosed-wrap eventclosed-position-fix">
                            <div className="eventclosed-center-wrap  mt-4">
                                <div className="eventclosed-landing-wrap ">
                                    <div className="eventclosed-landing-wrap-head">
                                        <div className="eventclosed-landing-image"><img src={images.eventClosed} alt="" height="150" /></div>
                                        <h2>Event is Closed/Inactive</h2>
                                        <p>The link you followed may be broken or the page may have been removed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    ) : (<>

        <main role="main">
            <div className="eventslanding-header">
                <div className="container">
                    <div className="eventslanding-logo-wrap"> <span className="eventslanding-logo-img mr-3">
                        {
                            client?.logoURL
                                ?
                                <img src={client.logoURL} height="60" />
                                :
                                <div className='eventslanding-info-card-title'>{client.name}</div>
                        }
                    </span >
                        <div className="eventslanding-logo-title"> </div>
                    </div >
                </div >
            </div >

            <div className="eventslanding-banner">
                {/* {
                    event.imageURL ? (
                        <img src={event.imageURL} />
                    ) : (<></>)
                } */}
                <img src={images.singlelink_landing_bg}></img>
            </div>


            <div className="container">

                <div className="eventslanding-wrap-bg ">
                    <div className="eventslanding-wrap">

                        <div className="eventslanding-singlelink-wrap">


                            <div className="singlelink-wrapper">
                                <div className="singlelink-head">
                                    <h1>Events</h1>
                                    <h3>Please select your preferred event</h3>

                                </div>

                                {
                                    !_.isEmpty(events)
                                        ?
                                        <>
                                            {
                                                events.map((_event, index) => {
                                                    return <div key={index} className="card mb-3">
                                                        <div className="row no-gutters">
                                                            <div className="col-md-3 singlelink-image">

                                                                {
                                                                    _event.imageURL ? (
                                                                        <img src={_event.imageURL} alt="" className="card-img" />
                                                                    ) : (
                                                                        <img src={images.noEvent} alt="" className="card-img" />
                                                                    )
                                                                }

                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{_event.title}</h5>
                                                                    <div className="singlelink-listing">
                                                                        <ul className="eventslanding-singlelink-listing">
                                                                            <li> <i className="ico icon-date"></i>{CommonHelper.showCombineDate(_event.startDate, _event.endDate, _event.timezone)}</li>
                                                                            <li><i className="ico icon-time"></i> {CommonHelper.showCombineTime(_event.startDate, _event.endDate, _event.timezone)}</li>
                                                                            <li> <i className="ico icon-location"></i> {_event.location}</li>
                                                                        </ul>
                                                                    </div>
                                                                    {
                                                                        _event.description ? (
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: _event.description,
                                                                                }}
                                                                            ></div>
                                                                        ) : (<></>)
                                                                    }
                                                                    <div className="singlelink-buttonwrap">
                                                                        <button type="button" className="btn btn-primary float-left" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            let pathname = window.location.pathname;
                                                                            history.push(`/attendee/confirmation/eoi/${_event.documentID}?source=${source.id}&type=${_type}&back-url=${pathname}`);
                                                                        }}>Select</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </> : <></>
                                }
                            </div>


                            <div className="eventslanding-footer-bottom">
                                <div className="eventslanding-footer-bottom-sub">
                                    <div className="eventslanding-cpy-wrap"> <img src={images.safeTag} alt="" width="120" /></div>
                                    {/* <div className="eventslanding-cpy-wrap"> Contact us if you are interested in joining one of our events or would like more information at {client?.email}, or phone {client?.phone}</div> */}
                                    <div className="clear"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main >
    </>
    );
};

export default AttendeeConfirmationSource;
