import React, { useState } from 'react';
import {  Modal } from 'react-bootstrap';
import { default as _images } from '../../images';
import _ from 'lodash';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';

const AddVehicles = ({
	show,
	handleClose,
	requirements,
	handleAddRequirement,
	title,
	dealersettings,
}) => {
	const [requirementsArr, setRequirements] = useState(requirements);
	const handleSubmit = async () => {
		let selectedRequirements = requirementsArr.filter(
			requirement => requirement.Checked && requirement.Checked === true,
		);
	//	console.log('selectedRequirements,', selectedRequirements);
		handleAddRequirement(selectedRequirements);
		handleClose();
	};

	const handleChange = (e, checked, id) => {
		// console.log('check');
		let selectedRequirement = requirementsArr.filter(
			requirement => requirement.documentID === id,
		);

		let otherSelectedRequirements = requirementsArr.filter(
			requirement => requirement.documentID !== id,
		);

		if (checked === true) {
			selectedRequirement[0].Checked = false;
		} else {
			selectedRequirement[0].Checked = true;
		}

		const newArray = [...otherSelectedRequirements, selectedRequirement[0]];
		// console.log(newArray);
		setRequirements(newArray);
	};
	
	const returnReqVal = (object, key) => {
		if (!_.isEmpty(object.stock))
			return object.stock[key] ? object.stock[key] : ''
		else
			return object[key] ? object[key] : ''
	}

	return (
		<Modal
			id='add-finance'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-add-finance'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className='modal-title'><Translate text={title ? title : 'Multiple Vehicle'} /></h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='multi-list-wrap'>
							{/* <div className="alert alert-danger" role="alert">
 Please select a vehicle for Lorem ipsum dolor sit amet.
</div> */}
							{_.orderBy(requirementsArr, ['addedDate.seconds']).map(
								requirement => (
									<div
										className='multi-vehicle-item'
										key={requirement.documentID}
									>
										<div className='multi-list-check'>
											<div className='checkbox icheck-success'>
												<input
													type='checkbox'
													id={requirement.documentID}
													checked={requirement.Checked}
													onChange={e =>
														handleChange(
															e,
															requirement.Checked,
															requirement.documentID,
														)
													}
												/>
												<label htmlFor={requirement.documentID}></label>
											</div>
										</div>
										<div className='vehicle-item flex-vehicle'>
											<div className='enquiry-more'> </div>
											<div className='vehicle-item-image'>
												{' '}
												<a>
													<img
														src={CommonHelper.showBrandLogo(dealersettings, returnReqVal(requirement, 'make'))} className="img-object-fit-contain"
														width='70'
														height='70'
														alt=''
													/>{' '}
												</a>{' '}
											</div>
											<div className='vehicle-data'>
												<div className='vehicle-item-title'>
													{`${returnReqVal(requirement, 'make')}
													 ${returnReqVal(requirement, 'model')}`}
													</div>
												<div className='vehicle-item-info'>
													{`${returnReqVal(requirement, 'extColor') }
													 ${returnReqVal(requirement, 'intColor') },
													  ${returnReqVal(requirement, 'year')}`}
												</div>
												{/* <div className='vehicle-item-info'>Stock #ST-2538</div> */}
												<div className='vehicle-item-status'>
													<div className='badge badge-pill badge-white'>
													{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
														returnReqVal(requirement, 'saleType'), '')}
														
													</div>
													{/* <div className='badge badge-pill badge-available ml-1'>
													Available
												</div> */}
													{returnReqVal(requirement, 'price') && (
														<div className='vehicle-item-price'>{`$${returnReqVal(requirement, 'price')}`}</div>
													)}
												</div>
											</div>
										</div>
									</div>
								),
							)}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className='btn btn-primary float-right'
					onClick={e => {
						e.preventDefault();
						handleSubmit();
						handleClose();
					}}
				>
					<Translate text={'Add'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddVehicles;
