export const formVM = {
    documentID: null,
    title: '',
    type: '',
    fields: '',
    clientID: null,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    visibility: '',
    oemID: null,
    groupID: null,
    regionID: null
}

export const visibilities = [
    { active: true, value: 'private', label: 'Private' },
    { active: true, value: 'shared', label: 'Shared' }
]

export const formListFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Created On',
        value: 'createdOn',
        sortValue: 'createdOn',
        subText: 'createdBy',
        flex: 1,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        subText: 'updatedBy',
        flex: 1,
        default: true
    },
    {
        name: 'Visibility',
        value: 'visibilityDOM',
        width: 80,
        flex: 0,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 200,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]
