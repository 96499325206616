/** LIBRARIES */
import React, { useState, useReducer, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip, Dropdown, Tabs, Tab } from 'react-bootstrap';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import { ContentOverlay, PopUpModal } from '../../../components'
import CommonHelper from '../../../services/common'
import { default as _images } from '../../../images';
import ActivityView from './activityView'
import ActivityPanel from './activityPanel';
import AcitivitylogReducer from './activitylogReducer'
import AddActivity from '../../activity'
import Translate from '../../../constants/translate';
/** VIEW-MODELS */
import { objActivitylog, activitylogTypes, objWalkIn } from '../viewModel'
import ActivityStatus from '../../activity/actvityStatusModal';
import { enqUnActionStatus, enquiryStatus } from '../../../services/enum';
import { firestoreDB } from '../../../services/helper';

const AcitivitiesReducer = (state, action) => {
    function cancelEditable() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.notes = action.data.oldnotes
                item.callDuration = action.data.oldcallDuration
                item.callStatus = action.data.oldcallStatus
                item.addedDate = action.data.oldaddedDate
                return item;
            }
            return item;
        });
    }
    function updatechangelogs() {
        return state.map((item, index) => {
            if (item.documentID === action.id) {
                item[action.field] = action.value;
                return item;
            }
            return item;
        });
    }

    function updateCustomerlog() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.isNewlog = false;
                for (let [key, value] of Object.entries(action.data)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function editCustomerlog() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = true;
                item.oldnotes = item.notes
                item.oldcallDuration = item.callDuration
                item.oldcallStatus = item.callStatus
                item.oldaddedDate = item.addedDate
                return item;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data;
        }
        case "ADDNEWLOG": {
            return [action.data, ...state];
        }
        case "REMOVELOG": {
            return state.filter(item => item.documentID !== action.data.documentID);
        }
        case "CANCELLOG": {
            return cancelEditable(action.data);
        }
        case "CHANGELOG": {
            return updatechangelogs();
        }
        case "SAVELOG": {
            return [...state, action.data];
        }
        case "EDITLOG": {
            return editCustomerlog();
        }
        case "UPDATELOG": {
            return updateCustomerlog();
        }
        default:
            return state;
    }
};


function ActivityTab(props) {
    const [loadingActivity, setLoadingActivity] = useState(true)
    const [showActivity, setShowActivity] = useState(false);
    const [activitylogs, dispatch] = useReducer(AcitivitiesReducer, [])
    const acitivtyTags = ["Overdue", "Today", "Upcoming", "Completed"]
    const [activeAcitivtyTag, setActiveactivity] = useState('Today')
    const [selectedActivitylog, setSelectedActivitylog] = useState(null);
    const [activeTab, setActivetab] = useState('appointment')
    const [editable, setEditable] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [showActivityModel, setShowActivityModel] = useState(false)
    const [stockObj, setStockObj] = useState(false)
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState({ "Overdue": true, "Today": true, "Upcoming": true, "Completed": true })
    const [pagingLoader, setPagingloader] = useState(false)
    const [activityCounter, setActivityCounter] = useState({ "overdue": 0, "today": 0, "upcoming": 0, "Completed": 0 })
    const pageLimit = 6
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const [isNewActivity, setNewActivity] = useState(false)
    const [filtertype, setFiltertype] = useState(null)
    const [showScheduleActivityModel, setShowScheduleActivityModel] = useState({
        show: false,
        activityLog: null,
        contact: null,
        enquiry: null
    })
    const [showOutcomeModal, setShowOutcomeModal] = useState({
        show: false,
        clsActive: '',
        isMandatory: false,
        data: {}
    });

    const [activityTypes, setActivityTypes] = useState(activitylogTypes);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;

    const enableCustShowNoShow = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

    useEffect(() => {

        if (props.enquiry)
            setActivityCounter(props.enquiry.counts);
        else if (props.tradeinPro)
            setActivityCounter(props.tradeinPro.counts);
        else if (props.service)
            setActivityCounter(props.service.counts);

        const { dealersettings } = props;
        let _clientID = (!_.isEmpty(props.enquiry) ? props.enquiry.clientID : (!_.isEmpty(props.tradeinPro) ? props.tradeinPro.clientID : (!_.isEmpty(props.service) ? props.service.clientID : null)))
        const _clientData = _clientID && dealersettings && dealersettings.group && dealersettings.group.clients[_clientID]
        let setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        if (_clientData)
            setttings = dealersettings.group.clients[_clientID].settings;

        if (setttings) {
            const _activityTypes = [];
            setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
                _activityTypes.push({
                    ico: `ico icon-${doc.icon}`,
                    name: doc.name,
                    type: doc.value
                });
            });

            if (!_.isEmpty(_activityTypes))
                setActivityTypes(_activityTypes);
        }

    }, [props])

    useEffect(() => {
        let _clientID = (!_.isEmpty(props.enquiry) ? props.enquiry.clientID : (!_.isEmpty(props.tradeinPro) ? props.tradeinPro.clientID : (!_.isEmpty(props.service) ? props.service.clientID : null)))
        let _actName = activityTypes.filter(e => e.type === activeTab)[0] ? activityTypes.filter(e => e.type === activeTab)[0].name : null;
        if (_.isEmpty(_actName)) {
            const { dealersettings } = props;
            let _settings = dealersettings && dealersettings.client && dealersettings.client.settings;
            _actName = CommonHelper.getNameByValue(_settings && _settings.activityTypes, activeTab, '')
        }

        let newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = CommonHelper.getActivityType('activity', props.originType);
        newlogVM.subType = activeTab;
        newlogVM.title = _actName;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();

        if (!_.isEmpty(props.enquiry)) {
            newlogVM.enquiryID = props.enquiry.documentID;
            newlogVM.enquiry = CommonHelper.getMinifiedData(props.enquiry, 'enquiry');
            if (!_.isEmpty(props.enquiry.contact)) {
                newlogVM.contactID = props.enquiry.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.enquiry.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.tradeinPro)) {
            newlogVM.tradeinProID = props.tradeinPro.documentID;
            newlogVM.tradeinPro = CommonHelper.getMinifiedData(props.tradeinPro, 'tradeinPro');
            if (!_.isEmpty(props.tradeinPro.contact)) {
                newlogVM.contactID = props.tradeinPro.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.tradeinPro.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.eventsPro)) {
            newlogVM.eventsProID = props.eventsPro.documentID;
            newlogVM.eventsPro = CommonHelper.getMinifiedData(props.eventsPro, 'eventsPro');
            if (!_.isEmpty(props.eventsPro.contact)) {
                newlogVM.contactID = props.eventsPro.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.eventsPro.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.service)) {
            newlogVM.serviceID = props.service.documentID;
            newlogVM.service = CommonHelper.getMinifiedData(props.service, 'service');
            if (!_.isEmpty(props.service.contact)) {
                newlogVM.contactID = props.service.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.service.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.contact)) {
            newlogVM.contactID = props.contact.documentID;
            newlogVM.contact = CommonHelper.getMinifiedData(props.contact, 'contact');
        }

        const { dealersettings, clientModule } = props;
        newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null, _clientID);

        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = window.firebase.firestore.Timestamp.fromDate(moment()._d);
        newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(['call'].includes(activeTab) ? moment().add(15, 'minutes')._d : moment().add(1, 'hours')._d);
        newlogVM.addedByName = CommonHelper.getUserNamebyId(allUsers, localStorage.uid);
        setSelectedActivitylog(newlogVM)
    }, [activeTab])


    useEffect(() => {
        return () => {
            window.unsubquickviewactivitylist && window.unsubquickviewactivitylist();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad[activeAcitivtyTag]) {
            return;
        }
        setPagingloader(true)
        let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('activities')
            //.where('enquiryID', '==', props.enquiry.documentID)
            .where('type', '==', CommonHelper.getActivityType('activity', props.originType))
            .where('isDeleted', '==', false)

        if (props.tradeinPro)
            refActivitiesData = refActivitiesData.where('tradeinProID', '==', props.tradeinPro.documentID)
        else if (props.service)
            refActivitiesData = refActivitiesData.where('serviceID', '==', props.service.documentID)
        else
            refActivitiesData = refActivitiesData.where('enquiryID', '==', props.enquiry.documentID)

        // if (activeAcitivtyTag === 'Overdue') {
        //     refActivitiesData = refActivitiesData
        //         .where('isDone', '==', false)
        //         .where('startDate', '<', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
        // }
        // else if (activeAcitivtyTag === 'Upcoming') {
        //     refActivitiesData = refActivitiesData
        //         .where('isDone', '==', false)
        //         .where('startDate', '>', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
        // }
        // else if (activeAcitivtyTag === 'Today') {
        //     refActivitiesData = refActivitiesData
        //         .where('isDone', '==', false)
        //         .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
        //         .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
        // }
        // else {
        //     refActivitiesData = refActivitiesData
        //         .where('isDone', '==', true)
        // }


        // if (activeAcitivtyTag === 'Completed') {
        //     refActivitiesData = refActivitiesData
        //         .orderBy('startDate', 'desc')
        // }
        // else {
        //     refActivitiesData = refActivitiesData
        //         .orderBy('startDate', 'asc')
        // }

        //.limit(pageLimit)
        // let lastRecord = hasMore.filter(e => e.type === activeAcitivtyTag);
        // if (lastRecord.length > 0) {
        //     refActivitiesData = refActivitiesData
        //         .startAfter(lastRecord[0].lastDoc)
        //         .limit(pageLimit)
        // }
        // else {
        //     refActivitiesData = refActivitiesData
        //         .limit(pageLimit)
        // }

        window.unsubquickviewactivitylist = refActivitiesData
            .onSnapshot(onCollectionUpdate);
    }, [checkDataLoad, activeAcitivtyTag])

    const onCollectionUpdate = (querySnapshot) => {
        // let actionType;
        // let snapshotDoc;
        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = {
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         };
        //         if (change.type === 'added') {
        //             actionType = "ADDNEWLOG";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "UPDATE_ACTIVITY"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "REMOVELOG"
        //         }
        //     }
        // })
        // const _activities = [];
        // if (!actionType) {
        //     if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
        //         setHasMoreData([...hasMore.filter(e => e.type !== activeAcitivtyTag),
        //         {
        //             type: activeAcitivtyTag,
        //             lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
        //         }])
        //     }
        //     else {
        //         setHasMoreData([...hasMore.filter(e => e.type !== activeAcitivtyTag)]);
        //     }
        //     querySnapshot.forEach(function (doc) {
        //         const objlogData = Object.assign({}, doc.data());

        //         objlogData.documentID = doc.id;
        //         const logVM = Object.assign({}, objActivitylog);
        //         for (let [key, value] of Object.entries(objlogData)) {
        //             logVM[key] = value;
        //         }
        //         _activities.push(logVM)
        //     });
        // }
        // dispatch({
        //     type: actionType ? actionType : "SUCCESS",
        //     data: actionType ? snapshotDoc : _activities,
        //     activeTab: activeAcitivtyTag
        // });
        // setDataload({
        //     ...checkDataLoad,
        //     [activeAcitivtyTag]: false
        // })
        // setLoadingActivity(false)
        // setPagingloader(false)


        const _activities = [];
        querySnapshot.forEach(function (doc) {
            const objlogData = Object.assign({}, doc.data());

            objlogData.documentID = doc.id;
            const logVM = Object.assign({}, objActivitylog);
            for (let [key, value] of Object.entries(objlogData)) {
                logVM[key] = value;
            }
            if (logVM.type === CommonHelper.getActivityType('log', props.originType)) {
                logVM.startDate = logVM.addedDate
            }
            logVM.owner = (!_.isEmpty(logVM.owner) ? logVM.owner : logVM.addedBy);
            _activities.push(logVM)
        });
        setLoadingActivity(false)

        dispatch({
            type: "SUCCESS",
            data: _activities
        });
    }


    const activitypanelClose = useCallback(() => {
        setStockObj(null)
        setSelectedActivitylog(null)
        setShowActivity(false)
        setActivetab('appointment')
        setEditable(false)
    }, [])

    const handleEditactivity = useCallback(async (e, logVM) => {
        e && e.preventDefault();
        if (logVM.stockID) {
            if (!_.isEmpty(logVM.stock)) {
                setStockObj(logVM.stock)
            }
            else {
                const stockDoc = await window.firebase.firestore().doc('stock/' + logVM.stockID).get();
                if (stockDoc.exists) {
                    const stockData = stockDoc.data()
                    stockData.documentID = stockDoc.id;
                    setStockObj(stockData)
                }
            }
        }
        setNewActivity(false)
        setActivityHeader('Edit Activity')
        // setShowActivity(true)
        setActivetab(logVM.subType)
        setShowActivityModel(true)
        setSelectedActivitylog(logVM)

        // setActivetab(logVM.subType)
        // setEditable(true)
        // if (document.getElementById('noanim-tab-example-tabpane-activity')) {
        //     setTimeout(function () {
        //         document.getElementById('noanim-tab-example-tabpane-activity').scrollTo({
        //             behavior: "smooth",
        //             top: 0
        //         });
        //     }, 1000);
        // }
    }, [])

    const handleSaveactivity = useCallback((e, objLog) => {
        setSectionLoading(true)
        objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
        objLog.modifiedBy = localStorage.uid;
        objLog.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

        delete objLog.searchUsers;
        firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).set(objLog, { merge: true })
            .then(snapshot => {
                // dispatch({
                //     type: "SAVELOG",
                //     data: objLog
                // });
                toast.notify('Log updated successfully', {
                    duration: 2000,
                    position: 'top-right'
                })
                if (objLog.startDate < CommonHelper.getBaseDate() && !objLog.isDone) { setActiveactivity('Overdue') }
                else if (objLog.startDate > CommonHelper.getBaseDate() && objLog.startDate < CommonHelper.getEndDate() && !objLog.isDone) { setActiveactivity('Today') }
                else if (objLog.startDate > CommonHelper.getEndDate() && !objLog.isDone) { setActiveactivity('Upcoming') }
                else if (objLog.isDone === true) { setActiveactivity('Completed') }
                setShowActivity(false)
                setActivetab('appointment')
                setEditable(false)
                setSectionLoading(false)

                if (objLog && document.getElementById('activity-' + objLog.documentID)) {
                    setTimeout(function () {

                        document.getElementById('activity-' + objLog.documentID).scrollIntoView(
                            {
                                behavior: "smooth",
                                block: "center"
                            });
                    }, 1000);
                }
            })
            .catch(error => {
                setSectionLoading(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000,
                    position: 'top-right'
                })
            });
    }, [])

    const handleCompleteContinue = (isDone, objLog, activityOutcome) => {
        // check mandActivityNotes
        if (CommonHelper.isValidActivityNotesCheck(objLog, isDone, props.dealersettings) === false) {
            handleEditactivity(null, { ...objLog, 'isDone': isDone, 'isFromComplete': true });
            return;
        }

        delete objLog.searchUsers;
        objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
        objLog.modifiedBy = localStorage.uid;
        if (activityOutcome) {
            objLog.activityOutcome = activityOutcome
        }
        if (!activityOutcome && isDone === false && objLog.isDone === true) {
            objLog.activityOutcome = null
        }
        objLog.isDone = isDone
        setSectionLoading(true)
        firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`)
            .set(objLog, { merge: true })
            .then(snapshot => {

                // dispatch({
                //     type: "SAVELOG",
                //     data: objLog
                // });
                toast.notify('Log updated successfully', {
                    duration: 2000,
                    position: 'top-right'
                })
                setShowActivity(false)
                //setActivetab('appointment')
                //setActiveactivity('Completed')
                setEditable(false)

                setSectionLoading(false)
                var checkboxDOM = document.getElementsByClassName('uncheck-activity');
                for (let i = 0; i < checkboxDOM.length; i++) {
                    checkboxDOM[i].checked = false;
                }

                // if (objLog && document.getElementById('activity-' + objLog.documentID)) {
                //     setTimeout(function () {

                //         document.getElementById('activity-' + objLog.documentID).scrollIntoView(
                //             {
                //                 behavior: "smooth",
                //                 block: "center"
                //             });
                //     }, 1000);
                // }
                //update enquiry modified date if activity is mark as done
                if (!_.isEmpty(objLog.enquiryID) && objLog.isDone === true) {
                    let objUpdate = {
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        unactionedNotify: enqUnActionStatus.COMPLETED,
                    }
                    // if (objLog.inbound === true) {
                    //     objUpdate = {
                    //         ...objUpdate,
                    //         bdcStatus: 'show'
                    //     }
                    // }
                    firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                        .set(objUpdate, { merge: true })
                }
                if (objLog.isDone === true) {
                    if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                        Swal.fire({
                            title: CommonHelper.showLocale(props, 'Please select'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                            cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                                .set({
                                    bdcStatus: result.value ? 'show' : 'noshow'
                                }, { merge: true })
                            shedulenextactivity(objLog);
                            if (result.value)
                                logWalkin(objLog.enquiryID)

                            CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), CommonHelper.getActivityType('log', props.originType), 'note', null, props.dealersettings);
                        });
                    }
                    else {
                        shedulenextactivity(objLog);
                    }
                }
            })
            .catch(error => {
                console.error(error);
                setSectionLoading(false)
                toast.notify('Something went wrong', {
                    duration: 2000,
                    position: 'top-right'
                })
            });
    }

    const handleComplete = useCallback((e, objLog, aTab) => {
        const isDone = e.target.checked
        const { dealersettings } = props
        if (
            dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings &&
            !_.isEmpty(dealersettings.client.settings.activityOutcome) &&
            dealersettings.client.settings.activityOutcome.filter(item => item.active === true).length &&
            !objLog.isDone && isDone &&
            objLog.subType && objLog.subType === 'call'
        ) {
            const isMandatory = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.mandActivityOutcome) ? true : false);
            const activtyOutcomes = [];
            _.orderBy(
                dealersettings.client.settings.activityOutcome.filter(
                    item => item.active === true,
                ),
                ['name'],
                ['asc'],
            ).forEach((data, index) => {
                activtyOutcomes.push({
                    value: data.value,
                    name: data.name,
                    label: data.name,
                    price: data.price,
                });
            });
            // console.log('activtyOutcomes', activtyOutcomes)
            // console.log('isMandatory', isMandatory)
            setShowOutcomeModal({
                show: true,
                clsActive: 'overlay-modal active',
                isMandatory,
                activtyOutcomes,
                data: { isDone, objLog }
            });
        } else {
            handleCompleteContinue(isDone, objLog);
        }
    }, [])

    const handleCloseOutcomeModal = (isMandatory, selectedStatus, data) => {
        //console.log('handleCloseOutcomeModal', isMandatory, selectedStatus, data)
        if (isMandatory) {
            if (selectedStatus) {
                handleCompleteContinue(data.isDone, data.objLog, selectedStatus)
            } else {
                setShowOutcomeModal({
                    show: false,
                    clsActive: '',
                    isMandatory: false,
                    activtyOutcomes: null,
                    data: {}
                });
                var checkboxDOM = document.getElementsByClassName('uncheck-activity');
                for (let i = 0; i < checkboxDOM.length; i++) {
                    checkboxDOM[i].checked = false;
                }
                return
            }
        } else {
            if (selectedStatus) {
                handleCompleteContinue(data.isDone, data.objLog, selectedStatus)
            } else {
                handleCompleteContinue(data.isDone, data.objLog,)
            }
        }
        setShowOutcomeModal({
            show: false,
            clsActive: '',
            isMandatory: false,
            activtyOutcomes: null
        });
    }

    const logWalkin = (enquiryID) => {
        const objData = Object.assign({}, objWalkIn);
        objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.clientID = props.dealersettings.client.id;
        objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        objData.enquiryID = enquiryID;
        objData.walkinDate = window.firebase.firestore.Timestamp.now();

        const refOnjData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData);
        CommonHelper.logFirstWalkin(enquiryID, objData.walkinDate, props.dealersettings);
    }
    const shedulenextactivity = async (objLog) => {
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.clientSettings && props.dealersettings.client.clientSettings.isScheduleNextActivity) {
            let _isAlreadyScheduled = false;
            if (objLog.enquiryID || objLog?.enquiry?.documentID) {
                const responseMKA = await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'activity')
                    .where('isDone', '==', false)
                    .limit(1)
                    .get();
                if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
            }
            if (_isAlreadyScheduled) return;
            
            const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(props.dealersettings, objLog?.enquiry);

            Swal.fire({
                title: CommonHelper.showLocale(props, 'Schedule next activity?'),
                text: CommonHelper.showLocale(props, 'Do you want to schedule next activity?'),
                icon: 'info',
                showCancelButton: mandScheduleNextActivity ? false : true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    setShowScheduleActivityModel({
                        activityLog: {
                            ...objLog,
                            inbound: null
                        },
                        show: true,
                        contact: objLog.contact ? objLog.contact : null,
                        enquiry: objLog.enquiry ? objLog.enquiry : null,
                    })
                }
            })
        }

    }

    const handleDeleteactivity = useCallback((e, objLog) => {

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'You want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                delete objLog.searchUsers;
                objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
                objLog.modifiedBy = localStorage.uid;
                objLog.isDeleted = true;
                //setSectionLoading(true)
                firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    modifiedFrom: 'web',
                    isDeleted: true
                }, { merge: true })
                    .then(snapshot => {
                        // dispatch({
                        //     type: "REMOVELOG",
                        //     data: objLog
                        // });
                        toast.notify('Log deleted successfully', {
                            duration: 2000,
                            position: 'top-right'
                        })

                        let logNotes = 'Log deleted';
                        if (!_.isEmpty(objLog) && objLog.type)
                            logNotes = objLog.type + ': ' + (objLog.subType ? objLog.subType : '') + ' deleted';

                        let _objLogData = {
                            notes: logNotes,
                            type: 'activities',
                            subType: 'delete',
                            recordId: objLog.documentID,
                        }
                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                        CommonHelper.saveAuditLog(_objLogData);
                        // setTimeout(function () {
                        //     setSectionLoading(false)
                        // }, 1000);
                    })
                    .catch(error => {
                        console.error(error);
                        //setSectionLoading(false)
                        toast.notify('Something went wrong', {
                            duration: 2000,
                            position: 'top-right'
                        })
                    });
            }
        })
    }, [])

    const handleActivityModelDelete = (objLog) => {
        setStockObj(null)
        setShowActivityModel(false)
        // if (objLog) {
        //     dispatch({
        //         type: "REMOVELOG",
        //         data: objLog
        //     });
        // }
    }

    const handleActivityModelClose = (objLog, isActivtyAlreadyDone) => {
        setShowActivityModel(false)
        setStockObj(null)
        if (objLog) {
            // dispatch({
            //     type: "CHECKLOG",
            //     data: objLog,
            //     activeTab: objLog.isDone ? 'Completed' :
            //         objLog.startDate.seconds < parseInt(moment().startOf('day').format('X')) ? 'Overdue' :
            //             objLog.startDate.seconds > parseInt(moment().endOf('day').format('X')) ? 'Upcoming' :
            //                 'Today'
            // });
            // if (objLog && document.getElementById('activity-' + objLog.documentID)) {
            //     setTimeout(function () {
            //         document.getElementById('activity-' + objLog.documentID).scrollIntoView(
            //             {
            //                 behavior: "smooth",
            //                 block: "center"
            //             });
            //     }, 1000);
            // }
            if (objLog.isDone === true && !isActivtyAlreadyDone) {
                if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                    Swal.fire({
                        title: CommonHelper.showLocale(props, 'Please select'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                        cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                            .set({
                                bdcStatus: result.value ? 'show' : 'noshow'
                            }, { merge: true })
                        shedulenextactivity(objLog);
                        if (result.value)
                            logWalkin(objLog.enquiryID)

                        CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), CommonHelper.getActivityType('log', props.originType), 'note', null, props.dealersettings);
                    });
                }
                else {
                    shedulenextactivity(objLog);
                }

            }
        }
    }

    const handleScheduledActivityModelClose = (objLog) => {
        setShowScheduleActivityModel({
            show: false,
            activityLog: null,
            contact: null,
            enquiry: null
        })

        if (objLog) {
            // dispatch({
            //     type: "CHECKLOG",
            //     data: objLog,
            //     activeTab: objLog.isDone ? 'Completed' :
            //         objLog.startDate.seconds < parseInt(moment().startOf('day').format('X')) ? 'Overdue' :
            //             objLog.startDate.seconds > parseInt(moment().endOf('day').format('X')) ? 'Upcoming' :
            //                 'Today'
            // });
            if (objLog && document.getElementById('activity-' + objLog.documentID)) {
                setTimeout(function () {
                    document.getElementById('activity-' + objLog.documentID).scrollIntoView(
                        {
                            behavior: "smooth",
                            block: "center"
                        });
                }, 1000);
            }
        }
    }

    const activityList = useMemo(() => activitylogs
        .filter(
            ({ subType }) => (filtertype ? subType === filtertype : true)
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [filtertype, activitylogs])

    const overdueActivity = useMemo(() => activityList
        .filter(
            ({ startDate, isDone }) => (startDate < CommonHelper.getBaseDate() && !isDone)
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [activityList])
    const todayActivity = useMemo(() => activityList
        .filter(
            ({ startDate, isDone }) => (startDate > CommonHelper.getBaseDate() && startDate < CommonHelper.getEndDate() && !isDone)
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [activityList])
    const upcomingActivity = useMemo(() => activityList
        .filter(
            ({ startDate, isDone }) => (startDate > CommonHelper.getEndDate() && !isDone)
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [activityList])
    const completedActivity = useMemo(() => activityList
        .filter(
            ({ isDone }) => (isDone === true)
        )
        .sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [activityList])
    const allAcitivty = {
        Overdue: overdueActivity,
        Today: todayActivity,
        Upcoming: upcomingActivity,
        Completed: completedActivity
    }

    useEffect(() => {
        if (_.isEmpty(props.enquiry) || _.isEmpty(props.enquiry.documentID) || _.isEmpty(allAcitivty))
            return

        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${props.enquiry.documentID}`)
            .set({
                counts: {
                    todayDate: moment().format('YYYY-MM-DD'),
                    overdue: allAcitivty.Overdue.length,
                    today: allAcitivty.Today.length,
                    upcoming: allAcitivty.Upcoming.length
                }
            }, { merge: true })

    }, [allAcitivty])

    useEffect(() => {
        if (_.isEmpty(props.tradeinPro) || _.isEmpty(props.tradeinPro.documentID) || _.isEmpty(allAcitivty))
            return

        firestoreDB(props.dealersettings).firestore().doc(`tradeinPro/${props.tradeinPro.documentID}`)
            .set({
                counts: {
                    todayDate: moment().format('YYYY-MM-DD'),
                    overdue: allAcitivty.Overdue.length,
                    today: allAcitivty.Today.length,
                    upcoming: allAcitivty.Upcoming.length
                }
            }, { merge: true })

    }, [allAcitivty])

    useEffect(() => {
        if (_.isEmpty(props.service) || _.isEmpty(props.service.documentID) || _.isEmpty(allAcitivty))
            return

        firestoreDB(props.dealersettings).firestore().doc(`serviceJobs/${props.service.documentID}`)
            .set({
                counts: {
                    todayDate: moment().format('YYYY-MM-DD'),
                    overdue: allAcitivty.Overdue.length,
                    today: allAcitivty.Today.length,
                    upcoming: allAcitivty.Upcoming.length
                }
            }, { merge: true })

    }, [allAcitivty])

    const activitypanelOpen = () => {

        const { enquiry } = props;
        if (props.isValidForActivity && enquiry) {
            if (props.isValidForActivity(enquiry.pipeline, enquiry.stage, 'activity')) activityOpen();
        }
        else activityOpen();

    }
    const activityOpen = () => {
        setSelectedActivitylog(null)
        setStockObj(null)
        setNewActivity(true)
        setActivityHeader('Add New Activity')
        setShowActivityModel(true)
    }

    return (
        <>

            <div className="quickview-activity-item" >
                <div className="activity-buttongroup">
                    <Tabs
                        activeKey={activeTab}
                        className="activity-buttongroup"
                        onSelect={(k) => setActivetab(k)}
                    >
                        {
                            activityTypes.map((activity, index) => {
                                return <Tab
                                    key={index}
                                    eventKey={activity.type}
                                    className="quick-view-tabfix"
                                    title={<OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip><Translate text={activity.name} /></Tooltip>
                                        }
                                    >
                                        <i className={activity.ico}></i>
                                    </OverlayTrigger>}
                                >

                                </Tab>
                            })
                        }
                    </Tabs>
                </div>
                <div
                    className={"activity-subinput " + (showActivity === true ? 'hide' : '')}
                    onClick={() => {
                        activitypanelOpen();
                    }}><Translate text={'Click here to add an activity'} /></div>
                {
                    showActivity === true ? (
                        <ActivityPanel
                            activitypanelClose={activitypanelClose}
                            placeholder={selectedActivitylog.title}
                            objActivity={selectedActivitylog}
                            id={selectedActivitylog.documentID}
                            activeTab={activeTab}
                            oldsubtype={selectedActivitylog.subType}
                            title={activityTypes.filter(e => e.type === activeTab)[0].name}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            handleSaveactivity={handleSaveactivity}
                            editable={editable}
                            handleComplete={handleComplete}
                            sectionLoading={sectionLoading}
                        >
                        </ActivityPanel>
                    ) : (<></>)
                }
            </div>
            <div className="quickview-activity-wraper">
                {
                    activitylogs.length > 0 ?
                        (<Dropdown className="quickview-filter-btn" alignRight>
                            <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                <span> <Translate text={filtertype ? activityTypes.filter(e => e.type === filtertype)[0].name : 'Filter'} /> </span><i className="ico icon-filter"></i>

                            </Dropdown.Toggle>
                            {
                                filtertype ?
                                    (<a href="#" className="quickview-filter-edit" onClick={(e) => { e.preventDefault(); setFiltertype(null) }}><i className="ico icon-remove"></i></a>)
                                    : (<></>)
                            }

                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                {
                                    activityTypes.map((activity, index) => {
                                        return <Dropdown.Item key={index} eventKey={index} onClick={(e) => { e.preventDefault(); setFiltertype(activity.type) }}> <Translate text={activity.name} /> </Dropdown.Item>
                                    })
                                }
                                {/* <Dropdown.Item eventKey="1" onClick={(e) => { e.preventDefault(); setFiltertype('appointment') }}> Appointment </Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={(e) => { e.preventDefault(); setFiltertype('testdrive') }}> Test Drive </Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={(e) => { e.preventDefault(); setFiltertype('call') }}> Call </Dropdown.Item>
                                <Dropdown.Item eventKey="4" onClick={(e) => { e.preventDefault(); setFiltertype('email') }}> Mail </Dropdown.Item>
                                <Dropdown.Item eventKey="5" onClick={(e) => { e.preventDefault(); setFiltertype('delivery') }}> Delivery </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>)
                        : (<></>)
                }
                {
                    loadingActivity ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        (<>
                            <div className="enquiry-flow-nav pl-0">
                                <ul>
                                    {
                                        acitivtyTags.map((rec, index) => {
                                            return <li key={index}>
                                                <a href="#"
                                                    id={rec}
                                                    className={`nav-activity-tabs nav-li-${rec} ${activeAcitivtyTag === rec ? 'active' : ''}`}
                                                    onClick={(event) => {
                                                        if (event.target.id) {
                                                            setActiveactivity(event.target.id)
                                                        }
                                                        event.preventDefault();
                                                    }}
                                                ><Translate text={rec} />
                                                    {
                                                        allAcitivty[rec].length > 0 ? (
                                                            <span className="count">({allAcitivty[rec].length})</span>
                                                        ) : (<></>)
                                                    }
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                            {
                                allAcitivty[activeAcitivtyTag].length > 0 ? (
                                    <>
                                        <div className="quickview-activity-item" >
                                            {
                                                allAcitivty[activeAcitivtyTag].map((log, index) => {
                                                    return <ActivityView
                                                        key={index}
                                                        title={log.title}
                                                        activityDate={log.startDate}
                                                        id={log.documentID}
                                                        type={log.subType}
                                                        addedBy={CommonHelper.getUserNamebyId(allUsers, log.owner)}
                                                        handleEditactivity={handleEditactivity}
                                                        handleComplete={handleComplete}
                                                        completed={log.isDone}
                                                        activitytag={activeAcitivtyTag}
                                                        dealersettings={props.dealersettings}
                                                        handleDeleteactivity={handleDeleteactivity}
                                                        activitylog={log}
                                                        originType={props.originType}
                                                    />
                                                })
                                            }
                                        </div>
                                    </>
                                ) : (<div className="spinner-loader h-100 p-5">
                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2">  <Translate text={'No activities'} /></div>
                                    </div>
                                </div>)
                            }
                            {/* {
                                pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                    ((activityCounter["overdue"] || activityCounter["today"] || activityCounter["upcoming"] || activityCounter["activity"]) > 0 && hasMore.filter(e => e.type === activeAcitivtyTag).length > 0 ?
                                        (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setDataload({
                                                ...checkDataLoad,
                                                [activeAcitivtyTag]: true
                                            })
                                        }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                        : (<></>))
                            } */}
                        </>)
                }
            </div>
            <PopUpModal show={showOutcomeModal.show}>
                <ActivityStatus
                    show={showOutcomeModal.show}
                    isMandatory={showOutcomeModal.isMandatory}
                    activtyOutcomes={showOutcomeModal.activtyOutcomes}
                    data={showOutcomeModal.data}
                    handleClose={handleCloseOutcomeModal}
                    title={'Select Activity Outcome'}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
            <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={selectedActivitylog}
                    enquiry={props.enquiryBasicVM}
                    tradeinPro={props.tradeinPro}
                    service={props.service}
                    contact={
                        !_.isEmpty(props.enquiry)
                            ? props.enquiry.contact
                            : !_.isEmpty(props.tradeinPro)
                                ? props.tradeinPro.contact
                                : !_.isEmpty(props.service)
                                    ? props.service.contact
                                    : null
                    }
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={isNewActivity}
                    activeTab={activeTab}
                    isFromLayout={props.isFromLayout}
                    originType={props.originType}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showScheduleActivityModel.show}>
                <AddActivity
                    show={showScheduleActivityModel.show}
                    handleClose={handleScheduledActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={'Add New Activity'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={showScheduleActivityModel.activityLog}
                    enquiry={props.enquiryBasicVM ? props.enquiryBasicVM : showScheduleActivityModel.enquiry ? showScheduleActivityModel.enquiry : null}
                    tradeinPro={props.tradeinPro}
                    service={props.service}
                    contact={showScheduleActivityModel.contact ? showScheduleActivityModel.contact : !_.isEmpty(props.enquiry)
                        ? props.enquiry.contact
                        : !_.isEmpty(props.tradeinPro)
                            ? props.tradeinPro.contact
                            : !_.isEmpty(props.service)
                                ? props.service.contact
                                : !_.isEmpty(props.contactBasicVM)
                                    ? props.contactBasicVM
                                    : null
                    }
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={true}
                    // activeTab={activeTab}
                    isScheduleActivity={true}
                    isFromLayout={props.isFromLayout}
                    originType={props.originType}
                >
                </AddActivity>
            </PopUpModal>
        </>)
}
export default ActivityTab;