export const fields = [
  {
    name: "Subject",
    value: "title",
    flex: 1,
    default: true,
    sortValue: 'title',
  },
  {
    name: "Name",
    value: "name",
    flex: 1,
    default: true,
    sortValue: 'contact."firstName"'
  },
  {
    name: "Sent To",
    value: "contactEmail",
    flex: 1,
    default: true,
    sortValue: 'contact."email"'
  },
  {
    name: "Sent time",
    value: "sentOn",
    default: true,
    flex: 1,
    sortValue: 'startDate'
  },
  {
    name: "Status",
    value: "status",
    flex: 1,
    default: true,
    sortValue: 'status',
    elementName: "strStatus",
  },
  {
    name: "Stats",
    value: "stats",
    flex: 1,
    default: true,
    elementName: "strStats",
  },
  {
    name: "Settings",
    value: "settings",
    subText: "documentID",
    width: 120,
    default: true,
    type: "settings",
    flex: 0,
    fixedRight: true,
  },
];

export const EVENTS = [
  { value: "clicked", label: "Clicked" },
  { value: "complained", label: "Complained" },
  { value: "delivered", label: "Delivered" },
  { value: "opened", label: "Opened" },
  { value: "failed", label: "Failed" },
  // { value: "permanent_fail", label: "Permanent Fail" },
  // { value: "temporary_fail", label: "Temporary Fail" },
  { value: "unsubscribed", label: "Unsubscribed" },
];
