import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment';
import ReactSummernote from 'react-summernote';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
// import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import { storageBucket } from '../../../../services/helper';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
window.jQuery = $;

const EmailSign = ({ userData, setUserData, userRef,dealersettings }) => {
    const [loading, setLoading] = useState(false)
    const [imagesSize, setImagesSize] = useState(0);
    const [trigger, setTrigger] = useState({
        email: !_.isEmpty(userData.emailSignature) ? userData.emailSignature : '',
    });
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
		if (trigger.email !== '' && !initialized) {
				// console.log('emailType', emailType)
				var node = document.createElement('div');
				node.innerHTML = `${trigger.email}`;
				ReactSummernote.insertNode(node);
				setInitialized(true);
		}
	}, []);

		

    const handleChange = async obj => {
			let htmlText = obj
			setTrigger({
					...trigger,
					email: htmlText,
					// email : obj.value,
			});

			// let res = htmlText.split("<img");
			// let links = []
			// res.forEach((str, i) => {
			// 	if (i > 0) {
			// 		let link = str.split('\"')[1]
			// 		if (!link.includes('567bdbc80fc3cf4c2924295e28f1eed0')) {
			// 			links.push(link)
			// 		}
			// 	}
			// })
			// console.log(links)

			// await downloadImages(links, htmlText)
    };
    
    async function downloadImages(arrImges, html) {
        console.log(`arrImges, html`,arrImges, html)
        let htmlText = html
        if (arrImges.length === 0) {
            return
        }
        // for(let count = 0; count < arrImges.length; count++) {
        // 	html.replace(arrImges[count], 'https://i.pinimg.com/474x/56/7b/db/567bdbc80fc3cf4c2924295e28f1eed0.jpg')
        // }  
        for (const link of arrImges) {
            htmlText = htmlText.replace(link, 'https://i.pinimg.com/474x/56/7b/db/567bdbc80fc3cf4c2924295e28f1eed0.jpg')
        }   

        console.log('new htnl with new links', htmlText)
        var node = document.createElement('div');
        node.innerHTML = `${htmlText}`;
        // ReactSummernote.insertNode(node);
    }

    async function asyncForEach(array, callback) {
     for (let index = 0; index < array.length; index++) {
         await callback(array[index], index, array);
     }
    }

    const handleSubmit = async () => {
        if (loading) {
            return
        }

        try {
            setLoading(true)
            await userRef.update({
                emailSignature: trigger.email
            })
            toast.notify('Signature updated successfully...');
            setLoading(false)

        } catch (error) {
            console.log(error)
        }
    }

    const handleClear = async () => {
        setTrigger({
            ...trigger,
            email: '',
        });
    }

    const handleImageUpload = async fileList => {
        try {
            // file sizes validation
            let fileListSize = 0;
            for (let i = 0; i < fileList.length; i++) {
                // console.log(fileList[i].size)
                fileListSize = fileListSize + fileList[i].size;
            }
            fileListSize = fileListSize / 1024 / 1024;
            let totalSize = imagesSize + fileListSize;

            if (totalSize > 25) {
                Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Maximum files size exceeded.'), '', 'info');
                return;
            } else {
                setImagesSize(totalSize);
            }
            toast.notify('Image is being uploaded...');

            for (let i = 0; i < fileList.length; i++) {
                // console.log(fileList[i])
                const imageURL = await saveFileToStorage(fileList[i]);
                // console.log('imageURL', imageURL);
                ReactSummernote.insertImage(imageURL);
            }
            toast.notify('Image has been added.');
        } catch (error) {
            console.error(error)
            toast.notify('There was an error in adding images...');
        }
    };

    const saveFileToStorage = async file => {
        let uid = moment()._d.getTime().toString();
        try {
            const res = await storageBucket(dealersettings).ref(
                    `${localStorage.uid}/signature/${uid}/${file.name}`,
                )
                .put(file);
            if (res.state === 'success') {
                const dataURL = storageBucket(dealersettings).ref(`${localStorage.uid}/signature/${uid}`)
                    .child(file.name)
                    .getDownloadURL();
                return dataURL;
            }
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <>
            <div className='email-settings-inner form-style'>
                <div className='email-sync-wrap'>
                    <h1><Translate text={'Email Signature'} /></h1>
                    <p><Translate text={'Create and edit your email signature below'} />
                        
                    </p>
                </div>
                <div className='email-signature-wrap mt-2'>
                    <div className='form-row mb-2'>
                        <div className='form-group col-md-12 react-summer-editor'>
                            <ReactSummernote
                                children={<div dangerouslySetInnerHTML={{ __html: `<div></div>` }}></div>}
                                options={{
                                    height: 250,
                                    dialogsInBody: true,
                                    toolbar: [
                                        ['style', ['style']],
                                        ['font', ['bold', 'underline', 'clear']],
                                        ['fontname', ['fontname']],
                                        ['para', ['paragraph']],
						                ['color', ['color']],
                                        ['insert', ['link', 'picture']],
                                        ['view', ['codeview']]
                                    ],
                                    disableResizeEditor: true
                                }}
                                onChange={handleChange}
                                onImageUpload={handleImageUpload}
                                // onPaste={onEditorPaste}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='settings-footer mt-3'>
                {/* <button type="button" className="btn btn-default float-left" onClick={handleClear}>Clear</button> */}
                <button type='button' className='btn btn-primary float-right' onClick={handleSubmit}>
                    {loading && (
                        <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>
                    )}
                    <Translate text={'save'} />
                </button>
            </div>
        </>
    );
};

export default EmailSign;