import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { InputText } from '../../../components';
import Translate from '../../../constants/translate';
import _ from 'lodash'
import { firestoreDB } from '../../../services/helper';

const AddRecipient = (props) => {
    const [errorFields, setErrorFields] = useState({})
    const [data, setData] = useState({
        firstName: props.title === 'Edit Recipient' ? props.data.firstName ? props.data.firstName : '' : '',
        middleName: props.title === 'Edit Recipient' ? props.data.middleName ? props.data.middleName : '' : '',
        lastName: props.title === 'Edit Recipient' ? props.data.lastName ? props.data.lastName : '' : '',
        email: props.title === 'Edit Recipient' ? props.data.email ? props.data.email : '' : '',
        documentID: props.title === 'Edit Recipient' ? props.data.documentID ? props.data.documentID : '' : '',
        amsproDocID: props.title === 'Edit Recipient' ? props.data.amsproDocID ? props.data.amsproDocID : '' : '',
        contactID: props.title === 'Edit Recipient' ? props.data.contactID ? props.data.contactID : '' : ''
    })

    const handleOnChange = e => {
        const { name, value } = e.target;

        setData({
            ...data,
            [name]: value,
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };

    const onSave = async e => {
        e.preventDefault()
        let isValid = true;
        let errors = { ...errorFields }
        if (data.firstName === '') {
            isValid = false;
            errors.firstName = 'input_error'
        }
        if (data.lastName === '') {
            isValid = false;
            errors.lastName = 'input_error'
        }
        if (data.email === '') {
            isValid = false;
            errors.email = 'input_error'
        }
        setErrorFields(errors);

        if (isValid) {
            if (_.isEmpty(data.contactID)) {
				data.contactID = await getContact(data);
				// console.log('return', data);
			}

            if (props.title === 'Edit Recipient') {
                props.handleClose(data, 'update');
            } else {
                props.handleClose(data, 'add');
            }
        }
    }

    const getContact = async (_data) => {
		let contactDetailsRef = firestoreDB(props.dealersettings).firestore().collection('contactDetails');
		const { dealersettings } = props;
		if (dealersettings && dealersettings.client && dealersettings.client.group && dealersettings.group && dealersettings.group.enableSharedContact)
			contactDetailsRef = contactDetailsRef.where('groupID', '==', dealersettings.client.group)
		else
			contactDetailsRef = contactDetailsRef.where('clientID', '==', (dealersettings.client.id))

		let _contactID = null;
		const email = data.email ? data.email : '';

		const checkEmail= await contactDetailsRef.where('keywords', 'array-contains', email).where('isDeleted', '==', false).limit(1).get()
		if (checkEmail.size === 1) {
			checkEmail.forEach(doc => {
				// console.log('checkEmail', doc.id)
				_contactID = doc.id;
			});
			return _contactID;
		}
		return _contactID;
	}

    return (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-save-filter">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={props.title} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">

                    <div className="form-style w-100">

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label >First Name</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["firstName"]}`}
                                    name="firstName"
                                    onChange={handleOnChange}
                                    value={data.firstName}
                                    placeholder='first name'
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label >Middle Name</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["middleName"]}`}
                                    name="middleName"
                                    onChange={handleOnChange}
                                    value={data.middleName}
                                    placeholder='middle name'
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label >Last Name</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["lastName"]}`}
                                    name="lastName"
                                    onChange={handleOnChange}
                                    value={data.lastName}
                                    placeholder='last name'
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label >Email</label>
                                <InputText
                                    autoComplete="off"
                                    className={`form-control ${errorFields["email"]}`}
                                    name="email"
                                    onChange={handleOnChange}
                                    value={data.email}
                                    placeholder='email'
                                />
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={onSave}>
                <Translate text={props.title === 'Edit Recipient' ? 'Save' : 'Add'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>);
}

export default AddRecipient;