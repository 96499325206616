/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
/** COMPONENTS */
//import Translate from '../../../constants/translate';
//import { default as _images } from '../../../images';
import StockInfo from '../../stock/stockInfo'
import RequirementInfo from '../requirementInfo'
/** VIEW-MODELS */

export default class Requirements extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const {
            stock
        } = this.props.requirement;

        const {
            requirement, handleEdit, setPrimary, setSold, isMultiple, enquiry,
            isPrimary, isDeivered, enquiryID, dealersettings, clientUsers, groupUsers,
            decrementQuantity, incrementQuantity, hideMore, deleteRequirement, status
        } = this.props;

        return (
            <>
                {
                    (!_.isEmpty(stock))
                        ?

                        <>
                            <StockInfo
                                requirement={requirement}
                                stock={stock}
                                enqStatus={status}
                                ShowFullDetails={false}
                                handleEdit={handleEdit}
                                setPrimary={setPrimary}
                                setSold={setSold}
                                isPrimary={isPrimary}
                                enquiryID={enquiryID}
                                dealersettings={dealersettings}
                                deleteRequirement={deleteRequirement}
                                isDeivered={isDeivered}
                                clientUsers={clientUsers}
                                groupUsers={groupUsers}
                                hideMore={hideMore}
                                isMultiple={isMultiple}
                                enquiry={enquiry}
                            />
                        </>
                        :
                        <>
                            <RequirementInfo
                                requirement={requirement}
                                enqStatus={status}
                                handleEdit={handleEdit}
                                setPrimary={setPrimary}
                                setSold={setSold}
                                enquiryID={enquiryID}
                                decrementQuantity={decrementQuantity}
                                incrementQuantity={incrementQuantity}
                                deleteRequirement={deleteRequirement}
                                isPrimary={isPrimary}
                                isDeivered={isDeivered}
                                dealersettings={dealersettings}
                                hideMore={hideMore}
                                isMultiple={isMultiple}
                            />
                        </>

                }
            </>
        );
    }
}

