export const objRequirement = {
    documentID: null,
    enquiryID: null,
    make: '',
    model: '',
    saleType: '',
    type: '',
    group: '',
    year: '',
    regNo: '',
    vinNo: '',
    stock: {},
    extColor: '',
    intColor: '',
    stockNo: '',
    transmission: '',
    warrantyStartDate: null,
    warrantyExp: null,
    price: null,
    quantity: 1,
    isSold: false,
    reqOtherInfo: [],
    addOns: [],
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};

export const objRequirementClone = {
    enquiryID: null,
    make: '',
    model: '',
    saleType: '',
    group: '',
    type: '',
    year: '',
    extColor: '',
    intColor: '',
    modifiedFrom: 'web',
    isDeleted: false
};

export const mandatoryReqFields = [
    'make',
    'model',
    //'type',
    'saleType'
];

export const mandatoryStkFields = [
    'make',
    'model',
    //'type',
    'saleType'
];
