/** LIBRARIES */
import * as React from 'react'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2';
/** COMPONENTS */
import CommonHelper from '../../services/common'
import { PopUpModal } from '../../components';
/** VIEW-MODELS */
import { enquiryStatus, serviceStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';
import { objService } from './viewModel';

const StageContext = React.createContext()


const fieldsData = {
    contact: '',
    businessContact: '',
    requirement: '',
    enquiry: '',
    company: ''
}


let enquiryOptions = [];
let enquiryOptionsDF = [];

export const StageConsumer = StageContext.Consumer

export class StageProvider extends React.Component {
    constructor(props) {
        super(props);
        this.mandatoryFields = [];
        this.state = {
            filter: {},
            services: {},
            pipeline: [],
            isLoading: false,
            columns: [],
            clientid: '',
            hasMore: [],
            pageNum: 0,
            pageLimit: 10,
            stageLoading: '',
            showNotifier: false,
            notifierMsg: '',
            notifierInfo: '',
            sectionloading: false,
            checkLoad: {},
            isFilterApplied: false,
            stageLoader: [],
            stageCounts: {},
            enquiryModal: {
                show: false,
                enquiry: {},
                errors: null,
                origin: {}
            },
            selectedEnquiry: {},
            searchText: (localStorage.serviceSearchText ? localStorage.serviceSearchText : ''),
        };

        //this.listenScrollEvent = this.listenScrollEvent.bind(this);
    }

    componentDidMount() {
        if (this.state.isLoading) {
            if (localStorage.servicePipelineFilter && this.state.isFilterApplied === false)
                this.setState({ isFilterApplied: true }, () => { this.LoadEnquiry() })
            else
                this.LoadEnquiry()

            // const { dealersettings } = this.props;
            // if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            //     const setttings = dealersettings.client.settings;
            //     if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.enquiry))
            //         this.mandatoryFields = setttings.mandatoryFields.enquiry.split(',');

            //     enquiryOptions = [];
            //     setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
            //         enquiryOptions.push({
            //             value: doc.value,
            //             label: doc.name,
            //         });
            //     });

            //     enquiryOptionsDF = [];
            //     if (setttings.enquiryOptionsDF)
            //         enquiryOptionsDF = setttings.enquiryOptionsDF;
            // }
            this.refreshStageCount()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isLoading) {
            this.LoadEnquiry()
        }
        if (JSON.stringify(prevState.columns) !== JSON.stringify(this.state.columns)) {
            this.refreshStageCount()
        }
    }

    componentWillUnmount() {
        !_.isEmpty(this.state.pipeline) && this.state.pipeline.forEach(p => {
            p.stages.forEach(s => {
                window['unsubService' + p.value + s.value] && window['unsubService' + p.value + s.value]();
            })
        })
        window.unSubServicePipelineCount && window.unSubServicePipelineCount();
    }

    refreshStageCount = () => {
        if (window.location.pathname !== '/service')
            return

        const { pipeline } = this.state;
        const { dealersettings } = this.props;
        let _selectedPipeline = pipeline.find(item => item.default === true)
        var _dpl = _selectedPipeline ? _selectedPipeline.value : '';

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && _dpl) {
            try {

                // let _searchObject = {
                //     "pipeline": _dpl,
                //     "owner": localStorage.uid,
                //     "settingsID": dealersettings.oemID ? dealersettings.oemID : null
                // }
                // _searchObject.clientID = dealersettings.client.id;
                // //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, null, true);

                // const stageCountsBQ = window.firebase.functions().httpsCallable('services-stageCountsBQ');
                // stageCountsBQ(_searchObject).then((response) => {
                //     console.log('stageCountsBQ', _dpl, response);
                // });
            }
            catch (error) {
                console.error(error)
            }
        }
    }

    isValidFilter = () => {
        if (localStorage.servicePipelineFilter) {
            let _pipelineFilter = JSON.parse(localStorage.servicePipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    LoadEnquiry = async (_isPaging) => {
        try {
            if (window.location.pathname !== '/service')
                return

            const { columns, pipeline, pageLimit, pageNum, searchText } = this.state;
            const { dealersettings } = this.props;
            let _selectedPipeline = pipeline.find(item => item.default === true);
            var _dpl = _selectedPipeline ? _selectedPipeline.value : '';

            // const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            //     !_.isEmpty(dealersettings.rolePermissions) &&
            //     !_.isEmpty(dealersettings.rolePermissions.servicePermissions) &&
            //     dealersettings.rolePermissions.servicePermissions.viewOtherEnquiry) ? true : false);
            const viewOtherEnquiry = true; //Sameer
            let _pageNum = pageNum;

            //console.log('LoadEnquiry', _pageNum, isFilterApplied, hasMore);


            let clientID = dealersettings.client ? dealersettings.client.id : '';
            let services = this.state.services
            if (_.isEmpty(this.state.services)
                || JSON.stringify(_.map(this.state.services.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)
                || (this.isValidFilter() && _pageNum === 0)
            ) {
                //console.log('EMPTY services', isFilterApplied, _pageNum, Object.keys(this.state.services).length, JSON.stringify(_.map(this.state.services.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns))
                services = {}
                _pageNum = 0
                this.setState({ services: services, pageNum: 0 })
            }

            //get total pipeline count by stages
            if ((!searchText && !localStorage.servicePipelineFilter))
                this.getPipelineCount(viewOtherEnquiry, _dpl)

            //let _hasMore = false;
            let i = 0;
            columns.forEach((_rec) => {

                let lastRecord = this.state.hasMore.filter(e => e.Stage === _dpl + _rec);
                if (JSON.stringify(_.map(this.state.services.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)) {
                    lastRecord = []
                }


                if (this.isValidFilter()) {
                    let _pipelineFilter = {};

                    if (localStorage.servicePipelineFilter)
                        _pipelineFilter = JSON.parse(localStorage.servicePipelineFilter);

                    let _filter = Object.assign({}, _pipelineFilter.value);

                    _filter.pipeline = _dpl;

                    if (_.isEmpty(_filter.stage))
                        _filter.stage = _rec;



                    // if (lastRecord.length > 0 && _hasMore === false)
                    //     _hasMore = true;

                    if (_pageNum === 0 || lastRecord.length > 0) {
                        if ((_pageNum === 0 || lastRecord.length > 0) && i === 0) {
                            i++;
                            _pageNum = (pageNum + 1);
                            this.setState({ pageNum: _pageNum })
                        }
                    }

                    if (!viewOtherEnquiry)
                        _filter.owner = dealersettings.id;

                    /* DATE RANGE FILTER */
                    if (!_.isEmpty(_filter.date)) {
                        _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                        delete _filter['date'];
                    }

                    if (_filter.rottenDays &&
                        !_.isEmpty(dealersettings) &&
                        !_.isEmpty(dealersettings.client) &&
                        !_.isEmpty(dealersettings.client.services) &&
                        !_.isEmpty(dealersettings.client.services.pipelines)) {
                        _filter.pipelineSettings = JSON.stringify(dealersettings.client.services.pipelines)
                    }

                    _filter.clientID = clientID;
                    _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess();
                    //_filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings, null, null, true);

                    let _searchObject = {
                        "type": "searchServiceJobs",
                        "filters": JSON.stringify(_filter),
                        "sortOrder": "stageDate desc",
                        "pageNum": pageNum,
                        "pageLimit": pageLimit
                    }
                    _searchObject.clientID = clientID;
                    //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, null, true);
                    //console.log('filter-searchServiceJobs', _searchObject, clientID, pageNum, _filter);
                    const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
                    searchEnquiries(_searchObject).then((response) => {
                        //console.log('generic-searchData', _rec, response);
                        if (response.data.success && this.isValidFilter()) {
                            this.setState({
                                stageCounts: {
                                    ...this.state.stageCounts,
                                    [_rec]: {
                                        total: response.data.total
                                    }
                                }
                            })
                            this.onCollectionEnquiry(response.data, _rec);
                            //     if (_pageNum === 0)
                            //         this.setState({ isLoading: columns[columns.length - 1] === _rec ? false : true, })
                        }
                    });

                    window['unsubService' + _dpl + _rec] && window['unsubService' + _dpl + _rec]();
                    window.unSubServicePipelineCount && window.unSubServicePipelineCount()
                }
                else {
                    var enquiryQuery = '';
                    let searchKeywords = searchText;
                    let searchOwners = [];

                    if (localStorage.servicePipelineFilter) {
                        let _pipelineFilter = JSON.parse(localStorage.servicePipelineFilter);
                        let _filter = Object.assign({}, _pipelineFilter.value);

                        searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                        searchOwners = _filter['owner'] ? _filter['owner'].split(",") : [];

                        this.setState({ searchText: searchKeywords });
                        localStorage.serviceSearchText = searchKeywords;
                        //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
                    }


                    enquiryQuery = firestoreDB(dealersettings).firestore().collection('serviceJobs')
                        .where('clientID', '==', clientID)
                        .where('isDeleted', '==', false)

                    if (searchKeywords) {
                        let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                        if (formattedSearchText.trim())
                            enquiryQuery = enquiryQuery.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())

                        enquiryQuery = enquiryQuery
                            .orderBy('addedDate', 'desc');
                    }
                    else {
                        if (!viewOtherEnquiry)
                            enquiryQuery = enquiryQuery.where('owner', 'in', [localStorage.uid])
                        else if (!_.isEmpty(searchOwners))
                            enquiryQuery = enquiryQuery.where('owner', 'in', searchOwners)

                        enquiryQuery = enquiryQuery
                            .where('pipeline', '==', _dpl)
                            .where('stage', '==', _rec)
                            .orderBy('stageDate', 'desc');
                    }

                    //enquiryQuery = CommonHelper.whereClientOrGroupOrRegionOrOemClause(enquiryQuery, dealersettings, null, true);



                    if (lastRecord.length > 0) {
                        enquiryQuery = enquiryQuery
                            .startAfter(lastRecord[0].lastDoc)
                            .limit(pageLimit)
                    }
                    else {
                        enquiryQuery = enquiryQuery
                            .limit(pageLimit)
                    }

                    window['unsubService' + _dpl + _rec] = enquiryQuery.onSnapshot(this.onCollectionUpdate)
                }

            });

            // if (_hasMore || (isFilterApplied && _pageNum === 0)) {
            //     _pageNum = (pageNum + 1);
            //     this.setState({ pageNum: _pageNum })
            // }
            //})
            if (Object.keys(services).length === 0) {
                services['tasks'] = {};
                let cols = {}
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];

                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    services.tasks && Object.keys(services.tasks).map(function (el) {
                        if (services.tasks[el].stage === col.value) {
                            leadsids.push(services.tasks[el].id)
                        }
                    })
                    cols[col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids
                    }
                })
                services['columns'] = cols
            }

            this.setState({
                isLoading: false,
                checkLoad: {
                    ...this.state.checkLoad,
                    [_dpl]: true
                }
            }, () => {
                if (_isPaging) {
                    document.querySelector(".pipeline-panel").scrollTo({
                        top: document.querySelector(".pipeline-panel").scrollHeight,
                        behavior: "smooth"
                    });
                }
            })
        }
        catch (error) {
            this.setState({
                isLoading: false,
                stageLoading: ''
            })
            console.error(error)
        }
    }

    getPipelineCount = (viewOtherEnquiry, pipeline) => {
        const { dealersettings } = this.props;
        var querySet = `clients/${dealersettings.client.id}/counts/${dealersettings.client.id}`

        // if (!viewOtherEnquiry) {
        //     querySet = `users/${localStorage.uid}/userClients/${dealersettings.client.id}`
        // }
        window.unSubServicePipelineCount = window.firebase.firestore().doc(querySet)
            .onSnapshot(docSnapshot => {

                if (docSnapshot.exists && docSnapshot.data().service && docSnapshot.data().service[pipeline] && docSnapshot.data().service[pipeline].stages) {
                    var stageCount = {};
                    let _selectedPipeline = this.state.pipeline.find(item => item.default === true);
                    _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
                        stageCount = {
                            ...stageCount,
                            [stage.value]: {
                                ...docSnapshot.data().service[pipeline].stages[stage.value],
                                ['total']: this.sumObj(docSnapshot.data().service[pipeline].stages[stage.value])
                            }
                        }
                    })
                    this.setState({
                        stageCounts: stageCount
                    })
                }
            })
    }

    sumObj = (obj) => {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    onCollectionUpdate = (querySnapshot) => {
        const { dealersettings } = this.props;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(async (change) => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                //console.log(change)
                let _data = change.doc.data()
                let _defaultPipeline = _data.pipeline;
                let _defaultStage = _data.stage;


                snapshotDoc = this.convertServiceJobVM({
                    ...change.doc.data(),
                    documentID: change.doc.id,
                    pipeline: _defaultPipeline,
                    stage: _defaultStage
                });
                if (change.type === 'removed') {
                    const enqDoc = await firestoreDB(dealersettings).firestore().doc(`serviceJobs/${change.doc.id}`).get();
                    if (enqDoc.exists && enqDoc.data().clientID === dealersettings.client.id) {
                        snapshotDoc = this.convertServiceJobVM({ ...enqDoc.data(), documentID: enqDoc.id });
                    }
                    else {
                        snapshotDoc.isDeleted = true;
                    }
                }
                this.updateEnquiry(snapshotDoc);
            }
        })
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let _services = this.state.services
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => item.default === true)
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            var _dplStage = '';
            if (querySnapshot.docs.length > 0)
                _dplStage = _dpl + querySnapshot.docs[0].data().stage;
            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {

                hasMoreData = [...hasMoreData.filter(e => e.Stage !== _dplStage), {
                    Stage: _dplStage,
                    docID: querySnapshot.docs[querySnapshot.docs.length - 1].data().documentID,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }]
            }

            querySnapshot.forEach(doc => {
                let _data = doc.data()
                let _defaultPipeline = _data.pipeline;
                let _defaultStage = _data.stage;

                const _enq = {
                    ...doc.data(),
                    documentID: doc.id,
                    pipeline: _defaultPipeline,
                    stage: _defaultStage
                }
                result[doc.id] = this.convertServiceJobVM(_enq)
                if (_services.tasks) {
                    _services['tasks'][doc.id] = { ...result[doc.id] }

                } else {
                    _services['tasks'] = result
                }

                let cols = {}
                if (_services.columns) {
                    cols = _services.columns
                }

                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _services.tasks && Object.keys(_services.tasks).map(function (el) {
                        if (_services.tasks[el].stage === col.value && _services.tasks[el].pipeline === _dpl) {
                            leadsids.push(_services.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = _services.tasks[el].modifiedDate ? moment().diff(moment.unix(_services.tasks[el].modifiedDate.seconds), 'days') : 0
                                _services.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _services['columns'] = cols
            })
            if (querySnapshot.docs.length === 0) {
                let cols = {}
                if (_services.columns) {
                    cols = _services.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _services.tasks && Object.keys(_services.tasks).map(function (el) {
                        if (_services.tasks[el].stage === col.value) {
                            leadsids.push(_services.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _services['columns'] = cols
            }

            this.setState({
                services: _services,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dplStage),
                hasMoreData[0]] : (querySnapshot.docs.length > 0 ? [...hasMore.filter(item => item.Stage !== _dplStage)] : [...hasMore])
            })

        }
    }

    onCollectionEnquiry = (querySnapshot, _stage) => {
        //console.log('onCollectionEnquiry', _stage, querySnapshot)
        let snapshotDoc;
        const { dealersettings } = this.props;
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let _services = this.state.services
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => item.default === true)
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
                hasMoreData.push({
                    Stage: _dpl + querySnapshot.data[0].stage,
                    lastDoc: querySnapshot.data[querySnapshot.data.length - 1]
                })
            }
            querySnapshot.data.forEach(doc => {
                const _enq = doc;
                result[doc.documentID] = this.convertServiceJobVM(_enq)
                if (_services.tasks) {
                    _services['tasks'][doc.documentID] = { ...result[doc.documentID] }

                } else {
                    _services['tasks'] = result
                }

                let cols = {}
                if (_services.columns) {
                    cols = _services.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _services.tasks && Object.keys(_services.tasks).map(function (el) {
                        if (_services.tasks[el].stage === col.value && _services.tasks[el].pipeline === _dpl) {
                            leadsids.push(_services.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = _services.tasks[el].modifiedDate ? moment().diff(moment.unix(_services.tasks[el].modifiedDate.seconds), 'days') : 0
                                _services.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }

                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _services['columns'] = cols
            })
            if (querySnapshot.data.length === 0) {
                let cols = {}
                if (_services.columns) {
                    cols = _services.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _services.tasks && Object.keys(_services.tasks).map(function (el) {
                        if (_services.tasks[el].stage === col.value) {
                            leadsids.push(_services.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _services['columns'] = cols
            }

            this.setState({
                //isLoading: columns[columns.length - 1] === _stage ? false : true,
                services: _services,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dpl + querySnapshot.data[0].stage), hasMoreData[0]] : [...hasMore.filter(item => item.Stage !== _dpl + _stage)]

            })
        }
    }


    convertServiceJobVM = (_enq) => {

        _enq.stageDate = _enq.stageDate && _enq.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.stageDate._seconds)._d) : _enq.stageDate;
        _enq.addedDate = _enq.addedDate && _enq.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.addedDate._seconds)._d) : _enq.addedDate;
        _enq.modifiedDate = _enq.modifiedDate && _enq.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.modifiedDate._seconds)._d) : _enq.modifiedDate;

        _enq.checkInDate = _enq.checkInDate && _enq.checkInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.checkInDate._seconds)._d) : _enq.checkInDate;
        _enq.completionDate = _enq.completionDate && _enq.completionDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.completionDate._seconds)._d) : _enq.completionDate;
        _enq.nextService = _enq.nextService && _enq.nextService._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.nextService._seconds)._d) : _enq.nextService;
        _enq.clientContactDate = _enq.clientContactDate && _enq.clientContactDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.clientContactDate._seconds)._d) : _enq.clientContactDate;


        _enq.stageHistory = [..._.map(_enq.stageHistory, function (obj) {
            return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]

        const { pipeline } = this.state;
        const { clientUsers, groupUsers } = this.props;
        var rottenDays = ''
        let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
        _selectedPipeline && _selectedPipeline.stages.forEach(col => {
            if (_enq.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = _enq.modifiedDate ? moment().diff(moment.unix(_enq.modifiedDate.seconds), 'days') : 0
                    rottenDays = enqage - col.rottenDays + 'Days'
                }
            }
        })

        const objData = Object.assign({}, _enq);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        return {
            id: _enq.documentID,
            displayID: _enq.displayID ? _enq.displayID : 'VSR-' + _enq.documentID,
            contactID: _enq.contact ? _enq.contact.documentID : '',
            name: _enq.contact ? CommonHelper.displayContactName([], _enq.contact) : '',
            businessContact: _enq.contact && _enq.contact.businessContact ? true : false,
            contactType: _enq.contact && _enq.contact.contactType ? _enq.contact.contactType : null,
            phone: (_enq.contact && _enq.contact.phone) ? _enq.contact.phone : '',
            phoneCode: (_enq.contact && _enq.contact.phoneCode ? (_enq.contact.phoneCode) : ''),
            email: _enq.contact ? (_enq.contact.email) : '',
            vehiclemodel: !_.isEmpty(_enq.vehicle) ? (!_.isEmpty(_enq.vehicle.make) ? (_enq.vehicle.make + ' ' + _enq.vehicle.model) : '') : '',
            vinNo: !_.isEmpty(_enq.vehicle?.vinNo) ? (_enq.vehicle.vinNo) : '',
            regNo: !_.isEmpty(_enq.vehicle?.rego) ? (_enq.vehicle.rego) : (!_.isEmpty(_enq.vehicle?.regNo) ? (_enq.vehicle.regNo) : ''),
            stage: _enq.stage,
            status: _enq.status,
            technicianStatus: _enq.technicianStatus,
            pipeline: _enq.pipeline,
            label: _enq.label,
            isFav: _enq.isFav,
            ownerId: _enq.owner, owner: CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _enq.owner),
            addedby: CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _enq.addedBy),
            modifiedby: CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _enq.modifiedBy),
            enquiryage: (_enq.addedDate ? (moment.unix(_enq.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(_enq.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
            stageHistory: _enq.stageHistory,
            stageDate: _enq.stageDate ? _enq.stageDate : _enq.addedDate,
            modifiedDate: _enq.modifiedDate,
            counts: _enq.counts,
            rottenDays: rottenDays,
            isDeleted: _enq.isDeleted,
            addedDate: _enq.addedDate,
            enqFields: objData,
            clientID: _enq.clientID ? _enq.clientID : null
        }
    }

    handleEnquiryFav = (id, val, stage, oldstage, _objService, _fieldName) => {
        //    console.log('handleEnquiryFav', id, val, stage, oldstage, _objService, _fieldName)

        const { services, pipeline } = this.state;
        const { dealersettings } = this.props;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.servicePermissions)) ? dealersettings.rolePermissions.servicePermissions : null);

        const viewOnlyAccess = ((!_.isEmpty(_permissions) && _permissions.viewOnlyAccess) ? true : false);
        const isDeivered = (_objService.status === enquiryStatus.DELIEVERD || _objService.status === enquiryStatus.LOST || _objService.status === enquiryStatus.PENDINGLOST || _objService.status === enquiryStatus.PROSPECTLOST) ? true : false;
        const canModifyEnquiry = viewOnlyAccess ? false : (((!_.isEmpty(_objService) && _objService.ownerId === dealersettings.id) ||
            (!_.isEmpty(_permissions) &&
                _permissions.modifyOtherEnquiry)) ? true : false);

        const viewOtherEnquiry = !_.isEmpty(dealersettings) ? (((!_.isEmpty(_objService) && _objService.ownerId === dealersettings.id) ||
            (!_.isEmpty(_permissions) &&
                _permissions.viewOtherEnquiry)) ? true : false) : false;

        const modifyStageAfterDeliveredLost = ((!_.isEmpty(_permissions) && _permissions.modifyStageAfterDeliveredLost) ? true : false);
        if (viewOnlyAccess) {
            Swal.fire(CommonHelper.showLocale(this.props, 'Modify access is denied.'), '', 'warning')
            return
        }
        // if (!viewOtherEnquiry) {
        //     Swal.fire("Modify access is denied.", '', 'warning')
        //     //console.log('viewOtherEnquiry=>', viewOtherEnquiry, 'isDeivered=>', isDeivered, 'modifyStageAfterDeliveredLost=>', modifyStageAfterDeliveredLost)
        //     return
        // }
        // if ((canModifyEnquiry && isDeivered && !modifyStageAfterDeliveredLost) || !canModifyEnquiry) {
        //     Swal.fire('Modify access is denied.', '', 'warning')
        //     //console.log('canModifyEnquiry=>', canModifyEnquiry, 'isDeivered=>', isDeivered, 'modifyStageAfterDeliveredLost=>', modifyStageAfterDeliveredLost)
        //     return
        // }

        let _selectedPipeline = pipeline.find(item => item.default === true)
        let _dpl = _selectedPipeline ? _selectedPipeline.value : '';


        _objService.isFav = val;
        var inqUpdate = {
            isFav: Boolean(val),
            stage: stage
        };

        let _selPipeline = !_.isEmpty(dealersettings) && dealersettings.allPipelines.filter(e => e.value === _objService.pipeline)[0];
        let _defaulStages = _selPipeline && _selPipeline.stages
        let statusSettings = _selPipeline && _selPipeline.statusSettings;

        if (stage !== oldstage || _dpl !== _objService.pipeline) {

            var _objFav = {
                id: id,
                val: val,
                stage: stage,
                oldstage: oldstage
            }
            if (_.isEmpty(_fieldName) && !this.isValidForStageUpdate(stage, (!_.isEmpty(_objService.enqFields) ? _objService.enqFields : _objService), _objFav))
                return

            inqUpdate.stageDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.modifiedBy = localStorage.uid;
            inqUpdate.modifiedDate = window.firebase.firestore.Timestamp.now();
            inqUpdate.modifiedFrom = 'web';
            if (_dpl !== _objService.pipeline && _.isEmpty(_fieldName)) {
                inqUpdate.pipeline = _dpl;
            }
            let newstagehistory = {
                pipeline: _dpl,
                stage: stage,
                date: window.firebase.firestore.Timestamp.now()
            };
            let arrstagehistory = _objService.stageHistory ? _objService.stageHistory : []
            let oldstagehistory = _.filter(arrstagehistory, { stage: oldstage, pipeline: _objService.pipeline })[0];
            if (oldstagehistory) {
                let stindex = _.findIndex(arrstagehistory, { stage: oldstage, pipeline: _objService.pipeline });
                let oldsittingDays = oldstagehistory.sittingDays ? oldstagehistory.sittingDays : 0
                arrstagehistory.splice(stindex, 1,
                    {
                        ...oldstagehistory,
                        sittingDays: oldsittingDays + moment().diff(moment.unix(oldstagehistory.date.seconds), 'days')
                    });
            }
            if (arrstagehistory.some(e => e.stage === stage && e.pipeline === _dpl)) {
                newstagehistory = _.filter(arrstagehistory, { stage: stage, pipeline: _dpl })[0]
                newstagehistory.date = window.firebase.firestore.Timestamp.now()
            }
            arrstagehistory = [...arrstagehistory.filter(e => e.stage !== stage), newstagehistory]
            inqUpdate.stageHistory = arrstagehistory;
            _objService.stageHistory = arrstagehistory;
            _objService.rottendays = 0
            _objService.stage = stage
            _objService.stageDate = window.firebase.firestore.Timestamp.now();
            _objService.modifiedBy = localStorage.uid;
            _objService.modifiedDate = window.firebase.firestore.Timestamp.now();
            _objService.modifiedFrom = 'web';
            if (_dpl !== _objService.pipeline && _.isEmpty(_fieldName)) {
                _objService.pipeline = _dpl;
            }
        }
        this.updateEnquiry(_objService)
        firestoreDB(dealersettings).firestore().doc(`serviceJobs/${id}`).set(inqUpdate, { merge: true })
            .then(snapshot => {
                setTimeout(function () {
                    if (document.getElementById('service-' + id)) {
                        document.getElementById('service-' + id).scrollIntoView(
                            {
                                behavior: "smooth",
                                block: "center"
                            });
                    }
                }, 1000)
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    static getDerivedStateFromProps(props, state) {
        let pathname = window.location.pathname;
        if ((pathname.indexOf('service') >= 0) && pathname.indexOf('service/list') < 0) {
            document.getElementsByTagName('body')[0].className = '';
        }

        if (props.dealersettings.client.services.pipelines.length === 0) {
            return {
                isLoading: false
            }
        }

        let _selectedPipeline = props.dealersettings.client.services.pipelines.filter(item => item.default === true)[0];
        let nextColumns = Array.prototype.map.call((_selectedPipeline ? _selectedPipeline.stages : []), function (item) { return item.value; }).join(",").split(',')
        if ((JSON.stringify(nextColumns) !== JSON.stringify(state.columns)
            || props.dealersettings.client.id !== state.clientid)) {
            //props.dealersettings.ref = "";
            return {
                columns: Array.prototype.map.call((_selectedPipeline ? _selectedPipeline.stages : []), function (item) { return item.value; }).join(",").split(','),
                pipeline: props.dealersettings.client.services.pipelines,
                clientid: props.dealersettings.client.id,
                isLoading: true
            }
        }
        else {
            return {
                isLoading: false
            }
        }

    }

    loadMoreData = () => {
        if (this.state.hasMore.length === 0) return;

        this.setState({
            stageLoading: this.state.hasMore[0].Stage
        })
        this.LoadEnquiry(true);
    }

    // listenScrollEvent(e) {
    //     if (this.state.hasMore.length === 0) return;

    //     if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
    //         this.setState({
    //             stageLoading: this.state.hasMore[0].Stage
    //         })
    //         this.LoadEnquiry()
    //     }
    //     return;
    // }

    updateEnquiry = (_objService) => {

        //console.log(_objService.id, _objService);
        const { services, pipeline } = this.state;
        const { } = this.props;
        if (_.isEmpty(services))
            return

        _objService.stageDate = _objService.stageDate && _objService.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objService.stageDate._seconds)._d) : (_objService.stageDate ? _objService.stageDate : _objService.addedDate);

        _objService.addedDate = _objService.addedDate && _objService.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objService.addedDate._seconds)._d) : _objService.addedDate;
        _objService.modifiedDate = _objService.modifiedDate && _objService.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objService.modifiedDate._seconds)._d) : _objService.modifiedDate;
        _objService.verifiedDate = _objService.verifiedDate && _objService.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objService.verifiedDate._seconds)._d) : _objService.verifiedDate;


        _objService.checkInDate = _objService.checkInDate && _objService.checkInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objService.checkInDate._seconds)._d) : _objService.checkInDate;
        _objService.completionDate = _objService.completionDate && _objService.completionDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objService.completionDate._seconds)._d) : _objService.completionDate;
        _objService.nextService = _objService.nextService && _objService.nextService._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objService.nextService._seconds)._d) : _objService.nextService;
        _objService.clientContactDate = _objService.clientContactDate && _objService.clientContactDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objService.clientContactDate._seconds)._d) : _objService.clientContactDate;


        _objService.stageHistory = [..._.map(_objService.stageHistory, function (obj) {
            return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]


        if (_objService.isDeleted === true && services.tasks[_objService.id]) {
            const oldpipipeline = services.columns[services.tasks[_objService.id].pipeline + services.tasks[_objService.id].stage] ? services.columns[services.tasks[_objService.id].pipeline + services.tasks[_objService.id].stage].taskIds : []
            const index = oldpipipeline.indexOf(_objService.id)
            oldpipipeline.splice(index, 1);

            delete services['tasks'][_objService.id]
        }
        else if (services.tasks[_objService.id] && services.tasks[_objService.id].pipeline === _objService.pipeline) {
            if (!Object.keys(services.tasks).some(enq => enq === _objService.id)) {
                services.columns[_objService.pipeline + _objService.stage].taskIds.splice(0, 0, _objService.id)
            }
            else if (services.tasks[_objService.id].stage !== _objService.stage) {
                const oldsatge = services.columns[services.tasks[_objService.id].pipeline + services.tasks[_objService.id].stage] ? services.columns[services.tasks[_objService.id].pipeline + services.tasks[_objService.id].stage].taskIds : []
                const newstage = services.columns[_objService.pipeline + _objService.stage] ? services.columns[_objService.pipeline + _objService.stage].taskIds : []
                const index = oldsatge.indexOf(_objService.id)
                if (index > -1) {
                    oldsatge.splice(index, 1);
                }
                newstage.splice(0, 0, _objService.id)
            }

            let rDays = 0;
            let _selectedPipeline = pipeline.filter(item => item.default === true)[0]
            _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                if (_objService.stage === col.value) {
                    if (col.rottenDays) {
                        var enqage = _objService.modifiedDate ? moment().diff(moment.unix(_objService.modifiedDate.seconds), 'days') : 0
                        rDays = enqage - col.rottenDays
                    }
                }
            })

            services['tasks'][_objService.id] = {
                ..._objService,
                enquiryage: (_objService.addedDate ? (moment.unix(_objService.addedDate.seconds).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '0 day' : moment.unix(_objService.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                rottendays: rDays
            }
        }
        else {
            let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
            if (_selectedPipeline && _selectedPipeline.value === _objService.pipeline && !_.isEmpty(services.columns[_objService.pipeline + _objService.stage])) {
                const newstage = services.columns[_objService.pipeline + _objService.stage].taskIds
                newstage.splice(0, 0, _objService.id)

                services['tasks'][_objService.id] = {
                    ..._objService,
                    enquiryage: (_objService.addedDate ? (moment.unix(_objService.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(_objService.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                    rottendays: 0
                }
            }
            else if (services.tasks[_objService.id]) {
                const oldpipipeline = services.columns[services.tasks[_objService.id].pipeline + services.tasks[_objService.id].stage].taskIds
                const index = oldpipipeline.indexOf(_objService.id)
                oldpipipeline.splice(index, 1);

                delete services['tasks'][_objService.id]
            }
        }

        this.setState({ services })
    }


    handleApplyFilter = (_filter) => {
        //console.log('handleApplyFilter', _filter)
        let _selectedPipeline = this.state.pipeline.filter(item => item.default === true)[0];
        _selectedPipeline && _selectedPipeline.stages.forEach(s => {
            window['unsubService' + _selectedPipeline.value + s.value] && window['unsubService' + _selectedPipeline.value + s.value]();
        })
        window.unSubServicePipelineCount && window.unSubServicePipelineCount();

        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('servicePipelineFilter', JSON.stringify(_filter));
            localStorage.serviceSearchText = '';
            this.setState({ isFilterApplied: true, isLoading: true, pageNum: 0, hasMore: [], services: {}, stageCounts: {}, searchText: '' }, () => { this.LoadEnquiry() })
        }
        else {
            this.handleClearFilter();
        }

    }

    handleClearFilter = () => {
        //console.log('handleClearFilter',) 
        if (!_.isEmpty(localStorage.servicePipelineFilter)) {
            localStorage.removeItem('servicePipelineFilter');
            localStorage.serviceSearchText = '';
            this.setState({ isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], services: {}, stageCounts: {}, searchText: '' }, () => { this.LoadEnquiry() })
        }
    }

    handlesearchText = (val) => {
        localStorage.removeItem('servicePipelineFilter');
        this.setState({ searchText: val, isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], services: {}, stageCounts: {} }, () => { this.LoadEnquiry() })
    }

    handlePageClear = () => {
        localStorage.removeItem('servicePipelineFilter');
        this.setState({ pageNum: 0, hasMore: [] })
    }

    //#region MANDATORY PERMISSIONstage, oldstage, _objService.enqFields
    isValidForStageUpdate = (_stage, enqFields, origin) => {

        const { pipeline, stage } = enqFields;
        const _oldStage = pipeline + '-' + stage;
        const _newStage = pipeline + '-' + _stage;
        const { dealersettings } = this.props;

        let _mandPiplines;
        let _isValid = true;
        if (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.services) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields) &&
            !_.isEmpty(dealersettings.client.services.mandatoryFields.pipeline))
            _mandPiplines = dealersettings.client.services.mandatoryFields.pipeline;
        //console.log('isValidForStageUpdate', _oldStage, _newStage, enqFields, origin, _mandPiplines);
        if (!_.isEmpty(_mandPiplines)) {

            let __newPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];
            let __oldPipeline = _mandPiplines.filter(e => e.name === _oldStage)[0];

            if (!_.isEmpty(__newPipeline)) {

                if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                    for (let [key] of Object.entries(fieldsData)) {
                        __newPipeline[key] = ((__newPipeline[key] ? __newPipeline[key] : "") + (__oldPipeline[key] ? (__newPipeline[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
                    }
                }

                _isValid = this.handleMandatoryPermission(__newPipeline, enqFields, origin)
                //    console.log('isValidForStage------>Update', _newStage, _oldStage, 'ISVALID:' + _isValid, __newPipeline, __oldPipeline);
            }
            else if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
                for (let [key] of Object.entries(fieldsData)) {
                    __oldPipeline[key] = (__oldPipeline[key] ? __oldPipeline[key] : "");
                }
                _isValid = this.handleMandatoryPermission(__oldPipeline, enqFields, origin)
                //    console.log('isValidForStage------>Update---->old', _oldStage, 'ISVALID:' + _isValid, __oldPipeline);
            }
        }

        return _isValid;
    }
    handleMandatoryPermission = (objFields, enqFields, origin) => {
        let isBreak = false;
        if (!_.isEmpty(objFields)) {
            const _fieldsData = Object.assign({}, fieldsData);
            for (let [key, value] of Object.entries(objFields)) {
                _fieldsData[key] = value;
            }
            const { contact } = enqFields;
            for (let [key, value] of Object.entries(_fieldsData)) {
                
                if (key === 'contact' && !contact?.businessContact && !_.isEmpty(value)) {
                    if (!this.checkMandatoryContact(value, enqFields)) {
                      isBreak = true;
                      break;
                    }
                  }
          
                  if (key === 'businessContact' && contact?.businessContact && !_.isEmpty(value)) {
                    if (!this.checkMandatoryContact(value, enqFields)) {
                      isBreak = true;
                      break;
                    }
                  }

                if (key === 'service' && !_.isEmpty(value)) {
                    if (!this.checkMandatoryService(value, enqFields, origin)) {
                        isBreak = true;
                        break;
                    }
                }
            }
        }
        if (isBreak)
            return false;
        else
            return true;
    }

    handleOpenEnquiry = (documentID) => {
        this.props.history.push('/service/details/' + documentID);
    }

    checkMandatoryContact = (value, enqFields) => {
        const { isContact, contact } = enqFields;
        let _mandatoryFields = value ? _.uniq(value.split(',')) : [];
        if (!isContact) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Please add contact'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.handleOpenEnquiry(enqFields.documentID);
                }
            });

            return false;

        }
        else if (!_.isEmpty(_mandatoryFields)) {
            const ObjCustomer = Object.assign({}, contact);
            let msg;
            _mandatoryFields.some((el, index) => {
                if (el.trim() === 'licenseFrontURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license front image';
                        return true;
                    }
                } else if (el.trim() === 'licenseBackURL') {
                    if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
                        msg = 'Please upload license back image';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemail') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
                        msg = 'Please enter Phone or Email';
                        return true;
                    }
                } else if (el.trim() === 'phoneORemailORlicenseNo') {
                    if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
                        msg = 'Please enter Phone or Email or License No.';
                        return true;
                    }
                }
                else if (!ObjCustomer[el]) {
                    msg = 'Please enter ' + el;
                    return true;
                }
            });
            if (msg) {
                Swal.fire({
                    title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                    cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                }).then(result => {
                    if (result.value) {
                        this.handleOpenEnquiry(enqFields.documentID);
                    }
                });
                return false;
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    checkMandatoryService = (value, enqFields, origin) => {
        let _mandatoryFields = !_.isEmpty(value) ? _.uniq(value.split(',')) : [];

        if (!_.isEmpty(this.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.mandatoryFields);

        if (!_.isEmpty(_mandatoryFields)) {
            const _objService = Object.assign({}, objService);
            const ObjEnqFields = Object.assign({}, enqFields);
            for (let [key, value] of Object.entries(ObjEnqFields)) {
                _objService[key] = value;
            }

            let msg;

            let _isbreak = false;

            let _specialKey = ['isContact', 'isVehicle', 'isInspection', 'isServicePackage', 'isFiles', 'recommendations', 'notes']

            let _requiredObject = _.filter(_mandatoryFields, (v) => (_specialKey.indexOf(v) >= 0));

            let _requiredFields = _.filter(_mandatoryFields, (v) => (_specialKey.indexOf(v) < 0));

            //console.log('checkMandatory-Enquiry', value, _mandatoryFields, this.mandatoryFields, _requiredObject, _requiredFields)

            !_.isEmpty(_requiredObject) && _requiredObject.some((el, index) => {
                if (el.trim() === 'isContact') {
                    if (!_objService[el] && _.isEmpty(_objService['contact'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add contact'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID);
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isVehicle') {
                    if (!_objService[el] && _.isEmpty(_objService['vehicle'])) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add vehicle'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID);
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isInspection') {
                    if (!_objService[el]) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add inspection'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID);
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isServicePackage') {
                    if (!_objService[el]) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add service packages'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID);
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                } else if (el.trim() === 'isFiles') {
                    if (!_objService[el]) {
                        Swal.fire({
                            title: CommonHelper.showLocale(this.props, 'Please add file'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                            cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
                        }).then(result => {
                            if (result.value) {
                                this.handleOpenEnquiry(enqFields.documentID);
                            }
                        });
                        _isbreak = true;
                        return true;
                    }
                }
            });

            if (_isbreak)
                return false;

            !_.isEmpty(_requiredFields) && _requiredFields.some((el, index) => {
                if (!_objService[el]) {
                    if (_objService.hasOwnProperty(el)) {
                        msg = 'Please enter ' + el;
                        return true;
                    }
                    else {
                        return true;
                    }
                }
            });

            if (msg) {
                let errors = {};
                let formIsValid = true;
                let errorClass = 'input_error';

                _requiredFields.forEach((key, index) => {
                    if (
                        !_objService[key] &&
                        _requiredFields.indexOf(key) >= 0
                    ) {
                        if (_objService.hasOwnProperty(key)) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                });

                if (formIsValid === true) {
                    return true;
                } else {
                    console.log('checkMandatory-Service-errors', errors)
                    // this.setState({
                    //     enquiryModal: {
                    //         show: true,
                    //         enquiry: _objService,
                    //         errors: errors,
                    //         origin: origin
                    //     },
                    // });
                    return formIsValid;
                }
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    //#endregion

    handlePipelineChange = (value) => {
        this.props.updateDealerSettings('servicePipeline', value);
        this.handlePageClear();
    }

    render() {

        const { children, dealersettings } = this.props
        const {
            columns, pipeline, services, hasMore,
            isLoading, stageLoading, showNotifier, notifierMsg, notifierInfo,
            sectionloading,
            pageNum, isFilterApplied, stageCounts, searchText,
        } = this.state


        //console.log('E N Q U I R I E S', stageCounts)
        return (
            <>
                <StageContext.Provider
                    value={{
                        services,
                        pipeline,
                        columns,
                        isLoading,
                        handleEnquiryFav: this.handleEnquiryFav,
                        loadMoreData: this.loadMoreData,
                        stageLoading,
                        showNotifier,
                        notifierMsg,
                        notifierInfo,
                        updateEnquiry: this.updateEnquiry,
                        sectionloading,
                        handleApplyFilter: this.handleApplyFilter,
                        handleClearFilter: this.handleClearFilter,
                        pageNum,
                        isFilterApplied,
                        stageCounts,
                        searchText,
                        hasMore,
                        handlesearchText: this.handlesearchText,
                        dealersettings: this.props.dealersettings,
                        handlePageClear: this.handlePageClear,
                        handlePipelineChange: this.handlePipelineChange
                    }}
                >
                    {children}
                </StageContext.Provider>

            </>
        )
    }

}