export const targetVM = {
    documentID: null,
    clientIDs: [],
    title: '',
    type: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    oemID: null
};

export const targetlistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Clients',
        value: 'clientsName',
        flex: 2,
        default: true,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        sortValue: 'createdOn',
        subText: 'createdBy',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'targetID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const kpitargetlistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Users',
        value: 'usersName',
        flex: 2,
        default: true,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        sortValue: 'createdOn',
        subText: 'createdBy',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'targetID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]
