/** LIBRARIES */
import React, { Component, Suspense } from 'react';
/** PROVIDERS */
import { LayoutConsumer } from '../layout/provider';
/** COMPONENTS */
import List from './list'

class CallLogs extends Component {

    render() {
        return (
            <LayoutConsumer>
                {({ dealersettings, clientUsers, groupUsers, updateDealerSettings }) => (

                    <Suspense >
                        <div className={`middle-wrapper ${this.props.isFromSales ? 'mt-0' : ''}`}>
                            <List
                                clientUsers={clientUsers}
                                groupUsers={groupUsers}
                                history={this.props.history}
                                dealersettings={dealersettings}
                                updateDealerSettings={updateDealerSettings}
                                isFromSales={this.props.isFromSales}
                            />
                        </div>

                    </Suspense>
                )}
            </LayoutConsumer>
        );
    }

}

export default CallLogs;