import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import _images from '../../images'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';
import images from '../../images';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { levelOptions } from '../eventsProTemplates/viewModel';

const LiveEvents = (props) => {
    const [loader, setLoader] = useState(true)
    const [monthData, setMonthData] = useState([])

    const eventsProStatus = props?.dealersettings?.client?.eventsPro?.eventsProStatus;
    useEffect(() => {
        //let allEventProDealers = CommonHelper.getAllEventsProDealers(props.dealersettings, true);
        // var collectionPromise = [];
        // for (let k = 0; k < Math.ceil(allEventProDealers.length / 10); k++) {
        //     let refEventsData = firestoreDB(props.dealersettings).firestore().collection('eventsPro')
        //         .where('isDeleted', '==', false)
        //         .where('clientIDs', 'array-contains-any', CommonHelper.arrPaginate(allEventProDealers, 10, k + 1))
        //         .where('status', '==', 'live')

        //     collectionPromise.push(refEventsData ? collection(refEventsData) : of([]));
        // }
        const collectionPromise = [];
        let _userLevel = levelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(levelOptions, { value: props.dealersettings.level })?.index)
        _userLevel.forEach(level => {
            let _levelIDs = CommonHelper.getAllEventsProByLevel(props.dealersettings, level.value, true, true);
            for (let k = 0; k < Math.ceil(_levelIDs.length / 10); k++) {
                let refEnquiryData = firestoreDB(props.dealersettings).firestore().collection('eventsPro')
                    .where('isDeleted', '==', false)
                    .where(`${level.value === 'individual' ? 'clientIDs' : 'levelID'}`, 'array-contains-any', CommonHelper.arrPaginate(_levelIDs, 10, k + 1))
                    .where('status', '==', 'live')

                collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
            }
        });
        if (collectionPromise.length > 0) {
            window.unsubrefLiveEventsData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    let _data = [];
                    var querySnapshot = [];
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    querySnapshot.forEach((rec) => {
                        const eventData = rec.data();
                        _data.push({
                            ...eventData,
                            'documentID': rec.id,
                            'id': rec.id,
                        })
                    })
                    _data = _.orderBy(_data, 'startDate.seconds', 'asc')
                    setMonthData(_.uniqBy(_data, 'id'))
                    setLoader(false);
                });
        }
        else {
            setLoader(false);
        }
        return () => {
            window.unsubrefLiveEventsData && window.unsubrefLiveEventsData.unsubscribe();
        }
    }, [])



    return (
        <>
            <div className="dashboard-box">
                {
                    loader ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    {/* <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setLoader(true) }}> <i className="ico icon-refresh"></i></a>

                    </div> */}
                    <h3><Translate text={'Live Events'} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                    {
                        monthData.length > 0 ? (<div style={{ height: 'calc(100% - 0px)', overflow: 'auto' }}>
                            {
                                monthData.map((event, index) => {
                                    return <div key={index} className="events-dashboard-livewrap mb-2" onClick={(e) => {
                                        e.preventDefault();
                                        props.history.push("/eventspro/details/" + event.id);
                                    }}>
                                        <div className="events-dashboard-live-flex">
                                            <div className="events-dashboard-live-image">
                                                {
                                                    event.imageURL ? (
                                                        <img src={event.imageURL} alt="" width="70" height="70" />
                                                    ) : (
                                                        <img src={images.noEvent} alt="" width="70" height="70" />
                                                    )
                                                }
                                            </div>
                                            <div className="events-dashboard-live-data">
                                                <div className="events-dashboard-card-title">{event.title}</div>
                                                <div className="events-dashboard-card-sub"><i className="ico icon-location"></i> {event.location} </div>
                                                <div className="events-dashboard-card-sub">
                                                    <i className="ico icon-date"></i> {CommonHelper.showCombineDate(event.startDate, event.endDate, event.timezone)}
                                                    <i className="ico icon-time ml-2"></i> {CommonHelper.showCombineTime(event.startDate, event.endDate, event.timezone)}
                                                </div>
                                                <div className="events-dashboard-card-sub">
                                                    {
                                                        _.find(eventsProStatus, { value: event.status }) ? (
                                                            <div className={`badge badge-pill badge-status-${_.find(eventsProStatus, { value: event.status }).value === 'New' ? 'open' : 'empty'}`} style={{ background: (_.find(eventsProStatus, { value: event.status })?.color ? _.find(eventsProStatus, { value: event.status }).color : '#6b717f') }}>{_.find(eventsProStatus, { value: event.status }).name} </div>
                                                        ) : (
                                                            <div className={`badge badge-pill badge-status-${event.status.toLowerCase()}`} >{event.status} </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="events-dashboard-live-status"></div>
                                        <ul className="events-dashboard-stat-details">
                                            <li>
                                                <div className="events-stat-column">
                                                    <div className="events-stat-icon events-stat-blue"> <i className="ico icon-events-invitation"></i> </div>
                                                    <div className="events-stat-right events-stat-blue">
                                                        <div className="events-stat-title">Invitations</div>
                                                        <div className="events-stat-count">{event?.counts?.invitations > 0 ? event?.counts?.invitations : 0}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="events-stat-column">
                                                    <div className="events-stat-icon events-stat-green"> <i className="ico icon-sent"></i> </div>
                                                    <div className="events-stat-right">
                                                        <div className="events-stat-title">Checked-In</div>
                                                        <div className="events-stat-count">{event?.counts?.checkedIn > 0 ? event?.counts?.checkedIn : 0}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="events-stat-column">
                                                    <div className="events-stat-icon events-stat-orange"> <i className="ico icon-events-new"></i> </div>
                                                    <div className="events-stat-right">
                                                        <div className="events-stat-title">New</div>
                                                        <div className="events-stat-count">{event?.counts?.newAttendees > 0 ? event?.counts?.newAttendees : 0}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="events-stat-column">
                                                    <div className="events-stat-icon events-stat-skyblue"> <i className="ico icon-events-testdrive"></i> </div>
                                                    <div className="events-stat-right">
                                                        <div className="events-stat-title">Test Drive</div>
                                                        <div className="events-stat-count">{event?.counts?.testDrive > 0 ? event?.counts?.testDrive : 0}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                })
                            }

                        </div>)

                            : (<div className="dashboard-activity-item" >
                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                    <div className="no-data-txt mt-2"> <Translate text={'No live events'} /> </div>
                                </div>
                            </div>)
                    }

                </div>


            </div>
        </>
    );
}

export default LiveEvents;