
/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import moment from 'moment'
import ReactExport from 'react-export-excel-xlsx-fix';
/** COMPONENTS */
import { AntDateRangePicker, ReactSelect } from "../../components";
import Translate from '../../constants/translate';
import { logTypes } from '../../services/enum';
import CommonHelper from '../../services/common';
//import Filters from './filters'

const sortOrders = [
    { active: true, value: 'modifiedDate', label: 'Updated On' },
    { active: true, value: 'addedDate', label: 'Created On' }
]
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [logsFilter, setLogsFilter] = useState({})

    const [searchText, setSearchText] = useState(props.searchText)
    const [filterLog, setFilterLog] = useState(props.filterLog)
    const [dates, setDates] = useState([]);
    const csvBtn = useRef();
    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])
    useEffect(() => {
        setFilterLog(props.filterLog);
    }, [props.filterLog])

    useEffect(() => {
        if (!_.isEmpty(localStorage.logsFilter) && Object.keys(JSON.parse(localStorage.logsFilter)).length > 0) {
            let _logsFilter = JSON.parse(localStorage.logsFilter);
            let _stockValue = _logsFilter.value;

            if (!_.isEmpty(_stockValue) && _stockValue.hasOwnProperty('keywords'))
                setSearchKeywords(_stockValue['keywords']);
            else
                setSearchKeywords('');

            setLogsFilter(_logsFilter);
        }
        else {
            setLogsFilter({});
            setSearchKeywords('');
        }

    }, [localStorage.logsFilter])

    //#region SEARCH KEYWORDS
    const handleKeywordsOnChange = (e) => {
        e.preventDefault()
        const { value } = e.target;

        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSearchKeywords(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSearchKeywords(str);
        }
    };

    const handleSearchKeywords = (_keywords) => {
        if (_keywords && _keywords.trim()) {
            if (!_.isEmpty(logsFilter)) {
                let _logsFilter = Object.assign({}, logsFilter)
                if (!_.isEmpty(logsFilter.value)) {
                    // update with the existing keyword
                    _logsFilter.value['keywords'] = _keywords.trim()
                }
                else {
                    // update with the new keyword
                    _logsFilter.value = { 'keywords': _keywords.trim() }
                }
                props.handleApplyFilter(_logsFilter);
            }
            else {
                // create new filter with keyword
                let _logsFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': _keywords.trim() }, type: 'filter' })
                props.handleApplyFilter(_logsFilter);
            }
        }
        else if (_keywords && !_keywords.trim()) {
            setSearchKeywords('');
        } else {
            if (!_.isEmpty(logsFilter)) {
                let _logsFilter = Object.assign({}, logsFilter)
                if (!_.isEmpty(logsFilter.value)) {
                    if (Object.keys(logsFilter.value).length > 1) {
                        delete _logsFilter.value['keywords']
                        props.handleApplyFilter(_logsFilter);
                    }
                    else {
                        props.handleClearFilter();
                    }
                }

            }
        }
    };

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };
    //#endregion

    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    return (
        <>
            <div className="filter-panel">


                {/* FILTER SEARCH KEYWORDS*/}
                <div className="filter-search">
                    <div className="input-group ">
                        <input
                            className="form-control"
                            type="text"
                            id="isearch"
                            placeholder="quick search..."
                            value={searchText ? searchText : ''}
                            onChange={(e) => {

                                let _val = e.target.value
                                setSearchText(_val);
                                localStorage.logSearchText = _val;

                                if (_val && _val.length > 2) {
                                    props.handlesearchText(_val)
                                }

                                if (!_val)
                                    props.handlesearchText('')
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    localStorage.logSearchText = searchText;
                                    props.handlesearchText(searchText)
                                }
                            }}
                            autoComplete="off"
                        />
                        <div className="input-group-append input-search-clear">
                            {
                                searchText && searchText.length > 0
                                    ?
                                    <a href="#"
                                        className="input-search-clear-icon"
                                        title="Clear"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSearchText('');
                                            localStorage.logSearchText = '';
                                            props.handlesearchText('')
                                        }}
                                    >
                                        <i className="ico icon-remove"></i>
                                    </a>
                                    :
                                    <>
                                    </>
                            }

                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.logSearchText = searchText;
                                    props.handlesearchText(searchText)
                                }}>
                                <i className="ico icon-search-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="form-dashboard form-row ml-2">
                    <div className="form-group col-md-4 daterange-fix">
                        <AntDateRangePicker
                            value={props.dateRange ? [moment.unix(props.dateRange.startDate.seconds), moment.unix(props.dateRange.endDate.seconds)] : null}
                            id={"logs-date-range"}
                            name={'date'}
                            format='DD/MM/YYYY'
                            onChange={(e) => { props.handleDateRangeChange(e, 'date') }}
                            placeholder='DD/MM/YYYY'
                            onCalendarChange={(value) => {
                                if (value) {
                                    const [start, end] = value;
                                    setDates([start, end]);
                                }
                                else {
                                    setDates([])
                                }
                            }}
                            disabledDate={disabledDate}
                        />
                    </div>
                </div>
                <div className="form-loan-history form-row ml-2">
                    <div className="form-group col-md-12 daterange-fix">
                        <ReactSelect
                            options={logTypes}
                            name={"filterLog"}
                            placeholder={'select log type'}
                            onChange={(e) => {
                                if (e) {
                                    setFilterLog(e.value);
                                    props.handleFilterLog(e.value)
                                }
                                else {
                                    setFilterLog('');
                                    props.handleFilterLog(null)
                                }
                            }}
                            value={filterLog}
                            classNamePrefix={`basic-select`}
                            removeClearable={false}
                        >
                        </ReactSelect>
                    </div>
                </div>
                {/* <div className="filter-search ml-2">
                    <div className="input-group ">
                        <input
                            className="form-control"
                            type="text"
                            id="isearch"
                            placeholder="Search..."
                            value={searchKeywords ? searchKeywords : ''}
                            onChange={(e) => {
                                handleKeywordsOnChange(e);
                                if (!e.target.value)
                                    handleSearchKeywords('')
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearchKeywords(searchKeywords)
                                }
                            }}
                            autoComplete="off"
                        />
                        <div className="input-group-append input-search-clear">
                            {
                                searchKeywords && searchKeywords.length > 0
                                    ?
                                    <a href="#"
                                        className="input-search-clear-icon"
                                        title="Clear"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSearchKeywords('');
                                            handleSearchKeywords('')
                                        }}
                                    >
                                        <i className="ico icon-remove"></i>
                                    </a>
                                    :
                                    <>
                                    </>
                            }

                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSearchKeywords(searchKeywords)
                                }}>
                                <i className="ico icon-search-fill"></i>
                            </button>
                        </div>
                    </div>
                </div> */}

                {/* {
                    ((!_.isEmpty(props.stockCount) || props.stockCount > 0) && !localStorage.logSearchText) ?
                        (<>
                            <div className="list-count-btn ml-3">
                                <span>  {props.stockCount > 1 ? ' Stock' : ' Stock'}</span>
                                <span className="list-count"> {props.stockCount} </span>
                            </div>
                        </>)
                        : (<></>)
                } */}



                <div className="float-right">

                    <button type="button" className="btn btn-sm btn-rejected float-left mr-2" onClick={() => {
                        if (props.rejectCount.length > 0)
                            props.handleRejectShow();
                    }}>
                        <i className="ico icon-rejected"></i> <Translate text={'Rejected'} />
                        <span className="badge badge-pill badge-rejected-count ml-1">{props.rejectCount.length}</span>
                    </button>

                    <div className="filter-add-button ml-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                props.handleAddStock();
                            }}
                        > <i className="ico icon-add"></i> <Translate text={'Add Log'} /></button>
                    </div>

                    {
                        (exportData)
                            ?
                            <>
                                <div className="filter-item-btn ml-2">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                                    }} title={'Excel Export'}>
                                        <i className="ico icon-excel-download"></i>
                                    </a>
                                    <ExcelFile
                                        ref={csvBtn}
                                        element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                        filename={`logs_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                        <ExcelSheet dataSet={CommonHelper.generateExcelData(props.datalist, props.csvHeader, props)} name={`Sheet1`} />
                                    </ExcelFile>
                                    {/* <CSVLink uFEFF={false} data={props.datalist} headers={props.csvHeader} filename={`logs_${moment().format('DD_MM_YYYY_HH_mm')}.csv`}>
                                        <i className="ico icon-excel-download"></i>
                                    </CSVLink> */}
                                </div>
                            </>
                            :
                            <>
                            </>
                    }

                </div>



                {/**** FILTERS ****/}
                {/* <Filters
                    searchKeywords={searchKeywords}
                    logsFilter={logsFilter}
                    customFilters={props.customFilters}
                    dealersettings={props.dealersettings}
                    handleApplyFilter={props.handleApplyFilter}
                    handleClearFilter={props.handleClearFilter}
                    clientUsers={props.clientUsers}
                >
                </Filters> */}

            </div >
        </>
    )
}


export default FilterPanel
