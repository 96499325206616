export const objContact = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    groupID: null,
    regionID: null,
    oemID: null,
    //mergeID: null,
    //customerNumber: '',
    contactType: 'Potential Customer',
    businessContact: false,
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    preferredName: '',
    userImageURL: '',
    userSignature: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    isVIP: false,
    isDLScan: false,
    isRepeat: null,
    prevPurchased: null,
    nationality: '',
    address: '',
    state: '',
    areaCode: '',
    country: '',
    phoneCode: '',
    phone: '',
    email: '',
    phones: [],
    emails: [],
    company: {},
    designation: '',
    visitingCardURL: '',
    contactMethod: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseFrontURL: '',
    licenseBackURL: '',
    licenseType: '',
    licenseState: '',
    interests: [],
    origin: '',
    campaign: '',
    optinPhone: true,
    optinEmail: true,
    optinPost: true,
    optinSMS: true,
    marketingOptinEmail: true,
    marketingOptinSMS: true,
    banLoanVehicle: false,
    dependents: '',
    driverName: '',
    secondaryLicenseFrontUrl: '',
    secondaryLicenseBackUrl: '',
    secondaryTitle: '',
    secondaryFirstName: '',
    secondaryMiddleName: '',
    secondaryLastName: '',
    secondaryPreferredName: '',
    secondaryPhones: [],
    secondaryEmails: [],
    secondaryPhoneCode: '',
    secondaryPhone: '',
    secondaryEmail: '',
    secondaryGender: '',
    secondaryDob: null,
    secondaryIDNumber: '',
    secondaryIDType: '',
    secondaryIDExpiry: null,
    tvDisplayName: '',
    tags: null,
    owner: '',
    terms: {},
    creditScoreTerms: {},
    amsMe: null,
    creditScore: null,
    meInvite: null,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    gdprDeleted: false,
    isDeleted: false
};

export const FilterState = {
    iSearch: '',
    filterGender: '',
    sortOrder: ''
};

export const mandatoryFields = [
    'title',
    'firstName',
    'lastName',
    'gender',
    'phone',
    'email'
];


export const objOwnedVehicle = {
    documentID: null,
    make: '',
    model: '',
    type: '',
    group: '',
    year: '',
    regNo: '',
    vinNo: '',
    exteriorColor: '',
    transmission: '',
    warrantyStartDate: null,
    warrantyExp: null,
    registrationExpiry: null,
    nextService: null,
    lastService: null,
    currentlyOwned: true,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null
};

export const objOwnedEnquiryVehicle = {
    enquiryID: null,
    displayID: null,
    make: '',
    model: '',
    type: '',
    group: '',
    year: '',
    regNo: '',
    chassisNo: '',
    extColor: '',
    intColor: '',
    price: null,
    status: '',
    owner: '',
    saleType: '',
    enquiry: null,
    transmission: '',
    currentlyNotOwned: false,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null
};


export const mandatoryOwnedFields = [
    'make',
    'model',
    //'type'
];
export const mandatoryOwnedEnqFields = [
    'make',
    'model'
];

export const contactBasicDetailsVM = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    groupID: null,
    regionID: null,
    oemID: null,
    contactType: '',
    businessContact: false,
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    preferredName: '',
    userImageURL: '',
    phoneCode: '',
    phone: '',
    email: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    isVIP: false,
    isDLScan: false,
    isRepeat: null,
    prevPurchased: null,
    nationality: '',
    address: '',
    state: '',
    areaCode: '',
    country: '',
    company: {},
    designation: '',
    contactMethod: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseFrontURL: '',
    licenseBackURL: '',
    licenseType: '',
    licenseState: '',
    interests: [],
    origin: '',
    campaign: '',
    optinPhone: true,
    optinEmail: true,
    optinPost: true,
    optinSMS: true,
    marketingOptinEmail: true,
    marketingOptinSMS: true,
    banLoanVehicle: false,
    dependents: '',
    driverName: '',
    secondaryTitle: '',
    secondaryPreferredName: '',
    secondaryLicenseFrontUrl: '',
    secondaryLicenseBackUrl: '',
    secondaryFirstName: '',
    secondaryLastName: '',
    secondaryPhoneCode: '',
    secondaryPhone: '',
    secondaryEmail: '',
    amsMe: null,
    creditScore: null,
    meInvite: null,
    //keywords: [],
    owner: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    gdprDeleted: false,
}

export const contactBasicWithKeywordsVM = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    groupID: null,
    regionID: null,
    oemID: null,
    contactType: '',
    businessContact: false,
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    preferredName: '',
    userImageURL: '',
    phoneCode: '',
    phone: '',
    email: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    isVIP: false,
    isDLScan: false,
    nationality: '',
    address: '',
    state: '',
    areaCode: '',
    country: '',
    company: {},
    designation: '',
    contactMethod: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseFrontURL: '',
    licenseBackURL: '',
    licenseType: '',
    licenseState: '',
    interests: [],
    origin: '',
    campaign: '',
    optinPhone: true,
    optinEmail: true,
    optinPost: true,
    optinSMS: true,
    marketingOptinEmail: true,
    marketingOptinSMS: true,
    banLoanVehicle: false,
    dependents: '',
    driverName: '',
    secondaryTitle: '',
    secondaryPreferredName: '',
    secondaryLicenseFrontUrl: '',
    secondaryLicenseBackUrl: '',
    secondaryFirstName: '',
    secondaryLastName: '',
    secondaryPhoneCode: '',
    secondaryPhone: '',
    secondaryEmail: '',
    keywords: [],
    amsMe: null,
    creditScore: null,
    meInvite: null,
    owner: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    gdprDeleted: false,
}

export const allcontactFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Name',
        value: 'fullName',
        flex: 2,
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
        sortValue: 'firstName',
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
        sortValue: 'email',
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone',
        sortValue: 'phone',
    },
    {
        name: 'License No',
        value: 'licenseNo',
        flex: 1,
        default: true,
        sortValue: 'licenseNo',

    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1,
        sortValue: 'licenseExpiry',
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By',
        sortValue: 'addedDate',
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By',
        sortValue: 'modifiedDate',
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1,
        sortValue: 'owner.name'
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2,
        sortValue: 'address'
    },
    {
        name: 'State',
        value: 'state',
        flex: 1,
        sortValue: 'state'
    },
    {
        name: 'VIP Contact',
        value: 'isVIP',
        flex: 1
    },
    {
        name: 'Restrict From Loan Vehicle',
        value: 'banLoanVehicle',
        flex: 1
    },
    {
        name: 'Digital Driving License',
        value: 'isDLScan',
        flex: 1
    },
    {
        name: 'GDPR Deleted',
        value: 'isGDPR',
        flex: 1
    },
    {
        name: 'Campaign',
        value: 'campaign',
        flex: 1,
        sortValue: 'campaign.name'
    },
    {
        name: 'Origin',
        value: 'origin',
        flex: 1,
        sortValue: 'origin.name'
    },
    {
        name: 'Company',
        value: 'company',
        flex: 1
    },
    {
        name: 'Designation',
        value: 'designation',
        flex: 1
    },
    {
        name: 'Date Of Birth',
        value: 'dob',
        flex: 1,
        sortValue: 'dob',
    },
    {
        name: 'Gender',
        value: 'gender',
        flex: 1,
        sortValue: 'gender.name'
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        flex: 1
    },
    {
        name: 'Nationality',
        value: 'nationality',
        flex: 1,
        sortValue: 'nationality'
    },
    {
        name: 'Language',
        value: 'language',
        flex: 1
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1,
        sortValue: 'contactType'
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        flex: 1
    },
    {
        name: 'Hobbies',
        value: 'interests',
        flex: 1
    },
    {
        name: 'Dependents',
        value: 'dependents',
        flex: 1
    },
    {
        name: 'Driver Name',
        value: 'driverName',
        flex: 1
    },
    {
        name: 'Secondary Name',
        value: 'secondaryName',
        flex: 1
    },
    {
        name: 'Secondary Phone',
        value: 'secondaryPhone',
        flex: 1
    },
    {
        name: 'Secondary Email',
        value: 'secondaryEmail',
        flex: 1
    },
    {
        name: 'Secondary Date Of Birth',
        value: 'secondaryDob',
        flex: 1,
        sortValue: 'secondaryDob',
    },
    {
        name: 'Secondary Gender',
        value: 'secondaryGender',
        flex: 1,
        sortValue: 'secondaryGender.name'
    },
    {
        name: 'Phone Opt In',
        value: 'optinPhone',
        flex: 1,
        sortValue: 'optinPhone'
    },
    {
        name: 'Email Opt In',
        value: 'optinEmail',
        flex: 1,
        sortValue: 'optinEmail'
    },
    {
        name: 'Post Opt In',
        value: 'optinPost',
        flex: 1,
        sortValue: 'optinPost'
    },
    {
        name: 'SMS Opt In',
        value: 'optinSMS',
        flex: 1,
        sortValue: 'optinSMS'
    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmail',
        flex: 1,
        sortValue: 'marketingOptinEmail'
    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMS',
        flex: 1,
        sortValue: 'marketingOptinSMS'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const frequencyOptions = [
    { active: true, value: 'daily', label: 'Daily' },
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' }
]

export const dataOptions = [
    { active: true, value: 'today', label: `Today's Data` },
    { active: true, value: 'wtd', label: 'Last 7 Days Data' },
    { active: true, value: 'mtd', label: 'Current Month Data' }
]
