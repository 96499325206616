/** LIBRARIES */
import React, { PureComponent } from "react";
import CommonHelper from '../../services/common';
import { default as _images } from "../../images";

import ActiveInfo from './activeInfo'
import InActiveInfo from './inactiveInfo'

export default class ActiveView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    handleTop3User = () => {
        const { users, clientUsers, dealersettings } = this.props;
        //console.log(users, clientUsers, activeQueueTab, activeTab)
        return (users && users.map((userid, index) => {
            //console.log(index, userid)
            if (index < 3) {
                var user = clientUsers.filter(item => item.id === userid).length > 0 ? clientUsers.filter(item => item.id === userid)[0] : null;
                if (user) {
                    return (
                        <div key={index} className="col-lg-4">
                            <ActiveInfo
                                key={index}
                                index={index}
                                user={user}
                                userid={userid}
                                text={'Disable Queue'}
                                isQueueActive={true}
                                handleShow={this.props.handleShow}
                                handleUserQueue={this.props.handleUserQueue}
                                frontdeskQueueActivation={this.props.frontdeskQueueActivation}
                                dealersettings={dealersettings}
                            ></ActiveInfo>
                        </div>

                    )
                }
            }
        })
        )
    }

    handleOtherUser = () => {
        const { users, clientUsers, dealersettings } = this.props;
        //console.log(users, users.length, clientUsers, activeQueueTab, activeTab)
        return (users && users.length > 3 && users.slice(3).map((userid, index) => {
            //console.log(index, userid)
            var user = clientUsers.filter(item => item.id === userid).length > 0 ? clientUsers.filter(item => item.id === userid)[0] : null;
            if (user) {
                return (
                    <div key={index + 4} className="col-lg-4 mb-3" onClick={(e) => { e.preventDefault(); this.props.handleShow(userid) }}>
                        <InActiveInfo
                            key={index}
                            index={index}
                            user={user}
                            userid={userid}
                            text={'Disable Queue'}
                            isQueueActive={true}
                            showQueueNumber={true}
                            handleShow={this.props.handleShow}
                            handleUserQueue={this.props.handleUserQueue}
                            frontdeskQueueActivation={this.props.frontdeskQueueActivation}
                            dealersettings={dealersettings}
                        ></InActiveInfo>
                    </div>
                )
            }

        })
        )
    }


    render() {
        const { users } = this.props;

        //let user = (clientUsers.filter(e => e.id === id).length > 0 ? clientUsers.filter(m => m.id === id)[0] : null)
        return (
            <>
                <div className="frontdesk-queue-list mt-3 mb-3">
                    <div className=" row queue-grid">
                        {
                            this.handleTop3User()
                        }
                    </div>
                </div>

                {
                    users && users.length > 3
                        ?
                        <>
                            <div className="frontdesk-queue-table">
                                <div className="frontdesk-queue-table-scroll">
                                    <div className=" row queue-grid">
                                        {
                                            this.handleOtherUser()
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                        </>
                }

            </>

        );
    }
}

