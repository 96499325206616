import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import common from '../../../services/common';
import moment from 'moment';
import SchedulerLink from '../../settings/workflow/tvDevices/tvSchedulerLink';
import ActivityLink from '../../settings/workflow/tvDevices/tvActivityLink';

const CustomerDelivery = props => {
	const [data, setData] = useState();
	const [scheduleData, setScheduleData] = useState();
	const [loader, setLoader] = useState(true);
	const [localDate, setLocalDate] = useState(moment().format('DD-MM-YYYY'));
	const [videoURL, setVideoURL] = useState();
	const [embedURL, setEmbedURL] = useState();
	const [tvschedulerURL, setTVschedulerURL] = useState();
	const [tvAactivityURL, setTVactivityURL] = useState();
	const [deviceData, setDeviceData] = useState();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const _oemid = params.get('cid');
	const inIframe = (window === window.parent) ? false : true;
	const [displayHeight, setDisplayHeight] = useState('768');
	const [refreshTime, setRefreshTime] = useState(moment());
	const [styles, setStyles] = useState({
		bgWrap: {
			width: '100%',
			position: 'absolute',
			left: 0,
			top: 0,
		},
		divOverlay: {

		},
		divDrag: {
			width: '100%',
			height: '100%',
			position: 'relative',
			left: 0,
			top: 0,
			zIndex: 99
		}
	});

	useEffect(() => {
		if (_.isEmpty(scheduleData))
			return;
		const getData = async () => {
			let enq = scheduleData.enquiry;
			const ref = window.firebase
				.firestore()
				.collectionGroup(`templates`)
				.where('documentID', '==', scheduleData.templateID)
				.limit(1);
			const snapshot = await ref.get();
			if (scheduleData.enquiryID) {
				let firestoreDB = window.firebase;
				if (scheduleData.projectId === window.secondaryProjectId) {
					firestoreDB = window.firebase2;
				}
				const enqRef = firestoreDB.firestore().doc(`enquiries/${scheduleData.enquiryID}`);
				const enqSnapshot = await enqRef.get();
				if (enqSnapshot.data()) {
					enq = enqSnapshot.data();
				}

				let _contactid = enq.contactID ? enq.contactID : enq.contact?.documentID ? enq.contact.documentID : null;
				if (_contactid) {
					const contRef = firestoreDB.firestore().doc(`contactDetails/${_contactid}`);
					const contSnapshot = await contRef.get();
					if (contSnapshot.data()) {
						let _contact = contSnapshot.data();
						if (_.trim(_contact?.tvDisplayName)) {
							_contact.firstName = _contact.tvDisplayName;
							_contact.middleName = ''
							_contact.lastName = ''

						}
						enq.contact = _contact;
					}
				}
			}
			const refDevice = await window.firebase.firestore().doc(`tvDevices/${props.id}`).get();
			let _deivceData = snapshot.size > 0 ? snapshot.docs[0].data() : { items: [] };
			if (_deivceData.displayResolution) {
				setDisplayHeight(_deivceData.displayResolution.split('x')[1])
			}
			if (_deivceData.items && _deivceData.items.length > 0) {
				_deivceData = {
					..._deivceData,
					items: _deivceData.items.map(rec => {
						return rec.type === 'text' ? {
							...rec,
							text: common.mapTemplate(rec.text, (enq && enq.contact ? enq.contact : null), (enq ? enq : null), null, [], null, null, scheduleData, (refDevice.exists ? refDevice.data() : null))
						} : { ...rec }
					})
				}
				setData(_deivceData);
				setLoader(false);
			} else {
				defaultTemplate();
			}
		}
		getData();
	}, [(scheduleData ? scheduleData.templateID : false), (scheduleData ? scheduleData.enquiryID : false), (scheduleData?.modifiedDate?.seconds || false)])

	useEffect(() => {
		const refScheduleData = window.firebase.firestore().collection('tvScheduler')
			.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day').toDate()))
			.where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day').toDate()))
			.where('oemID', '==', _oemid)
			.where('isDeleted', '==', false)
			.onSnapshot(querySnapshot => {
				let _list = []
				querySnapshot.forEach(function (doc) {
					if (doc.data().deviceID === props.id)
						_list.push({
							...doc.data(),
							documentID: doc.id
						})
				});
				checkData(_list);
				if (window.tvSchedulerTimeout) {
					clearInterval(window.tvSchedulerTimeout);
				}
				window.tvSchedulerTimeout = setInterval(() => {
					setLocalDate(moment().format('DD-MM-YYYY HH:mm'));
					checkData(_list);
				}, 60000);
			});
		return () => {
			window.tvSchedulerTimeout && clearInterval(window.tvSchedulerTimeout);
			refScheduleData && refScheduleData();
		}
	}, [localDate])

	const checkData = (list) => {
		const _schduleData = _.orderBy(list.filter(a => moment().unix() >= a.startDate.seconds && moment().unix() <= a.endDate.seconds), ['startDate.seconds'], ['desc']);
		if (_schduleData.length > 0) {
			window.unsubTvDevice && window.unsubTvDevice();
			setScheduleData(_schduleData[0]);
			setVideoURL(null);
			setEmbedURL(null);
			setTVschedulerURL(false);
			setTVactivityURL(false);
		}
		else {
			defaultTemplate()
		};
	}

	const defaultTemplate = () => {
		window.unsubTvDevice = window.firebase.firestore().doc(`tvDevices/${props.id}`)
			.onSnapshot(async doc => {
				setDeviceData(doc.data());
				if (doc.exists && doc.data().templateID) {
					const ref = window.firebase
						.firestore()
						.collectionGroup(`templates`)
						.where('documentID', '==', doc.data().templateID)
						.limit(1);
					const snapshot = await ref.get();
					let _deivceData = snapshot.size > 0 ? snapshot.docs[0].data() : { items: [] };
					if (_deivceData.items && _deivceData.items.length > 0) {
						_deivceData = {
							..._deivceData,
							items: _deivceData.items.map(rec => {
								return rec.type === 'text' ? {
									...rec,
									text: common.mapTemplate(rec.text, null, null, null, [], null, null, null, doc.data())
								} : { ...rec }
							})
						}
						setData(_deivceData);
						setLoader(false);
					} else {
						setData({
							items: []
						})
						setLoader(false);
					}
				}
				else if (doc.exists && doc?.data()?.showScheduler === true) {
					setVideoURL(null)
					setEmbedURL(null);
					if (doc?.data()?.slides?.length > 0) {
						setInterval(() => {
							if (moment().diff(refreshTime, 'minutes') >= 10) {
								window.location.reload();
							}
						}, 60000);
						setTVschedulerURL(false);
						setTVactivityURL(true);
					}
					else {
						setTVschedulerURL(true);
						setTVactivityURL(false);
					}
					setLoader(false);
				}
				else if (doc.exists && doc.data().videoURL) {
					setVideoURL(doc.data().videoURL)
					setEmbedURL(null);
					setTVschedulerURL(false);
					setTVactivityURL(false);
					setLoader(false);
				}
				else if (doc.exists && doc.data().embedURL) {
					setEmbedURL(doc.data().embedURL)
					setVideoURL(null);
					setTVschedulerURL(false);
					setTVactivityURL(false);
					setLoader(false);
				}
				else {
					setData({
						items: []
					})
					setLoader(false);
				}
			})
	}

	useEffect(() => {
		if (_.isEmpty(data))
			return;

		setStyles({
			...styles,
			bgWrap: {
				...styles.bgWrap,
				background: `${data.bgColor ? data.bgColor : ''} ${data.bgImage ? `url(${data.bgImage})` : ''} no-repeat center center / cover`,
				height: inIframe ? `${displayHeight}px` : '100vh',
			},
			divOverlay: data.opacity > 0 ? {
				display: 'block',
				backgroundColor: '#000',
				zIndex: 9,
				opacity: data.opacity / 100,
				height: inIframe ? `${displayHeight}px` : '100vh',
				left: 0,
				position: 'absolute',
				top: 0,
				width: '100vw'

			} : {}
		})

	}, [data])

	const calcXY = (val, xy) => {
		let _val = val;
		if (data.displayResolution && val >= 0) {
			const _width = data.displayResolution.split('x')[0];
			const _height = data.displayResolution.split('x')[1];
			if (xy === 'x') {
				_val = _val - (_width * (3 / 100));
			}
			else {
				_val = _val - (_height * (3 / 100));
			}
			// if (_val < 0) {
			// 	_val = 0
			// }
		}
		return _val;
	}


	return loader ? (
		<>
			<div className='loader-center-screen'>
				<div className='spinner-loader h-100 '>
					<div className='d-flex h-100 justify-content-center align-items-center'>
						<div role='status' className='spinner-border' style={{ color: '#4466F2' }}></div>
						<p className='text-muted mt-3'></p>
					</div>
				</div>
			</div>
		</>
	) : tvschedulerURL === true ? (
		<SchedulerLink oemID={_oemid} inIframe={inIframe} />
	) : tvAactivityURL === true ? (
		<ActivityLink {...deviceData} oemID={_oemid} inIframe={inIframe} />
	) : videoURL ? (
		<video autoPlay muted loop id="myVideo" style={{ minWidth: '100%', height: '100vh', position: 'fixed', objectFit: 'fill' }}>
			<source src={videoURL} />
		</video>
	) : embedURL ? (
		<iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${embedURL}?autoplay=1&controls=0&mute=1&loop=1&playlist=${embedURL}`} frameBorder="0" allowFullScreen></iframe>
	) : data ? (<>
		<div className='tv-main h-100'>
			<div style={styles.divDrag}>
				{
					data.items.map((item, index) => {
						return <div key={index} style={{ position: 'absolute', left: `${calcXY(item.defaultPos.x, 'x')}px`, top: `${calcXY(item.defaultPos.y, 'y')}px` }}>
							{
								item.type === 'image' ? (<img src={item.src} height={item.height} width={item.width} />) :
									item.type === 'line' ? (<hr style={{ borderTop: `${item.thickness}px solid ${item.color}`, width: `${item.width}px` }} />) : (<div style={{ margin: 0 }}>
										<div dangerouslySetInnerHTML={{ __html: item.text }} />
									</div>)
							}
						</div>
					})
				}
			</div>
			<div style={styles.divOverlay}>

			</div>
			<div style={styles.bgWrap}>
			</div>
		</div>
	</>
	) : (<></>);
};

export default CustomerDelivery;
