/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import CommonHelper from "../../services/common";

const ActivityView = React.memo(({ title, activityDate, id, type, addedBy, handleEditactivity, handleComplete, completed, activitytag, handleDeleteactivity, activitylog, dealersettings }) => {
    // const [taskDone,setTaskdone] = useState(false) 
    // useEffect(()=>{
    //    setTaskdone(activitylog.isDone)
    // },[id])
    return (
        (!_.isEmpty(dealersettings))
            ?
            <>
                <div className="dashboard-activity-loop" id={`activity-${id}`}>
                    <Dropdown className="dashboard-activity-more" alignRight>
                        <Dropdown.Toggle as={CustomToggle} className="dropdown">
                            <i className="ico icon-more"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">

                            {
                                ((!_.isEmpty(activitylog.searchUsers) && activitylog.searchUsers.indexOf(dealersettings.id) >= 0) || (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.modifyOtherActivity))
                                    ?
                                    <Dropdown.Item eventKey="1" onClick={(e) => { handleEditactivity(e, activitylog) }}><i className="ico icon-edit"></i> Edit </Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="1" onClick={(e) => { handleEditactivity(e, activitylog) }}><i className="ico icon-zoom"></i> View </Dropdown.Item>
                            }
                            {
                                (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.deleteActivity)
                                    ?
                                    <Dropdown.Item eventKey="2" onClick={(e) => { handleDeleteactivity(e, activitylog) }}><i className="ico icon-delete"></i> Delete </Dropdown.Item>
                                    :
                                    <></>
                            }

                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="dashboard-activity-checkbox">
                        {
                            !activitylog.isDone ? (<div className="checkbox icheck-success">
                                {
                                    ((!_.isEmpty(activitylog.searchUsers) && activitylog.searchUsers.indexOf(dealersettings.id) >= 0) ||
                                        (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.modifyOtherActivity))
                                        ?
                                        <input type="checkbox"
                                            id={`success-${id}`}
                                            className="uncheck-activity"
                                            title={completed ? 'mark as not done' : 'mark as done'}
                                            checked={activitylog.isDone}
                                            onChange={(e) => {
                                                // setTaskdone(e.target.checked)
                                                handleComplete(e, activitylog, activitytag)
                                                // if (e.target.checked) {
                                                //     handleComplete(e, activitylog)
                                                // }
                                            }} />
                                        :
                                        <input type="checkbox"
                                            id={`success-${id}`}
                                            disabled={true}
                                        />

                                }

                                <label htmlFor={`success-${id}`}></label>
                            </div>) : (<></>)
                        }


                    </div>
                    <div className="dashboard-activity-list">
                        <div className="dashboard-activity-title">
                            <div className="dashboard-activity-icon">
                                <i className={`ico ${CommonHelper.activityIconByValue(dealersettings, type)}`}></i>
                            </div>
                            <a href="#" onClick={(e) => { e.preventDefault(); handleEditactivity(e, activitylog) }}>{title}</a>
                        </div>
                        {
                            activitylog && !_.isEmpty(activitylog.contact) ? (<>
                                <div className="dashboard-activity-user">
                                    {CommonHelper.displayContactIcon(activitylog.contact, '')} {`${CommonHelper.displayContact([], activitylog.contact, '--')}`}
                                    <span className='activity-details-preferred'>{CommonHelper.displayPreferredName(activitylog.contact, '')}</span>
                                </div>
                                {
                                    activitylog.contact.clientID && (activitylog.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (<span className="tbl-sub-dealer">{CommonHelper.getOtherDealerName(dealersettings, activitylog.contact.clientID)}</span>) : (<></>)
                                }
                            </>) : (<></>)
                        }
                        {
                            _.isEmpty(activitylog?.enquiry?.requirement) ? (<></>) :
                                !_.isEmpty(activitylog?.enquiry?.requirement?.stock?.make) ?
                                    <span>
                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                        {(activitylog.enquiry.requirement.stock.make ? activitylog.enquiry.requirement.stock.make : '') + ' ' + (activitylog.enquiry.requirement.stock.model ? activitylog.enquiry.requirement.stock.model : '')}
                                        {activitylog.enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                            {CommonHelper.getNameByValue(dealersettings?.client?.settings?.salesType,
                                                activitylog.enquiry.requirement.saleType, '')}
                                        </div>) : ''}

                                    </span>
                                    :
                                    <span>
                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                        {(activitylog.enquiry.requirement.make ? activitylog.enquiry.requirement.make : '') + ' ' + (activitylog.enquiry.requirement.model ? activitylog.enquiry.requirement.model : '')}
                                        {activitylog.enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                            {CommonHelper.getNameByValue(dealersettings?.client?.settings?.salesType,
                                                activitylog.enquiry.requirement.saleType, '')}
                                        </div>) : ''}

                                    </span>
                        }

                        <div className="dashboard-activity-date w-100 d-inline-block">

                            <span className={`${activitytag === 'Overdue' ? 'activity-due' : activitytag === 'Today' ? 'activity-upcoming' : ''}`}>
                                {
                                    activitytag === 'Overdue'
                                        ? (moment().diff(moment.unix(activityDate.seconds), 'days') === 1 ? '1 day ago' : moment().to(moment.unix(activityDate.seconds)))
                                        : moment.unix(activityDate.seconds).format('DD/MM/YYYY h:mm A')
                                }
                            </span> - {addedBy}</div>

                    </div>
                </div>
            </>
            :
            <></>

    )
})
export default ActivityView;

