/** LIBRARIES */
import React, { useEffect, Suspense } from 'react';
import _ from 'lodash'
/** PROVIDERS */
/** COMPONENTS */

import MainContainer from './customercontainer';


const CustomerApprovalLayout = (props) => {



    useEffect(() => {
        if (window.location.pathname.indexOf('/serviceinvoice/preview/') >= 0 || window.location.pathname.indexOf('/service/history/') >= 0) {
            document.getElementsByTagName('body')[0].classList.add('invoice-page-landing');
        }
        else {
            document.getElementsByTagName('body')[0].classList.add('customer-page-landing');
        }
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
        }
    }, [])


    return (
        <Suspense fallback={<></>}>
            <>
                <MainContainer history={props.history} />
            </>
        </Suspense>
    );
}

export default CustomerApprovalLayout;