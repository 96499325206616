/** LIBRARIES */
import React, { Component } from 'react';
import { Accordion, Card, Dropdown, Modal } from 'react-bootstrap';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
import { Tooltip } from 'antd';
/** PROVIDERS */
/** COMPONENTS */
import {
	PopUpModal,
	ReactSelect,
	LoaderOverlay,
	SidePanel,
	ContentOverlay
} from '../../../components';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';

import PageNotFound from '../../../components/pagenotFound';
import Requirements from '../../requirement/requirementInfo';
import TestDriveInfo from './testDriveInfo';
import Activity from './activity'
import InfoInputs from './infoInputs'
/** VIEW-MODELS */
import {
	objContact,
} from '../../contacts/viewModel';
import { objAttendees } from '../../eventsPro/viewModel';
import { firestoreDB } from '../../../services/helper';
import { eventStatus } from '../../../services/enum';

import AddAttendee from '../../eventsPro/attendees/add'
import axios from 'axios';
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
let titles = [];
let languages = [];
let license_state = [];
export default class CompanyInfo extends Component {
	// _isMounted = false;
	constructor(props) {
		super(props);
		this.refEventsPro = firestoreDB(this.props.dealersettings, true).firestore().collection('eventsPro');
		this.refAttendee = firestoreDB(this.props.dealersettings, true).firestore().collection('eventsProAttendees');
		this.newID = this.refAttendee.doc().id;
		this.unsubscribeEvents = null;
		this.unsubscribeAttendee = null;
		this.state = {
			loading: false,
			activeLeftKey: '10',
			activeRightKey: '02',
			activeCntKey: '0',
			activeVehKey: '1',
			activeTipKey: '2',
			fields: Object.assign({}, objAttendees),
			currentEvent: Object.assign({}),
			events: Object.assign([]),
			errors: {},
			attendeeID: null,
			clientUsers: [],
			pagenotFound: false,
			showNotes: false,
			activeTab: 'notes',
			eventSettings: Object.assign({}),
			contactModal: { show: false, id: null, clsActive: '' },
			edmLoader: {}
		};
	}


	//#region CONTACT CHANGES

	convertAttendeeVM = (doc) => {
		const { dealersettings } = this.props;
		const { eventSettings } = this.state;
		doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
		doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

		const attendee = Object.assign({}, objAttendees);
		for (let [key, value] of Object.entries(Object.assign({}, doc))) {
			attendee[key] = value;
		}
		attendee.fullName = CommonHelper.getFullName(titles, attendee);

		if (attendee.clientID && !attendee.clientName)
			attendee.clientName = CommonHelper.getOtherDealerName(dealersettings, attendee.clientID);

		if (attendee.toClientID && !attendee.toClientName)
			attendee.toClientName = CommonHelper.getOtherDealerName(dealersettings, attendee.toClientID);

		attendee.gender = CommonHelper.getNameByValue(dealersettings?.client?.settings?.genders, attendee.gender, '');
		attendee.licenseType = CommonHelper.getNameByValue(dealersettings?.client?.settings?.licenseType, attendee.licenseType, '');
		attendee.source = CommonHelper.getNameByValue(eventSettings?.source, attendee.source, '');
		attendee.dietaryPreference = CommonHelper.getNameByValue(eventSettings?.dietary, attendee.dietaryPreference, attendee.dietaryPreference);
		attendee.eventSlot = CommonHelper.getNameByValue(eventSettings?.slots, attendee.eventSlot, attendee.eventSlot);
		attendee.state = CommonHelper.getNameByCode(dealersettings.states.filter(a => attendee?.country ? a.country_code === attendee.country : true), attendee.state, '');
		attendee.country = CommonHelper.getNameByCode(dealersettings.countries, attendee.country, '');

		const enquiryAllStatus = Object.assign([], dealersettings?.client?.settings?.enquiryStatus);
		attendee.leadStatusName = CommonHelper.getNameByValue(enquiryAllStatus, doc.leadStatus, '');
		let _objCurrentStatus = doc.leadStatus && enquiryAllStatus.filter(e => e.value === doc.leadStatus)[0];
		attendee.leadStatus = !_.isEmpty(doc.leadStatus) ?
			(
				_objCurrentStatus
					?
					<>
						<span key={doc.leadStatus}
							className={`status-${doc.leadStatus === 'open' ? 'open' : 'empty'}`}
							style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
						>
							{_objCurrentStatus.name}
						</span>
					</>
					:
					<>
					</>
			) : ('');
		attendee.statusName = doc.status;
		attendee.status = (attendee.status
			?
			<div key={attendee.status} className={`badge badge-pill badge-events-${attendee.status}`}>
				{attendee.status}
			</div>
			:
			<></>);

		return attendee
	}

	//#endregion

	//#region LOAD PAGE

	onAttendeeCollectionUpdate = docSnapshot => {
		if (!docSnapshot.exists) {
			return;
		}
		const attendee = this.convertAttendeeVM(docSnapshot.data());
		attendee.documentID = docSnapshot.id;

		let _companyBasicVM = Object.assign({}, objAttendees);
		_companyBasicVM.documentID = docSnapshot.id;
		for (let [key, value] of Object.entries(docSnapshot.data())) {
			if (_companyBasicVM.hasOwnProperty(key)) _companyBasicVM[key] = value;
		}

		attendee.fullName = attendee.name

		let dealersettings = this.props.dealersettings;
		if (attendee.clientID && dealersettings && dealersettings.group &&
			dealersettings.group.clients && dealersettings.group.clients[attendee.clientID] && dealersettings.group.clients[attendee.clientID].settings) {
			const _setttings = Object.assign({}, dealersettings.group.clients[attendee.clientID].settings);
			attendee.type = CommonHelper.getNameByValue(_setttings.companyType, attendee.type, '');
		}
		else {
			const _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
			attendee.type = CommonHelper.getNameByValue(_setttings.companyType, attendee.type, '');
		}

		if (attendee.clientID && !attendee.clientName)
			attendee.clientName = CommonHelper.getOtherDealerName(dealersettings, attendee.clientID);

		if (attendee.toClientID && !attendee.toClientName)
			attendee.toClientName = CommonHelper.getOtherDealerName(dealersettings, attendee.toClientID);

		this.setState({
			attendeeID: docSnapshot.id,
			fields: attendee,
			companyBasicVM: _companyBasicVM,
		});
	};


	onEventsCollectionUpdate = querySnapshot => {
		const events = [];
		const { fields } = this.state;
		querySnapshot.forEach(data => {
			const event = Object.assign({}, data.data());
			event.documentID = data.id;
			let dealersettings = this.props.dealersettings;
			if (dealersettings && event.clientID) {
				event.dealerName = event.clientName ? event.clientName : CommonHelper.getOtherDealerName(dealersettings, event.clientID);
			}
			events.push(event);
		});

		this.setState({
			events: _.orderBy(events, ["modifiedDate"], ["desc"]),
			currentEvent: Object.assign({}, (fields.eventsProID ? _.find(events, { documentID: fields.eventsProID }) : events[0]))
		});
	}


	async loadEnquiry() {
		const { attendeeid, dealersettings, clientUsers, groupUsers } = this.props;
		//document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		//document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		if (attendeeid && dealersettings) {
			const refData = await this.refAttendee.doc(attendeeid).get();

			if (refData.exists) {
				//console.log('E              X             I                S                  T             S', contactid)
				let _clientUsers = [];
				const attendee = this.convertAttendeeVM(refData.data());
				attendee.documentID = refData.id;

				this.unsubscribeAttendee = this.refAttendee
					.doc(attendeeid)
					.onSnapshot(this.onAttendeeCollectionUpdate);

				let eventSettings;
				if (attendee.eventsProID) {
					this.unsubscribeEvents = this.refEventsPro
						.where('documentID', '==', attendee.eventsProID)
						.where('isDeleted', '==', false)
						.onSnapshot(this.onEventsCollectionUpdate);
					if (this.props.eventSettings) {
						eventSettings = this.props.eventSettings
					} else {
						eventSettings = await this.loadEventSettings(attendee.eventsProID)
					}
				}
				attendee.source = CommonHelper.getNameByValue(eventSettings?.source, attendee.source, '');
				attendee.dietaryPreference = CommonHelper.getNameByValue(eventSettings?.dietary, attendee.dietaryPreference, '');
				attendee.eventSlot = CommonHelper.getNameByValue(eventSettings?.slots, attendee.eventSlot, '');

				if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
					const client = dealersettings.client;
					const setttings = client.settings;
					let defaultCountry = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
					titles = [];
					setttings.titles && setttings.titles.forEach((doc) => {
						titles.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name
						});
					});

					languages = [];
					dealersettings.languages && dealersettings.languages.forEach((doc) => {
						languages.push({
							value: doc.code,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name
						});
					});

					license_state = [];
					defaultCountry && dealersettings.states &&
						dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
							license_state.push({
								value: doc.code,
								active: _.isBoolean(doc.active) ? doc.active : true,
								label: doc.name
							});

						});
				}

				let _allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
				if (attendee.clientID && !_.isEmpty(groupUsers)) {
					_clientUsers = groupUsers.filter(e => e.clientID === attendee.clientID);
				}
				else if (!_.isEmpty(_allUsers)) {
					_.uniq(_allUsers.map(v => _.pick(v, ['id']).id)).map(e => { return { ..._.find(_allUsers, { id: e }) } }).forEach((rec) => {
						_clientUsers.push({
							value: rec.id,
							active: _.isBoolean(rec.active) ? rec.active : true,
							searchlabel: rec.name,
							label: <div className="drop-image">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
								{rec.name}
							</div>
						});
					});
				}


				this.setState({
					attendeeID: refData.id,
					fields: attendee,
					clientUsers: _clientUsers,
					eventSettings: eventSettings,
				});
			}
			else {
				this.setState({
					pagenotFound: true,
					isLoading: false,
					attendeeID: null
				})
			}
		}
	}

	loadEventSettings = async (eventsProID) => {
		let _eventSettings = null;
		const refSettingsData = await firestoreDB(this.props.dealersettings, true).firestore().doc(`eventsPro/${eventsProID}/settings/${eventsProID}`).get();
		if (refSettingsData.exists)
			_eventSettings = Object.assign({}, refSettingsData.data());


		return _eventSettings;
	}

	componentDidMount() {
		localStorage.removeItem("objSales");
		this._isMounted = true;
		if (this._isMounted) {
			this.loadEnquiry();
			setTimeout(() => this.scrollIntoComments(), 4000);
		}
	}

	componentDidUpdate() {
		if (this.state.loading) {
			this.unsubscribeEvents && this.unsubscribeEvents();
			this.unsubscribeAttendee && this.unsubscribeAttendee();
			this.loadEnquiry();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.unsubscribeEvents && this.unsubscribeEvents();
		this.unsubscribeAttendee && this.unsubscribeAttendee();
		//document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
	}

	static getDerivedStateFromProps(props, state) {
		if (props.attendeeid !== state.attendeeID && state.attendeeID) {
			return {
				loading: true,
				attendeeID: null,
			};
		} else {
			return {
				loading: false,
			};
		}
		return null;
	}

	scrollIntoComments = () => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let __comments = params.get('comments');

		if (__comments && document.getElementById('enquiry-item-' + __comments)) {
			document.getElementById('enquiry-item-' + __comments).scrollIntoView({
				behavior: "smooth",
				block: "center"
			})
		}

	}
	//#endregion

	//#region CHANGE EVENT ENQUIRY

	handleLeftActiveKey = index => {
		this.setState({ activeLeftKey: index });
	};
	handleRightActiveKey = index => {
		this.setState({ activeRightKey: index });
	};

	handleCntActiveKey = (index) => {
		this.setState({ activeCntKey: index });
	}
	handleVehActiveKey = (index) => {
		this.setState({ activeVehKey: index });
	}
	handleTipActiveKey = (index) => {
		this.setState({ activeTipKey: index });
	}

	//#endregion

	//#region OWNED VEHICLE

	handleCompanyOpen = () => {
		this.setState({
			companyModal: {
				show: true,
				docID: this.props.attendeeid,
			}
		})
	}

	handleCompanyClose = () => {
		this.setState({
			companyModal: {
				show: false,
				docID: null,
			}
		})
	}

	updateCompany = (objAttendee) => {
		console.log(objAttendee)
	}

	//#endregion

	//#region DYNAMIC FIELDS

	handleDynamicCheckChange = (e) => {
		const { name, checked } = e.target;
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = checked;
		this.saveAttendeeField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(name), 500);
	}

	handleDynamicOnChange = (e) => {
		const { name, value } = e.target;
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = value;
		state.dynamicFields = _dynamicFields;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}

	handleDynamicOnCapChange = (e) => {
		e.preventDefault()
		const { name, value } = e.target;
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);

		if (value)
			_dynamicFields[name] = _.trim(value.toUpperCase());
		else
			_dynamicFields[name] = null;

		state.dynamicFields = _dynamicFields;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}

	onDynamicNumberChange = (name, e) => {
		//{formattedValue: "$9,856", value: "9856", floatValue: 9856}

		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);

		_dynamicFields[name] = e.floatValue;
		state.dynamicFields = _dynamicFields;
		this.setState({ fields: state }, () => { this.errorChange(name) });
	}

	handleDynamicSelectSave = (e, data) => {

		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[data.name] = e.value;
		this.saveAttendeeField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(data.name), 500);
	};

	handleDynamicMultiSelectChange = (selectedOptions, _data) => {
		const value = [];
		!_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
			value.push(data.value)
		})
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[_data.name] = value;
		this.saveAttendeeField('dynamicFields', _dynamicFields);
		this.setState({ fields: state });
	}

	handleDynamicDateChange = (val, name) => {

		if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = val;

		this.saveAttendeeField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(name), 500);
	};

	handleDynamicMonthChange = (val, name) => {
		if (val) val = moment(val).format('MMM YYYY');

		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = val;

		this.saveAttendeeField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(name), 500);
	};

	saveDynamicField = (name, value) => {
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = value;
		this.saveAttendeeField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(name), 500);
	}

	cancelDynamicField = (name, value) => {
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = value;
		state.dynamicFields = _dynamicFields;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}
	//#endregion

	//#region  SAVE TIP INFO


	cancelAttendeeField = (name, val) => {
		let state = Object.assign({}, this.state.fields);
		state[name] = val;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}

	saveAttendeeField = (name, val, noToast) => {

		let objData = {};
		let state = Object.assign({}, this.state.fields);

		const { attendeeid, dealersettings } = this.props;
		state.modifiedBy = dealersettings ? dealersettings.id : '';
		state.modifiedDate = window.firebase.firestore.Timestamp.now();
		state[name] = val;
		objData[name] = val;
		objData.modifiedBy = state.modifiedBy;
		objData.modifiedDate = state.modifiedDate;
		objData.modifiedFrom = 'web';

		this.setState({ fields: state });

		const updateRef = firestoreDB(dealersettings, true).firestore().collection('eventsProAttendees').doc(attendeeid);
		updateRef.set(objData, { merge: true }).then((docRef) => {

			if (!noToast)
				toast.notify('Attendee Info updated successfully.', { duration: 2000 });

		}).catch(error => {
			console.error('Error updating enquiries: ', error);
		});

	};

	errorChange = (key) => {
		let errors = Object.assign({}, this.state.errors);
		let fields = Object.assign({}, this.state.fields);
		let errorClass = 'input_error';
		if (!_.isEmpty(errors)) {
			if (!fields[key]) {
				errors[key] = errorClass;
			}
			else
				errors[key] = '';
			this.setState({ errors: errors });
		}
	};

	handleContactOpen = () => {
		const { attendeeid } = this.props;
		this.setState({
			contactModal: {
				show: true,
				id: attendeeid,
				clsActive: 'overlay-modal active',
			}
		})

	}

	handleContactClose = (ID) => {
		this.setState({
			contactModal: {
				show: false,
				id: null,
				clsActive: '',
			}
		})
	}

	//#endregion

	handleResendEdm = async (rec, _category) => {
		const result = await Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `Do you want to send ${_category.name} EDM?`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
		})
		if (!result.value) {
			return
		} else {
			this.setState({
				edmLoader: {
					[rec.category]: true
				}
			})
		}
		if (rec.status === "eventsProAttendeeStatus") {
			await this.refAttendee.doc(this.props.attendeeid).update({ [`category.${rec.category}`]: false, status: `${rec.eventsProAttendeeStatus}-` });
			await this.refAttendee.doc(this.props.attendeeid).update({ status: rec.eventsProAttendeeStatus });
			Swal.fire({
				icon: 'success',
				title: CommonHelper.showLocale(this.props, 'Email sent successfully.'),
				showConfirmButton: false,
				timer: 1500
			})
		} else {
			const url = `${this.props.dealersettings.apiURL}/triggers-runEventsProTask`
			let objUpdate = { [`category.${rec.category}`]: false }
			if (rec.eventsProAttendeeStatus) {
				objUpdate = {
					...objUpdate,
					status: rec.eventsProAttendeeStatus
				}
			}
			await this.refAttendee.doc(this.props.attendeeid).update(objUpdate);
			try {
				let reqBody = {
					triggerID: rec.documentID,
					projectId: this.state.fields.projectId
				};

				const headers = {
					"Content-Type": "application/json",
				};
				const resp = await axios.post(
					url,
					JSON.stringify(reqBody),
					{
						headers: headers,
					}
				);
				console.log("reqResponse", resp);
				Swal.fire({
					icon: 'success',
					title: CommonHelper.showLocale(this.props, 'Email sent successfully.'),
					showConfirmButton: false,
					timer: 1500
				})
			}
			catch (error) {
				console.log(error)
			}
		}
		this.setState({ edmLoader: {} })
	}

	render() {
		const { title, firstName, lastName, clientID, gender, address,
			licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL,
			phone, phoneCode, email, licenseState, licenseType,
			addedBy, addedDate, clientName, isDeleted, dataLocation,
			notes, requirements, testDrives, guests, guestOf,
			optinEmail, optinPhone, optinPost, optinSMS,
			dietaryPreference, eventSlot, leadStatus, statusName,
			checkinDate, isNew, source, preferredRetailer, ownedVehicle, category
		} = this.state.fields;

		const {
			activeLeftKey,
			activeRightKey,
			activeCntKey,
			activeVehKey,
			pagenotFound, contactModal,
			showNotes, activeTab, events, currentEvent,
			errors, eventSettings
		} = this.state;



		const { dealersettings, show, groupUsers, clientUsers, trigger } = this.props;
		const canModifyContact = isDeleted ? false : true;
		const eventsProStatus = dealersettings?.client?.eventsPro?.eventsProStatus;
		const dataLocations = dealersettings?.client?.eventsPro?.dataLocation;

		const currencySymbol = (!_.isEmpty(dealersettings?.client?.currency) ? dealersettings?.client.currency.symbol + ' ' : '$ ');
		let attendeesDF = Object.assign([], eventSettings?.attendeesDF)
		let dynamicDetailList = Object.assign([], (!_.isEmpty(attendeesDF) && !_.isEmpty(attendeesDF.filter(m => m.active === true)) ?
			Array.prototype.map.call(attendeesDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') :
			[]));
		const canModify = currentEvent?.status === eventStatus.CLOSED ? false : true;

		const modifyAccess = this.props.canModifyEvent;
		const _permissions = dealersettings?.rolePermissions?.eventsProPermissions;
		const addAttendeeAccess = ((!_.isEmpty(_permissions) && _permissions.addAttendee) ? true : false);

		return this.state.attendeeID ? (
			<>
				<Modal
					show={show}
					backdrop='static'
					backdropClassName={'overlay-modal active'}
					dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
				>
					<div className='modal-content modal-content-fullscreen'>
						<Modal.Header className='modal-header-fullscreen'>
							<div className='modal-title'>
								<Translate text={'attendeeDetails'} />

							</div>
							<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
								<button
									type='button'
									className='close close-fullscreen float-right'
									onClick={() => {
										this.props.handleClose();
									}}
								>
									×
								</button>
							</div>

						</Modal.Header>
						<Modal.Body className='modal-body-fullscreen pt-0'>

							<div className='popup-wrap-fullscreen h-100'>
								<div className='container-fluid '>
									<div className='middle-wrapper middle-wrapper-pipeline mt-0'>
										{/* <div className="filter-panel"><div className="float-left fleet-head"><h3><Translate text={'Company Details'} /></h3></div></div> */}
										<div className='enquiry-detailswrap mt-2'>
											<div className='enquiry-detail-head'>
												{/* <div className="float-left">
													<div className="contactdetails-userthumb-img ">
														{
															userImageURL
																?
																<a
																	data-fancybox={`user-img-box`}
																	href={userImageURL}
																	onClick={e => {
																		e.preventDefault();
																		this.handleSaveAuditLog(userImageURL);
																	}}
																>
																	<img
																		src={userImageURL}
																		alt=''
																		className="rounded-circle img-object-fit"
																	/>{' '}
																</a>
																:
																<img src={_images.nouser} alt="" className="rounded-circle img-object-fit" />
														}

													</div>

												</div> */}
												<div className='float-left enquiry-details-title-wrap' id={`attendeeID-${this.props.attendeeid}`}>
													<div className='enquiry-details-contact'>
														{CommonHelper.displayContactIcon(this.state.fields, 'mr-1')}
														{CommonHelper.displayContactName(titles, this.state.fields, '--')}
													</div>
												</div>

												<div className="enquiry-details-optin">
													{
														optinPhone === false
															?
															<span><Tooltip placement="bottom" title={<Translate text={'optoutPhone'} />}>
																<i className={`ico icon-no-call`}></i>
															</Tooltip></span>
															:
															<></>
													}
													{
														optinSMS === false
															?
															<span><Tooltip placement="bottom" title={<Translate text={'optoutSMS'} />}>
																<i className={`ico icon-no-sms`}></i>
															</Tooltip></span>
															:
															<></>
													}
													{
														optinEmail === false
															?
															<span><Tooltip placement="bottom" title={<Translate text={'optoutEmail'} />}>
																<i className={`ico icon-no-email`}></i>
															</Tooltip></span>
															:
															<></>
													}
													{
														optinPost === false
															?
															<span><Tooltip placement="bottom" title={<Translate text={'optoutPost'} />}>
																<i className={`ico icon-no-post`}></i>
															</Tooltip></span>
															:
															<></>
													}

												</div>
												<div className='float-right'>
													<Dropdown className='enquiry-view-more'>
														<Dropdown.Toggle as={CustomToggle} className='common-button'>
															<i className='ico icon-more'></i>
														</Dropdown.Toggle>
														<Dropdown.Menu
															as={CustomMenu}
															ChildClass="more-dropdown dropsub-fix"
															xplacement="bottom-end"
														>
															<Dropdown.Item eventKey="1" onClick={(e) => {
																const url = `/attendee/report/consent/${this.props.attendeeid}?eid=${this.props?.eventsPro?.documentID}`;
																window.open(url, "_blank");
															}}><i className="ico icon-waiver"></i> <Translate text={'Customer Consent'} /> </Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
												{
													trigger?.length ? (
														<div className='float-right mt-2'>
															{
																trigger.filter(a => a.isActive).map(rec => {
																	const _category = _.find(dealersettings?.client?.eventsPro?.categories, { value: rec.category })
																	return <button key={_category.value} className='btn btn-primary ml-2'
																		onClick={(e) => {
																			e.preventDefault();
																			this.handleResendEdm(rec, _category);
																		}}>
																		{
																			this.state.edmLoader[_category.value] ?
																				(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
																				: (<><i className="ico icon-email mr-2"></i></>)
																		}
																		{_category.name}
																	</button>
																})
															}
														</div>
													) : (<></>)
												}

												<div className='enquiry-subtitle'>
													{guestOf ? (
														<div className='badge badge-pill badge-white ml-2'>
															{guestOf}
														</div>
													) : (
														<></>
													)}

													{
														checkinDate ?
															<div className="badge badge-pill badge-available-fill ml-2">CHECKED-IN</div>
															:
															<></>
													}

													{
														isNew ?
															<div className="badge badge-pill badge-white ml-2">NEW</div>
															:
															<></>
													}

													{/* {isDLScan ? (
														<div className="badge badge-pill badge-white ml-2">
															<img src={_images.meSent} alt="" width="14" className="mr-1" />
															{`Digital Driving Licence`}
														</div>
													) : (
														<></>
													)}

													{!_.isEmpty(terms) ? <div className="badge badge-pill badge-white ml-2">
														<img src={_images.meSent} alt="" width="14" className="mr-1" />
														{`Customer Consent ${terms.verbalConsent ? '(Verbal)' : (terms.consentType ? `(${terms.consentType})` : '')}`}
													</div> : <></>} */}

												</div>
											</div>
											<div className='enquiry-detail-bottom'>
												<div className='float-left mt-2'>
													<ul className='enquiry-details'>
														{/* <li> <i className="ico icon-enquiry"></i> {displayID ? displayID : (`CNT-` + this.state.contactID)}</li> */}

														{clientName ? (
															<li> <span className="blue-color"><i className="ico icon-group"></i>{clientName}</span></li>
														) : (
															<></>
														)}

														{/* {
										tags && tags.length > 0 ? (<li>
											{
												tags.map((r, i) => {
													return r.subType ? (
														<span key={i} className="lead-badge-border">
															<div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>
															<div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div>
														</span>
													) : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
												})
											}
										</li>) : (<></>)
									} */}
														<li>
															<i className='ico icon-call'></i>
															{phone ? CommonHelper.getFullPhone(phoneCode, phone) : '--'}
														</li>
														<li>
															<i className='ico icon-Mail'></i> {email ? email : '--'}
														</li>


														<li>
															<i className='ico icon-date'></i>{' '}
															<Translate text={'created'} /> -{' '}
															{addedDate
																? moment.unix(addedDate.seconds).format('DD MMM YYYY')
																: '--'}
														</li>
													</ul>
												</div>
												<div className='float-right'>

												</div>
											</div>
										</div>
										{
											!canModifyContact || isDeleted
												?
												<>
													<div className="enquiry-stagewrap">
														<div className="enquiry-stagewrap-head">
															<div className="float-left">
																{
																	!canModifyContact ? (
																		<div className="pipeline-enquiry-status ml-2">
																			<div className="badge badge-pill badge-readonly">
																				<i className="ico icon-lock mr-1"> </i> <Translate text={'readonly'} upperCase={true} />
																			</div>
																		</div>
																	) : (
																		<></>
																	)
																}

																{
																	isDeleted ? (
																		<div className="pipeline-enquiry-status ml-2">
																			<div className="badge badge-pill badge-readonly">
																				<i className="ico icon-delete mr-1"> </i> <Translate text={'deleted'} upperCase={true} />
																			</div>
																		</div>
																	) : (
																		<></>
																	)
																}

															</div>
														</div>
													</div>
												</>
												:
												<>
												</>
										}
										<div className='clearfix'></div>

										<div className='enquirydetail-grid flex-column mt-4'>
											{/** LEFT TAB */}
											<aside className='small-column mr-3 '>
												<div className='enquiry-sidebar'>
													<Accordion defaultActiveKey='0'>
														{/** CONTACT INFO */}
														<Card>
															<Accordion.Toggle
																as={Card.Header}
																eventKey='0'
																onClick={e => {
																	e.preventDefault();

																	if (activeCntKey === '0')
																		this.handleCntActiveKey(null);
																	else this.handleCntActiveKey('0');
																}}
															>
																<div className='mb-0'>
																	<div className='cardlink'>
																		<a
																			onClick={e => {
																				e.preventDefault();
																			}}
																			className={`btn btn-link ${activeCntKey === '0' ? '' : 'collapsed'
																				}`}
																		>
																			<i className='ico icon-Contacts'></i>{' '}
																			<Translate text={'attendeeInfo'} />{' '}
																			<i className='arrow-collapse'></i>
																		</a>
																	</div>
																</div>
															</Accordion.Toggle>
															{
																modifyAccess && addAttendeeAccess && (!clientID || (clientID && _.find(dealersettings?.clients, { documentID: clientID })))
																	?
																	<div style={{ cursor: 'pointer' }} className='edit-button right-icon'>
																		<a
																			href='#'
																			className='mini-button'
																			onClick={e => {
																				e.preventDefault();
																				this.handleContactOpen();
																			}}
																		>
																			<i className='ico icon-edit'></i>
																		</a>
																	</div>
																	: <></>
															}

															<Accordion.Collapse eventKey='0'>
																<Card.Body>
																	<div className='info-table'>

																		<table className='table-fill'>
																			<tbody>
																				<tr>
																					<td
																						className='text-left label-text'
																						width='35%'
																					>
																						<Translate text={'licenseInfo'} />
																					</td>
																					<td className='text-left' width='65%'>
																						<div className='contact-detail-license'>
																							<div className='license-box'>
																								{licenseFrontURL ? (
																									<>

																										<a
																											data-fancybox={`license-box`}
																											href={licenseFrontURL}
																											onClick={e => {
																												e.preventDefault();
																												this.handleSaveAuditLog(licenseFrontURL);
																											}}
																										>
																											<img src={licenseFrontURL} alt='' className="img-object-fit" />{' '}
																										</a>

																									</>

																								) : (
																									<a
																										href='#'
																										onClick={e => {
																											e.preventDefault();
																										}}
																									>
																										<img src={_images.nolicense} alt='' />{' '}
																									</a>
																								)}
																							</div>
																							<div className='license-box'>
																								{licenseBackURL ? (
																									<>

																										<a
																											data-fancybox={`license-box`}
																											href={licenseBackURL}
																											onClick={e => {
																												e.preventDefault();
																												this.handleSaveAuditLog(licenseBackURL);
																											}}
																										>
																											<img src={licenseBackURL} alt='' className="img-object-fit" />{' '}
																										</a>

																									</>

																								) : (
																									<a
																										href='#'
																										onClick={e => {
																											e.preventDefault();
																										}}
																									>
																										<img src={_images.nolicense} alt='' />{' '}
																									</a>
																								)}
																							</div>
																						</div>
																					</td>
																				</tr>


																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'licenseNo'} />
																					</td>
																					<td className='text-left'>
																						{licenseNo ? licenseNo : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'licenseExpiry'} />
																					</td>
																					<td className='text-left'>
																						{licenseExpiry ? moment(licenseExpiry).format('DD MMM YYYY') : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'licenseState'} />
																					</td>
																					<td className='text-left'>
																						{licenseState ? CommonHelper.getLabelByValue(license_state, licenseState) : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'licenseType'} />
																					</td>
																					<td className='text-left'>
																						{licenseType ? licenseType : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'address'} />
																					</td>
																					<td className='text-left'>
																						{address ? address : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'dataLocation'} />
																					</td>
																					<td className='text-left'>
																						{dataLocation ? CommonHelper.getNameByValue(dataLocations, dataLocation) : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td
																						className='text-left label-text'
																						width='35%'
																					>
																						<Translate text={'gender'} />
																					</td>
																					<td className='text-left' width='65%'>
																						{gender ? gender : '--'}
																					</td>
																				</tr>

																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'optinPhone'} />
																					</td>
																					<td className='text-left'>
																						{optinPhone ? 'Yes' : 'No'}
																					</td>
																				</tr>

																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'optinSMS'} />
																					</td>
																					<td className='text-left'>
																						{optinSMS === null ? '--' : (optinSMS ? 'Yes' : 'No')}
																					</td>
																				</tr>

																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'optinEmail'} />
																					</td>
																					<td className='text-left'>
																						{optinEmail ? 'Yes' : 'No'}
																					</td>
																				</tr>

																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'optinPost'} />
																					</td>
																					<td className='text-left'>
																						{optinPost ? 'Yes' : 'No'}
																					</td>
																				</tr>
																				{
																					ownedVehicle ? (<tr>
																						<td className='text-left label-text'>
																							<Translate text={'Owned Vehicle'} />
																						</td>
																						<td className='text-left'>
																							{ownedVehicle?.make || ''} {ownedVehicle?.model || ''} {ownedVehicle?.year || ''}
																						</td>
																					</tr>) : (<></>)
																				}
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'dietaryPreference'} />
																					</td>
																					<td className='text-left'>
																						{dietaryPreference ? dietaryPreference : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'Preferred Retailer'} />
																					</td>
																					<td className='text-left'>
																						{preferredRetailer ? preferredRetailer : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'source'} />
																					</td>
																					<td className='text-left'>
																						{source ? source : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'eventSlot'} />
																					</td>
																					<td className='text-left'>
																						{eventSlot ? eventSlot : '--'}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left label-text'>
																						<Translate text={'leadStatus'} />
																					</td>
																					<td className='text-left'>
																						{leadStatus ? leadStatus : '--'}
																					</td>
																				</tr>
																			</tbody>
																		</table>

																	</div>
																</Card.Body>
															</Accordion.Collapse>
														</Card>
													</Accordion>
													{/** TEST DRIVE INFO */}
													<Accordion defaultActiveKey="10" >

														<Card>
															<Accordion.Toggle as={Card.Header} eventKey="10" onClick={(e) => {
																e.preventDefault()

																if (activeLeftKey === '10')
																	this.handleLeftActiveKey(null);
																else
																	this.handleLeftActiveKey('10');
															}}>
																<div className="mb-0">
																	{/* {isTestDrive ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>} */}
																	<div className="cardlink">
																		<a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeLeftKey === '10' ? '' : 'collapsed'}`}>
																			<i className={'ico icon-events-guest'}></i><Translate text={'guests'} />
																			{guests?.length > 0 ? <span className="badge badge-pill badge-cafe ml-1">{guests.length}</span> : <></>}
																			<i className="arrow-collapse"></i>
																		</a>
																	</div>
																</div>
															</Accordion.Toggle>

															<Accordion.Collapse eventKey="10">
																<Card.Body>
																	{(() => {
																		if (!_.isEmpty(guests)) {
																			return (
																				<>
																					{
																						guests.map((contact, index) => {
																							return <div key={index} className="vehicle-popover-loop pointer-none" >
																								<div className="divTable">
																									<div className="divTableBody">
																										<div className="divTableRow">
																											<div className="divTableCell width100">
																												<div className="customer-content">

																													<div className="customer-avatar-title">
																														{CommonHelper.displayContactName([], contact)}
																													</div>
																													{
																														contact.phone
																															?
																															<div className="customer-avatar-titlesub">
																																<strong>Phone: </strong> {contact.phone}
																															</div>
																															: <></>
																													}
																													{
																														contact.email
																															?
																															<div className="customer-avatar-titlesub">
																																<strong>Email: </strong>{contact.email}
																															</div>
																															: <></>
																													}
																												</div>
																											</div>

																										</div>
																									</div>
																								</div>
																							</div>

																						})
																					}
																				</>
																			)
																		}
																		else {
																			return (

																				<div className='text-center p-4'>
																					<p><Translate text={'noguestdata'} /></p>
																				</div>

																			);

																		}

																	})()}
																</Card.Body>
															</Accordion.Collapse>
														</Card>

													</Accordion>

												</div>
											</aside>

											{/** ACTIVITY TAB */}
											<Activity
												notes={notes}
												attendeeid={this.props.attendeeid}
												clientUsers={clientUsers}
												groupUsers={groupUsers}
												dealersettings={dealersettings}
												history={this.props.history}
											>
											</Activity>


											{/** RIGHT TAB */}
											<aside className='small-column ml-3'>
												<div className='enquiry-sidebar'>
													<Accordion defaultActiveKey='02'>
														{/** CONTACTS INFO */}
														<Card>
															<Accordion.Toggle
																as={Card.Header}
																eventKey='02'
																onClick={e => {
																	e.preventDefault();

																	if (activeRightKey === '02')
																		this.handleRightActiveKey(null);
																	else this.handleRightActiveKey('02');
																}}
															>
																<div className='mb-0'>
																	<div className="cardlink">
																		<a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeRightKey === '02' ? '' : 'collapsed'}`}>
																			<i className='ico icon-events-event'></i>{' '}
																			<Translate text={'currentEvent'} />
																			{/* {!_.isEmpty(events) ? <span className="badge badge-pill badge-cafe ml-1">{events.length}</span> : <></>} */}
																			<i className="arrow-collapse"></i>
																		</a></div>
																</div>
															</Accordion.Toggle>

															<Accordion.Collapse eventKey='02'>
																<Card.Body>
																	{(() => {
																		if (!_.isEmpty(currentEvent)) {
																			return (
																				<>
																					<div className='events-dashboard-livewrap mb-2' id={`eventID-${currentEvent.documentID}`} onClick={(e) => {
																						e.preventDefault();
																						//props.history.push("/eventspro/details/" + currentEvent.id);
																					}}>
																						<div className="events-dashboard-live-flex">
																							<div className="events-dashboard-live-image">
																								{
																									currentEvent.imageURL ? (
																										<img src={currentEvent.imageURL} alt="" width="70" height="70" />
																									) : (
																										<img src={_images.noEvent} alt="" width="70" height="70" />
																									)
																								}
																							</div>
																							<div className="events-dashboard-live-data">
																								<div className="events-dashboard-card-title">{currentEvent.title}</div>
																								<div className="events-dashboard-card-sub"><i className="ico icon-location"></i> {currentEvent.location} </div>
																								<div className="events-dashboard-card-sub">
																									<i className="ico icon-date"></i> {CommonHelper.showCombineDate(currentEvent.startDate, currentEvent.endDate, currentEvent.timezone)}
																								</div>
																								<div className="events-dashboard-card-sub">
																									<i className="ico icon-time"></i> {CommonHelper.showCombineTime(currentEvent.startDate, currentEvent.endDate, currentEvent.timezone)}
																								</div>
																								<div className="events-dashboard-card-sub justify-content-between">
																									{
																										currentEvent.status ? (
																											<>
																												{(() => {
																													let _objCurrentStatus = eventsProStatus.filter(e => e.value === currentEvent.status)[0];
																													return <>
																														{
																															_objCurrentStatus
																																?
																																<>
																																	<div
																																		className={`badge badge-pill badge-empty`}
																																		style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																																	>
																																		{_objCurrentStatus.name}
																																	</div>
																																</>
																																:
																																<><div className={`badge badge-pill badge-status-${currentEvent.status.toLowerCase()}`} >{currentEvent.status} </div></>
																														}
																													</>
																												})()}
																												{
																													statusName ?
																														<div className={`badge badge-pill badge-events-${statusName.toLowerCase()}`}>{statusName} </div>
																														:
																														<></>
																												}

																											</>
																										) : (<></>)
																									}
																								</div>
																							</div>
																						</div>
																					</div>
																				</>
																			)
																		}
																		else {
																			return (

																				<div className='text-center p-4'>
																					<p><Translate text={'No Events Found'} /></p>
																				</div>

																			);

																		}

																	})()}

																</Card.Body>
															</Accordion.Collapse>
														</Card>


													</Accordion>

													{/** VEHICLE INFO */}
													<Accordion defaultActiveKey="1" >
														<Card>
															<Accordion.Toggle as={Card.Header} eventKey="1" onClick={(e) => {
																e.preventDefault()

																if (activeVehKey === '1')
																	this.handleVehActiveKey(null);
																else
																	this.handleVehActiveKey('1');
															}}
															>
																<div className="mb-0">

																	<div className="cardlink">
																		<a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeVehKey === '1' ? '' : 'collapsed'}`}>
																			<i className={"ico icon-text-box-multiple"}></i><Translate text={'interests'} />

																			<i className="arrow-collapse"></i>
																		</a></div>
																</div>
															</Accordion.Toggle>
															<Accordion.Collapse eventKey="1">
																<Card.Body>

																	<div className={`info-table `}>

																		<table className="table-add add-form-style">
																			<tbody>
																				{
																					!_.isEmpty(dynamicDetailList)
																						?
																						<InfoInputs
																							fields={this.state.fields}
																							errors={this.state.errors}
																							currencySymbol={currencySymbol}
																							dealersettings={dealersettings}
																							canModify={canModify}
																							attendeesDF={attendeesDF}
																							handleDynamicCheckChange={this.handleDynamicCheckChange}
																							handleDynamicOnChange={this.handleDynamicOnChange}
																							saveDynamicField={this.saveDynamicField}
																							cancelDynamicField={this.cancelDynamicField}
																							onDynamicNumberChange={this.onDynamicNumberChange}
																							handleDynamicDateChange={this.handleDynamicDateChange}
																							handleDynamicMonthChange={this.handleDynamicMonthChange}
																							handleDynamicSelectSave={this.handleDynamicSelectSave}
																							handleDynamicMultiSelectChange={this.handleDynamicMultiSelectChange}

																						></InfoInputs>
																						:
																						<>
																							<tr>
																								<td colSpan={2}>
																									<div className='text-center p-4'>
																										<p><Translate text={'noInfoAvail'} /></p>
																									</div>
																								</td>
																							</tr>

																						</>


																				}


																			</tbody>
																		</table>
																	</div>
																</Card.Body>
															</Accordion.Collapse>
														</Card>
														<Card>
															<Accordion.Toggle as={Card.Header} eventKey="2" onClick={(e) => {
																e.preventDefault()

																if (activeVehKey === '2')
																	this.handleVehActiveKey(null);
																else
																	this.handleVehActiveKey('2');
															}}>
																<div className="mb-0">
																	{/* {isTestDrive ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>} */}
																	<div className="cardlink">
																		<a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${activeVehKey === '2' ? '' : 'collapsed'}`}>
																			<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, "ico icon-activity-testdrive")}></i><Translate text={'testDrive'} />
																			{testDrives?.length > 0 ? <span className="badge badge-pill badge-cafe ml-1">{testDrives.length}</span> : <></>}
																			<i className="arrow-collapse"></i>
																		</a>
																	</div>
																</div>
															</Accordion.Toggle>

															<Accordion.Collapse eventKey="2">
																<Card.Body>
																	{(() => {
																		if (!_.isEmpty(testDrives)) {
																			return (
																				<>
																					{
																						testDrives.map((_testdrive, index) => (
																							<TestDriveInfo
																								key={index}
																								testdrive={_testdrive}
																								stockData={_testdrive.stock}
																								dealersettings={dealersettings}
																								eventsProID={this.props?.eventsPro?.documentID}
																								attendeeid={this.props.attendeeid}
																							>
																							</TestDriveInfo>

																						))
																					}
																				</>
																			)
																		}
																		else {
																			return (

																				<div className='text-center p-4'>
																					<p><Translate text={'notestdrivedata'} /></p>
																				</div>

																			);

																		}

																	})()}
																</Card.Body>
															</Accordion.Collapse>
														</Card>
													</Accordion>




												</div>
											</aside>
										</div>

									</div>


								</div>
							</div>

						</Modal.Body>
					</div>
				</Modal>

				<PopUpModal show={contactModal.show}>
					<AddAttendee
						{...this.props}
						docID={contactModal.id}
						show={contactModal.show}
						clsActive={contactModal.clsActive}
						handleClose={this.handleContactClose}
						title={'attendee'}
						eventsProID={this.props?.eventsPro?.documentID}
						eventsPro={this.props.eventsPro}
						clientID={this.props.clientID}
					>
					</AddAttendee>
				</PopUpModal>


			</>
		) : pagenotFound ? (
			<PageNotFound />
		) : (
			<LoaderOverlay text='Fetching attendee details...' active={true} />
		);
	}
}
