import React from "react";

export const reportVM = {
    documentID: null,
    clientID: null,
    clientIDs: [],
    userIDs: [],
    name: '',
    pipeline: '',
    type: '',
    subType: '',
    calculationType: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
};

export const modelGroupTypes = [
    { active: true, value: 'make', label: 'By Make' },
    { active: true, value: 'model', label: 'By Model' },
    //{ active: true, value: 'type', label: 'By Type' },
    //{ active: true, value: 'group', label: 'By Group' }
]

export const reportOptions = [
    { active: true, value: 'walkintestdrive', label: 'Walk In to Test Drive' },
    { active: true, value: 'enquiryOptionsDF', label: 'KPIs' },
    { active: true, value: 'salesConversion', label: 'Sales Conversion' },
]

export const salesConversions = [
    { active: true, value: 'appointment', label: 'Appointment' },
    { active: true, value: 'call', label: 'Call' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'walkin', label: 'Walk Ins' },
]

export const calculateOptions = [
    { active: true, value: 'count', label: 'Count' },
    { active: true, value: 'duration', label: 'Duration' },
    { active: true, value: 'percentage', label: 'Percentage' }
]

export const datefilterType = [
    { active: true, value: 'daily', label: 'Daily' },
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' }
]

export const leadTypes = [
    { value: 'new', label: 'New Leads Only' },
    { value: 'existing', label: 'Existing Leads Only' }
]

export const reportGroupBy = [
    { label: 'User', value: 'user', displayName: 'User', displayValue: 'User', tableHeader: 'User' },
    { label: 'Model', value: 'model', displayName: 'Make', displayValue: 'make', tableHeader: 'Model' },
    { label: 'Lead Origins', value: 'origins', displayName: 'Lead Origins', displayValue: 'Lead Origins', tableHeader: 'Lead Origins' },
    { label: 'Lost Reasons', value: 'lostReasons', displayName: 'Lost Reasons', displayValue: 'Lost Reasons', tableHeader: 'Lost Reasons' },
    { label: 'Status', value: 'tradeinProStatus', displayName: 'Status', displayValue: 'status', tableHeader: 'Status' },
    { label: 'Date Filter', value: 'dateFilter', displayName: 'Date Filter', displayValue: 'date filter', tableHeader: 'Date' },
    { label: 'Inbound Source', value: 'inboundSource', displayName: 'Inbound Source', displayValue: 'inbound source', tableHeader: 'Name' },
]

export const filterOptions = [
    { label: 'Date', value: 'date', displayValue: 'date' },
    { label: 'Client', value: 'client', displayValue: 'client' },
    { label: 'Pipelines', value: 'pipelines', displayValue: 'Pipelines' },
    { label: 'User', value: 'user', displayValue: 'user' },
    { label: 'Inbound Source', value: 'inboundSource', displayValue: 'inbound source' },
    { label: 'Lead Origins', value: 'origins', displayValue: 'Lead Origins' },
    { label: 'Lost Reasons', value: 'lostReasons', displayValue: 'Lost Reasons' },
    { label: 'Status', value: 'tradeinProStatus', displayValue: 'status' },
    { label: 'Make and Models', value: 'makeModels', displayValue: 'make' },
    { label: 'Contact Area Code', value: 'areaCode', displayValue: 'area code' },
]

export const visibilities = [
    { active: true, value: 'private', label: <><i className="ico icon-reserve mr-1"></i> Private</> },
    { active: true, value: 'shared', label: <><i className="ico icon-edit-circle mr-1"></i> Shared - anyone can edit</> },
    { active: true, value: 'sharedView', label: <><i className="ico icon-activity-meeting mr-1"></i> Shared - only owner can edit</> },
]

export const reportDynColumns = [
    //end
    { label: 'Total Appraisal', value: 'totalAppraisal', name: 'Total Appraisal', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#4967CC' },
    //revenue
    { label: 'Total Price', value: 'dealerPrice', name: 'Total Price', view: ['table', 'column', 'pie', 'target'], calculationType: ['amount'], color: '#39B54A', currency: true },
]


export const frequencyOptions = [
    { active: true, value: 'daily', label: 'Daily' },
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' }
]

export const dataOptions = [
    { active: true, value: 'today', label: `Today's Data` },
    { active: true, value: 'wtd', label: 'Last 7 Days Data' },
    { active: true, value: 'mtd', label: 'Current Month Data' }
]

export const columnTypes = [
    { label: 'Activities', value: 'activities' },
    { label: 'Trigger Activities', value: 'triggers' },
    { label: 'Customer Logs', value: 'logs' },
    { label: 'Duration', value: 'duration' },
    { label: 'Performance', value: 'performance' }
]

export const dataQualityOptions = [
    { label: 'With Phone', value: 'phone' },
    { label: 'Without Phone', value: 'nophone' },
    { label: 'With Email', value: 'email' },
    { label: 'Without Email', value: 'noemail' },
    { label: 'With Nationality', value: 'nationality' },
    { label: 'Without Nationality', value: 'nonationality' },
    { label: 'With Vehicle Model', value: 'make' },
    { label: 'Without Vehicle Model', value: 'nomake' },
    { label: 'With Origin', value: 'origin' },
    { label: 'Without Origin', value: 'noorigin' },
    { label: 'With Enquiry Type', value: 'enquiryType' },
    { label: 'Without Enquiry Type', value: 'noenquiryType' },
    { label: 'With Campaign', value: 'campaign' },
    { label: 'Without Campaign', value: 'nocampaign' },
]
export const defaultFilters = {
    user: [],
    dmstype: [],
    make: '',
    model: [],
    pipelines: [],
    enquiryTypes: [],
    campaigns: [],
    origins: [],
    status: [],
    label: [],
    lostReasons: [],
    lostsubreason: [],
    salesType: [],
    vehicleSearchOn: '',
}

export const dealertargetDynCols = [
    { name: 'Target', value: 'target', color: '#f8cbad', borderColor: '#f19454', dummyData: { width: 20, height: 20 } },
    { name: 'Actual', value: 'actual', color: '#e7e6e6', borderColor: '#2f2f2f', dummyData: { width: 20, height: 20, } },
    { name: 'Target-Cummulative', value: 'cummulativeTarget', color: '#ed7d31', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
    { name: 'Actual-Cummulative', value: 'cummulativeActual', color: '#000000', dummyData: { width: 25, height: 7, top: 8, right: 20 } }
]

export const reportDynFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 2,
        default: true,
        fixed: true
    },
    { value: 'totalAppraisal', name: 'Total Appraisal', flex: 1, },
    { value: 'dealerPrice', name: 'Total Price', flex: 1, },

]