/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import moment from 'moment';
import { Modal } from "react-bootstrap";
/** COMPONENTS */
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { default as _images } from "../../../images";
import { PopoverListLoader, TableView, ContentOverlay, PopoverPanel } from '../../../components';
/** VIEW-MODELS */
import { stockStatus, stockAvail } from '../../../services/enum'
import { firestoreDB } from "../../../services/helper";
import { inboundTestDriveFields } from '../viewModel';


class InboundTestdrives extends Component {
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            inboundTestdrives: [],
            filter: {
                iSearch: '',
                isAvailable: false
            },
            loading: true,
            windowSize: { windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) },
            notesPopOver: { show: false }
        };
    }

    static applyFilter = (listings, filter) => {
        const { iSearch, isAvailable } = filter
        let result = listings

        if (_.trim(iSearch)) {
            result = result.filter(item => item.keywords?.includes(_.trim(iSearch).toLowerCase()))
        }

        return result
    }

    onCollectionUpdate = (querySnapshot) => {
        let list = [];
        querySnapshot.forEach((doc) => {
            const stock = Object.assign({}, doc.data());
            stock.documentID = doc.id;
            list.push(stock);
        });
        list = _.orderBy(list, ['addedDate'], ['desc'])
        this.setState({
            inboundTestdrives: list,
            loading: false
        });
    }

    componentDidMount() {
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        window.addEventListener('resize', this.handleResize);

        const { inboundTestdrives } = this.props;
        if (!_.isEmpty(inboundTestdrives)) {
            this.loadPreInbound(inboundTestdrives);
        } else {
            this.loadInbound();
        }
    }

    loadPreInbound = async (inboundTestdrives) => {
        let list = [];
        inboundTestdrives.forEach((doc) => {
            const inboundTestdrive = Object.assign({}, this.dataMappingVM(doc));
            list.push(inboundTestdrive);
        });

        this.setState({
            inboundTestdrives: _.orderBy(list, ['addedDate'], ['desc']),
            loading: false
        });

    }

    loadInbound = async () => {
        const { dealersettings, show } = this.props;
        if (show === true) {
            let list = [];
            firestoreDB(dealersettings).firestore().collection(`inboundTestdrive`)
                .where('clientID', '==', dealersettings.client.id)
                .where('status', '==', 0)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    response.docs.forEach((doc) => {
                        const inboundTestdrive = Object.assign({}, this.dataMappingVM(doc.data()));
                        inboundTestdrive.documentID = doc.id;
                        list.push(inboundTestdrive);
                    });

                    this.setState({
                        inboundTestdrives: _.orderBy(list, ['addedDate'], ['desc']),
                        loading: false
                    });
                });
        }
    }
    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        //document.documentElement.style.setProperty('--ddHeight', (window.innerHeight - 30) + 'px');       
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
    }

    handleChange = (prop, value) => {
        let state = this.state.filter;
        state[prop] = value;
        this.setState({ filter: state });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = this.state.filter;
        state[name] = checked;
        this.setState({ filter: state });
    }

    handleNull = (_data) => {
        return (_data || '')
    }

    dataMappingVM = (doc) => {


        const listVM = Object.assign({}, doc);
        listVM.inboundTestdrive = doc

        listVM.createdOn = listVM.addedDate ? moment.unix(listVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        listVM.updatedOn = listVM.modifiedDate ? moment.unix(listVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';

        if (!_.isEmpty(doc.testdrive)) {
            listVM.vehicleModel = (doc.testdrive.make ? doc.testdrive.make + ' ' + doc.testdrive.model : '')
            listVM.vehicleYear = !_.isEmpty(doc.testdrive.stock) ? doc.testdrive.stock.year : doc.testdrive.year;
            listVM.startDate = doc.testdrive.startDate ? moment.unix(doc.testdrive.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            listVM.endDate = doc.testdrive.endDate ? moment.unix(doc.testdrive.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        }
        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact(null, doc.contact);
            listVM.displayPhone = doc.contact.phone ? (doc.contact.phoneCode ? doc.contact.phoneCode : '') + doc.contact.phone : '';
            listVM.email = doc.contact.email;
        }
        return listVM
    }

    handleDetailsClick = (e, id, data, tblprops) => {
        e.preventDefault();
        if (tblprops.columnKey === "notes" && data.notes) {
            this.setState({ notesPopOver: { show: true, target: e.target, notes: data.notes } })
        }
    }

    handleActionClick = (id, inbound) => {
        return (

            <div className="fleet-btn-loan  reassign-fix" style={{ width: '100px', float: 'left' }}>
                <button type="submit" className="btn btn-sm btn-primary" onClick={(e) => {
                    e.preventDefault();
                    this.props.handleClose(inbound?.inboundTestdrive)
                }}>Accept</button>
            </div>
        )
    }

    render() {
        const { iSearch, isAvailable } = this.state.filter
        const { inboundTestdrives, filter, loading, windowSize, notesPopOver } = this.state
        const filteredList = InboundTestdrives.applyFilter(inboundTestdrives, filter)

        const { show, showAdd, fromActivity, clientUsers } = this.props;

        const divStyle = {
            height: '400px',
            overflow: 'auto'
        };
        return (
            <>
                <Modal
                    id="inbound_testdrive_list"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-dashboard-popup">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'Test Drive Request'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        <div className="container-fluid">
                            <div className="row">
                                <div className="settings-head">
                                    <div className="float-left">
                                        <div className={`filter-search`}>
                                            <div className="input-group ">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="itd_isearch"
                                                    aria-label="Search"
                                                    placeholder="quick search..."
                                                    value={iSearch}
                                                    autoComplete="off"
                                                    onChange={event =>
                                                        this.handleChange('iSearch', event.target.value)
                                                    }
                                                />
                                                <div className="input-group-append input-search-clear">
                                                    {
                                                        iSearch && iSearch.length > 0
                                                            ?
                                                            <a href="#"
                                                                className="input-search-clear-icon"
                                                                title="Clear"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.handleChange('iSearch', '')
                                                                }}
                                                            >
                                                                <i className="ico icon-remove"></i>
                                                            </a>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="common-table">
                                    {
                                        loading
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                    <TableView
                                        isReorderable={false}
                                        datalist={filteredList}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={inboundTestDriveFields}
                                        dynamicFields={inboundTestDriveFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={loading}
                                        handleActionClick={this.handleActionClick}
                                        handleRowSingleClick={this.handleDetailsClick}
                                        isSettings={false}
                                        //hasMore={hasMore}
                                        //handlePagination={handlePagination}
                                        isPaging={false}
                                    />

                                </div>



                            </div>
                        </div>


                        {/* <div className={`vehicle-popover-head pr-0`}>



                            <input
                                //className="form-control"
                                type="text"
                                id="isearch"
                                aria-label="Search"
                                placeholder="search..."
                                value={iSearch}
                                autoComplete="off"
                                onChange={event =>
                                    this.handleChange('iSearch', event.target.value)
                                }
                            />
                        </div>



                        <Suspense>
                            <div style={divStyle} >
                                {
                                    loading ? (<PopoverListLoader />) :
                                        filteredList.length > 0
                                            ?
                                            <>
                                                {filteredList.map(inbound =>
                                                    <div key={inbound.documentID} className="vehicle-popover-loop" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.handleClose(inbound)

                                                    }}>
                                                        <div className="divTable">
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell width100">
                                                                        <div className="customer-content">
                                                                            <div className="customer-avatar-title">
                                                                                {CommonHelper.displayContactIcon(inbound?.contact, 'mr-1')}
                                                                                {CommonHelper.displayContact([], inbound.contact, '--')}
                                                                            </div>
                                                                            {
                                                                                inbound?.contact.phone
                                                                                    ?
                                                                                    <>
                                                                                        <div className="customer-avatar-titlesub">
                                                                                            <strong><Translate text={'mobile'} />: </strong> {CommonHelper.getFullPhone(inbound.contact.phoneCode, inbound.contact.phone)}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {
                                                                                inbound?.contact.email
                                                                                    ?
                                                                                    <>
                                                                                        <div className="customer-avatar-titlesub">
                                                                                            <strong><Translate text={'email'} />: </strong>  {inbound.contact.email}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            <div className="vehicle-stock-title"><i className={`ico icon-Stock mr-1`}></i>{this.handleNull(inbound?.testdrive?.make) + " " + this.handleNull(inbound?.testdrive?.model) + " " + this.handleNull(inbound?.testdrive?.year)}</div>
                                                                            <div className="vehicle-stock-titlesub"><strong>Stock #:</strong> {this.handleNull(inbound?.testdrive?.stockNo)} <strong>VIN #:</strong> {this.handleNull(inbound?.testdrive?.vinNo)} </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className="ico icon-inbound"></i> </div>
                                                        <div className="no-data-txt mt-2"> No inbound available</div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div> 
                        </Suspense>*/}
                    </Modal.Body>
                </Modal>

                <PopoverPanel
                    showpopover={notesPopOver.show}
                    targetpopover={notesPopOver.target}
                    title='Notes'
                    position='left'
                    id='pipeline-switch-inbound'
                    className='popover-pipeline-switch'
                    closepopover={() => {
                        this.setState({ notesPopOver: { show: false } })
                    }}>
                    <div className='popover-notes-inner-wrap'>
                        <div className="popover-notes-inner m-3">
                            {notesPopOver.notes}
                        </div>
                    </div>
                </PopoverPanel>
            </>
        );
    }

}

export default InboundTestdrives;