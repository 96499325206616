/** LIBRARIES */
import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
/** COMPONENTS */
import { SectionOverlay } from '../../../components'
import OrderStages from './orderStages'

function OrderList(props) {

    const [state, setState] = useState(props.cafeOrders);
    const [columns, setColumns] = useState(props.columns);
    //const [pipeline, setPipeline] = useState(props.pipeline);
    const [panelWidth, setPanelWidth] = useState('100%');
    const [windowWidth, setWindowwidth] = useState(window.outerWidth);


    useEffect(() => {
        //console.log('props', props.cafeOrders)
        setState(props.cafeOrders)
    }, [props])

    useEffect(() => {

        if (props.columns.length > 6 && window.outerWidth > 1500) {
            document.documentElement.style.setProperty('--boardwidth', (((window.innerWidth - 30) / 6) - 15) + 'px')
            setPanelWidth((((((window.innerWidth - 30) / 6)) * props.columns.length) + 80) + 'px')
        }
        else if (window.outerWidth < 1500 && props.columns.length > 5) {
            if (window.outerWidth < 1100) {
                document.documentElement.style.setProperty('--boardwidth', '300px')
                setPanelWidth((320 * props.columns.length) + 'px')
            }
            else {
                document.documentElement.style.setProperty('--boardwidth', (((window.innerWidth - 30) / 4) - 30) + 'px')
                setPanelWidth((((((window.innerWidth - 30) / 4)) * props.columns.length) + 40) + 'px')
            }
        }
        else if (window.outerWidth < 1100) {
            document.documentElement.style.setProperty('--boardwidth', '300px')
            setPanelWidth((320 * props.columns.length) + 'px')
        }
        else {
            document.documentElement.style.setProperty('--boardwidth', ((100 / props.columns.length) - 1.5).toFixed(2) + '%')
            setPanelWidth('100%')
        }
    }, [props, windowWidth])


    useEffect(() => {
        function handleResize() {
            handlePanleheight();
            setWindowwidth(window.outerWidth)
        }
        window.addEventListener('resize', handleResize);
        handlePanleheight();
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    function handlePanleheight() {
        var panelHeight = (window.innerHeight - 123)
        var pipelineItems = []
        columns.forEach((r) => {
            pipelineItems.push(state.columns[r].taskIds.length)
        })
        var _h = (Math.max(...pipelineItems) * 123) + 30;
        _h = _h > (panelHeight - 72) ? (_h + 72) : panelHeight;
        var boardHeight = _h;
        var pipelineHeight = (_h - 72);

        document.documentElement.style.setProperty('--panelHeight', panelHeight + 'px');
        document.documentElement.style.setProperty('--boardheight', boardHeight + 'px');
        document.documentElement.style.setProperty('--pipelineHeight', pipelineHeight + 'px');
    }

    const handleDragStart = useCallback(
        start => {

            setState({
                ...state,
                homeIndex: columns.indexOf(start.source.droppableId)
            });
        },
        [state]
    );

    const handleDragUpdate = useCallback(
        update => {
            // const opacity = update.destination
            //     ? update.destination.index / Object.keys(state.tasks).length
            //     : 0;


        },
        [state]
    );

    const handleDragEnd = useCallback(
        result => {


            setState({
                ...state,
                homeIndex: null
            });

            if (!result.destination) {
                return;
            }

            if (
                result.destination.droppableId === result.source.droppableId
            ) {
                return;
            }

            const start = state.columns[result.source.droppableId];
            const finish = state.columns[result.destination.droppableId];

            if (start === finish) {
                const newTaskIds = Array.from(start.taskIds);
                newTaskIds.splice(result.source.index, 1);
                newTaskIds.splice(result.destination.index, 0, result.draggableId);

                const newColumn = {
                    ...start,
                    taskIds: newTaskIds
                };

                setState({
                    ...state,
                    columns: {
                        ...state.columns,
                        [newColumn.id]: newColumn
                    }
                });

                return;
            }

            const startTaskIds = Array.from(start.taskIds);
            startTaskIds.splice(result.source.index, 1);

            const newStart = {
                ...start,
                taskIds: startTaskIds
            };
            //console.log('startTaskIds',startTaskIds, newStart)
            const finishTaskIds = Array.from(finish.taskIds);
            finishTaskIds.splice(0, 0, result.draggableId);
            const newFinish = {
                ...finish,
                taskIds: finishTaskIds
            };
            //console.log('finishTaskIds',finishTaskIds, newFinish)
            setState({
                ...state,
                columns: {
                    ...state.columns,
                    [newStart.id]: newStart,
                    [newFinish.id]: newFinish
                }
            });
            //console.log('handleDragEnd', result.draggableId, state.tasks[result.draggableId].orderNumber, result.destination, result.source)
            props.handleOrderStatus(result.draggableId, result.destination.droppableId, result.source.droppableId)
        },
        [state]
    );


    return (
        <div style={{ position: "relative" }}>
            <SectionOverlay active={props.sectionloading} />
            <div className="pipeline-panel" onScroll={props.listenScrollEvent}>
                <DragDropContext
                    onDragStart={handleDragStart}
                    onDragUpdate={handleDragUpdate}
                    onDragEnd={handleDragEnd}
                >
                    {
                        <div style={{ width: `${panelWidth}` }}>
                            {columns.map((columnId, index) => {

                                const column = state.columns[columnId];
                                const tasks = column.taskIds.map(taskId => state.tasks[taskId]);

                                return <OrderStages
                                    key={index}
                                    column={column}
                                    tasks={tasks}
                                    clientUsers={props.clientUsers}
                                    groupUsers={props.groupUsers}
                                    sidepanelOpen={props.sidepanelOpen}
                                    stageLoading={props.stageLoading} />

                            })}
                        </div>

                    }
                    {/* {

                        <div>
                            {
                                document.documentElement.style.setProperty('--boardwidth', ((100 / columns.length) - .80).toFixed(2) + '%')
                            }
                            {
                                columns.map((columnId, index) => {
                                    const column = state.columns[columnId];
                                    const tasks = column.taskIds.map(taskId => state.tasks[taskId]);

                                    return <OrderStages
                                        key={index}
                                        column={column}
                                        tasks={tasks}
                                        clientUsers={props.clientUsers}
                                        groupUsers={props.groupUsers}
                                        sidepanelOpen={props.sidepanelOpen}
                                        stageLoading={props.stageLoading} />

                                })
                            }
                        </div>

                    } */}
                </DragDropContext>
            </div>
        </div>

    )
}

export default OrderList
