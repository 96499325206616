/** LIBRARIES */
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import ChangePassword from './changepassword';
import TwoFactorAuth from './twoFactorAuth';
import CommonHelper from '../../../../services/common';

const PasswordSecurity = props => (
	<>
		<h2><Translate text={'Security/Password'} /></h2>
		<div className='settings-tab-wrap tabs'>
			<div className='tabs-wraper'>
				<nav>
					<Tabs
						defaultActiveKey='changepassword'
						transition={false}
						className='nav-fill'
					>
						<Tab eventKey='changepassword' title={CommonHelper.showLocale(props, 'Change Password')}>
							<div>
								<ChangePassword {...props} />
							</div>
						</Tab>
						<Tab eventKey='authentication' title={CommonHelper.showLocale(props, 'Two Factor Authentication')}>
							<div> 
								<TwoFactorAuth {...props} />
							</div>
						</Tab>
					</Tabs>
				</nav>
			</div>
		</div>
	</>
);

export default PasswordSecurity;
