/** LIBRARIES */
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import toast from "toasted-notes";
import NumberFormat from "react-number-format";
import moment from "moment";
import { Modal, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
/** COMPONENTS */
import Translate from "../../constants/translate";
import { default as _images } from "../../images";
import { PopUpModal, DropDownMenu, PpsrScan } from "../../components";
import Evaluators from "../users/evaluators";
import ProEvaluators from "../users/evaluators/tradeinpro";
import { tradeInStatus, tipStatus, tipStatuses } from "../../services/enum";
import CommonHelper from "../../services/common";
import AddStock from "../stock/add";
import TradeInFields from './tradeInFields'

import { contactBasicDetailsVM } from "../contacts/viewModel";
import { objBasicTradeInVM } from "../tradeinplus/viewModel";
import { firestoreDB } from "../../services/helper";

const __status = [
  { active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}>IN PROGRESS</div> },
  { active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}>PENDING</div> },
  { active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
  { active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> }
]

const TradeInInfo = ({
  tradein,
  handleEditTradeIn,
  handleAutoGrap,
  isDeivered,
  tradeinPlusEnabled,
  isContact,
  contact,
  dealersettings,
  handleContactOpen,
  handleReSendLink,
  showTag,
  clientUsers,
  groupUsers,
}) => {
  const [editshow, setEditshow] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [evaluatorShow, setEvaluatorShow] = useState(false);
  const [proEvaluatorShow, setProEvaluatorShow] = useState(false);
  const [showPpsrScan, setShowPpsrScan] = useState(false);
  const [sectionLoader, setSectionLoader] = useState(false);
  const [showTradein, setShowTradein] = useState(false)
  const [stockModal, setStockModal] = useState({
    show: false,
    ID: "",
    prefillData: null,
    title: "",
    clsActive: "",
  });

  const node = useRef();
  const {
    documentID,
    evalexpertID,
    enquiryID,
    serviceID,
    tradeinProID,
    make,
    model,
    regNo,
    status,
    offerPrice,
    chassisNo,
    year,
    exteriorColor,
    images,
    sent,
    tags,
    linkStatus,
    linkURL,
    sidePhotos,
    linkedAppraisal,
    linkedAppraisalService
  } = tradein;
  //console.log(evalexpertID, tags)
  const isLinked = localStorage.defaultModule === 'service' ? linkedAppraisalService : linkedAppraisal
  const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
  const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
  let _objCurrentStatus = tradeinProID && status && tradeinProAllStatus.filter(e => e.value === status)[0]

  let _allImages = CommonHelper.bindAllImages(images, sidePhotos);

  const currencySymbol = ((!_.isEmpty(dealersettings) &&
    !_.isEmpty(dealersettings.client) &&
    !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

  const _permissions = ((!_.isEmpty(dealersettings) &&
    !_.isEmpty(dealersettings.rolePermissions) &&
    !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

  const accessToShowTradeInPrice = ((!_.isEmpty(_permissions) && _permissions.accessToShowTradeInPrice) ? true : false);
  const deleteTradeIn = ((!_.isEmpty(_permissions) && _permissions.deleteTradeIn) ? true : false);

  const _moduleSettings = ((!_.isEmpty(dealersettings) &&
    !_.isEmpty(dealersettings.client) &&
    !_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);

  const tradeinProEnabled = ((!_.isEmpty(_moduleSettings) &&
    !_.isEmpty(dealersettings.client.moduleSettings.tradeinPro) &&
    dealersettings.client.moduleSettings.tradeinPro.enabled) ? true : false);

  const accessToMarketOverlay = localStorage.defaultModule !== 'tradeinPro' ? ((!_.isEmpty(_permissions) && _permissions.accessToMarketOverlay) ? true : false) : true;
  const isLinkSent = linkStatus === "sent" ? true : false;

  const ppsrEnabled = (!_.isEmpty(dealersettings?.client?.moduleSettings?.ppsr) &&
    dealersettings.client.moduleSettings.ppsr.enabled &&
    dealersettings.client.moduleSettings.ppsr.active) ? true : false;

  const autoGrabEnabled = !_.isEmpty(dealersettings) &&
    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
    dealersettings.client.integrations.filter((e) => e.type === "autograb")[0] &&
    dealersettings.client.integrations.filter((e) => e.type === "autograb")[0].active === true &&
    dealersettings.client.integrations.filter((e) => e.type === "autograb")[0].enabled === true ? true : false;

  const autoitEnabled = ((!_.isEmpty(dealersettings) &&
    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
    dealersettings.client.integrations.filter(e => e.type === "autoit")[0] &&
    dealersettings.client.integrations.filter(e => e.type === "autoit")[0].active === true &&
    dealersettings.client.integrations.filter(e => e.type === "autoit")[0].enabled === true &&
    dealersettings.client.integrations.filter(e => e.type === "autoit")[0].settings &&
    dealersettings.client.integrations.filter(e => e.type === "autoit")[0].settings.enableTradeIn === true
  ) ? true : false);

  const revolutionEnabled = !_.isEmpty(dealersettings) &&
    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
    dealersettings.client.integrations.filter((e) => e.type === "revolution")[0] &&
    dealersettings.client.integrations.filter((e) => e.type === "revolution")[0].active === true &&
    dealersettings.client.integrations.filter((e) => e.type === "revolution")[0].enabled === true ? true : false;

  const titanEnabled = ((!_.isEmpty(dealersettings) &&
    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
    dealersettings.client.integrations.filter(e => e.type === "titan")[0] &&
    dealersettings.client.integrations.filter(e => e.type === "titan")[0].active === true &&
    dealersettings.client.integrations.filter(e => e.type === "titan")[0].enabled === true &&
    dealersettings.client.integrations.filter(e => e.type === "titan")[0].settings?.enableTradeIn === true
  ) ? true : false);

  const autograbModulerEnabled = dealersettings?.client?.moduleSettings?.autograb?.active && dealersettings?.client?.moduleSettings?.autograb?.enabled;

  const autoGrabModulerVehicleDetailEnabled = dealersettings?.client?.moduleSettings?.autograb?.vehicleDetail && dealersettings?.client?.moduleSettings?.autograb?.enabled;

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e) => {
    if (node && node.current && node.current.contains(e.target)) {
      return;
    }
    setEditshow(false);
  };

  const isValidForTradeInSend = () => {
    // let _mandatory = ['email'];
    // if (isContact) {
    //     if (!contact['email']) {
    //         Swal.fire({
    //             title: 'Please enter email',
    //             icon: 'info',
    //             showCancelButton: true,
    //             confirmButtonText: 'Add Now',
    //             cancelButtonText: 'Later',
    //         }).then(result => {
    //             if (result.value) {
    //                 handleContactOpen('editContact', _mandatory);
    //             }
    //         });
    //         return false;
    //     } else {
    //         handleShowEval(null);
    //         return true;
    //     }

    // } else {
    //     Swal.fire({
    //         title: 'Please add contact',
    //         icon: 'info',
    //         showCancelButton: true,
    //         confirmButtonText: 'Add Now',
    //         cancelButtonText: 'Later',
    //     }).then(result => {
    //         if (result.value) {
    //             handleContactOpen('addContact', _mandatory);
    //         }
    //     });
    //     return false;
    // }
    if (tradeinProEnabled)
      handleShowProEval();
    else
      handleShowEval(null);
    return true;
  };

  const handleShowEval = (e) => {
    setEvaluatorShow(true);
  };

  const handleSendMail = () => {
    if (!_.isEmpty(tradein)) {
      setSendLoader(true);
      var objData = Object.assign({}, tradein);

      for (var propName in objData) {
        if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
          delete objData[propName];
        }
      }

      //    console.log('handleSendMail', objData, tradein);
      const tradeinplussendEmail = window.firebase.functions().httpsCallable('tradeinplus-sendEmail');
      tradeinplussendEmail(objData).then((response) => {
        //    console.log('handleSendMail', response, response.data.message, response.data.success);
        if (response.data.success) {
          let modifiedBy = dealersettings ? dealersettings.id : "";
          let modifiedDate = window.firebase.firestore.Timestamp.now();

          const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(tradein.documentID);
          updateRef
            .update({
              status: tradein.status ? tradein.status : tradeInStatus.PENDING,
              sent: true,
              modifiedBy: modifiedBy,
              modifiedFrom: "web",
              modifiedDate: modifiedDate,
            })
            .then((docRef) => {
              toast.notify("Email sent successfully", {
                duration: 2000,
              });
              setSendLoader(false);
            })
            .catch((error) => {
              console.error("Error updating enquiries: ", error);
            });
        } else {
          setSendLoader(false);
        }
        setSendLoader(false);
      });
    }

    //console.log('handleSendMail', tradein)
  };

  const handleShowProEval = () => {
    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
      text: CommonHelper.showLocale({ dealersettings }, 'Do you want to sent evaluation to tradein pro'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        handleCloseProEval();
      }
    });
  };
  const handleCloseProEval = (Id, evalName) => {
    //if (Id) {
    setSendLoader(true);

    if (dealersettings?.client?.tradeinPro) {
      const setttings = dealersettings.client.tradeinPro;

      let state = Object.assign({});
      if (_.isEmpty(state.addedBy)) {
        state.addedBy = dealersettings ? dealersettings.id : "";
        state.addedDate = window.firebase.firestore.Timestamp.now();
      }
      state.modifiedBy = dealersettings ? dealersettings.id : "";
      state.modifiedDate = window.firebase.firestore.Timestamp.now();
      state.clientID = dealersettings ? dealersettings.client.id : "";
      state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
      if (enquiryID) state.ownerSales = tradein?.enquiry?.owner;
      if (serviceID) state.ownerService = tradein?.service?.owner;
      state.isDeleted = false;
      state.isNewTradein = true;
      state.documentID = firestoreDB(dealersettings).firestore().collection("tradeinPro").doc().id;

      if (contact?.documentID) {
        let _contact = Object.assign({}, contactBasicDetailsVM);
        for (let [key, value] of Object.entries(contact)) {
          if (_contact.hasOwnProperty(key) && value !== undefined)
            _contact[key] = value;
        }
        state.contact = _contact;
        state.contactID = _contact.documentID;
        state.isContact = true;
        state.isNewTradein = false;
      }

      if (!_.isEmpty(tradein)) {
        const _vehicle = Object.assign({}, objBasicTradeInVM);
        for (let [key, value] of Object.entries(tradein)) {
          if (_vehicle.hasOwnProperty(key)) _vehicle[key] = value;
        }
        _vehicle.tradeinProID = state.documentID;
        state["tradein"] = _vehicle;
        state.tradeinID = _vehicle.documentID;
        state.isTradein = true;
      }

      let _selPipeline = Object.assign({}, setttings.allPipelines.filter((i) => i.default === true)[0]);
      if (_.isEmpty(_selPipeline))
        _selPipeline = Object.assign({}, setttings.allPipelines[0]);
      // if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaulttradeinProPipeline)) {
      //     _selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaulttradeinProPipeline);
      // }

      let defaultstages = _selPipeline.stages;
      let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

      state.pipeline = _selPipeline.value;
      state.stage = stages[0];
      state.status = tipStatus.NEW;
      state.stageDate = window.firebase.firestore.Timestamp.now();
      state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);

      if (enquiryID) state.tags = [{ type: "ams pro", refID: enquiryID, subType: "" }];
      if (serviceID) state.tags = [{ type: "service", refID: serviceID, subType: "" }];

      const objData = Object.assign({}, state);
      //Remove empty value from object
      for (var propName in objData) {
        if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
          delete objData[propName];
        }
      }

      const batch = firestoreDB(dealersettings).firestore().batch();
      batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${objData.documentID}`), objData, { merge: true });
      batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${tradein.documentID}`), {
        tradeinProID: objData.documentID,
        status: tipStatus.NEW,
        sent: true,
        modifiedBy: objData.modifiedBy,
        modifiedFrom: "web",
        modifiedDate: objData.modifiedDate,
      }, { merge: true });

      let newlogVM = Object.assign({});
      newlogVM.type = "tradeinProlog";
      newlogVM.subType = "note";
      newlogVM.isDone = true;
      newlogVM.isDeleted = false;
      newlogVM.addedBy = localStorage.uid;
      newlogVM.modifiedBy = localStorage.uid;
      newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
      newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
      newlogVM.tradeinProID = objData.documentID ? objData.documentID : null;
      newlogVM.contactID = (contact && contact.documentID) ? contact.documentID : null;
      newlogVM.clientID = objData.clientID ? objData.clientID : (dealersettings ? dealersettings.client.id : null);
      newlogVM.projectId = objData.projectId ? objData.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
      newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
      newlogVM.startDate = window.firebase.firestore.Timestamp.now();
      if (enquiryID) newlogVM.notes = `Appraisal has been pushed from AMS Pro`;
      if (serviceID) newlogVM.notes = `Appraisal has been pushed from Service`;
      newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

      batch.set(firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });

      batch.commit().then(snapshot => {
        toast.notify("Evaluation sent successfully", {
          duration: 2000,
        });

        let _makeModel = (tradein.make ? tradein.make + ' ' : '') + (tradein.model ? tradein.model + ' ' : '') + (tradein.year ? tradein.year : '');
        let _logNote = 'Appraisal pushed to Trade-In Pro ' + (evalName ? evalName : '')

        let _ID = (tradein.enquiryID ? tradein.enquiryID : tradein.serviceID ? tradein.serviceID : null)
        let _subType = (tradein.serviceID ? 'service' : 'enquiry')

        CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, _ID, _logNote + (_makeModel ? " (" + _makeModel + ")" : ""), "tradein", _subType);
        setSendLoader(false);
        //sendNotification(objData)
      }).catch((error) => {
        console.error("Error updating document: ", error);
      });
    }

    //}
    setProEvaluatorShow(false);
  };

  const sendNotification = (objData) => {
    let _reqObject = {
      userID: objData.owner,
      notification: {
        title: "New Appraisal Received",
        body: `Appraisal received from Sales`,
        type: "tradeinProRequest",
        recordID: objData.documentID,
        clientID: objData.clientID ? objData.clientID : "",
      },
    };
    //console.log('generic-notify', _reqObject)
    const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
  };

  const handleCloseEval = (Id, evalName) => {
    if (Id) {
      setSendLoader(true);

      var objData = {
        "tradein": tradein,
        "contact": contact,
        "clientID": (dealersettings && dealersettings.client ? dealersettings.client.id : ''),
        "evaluatorID": Id,
        "userName": (dealersettings && dealersettings.name ? dealersettings.name : ''),
        "userEmail": (dealersettings && dealersettings.email ? dealersettings.email : '')
      }

      if (!_.isEmpty(objData.contact) && dealersettings && dealersettings.client && dealersettings.client.settings) {
        const setttings = dealersettings.client.settings;
        objData.contact.title = CommonHelper.getNameByValue(setttings.titles, objData.contact.title, '');
        objData.contact.secondaryTitle = CommonHelper.getNameByValue(setttings.titles, objData.contact.secondaryTitle, '');
        objData.contact.origin = CommonHelper.getNameByValue(setttings.origins, objData.contact.origin, '');
        objData.contact.campaign = CommonHelper.getNameByValue(setttings.campaigns, objData.contact.campaign, '');
        objData.contact.gender = CommonHelper.getNameByValue(setttings.genders, objData.contact.gender, '');
        objData.contact.maritalStatus = CommonHelper.getNameByValue(setttings.maritalStatus, objData.contact.maritalStatus, '');
        objData.contact.contactMethod = CommonHelper.getNameByValue(setttings.contactMethod, objData.contact.contactMethod, '');
        objData.contact.interests = CommonHelper.getLabelByMultipleValues(setttings.interests, objData.contact.interests, '', true);
        objData.contact.licenseType = CommonHelper.getNameByValue(setttings.licenseType, objData.contact.licenseType, '');
        objData.contact.nationality = CommonHelper.getNameByValue(dealersettings.nationalities, objData.contact.nationality, '');
        objData.contact.language = CommonHelper.getNameByValue(dealersettings.languages, objData.contact.language, '');
      }

      if (!_.isEmpty(objData.tradein)) {
        const _tradeIn = objData.tradein
        objData.tradein.regDate = _tradeIn.regDate ? moment(_tradeIn.regDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';
        objData.tradein.serviceContFrom = _tradeIn.serviceContFrom ? moment(_tradeIn.serviceContFrom, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';
        objData.tradein.serviceContTo = _tradeIn.serviceContTo ? moment(_tradeIn.serviceContTo, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';
        objData.tradein.lastService = _tradeIn.lastService ? moment(_tradeIn.lastService, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';
        objData.tradein.images = CommonHelper.bindAllImages(_tradeIn.images, _tradeIn.sidePhotos)
      }

      const tradeinpluspush = window.firebase.functions().httpsCallable('tradeinplus-push');
      tradeinpluspush(objData).then((response) => {
        // console.log('tradeinpluspush', response, response.data.message, response.data.success);
        if (response.data.success) {
          let modifiedBy = dealersettings ? dealersettings.id : "";
          let modifiedDate = window.firebase.firestore.Timestamp.now();

          const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(tradein.documentID);
          updateRef.update({
            status: tradeInStatus.PENDING,
            sent: true,
            modifiedBy: modifiedBy,
            modifiedFrom: "web",
            modifiedDate: modifiedDate,
          }).then((docRef) => {
            toast.notify("Evaluation sent successfully", {
              duration: 2000,
            });

            let _makeModel = (tradein.make ? tradein.make + ' ' : '') + (tradein.model ? tradein.model + ' ' : '') + (tradein.year ? tradein.year : '');
            let _logNote = 'Trade-In evaluation sent to ' + (evalName ? evalName : '')

            let _ID = (tradein.enquiryID ? tradein.enquiryID : tradein.serviceID ? tradein.serviceID : null)
            let _subType = (tradein.serviceID ? 'service' : 'enquiry')

            CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, _ID, _logNote + (_makeModel ? " (" + _makeModel + ")" : ""), "tradein", _subType);

            setSendLoader(false);
          }).catch((error) => {
            console.error("Error updating enquiries: ", error);
          });
        } else {
          setSendLoader(false);
        }
      }).catch((error) => {
        setSendLoader(false);
        console.log(error);
        Swal.fire(
          "Something went wrong. Please contact support.",
          "",
          "error"
        );
      });
      //   console.log('handleCloseEval', objData)
    }

    setEvaluatorShow(false);
  };

  const handleStatusChange = (status) => {
    //   console.log('handleStatusChange', status)
    let modifiedBy = dealersettings ? dealersettings.id : "";
    let modifiedDate = window.firebase.firestore.Timestamp.now();

    const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(tradein.documentID);
    updateRef.update({
      status: status,
      modifiedBy: modifiedBy,
      modifiedFrom: "web",
      modifiedDate: modifiedDate,
    });
  };

  const handleSendEvalExpert = () => {
    if (!_.isEmpty(tradein)) {
      setSendLoader(true);
      var objData = Object.assign({}, tradein);

      for (var propName in objData) {
        if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
          delete objData[propName];
        }
      }
      objData.images = CommonHelper.bindAllImages(tradein.images, tradein.sidePhotos)
      //console.log('handleSendEvalExpert', objData, tradein);
      const evalexpertPush = window.firebase.functions().httpsCallable('evalexpert-push');
      evalexpertPush(objData).then((response) => {
        //console.log('handleSendEvalExpert', response, response.data.message, response.data.success);
        if (response.data.success) {
          let modifiedBy = dealersettings ? dealersettings.id : "";
          let modifiedDate = window.firebase.firestore.Timestamp.now();

          const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(tradein.documentID);
          updateRef.update({
            status: tradein.status ? tradein.status : tradeInStatus.PENDING,
            sent: true,
            modifiedBy: modifiedBy,
            modifiedFrom: "web",
            modifiedDate: modifiedDate,
          }).then((docRef) => {
            const evalexpertPushImages = window.firebase.functions().httpsCallable('evalexpert-pushImages');
            evalexpertPushImages(objData)

            toast.notify("Send eval expert successfully", {
              duration: 2000,
            });

            let _makeModel = (tradein.make ? tradein.make + ' ' : '') + (tradein.model ? tradein.model + ' ' : '') + (tradein.year ? tradein.year : '');
            let _logNote = 'Appraisal pushed to Eval Expert '

            let _ID = (tradein.enquiryID ? tradein.enquiryID : tradein.serviceID ? tradein.serviceID : null)
            let _subType = (tradein.serviceID ? 'service' : 'enquiry')

            CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, _ID, _logNote + (_makeModel ? " (" + _makeModel + ")" : ""), "tradein", _subType);
            setSendLoader(false);
          }).catch((error) => {
            console.error("Error updating enquiries: ", error);
          });
        } else {
          setSendLoader(false);
        }
        setSendLoader(false);
      }).catch((error) => {
        setSendLoader(false);
        console.log(error);
        Swal.fire('Something went wrong. Please contact support.', '', 'error')
      });
    }

    //console.log('handleSendMail', tradein)
  };

  const deleteTradeIns = (fileID) => {

    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
      text: CommonHelper.showLocale({ dealersettings }, 'Do you want to delete the trade-in?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
    }).then((result) => {
      if (result.value) {
        if (tradeinProID) {
          // unlink all trade-in data from appraisal.
          handleUnlinkAppraisal().then((docRef) => {
            toast.notify('Trade-In successfully deleted.', {
              duration: 2000
            })
            let _makeModel = (tradein.make ? tradein.make + ' ' : '') + (tradein.model ? tradein.model + ' ' : '') + (tradein.year ? tradein.year : '');
            let logNotes = 'Trade-In deleted' + (_makeModel ? ' (' + _makeModel + ')' : '');

            let _objLogData = {
              notes: logNotes,
              type: 'tradeins',
              subType: 'delete',
              recordId: fileID,
              enquiryID: enquiryID ? enquiryID : null,
              serviceID: serviceID ? serviceID : null,
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
            CommonHelper.saveFileChangeLog(_objLogData, 'updated');

          }).catch((error) => {
            console.error("Error updating contact_details: ", error);
          });
        }
        else {
          let modifiedBy = dealersettings ? dealersettings.id : '';
          let modifiedDate = window.firebase.firestore.Timestamp.now();

          const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(fileID)
          updateRef.set({
            isDeleted: true,
            modifiedBy,
            modifiedDate,
            modifiedFrom: 'web'
          }, { merge: true })
            .then((docRef) => {
              toast.notify('Trade-In successfully deleted.', {
                duration: 2000
              })
              let _makeModel = (tradein.make ? tradein.make + ' ' : '') + (tradein.model ? tradein.model + ' ' : '') + (tradein.year ? tradein.year : '');
              let logNotes = 'Trade-In deleted' + (_makeModel ? ' (' + _makeModel + ')' : '');

              let _objLogData = {
                notes: logNotes,
                type: 'tradeins',
                subType: 'delete',
                recordId: fileID,
                enquiryID: enquiryID ? enquiryID : null,
                serviceID: serviceID ? serviceID : null,
              }
              _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
              CommonHelper.saveAuditLog(_objLogData);
              CommonHelper.saveFileChangeLog(_objLogData, 'updated');

            }).catch((error) => {
              console.error("Error updating contact_details: ", error);
            });

        }

      }
    })

  }

  const handleStockClose = (id) => {
    setStockModal({
      show: false,
      ID: "",
      prefillData: null,
      title: "",
      clsActive: "",
    });
  };

  const handleEditStock = (documentID) => {
    setStockModal({
      show: true,
      ID: documentID,
      prefillData: null,
      title: "stock",
      clsActive: "overlay-modal active",
    });
  };

  const handleAddStock = (prefillData) => {
    setStockModal({
      show: true,
      ID: "",
      prefillData: prefillData,
      title: "addStock",
      clsActive: "overlay-modal active",
    });
  };

  const showAlert = () => {
    if (tradeinPlusEnabled)
      Swal.fire(CommonHelper.showLocale({ dealersettings }, 'tradeinStatus'), CommonHelper.showLocale({ dealersettings }, 'Only trade-in with Won Status can be added to stock'), 'info')
    else
      Swal.fire(CommonHelper.showLocale({ dealersettings }, 'tradeinStatus'), CommonHelper.showLocale({ dealersettings }, 'Please change trade-in status to Won to proceed'), 'info')
  }

  const isValidForPushToAutoIT = (_origin) => {
    if (!_origin) return;

    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
      text: CommonHelper.showLocale({ dealersettings }, `You want to push this appraisal to DMS`),
      icon: "info",
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        setSectionLoader(true);
        try {
          const dmsreq = window.firebase.functions().httpsCallable(_origin);
          dmsreq({
            clientID: tradein.clientID,
            "tradein": {
              ...tradein,
              owner: localStorage.defaultModule === 'service' ? tradein?.service?.owner : tradein?.enquiry?.owner
            }
          })
            .then((res) => {
              setSectionLoader(false);
              if (res.data.success) {
                toast.notify("Appraisal has been sent successfully", {
                  duration: 2000,
                });
              } else {
                Swal.fire(res.data.message, "", "error");
              }
            })
            .catch((error) => {
              setSectionLoader(false);
              console.log(error);
              Swal.fire('Something went wrong. Please contact support.', '', 'error')
            });
        } catch (error) {
          Swal.fire('Something went wrong. Please contact support.', '', 'error')
        }
      }
    });
  };

  const handleModalClose = (_data) => {
    if (!_.isEmpty(_data)) handleAutoGrap(documentID, { ...tradein, ..._data });
    setShowTradein(false);
  }

  const handleUnlinkAppraisal = async (_showLoading) => {
    if (_.isEmpty(tradeinProID)) return;

    if (_showLoading)
      toast.notify((<div style={{ fontSize: `14px` }}>
        <span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span>
        un-linking...</div>), { position: 'top', duration: null })

    let __promise = []
    __promise.push(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinProID}`).get());
    const __snapsnot = await Promise.all(__promise);
    let _tradePro = null
    __snapsnot.forEach((snap) => {
      if (snap.exists) {
        _tradePro = snap.data();
      }
    })
    //console.log('_tradePro', _tradePro);

    let _enquiryID = localStorage.defaultModule !== 'service' ? enquiryID : null
    let _enquiryNo = tradein?.enquiry?.displayID;

    let _serviceID = localStorage.defaultModule === 'service' ? serviceID : null
    let _serviceNo = tradein?.service?.displayID;
    if (tradeinProID && documentID && !_.isEmpty(_tradePro)) {

      // update tip
      let state = Object.assign({});
      if (_enquiryID) state.ownerSales = null;
      if (_serviceID) state.ownerService = null;
      state.modifiedBy = dealersettings ? dealersettings.id : '';
      state.modifiedDate = window.firebase.firestore.Timestamp.now();
      state.modifiedFrom = 'web';

      let tradein = _tradePro.tradein;
      if (!_.isEmpty(tradein)) {
        let _vehicle = Object.assign({}, tradein);
        if (localStorage.defaultModule === 'service' && _serviceID) {
          _vehicle.serviceID = null;
          _vehicle.service = null;
          _vehicle.linkedAppraisalService = false;
        }
        else {
          _vehicle.enquiryID = null;
          _vehicle.enquiry = null;
          _vehicle.linkedAppraisal = false;
        }

        Object.keys(_vehicle).forEach(key => _vehicle[key] === undefined ? delete _vehicle[key] : {});
        state['tradein'] = _vehicle;
      }

      if (!_.isEmpty(_tradePro?.tags)) {
        let tags = Object.assign([], _tradePro?.tags);
        if (localStorage.defaultModule === 'service')
          state.tags = tags.filter(item => item.type !== 'service');
        else
          state.tags = tags.filter(item => item.type !== 'ams pro');
      }
      else
        state.tags = [];

      const batch = firestoreDB(dealersettings).firestore().batch();
      batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinProID}`), state, { merge: true });

      // update tradein
      let tradein_state = Object.assign({
        modifiedBy: state.modifiedBy,
        modifiedFrom: 'web',
        modifiedDate: state.modifiedDate
      });

      if (localStorage.defaultModule === 'service') {
        if (!enquiryID) tradein_state.sent = false;
        tradein_state.serviceID = null;
        tradein_state.service = null;
        tradein_state.linkedAppraisalService = false;
      }
      else {
        if (!serviceID) tradein_state.sent = false;
        tradein_state.enquiryID = null;
        tradein_state.enquiry = null;
        tradein_state.linkedAppraisal = false;
      }
      Object.keys(tradein_state).forEach(key => tradein_state[key] === undefined ? delete tradein_state[key] : {});

      batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${documentID}`), tradein_state, { merge: true });

      batch.commit().then(snapshot => {
        toast.closeAll();
        toast.notify('Appraisal un-linked successfully', { duration: 2000 })

        //update change logs    
        let _makeModel = (tradein.make ? tradein.make + ' ' : '') + (tradein.model ? tradein.model + ' ' : '') + (tradein.year ? tradein.year : '') + (tradein.regNo ? ', ' + tradein.regNo : '');

        if (_enquiryID) {
          //1 - enquiry logs
          CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, _enquiryID, `UnLinked appraisal from this enquiry. \n(Valuation no. ${_tradePro.displayID?.toUpperCase()})` + (_makeModel ? ' (' + _makeModel + ')' : ''), 'tradein', 'enquiry');
          //2 - tip logs
          CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, tradeinProID, `UnLinked appraisal from the enquiry. \n ${_enquiryNo ? '(Enquiry no. ' + _enquiryNo?.toUpperCase() + ')' : ''}`, 'tradein', 'tradeinPro');

        }
        if (_serviceID) {
          //1 - service logs
          CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, _serviceID, `UnLinked appraisal from this service. \n(Valuation no. ${_tradePro.displayID?.toUpperCase()})` + (_makeModel ? ' (' + _makeModel + ')' : ''), 'tradein', 'service');
          //2 - tip logs
          CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, tradeinProID, `UnLinked appraisal from the service. \n ${_serviceNo ? '(Service no. ' + _serviceNo?.toUpperCase() + ')' : ''}`, 'tradein', 'tradeinPro');

        }
      }).catch((error) => {
        console.error("Error updating document: ", error);
      });
    }
  }

  return (
    <>
      {linkURL && !linkStatus ? (
        <></>
      ) : (
        <>
          <div className="vehicle-item" id={"tradein_" + documentID}  >
            <div className="flex-vehicle"   >
              {sectionLoader ? (
                <>
                  <div className="dashboard-widget-loader h-100">
                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="quickview-activity-more">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditshow(!editshow);
                    if (editshow)
                      document.addEventListener("click", setEditshow(false));
                  }}
                >
                  <i className="ico icon-more"></i>
                </a>
                <div
                  ref={node}
                  className={`custom-drop custom-drop-align ${editshow ? "" : "hide"
                    }`}
                >
                  <ul className="more-dropdown">
                    <li>
                      <a href="#" onClick={(e) => { e.preventDefault(); handleEditTradeIn(documentID, sent) }}>
                        {
                          (sent || isDeivered)
                            ?
                            <><i className="ico icon-zoom"></i> <Translate text={'view'} /> </>
                            :
                            <><i className="ico icon-edit"></i> <Translate text={'edit'} /> </>
                        }
                      </a>
                    </li>
                    {isLinkSent ? (
                      <>
                        <li>
                          {" "}
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditshow(false);
                              handleReSendLink(documentID);
                            }}
                          >
                            {" "}
                            <i className="ico icon-email"></i>
                            <Translate text={"resend"} />{" "}
                          </a>{" "}
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          {" "}
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditshow(false);
                              const url = `/tradein/details/${documentID}?isView=y${accessToShowTradeInPrice ? "" : "es"
                                }`;
                              window.open(url, "_blank");
                            }}
                          >
                            {" "}
                            <i className="ico icon-waiver"></i>
                            <Translate text={"External Report"} />{" "}
                          </a>{" "}
                        </li>
                        {tradeinProID ? (
                          <li>
                            {" "}
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setEditshow(false);
                                const url = `/tradeinpro/internal/${tradeinProID}?isView=y`;
                                window.open(url, "_blank");
                              }}
                            >
                              {" "}
                              <i className="ico icon-waiver"></i>
                              <Translate text={"Internal Report"} />{" "}
                            </a>{" "}
                          </li>
                        ) : (<></>)}

                        {tradeinProID && isLinked ? (
                          <li>
                            {" "}
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setEditshow(false);
                                Swal.fire({
                                  title: CommonHelper.showLocale({ dealersettings }, "Are you sure?"),
                                  text: CommonHelper.showLocale({ dealersettings }, `Do you want to un-link this appraisal from this ${localStorage.defaultModule}.`),
                                  icon: "info",
                                  showCancelButton: true,
                                  confirmButtonText: CommonHelper.showLocale({ dealersettings }, "Yes"),
                                  cancelButtonText: CommonHelper.showLocale({ dealersettings }, "No"),
                                  allowOutsideClick: false,
                                  allowEscapeKey: false,
                                }).then((result) => {
                                  if (result.value) {
                                    handleUnlinkAppraisal(true);
                                  }
                                });
                              }}
                            >
                              {" "}
                              <i className="fa fa-link custom-icon-preview"></i>
                              <Translate text={"Unlink Appraisal"} />{" "}
                            </a>{" "}
                          </li>
                        ) : (<></>)}

                        {tradein.status === tradeInStatus.WON && enquiryID &&
                          (tradein.sent === true || !tradeinPlusEnabled) ? (
                          <>
                            {tradein.stockID ? (
                              <li>
                                {" "}
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEditshow(false);
                                    handleEditStock(tradein.stockID);
                                  }}
                                >
                                  {" "}
                                  <i
                                    className={CommonHelper.getVehicleIcons(
                                      dealersettings?.client?.category,
                                      "ico icon-Stock"
                                    )}
                                  ></i>
                                  <Translate text={"View Stock"} />{" "}
                                </a>{" "}
                              </li>
                            ) : (
                              <>
                                {dealersettings?.rolePermissions?.permissions
                                  ?.addStock ? (
                                  <li>
                                    {" "}
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setEditshow(false);
                                        handleAddStock(tradein);
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className={CommonHelper.getVehicleIcons(
                                          dealersettings?.client?.category,
                                          "ico icon-Stock"
                                        )}
                                      ></i>
                                      <Translate text={"Add To Stock"} />{" "}
                                    </a>{" "}
                                  </li>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <li>
                              {" "}
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEditshow(false);
                                  showAlert();
                                }}
                              >
                                {" "}
                                <i
                                  className={CommonHelper.getVehicleIcons(
                                    dealersettings?.client?.category,
                                    "ico icon-Stock"
                                  )}
                                ></i>
                                <Translate text={"Add To Stock"} />{" "}
                              </a>{" "}
                            </li>
                          </>
                        )}
                        {(autoitEnabled || revolutionEnabled || titanEnabled) ? (
                          <li>
                            {" "}
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                let _origin = autoitEnabled ? `autoit-sendTradein` :
                                  revolutionEnabled ? `revolution-sendTradein` :
                                    titanEnabled ? `titan-sendTradein` : null
                                isValidForPushToAutoIT(_origin);
                              }}
                            >
                              {" "}
                              <i
                                className="fa fa-arrow-right mr-3"
                                aria-hidden="true"
                              ></i>
                              <Translate text={"Push to DMS"} />{" "}
                            </a>{" "}
                          </li>
                        ) : (
                          <></>
                        )}
                      </>
                    )}


                    {deleteTradeIn ? (
                      <>
                        <li>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditshow(false);
                              deleteTradeIns(documentID);
                            }}
                          >
                            {" "}
                            <i className="ico icon-delete"></i>{" "}
                            <Translate text={"delete"} />{" "}
                          </a>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                    {/*  */}
                  </ul>
                </div>
              </div>

              {isLinkSent ? (
                <>
                  <div className="vehicle-data">
                    <div className="vehicle-data pb-1">
                      <i className="ico icon-multi-check mr-1"></i>
                      <Translate text={"Link sent to customer/internal"} />
                    </div>

                    <div className="flow-activity-date ml-4">
                      <span>
                        {tradein.modifiedDate ? (
                          <>
                            {moment
                              .unix(tradein.modifiedDate.seconds)
                              .format("DD MMM YYYY h:mm A")}
                            {" - "}
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                      {CommonHelper.getUserNamebyId(allUsers, tradein.modifiedBy)}
                      {tradein.modifiedFrom ? (
                        <>
                          {` - `}
                          <i
                            className={`ico icon-${tradein.modifiedFrom === "web"
                              ? "monitor"
                              : tradein.modifiedFrom
                              } mr-1`}
                          ></i>
                          {tradein.modifiedFrom}{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="vehicle-item-image">
                    {!_.isEmpty(_allImages) ? (
                      <>
                        {_allImages.map((data, index) => (
                          <a
                            key={index}
                            data-fancybox={`tradein_info_img_slider${documentID}`}
                            className={index === 0 ? "" : "hide"}
                            href={data}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {index === 0 ? (
                              <>
                                <div className="zoom-ico">
                                  <i className="ico icon-zoom"></i>
                                </div>
                                <img
                                  src={data}
                                  width="70"
                                  height="70"
                                  alt=""
                                  className="img-object-fit"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </a>
                        ))}
                      </>
                    ) : (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <img
                          src={CommonHelper.showBrandLogo(dealersettings, make)}
                          className="img-object-fit-contain"
                          width="70"
                          height="70"
                          alt=""
                        />
                      </a>
                    )}
                  </div>
                  <div className="vehicle-data">
                    <div
                      className="vehicle-item-title"
                      onClick={(e) => {
                        handleEditTradeIn(documentID, sent);
                      }}
                    >
                      {make} {model}
                      {!_.isEmpty(tags) &&
                        tags.map((rec, index) => {
                          return (
                            <div
                              key={index}
                              className="badge badge-pill badge-white ml-1 badge-mini text-capitalize"
                              title={
                                rec.type === "evalexpert"
                                  ? `EVAL#: ${rec.refID}`
                                  : ""
                              }
                            >
                              {CommonHelper.getNameByValue(
                                dealersettings.integrations,
                                rec.type
                              )}
                            </div>
                          );
                        })}
                      {tradeinProID ? (<>
                        <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          console.log('tradeinProID', tradeinProID)
                        }}>
                          {`trade-in pro${isLinked ? ' - linked' : ''}`}
                        </div>
                        {/* {isLinked && <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" >{"linked"}</div>} */}
                      </>) : (
                        <></>
                      )}
                    </div>
                    <div
                      className="vehicle-item-info"
                      onClick={(e) => {
                        handleEditTradeIn(documentID, sent);
                      }}
                    >
                      {exteriorColor ? `${exteriorColor},` : ""}{" "}
                      {year ? year : ""}
                    </div>
                    <div
                      className="vehicle-item-info"
                      onClick={(e) => {
                        if (!regNo)
                          handleEditTradeIn(documentID, sent);
                      }}
                    >
                      REG#: {regNo ? (<>{regNo} <span onClick={(e) => CommonHelper.handleCopy(e, regNo)}><i className="fas fa-copy"></i></span></>) : "--"}{" "}
                    </div>
                    <div
                      className="vehicle-item-info"
                      onClick={(e) => {
                        if (!chassisNo)
                          handleEditTradeIn(documentID, sent);
                      }}
                    >
                      VIN#: {chassisNo ? (<>{chassisNo} <span onClick={(e) => CommonHelper.handleCopy(e, chassisNo)}><i className="fas fa-copy"></i></span></>) : "--"}{" "}
                    </div>
                    {/* {evalexpertID ? <div className="vehicle-item-info" onClick={(e) => { handleEditTradeIn(documentID, sent) }}>EVAL#: {evalexpertID} </div> : <></>} */}
                    {tradein.status === tradeInStatus.WON &&
                      tradein.sent === true &&
                      tradein.stockID ? (
                      <>
                        <div className="testdrive-item-status">
                          {status ? (
                            <div
                              className={`badge badge-pill badge-white badge-mini text-capitalize`}
                            >
                              <Translate
                                text={"Converted To Stock"}
                                upperCase={true}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* {
                                            !tradeinPlusEnabled && !evalexpertID
                                                ?
                                                <>{
                                                    isDeivered
                                                        ?
                                                        <>
                                                            <div className="testdrive-item-status">
                                                                {status ? <div className={`badge badge-pill badge-status-${status.toLowerCase()}`}>
                                                                    <Translate
                                                                        text={status.toLowerCase()}
                                                                        upperCase={true}
                                                                    />
                                                                </div> : <></>}

                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="testdrive-item-status">
                                                                <div className={`badge badge-pill badge-status-${status ? status.toLowerCase() : 'empty'}`} style={{ color: (status ? '#fff' : '#333') }}>
                                                                    <DropDownMenu
                                                                        text={status ? status.toUpperCase() : 'Select status'}
                                                                        keyVal="10045341"
                                                                        //className={`inputlink-addmore`}
                                                                        tIndex="-1"
                                                                        menu={__status}
                                                                        handleChange={handleStatusChange}
                                                                    >
                                                                    </DropDownMenu>
                                                                </div>
                                                            </div>
                                                        </>
                                                }

                                                </>
                                                :
                                                <></>
                                        } */}


                  </div>

                </>
              )}
            </div>

            {
              isLinkSent ?
                <></>
                :
                <>
                  {sent ? (
                    <>
                      <div
                        className="tradin-item-button"
                        onClick={(e) => {
                          handleEditTradeIn(documentID, sent);
                        }}
                      >
                        {status ? (
                          <>
                            {/* {status ? <div className={`badge badge-pill badge-status-${status.toLowerCase()} float-left`}>
                                                                        <Translate
                                                                            text={status.toLowerCase()}
                                                                            upperCase={true}
                                                                        />
                                                                    </div> : <></>} */}

                            {!tradeinPlusEnabled &&
                              !evalexpertID &&
                              !tradeinProEnabled ? (
                              <>
                                {isDeivered ? (
                                  <>
                                    {_objCurrentStatus ? (
                                      <>
                                        <div
                                          className={`badge badge-pill badge-status-${status === tipStatus.NEW
                                            ? "open"
                                            : "empty"
                                            } float-left`}
                                          style={{
                                            background: _objCurrentStatus.color
                                              ? _objCurrentStatus.color
                                              : "#333",
                                          }}
                                        >
                                          <Translate
                                            text={_objCurrentStatus.name}
                                            upperCase={true}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className={`badge badge-pill badge-status-${status.toLowerCase()} float-left`}
                                        >
                                          <Translate
                                            text={status.toLowerCase()}
                                            upperCase={true}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={`badge badge-pill badge-status-${status ? status.toLowerCase() : "empty"
                                        } float-left`}
                                      style={{
                                        color: status ? "#fff" : "#333",
                                      }}
                                    >
                                      <DropDownMenu
                                        text={
                                          status
                                            ? status.toUpperCase()
                                            : "Select status"
                                        }
                                        keyVal="10045341"
                                        //className={`inputlink-addmore`}
                                        tIndex="-1"
                                        menu={__status}
                                        handleChange={handleStatusChange}
                                      ></DropDownMenu>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {_objCurrentStatus ? (
                                  <>
                                    <div
                                      className={`badge badge-pill badge-status-${status === tipStatus.NEW
                                        ? "open"
                                        : "empty"
                                        } float-left`}
                                      style={{
                                        background: _objCurrentStatus.color
                                          ? _objCurrentStatus.color
                                          : "#333",
                                      }}
                                    >
                                      <Translate
                                        text={_objCurrentStatus.name}
                                        upperCase={true}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={`badge badge-pill badge-status-${status.toLowerCase()} float-left`}
                                    >
                                      <Translate
                                        text={status.toLowerCase()}
                                        upperCase={true}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {offerPrice && accessToShowTradeInPrice ? (
                          <div className="tradein-offer-price ml-2">
                            <NumberFormat
                              value={offerPrice}
                              displayType={"text"}
                              decimalScale={2}
                              thousandSeparator={true}
                              prefix={currencySymbol}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          className={`badge badge-pill badge-testdrive-done float-right`}
                        >
                          <Translate text={"sent"} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {(() => {
                        if (
                          evalexpertID &&
                          localStorage.defaultModule !== "oem"
                        ) {
                          return (
                            <>
                              <div className="tradin-item-button">
                                <button
                                  type="button"
                                  className={`btn btn-primary float-left ${sendLoader ? "form-disable" : ""
                                    }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleSendEvalExpert();
                                  }}
                                >
                                  {sendLoader ? (
                                    <span
                                      className="spinner-border spinner-button mr-1"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    <></>
                                  )}
                                  <Translate text={"sendForEvaluation"} />
                                </button>
                              </div>
                            </>
                          );
                        } else if (
                          (tradeinPlusEnabled || tradeinProEnabled) &&
                          localStorage.defaultModule !== "oem"
                        ) {
                          return (
                            <>
                              <div className="tradin-item-button">
                                <button
                                  type="button"
                                  className={`btn btn-primary float-left ${sendLoader ? "form-disable" : ""
                                    }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    isValidForTradeInSend();
                                  }}
                                >
                                  {sendLoader ? (
                                    <span
                                      className="spinner-border spinner-button mr-1"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    <></>
                                  )}
                                  <Translate text={"sendForEvaluation"} />
                                </button>
                              </div>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <div className="tradin-item-button">
                                <button
                                  type="button"
                                  className={`btn btn-primary float-left ${sendLoader ? "form-disable" : ""
                                    }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleSendMail();
                                  }}
                                >
                                  {sendLoader ? (
                                    <span
                                      className="spinner-border spinner-button mr-1"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    <></>
                                  )}
                                  <Translate text={"sendEmail"} />
                                </button>
                              </div>
                            </>
                          );
                        }
                      })()}
                    </>
                  )}


                  {
                    showTag && (ppsrEnabled || (accessToMarketOverlay && (autoGrabEnabled || (autograbModulerEnabled && autoGrabModulerVehicleDetailEnabled))))
                      ?
                      <>
                        <div className="tradin-item-ppsr mt-2">
                          <div className="float-right">

                            {ppsrEnabled && (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setShowPpsrScan(true);
                                }}
                              >
                                <img
                                  src={_images.ppsr_logo}
                                  width="12"
                                  className="mr-1"
                                  style={{ marginTop: "-2px" }}
                                />
                                {"PPSR"}
                              </button>

                            )}

                            {(accessToMarketOverlay && (autoGrabEnabled ||
                              (autograbModulerEnabled &&
                                autoGrabModulerVehicleDetailEnabled))) && (
                                <button
                                  type="button"
                                  className="btn btn-secondary ml-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (_.isEmpty(tradein.mileage) || tradein.mileage === '0' || _.isEmpty(tradein.chassisNo))
                                      setShowTradein(true);
                                    else
                                      handleAutoGrap(documentID, tradein);
                                  }}
                                >
                                  <i className="ico icon-market-overlay"></i>{" "}
                                  <Translate text={"Market Overlay"} />
                                </button>
                              )}

                          </div>
                        </div>
                      </>
                      :
                      <></>
                  }
                </>
            }



            <PopUpModal show={evaluatorShow}>
              <Evaluators
                show={evaluatorShow}
                title={"Evaluators"}
                dealersettings={dealersettings}
                handleClose={handleCloseEval}
              ></Evaluators>
            </PopUpModal>

            <PopUpModal show={proEvaluatorShow}>
              <ProEvaluators
                show={proEvaluatorShow}
                title={"Appraisal Evaluators"}
                dealersettings={dealersettings}
                clientUsers={clientUsers}
                handleClose={handleCloseProEval}
              ></ProEvaluators>
            </PopUpModal>

            <PopUpModal show={stockModal.show}>
              <AddStock
                docID={stockModal.ID}
                show={stockModal.show}
                clsActive={stockModal.clsActive}
                handleClose={handleStockClose}
                title={stockModal.title}
                prefillTradeData={stockModal.prefillData}
                enableSale={true}
                enableTestDrive={false}
                enableLoan={false}
                dealersettings={dealersettings}
                clientUsers={clientUsers}
                groupUsers={groupUsers}
              ></AddStock>
            </PopUpModal>
          </div>
        </>
      )}

      <PopUpModal show={showPpsrScan}>
        <PpsrScan
          show={showPpsrScan}
          clsActive="overlay-modal active"
          regNo={tradein?.regNo}
          regState={tradein?.regState}
          enquiryID={tradein?.enquiryID}
          serviceID={tradein?.serviceID}
          clientID={tradein?.clientID}
          tradeinID={tradein?.documentID}
          chassisNo={tradein?.chassisNo}
          dealersettings={dealersettings}
          clientUsers={clientUsers}
          groupUsers={groupUsers}
          handleClose={() => {
            setShowPpsrScan(false);
          }}
        ></PpsrScan>
      </PopUpModal>
      <PopUpModal show={showTradein}>
        <TradeInFields
          show={showTradein}
          tradeinID={tradein?.documentID}
          tradein={tradein}
          dealersettings={dealersettings}
          handleClose={handleModalClose}
        >
        </TradeInFields>
      </PopUpModal>
    </>
  );
};

export default TradeInInfo;
