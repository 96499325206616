/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import _ from 'lodash';
/** COMPONENTS */
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import {
    objContact
} from '../../contacts/viewModel';
import {
    objTradeIP
} from '../viewModel';
import { firestoreDB } from '../../../services/helper';
//#region  ASSIGN VARIABLES
let titles = [];
//#endregion

export default class NotifyServiceRequest extends Component {
    constructor(props) {
        super(props);
        this.isLoading = true;
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.unsubscribeConfirmTIP = null;
        this.state = {
            fields: Object.assign({}, objTradeIP),
            contact: Object.assign({}, objContact)
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadEnquiry();
    }

    componentWillUnmount() {
        this.unsubscribeConfirmTIP && this.unsubscribeConfirmTIP();
    }

    async loadEnquiry() {
        const { docID, dealersettings, clientUsers } = this.props;
        if (docID && dealersettings) {
            if (dealersettings.client.tradeinPro.pipelines.length === 0) {
                this.props.history.push('/tradeinpro');
                return
            }
            const refData = await firestoreDB(dealersettings).firestore().collection('tradeinPro').doc(docID).get();

            if (refData.exists) {
                if (!dealersettings.client.tradeinPro.pipelines.some(item => item.value === refData.data().pipeline)) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have pipleine access. Please contact administrator.'), '', 'info')
                    this.props.history.push('/pipeline');
                    return
                }
                const _tradeinpro = Object.assign({}, objTradeIP);
                const contact = Object.assign({}, objContact);

                const _clientUsers = [];
                for (let [key, value] of Object.entries(refData.data())) {
                    _tradeinpro[key] = value;
                }
                _tradeinpro.documentID = refData.id;

                if (_tradeinpro.isContact && !_.isEmpty(_tradeinpro['contact'])) {
                    const refContactData = await this.refContact.doc(_tradeinpro['contact'].documentID).get();
                    if (refContactData.exists) {
                        const objcontactData = Object.assign({}, refContactData.data());
                        for (let [key, value] of Object.entries(objcontactData)) {
                            contact[key] = value;
                        }
                        contact.documentID = _tradeinpro['contact'].documentID;
                    }
                }
                else if (!_.isEmpty(_tradeinpro.engageNewContact)) {
                    for (let [key, value] of Object.entries(_tradeinpro.engageNewContact)) {
                        contact[key] = value;
                    }
                }

                if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
                    const setttings = dealersettings.client.settings;

                    titles = [];
                    setttings.titles && setttings.titles.forEach((doc) => {
                        titles.push({
                            value: doc.value,
                            label: doc.name
                        });
                    });

                }

                if (clientUsers) {
                    clientUsers.forEach(doc => {
                        _clientUsers.push({
                            value: doc.id,
                            searchlabel: doc.name,
                            label: (
                                <div className='drop-image'>
                                    <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                                    {doc.name}
                                </div>
                            ),
                        });
                    });
                }

                this.isLoading = false;
                this.setState({
                    fields: _tradeinpro,
                    contact: contact,
                    clientUsers: _clientUsers
                });

                this.unsubscribeConfirmTIP = this.ref
                    .doc(docID)
                    .onSnapshot(this.onCollectionUpdate);

            }
        }
    }

    onCollectionUpdate = (docSnapshot) => {

        if (!docSnapshot.exists) {
            return;
        }

        const fields = Object.assign({}, this.state.fields);
        const objData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }
        fields.documentID = docSnapshot.id;
        this.setState({ fields: fields });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isLoading = true;

        const { setNotifyClose, handleModuleChange, handleClose } = this.props;
        if (setNotifyClose)
            setNotifyClose(true)

        handleClose(this.state.fields)


        if (handleModuleChange && localStorage.defaultModule !== 'tradeinPro') {
            localStorage.setItem('objNotify', JSON.stringify({ id: this.state.fields.documentID, type: 'tradeinPro' }));
            localStorage.setItem('defaultModule', 'tradeinPro');
            handleModuleChange('tradeinPro');
        }
        else {
            this.props.history.push(`/tradeinpro/details/${this.state.fields.documentID}`);
        }


    };

    handleAcceptSubmit = (e) => {
        e.preventDefault();
        this.isLoading = true;
        const { documentID } = this.state.fields;
        let objData = {
            owner: localStorage.uid,
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
        }
        firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro').doc(documentID).set(objData, { merge: true }).then(res => {
            this.handleSubmit(e);
        })


    };

    render() {
        const { dealersettings, clientUsers, groupUsers } = this.props;
        const { fields, contact } = this.state;
        const _settings = dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings;
        const _tag = !_.isEmpty(fields?.tags) ? fields?.tags[fields?.tags.length - 1]?.type : 'AMS PRO'

        return (
            this.isLoading
                ?
                <></> :
                <>
                    <Modal
                        show={this.props.show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-service-lead">
                        <Modal.Header >
                            <Modal.Title>
                                <Translate text={this.props.title} />
                                {fields.displayID ? <div className="lead-source-badge"><div className="tradein-valuation-num text-uppercase"><Translate text={'Valuation No'} />: {fields.displayID}</div></div> : <></>}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="newlead-assigned-head">
                                            <div className="vehicle-status-alert alert-user">
                                                <b className="alert-link">{CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), fields.modifiedBy)}</b> request a new appraisal from {_tag}.
                                            </div>

                                        </div>
                                        <div className="lead-service-pop-body">
                                            <div className="lead-service-pop-contact-details">
                                                {/* <div className="vehicle-status-alert alert-user mt-2"> <b className="alert-link">Richard Roberts</b> assigned you .. text here. </div>
                                                <h3> Appraisal Details</h3>

                                                <div className="lead-service-pop-box">
                                                    <div className="lead-service-pop-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'Job Number'} /> </span> {fields.displayID ? fields.displayID : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'roNumber'} /></span> {fields.roNumber ? fields.roNumber : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'appointmentDate'} /></span>
                                                                        {fields.appointmentDate ? moment(fields.appointmentDate).format('DD MMM YYYY h:mm A') : '--'}
                                                                    </td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'slot'} /></span>
                                                                        {
                                                                            !_.isEmpty(fields.slot)
                                                                                ?
                                                                                <>
                                                                                    <span style={{ color: `${fields.slot.color ? fields.slot.color : '#f47815 '}` }}>
                                                                                        <i className="ico icon-service-timer"></i> {fields.slot.name}
                                                                                        {
                                                                                            fields.slot.isExpress ? <span className="express-inline-tag"><div className="badge red-ribbon ml-2 express-fix"><Translate text={'express'} upperCase={true}/></div></span> : <></>
                                                                                        }
                                                                                    </span>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> */}

                                                <h3> <Translate text={'contactDetails'} /></h3>
                                                <div className="lead-service-pop-box">
                                                    <div className="lead-service-pop-contact-head">
                                                        <div className="lead-service-pop-contact-title"> {CommonHelper.displayFullContact(titles, contact, '--')}</div>

                                                        <div className="lead-service-pop-contact-sub">
                                                            <ul className="lead-service-pop-contact-details">
                                                                <li> <i className="ico icon-enquiry"></i> {contact && contact.displayID ? contact.displayID : '--'}</li>
                                                                <li> <i className="ico icon-call"></i> {contact && contact.phone ? ((contact.phoneCode ? contact.phoneCode : '') + contact.phone) : '--'}</li>
                                                                <li> <i className="ico icon-email"></i> {contact && contact.email ? contact.email : '--'}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="lead-service-pop-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'licenseNo'} /></span> {contact && contact.licenseNo ? contact.licenseNo : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'gender'} /></span> {contact && contact.gender ? CommonHelper.getNameByValue(_settings ? _settings.genders : [], contact.gender, '') : '--'}</td>
                                                                    <td colSpan="2"><span className="table-label"><Translate text={'address'} /></span> {contact && contact.address ? contact.address : '--'} </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <h3> <Translate text={'vehicleDetails'} /></h3>
                                                <div className="lead-service-pop-box">
                                                    <div className="lead-service-pop-vehicle-head">
                                                        <div className="flex-vehicle">
                                                            <div className="vehicle-item-image"> <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                <img src={CommonHelper.showBrandLogo(dealersettings, fields?.tradein?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                            </a> </div>
                                                            <div className="vehicle-data">
                                                                <div className="vehicle-item-title">{fields?.tradein?.make} &nbsp;
                                                                    {fields?.tradein?.model}</div>
                                                                <div className="vehicle-item-info">{fields.tradein && fields.tradein.year ? (fields.tradein.year) : ''}</div>
                                                                <div className="vehicle-item-info"><Translate text={'reg'} />#: {fields.tradein && fields.tradein.rego ? (fields.tradein.rego) : (fields.tradein && fields.tradein.regNo ? (fields.tradein.regNo) : '--')}</div>
                                                                <div className="vehicle-item-info"><Translate text={'VIN'} />#: {fields.tradein && fields.tradein.chassisNo ? (fields.tradein.chassisNo) : '--'}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <button type="button"
                                className={`btn btn-default float-left`}
                                onClick={(e) => { e.preventDefault(); this.props.handleClose(); }} >
                                <Translate text={'cancel'} />
                            </button>
                            {
                                fields.owner
                                    ?
                                    <button type="button"
                                        className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                                        onClick={(e) => this.handleSubmit(e)} >
                                        {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                        <Translate text={'view'} />
                                    </button>
                                    :
                                    <button type="button"
                                        className={`btn btn-green float-right ${this.isLoading ? 'form-disable' : ''}`}
                                        onClick={(e) => this.handleAcceptSubmit(e)} >
                                        {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                        <Translate text={'accept'} />
                                    </button>
                            }

                        </Modal.Footer>
                    </Modal>

                </>
        );
    }
}
