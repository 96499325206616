/** LIBRARIES */
import React, { useEffect, Suspense } from 'react';
import _ from 'lodash'
/** PROVIDERS */
/** COMPONENTS */

import MainContainer from './container';


const EventsLayout = (props) => {



    useEffect(() => {
        let pathname = window.location.pathname;
        if (pathname.indexOf('attendee/report/testdrive') >= 0 || pathname.indexOf('attendee/report/consent') >= 0) {
            document.getElementsByTagName('body')[0].classList.add('testdrive-page-landing');

        } else if (pathname.indexOf('attendee/report') >= 0) {
            document.getElementsByTagName('body')[0].classList.add('daily-page-landing');

        } else {
            document.getElementsByTagName('body')[0].classList.add('confirmation-page-landing');
        }
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
        }
    }, [])





    return (
        <Suspense fallback={<></>}>
            <>
                <MainContainer history={props.history} />
            </>
        </Suspense>
    );
}

export default EventsLayout;