import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Nav } from 'react-bootstrap'
import _ from 'lodash'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { doc } from 'rxfire/firestore';
import Lookups from './lookups/index'
import FieldSettings from './fieldSettings'
import Translate from '../../../constants/translate';
import { TemplateContext } from './templateContext'
import SortFleetFields from "./fleetOptions";
import FleetTerms from './terms';
import CustomAlerts from './alerts';

const FleetProWorkFlowSettings = (props) => {
    const [active, setActive] = useState('fields')
    const [loader, setLoader] = useState(true)
    const [clientGroups, setClientGroups] = useState([]);
    const [oemClients, setOEMClients] = useState([]);
    const [clientRegions, setClientRegions] = useState([])
    const [clientSettings, setClientSettings] = useState([])

    const [clientSettingsFleet, setClientSettingsFleet] = useState([])
    const [clientGroupsFleet, setClientGroupsFleet] = useState([])
    const [clientRegionsFleet, setClientRegionsFleet] = useState([])
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);
    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        const groupSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/groups`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _groups = [];
                querySnapshot.forEach((rec) => {
                    _groups.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientGroups(_groups)
            })
        return () => {
            groupSnapshot && groupSnapshot();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientGroups))
            return

        const refs = []
        clientGroups.forEach(group => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${group.documentID}/fleetPro/${group.documentID}`)))
        })

        var groups_fleetpro = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    let valToReturn = []
                    lookups.forEach((lookup) => {
                        valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id, name: getGroupOrRegionName(lookup.id, clientGroups) })
                    })
                    return valToReturn
                }))
            .subscribe(allDocs => {
                setClientGroupsFleet(allDocs)
            });
        return () => {
            groups_fleetpro && groups_fleetpro.unsubscribe()
        }
    }, [clientGroups])

    useEffect(() => {
        const regionSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/regions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _regions = [];
                querySnapshot.forEach((rec) => {
                    _regions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientRegions(_regions)
            })
        return () => {
            regionSnapshot && regionSnapshot();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientRegions))
            return
        const refs = []
        clientRegions.forEach(region => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${region.documentID}/fleetPro/${region.documentID}`)))
        })

        var regions_fleet = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    let valToReturn = []
                    lookups.forEach((lookup) => {
                        valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id, code: lookup.id, name: getGroupOrRegionName(lookup.id, clientRegions) })
                    })
                    return valToReturn
                }))
            .subscribe(allDocs => {
                setClientRegionsFleet(allDocs)
            });
        return () => {
            regions_fleet && regions_fleet.unsubscribe()
        }
    }, [clientRegions])

    useEffect(() => {
        const clientsSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/clients`)
            .onSnapshot(querySnapshot => {
                let _client = [];
                querySnapshot.forEach((rec) => {
                    _client.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientSettings(_client)
            })
        return () => {
            clientsSnapshot && clientsSnapshot();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientSettings))
            return

        const refs = []
        clientSettings.forEach(clientOption => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${clientOption.documentID}/fleetPro/${clientOption.documentID}`)))
        })
        var clients_fleetpro = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    let valToReturn = []
                    lookups.forEach((lookup) => {
                        valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id },)
                    })
                    return valToReturn
                }))
            .subscribe(allDocs => {
                setClientSettingsFleet(allDocs)
            });
        return () => {
            clients_fleetpro && clients_fleetpro.unsubscribe()
        }
    }, [clientSettings])

    useEffect(() => {
        const oemclientSnapshot = window.firebase.firestore().collection('clients')
            .where('settingsID', '==', props.dealersettings.client.settingsID)
            .onSnapshot(querySnapshot => {
                let _clients = [];
                querySnapshot.forEach((rec) => {
                    _clients.push({ ...rec.data(), 'documentID': rec.id })
                })
                setOEMClients(_clients)
            })
        return () => {
            oemclientSnapshot && oemclientSnapshot();
        }
    }, [])
    const clientsMemo = useMemo(() => oemClients, [oemClients])

    const groupsMemo = useMemo(() => clientGroupsFleet, [clientGroupsFleet])
    const regionsMemo = useMemo(() => clientRegionsFleet, [clientRegionsFleet])
    const clientSettingsMemo = useMemo(() => clientSettingsFleet, [clientSettingsFleet])

    const getQueryparam = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (!key) {
            key = "fields"
        }
        return key
    }

    const getGroupOrRegionName = (id, dataArr) => {
        if (dataArr.filter(a => a.documentID === id).length && dataArr.filter(a => a.documentID === id)[0].name) {
            return dataArr.filter(a => a.documentID === id)[0].name
        } else {
            return ''
        }
    }

    return (
        <>
            <Tab.Container defaultActiveKey={`${getQueryparam()}`} mountOnEnter={true}
                onSelect={(key) => {
                    setActive(key)
                }}>
                <div className="settings-submenu">
                    <h4> <Translate text={'Fleet Pro Workflow Setup'} /></h4>
                    <Nav as="ul" bsPrefix="ul-nav">
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="fields" bsPrefix="li-a-nav"> <Translate text={'Mandatory Fields'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="lookups" bsPrefix="li-a-nav"> <Translate text={'Dynamic Fields'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="enquiryOptions" bsPrefix="li-a-nav"> <Translate text={'Fleet Fields'} /></Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="terms" bsPrefix="li-a-nav"> <Translate text={'Fleet Terms'} /></Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="alerts" bsPrefix="li-a-nav"> <Translate text={'alerts'} /></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <TemplateContext.Provider value={{ loading: loader, groups: groupsMemo, clients: clientsMemo, regions: regionsMemo, clientSettings: clientSettingsMemo }}>
                    <div className={`settings-container mb-0 ${liteVersion ? '' : 'h-100'}`} style={liteVersion ? { overflow: 'hidden' } : {}}>
                        <div className="settings-container-sub" >
                            <Tab.Pane eventKey="fields">
                                <FieldSettings activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="lookups">
                                <Lookups activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="enquiryOptions">
                                <SortFleetFields activeKey={active} {...props} />
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="terms">
                                <FleetTerms activeKey={active} {...props} />
                            </Tab.Pane> */}
                            <Tab.Pane eventKey="alerts">
                                <CustomAlerts activeKey={active} {...props} />
                            </Tab.Pane>
                        </div>
                    </div>
                </TemplateContext.Provider>
            </Tab.Container>
        </>
    )
}

export default FleetProWorkFlowSettings;