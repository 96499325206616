
/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Swal from 'sweetalert2';
import CommonHelper from '../../services/common';
import { ReactSelect } from '../../components';
import Translate from '../../constants/translate';
import Filters from './filters'
import moment from 'moment'
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [events, setEvents] = useState(props.events);
    const [attendeeFilter, setContactFilter] = useState({})
    const [searchText, setSearchText] = useState(props.searchText)
    const [filterEvent, setFilterEvent] = useState(props.filterEvent)


    const _csv_title = _.find(props.events, { value: props.filterEvent }) ? _.find(props.events, { value: props.filterEvent })?.searchlabel : 'attendees'
    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {
        setEvents(props.events)
        if (!props.filterEvent)
            setFilterEvent(props.dealersettings?.client?.id);
        else
            setFilterEvent(props.filterEvent);
    }, [props.filterEvent])






    useEffect(() => {
        if (!_.isEmpty(localStorage.attendeeFilter) && Object.keys(JSON.parse(localStorage.attendeeFilter)).length > 0) {
            let _contactFilter = JSON.parse(localStorage.attendeeFilter);
            let _contactValue = _contactFilter.value;

            if (!_.isEmpty(_contactValue) && _contactValue.hasOwnProperty('keywords'))
                setSearchKeywords(_contactValue['keywords']);
            else
                setSearchKeywords('');

            setContactFilter(_contactFilter);
        }
        else {
            setContactFilter({});
            setSearchKeywords('');
        }

    }, [localStorage.attendeeFilter])



    const exportData = props.dealersettings?.rolePermissions?.eventsProPermissions?.exportData && props.dataCount > 0 ? true : false;

    return (
        <div className="filter-panel">

            <div className="tabber-nav float-left">
                <ul>
                    <li><a href="#" className={`${props.activeTab === 'unallocated' ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleSetActiveTab('unallocated');
                        }}><Translate text={'unallocated'} />
                        {/* {props.inboundCount ? <span className="badge badge-pill badge-counter ml-1">{props.inboundCount.unallocated}</span> : <></>} */}
                    </a></li>

                    <li><a href="#" className={`${props.activeTab === 'allocated' ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleSetActiveTab('allocated')
                        }}
                    > <Translate text={'allocated'} />
                        {/* {props.inboundCount ? <span className="badge badge-pill badge-counter ml-1">{props.inboundCount.allocated}</span> : <></>} */}
                    </a></li>


                </ul>
            </div>

            {/* FILTER SEARCH KEYWORDS*/}
            <div className={`filter-search ml-2`}>
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="quick search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value
                            setSearchText(_val);
                            localStorage.attendeeSearchText = _val;

                            if (_val && _val.length > 2) {
                                props.handlesearchText(_val)
                            }

                            if (!_val)
                                props.handlesearchText('')
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                localStorage.attendeeSearchText = searchText;
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.attendeeSearchText = '';
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                localStorage.attendeeSearchText = searchText;
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>

            </div>


            {/* <div className="form-loan-history form-row ml-2">
                <div className="form-group col-md-12 daterange-fix">
                    <ReactSelect
                        options={regions}
                        name={"region"}
                        placeholder={'select region'}
                        onChange={(e) => {
                            if (e) {
                                setRegion(e.value);
                                setDealer('');
                            }
                            else {
                                setRegion('');
                                setDealer('');
                            }
                        }}
                        value={region}
                        classNamePrefix={`basic-select`}
                    >
                    </ReactSelect>
                </div>
            </div>

            <div className="form-loan-history form-row ml-2">
                <div className="form-group col-md-12 daterange-fix">
                    <ReactSelect
                        options={region ? dealers.filter(e => e.region === region) : dealers}
                        name={"dealer"}
                        placeholder={'select dealer'}
                        onChange={(e) => {
                            if (e) {
                                setDealer(e.value);
                                let _events = events.filter(m => !_.isEmpty(m.clientIDs) && m.clientIDs.includes(e.value));
                                if (!_.find(_events, { value: filterEvent })) {
                                    setFilterEvent(_events[0]?.value ? _events[0].value : '')
                                    props.handleFilterEvent(_events[0]?.value ? _events[0].value : null)
                                }
                            }
                            else {
                                setDealer('');
                            }
                        }}
                        value={dealer}
                        classNamePrefix={`basic-select`}
                    >
                    </ReactSelect>
                </div>
            </div> */}

            {
                !_.isEmpty(events)
                    ?
                    <div className="form-loan-history form-row ml-2">
                        <div className="form-group col-md-12 daterange-fix">
                            <ReactSelect
                                options={events}
                                name={"filterEvent"}
                                placeholder={'select event'}
                                onChange={(e) => {
                                    if (e) {
                                        setFilterEvent(e.value);
                                        props.handleFilterEvent(e.value)
                                    }
                                    else {
                                        setFilterEvent('');
                                        props.handleFilterEvent(null)
                                    }
                                }}
                                value={filterEvent}
                                classNamePrefix={`basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>
                    </div>
                    :
                    <></>
            }

            {
                (!_.isEmpty(props.selectedIds)) ?
                    (<>
                        <div className="list-count-btn ml-3" style={{ float: 'left' }}>
                            <span>{'Selected'}  {Object.keys(props.selectedIds).length > 1 ? ' Attendees' : ' Attendee'}</span>
                            <span className="list-count"> {Object.keys(props.selectedIds).length} </span>
                        </div>
                    </>)
                    : (<></>)
            }

            <div className="float-right">
                {
                    props.activeTab === 'unallocated'
                        ?
                        <>
                            <div className="filter-add-button ml-2">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (Object.keys(props.selectedIds).length > 0) {
                                            props.handleOpenAllocateModal()
                                        } else {
                                            Swal.fire(CommonHelper.showLocale(props, 'Please select one or more attendee(s) to allocate.'), '', 'info');
                                            return;
                                        }
                                    }
                                    }
                                > <Translate text={'allocate'} /></button>
                            </div>
                        </>
                        :
                        <></>
                }

                {
                    (exportData)
                        ?
                        <>
                            <div className="filter-item-btn ml-2">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    props.excelDownload();
                                }} title={'Excel Export'}>
                                    <i className="ico icon-excel-download"></i>
                                </a>
                                <ExcelFile ref={props.csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`${props.activeTab}_${_csv_title}_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                    <ExcelSheet dataSet={props.csvData} name={`Sheet1`} />
                                </ExcelFile>
                            </div>
                        </>
                        :
                        <>
                        </>
                }
            </div>

            <Filters
                searchKeywords={searchKeywords}
                pipelineFilter={attendeeFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                eventSettings={props.eventSettings}
                noSaveAccess={true}
            >
            </Filters>


            {/* {
                ((!_.isEmpty(props.contactCount) || props.contactCount > 0) && !localStorage.attendeeSearchText && !myContactsOnly && !props.isUsedInAudience) ?
                    (<>
                        <div className="list-count-btn mr-3" onClick={(e) => {
                            e.preventDefault();
                            console.log('dealersettings', props.dealersettings)
                        }}>
                            <span>  <Translate text={props.contactCount > 1 ? 'contacts' : 'contact'} /></span>
                            <span className="list-count"> {props.contactCount} </span>
                        </div>
                    </>)
                    : (<></>)
            }
            {
                props.isUsedInAudience && (!_.isEmpty(props.contactCount) || props.contactCount > 0) ?
                    (<>
                        <div className="list-count-btn mr-3" onClick={(e) => {
                            e.preventDefault();
                            console.log('dealersettings', props.dealersettings)
                        }}>
                            <span>  <Translate text={props.contactCount > 1 ? 'contacts' : 'contact'} /></span>
                            <span className="list-count"> {props.contactCount} </span>
                        </div>
                    </>)
                    : (<></>)
            } */}


        </div >
    )
}


export default FilterPanel
