/** LIBRARIES */
import React, { Component } from "react";
//import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from 'moment';
// import NumberFormat from 'react-number-format';

class AntMonthPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.value !== this.props.value;
    // }
    //onDatepickerRef = (el) => { if (el && el.input) { el.input.readOnly = true; } }
    render() {
        const { name, onChange, placeholder, value, className, readOnly, disabledDate, disabled,picker,format } = this.props;
        return (
            <DatePicker
                //ref={el => this.onDatepickerRef(el)}
                id={name}
                value={value ? moment(moment(value)._d) : null}
                onChange={onChange}
                className={className}
                picker={picker}
                format={format}
                placeholder={placeholder}
                showToday={false}
                inputReadOnly={readOnly}
                disabledDate={disabledDate}
                disabled={disabled}
            />
        );
    }
}

export default AntMonthPicker;