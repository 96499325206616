/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { InputText, EvalSearch, PopUpModal } from '../../components';



export default class VinScan extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            fields: {
                vinNum: (this.props.chassisNo ? this.props.chassisNo.toUpperCase() : ''),
            },
            errors: {},
            showEvalScan: false,
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    /*componentDidMount() {
        if (this.props.chassisNo) {
            let fields = Object.assign({}, this.state.fields);
            fields.vinNum = this.props.chassisNo.toUpperCase();
            this.setState({ fields: fields });
        }
    }*/

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.toUpperCase();
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name) });
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        //console.log('handleSubmit refId=>', this.newDocID);
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;

            }
        }
        this.setState({ errors: errors });

        if (formIsValid === true) {
            if (this.props.isEvalExpert)
                this.searchEvalExpert(this);
            else
                this.searchScan(this);
        }
        else
            return formIsValid;
    };

    searchEvalExpert = () => {
        const { dealersettings } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';
        const objData = Object.assign({}, this.state.fields);
        this.isLoading = true;
        const evalXpertSearch = window.firebase.functions().httpsCallable(`evalexpert-search`);
        evalXpertSearch({ clientID: clientID, vinNo: objData.vinNum }).then((_response) => {
            this.isLoading = false;
            //console.log('evalexpert-search', _response);
            //check for success response
            if (_response && _response.data && _response.data.success) {
                //console.log('evalexpert-search--', _response.data.data[0]);
                this.props.handleClose(_response.data.data && _response.data.data[0]);
            }
            else {
                this.setState({ showEvalScan: true })
                // Swal.fire('No record found!', '', 'info')
                // this.forceUpdate()
            }
        });

    }

    searchScan = (e) => {
        const objData = Object.assign({}, this.state.fields);
        const { dealersettings } = this.props;
        this.isLoading = true;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Vin: objData.vinNum })
        };

        let tradeinplusURL = dealersettings && dealersettings.tradeinplusURL ? dealersettings.tradeinplusURL : 'https://demo.fusionsd.net/tradeinplusqa';
        let url = tradeinplusURL + '/api/TradeInApp/Action/GetNevdisVehicle'
        fetch(url, requestOptions)
            .then(async response => {
                const data = await response.json();
                this.isLoading = false;
                //check for success response
                if (response.ok && data && data.success) {
                    this.props.handleClose(data.data);
                }
                else {
                    Swal.fire(CommonHelper.showLocale(this.props, 'No record found!'), '', 'info')
                    this.forceUpdate();
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

    };

    handleEvalScanClose = (data) => {
        if (!_.isEmpty(data)) {
            this.props.handleClose({
                ...data,
                EvalID: data.EvalID ? data.EvalID.toString() : null,
                //VIN: this.state.fields.vinNum,
                Registration: null
            });
        }
        this.setState({ showEvalScan: false })
    }

    render() {
        const { vinNum } = this.state.fields;
        const { show, isGenericLayout } = this.props;
        const { showEvalScan } = this.state;

        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'searchForVehicleUsingChassisNo'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className={`container-fluid ${isGenericLayout ? 'pl-0 pr-0' : ''}`}>
                            <div className={`${isGenericLayout ? 'w-100 d-block border-0' : 'row'}`}>
                                <div className="search-pop form-style">
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'chassisNo'} /></label>
                                            <InputText
                                                type="text"
                                                name="vinNum"
                                                value={vinNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'chassis/vin number'}
                                                className={`form-control ${this.state.errors["vinNum"]}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                            {this.isLoading ? <i className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></i> : <></>}
                            <Translate text={'search'} />
                        </button>
                    </Modal.Footer>
                </Modal>

                <PopUpModal show={showEvalScan}>
                    <EvalSearch
                        show={showEvalScan}
                        clsActive='overlay-modal active'
                        vinNum={vinNum}
                        dealersettings={this.props.dealersettings}
                        handleClose={this.handleEvalScanClose}
                    >
                    </EvalSearch>
                </PopUpModal>
            </>


        );
    }
}

