/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../../styles/enquiry-preview.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { LoaderOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { enquiryStatus } from '../../../services/enum';
import { firestoreDB } from '../../../services/helper';

//#region  ASSIGN VARIABLES

const engineTypes = [
    { active: true, value: 'Petrol', label: <Translate text={'Petrol'} /> },
    { active: true, value: 'Diesel', label: <Translate text={'Diesel'} /> },
    { active: true, value: 'Electric', label: <Translate text={'Electric'} /> },
    { active: true, value: 'Hybrid', label: <Translate text={'Hybrid'} /> }
]

// const labels = [
//     { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
//     { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
//     { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]
//#endregion

const EnquiryPreview = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [enquiryData, setEnquiryData] = useState({})
    const [isPDF, setPDF] = useState(false)

    let {
        documentID, clientID, contactID, displayID,
        pipeline, stage, status, enquiryType,
        label, purchaseIntention, contractNumber,
        depositAmount, depositReciptNumber, grossAmount, origin, campaign,
        requirement, owner, leadSource, serviceType, isAfterSales,
        isFav, isContact, isVehicle, isTestDrive, isTradeIn, isCafe, isFiles, isVideo, isFinance, isQuotation,
        addedBy, addedDate, modifiedDate, modifiedBy,
        lostReason, lostSubReason,
        deliveryDate, lostDate, wonDate, deliveredDate,
        chassisNo, regNo, stockNo, warrantyExpiry, warrantyStartDate,
        isFinanceRequired, financeStatus, options, lastStatus,
        verifiedBy, verifiedDate, tags, dynamicFields, receivedDate, modifiedFrom,
        convertedBy, convertedDate, isDeleted, isNewEnquiry,
        contact, requirements, tradeIns, testdrives,
        cafeOrders, activities,
        client, currentSettings, users,
    } = enquiryData;

    status = (status && status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : status;



    const currencySymbol = ((!_.isEmpty(enquiryData) &&
        !_.isEmpty(enquiryData.client) &&
        !_.isEmpty(enquiryData.client.currency)) ? enquiryData.client.currency.symbol + ' ' : '$ ');

    const _settings = ((!_.isEmpty(enquiryData) &&
        !_.isEmpty(enquiryData.currentSettings) &&
        !_.isEmpty(enquiryData.currentSettings.settings)) ? enquiryData.currentSettings.settings : null);

    const isStatusOpen = CommonHelper.isEnqStatusOpen(_settings && _settings.enquiryStatus, status)
    const isStatusSold = CommonHelper.isEnqStatusSold(_settings && _settings.enquiryStatus, status);

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`enquiries/${props.enquiryid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB)) return
        //console.log('ServicePreview', props.enquiryid);
        if (props.enquiryid) {
            loadEnquiryData();
        }
        return () => {
            window.refCollectionData && window.refCollectionData.unsubscribe()
        }
    }, [firestoreDB])

    const loadEnquiryData = async () => {


        window.refCollectionData = docData(firestoreDB.firestore().doc(`enquiries/${props.enquiryid}`))
            .pipe(
                switchMap(enquiryDetail => {
                    //console.log(enquiryDetail);
                    let _contactID = enquiryDetail.contactID ? enquiryDetail.contactID : enquiryDetail?.contact?.documentID
                    const enquiryIds = [props.enquiryid];
                    const contactIds = _contactID ? [_contactID] : [];
                    const clientIds = [enquiryDetail.clientID];
                    return combineLatest(
                        of(enquiryDetail),
                        combineLatest(
                            contactIds.length > 0 ? contactIds.map(contactID =>
                                collectionData(firestoreDB.firestore().collection('contactDetails').where('documentID', '==', contactID)).pipe(
                                    map(contactSnapshot => contactSnapshot[0] ? contactSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            enquiryIds.length > 0 ? enquiryIds.map(enquiryID =>
                                collectionData(firestoreDB.firestore().collection(`enquiries/${enquiryID}/requirements`).where('isDeleted', '==', false)).pipe(
                                    map(reqSnapshot => reqSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            enquiryIds.length > 0 ? enquiryIds.map(enquiryID =>
                                collectionData(firestoreDB.firestore().collection(`tradeins`).where('enquiryID', '==', enquiryID).where('isDeleted', '==', false)).pipe(
                                    map(tradeinsSnapshot => tradeinsSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            enquiryIds.length > 0 ? enquiryIds.map(enquiryID =>
                                collectionData(firestoreDB.firestore().collection(`testdrives`).where('enquiryID', '==', enquiryID).where('isDeleted', '==', false)).pipe(
                                    map(testdrivesSnapshot => testdrivesSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            enquiryIds.length > 0 ? enquiryIds.map(enquiryID =>
                                collectionData(firestoreDB.firestore().collection(`cafeOrders`).where('enquiryID', '==', enquiryID).where('isDeleted', '==', false)).pipe(
                                    map(cafeOrdersSnapshot => cafeOrdersSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            enquiryIds.length > 0 ? enquiryIds.map(enquiryID =>
                                collectionData(firestoreDB.firestore().collection(`activities`)
                                    .where('enquiryID', '==', enquiryID)
                                    .where('type', 'in', ['log', 'activity'])
                                    //.where('subType', '==', 'note')
                                    .where('isDeleted', '==', false)
                                ).pipe(
                                    map(activitiesSnapshot => activitiesSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            contactIds.length > 0 ? contactIds.map(contactID =>
                                collectionData(firestoreDB.firestore().collection(`activities`)
                                    .where('contactID', '==', contactID)
                                    .where('clientID', '==', enquiryDetail.clientID)
                                    .where('tags', 'array-contains-any', ['aircall', 'justcall', 'tss'])
                                    .where('type', '==', 'log')
                                    //.where('subType', '==', 'note')
                                    .where('isDeleted', '==', false)
                                ).pipe(
                                    map(aircallContactsLogs => aircallContactsLogs)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                    map(clientSettingsSnapshot => clientSettingsSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                    map(usersSnapshot => usersSnapshot)
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([
                    enquiryDetail, contactSnapshot, reqSnapshot, tradeinsSnapshot,
                    testdrivesSnapshot, cafeOrdersSnapshot, activitiesSnapshot, aircallContactsLogs,
                    clientSnapshot, clientSettingsSnapshot, usersSnapshot
                ]) => {
                    let _contactID = enquiryDetail.contactID ? enquiryDetail.contactID : enquiryDetail?.contact?.documentID
                    let activities = Object.assign([], activitiesSnapshot[0])
                    let activitiesCall = Object.assign([], aircallContactsLogs[0])
                    // console.log('activities', _.orderBy(activities.filter(a => a.type === 'log' ? ['note', 'call'].includes(a.subType) : true), ['addedDate'], ['desc']))
                    // console.log('activitiesCall', _.orderBy(activitiesCall, ['addedDate'], ['desc']))
                    let allActivities = _.uniqBy(activities.filter(a => a.type === 'log' ? ['note', 'call'].includes(a.subType) : true).concat(activitiesCall), 'documentID');
                    return {
                        ...enquiryDetail,
                        contact: _contactID ? contactSnapshot.find(a => a.documentID === _contactID) : enquiryDetail.contact,
                        requirements: reqSnapshot[0],
                        tradeIns: tradeinsSnapshot[0],
                        testdrives: testdrivesSnapshot[0],
                        cafeOrders: cafeOrdersSnapshot[0],
                        activities: allActivities.filter(a => !_.isEmpty(a.notes)),
                        currentSettings: clientSettingsSnapshot[0],
                        client: clientSnapshot.find(a => a.documentID === enquiryDetail.clientID),
                        users: clientSettingsSnapshot[0] ? clientSettingsSnapshot[0].users : usersSnapshot[0],
                    }

                })
            )
            .subscribe(_enquiryDetail => {
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let backURL = params.get('isView');

                setPDF(backURL ? false : true);

                if (!_.isEmpty(_enquiryDetail)) {
                    setEnquiryData(_enquiryDetail);
                    setIsLoading(false);
                }
                window.refCollectionData && window.refCollectionData.unsubscribe()
                //console.log('ServicePreview_enquiryDetail', _enquiryDetail);

            });

    }



    let objCurrentStatus = status && _settings && _settings.enquiryStatus && _settings.enquiryStatus.filter(e => e.value === status)[0]
    let enquiryOptionsStatic = Object.assign([], _settings?.enquiryOptionsStatic)

    return (
        <>

            {
                isLoading
                    ?
                    <>
                        <LoaderOverlay active={true} text='Fetching enquiry details...' />
                    </>
                    :
                    <>
                        <div className="quotation-wrap report-data-load">
                            <div className="print-ico">
                                <a href="#" onClick={() => window.print()}> <img src={_images.print} width="19" height="18" /> <span><Translate text={'Print'} /></span></a>
                            </div>

                            <div className="wrapper">
                                <div className="print-wrap">
                                    <div className="header">
                                        <div className="headerwrap-left">
                                            <div className="header-left">
                                                {
                                                    client && client.logoURL
                                                        ?
                                                        <div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                        :
                                                        <>
                                                            <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                        </>
                                                }
                                            </div>
                                        </div>

                                        <div className="headerwrap-right">
                                            <div className='header-right'>
                                                {' '}
                                                {client.name} <br />
                                                <span>
                                                    {' '}
                                                    {client.address ? client.address : ''}
                                                    {client.phone ? (
                                                        <>
                                                            <br />
                                                            {`Phone: ${client.phone}`}
                                                        </>
                                                    ) : <></>}
                                                    {client.email || client.website ? (
                                                        <>
                                                            <br />
                                                            {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                        </>
                                                    ) : <></>}
                                                    <br />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="clear"></div>
                                    <div className="middle">
                                        <div className="title-head"><Translate text={'Enquiry Report'} upperCase={true} /></div>
                                        <div className="clear"></div>
                                        <div className="table-wraper">
                                            <div className="table-head valuation-num"><Translate text={'Enquiry Information'} />
                                                {
                                                    status
                                                        ?
                                                        <>
                                                            {
                                                                objCurrentStatus
                                                                    ?
                                                                    <>
                                                                        <div
                                                                            className={`mrg-fixstatus badge-${status === 'open' ? 'open' : 'empty'} ml-2`} style={{ background: (objCurrentStatus && objCurrentStatus.color ? objCurrentStatus.color : '#333') }}>
                                                                            <Translate
                                                                                text={objCurrentStatus ? objCurrentStatus.name : status.toLowerCase()}
                                                                                upperCase={true}
                                                                            />

                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </div>
                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'Enquiry'} />#</span> {displayID ? displayID : '--'} </div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'owner'} /></span> {(!_.isEmpty(users) && owner) ? CommonHelper.getUserNamebyId(users, owner) : '--'}</div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'createdBy'} /></span> {(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserNamebyId(users, addedBy) : '--'}</div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'createdOn'} /></span>{addedDate ?
                                                                (!_.isEmpty(client) && client.timezone ?
                                                                    moment(moment.unix(addedDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                    moment.unix(addedDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                : '--'} </div></td>
                                                        </tr>

                                                        <tr>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'enquiryType')} /></span>
                                                                    {enquiryType ? CommonHelper.getNameByValue(_settings && _settings.enquiryTypes, enquiryType, '--') : '--'}
                                                                </div>
                                                            </td>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'origin')} /></span>
                                                                    {origin ? CommonHelper.getNameByValue(_settings && _settings.origins, origin, '--') : '--'}
                                                                </div>
                                                            </td>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'campaign')} /></span>
                                                                    {campaign ? CommonHelper.getNameByValue(_settings && _settings.campaigns, campaign, '--') : '--'}
                                                                </div>
                                                            </td>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'label')} /></span>
                                                                    {CommonHelper.bindEnquiryLabel(_settings && _settings.enquiryLabels, label, '--')}
                                                                    {/* {label ? CommonHelper.getLabelByValue(labels, label, '--') : '--'} */}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'purchaseIntention')} /></span>
                                                                    {purchaseIntention ? purchaseIntention : '--'}
                                                                </div>
                                                            </td>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'depositReciptNumber')} /></span>
                                                                    {depositReciptNumber ? depositReciptNumber : '--'}
                                                                </div>
                                                            </td>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'depositAmount')} /></span>
                                                                    {depositAmount ? <NumberFormat value={depositAmount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : '--'}
                                                                </div>
                                                            </td>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'grossAmount')} /></span>
                                                                    {grossAmount ? <NumberFormat value={grossAmount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /> : '--'}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        {
                                                            (status === enquiryStatus.DELIEVERD)
                                                                ?
                                                                <>
                                                                    <tr>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'leadSource')} /></span>
                                                                                {leadSource ? CommonHelper.getNameByValue(_settings && _settings.leadSource, leadSource, '--') : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'chassisNo'} /></span>
                                                                                {chassisNo ? chassisNo : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'regNo'} /></span>
                                                                                {regNo ? regNo : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'stockNo'} /></span>
                                                                                {stockNo ? stockNo : '--'}
                                                                            </div>
                                                                        </td>

                                                                    </tr>

                                                                    <tr>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'warrantyStartDate'} /></span>
                                                                                {warrantyStartDate ? moment(warrantyStartDate, 'YYYY-MM-DD').format('DD MMM YYYY') : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'warrantyExpiry'} /></span>
                                                                                {warrantyExpiry ? moment(warrantyExpiry, 'YYYY-MM-DD').format('DD MMM YYYY') : '--'}
                                                                            </div>
                                                                        </td>
                                                                        {/* <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'deliveryDate'} /></span>
                                                                                {deliveryDate ?
                                                                                    (!_.isEmpty(client) && client.timezone ?
                                                                                        moment(moment.unix(deliveryDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                        moment.unix(deliveryDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                    : '--'}
                                                                            </div>
                                                                        </td> */}
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={CommonHelper.getNameByValue(_settings && _settings.enquiryStatus, 'won', 'Won') + ' Date'} /></span>
                                                                                {wonDate ?
                                                                                    (!_.isEmpty(client) && client.timezone ?
                                                                                        moment(moment.unix(wonDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                        moment.unix(wonDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                    : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={CommonHelper.getNameByValue(_settings && _settings.enquiryStatus, 'delivered', 'Delivered') + ' Date'} /></span>
                                                                                {deliveredDate ?
                                                                                    (!_.isEmpty(client) && client.timezone ?
                                                                                        moment(moment.unix(deliveredDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                        moment.unix(deliveredDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                    : '--'}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                                :
                                                                <></>
                                                        }

                                                        {
                                                            (isStatusSold || status === enquiryStatus.WON)
                                                                ?
                                                                <>
                                                                    <tr>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'leadSource'} /></span>
                                                                                {leadSource ? CommonHelper.getNameByValue(_settings && _settings.leadSource, leadSource, '--') : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={CommonHelper.getNameByValue(_settings && _settings.enquiryStatus, 'delivered', 'Delivered') + ' Date'} /></span>
                                                                                {deliveryDate ?
                                                                                    (!_.isEmpty(client) && client.timezone ?
                                                                                        moment(moment.unix(deliveryDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                        moment.unix(deliveryDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                    : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td colSpan='2'>
                                                                            <div className="grid-one">
                                                                                <span><Translate text={CommonHelper.getNameByValue(_settings && _settings.enquiryStatus, 'won', 'Won') + ' Date'} /></span>
                                                                                {wonDate ?
                                                                                    (!_.isEmpty(client) && client.timezone ?
                                                                                        moment(moment.unix(wonDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                        moment.unix(wonDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                    : '--'}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            (status === enquiryStatus.CLOSED)
                                                                ?
                                                                <>
                                                                    <tr>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'serviceType'} /></span>
                                                                                {serviceType ? CommonHelper.getNameByValue(_settings && _settings.serviceTypes, serviceType, '--') : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'leadSource'} /></span>
                                                                                {leadSource ? CommonHelper.getNameByValue(_settings && _settings.leadSource, leadSource, '--') : '--'}
                                                                            </div>
                                                                        </td>
                                                                        <td colSpan='2'>
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'closeWonDate'} /></span>
                                                                                {wonDate ?
                                                                                    (!_.isEmpty(client) && client.timezone ?
                                                                                        moment(moment.unix(wonDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                        moment.unix(wonDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                    : '--'}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                                :
                                                                <></>
                                                        }

                                                        {
                                                            (isStatusOpen || status === enquiryStatus.OPEN)
                                                                ?
                                                                <>
                                                                    <tr>
                                                                        <td width="25%">
                                                                            <div className="grid-one">
                                                                                <span><Translate text={'leadSource'} /></span>
                                                                                {leadSource ? CommonHelper.getNameByValue(_settings && _settings.leadSource, leadSource, '--') : '--'}
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            isAfterSales
                                                                                ?
                                                                                <td colSpan='3'>
                                                                                    <div className="grid-one">
                                                                                        <span><Translate text={'serviceType'} /></span>
                                                                                        {serviceType ? CommonHelper.getNameByValue(_settings && _settings.serviceTypes, serviceType, '--') : '--'}
                                                                                    </div>
                                                                                </td>
                                                                                :

                                                                                <td colSpan='3'>
                                                                                    <div className="grid-one">
                                                                                        <span><Translate text={'deliveryDate'} /></span>
                                                                                        {deliveryDate ?
                                                                                            (!_.isEmpty(client) && client.timezone ?
                                                                                                moment(moment.unix(deliveryDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                                moment.unix(deliveryDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                            : '--'}
                                                                                    </div>
                                                                                </td>
                                                                        }

                                                                    </tr>
                                                                </>
                                                                :
                                                                <></>
                                                        }

                                                        {
                                                            (status === enquiryStatus.LOST)
                                                                ?
                                                                <>
                                                                    {(() => {

                                                                        let _lostReasons = _settings && lostReason && _settings.lostReasons.filter(e => e.value === lostReason)[0];
                                                                        return <tr>
                                                                            <td width="25%">
                                                                                <div className="grid-one">
                                                                                    <span><Translate text={'leadSource'} /></span>
                                                                                    {leadSource ? CommonHelper.getNameByValue(_settings && _settings.leadSource, leadSource, '--') : '--'}
                                                                                </div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one">
                                                                                    <span><Translate text={'lostReason'} /></span>
                                                                                    {lostReason && _lostReasons ? _lostReasons.name : '--'}
                                                                                </div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one">
                                                                                    <span><Translate text={'lostSubReason'} /></span>
                                                                                    {lostSubReason ? CommonHelper.getNameByValue(_lostReasons && _lostReasons.subList, lostSubReason, '--') : '--'}
                                                                                </div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one">
                                                                                    <span><Translate text={CommonHelper.getNameByValue(_settings && _settings.enquiryStatus, 'lost', 'Lost') + ' Date'} /></span>
                                                                                    {lostDate ?
                                                                                        (!_.isEmpty(client) && client.timezone ?
                                                                                            moment(moment.unix(lostDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                            moment.unix(lostDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                        : '--'}
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    })()}
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="table-wraper brd-none">
                                            <div className="table-head page-break"><Translate text={'contactDetails'} /> &nbsp;{((contact && contact.displayID) ? '(' + contact.displayID + ')' : '')}</div>
                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'Contact Name'} /></span>
                                                                {CommonHelper.getFullName(null, contact, '--')}
                                                            </div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'phone'} /></span>
                                                                {contact && contact.phone ? CommonHelper.getFullPhone(contact.phoneCode, contact.phone, true) : '--'}
                                                            </div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'email'} /></span>
                                                                {contact && contact.email ? contact.email : '--'}
                                                            </div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'company'} /></span>
                                                                {contact?.company?.name ? contact.company.name : '--'}
                                                            </div></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'gender'} /></span>
                                                                {contact && contact.gender ? CommonHelper.getNameByValue(_settings && _settings.genders, contact.gender, '--') : '--'}
                                                            </div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'licenseNo'} /></span>
                                                                {contact && contact.licenseNo ? contact.licenseNo : '--'}
                                                            </div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'licenseExpiry'} /></span>
                                                                {contact && contact.licenseExpiry ? moment(contact.licenseExpiry).format('DD MMM YYYY') : '--'} </div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'Address'} /></span>
                                                                {contact && contact.address ? contact.address : '--'} </div></td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div className="tbl-lic-fix">
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td width="25%"><div className="license-wrap"><span><Translate text={'licenseFrontURL'} /></span>

                                                                    {
                                                                        (contact && contact.licenseFrontURL)
                                                                            ?
                                                                            <a data-fancybox={`licenseFrontURL`} href={contact.licenseFrontURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                <img id="licenseFrontURL" src={contact.licenseFrontURL} alt="" className="" width="150" />
                                                                            </a>
                                                                            :
                                                                            <img id="licenseFrontURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                    }
                                                                </div></td>
                                                                <td width="25%"><div className="license-wrap"><span><Translate text={'licenseBackURL'} /></span>{
                                                                    (contact && contact.licenseBackURL)
                                                                        ?
                                                                        <a data-fancybox={`licenseBackURL`} href={contact.licenseBackURL} onClick={(e) => { e.preventDefault(); }}>
                                                                            <img id="licenseBackURL" src={contact.licenseBackURL} alt="" className="" width="150" />
                                                                        </a>
                                                                        :
                                                                        <img id="licenseBackURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                }</div></td>
                                                                <td width="25%"><div className="license-wrap"><span><Translate text={'secondaryLicenseFrontURL'} /></span>
                                                                    {
                                                                        (contact && contact.secondaryLicenseFrontURL)
                                                                            ?
                                                                            <a data-fancybox={`secondaryLicenseFrontURL`} href={contact.secondaryLicenseFrontURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                <img id="secondaryLicenseFrontURL" src={contact.secondaryLicenseFrontURL} alt="" className="" width="150" />
                                                                            </a>
                                                                            :
                                                                            <img id="secondaryLicenseFrontURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                    }
                                                                </div></td>
                                                                <td width="25%"><div className="license-wrap"><span><Translate text={'secondaryLicenseBackURL'} /></span>
                                                                    {
                                                                        (contact && contact.secondaryLicenseBackURL)
                                                                            ?
                                                                            <a data-fancybox={`secondaryLicenseBackURL`} href={contact.secondaryLicenseBackURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                <img id="secondaryLicenseBackURL" src={contact.secondaryLicenseBackURL} alt="" className="" width="150" />
                                                                            </a>
                                                                            :
                                                                            <img id="secondaryLicenseBackURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                    }
                                                                </div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-wraper brd-none">
                                            <div className="table-head page-break"><Translate text={'vehicleDetails'} /> </div>
                                            <div className="vehicle-req-loop-wrapper">
                                                {
                                                    !_.isEmpty(requirements)
                                                        ?
                                                        <>
                                                            {
                                                                requirements.map((_requirement, index) => (
                                                                    <div key={index} className="vehicle-req-loop-table">
                                                                        {
                                                                            (!_.isEmpty(_requirement.stock))
                                                                                ?
                                                                                <>
                                                                                    {(() => {
                                                                                        let _stock = _requirement.stock;
                                                                                        let _makeModel = (_stock.make ? _stock.make + ' ' : '') + (_stock.model ? _stock.model : '') + (_stock.year ? ', ' + _stock.year : '');
                                                                                        return <>
                                                                                            <div className="vehicle-req-loop-head">
                                                                                                <strong> {_makeModel} </strong>
                                                                                                {
                                                                                                    _requirement.isSold
                                                                                                        ?
                                                                                                        <div className={`mrg-fixstatus badge-won ml-2`}>
                                                                                                            {'WON'}
                                                                                                        </div>
                                                                                                        :
                                                                                                        <></>
                                                                                                }
                                                                                            </div>
                                                                                            <table className="table-loop table-sub-fix">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th width="25%"><Translate text={'stockNo'} /></th>
                                                                                                        <th width="25%"> <Translate text={'regNo'} /> </th>
                                                                                                        <th width="25%"> <Translate text={'vinNo'} /> </th>
                                                                                                        <th width="25%"> <Translate text={'saleType'} /> </th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>{_stock.stockNo ? _stock.stockNo : '--'} </td>
                                                                                                        <td>{_stock.regNo ? _stock.regNo : '--'} </td>
                                                                                                        <td>{_stock.vinNo ? _stock.vinNo : '--'} </td>
                                                                                                        <td>{_requirement.saleType ? <div className="badge badge-pill badge-open">
                                                                                                            {CommonHelper.getNameByValue(_settings && _settings.salesType, _requirement.saleType, '--')}
                                                                                                        </div> : <></>}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </>
                                                                                    })()}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {(() => {
                                                                                        let _makeModel = (_requirement.make ? _requirement.make + ' ' : '') + (_requirement.model ? _requirement.model : '') + (_requirement.year ? ', ' + _requirement.year : '');
                                                                                        return <>
                                                                                            <div className="vehicle-req-loop-head">
                                                                                                <strong> {_makeModel} </strong>
                                                                                                {
                                                                                                    _requirement.isSold
                                                                                                        ?
                                                                                                        <div className={`mrg-fixstatus badge-won ml-2`}>
                                                                                                            <Translate text={'won'} upperCase={true} />
                                                                                                        </div>
                                                                                                        :
                                                                                                        <></>
                                                                                                }
                                                                                            </div>
                                                                                            <table className="table-loop table-sub-fix">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th width="25%"><Translate text={'color'} /></th>
                                                                                                        <th width="25%"> <Translate text={'regNo'} /> </th>
                                                                                                        <th width="25%"> <Translate text={'vinNo'} /> </th>
                                                                                                        <th width="25%"> <Translate text={'saleType'} /> </th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>{_requirement.extColor ? _requirement.extColor : '--'} </td>
                                                                                                        <td>{_requirement.regNo ? _requirement.regNo : '--'} </td>
                                                                                                        <td>{_requirement.vinNo ? _requirement.vinNo : '--'} </td>
                                                                                                        <td>{_requirement.saleType ? <div className="badge badge-pill badge-open">
                                                                                                            {CommonHelper.getNameByValue(_settings && _settings.salesType, _requirement.saleType, '--')}
                                                                                                        </div> : <></>}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </>
                                                                                    })()}
                                                                                </>
                                                                        }

                                                                    </div>

                                                                ))
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }

                                            </div>
                                        </div>

                                        {
                                            !_.isEmpty(testdrives)
                                                ?
                                                <>
                                                    <div className="table-wraper brd-none page-break">
                                                        <div className="table-head page-break"><Translate text={'Test Drive Information'} /> </div>
                                                        <div className="vehicle-req-loop-wrapper">
                                                            {
                                                                testdrives.map((_testdrive, index) => (
                                                                    <div key={index} className="vehicle-req-loop-table">
                                                                        <>
                                                                            {(() => {
                                                                                let _stock = _testdrive.stock;
                                                                                let _makeModel = (_stock.make ? _stock.make + ' ' : '') + (_stock.model ? _stock.model : '') + (_stock.year ? ', ' + _stock.year : '');
                                                                                return <>
                                                                                    <div className="vehicle-req-loop-head">
                                                                                        <strong> {_makeModel} </strong>
                                                                                        {
                                                                                            _testdrive.status
                                                                                                ?
                                                                                                <div className={`mrg-fixstatus badge-${_testdrive.status.toLowerCase()} ml-2`}>
                                                                                                    <Translate
                                                                                                        text={_testdrive.status.toLowerCase()}
                                                                                                        upperCase={true}
                                                                                                    />
                                                                                                </div>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </div>
                                                                                    <table className="table-loop table-sub-fix">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th width="25%"><Translate text={'startDate'} /></th>
                                                                                                <th width="25%"><Translate text={'startOdometer'} /></th>
                                                                                                <th width="25%"><Translate text={'endDate'} /></th>
                                                                                                <th width="25%"><Translate text={'endOdometer'} /></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>{_testdrive.startDate ?
                                                                                                    (!_.isEmpty(client) && client.timezone ? moment(moment.unix(_testdrive.startDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(_testdrive.startDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                                    : '--'} </td>
                                                                                                <td>{_testdrive.startOdometer ? <NumberFormat value={_testdrive.startOdometer} displayType={'text'} thousandSeparator={true} /> : <>{'--'}</>} </td>
                                                                                                <td>{_testdrive.endDate ?
                                                                                                    (!_.isEmpty(client) && client.timezone ? moment(moment.unix(_testdrive.endDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(_testdrive.endDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                                    : '--'} </td>
                                                                                                <td>{_testdrive.endOdometer ? <NumberFormat value={_testdrive.endOdometer} displayType={'text'} thousandSeparator={true} /> : <>{'--'}</>}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </>
                                                                            })()}
                                                                        </>

                                                                    </div>

                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            !_.isEmpty(tradeIns)
                                                ?
                                                <>
                                                    <div className="table-wraper brd-none page-break">
                                                        <div className="table-head page-break"><Translate text={'Trade-In Information'} /> </div>
                                                        <div className="vehicle-req-loop-wrapper">
                                                            {
                                                                tradeIns.map((_tradeIn, index) => (
                                                                    <div key={index} className="vehicle-req-loop-table">
                                                                        <>
                                                                            {(() => {
                                                                                let _makeModel = (_tradeIn.make ? _tradeIn.make + ' ' : '') + (_tradeIn.model ? _tradeIn.model : '') + (_tradeIn.year ? ', ' + _tradeIn.year : '');
                                                                                return <>
                                                                                    <div className="vehicle-req-loop-head">
                                                                                        <strong> {_makeModel} </strong>
                                                                                        {/* {
                                                                                            _tradeIn.status
                                                                                                ?
                                                                                                <div className={`mrg-fixstatus badge-${_tradeIn.status.toLowerCase()} ml-2`}>
                                                                                                    <Translate
                                                                                                        text={_tradeIn.status.toLowerCase()}
                                                                                                        upperCase={true}
                                                                                                    />
                                                                                                </div>
                                                                                                :
                                                                                                <></>
                                                                                        } */}
                                                                                    </div>
                                                                                    <table className="table-loop table-sub-fix">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th width="25%"><Translate text={'color'} /></th>
                                                                                                <th width="25%"> <Translate text={'regNo'} /> </th>
                                                                                                <th width="25%"> <Translate text={'vinNo'} /> </th>
                                                                                                <th width="25%"> <Translate text={'status'} /> </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>{_tradeIn.exteriorColor ? _tradeIn.exteriorColor : '--'} </td>
                                                                                                <td>{_tradeIn.regNo ? _tradeIn.regNo : '--'} </td>
                                                                                                <td>{_tradeIn.chassisNo ? _tradeIn.chassisNo : '--'} </td>
                                                                                                <td>{_tradeIn.status ? <div className={`mrg-fixstatus badge-${_tradeIn.status.toLowerCase()} ml-2`}>
                                                                                                    <Translate
                                                                                                        text={_tradeIn.status.toLowerCase()}
                                                                                                        upperCase={true}
                                                                                                    />
                                                                                                </div> : <>{'--'}</>}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </>
                                                                            })()}
                                                                        </>

                                                                    </div>

                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }


                                        {
                                            !_.isEmpty(cafeOrders)
                                                ?
                                                <>
                                                    <div className="table-wraper brd-none page-break">
                                                        <div className="table-head page-break"><Translate text={'Cafe Information'} /> </div>
                                                        <div className="vehicle-req-loop-wrapper">
                                                            <table className="table-loop table-sub-fix">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="25%"><Translate text={'orderNo'} /></th>
                                                                        <th width="25%"> <Translate text={'date_Time'} /> </th>
                                                                        <th width="25%"> <Translate text={'orderBy'} /> </th>
                                                                        <th width="25%"> <Translate text={'status'} /> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        cafeOrders.map((_cafe, index) => (
                                                                            <tr key={index}>
                                                                                <td>{_cafe.orderNumber ? _cafe.orderNumber : '--'} </td>
                                                                                <td>{_cafe.addedDate ?
                                                                                    (!_.isEmpty(client) && client.timezone ? moment(moment.unix(_cafe.addedDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(_cafe.addedDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                    : '--'} </td>
                                                                                <td> {(!_.isEmpty(users) && _cafe.addedBy) ? CommonHelper.getUserNamebyId(users, _cafe.addedBy) : '--'} </td>
                                                                                <td>{_cafe.status ? <div className={`mrg-fixstatus badge-${_cafe.status.toLowerCase()} ml-2`}>
                                                                                    <Translate
                                                                                        text={_cafe.status.toLowerCase()}
                                                                                        upperCase={true}
                                                                                    />
                                                                                </div> : <>{'--'}</>}</td>
                                                                            </tr>

                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            !_.isEmpty(activities)
                                                ?
                                                <>
                                                    <div className="table-wraper brd-none page-break">
                                                        <div className="right-notes">
                                                            <div className="table-head page-break"><Translate text={'Notes'} /> </div>
                                                            <div className="notes-list-loop">

                                                                {
                                                                    _.orderBy(activities, ['addedDate'], ['desc']).map((noteData, index) => {
                                                                        return <div key={index} className="notes text-area-space">
                                                                            {_.trim(noteData.notes)}
                                                                            <div className="notes-sub-info">
                                                                                <>
                                                                                    <span>{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                                                    {(!_.isEmpty(users) && noteData.addedBy) ? ` - ${CommonHelper.getUserNamebyId(users, noteData.addedBy)}` : '--'}
                                                                                </>


                                                                                {
                                                                                    (noteData.type === "activity" || noteData.subType !== 'note') && (
                                                                                        <div className="badge badge-pill badge-input-white ml-2 text-capitalize">
                                                                                            <Translate text={noteData.type === 'activity' ? 'activity' : noteData.subType} toUpperCase={true} />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {
                                                                                    noteData.tags && noteData.tags.map((rec, index) => {
                                                                                        return <div key={index} className="badge badge-pill badge-input-white ml-2 text-capitalize">{CommonHelper.getNameByValue(currentSettings?.integrations, (_.isObject(rec) ? rec.type : rec), (_.isObject(rec) ? rec.type : rec))}</div>
                                                                                    })
                                                                                }

                                                                                {noteData.modifiedDate && noteData.addedDate && noteData.addedDate.seconds !== noteData.modifiedDate.seconds && noteData.subType === 'note' && (<div >
                                                                                    <Translate text={"Last Edited"} />:&nbsp;
                                                                                    <span className={'pl-0'}>{moment.unix(noteData.modifiedDate.seconds).format("DD MMM YYYY h:mm A")}</span>
                                                                                    {!_.isEmpty(users) && noteData.modifiedBy ? ` - ${CommonHelper.getUserNamebyId(users, noteData.modifiedBy)}` : ""}
                                                                                    {/* {noteData.modifiedFrom ? (
                                <>  &nbsp;{` - `}&nbsp;  <i className={`ico icon-${noteData.modifiedFrom === "web" ? "monitor" : noteData.modifiedFrom} mr-1`}  ></i>  {noteData.modifiedFrom}{" "}</>
                              ) : (<></>)} */}
                                                                                </div>)}

                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                                <div className="push"></div>
                            </div>
                            <div className="clear"></div>
                            <div className="footer">

                                <div className="footer-bottom">
                                    <p>
                                        <span style={{ paddingLeft: 0 }} >
                                            {client.address ? <>{client.address}</> : <></>}
                                        </span>
                                        <span style={{ paddingLeft: 0 }}>
                                            {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                            {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                            {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                            {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                        </span>
                                    </p>
                                </div>
                                <div className="footer-cpy">
                                    <div className="footer-left">{'Powered by FusionSD.'}</div>
                                    <div className='footer-right'>Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>
                                </div>
                                <div className="clear"></div>
                            </div>




                        </div>
                    </>
            }
        </>



    )
};

export default EnquiryPreview;
