/** LIBRARIES */
import React, { PureComponent, Component } from "react";
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
import {
    PopUpModal,
    AntMonthPicker,
    ReactSelect,
    LoaderOverlay,
    InputAutoText,
    InputAutoNumber,
    AntDatePicker,
    PopoverPanel,
    SidePanel,
    InputCheckBox,
    DynamicInput
} from '../../../components';
import _ from 'lodash'
import moment from 'moment';
import CommonHelper from '../../../services/common';
import { tipStatus } from '../../../services/enum';

class InfoInputs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {

        let { dynamicFields } = this.props.fields;

        const { dealersettings, canModify, errors, currencySymbol,
            attendeesDF,
            handleDynamicCheckChange, handleDynamicOnChange, saveDynamicField, cancelDynamicField, onDynamicNumberChange,
            handleDynamicDateChange, handleDynamicSelectSave, handleDynamicMultiSelectChange, handleDynamicMonthChange
        } = this.props;
        //console.log(attendeesDF)

        return (
            <>
                {
                    !_.isEmpty(attendeesDF) && attendeesDF.filter(m => m.active === true).map((info, index) => {
                        return <tr key={info.value}>
                            <td className='text-left label-text'>{info.name}</td>
                            <td className={`text-left ${(canModify) ? '' : 'form-disable'}`}>
                                <DynamicInput
                                    index={'0' + index}
                                    data={info}
                                    errors={{}}
                                    id={info.value}
                                    value={dynamicFields ? dynamicFields[info.value] : null}
                                    handleCheckChange={handleDynamicCheckChange}
                                    handleOnChange={handleDynamicOnChange}
                                    saveEnquiryField={saveDynamicField}
                                    cancelEnquiryField={cancelDynamicField}
                                    onCurrencyChange={onDynamicNumberChange}
                                    handleDateChange={handleDynamicDateChange}
                                    handleMonthChange={handleDynamicMonthChange}
                                    handleSelectSave={handleDynamicSelectSave}
                                    handleMultiSelectChange={handleDynamicMultiSelectChange}
                                    currencySymbol={currencySymbol}
                                    inputAutoFormat={true}
                                    canModify={canModify}
                                    AddUnAvailable={true}
                                >
                                </DynamicInput>
                            </td>
                        </tr>
                    })
                }
            </>

        );
    }
}

export default InfoInputs;