/** LIBRARIES */
import React, { Component } from 'react'
import toast from 'toasted-notes';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import PageNotFound from '../../../components/pagenotFound';
import CommonHelper from '../../../services/common';
import { PopUpModal, LoaderOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { tipOfferStatus, tipStatus } from '../../../services/enum';
import AddNotes from '../../tradeinplus/offerNotes/addWholesalerNote';
import OfferLogs from '../../tradeinplus/offerLogs/index'

import { objTradeIn, tradeinSortOptions } from '../../tradeIn/viewModel'
import { objTradeIPOffers, objOfferNotesVM } from '../../tradeinplus/viewModel'
import { firestoreDB } from '../../../services/helper';


class TradeInDetails extends Component {

    constructor(props) {
        super(props);
        this.unsubscribeService = null;

        this.state = {
            loader: false,
            loading: false,
            offerID: null,
            tradeInOffer: Object.assign({}, objTradeIPOffers),
            tradeIn: Object.assign({}, objTradeIn),
            recommendations: Object.assign([]),
            errorFields: Object.assign({}),
            tradeinOptionsDF: Object.assign([]),
            dealerSettings: Object.assign({}),
            wholesalerPrice: null,
            wholesalerNote: null,
            showNotes: false,
            showOfferLog: false,
            showMoreInfo: false,
            pagenotFound: false,
        }
    }


    componentDidMount() {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        this._isMounted = true;
        if (this._isMounted) {
            this.loadTradeIn();
        }

        window.addEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });

    }

    componentDidUpdate() {
        let classList = document.getElementsByTagName('body')[0].classList.value;
        if (classList.indexOf('enquiry-bg') >= 0 && classList.indexOf('inner-bg') >= 0) {
            //console.log('componentDidUpdate-classList', classList.indexOf('enquiry-bg'), classList.indexOf('inner-bg'));
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }
        if (this.state.loading) {
            window?.refCollectionData?.unsubscribe && window.refCollectionData.unsubscribe()
            this.loadTradeIn();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.offerid !== state.offerID && state.offerID) {
            return {
                loading: true,
                offerID: null,
            };
        } else {
            return {
                loading: false,
            };
        }
    }

    handleSpaceScrollLock = (e) => {
        if (e.keyCode == 32 && e.target == document.body) {
            e.preventDefault();
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        window.removeEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });

        window.refCollectionData && window.refCollectionData.unsubscribe()
        if (window.location.pathname.indexOf('wholesaler/details') < 0) {
            if (this.props.resetnavbar()) this.props.resetnavbar();
        }
        this._isMounted = false;
    }

    loadTradeIn = async () => {
        const { offerid, dealersettings } = this.props;

        window.refCollectionData = collectionData(firestoreDB(dealersettings).firestore().collectionGroup(`offers`).where('documentID', '==', offerid).limit(1))
            .pipe(
                switchMap(_tradeIns => {
                    //console.log(_tradeIns);
                    let tradeIns = _tradeIns[0] ? _tradeIns[0] : {}

                    // //add tradeinpro into routes
                    this.props.handleRouteChange({
                        name: tradeIns?.tradeinPro?.tradein?.make ? (tradeIns?.tradeinPro?.tradein?.make + ' ' + tradeIns?.tradeinPro?.tradein?.model) : offerid,
                        id: offerid,
                        displayID: tradeIns?.tradeinPro?.displayID ? tradeIns.tradeinPro.displayID : offerid,
                        status: tradeIns?.status,
                        active: true,
                        clientid: localStorage.uid,
                        isNew: null,
                        params: '',
                        module: 'wholesaler'
                    }, 'wholesaler');


                    const enqIds = tradeIns.tradeinProID ? [tradeIns.tradeinProID] :
                        (tradeIns?.tradeinPro?.documentID ? [tradeIns.tradeinPro.documentID] : []);
                    const tradeIds = tradeIns?.tradeinPro?.tradeinID ? [tradeIns.tradeinPro.tradeinID] :
                        (tradeIns?.tradeinPro?.tradein?.documentID ? [tradeIns.tradeinPro.tradein.documentID] : []);
                    const clientIds = tradeIns.clientID ? [tradeIns.clientID] :
                        (tradeIns?.tradeinPro?.clientID ? [tradeIns.tradeinPro.clientID] : []);
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(enquiryID =>
                                collectionData(firestoreDB(dealersettings).firestore().collection('tradeinPro').where('documentID', '==', enquiryID)).pipe(
                                    map(proSnapshot => proSnapshot[0] ? proSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            tradeIds.length > 0 ? tradeIds.map(tradeID =>
                                collectionData(firestoreDB(dealersettings).firestore().collection('tradeins').where('documentID', '==', tradeID)).pipe(
                                    map(tradeinSnapshot => tradeinSnapshot[0] ? tradeinSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(enquiryID =>
                                collectionData(firestoreDB(dealersettings).firestore().collection(`tradeinPro/${enquiryID}/offerNotes`).where('offerID', '==', offerid)).pipe(
                                    map(notesSnapshot => notesSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                    map(clientSettingsSnapshot => clientSettingsSnapshot)
                                )
                            ) : of([])
                        ),
                        // combineLatest(
                        //     clientIds.length > 0 ? clientIds.map(clientID =>
                        //         collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                        //             map(usersSnapshot => usersSnapshot)
                        //         )
                        //     ) : of([])
                        // ),
                    )
                }),
                map(([tradeIns, proSnapshot, tradeinSnapshot, notesSnapshot, clientSnapshot, clientSettingsSnapshot]) => {

                    const offerNotes = [];
                    notesSnapshot[0] && notesSnapshot[0].forEach((doc) => {
                        const _notes = Object.assign({}, objOfferNotesVM);
                        for (let [key, value] of Object.entries(doc)) {
                            _notes[key] = value;
                        }

                        if (!_notes.isDeleted && ((_notes.isInternalNotes === false && _notes.wholeSalerNotes === false) || // isDealerPublicNotes 
                            (_notes.isInternalNotes === true && _notes.wholeSalerNotes === true) || // isWholesalerInternalNotes 
                            (_notes.isInternalNotes === false && _notes.wholeSalerNotes === true) // isWholesalerPublicNotes 
                        )) offerNotes.push(_notes);
                    });

                    return {
                        ...tradeIns,
                        tradeinPro: proSnapshot[0] ? proSnapshot[0] : tradeIns.tradeinPro,
                        tradein: tradeinSnapshot[0] ? tradeinSnapshot[0] : (tradeIns.tradeinPro && tradeIns.tradeinPro.tradein ? tradeIns.tradeinPro.tradein : {}),
                        offerNotes: offerNotes,
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                        currentSettings: clientSettingsSnapshot[0],
                        //users: usersSnapshot[0],
                    }

                })
            )
            .subscribe(_tradeIns => {

                //console.log('_tradeIns', _tradeIns)
                if (!_.isEmpty(_tradeIns)) {

                    let _dealerSettings = {};
                    let _client = Object.assign({}, _tradeIns.client);
                    let _clientSettings = Object.assign({}, _tradeIns.currentSettings);
                    _dealerSettings.client = Object.assign({
                        ..._client,
                        id: _tradeIns.clientID,
                        name: _clientSettings.name,
                        timezone: _clientSettings.timezone,
                        phone: _clientSettings.phone,
                        logoURL: _clientSettings.logoURL,
                        address: _clientSettings.address,
                        moduleSettings: _clientSettings.moduleSettings,
                        currency: _clientSettings.settings && _clientSettings.settings.currency,
                        integrations: _clientSettings.integrations,
                        settings: _clientSettings.settings
                    });

                    let _tradeinOptionsDF = [];
                    if (_dealerSettings?.client?.settings?.tradeinOptionsDF)
                        _tradeinOptionsDF = _dealerSettings?.client?.settings?.tradeinOptionsDF;

                    let _tradeInFields = Object.assign({}, _tradeIns.tradein);
                    let _dynamicFields = _tradeInFields.dynamicFields
                    if (!_.isEmpty(_dynamicFields) && _tradeinOptionsDF) {

                        _tradeinOptionsDF.filter(e => e.active === true).forEach(rec => {
                            if (rec.type === 'toggle') {
                                _tradeInFields[rec.value] = _dynamicFields[rec.value] === true ? 'Yes' : 'No';
                            }
                            else if (rec.type === 'date') {
                                _dynamicFields[rec.value] = _dynamicFields[rec.value] ? _dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_dynamicFields[rec.value]._seconds)._d) : _dynamicFields[rec.value] : ''
                                _tradeInFields[rec.value] = _dynamicFields[rec.value] ? moment.unix(_dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                            }
                            else if (rec.type === 'multiselect') {
                                _tradeInFields[rec.value] = _dynamicFields[rec.value] ? _dynamicFields[rec.value].join(',') : ''
                                // _tradeInFields[rec.value] = _dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                                //     {
                                //         _dynamicFields[rec.value].map((rec, index) => {
                                //             return <div key={index}><span>{rec}</span></div>
                                //         })
                                //     }
                                // </div>) : (<></>)
                            }
                            else {
                                _tradeInFields[rec.value] = _dynamicFields[rec.value]
                            }
                        })
                    }

                    this.setState({
                        offerID: offerid,
                        tradeInOffer: Object.assign({}, _tradeIns),
                        tradeIn: Object.assign({}, _tradeInFields),
                        tradeinOptionsDF: _tradeinOptionsDF,
                        dealerSettings: _dealerSettings,
                    })
                }
                else {
                    this.setState({
                        pagenotFound: true,
                        offerID: null
                    })
                }

            });

    }

    handleMoveStageForStatusChange = (_status, state) => {
        const { dealersettings } = this.props;
        const { tradeInOffer } = this.state;
        // let _client = tradeInOffer.clientID && !_.isEmpty(dealersettings) &&
        //     !_.isEmpty(dealersettings.group) &&
        //     !_.isEmpty(dealersettings.group.clients) &&
        //     dealersettings.group.clients[tradeInOffer.clientID] &&
        //     dealersettings.group.clients[tradeInOffer.clientID].tradeinPro ? dealersettings.group.clients[tradeInOffer.clientID] : dealersettings.client;
        //     const _settings = _client && _client.tradeinPro;
        const _settings = ((!_.isEmpty(tradeInOffer) &&
            !_.isEmpty(tradeInOffer.currentSettings) &&
            !_.isEmpty(tradeInOffer.currentSettings.tradeinPro)) ? tradeInOffer.currentSettings.tradeinPro : null);



        let _selectedPipeline = tradeInOffer?.tradeinPro && tradeInOffer?.tradeinPro.pipeline && !_.isEmpty(_settings) ? _settings.allPipelines.filter(item => item.value === tradeInOffer.tradeinPro.pipeline)[0] : null;
        let statusSettings = _selectedPipeline && _selectedPipeline.statusSettings;

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], tradeInOffer.tradeinPro.stageHistory);
            let _stageData = CommonHelper.handleStageHistory(_stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            return state;
        }
        else
            return state;
    }

    onCurrencyChange = (e, name) => {

        this.setState({
            wholesalerPrice: e.floatValue,
            errorFields: {
                ...this.state.errorFields,
                [name]: ''
            }
        })
    }

    handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        this.setState({
            wholesalerNote: value
        })

    }

    handleSubmit = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};


        if (!this.state.wholesalerPrice) {
            formIsValid = false;
            errors['wholesalerPrice'] = errorClass;
        }

        this.setState({
            errorFields: errors
        })


        if (!formIsValid) {
            return;
        }

        this.handleSave();


    }

    handleSave = () => {
        this.setState({ loader: true })


        let objDataOffer = Object.assign({})
        const { tradeInOffer, wholesalerPrice, wholesalerNote } = this.state;
        let _currentUser = tradeInOffer.wholeSellerId;
        let _currentDate = window.firebase.firestore.Timestamp.now();

        objDataOffer.modifiedBy = _currentUser;
        objDataOffer.modifiedDate = _currentDate;
        objDataOffer.evaluatedBy = _currentUser;
        objDataOffer.evaluationDate = _currentDate;
        objDataOffer.isSelfEvaluation = false;
        objDataOffer.evaluationPrice = wholesalerPrice;
        //objDataOffer.dealerPrice = wholesalerPrice;
        objDataOffer.status = tipOfferStatus.COMPLETED;

        //Remove empty value from object
        for (var propName in objDataOffer) {
            if (objDataOffer[propName] === null || objDataOffer[propName] === undefined || objDataOffer[propName] === '' || objDataOffer[propName] === [] || (_.isObject(objDataOffer[propName]) && _.isEmpty(objDataOffer[propName]))) {
                delete objDataOffer[propName];
            }
        }

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection(`tradeinPro/${tradeInOffer.tradeinProID}/offers`).doc(this.props.offerid);
        updateRef.set(objDataOffer, { merge: true }).then((docRef) => {

            toast.notify('appraisal updated successfully.', {
                duration: 2000,
            });

            if (!_.isEmpty(wholesalerNote))
                this.handleNotesClose(wholesalerNote)

            this.saveTradeInProLog();

            if (tradeInOffer?.tradeinPro.status === tipStatus.NEW || tradeInOffer?.tradeinPro.status === tipStatus.INPROGRESS) {
                this.saveTradeinPro(tipStatus.PENDING);
            }
            else
                this.setState({ loader: false, wholesalerNote: null, wholesalerPrice: null })



        }).catch(error => {
            this.setState({ loader: false })

            console.error('Error updating enquiries: ', error);
        });


    }

    saveTradeinPro = (_status) => {
        var objData = {
            status: _status,
            modifiedDate: window.firebase.firestore.Timestamp.now()
        }
        const { tradeInOffer, tradeIn } = this.state;
        const { dealersettings } = this.props;
        const batch = firestoreDB(dealersettings).firestore().batch();
        if (tradeIn.documentID)
            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${tradeIn.documentID}`), { ...objData }, { merge: true });

        objData = this.handleMoveStageForStatusChange(objData.status, objData);

        const updateRef = firestoreDB(dealersettings).firestore().collection(`tradeinPro`).doc(tradeInOffer.tradeinProID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.setState({ loader: false, wholesalerNote: null, wholesalerPrice: null })

            batch.commit();
        }).catch(error => {
            this.setState({ loader: false })

            console.error('Error updating enquiries: ', error);
        });
    }

    saveTradeInProLog = () => {
        const { tradeInOffer, wholesalerPrice, tradeIn } = this.state;
        const { clientUsers, dealersettings } = this.props;
        const currencySymbol = !_.isEmpty(tradeInOffer?.currency) ? tradeInOffer?.currency.symbol + ' ' : '$ ';
        var objData = {
            documentID: window.firebase.firestore().collection('tradeins').doc().id,
            addedBy: tradeInOffer.wholeSellerId,
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedFrom: 'web',
            offerID: this.props.offerid,
            tradeinProID: tradeInOffer.tradeinProID,
            isDeleted: false,
            projectId: tradeInOffer?.projectId ? tradeInOffer?.projectId : (tradeIn?.projectId ? tradeIn?.projectId : null),
            message: `️An offer of ${CommonHelper.formatCurrency(currencySymbol, wholesalerPrice)} has been received from ${CommonHelper.getUserNamebyId(clientUsers, tradeInOffer.wholeSellerId, '')}.`
        }

        const updateRef = firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeInOffer.tradeinProID}/offerLogs`).doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.setState({ loader: false })


        }).catch(error => {
            this.setState({ loader: false })

            console.error('Error updating enquiries: ', error);
        });
    }

    handleNotesClose = (noteTxt, _mentions, _isInternal) => {

        if (noteTxt) {
            const { tradeInOffer, wholesalerPrice, tradeIn } = this.state;
            const { dealersettings } = this.props;
            let objData =
            {
                documentID: window.firebase.firestore().collection('tradeins').doc().id,
                notes: noteTxt,
                addedBy: tradeInOffer.wholeSellerId,
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web',
                offerID: tradeInOffer.documentID,
                tradeinProID: tradeInOffer.tradeinProID,
                projectId: tradeInOffer?.projectId ? tradeInOffer?.projectId : (tradeIn?.projectId ? tradeIn?.projectId : null),
                wholeSalerNotes: true,
                isDeleted: false
            }

            if (_isInternal)
                objData.isInternalNotes = true;

            const updateRef = firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeInOffer.tradeinProID}/offerNotes`).doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {


            }).catch(error => {

                console.error('Error updating enquiries: ', error);
            });

        }
        this.setState({ showNotes: false })

    }

    handleLogClose = (ID) => {
        this.setState({
            showOfferLog: false
        });
    }

    handleLogOpen = (ID) => {
        this.setState({
            showOfferLog: true
        });
    }


    render() {

        const { tradeInOffer, tradeIn, wholesalerNote, wholesalerPrice, errorFields, loader, showNotes, showOfferLog, showMoreInfo, tradeinOptionsDF, dealerSettings } = this.state;
        const { dealersettings, clientUsers } = this.props;
        const { client, currentSettings, offerNotes, tradeinPro, evaluationPrice, clientID, status, addedDate, addedBy, currency, isReRequested, previousWSPrice } = tradeInOffer;
        const {
            make, model, year, specifications,
            exteriorColor,

            images, marks, inspectionSnapshot, sidePhotos,
            tradeinProID,
        } = tradeIn;

        const currencySymbol = !_.isEmpty(currency) ? currency.symbol + ' ' : '$ ';
        let dynamicDetailList = (!_.isEmpty(dealerSettings?.client?.settings?.sortTradeinOptions))
            ? dealerSettings.client.settings.sortTradeinOptions :
            Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

        return <>

            {
                !_.isEmpty(tradeIn) && !_.isEmpty(tradeinProID)
                    ?
                    <>
                        <div className="middle-wrapper">
                            <div className="enquiry-detailswrap">
                                <div className="enquiry-detail-head">
                                    <div className="float-left enquiry-details-title-wrap">
                                        <div className="enquiry-details-contact"> <i className={CommonHelper.getVehicleIcons(client?.category, 'ico icon-Stock')}></i> {make ? make : ''}&nbsp;{model ? model : ''}
                                        </div>

                                        <div className="enquiry-subtitle">
                                            {year ? year + (exteriorColor ? ', ' : ' ') : ''}&nbsp; {exteriorColor ? exteriorColor : ''}
                                            {
                                                isReRequested
                                                    ?
                                                    <> <div style={{ display: 'inline' }}>
                                                        <div className="badge badge-pill badge-white badge-mini ml-1 text-capitalize"><Translate text={'reRequested'} /></div>
                                                    </div></>
                                                    :
                                                    <></>
                                            }
                                        </div>

                                    </div>
                                    <div className="float-right">
                                        {
                                            (status)
                                                ?
                                                <div className="current-status mt-3"><Translate text={'currentStatus'} />

                                                    <div
                                                        className={`badge badge-pill badge-${status.toLowerCase()} ml-2`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        <Translate
                                                            text={status.split(/(?=[A-Z])/).join(' ').toLowerCase()}
                                                            upperCase={true}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <></>

                                        }


                                        {/* <div className="enquiry-view-more dropdown float-right ">
                                            <button aria-haspopup="true" aria-expanded="false" id="dropdown-basic" type="button" className="common-button dropdown-toggle btn btn-primary"><i className="ico icon-more"></i></button>
                                        </div> */}





                                        <div>



                                        </div>





                                    </div>
                                </div>
                                <div className="enquiry-detail-bottom ">
                                    <div className="float-left mt-2">
                                        <ul className="enquiry-details">
                                            <li>  <div className="tradein-valuation-num text-uppercase" onDoubleClick={(e) => {
                                                e.preventDefault()
                                                console.log(dealersettings);
                                                console.log('clientUsers:-', clientUsers)
                                            }}><Translate text={'Valuation No'} />: {tradeinPro?.displayID ? tradeinPro.displayID : (tradeinProID.substring(0, 8))}</div></li>
                                            {client?.name ? <li> <i className="ico icon-dealers"></i>{client.name}</li> : <></>}
                                            {client?.email ? <li><i className="ico icon-email"></i> {client.email}</li> : <></>}
                                            <li><i className="ico icon-date"></i> <Translate text={'created'} /> - {addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY') : '--'}</li>

                                        </ul>
                                    </div>


                                    <div className="float-right">
                                        <div className="filter-item-btn ml-2"> <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                            <div className="filter-item-users">

                                                <img src={CommonHelper.getUserImagebyId(clientUsers, addedBy)} alt="" width="300" height="300" />
                                            </div>
                                            <span>  {CommonHelper.getUserNamebyId(clientUsers, addedBy)}</span>
                                        </a> </div>
                                    </div>

                                </div>
                            </div>

                            <div className="clearfix"></div>
                            <div className="enquirydetail-grid flex-column mt-4">

                                <div className="mid-column pb-5">



                                    <div className="service-section-boxloop mb-3">
                                        <div className="service-section-boxloop-header">
                                            <div className="float-left">
                                                <h3 className="service-section-page-title"> <span className="service-section-page-title-icon bg-service-blue text-white mr-3"> <i className={CommonHelper.getVehicleIcons(client?.category, 'ico icon-Stock')}></i> </span> <Translate text={'Trade-In Info'} /> </h3>
                                            </div>


                                            <div className="float-right">
                                                {
                                                    !showMoreInfo ? (
                                                        <div className="wholesaler-loadmore-btn mt-1"><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                showMoreInfo: true
                                                            })
                                                        }}><Translate text={'showMore'} />  <i className="ico icon-dropdown"></i></a></div>
                                                    ) : <><div className="wholesaler-loadmore-btn mt-1 show-less-ico"><a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            showMoreInfo: false
                                                        })
                                                    }}><Translate text={'showLess'} />  <i className="ico icon-dropdown"></i></a></div></>
                                                }

                                            </div>

                                        </div>

                                        <div className="wholesaler-vehicle-tbl">
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="wholesaler-subtable">
                                                <tbody>
                                                    {
                                                        _.chunk(dynamicDetailList.filter(e => !['notes', 'specifications', 'status'].includes(e)), 4).map((stats, i) => {
                                                            return <tr key={i} className={i > 3 && !showMoreInfo ? 'hide' : ''}>
                                                                {
                                                                    stats.map((rec, index) => {

                                                                        return <td key={index}>

                                                                            {(() => {
                                                                                if (rec === 'regDate' || rec === 'complianceDate' || rec === 'buildDate' || rec === 'serviceContFrom' || rec === 'serviceContTo' || rec === 'lastService' || rec === 'warrantyExpiry') {
                                                                                    return <>
                                                                                        <span className="wholesaler-subDept"><Translate text={rec} /></span><div>{tradeIn[rec] ? moment(tradeIn[rec], 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'massageVentilated' || rec === 'contrastStiching' || rec === 'financed' || rec === 'outstandingPayments' || rec === 'sunroof' || rec === 'camera' || rec === 'woodSteering' || rec === 'warranty' || rec === 'reconditioning') {
                                                                                    return <>
                                                                                        <span className="wholesaler-subDept"><Translate text={rec} /></span><div>{tradeIn[rec] ? 'YES' : 'NO'}</div>
                                                                                    </>
                                                                                }
                                                                                else if (rec === 'reconditioningCost') {
                                                                                    return <>
                                                                                        <span className="wholesaler-subDept"><Translate text={rec} /></span><div>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}</div>
                                                                                    </>
                                                                                }
                                                                                else if (objTradeIn.hasOwnProperty(rec)) {
                                                                                    return <><span className="wholesaler-subDept"><Translate text={rec} /></span><div>{tradeIn[rec] ? tradeIn[rec] : '--'}</div></>
                                                                                }
                                                                                else if (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                                                                                    let info = tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                                    if (info.type === 'price')
                                                                                        return <><span className="wholesaler-subDept"><Translate text={info.name} /></span><div>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}</div></>
                                                                                    else
                                                                                        return <><span className="wholesaler-subDept"><Translate text={info.name} /></span><div>{tradeIn[rec] ? tradeIn[rec] : '--'}</div></>
                                                                                }
                                                                            })()}
                                                                        </td>
                                                                    })
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div className="wholesaler-vehicle-tbl">
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="wholesaler-subtable">
                                                <tbody>
                                                    <tr>
                                                        <td><span className="wholesaler-subDept"><Translate text={'make'} /></span>
                                                            <div>{make ? make : '--'}</div> </td>
                                                        <td><span className="wholesaler-subDept"><Translate text={'model'} /></span>
                                                            <div>{model ? model : '--'}</div> </td>
                                                        <td><span className="wholesaler-subDept"><Translate text={'group'} /></span>
                                                            <div>{group ? group : '--'}</div></td>
                                                        <td><span className="wholesaler-subDept"><Translate text={'modelDescription'} /></span>
                                                            <div>{modelDescription ? modelDescription : '--'}</div> </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="wholesaler-subDept"><Translate text={'year'} /></span>
                                                            <div>{year ? year : '--'}</div>  </td>
                                                        <td><span className="wholesaler-subDept"><Translate text={'registrationNumber'} /></span>
                                                            <div>{regNo ? regNo : '--'}</div></td>
                                                        <td><span className="wholesaler-subDept"><Translate text={'registrationExpiry'} /></span>
                                                            <div>{regDate ? moment(regDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div></td>
                                                        <td><span className="wholesaler-subDept"><Translate text={'mileage'} /></span>
                                                            <div>{mileage ? mileage : '--'}</div></td>


                                                    </tr>
                                                    <tr>
                                                        <td><div><span className="wholesaler-subDept"><Translate text={'chassisNo'} /></span>
                                                            <div>{chassisNo ? chassisNo : '--'}</div>
                                                        </div></td>
                                                        <td><div><span className="wholesaler-subDept"><Translate text={'engineNo'} /></span>
                                                            <div>{engineNo ? engineNo : '--'}</div>
                                                        </div></td>
                                                        <td><div><span className="wholesaler-subDept"><Translate text={'transmission'} /></span>
                                                            <div>{transmission ? transmission : '--'}</div>
                                                        </div></td>
                                                        <td><div><span className="wholesaler-subDept"><Translate text={'type'} /></span>
                                                            <div>{type ? type : '--'}</div>
                                                        </div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div><span className="wholesaler-subDept"><Translate text={'complianceDate'} /></span>
                                                            <div>{complianceDate ? moment(complianceDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</div>
                                                        </div></td>
                                                        <td><div><span className="wholesaler-subDept"><Translate text={'buildDate'} /></span>
                                                            <div>{buildDate ? moment(buildDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</div>
                                                        </div></td>
                                                        <td><div><span className="wholesaler-subDept"><Translate text={'engineType'} /></span>
                                                            <div>{engineType ? engineType : '--'}</div>
                                                        </div></td>
                                                        <td><div><span className="wholesaler-subDept"><Translate text={'colour'} /></span>
                                                            <div>{exteriorColor ? exteriorColor : '--'}</div>
                                                        </div></td>
                                                    </tr>

                                                    {
                                                        showMoreInfo
                                                            ?
                                                            <>
                                                                <tr>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'vehicleCondition'} /></span>
                                                                        <div>{vehicleCondition ? vehicleCondition : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'interiorColor'} /></span>
                                                                        <div>{interiorColor ? interiorColor : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'trim'} /></span>
                                                                        <div>{trim ? trim : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'doors'} /></span>
                                                                        <div>{doors ? doors : '--'}</div>
                                                                    </div></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'engineSize'} /></span>
                                                                        <div>{engineSize ? engineSize : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'owners'} /></span>
                                                                        <div>{owners ? owners : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'financeCo'} /></span>
                                                                        <div>{financeCo ? financeCo : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'spareKey'} /></span>
                                                                        <div>{spareKey ? spareKey : '--'}</div>
                                                                    </div></td>
                                                                </tr>

                                                                <tr>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'serviceHistory'} /></span>
                                                                        <div>{serviceHistory ? serviceHistory : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'cargoBlind'} /></span>
                                                                        <div>{cargoBlind ? cargoBlind : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'logBooks'} /></span>
                                                                        <div>{logBooks ? logBooks : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'headRests'} /></span>
                                                                        <div>{headRests ? headRests : '--'}</div>
                                                                    </div></td>
                                                                </tr>

                                                                <tr>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'frontRightWheel'} /></span>
                                                                        <div>{frontRightWheel ? frontRightWheel : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'frontLeftWheel'} /></span>
                                                                        <div>{frontLeftWheel ? frontLeftWheel : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'rearRightWheel'} /></span>
                                                                        <div>{rearRightWheel ? rearRightWheel : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'rearLeftWheel'} /></span>
                                                                        <div>{rearLeftWheel ? rearLeftWheel : '--'}</div>
                                                                    </div></td>
                                                                </tr>

                                                                <tr>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'serviceContFrom'} /></span>
                                                                        <div>{serviceContFrom ? moment(serviceContFrom, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'serviceContTo'} /></span>
                                                                        <div>{serviceContTo ? moment(serviceContTo, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'lastService'} /></span>
                                                                        <div>{lastService ? moment(lastService, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'warrantyExpiry'} /></span>
                                                                        <div>{warrantyExpiry ? moment(warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                                                    </div></td>
                                                                </tr>

                                                                <tr>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'spareWheel'} /></span>
                                                                        <div>{spareTyre ? spareTyre : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'sdCard'} /></span>
                                                                        <div>{sdCard ? sdCard : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'tyreAge'} /></span>
                                                                        <div>{tyreAge ? tyreAge : '--'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'reconditioningCost'} /></span>
                                                                        <div>{reconditioningCost ? <NumberFormat style={{ fontSize: 12 }} value={reconditioningCost} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}</div>
                                                                    </div></td>
                                                                </tr>

                                                                <tr>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'massageVentilated'} /></span>
                                                                        <div>{massageVentilated ? 'YES' : 'NO'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'contrastStiching'} /></span>
                                                                        <div>{contrastStiching ? 'YES' : 'NO'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'sunroof'} /></span>
                                                                        <div>{sunroof ? 'YES' : 'NO'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'camera'} /></span>
                                                                        <div>{camera ? 'YES' : 'NO'}</div>
                                                                    </div></td>
                                                                </tr>

                                                                <tr>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'woodSteering'} /></span>
                                                                        <div>{woodSteering ? 'YES' : 'NO'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'outstandingPayments'} /></span>
                                                                        <div>{outstandingPayments ? 'YES' : 'NO'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'reconditioning'} /></span>
                                                                        <div>{reconditioning ? 'YES' : 'NO'}</div>
                                                                    </div></td>
                                                                    <td><div><span className="wholesaler-subDept"><Translate text={'financed'} /></span>
                                                                        <div>{financed ? 'YES' : 'NO'}</div>
                                                                    </div></td>
                                                                </tr>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                </tbody></table>

                                        </div> */}
                                    </div>

                                    <div className="tradein-section-boxloop">
                                        <div className="tradein-section-boxloop-header ">
                                            <div className="float-left">
                                                <h3 className="tradein-section-page-title"> <span
                                                    className="tradein-section-page-title-icon bg-tradein-grey text-white mr-3"> <i
                                                        className="ico icon-service-visuals"></i> </span> <Translate text={'Vehicle Visuals'} /> </h3>
                                            </div>
                                            <div className="float-right">

                                                <div className="service-rec-inspection">
                                                    <a data-fancybox={`tradein_inspection_img`} href={inspectionSnapshot ? inspectionSnapshot : _images[CommonHelper.getNoVehicleIcons(client?.category, 'inspection')]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                        <button type="button" className="btn btn-inspection-service mt-1"><i className={CommonHelper.getVehicleIcons(client?.category, 'ico icon-inspection')}></i> <Translate text={'vehicleInspection'} />
                                                            <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                            <div className="float-right ml-2">
                                                                <span className="badge badge-inspection">{!_.isEmpty(marks) ? marks.length : 0}</span>
                                                            </div>

                                                        </button>
                                                    </a>

                                                </div>

                                                {/* <button type="button" className="btn btn-inspection-service mt-1"><i className="ico icon-inspection"></i>Vehicle
                  Inspection</button> */}
                                            </div>
                                        </div>
                                        <div className="tradein-section-page-content pt-0 form-style">



                                            <div className="tabs recom-popup-tabs">
                                                <div className="tabs-wraper">
                                                    {/* <nav>
                                                        <div className="nav nav-tabs nav-fill position-relative" id="nav-tab" role="tablist">
                                                            <a className="nav-item nav-link active" id="nav-image1-tab" data-toggle="tab" href="#nav-image1"
                                                                role="tab" aria-controls="nav-image1" aria-selected="true">Images </a>
                                                            <a className="nav-item nav-link" id="nav-video1-tab" data-toggle="tab" href="#nav-video1" role="tab"
                                                                aria-controls="nav-video1" aria-selected="false">Videos</a>
                                                        </div>
                                                    </nav> */}
                                                    <div className="tab-content">
                                                        <div className="tab-pane fade show active"
                                                            aria-labelledby="nav-image1-tab">

                                                            <div className="tradein-section-thumb-wraper ">

                                                                {
                                                                    (!_.isEmpty(images) || !_.isEmpty(sidePhotos) || (!_.isEmpty(marks) && marks.find(item => !_.isEmpty(item.images))))
                                                                        ?
                                                                        <>
                                                                            <ul>
                                                                                {
                                                                                    !_.isEmpty(images) && images.map((data, index) => (
                                                                                        <div className="tradein-section-item-image" key={index}>
                                                                                            <a className="venobox vbox-item" data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="35" height="35">
                                                                                                <img id={`tradein-before-img-${index}`} src={data} width="35" height="35" alt="" className="img-object-fit" />
                                                                                            </a>
                                                                                        </div>
                                                                                    ))
                                                                                }

                                                                                {!_.isEmpty(sidePhotos) && Object.keys(sidePhotos).map((key, index) => {
                                                                                    if (sidePhotos[key]) {
                                                                                        return (<div className="tradein-section-item-image" key={index}>
                                                                                            <a data-fancybox={`tradein_img`} href={sidePhotos[key]} onClick={(e) => { e.preventDefault(); }} width="35" height="35">
                                                                                                <img id={`tradein-side-img-${index}`} src={sidePhotos[key]} width="35" height="35" alt="" className="img-object-fit" />
                                                                                            </a>
                                                                                        </div>)
                                                                                    }

                                                                                })}

                                                                                {
                                                                                    !_.isEmpty(marks) && marks.map((mark, index) => {
                                                                                        return <div key={index}>
                                                                                            {!_.isEmpty(mark.images) && mark.images.map((data, index) => (
                                                                                                <div className="tradein-section-item-image" key={index}>
                                                                                                    <a className="venobox vbox-item" data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="35" height="35">
                                                                                                        <img id={`tradein-mark-img-${index}`} src={data} width="35" height="35" alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </>
                                                                        :
                                                                        <>

                                                                            <div className="tradein-section-item-image">
                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }} width="35" height="35">
                                                                                    <img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} width="35" height="35" alt="" className="img-object-fit" />
                                                                                </a>
                                                                            </div>

                                                                        </>
                                                                }


                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                    <div className="service-section-boxloop mt-3">
                                        <div className="service-section-boxloop-header">
                                            <div className="float-left">
                                                <h3 className="service-section-page-title"> <span className="service-section-page-title-icon bg-service-yellow text-white mr-3"> <i className="ico icon-service-notes"></i> </span> <Translate text={'Notes'} /> </h3>
                                            </div>
                                            <div className="float-right">
                                                <div className="service-add-button">
                                                    <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); this.setState({ showNotes: true }) }}><i className="ico icon-add"></i></a>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="service-section-page-content  form-style">

                                            {
                                                !_.isEmpty(offerNotes)
                                                    ?
                                                    <>
                                                        {
                                                            _.orderBy(offerNotes, ['addedDate'], ['desc']).map((_data, index) => {
                                                                return <div className="notes-item text-area-space" key={index}>
                                                                    {_data.notes}
                                                                    <div className="notes-item-sub">

                                                                        <span className="pl-0">{_data.addedDate ? moment.unix(_data.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'} </span>
                                                                        &nbsp;{' - '}&nbsp;
                                                                        {CommonHelper.getUserNamebyId(clientUsers, _data.addedBy)}

                                                                        {
                                                                            (!_data.wholeSalerNotes) ? (
                                                                                <div className="badge badge-pill badge-input-white ml-2 text-uppercase">{'dealer'}</div>
                                                                            ) : (<></>)
                                                                        }

                                                                        {
                                                                            (_data.isInternalNotes) ? (
                                                                                <div className="badge badge-pill badge-input-white ml-1 text-uppercase">{'Internal'}</div>
                                                                            ) : (<></>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div className="spinner-loader">
                                                            <div className="no-cafe-flex justify-content-center align-items-center ">
                                                                <div className="no-cafe-img">
                                                                    <i className="ico icon-note icon-service-nonote"></i> </div>
                                                            </div>
                                                        </div>
                                                    </>

                                            }


                                        </div>
                                    </div>

                                    {
                                        !_.isEmpty(dynamicDetailList.filter(e => ['specifications'].includes(e))[0])
                                            ?
                                            <>
                                                <div className="service-section-boxloop mt-3">
                                                    <div className="service-section-boxloop-header">
                                                        <div className="float-left">
                                                            <h3 className="service-section-page-title"> <span className="service-section-page-title-icon bg-service-orange text-white mr-3"> <i className="ico icon-service-recommendation"></i> </span> <Translate text={'Specification'} /> </h3>
                                                        </div>

                                                    </div>

                                                    {
                                                        !_.isEmpty(specifications)
                                                            ?
                                                            <>

                                                                <div className="wholesaler-spec-section-page-content text-area-space "
                                                                    dangerouslySetInnerHTML={{ __html: (specifications ? '&middot; ' + specifications.replace(/\n/g, "<br/> &middot; ") : '--') }} />


                                                            </>
                                                            :
                                                            <>
                                                                <div className="spinner-loader">
                                                                    <div className="no-cafe-flex justify-content-center align-items-center ">
                                                                        <div className="no-cafe-img">
                                                                            <i className="ico icon-service-recommendation"></i> </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }

                                </div>


                                <aside className="small-column ml-3">
                                    <div className="enquiry-sidebar">
                                        <div id="accordion-left">


                                            <div className="card">
                                                <div className="card-header" >
                                                    <div className="mb-0">
                                                        <div className="wholesaler-offerlog">
                                                            <button type="button"
                                                                className="btn btn-default float-right tradein-section-logbtn"
                                                                onClick={(e) => { e.preventDefault(); this.handleLogOpen(); }}><i className="ico icon-service-log"></i> <Translate text={'Offer Log'} /></button>
                                                        </div>

                                                        <div className="cardlink"><a className="btn btn-link" data-toggle="collapse" data-target="#collapse1"
                                                            aria-expanded="true" aria-controls="collapse1"> <i className="ico icon-finance"></i> <Translate text={'Offer'} />
                                                        </a></div>
                                                    </div>
                                                </div>
                                                <div className="collapse show" aria-labelledby="heading1" data-parent="#accordion-left">
                                                    <div className="card-body">
                                                        <div className="wholesaler-evaluation-offer-wrapper">

                                                            {
                                                                evaluationPrice
                                                                    ?
                                                                    <>


                                                                        <div className="wholesaler-inputwrap">

                                                                            <p className="wholesaler-top-fixmob"><b><Translate text={'You have quoted the price'} /></b> </p>
                                                                            <div className="wholesaler-offer-cost"><NumberFormat
                                                                                style={{ fontSize: 25, fontWeight: 700 }}
                                                                                value={evaluationPrice}
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                thousandSeparator={true}
                                                                                prefix={currencySymbol} /></div>
                                                                        </div>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            status === tipOfferStatus.PENDING
                                                                                ?
                                                                                <>
                                                                                    <div className="wholesaler-inputwrap">
                                                                                        {
                                                                                            isReRequested
                                                                                                ?
                                                                                                <p className="wholesaler-top-fixmob"><b><Translate text={'The manager has re-requested for the price'} /></b> </p>
                                                                                                :
                                                                                                <p className="wholesaler-top-fixmob"><b><Translate text={'Please insert your offer and click Send button'} /></b> </p>
                                                                                        }


                                                                                        <div className="form">

                                                                                            <NumberFormat
                                                                                                id={'wholesalerPrice'}
                                                                                                decimalScale={2}
                                                                                                allowNegative={true}
                                                                                                thousandSeparator={true}
                                                                                                prefix={currencySymbol}
                                                                                                placeholder={currencySymbol}
                                                                                                className={`form-control wholesaler-input-price ${errorFields["wholesalerPrice"]}`}
                                                                                                onValueChange={(e) => {
                                                                                                    this.onCurrencyChange(e, 'wholesalerPrice')
                                                                                                }}
                                                                                                value={wholesalerPrice ? wholesalerPrice : null}
                                                                                            />


                                                                                            <div className="wholesaler-notes-item">
                                                                                                {/* <InputTextArea
                                                                                                    className={`form-control notes ${this.state.errors["notes"]}`}
                                                                                                    name="notes"
                                                                                                    onChange={this.handleOnAreaChange}
                                                                                                    value={notes}
                                                                                                    maxLength="280"
                                                                                                    rows="3">
                                                                                                </InputTextArea> */}

                                                                                                <textarea
                                                                                                    className={`form-control notes`}
                                                                                                    name="notes" onChange={(e) => this.handleNotesChanges(e)}
                                                                                                    value={wholesalerNote ? wholesalerNote : ''}
                                                                                                    placeholder="notes"
                                                                                                    //cols="80"
                                                                                                    rows="3"
                                                                                                ></textarea>

                                                                                            </div>



                                                                                            <div className="wholesaler-btn-wrap">
                                                                                                {/* <input type="submit" className="wholesaler-blue-btn pull-right" value="Send" onclick="" /> */}

                                                                                                <button type="button" className="wholesaler-blue-btn pull-right ml-2" onClick={(e) => this.handleSubmit(e)}>
                                                                                                    {
                                                                                                        loader ?
                                                                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                                            : (<></>)
                                                                                                    }
                                                                                                    <Translate text={'send'} />
                                                                                                </button>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }




                                                                    </>
                                                            }
                                                        </div>
                                                        {
                                                            previousWSPrice
                                                                ?
                                                                <div className="wholesaler-last-offer-alert alert-user mt-2">
                                                                    <Translate text={'Previous Offer'} /> : <b className="alert-link"><NumberFormat
                                                                        value={previousWSPrice}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol} /></b>

                                                                </div>
                                                                :
                                                                <></>
                                                        }


                                                        {/* <!-- <div className="wholesaler-evaluation-offer-wrapper">

                                                                                                            <div className="wholesaler-inputwrap">

                                                                                                                <p className="wholesaler-top-fixmob"><b>You have quoted the price</b> </p>
                                                                                                                <div className="wholesaler-offer-cost">$ 59,999</div>
                                                                                                            </div>
                                                                                                        </div> --> */}
                                                    </div>
                                                </div>

                                            </div>












                                            <div className="card">
                                                <div className="card-header" >
                                                    <div className="mb-0">


                                                        <div className="cardlink"><a className="btn btn-link" data-toggle="collapse" data-target="#collapse1"
                                                            aria-expanded="true" aria-controls="collapse1"> <i className="ico ico icon-dealers"></i> <Translate text={'Dealer Info'} />
                                                        </a></div>
                                                    </div>
                                                </div>
                                                <div className="collapse show" aria-labelledby="heading1" data-parent="#accordion-left">
                                                    <div className="card-body">
                                                        <div className="info-table">
                                                            <table className="table-fill">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="text-left label-text"><Translate text={'Dealer Name'} /></td>
                                                                        <td className="text-left">{client?.name ? client.name : '--'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left label-text"><Translate text={'phone'} /></td>
                                                                        <td className="text-left">{client?.phone ? client.phone : '--'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left label-text"> <Translate text={'email'} /></td>
                                                                        <td className="text-left">{client?.email ? client.email : '--'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-left label-text"><Translate text={'address'} /></td>
                                                                        <td className="text-left">{client?.address ? client.address : '--'}</td>
                                                                    </tr>






                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* <div className="enquiry-detailed-info">
                                                <ul>
                                                    <li> <span>Last Modified by:</span> Williams David</li>
                                                    <li> <span>Last Modified on:</span> 21/07/2022 02:45 PM</li>
                                                    <li> <span>Created on:</span> 21/07/2022 10:20 AM</li>
                                                </ul>
                                            </div> */}


                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </>
                    : this.state.pagenotFound ? <><PageNotFound /></>
                        :
                        <>
                            <LoaderOverlay text='Fetching tradein details...' active={true} />
                        </>

            }


            <PopUpModal show={showNotes}>
                <AddNotes
                    show={showNotes}
                    handleClose={this.handleNotesClose}
                />
            </PopUpModal>

            <PopUpModal show={showOfferLog}>
                <OfferLogs
                    show={showOfferLog}
                    tradeinProID={tradeinProID}
                    offerID={this.props.offerid}
                    userID={localStorage.uid}
                    handleClose={this.handleLogClose}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                >
                </OfferLogs>
            </PopUpModal>
        </>




    }
}

export default TradeInDetails;
