/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import { ReactSelect } from '../../../components';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'
/** VIEW-MODELS */


//#region  ASSIGN VARIABLES
const mappingList = [
    { active: true, value: 'title', label: <><Translate text={'title'} /></> },
    { active: true, value: 'firstName', label: <><Translate text={'firstName'} /></> },
    { active: true, value: 'middleName', label: <><Translate text={'middleName'} /></> },
    { active: true, value: 'lastName', label: <><Translate text={'lastName'} /></> },
    //{ active: true, value: 'preferredName', label: <><Translate text={'preferredName'} /></> },
    { active: true, value: 'phone', label: <><Translate text={'phone'} /></> },
    { active: true, value: 'email', label: <><Translate text={'email'} /></> },
    { active: true, value: 'gender', label: <><Translate text={'gender'} /></> },
    { active: true, value: 'dob', label: <><Translate text={'dob'} /></> },
    { active: true, value: 'licenseNo', label: <><Translate text={'licenseNo'} /></> },
    { active: true, value: 'licenseExpiry', label: <><Translate text={'licenseExpiry'} /></> },
    { active: true, value: 'licenseState', label: <><Translate text={'licenseState'} /></> },
    { active: true, value: 'licenseType', label: <><Translate text={'licenseType'} /></> },
    { active: true, value: 'licenseFrontURL', label: <><Translate text={'licenseFrontURL'} /></> },
    { active: true, value: 'licenseBackURL', label: <><Translate text={'licenseBackURL'} /></> },
    { active: true, value: 'address', label: <><Translate text={'address'} /></> },
    { active: true, value: 'areaCode', label: <><Translate text={'areaCode'} /></> },
    { active: true, value: 'state', label: <><Translate text={'state'} /></> },
    { active: true, value: 'country', label: <><Translate text={'country'} /></> },
    { active: true, value: 'ownedVehicle', label: <><Translate text={'ownedVehicle'} /></> },
    //{ active: true, value: 'model', label: <><Translate text={'vehicleModel'} /></> },
    //{ active: true, value: 'vinNo', label: <><Translate text={'vinNo'} /></> },
    //{ active: true, value: 'language', label: <><Translate text={'language'} /></> }
]
//#endregion


const MergeContact = (props) => {
    const [selected, setSelected] = useState({})
    const [isLoading, setLoading] = useState(false)


    useEffect(() => {
        const { selectedKeys } = props;

        if (!_.isEmpty(selectedKeys)) setSelected(selectedKeys);

    }, [])
    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, selected);
        if (e) {
            state[data.name] = e.value;
            setSelected(state)
        }
        else {
            delete state[data.name]
            setSelected(state)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (_.isEmpty(selected)) {
            Swal.fire('No mapping has been set yet.', '', 'info')
            return;
        }

        props.handleClose(selected);
    };

    let { show, mappingKeys, eventSettings } = props;
    let _mappingData = !_.isEmpty(eventSettings?.attendeesDF) ? _.union(mappingList, _.map(eventSettings.attendeesDF.filter(m => !['monthYear', 'price', 'number', 'date', 'toggle', 'switch'].includes(m.type)), function (e) { return { label: e.name, value: e.value, active: e.active } })) : mappingList

    return (
        <>

            <Modal
                show={show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName={`${!_.isEmpty(mappingKeys) && mappingKeys.length > 8 ? 'modal-dialog-scrollable' : ''} modal-dialog-centered modal-file-manager`}>
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={'Excel Attendee Mapping'} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="common-table">
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="head-light" width="30%"><Translate text={'Excel Fields'} /></th>
                                                <th scope="col" className="head-light" width="50%"><Translate text={'Attendee Fields'} /> </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                !_.isEmpty(mappingKeys) && mappingKeys.map((key, idx) => (
                                                    <tr key={idx}>
                                                        <th scope="row" className="th-active-bg">{key}</th>
                                                        <td>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-12">
                                                                    <ReactSelect
                                                                        options={_.uniqBy(_mappingData, 'value')}
                                                                        id={'_' + key}
                                                                        name={key}
                                                                        placeholder={key}
                                                                        onChange={handleSelectChange}
                                                                        value={selected[key] ? selected[key] : null}
                                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                                        removeClearable={false}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>



                                </div>
                            </div>
                        </div>


                    </div>
                </Modal.Body>

                <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                    <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); props.handleClose(); }}>
                        <Translate text={'cancel'} />
                    </button>
                    <button type="button" className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`} onClick={(e) => handleSubmit(e)} >
                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                        <Translate text={'done'} />
                    </button>
                </Modal.Footer>

            </Modal>






        </>
    );

}
export default MergeContact;