export const pipelineVM = {
    name: '',
    value: '',
    stages: [],
    modules: {
        contact: true,
        requirement: true,
        testDrive: true,
        tradeIn: true,
        cafe: true,
        files: true,
        finance: false,
        amsVideo: false
    },
    active: true,
    statusSettings: [],
    status: null,
    disableReport: null,
    makes: null,
    key: null
}

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]

export const pipelineSettingsModalFields = [
    {
        name: 'Pipeline',
        value: '_pipeline',
        flex: 2,
        default: true,
    },
    {
        name: 'Stage',
        value: '_stage',
        flex: 1,
        default: true
    },
    {
        name: 'Status',
        value: '_status',
        flex: 1,
        default: true,
    },
]