/** LIBRARIES */
import React, { PureComponent } from "react";
import moment from 'moment'
import _ from 'lodash';

export default class Timer extends PureComponent {
    constructor(props) {
        super(props);
        this.interval = null;
        this.state = {
            loading: false,
            qTime: null,
            timer: undefined,
        };
    }

    loadTimer = () => {
        const { timerStarted } = this.props;

        if (!_.isEmpty(timerStarted)) {

            var startTime = moment.unix(timerStarted.seconds);
            var currentTime = moment();
            var duration = moment.duration(currentTime.diff(startTime));
            var interval = 1000;

            this.interval = setInterval(() => {

                duration = moment.duration(duration + interval, 'milliseconds');
                const days = duration.days();
                const hours = duration.hours();
                const minutes = duration.minutes();
                const seconds = duration.seconds();
                var timer = (days > 0 ? ((days < 10 ? '0' : '') + days + "d ") : "") +
                    (hours < 10 ? '0' : '') + hours + ":" +
                    (minutes < 10 ? '0' : '') + minutes + ":" +
                    (seconds < 10 ? '0' : '') + seconds
                this.setState({ timer });

            }, interval);

        }
    }

    componentDidMount() {
        const { timerStarted } = this.props;
        if (!_.isEmpty(timerStarted))
            this.setState({ qTime: timerStarted });
        this.loadTimer();

    }

    componentDidUpdate() {
        if (this.state.loading) {
            clearInterval(this.interval);
            this.loadTimer();
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (JSON.stringify(props.timerStarted) !== JSON.stringify(state.qTime) && state.qTime) {
            //console.log('getDerivedStateFromProps - qTime', props.data.qTime, state.qTime)            
            return {
                loading: true,
                qTime: props.timerStarted
            }
        }
        else {
            return {
                loading: false
            }
        }

    }

    onFinish = () => {
        console.log('countdown finished!');
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { timer } = this.state;

        return (
            <>
                {
                    (timer)
                        ?
                        <>{timer}</>
                        :
                        <>{'00:00:00'}</>

                }

            </>

        );
    }
}

