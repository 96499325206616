import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText } from '../../../../components'
import Translate from '../../../../constants/translate';

const UpdatePassword = (props) => {
    const [objPassword, setObjpassword] = useState({password:'',cpassword:''});
    const [loader,setLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setObjpassword({
            ...objPassword,
            [name]: value
        });
    }

    const resetPassword = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(objPassword['password'])) {
            formIsValid = false;
            errors['password'] = errorClass;
        }
        if (objPassword['password'] !== objPassword['cpassword']) {
            formIsValid = false;
            errors['cpassword'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        const updateUserPassword = window.firebase.functions().httpsCallable('amsadmin-updateUserPassword');
        updateUserPassword({
                "uid": props.uid,
                "password": objPassword.password
            }).then((data) => { 
                //console.log(data)
                setLoader(false)
                if (data.data.success === true) {
                    toast.notify('Password updated successfully', {
                        duration: 2000
                    })
                    props.handleClose();
                }
                else {
                    Swal.fire(data.data.message, '', 'error')
                }
            });
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={'Update Password'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'password'}/></label>
                                    <InputText
                                        type={'password'}
                                        autoComplete="off"
                                        placeholder={'password'}
                                        className={`form-control ${errorFields["password"]}`}
                                        name="password"
                                        onChange={handleOnChange}
                                        value={objPassword.password}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Confirm Password'}/></label>
                                    <InputText
                                        type={'password'}
                                        autoComplete="off"
                                        placeholder={'confirm password'}
                                        className={`form-control ${errorFields["cpassword"]}`}
                                        name="cpassword"
                                        onChange={handleOnChange}
                                        value={objPassword.cpassword}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => resetPassword(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                        <Translate text={'save'}/>
                        </button>
                <button type="button" className="btn btn-default float-right" onClick={()=>props.handleClose()} ><Translate text={'cancel'}/></button>
            </Modal.Footer>
        </Modal>
    );
}

export default UpdatePassword;