/** LIBRARIES */
import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Route, Switch } from 'react-router-dom';
import _ from 'lodash'
/** COMPONENTS */
import QuoteForm from '../form';
import QuotePreview from '../preview';

const MainContainer = props => {
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let _type = params.get('type');
	let _stockID = params.get('stockID');
	let _previewID = params.get('previewID');
	let _showPrice = params.get('showPrice')

	const tab = (_type === 'new' || _type === 'edit') ? 'details' : 'preview';
	const [activeTab, setActiveTab] = useState(tab);
	const [newlySavedData, setNewlySavedData] = useState({});
	const [client, setClient] = useState({});
	const [clientSettings, setClientSettings] = useState({});
	const [currencySymbol, setCurrencySymbol] = useState('$ ');

	let enquiryID = props.history.location.pathname.replace(
		'/quote/details/',
		'',
	);

	const [firestoreDB, setFirestoreDB] = useState()
	useEffect(() => {
		window.firebase.firestore().doc(`enquiries/${enquiryID}`).get()
			.then(doc => {
				if (doc.exists) {
					setFirestoreDB(window.firebase)
				}
				else {
					setFirestoreDB(window.firebase2)
				}
			})
	}, [])

	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return
		if (_.isEmpty(firestoreDB.auth().currentUser)) {
			firestoreDB.auth().signInWithEmailAndPassword('secondarydb@fusionamspro.com', 'secondaryDB@321')
				.catch((error) => {
					console.log('error on secondary firestore auth user', error)
				});
		}
	}, [firestoreDB])

	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return
		const fetchData = async () => {
			if ((_type === 'print' || _type === 'preview') && _previewID) {
				let quoteData = {}
				const quoteRef = firestoreDB.firestore().doc(`enquiries/${enquiryID}/quotations/${_previewID}`);
				const quoteSnap = await quoteRef.get();
				quoteData = quoteSnap.data();
				if (quoteData.clientID) {
					const clientSnap = await window.firebase.firestore().doc(`clients/${quoteData.clientID}`).get()
					let clientData = clientSnap.data();
					const langtermsSnapshot = await window.firebase.firestore().collection(`clients/${quoteData.clientID}/localizeData`).get();
					if (clientData && langtermsSnapshot.docs.length > 0) {
						langtermsSnapshot.docs.forEach(rec => {
							clientData[rec.id] = rec.data();
						})
					}
					const clientSettingsSnap = await window.firebase.firestore().doc(`clients/${quoteData.clientID}/currentSettings/${quoteData.clientID}`).get()
					const clientSettingsData = clientSettingsSnap.data();
					setClient(clientData);
					setClientSettings(clientSettingsData);
					setCurrencySymbol(((!_.isEmpty(clientData) &&
						!_.isEmpty(clientData.currency)) ? clientData.currency.symbol + ' ' : '$ '))
				}
				setNewlySavedData(quoteData)
			}
		}
		fetchData()
	}, [firestoreDB])

	return activeTab === 'details' ? (
		<QuoteForm
			setActiveTab={setActiveTab}
			enquiryID={props.match.params.id}
			history={props.history}
			newlySavedData={newlySavedData}
			setNewlySavedData={setNewlySavedData}
			client={client}
			setClient={setClient}
			clientSettings={clientSettings}
			setClientSettings={setClientSettings}
			currencySymbol={currencySymbol}
			setCurrencySymbol={setCurrencySymbol}
			_type={_type}
			_stockID={_stockID}
			_previewID={_previewID}
		/>
	) : (
		<QuotePreview
			setActiveTab={setActiveTab}
			enquiryID={props.match.params.id}
			history={props.history}
			newlySavedData={newlySavedData}
			setNewlySavedData={setNewlySavedData}
			client={client}
			setClient={setClient}
			clientSettings={clientSettings}
			setClientSettings={setClientSettings}
			currencySymbol={currencySymbol}
			setCurrencySymbol={setCurrencySymbol}
			_type={_type}
			_stockID={_stockID}
			_previewID={_previewID}
			_showPrice={_showPrice}
		/>
	);
};

export default MainContainer;
