import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Translate from '../../../../constants/translate';

const RearrangePipeline = (props) => {
    const [pipelines, setPipelines] = useState(props.allPipelines);
    const [loader, setLoader] = useState(false)

    const savePipeline = () => {
        // console.log(stages)
        // props.handleClose({
        //     ...props.pipeline,
        //     stages: [...stages]
        // })



        setLoader(true)
        console.log('address', `clientSettings/${props.dealersettings.client.settingsID}/clients/${props.selectedClient.documentID}/services/${props.selectedClient.documentID}/enquiryOptions/${props.selectedClient.documentID}`)
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${props.selectedClient.documentID}/services/${props.selectedClient.documentID}/enquiryOptions/${props.selectedClient.documentID}`)
            .set({
                pipelines: pipelines.map(r => { return r.value })
            }, { merge: true })
            .then(snapshot => {
                console.log('rearrange  pipeline done')
                setLoader(false)
                //update settings date and priority
                if (!_.isEmpty(props.selectedClient)) {
                    window.firebase.firestore().doc(`clients/${props.selectedClient.documentID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Service Pipeline Settings has been updated',
                        }, { merge: true })
                }
                //end
                toast.notify('Pipeline moved successfully', {
                    duration: 2000
                })
                props.handleClose()
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const [removed] = pipelines.splice(result.source.index, 1);
        pipelines.splice(result.destination.index, 0, removed);
        setPipelines(pipelines)
    }



    return _.isEmpty(pipelines) ? (<></>) : (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-rearrange-stage"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid" >
                <div className="row " style={{ height: `${(pipelines.length * 60) + 30}px` }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="rearrange-stage-wrap">
                                    {
                                        pipelines.map((rec, index) => {
                                            return <Draggable key={rec.value} draggableId={rec.value} index={index}>
                                                {(provided, snapshot) => (<div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="stage-rearrange-box stage-flex-queue sort-pipeline-fix">
                                                    <div className="stage-rearrange-count"> <span className="badge badge-pill badge-stage-queue ml-1">{index + 1}</span> </div>
                                                    <div className="queue-drag"> <i className="ico icon-drag"></i></div>
                                                    <div className="stage-rearrange-detail">
                                                        <h3>{rec.name} </h3>
                                                    </div>
                                                </div>
                                                )}
                                            </Draggable>
                                        })
                                    }

                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => savePipeline(e)}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>
    );
}

export default RearrangePipeline;