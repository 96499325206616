/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData } from 'rxfire/firestore';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { collection } from 'rxfire/firestore';
/** COMPONENTS */
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import { ContentOverlay, PieChart, NoDataPieChart } from '../../components'
import Translate from '../../constants/translate';

const acquisitionCertifiable = [
    { active: true, value: 'Yes', label: 'Yes' },
    { active: true, value: 'No', label: 'No' },
    { active: true, value: 'NA', label: 'N/A' },
]

const AcquisitionReport = (props) => {
    const [acquisitions, setAcquisitions] = useState([])
    const [client, setClient] = useState({});
    const [clientSettings, setClientSettings] = useState();
    const [dateRange, setDateRange] = useState({})
    const [brands, setBrands] = useState([])

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setClient(doc.data());
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(client))
            return;

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let _date = params.get('date');
        _date = _date ? (_date.split('-').length === 2 ? `${_date}-01` : _date) : moment().format('YYYY-MM-DD');


        let timezone = !_.isEmpty(client.timezone) ? client.timezone : moment.tz.guess();
        let _dateRange = {
            startDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(timezone).startOf('month')._d),
            endDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(timezone).endOf('month')._d)
        }
        if (_date === 'today') {
            _dateRange = {
                startDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).startOf('day').valueOf()),
                endDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('day').valueOf())
            };
        }
        else if (_date === 'wtd') {
            _dateRange = {
                startDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).add('day', -6).startOf('day').valueOf()),
                endDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('day').valueOf())
            };
        }
        else if (_date === 'mtd' || _date === 'undefined') {
            _dateRange = {
                startDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).startOf('month').valueOf()),
                endDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('month').valueOf())
            };
        }

        setDateRange(_dateRange)

        let refAcquisitionsData = window.firebase.firestore().collection('acquisitions')
            .where('clientID', '==', props.clientid)
            .where('isDeleted', '==', false)
            .where('logDate', '>=', _dateRange.startDate)
            .where('logDate', '<=', _dateRange.endDate)
            .orderBy('logDate', 'desc')

        let refClientData = window.firebase.firestore().collection('clients').where('documentID', '==', props.clientid)
        let refClientSettings = window.firebase.firestore().collection('clients').doc(props.clientid).collection('currentSettings')

        var acqList = combineLatest(
            collection(refAcquisitionsData),
            collection(refClientData),
            collection(refClientSettings),
        ).pipe(
            map(([acquisition, clients, clientsSetting]) => {
                return [
                    _.map(acquisition, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(clients, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(clientsSetting, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                ]
            }))
            .subscribe(allDocs => {
                let _clientsSetting = allDocs[2] ? Object.assign({}, allDocs[2][0]) : {}
                let _acqList = _.map((allDocs[0] ? Object.assign([], allDocs[0]) : []), function (_data) {
                    return {
                        ..._data,
                        //'certifiable_name': CommonHelper.getNameByValue(_clientsSetting?.settings?.acquisitionCertifiable, _data.certifiable),
                        'buyer_name': CommonHelper.getNameByValue(_clientsSetting?.settings?.acquisitionBuyer, _data.buyer),
                        'vehicleSource_name': CommonHelper.getNameByValue(_clientsSetting?.settings?.acquisitionSource, _data.vehicleSource),
                        'vehicleDestination_name': CommonHelper.getNameByValue(_clientsSetting?.settings?.acquisitionDestination, _data.vehicleDestination),
                    }
                })

                setAcquisitions(_acqList)
                //setClient(allDocs[1] ? Object.assign({}, allDocs[1][0]) : {})
                setBrands(_clientsSetting ? _clientsSetting.brands : [])
                setClientSettings(_clientsSetting)
            });
        return () => {
            acqList && acqList.unsubscribe()
        }
    }, [client])



    return (
        <>
            {
                !_.isEmpty(client) && !_.isEmpty(clientSettings)
                    ?
                    <>
                        <div className="A4 landscape report-data-loaded report-data-load">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }
                                                </div></td>

                                                <td width="40%"><div className="header-right"><Translate text={'acquisitionreport'} upperCase={true} /> {` ${client ? ' - ' + client.name.toUpperCase() : ''}`} <br></br>
                                                    <span> {moment.unix(dateRange.startDate.seconds).format('MMMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">

                                    <div className="table-head"><Translate text={'Acquisition'} upperCase={true} /></div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>

                                                <th width="10%"><Translate text={'Log Date'} /></th>
                                                <th width="10%"><Translate text={'make'} /></th>
                                                <th width="10%"><Translate text={'model'} /></th>
                                                <th width="10%"><Translate text={'Certifiable'} /></th>
                                                <th width="10%"><Translate text={'Registration #'} /></th>
                                                <th width="10%"><Translate text={'Buyer'} /></th>
                                                <th width="10%"><Translate text={'Car Sales'} /></th>
                                                <th width="10%"><Translate text={'Destination'} /></th>
                                                <th width="15%"><Translate text={'notes'} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !_.isEmpty(acquisitions) ?
                                                    <>{acquisitions.map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td>{data.logDate ? moment.unix(data.logDate.seconds).format('DD-MM-YYYY') : ''}</td>
                                                            <td>{data.make ? data.make : ''}</td>
                                                            <td>{data.model ? data.model : ''}</td>
                                                            <td>{data.certifiable ? data.certifiable : ''}</td>
                                                            <td>{data.registration ? data.registration : ''}</td>
                                                            <td>{data.buyer_name ? data.buyer_name : ''}</td>
                                                            <td>{data.vehicleSource_name ? data.vehicleSource_name : ''}</td>
                                                            <td>{data.vehicleDestination_name ? data.vehicleDestination_name : ''}</td>
                                                            <td>{data.notes ? <><div className={'text-area-space'}>
                                                                {data.notes}
                                                            </div></> : <></>}</td>
                                                        </tr>)
                                                    })
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                        <tr>
                                                            <td colSpan={9}>
                                                                <div className="spinner-loader h-100 p-2">
                                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                        <div className="no-data-txt mt-2"> <Translate text={'No Data'} /></div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                            }


                                        </tbody>
                                    </table>
                                    <div className="graph-wrapper">
                                        <div className="table-left-align">

                                            <table className="table-loop table-sub-fix">
                                                <thead>
                                                    <tr>
                                                        <th width="80%"><Translate text={'Source'} upperCase={true} /></th>
                                                        <th width="20%"><Translate text={'Units'} upperCase={true} /></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        clientSettings?.settings?.acquisitionSource && clientSettings.settings.acquisitionSource.map((data, index) => {
                                                            return <tr key={index}>
                                                                <td>{data.name}</td>
                                                                <td className="count-large">{acquisitions.filter(e => e.vehicleSource === data.value).length}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    <tr>
                                                        <td><Translate text={'Total MTD'}/></td>
                                                        <td className="count-large">{acquisitions.filter(e => !_.isEmpty(e.vehicleSource)).length}</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="table-right-align">


                                            <table className="table-loop table-sub-fix">
                                                <thead>
                                                    <tr>
                                                        <th width="80%"><Translate text={'Destination'} upperCase={true} /></th>


                                                        <th width="20%"><Translate text={'UNITS'} upperCase={true} /></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        clientSettings?.settings?.acquisitionDestination && clientSettings.settings.acquisitionDestination.map((data, index) => {
                                                            return <tr key={index}>
                                                                <td>{data.name}</td>
                                                                <td className="count-large">{acquisitions.filter(e => e.vehicleDestination === data.value).length}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    {/* <tr>
                                                        <td>{'Total MTD'}</td>
                                                        <td className="count-large">{acquisitions.filter(e => !_.isEmpty(e.vehicleDestination)).length}</td>
                                                    </tr> */}


                                                </tbody>
                                            </table>

                                            <table className="table-loop table-sub-fix">
                                                <thead>
                                                    <tr>
                                                        <th width="80%"><Translate text={'Buyer'} upperCase={true} /></th>


                                                        <th width="20%"><Translate text={'UNITS'} upperCase={true} /></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        clientSettings?.settings?.acquisitionBuyer && clientSettings.settings.acquisitionBuyer.map((data, index) => {
                                                            return <tr key={index}>
                                                                <td>{data.name}</td>
                                                                <td className="count-large">{acquisitions.filter(e => e.buyer === data.value).length}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    {/* <tr>
                                                        <td>{'Total MTD'}</td>
                                                        <td className="count-large">{acquisitions.filter(e => !_.isEmpty(e.buyer)).length}</td>
                                                    </tr> */}


                                                </tbody>
                                            </table>


                                            <table className="table-loop table-sub-fix">
                                                <thead>
                                                    <tr>
                                                        <th width="60%"><Translate text={'make'} upperCase={true} /></th>
                                                        <th width="20%"><Translate text={'Certifiable'} upperCase={true} /></th>
                                                        <th width="20%"><Translate text={'Total'} upperCase={true} /></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        brands && brands.map((data, index) => {
                                                            return <tr key={index}>
                                                                <td>{data.name}</td>
                                                                <td className="count-large">{acquisitions.filter(e => e.certifiable === 'Yes' && e.make.toLowerCase() === data.value.toLowerCase()).length}</td>
                                                                <td className="count-large">{acquisitions.filter(e => e.make.toLowerCase() === data.value.toLowerCase()).length}</td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    <div className="graph-wrapper">
                                        <div className="graph-left">
                                            <div className="graph-head"><Translate text={'Source'} /></div>
                                            <div className="graph-panel">
                                                {
                                                    !_.isEmpty(acquisitions) ? (
                                                        <div className="dashboard-box-body p-0" style={{ minHeight: `350px` }}>
                                                            <div className="text-center">
                                                                <PieChart
                                                                    id={'acquisition-pie-chart'}
                                                                    height={'350px'}
                                                                    data={clientSettings?.settings?.acquisitionSource && clientSettings.settings.acquisitionSource.map((data) => {
                                                                        return {
                                                                            name: data.name,
                                                                            color: data.color,
                                                                            count: acquisitions.filter(e => e.vehicleSource === data.value).length
                                                                        }
                                                                    })}
                                                                    handleShowEnquiryList={() => { }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                                                            <div className="text-center">
                                                                <NoDataPieChart
                                                                    id={'acquisition-pie-chart'}
                                                                    height={'350px'}
                                                                />
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="graph-border-right">
                                            <div className="graph-head"><Translate text={'Certifiable'} /></div>
                                            <div className="graph-panel">
                                                {
                                                    !_.isEmpty(acquisitions) ? (
                                                        <div className="dashboard-box-body p-0" style={{ minHeight: `350px` }}>
                                                            <div className="text-center">
                                                                <PieChart
                                                                    id={'certifiable-pie-chart'}
                                                                    height={'350px'}
                                                                    data={acquisitionCertifiable.filter(a => a.value !== 'NA').map((data) => {
                                                                        return {
                                                                            name: data.value === 'Yes' ? data.label : 'Total',
                                                                            count: acquisitions.filter(e => brands.some(a =>
                                                                                a.value.toLowerCase() === e.make.toLowerCase())
                                                                                && (data.value === 'Yes' ? e.certifiable === 'Yes' : true)).length,
                                                                            color: data.value === 'Yes' ? '#ef4136' : '#000000'
                                                                        }
                                                                    })}
                                                                    handleShowEnquiryList={() => { }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                                                            <div className="text-center">
                                                                <NoDataPieChart
                                                                    id={'certifiable-pie-chart'}
                                                                    height={'350px'}
                                                                />
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address}</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>


                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>

            }
        </>



    )
};

export default AcquisitionReport;
