import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import common from '../../../services/common';

const StatusUpdate = (props) => {
    const [loader, setLoader] = useState(true)
    const [client, setClient] = useState({});
    const [reportData, setReportData] = useState();
    const [dateRange, setDateRange] = useState({})
    const [enquiryOptions, setEnquiryOptions] = useState({})
    const [firestoreDB, setFirestoreDB] = useState()
    const [timezone, setTimezone] = useState()

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}/currentSettings/${props.clientid}`)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let _date = params.get('date');
                    const _timezone = doc.data()?.timezone ? doc.data().timezone : moment().tz.guess();
                    setTimezone(_timezone)
                    _date = _date ? _date : moment.tz(_timezone).format('YYYY-MM-DD');

                    setDateRange({
                        date: _date,
                        todayStart: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(_timezone).startOf('day').toDate()),
                        todayEnd: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(_timezone).endOf('day').toDate()),
                        yesterdayStart: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(_timezone).add('day', '-1').startOf('day').toDate()),
                        yesterdayEnd: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(_timezone).add('day', '-1').endOf('day').toDate()),
                    });
                    setEnquiryOptions(doc.data()?.settings?.enquiryOptionsDF?.filter(a => !_.isEmpty(a.key)))
                    setClient({
                        ...doc.data(),
                        documentID: doc.id
                    });

                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(client) || _.isEmpty(firestoreDB))
            return

        if (enquiryOptions.length > 0) {
            window.firebase.firestore()
                .collection('changeLogs')
                .where('logDate', '>=', dateRange.todayStart)
                .where('logDate', '<=', dateRange.todayEnd)
                .where('clientID', '==', client.documentID)
                .where('type', '==', 'enquiry')
                .where('subType', '==', 'updated')
                .get()
                .then(async snapshot => {
                    if (snapshot.docs.length > 0) {
                        let data = [];
                        snapshot.docs.forEach(doc => {
                            const docData = doc.data();
                            let _enquiry = data.find(e => e.enquiryID === docData.recordId) ? data.find(e => e.enquiryID === docData.recordId) : {};
                            enquiryOptions.filter(a => a.key === 'dealerETA' || a.key === 'locationStatus').forEach(rec => {
                                if (docData?.newData?.dynamicFields?.[rec.value] && docData.recordId) {
                                    _enquiry = {
                                        ..._enquiry,
                                        ['enquiryID']: docData.recordId,
                                        [`current${rec.key}`]: docData?.newData?.dynamicFields?.[rec.value]?.seconds ? moment.unix(docData?.newData?.dynamicFields?.[rec.value]?.seconds).tz(timezone).format('DD/MM/YYYY') : docData?.newData?.dynamicFields?.[rec.value],
                                        [`prev${rec.key}`]: docData?.oldData?.dynamicFields?.[rec.value] ? docData?.oldData?.dynamicFields?.[rec.value]?.seconds ? moment.unix(docData?.oldData?.dynamicFields?.[rec.value]?.seconds).tz(timezone).format('DD/MM/YYYY') : docData?.oldData?.dynamicFields?.[rec.value] : '--',
                                    }
                                }
                            })
                            if (!_.isEmpty(_enquiry))
                                data = [
                                    ...data.filter(a => a.enquiryID !== _enquiry.enquiryID),
                                    _enquiry
                                ];
                        });
                        let enqPromise = [];
                        data.forEach(enq => {
                            enqPromise.push(firestoreDB.firestore().doc(`enquiries/${enq.enquiryID}`).get())
                        })
                        const enqSnapshot = await Promise.all(enqPromise);
                        const enquiries = enqSnapshot.map(r => { return { ...r.data(), id: r.id } });
                        data = data.map(r => {
                            return {
                                ...r,
                                ...mapEnquiry(enquiries.find(a => a.id === r.enquiryID))
                            }
                        })
                        setReportData(data);
                        setLoader(false)
                    }
                    else {
                        setLoader(false)
                    }
                })
        }
        else {
            setLoader(false)
        }

    }, [client, firestoreDB])

    // useEffect(() => {
    //     if (_.isEmpty(client) || _.isEmpty(firestoreDB))
    //         return

    //     if (enquiryOptions.length > 0) {
    //         const changeLogPromises = [];
    //         changeLogPromises.push(
    //             window.firebase.firestore()
    //                 .collection('changeLogs')
    //                 .where('logDate', '>=', dateRange.yesterdayStart)
    //                 .where('logDate', '<=', dateRange.yesterdayEnd)
    //                 .where('clientID', '==', client.documentID)
    //                 .where('type', '==', 'enquiry')
    //                 .where('subType', '==', 'updated')
    //                 .get()
    //         );
    //         changeLogPromises.push(
    //             window.firebase.firestore()
    //                 .collection('changeLogs')
    //                 .where('logDate', '>=', dateRange.todayStart)
    //                 .where('logDate', '<=', dateRange.todayEnd)
    //                 .where('clientID', '==', client.documentID)
    //                 .where('type', '==', 'enquiry')
    //                 .where('subType', '==', 'updated')
    //                 .get()
    //         );
    //         Promise.all(changeLogPromises)
    //             .then(async snapshot => {
    //                 const _yesterdayData = _.map(snapshot[0].docs, function (obj) {
    //                     return {
    //                         ...obj.data(), id: obj.id
    //                     };
    //                 });
    //                 const _todayData = _.map(snapshot[1].docs, function (obj) {
    //                     return {
    //                         ...obj.data(), id: obj.id
    //                     };
    //                 });
    //                 if (_yesterdayData.length > 0 || _todayData.length > 0) {
    //                     let data = [];
    //                     _yesterdayData.forEach(doc => {
    //                         const docData = doc;
    //                         let _enquiry = data.find(e => e.enquiryID === docData.recordId) ? data.find(e => e.enquiryID === docData.recordId) : {};
    //                         enquiryOptions.filter(a => a.key === 'dealerETA' || a.key === 'locationStatus').forEach(rec => {
    //                             if (docData?.newData?.dynamicFields?.[rec.value] && docData.recordId) {
    //                                 _enquiry = {
    //                                     ..._enquiry,
    //                                     ['enquiryID']: docData.recordId,
    //                                     [`prev${rec.key}`]: docData?.newData?.dynamicFields?.[rec.value] ? docData?.newData?.dynamicFields?.[rec.value]?.seconds ? moment.unix(docData?.newData?.dynamicFields?.[rec.value]?.seconds).tz(timezone).format('DD/MM/YYYY') : docData?.newData?.dynamicFields?.[rec.value] : '--',
    //                                 }
    //                             }
    //                         })
    //                         if (!_.isEmpty(_enquiry))
    //                             data = [
    //                                 ...data.filter(a => a.enquiryID !== _enquiry.enquiryID),
    //                                 _enquiry
    //                             ];
    //                     });
    //                     _todayData.forEach(doc => {
    //                         const docData = doc;
    //                         let _enquiry = data.find(e => e.enquiryID === docData.recordId) ? data.find(e => e.enquiryID === docData.recordId) : {};
    //                         enquiryOptions.filter(a => a.key === 'dealerETA' || a.key === 'locationStatus').forEach(rec => {
    //                             if (docData?.newData?.dynamicFields?.[rec.value] && docData.recordId) {
    //                                 _enquiry = {
    //                                     ..._enquiry,
    //                                     ['enquiryID']: docData.recordId,
    //                                     [`current${rec.key}`]: docData?.newData?.dynamicFields?.[rec.value] ? docData?.newData?.dynamicFields?.[rec.value]?.seconds ? moment.unix(docData?.newData?.dynamicFields?.[rec.value]?.seconds).tz(timezone).format('DD/MM/YYYY') : docData?.newData?.dynamicFields?.[rec.value] : '--',
    //                                 }
    //                             }
    //                         })
    //                         if (!_.isEmpty(_enquiry))
    //                             data = [
    //                                 ...data.filter(a => a.enquiryID !== _enquiry.enquiryID),
    //                                 _enquiry
    //                             ];
    //                     });
    //                     let enqPromise = [];
    //                     data.forEach(enq => {
    //                         enqPromise.push(firestoreDB.firestore().doc(`enquiries/${enq.enquiryID}`).get())
    //                     })
    //                     const enqSnapshot = await Promise.all(enqPromise);
    //                     const enquiries = enqSnapshot.map(r => { return { ...r.data(), id: r.id } });
    //                     data = data.map(r => {
    //                         return {
    //                             ...r,
    //                             ...mapEnquiry(enquiries.find(a => a.id === r.enquiryID))
    //                         }
    //                     })
    //                     setReportData(data);
    //                     setLoader(false)
    //                 }
    //                 else {
    //                     setLoader(false)
    //                 }
    //             })
    //     }
    //     else {
    //         setLoader(false)
    //     }

    // }, [client, firestoreDB])

    const mapEnquiry = (doc) => {
        let _data = {};
        if (doc) {
            if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
                _data.vehicleModel = <div className="user-list-items-info pr-0 ">
                    <h4>{!_.isEmpty(doc.requirement.stock) && !_.isEmpty(doc.requirement.stock.make) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : !_.isEmpty(doc.requirement.make) ? doc.requirement.make + ' ' + doc.requirement.model : ''}</h4>
                    <h5>{common.getNameByValue(client?.settings?.salesType, doc.requirement.saleType, '')}</h5>
                </div>
            }
            _data.displayID = doc.displayID;
            if (enquiryOptions.find(a => a.key === 'batchNumber') && doc.dynamicFields[enquiryOptions.find(a => a.key === 'batchNumber').value]) {
                _data.batchNumber = <div className="user-list-items-info pr-0 ">
                    <h4>{doc.dynamicFields[enquiryOptions.find(a => a.key === 'batchNumber').value]}</h4>
                    <h5>{doc.displayID}</h5>
                </div>;
            }
            else {
                _data.batchNumber = doc.displayID
            }
            if (enquiryOptions.find(a => a.key === 'dealNumber') && doc.dynamicFields[enquiryOptions.find(a => a.key === 'dealNumber').value]) {
                _data.dealNumber = doc.dynamicFields[enquiryOptions.find(a => a.key === 'dealNumber').value];
            }
            if (enquiryOptions.find(a => a.key === 'shipName') && doc.dynamicFields[enquiryOptions.find(a => a.key === 'shipName').value]) {
                _data.shipName = doc.dynamicFields[enquiryOptions.find(a => a.key === 'shipName').value];
            }
            if (enquiryOptions.find(a => a.key === 'retailCategory') && doc.dynamicFields[enquiryOptions.find(a => a.key === 'retailCategory').value]) {
                _data.retailCategory = doc.dynamicFields[enquiryOptions.find(a => a.key === 'retailCategory').value];
            }
            if (!_.isEmpty(doc.contact)) {
                _data.customerName = <div className="user-list-items-info pr-0 ">
                    <h4>{common.displayContact(client?.settings?.titles, doc.contact, '', true)}</h4>
                    {
                        doc.contact.phone ? (
                            <h5>{common.getFullPhone(doc.contact.phoneCode, doc.contact.phone)}</h5>
                        ) : doc.contact.email ? (
                            <h5>{doc.contact.email}</h5>
                        ) : (<></>)
                    }

                </div>;

            }
        }
        return _data;
    }


    return loader || _.isEmpty(client) ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="report-loader spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) : (
        <div className="A4 landscape">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header report-data-loaded report-data-load">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        client && client.logoURL
                                            ?
                                            <div className="logo-union"> <img src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                            </>
                                    }
                                </div></td>

                                <td width="40%"><div className="header-right"> {`STATUS UPDATE - ${client.name.toUpperCase()}`} <br></br>
                                    <span> {moment(dateRange.date).format('DD MMMM YYYY')}</span> </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-wraper">
                    <div className="table-head">Today's Dealer ETA Changes</div>
                    <table className="table-loop table-sub-fix">
                        <thead>
                            <tr>
                                <th width="10%">BATCH NUMBER</th>
                                <th width="10%">DEAL NUMBER</th>
                                <th width="10%">VESSEL</th>
                                <th width="10%">DEPARTMENT</th>
                                <th width="20%">CUSTOMER</th>
                                <th width="20%">VEHICLE MODEL</th>
                                <th width="10%">YESTERDAY'S DEALER ETA</th>
                                <th width="10%">TODAY'S DEALER ETA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reportData && reportData.filter(a => a.prevdealerETA || a.currentdealerETA).map((data, index) => {
                                    return <tr key={index}>
                                        <td>{data.batchNumber}</td>
                                        <td>{data.dealNumber ? data.dealNumber : '--'}</td>
                                        <td>{data.shipName ? data.shipName : '--'}</td>
                                        <td>{data.retailCategory ? data.retailCategory : '--'}</td>
                                        <td>{data.customerName}</td>
                                        <td>{data.vehicleModel}</td>
                                        <td>{data.prevdealerETA ? data.prevdealerETA : '--'}</td>
                                        <td>{data.currentdealerETA ? data.currentdealerETA : '--'}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="table-wraper">
                    <div className="table-head">Today's Status Changes</div>
                    <table className="table-loop table-sub-fix">
                        <thead>
                            <tr>
                                <th width="10%">BATCH NUMBER</th>
                                <th width="10%">DEAL NUMBER</th>
                                <th width="10%">VESSEL</th>
                                <th width="10%">DEPARTMENT</th>
                                <th width="20%">CUSTOMER</th>
                                <th width="20%">VEHICLE MODEL</th>
                                <th width="10%">YESTERDAY'S DEALER ETA</th>
                                <th width="10%">TODAY'S DEALER ETA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reportData && reportData.filter(a => a.prevlocationStatus || a.currentlocationStatus).map((data, index) => {
                                    return <tr key={index}>
                                        <td>{data.batchNumber}</td>
                                        <td>{data.dealNumber ? data.dealNumber : '--'}</td>
                                        <td>{data.shipName ? data.shipName : '--'}</td>
                                        <td>{data.retailCategory ? data.retailCategory : '--'}</td>
                                        <td>{data.customerName}</td>
                                        <td>{data.vehicleModel}</td>
                                        <td>{data.prevlocationStatus ? data.prevlocationStatus : '--'}</td>
                                        <td>{data.currentlocationStatus ? data.currentlocationStatus : '--'}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <div className="footer-bottom">

                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                            </span>
                            <span style={{ paddingLeft: 0 }}>
                                {client.phone ? <>Tel : <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                {client.website ? <>Web : <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                            </span>
                        </p>
                    </div>
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                        <div className="footer-right"></div>

                    </div>
                    <div className="clear"></div>
                </div>
            </section>
        </div>
    )

}

export default StatusUpdate;