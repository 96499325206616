import React, { useState, useEffect, useMemo } from 'react';
import AddBulkSMS from './add';
import { PopUpModal } from '../../components';
import _ from 'lodash';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData } from 'rxfire/firestore';
import { TemplateContext } from '../settings/workflow/templateContext';
import { Tab, Nav } from 'react-bootstrap';
import BulkSMSList from './list';
import axios from 'axios';
import moment from 'moment';
import toast from 'toasted-notes'
import CommonHelper from '../../services/common';

const BulkSMS = ({ dealersettings, clientUsers, updateDealerSettings, history }) => {
	const [showAddCampaign, setShowAddCampaign] = useState({
		show: false,
		title: '',
		campaignID: ''
	});
	const [bulksmsLoader, setBulksmsLoader] = useState(true)
	const [loadAgain, setLoadAgain] = useState(true)

	let API_KEY = '';
	let API_SECRET = ''
	let SERVER_PREFIX = ''
	const apiURL = process.env.NODE_ENV === 'development'
		? 'http://localhost:5001/ams-pro-qa/us-central1/'
		: dealersettings.apiURL
	if (dealersettings && dealersettings.client && !_.isEmpty(dealersettings.client.integrations)) {
		const messagemediaIntegration = dealersettings.client.integrations.filter(b => b.active && b.enabled && b.type === 'messagemedia')[0]
		if (messagemediaIntegration && !_.isEmpty(messagemediaIntegration.settings) && messagemediaIntegration.settings.api_key) {
			API_KEY = messagemediaIntegration.settings.api_key
			API_SECRET = messagemediaIntegration.settings.api_secret
			SERVER_PREFIX = messagemediaIntegration.settings.api_key.split('-')[1]
		} else {
			history.push('/settings/integrations')
			toast.notify('Please integrate messagemedia.', {
				duration: 2000
			})
		}
	} else {
		history.push('/settings/integrations')
		toast.notify('Please integrate messagemedia.', {
			duration: 2000
		})
	}

	const [activeTab, setActiveTab] = useState('all');
	const [campaigns, setCampaigns] = useState([]);
	const [SMStemplates, setSMSTemplates] = useState([]);
	const [loader, setLoader] = useState(false);

	const SMSTemplatesMemo = useMemo(() => SMStemplates, [SMStemplates]);

	const handleModelClose = () => {
		setShowAddCampaign({
			...showAddCampaign,
			show: false,
			title: '',
			campaignID: ''
		});
	};

	const onCreateCampaign = () => {
		setShowAddCampaign({
			...showAddCampaign,
			show: true,
			title: 'Create SMS Campaign'
		})
	}



	useEffect(() => {
		if (_.isEmpty(dealersettings)) {
			return;
		}
		if (!_.isEmpty(SMStemplates)) {
			return;
		}

		let privateTemplatesRef = window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/templates`).
			where('visibility', '==', 'private')
			.where('isDeleted', '==', false)
		privateTemplatesRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(privateTemplatesRef, dealersettings, null, true);

		var alltemplates = combineLatest(
			collectionData(privateTemplatesRef),
			collectionData(window.firebase.firestore().collection(`clientSettings/${dealersettings.client.settingsID}/templates`)
				.where('visibility', '==', 'shared')
				.where('isDeleted', '==', false))
		).pipe(
			map((templates) => {
				var [dealer, shared] = templates;
				return [
					[...dealer],
					[...shared]
				];
			}))
			.subscribe(allDocs => {
				let list = [];
				allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
				let listVM = [];
				list.forEach(rec => {
					if (!listVM.some(e => e.documentID === rec.documentID))
						listVM.push(rec)
				})
				setSMSTemplates(listVM.filter(item => item.type === 'smsTemplate'))
			});
		return () => {
			alltemplates && alltemplates.unsubscribe()
		}
	}, []);

	useEffect(() => {
		const smsCampaignsSnapshot = window.firebase.firestore().collection('smsCampaigns')
			.where('clientID', '==', dealersettings.client.id)
			.onSnapshot(querySnapshot => {
				let _smsCampaigns = [];
				querySnapshot.forEach((rec) => {
					_smsCampaigns.push({ ...rec.data(), 'documentID': rec.id })
				})
				_smsCampaigns = _smsCampaigns.map(a => convertCampaignVM(a))
				_smsCampaigns = _smsCampaigns.filter(a => !a.type)
				setCampaigns(_smsCampaigns)
				setBulksmsLoader(false)
			})
		return () => {
			smsCampaignsSnapshot && smsCampaignsSnapshot();
		}
	}, [])

	const convertCampaignVM = doc => {
		let objToReturn = { ...doc };

		// if (!doc.recipientIDs) {
		// 	return objToReturn
		// }

		objToReturn.content = doc.content.length > 80 ? doc.content.substring(0, 80) + '...' : doc.content;
		objToReturn._title = <div className="bulksms-subject">{doc.title}</div>
		objToReturn._updatedOn = (
			<div className="user-list-items-info">
				<h4>{moment(doc.modifiedDate.toDate()).format('DD/MM/YYYY hh:mm A')}</h4>
				<h5>{CommonHelper.getUserNamebyId(clientUsers, doc.addedBy)}</h5>
			</div>
		)
		objToReturn._status = (
			<>
				<div className={`badge badge-pill badge-${doc.status === 'draft' ? 'draft' : doc.status === 'sending' ? 'pending' : doc.status === 'scheduled' ? 'allocated' : 'available'}-fill `}>
					{doc.status === 'draft' ? 'DRAFT' : doc.status === 'sending' ? 'SENDING' : doc.status === 'scheduled' ? 'SCHEDULED' : 'SENT'}
				</div>
				{doc.status === 'scheduled' && doc.scheduleDate && (
					<div className="schedule-sms-info">{moment(doc.scheduleDate).format('DD/MM/YYYY hh:mm A')}</div>
				)}
			</>
		)
		objToReturn._stats = (
			<div className="bulksms-stat-box-wraper">
				<div className={`bulksms-stat-box`}>
					<p className="bulksms-stat-count w-100">{doc.recipientsCount > 0 ? doc.recipientsCount : 0}</p>
					<p className="bulksms-stat-title w-100">Total</p>
				</div>
				<div className={`bulksms-stat-box ${doc.status !== 'draft' ? '' : 'no-stat'}`}>
					<p className="bulksms-stat-count w-100">{doc.status !== 'draft' ? doc.deliveredIDs ? doc.deliveredIDs.length : doc.deliveredCount : '0'}</p>
					<p className="bulksms-stat-title w-100">Delivered</p>
				</div>
				<div className={`bulksms-stat-box ${doc.status !== 'draft' ? '' : 'no-stat'}`}>
					<p className="bulksms-stat-count w-100">{doc.status !== 'draft' ? doc.failedIDs ? doc.failedIDs.length : doc.failedCount : '0'}</p>
					<p className="bulksms-stat-title w-100">Failed</p>
				</div>
				{
					doc.repliesCount > 0 ? (
						<div className={`bulksms-stat-box ${doc.status !== 'draft' ? '' : 'no-stat'}`}>
							<p className="bulksms-stat-count w-100">{doc.status !== 'draft' ? (doc.repliesCount || 0) : '0'}</p>
							<p className="bulksms-stat-title w-100">Replies</p>
						</div>
					) : (<></>)
				}
			</div>
		)
		var data = doc.templateJSON ? JSON.parse(doc.templateJSON) : '';
		var text = '';
		if (!_.isEmpty(data) && !_.isEmpty(data.blocks)) {
			data.blocks.forEach(ele => {
				text += ele.text + ' ';
			});
		}
		objToReturn._message = text.length > 100 ? text.substring(0, 100) + '...' : text;
		return objToReturn;
	};

	return (
		<TemplateContext.Provider
			value={{
				smsTemplateList: SMSTemplatesMemo,
				loading: loader,
			}}
		>
			<div className='middle-wrapper'>
				<div className='filter-panel'>
					<div className='float-left campaign-head'>
						<h3>SMS Campaigns</h3>
					</div>
					<div className='float-right'>
						<div className='filter-add-button'>
							<button
								type='button'
								className='btn btn-primary'
								onClick={onCreateCampaign}
							>
								{' '}
								<i className='ico icon-add'></i> Create SMS Campaign
							</button>
						</div>
					</div>
				</div>
				<div className='bulksms-panel'>
					<div className='bulksms-main-container'>
						<div className='bulksms-wrapper'>
							<Tab.Container
								defaultActiveKey={activeTab}
								mountOnEnter={true}
								onSelect={k => setActiveTab(k)}
							>
								<div className='bulksms-left-panel'>
									<div className='bulksms-left-menu'>
										<h4>Campaigns</h4>
										<div className='bulksms-submenu'>
											<Nav as='ul' bsPrefix='ul-nav'>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='all' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-all'> </i>{' '}
														<span>All</span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='sent' bsPrefix='li-a-nav'>
														<i className='ico icon-sent-icon'> </i>{' '}
														<span>Sent</span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='sending' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-ongoing'> </i>{' '}
														<span>In Progress</span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='scheduled' bsPrefix='li-a-nav'>
														<i className='ico icon-schedule-icon'> </i>{' '}
														<span>Scheduled</span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='draft' bsPrefix='li-a-nav'>
														<i className='ico icon-campaign-draft'> </i>{' '}
														<span>Draft</span>{' '}
													</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' bsPrefix='li-nav'>
													<Nav.Link eventKey='templates' bsPrefix='li-a-nav'>
														<i className='ico icon-sms-template-icon'> </i>{' '}
														<span>Templates </span>{' '}
													</Nav.Link>
												</Nav.Item>
											</Nav>
										</div>
									</div>
								</div>
								<BulkSMSList
									activeTab={activeTab}
									campaigns={campaigns}
									setCampaigns={setCampaigns}
									setShowAddCampaign={setShowAddCampaign}
									showAddCampaign={showAddCampaign}
									apiUrl={apiURL}
									API_KEY={API_KEY}
									API_SECRET={API_SECRET}
									SERVER_PREFIX={SERVER_PREFIX}
									onCreateCampaign={onCreateCampaign}
									loader={bulksmsLoader}
									dealersettings={dealersettings}
									clientUsers={clientUsers}
								/>
							</Tab.Container>
						</div>
					</div>
				</div>
				<PopUpModal show={showAddCampaign.show}>
					<AddBulkSMS
						show={showAddCampaign.show}
						handleClose={handleModelClose}
						title={showAddCampaign.title}
						campaignID={showAddCampaign.campaignID}
						dealersettings={dealersettings}
						clientUsers={clientUsers}
						updateDealerSettings={updateDealerSettings}
						history={history}
						apiUrl={apiURL}
						API_KEY={API_KEY}
						API_SECRET={API_SECRET}
						SERVER_PREFIX={SERVER_PREFIX}
						loadAgain={loadAgain}
						setLoadAgain={setLoadAgain}
						isScheduleAllowed={true}
					/>
				</PopUpModal>
			</div>
		</TemplateContext.Provider>
	);
};

export default BulkSMS;
