/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
/** COMPONENTS */
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';
/** VIEW-MODELS */
import { objStock } from '../stock/viewModel'


const InboundTestDriveInfo = ({ inbound, dealersettings }) => {

    const { testdrive, contact, addedDate, documentID, notes } = inbound
    

    return (

        <div className="container-testdrive-new-info" id={'testD_inbound_' + documentID}>

            <div className="testdrive-new-info-item ">

                <div className="testdrive-new-info-tab">
                    <div className="vehicle-item-image">
                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                            <img src={CommonHelper.showBrandLogo(dealersettings, testdrive?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                        </a>
                    </div>
                    <div className="testdrive-new-info-flex">
                        <div className="vehicle-data">
                            <div className="testdrive-new-info-title">{testdrive?.make || ''} {testdrive?.model || ''}</div>



                        </div>
                        <div className="testdrive-new-info-duration">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td width="20%">Start Time</td>
                                        <td width="20%">End Time</td>
                                        <td width="60%">Received</td>
                                    </tr>
                                    <tr>
                                        <td className="label-duration">{(!_.isEmpty(testdrive?.startDate)) ? moment.unix(testdrive.startDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                                        <td className="label-duration">{(!_.isEmpty(testdrive?.endDate)) ? moment.unix(testdrive.endDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                                        <td className="label-duration">{(!_.isEmpty(addedDate)) ? moment.unix(addedDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                {
                    notes && (<div className="testdrive-new-info-notes">
                        <div className="testdrive-new-info-inner">{notes}</div>
                    </div>)
                }


            </div>

        </div>




    )
};

export default InboundTestDriveInfo;
