export const sourceFields = [
    {
        name: 'Name',
        value: 'name',
        sortValue: 'name',
        flex: 1,
        default: true,
        subText: 'levelValue',
        subTextTitle: 'Level'
    },
    {
        name: 'Region/Subregion/Group/Client',
        value: 'sublevelsDOM',
        sortValue: 'strlevels',
        flex: 1,
        default: true
    },
    {
        name: 'EOI URL',
        value: 'eoiLink',
        flex: 2,
        default: true
    },
    {
        name: 'Registration URL',
        value: 'registrationLink',
        flex: 2,
        default: true
    },
    {
        name: 'Active',
        value: 'active',
        sortValue: 'active',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const levelOptions = [
    //{ value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'subregion', label: 'Subregion', index: 3 },
    { value: 'group', label: 'Group', index: 4 },
    { value: 'individual', label: 'Individual', index: 5 }
]


export const sourceVM = {
    documentID: null,
    name: null,
    level: null,
    levelIDs: null,
    eventsProIDs: null,
    active: null,
    addedBy: null,
    addedDate: null,
    addedDate: null,
    eoiURL: null,
    isDeleted: null,
    modifiedBy: null,
    modifiedDate: null,
    oemID: null,
    registrationURL: null,
}