import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
	dealerslistFields,
	clientsVM,
} from '../../settings/company/clients/viewModel';
import { PopUpModal, TableView, ReactSelect } from '../../../components';
import CommonHelper from '../../../services/common';
import AddClient from './addClientModal';
import DealerFiles from './dealerFiles'
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import { MainContext } from '../../layout/provider';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import { RouteContext } from '../../layout/RouteProvider';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { doc } from 'rxfire/firestore';

const Dealers = ({ dealersettings, clientUsers, groupUsers }) => {
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 30,
		windowHeight: window.innerHeight - 123,
	});
	const [oemClients, setOEMClients] = useState([]);
	const [clientLst, setClientList] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [subregionList, setSubregionList] = useState([]);
	const [groupLst, setGroupList] = useState([]);
	const [loader, setLoader] = useState(true);
	const [searchText, setSearchText] = useState('');
	const [isPaging, setPaging] = useState(false);
	const [hasMore, setHasMoreData] = useState([]);
	const [showclientModel, setShowClientModel] = useState(false);
	const [showFilesModel, setShowFilesModel] = useState(false);
	const [popHeader, setPopHeader] = useState('');
	const [selectedClient, setSelectedclient] = useState(null);
	const [checkDataLoad, setDataload] = useState(true);
	const [brands, setBrands] = useState([]);
	const { updateDealerSettings, handleClientChange } = useContext(MainContext)
	const { resetnavbar } = useContext(RouteContext);
	const [filters, setFilters] = useState()
	const dealerFileUpload = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.permissions) &&
		dealersettings.rolePermissions.permissions.dealerFileUpload) ? true : false);

	const addDealer = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.permissions) &&
		dealersettings.rolePermissions.permissions.addDealer) ? true : false);
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 30,
				windowHeight: window.innerHeight - 123,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			//window.unSubscribeCompanies && window.unSubscribeCompanies();
		};
	}, []);

	useEffect(() => {
		// let oemID = dealersettings.oemID ? dealersettings.oemID : !_.isEmpty(dealersettings.client) && dealersettings.client.settingsID ? dealersettings.client.settingsID : '' 

		let clientPromise = []
		clientPromise.push(doc(window.firebase.firestore().collection(`clients`).where('settingsID', '==', dealersettings.oemID)));
		clientPromise.push(doc(window.firebase.firestore().collection(`clients`).where('settingsIDs', 'array-contains', dealersettings.oemID)));
		// dealersettings.clients.forEach(client => {
		// 	clientPromise.push(doc(window.firebase.firestore().doc(`clients/${client.documentID}`)));
		// });
		if (clientPromise.length === 0) {
			setClientList([]);
			setOEMClients([]);
			setLoader(false);
		}
		var oemclientSnapshot = combineLatest(
			clientPromise
		).pipe(
			map(([dealers, linkDealers]) => {
				return [
					_.map(dealers.docs, function (obj) { return obj.exists ? { ...obj.data(), id: obj.id } : {} }),
					_.map(linkDealers.docs, function (obj) { return obj.exists ? { ...obj.data(), id: obj.id } : {} })
				]
			}))
			.subscribe((allDocs) => {
				let allconverted = []
				allDocs.forEach(dealer => {
					dealer.forEach(rec => {
						if (rec && !allconverted.some(a => a.id === rec.id) && dealersettings.allClients.some(a => a.documentID === rec.id))
							allconverted.push(convertVM(rec));
					})
				});
				setClientList(allconverted);
				setOEMClients(allconverted);
				setRegionList(dealersettings.regions && dealersettings.regions
					.filter(c => _.indexOf(allconverted.map(v => _.pick(v, ['region']).region), c.documentID) >= 0)
					.map(r => {
						return {
							label: r.name,
							value: r.documentID
						}
					}))
				setSubregionList(dealersettings.subregions ? dealersettings.subregions
					.filter(c => _.indexOf(allconverted.map(v => _.pick(v, ['subregion']).subregion), c.documentID) >= 0)
					.map(r => {
						return {
							label: r.name,
							value: r.documentID,
							region: _.find(allconverted, { subregion: r.documentID })?.region
						}
					}) : [])
				setGroupList(dealersettings.groups && dealersettings.groups
					.filter(c => _.indexOf(allconverted.map(v => _.pick(v, ['group']).group), c.documentID) >= 0)
					.map(r => {
						return {
							label: r.name,
							value: r.documentID
						}
					}))
				setLoader(false);
			});
		return () => {
			oemclientSnapshot && oemclientSnapshot.unsubscribe();
		};
	}, []);

	// useEffect(() => {
	// 	if (!_.isEmpty(oemClients)) {
	// 		console.log('setClientLst useEffect')
	// 		let _client = [];
	// 		oemClients
	// 			.filter(({ name, address, phone, email }) =>
	// 				!_.isEmpty(searchText)
	// 					? (!_.isEmpty(name)
	// 						? name.toLowerCase().includes(searchText.toLowerCase())
	// 						: false) ||
	// 					(!_.isEmpty(address)
	// 						? address.toLowerCase().includes(searchText.toLowerCase())
	// 						: false) ||
	// 					(!_.isEmpty(phone)
	// 						? phone.toLowerCase().includes(searchText.toLowerCase())
	// 						: false) ||
	// 					(!_.isEmpty(email)
	// 						? email.toLowerCase().includes(searchText.toLowerCase())
	// 						: false)
	// 					: true,
	// 			)
	// 			.forEach(rec => {
	// 				_client.push(convertVM(rec));
	// 			});
	// 		setClientList(_client);
	// 		setLoader(false);
	// 	}
	// }, [oemClients, searchText]);

	useEffect(() => {
		const brandCollection = window.firebase
			.firestore()
			.collection(`brands`)
			.where('category', '==', 'Automotive')
			.where('isDeleted', '==', false)
			//.orderBy('addedDate', 'desc')
			.onSnapshot(docSnapshot => {
				let _brand = [];
				docSnapshot.docs.forEach(doc => {
					const branddoc = {
						...doc.data(),
						documentID: doc.id,
					};
					_brand.push({
						label: branddoc.name,
						value: doc.id,
						data: branddoc,
						active: _.isBoolean(doc.active) ? doc.active : true,
					});
				});
				setBrands(_.orderBy(_brand, ["label"], ["asc"]));
			});
		return () => {
			brandCollection && brandCollection();
		};
	}, []);

	useEffect(() => {
		document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		return () => {
			document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
		};
	}, []);

	const convertVM = doc => {
		const objclientData = Object.assign({}, doc);
		const listVM = Object.assign({}, clientsVM);
		for (let [key, value] of Object.entries(objclientData)) {
			if (listVM.hasOwnProperty(key)) listVM[key] = value;
		}
		if (doc.settingsID !== dealersettings.oemID)
			listVM.settingsName = doc.settingsName
		listVM.clientName = listVM.name
		listVM.createdOn = moment.unix(listVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
		listVM.createdOnSec = listVM.addedDate.seconds;
		//listVM.name = (<div className="user-list-items-info pr-0 "><h4 title={listVM.name}>{listVM.name}</h4></div>)
		listVM.countryName = _.find(dealersettings.countries, {
			code: listVM.country,
		})
			? _.find(dealersettings.countries, { code: listVM.country }).name
			: '';
		listVM.regionName = _.find(dealersettings.regions, {
			code: listVM.region,
		})
			? _.find(dealersettings.regions, { code: listVM.region }).name
			: '';
		listVM.stateName = objclientData.state ? objclientData.state : ''
		let country = _.find(dealersettings.countries, {
			code: listVM.country,
		})
			? _.find(dealersettings.countries, { code: listVM.country })
			: '';

		if (country) {
			dealersettings.states.forEach(state => {
				if (objclientData.state === state.code && state.country_code === country.code) {
					listVM.stateName = state.name
				}
				if (objclientData.state === state.name && state.country_code === country.code) {
					listVM.stateName = state.name
				}
			})
		}
		listVM.sortOrder = doc.sortOrder;
		return listVM;
	};

	const handleClientdetailsClick = (e, id) => {
		e.preventDefault();
		handleRedirectToDashboard(id);
	};

	const handlePagination = () => {
		setPaging(true);
		setDataload(true);
	};

	const hadleEditClient = id => {
		const editClient = oemClients.find(item => item.documentID === id);
		const clientVM = Object.assign({}, clientsVM);
		for (let [key, value] of Object.entries(editClient)) {
			if (clientVM[key] !== undefined) clientVM[key] = value;
		}
		setSelectedclient(clientVM);
		setPopHeader('Edit Client');
		setShowClientModel(true);
	};

	const handleRedirectToDashboard = (clientID) => {
		const currentClient = dealersettings.client;
		const clientToNavigate = dealersettings.clients.filter(
			client => client.id === clientID,
		);

		// console.log('currentClient,', currentClient);
		// console.log('clientToNavigate,', clientToNavigate);

		if (clientToNavigate.length === 0) {
			toast.notify('You do not have access to this dealer!', {
				duration: 2000,
			});
			return;
		}

		Swal.fire({
			title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
			text: `${CommonHelper.showLocale({ dealersettings }, 'Do you want to switch to')} ${clientToNavigate[0].name}?`,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
			cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
		}).then(result => {
			if (result.value) {
				// handleModuleChange(
				// 	'enquiry',
				// 	AllModules.find(item => item.module === 'enquiry').route,
				// );
				window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${clientID}`)
					.set({ defaultModule: 'enquiry' }, { merge: true });
				localStorage.setItem('oemswitchclient', clientID)
				localStorage.setItem('defaultModule', 'enquiry');
				resetnavbar();
				handleClientChange(clientID);
				// history.push('/dashboard')
			}
		});
		//}
	};

	const handleActionClick = id => {
		//console.log(id, dealerFileUpload)
		return (
			<div style={{
				flexDirection: 'rowReverse',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
				<OverlayTrigger
					placement='bottom'
					overlay={
						<Tooltip><span>Switch to this dealer</span></Tooltip>
					}
				>
					<div
						style={{ cursor: 'pointer', float: '' }}
						className='mini-button mr-2'
						onClick={e => {
							handleRedirectToDashboard(id);
						}}
					>
						<i className='ico icon-zoom'></i>
					</div>
				</OverlayTrigger>
				<OverlayTrigger
					placement='bottom'
					overlay={
						<Tooltip><span>{dealerFileUpload ? 'Attachments' : 'You do not have permission of files'}</span></Tooltip>
					}
				>
					<div
						style={{ cursor: 'pointer', float: 'right' }}
						className='mini-button mr-2'
						onClick={e => {
							if (dealerFileUpload) {
								handleFilesOpen(id);
							}
						}}
					>
						<i className={`ico icon-${dealerFileUpload ? 'attachment' : 'lock'}`}></i>
					</div>
				</OverlayTrigger>
				<OverlayTrigger
					placement='bottom'
					overlay={
						<Tooltip><span>Edit Dealer</span></Tooltip>
					}
				>
					<div
						style={{ cursor: 'pointer', float: 'right' }}
						className='mini-button mr-2'
						onClick={e => {
							hadleEditClient(id);
						}}
					>
						<i className='ico icon-edit'></i>
					</div>
				</OverlayTrigger>
			</div>
		);
	};

	const handleModelClose = (client, isNewClient) => {
		setSelectedclient(null);
		setShowClientModel(false);

		if (client && clientLst.some(e => e.documentID === client.documentID))
			setClientList(clientLst.map(rec => {
				return rec.documentID === client.documentID ? {
					...convertVM(client)
				} : { ...rec }
			}));
		else if (client && isNewClient) {
			setClientList([...clientLst, convertVM(client)]);
			updateDealerSettings('clients', { ...client, id: client.documentID })
		}

	};

	const handleFilesOpen = id => {
		console.log('handleFilesOpen', id)
		const editClient = oemClients.find(item => item.documentID === id);
		const clientVM = Object.assign({}, clientsVM);
		for (let [key, value] of Object.entries(editClient)) {
			if (clientVM[key] !== undefined) clientVM[key] = value;
		}
		setSelectedclient(clientVM);
		setShowFilesModel(true);
	};

	const handleFilesClose = () => {
		setSelectedclient(null);
		setShowFilesModel(false);
	};

	const handleAddClient = () => {
		setSelectedclient(null);
		setPopHeader('Add Dealer');
		setShowClientModel(true);
	};

	const filterBySearchText = clientObj => {
		let returnVal = false;

		if (!searchText.length) {
			return true;
		}

		if (searchText && clientObj.clientName && clientObj.clientName.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (searchText && clientObj.address && clientObj.address.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (searchText && clientObj.phone && clientObj.phone.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}

		if (searchText && clientObj.email && clientObj.email.toLowerCase().includes(searchText.toLowerCase())) {
			returnVal = true;
		}
		return returnVal;
	};

	return (
		<>
			<div className='middle-wrapper'>
				<div className='filter-panel'>
					<div className='float-left'>
						<div className='filter-search search-icon'>
							{/* <input
								placeholder='Search'
								aria-label='Search'
								value={searchText}
								onChange={e => {
									setSearchText(e.target.value);
								}}
							/> */}
							<div className='input-group template-search'>
								<input type='text' className='form-control' placeholder='Search' value={searchText} onChange={e => setSearchText(e.target.value)} style={{ width: '100%' }} />
								<div className='input-group-append input-search-clear'>
									{searchText.length > 0 && (
										<a href='#' className='input-search-clear-icon' style={{ top: '9px' }} onClick={() => setSearchText('')}>
											<i className='ico icon-remove'></i>
										</a>
									)}
								</div>
							</div>
						</div>
						<div className='float-left ml-2' style={{ width: '200px' }}>
							<ReactSelect
								options={regionList}
								name={"region"}
								placeholder={'select region'}
								onChange={(e, data) => {
									setFilters({
										...filters,
										region: e ? e.value : null,
										subregion: null
									})
								}}
								value={filters?.region}
								classNamePrefix={`cursor-pointer basic-select`}
								removeClearable={false}
								id="filter-region"
							>
							</ReactSelect>
						</div>
						<div className='float-left ml-2' style={{ width: '200px' }}>
							<ReactSelect
								options={subregionList.filter(a => filters?.region ? a.region === filters.region : true)}
								name={"subregion"}
								placeholder={'select subregion'}
								onChange={(e, data) => {
									setFilters({
										...filters,
										subregion: e ? e.value : null
									})
								}}
								value={filters?.subregion}
								classNamePrefix={`cursor-pointer basic-select`}
								removeClearable={false}
								id="filter-subregion"
							>
							</ReactSelect>
						</div>
						<div className='float-left ml-2' style={{ width: '200px' }}>
							<ReactSelect
								options={groupLst}
								name={"group"}
								placeholder={'select group'}
								onChange={(e, data) => {
									setFilters({
										...filters,
										group: e ? e.value : null
									})
								}}
								value={filters?.group}
								classNamePrefix={`cursor-pointer basic-select`}
								removeClearable={false}
								id="filter-group"
							>
							</ReactSelect>
						</div>

					</div>
					{dealersettings.level === 'oem' && addDealer && (
						<div className='float-right'>
							<div className='filter-add-button'>
								<button
									type='button'
									className='btn btn-primary'
									onClick={() => {
										handleAddClient();
									}}
								>
									{' '}
									<i className='ico icon-add'></i> Add Dealer
								</button>
							</div>
							{/* <div className='filter-view-more  ml-2 dropdown'>
								<a href='#' className='common-button dropdown-toggle'>
									<i className='ico icon-more'></i>
								</a>
							</div> */}
						</div>
					)}
				</div>
				<div
					className='contact-panel'
					style={{ width: windowSize.windowWidth + 'px' }}
				>
					{clientLst && clientLst.length > 0 ? (
						<TableView
							datalist={_.orderBy(clientLst ? clientLst.filter((clientObj) =>
								filterBySearchText(clientObj)
								&& (filters?.region ? clientObj.region === filters?.region : true)
								&& (filters?.subregion ? clientObj.subregion === filters?.subregion : true)
								&& (filters?.group ? clientObj.group === filters?.group : true)
							) : [], ['sortOrder'], ['asc'])}
							height={windowSize.windowHeight}
							width={windowSize.windowWidth}
							columns={dealerslistFields}
							dynamicFields={dealerslistFields
								.filter(e => e.default === true)
								.map(v => _.pick(v, ['value']).value)}
							handleActionClick={handleActionClick}
							handleRowSingleClick={handleClientdetailsClick}
							isSettings={false}
							hasMore={hasMore}
							handlePagination={handlePagination}
							isPaging={true}
							isReorderable={false}
							isSorting={true}

						/>
					) : loader ? (
						<div className="mt-5"><div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div></div>
					) : (
						<div className="common-table">
							<div className="text-center p-5">
								<p>No dealers found.</p>
								<button className="btn btn-sm btn-default" onClick={() => { handleAddClient(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
									Add Dealer
								</button>
							</div>
						</div>
					)}
				</div>
			</div >
			<PopUpModal show={showclientModel}>
				<AddClient
					show={showclientModel}
					title={popHeader}
					dealersettings={dealersettings}
					handleClose={handleModelClose}
					client={selectedClient}
					brands={brands}
					oemClients={oemClients}
				// title='Add a dealer'
				/>
			</PopUpModal>
			<PopUpModal show={showFilesModel}>
				<DealerFiles
					show={showFilesModel}
					clientID={selectedClient && selectedClient.documentID}
					dealersettings={dealersettings}
					clientUsers={clientUsers}
					groupUsers={groupUsers}
					handleClose={handleFilesClose}
					client={selectedClient}
					brands={brands}
					title='Add a dealer'
				/>
			</PopUpModal>
		</>
	);
};

export default Dealers;
