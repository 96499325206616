/** LIBRARIES */
import React, { PureComponent, Component } from "react";
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
import {
    AntMonthPicker,
    ReactSelect,
    InputAutoText,
    InputAutoNumber,
    AntDatePicker,
    InputCheckBox,
    DynamicInput,
    ReactTimePicker
} from '../../../components';
import _ from 'lodash'
import moment from 'moment';
import { enquiryStatus } from '../../../services/enum';
import CommonHelper from '../../../services/common';

class EnquiryInputs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    minDate = (current) => {
        let state = Object.assign({}, this.props.fields);
        var minDate = state.wonDate ? moment.unix(state.wonDate.seconds).startOf('day') :
            state.addedDate ? moment.unix(state.addedDate.seconds).startOf('day') :
                moment().startOf('day')
        return current && current < minDate;
    }
    render() {

        let { status, enquiryType, serviceType,
            label, purchaseIntention, contractNumber,
            depositAmount, depositReciptNumber, grossAmount, origin, campaign,
            lostReason, lostSubReason, deliveryDate, leadSource,
            chassisNo, regNo, stockNo, warrantyExpiry, warrantyStartDate,
            isFinanceRequired, financeStatus, dynamicFields
        } = this.props.fields;

        const { dealersettings, canModifyEnquiry, errors, currencySymbol, type, serviceTypes, enquiryOptionsStatic,
            enquiryTypes, leadSources, origins, campaigns, labels, enquiryOptionsDF, financeEnabled, drivaEnabled,
            lostReasons, lostSubReasons, __financestatus, financeStatuses, enquiryAllStatus, handleTimeChange,
            handleSelectSave, handlePIDateSave, isPIValid, handleDeliveryDateChange, handleOnCapChange,
            saveEnquiryField, cancelEnquiryField, handleDateChange, handleLostReasonSave,
            handleCheckChange, onCurrencyChange, handleOnChange,
            handleDynamicCheckChange, handleDynamicOnChange, saveDynamicField, cancelDynamicField, onDynamicNumberChange,
            handleDynamicDateChange, handleDynamicSelectSave, handleDynamicMultiSelectChange,
        } = this.props;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const modifyPurhaseInt = ((!_.isEmpty(_permissions) && _permissions.modifyPurhaseInt) ? true : false);

        const modifyCampaign = ((!_.isEmpty(_permissions) && _permissions.modifyCampaign) ? true : false);

        const modifyEnquiryOrigin = ((!_.isEmpty(_permissions) && _permissions.modifyEnquiryOrigin) ? true : false);

        const modifyEnquiryType = ((!_.isEmpty(_permissions) && _permissions.modifyEnquiryType) ? true : false);

        const modifyLabel = ((!_.isEmpty(_permissions) && _permissions.modifyLabel) ? true : false);

        const modifyLostReason = ((!_.isEmpty(_permissions) && _permissions.modifyLostReason) ? true : false);

        const modifyLeadSource = ((!_.isEmpty(_permissions) && _permissions.modifyLeadSource) ? true : false);

        const isDynStatusOpen = CommonHelper.isEnqStatusOpen(enquiryAllStatus, status)
        const isStatusOpen = (_.isEmpty(status) || isDynStatusOpen || status === enquiryStatus.OPEN || status === enquiryStatus.PENDING) ? true : false;
        const isStatusLost = (status === enquiryStatus.LOST || status === enquiryStatus.PENDINGLOST || status === enquiryStatus.PROSPECTLOST) ? true : false;
        const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, status);

        return (
            <>

                {(() => {
                    if (type === 'enquiryType') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'enquiryType')} /></label>
                                    <ReactSelect
                                        options={enquiryTypes}
                                        name={"enquiryType"}
                                        placeholder={'select enquiry type'}
                                        onChange={handleSelectSave}
                                        value={enquiryType}
                                        classNamePrefix={`cursor-pointer ${errors["enquiryType"]} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={canModifyEnquiry && (modifyEnquiryType || !enquiryType || isStatusOpen) ? false : true}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'serviceType') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'serviceType')} /></label>
                                    <ReactSelect
                                        options={serviceTypes}
                                        name={"serviceType"}
                                        placeholder={'select service type'}
                                        onChange={handleSelectSave}
                                        value={serviceType}
                                        classNamePrefix={`cursor-pointer ${errors["serviceType"]} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={canModifyEnquiry && (modifyEnquiryType || !serviceType || isStatusOpen) ? false : true}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'leadSource') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'leadSource')} /></label>
                                    <ReactSelect
                                        options={leadSources}
                                        name={"leadSource"}
                                        placeholder={'select lead source'}
                                        onChange={handleSelectSave}
                                        value={leadSource}
                                        classNamePrefix={`cursor-pointer ${errors["leadSource"]} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={canModifyEnquiry && (modifyLeadSource || !leadSource || isStatusOpen) ? false : true}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'origin') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'origin')} /></label>
                                    <ReactSelect
                                        options={origins}
                                        name={"origin"}
                                        placeholder={'select origin'}
                                        onChange={handleSelectSave}
                                        value={origin}
                                        classNamePrefix={`cursor-pointer ${errors["origin"]} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={canModifyEnquiry && (modifyEnquiryOrigin || !origin || isStatusOpen) ? false : true}
                                    >
                                    </ReactSelect>
                                </div>

                            </>
                        )
                    }
                    else if (type === 'campaign') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'campaign')} /></label>
                                    <ReactSelect
                                        options={campaigns}
                                        name={"campaign"}
                                        placeholder={'select campaign'}
                                        onChange={handleSelectSave}
                                        value={campaign}
                                        classNamePrefix={`cursor-pointer ${errors["campaign"]} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={canModifyEnquiry && (modifyCampaign || !campaign || isStatusOpen) ? false : true}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'label') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'label')} /></label>
                                    <ReactSelect
                                        options={labels}
                                        name={"label"}
                                        placeholder={'select label'}
                                        onChange={handleSelectSave}
                                        value={label}
                                        classNamePrefix={`cursor-pointer ${errors["label"]} basic-select`}
                                        //removeClearable={true}
                                        isSearchable={false}
                                        isDisabled={canModifyEnquiry && (modifyLabel || !label || isStatusOpen) ? false : true}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'purchaseIntention') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'purchaseIntention')} /></label>
                                    <AntMonthPicker
                                        value={purchaseIntention ? moment('01 ' + purchaseIntention)._d : null}
                                        name={'purchaseIntention'}
                                        onChange={e => {
                                            if (canModifyEnquiry && (modifyPurhaseInt || !purchaseIntention || isStatusOpen))
                                                handlePIDateSave(e, 'purchaseIntention');
                                        }}
                                        placeholder='set purchase intention'
                                        className={`form-control cursor-pointer ${purchaseIntention ? isPIValid(purchaseIntention) : ''} ${errors["purchaseIntention"]}`}
                                        disabled={canModifyEnquiry && (modifyPurhaseInt || !purchaseIntention || isStatusOpen) ? false : true}
                                        disabledDate={(current) => { return current && current < moment().startOf('month'); }}
                                        format={'MMM YYYY'}
                                        picker={'month'}
                                    />
                                </div>
                            </>
                        )
                    }
                    else if (type === 'deliveryDate' && status !== enquiryStatus.LOST) {
                        return (
                            <>

                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'deliveryDate')} /></label>
                                    <div className="splite-input-half">
                                        <AntDatePicker
                                            value={deliveryDate ? moment.unix(deliveryDate.seconds).format('YYYY-MM-DD') : null}
                                            name={'deliveryDate'}
                                            onChange={(e) => { handleDeliveryDateChange(e, 'deliveryDate') }}
                                            format='DD/MM/YYYY'
                                            placeholder='DD/MM/YYYY'
                                            className={`form-control mr-1 ${errors["deliveryDate"]}`}
                                            disabledDate={this.minDate}
                                        />
                                        <div className='inner-addon right-addon ml-1'>
                                            <i className='bg-icons ico icon-time'></i>
                                            <ReactTimePicker
                                                value={deliveryDate ? moment.unix(deliveryDate.seconds)._d : null}
                                                name={'deliveryDate'}
                                                onChange={handleTimeChange}
                                                timeIntervals={15}
                                                placeholder='h:mm aa'
                                                className={`form-control ${errors["deliveryDate"]}`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </>
                        )
                    }
                    else if (type === 'chassisNo' && (isStatusSold || status === enquiryStatus.WON || status === enquiryStatus.CLOSED || status === enquiryStatus.DELIEVERD)) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'chassisNo')} /></label>
                                    <InputAutoText
                                        type='text'
                                        autoComplete='off'
                                        placeholder={'vehicle chassis/vin number'}
                                        className={`form-control cursor-pointer ${errors['chassisNo']}`}
                                        name='chassisNo'
                                        onChange={handleOnCapChange}
                                        value={chassisNo ? chassisNo : ''}
                                        onSaveField={saveEnquiryField}
                                        onCancelField={cancelEnquiryField}
                                    />
                                </div>
                            </>
                        )
                    }
                    else if (type === 'regNo' && (isStatusSold || status === enquiryStatus.WON || status === enquiryStatus.CLOSED || status === enquiryStatus.DELIEVERD)) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'regNo')} /></label>
                                    <InputAutoText
                                        type='text'
                                        autoComplete='off'
                                        placeholder={'vehicle registration number'}
                                        className={`form-control cursor-pointer ${errors['regNo']}`}
                                        name='regNo'
                                        onChange={handleOnCapChange}
                                        value={regNo ? regNo : ''}
                                        onSaveField={saveEnquiryField}
                                        onCancelField={cancelEnquiryField}
                                    />
                                </div>
                            </>
                        )
                    }
                    else if (type === 'stockNo' && (isStatusSold || status === enquiryStatus.WON || status === enquiryStatus.CLOSED || status === enquiryStatus.DELIEVERD)) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'stockNo')} /></label>
                                    <InputAutoText
                                        type='text'
                                        autoComplete='off'
                                        placeholder={'vehicle stock number'}
                                        className={`form-control cursor-pointer ${errors['stockNo']}`}
                                        name='stockNo'
                                        onChange={handleOnCapChange}
                                        value={stockNo ? stockNo : ''}
                                        onSaveField={saveEnquiryField}
                                        onCancelField={cancelEnquiryField}
                                    />
                                </div>
                            </>
                        )
                    }
                    else if (type === 'warrantyStartDate' && (isStatusSold || status === enquiryStatus.WON || status === enquiryStatus.CLOSED || status === enquiryStatus.DELIEVERD)) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'warrantyStartDate')} /></label>
                                    <AntDatePicker
                                        value={warrantyStartDate}
                                        name={'warrantyStartDate'}
                                        onChange={(e) => { handleDateChange(e, 'warrantyStartDate') }}
                                        format='DD/MM/YYYY'
                                        placeholder='DD/MM/YYYY'
                                        className={`form-control ${errors["warrantyStartDate"]}`}
                                    />
                                </div>
                            </>
                        )
                    }
                    else if (type === 'warrantyExpiry' && (isStatusSold || status === enquiryStatus.WON || status === enquiryStatus.CLOSED || status === enquiryStatus.DELIEVERD)) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'warrantyExpiry')} /></label>
                                    <AntDatePicker
                                        value={warrantyExpiry}
                                        name={'warrantyExpiry'}
                                        onChange={(e) => { handleDateChange(e, 'warrantyExpiry') }}
                                        format='DD/MM/YYYY'
                                        placeholder='DD/MM/YYYY'
                                        className={`form-control ${errors["warrantyExpiry"]}`}
                                    />
                                </div>
                            </>
                        )
                    }
                    else if (type === 'lostReason' && isStatusLost) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'lostReason')} /></label>
                                    <ReactSelect
                                        options={lostReasons}
                                        name={'lostReason'}
                                        placeholder={'select lost reason'}
                                        onChange={handleLostReasonSave}
                                        value={lostReason}
                                        classNamePrefix={`cursor-pointer ${errors['lostReason']} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={(canModifyEnquiry && (modifyLostReason || !lostReason || isStatusOpen)) ? false : true}
                                    ></ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'lostSubReason' && isStatusLost && !_.isEmpty(lostSubReasons)) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'lostSubReason')} /></label>
                                    <ReactSelect
                                        options={lostSubReasons}
                                        name={'lostSubReason'}
                                        placeholder={'select lost sub reason'}
                                        onChange={handleSelectSave}
                                        value={lostSubReason}
                                        classNamePrefix={`cursor-pointer ${errors['lostSubReason']} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={(canModifyEnquiry && (modifyLostReason || !lostSubReason || isStatusOpen)) ? false : true}
                                    ></ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'financeStatus' && (financeEnabled || drivaEnabled) && financeStatus) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeStatus')} /></label>
                                    <ReactSelect
                                        options={__financestatus}
                                        name={'financeStatus'}
                                        placeholder={'select finance status'}
                                        onChange={handleSelectSave}
                                        value={financeStatus}
                                        classNamePrefix={`cursor-pointer ${errors['financeStatus']} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={true}
                                    ></ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'isFinanceRequired' && !financeEnabled && !drivaEnabled) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'finance')} /></label>
                                    <div className="form-control">
                                        <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'isFinanceRequired')} /></label>
                                        <span className="switch switch-sm float-right">
                                            <InputCheckBox
                                                className="switch"
                                                id={'isFinanceRequired'}
                                                name={'isFinanceRequired'}
                                                checked={isFinanceRequired ? true : false}
                                                onChange={(e) => { handleCheckChange(e) }} />
                                            <label htmlFor={'isFinanceRequired'}></label>
                                        </span>
                                    </div>

                                </div>
                            </>
                        )
                    }
                    else if (type === 'financeStatus' && !financeEnabled && !drivaEnabled && isFinanceRequired) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'financeStatus')} /></label>
                                    <ReactSelect
                                        options={financeStatuses}
                                        name={'financeStatus'}
                                        placeholder={'select finance status'}
                                        onChange={handleSelectSave}
                                        value={financeStatus}
                                        classNamePrefix={`cursor-pointer ${errors['financeStatus']} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={(isFinanceRequired) ? false : true}
                                    ></ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'depositAmount') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'depositAmount')} /></label>
                                    <InputAutoNumber
                                        id="depositAmount"
                                        name="depositAmount"
                                        allowNegative={true}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                        placeholder={'deposit amount'}
                                        onValueChange={onCurrencyChange.bind(this, 'depositAmount')}
                                        value={depositAmount ? depositAmount : null}
                                        onSaveField={saveEnquiryField}
                                        onCancelField={cancelEnquiryField}
                                        className={`form-control cursor-pointer ${errors["depositAmount"]}`}
                                    />
                                </div>
                            </>
                        )
                    }
                    else if (type === 'depositReciptNumber') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'depositReciptNumber')} /></label>
                                    <InputAutoText
                                        type="text"
                                        autoComplete="off"
                                        placeholder={'deposit receipt number'}
                                        name="depositReciptNumber"
                                        onChange={handleOnChange}
                                        value={depositReciptNumber}
                                        onSaveField={saveEnquiryField}
                                        onCancelField={cancelEnquiryField}
                                        className={`form-control cursor-pointer ${errors["depositReciptNumber"]}`}
                                    />
                                </div>
                            </>
                        )
                    }
                    // else if (type === 'leadSource') {
                    //     return (
                    //         <>
                    //             <div className="form-group col-md-6">
                    //                 <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'leadSource')} /></label>
                    //                 <InputAutoText
                    //                     type="text"
                    //                     autoComplete="off"
                    //                     placeholder={'lead source'}
                    //                     name="leadSource"
                    //                     onChange={handleOnChange}
                    //                     value={leadSource}
                    //                     onSaveField={saveEnquiryField}
                    //                     onCancelField={cancelEnquiryField}
                    //                     className={`form-control cursor-pointer ${errors["leadSource"]}`}
                    //                 />
                    //             </div>
                    //         </>
                    //     )
                    // }
                    else if (type === 'grossAmount') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'grossAmount')} /></label>
                                    <InputAutoNumber
                                        id="grossAmount"
                                        name="grossAmount"
                                        allowNegative={true}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                        placeholder={'gross amount'}
                                        onValueChange={onCurrencyChange.bind(this, 'grossAmount')}
                                        value={grossAmount ? grossAmount : null}
                                        onSaveField={saveEnquiryField}
                                        onCancelField={cancelEnquiryField}
                                        className={`form-control cursor-pointer ${errors["grossAmount"]}`}
                                    />
                                </div>
                            </>
                        )
                    }
                    else if (type === 'contractNumber') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={CommonHelper.getStaticFieldName(enquiryOptionsStatic, 'salesContractNumber')} /></label>
                                    <InputAutoText
                                        type="text"
                                        autoComplete="off"
                                        placeholder={'sales contract number'}
                                        className={`form-control cursor-pointer ${errors["contractNumber"]}`}
                                        name="contractNumber"
                                        onChange={handleOnChange}
                                        value={contractNumber}
                                        onSaveField={saveEnquiryField}
                                        onCancelField={cancelEnquiryField}
                                    />
                                </div>
                            </>
                        )
                    }
                    else {
                        return (
                            <>
                                {!_.isEmpty(enquiryOptionsDF) && enquiryOptionsDF.filter(m => m.active === true).map((info, index) => {
                                    if (type === info.value) {
                                        return (<div key={info.value} className="form-group col-md-6">
                                            <label>{info.name}</label>
                                            <DynamicInput
                                                index={'0' + index}
                                                data={info}
                                                errors={errors ? errors : {}}
                                                id={info.value}
                                                value={dynamicFields ? dynamicFields[info.value] : null}
                                                handleCheckChange={handleDynamicCheckChange}
                                                handleOnChange={handleDynamicOnChange}
                                                saveEnquiryField={saveDynamicField}
                                                cancelEnquiryField={cancelDynamicField}
                                                onCurrencyChange={onDynamicNumberChange}
                                                handleDateChange={handleDynamicDateChange}
                                                handleSelectSave={handleDynamicSelectSave}
                                                handleMultiSelectChange={handleDynamicMultiSelectChange}
                                                currencySymbol={currencySymbol}
                                                inputAutoFormat={true}
                                            >
                                            </DynamicInput>
                                        </div>)
                                    }
                                })}
                            </>
                        )
                    }

                })()}

            </>

        );
    }
}

export default EnquiryInputs;