/** LIBRARIES */
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */
import StockPipelineReport from '../mclarenPipeline/pipelineReport';

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        return (
            <main role="main" className={`container-fluid  pl-0 pr-0`}>

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/stock/pipeline/report/:id" exact render={(match) => <StockPipelineReport clientid={match.match.params.id} />} />

                    </Switch>
                </Suspense>

            </main>
        );
    }
}

export default MainContainer;