import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import moment from 'moment'
import htmlToText from 'html-to-text';
import TemplateEditor from './templateEditor'
import { PopUpModal, ContentOverlay, TableView } from '../../../../components'
import { templatelistFields, templateVM } from './viewModel'
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import { TemplateContext } from '../templateContext'
import AddTemplateHTML from './addTemplateHTML';
import { mentions } from './viewModel';

const EmailTemplateList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [showEdiorModel, setShowEdiorModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [templateList, setTemplateList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [template, setTemplate] = useState(null)
    const [addHtml, setAddHtml] = useState({
        show: false,
        title: '',
        template: null
    })

    const { emailTemplateList, edmTemplates, loading, formTemplates } = useContext(TemplateContext);
    const templates = props.type === 'email' ? edmTemplates : emailTemplateList
    const [formMentions, setMentions] = useState(mentions);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 315)
            })

        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(formTemplates))
            return

        let _formMentions = mentions;
        formTemplates.forEach(form => {
            _formMentions.push({
                name: `Form-${form.title}`,
                value: `@FORM/${form.documentID} `
            })
        })
        setMentions(_formMentions);

    }, [formTemplates])

    useEffect(() => {
        if (_.isEmpty(templates)) {
            return;
        }
        // var alltemploates = combineLatest(
        //     collectionData(window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/tradeinProtemplates`)
        //         .where('clientID', '==', props.dealersettings.client.id)
        //         .where('visibility', '==', 'private')
        //         .where('isDeleted', '==', false)
        //         .where('type', '==', 'email')),
        //     collectionData(window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/tradeinProtemplates`)
        //         .where('visibility', '==', 'shared')
        //         .where('type', '==', 'email')
        //         .where('isDeleted', '==', false))
        // ).pipe(
        //     map((templates) => {
        //         var [dealer, shared] = templates;
        //         return [
        //             [...dealer],
        //             [...shared]
        //         ];
        //     }))
        //     .subscribe(allDocs => {
        //         let list = [];
        //         allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
        //         let listVM = [];
        //         list.forEach(rec => {
        //             if (!listVM.some(e => e.documentID === rec.documentID))
        //                 listVM.push(convertVM(rec))
        //         })
        //         setTemplateList(listVM)
        //         setLoader(false)
        //     });
        // return () => {
        //     alltemploates && alltemploates.unsubscribe()
        // }
        let listVM = [];
        templates.forEach(rec => {
            if (!listVM.some(e => e.documentID === rec.documentID))
                listVM.push(convertVM(rec))
        })
        setTemplateList(listVM)
    }, [templates, props.type])

    const convertVM = (doc) => {
        const objData = Object.assign({}, doc);
        const listVM = Object.assign({}, templateVM);
        for (let [key, value] of Object.entries(objData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }
        var textlimit = 150
        if (window.innerWidth < 1500) textlimit = 85
        var text = doc.bodyHTML ? htmlToText.fromString(doc.bodyHTML.replace(/<img[^>]*>/g, "")) : '';
        listVM.emailContent = text.length > textlimit ? text.substring(0, textlimit) + '...' : text;
        listVM.createdBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.addedBy);
        listVM.updatedBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.modifiedBy);
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.visibilityDOM = doc.visibility === 'private' ?
            (<div className="badge badge-pill badge-private"  >Private </div>)
            : (<div className="badge badge-pill badge-shared" >Shared  </div>)

        return listVM
    }

    const handleModelClose = () => {
        setShowEdiorModel(false)
    }

    const hadleEditTemplate = (template) => {
        if (template.templateJSON) {
            setTemplate(template)
            setPopHeader(template.title)
            setShowEdiorModel(true)
        } else {
            setAddHtml({ show: true, title: template.title, template: template })
        }
    }

    const hadleDeletetemplate = (template) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinProtemplates/${template.documentID}`)
                    .set({
                        'isDeleted': true,
                        'modifiedBy': localStorage.uid,
                        'modifiedDate': window.firebase.firestore.Timestamp.now()
                    }, { merge: true })
                    .then(() => {
                        toast.notify(`Template deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })
            }
        })
    }

    const hadleCloneTemplate = (template) => {
        let templateToBePassed = {
            ...template,
            'documentID': window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/tradeinProtemplates/`).doc().id,
            'clientID': props.dealersettings.client.id,
            'addedBy': localStorage.uid,
            'modifiedBy': localStorage.uid,
            'addedDate': window.firebase.firestore.Timestamp.now(),
            'modifiedDate': window.firebase.firestore.Timestamp.now(),
            'visibility': 'private',
            'title': template.title + ' - Copy'
        }
        if (template.templateJSON) {
            setTemplate(templateToBePassed)
            setPopHeader(template.title + ' - Copy')
            setShowEdiorModel(true)
        } else {
            setAddHtml({ show: true, title: template.title + ' - Copy', template: templateToBePassed })
        }
    }

    const handleActionClick = (id, data) => {
        return (data.clientID === props.dealersettings.client.id || data.addedBy === localStorage.uid) ? (
            <div className="">
                <div title="Clone template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleCloneTemplate(data)
                }}>
                    <i className="ico icon-rearrange"></i>
                </div>
                <div title="Edit template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {

                    hadleEditTemplate(data)

                }}>
                    <i className={`ico icon-edit`}></i>
                </div>
                <div title="Delete template" style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {

                    hadleDeletetemplate(data)

                }}>
                    <i className={`ico icon-delete`}></i>
                </div>
            </div>
        ) : (
            <div className="">
                <div title="Clone template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleCloneTemplate(data)
                }}>
                    <i className="ico icon-rearrange"></i>
                </div>
                <div title="Edit template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2 btn-disable" onClick={(e) => {

                    e.preventDefault()
                }}>
                    <i className={`ico icon-lock`}></i>
                </div>
                <div title="Delete template" style={{ cursor: 'pointer' }} className="mini-button  btn-disable" onClick={(e) => {

                    e.preventDefault()
                }}>
                    <i className={`ico icon-lock`}></i>
                </div>
            </div>
        )
    }

    const handledetailsClick = (e, id, data) => {
        e.preventDefault();
        if (data.clientID === props.dealersettings.client.id || data.addedBy === localStorage.uid)
            hadleEditTemplate(data)
    }

    return (<>


        <div className="settings-head">

            <div className="float-left">
                <div className="filter-search search-icon">
                    <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                </div>
            </div>

            <div className="float-right">
                <button type="button" className="btn btn-primary float-right" onClick={() => {
                    setTemplate(null)
                    setPopHeader('Add Email Template')
                    setShowEdiorModel(true)
                }} ><i className="ico icon-add mr-2"></i>   <Translate text={'Add Email Template'} /></button>
            </div>

            <div className="float-right">
                <button type="button" className="btn btn-primary float-right mr-2" onClick={() => {
                    setAddHtml({ show: true, title: 'Add Email HTML', template: null })
                }} ><i className="ico icon-add mr-2"></i>   <Translate text={'Add Email HTML'} /></button>
            </div>

        </div>
        {
            loading ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                templateList.length > 0 ? (
                    <div className="common-table">
                        <TableView
                            datalist={_.orderBy(templateList.filter(item => item.type === props.type).filter(({ title, bodyHTML, createdBy, updatedBy }) =>
                            (!_.isEmpty(searchText) ? (
                                (!_.isEmpty(title) ? title.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                (!_.isEmpty(createdBy) ? createdBy.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                (!_.isEmpty(updatedBy) ? updatedBy.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                (!_.isEmpty(bodyHTML) ? bodyHTML.toLowerCase().includes(searchText.toLowerCase()) : false)
                            ) : true)
                            ), ['modifiedDate.seconds'], ['desc'])}
                            height={windowSize.windowHeight}
                            width={windowSize.windowWidth}
                            columns={templatelistFields}
                            dynamicFields={templatelistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                            handleActionClick={handleActionClick}
                            handleRowSingleClick={handledetailsClick}
                            isSettings={false}
                            isReorderable={false}
                            isSorting={false}
                            isTranslate={true}
                            dealersettings={props.dealersettings}
                        />
                    </div>
                ) : (
                    <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
                        <div className="text-center p-5">
                            <p> <Translate text={'No templates found'} /></p>
                            <button className="btn btn-sm btn-default" onClick={() => {
                                setTemplate(null)
                                setPopHeader('Add Email Template')
                                setShowEdiorModel(true)
                            }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                <Translate text={'Add Email Template'} />
                            </button>
                        </div>
                    </div>
                )
        }


        <PopUpModal show={showEdiorModel}>
            <TemplateEditor
                {...props}
                show={showEdiorModel}
                title={popHeader}
                handleClose={handleModelClose}
                template={template}
                type={props.type ? props.type : 'email'}
                formMentions={formMentions}
            />
        </PopUpModal>
        <PopUpModal show={addHtml.show}>
            <AddTemplateHTML
                {...props}
                show={addHtml.show}
                title={addHtml.title}
                handleClose={() => { setAddHtml({ show: false, title: '', template: null }) }}
                template={addHtml.template}
                type={props.type ? props.type : 'email'}
                dealersettings={props.dealersettings}
                formMentions={formMentions}
            />
        </PopUpModal>
    </>);
}

export default EmailTemplateList;