import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import NumberFormat from 'react-number-format';
import { InputText, ReactSelect, InputCheckBox, ReactMultiSelect, AntDateRangePicker } from '../../../../components'
import Translate from '../../../../constants/translate';
import { levelOptions, rosterLookupVM } from './viewModel'
import { SketchPicker } from 'react-color';
import moment from 'moment';
import CommonHelper from '../../../../services/common';

const AddLookup = (props) => {

    const [lookup, setLookup] = useState(props.lookup)
    const [oldLookup, setOldLookup] = useState(props.lookup ? props.lookup : {})
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const newLookup = !_.isEmpty(props.lookup) ? false : true;
    const [integarions, setIntegration] = useState([]);
    const [addSubList, setAddSubList] = useState({ type: '', value: '', id: uuid() })
    const [textEditor, setTexteditor] = useState({})
    const [editSubList, setEditSubList] = useState()
    const [subList, setSubList] = useState(!_.isEmpty(props.lookup) && !_.isEmpty(props.lookup.dmsKeys) ? props.lookup.dmsKeys.map(r => { return { ...r, id: r.id ? r.id : uuid() } }) : []);
    const [colorPicker, setColorPicker] = useState(false)
    const [cron, setCron] = useState();
    const [dateOptions, setDateOptions] = useState()
    const styles = {
        colorPicker: {
            width: '100%',
            height: '20px',
            borderRadius: '2px',
            background:
                !_.isEmpty(lookup) && lookup.color
                    ? lookup.color
                    : '#CCCCCC',
        },
        swatch: {
            padding: '5px',
            background: '#FFFFFF',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
            width: '100%',
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
    };

    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, rosterLookupVM);
            newlookupVM.sortNo = props.allLookups[props.activeLookup] ? (props.allLookups[props.activeLookup].length + 1) : 1
            if (props.activeLookup === 'activityTypes')
                newlookupVM.icon = 'activity-training'

            if (props.newLookup)
                setLookup(props.newLookup)
            else
                setLookup(newlookupVM)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(lookup) || props.activeLookup !== 'rosterHolidays')
            return

        let _cron = ['*', '*', '*', '*', '*']
        if (lookup.day) {
            _cron[4] = lookup.day;
        }
        if (lookup.date) {
            _cron[2] = lookup.date;
        }
        if (lookup.month) {
            _cron[3] = lookup.month;
        }
        setCron(_cron.join(' '));
    }, [lookup])

    useEffect(() => {
        let _date = []
        for (let i = 1; i < 31; i++) {
            _date.push({ label: `${i}`, value: `${i}` })
        }
        _date.push({ label: 'Last day of month', value: 'L' })
        setDateOptions(_date)
    }, [])



    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? props.activeLookup === 'rosterCategories' ? str.toUpperCase().match(/\b(\w)/g).join('') : uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? props.activeLookup === 'rosterCategories' ? str.toUpperCase().match(/\b(\w)/g).join('') : uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handlecolorSelectChange = (e, data) => {
        setLookup({
            ...lookup,
            [data.name]: e ? e.value : ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['levelID']: (data.name === 'level' && e.value === 'oem') ? [props.selectedOEM.id] : '',
                ['groupIDs']: '',
                ['clientIDs']: ''
            });
        }
        else if (data.name === 'frequency') {
            setLookup({
                ...lookup,
                [data.name]: e ? e.value : '',
                ['day']: null,
                ['date']: null,
                ['month']: null,
            });
        }
        else if (data.name === 'month') {
            setLookup({
                ...lookup,
                [data.name]: e ? e.value : '',
                ['day']: null,
                ['date']: null
            });
        }
        else {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['groupIDs']: e.groupIDs,
                ['clientIDs']: e.clientIDs
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setLookup({
            ...lookup,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setLookup({
            ...lookup,
            [e.target.name]: e.target.checked
        })
    }

    // CAN UPDATE DIFFIRENT LEVEL
    const saveLookup = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let _regionLookups = [];
        let _groupLookups = [];
        let _clientLookups = []
        if (_.isEmpty(lookup['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (props.activeLookup === 'rosterCategories' && _.isEmpty(lookup['value'])) {
            formIsValid = false;
            errors['value'] = errorClass;
        }
        if (!lookup['price'] && lookup['price'] !== 0 && props.activeLookup === 'servicePackages') {
            formIsValid = false;
            errors['price'] = errorClass;
        }
        if (_.isEmpty(lookup['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(lookup.level) && lookup.level !== 'oem' && _.isEmpty(lookup['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (newLookup && props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.name.toLowerCase() === lookup.name.toLowerCase() && lookup.levelID.some(a => item.levelID === a))) {
            Swal.fire(CommonHelper.showLocale(props, 'Same settings already exists'), '', 'error')
            formIsValid = false;
        }
        if (!lookup['color'] && (props.activeLookup === 'financeStatus' || props.activeLookup === 'enquiryStatus' || props.activeLookup === 'checkoutPerformance' || props.activeLookup === 'acquisitionSource' || props.activeLookup === 'rosterCategories' || props.activeLookup === 'rosterHolidays')) {
            formIsValid = false;
            errors['color'] = errorClass;
        }
        if (props.activeLookup === 'rosterHolidays' && _.isEmpty(lookup['dateFrom']) && !Boolean(lookup.recurrence)) {
            formIsValid = false;
            errors['dateField'] = errorClass;
        }
        if (lookup.recurrence === true && props.activeLookup === 'rosterHolidays') {
            if (_.isEmpty(lookup['frequency'])) {
                formIsValid = false;
                errors['frequency'] = errorClass;
            }
            if (lookup['frequency'] === 'weekly' && _.isEmpty(lookup['day'])) {
                formIsValid = false;
                errors['day'] = errorClass;
            }
            if ((lookup['frequency'] === 'monthly' || lookup['frequency'] === 'yearly') && _.isEmpty(lookup['date'])) {
                formIsValid = false;
                errors['date'] = errorClass;
            }
            if (lookup['frequency'] === 'yearly' && !lookup['month']) {
                formIsValid = false;
                errors['month'] = errorClass;
            }
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (cron) {
            lookup.cron = cron;
        }
        const batch = window.firebase.firestore().batch();
        if (_.isArray(lookup.levelID)) {
            lookup.levelID.forEach(rec => {
                const lookupObj = {
                    [props.activeLookup]: (newLookup || !oldLookup.levelID.includes(rec)) ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === rec), function (obj) { return { ..._.pick(obj, _.keys(rosterLookupVM)) }; }), _.pick(lookup, _.keys(rosterLookupVM))] :
                        [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && rec === item.levelID), function (obj) {
                            return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(rosterLookupVM)) } : { ..._.pick(obj, _.keys(rosterLookupVM)) };
                        })]
                }
                console.log(`${lookup.level}-${rec}`, lookupObj)
                if (lookup.level !== 'oem') {
                    batch.set(window.firebase.firestore().doc(`clients/${rec}/roster/${rec}`),
                        {
                            ...lookupObj,
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                            modifiedBy: localStorage.uid
                        }, { merge: true });
                    batch.set(window.firebase.firestore().doc(`clients/${rec}`),
                        {
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Roster Settings has been updated',
                        }, { merge: true })
                }

            })
            if (_.isArray(oldLookup.levelID)) {
                (oldLookup.level === lookup.level ? _.difference(oldLookup.levelID, lookup.levelID) : oldLookup.levelID).forEach(rec => {
                    const updatelookupObj = {
                        [props.activeLookup]: [..._.map(props.allLookups[props.activeLookup].filter(item => item.value !== oldLookup.value && item.level === oldLookup.level && rec === item.levelID), function (obj) {
                            return { ..._.pick(obj, _.keys(rosterLookupVM)) };
                        })]
                    }
                    console.log(`${oldLookup.level}-${rec}`, updatelookupObj)
                    if (oldLookup.level !== 'oem') {
                        batch.set(window.firebase.firestore().doc(`clients/${rec}/roster/${rec}`),
                            {
                                ...updatelookupObj,
                                modifiedDate: window.firebase.firestore.Timestamp.now(),
                                modifiedBy: localStorage.uid
                            }, { merge: true });
                        batch.set(window.firebase.firestore().doc(`clients/${rec}`),
                            {
                                settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                settingsUpdatePriority: 'normal',
                                settingsUpdateBy: props?.dealersettings?.name || null,
                                settingsUpdateText: 'Roster Settings has been updated',
                            }, { merge: true })
                    }

                })
            }
        }

        batch.commit()
            .then(snapshot => {
                setLoader(false)
                toast.notify((newLookup === true ? 'Settings added successfully' : 'Settings updated successfully'), {
                    duration: 2000
                })
                props.handleClose(lookup, newLookup)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });

    }

    const handleDateRangeChange = (val, name) => {
        setLookup({
            ...lookup,
            dateFrom: val ? val[0].format('YYYY-MM-DD') : null,
            dateTo: val ? val[1].format('YYYY-MM-DD') : null
        })
    };

    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-lookup"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className={`form-group col-md-${props.activeLookup === 'rosterCategories' ? '12' : '9'}`}>
                                    <label > <Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={lookup.name}
                                        maxLength={150}
                                        disabled={(!Boolean(lookup.disableEdit) || newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false}
                                    />
                                </div>
                                {
                                    props.activeLookup === 'rosterCategories' ? (
                                        <div className="form-group col-md-9">
                                            <label > <Translate text={'Code'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'value'}
                                                className={`form-control ${errorFields["value"]}`}
                                                name="value"
                                                onChange={handleOnChange}
                                                value={lookup.value}
                                                maxLength={150}
                                                disabled={(!Boolean(lookup.disableEdit) || newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
                                                    (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false}
                                            />
                                        </div>) : (<></>)
                                }

                                <div className="form-group col-md-3">
                                    <label > <Translate text={'Colour'} /></label>
                                    <div
                                        style={styles.swatch}
                                        onClick={() => {
                                            setColorPicker(!colorPicker);
                                        }}
                                    >
                                        <div
                                            //className='custom-color-picker-form-template'
                                            style={styles.colorPicker}
                                        />
                                    </div>
                                    {colorPicker ? (
                                        <div style={styles.popover}>
                                            <div
                                                style={styles.cover}
                                                onClick={() => {
                                                    setColorPicker(false);
                                                }}
                                            />
                                            <SketchPicker
                                                color={
                                                    !_.isEmpty(lookup) && lookup.color
                                                        ? lookup.color
                                                        : '#000000'
                                                }
                                                onChange={color =>
                                                    setLookup({
                                                        ...lookup,
                                                        color: color.hex
                                                    })
                                                }
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                {
                                    props.activeLookup === 'rosterHolidays' ? (
                                        <>
                                            {
                                                lookup.recurrence ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        <div className="form-group col-md-12 daterange-fix">
                                                            <label ><Translate text={'Date'} /></label>
                                                            <AntDateRangePicker
                                                                value={lookup.dateFrom && lookup.dateTo ? [moment(lookup.dateFrom), moment(lookup.dateTo)] : null}
                                                                id={"field-date-range"}
                                                                name={'dateField'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'dateField') }}
                                                                placeholder='DD/MM/YYYY'
                                                                className={`${errorFields["dateField"]}`}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className='form-group col-md-12 mt-2 mb-2'>
                                                <span className="switch switch-sm float-left">
                                                    <InputCheckBox
                                                        className="switch"
                                                        name={'recurrence'}
                                                        checked={lookup.recurrence === true ? true : false}
                                                        onChange={(e) => { handleCheckedChange(e) }}
                                                        id={'fields-recurrence'} />
                                                    <label htmlFor={'fields-recurrence'}><Translate text={'Recurrence'} /></label>
                                                </span>
                                            </div>
                                            {
                                                lookup.recurrence === true ? (<>

                                                    <div className={`form-group col-md-12`}>
                                                        <label ><Translate text={'Frequency'} /></label>
                                                        <ReactSelect
                                                            options={props.frequencyOptions}
                                                            name={"frequency"}
                                                            placeholder={'select frequency'}
                                                            onChange={handleSelectChange}
                                                            value={lookup.frequency}
                                                            classNamePrefix={`${errorFields["frequency"]} cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    {
                                                        lookup.frequency === 'weekly' ? (
                                                            <div className="form-group col-md-12">
                                                                <label ><Translate text={'Select Day'} /></label>
                                                                <ReactMultiSelect
                                                                    options={moment.weekdays().map(rec => {
                                                                        return {
                                                                            label: rec,
                                                                            value: moment.weekdays().indexOf(rec).toString()
                                                                        }
                                                                    })}
                                                                    name={"day"}
                                                                    placeholder={'select day'}
                                                                    onChange={(option) => handleReactMultiSelectChange(option, 'day')}
                                                                    value={lookup.day ? lookup.day : []}
                                                                    classNamePrefix={`${errorFields["day"]} cursor-pointer basic-select`}
                                                                    removeClearable={false}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        ) : lookup.frequency === 'monthly' ? (
                                                            <div className="form-group col-md-12">
                                                                <label ><Translate text={'selectDate'} /></label>
                                                                <ReactMultiSelect
                                                                    options={dateOptions}
                                                                    name={"date"}
                                                                    placeholder={'select date'}
                                                                    onChange={(option) => handleReactMultiSelectChange(option, 'date')}
                                                                    value={lookup.date ? lookup.date : []}
                                                                    classNamePrefix={`${errorFields["date"]} cursor-pointer basic-select`}
                                                                    removeClearable={false}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        ) : lookup.frequency === 'yearly' ? (<>
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'month'} /></label>
                                                                <ReactSelect
                                                                    options={Array.apply(0, Array(12))
                                                                        .map(function (_, i) { return moment().month(i).format('MMMM') })
                                                                        .map(r => {
                                                                            return {
                                                                                label: r,
                                                                                value: moment(`${r} ${moment().year()}`).month() + 1
                                                                            }
                                                                        })}
                                                                    name={"month"}
                                                                    placeholder={'select month'}
                                                                    onChange={handleSelectChange}
                                                                    value={lookup.month}
                                                                    classNamePrefix={`${errorFields["month"]} cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                            <div className="form-group col-md-6">

                                                                <label ><Translate text={'selectDate'} /></label>
                                                                <ReactMultiSelect
                                                                    options={lookup.month ? Array.from(Array(moment(`${moment().year()}-${lookup.month}`).daysInMonth()).keys())
                                                                        .map(r => {
                                                                            return {
                                                                                label: `${r + 1}`,
                                                                                value: `${r + 1}`
                                                                            }
                                                                        }) : []}
                                                                    name={"date"}
                                                                    placeholder={'select date'}
                                                                    onChange={(option) => handleReactMultiSelectChange(option, 'date')}
                                                                    value={lookup.date ? lookup.date : []}
                                                                    classNamePrefix={`${errorFields["date"]} cursor-pointer basic-select`}
                                                                    removeClearable={false}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </>) : (<></>)
                                                    }
                                                </>) : (<></>)
                                            }
                                        </>) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={lookup.level}
                                        classNamePrefix={`${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                        isDisabled={lookup.disableDelete || props.activeLookup === 'rosterHolidays' || props.activeLookup === 'rosterCategories'}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(lookup.level) && lookup.level !== 'oem') ? (<div className="form-group col-md-12">
                                        <label ><Translate text={lookup.level === 'group' ? 'Group' : lookup.level === 'region' ? 'Region' : 'client'} /></label>
                                        <ReactMultiSelect
                                            options={lookup.level === 'group' ? props.groupOptions : lookup.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'levelID'}
                                            placeholder={`select ${lookup.level === 'individual' ? 'client' : lookup.level}`}
                                            onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
                                            value={lookup.levelID ? _.isArray(lookup.levelID) ? lookup.levelID : [lookup.levelID] : []}
                                            classNamePrefix={` ${errorFields["levelID"]} basic-select`}
                                            removeClearable={true}
                                            //${!newLookup ? 'select-readonly' : ''} 
                                            //isDisabled={!newLookup}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className={`switch switch-sm float-left mt-2 ${lookup.disableDelete ? 'btn-disable' : (newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? '' :
                    (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? 'btn-disable' : ''}`}>
                    <InputCheckBox
                        className={`switch`}
                        id={`lookup-active`}
                        name={'active'}
                        checked={lookup.active ? lookup.active : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`lookup-active`}><Translate text={'active'} /></label>
                </span>
                {
                    props.activeLookup === 'rosterHolidays' ? (<>
                        <span className={`switch switch-sm float-left mt-2  ${lookup.disableDelete ? 'btn-disable' : ''}`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`field-clickable`}
                                name={'clickable'}
                                checked={lookup.clickable ? lookup.clickable : false}
                                onChange={(e) => { handleCheckedChange(e) }} />
                            <label htmlFor={`field-clickable`}>Clickable</label>
                        </span>
                        <span className={`switch switch-sm float-left mt-2  ${lookup.disableDelete ? 'btn-disable' : ''}`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`field-dealershipOff`}
                                name={'dealershipOff'}
                                checked={lookup.dealershipOff ? lookup.dealershipOff : false}
                                onChange={(e) => { handleCheckedChange(e) }} />
                            <label htmlFor={`field-dealershipOff`}>Dealership Off</label>
                        </span>
                    </>) : (<></>)
                }
                <button type="button" className={`btn btn-primary float-right ml-2`} onClick={(e) => saveLookup(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddLookup;