import React, { useState, useEffect } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap'
import _ from 'lodash'
import { ReactSelect, InputCheckBox, ReactMultiSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import uuid from 'react-uuid'
import CommonHelper from '../../../../services/common';
import { timeSlots } from './viewmodel';

const AddRules = (props) => {

    const [state, setState] = useState(props.state)
    const [errorFields, setErrorFields] = useState({});
    const newlist = !_.isEmpty(props.state) && !props.newRule ? false : true;
    const roleIds = [..._.map(props.dealersettings.client.roles.filter(e => e.modules && e.modules.tradeinPro === true), function (obj) {
        return obj.documentID;
    })];


    useEffect(() => {
        if (_.isEmpty(props.state)) {
            setState({ id: uuid() })
        }
    }, [])

    const handleSubMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        setState({
            ...state,
            [name]: value,
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleonSubSelectChange = (e, data) => {
        if (data.name === 'pipeline') {
            setState({
                ...state,
                [data.name]: e ? e.value : '',
                stage: '',
            })
        } else {
            setState({
                ...state,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const generateStageOptions = _pipeline => {
        const selectedPipeline = props.dealersettings.client.tradeinPro.allPipelines.filter(rec => rec.value === _pipeline)[0]
        let stagesToReturn = !_.isEmpty(selectedPipeline) ? selectedPipeline.stages.map(stage => {
            return {
                active: true,
                label: stage.name ? stage.name : '',
                value: stage.value,
                name: stage.name ? stage.name : '',
            }
        }) : []

        return stagesToReturn
    }

    const handleCheckedChange = (e, name) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked
        })
    }

    const handleSave = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(state['stage']) || _.isEmpty(state['stage'].trim())) {
            formIsValid = false;
            errors['stage'] = errorClass;
        }
        if (_.isEmpty(state['pipeline']) || _.isEmpty(state['pipeline'].trim())) {
            formIsValid = false;
            errors['pipeline'] = errorClass;
        }
        if (_.isEmpty(state['time']) || _.isEmpty(state['time'].trim())) {
            formIsValid = false;
            errors['time'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        props.handleSublistSave(state, newlist)
    }

    return _.isEmpty(state) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-lookup"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'inboundSource'} /></label>
                                    <ReactMultiSelect
                                        options={props.integrations}
                                        name={"systemType"}
                                        placeholder={'select inbound source'}
                                        onChange={(option) => handleSubMultiSelectChange(option, 'systemType')}
                                        value={state.systemType ? state.systemType : []}
                                        classNamePrefix={`${errorFields["systemType"]} basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                {/*  <div className="form-group col-md-12">
                                    <label > <Translate text={'make'} /></label>
                                    <ReactMultiSelect
                                        options={props.makes}
                                        name={"make"}
                                        placeholder={'select make'}
                                        onChange={(option) => handleSubMultiSelectChange(option, 'make')}
                                        value={state.make ? state.make : []}
                                        classNamePrefix={`basic-select`}
                                        id="sublistmake"
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                               <div className="form-group col-md-12">
                                    <label > <Translate text={'vehicleSaletype'} /></label>
                                    <ReactMultiSelect
                                        options={props.salesTypes}
                                        name={"saleType"}
                                        placeholder={'select saleType'}
                                        onChange={(option) => handleSubMultiSelectChange(option, 'saleType')}
                                        value={state.saleType ? _.isArray(state.saleType) ? state.saleType : [state.saleType] : []}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div> */}
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'origin'} /></label>
                                    <ReactMultiSelect
                                        options={props.origins}
                                        name={"origin"}
                                        placeholder={'select origin'}
                                        onChange={(option) => handleSubMultiSelectChange(option, 'origin')}
                                        value={state.origin ? state.origin : []}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Pipeline'} /></label>
                                    <ReactSelect
                                        options={props.dealersettings?.client?.tradeinPro?.allPipelines ? props.dealersettings?.client?.tradeinPro?.allPipelines.filter(a => a.value !== 'LeadsBucket').map(rec => {
                                            return {
                                                label: rec.name,
                                                value: rec.value
                                            }
                                        }) : []}
                                        name={"pipeline"}
                                        placeholder={'select pipeline'}
                                        onChange={handleonSubSelectChange}
                                        value={state.pipeline ? state.pipeline : ''}
                                        classNamePrefix={`${errorFields["pipeline"]} cursor-pointer basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Stage'} /></label>
                                    <ReactSelect
                                        options={generateStageOptions(state.pipeline ? state.pipeline : '')}
                                        name={"stage"}
                                        placeholder={'select stage'}
                                        onChange={handleonSubSelectChange}
                                        value={state.stage}
                                        classNamePrefix={`${errorFields["stage"]} cursor-pointer basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Sales Persons'} /></label>
                                    <ReactMultiSelect
                                        options={props.clientUsers.filter(e => Boolean(e.superAdmin) === false && (roleIds ? _.includes(roleIds, e.roleID) : true))
                                            .map(rec => {
                                                return {
                                                    value: rec.id,
                                                    searchlabel: rec.name,
                                                    active: _.isBoolean(rec.active) ? rec.active : true,
                                                    label: (
                                                        <div className='drop-image'>
                                                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                            {rec.name}
                                                        </div>
                                                    ),
                                                };
                                            })}
                                        name={"salesPersonIDs"}
                                        placeholder={`select sales persons`}
                                        onChange={(option) => handleSubMultiSelectChange(option, 'salesPersonIDs')}
                                        value={state.salesPersonIDs ? state.salesPersonIDs : []}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>

                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Managers'} /></label>
                                    <ReactMultiSelect
                                        options={props.clientUsers.filter(e => Boolean(e.superAdmin) === false && (roleIds ? _.includes(roleIds, e.roleID) : true))
                                            .map(rec => {
                                                return {
                                                    value: rec.id,
                                                    searchlabel: rec.name,
                                                    active: _.isBoolean(rec.active) ? rec.active : true,
                                                    label: (
                                                        <div className='drop-image'>
                                                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                            {rec.name}
                                                        </div>
                                                    ),
                                                };
                                            })}
                                        name={"managerIDs"}
                                        placeholder={`select managers`}
                                        onChange={(option) => handleSubMultiSelectChange(option, 'managerIDs')}
                                        value={state.managerIDs ? state.managerIDs : []}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Time'} /></label>
                                    <ReactSelect
                                        options={timeSlots}
                                        name={"time"}
                                        placeholder={'select time'}
                                        onChange={handleonSubSelectChange}
                                        value={state.time}
                                        classNamePrefix={`${errorFields["time"]} cursor-pointer basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className={`switch switch-sm float-left mt-2`}>
                    <InputCheckBox
                        className={`switch`}
                        id={`default-active`}
                        name={'default'}
                        checked={state.default ? state.default : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`default-active`}><Translate text={'Default'} /></label>
                </span>
                <button type="button" className={`btn btn-primary float-right ml-2`} onClick={(e) => {
                    handleSave()
                }}>
                    {
                        props.rulesLoader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddRules;