/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import CommonHelper from '../../../services/common';
import moment from 'moment';
import { InputRadio, InputText, InputTextArea, PopUpModal, ReactSelect } from '../../../components';
import "../../../styles/attendeeConfirmation.scss";
import _ from 'lodash'
import AddGuest from './addGuest';
import images from '../../../images';
import Swal from 'sweetalert2';
import uuid from 'react-uuid';

const AttendeeConfirmation = props => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _eid = params.get('eid');
    const _sts = params.get('sts');
    const [firestoreDB, setFirestoreDB] = useState()
    const [submitLoader, setSubmitLoader] = useState(false);
    const [projectId, setProjectId] = useState()
    const [event, setEvent] = useState()
    const [contact, setContact] = useState()
    const [settings, setSettings] = useState()
    const [client, setClient] = useState()
    const [loader, setLoader] = useState(true);
    const [guestInfo, setGuestInfo] = useState([]);
    const [guestModel, setGuestModel] = useState({ show: false });
    const [contactStatus, setContactStatus] = useState();
    const [clientSettings, setClientSettings] = useState()
    const [notes, setNotes] = useState()
    // const [phoneCodes, setPhoneCodes] = useState([]);



    useEffect(() => {
        if (props.contactid) {
            window.firebase.firestore().doc(`eventsProAttendees/${props.contactid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase)
                    }
                    else {
                        setFirestoreDB(window.firebase2)
                        setProjectId(window.secondaryProjectId);
                    }
                })
        }
        else {
            setFirestoreDB(window.firebase)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        let _promise = [];
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(_eid).get());
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(_eid).collection('settings').doc(_eid).get());
        _promise.push(firestoreDB.firestore().collection(`eventsProAttendees`).doc(props.contactid).get());

        Promise.all(_promise)
            .then(async docs => {
                if (docs[0]?.exists) {
                    setEvent(docs[0].data())
                    if (docs[0].data().clientID) {
                        const clientDoc = await window.firebase.firestore().collection(`clients`).doc(docs[0].data().clientID).get();
                        if (clientDoc.exists) {
                            setClient(clientDoc.data());
                        }
                        const settingsDoc = await window.firebase.firestore().doc(`clients/${docs[0].data().clientID}/currentSettings/${docs[0].data().clientID}/eventsPro/${docs[0].data().clientID}`).get();
                        if (settingsDoc.exists) {
                            setClientSettings(settingsDoc.data());
                        }
                    }
                    else if (docs[0].data().oemID) {
                        const clientDoc = await window.firebase.firestore().collection(`clientSettings`).doc(docs[0].data().oemID).get();
                        if (clientDoc.exists) {
                            setClient(clientDoc.data());
                        }
                        const settingsDoc = await window.firebase.firestore().doc(`clientSettings/${docs[0].data().oemID}/eventsPro/${docs[0].data().oemID}`).get();
                        if (settingsDoc.exists) {
                            setClientSettings(settingsDoc.data());
                        }
                    }
                    // const responseCountries = await fetch(`${window.location.origin}/lookups/countries.json`);
                    // const countriesjson = await responseCountries.json();
                    // let _phoneCodes = [];
                    // countriesjson.forEach((doc) => {
                    //     if (doc.phone_code) {
                    //         _phoneCodes.push({
                    //             country_code: doc.code,
                    //             value: doc.phone_code,
                    //             active: _.isBoolean(doc.active) ? doc.active : true,
                    //             label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                    //         });
                    //     }
                    // });
                    // setPhoneCodes(_phoneCodes);
                }
                if (docs[1]?.exists) {
                    setSettings(docs[1].data())
                }
                if (docs[2]?.exists) {
                    setContact({
                        ...docs[2].data(),
                        'status': docs[2].data().status ? docs[2].data().status : _sts ? _sts : 'accepted'
                    })
                    setContactStatus(docs[2].data().status);
                    if (docs[2].data().guests) {
                        setGuestInfo(docs[2].data().guests)
                    }
                    if (docs[2].data().notes && _.find(docs[2].data().notes, { addedBy: 'Attendee' })) {
                        setNotes(_.find(docs[2].data().notes, { addedBy: 'Attendee' }).notes)
                    }
                }
                setLoader(false)
            })

    }, [firestoreDB]);

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email') {
            setContact({
                ...contact,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setContact({
                    ...contact,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setContact({
                    ...contact,
                    [name]: str
                });
            }
        }
    }


    const handleReactSelectChange = (e, data) => {
        setContact({
            ...contact,
            [data.name]: e ? e.value : null
        })
    }

    const handleNotesChange = (e) => {
        e.preventDefault()
        const { value } = e.target;
        setNotes(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        let categories = clientSettings?.categories;
        let category = {};
        if (categories?.length > 0) {
            categories.map(rec => { return category = { ...category, [rec.value]: false } });
        }
        else {
            category = {
                invitation: false
            }
        }
        const _reqObject = {
            attendeeID: props.contactid,
            attendee: {
                status: contact.status,
                firstName: contact.firstName,
                middleName: (contact.middleName || ''),
                lastName: contact.lastName,
                phone: contact.phone,
                email: contact.email,
                eventSlot: contact.eventSlot,
                dietaryPreference: contact.dietaryPreference,
            },
            guestInfo: guestInfo?.length > 0 && contact.status !== 'declined' ? guestInfo : [],
            eventsProID: _eid,
            projectId: projectId,
            category
        }
        if (!_.isEmpty(notes)) {
            _reqObject.notes = notes
        }
        const genericSaveData = window.firebase.functions().httpsCallable('eventsPro-registerAttendee');
        genericSaveData(_reqObject).then((response) => {
            //console.log(response);
            if (response.data.success) {
                setSubmitLoader(false)
                setContactStatus(contact.status)
                Swal.fire('Thank you for registering your interest.', '', 'success')
            }
            else {
                setSubmitLoader(false)
                Swal.fire('Something went wrong.', '', 'error')
            }
        });
        // const batch = firestoreDB.firestore().batch();
        // batch.set(firestoreDB.firestore().doc(`eventsProAttendees/${props.contactid}`), {
        //     ...contact,
        //     [`${contact.status}Date`]: window.firebase.firestore.Timestamp.now(),
        //     notes: !_.isEmpty(contact.notes) ? [
        //         ...contact.notes,
        //         notesObj
        //     ] : [notesObj],
        //     guests: guestInfo
        // }, { merge: true });
        // if (guestInfo?.length > 0 && contact.status !== 'declined') {
        //     guestInfo.forEach(rec => {
        //         batch.set(firestoreDB.firestore().doc(`eventsProAttendees/${rec.documentID}`), {
        //             ...rec,
        //             eventsProID: _eid,
        //             documentID: rec.documentID,
        //             addedDate: window.firebase.firestore.Timestamp.now(),
        //             modifiedDate: window.firebase.firestore.Timestamp.now(),
        //             isDeleted: false,
        //             category,
        //             isShow: false
        //         }, { merge: true });
        //     })
        // }
        // batch.commit()
        //     .then(() => {
        //         setSubmitLoader(false)
        //         setContactStatus(contact.status)
        //         Swal.fire('Thank you for registering your interest.', '', 'success')
        //     })
        //     .catch((error) => {
        //         setSubmitLoader(false)
        //         Swal.fire('Something went wrong.', '', 'error')
        //     });;
    }


    return loader ? (
        <>
            <div className='loader-center-screen'>
                <div className='spinner-loader h-100 '>
                    <div className='d-flex h-100 justify-content-center align-items-center'>
                        <div role='status' className='spinner-border'></div>
                        <p className='text-muted mt-3'></p>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <main role="main">
            <div className="eventslanding-header">
                <div className="container">
                    <div className="eventslanding-logo-wrap"> <span className="eventslanding-logo-img mr-3">
                        {
                            client?.logoURL
                                ?
                                <img src={client.logoURL} height="60" />
                                :
                                <div className='eventslanding-info-card-title'>{event.title}</div>
                        }
                    </span>
                        <div className="eventslanding-logo-title"> </div>
                    </div>
                </div>
            </div>

            <div className="eventslanding-banner">
                {
                    event.imageURL ? (
                        <img src={event.imageURL} />
                    ) : (<></>)
                }

            </div>


            <div className="container">

                <div className="eventslanding-wrap-bg ">
                    <div className={`eventslanding-wrap ${contactStatus ? 'form-disable' : ''}`}>




                        <div className="eventslanding-question-loop">


                            <div className="eventslanding-info-card">

                                <div className="eventslanding-info-card-title">{event.title}</div>

                                <div className="eventslanding-sub-info ">
                                    <div className="float-left mt-1">
                                        <ul className="eventslanding-sub-details">

                                            <li> <i className="ico icon-location"></i> {event.location} </li>
                                            <li> <i className="ico icon-date"></i>{CommonHelper.showCombineDate(event.startDate, event.endDate)}</li>
                                            <li><i className="ico icon-time"></i>{CommonHelper.showCombineTime(event.startDate, event.endDate)}</li>

                                        </ul>
                                    </div>

                                </div>

                            </div>
                            <div className="eventslanding-description">
                                {
                                    event.description ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: event.description,
                                            }}
                                        ></div>
                                    ) : (<></>)
                                }
                            </div>


                            <div className="eventslanding-title-head"><h2>Register Your Interest</h2></div>
                            <div className="events-radio-buttonwrap">

                                <div className={`radio icheck-success mr-1 radio-bg ${contact.status === 'accepted' ? 'radio-bg-accepted' : ''}`}>
                                    <InputRadio
                                        id="accepted"
                                        type="radio"
                                        value="accepted"
                                        name="status"
                                        checked={contact.status === 'accepted' ? true : false}
                                        onChange={(e) => {
                                            setContact({
                                                ...contact,
                                                'status': e.target.value
                                            })
                                        }}
                                    />
                                    <label htmlFor="accepted">Accepted</label>
                                </div>
                                <div className={`radio icheck-success mr-1 radio-bg ${contact.status === 'tentative' ? 'radio-bg-tentative' : ''}`}>
                                    <InputRadio
                                        id="tentative"
                                        type="radio"
                                        value="tentative"
                                        name="status"
                                        checked={contact.status === 'tentative' ? true : false}
                                        onChange={(e) => {
                                            setContact({
                                                ...contact,
                                                'status': e.target.value
                                            })
                                        }}
                                    />
                                    <label htmlFor="tentative">Tentative</label>
                                </div>
                                <div className={`radio icheck-success mr-1 radio-bg ${contact.status === 'declined' ? 'radio-bg-declined' : ''}`}>
                                    <InputRadio
                                        id="declined"
                                        type="radio"
                                        value="declined"
                                        name="status"
                                        checked={contact.status === 'declined' ? true : false}
                                        onChange={(e) => {
                                            setContact({
                                                ...contact,
                                                'status': e.target.value
                                            })
                                        }}
                                    />
                                    <label htmlFor="declined">Declined</label>
                                </div>




                            </div>

                            <div className="eventslanding-subheader-info"><i className="ico icon-Contacts"></i> Attendee Details</div>


                            <div className="eventslanding-field-wraper form-style ">

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label >First Name</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'first name'}
                                            className={`form-control`}
                                            name="firstName"
                                            onChange={handleOnChange}
                                            value={contact.firstName}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Middle Name</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'middle name'}
                                            className={`form-control`}
                                            name="middleName"
                                            onChange={handleOnChange}
                                            value={contact.middleName}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Last Name</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'last name'}
                                            className={`form-control`}
                                            name="lastName"
                                            onChange={handleOnChange}
                                            value={contact.lastName}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Phone</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'phone'}
                                            className={`form-control`}
                                            name="phone"
                                            onChange={handleOnChange}
                                            value={contact.phone}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Email</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'email'}
                                            className={`form-control`}
                                            name="email"
                                            onChange={handleOnChange}
                                            value={contact.email}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Slots</label>
                                        <ReactSelect
                                            options={settings?.slots ? settings?.slots.map(rec => {
                                                return {
                                                    label: rec.name,
                                                    value: rec.value
                                                }
                                            }) : []}
                                            name={"eventSlot"}
                                            placeholder={'select event slot'}
                                            onChange={handleReactSelectChange}
                                            value={contact.eventSlot}
                                            classNamePrefix={`basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Meal Preference</label>
                                        <ReactSelect
                                            options={settings?.dietary ? settings?.dietary.map(rec => {
                                                return {
                                                    label: rec.name,
                                                    value: rec.value
                                                }
                                            }) : []}
                                            name={"dietaryPreference"}
                                            placeholder={'select meal preference'}
                                            onChange={handleReactSelectChange}
                                            value={contact.dietaryPreference}
                                            classNamePrefix={`basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </div>

                            {
                                contact.status === 'declined' || _.isEmpty(settings?.guest?.toString()) || settings?.guest === 0 ? (<></>) : (
                                    <>
                                        <div className="eventslanding-subheader-info"><i className="ico  icon-events-guest"></i> Guest Details <span className="guest-max-limit"> (Maximum {settings.guest} Guest )</span>
                                            {
                                                guestInfo.length === settings.guest ? (<></>) : (
                                                    <button className="btn btn-sm btn-default float-right" onClick={(e) => {
                                                        e.preventDefault();
                                                        setGuestModel({
                                                            show: true,
                                                            title: 'Add Guest'
                                                        })
                                                    }}><i className="ico icon-add mr-1"></i> Add </button>
                                                )
                                            }
                                        </div>
                                        {
                                            guestInfo.map((info, index) => {
                                                return <div key={index} className="events-guest-listwrap">
                                                    <div className="events-guest-list">
                                                        <div className="events-guest-info-details">
                                                            <button className="btn btn-sm btn-default float-right ml-2" onClick={(e) => {
                                                                e.preventDefault();
                                                                setGuestModel({
                                                                    show: true,
                                                                    title: 'Edit Guest',
                                                                    guest: info
                                                                })
                                                            }}><i className="ico icon-edit"></i> </button>
                                                            <button className="btn btn-sm btn-default float-right ml-2" onClick={(e) => {
                                                                e.preventDefault();
                                                                Swal.fire({
                                                                    title: 'Are you sure?',
                                                                    text: 'Do you want to delete?',
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Yes',
                                                                    cancelButtonText: 'No',
                                                                    allowOutsideClick: false,
                                                                    allowEscapeKey: false
                                                                }).then((result) => {
                                                                    if (result.value) {
                                                                        setGuestInfo(guestInfo.filter(a => a.documentID !== info.documentID))
                                                                    }
                                                                })
                                                            }}><i className="ico icon-delete"></i> </button>
                                                            <ul>
                                                                <li> <strong>Name</strong> : {CommonHelper.displayContactName([], info)}</li>
                                                                {
                                                                    info.email ? (
                                                                        <li> <strong>Email</strong> : {info.email}</li>
                                                                    ) : (<></>)
                                                                }
                                                                {
                                                                    info.phone ? (
                                                                        <li> <strong>Email</strong> : {info.phone}</li>
                                                                    ) : (<></>)
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </>
                                )
                            }
                            <div className="form-group col-md-12 pr-0 pl-0">
                                <label >Comments</label>
                                <InputTextArea
                                    className={`form-control notes`}
                                    name="notes"
                                    onChange={handleNotesChange}
                                    value={notes}
                                    maxLength="280"
                                    rows="3">
                                </InputTextArea>
                            </div>
                            {
                                _.isEmpty(contactStatus) ? (
                                    <div className="eventslanding-footer">

                                        <button type="button" className={`btn btn-primary float-right ${submitLoader ? 'btn-disable' : ''}`} onClick={(e) => handleSubmit(e)}>
                                            {
                                                submitLoader ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            Register</button>
                                    </div>
                                ) : (
                                    <div className="alert alert-success mt-2" role="alert">
                                        Thank you for registering your interest.
                                    </div>
                                )
                            }


                            <div className="eventslanding-footer-bottom">
                                <div className="eventslanding-footer-bottom-sub">
                                    <div className="eventslanding-cpy-wrap"> <img src={images.safeTag} alt="" width="120" /></div>
                                    <div className="eventslanding-cpy-wrap"> Contact us if you are interested in joining one of our events or would like more information at {client?.email}, or phone {client?.phone}</div>
                                    <div className="clear"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <PopUpModal show={guestModel.show}>
                <AddGuest
                    {...guestModel}
                    handleClose={(val, isNew) => {
                        if (val) {
                            setGuestInfo(isNew ? [
                                ...guestInfo,
                                val
                            ] : guestInfo.map(rec => rec.documentID === val.documentID ? {
                                ...val
                            } : { ...rec }))
                        }
                        setGuestModel({ show: false })
                    }}
                />
            </PopUpModal>
        </main>
    );
};

export default AttendeeConfirmation;
