/** LIBRARIES */
import React, { PureComponent, Component } from "react";
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
import {
    PopUpModal,
    AntMonthPicker,
    ReactSelect,
    LoaderOverlay,
    InputAutoText,
    InputAutoNumber,
    AntDatePicker,
    PopoverPanel,
    SidePanel,
    InputCheckBox,
    DynamicInput
} from '../../../components';
import _ from 'lodash'
import moment from 'moment';
import CommonHelper from '../../../services/common';
import { tipStatus } from '../../../services/enum';

class TipInfoInputs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        let { status, enquiryType, serviceType,
            label, purchaseIntention, contractNumber,
            depositAmount, depositReciptNumber, grossAmount, origin, campaign,
            lostReason, lostSubReason, deliveryDate, leadSource,
            chassisNo, regNo, stockNo, warrantyExpiry, warrantyStartDate,
            isFinanceRequired, financeStatus, dynamicFields
        } = this.props.fields;

        const { dealersettings, canModifyTIP, errors, currencySymbol, type, origins,
            enquiryOptionsDF, lostReasons, lostSubReasons, handleSelectSave, handleLostReasonSave,           
            // enquiryTypes, leadSources, origins, campaigns, labels, financeEnabled,
            // __financestatus, financeStatuses, serviceTypes, enquiryAllStatus,
            // handlePIDateSave, isPIValid, handleDeliveryDateChange, handleOnCapChange,
            // saveEnquiryField, cancelEnquiryField, handleDateChange,
            // handleCheckChange, onCurrencyChange, handleOnChange,
            handleDynamicCheckChange, handleDynamicOnChange, saveDynamicField, cancelDynamicField, onDynamicNumberChange,
            handleDynamicDateChange, handleDynamicSelectSave, handleDynamicMultiSelectChange
        } = this.props;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);

        const modifyLostReason = true;//((!_.isEmpty(_permissions) && _permissions.modifyLostReason) ? true : false);

        
        const isStatusOpen = (_.isEmpty(status)  || status === tipStatus.NEW) ? true : false;
        const isStatusLost = (status === tipStatus.LOST) ? true : false;

        return (
            <>

                {(() => {
                    if (type === 'origin') {
                        return (
                            <>
                                <tr>
                                    <td className="text-left label-text"><Translate text={'origin'} /></td>
                                    <td className="text-left">
                                        <ReactSelect
                                            options={origins}
                                            name={"origin"}
                                            placeholder={'select origin'}
                                            onChange={handleSelectSave}
                                            value={origin}
                                            classNamePrefix={`cursor-pointer ${errors["origin"]} basic-select`}
                                            //removeClearable={true}
                                            isDisabled={canModifyTIP ? false : true}
                                        >
                                        </ReactSelect>
                                    </td>
                                </tr>
                            </>
                        )
                    }
                    else if (type === 'lostReason' && isStatusLost) {
                        return (
                            <>
                                <tr>
                                    <td className='text-left label-text'><Translate text={'lostReason'} /></td>
                                    <td className='text-left'>
                                        <ReactSelect
                                            options={lostReasons}
                                            name={'lostReason'}
                                            placeholder={'select lost reason'}
                                            onChange={handleLostReasonSave}
                                            value={lostReason}
                                            classNamePrefix={`cursor-pointer ${errors['lostReason']} basic-select`}
                                            //removeClearable={true}
                                            isDisabled={(canModifyTIP && (modifyLostReason || !lostReason || isStatusOpen)) ? false : true}
                                        ></ReactSelect>
                                    </td>
                                </tr>
                            </>
                        )
                    }
                    else if (type === 'lostSubReason' && isStatusLost && !_.isEmpty(lostSubReasons)) {
                        return (
                            <>
                                <tr>
                                    <td className='text-left label-text'><Translate text={'lostSubReason'} /></td>
                                    <td className='text-left'>
                                        <ReactSelect
                                            options={lostSubReasons}
                                            name={'lostSubReason'}
                                            placeholder={'select lost sub reason'}
                                            onChange={handleSelectSave}
                                            value={lostSubReason}
                                            classNamePrefix={`cursor-pointer ${errors['lostSubReason']} basic-select`}
                                            //removeClearable={true}
                                            isDisabled={(canModifyTIP && (modifyLostReason || !lostSubReason || isStatusOpen)) ? false : true}
                                        ></ReactSelect>
                                    </td>
                                </tr>
                            </>
                        )
                    }
                    else {
                        return (
                            <>
                                {!_.isEmpty(enquiryOptionsDF) && enquiryOptionsDF.filter(m => m.active === true).map((info, index) => {
                                    if (type === info.value) {
                                        return (<tr key={info.value}>
                                            <td className='text-left label-text'>{info.name}</td>
                                            <td className={`text-left`}>
                                                <DynamicInput
                                                    index={'0' + index}
                                                    data={info}
                                                    errors={errors ? errors : {}}
                                                    id={info.value}
                                                    value={dynamicFields ? dynamicFields[info.value] : null}
                                                    handleCheckChange={handleDynamicCheckChange}
                                                    handleOnChange={handleDynamicOnChange}
                                                    saveEnquiryField={saveDynamicField}
                                                    cancelEnquiryField={cancelDynamicField}
                                                    onCurrencyChange={onDynamicNumberChange}
                                                    handleDateChange={handleDynamicDateChange}
                                                    handleSelectSave={handleDynamicSelectSave}
                                                    handleMultiSelectChange={handleDynamicMultiSelectChange}
                                                    currencySymbol={currencySymbol}
                                                    inputAutoFormat={true}
                                                >
                                                </DynamicInput>
                                            </td>
                                        </tr>)
                                    }

                                })}
                            </>
                        )
                    }

                })()}

            </>

        );
    }
}

export default TipInfoInputs;