import React, { Component } from 'react'
import MyMapComponent from './oemMap';

class MapWrapper extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    let a = this.props.clientCoordinatesArray
    let b = nextProps.clientCoordinatesArray

    function comparer(otherArray){
      return function(current){
        return otherArray.filter(function(other){
          return other.documentID === current.documentID 
        }).length === 0;
      }
    }

    var onlyInA = a.filter(comparer(b));
    var onlyInB = b.filter(comparer(a));

    let diff = onlyInA.concat(onlyInB);

    //console.log(diff);
    if (diff.length === 0) {
      return false
    } else {
      return true
    }
  }

  render() {
    return (
      <MyMapComponent
        isMarkerShown={this.props.isMarkerShown}
        clientCoordinatesArray={this.props.clientCoordinatesArray}
        onMarkerClick={this.props.onMarkerClick}
      />
    )
  }


}

export default MapWrapper