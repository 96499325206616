/** LIBRARIES */
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash';
import Swal from 'sweetalert2'
/** COMPONENTS */
import { ReactSelect } from '../../components';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';

export default class PipelineSwitch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields:
            {
                pipeline: '',
                stage: '',
                owner: ''
            },
            isLoading: false,
            errors: {},
            pipelines: [],
            defaulStages: [],
            statusSettings: [],
            activeStageIndex: 0,
            allUsers: [],
        }
    }

    loadEnquiry = () => {
        const { pipeline, stage, owner, dealersettings, pipelineView, clientID, groupUsers, clientUsers } = this.props;
        if (dealersettings && dealersettings.client && dealersettings.client.tradeinPro) {

            // const setttings = dealersettings && dealersettings.group &&
            // dealersettings.group.clients && dealersettings.group.clients[fields.clientID] && dealersettings.group.clients[fields.clientID].tradeinPro;

            const pipelines = [];
            let stages = null;

            let setttings = dealersettings.client.tradeinPro;

            if (clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[clientID] && dealersettings.group.clients[clientID].tradeinPro)
                setttings = dealersettings.group.clients[clientID].tradeinPro;

            let _selectedPipeline = null;

            if (pipeline)
                _selectedPipeline = setttings.allPipelines.filter(item => item.value === pipeline)[0];
            else
                _selectedPipeline = setttings.allPipelines.filter(item => item.default === true)[0];

            let defaultstages = _selectedPipeline.stages;

            let statusSettings = pipeline ? setttings.allPipelines.filter(e => e.value === pipeline)[0].statusSettings : null;

            stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            setttings.allPipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let objFilds = {
                pipeline: pipeline ? pipeline : _selectedPipeline.value,
                stage: stage ? stage : stages[0],
                owner: owner
            }

            if (owner === localStorage.uid) {
                const _userRoles = (dealersettings && dealersettings.roleID &&
                    dealersettings.client && dealersettings.client.roles &&
                    dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
                const noPipelineAccess = _userRoles && objFilds.pipeline && _userRoles.tradeinProPipeline && _userRoles.tradeinProPipeline.some(u => u === objFilds.pipeline) ? false : true;
                if (noPipelineAccess)
                    objFilds.owner = '';
            }


            // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            let options = [];
            if (!_.isEmpty(dealersettings.client.roles) && clientID) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.tradeinProPipeline && e.tradeinProPipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === clientID), (v) => _.includes(roleIds, v.roleID));


                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
            }
            else {
                clientUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
            }

            let _currentUser = options.filter(v => v.value === owner)[0];
            if (!_currentUser) {
                objFilds.owner = '';
            }

            this.setState({
                fields: objFilds,
                errors: {},
                allUsers: options,
                pipelines: pipelines,
                defaulStages: defaultstages,
                statusSettings: statusSettings,
                activeStageIndex: stage ? stages.indexOf(stage) : 0
            });
        }
    }

    componentDidMount() {
        this.loadEnquiry();
    }

    handlePipelineChange = (e, data) => {
        const { pipeline, dealersettings, clientID, groupUsers, clientUsers } = this.props;
        const { fields } = this.state;
        let state = Object.assign({}, this.state.fields);
        let setttings = dealersettings.client.tradeinPro;

        if (clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[clientID] && dealersettings.group.clients[clientID].tradeinPro)
            setttings = dealersettings.group.clients[clientID].tradeinPro;

        let defaultstages = setttings.allPipelines.filter(v => v.value === e.value)[0].stages;
        let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

        let statusSettings = setttings.allPipelines.filter(e => e.value === e.value)[0].statusSettings;
        state.pipeline = e.value;
        state.stage = '';

        if (fields.owner === localStorage.uid) {
            const _userRoles = (dealersettings && dealersettings.roleID &&
                dealersettings.client && dealersettings.client.roles &&
                dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
            const noPipelineAccess = _userRoles && e.value && _userRoles.tradeinProPipeline && _userRoles.tradeinProPipeline.some(u => u === e.value) ? false : true;
            if (noPipelineAccess)
                state.owner = '';
        }

        // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
        let options = [];
        if (!_.isEmpty(dealersettings.client.roles)) {
            let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.tradeinProPipeline && e.tradeinProPipeline.some(m => m === state.pipeline)), function (obj) {
                return obj.documentID;
            })];
            let _selectedUsers = [];
            let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            if (!_.isEmpty(roleIds))
                _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === clientID), (v) => _.includes(roleIds, v.roleID));


            _selectedUsers && _selectedUsers.map(rec => {
                return options.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                })
            })

            let _currentUser = options.filter(v => v.value === fields.owner)[0];
            if (!_currentUser) {
                state.owner = '';
            }
        }

        this.setState({
            fields: state,
            allUsers: options,
            defaulStages: defaultstages,
            statusSettings: statusSettings,
            activeStageIndex: -1
        });
    }

    handleStageChange = (e) => {
        e.preventDefault();
        const { id, name } = e.target;

        const { status, isNewEnq } = this.props;
        const { statusSettings, fields } = this.state;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === fields.pipeline && e.stage === name)[0];
        if (!isNewEnq && _statusMndry && status !== _statusMndry.status) {
            let __stage = this.state.defaulStages.find(x => x.value === name);
            Swal.fire(`${CommonHelper.showLocale(this.props, 'Please change appraisal status to')} ${_statusMndry.status.toUpperCase()} ${CommonHelper.showLocale(this.props, 'before moving to stage')}: ${__stage ? __stage.name : ''}`, '', 'info')
            return;
        }

        let state = Object.assign({}, this.state.fields);
        state.stage = name;

        this.setState({ fields: state, activeStageIndex: id }, () => { this.errorChange('stage'); });

    }

    handleOwnerChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';
        //console.log('fields', fields)
        for (let [key] of Object.entries(fields)) {
            if (!fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.setState({ isLoading: true });
            this.props.handleClose(fields);
        }
        else
            return formIsValid;

    };

    handleCancel = (e) => {
        e.preventDefault();
        this.loadEnquiry();
    }


    render() {
        const { pipeline, owner } = this.state.fields;
        const { allUsers } = this.state;
        const { dealersettings, isNewEnq } = this.props;
        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);
        const modifyEnquiryOwner = true;//((!_.isEmpty(_permissions) && _permissions.modifyEnquiryOwner) ? true : false);
        return (
            <>
                <div>
                    <div className={`${this.props.pipeline && !isNewEnq ? 'popover-body' : ''} popover-content`}>
                        <div className="form-style popover-middle-pipeline">
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'pipeline'} /></label>
                                    <ReactSelect
                                        options={this.state.pipelines}
                                        name={"pipeline"}
                                        placeholder={'select pipeline'}
                                        onChange={this.handlePipelineChange}
                                        value={pipeline}
                                        classNamePrefix={`${this.state.errors["pipeline"]} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Pipeline Stage'} /></label>
                                    <div className={`${this.state.errors["stage"] ? 'custom_error' : ''}`}>
                                        <ul className={`pipeline-step `}>
                                            {
                                                this.state.defaulStages && this.state.defaulStages.map((_stage, index) => {
                                                    return (
                                                        <OverlayTrigger
                                                            key={index}
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip>{_stage.name}</Tooltip>
                                                            }
                                                        >
                                                            <li key={index} className={this.state.activeStageIndex >= index ? 'active' : ''}>
                                                                <a href="#" id={index} name={_stage.value} onClick={(e) => this.handleStageChange(e)}>
                                                                    {/* {_stage.name} */}
                                                                    {<>&nbsp;</>}
                                                                </a>
                                                            </li>
                                                        </OverlayTrigger>

                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="form-group">
                                <label ><Translate text={'owner'} /></label>
                                <ReactSelect
                                    options={allUsers}
                                    name={"owner"}
                                    onChange={this.handleOwnerChange}
                                    value={owner}
                                    classNamePrefix={`${this.state.errors["owner"]} basic-select`}
                                    removeClearable={true}
                                    isDisabled={modifyEnquiryOwner ? false : true}
                                >
                                </ReactSelect>
                            </div>
                        </div>
                        <div className={`popover-footer d-inline-block width100 ${this.state.isLoading ? 'form-disable' : ''}`}>
                            <button
                                type="button"
                                className="btn btn-primary float-right ml-2"
                                onClick={(e) => this.handleSubmit(e)}
                            >
                                {this.state.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'done'} />
                            </button>
                            <button
                                type="button"
                                className="btn btn-default float-right"
                                onClick={(e) => this.handleCancel(e)}
                            ><Translate text={'clear'} />
                            </button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
