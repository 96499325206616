/** LIBRARIES */
import React, { useEffect, Suspense } from 'react';
import _ from 'lodash'
/** PROVIDERS */
/** COMPONENTS */

import MainContainer from './container';
 

const VideoLayout = (props) => {



    useEffect(() => {
        //document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        document.getElementsByTagName('body')[0].classList.add('video-page-landing');
        //document.getElementsByTagName('body')[0].classList.add('inner-video-bg');
        document.getElementsByTagName('body')[0].classList.add('body-vid');
        
        //document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        //document.getElementsByTagName('body')[0].className = 'inner-video-bg';
        //document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        return () => {
            //document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
        }
    }, [])





    return (
        <Suspense fallback={<></>}>
            <>
                <MainContainer history={props.history} />
            </>
        </Suspense>
    );
}

export default VideoLayout;