
export const reportlistFields = [
    {
        name: 'Name',
        value: 'name',
        subText: 'strfileType',
        flex: 1,
        default: true
    },
    {
        name: 'Scheduled At',
        value: 'cronDesc',
        flex: 1,
        default: true
    }, 
    {
        name: 'Last Execution Status',
        value: 'strStatus',
        subText: 'lastExecution',
        flex: 1,
        default: true
    },
    {
        name: 'Next Execution',
        value: 'nextExecution',
        flex: 1,
        default: true
    },
    {
        name: 'Active',
        value: 'enabled',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]