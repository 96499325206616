
/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import _ from 'lodash';
import moment from 'moment';
//import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { PopUpModal, ReactSelect, InputTextArea, InputText } from '../../components';
import Users from '../users/list/index';
import Contacts from '../contacts/list/index';
import ContactActivityView from './activity'
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import AddContact from '../contacts/add';
import SearchContainer from '../contacts/searchContainer';
/** VIEW-MODELS */
import { engageStatus } from '../../services/enum'
import { objEngageRequest } from './viewModel'
import { objContact } from "../contacts/viewModel";
import { objCallLogs } from "../callLogs/viewModel";
import { firestoreDB } from "../../services/helper";


// const enquiryTypes = [
//     { value: 'Walk In', label: 'Walk In' },
//     { value: 'Phone', label: 'Phone' },
//     { value: 'Email', label: 'Email' }
// ]

// const campaigns = [
//     { value: 'Walk In', label: 'Walk In' },
//     { value: 'Test Campaign', label: 'Test Campaign' },
//     { value: 'Service Leads', label: 'Service Leads' }
// ]
let titles = [];
let origins = [];
let enquiryTypes = [];
let campaigns = [];
let sale_type = [];

export default class EngageContact extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.mandatoryFields = [];
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('engageRequests');
        this.state = {
            fields: Object.assign({}, objEngageRequest),
            showUsers: false,
            showContacts: false,
            showContactsMobile: false,
            errors: {},
            contactModal:
            {
                docID: null,
                showContact: false,
                showContactSearch: false,
                contactTitle: '',
                clsContactActive: '',
                mandatoryFields: null
            },
            makes: [],
            models: [],
            types: [],
            groups: [],
            years: [],
            rosterMsg: null,
            //origins: []
        }
    }

    //#region PAGE LOAD EVENTS
    componentDidMount() {
        const { userID, show, dealersettings, groupUsers, clientUsers } = this.props;
        const accessToSelectAllKPIs = dealersettings?.rolePermissions?.permissions?.accessToSelectAllKPIs;

        if (show === true) {

            const fields = Object.assign({}, this.state.fields);
            if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                const setttings = dealersettings.client.settings;

                if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.frontdesk))
                    this.mandatoryFields = setttings.mandatoryFields.frontdesk.split(',');

                titles = [];
                setttings.titles && setttings.titles.forEach(doc => {
                    titles.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name,
                    });
                });

                origins = [];
                let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)
                setttings.origins && setttings.origins.forEach(doc => {
                    origins.push({
                        value: doc.value,
                        active: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? false : _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name,
                        isDisabled: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? true : accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                    });
                });

                enquiryTypes = [];
                setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                    enquiryTypes.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name,
                        isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                    });
                });

                campaigns = [];
                setttings.campaigns && setttings.campaigns.forEach(doc => {
                    campaigns.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name,
                        isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                    });
                });

                sale_type = [];
                setttings.salesType && setttings.salesType.forEach(doc => {
                    sale_type.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name,
                    });
                });

                const years = [];
                const makes = [];
                const brands = dealersettings.client.brands;
                for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                    years.push({
                        value: i.toString(),
                        label: i.toString(),
                        active: true
                    });
                }

                _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                    makes.push({
                        value: doc.value,
                        label: doc.name,
                        models: doc.models,
                        active: _.isBoolean(doc.active) ? doc.active : true
                    });
                });

                this.setState({
                    makes: makes,
                    years: years
                });

                const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);
                if (enableServicePackages && !fields.saleType)
                    fields.saleType = !_.isEmpty(sale_type[0]) ? sale_type[0].value : 'New';

            }

            fields.user = userID ? userID : '';
            this.setState({ fields: fields });

            if (userID) {
                CommonHelper.checkUserRoster(dealersettings,
                    {
                        owner: userID,
                        startDate: window.firebase.firestore.Timestamp.now()
                    }, (!_.isEmpty(groupUsers) ? groupUsers : clientUsers))
                    .then(msg => {
                        this.setState({ rosterMsg: msg });
                    })
            }

        }
    }
    //#endregion


    //#region CONTACT CHANGES
    handleContactOpen = (ID, title, _manFields) => {
        //    console.log('handleContactOpen', ID, title, _manFields);
        this.setState({
            contactModal: {
                docID: ID ? ID : null,
                showContact: true,
                showContactSearch: false,
                contactTitle: title,
                clsContactActive: 'overlay-modal active',
                mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
            }
        });
    }

    handleContactClose = (ID, objData) => {
        if (ID && !_.isEmpty(objData)) {

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            objData.documentID = ID;
            const fields = Object.assign({}, this.state.fields);
            fields.contact = Object.assign({}, objData);
            fields.name = '';
            fields.notes = (CommonHelper.displayContact([], objData, '--')) + ' is an existing contact. please engage.';
            this.setState({ fields: fields });

        }
        else if (ID) {
            const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objData = Object.assign({}, doc.data());
                    //Remove empty value from object
                    for (var propName in objData) {
                        if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                            delete objData[propName];
                        }
                    }
                    const customer = Object.assign({}, objContact);
                    for (let [key, value] of Object.entries(objData)) {
                        customer[key] = value;
                    }

                    customer.documentID = doc.id;

                    const fields = Object.assign({}, this.state.fields);
                    fields.contact = Object.assign({}, customer);
                    fields.name = '';
                    fields.notes = (CommonHelper.displayContact([], customer, '--')) + ' is an existing contact. please engage.';
                    this.setState({ fields: fields });

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({
            showContacts: false,
            showContactsMobile: false,
            contactModal: { docID: null, showContact: false, showContactSearch: false, contactTitle: '', clsContactActive: '', mandatoryFields: null }
        }, () => { this.errorChange('contact') });
    }

    handleRemoveCustomer = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.contact = null;
        fields.name = '';
        fields.notes = '';
        this.setState({ fields: fields });
    }
    //#endregion


    //#region USER CHANGES
    handleStaffClose = (ID, objData) => {
        if (ID) {

            const fields = Object.assign({}, this.state.fields);
            fields.user = ID;
            this.setState({ fields: fields });

            if (ID) {
                const { dealersettings, groupUsers, clientUsers } = this.props;
                CommonHelper.checkUserRoster(dealersettings,
                    {
                        owner: ID,
                        startDate: window.firebase.firestore.Timestamp.now()
                    }, (!_.isEmpty(groupUsers) ? groupUsers : clientUsers))
                    .then(msg => {
                        this.setState({ rosterMsg: msg });
                    })
            }
        }
        this.setState({
            showUsers: false
        }, () => { this.errorChange('staff') });
    }

    handleRemoveStaff = () => {
        const fields = Object.assign({}, this.state.fields);
        fields.user = null;
        this.setState({ fields: fields, rosterMsg: null });
    }
    //#endregion

    //#region CHANGE EVENTS
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                errors[key] = '';
            }

            // if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
            //     errors[key] = errorClass;
            // }
        }

        this.setState({ errors: errors });
    }

    bindUserData = () => {
        const { user } = this.state.fields;
        const { clientUsers, dealersettings } = this.props;
        // let _departments = [];

        // if (dealersettings && dealersettings.client && dealersettings.client.settings) {
        //     const setttings = dealersettings.client.settings;
        //     _departments = setttings.departments;
        // }
        //console.log('bindUserData', user, clientUsers, clientUsers.filter(e => e.id === user))
        if (clientUsers && clientUsers.filter(e => e.id === user).length > 0) {
            let _userData = Object.assign({}, clientUsers.filter(e => e.id === user)[0]);
            //let department = _userData && _userData.department && _departments && _departments.filter(item => item.value === _userData.department).length > 0 ? _departments.filter(item => item.value === _userData.department)[0].name : '';
            return (
                <div className="engage-contact-user cursor-pointer" onClick={(e) => {
                    e.preventDefault();
                    this.setState({ showUsers: true })
                }}>
                    <div className="frontdesk-change-btn">
                        <i className="ico icon-down-arrow"></i>
                    </div>
                    <div className="engage-contact-user-content">
                        <div className="engage-contact-user-image">
                            <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={CommonHelper.showUserAvatar(_userData.profileImage, _userData.name)} alt="" className="img-object-fit"></img> </a></div>
                        <div className="engage-contact-user-title">{_userData.name}</div>
                        <div className="engage-contact-user-titlesub">{_userData.role ? _userData.role : ''} - {_userData.departmentName ? _userData.departmentName : _userData.department} </div>
                    </div>
                </div>
            )
        }
    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);



        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            fields.notes = (fields[name] ? fields[name] + ' is an new contact. please engage.' : '');
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            fields.notes = (fields[name] ? fields[name] + ' is an new contact. please engage.' : '');
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        if (name === 'name') {
            if (fields[name].length > 2 && !this.state.showContactsMobile) {
                this.setState({ showContactsMobile: true })
            } else if (fields[name].length < 3) {
                this.setState({ showContactsMobile: false })
            }
        }

    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ fields: state }, () => { this.errorChange(data.name); });

    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ fields: state, models: models }, () => { this.errorChange(data.name); });

    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })
        }

        state['model'] = '';
        this.setState({ fields: state, models: models, types: _.orderBy(types, ['label'], ['asc']) }, () => { this.errorChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.fields);
        const types = [];
        const models = [];
        const groups = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            fields: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorChange(data.name); });

    }
    //#endregion


    //#region SAVE & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();

        if (this.isLoading) {
            return
        }

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                if (key === 'name') {
                    if ((_.isObject(fields.contact) && _.isEmpty(fields.contact)) && !fields['name']) {
                        formIsValid = false;
                        errors.name = errorClass;
                    }
                }
                else if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }

            }
        }

        if (_.isEmpty(this.mandatoryFields) && (_.isObject(fields.contact) && _.isEmpty(fields.contact)) && !fields['name']) {
            formIsValid = false;
            errors.name = errorClass;
        }

        this.setState({ errors: errors });


        if (formIsValid === true) {
            this.addEngage(this);
        }
        else
            return formIsValid;

    };

    addEngage = (e) => {
        const { dealersettings } = this.props;
        this.isLoading = true;
        const objData = Object.assign({}, this.state.fields);
        let _date = moment()._d;
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.fromDate(_date);
        }
        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.fromDate(_date);
        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!objData.clientID)
            objData.clientID = dealersettings ? dealersettings.client.id : '';

        if (!objData.status)
            objData.status = engageStatus.PENDING;

        if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('engageRequests').doc().id;

        const logRef = firestoreDB(dealersettings).firestore().collection('callLogs')
        let logDocID = logRef.doc().id

        objData.callLogID = logDocID;

        //console.log('Engage request send', objData);
        const updateRef = firestoreDB(dealersettings).firestore().collection('engageRequests').doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            let logData = Object.assign({}, objCallLogs)
            logData.noNotify = true
            logData.callDuration = 0
            logData.clientID = dealersettings ? dealersettings.client.id : '';
            logData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            logData.documentID = logDocID;
            logData.isDeleted = false
            logData.notes = this.state.fields.notes
            logData.status = engageStatus.PENDING;
            logData.user = this.state.fields.user
            logData.addedBy = localStorage.uid
            logData.addedDate = window.firebase.firestore.Timestamp.fromDate(_date);
            logData.addedFrom = 'web'
            logData.modifiedBy = localStorage.uid
            logData.modifiedDate = window.firebase.firestore.Timestamp.fromDate(_date);
            logData.modifiedFrom = 'web'
            logData.engageRequestID = objData.documentID;

            if (objData.enquiryType) logData.enquiryType = objData.enquiryType
            if (objData.origin) logData.origin = objData.origin
            if (objData.campaign) logData.campaign = objData.campaign

            // ADD REQUIREMENT DATA
            if (!_.isEmpty(objData.make)) {
                for (let [key, value] of Object.entries(objData)) {
                    if (value && (key === 'make' || key === 'model' || key === 'type' || key === 'group' || key === 'year' || key === 'saleType'))
                        logData[key] = value;
                }
            }

            if (objData.enquiryType && objData.enquiryType.toLowerCase().includes('walk')) {
                logData.logType = 'walkin'
            } else if (objData.enquiryType && objData.enquiryType.toLowerCase().includes('call')) {
                logData.logType = 'call'
                logData.callType = 'inbound'
            }
            if (!_.isEmpty(objData.contact)) {
                logData.contact = objData.contact
            } else {
                logData.contact = {
                    businessContact: false,
                    contactType: 'Potential Customer',
                    firstName: objData.name,
                    isDeleted: false,
                    isVIP: false,
                    banLoanVehicle: false,
                    optinPhone: true,
                    optinSMS: true,
                    optinEmail: true,
                    optinPost: true,
                    marketingOptinEmail: true,
                    marketingOptinSMS: true,
                }
            }
            logRef.doc(logDocID).set(logData, { merge: true }).then(res => {
                this.props.handleClose()
                toast.notify('Engage request send successfully', {
                    duration: 2000
                })
                this.isLoading = false;
            })
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };
    //#endregion

    render() {
        const { user, contact, name, notes, campaign, origin, enquiryType,
            make, model, saleType, type, year, group
        } = this.state.fields;
        const { show, dealersettings } = this.props;
        const { showUsers, showContacts, contactModal, errors, showContactsMobile,
            makes, types, models, years, groups, rosterMsg } = this.state;

        const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);
        return (
            <>

                <Modal
                    id="engage-contact"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-engage-contact">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'engageContact'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className={`container-fluid`}>
                            <div className="row">
                                <div className="engage-contact-head form-style">

                                    <div className="form-group">
                                        {
                                            user
                                                ?
                                                <>
                                                    {this.bindUserData()}
                                                </>
                                                :
                                                <>
                                                    <div className="testdrive-search">
                                                        <button type="button" className={`btn btn-secondary float-left `}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ showUsers: true })
                                                            }}>
                                                            <i className="ico icon-search"></i> <Translate text={'searchFromExistingStaffs'} />
                                                        </button>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    {
                                        !_.isEmpty(rosterMsg) ? (
                                            <div className="activity-pop-info alert-danger mb-1">
                                                <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{rosterMsg}
                                            </div>
                                        ) : (<></>)
                                    }
                                    <div className="engage-contact-title">{'Contact Info'}
                                        <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => {
                                            e.preventDefault();
                                            this.handleContactOpen(null, 'addContact');
                                        }}>
                                            <i className="ico icon-add"></i> <Translate text={'addContact'} />
                                        </button>
                                        <button type="button" className={`btn btn-secondary float-right `}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ showContacts: true })
                                            }}>
                                            <i className="ico icon-search"></i> <Translate text={'searchFromExistingContacts'} />
                                        </button>
                                    </div>

                                    <div className="form-row">

                                        {
                                            (!_.isEmpty(contact))
                                                ?
                                                <div className="form-group col-md-12">
                                                    <div className="form-readonly-wrap">
                                                        <div className="input-readonly">
                                                            <div className="input-readonly-edit">
                                                                <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); this.handleContactOpen(contact.documentID, 'editContact'); }}>
                                                                    <i className="ico icon-edit"></i>
                                                                </a>
                                                            </div>
                                                            <div className="input-readonly-close">
                                                                <a href="#" onClick={(e) => { e.preventDefault(); this.handleRemoveCustomer(); }}><i className="ico icon-remove"></i> </a>
                                                            </div>

                                                            <div className="input-readonly-inner">
                                                                <div className="calllogs-head">
                                                                    {CommonHelper.displayContactIcon(contact, 'mr-1')}
                                                                    {CommonHelper.displayContact(titles, contact)}
                                                                    {/* {contact.displayID ? <span className='calllogs-contact-id'>({contact.displayID})</span> : <></>} */}
                                                                </div>
                                                                {
                                                                    (contact.businessContact && !_.isEmpty(contact.company))
                                                                        ?
                                                                        <div className="calllogs-head">
                                                                            <i className={`ico icon-Contacts mr-1`}></i>
                                                                            {CommonHelper.getFullName([], contact, '')}
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    contact.displayID ?
                                                                        (<div className="vehicle-item-info"><i className={`ico icon-enquiry mr-1`}></i>{contact.displayID}</div>) :
                                                                        (<></>)
                                                                }

                                                                {dealersettings.group && contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                                                    <div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getOtherDealerName(dealersettings, contact.clientID)}</strong></div> :
                                                                    <></>}

                                                                {contact.regionID && dealersettings.regionID && contact.regionID !== dealersettings.regionID ?
                                                                    <div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getNameByCode(dealersettings.regions, contact.regionID, '')}</strong></div>
                                                                    : <></>}


                                                                {(contact.phone || contact.email)
                                                                    ?
                                                                    <div className="calllogs-contact-sub">
                                                                        <ul className="calllogs-contact-details">
                                                                            {contact.phone ? <li> <i className="ico icon-call"></i>{CommonHelper.getFullPhone(contact.phoneCode, contact.phone)}</li> : <></>}
                                                                            {contact.email ? <li> <i className="ico icon-email"></i>{contact.email}</li> : <></>}
                                                                        </ul>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {/* <div className="form-group col-md-12">
                                                        <label ><Translate text={'name'} /></label>
                                                        <InputText
                                                            name="name"
                                                            value={name}
                                                            onChange={this.handleOnChange}
                                                            autoComplete="off"
                                                            placeholder={'name'}
                                                            className={`form-control ${this.state.errors["name"]}`}
                                                        />
                                                    </div> */}

                                                    <div className="form-group position-relative col-md-12">
                                                        <label ><Translate text={'name'} /></label>
                                                        <InputText
                                                            name="name"
                                                            value={name}
                                                            onChange={this.handleOnChange}
                                                            autoComplete="off"
                                                            placeholder={'name'}
                                                            className={`form-control ${this.state.errors["name"]}`}
                                                        />

                                                        <SearchContainer
                                                            history={this.props.history}
                                                            showSearchPanel={showContactsMobile}
                                                            dealersettings={dealersettings}
                                                            searchText={name ? name : ''}
                                                            handleClose={this.handleContactClose}
                                                            handleSearchClose={() => this.setState({ showContactsMobile: false })}
                                                        />

                                                    </div>
                                                </>
                                        }

                                        <div className="form-group col-md-6">
                                            <label> <Translate text={'make'} /></label>
                                            <ReactSelect
                                                options={makes}
                                                name={"make"}
                                                placeholder={'select vehicle make'}
                                                onChange={this.handleMakeChange}
                                                value={make}
                                                classNamePrefix={`cursor-pointer ${errors["make"]} basic-select`}
                                                AddUnAvailable={true}
                                                menuPlacement='bottom'
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label><Translate text={'group'} /></label>
                                            <ReactSelect
                                                options={groups}
                                                name={"group"}
                                                placeholder={'select group'}
                                                onChange={this.handleGroupChange}
                                                value={group}
                                                classNamePrefix={`cursor-pointer ${errors["group"]} basic-select`}
                                                AddUnAvailable={true}
                                                menuPlacement='bottom'
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label><Translate text={'type'} /></label>
                                            <ReactSelect
                                                options={types}
                                                name={"type"}
                                                placeholder={'select type'}
                                                onChange={this.handleTypeChange}
                                                value={type}
                                                classNamePrefix={`cursor-pointer ${errors["type"]} basic-select`}
                                                AddUnAvailable={true}
                                                menuPlacement='bottom'
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label> <Translate text={'model'} /></label>
                                            <ReactSelect
                                                options={models}
                                                name={"model"}
                                                placeholder={'select vehicle model'}
                                                onChange={this.handleReactSelectChange}
                                                value={model}
                                                classNamePrefix={`cursor-pointer ${errors["model"]} basic-select`}
                                                AddUnAvailable={true}
                                                menuPlacement='bottom'
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'year'} /></label>
                                            <ReactSelect
                                                options={years}
                                                name={"year"}
                                                placeholder={'select vehicle year'}
                                                onChange={this.handleReactSelectChange}
                                                value={year}
                                                classNamePrefix={`cursor-pointer ${errors["year"]} basic-select`}
                                                menuPlacement='bottom'
                                            >
                                            </ReactSelect>
                                        </div>
                                        {
                                            enableServicePackages
                                                ?
                                                <></>
                                                :
                                                <>
                                                    <div className="form-group col-md-6">
                                                        <label ><Translate text={'saleType'} /></label>
                                                        <ReactSelect
                                                            options={sale_type}
                                                            name={"saleType"}
                                                            placeholder={'select vehicle sale type'}
                                                            onChange={this.handleReactSelectChange}
                                                            value={saleType}
                                                            classNamePrefix={`cursor-pointer ${errors["saleType"]} basic-select`}
                                                            menuPlacement='bottom'
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                        }
                                        <div className="form-group col-md-6">
                                            <label ><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'enquiryType')} /></label>
                                            <ReactSelect
                                                options={enquiryTypes}
                                                name={"enquiryType"}
                                                placeholder={'select enquiry type'}
                                                onChange={this.handleReactSelectChange}
                                                value={enquiryType}
                                                classNamePrefix={`cursor-pointer ${this.state.errors["enquiryType"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'origin')} /></label>
                                            <ReactSelect
                                                options={origins}
                                                name={"origin"}
                                                placeholder={'select origin'}
                                                onChange={this.handleReactSelectChange}
                                                value={origin}
                                                classNamePrefix={`cursor-pointer ${this.state.errors["origin"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'campaign')} /></label>
                                            <ReactSelect
                                                options={campaigns}
                                                name={"campaign"}
                                                placeholder={'select campaign'}
                                                onChange={this.handleReactSelectChange}
                                                value={campaign}
                                                classNamePrefix={`cursor-pointer ${this.state.errors["campaign"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label ><Translate text={'notes'} /></label>
                                        <InputTextArea
                                            className={`form-control notes ${this.state.errors["notes"]}`}
                                            name="notes"
                                            onChange={this.handleOnAreaChange}
                                            value={notes}
                                            maxLength="280"
                                            rows="3">
                                        </InputTextArea>
                                    </div>
                                </div>

                                {
                                    (user || (!_.isEmpty(contact)))
                                        ?
                                        <>
                                            <ContactActivityView
                                                contactID={contact ? contact.documentID : null}
                                                userID={user ? user : null}
                                                dealersettings={this.props.dealersettings}
                                                clientUsers={this.props.clientUsers}
                                                groupUsers={this.props.groupUsers}
                                            >
                                            </ContactActivityView>
                                        </>
                                        :
                                        <>
                                            <div className="engage-contact-middle">
                                                <div className="engage-activity-item">
                                                    <div className="common-table">
                                                        <div className="text-center p-5">
                                                            <p><Translate text={'noActivitiesMsg'} />.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose() }}><Translate text={'cancel'} /></button>
                        <button type="button"
                            className={`btn btn-primary float-right`}
                            onClick={(e) => this.handleSubmit(e)}
                        >
                            {this.isLoading ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'sendEngage'} /></button>

                    </Modal.Footer>


                </Modal>

                <PopUpModal show={showContacts}>
                    <Contacts
                        show={showContacts}
                        dealersettings={this.props.dealersettings}
                        clientUsers={this.props.clientUsers}
                        groupUsers={this.props.groupUsers}
                        handleContactClose={this.handleContactClose}
                        showAdd={false}
                    />
                </PopUpModal>

                <PopUpModal show={contactModal.showContact}>
                    <AddContact
                        docID={contactModal.docID}
                        show={contactModal.showContact}
                        clsActive={contactModal.clsContactActive}
                        handleClose={this.handleContactClose}
                        title={contactModal.contactTitle}
                        showSearch={contactModal.showContactSearch}
                        mandatoryFields={contactModal.mandatoryFields}
                        dealersettings={this.props.dealersettings}
                        clientUsers={this.props.clientUsers}
                        groupUsers={this.props.groupUsers}
                    >
                    </AddContact>
                </PopUpModal>

                <PopUpModal show={showUsers}>
                    <Users
                        show={showUsers}
                        clientUsers={this.props.clientUsers.sort(function (x, y) { return (x.queueNum < y.queueNum ? -1 : 1); })}
                        dealersettings={this.props.dealersettings}
                        handleUserClose={this.handleStaffClose}
                        title='users'
                        showAdd={false}
                        showQueue={true}
                    />
                </PopUpModal>

            </>


        );
    }
}

