export const objService = {
    documentID: null,
    clientID: null,
    projectId: null,
    contactID: null,
    displayID: null,
    loanAppointmentID: null,
    pipeline: '',
    stage: '',
    serviceType: 'external',
    appointmentType: '',
    serviceAdvisor: '',
    technician: '',
    partsInterpretor: '',
    workshopManager: '',
    technicians: [],
    roNumber: '',
    status: '',
    technicianStatus: '',
    contact: null,
    vehicle: null,
    checkInDate: null,
    completionDate: null,
    appointmentDate: null,
    slot: null,
    timeOfDay: null,
    stageDate: null,
    isServicePackage: false,
    isContact: false,
    isVehicle: false,
    isTradeIn: false,
    isInspection: false,
    isChecklist: false,
    isBbtChecklist: false,
    isFiles: false,
    isFav: false,
    isLoan: false,
    totalServiceCost: null,
    totalDeclinedCost: null,
    stageHistory: [],
    statusHistory: [],
    parts: [],
    images: [],
    videos: [],
    marks: [],
    //checklist: [],
    //servicePackages: [],
    teammates: [],
    mentions: [],
    teams: [],
    //notes: [],
    owner: '',
    checkInBy: '',
    addedBy: '',
    modifiedBy: '',
    addedDate: null,
    modifiedDate: null,
    modifiedFrom: 'web',
    addedFrom: 'web',
    isDeleted: false,

    taxType: null,
    taxPercentage: null,

    discount: null,
    discountType: '',

    spokeTo: '',
    ownerResponse: null,
    responseDate: null,
    jobAllocated: false,
    jobConfirmed: false,
    clientContactDate: null,
    partsConfirmed: false,
    workshopConfirmed: false,

    disclaimer: null,
    userSign: null,
    quoteSentDate: null,
    startDate: null,
    completeDate: null,

    internalTaxExemption: false,
    nextService: null,

};

export const objServiceBasicVM = {
    documentID: null,
    clientID: null,
    projectId: null,
    contactID: null,
    displayID: null,
    loanAppointmentID: null,
    pipeline: '',
    stage: '',
    serviceType: 'external',
    appointmentType: '',
    serviceAdvisor: '',
    technician: '',
    partsInterpretor: '',
    workshopManager: '',
    roNumber: '',
    status: '',
    technicianStatus: '',
    contact: null,
    vehicle: null,
    checkInDate: null,

    appointmentDate: null,
    slot: null,

    totalServiceCost: null,
    totalDeclinedCost: null,

    owner: '',
    addedBy: '',
    addedDate: null,
    isDeleted: false,

};

// export const service_Types = [
//     { active: true, value: 'external', label: "External" },
//     { active: true, value: 'internal', label: 'Internal' },
// ]
export const techList = [
    { active: true, value: 'Secondary', label: 'Secondary' },
    { active: true, value: 'Other', label: 'Other' },
]

export const partQuotes = [
    { active: true, value: 'customer', label: 'Customer' },
    { active: true, value: 'warranty', label: 'Warranty' },
    { active: true, value: 'includedInPackage', label: 'Included In Package' }
]


export const objSRVehicle = {
    make: '',
    type: '',
    group: '',
    model: '',
    year: '',
    odometer: '',
    vinNo: '',
    regNo: '',
    rego: '',
    extColor: '',
    warrantyStartDate: null,
    warrantyExp: null,
}
export const mandatoryVehicleFields = [
    'make',
    'model'
];

export const objServicePackage = {
    documentID: null,
    name: '',
    value: '',
    make: null,
    model: null,
    price: null,
    description: '',
    subList: [],
    sortOrder: 0
}

export const objSlot = {
    name: '',
    value: '',
    from: '',
    to: '',
    description: '',
    isExpress: false,
    halfanHrSlot: false,
    color: '',
    total: null
}

export const objRecommendation = {
    documentID: null,
    serviceID: null,
    type: '',
    reference: '',
    category: '',
    name: '',
    choice: '',
    items: [],
    detail: null,
}
export const objRecommendationSubDetail = {
    faultReports: '',
    oilMiscSub: '',
    option: '',
    status: '',
    images: []
}
export const objRecommendationDetail = {
    option: '',
    faultReports: '',
    oilMiscSub: '',
    status: '',
    totalLabourCost: 0,
    allocationJobTime: 0,
    labourCost: 0,
    oKToDo: null,
    images: [],
    parts: [],
    reason: '',
    recomStatus: '',
}
export const objPart = {
    documentID: '',
    partNumber: '',
    partName: '',
    partStatus: '',
    partCost: 0,
    totalCost: 0,
    quantity: 0,
    comments: '',
    quote: '',
    priceLevelCode: 0
}
export const objPartVM = {
    documentID: '',
    partNumber: '',
    partName: '',
    partCost: '',
    make: null,
    model: null,
    level: '',
    oemID: '',
    addedBy: '',
    renderType: '',
    active: false,
    isDeleted: false
}
export const objChecklist = {
    id: '',
    title: '',
    items: [],
}
export const objChecklistItem = {
    id: '',
    title: '',
    choice: ''
}

export const objModules = {
    contact: true,
    files: true,
    vehicle: true,
    packages: true,

};

export const objMark = {
    dx: 0,
    dy: 0,
    markType: '',
    notes: '',
    price: 0,
    currency: '',
    images: []
};

export const mandatoryMark = [
    'dx',
    'dy',
    'markType'
];


export const objVideos = {
    documentID: null,
    clientID: null,
    projectId: null,
    recordID: null,
    name: '',
    url: '',
    type: '',
    size: 0,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};

export const objWorkLog = {
    documentID: '',
    clientID: null,
    projectId: null,
    serviceID: null,
    description: '',
    timeSpent: 0,
    active: false,
    mannual: false,
    startTime: null,
    modifiedDate: null,
    addedBy: '',
    modifiedBy: '',
    addedFrom: 'web',
}


export const mandatoryVideo = ['url']

export const objServiceListVM = {
    documentID: null,
    displayID: null,
    pipeline: '',
    stage: '',
    status: '',
    technicianStatus: '',
    stageDate: null,
    owner: '',
    createdOn: '',
    updatedOn: '',
    addedby: '',
    modifiedby: '',
    modifiedFrom: 'web',
    isFav: false,

    serviceType: 'external',
    appointmentType: '',
    serviceAdvisor: '',
    technician: '',
    checkInDate: null,
    completionDate: null,
    appointmentDate: null,
    slot: null,
    timeOfDay: null,
    totalServiceCost: null,
    totalDeclinedCost: null,
    checkInBy: '',

    spokeTo: '',
    ownerResponse: '',
    jobAllocated: false,
    jobConfirmed: false,
    clientContactDate: null,
    discount: null,
    discountType: '',
    partsConfirmed: false,
    workshopConfirmed: false,

    contactName: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    nationality: '',
    address: '',
    phone: '',
    email: '',
    companyName: '',
    designation: '',
    contactMethod: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseType: '',
    licenseState: '',
    interests: [],
    origin: '',
    campaign: '',
    optinPhone: true,
    optinEmail: true,
    optinPost: true,
    optinSMS: true,
    marketingOptinEmail: true,
    marketingOptinSMS: true,
    dependents: '',
    driverName: '',
    secondaryName: '',
    secondaryPhone: '',
    secondaryEmail: '',

    vehicleModel: '',
    vehicleRegNo: '',
    vehicleVinNo: '',
    vehicleYear: '',
    nextService: null,

};


export const allserviceFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Favourite',
        value: 'documentID',
        subText: 'isFav',
        width: 80,
        default: true,
        type: 'favorite',
        flex: 0,
        align: 'center'
    },
    {
        name: 'Service Number',
        value: 'displayID',
        flex: 1,
        default: true,
    },
    {
        name: 'Name',
        value: 'contactName',
        flex: 2,
        default: true,
        subText: 'contactNumber',
        subTextTitle: 'Contact ID',
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone'
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleYear',
        subTextTitle: 'Vehicle Year'

    },
    {
        name: 'Appointment Date',
        value: 'appointmentDate',
        flex: 1,
        default: true,
    },
    {
        name: 'Appointment Slot',
        value: 'slot',
        flex: 1,
        default: true,
        elementName: 'slotName'
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        default: true,
        elementName: 'statusName'
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1,
        default: true,
    },
    {
        name: 'Technician Status',
        value: 'technicianStatus',
        flex: 1,
        elementName: 'technicianStatusName'
    },
    {
        name: 'Stage Date',
        value: 'stageDate',
        flex: 1,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Last Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'CheckIn On',
        value: 'checkInDate',
        flex: 1,
        subText: 'checkInBy',
        subTextTitle: 'checkIn By'
    },
    {
        name: 'Service Advisor',
        value: 'serviceAdvisor',
        flex: 1,
    },
    {
        name: 'Technician',
        value: 'technician',
        flex: 1,
    },
    {
        name: 'Parts Interpretor',
        value: 'partsInterpretor',
        flex: 1,
    },
    {
        name: 'Workshop Manager',
        value: 'workshopManager',
        flex: 1,
    },
    {
        name: 'Pipeline',
        value: 'pipeline',
        flex: 1,
    },
    {
        name: 'Stage',
        value: 'stage',
        flex: 1,
    },
    {
        name: 'Job Allocated',
        value: 'jobAllocated',
        flex: 1,
    },
    {
        name: 'Job Confirmed',
        value: 'jobConfirmed',
        flex: 1,
    },
    {
        name: 'Parts Confirmed',
        value: 'partsConfirmed',
        flex: 1,
    },
    {
        name: 'Workshop Confirmed',
        value: 'workshopConfirmed',
        flex: 1,
    },
    {
        name: 'Service Type',
        value: 'serviceType',
        flex: 1,
    },
    {
        name: 'Appointment Type',
        value: 'appointmentType',
        flex: 1,
    },
    {
        name: 'Completion Date',
        value: 'completionDate',
        flex: 1
    },
    {
        name: 'Next Service Due Date',
        value: 'nextService',
        flex: 1
    },
    {
        name: 'Unattended Leads',
        value: 'rottenDays',
        flex: 1,
    },
    {
        name: 'Service Age',
        value: 'serviceAge',
        flex: 1,
    },
    {
        name: 'Spoke To',
        value: 'spokeTo',
        flex: 1,
    },
    {
        name: 'Client Contact Date',
        value: 'clientContactDate',
        flex: 1,
    },
    {
        name: 'Vehicle VIN No.',
        value: 'vehicleVinNo',
        flex: 1,
    },
    {
        name: 'Vehicle Reg No.',
        value: 'vehicleRegNo',
        flex: 1,
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2
    },
    {
        name: 'Company',
        value: 'companyName',
        flex: 1
    },
    {
        name: 'Designation',
        value: 'designation',
        flex: 1
    },
    {
        name: 'Birthday',
        value: 'dob',
        flex: 1
    },
    {
        name: 'Gender',
        value: 'gender',
        flex: 1
    },
    {
        name: 'Campaign',
        value: 'campaign',
        flex: 1,
    },
    {
        name: 'Origin',
        value: 'origin',
        flex: 1
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        flex: 1
    },
    {
        name: 'Nationality',
        value: 'nationality',
        flex: 1
    },
    {
        name: 'Language',
        value: 'language',
        flex: 1
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        flex: 1
    },
    {
        name: 'Hobbies',
        value: 'interests',
        flex: 1
    },
    {
        name: 'Dependents',
        value: 'dependents',
        flex: 1
    },
    {
        name: 'Driver Name',
        value: 'driverName',
        flex: 1
    },
    {
        name: 'Phone Opt In',
        value: 'optinPhone',
        flex: 1
    },
    {
        name: 'SMS Opt In',
        value: 'optinSMS',
        flex: 1
    },
    {
        name: 'Email Opt In',
        value: 'optinEmail',
        flex: 1
    },
    {
        name: 'Post Opt In',
        value: 'optinPost',
        flex: 1
    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmail',
        flex: 1,
    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMS',
        flex: 1,
    },
    {
        name: 'Secondary Name',
        value: 'secondaryName',
        flex: 1
    },
    {
        name: 'Secondary Phone',
        value: 'secondaryPhone',
        flex: 1
    },
    {
        name: 'Secondary Email',
        value: 'secondaryEmail',
        flex: 1
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allCancelledFieldsVM = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        group: 'Contact',
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        flex: 1,
        default: true
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleYear',
        subTextTitle: 'Vehicle Year'

    },
    {
        name: 'Appointment Date',
        value: 'appointmentDate',
        flex: 1,
        default: true
    },
    {
        name: 'Created By',
        value: 'addedByName',
        flex: 1,
        default: true,
        subText: 'createdOn'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]




