import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Modal } from 'react-bootstrap';
import { default as _images } from '../../images';
import toast from 'toasted-notes';
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';

const AddProvider = ({
	show,
	handleClose,
	contact,
	dealersettings,
	enquiryBasicVM: { documentID },
	onFinanceCollectionUpdate
}) => {
	const [providers, setProviders] = useState([]);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const fetchProviders = async () => {
			const ObjectToSend = {
				AMSProInqId: documentID,
				DealerCode: dealersettings.client.id,
				AMSProCustomerId: contact.documentID,
			};

			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(ObjectToSend),
			};

			const response = await fetch(
				dealersettings.financeURL + 'api/AMSProFinance/Action/FinanceUsers',
				requestOptions,
			);

			const respjson = await response.json();
			//console.log('respjson', respjson);
			if (respjson.success) {
				setProviders(respjson.data);
			}
		};

		fetchProviders();

		// console.log('add Provder use Effect ran', {
		// 	AMSProInqId: documentID,
		// 	DealerCode: dealersettings.client.id,
		// 	RequestBy: dealersettings.name,
		// 	AMSProCustomerId: contact.documentID,
		// });
	}, []);

	const handleSubmit = async () => {
		let selectedProviders = providers
			.filter(provider => provider.Checked && provider.Checked === true)
			.map(provider => provider.Id);

		if (selectedProviders.length > 0) {
			setLoader(true)
			const ObjectToSend = {
				InquiryId: 0,
				// ThirdPartyIds: [1, 2],
				ThirdPartyIds: selectedProviders,
				DealerUrl: '',
				AMSProInqId: documentID,
				DealerCode: dealersettings.client.id,
				RequestBy: dealersettings.name,
			};

			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(ObjectToSend),
			};

			const response = await fetch(
				dealersettings.financeURL + 'api/AMSProFinance/Action/SaveFinanceRequest',
				requestOptions,
			);

			const respjson = await response.json();

			if (respjson.success) {
				// console.log(respjson);
				toast.notify('Finance Request Sent Successfully', {
					duration: 2000,
				});
				onFinanceCollectionUpdate()
				const enquiryRef = firestoreDB(dealersettings).firestore().doc(`enquiries/${documentID}`);
				await enquiryRef.update({
					isFinanceRequestSent: true,
					financeStatus: 'Pending'
				})

				setLoader(false)
				handleClose();
			} else {
				toast.notify('Finance Request Not Saved', {
					duration: 2000,
				});
				handleClose();
			}
		} else {
			let isFinanceSentToAll;
			providers.forEach(provider => {
				isFinanceSentToAll = false
				if (provider.Status) {
					isFinanceSentToAll = true
				}
			})
			if (isFinanceSentToAll) {
				toast.notify('Already requested available providers', {
					duration: 2000,
				});
				handleClose()
			} else {
				toast.notify('No provider selected', {
					duration: 2000,
				});
			}
		}
	};

	const handleChange = (e, checked, id) => {
		let selectedProvider = providers.filter(provider => provider.Id === id);

		if (selectedProvider[0].Status) {
			// console.log('already sent request to it');
			return;
		}

		let otherSelectedProviders = providers.filter(
			provider => provider.Id !== id,
		);

		if (checked === true) {
			selectedProvider[0].Checked = false;
		} else {
			selectedProvider[0].Checked = true;
		}

		const newArray = [...otherSelectedProviders, selectedProvider[0]];
		// console.log(newArray);
		setProviders(newArray);
	};

	return (
		<Modal
			id='add-cafe'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-add-finance'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title' >
						<Translate text={'Finance Providers'} />
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className=' pop-layout form-style'>
							<div className='tabs-wraper tabs finance-tab-fix'>
								<div className='tab-content' id='nav-tabContent2'>
									<div
										className='tab-pane fade show active'
										id='nav-1'
										role='tabpanel'
										aria-labelledby='nav-1-tab'
									>
										{providers.length > 0 &&
											providers.map(provider => (
												<div
													className='finance-provider-loop'
													key={provider.Id}
												>
													<table
														width='100%'
														cellSpacing='0'
														cellPadding='0'
														border='0'
													>
														<tbody>
															<tr>
																<td width='150'>
																	<img
																		src={provider.ImageUrl ? provider.ImageUrl : _images.nofinance}
																		width='130'
																		height='60'
																		alt='finance provider'
																	/>
																</td>
																<td>
																	<div>{provider.UserName}</div>
																	<div className='provider-contact'>
																		{' '}
																		<span className='fnc-contact'>
																			<i className='ico icon-call'></i>{' '}
																			{provider.Mobile}
																		</span>{' '}
																		<span className='fnc-address'>
																			<i className='ico icon-email'></i>{' '}
																			{provider.EmailAddress}
																		</span>{' '}
																	</div>
																</td>
																<td>
																	<div className='checkbox icheck-success float-right'>
																		<input
																			type='checkbox'
																			checked={
																				provider.Checked || provider.Status
																			}
																			onChange={e =>
																				handleChange(
																					e,
																					provider.Checked,
																					provider.Id,
																				)
																			}
																			id={`finance-provider-${provider.Id}`}
																		/>
																		<label htmlFor={`finance-provider-${provider.Id}`}></label>
																	</div>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className='btn btn-primary float-right'
					onClick={e => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					{
						loader ?
							(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
							: (<></>)
					}
					<Translate text={'send'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddProvider;
