/** LIBRARIES */
import React, { Component, Suspense,lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */
//const VideoDetails = lazy(() => import('../details'));
import VideoDetails from '../details';


class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            // <main role="main" className="container-fluid  pl-0 pr-0">

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/video/details/:id" exact render={(match) => <VideoDetails videoid={match.match.params.id} />} />

                    </Switch>
                </Suspense>

            // </main>
        );
    }
}

export default MainContainer;