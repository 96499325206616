/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash';
/** COMPONENTS */
import { ReactSelect, InputText, AutoComplete, DropDownMenu } from '../../../components'
import { CustomToggle, CustomSearchMenu } from '../../../components/customdropdown';
import { default as _images } from "../../../images";
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
/** VIEW-MODELS */
import { objCompany } from '../viewModel'
import { firestoreDB } from '../../../services/helper';


//#region  ASSIGN VARIABLES
let company_type = [];
// const company_type = [
//     { value: 'Private', label: 'Private' },
//     { value: 'Public', label: 'Public' }
// ]
let defaultCountry = '';
const phoneList = [
    { active: true, value: 'Home', label: <Translate text={'home'} /> },
    { active: true, value: 'Work', label: <Translate text={'Work'} /> },
    { active: true, value: 'Other', label: <Translate text={'Other'} /> },
]
const emailList = [
    { active: true, value: 'Primary', label: <Translate text={'primary'} /> },
    { active: true, value: 'Secondary', label: <Translate text={'Secondary'} /> },
    { active: true, value: 'Other', label: <Translate text={'Other'} /> },
]
//#endregion


class AddCompany extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.mandatoryFields = '';
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('companies');
        this.unsubscribeCOM = null;
        this.selectedClientID = null;
        this.selectedGroupID = null;
        this.state = {
            fields: Object.assign({}, objCompany),
            errors: {},
            companys: [],
            clientUsers: [],
            phoneCodes: [],
            countries: [],
            states: []
        }

    }


    //#region PAGE LOAD
    onCollectionUpdate = (querySnapshot) => {
        //console.log('onCollectionUpdate');
        const companys = [];
        querySnapshot.forEach((doc) => {
            const { name } = doc.data();
            companys.push({
                value: doc.id,
                active: _.isBoolean(doc.active) ? doc.active : true,
                doc, // DocumentSnapshot                
                label: name
            });
        });
        this.setState({
            companys: companys
        });
    }

    componentDidMount() {
        //console.log('componentDidMount- COMPANY', this.props)
        const { companyName, dealersettings, selectedClientID } = this.props;
        let fields = Object.assign({}, objCompany);
        const states = [];
        if (this.props.show === true && this.props.docID) {
            const refData = firestoreDB(this.props.dealersettings).firestore().collection('companies').doc(this.props.docID);
            refData.get().then((doc) => {
                if (doc.exists) {
                    const objData = Object.assign({}, doc.data());
                    objData.documentID = doc.id;

                    for (let [key, value] of Object.entries(objData)) {
                        fields[key] = value;
                    }

                    if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
                        fields.country && dealersettings.states &&
                            dealersettings.states.filter(e => e.country_code === fields.country).forEach((doc) => {
                                states.push({
                                    value: doc.code,
                                    active: _.isBoolean(doc.active) ? doc.active : true,
                                    label: doc.name
                                });
                            });
                    }

                    this.setState({ fields: fields, states: states }, () => { this.loadSettings(); });

                } else {
                    console.error("No such company document!");
                }
            });
        }
        else if (this.props.show === true) {
            fields.name = companyName ? companyName : '';
            fields.owner = dealersettings ? dealersettings.id : '';
            //fields.clientID = dealersettings && dealersettings.client ? dealersettings.client.id : '';
            fields = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(fields, dealersettings, selectedClientID, true);

            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
                let countryCode = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
                let phone_code = dealersettings.countries.filter(m => m.code === countryCode)[0];
                if (!_.isEmpty(phone_code)) {
                    fields.phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
                }
            }
            // if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client))
            //     fields.country = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : ''

            this.setState({ fields: fields }, () => { this.loadSettings(); });
        }

        let unsubscribeCOM = this.ref
        unsubscribeCOM = CommonHelper.whereClientOrGroupOrRegionOrOemClause(unsubscribeCOM, dealersettings);
        this.unsubscribeCOM = unsubscribeCOM.where('isDeleted', '==', false).limit(100)
            .onSnapshot(this.onCollectionUpdate);


    }

    loadSettings = async () => {
        const { dealersettings, clientUsers, groupUsers } = this.props;
        const { fields } = this.state;
        const countries = []; const states = []; const phoneCodes = [];

        this.selectedClientID = fields && fields.clientID ? fields.clientID : dealersettings.client.id;
        this.selectedGroupID = fields && fields.groupID ? fields.groupID : dealersettings.client.group;

        // let _clientData = this.selectedClientID && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.group) && !_.isEmpty(dealersettings.group.clients)
        //     && !_.isEmpty(dealersettings.group.clients[this.selectedClientID]) ? dealersettings.group.clients[this.selectedClientID] : dealersettings.client;

        let setttings = dealersettings.client.settings;
        let _currentSettings = null;
        if (this.selectedClientID && this.selectedClientID !== dealersettings.client.id) {
            _currentSettings = await CommonHelper.updateClientSettings(this.selectedClientID, Object.assign({}, dealersettings));
            if (!_.isEmpty(_currentSettings?.client?.settings)) setttings = _currentSettings.client.settings;
        }

        company_type = [];
        setttings?.companyType && setttings.companyType.forEach(doc => {
            company_type.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name,
            });
        });

        const _clientData = _currentSettings?.client ? _currentSettings.client : dealersettings.client;
        defaultCountry = _clientData?.countryCode ? _clientData.countryCode.toUpperCase() : 'AU';

        dealersettings.countries && dealersettings.countries.forEach((doc) => {
            if (doc.phone_code) {
                phoneCodes.push({
                    country_code: doc.code,
                    value: doc.phone_code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                });
            }

            countries.push({
                value: doc.code,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        if (fields.country) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === fields.country).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }

        if (!_.isEmpty(setttings?.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.company))
            this.mandatoryFields = setttings.mandatoryFields.company;


        let _clientUsers = [];
        let _allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        if (this.selectedClientID && !_.isEmpty(groupUsers)) {
            _clientUsers = groupUsers.filter(e => e.clientID === this.selectedClientID);
        }
        else if (!_.isEmpty(_allUsers)) {
            _.uniq(_allUsers.map(v => _.pick(v, ['id']).id)).map(e => { return { ..._.find(_allUsers, { id: e }) } }).forEach((rec) => {
                _clientUsers.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                });
            });
        }

        this.setState({
            clientUsers: _clientUsers,
            countries: countries,
            phoneCodes: phoneCodes,
            //states: states
        });
    }

    componentWillUnmount() {
        this.unsubscribeCOM && this.unsubscribeCOM();
    }

    //#endregion

    //#region CHANGE EVENT

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);

        if (name === 'email' || name === 'website') {
            fields[name] = value;
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str;
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }

    handleOnNameChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);

        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = _.trim(value);
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase().replace(/[@!|~&\/\\#,+()$~%.'":*?<>{}-]/g, '') + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {

                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0).replace(/[@!|~&\/\\#,+()$~%.'":*?<>{}-]/g, '');
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str ? str.replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }

    onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9 \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            state[e.target.name] = _.trim(value);
            this.setState({ fields: state }, () => { this.errorChange(name) });
        }
    };

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {

            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && !_.isEmpty(this.mandatoryFields) && this.mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' && fields[key].length > 1) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }
    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        if (e) {
            if (data.name === 'associatedCompany')
                state[data.name] = { 'documentID': e.value, 'name': e.label }
            else
                state[data.name] = e.value;
        }
        else {
            if (data.name === 'associatedCompany')
                state[data.name] = {}
            else
                state[data.name] = '';
        }

        if (data.name === 'country') {
            if (e)
                this.handleCountryChange(e.value);
            else
                this.handleCountryChange('');
            state['state'] = '';
        }

        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    handleCountryChange = make => {
        const { dealersettings } = this.props;
        const states = [];
        if (make && !_.isEmpty(dealersettings)) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === make).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        this.setState({ states: states });
    }
    //#endregion

    //#region  LOCATION AUTOCOMPLETE SEARCH
    bindAddress = (place) => {
        let state = Object.assign({}, this.state.fields);
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };

        if (place.formatted_address !== null)
            state['address'] = place.formatted_address;

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];

                if (addressType === "postal_code")
                    state['areaCode'] = val;

                if (addressType === "administrative_area_level_1")
                    state['state'] = val;

                if (addressType === "country") {
                    state['country'] = val;
                    this.handleCountryChange(val);
                }

                if (addressType === "street_number") {
                    //var street_number = val;
                }

                if (addressType === "route") {
                    //var sublocality_level_1 = val;
                }
            }
        }

        this.setState({ fields: state }, () => {
            this.errorChange('address');
            this.errorChange('areaCode');
            this.errorChange('state');
            this.errorChange('country');
        });

    };
    //#endregion

    //#region  ADDTIONAL PHONE & EMAIL
    handleAddPhoneCode = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = value ? value : '';
        this.setState({ fields: state });
    };
    handlePhoneChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9+ \b]+$/;
        const newPhone = !_.isEmpty(state.phones) && state.phones.map((data, sidx) => {
            if (idx !== sidx) return data;

            let telephone = evt.target.value;
            // if value is not blank, then test the regex
            if (telephone === '' || regexp.test(telephone)) {
                return { ...data, value: evt.target.value };
            }
            else
                return { ...data };
        });
        state['phones'] = newPhone
        this.setState({ fields: state });
    };
    handleAddPhone = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['phones'] = state.phones.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemovePhone = idx => {
        let state = Object.assign({}, this.state.fields);
        state['phones'] = state.phones.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };

    handleEmailChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        const newEmail = !_.isEmpty(state.emails) && state.emails.map((data, sidx) => {
            if (idx !== sidx) return data;
            return { ...data, value: evt.target.value };
        });
        state['emails'] = newEmail
        this.setState({ fields: state });
    };
    handleAddEmail = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveEmail = idx => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };


    //#endregion

    //#region ADD, EDIT & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (this.mandatoryFields && this.mandatoryFields.split(',').indexOf(key) >= 0) {
                if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }

                if (key === 'email' && fields[key]) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }

        if (!_.isEmpty(errors)) {
            let isChange = false;
            for (let [key] of Object.entries(errors)) {
                if (key === 'phones' || key === 'emails' || key === 'secondaryPhones' || key === 'secondaryEmails') {
                    fields[key] = fields[key].concat([{ value: "", name: 'Other' }])
                    isChange = true;
                }
            }
            if (isChange) this.setState({ fields: fields });
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            if (this.props.docID)
                this.editCompany(this);
            else
                this.addCompany(this);
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }

    };

    addCompany = (e) => {

        let objData = Object.assign({}, this.state.fields);
        this.isLoading = true;
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, this.props.dealersettings, objData.clientID, true);
        // if (!objData.clientID)
        //     objData.clientID = this.selectedClientID ? this.selectedClientID : (this.props.dealersettings ? this.props.dealersettings.client.id : '');

        // if (!objData.groupID)
        //     objData.groupID = this.selectedGroupID ? this.selectedGroupID : (this.props.dealersettings && this.props.dealersettings.client.group ? this.props.dealersettings.client.group : null);

        // if (!objData.regionID)
        //     objData.regionID = (this.props.dealersettings && this.props.dealersettings.client.region ? this.props.dealersettings.client.region : null);

        // if (!objData.oemID)
        //     objData.oemID = (this.props.dealersettings ? this.props.dealersettings.client.settingsID : null);

        //Remove empty value from object
        for (var propName in objData) {

            if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
            }

            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!objData.documentID)
            objData.documentID = this.ref.doc().id;

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('companies').doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.isLoading = false;
            toast.notify('Company added successfully.', {
                duration: 2000
            })

            if (this.props.isFilterApplied)
                this.props.updateCompany(objData);
            //REDIRECT 
            this.handleModalClose(objData.documentID, objData);

        }).catch((error) => {
            console.error("Error adding company: ", error);
        });


    };

    editCompany = (e) => {

        let objData = Object.assign({}, this.state.fields);
        this.isLoading = true;
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();

        objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, this.props.dealersettings, objData.clientID, true);

        // if (!objData.clientID)
        //     objData.clientID = this.selectedClientID ? this.selectedClientID : (this.props.dealersettings ? this.props.dealersettings.client.id : '');

        // if (!objData.groupID)
        //     objData.groupID = this.selectedGroupID ? this.selectedGroupID : (this.props.dealersettings && this.props.dealersettings.client.group ? this.props.dealersettings.client.group : null);

        // if (!objData.regionID)
        //     objData.regionID = (this.props.dealersettings && this.props.dealersettings.client.region ? this.props.dealersettings.client.region : null);

        // if (!objData.oemID)
        //     objData.oemID = (this.props.dealersettings ? this.props.dealersettings.client.settingsID : null);


        //Remove empty value from object
        for (var propName in objData) {

            if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
            }

            if (objData[propName] === undefined || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        var id = this.props.docID.toString();

        if (!objData.documentID)
            objData.documentID = id;

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('companies').doc(id);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.isLoading = false;
            toast.notify('Company updated successfully', {
                duration: 2000
            })

            if (this.props.isFilterApplied)
                this.props.updateCompany(objData);

        }).catch((error) => {
            console.error("Error updating company: ", error);
        });
        this.handleModalClose(objData.documentID, objData);
    };

    handleModalClose = (companyID, obj) => {

        this.setState({ fields: Object.assign({}, objCompany) });
        this.setState({ errors: {} });
        this.props.handleClose(companyID, obj);
    };
    //#endregion

    render() {
        //console.log('R E N D E R - C O M P A N Y ');
        const { clientID, name, phone, email, phones, emails, website, type,
            address, state, areaCode, country, phoneCode,
            companyAbnAcn, associatedCompany, owner, companyOwner, companyRMS,
            addedBy, companyACN
        } = this.state.fields;

        const { isReadOnly, show, dealersettings, selectedClientID } = this.props;
        const { phoneCodes, countries, states } = this.state;

        const isGroupDealer = ((localStorage.defaultModule !== 'oem' && clientID && !_.isEmpty(dealersettings) && clientID !== dealersettings.client.id) || localStorage.defaultModule === 'oem') ? true : false;

        const modifyContactOwner = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.modifyContactOwner) ? (isGroupDealer ? false : true) : false);


        let dealerName = '';
        if (clientID && (isGroupDealer || selectedClientID)) {
            dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);
        }

        return (
            <>

                <Modal
                    show={show}
                    onHide={this.handleModalClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-company">
                    <Modal.Header closeButton>
                        <Modal.Title><Translate text={this.props.title} /> </Modal.Title>
                        {dealerName ? <div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{dealerName}</div></div> : <></>}
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-md-12 form-style">

                                    <div className={`form-row ${(isReadOnly) ? 'form-disable' : ''}`}>

                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'companyName'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'company name'}
                                                className={`form-control ${this.state.errors["name"]}`}
                                                name="name"
                                                onChange={this.handleOnNameChange}
                                                value={name}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <DropDownMenu
                                                text='+ add more'
                                                keyVal="1001"
                                                className={`inputlink-addmore ${this.state.errors["phones"] ? 'label-error' : ''}`}
                                                tIndex="-1"
                                                menu={phoneList}
                                                handleChange={this.handleAddPhone}
                                            >
                                            </DropDownMenu>
                                            <label ><Translate text={'phone'} /></label>
                                            <div className={`input-group country-code ${this.state.errors["phone"] ? 'phone_error' : ''}`}>

                                                <div className="input-group-prepend">
                                                    <Dropdown className={`btn btn-outline-secondary`} >
                                                        <Dropdown.Toggle as={CustomToggle} >
                                                            <span id="spn-code-name" data-name={phoneCode}>
                                                                {phoneCode && phoneCodes.find(item => item.value === phoneCode)
                                                                    ? phoneCodes.find(item => item.value === phoneCode).value + ' ' :
                                                                    'Code'
                                                                }</span>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu as={CustomSearchMenu}
                                                            className="rm-pointers dropdown-menu-lg dropdown-menu-right"
                                                            ChildClass="pipeline-dropdown"
                                                            xplacement="bottom-end"
                                                        >
                                                            {
                                                                !_.isEmpty(phoneCodes) && phoneCodes.length > 0 ?
                                                                    phoneCodes.map((rec, index) => {
                                                                        return (<Dropdown.Item key={index} className={`current-pipeline ${(phoneCode && rec.value === phoneCode) ? 'active' : ''}`} onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleAddPhoneCode('phoneCode', rec.value)
                                                                        }}>
                                                                            {rec.label}
                                                                        </Dropdown.Item>)
                                                                    })
                                                                    : (<></>)
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'phone'}
                                                    pattern="[0-9]*"
                                                    className={`form-control`}
                                                    name="phone"
                                                    onChange={this.onHandleTelephoneChange}
                                                    value={phone}
                                                />
                                            </div>

                                        </div>

                                        {!_.isEmpty(phones) && phones.map((phone, idx) => (
                                            <div className="form-group col-md-6" key={idx}>
                                                <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemovePhone(idx) }}>- <Translate text={'remove'} /></a>
                                                <label >{phone.name} <Translate text={'phone'} /> </label>
                                                <InputText
                                                    className={`form-control ${this.state.errors["phones"]}`}
                                                    placeholder={`${phone.name.toLowerCase()} phone `}
                                                    id={idx}
                                                    value={phone.value}
                                                    onChange={this.handlePhoneChange(idx)}
                                                />
                                            </div>
                                        ))}

                                        <div className="form-group col-md-6">
                                            <DropDownMenu
                                                text='+ add more'
                                                keyVal="1002"
                                                className={`inputlink-addmore ${this.state.errors["emails"] ? 'label-error' : ''}`}
                                                tIndex="-1"
                                                menu={emailList}
                                                handleChange={this.handleAddEmail}
                                            >
                                            </DropDownMenu>
                                            <label ><Translate text={'email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'email'}
                                                className={`form-control ${this.state.errors["email"]}`}
                                                name="email"
                                                onChange={this.handleOnChange}
                                                value={email}
                                            />
                                        </div>

                                        {!_.isEmpty(emails) && emails.map((email, idx) => (
                                            <div className="form-group col-md-6" key={idx}>
                                                <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemoveEmail(idx) }}>- <Translate text={'remove'} /></a>
                                                <label >{email.name} <Translate text={'email'} /> </label>
                                                <InputText
                                                    className={`form-control ${this.state.errors["emails"]}`}
                                                    id={idx}
                                                    placeholder={`${email.name.toLowerCase()} email `}
                                                    value={email.value}
                                                    onChange={this.handleEmailChange(idx)}
                                                />
                                            </div>
                                        ))}

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'type'} /></label>
                                            <ReactSelect
                                                options={company_type}
                                                name={"type"}
                                                placeholder={'select type'}
                                                onChange={this.handleReactSelectChange}
                                                value={type}
                                                classNamePrefix={`${this.state.errors["type"]} basic-select`}
                                                AddUnAvailable={true}
                                            >
                                            </ReactSelect>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'website'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'website'}
                                                className={`form-control ${this.state.errors["website"]}`}
                                                name="website"
                                                onChange={this.handleOnChange}
                                                value={website}
                                            />
                                        </div>

                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'address'} /></label>
                                            <AutoComplete
                                                className={`form-control ${this.state.errors["address"]}`}
                                                bindAddress={this.bindAddress}
                                                types={['address']}
                                                value={address}
                                                placeholder={'search address'}
                                                onChange={this.handleOnChange}
                                                componentRestrictions={country ? { country: country } : { country: [] }}
                                                name="address"
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'areaCode'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'area/zip code'}
                                                className={`form-control ${this.state.errors["areaCode"]}`}
                                                id="areaCode"
                                                value={areaCode}
                                                name="areaCode"
                                                onChange={this.handleOnChange} />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'country'} /></label>
                                            <ReactSelect
                                                options={countries}
                                                name={"country"}
                                                placeholder={'select country'}
                                                onChange={this.handleReactSelectChange}
                                                value={country}
                                                classNamePrefix={`${this.state.errors["country"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'state'} /></label>
                                            <ReactSelect
                                                options={states}
                                                name={"state"}
                                                placeholder={'select state'}
                                                onChange={this.handleReactSelectChange}
                                                value={state}
                                                classNamePrefix={`${this.state.errors["state"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>



                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'ABN'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'company abn'}
                                                className={`form-control ${this.state.errors["companyAbnAcn"]}`}
                                                name="companyAbnAcn"
                                                onChange={this.handleOnChange}
                                                value={companyAbnAcn}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'ACN'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'company acn'}
                                                className={`form-control ${this.state.errors["companyACN"]}`}
                                                name="companyACN"
                                                onChange={this.handleOnChange}
                                                value={companyACN}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label >{'RMS'}</label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'company rms'}
                                                className={`form-control ${this.state.errors["companyRMS"]}`}
                                                name="companyRMS"
                                                onChange={this.handleOnChange}
                                                value={companyRMS}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'associatedCompany'} /></label>
                                            <ReactSelect
                                                options={this.state.companys}
                                                name={"associatedCompany"}
                                                placeholder={'select associated company'}
                                                onChange={this.handleReactSelectChange}
                                                value={Object.keys(associatedCompany).length > 0 ? associatedCompany.documentID : ''}
                                                classNamePrefix={`${this.state.errors["associatedCompany"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>

                                        {/* <div className="form-group col-md-6">
                                            <label ><Translate text={'companyOwner'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'company owner'}
                                                className={`form-control ${this.state.errors["companyOwner"]}`}
                                                name="companyOwner"
                                                onChange={this.handleOnChange}
                                                value={companyOwner}
                                            />
                                        </div> */}

                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'ownership'} /></label>
                                            <ReactSelect
                                                options={this.state.clientUsers}
                                                name={"owner"}
                                                placeholder={'select ownership'}
                                                onChange={this.handleReactSelectChange}
                                                value={owner ? owner : addedBy}
                                                classNamePrefix={`${this.state.errors["owner"]} basic-select`}
                                                removeClearable={true}
                                                isSearchable={false}
                                                isDisabled={modifyContactOwner ? false : true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    {
                        isReadOnly
                            ?
                            <></>
                            :
                            <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                                <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose() }}>
                                    <Translate text={'cancel'} />
                                </button>
                                <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)} >
                                    {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                    <Translate text={'save'} />
                                </button>
                            </Modal.Footer>
                    }

                </Modal>

            </>
        );
    }
}

export default AddCompany;