import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { ContentOverlay, PopUpModal, ReactSelect } from '../../../../components'
import { testdriveFieldsVM, levelOptions } from './viewModel'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
const TestDriveFields = (props) => {
    const [searchText, setSearchText] = useState('')
    const [fields, setTestDrivefields] = useState([])
    const [errorFields, setErrorFields] = useState({});

    useEffect(() => {
        if (!_.isEmpty(props.fields)) {
            var _contFields = [];
            props.fields.forEach(rec => {
                if (!_.isEmpty(rec['testdrive'])) {
                    rec['testdrive'].split(',').forEach(f => {
                        _contFields.push(f)
                    })
                }
            })
            setTestDrivefields(_.uniq(_contFields))
        }
    }, [props])

    return _.isEmpty(props.selectedLevel) ? (<></>) : (
        <div className="settings-inner-block">
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3 pr-0">
                                <ReactSelect
                                    options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={props.setLevel}
                                    value={props.selectedLevel.level}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                (!_.isEmpty(props.selectedLevel.level) && props.selectedLevel.level !== 'oem') ? (<div className="col-md-3 pr-0" id={'fieldsettings-levelid-testdrive'}>
                                    <ReactSelect
                                        options={props.selectedLevel.level === 'group' ? props.groupOptions : props.selectedLevel.level === 'region' ? props.regionOptions : props.clientOptions}
                                        name={'levelID'}
                                        placeholder={`select ${props.selectedLevel.level === 'individual' ? 'client' : props.selectedLevel.level}`}
                                        onChange={(e, data) => { setErrorFields({ ...errorFields, ['levelID']: '' }); props.setLevel(e, data) }}
                                        value={props.selectedLevel.levelID}
                                        classNamePrefix={`${errorFields['levelID']} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>) : (<></>)
                            }

                            <div className="filter-search search-icon col-md-3">
                                <input placeholder="search fields" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="common-table">
                {
                    props.loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>)
                        : (<table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="head-light" width="80%"> <Translate text={'Fields'} /></th>
                                    <th scope="col" className="head-light border-right-0 border-right-0" width="10%"> <Translate text={'Rules'} /></th>
                                    <th scope="col" className="head-light border-left-0 " width="10%">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    testdriveFieldsVM.filter(({ name, value }) =>
                                        (!_.isEmpty(searchText) ? (
                                            (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                            (!_.isEmpty(value) ? value.toLowerCase().includes(searchText.toLowerCase()) : false)
                                        ) : true)
                                    ).map((rec, index) => {
                                        return <tr key={index}>
                                            <td>{CommonHelper.showLocale(props, rec.value, rec.name)}</td>
                                            <td className="border-right-0">
                                                {
                                                    fields.some(item => item === rec.value) ?
                                                        (<a href="#" onClick={(e) => { props.setRequired(e, rec.value, 'testdrive') }}>
                                                            <div className="badge badge-pill badge-required ml-1">  <Translate text={'Required'} /></div>
                                                        </a>) :
                                                        (<></>)
                                                }
                                            </td>
                                            <td className="border-left-0">
                                                <div className="float-right">
                                                    <div className="table-more"><a href="#" className="common-button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (_.isEmpty(props.selectedLevel.levelID)) {
                                                                setErrorFields({ ...errorFields, ['levelID']: 'input_error' })
                                                                if (document.getElementById('fieldsettings-levelid-testdrive')) {
                                                                    setTimeout(function () {
                                                                        document.getElementById('fieldsettings-levelid-testdrive').scrollIntoView({
                                                                            behavior: "smooth",
                                                                            block: "center"
                                                                        });
                                                                    }, 300);
                                                                }
                                                                return
                                                            }
                                                            props.setfield({
                                                                ...rec,
                                                                'required': fields.some(item => item === rec.value),
                                                                'level': props.selectedLevel.level,
                                                                'levelID': props.selectedLevel.levelID,
                                                                'levelName': props.selectedLevel.levelName
                                                            }, 'testdrive')
                                                        }}
                                                    ><i className="ico icon-more"></i></a></div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        )
                }
            </div>
        </div>
    );
}

export default TestDriveFields;