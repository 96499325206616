import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { salesTabs, NoDataDiv, LoadingDiv } from '../constants';
import CommonHelper from '../../../../services/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { enquiryStatus, tipStatus, tradeInStatus } from '../../../../services/enum'
import { companyFields, contactFields, enquiryFields, serviceJobsFields, stockFields } from '../../../../admin/meilisearch/viewModel';
import { firestoreDB } from '../../../../services/helper';
import Translate from '../../../../constants/translate';
import { ContentOverlay, PopUpModal } from '../../../../components'

const SalesSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	handleCompanyView,
	handleOpenEditActivity,
	handleOpenStock,
	handleInBoundOpen,
	history,
	pipeline,
	enableSharedContact,
	enableSharedStock,
	getDealerName,
	modules
}) => {
	const [activeTab, setActivetab] = useState('all');

	const [enquiries, setEnquiries] = useState([]);
	const [tradeins, setTradeins] = useState([]);
	const [contactDetails, setContactDetails] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [stock, setStock] = useState([]);
	const [activities, setActivities] = useState([]);
	const [serviceJobs, setServiceJobs] = useState([]);
	const [all, setAll] = useState([]);

	const [loader, setLoader] = useState([]);
	const [checkDataLoad, setDataload] = useState(false)
	const [hasMore, setHasMoreData] = useState([])
	const pageLimit = 10;
	const [pagingLoader, setPagingloader] = useState(false)

	const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.permissions) &&
		dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

	const accessToFinanceActivity = (dealersettings?.rolePermissions?.permissions?.accessToFinanceActivity ? true : false);
	const accessToAfterMarketActivity = (dealersettings?.rolePermissions?.permissions?.accessToAfterMarketActivity ? true : false);

	const _servicePermissions = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.servicePermissions)) ? dealersettings.rolePermissions.servicePermissions : null);

	const isServiceModuleEnabled = modules.filter(module => module.type === 'service').length === 0 ? false : true
	const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
	let _salesTabs = []
	if (_servicePermissions && isServiceModuleEnabled) {
		_salesTabs = [...salesTabs]
	} else {
		_salesTabs = [...salesTabs.filter(a => a.value !== 'service')]
	}

	useEffect(() => {
		setLoader(true)
		const timeoutId = setTimeout(() => {
			if (searchText && searchText.length > 2) {
				setDataload(true)
				setEnquiries([]);
				setTradeins([]);
				setContactDetails([]);
				setCompanies([]);
				setStock([]);
				setActivities([]);
				setServiceJobs([]);
				setAll([]);
			}

			if (searchText === '') {
				setDataload(true)
			}

		}, 1000);
		return () => clearTimeout(timeoutId);
	}, [searchText]);

	useEffect(() => {
		if (!checkDataLoad && !pagingLoader) {
			return;
		}

		if (searchText.length > 0) {
			let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)
			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
			let companiesInContacts = []
			let stocksInEnquiries = []

			// enquiries
			let refEnquiries = '';
			let refEnquiries_TEMP = firestoreDB(dealersettings).firestore()
				.collection('enquiries')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('stageDate', 'desc')

			if (!_.isEmpty(excludeOrigins))
				refEnquiries_TEMP = refEnquiries_TEMP.where('origin', 'in', CommonHelper.getDataExcluded(dealersettings.client?.settings?.origins, excludeOrigins, true))

			if (!viewOtherEnquiry)
				refEnquiries_TEMP = refEnquiries_TEMP.where('owner', '==', dealersettings.id)

			if (pagingLoader && !_.isEmpty(hasMore.filter(e => e.type === 'enquiry')[0])) {
				refEnquiries = refEnquiries_TEMP
					.startAfter(hasMore.filter(e => e.type === 'enquiry')[0].lastDoc)
					.limit(pageLimit)
					.get();
			} else if (checkDataLoad) {
				refEnquiries = refEnquiries_TEMP
					.limit(pageLimit)
					.get();
			}

			// tradeins
			let refTradeins = '';
			let refTradeins_TEMP = firestoreDB(dealersettings).firestore()
				.collection('tradeins')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('modifiedDate', 'desc')


			if (pagingLoader && !_.isEmpty(hasMore.filter(e => e.type === 'tradein')[0])) {
				refTradeins = refTradeins_TEMP
					.startAfter(hasMore.filter(e => e.type === 'tradein')[0].lastDoc)
					.limit(pageLimit)
					.get();
			} else if (checkDataLoad) {
				refTradeins = refTradeins_TEMP
					.limit(pageLimit)
					.get();
			}

			// inboundLeads
			let refInboundLeads = '';
			if (pipeline.filter(item => item.value === 'LeadsBucket').length > 0) {
				let refInboundLeads_TEMP = firestoreDB(dealersettings)
					.firestore()
					.collection('inboundLeads')
					.where('clientID', '==', dealersettings.client.documentID)
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('status', '==', 0)
					.where('isDeleted', '==', false)
					.orderBy('addedDate', 'desc')

				if (pagingLoader && !_.isEmpty(hasMore.filter(e => e.type === 'inboundLeads')[0])) {
					refInboundLeads = refInboundLeads_TEMP
						.startAfter(hasMore.filter(e => e.type === 'inboundLeads')[0].lastDoc)
						.limit(pageLimit)
						.get();
				} else if (checkDataLoad) {
					refInboundLeads = refInboundLeads_TEMP
						.limit(pageLimit)
						.get();
				}

			}

			// refContactDetails
			let refContactDetails = '';
			let refContactDetails_TEMP = firestoreDB(dealersettings)
				.firestore()
				.collection('contactDetails')
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('addedDate', 'desc')

			if (enableSharedContact && dealersettings.client.group)
				refContactDetails_TEMP = refContactDetails_TEMP.where('groupID', '==', dealersettings.client.group)
			else
				refContactDetails_TEMP = refContactDetails_TEMP.where('clientID', '==', dealersettings.client.documentID)

			if (pagingLoader && !_.isEmpty(hasMore.filter(e => e.type === 'contacts')[0])) {
				refContactDetails = refContactDetails_TEMP
					.startAfter(hasMore.filter(e => e.type === 'contacts')[0].lastDoc)
					.limit(pageLimit)
					.get();
			} else if (checkDataLoad) {
				refContactDetails = refContactDetails_TEMP
					.limit(pageLimit)
					.get();
			}

			// companies
			let refCompanies = '';
			let refCompanies_TEMP = firestoreDB(dealersettings)
				.firestore()
				.collection('companies')
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('addedDate', 'desc')

			if (enableSharedContact && dealersettings.client.group)
				refCompanies_TEMP = refCompanies_TEMP.where('groupID', '==', dealersettings.client.group)
			else
				refCompanies_TEMP = refCompanies_TEMP.where('clientID', '==', dealersettings.client.documentID)

			if (pagingLoader && !_.isEmpty(hasMore.filter(e => e.type === 'company')[0])) {
				refCompanies = refCompanies_TEMP
					.startAfter(hasMore.filter(e => e.type === 'company')[0].lastDoc)
					.limit(pageLimit)
					.get();
			} else if (checkDataLoad) {
				refCompanies = refCompanies_TEMP
					.limit(pageLimit)
					.get();
			}

			// stock
			let refStock = '';
			let refStock_TEMP = firestoreDB(dealersettings)
				.firestore()
				.collection('stock')
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('modifiedDate', 'desc')

			if (enableSharedStock && dealersettings.client.group)
				refStock_TEMP = refStock_TEMP.where('groupID', '==', dealersettings.client.group)
			else
				refStock_TEMP = refStock_TEMP.where('clientID', '==', dealersettings.client.documentID)

			if (pagingLoader && !_.isEmpty(hasMore.filter(e => e.type === 'stock')[0])) {
				refStock = refStock_TEMP
					.startAfter(hasMore.filter(e => e.type === 'stock')[0].lastDoc)
					.limit(pageLimit)
					.get();
			} else if (checkDataLoad) {
				refStock = refStock_TEMP
					.limit(pageLimit)
					.get();
			}



			// activities
			let _actTypes = ['activity']
			if (accessToFinanceActivity) _actTypes.push('financeactivity')
			if (accessToAfterMarketActivity) _actTypes.push('afterMarketactivity')
			let refActivities = '';
			let refActivities_TEMP = firestoreDB(dealersettings).firestore().collection('activities')
				.where('type', 'in', _actTypes)
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('startDate', 'desc')

			if (pagingLoader && !_.isEmpty(hasMore.filter(e => e.type === 'activity')[0])) {
				refActivities = refActivities_TEMP
					.startAfter(hasMore.filter(e => e.type === 'activity')[0].lastDoc)
					.limit(pageLimit)
					.get();
			} else if (checkDataLoad) {
				refActivities = refActivities_TEMP
					.limit(pageLimit)
					.get();
			}


			// serviceJobs
			let refServiceJobs = '';
			let refServiceJobs_TEMP = firestoreDB(dealersettings).firestore().collection('serviceJobs')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('stageDate', 'desc')

			//.limit(5).get();
			if (pagingLoader && !_.isEmpty(hasMore.filter(e => e.type === 'service')[0])) {
				refServiceJobs = refServiceJobs_TEMP
					.startAfter(hasMore.filter(e => e.type === 'service')[0].lastDoc)
					.limit(pageLimit)
					.get();
			} else if (checkDataLoad) {
				refServiceJobs = refServiceJobs_TEMP
					.limit(pageLimit)
					.get();
			}


			Promise.all([
				refEnquiries,
				refInboundLeads,
				refContactDetails,
				refCompanies,
				refStock,
				refActivities,
				refServiceJobs,
				refTradeins
			])
				.then((allDocs) => {
					let _hasmore = [...hasMore]

					if (allDocs[0] && allDocs[0].docs.length === pageLimit) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'enquiry'),
						{
							type: 'enquiry',
							lastDoc: allDocs[0].docs[allDocs[0].docs.length - 1]

						}]
					}
					else if (refEnquiries) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'enquiry')];
					}


					const _enquiries = _.map(allDocs[0].docs, function (obj) {
						let enquiryObj = obj.data()
						if (!_.isEmpty(enquiryObj.requirement)) {
							if (!_.isEmpty(enquiryObj.requirement.stock) && enquiryObj.requirement.stock.documentID) {
								stocksInEnquiries.push({ ...enquiryObj.requirement.stock, renderType: 'stock', })
							}
						}
						return {
							...obj.data(),
							documentID: obj.id,
							renderType: 'enquiry',
							sortDate: obj.data().stageDate,
							isInbound: false,
						};
					});

					if (allDocs[1] && allDocs[1].docs.length === pageLimit) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'inboundLeads'),
						{
							type: 'inboundLeads',
							lastDoc: allDocs[1].docs[allDocs[1].docs.length - 1]

						}]
					}
					else if (refInboundLeads) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'inboundLeads')];
					}

					const _inbound = allDocs[1] ? _.map(allDocs[1].docs, function (obj) {
						return {
							...obj.data(),
							documentID: obj.id,
							renderType: 'enquiry',
							sortDate: obj.data().addedDate,
							isInbound: true,
						};
					}) : [];



					if (allDocs[2] && allDocs[2].docs.length === pageLimit) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'contacts'),
						{
							type: 'contacts',
							lastDoc: allDocs[2].docs[allDocs[2].docs.length - 1]

						}]
					}
					else if (refContactDetails) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'contacts')];
					}
					const allContacts = allDocs[2] ? _.map(allDocs[2].docs, function (obj) {
						let contactObj = obj.data()
						if (!_.isEmpty(contactObj.company) && contactObj.company.documentID) {
							companiesInContacts.push({ ...contactObj.company, renderType: 'company', })
						}
						return {
							...contactObj,
							documentID: obj.id,
							renderType: 'contactDetail',
							sortDate: contactObj.addedDate,
						};
					}) : [];


					if (allDocs[3] && allDocs[3].docs.length === pageLimit) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'company'),
						{
							type: 'company',
							lastDoc: allDocs[3].docs[allDocs[3].docs.length - 1]

						}]
					}
					else if (refCompanies) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'company')];
					}
					const allCompanies = allDocs[3] ? _.map(allDocs[3].docs, function (obj) {
						return {
							...obj.data(),
							documentID: obj.id,
							renderType: 'company',
							sortDate: obj.data().addedDate,
						};
					}) : [];

					if (allDocs[4] && allDocs[4].docs.length === pageLimit) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'stock'),
						{
							type: 'stock',
							lastDoc: allDocs[4].docs[allDocs[4].docs.length - 1]

						}]
					}
					else if (refStock) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'stock')];
					}
					const allStocks = allDocs[4] ? _.map(allDocs[4].docs, function (obj) {
						return {
							...obj.data(),
							documentID: obj.id,
							renderType: 'stock',
							sortDate: obj.data().addedDate,
						};
					}) : [];


					if (allDocs[5] && allDocs[5].docs.length === pageLimit) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'activity'),
						{
							type: 'activity',
							lastDoc: allDocs[5].docs[allDocs[5].docs.length - 1]

						}]
					}
					else if (refActivities) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'activity')];
					}
					const filterActivities = allDocs[5] ? _.map(allDocs[5].docs, function (obj) {
						return {
							...obj.data(),
							documentID: obj.id,
							renderType: 'activity',
							sortDate: obj.data().startDate,
						};
					}) : [];


					if (allDocs[6] && allDocs[6].docs.length === pageLimit) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'service'),
						{
							type: 'service',
							lastDoc: allDocs[6].docs[allDocs[6].docs.length - 1]

						}]
					}
					else if (refServiceJobs) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'service')];
					}
					const allServiceJobs = allDocs[6] ? _.map(allDocs[6].docs, function (obj) {
						return {
							...obj.data(),
							documentID: obj.id,
							renderType: 'service',
							sortDate: obj.data().stageDate,
							isInbound: false,
						};
					}) : [];


					if (allDocs[7] && allDocs[7].docs.length === pageLimit) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'tradein'),
						{
							type: 'tradein',
							lastDoc: allDocs[7].docs[allDocs[7].docs.length - 1]

						}]
					}
					else if (refTradeins) {
						_hasmore = [..._hasmore.filter(e => e.type !== 'tradein')];
					}

					const allTradeins = allDocs[7] ? _.map(allDocs[7].docs, function (obj) {
						return {
							...obj.data(),
							documentID: obj.id,
							renderType: 'tradein',
							sortDate: obj.data().modifiedDate,
							isInbound: false,
						};
					}) : [];


					setHasMoreData(_hasmore)
					const allEnquiries = [..._enquiries, ..._inbound]
					setEnquiries((prevEnquiries) => ([...prevEnquiries, ...allEnquiries]));
					setTradeins((prevTradeins) => ([...prevTradeins, ...allTradeins.filter(a => !_.isEmpty(a.enquiryID))]));
					setContactDetails((prevContactDetails) => [...prevContactDetails, ...allContacts]);
					setCompanies((prevCompanies) => [...prevCompanies, ...allCompanies]);
					setStock((prevStocks) => [...prevStocks, ...allStocks]);
					setActivities((prevActivities) => [...prevActivities, ...filterActivities]);

					let _all = [
						...allEnquiries,
						...allContacts,
						...allCompanies,
						...allStocks,
						...filterActivities,
						...allTradeins
					]
					if (_servicePermissions) {
						setServiceJobs((prev) => [...prev, ...allServiceJobs]);
						setAll((prev) => [
							...prev,
							..._all,
							...allServiceJobs
						]);
					} else {
						setServiceJobs([]);
						setAll((prev) => [...prev, ..._all]);
					}

					setLoader(false);
					setPagingloader(false)
					setDataload(false)
				})

		}
		else {
			setDataload(false)
			setPagingloader(false)
		}
		//return () => {
		//	dashboardPipelines && dashboardPipelines.unsubscribe();
		//};
	}, [checkDataLoad, pagingLoader]);

	const getHighlightTags = (_rec, _fields) => {
		if (_.isEmpty(_rec?._formatted)) {
			return [];
		}
		let _tags = [];
		_fields && _fields.forEach(field => {
			if (_tags.length === 2) {
				return;
			}
			let splitField = field.value.split('.');
			if (_.isString(_rec._formatted[field.value]) && _rec._formatted[field.value].includes('badge-search-yellow')) {
				_tags.push(`<span class="search-mark-yellow"><span class="search-mark-italic">${field.name}:</span> ${_rec._formatted[field.value]}</span>`)
			}
			else if (_.isArray(_rec._formatted[field.value])) {
				_rec._formatted[field.value].forEach(r => {
					if (r.value && r.value.includes('badge-search-yellow')) {
						_tags.push(`<span class="search-mark-yellow"><span class="search-mark-italic">${field.name}:</span> ${r.value}</span>`)
					}
				})

			}
			else if (_.isObject(_rec._formatted[splitField[0]])) {
				const objValue = CommonHelper.getPropByString(_rec._formatted, field.value);
				if (_.isString(objValue) && objValue.includes('badge-search-yellow')) {
					_tags.push(`<span class="search-mark-yellow"><span class="search-mark-italic">${field.name}:</span> ${objValue}</span>`)
				}
			}
		})
		return CommonHelper.arrLimit(_tags, 2);
	}

	return (
		<div
			className='dropdown-menu dropdown-menu-left'
			aria-labelledby='dropdownMenuButton'
			style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
		>
			<div className='form-style globalsearch-wrap'>
				<div className='globalsearch-nav-wraper'>
					<div className='globalsearch-nav pl-0'>
						<Tab.Container defaultActiveKey={activeTab}>
							<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
								{_salesTabs.map((rec, index) => {
									return (
										<OverlayTrigger
											key={index}
											placement='bottom'
											overlay={
												<Tooltip><span>{rec.title}</span></Tooltip>
											}
										>
											<Nav.Item
												as='li'
												bsPrefix='li-nav'
												key={index}
											>
												<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
													<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.ico, true, rec.icoVehicle)}></i>
													{rec.name}
												</Nav.Link>
											</Nav.Item>
										</OverlayTrigger>
									);
								})}
							</Nav>
						</Tab.Container>
					</div>
				</div>
				<div className='globalsearch-body'>
					{loader ? (
						<LoadingDiv />
					) : (
						<div className='global-item-wraper'>
							{activeTab === 'all' ? (
								<>
									{all.length > 0 ? (
										<>
											{all
												// _.orderBy(all, ['sortDate.seconds'], ['desc'])
												.map((obj, index) => {
													return <Fragment key={`${obj.renderType}-${obj.documentID}-${index}`}>
														{
															obj.renderType === 'enquiry' ? (
																<>
																	{obj.isInbound ? (
																		<a
																			className='global-item d-flex'
																			onClick={(e) => {
																				e.preventDefault();
																				handleInBoundOpen(obj)
																				handleClose();
																			}}
																			key={`${obj.renderType}-${obj.documentID}-${index}-inbound`}
																		>
																			<div className='global-space'>
																				<div className='global-icon-circle'>
																					<i className='ico icon-enquiry'></i>
																				</div>
																			</div>
																			<div>
																				{(!_.isEmpty(obj.requirement) && (obj.requirement.make || obj.requirement.stock)) ? (
																					<>
																						{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																							<span className='global-header'>
																								{' '}
																								{`${obj.requirement
																									? obj.requirement.stock.make
																									: ''
																									} ${obj.requirement
																										? obj.requirement.stock.model
																										: ''
																									}`}{' '}
																							</span>
																						) : (
																							<span className='global-header'>
																								{' '}
																								{`${obj.requirement
																									? obj.requirement.make
																									: ''
																									} ${obj.requirement
																										? obj.requirement.model
																										: ''
																									}`}{' '}
																							</span>
																						)}
																					</>
																				) : (
																					<></>
																				)}
																				<span className='global-info'>
																					{' '}
																					{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																					{obj.displayID ? ` - ${obj.displayID}` : ''}
																				</span>
																				<div className='global-status'>
																					<span
																						className={`status-pending`}
																					>
																						{' '}
																						Pending{' '}
																					</span>
																				</div>
																				{
																					obj.highlightTags && obj.highlightTags.map((rec, index) => {
																						return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																					})
																				}
																			</div>
																		</a>
																	) : (
																		<a
																			className='global-item d-flex'
																			onClick={(e) => {
																				e.preventDefault();
																				history.push(`/`);
																				history.push(`enquiry/details/${obj.documentID}`);
																				handleClose();
																			}}
																			key={`${obj.renderType}-${obj.documentID}-${index}-outbound`}
																		>
																			<div className='global-space'>
																				<div className='global-icon-circle'>
																					<i className='ico icon-enquiry'></i>
																				</div>
																			</div>
																			<div>
																				{(!_.isEmpty(obj.requirement) && (obj.requirement.make || obj.requirement.stock)) ? (
																					<>
																						{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																							<span className='global-header'>
																								{' '}
																								{`${obj.requirement
																									? obj.requirement.stock.make
																									: ''
																									} ${obj.requirement
																										? obj.requirement.stock.model
																										: ''
																									}`}{' '}
																							</span>
																						) : (
																							<span className='global-header'>
																								{' '}
																								{`${obj.requirement
																									? obj.requirement.make
																									: ''
																									} ${obj.requirement
																										? obj.requirement.model
																										: ''
																									}`}{' '}
																							</span>
																						)}
																					</>
																				) : (
																					<></>
																				)}
																				<span className='global-info'>
																					{' '}
																					{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																					{obj.displayID ? ` - ${obj.displayID}` : ''}
																				</span>
																				{!_.isEmpty(obj) && obj.status && (
																					<div className='global-status'>
																						<>
																							{(() => {
																								let __status = (obj.status && obj.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : obj.status;
																								let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

																								return <>
																									{
																										_objCurrentStatus
																											?
																											<>
																												<span
																													className={`status-${obj.status === 'open' ? 'open' : 'empty'} mr-2`}
																													style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																												>
																													{_objCurrentStatus.name}
																												</span>
																											</>
																											:
																											<></>
																									}
																								</>
																							})()}
																						</>
																						{obj.status.toLowerCase() === 'pendinglost' ? (
																							<OverlayTrigger
																								placement='bottom'
																								overlay={
																									<Tooltip>Lost Pending Approval</Tooltip>
																								}
																							>
																								<span className="pipeline-pendinglost ml-1" style={{ float: 'none', paddingLeft: '0px' }}> <span className="status-pendinglost-circle">PA</span> </span>
																							</OverlayTrigger>) : (<></>)
																						}
																					</div>
																				)}
																				{
																					obj.highlightTags && obj.highlightTags.map((rec, index) => {
																						return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																					})
																				}
																			</div>
																		</a>
																	)}
																</>
															) : obj.renderType === 'contactDetail' ? (
																<a
																	className='global-item d-flex'
																	onClick={e => {
																		e.preventDefault();
																		history.push(`/`);
																		history.push(
																			`contacts/details/${obj.documentID}`,
																		);
																		handleClose();
																	}}
																	key={`${obj.renderType}-${obj.documentID}-${index}`}
																>
																	<div className='global-space'>
																		<div className='global-icon-circle'>
																			<i className='ico icon-Contacts'></i>
																		</div>
																	</div>
																	<div>
																		{(obj.firstName || obj.lastName) && (
																			<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
																		)}
																		{obj.phone ? (
																			<span className='global-info'>{CommonHelper.getFullPhone(obj.phoneCode, obj.phone)}</span>
																		) : obj.email ? (
																			<span className='global-info'>{`${obj.email}`}</span>
																		) : (
																			<></>
																		)}
																		{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																			(<>
																				<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																			</>) : <></>}
																		{
																			obj.highlightTags && obj.highlightTags.map((rec, index) => {
																				return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																			})
																		}
																	</div>
																</a>
															) : obj.renderType === 'company' ? (
																<a
																	className='global-item d-flex'
																	href='#'
																	onClick={() => {
																		history.push(`/`)
																		history.push(`/company/details/${obj.documentID}`)
																		handleClose();
																	}}
																	key={`${obj.renderType}-${obj.documentID}-${index}`}
																>
																	<div className='global-space'>
																		<div className='global-icon-circle'>
																			<i className='ico icon-company'></i>
																		</div>
																	</div>
																	<div>
																		{obj.name && (
																			<span className='global-header'>{obj.name}</span>
																		)}
																		{obj.address && (
																			<span className='global-info'>
																				{`${obj.address} ${obj.areaCode}`}
																			</span>
																		)}
																		{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																			(<>
																				<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																			</>) : <></>}
																		{
																			obj.highlightTags && obj.highlightTags.map((rec, index) => {
																				return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																			})
																		}
																	</div>
																</a>
															) : obj.renderType === 'stock' ? (
																<a
																	className='global-item d-flex'
																	href='#'
																	onClick={e => {
																		e.preventDefault();
																		handleOpenStock(obj.documentID);
																		handleClose();
																	}}
																	key={`${obj.renderType}-${obj.documentID}-${index}`}
																>
																	<div className='global-space'>
																		<div className='global-icon-circle'>
																			<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
																		</div>
																	</div>
																	<div>
																		<span className='global-header'>
																			{`${obj.make} ${obj.model}`}
																		</span>
																		<span className='global-info'>
																			{' '}
																			{`${obj.extColor ? obj.extColor + ',' : ''} ${obj.year
																				}`}
																			{obj.stockNo ? ` - ${obj.stockNo}` : ''}
																		</span>
																		{enableSharedStock && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																			(<>
																				<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																			</>) : <></>}
																		{obj.saleType && (
																			<div className='global-status'>
																				<div className='badge badge-pill badge-white'>
																					{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, obj.saleType, '')}
																				</div>
																			</div>
																		)}
																		{
																			obj.highlightTags && obj.highlightTags.map((rec, index) => {
																				return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																			})
																		}
																	</div>
																</a>
															) : obj.renderType === 'tradein' ? (
																<a
																	className='global-item d-flex'
																	href='#'
																	onClick={(e) => {
																		e.preventDefault();
																		if (obj.enquiryID) history.push(`/enquiry/details/${obj.enquiryID}`)
																		handleClose();
																	}}
																	key={`${obj.renderType}-${obj.documentID}-${index}`}
																>
																	<div className='global-space'>
																		<div className='global-icon-circle'>
																			<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein')}></i>
																		</div>
																	</div>
																	<div>
																		<span className='global-header'>
																			{`${obj.make} ${obj.model}`}
																		</span>

																		<span className='global-info'>
																			{' '}
																			{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																			{obj.displayID ? ` - ${obj.displayID}` : ''}
																			{obj.regNo ? ` - ${obj.regNo}` : ''}
																		</span>
																		{!_.isEmpty(obj) && obj.status && (
																			<div className='global-status'>
																				<>
																					{(() => {
																						const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
																						let _objCurrentStatus = obj.tradeinProID && obj.status && tradeinProAllStatus.filter(e => e.value === obj.status)[0]
																						return (
																							<>
																								{
																									_objCurrentStatus
																										?
																										<>
																											<div
																												className={`badge badge-pill badge-status-${obj.status === tipStatus.NEW ? 'open' : 'empty'} mr-2`}
																												style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																											>
																												<Translate
																													text={_objCurrentStatus.name}
																													upperCase={true}
																												/>
																											</div>
																										</>
																										:
																										<><div className={`badge badge-pill badge-status-${obj.status.toLowerCase()} mr-2`}>
																											<Translate
																												text={obj.status.toLowerCase()}
																												upperCase={true}
																											/>
																										</div></>
																								}
																							</>
																						);
																					})()}
																				</>
																			</div>
																		)}
																		{obj.highlightTags &&
																			obj.highlightTags.map((rec, index) => {
																				return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>;
																			})}
																	</div>
																</a>
															) : obj.renderType === 'activity' ? (
																<>
																	{obj.type === 'activity' ? (
																		<a
																			className='global-item d-flex'
																			href='#'
																			onClick={e => {
																				e.preventDefault();
																				handleOpenEditActivity(obj);
																				handleClose();
																			}}
																			key={`${obj.renderType}-${obj.documentID}-${index}`}
																		>
																			<div className='global-space'>
																				<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																					<i className='ico icon-date'></i>
																				</div>
																			</div>
																			<div>
																				{obj.title && (
																					<span className='global-header'>
																						{obj.title}
																					</span>
																				)}
																				{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																				{!isEmpty(obj.addedDate) && (
																					<div className='global-date'>
																						{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj?.completedDate?.seconds ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj?.startDate?.seconds ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																					</div>
																				)}
																				{
																					obj.highlightTags && obj.highlightTags.map((rec, index) => {
																						return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																					})
																				}
																			</div>
																		</a>
																	) : (
																		<Link
																			className='global-item d-flex'
																			to={
																				obj.enquiryID
																					? `enquiry/details/${obj.enquiryID}?comments=${obj.documentID}`
																					: `contacts/details/${obj.contactID}?comments=${obj.documentID}`
																			}
																			key={obj.documentID}
																			onClick={handleClose}
																		>
																			<div className='global-space'>
																				<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																					<i className='ico icon-date'></i>
																				</div>
																			</div>
																			<div>
																				{obj.title && (
																					<span className='global-header'>
																						{obj.title}
																					</span>
																				)}
																				{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																				{!isEmpty(obj.addedDate) && (
																					<div className='global-date'>
																						{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj?.completedDate?.seconds ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj?.startDate?.seconds ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																					</div>
																				)}
																				{
																					obj.highlightTags && obj.highlightTags.map((rec, index) => {
																						return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																					})
																				}
																			</div>
																		</Link>
																	)}
																</>
															) : obj.renderType === 'service' ? (
																<>
																	{obj.isInbound || !isServiceModuleEnabled ? (
																		<>
																		</>
																	) : (
																		<a
																			className='global-item d-flex'
																			onClick={(e) => {
																				e.preventDefault();
																				history.push(`/`);
																				history.push(`service/details/${obj.documentID}`);
																				handleClose();
																			}}
																			key={`${obj.renderType}-${obj.documentID}-${index}`}
																		>
																			<div className='global-space'>
																				<div className='global-icon-circle'>
																					<i className='ico icon-service-settings'></i>
																				</div>
																			</div>
																			<div>
																				{!_.isEmpty(obj.vehicle) ? (
																					<>
																						{!_.isEmpty(obj.vehicle.make) && obj.vehicle.model ? (
																							<span className='global-header'>
																								{' '}
																								{`${obj.vehicle
																									? obj.vehicle.make
																									: ''
																									} ${obj.vehicle
																										? obj.vehicle.model
																										: ''
																									}`}{' '}
																							</span>
																						) : (
																							<span className='global-header'>
																								{' '}
																								{`${obj.vehicle
																									? obj.vehicle.make ? obj.vehicle.make : ''
																									: ''
																									} ${obj.vehicle
																										? obj.vehicle.model ? obj.vehicle.model : ''
																										: ''
																									}`}{' '}
																							</span>
																						)}
																					</>
																				) : (
																					<></>
																				)}
																				<span className='global-info'>
																					{' '}
																					{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																					{obj.displayID ? ` - ${obj.displayID}` : ''}
																				</span>
																				{!_.isEmpty(obj) && obj.status && (
																					<div className='global-status'>
																						<>
																							{(() => {
																								let __status = (obj.status && obj.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : obj.status;
																								let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

																								return <>
																									{
																										_objCurrentStatus
																											?
																											<>
																												<span
																													className={`status-${obj.status === 'open' ? 'open' : 'empty'} mr-2`}
																													style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																												>
																													{_objCurrentStatus.name}
																												</span>
																											</>
																											:
																											<></>
																									}
																								</>
																							})()}
																						</>
																						{obj.status.toLowerCase() === 'pendinglost' ? (
																							<OverlayTrigger
																								placement='bottom'
																								overlay={
																									<Tooltip>Lost Pending Approval</Tooltip>
																								}
																							>
																								<span className="pipeline-pendinglost ml-1" style={{ float: 'none', paddingLeft: '0px' }}> <span className="status-pendinglost-circle">PA</span> </span>
																							</OverlayTrigger>) : (<></>)
																						}
																					</div>
																				)}
																				{
																					obj.highlightTags && obj.highlightTags.map((rec, index) => {
																						return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																					})
																				}
																			</div>
																		</a>
																	)}
																</>
															) : (
																<></>
															)
														}
													</Fragment>
												})}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'enquiry' ? (
								<>
									{enquiries.length > 0 ? (
										<>
											{enquiries.map((obj, index) => (
												<Fragment key={`${obj.renderType}-${obj.documentID}`}>
													{obj.isInbound ? (
														<a
															className='global-item d-flex'
															onClick={(e) => {
																e.preventDefault();
																handleInBoundOpen(obj)
																handleClose();
															}}
															key={`${obj.renderType}-${obj.documentID}`}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-enquiry'></i>
																</div>
															</div>
															<div>
																{(!_.isEmpty(obj.requirement) && (obj.requirement.make || obj.requirement.stock)) ? (
																	<>
																		{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																			<span className='global-header'>
																				{' '}
																				{`${obj.requirement
																					? obj.requirement.stock.make
																					: ''
																					} ${obj.requirement
																						? obj.requirement.stock.model
																						: ''
																					}`}{' '}
																			</span>
																		) : (
																			<span className='global-header'>
																				{' '}
																				{`${obj.requirement
																					? obj.requirement.make
																					: ''
																					} ${obj.requirement
																						? obj.requirement.model
																						: ''
																					}`}{' '}
																			</span>
																		)}
																	</>
																) : (
																	<></>
																)}
																<span className='global-info'>
																	{' '}
																	{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																	{obj.displayID ? ` - ${obj.displayID}` : ''}
																</span>
																<div className='global-status'>
																	<span
																		className={`status-pending`}
																	>
																		{' '}
																		Pending{' '}
																	</span>
																</div>
																{
																	obj.highlightTags && obj.highlightTags.map((rec, index) => {
																		return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																	})
																}
															</div>
														</a>
													) : (
														<a
															className='global-item d-flex'
															onClick={(e) => {
																e.preventDefault();
																history.push(`/`);
																history.push(`enquiry/details/${obj.documentID}`);
																handleClose();
															}}
															key={`${obj.renderType}-${obj.documentID}`}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-enquiry'></i>
																</div>
															</div>
															<div>
																{(!_.isEmpty(obj.requirement) && (obj.requirement.make || obj.requirement.stock)) ? (
																	<>
																		{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																			<span className='global-header'>
																				{' '}
																				{`${obj.requirement
																					? obj.requirement.stock.make
																					: ''
																					} ${obj.requirement
																						? obj.requirement.stock.model
																						: ''
																					}`}{' '}
																			</span>
																		) : (
																			<span className='global-header'>
																				{' '}
																				{`${obj.requirement
																					? obj.requirement.make
																					: ''
																					} ${obj.requirement
																						? obj.requirement.model
																						: ''
																					}`}{' '}
																			</span>
																		)}
																	</>
																) : (
																	<></>
																)}
																<span className='global-info'>
																	{' '}
																	{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																	{obj.displayID ? ` - ${obj.displayID}` : ''}
																</span>
																{!_.isEmpty(obj) && obj.status && (
																	<div className='global-status'>
																		<>
																			{(() => {
																				let __status = (obj.status && obj.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : obj.status;
																				let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

																				return <>
																					{
																						_objCurrentStatus
																							?
																							<>
																								<span
																									className={`status-${obj.status === 'open' ? 'open' : 'empty'} mr-2`}
																									style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																								>
																									{_objCurrentStatus.name}
																								</span>
																							</>
																							:
																							<></>
																					}
																				</>
																			})()}
																		</>
																		{obj.status.toLowerCase() === 'pendinglost' ? (
																			<OverlayTrigger
																				placement='bottom'
																				overlay={
																					<Tooltip>Lost Pending Approval</Tooltip>
																				}
																			>
																				<span className="pipeline-pendinglost ml-1" style={{ float: 'none', paddingLeft: '0px' }}> <span className="status-pendinglost-circle">PA</span> </span>
																			</OverlayTrigger>) : (<></>)
																		}
																	</div>
																)}
																{
																	obj.highlightTags && obj.highlightTags.map((rec, index) => {
																		return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																	})
																}
															</div>
														</a>
													)}
												</Fragment>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'contacts' ? (
								<>
									{contactDetails.length > 0 ? (
										<>
											{contactDetails.map(obj => (
												<Fragment key={`${obj.renderType}-${obj.documentID}`}>
													<a
														className='global-item d-flex'
														onClick={(e) => {
															e.preventDefault();
															history.push(`/`);
															history.push(`contacts/details/${obj.documentID}`);
															handleClose();
														}}
														key={`${obj.renderType}-${obj.documentID}`}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className='ico icon-Contacts'></i>
															</div>
														</div>
														<div>
															{(obj.firstName || obj.lastName) && (
																<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
															)}
															{obj.phone ? (
																<span className='global-info'>{CommonHelper.getFullPhone(obj.phoneCode, obj.phone)}</span>
															) : obj.email ? (
																<span className='global-info'>{obj.email}</span>
															) : (
																<></>
															)}
															{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																(<>
																	<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																</>) : <></>}
															{
																obj.highlightTags && obj.highlightTags.map((rec, index) => {
																	return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																})
															}
														</div>
													</a>
												</Fragment>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'company' ? (
								<>
									{companies.length > 0 ? (
										<>
											{companies.map(obj => (
												<Fragment key={`${obj.renderType}-${obj.documentID}`}>
													<a
														className='global-item d-flex'
														href='#'
														onClick={() => {
															history.push(`/`)
															history.push(`/company/details/${obj.documentID}`)
															// handleCompanyView(obj.documentID);
															handleClose();
														}}
														key={`${obj.renderType}-${obj.documentID}}`}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className='ico icon-company'></i>
															</div>
														</div>
														<div>
															{obj.name && (
																<span className='global-header'>{obj.name}</span>
															)}
															{obj.address && (
																<span className='global-info'>
																	{`${obj.address} ${obj.areaCode}`}
																</span>
															)}
															{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																(<>
																	<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																</>) : <></>}
															{
																obj.highlightTags && obj.highlightTags.map((rec, index) => {
																	return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																})
															}
														</div>
													</a>
												</Fragment>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'stock' ? (
								<>
									{stock.length > 0 ? (
										<>
											{stock.map(obj => (
												<Fragment key={`${obj.renderType}-${obj.documentID}`}>
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															handleOpenStock(obj.documentID);
															handleClose();
														}}
														key={`${obj.renderType}-${obj.documentID}`}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.make} ${obj.model}`}
															</span>
															<span className='global-info'>
																{' '}
																{`${obj.extColor ? obj.extColor + ',' : ''} ${obj.year
																	}`}
																{obj.stockNo ? ` - ${obj.stockNo}` : ''}
															</span>
															{enableSharedStock && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																(<>
																	<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																</>) : <></>}
															{obj.saleType && (
																<div className='global-status'>
																	<div className='badge badge-pill badge-white'>
																		{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType, obj.saleType, '')}
																	</div>
																</div>
															)}
															{
																obj.highlightTags && obj.highlightTags.map((rec, index) => {
																	return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																})
															}
														</div>
													</a>
												</Fragment>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'tradein' ? (
								<>
									{tradeins.length > 0 ? (
										<>
											{tradeins.map(obj => (
												<Fragment key={`${obj.renderType}-${obj.documentID}`}>
													<a
														className='global-item d-flex'
														href='#'
														onClick={(e) => {
															e.preventDefault();
															if (obj.enquiryID) history.push(`/enquiry/details/${obj.enquiryID}?tid=${obj.documentID}`)
															handleClose();
														}}
														key={`${obj.renderType}-${obj.documentID}`}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein')}></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.make} ${obj.model}`}
															</span>
															<span className='global-info'>
																{' '}
																{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																{obj.displayID ? ` - ${obj.displayID}` : ''}
																{obj.regNo ? ` - ${obj.regNo}` : ''}
															</span>
															{!_.isEmpty(obj) && obj.status && (
																<div className='global-status'>
																	<>
																		{(() => {

																			const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
																			let _objCurrentStatus = obj.tradeinProID && obj.status && tradeinProAllStatus.filter(e => e.value === obj.status)[0]
																			return (
																				<>
																					{
																						_objCurrentStatus
																							?
																							<>
																								<div
																									className={`badge badge-pill badge-status-${obj.status === tipStatus.NEW ? 'open' : 'empty'} mr-2`}
																									style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																								>
																									<Translate
																										text={_objCurrentStatus.name}
																										upperCase={true}
																									/>
																								</div>
																							</>
																							:
																							<><div className={`badge badge-pill badge-status-${obj.status.toLowerCase()} mr-2`}>
																								<Translate
																									text={obj.status.toLowerCase()}
																									upperCase={true}
																								/>
																							</div></>
																					}
																				</>
																			);
																		})()}
																	</>
																</div>
															)}
															{
																obj.highlightTags && obj.highlightTags.map((rec, index) => {
																	return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																})
															}
														</div>
													</a>
												</Fragment>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'activity' ? (
								<>
									{activities.length > 0 ? (
										<>
											{_.orderBy(
												activities,
												['sortDate.seconds'],
												['desc'],
											).map((obj, index) => (
												<Fragment key={`${obj.renderType}-${obj.documentID}`}>
													{obj.type === 'activity' ? (
														<a
															className='global-item d-flex'
															href='#'
															onClick={e => {
																e.preventDefault();
																handleOpenEditActivity(obj);
																handleClose();
															}}
															key={`${obj.renderType}-${obj.documentID}-${index}`}
														>
															<div className='global-space'>
																<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																	<i className='ico icon-date'></i>
																</div>
															</div>
															<div>
																{obj.title && (
																	<span className='global-header'>{obj.title}</span>
																)}
																{!_.isEmpty(obj.contact) && (
																	<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>
																)}
																{!isEmpty(obj.addedDate) && (
																	<div className='global-date'>
																		{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj?.completedDate?.seconds ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj?.startDate?.seconds ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																	</div>
																)}
																{
																	obj.highlightTags && obj.highlightTags.map((rec, index) => {
																		return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																	})
																}
															</div>
														</a>
													) : (
														<Link
															className='global-item d-flex'
															to={
																obj.enquiryID
																	? `enquiry/details/${obj.enquiryID}?comments=${obj.documentID}`
																	: `contacts/details/${obj.contactID}?comments=${obj.documentID}`
															}
															onClick={handleClose}
														>
															<div className='global-space'>
																<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																	<i className='ico icon-date'></i>
																</div>
															</div>
															<div>
																{obj.title && (
																	<span className='global-header'>{obj.title}</span>
																)}
																{!_.isEmpty(obj.contact) && (
																	<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>
																)}
																{!isEmpty(obj.addedDate) && (
																	<div className='global-date'>
																		{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj?.completedDate?.seconds ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj?.startDate?.seconds ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																	</div>
																)}
																{
																	obj.highlightTags && obj.highlightTags.map((rec, index) => {
																		return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																	})
																}
															</div>
														</Link>
													)}
												</Fragment>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'service' ? (
								<>
									{serviceJobs.length > 0 ? (
										<>
											{serviceJobs.map((obj, index) => (
												<Fragment key={`${obj.renderType}-${obj.documentID}`}>
													{obj.isInbound || !isServiceModuleEnabled ? (
														<>
														</>
													) : (
														<a
															className='global-item d-flex'
															onClick={(e) => {
																e.preventDefault();
																history.push(`/`);
																history.push(`service/details/${obj.documentID}`);
																handleClose();
															}}
															key={`${obj.renderType}-${obj.documentID}`}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-service-settings'></i>
																</div>
															</div>
															<div>
																{!_.isEmpty(obj.vehicle) ? (
																	<>
																		{!_.isEmpty(obj.vehicle.make) && obj.vehicle.model ? (
																			<span className='global-header'>
																				{' '}
																				{`${obj.vehicle
																					? obj.vehicle.make
																					: ''
																					} ${obj.vehicle
																						? obj.vehicle.model
																						: ''
																					}`}{' '}
																			</span>
																		) : (
																			<span className='global-header'>
																				{' '}
																				{`${obj.vehicle
																					? obj.vehicle.make ? obj.vehicle.make : ''
																					: ''
																					} ${obj.vehicle
																						? obj.vehicle.model ? obj.vehicle.model : ''
																						: ''
																					}`}{' '}
																			</span>
																		)}
																	</>
																) : (
																	<></>
																)}
																<span className='global-info'>
																	{' '}
																	{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																	{obj.displayID ? ` - ${obj.displayID}` : ''}
																</span>
																{!_.isEmpty(obj) && obj.status && (
																	<div className='global-status'>
																		<>
																			{(() => {
																				let __status = (obj.status && obj.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : obj.status;
																				let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

																				return <>
																					{
																						_objCurrentStatus
																							?
																							<>
																								<span
																									className={`status-${obj.status === 'open' ? 'open' : 'empty'} mr-2`}
																									style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																								>
																									{_objCurrentStatus.name}
																								</span>
																							</>
																							:
																							<></>
																					}
																				</>
																			})()}
																		</>
																		{obj.status.toLowerCase() === 'pendinglost' ? (
																			<OverlayTrigger
																				placement='bottom'
																				overlay={
																					<Tooltip>Lost Pending Approval</Tooltip>
																				}
																			>
																				<span className="pipeline-pendinglost ml-1" style={{ float: 'none', paddingLeft: '0px' }}> <span className="status-pendinglost-circle">PA</span> </span>
																			</OverlayTrigger>) : (<></>)
																		}
																	</div>
																)}
																{
																	obj.highlightTags && obj.highlightTags.map((rec, index) => {
																		return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																	})
																}
															</div>
														</a>
													)}
												</Fragment>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : (
								<></>
							)

							}

							{

								pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
									(!_.isEmpty(hasMore) && !_.isEmpty(hasMore.filter(e => activeTab === 'all' ? true : (activeTab === 'enquiry' ? ['enquiry', 'inboundLeads'].includes(e.type) : e.type === activeTab))[0]) ?

										(<div className="load-more-button"> <a href="#" onClick={(e) => {
											e.preventDefault();
											// console.log('hasmore:', hasMore, activeTab);
											setPagingloader(true)
										}}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
										: (<></>))
							}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SalesSearch;
