/** LIBRARIES */
import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
/** COMPONENTS */
import Translate from '../../constants/translate';
import { PopoverPanel } from '../../components';
import CafeOrderLIst from './list'

export default class CafeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cafePopOver: { showpopover: false, targetpopover: null, cafe: null, count: 0 }
        }
    }

    handleReOrder = (id) => {
        this.props.handleReOrder(id);
        this.setState({
            cafePopOver: { showpopover: false, targetpopover: '', cafe: null, count: 0 }
        });
    }

    showOrderQuantity = (orders) => {

        if (!_.isEmpty(orders)) {
            var total = 0;
            for (var i = 0; i < orders.length; i++) {
                total = total + (orders[i].quantity ? orders[i].quantity : 0);
            }
            return total;
        }
        else {
            return 0;
        }
    }


    render() {
        const {
            cafeOrders, handleOrderCancelled, clientUsers, isDeivered, groupUsers
        } = this.props;

        return (
            <>
                <div className="cafe-item">
                    <div className="info-table">
                        <table className="table-fill cafe-table">

                            <thead>
                                <tr>
                                    <th className="text-left label-text" width="30%"><Translate text={'orderNo'} /></th>
                                    <th className="text-left" width="50%"><Translate text={'date_Time'} /></th>
                                    <th className="text-left" width="20%"><Translate text={'status'} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cafeOrders && cafeOrders.map((cafe, index) => (
                                        <tr key={index} id={cafe.documentID} onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                                cafePopOver: { showpopover: true, targetpopover: e.target, cafe: cafe, count: this.showOrderQuantity(cafe.orders) }
                                            });
                                        }}>
                                            <td className="text-left cursor-pointer"><strong>{cafe.orderNumber}</strong></td>
                                            <td className="text-left cursor-pointer">{cafe.addedDate ? moment.unix(cafe.addedDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                                            <td className="text-left cursor-pointer"><div className={`badge badge-${cafe.status}-fill`}>{cafe.status ? cafe.status.toUpperCase() : 'pending'}</div></td>
                                        </tr>

                                    ))
                                }
                            </tbody>

                        </table>
                    </div>
                </div>

                <PopoverPanel
                    showpopover={this.state.cafePopOver.showpopover}
                    targetpopover={this.state.cafePopOver.targetpopover}
                    title={<><Translate text={'orders'} />&nbsp; <span className="badge badge-pill badge-activity ml-1">{this.state.cafePopOver.count}</span></>}
                    position="right"
                    id="cafe-items"
                    className="popover-cafe"
                    closepopover={() => {
                        this.setState({
                            cafePopOver: { showpopover: false, targetpopover: '', cafe: null, count: 0 }
                        });
                    }}>
                    <CafeOrderLIst
                        cafe={this.state.cafePopOver.cafe}
                        handleCancel={handleOrderCancelled}
                        handleReOrder={this.handleReOrder}
                        dealersettings={this.props.dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isDeivered={isDeivered}
                    />
                </PopoverPanel>

            </>
        );
    }
}
