import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { BarChart, ContentOverlay } from "../../../components";
import _images from '../../../images'
import Translate from '../../../constants/translate';
import { columnValues, saleType } from '../../reports/yearlyPerformance/viewModel';

const YearlyPerformance = (props) => {
    const [loader, setLoader] = useState(true)
    const [reportData, setReportData] = useState([])
    const [client, setClient] = useState({})
    const [makes, setMakes] = useState({})

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _year = params.get('year');
    const _clientIDs = params.get('clientids');
    const _makes = params.get('makes');

    const dateRange = {
        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_year).startOf('year')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_year).endOf('year')._d),
        year: Number(_year)
    }

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    let makePromise = [];
                    if (_makes)
                        _makes.split(',').forEach(rec => {
                            makePromise.push(window.firebase.firestore().doc(`brands/${rec}`).get());
                        })
                    const makeSnapshot = await Promise.all(makePromise);
                    let __makes = []
                    makeSnapshot.forEach(doc => {
                        if (doc.exists)
                            __makes.push(doc.data())
                    })
                    setMakes(__makes)
                    setClient(doc.data());
                } else {
                    setLoader(false)
                }
            })

    }, [])


    useEffect(() => {
        if (_.isEmpty(client))
            return

        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getOEMSummaryReport',
                params: JSON.stringify({
                    clientID: _clientIDs,
                    timezone: moment.tz.guess(),
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    wonLead: true,
                    newLead: true
                })
            }).then((response) => {
                setLoader(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
            });
        }
        catch (error) {
            setLoader(false)
        }

    }, [client])


    const loadReportData = (_allData) => {

        let _data = [];
        makes.filter(a => a?.value).forEach(mk => {
            saleType.forEach(rec => {
                _data.push({
                    title: `${mk.name.toUpperCase()} ${rec.toUpperCase()}`,
                    make: mk.name,
                    months: moment.monthsShort().map(month => {
                        const _startDate = moment(`${dateRange.year} ${month}`).startOf('month').unix();
                        const _endDate = moment(`${dateRange.year} ${month}`).endOf('month').unix();
                        const leadCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.mk === mk.value), _startDate, _endDate, rec);
                        const walkinCount = checkDataFilter(_allData.filter((a) => a.col === 'wk' && a.mk === mk.value), _startDate, _endDate, rec);
                        const wonCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.mk === mk.value), _startDate, _endDate, rec, 'sold', 'wd');
                        return moment(`${dateRange.year} ${month}`).month() <= moment().month() || dateRange.year !== moment().year() ? {
                            title: month.toUpperCase(),
                            leadCount: leadCount.actual,
                            walkinCount: walkinCount.actual,
                            wonCount: wonCount.actual,
                            conv: leadCount.actual > 0 ? Math.round((100 / leadCount.actual) * wonCount.actual) : 0
                        } : {
                            title: month.toUpperCase(),
                            leadCount: null,
                            walkinCount: null,
                            wonCount: null,
                            conv: null
                        }
                    })
                })

            });
        })
        if (_data.length) {
            const totalMonths = [];
            _data.map(r => totalMonths.push(...r.months))
            _data.push({
                title: 'TOTAL',
                make: 'TOTAL',
                months: _.chain(totalMonths)
                    .groupBy('title')
                    .map((value, key) => (moment(`${dateRange.year} ${key}`).month() <= moment().month() || dateRange.year !== moment().year() ? {
                        title: key,
                        leadCount: _.sumBy(value, 'leadCount'),
                        walkinCount: _.sumBy(value, 'walkinCount'),
                        wonCount: _.sumBy(value, 'wonCount'),
                        conv: _.sumBy(value, 'leadCount') > 0 ? Math.round((100 / _.sumBy(value, 'leadCount')) * _.sumBy(value, 'wonCount')) : 0
                    } : {
                        title: key,
                        leadCount: null,
                        walkinCount: null,
                        wonCount: null,
                        conv: null
                    }))
                    .value()
            })
        }
        _data = _data.map(r => {
            return {
                ...r,
                months: r.months.map(a => {
                    return {
                        ...a,
                        conv: a.conv > 100 ? '100%' : _.isNull(a.conv) ? null : `${a.conv}%`
                    }
                })
            }
        })
        setReportData(_.chain(_data).groupBy('make').map((value, key) => ({ key, value })).value());
    }

    const checkDataFilter = (_data, startDt, endDt, saleType, status, dateType, newlead) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return {
            arractual: _.uniqBy(filterData, 'eid').map(r => { return r.eid }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, 'eid'), 'wc') : _.uniqBy(filterData, 'eid').length
        }
    }


    return (loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        client && client.logoURL
                                            ?
                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{client.name}</div>
                                            </>
                                    }
                                </div></td>
                                <td width="40%"><div className="header-right"> Yearly Performance Report <br></br>
                                    <span> {_year}</span>
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : reportData?.length === 0 ? (
                        <div className="table-wraper">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> No records found</div>
                            </div>
                        </div>) : (
                        <div className="table-page-break">
                            {
                                reportData.map((rec, j) => {
                                    return <div key={j} className='middle-section-wraper'>
                                        {
                                            rec.value.map((data, i) => {
                                                return <div key={i} className={`common-table-outer`}>
                                                    <table className="col-table-loop col-table">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="13" className="head-bg-main" scope="col">{data.title}</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="head-light" scope="col" width="16%">        MONTH   </th>
                                                                {
                                                                    data.months.map((month, mi) => {
                                                                        return <th key={mi} scope="col" className="head-light text-center" width="7%">{month.title}</th>

                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-left col-td-head"><b>ENQUIRIES</b></td>
                                                                {
                                                                    data.months.map((month, mi) => {
                                                                        return <td key={mi} className="text-center">
                                                                            {month.leadCount}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left col-td-head"><b>WALK INS </b></td>
                                                                {
                                                                    data.months.map((month, mi) => {
                                                                        return <td key={mi} className="text-center" >{month.walkinCount}</td>
                                                                    })
                                                                }

                                                            </tr>
                                                            <tr>
                                                                <td className="text-left col-td-head"><b>SOLD</b> </td>
                                                                {
                                                                    data.months.map((month, mi) => {
                                                                        return <td key={mi} className="text-center">{month.wonCount}</td>

                                                                    })
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <td className="text-left col-td-head"><b>CONVERSION %</b></td>
                                                                {
                                                                    data.months.map((month, mi) => {
                                                                        return <td key={mi} className="text-center">{month.conv}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            })
                                        }
                                        <div className='report-loop-box-wrapper'>
                                            {
                                                rec.value.map((data, k) => {
                                                    return <div key={k} className={`col-loop-${data.title === 'TOTAL' ? '12' : '6'}`}>
                                                        <div className="col-report-box">
                                                            <BarChart
                                                                id={`${rec.key}-${k}`}
                                                                height={`340px`}
                                                                data={data.months}
                                                                columns={columnValues.filter(r => !r.lineChart).map(r => { return r.value })}
                                                                lineColumns={['wonCount']}
                                                                dynamicColumns={columnValues}
                                                                xCategory={'title'}
                                                                calculationType={'count'}
                                                                handleShowEnquiryList={(value, rec) => { }}
                                                                title={data.title}
                                                                dynColor={true}
                                                                labelMaxWidth={75}
                                                                hideBullet={true}
                                                                sameYAxis={true}
                                                            />
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div style={{ pageBreakAfter: 'always' }}></div>
                                    </div>

                                })
                            }
                        </div>
                    )
                }
                {
                    client ? (<div className="footer">
                        <div className="footer-bottom">
                            <p>
                                <span style={{ paddingLeft: 0 }} >
                                    {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                </span>
                                <span style={{ paddingLeft: 0 }}>
                                    {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                    {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >&nbsp; | &nbsp;{client.website}</span></> : <></>}
                                </span>
                            </p>
                        </div>
                        <div className="footer-cpy">
                            <div className="footer-left">{'Powered by FusionSD.'}</div>

                            <div className="footer-right"></div>

                        </div>
                        <div className="clear"></div>
                    </div>) : (<></>)
                }
            </section >
        </div >
    </>)
}

export default YearlyPerformance;