export const allfeedbackFields = [
  // {
  //     name: 'Message',
  //     value: '_content',
  //     flex: 2,
  //     default: true,
  // },
  {
      name: 'Customer Name',
      value: '_participant',
      subText: 'enqdisplayID',
      flex: 1,
      default: true,
  },
  {
    name: 'Phone',
    value: '_phone',
    flex: 1,
    default: true,
  },
  {
    name: 'Email',
    value: '_email',
    flex: 1,
    default: true,
  },
  {
    name: 'Type',
    value: '_type',
    flex: 1,
    default: true,
  },
  {
    name: 'Sent On',
    value: '_sentOn',
    flex: 1,
    default: true
  },
  {
    name: 'Response',
    value: '_responseCombined',
    flex: 1,
    default: true,
  },
]

export const responseOptions = [
	{ value: 'yes', label: 'Yes', index: 1 },
	{ value: 'no', label: 'No', index: 2 },
	{ value: 'other', label: 'Other', index: 3 },
]


export const csvHeader = [
  { label: 'Enquiry Number', key: 'enqdisplayID'},
  { label: 'Customer Name', key: '_participant'},
  { label: 'Phone', key: 'csvPhone'},
  { label: 'Email', key: '_email'},
  { label: 'Type', key: '_type'},
  { label: 'Sent On', key: '_sentOnCSV'},
  { label: 'Response On', key: '_responseOn'},
  { label: 'Response', key: 'responseCSV'},
]
