/** LIBRARIES */
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */

import ServicePreview from '../preview'
import ServiceCustomerApproval from '../customer'


class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <main role="main" className="container-fluid  pl-0 pr-0">

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/service/preview/:id" exact render={(match) => <ServicePreview serviceid={match.match.params.id} />} />
                    </Switch>
                </Suspense>

            </main>
        );
    }
}

export default MainContainer;