import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import Translate from '../../../../constants/translate';

const StagePopover = ({ stage, enquiryStatus, allPipelines }) => {
  const [state, setState] = useState([])

  useEffect(() => {
    let data = {}
    enquiryStatus.forEach(rec => {
      data = {
        ...data,
        [rec.value] : []
      }
    })
    let allStatusSettings = []
    allPipelines.forEach(rec => {
      if (!_.isEmpty(rec.statusSettings)) {
        let temp = rec.statusSettings.map(item => {
          return {
            ...item,
            pipelineName: rec.name
          }
        })
        allStatusSettings = [...allStatusSettings, ...temp]
      }
    })
    allStatusSettings = allStatusSettings.filter(rec => rec.stage === stage.value)
    allStatusSettings.forEach(item => {
      data[item.status] = [...data[item.status], item.pipelineName]
    })
    data = Object.entries(data).filter(a => a[1].length)
    setState(data)
  }, [])

  return !_.isEmpty(state) ? (
    <div className="target-popover-list">
      {state.map((item, index) => (
        <ul key={item[0]} style={index > 0 ? { marginTop: '64px' } : {}}>
          <li> <Translate text={'status'} /> : {enquiryStatus.filter(status => status.value === item[0])[0].label} </li>
          <li> <Translate text={'From Pipeline'} />:
            <div className="target-sublist">
              <div className="Vehicle-availability">
                {item[1].map(rec => (
                  <div>
                    <span>{rec}</span>
                  </div>
                ))}
              </div>
            </div>
          </li>
        </ul>
      ))}
    </div>
  ) : (<></>)
}

export default StagePopover