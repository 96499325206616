/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import {
    ReactTimePicker, AntDatePicker,
    PopUpModal, InputRadio, ReactSelect,
    InputText, InputTextArea,
    PopoverPanel, InputRange,
    Terms, AfterSignature, ImageCanvas, AutoComplete
} from '../../../components';
import SignOptions from '../../common/signOptions'
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { validateEmail } from "../../../services/helper";
import EventsList from '../../eventsPro/list/index.js'
import AddNotes from '../../fleet/add/notes'
/** VIEW-MODELS */
import { fleetAssignStatus, fleetAssignTypes, fleetProCategory, fleetProStatus, userLevel } from '../../../services/enum'
import { objFleetPro, objFleetProAssign, event_Types, fleetAssignStatusLevel, objFleetProBasic } from '../viewModel'
import { objMark } from '../../tradeIn/viewModel'

const assign_types = [
    { value: 'retailerEvent', label: 'Retailer Event' },
    { value: 'extIntEvent', label: 'External/Internal Event' },
    { value: 'loanVehicle', label: 'Loan Vehicle' }
]
const loan_type = [
    { value: 'Service', label: 'Service' },
    { value: 'Other', label: 'Other' }
]
const sign_type = [
    { active: true, ico: 'ico icon-email', value: 'send', label: 'Send sign request', text: 'Send signature request to customer' },
    { active: true, ico: 'ico icon-sign', value: 'sign', label: 'Sign on-sight', text: 'Get signature on-site from customer' }
]

const fleetHistory = {
    HANDOVER: 'info',
    RECEIVED: 'received',
    RELEASED: 'released'
}
export default class AddFleetAssign extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = null;
        this.ref = window.firebase.firestore().collection('fleetProAssign');
        this.refFleetPro = window.firebase.firestore().collection('fleetPro');
        this.newDocID = null;
        this.mandatoryFields = [];
        this.returnMandatoryFields = [];
        this.mandatoryStaffForTestDrive = [];
        this.unsubscribeFleetPro = null;
        this.unsubscribeTerms = null;
        this.unsubscribeInsurance = null;
        this.unsubscribeSign = null;
        this.state = {
            fields: Object.assign({}, objFleetProAssign),
            fieldOld: {},
            fleetPro: Object.assign({}, objFleetPro),
            mark: Object.assign({}, objMark),
            loanBookings: Object.assign([]),
            notesPopOver: { showpopover: false, targetpopover: null },
            errors: {},
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            showBeforeInspection: false,
            showAfterInspection: false,
            showBeforeTerms: false,
            showAfterSignature: false,
            showBeforeInsurance: false,
            showEventListModal: false,
            showTermsOption: false,
            showInsuranceOption: false,
            showSignOption: false,
            editApprovedBooking: false,
            activeTag: 'info',
            showInspection: { show: false, id: null, marks: null, isReadOnly: false },
            showSignature: { show: false, id: null, sign: null, isReadOnly: false },
            notesPopOver: { showpopover: false, targetpopover: null, notes: '' },
        }
    }

    //#region FUNCTIONS

    //#region PAGE LOAD

    async loadFleet(props, isReload) {

        const { show, docID, fleetProID, dealersettings, fleetPro } = props;
        const fields = Object.assign({}, objFleetProAssign);

        if (show === true && docID) {
            this.newDocID = docID;
            const refData = await window.firebase.firestore().collection('fleetProAssign').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }

                if (!fields.documentID) fields.documentID = docID;
                if (!fields.fleetProID && fleetProID) fields.fleetProID = fleetProID;

                // if (!_.isEmpty(fields.startDate))
                //     fields.startDate = moment.unix(fields.startDate.seconds).format('YYYY-MM-DD HH:mm')

                // if (!_.isEmpty(fields.endDate))
                //     fields.endDate = moment.unix(fields.endDate.seconds).format('YYYY-MM-DD HH:mm')

                // if (!_.isEmpty(fields.beforeInspection)) {
                //     const _marks = [];
                //     fields.beforeInspection.map((mark) => {
                //         const _mark = Object.assign({}, objMark);
                //         for (let [key, value] of Object.entries(mark)) {
                //             _mark[key] = value;
                //         }
                //         _marks.push(_mark)
                //     })
                //     fields.beforeInspection = _marks;
                // }

                // if (!_.isEmpty(fields.afterInspection)) {
                //     const _marks = [];
                //     fields.afterInspection.map((mark) => {
                //         const _mark = Object.assign({}, objMark);
                //         for (let [key, value] of Object.entries(mark)) {
                //             _mark[key] = value;
                //         }
                //         _marks.push(_mark)
                //     })
                //     fields.afterInspection = _marks;
                // }


            } else {
                console.error("No such document2!");
            }

        }
        else if (show === true && (!docID)) {
            this.newDocID = this.ref.doc().id;
            fields.documentID = this.newDocID;
            fields.fleetProID = fleetProID;

            if (fleetPro?.category !== fleetProCategory.MARKETING)
                fields.assignType = fleetAssignTypes.ExternalInternalEvent;
            else
                fields.assignType = fleetAssignTypes.RetailerEvent;

            if (dealersettings.level === userLevel.INDIVIDUAL) {
                fields.clientID = dealersettings.client.id;
                fields.clientName = dealersettings.client.name;
                fields.name = dealersettings.name;
                fields.email = dealersettings.email;
                fields.phone = dealersettings.phone;
                fields.companyName = dealersettings.client.name;
                fields.address = dealersettings.client.address;
            }

        }

        // let _activeTAg = 'info'
        // if (([fleetAssignStatus.RECEIVED].includes(fields.status) ||
        //     ([fleetAssignStatus.APPROVED].includes(fields.status) && [fleetAssignTypes.LoanVehicle, fleetAssignTypes.ExternalInternalEvent].includes(fields.assignType))
        // ))
        //     _activeTAg = fleetAssignStatus.RELEASED
        // else if ([fleetAssignStatus.APPROVED].includes(fields.status) && [fleetAssignTypes.RetailerEvent].includes(fields.assignType))
        //     _activeTAg = fleetAssignStatus.RECEIVED
        //activeTag: _activeTAg
        this.setState({ fields: fields, fieldOld: fields }, () => {
            if (!isReload) {
                this.loadFleetPro(fields.fleetProID);
                this.loadBookingAppointments(fields.fleetProID)
            }
        });
    }

    loadFleetPro = (fleetProID) => {
        if (!_.isEmpty(fleetProID)) {
            const fields = Object.assign({}, objFleetPro);
            this.unsubscribeFleetPro = window.firebase.firestore().collection(`fleetPro`).doc(fleetProID)
                .onSnapshot(docSnapshot => {
                    if (docSnapshot.exists) {
                        for (let [key, value] of Object.entries(docSnapshot.data())) {
                            fields[key] = value;
                        }
                        this.setState({
                            fleetPro: fields
                        });
                    }
                })


        }
    }

    loadBookingAppointments = (fleetProID) => {
        if (fleetProID) {
            window.firebase.firestore().collection('fleetProAssign')
                .where('fleetProID', '==', fleetProID)
                .where('isDeleted', '==', false)
                .get()
                .then(querySnapshot => {
                    let _bookings = [];
                    querySnapshot.docs.forEach(rec => {
                        let fields = rec.data();
                        if (fields.assignType === fleetAssignTypes.LoanVehicle && fields.expectedReturnDate)
                            fields.endDate = fields.expectedReturnDate;
                        _bookings.push(fields)
                    })
                    this.setState({ loanBookings: _bookings })
                });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadFleet(this.props);

    }

    componentWillUnmount() {
        this._isMounted = false;
        let _req = Object.assign({}, objFleetProAssign);
        _req.insurance = {};
        _req.terms = {};
        _req.keywords = [];
        _req.beforeInspection = [];
        _req.afterInspection = [];
        this.unsubscribeFleetPro && this.unsubscribeFleetPro();

        this.unsubscribeTerms && this.unsubscribeTerms();
        //this.unsubscribeInsurance && this.unsubscribeInsurance();
        //this.unsubscribeSign && this.unsubscribeSign();

        const { requestsTerms, requestsInsurance, requestsSign } = this.state;
        if (!_.isEmpty(requestsTerms) && requestsTerms.id)
            window.firebase.firestore().doc(`contactDetails/${requestsTerms.contactID}/signRequest/${requestsTerms.id}`).delete();

        // if (requestsInsurance && requestsInsurance.status === 'requested')
        //     window.firebase.firestore().doc(`contactDetails/${requestsInsurance.contactID}/signRequest/${requestsInsurance.id}`).delete();

        // if (requestsSign && requestsSign.status === 'requested')
        //     window.firebase.firestore().doc(`contactDetails/${requestsSign.contactID}/signRequest/${requestsSign.id}`).delete();

        this.setState({ fields: _req });
    }
    //#endregion

    //#region DATEPICKER

    handleFirebaseDateChange = (val, name) => {
        let fields = Object.assign({}, this.state.fields);
        if (val) {
            val = moment(moment(val).format('YYYY-MM-DD') + ' ' + (fields[name] ? moment.unix(fields[name].seconds).format('HH:mm') : moment().format('HH:mm')))._d;
            fields[name] = window.firebase.firestore.Timestamp.fromDate(moment(val)._d);
        }
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    };

    handleFirebaseTimeChange = (val, name) => {
        let fields = Object.assign({}, this.state.fields);
        fields[name] = window.firebase.firestore.Timestamp.fromDate(moment(val)._d);
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    };

    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.fields);
        if (val) {
            let date = state[name];
            if (date)
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm');
            else
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm');
        }
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleTimeChange = (date, name) => {
        let state = Object.assign({}, this.state.fields);

        state[name] = moment(date).format('YYYY-MM-DD HH:mm');

        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    minStartDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }


    minEndDate = (current) => {
        // Can not select days before today and today
        let state = Object.assign({}, this.state.fields);
        var minDate = state.startDate ? moment(state.startDate).startOf('day') : moment().startOf('day')
        return current && current < minDate;
    }
    //#endregion

    //#region INSPECTION
    handleInspectionClose = (marks, id) => {
        let state = Object.assign({}, this.state.fields);
        if (!_.isEmpty(marks) || (!_.isEmpty(state[id]) && _.isEmpty(marks))) {
            state[id] = marks;
            this.setState({
                showInspection: { show: false, id: null, marks: null, isReadOnly: false },
                fields: state
            }, () => { this.errorChange(id); });
        }
        else {
            this.setState({ showInspection: { show: false, id: null, marks: null, isReadOnly: false } })
        }
    }
    //#endregion

    //#region  TERMS / NOTES

    handleBeforeTermsClose = (data) => {

        if (!_.isEmpty(data)) {
            let state = Object.assign({}, this.state.fields);
            state.terms = data;
            this.setState({ showBeforeTerms: false, fields: state }, () => { this.errorChange('terms'); });

        }
        else {
            this.setState({ showBeforeTerms: false })
        }
    }

    handleSignatureClose = (imgURL, id) => {
        if (imgURL) {
            let state = Object.assign({}, this.state.fields);
            state[id] = imgURL;
            this.setState({
                showSignature: { show: false, id: null, sign: null, isReadOnly: false },
                fields: state
            }, () => { this.errorChange(id); });
        }
        else {
            this.setState({ showSignature: { show: false, id: null, sign: null, isReadOnly: false } })
        }
    }


    // handleAfterSignatureClose = (imgURL) => {
    //     if (imgURL) {
    //         let state = Object.assign({}, this.state.fields);
    //         state.afterSignatureURL = imgURL;
    //         this.setState({ showAfterSignature: false, fields: state }, () => { this.errorChange('afterSignatureURL'); });
    //     }
    //     else {
    //         this.setState({ showAfterSignature: false })
    //     }
    // }

    // handleNotesClose = (val) => {
    //     let state = Object.assign({}, this.state.fields);
    //     state['notes'] = val;
    //     this.setState({ fields: state, notesPopOver: { showpopover: false, targetpopover: '' } }, () => { this.errorChange('notes'); });
    // }

    // handleBeforeInsuranceClose = (data) => {
    //     if (!_.isEmpty(data)) {
    //         let state = Object.assign({}, this.state.fields);
    //         state.insurance = data;
    //         this.setState({ showBeforeInsurance: false, fields: state }, () => { this.errorChange('insurance'); });
    //     }
    //     else {
    //         this.setState({ showBeforeInsurance: false })
    //     }
    // }

    //#endregion

    //#region CHANGE EVENT
    handleAssignTypeChange = (e, name) => {
        const { checked } = e.target;
        //console.log('handleCheckChange', name, checked)
        let fields = Object.assign({}, this.state.fields);
        fields['assignType'] = checked ? name : '';
        fields.clientID = null;
        fields.clientName = null;
        fields.eventsProID = null;
        fields.eventName = '';
        fields.location = '';
        fields.endDate = null;
        fields.expectedReturnDate = null;
        fields.departureDate = null;
        fields.expectedArrivalDate = null

        this.setState({ fields: fields }, () => { this.errorChange('assignType'); });

    }

    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);
        if (e) {
            if (data.name === 'clientID') {
                fields.clientName = e.label;
            }
            fields[data.name] = e.value;
        }
        else
            fields[data.name] = 'Service';

        this.setState({ fields: fields }, () => { this.errorChange(data.name) });
    }



    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }


    handleFuelChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        fields['startFuelGauge'] = parseInt(value);
        this.setState({ fields: fields }, () => { this.errorChange(name); });
        this.slideguage(name, value);

    }

    slideguage = (id, value) => {
        if (value >= 0 && value <= 25) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else if (value >= 25 && value <= 50) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else if (value >= 50 && value <= 75) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) 50%, rgb(175, 204, 115) 50%, rgb(175, 204, 115) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) 50%, rgb(175, 204, 115) 50%, rgb(175, 204, 115) 75%, rgb(100, 215, 112) 75%, rgb(100, 215, 112) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
    }

    handleEFuelChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        fields['endFuelGauge'] = parseInt(value);
        this.setState({ fields: fields }, () => { this.errorChange(name); });
        this.slideguage(name, value);

    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleOnNameChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);
        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = _.trim(value);
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase().replace(/[@!|~&\/\\#,+()$~%.'":*?<>{}-]/g, '') + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';

            } else {

                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0).replace(/[@!|~&\/\\#,+()$~%.'":*?<>{}-]/g, '');
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str ? str.replace(/\u2028/g, '') : '';

            }
        }
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        var regexp = /^[0-9 \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            fields[e.target.name] = _.trim(value);
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
    };

    handleRadioChange = (e) => {
        const { value, name } = e.target;
        //console.log('handleTypeChange', name, value)
        let fields = Object.assign({}, this.state.fields);

        fields[name] = (value === 'yes' ? true : false);
        this.setState({ fields: fields });
    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    onCurrencyChange(name, e) {
        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {
            if (!fields[key]) {
                if ((key === 'beforeInspection' || key === 'insurance' || key === 'terms' || key === 'afterInspection') && _.isEmpty(fields[key])) {
                    errors[key] = errorClass;
                }
            }
            else {
                errors[key] = '';
            }
        }

        if (key === 'email' && fields[key] && !validateEmail(fields[key]))
            errors[key] = errorClass;
        this.setState({ errors: errors });
    }

    onCurrencyChange(name, e) {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    //#endregion

    //#region SAVE & SUBMIT 
    handleLoanSubmit = async (skipMandtry, isSave) => {

        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';
        let fields = Object.assign({}, this.state.fields);

        const { dealersettings } = this.props;


        if (!skipMandtry) {

            let _preFields = ['preInspection', 'preOdometer', 'preSignature', 'preNotes']
            let _postFields = ['postInspection', 'postOdometer', 'postSignature', 'postNotes']
            let _allExcludes = _.union(_preFields, _postFields);
            let _mandStatusFields = Object.assign([], dealersettings?.client?.fleetPro?.mandatoryFields?.[fields.assignType]?.split(','));
            if (_.isEmpty(_mandStatusFields)) _mandStatusFields.push('startDate')

            if ([fleetAssignTypes.LoanVehicle].includes(fields.assignType)) {
                _mandStatusFields.push('expectedReturnDate')
                _allExcludes.push('endDate')
            }
            else _mandStatusFields.push('endDate')

            if ([fleetAssignTypes.ExternalInternalEvent].includes(fields.assignType)) _mandStatusFields.push('eventType');
            else _allExcludes.push('eventType')

            if ([fleetAssignTypes.RetailerEvent].includes(fields.assignType)) {
                _mandStatusFields.push('clientID');

                if (dealersettings.level === userLevel.INDIVIDUAL) {
                    _allExcludes.push('inspection')
                    _allExcludes.push('terms')
                    _allExcludes.push('methodOfTransport')
                    _allExcludes.push('transportCompany')
                    _allExcludes.push('departureDate')
                    _allExcludes.push('expectedArrivalDate')
                    _allExcludes.push('expectedReturnDate')
                    _allExcludes.push('malTechnicalSupportReq')
                    _allExcludes.push('odometer')
                    _allExcludes.push('purpose')
                    _allExcludes.push('notes')
                }
                else _allExcludes.push('requestNotes')
            }

            _mandStatusFields && _mandStatusFields.filter(e => !_allExcludes.includes(e)).forEach(key => {
                if (key !== 'undefined' && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            })
            //console.log('errors', _mandStatusFields, errors)
            this.setState({ errors: errors });
        }


        if (formIsValid === true) {
            const { loanBookings } = this.state;
            if (!_.isEmpty(loanBookings) && fields.startDate && (fields.endDate || fields.expectedReturnDate)) {

                const { clientUsers, dealersettings } = this.props;
                let _startDate = fields.startDate ? window.firebase.firestore.Timestamp.fromDate(moment.unix(fields.startDate.seconds)._d) : null;
                let _endDate = fields.endDate ? window.firebase.firestore.Timestamp.fromDate(moment.unix(fields.endDate.seconds)._d) : null;
                let _expDate = fields.expectedReturnDate ? window.firebase.firestore.Timestamp.fromDate(moment.unix(fields.expectedReturnDate.seconds)._d) : null;
                let _recordID = fields.documentID;
                if (await CommonHelper.checkFleetAppointments(
                    _.map(loanBookings.filter(a => a.documentID !== _recordID && ![fleetAssignStatus.CANCELLED, fleetAssignStatus.DECLINED, fleetAssignStatus.RELEASED].includes(a.status)), function (_data) {
                        return {
                            ..._data,
                            'endDate': (_data.assignType === fleetAssignTypes.LoanVehicle && _data.expectedReturnDate ? _data.expectedReturnDate : _data.endDate)
                        }
                    }),
                    _startDate,
                    (fields.assignType === fleetAssignTypes.LoanVehicle ? _expDate : _endDate),
                    formIsValid,
                    clientUsers,
                    dealersettings,
                    this.state.fleetPro
                ))
                    this.saveFleet(isSave);
            }
            else
                this.saveFleet(isSave);
        }
        else
            return formIsValid;

    };

    saveFleet = (isSave) => {
        this.isLoading = isSave ? 'save' : 'assign';
        const { dealersettings, docID } = this.props;
        const _permissions = dealersettings?.rolePermissions?.fleetProPermissions;
        const accessToApprove = ((!_.isEmpty(_permissions) && _permissions.accessToApprove) ? true : false);
        const objData = Object.assign({}, this.state.fields);

        if (_.isEmpty(objData.addedBy) || _.isEmpty(docID) || this.state.editApprovedBooking) {

            if (_.isEmpty(objData.addedBy) || _.isEmpty(docID)) {
                objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }

            if (dealersettings.level === userLevel.INDIVIDUAL) {
                objData.status = fleetAssignStatus.REQUESTED;
                objData.requestedBy = localStorage.uid;
                objData.requestedDate = window.firebase.firestore.Timestamp.now();
            }
            else {
                objData.status = fleetAssignStatus.PENDINGAPPROVAL;
                objData.assignBy = localStorage.uid;
                objData.assignDate = window.firebase.firestore.Timestamp.now();
            }

            objData.approvedBy = null;
            objData.approvedDate = null;
        }

        if (!_.isEmpty(this.state.fleetPro)) {
            let _fleetProVM = Object.assign({}, objFleetProBasic);
            for (let [key, value] of Object.entries(this.state.fleetPro)) {
                if (_fleetProVM.hasOwnProperty(key)) _fleetProVM[key] = value;
            }
            objData.fleetPro = _fleetProVM;
        }


        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (!isSave && accessToApprove && [fleetAssignStatus.REQUESTED, fleetAssignStatus.PENDINGAPPROVAL].includes(objData.status)) {
            objData.status = fleetAssignStatus.APPROVED;
            objData.approvedBy = localStorage.uid;
            objData.approvedDate = window.firebase.firestore.Timestamp.now();
        }

        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        for (var propName in objData) {
            if (objData[propName] === undefined || objData[propName] === '' || objData[propName] === Object.assign([]) || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!this.newDocID) this.newDocID = this.ref.doc().id;
        if (!objData.documentID) objData.documentID = this.newDocID;

        console.log(objData)

        const updateRef = window.firebase.firestore().collection('fleetProAssign').doc(this.newDocID);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            this.isLoading = null;
            toast.notify(`Fleet ${objData.status} successfully`, {
                duration: 2000
            });
            if (_.isEmpty(docID))
                this.addAssignSummaryOnFleetProDetail(objData)
            else
                this.updateAssignSummaryOnFleetProDetail(objData)
            this.props.handleClose();


        }).catch((error) => {
            console.error("Error updating loan_details: ", error);
        });

    };

    handleReceive = async () => {


        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';
        let fields = Object.assign({}, this.state.fields);

        const { dealersettings } = this.props;
        let _mandStatusFields = dealersettings?.client?.fleetPro?.mandatoryFields?.[fields.assignType]?.split(',');
        _mandStatusFields && _mandStatusFields.filter(e => ['preInspection', 'preOdometer', 'preSignature', 'preNotes'].includes(e)).forEach(key => {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        })

        if (fields.odometer && fields.preOdometer && parseInt(fields.odometer) > parseInt(fields.preOdometer)) {
            formIsValid = false;
            errors.preOdometer = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'End odometer reading should be equal or greater than the current odometer reading'), '', 'info')
        }

        //console.log('errors', _mandStatusFields, errors)
        this.setState({ errors: errors });
        if (formIsValid === true) {

            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, `Do you want mark this fleet as received.`),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            }).then(result => {
                if (result.value) {
                    this.isLoading = fleetAssignStatus.RECEIVED;
                    fields.status = fleetAssignStatus.RECEIVED;
                    fields.receivedBy = localStorage.uid;
                    fields.receivedDate = window.firebase.firestore.Timestamp.now();
                    fields.modifiedBy = localStorage.uid;
                    fields.modifiedDate = window.firebase.firestore.Timestamp.now();
                    fields.modifiedFrom = 'web';
                    fields = CommonHelper.removeEmptyOrNull(fields);
                    const updateRef = window.firebase.firestore().collection('fleetProAssign').doc(this.newDocID);
                    updateRef.set(fields, { merge: true }).then((docRef) => {
                        this.isLoading = null;

                        this.updateAssignSummaryOnFleetProDetail(fields)
                        toast.notify(`Fleet ${fields.status} successfully`, { duration: 2000 });
                        this.props.handleClose();

                    }).catch(error => {
                        console.error('Error updating enquiries: ', error);
                    });
                }
            })

        }
        else {
            //if (this.state.activeTag !== fleetAssignStatus.RECEIVED) this.setState({ activeTag: fleetAssignStatus.RECEIVED });
            return formIsValid;
        }


    };

    handleReturn = async () => {


        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';
        let fields = Object.assign({}, this.state.fields);


        const { dealersettings } = this.props;
        let _postFields = ['postInspection', 'postOdometer', 'postSignature', 'postNotes']
        if ([fleetAssignTypes.LoanVehicle].includes(fields.assignType)) _postFields.push('endDate')
        let _mandStatusFields = dealersettings?.client?.fleetPro?.mandatoryFields?.[fields.assignType]?.split(',');
        _mandStatusFields && _mandStatusFields.filter(e => _postFields.includes(e)).forEach(key => {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        })
        if (fields.odometer && fields.postOdometer && parseInt(fields.odometer) > parseInt(fields.postOdometer)) {
            formIsValid = false;
            errors.postOdometer = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'End odometer reading should be equal or greater than the current odometer reading'), '', 'info')
        }

        //console.log('errors', _mandStatusFields, errors)
        this.setState({ errors: errors });
        if (formIsValid === true) {

            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, `Do you want mark this fleet as returned.`),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            }).then(result => {
                if (result.value) {
                    this.isLoading = fleetAssignStatus.RELEASED;
                    fields.status = fleetAssignStatus.RELEASED;
                    fields.releasedBy = localStorage.uid;
                    fields.releasedDate = window.firebase.firestore.Timestamp.now();
                    fields.modifiedBy = localStorage.uid;
                    fields.modifiedDate = window.firebase.firestore.Timestamp.now();
                    fields.modifiedFrom = 'web';
                    fields = CommonHelper.removeEmptyOrNull(fields);
                    const updateRef = window.firebase.firestore().collection('fleetProAssign').doc(this.newDocID);
                    updateRef.set(fields, { merge: true }).then((docRef) => {
                        this.isLoading = null;
                        this.updateAssignSummaryOnFleetProDetail(fields)

                        toast.notify(`Fleet ${fields.status} successfully`, { duration: 2000 });
                        this.props.handleClose();

                    }).catch(error => {
                        console.error('Error updating enquiries: ', error);
                    });
                }
            })

        }
        else {
            //if (this.state.activeTag !== fleetAssignStatus.RELEASED) this.setState({ activeTag: fleetAssignStatus.RELEASED });
            return formIsValid;
        }


    };


    saveAssignField = (name, val, noToast, isReopen) => {

        this.isLoading = val;
        let objData = {};
        let state = Object.assign({}, this.state.fields);

        if (name === 'status') {
            if (val === fleetAssignStatus.APPROVED) {
                objData.approvedBy = localStorage.uid;
                objData.approvedDate = window.firebase.firestore.Timestamp.now();
                if (isReopen) {
                    objData.assignBy = localStorage.uid;
                    objData.assignDate = window.firebase.firestore.Timestamp.now();
                }
            }
            else if (val === fleetAssignStatus.PENDINGAPPROVAL) {
                objData.assignBy = localStorage.uid;
                objData.assignDate = window.firebase.firestore.Timestamp.now();
            }
            else if (val === fleetAssignStatus.REQUESTED) {
                objData.requestedBy = localStorage.uid;
                objData.requestedDate = window.firebase.firestore.Timestamp.now();
            }

        }


        objData[name] = val;
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';



        const updateRef = window.firebase.firestore().collection('fleetProAssign').doc(this.newDocID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.isLoading = null;
            this.setState({ fields: { ...state, ...objData } }, () => {
                if (name === 'status')
                    this.updateAssignSummaryOnFleetProDetail({ ...state, ...objData })
            });
            if (!noToast)
                toast.notify(`Fleet ${name === 'status' ? objData.status : 'updated'} successfully.`, { duration: 2000 });


            this.props.handleClose();

        }).catch(error => {
            console.error('Error updating enquiries: ', error);
        });

    };

    addAssignSummaryOnFleetProDetail = (objData) => {

        let _data = {
            id: objData.documentID,
            documentID: objData.documentID,
            clientID: objData.clientID,
            assignType: objData.assignType,
            endDate: objData.endDate,
            fleetProID: objData.fleetProID,
            startDate: objData.startDate,
            status: objData.status,
            departureDate: objData.departureDate,
            expectedArrivalDate: objData.expectedArrivalDate,
            expectedReturnDate: objData.expectedReturnDate,
        }
        Object.keys(_data).forEach(key => _data[key] === undefined ? delete _data[key] : {});

        window.firebase.firestore().collection('fleetPro').doc(objData.fleetProID)
            .set({ fleetAssign: window.firebase.firestore.FieldValue.arrayUnion(_data) }, { merge: true })
    }

    updateAssignSummaryOnFleetProDetail = (objData) => {
        const { fleetPro } = this.state
        const _fleetAssign = Object.assign([], fleetPro.fleetAssign);
        if ([fleetAssignStatus.CANCELLED, fleetAssignStatus.DECLINED, fleetAssignStatus.RELEASED].includes(fleetPro.status)) {
            _fleetAssign = _fleetAssign.filter(e => e.documentID !== objData.documentID)
        }
        else {
            let _data = {
                id: objData.documentID,
                documentID: objData.documentID,
                clientID: objData.clientID,
                assignType: objData.assignType,
                endDate: objData.endDate,
                fleetProID: objData.fleetProID,
                startDate: objData.startDate,
                status: objData.status,
                departureDate: objData.departureDate,
                expectedArrivalDate: objData.expectedArrivalDate,
                expectedReturnDate: objData.expectedReturnDate,
            }
            Object.keys(_data).forEach(key => _data[key] === undefined ? delete _data[key] : {});

            _fleetAssign.filter(function (obj) {
                //Update old data
                if (obj.documentID === _data.documentID) {
                    for (let [key, value] of Object.entries(_data)) {
                        obj[key] = value;
                    }
                }
            });
        }


        window.firebase.firestore().collection('fleetPro').doc(objData.fleetProID).set({ fleetAssign: _fleetAssign }, { merge: true })
    }

    // handleDiffDays = (_date) => {
    //     const difference = moment().diff(moment.unix(_date.seconds), 'days')
    //     if (difference > 1 || difference < -1)
    //         return difference + ' Days';
    //     else
    //         return difference + ' Day';

    // }

    // handleViewReport = () => {
    //     const { documentID, enquiryID } = this.state.fields;
    //     const url = `/fleet/details/${documentID}`;
    //     window.open(url, '_blank');
    // }

    //#endregion

    //#region SIGN_REQUEST

    handleTermsClick = () => {
        const { docID } = this.props;
        const { fields } = this.state;

        if (fields.email && validateEmail(fields.email) && _.isEmpty(docID) && _.isEmpty(fields.terms))
            this.setState({ showTermsOption: true })
        else
            this.setState({ showBeforeTerms: true })
    }
    handleTermsChange = (type) => {

        if (type === 'sign') {
            this.setState({ showBeforeTerms: true })
        }
        else if (type === 'send') {
            const { requestsTerms, fields } = this.state

            if (_.isEmpty(requestsTerms) && fields.email) {
                const { dealersettings } = this.props;

                let userID = dealersettings ? dealersettings.id : '';
                let currenDate = window.firebase.firestore.Timestamp.now();

                let objData = Object.assign({});
                objData.id = this.ref.doc().id;
                objData.addedBy = userID;
                objData.addedDate = currenDate;
                objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                if (fields.clientID) {
                    objData.clientID = fields.clientID;
                    objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                }
                else {
                    objData.clientID = dealersettings.client.id;
                    objData.clientName = dealersettings.client.name;
                }

                objData.status = 'requested'
                objData.contactID = fields.email;
                objData.contactName = fields.name ? fields.name : ''
                objData.email = fields.email

                if (fields.phone) {
                    objData.phone = fields.phone
                    objData.phoneCode = fields.phoneCode ? fields.phoneCode : ''
                }
                let _fleetTC = CommonHelper.getTermsByField(dealersettings?.client, 'fleetTC', '');
                objData.title = 'Loan Vehicle Terms & Conditions';
                objData.type = 'termsConditions'
                objData.terms =
                {
                    'termsAndConditions': !_.isEmpty(_fleetTC) ? _fleetTC : ''
                }


                const refOnjData = window.firebase.firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
                refOnjData.set(objData, { merge: true }).then((docRef) => {
                    this.unsubscribeTerms = window.firebase.firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
                        .onSnapshot(docSnapshot => {
                            if (docSnapshot.exists) {
                                const _signRequest = Object.assign({}, docSnapshot.data());
                                _signRequest.id = docSnapshot.id;

                                this.setState({
                                    requestsTerms: _signRequest
                                });

                                if (_signRequest.status === 'signed') {
                                    this.unsubscribeTerms && this.unsubscribeTerms();
                                    const fields = Object.assign({}, this.state.fields);
                                    fields.terms = _signRequest.terms;
                                    fields.terms['addedDate'] = window.firebase.firestore.Timestamp.now();
                                    this.setState({ fields: fields });
                                }
                            }
                        })
                }).catch((error) => {
                    console.error("Error updating walkin_details: ", error);
                });

            }
            else {
                this.setState({ showBeforeTerms: true })
                return;
            }

        }

        this.setState({ showTermsOption: false })

    }


    handleInsuranceClick = () => {
        const { docID } = this.props;
        const { fields } = this.state

        // if (fields.contact && fields.contact.documentID && _.isEmpty(docID) && _.isEmpty(fields.insurance))
        //     this.setState({ showInsuranceOption: true })
        // else
        this.setState({ showBeforeInsurance: true })
    }
    handleInsuranceChange = (type) => {

        // if (type === 'sign') {
        //     this.setState({ showBeforeInsurance: true })
        // }
        // else if (type === 'send') {
        //     const { requestsInsurance, fields } = this.state
        //     const _contact = fields.contact;
        //     if (_.isEmpty(requestsInsurance) && _contact && _contact.documentID) {

        //         const { dealersettings } = this.props;

        //         const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
        //             !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
        //             dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
        //             dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
        //             dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
        //         ) ? true : false);

        //         const isAmsMeContact = amsMeEnabled && _contact.amsMe && !_.isEmpty(_contact.amsMe.meID) ? true : false;

        //         let userID = dealersettings ? dealersettings.id : '';
        //         let currenDate = window.firebase.firestore.Timestamp.now();

        //         let objData = Object.assign({});
        //         objData.id = this.ref.doc().id;
        //         objData.addedBy = userID;
        //         objData.addedDate = currenDate;
        //         objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
        //         objData.clientID = dealersettings.client.id;
        //         objData.clientName = dealersettings.client.name;
        //         objData.status = 'requested'
        //         objData.contactID = _contact.documentID;

        //         if (isAmsMeContact)
        //             objData.amsMe = _contact.amsMe
        //         if (_contact.email || _contact.phone) {
        //             objData.contactName = CommonHelper.displayFullContact(null, _contact, '');
        //             objData.email = _contact.email ? _contact.email : ''
        //             objData.phone = _contact.phone ? _contact.phone : ''
        //             objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : ''
        //         } else if (!isAmsMeContact) {
        //             this.setState({ showBeforeInsurance: true })
        //             return;
        //         }

        //         let _insuranceTC = CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', _contact?.language);
        //         objData.title = 'Insurance Terms & Conditions';
        //         objData.type = 'termsConditions'
        //         objData.terms =
        //         {
        //             'termsAndConditions': !_.isEmpty(_insuranceTC) ? _insuranceTC : ''
        //         }


        //         const refOnjData = window.firebase.firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
        //         refOnjData.set(objData, { merge: true }).then((docRef) => {
        //             this.unsubscribeInsurance = window.firebase.firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
        //                 .onSnapshot(docSnapshot => {
        //                     if (docSnapshot.exists) {
        //                         const _signRequest = Object.assign({}, docSnapshot.data());
        //                         _signRequest.id = docSnapshot.id;

        //                         this.setState({
        //                             requestsInsurance: _signRequest
        //                         });

        //                         if (_signRequest.status === 'signed') {
        //                             this.unsubscribeInsurance && this.unsubscribeInsurance();
        //                             const fields = Object.assign({}, this.state.fields);
        //                             fields.insurance = _signRequest.terms;
        //                             fields.insurance['addedDate'] = window.firebase.firestore.Timestamp.now();
        //                             this.setState({ fields: fields });
        //                         }
        //                     }
        //                 })
        //         }).catch((error) => {
        //             console.error("Error updating walkin_details: ", error);
        //         });

        //     }

        // }
        this.setState({ showInsuranceOption: false })
    }


    handleSignClick = () => {
        // const { fields } = this.state

        // if (fields.contact && fields.contact.documentID && _.isEmpty(fields.afterSignatureURL))
        //     this.setState({ showSignOption: true })
        // else
        this.setState({ showAfterSignature: true })
    }
    handleSignChange = (type) => {

        // if (type === 'sign') {
        //     this.setState({ showAfterSignature: true })
        // }
        // else if (type === 'send') {
        //     const { requestsSign, fields } = this.state
        //     const _contact = fields.contact;
        //     if (_.isEmpty(requestsSign) && _contact && _contact.documentID) {

        //         const { dealersettings } = this.props;

        //         const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
        //             !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
        //             dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
        //             dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
        //             dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
        //         ) ? true : false);

        //         const isAmsMeContact = amsMeEnabled && _contact.amsMe && !_.isEmpty(_contact.amsMe.meID) ? true : false;

        //         let userID = dealersettings ? dealersettings.id : '';
        //         let currenDate = window.firebase.firestore.Timestamp.now();
        //         const _selectedStock = !_.isEmpty(this.props.stock) ? this.props.stock : this.state.fields.stock;

        //         let objData = Object.assign({});
        //         objData.id = this.ref.doc().id;
        //         objData.addedBy = userID;
        //         objData.addedDate = currenDate;
        //         objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
        //         objData.clientID = dealersettings.client.id;
        //         objData.clientName = dealersettings.client.name;
        //         objData.status = 'requested'
        //         objData.contactID = _contact.documentID;

        //         if (isAmsMeContact)
        //             objData.amsMe = _contact.amsMe
        //         if (_contact.email || _contact.phone) {
        //             objData.contactName = CommonHelper.displayFullContact(null, _contact, '');
        //             objData.email = _contact.email ? _contact.email : ''
        //             objData.phone = _contact.phone ? _contact.phone : ''
        //             objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : ''
        //         } else if (!isAmsMeContact) {
        //             this.setState({ showAfterSignature: true })
        //             return;
        //         }

        //         objData.title = 'Sign Loan ' + (_selectedStock.make ? _selectedStock.make : '') + ' ' + (_selectedStock.model ? _selectedStock.model : '');
        //         objData.type = 'signOnly';
        //         //objData.testdriveId = this.newDocID;


        //         const refOnjData = window.firebase.firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
        //         refOnjData.set(objData, { merge: true }).then((docRef) => {
        //             this.unsubscribeSign = window.firebase.firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
        //                 .onSnapshot(docSnapshot => {
        //                     if (docSnapshot.exists) {
        //                         const _signRequest = Object.assign({}, docSnapshot.data());
        //                         _signRequest.id = docSnapshot.id;

        //                         this.setState({
        //                             requestsSign: _signRequest
        //                         });

        //                         if (_signRequest.status === 'signed') {
        //                             this.unsubscribeSign && this.unsubscribeSign();
        //                             const fields = Object.assign({}, this.state.fields);
        //                             fields.afterSignatureURL = _signRequest.signature;
        //                             this.setState({ fields: fields });
        //                         }
        //                     }
        //                 })
        //         }).catch((error) => {
        //             console.error("Error updating walkin_details: ", error);
        //         });

        //     }

        // }
        this.setState({ showSignOption: false })
    }

    //#endregion

    //#region  LOCATION AUTOCOMPLETE SEARCH
    bindAddress = (place) => {
        let fields = Object.assign({}, this.state.fields);
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };

        if (place.formatted_address !== null)
            fields['address'] = place.formatted_address;
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];
                if (addressType === "postal_code")
                    fields['areaCode'] = val;

                if (addressType === "administrative_area_level_1")
                    fields['state'] = val;

                if (addressType === "country") {
                    fields['country'] = val;
                }
            }
        }

        this.setState({ fields: fields }, () => {
            this.errorChange('address');
            this.errorChange('areaCode');
            this.errorChange('state');
            this.errorChange('country');
        });

    };

    bindLocation = (place) => {
        let fields = Object.assign({}, this.state.fields);
        if (place.formatted_address !== null)
            fields['location'] = place.formatted_address;
        this.setState({ fields: fields }, () => { this.errorChange('location'); });
    };

    zonesForCountry = (country, address, countryName) => {
        if (country) {
            let timezones = Object.assign([], moment.tz.zonesForCountry(country))
            if (!_.isEmpty(timezones)) {
                //console.log('timezones', timezones)
                if (timezones.length === 1) {
                    return timezones[0];
                }
                else if (address) {
                    let _split = _.uniq(address.replace(',', '').split(' ')).filter(c => (countryName ? c !== countryName : true));
                    let _timezonesList = timezones.filter(e => _split.some(m => e.includes(m)))
                    //console.log('_timezonesList', _timezonesList)
                    if (_timezonesList.length === 1)
                        return _timezonesList[0];
                    else if (_timezonesList.length > 1)
                        return _timezonesList[_timezonesList.length - 1]
                    else return timezones[timezones.length - 1];
                }
                else return timezones[timezones.length - 1];
            }
        }
        return null

    }
    //#endregion





    loadDealers = () => {
        const { regionID, subregionID, groupID } = this.state.fleetPro
        let _dealers = [];
        let allClients = [];
        if (groupID)
            allClients = CommonHelper.getAllFleetClientsDataByLevel(this.props.dealersettings, 'group', groupID)
        else if (subregionID)
            allClients = CommonHelper.getAllFleetClientsDataByLevel(this.props.dealersettings, 'subregion', subregionID)
        else if (regionID)
            allClients = CommonHelper.getAllFleetClientsDataByLevel(this.props.dealersettings, 'region', regionID)

        if (!_.isEmpty(allClients))
            allClients.forEach(rec => {
                _dealers.push({
                    ...rec,
                    label: rec.name,
                    value: rec.id,
                    data: rec,
                    active: true,
                })
            })

        return _dealers
    }

    handleEventClose = (data) => {
        //console.log('handleEventClose', data)
        if (!_.isEmpty(data)) {
            let fields = Object.assign({}, this.state.fields);
            if (data.documentID) fields.eventsProID = data.documentID;
            if (data.title) fields.eventName = data.title;
            if (data.location) fields.location = data.location;

            if (data.startDate) fields.startDate = data.startDate;
            if (data.endDate) fields.endDate = data.endDate;
            this.setState({ fields: fields }, () => { });
        }
        this.setState({ showEventListModal: false })
    }

    handleremoveEvent = () => {
        let fields = Object.assign({}, this.state.fields);
        fields.eventsProID = null; fields.startDate = null; fields.endDate = null;
        fields.eventName = ''; fields.location = '';
        this.setState({ fields: fields }, () => { });
    }
    //#endregion

    render() {
        const { show, docID, dealersettings, clientUsers, groupUsers } = this.props;
        const { errors, notesPopOver, fleetPro, showEventListModal, editApprovedBooking } = this.state;
        const { eventsProID, assignType, startDate, endDate,
            inspection, notes, odometer, terms, requestNotes,
            preInspection, preNotes, preOdometer, preSignature,
            postInspection, postNotes, postOdometer, postSignature,
            status, clientID, typeOfActivity,
            name, email, phone, companyName, address,
            eventName, location, purpose, eventType,
            methodOfTransport, transportCompany, malTechnicalSupportReq,
            departureDate, expectedArrivalDate, expectedReturnDate,
            addedBy, requestedBy, assignBy, approvedBy, receivedBy, releasedBy, modifiedBy, receivedByName, releasedByName,
            requestedDate, assignDate, approvedDate, receivedDate, releasedDate, modifiedDate
        } = this.state.fields;
        let _currentStatus = editApprovedBooking ? null : status;
        const _allUser = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
        const { requestsTerms, requestsInsurance, requestsSign, showTermsOption, activeTag,
            showInspection, showSignature, fieldOld } = this.state;
        const isTermsRequested = requestsTerms && requestsTerms.status === 'requested' ? true : false;
        const isInsuranceRequested = requestsInsurance && requestsInsurance.status === 'requested' ? true : false;
        const isSignRequested = requestsSign && requestsSign.status === 'requested' ? true : false;

        const fleetProAllStatus = Object.assign([], dealersettings?.client?.fleetPro?.fleetProStatus)
        let _fleetStatus = CommonHelper.getFleetStatus(fleetPro);
        let _objCurrentStatus = _fleetStatus && fleetProAllStatus.filter(e => e.value === _fleetStatus)[0];

        const _permissions = dealersettings?.rolePermissions?.fleetProPermissions;

        const readOnlyFleetVehicle = ((!_.isEmpty(_permissions) && _permissions.readOnlyFleetVehicle) ? true : false);
        // const accessToAddVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToAddVehicle && !readOnlyFleetVehicle) ? true : false);
        // const accessToEditVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToEditVehicle && !readOnlyFleetVehicle) ? true : false);
        // const accessToDeleteVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToDeleteVehicle && !readOnlyFleetVehicle) ? true : false);
        // const accessToEditDefleetDate = ((!_.isEmpty(_permissions) && _permissions.accessToEditDefleetDate && !readOnlyFleetVehicle) ? true : false);
        // const accessToVehicleScheduler = ((!_.isEmpty(_permissions) && _permissions.accessToVehicleScheduler && !readOnlyFleetVehicle) ? true : false);
        // const accessToUpdateFleetStatus = ((!_.isEmpty(_permissions) && _permissions.accessToUpdateFleetStatus && !readOnlyFleetVehicle) ? true : false);
        // const accessToScheduleTransit = ((!_.isEmpty(_permissions) && _permissions.accessToScheduleTransit && !readOnlyFleetVehicle) ? true : false);
        const accessToAssign = ((!_.isEmpty(_permissions) && _permissions.accessToAssign && !readOnlyFleetVehicle) ? true : false);
        const accessToRequest = ((!_.isEmpty(_permissions) && _permissions.accessToRequest && !readOnlyFleetVehicle) ? true : false);
        const accessToApprove = ((!_.isEmpty(_permissions) && _permissions.accessToApprove && !readOnlyFleetVehicle) ? true : false);
        const accessToReturn = ((!_.isEmpty(_permissions) && _permissions.accessToReturn && !readOnlyFleetVehicle) ? true : false);
        const accessToReceive = ((!_.isEmpty(_permissions) && _permissions.accessToReceive && !readOnlyFleetVehicle) ? true : false);
        // const accessToMarketingFleet = ((!_.isEmpty(_permissions) && _permissions.accessToMarketingFleet) ? true : false);
        // const accessToPRFleet = ((!_.isEmpty(_permissions) && _permissions.accessToPRFleet) ? true : false);
        // const accessToMSOAssest = ((!_.isEmpty(_permissions) && _permissions.accessToMSOAssest) ? true : false);
        // const accessToFleetHistory = ((!_.isEmpty(_permissions) && _permissions.accessToFleetHistory) ? true : false);
        // const accessToViewAllocatedFleet = ((!_.isEmpty(_permissions) && _permissions.accessToViewAllocatedFleet) ? true : false);
        // const accessToViewFOKFleet = ((!_.isEmpty(_permissions) && _permissions.accessToViewFOKFleet) ? true : false);
        // const accessToOnFleetingVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToOnFleetingVehicle) ? true : false);
        // const accessToOperationalVehicle = ((!_.isEmpty(_permissions) && _permissions.accessToOperationalVehicle) ? true : false);
        // const accessToDeFleetVehicles = ((!_.isEmpty(_permissions) && _permissions.accessToDeFleetVehicles) ? true : false);

        //let _handles = ['info']

        let _isDeclined = [fleetAssignStatus.CANCELLED, fleetAssignStatus.DECLINED].includes(_currentStatus) ? true : false;
        let _isApproved = !_.isEmpty(fleetAssignStatusLevel.filter(e => e.value == _currentStatus && e.index >= _.find(fleetAssignStatusLevel, { value: fleetAssignStatus.APPROVED })?.index)[0]) ? true : false;
        let _isBookedOrReceived = !_.isEmpty(fleetAssignStatusLevel.filter(e => e.value == _currentStatus && e.index >= _.find(fleetAssignStatusLevel, { value: fleetAssignStatus.RECEIVED })?.index)[0]) ? true : false;
        let _isCompletedOrReleased = !_.isEmpty(fleetAssignStatusLevel.filter(e => e.value == _currentStatus && e.index >= _.find(fleetAssignStatusLevel, { value: fleetAssignStatus.RELEASED })?.index)[0]) ? true : false;

        //if (_isApproved && [fleetAssignTypes.RetailerEvent].includes(assignType)) _handles.push(fleetAssignStatus.RECEIVED)
        //if (_isBookedOrReceived || (_isApproved && [fleetAssignTypes.LoanVehicle, fleetAssignTypes.ExternalInternalEvent].includes(assignType))) _handles.push(fleetAssignStatus.RELEASED)
        //console.log(_isDeclined, _isApproved, _isBookedOrReceived, _isCompletedOrReleased)
        let _canModify = (readOnlyFleetVehicle || _isDeclined) ? false : true;
        let _approvedClass = _isApproved || !_canModify ? 'form-lite' : ''
        let _BookedClass = _isBookedOrReceived || !_canModify ? 'form-lite' : ''
        let _completedClass = _isCompletedOrReleased || !_canModify ? 'form-lite' : ''

        return (

            this.newDocID
                ?
                <>
                    <Modal
                        id="add-fleet-pro-assign"
                        show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName={`modal-dialog-scrollable modal-dialog-centered modal-assign-fleet`}>

                        <Modal.Header closeButton>

                            <Modal.Title className="h4 w-100">
                                <h5 className="modal-title" ><Translate text={this.props.title ? this.props.title : 'Assign Vehicle'} />
                                    {_currentStatus ? <div className={`badge badge-pill badge-status-${_currentStatus.trim().toLowerCase()} btn-fix-assign`}>
                                        {_currentStatus.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
                                    </div> : <></>}

                                    {
                                        dealersettings.level !== userLevel.INDIVIDUAL && <div className={`float-right assign-fleet-control`}>
                                            {
                                                _isApproved || requestedBy
                                                    ?
                                                    <>
                                                        <div title='Assign Type' className="badge badge-pill badge-fleetpro-white ml-1 mt-2">
                                                            {CommonHelper.getLabelByValue(assign_types, assignType)}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            assign_types.filter(e => fleetPro.category !== fleetProCategory.MARKETING ? e.value !== fleetAssignTypes.RetailerEvent : true).map((data, index) => {
                                                                return <div key={index} className={`radio icheck-success float-left mr-3`}>
                                                                    <input
                                                                        type="radio"
                                                                        id={`${data.value}_${index}`}
                                                                        name={`${data.value}_${index}`}
                                                                        className="uncheck-activity"
                                                                        checked={assignType === data.value ? true : false}
                                                                        onChange={(e) => {
                                                                            if (!_isApproved && !requestedBy)
                                                                                this.handleAssignTypeChange(e, data.value)
                                                                        }} />
                                                                    <label htmlFor={`${data.value}_${index}`}>{data.label}</label>
                                                                </div>
                                                            })
                                                        }
                                                    </>
                                            }

                                        </div>

                                    }

                                </h5>
                            </Modal.Title>

                        </Modal.Header>
                        <Modal.Body>

                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-md-3 mb-2">
                                        <div className="assign-fleet-left form-style">
                                            <h3>Vehicle Details</h3>
                                            <div className="vehicle-assign-fleet-list mt-1 mb-3">
                                                <div className="vehicle-assign-fleet-image">
                                                    {
                                                        (!_.isEmpty(fleetPro?.images))
                                                            ?
                                                            <>
                                                                {
                                                                    fleetPro.images.map((data, index) => (
                                                                        <a key={index} data-fancybox={`fleet_pro_info_img_slider`} className={index === 0 ? '' : 'hide'} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                            {index === 0 ?
                                                                                <>
                                                                                    <div className="zoom-ico"><i className="ico icon-zoom"></i></div>
                                                                                    <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                                </>
                                                                                : <></>}

                                                                        </a>
                                                                    ))
                                                                }
                                                            </>
                                                            :
                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                <img src={CommonHelper.showBrandLogo(dealersettings, fleetPro?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                            </a>
                                                    }

                                                </div>
                                                <div className="vehicle-assign-fleet-data ">
                                                    <div className="vehicle-assign-fleet-title">{fleetPro?.make} {fleetPro?.model}</div>
                                                    <div className="vehicle-assign-fleet-info">{fleetPro?.extColor ? `${fleetPro?.extColor}, ` : ''} {fleetPro?.year ? fleetPro?.year : ''}</div>
                                                    <div className="vehicle-assign-fleet-info mt-1">
                                                        {
                                                            _objCurrentStatus ? (<div className={`badge badge-pill badge-status-empty float-left`}
                                                                style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                            >
                                                                {_objCurrentStatus.name}
                                                            </div>) : <></>
                                                        }
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <label ><Translate text={'Category'} /> </label>
                                                <InputText
                                                    type="text"
                                                    name="category"
                                                    value={CommonHelper.getNameByValue(dealersettings.client?.fleetPro?.category, fleetPro?.category, '')}
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    placeholder={'category'}
                                                    className={`form-control btn-disable`}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label ><Translate text={'vinNo'} /> </label>
                                                <InputText
                                                    type="text"
                                                    name="vinNo"
                                                    value={fleetPro?.vinNo}
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    placeholder={'VIN number'}
                                                    className={`form-control btn-disable`}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label ><Translate text={'regNo'} /> </label>
                                                <InputText
                                                    type="text"
                                                    name="regNo"
                                                    value={fleetPro?.regNo}
                                                    readOnly={true}
                                                    autoComplete="off"
                                                    placeholder={'registration number'}
                                                    className={`form-control btn-disable`}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label ><Translate text={'mileage'} /></label>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    id={'mileage'}
                                                    thousandSeparator={true}
                                                    placeholder={'mileage'}
                                                    className={`form-control`}
                                                    value={fleetPro?.mileage ? fleetPro?.mileage : null}
                                                />
                                            </div>

                                            {/* <div className="form-group">
                                                <label>Drive </label>
                                                <input type="text" className="form-control" id="" placeholder="" />
                                            </div>

                                            <div className="form-group">
                                                <label>Production Type </label>
                                                <input type="text" className="form-control" id="" placeholder="" />
                                            </div>

                                            <div className="form-group">
                                                <label>Defleet Date </label>
                                                <input type="text" className="form-control" id="" placeholder="" />
                                            </div> */}
                                        </div>

                                    </div>



                                    <div className="col-md-9 pl-0 form-style mb-2">

                                        {
                                            [fleetAssignTypes.RetailerEvent].includes(assignType) && (
                                                <>
                                                    {
                                                        _approvedClass
                                                            ?
                                                            <div className="assign-fleet-readonly-box">
                                                                <h3> <i className="ico icon-dealers"></i> {CommonHelper.getOtherDealerName(dealersettings, clientID)}</h3>

                                                                <div className="float-right fleetpro-activity-title">Type of Activity :&nbsp;
                                                                    {CommonHelper.getNameByValue(dealersettings.client?.fleetPro?.typeOfActivity, typeOfActivity, '--')}</div>
                                                            </div>
                                                            :
                                                            <div className="assign-fleet-right">
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label className="assign-fleet-label">Dealership</label>
                                                                        <ReactSelect
                                                                            options={this.loadDealers()}
                                                                            name={'clientID'}
                                                                            onChange={this.handleReactSelectChange}
                                                                            value={clientID ? clientID : null}
                                                                            classNamePrefix={`${errors["clientID"]} basic-select`}
                                                                            removeClearable={true}
                                                                            isSearchable={true}
                                                                            isDisabled={_canModify ? _isApproved : true}
                                                                        ></ReactSelect>
                                                                    </div>

                                                                    <div className="form-group col-md-6">
                                                                        <label className="assign-fleet-label">Type of Activity</label>
                                                                        <ReactSelect
                                                                            options={(!_.isEmpty(dealersettings.client?.fleetPro?.typeOfActivity) ?
                                                                                _.map(dealersettings.client?.fleetPro?.typeOfActivity, function (e) {
                                                                                    return {
                                                                                        label: e.name,
                                                                                        value: e.value,
                                                                                        active: _.isBoolean(e.active) ? e.active : true
                                                                                    }
                                                                                }) : [])}
                                                                            name={'typeOfActivity'}
                                                                            onChange={this.handleReactSelectChange}
                                                                            value={typeOfActivity ? typeOfActivity : null}
                                                                            classNamePrefix={`${errors["typeOfActivity"]} basic-select`}
                                                                            removeClearable={true}
                                                                            isSearchable={true}
                                                                            isDisabled={_canModify ? _isApproved : true}
                                                                        ></ReactSelect>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </>

                                            )
                                        }
                                        {/* {
                                            docID && _handles.length > 1 && (<div className="fleetpro-tabber-nav pl-0">
                                                <ul className="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
                                                    {
                                                        _handles.map((rec, index) => {
                                                            return <li key={index} className="nav-item flex-sm-fill">
                                                                <a href="#"
                                                                    id={rec}
                                                                    className={`nav-link border-0 text-uppercase font-weight-bold ${activeTag === rec ? 'active' : ''}`}
                                                                    onClick={(event) => {
                                                                        if (event.target.id) {
                                                                            this.setState({ activeTag: event.target.id })
                                                                        }
                                                                        event.preventDefault();
                                                                    }}
                                                                ><Translate text={rec} />
                                                                </a>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>)
                                        } */}


                                        <div className="assign-fleet-middle">
                                            <div className="assign-fleet-middle-head">
                                                <div className=" float-left">
                                                    <h3>Fleet Info </h3>
                                                </div>

                                                {
                                                    dealersettings.level !== userLevel.INDIVIDUAL && !_approvedClass && (<div className=" float-right">

                                                        <button className={`btn btn-sm btn-default  ml-1 ${errors["inspection"]}`} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({ showInspection: { show: true, id: 'inspection', marks: inspection, isReadOnly: (_isCompletedOrReleased || (_isApproved && !_.isEmpty(fieldOld.inspection))) ? true : false } })
                                                        }}>
                                                            {!_.isEmpty(inspection) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                            <Translate text={'inspection'} />
                                                        </button>


                                                        <button className={`btn btn-sm btn-default  ml-1 ${errors["terms"]}`} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.handleTermsClick();
                                                        }}>
                                                            {!_.isEmpty(terms) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                            {
                                                                isTermsRequested
                                                                    ?
                                                                    <>
                                                                        <span className="spinner-border spinner-button blue-color mr-2 ml-0 mt-0" role="status" aria-hidden="true"></span>
                                                                        <Translate text={'Waiting for response'} />
                                                                    </>
                                                                    :
                                                                    <><i className="ico icon-terms mr-1"></i><Translate text={'termsAndConditions'} /> </>
                                                            }
                                                        </button>
                                                    </div>)
                                                }

                                            </div>

                                            {
                                                !_approvedClass && (<>
                                                    <div className="assign-fleet-section-head"><h4>Responsible Person</h4></div>

                                                    <div className={`assign-fleet-form ${_approvedClass}`}>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label ><Translate text={'name'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'name'}
                                                                    className={`form-control ${errors["name"]}`}
                                                                    name="name"
                                                                    onChange={this.handleOnNameChange}
                                                                    value={name ? name : ''}
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label ><Translate text={'email'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'email'}
                                                                    className={`form-control ${errors["email"]}`}
                                                                    name="email"
                                                                    onChange={this.handleOnNameChange}
                                                                    value={email ? email : ''}
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label ><Translate text={'phone'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'phone'}
                                                                    pattern="[0-9]*"
                                                                    className={`form-control ${errors["phone"]}`}
                                                                    name="phone"
                                                                    onChange={this.onHandleTelephoneChange}
                                                                    value={phone ? phone : ''}
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label ><Translate text={'company'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'company'}
                                                                    className={`form-control ${errors["companyName"]}`}
                                                                    name="companyName"
                                                                    onChange={this.handleOnNameChange}
                                                                    value={companyName ? companyName : ''}
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label ><Translate text={'address'} /></label>
                                                                <AutoComplete
                                                                    className={`form-control ${errors["address"]}`}
                                                                    bindAddress={this.bindAddress}
                                                                    types={['address']}
                                                                    value={address ? address : ''}
                                                                    placeholder={'search address'}
                                                                    onChange={this.handleOnChange}
                                                                    componentRestrictions={{ country: [] }}
                                                                    name="address"
                                                                />
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="assign-fleet-section-head">
                                                        {
                                                            [fleetAssignTypes.LoanVehicle].includes(assignType)
                                                                ?
                                                                <><h4>Loan Details</h4></>
                                                                :
                                                                <><h4>Event Details</h4></>
                                                        }

                                                        {
                                                            [fleetAssignTypes.RetailerEvent, fleetAssignTypes.ExternalInternalEvent].includes(assignType) && clientID && !_isApproved && _canModify && (
                                                                <div className="float-right fleetpro-eventselect-btn">
                                                                    {
                                                                        eventsProID
                                                                            ?
                                                                            <>
                                                                                <button className="btn btn-sm btn-red" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    Swal.fire({
                                                                                        title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                                                                                        text: CommonHelper.showLocale(this.props, `Do you want to remove this event.`),
                                                                                        icon: 'info',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                                                                                        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                                                                                    }).then(result => {
                                                                                        if (result.value) {
                                                                                            this.handleremoveEvent();
                                                                                        }
                                                                                    })

                                                                                }}>
                                                                                    <i className="ico icon-events-event"></i> Remove Event </button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <button className="btn btn-sm btn-secondary" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({ showEventListModal: true })
                                                                                }}>
                                                                                    <i className="ico icon-events-event"></i> Select Event </button>
                                                                            </>
                                                                    }

                                                                </div>
                                                            )
                                                        }

                                                    </div>

                                                    <div className={`assign-fleet-form ${_approvedClass}`}>
                                                        <div className="form-row">
                                                            {
                                                                [fleetAssignTypes.RetailerEvent, fleetAssignTypes.ExternalInternalEvent].includes(assignType) && (
                                                                    <div className="form-group col-md-4">
                                                                        <label>Event Name</label>
                                                                        <InputText
                                                                            autoComplete="off"
                                                                            placeholder={'event name'}
                                                                            className={`form-control ${errors["eventName"]}`}
                                                                            name="eventName"
                                                                            onChange={this.handleOnNameChange}
                                                                            value={eventName ? eventName : ''}
                                                                        />
                                                                    </div>
                                                                )
                                                            }


                                                            <div className="form-group col-md-2">
                                                                <label ><Translate text={'startDate'} /></label>
                                                                <AntDatePicker
                                                                    value={startDate ? moment.unix(startDate.seconds).format('YYYY-MM-DD') : null}
                                                                    name={'startDate'}
                                                                    onChange={(e) => { this.handleFirebaseDateChange(e, 'startDate') }}
                                                                    format='DD/MM/YYYY'
                                                                    placeholder='DD/MM/YYYY'
                                                                    className={`form-control ${errors["startDate"]}`}
                                                                    allowClear={false}
                                                                    disabledDate={this.minStartDate}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-2">
                                                                <label>Start Time</label>
                                                                <div className='inner-addon right-addon'>
                                                                    <i className='bg-icons ico icon-time'></i>
                                                                    <ReactTimePicker
                                                                        value={startDate ? moment.unix(startDate.seconds)._d : null}
                                                                        name={'startDate'}
                                                                        onChange={this.handleFirebaseTimeChange}
                                                                        timeIntervals={15}
                                                                        placeholder='h:mm aa'
                                                                        className={`form-control`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                [fleetAssignTypes.LoanVehicle].includes(assignType) && (
                                                                    <>
                                                                        <div className="form-group col-md-2">
                                                                            <label>Expected Return Date</label>
                                                                            <AntDatePicker
                                                                                value={expectedReturnDate ? moment.unix(expectedReturnDate.seconds).format('YYYY-MM-DD') : null}
                                                                                name={'expectedReturnDate'}
                                                                                onChange={(e) => { this.handleFirebaseDateChange(e, 'expectedReturnDate') }}
                                                                                format='DD/MM/YYYY'
                                                                                placeholder='DD/MM/YYYY'
                                                                                className={`form-control ${errors["expectedReturnDate"]}`}
                                                                                allowClear={false}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group col-md-2">
                                                                            <label>Expected Return Time</label>
                                                                            <div className='inner-addon right-addon'>
                                                                                <i className='bg-icons ico icon-time'></i>
                                                                                <ReactTimePicker
                                                                                    value={expectedReturnDate ? moment.unix(expectedReturnDate.seconds)._d : null}
                                                                                    name={'expectedReturnDate'}
                                                                                    onChange={this.handleFirebaseTimeChange}
                                                                                    timeIntervals={15}
                                                                                    placeholder='h:mm aa'
                                                                                    className={`form-control`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                [fleetAssignTypes.RetailerEvent, fleetAssignTypes.ExternalInternalEvent].includes(assignType) && (
                                                                    <>
                                                                        <div className="form-group col-md-2">
                                                                            <label>End Date</label>
                                                                            <AntDatePicker
                                                                                value={endDate ? moment.unix(endDate.seconds).format('YYYY-MM-DD') : null}
                                                                                name={'endDate'}
                                                                                onChange={(e) => { this.handleFirebaseDateChange(e, 'endDate') }}
                                                                                format='DD/MM/YYYY'
                                                                                placeholder='DD/MM/YYYY'
                                                                                className={`form-control ${errors["endDate"]}`}
                                                                                allowClear={false}
                                                                                disabledDate={this.minEndDate}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-2">
                                                                            <label>End Time</label>
                                                                            <div className='inner-addon right-addon'>
                                                                                <i className='bg-icons ico icon-time'></i>
                                                                                <ReactTimePicker
                                                                                    value={endDate ? moment.unix(endDate.seconds)._d : null}
                                                                                    name={'endDate'}
                                                                                    onChange={this.handleFirebaseTimeChange}
                                                                                    timeIntervals={15}
                                                                                    placeholder='h:mm aa'
                                                                                    className={`form-control`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }




                                                            <div className="form-group col-md-4">
                                                                <label ><Translate text={'location'} /></label>
                                                                <AutoComplete
                                                                    className={`form-control ${errors["location"]}`}
                                                                    bindAddress={this.bindLocation}
                                                                    types={['address']}
                                                                    value={location ? location : ''}
                                                                    placeholder={'search location'}
                                                                    onChange={this.handleOnChange}
                                                                    componentRestrictions={{ country: [] }}
                                                                    name="location"
                                                                />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label>Purpose</label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'purpose'}
                                                                    className={`form-control ${errors["purpose"]}`}
                                                                    name="purpose"
                                                                    onChange={this.handleOnChange}
                                                                    value={purpose ? purpose : ''}
                                                                />
                                                            </div>

                                                            {
                                                                dealersettings.level !== userLevel.INDIVIDUAL && (<div className="form-group col-md-4">
                                                                    <label ><Translate text={'currentOdometerReading'} /></label>
                                                                    <NumberFormat
                                                                        id={'odometer'}
                                                                        thousandSeparator={true}
                                                                        placeholder={'current odometer reading'}
                                                                        className={`form-control ${errors["odometer"]}`}
                                                                        onValueChange={this.onCurrencyChange.bind(this, 'odometer')}
                                                                        value={odometer ? odometer : null}
                                                                    />
                                                                </div>)
                                                            }


                                                            {
                                                                [fleetAssignTypes.ExternalInternalEvent].includes(assignType) && (
                                                                    <>
                                                                        <div className="form-group col-md-4">
                                                                            <label>Event Type</label>
                                                                            <ReactSelect
                                                                                options={event_Types}
                                                                                name={'eventType'}
                                                                                onChange={this.handleReactSelectChange}
                                                                                value={eventType ? eventType : null}
                                                                                classNamePrefix={`${errors["eventType"]} basic-select`}
                                                                                removeClearable={true}
                                                                                isSearchable={true}
                                                                            ></ReactSelect>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }

                                                        </div>
                                                    </div>


                                                    {
                                                        dealersettings.level !== userLevel.INDIVIDUAL && [fleetAssignTypes.RetailerEvent].includes(assignType) && (<>
                                                            <div className="assign-fleet-section-head"><h4>Transit Scheduler</h4></div>

                                                            <div className={`assign-fleet-form`}>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-4">
                                                                        <label>Method of Transport</label>
                                                                        <ReactSelect
                                                                            options={(!_.isEmpty(dealersettings.client?.fleetPro?.methodOfTransport) ?
                                                                                _.map(dealersettings.client?.fleetPro?.methodOfTransport, function (e) {
                                                                                    return {
                                                                                        label: e.name,
                                                                                        value: e.value,
                                                                                        active: _.isBoolean(e.active) ? e.active : true
                                                                                    }
                                                                                }) : [])}
                                                                            name={'methodOfTransport'}
                                                                            onChange={this.handleReactSelectChange}
                                                                            value={methodOfTransport ? methodOfTransport : null}
                                                                            classNamePrefix={`${errors["methodOfTransport"]} basic-select`}
                                                                            removeClearable={true}
                                                                            isSearchable={true}
                                                                        ></ReactSelect>
                                                                    </div>

                                                                    <div className="form-group col-md-2">
                                                                        <label>Departure Date</label>
                                                                        <AntDatePicker
                                                                            value={departureDate ? moment.unix(departureDate.seconds).format('YYYY-MM-DD') : null}
                                                                            name={'departureDate'}
                                                                            onChange={(e) => { this.handleFirebaseDateChange(e, 'departureDate') }}
                                                                            format='DD/MM/YYYY'
                                                                            placeholder='DD/MM/YYYY'
                                                                            className={`form-control ${errors["departureDate"]}`}
                                                                            allowClear={false}
                                                                        />
                                                                    </div>

                                                                    <div className="form-group col-md-2">
                                                                        <label>Departure Time</label>
                                                                        <div className='inner-addon right-addon'>
                                                                            <i className='bg-icons ico icon-time'></i>
                                                                            <ReactTimePicker
                                                                                value={departureDate ? moment.unix(departureDate.seconds)._d : null}
                                                                                name={'departureDate'}
                                                                                onChange={this.handleFirebaseTimeChange}
                                                                                timeIntervals={15}
                                                                                placeholder='h:mm aa'
                                                                                className={`form-control`}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group col-md-2">
                                                                        <label>Expected Arrival Date</label>
                                                                        <AntDatePicker
                                                                            value={expectedArrivalDate ? moment.unix(expectedArrivalDate.seconds).format('YYYY-MM-DD') : null}
                                                                            name={'expectedArrivalDate'}
                                                                            onChange={(e) => { this.handleFirebaseDateChange(e, 'expectedArrivalDate') }}
                                                                            format='DD/MM/YYYY'
                                                                            placeholder='DD/MM/YYYY'
                                                                            className={`form-control ${errors["expectedArrivalDate"]}`}
                                                                            allowClear={false}
                                                                        />
                                                                    </div>

                                                                    <div className="form-group col-md-2">
                                                                        <label>Expected Arrival Time</label>
                                                                        <div className='inner-addon right-addon'>
                                                                            <i className='bg-icons ico icon-time'></i>
                                                                            <ReactTimePicker
                                                                                value={expectedArrivalDate ? moment.unix(expectedArrivalDate.seconds)._d : null}
                                                                                name={'expectedArrivalDate'}
                                                                                onChange={this.handleFirebaseTimeChange}
                                                                                timeIntervals={15}
                                                                                placeholder='h:mm aa'
                                                                                className={`form-control`}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group col-md-2">
                                                                        <label>Expected Return Date</label>
                                                                        <AntDatePicker
                                                                            value={expectedReturnDate ? moment.unix(expectedReturnDate.seconds).format('YYYY-MM-DD') : null}
                                                                            name={'expectedReturnDate'}
                                                                            onChange={(e) => { this.handleFirebaseDateChange(e, 'expectedReturnDate') }}
                                                                            format='DD/MM/YYYY'
                                                                            placeholder='DD/MM/YYYY'
                                                                            className={`form-control ${errors["expectedReturnDate"]}`}
                                                                            allowClear={false}
                                                                        />
                                                                    </div>

                                                                    <div className="form-group col-md-2">
                                                                        <label>Expected Return Time</label>
                                                                        <div className='inner-addon right-addon'>
                                                                            <i className='bg-icons ico icon-time'></i>
                                                                            <ReactTimePicker
                                                                                value={expectedReturnDate ? moment.unix(expectedReturnDate.seconds)._d : null}
                                                                                name={'expectedReturnDate'}
                                                                                onChange={this.handleFirebaseTimeChange}
                                                                                timeIntervals={15}
                                                                                placeholder='h:mm aa'
                                                                                className={`form-control`}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group col-md-4">
                                                                        <label>Transport Company</label>
                                                                        <ReactSelect
                                                                            options={(!_.isEmpty(dealersettings.client?.fleetPro?.transportCompany) ?
                                                                                _.map(dealersettings.client?.fleetPro?.transportCompany, function (e) {
                                                                                    return {
                                                                                        label: e.name,
                                                                                        value: e.value,
                                                                                        active: _.isBoolean(e.active) ? e.active : true
                                                                                    }
                                                                                }) : [])}
                                                                            name={'transportCompany'}
                                                                            onChange={this.handleReactSelectChange}
                                                                            value={transportCompany ? transportCompany : null}
                                                                            classNamePrefix={`${errors["transportCompany"]} basic-select`}
                                                                            removeClearable={true}
                                                                            isSearchable={true}
                                                                        ></ReactSelect>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="assign-fleet-section-head"><h4>MAL Technical Support Required?</h4></div>


                                                            <div className={`assign-fleet-form ${_approvedClass}`}>
                                                                <div className="radio icheck-success float-left">
                                                                    <InputRadio
                                                                        id="mal_yes"
                                                                        type="radio"
                                                                        value="yes"
                                                                        name="malTechnicalSupportReq"
                                                                        checked={malTechnicalSupportReq === true ? true : false}
                                                                        onChange={this.handleRadioChange}
                                                                    />
                                                                    <label htmlFor="mal_yes"><Translate text={'Yes'} /></label>
                                                                </div>

                                                                <div className="radio icheck-success float-left ml-3">
                                                                    <InputRadio
                                                                        id="mal_no"
                                                                        type="radio"
                                                                        value="no"
                                                                        name="malTechnicalSupportReq"
                                                                        checked={malTechnicalSupportReq === false ? true : false}
                                                                        onChange={this.handleRadioChange}
                                                                    />
                                                                    <label htmlFor="mal_no"><Translate text={'No'} /></label>
                                                                </div>

                                                            </div>
                                                        </>)
                                                    }

                                                    {
                                                        !_.isEmpty(inspection) && !_.isEmpty(inspection.filter(a => !_.isEmpty(a.images))[0])
                                                            ?
                                                            <>
                                                                <div className="assign-fleet-section-head"><h4>Vehicle Images</h4></div>
                                                                <div className="modal-assign-fleet-image-wraper">
                                                                    {
                                                                        inspection.map((mark, index) => {
                                                                            return <div key={index}>
                                                                                {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                    <div key={_index} className="modal-assign-fleet-item-image">
                                                                                        <a data-fancybox={`mark-inspection-${index}-${_index}`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                            <img src={data} className="img-object-fit" width="50" height="50" alt="" />
                                                                                        </a>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                    {
                                                        dealersettings.level === userLevel.INDIVIDUAL
                                                            ?
                                                            <>
                                                                <div className="assign-fleet-section-head"><h4>Notes</h4></div>
                                                                <div className={`assign-fleet-form ${_approvedClass}`}>

                                                                    <InputTextArea
                                                                        className={`form-control notes-assign-fleet ${errors["requestNotes"]}`}
                                                                        name="requestNotes"
                                                                        onChange={this.handleOnAreaChange}
                                                                        value={requestNotes ? requestNotes : null}
                                                                        maxLength={5000}
                                                                        rows="4">
                                                                    </InputTextArea>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    requestNotes && (<>
                                                                        <div className="assign-fleet-section-head"><h4>Request Notes</h4></div>
                                                                        <div className={`assign-fleet-form form-lite`}>

                                                                            <InputTextArea
                                                                                className={`form-control notes-assign-fleet ${errors["requestNotes"]}`}
                                                                                name="requestNotes"
                                                                                onChange={() => { }}
                                                                                value={requestNotes ? requestNotes : null}
                                                                                maxLength={5000}
                                                                                rows="4">
                                                                            </InputTextArea>
                                                                        </div>
                                                                    </>)
                                                                }

                                                                <div className="assign-fleet-section-head"><h4>Notes</h4></div>
                                                                <div className={`assign-fleet-form ${_approvedClass}`}>

                                                                    <InputTextArea
                                                                        className={`form-control notes-assign-fleet ${errors["notes"]}`}
                                                                        name="notes"
                                                                        onChange={this.handleOnAreaChange}
                                                                        value={notes ? notes : null}
                                                                        maxLength={5000}
                                                                        rows="4">
                                                                    </InputTextArea>
                                                                </div>
                                                            </>
                                                    }
                                                </>)
                                            }
                                        </div>

                                        {
                                            _approvedClass && (<div className="fleetpro-readonly-middle">

                                                <div className="fleetpro-accordion-innerhead">

                                                    {
                                                        ((!_isBookedOrReceived && !_isCompletedOrReleased && !_isDeclined) && (dealersettings.level !== userLevel.INDIVIDUAL && (accessToAssign || accessToApprove))) && (
                                                            <div className="fleetpro-mini-edit-ico">
                                                                <a href="#" title='Edit' className="mini-button" onClick={(e) => { e.preventDefault(); this.setState({ editApprovedBooking: true }) }}>
                                                                    <i className="ico icon-edit"></i>
                                                                </a>
                                                            </div>
                                                        )
                                                    }

                                                    <h3>
                                                        {eventName ? <> <i className="ico icon-events-event mr-2"></i>{eventName}</> : <></>}
                                                        {eventType && assignType === fleetAssignTypes.ExternalInternalEvent && (<div title='Event Type' className="fleetpro-eventtype-tag">
                                                            <div className="badge badge-pill badge-input-white mr-1">
                                                                {eventType}
                                                            </div>
                                                        </div>)}
                                                    </h3>

                                                    <div className="events-info-card-sub">
                                                        {startDate ? <>
                                                            <i className="ico icon-date mr-2"></i>{CommonHelper.showCombineDate(startDate, ([fleetAssignTypes.LoanVehicle].includes(assignType) && _currentStatus !== fleetAssignStatus.RELEASED ? expectedReturnDate : endDate))}
                                                            <i className="ico icon-time ml-2 mr-2"></i> {CommonHelper.showCombineTime(startDate, ([fleetAssignTypes.LoanVehicle].includes(assignType) && _currentStatus !== fleetAssignStatus.RELEASED ? expectedReturnDate : endDate), null, null)}</> : <></>}
                                                        {purpose && (<> <i className="ico icon-files-document ml-2 mr-2" title='Purpose'></i> {purpose}</>)}
                                                        {odometer ? (<> <i className="ico icon-odometer-ico ml-2 mr-2" title="Odometer"></i> <NumberFormat style={{ fontSize: 12 }} value={odometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /></>) : (<></>)}
                                                        {location && (<> <i className="ico icon-location ml-2 mr-2" title='Location'></i> {location}</>)}
                                                    </div>

                                                    <div className="fleetpro-accordion-approve-bg">
                                                        <div className="fleetpro-accordion-approve">

                                                            {requestedBy && (<span>
                                                                Requested By: <b>{CommonHelper.getUserNamebyId(_allUser, requestedBy)}</b>
                                                                &nbsp;on&nbsp; <b>{requestedDate ? moment.unix(requestedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                            </span>)}

                                                            {assignBy && (<span>
                                                                Assign By: <b>{CommonHelper.getUserNamebyId(_allUser, assignBy)}</b>
                                                                &nbsp;on&nbsp; <b>{assignDate ? moment.unix(assignDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                            </span>)}

                                                            {approvedBy && (<span>
                                                                Approved By: <b>{CommonHelper.getUserNamebyId(_allUser, approvedBy)}</b>
                                                                &nbsp;on&nbsp; <b>{approvedDate ? moment.unix(approvedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                            </span>)}

                                                            {_currentStatus === fleetAssignStatus.DECLINED && (<span>
                                                                Declined By: <b>{CommonHelper.getUserNamebyId(_allUser, modifiedBy)}</b>
                                                                &nbsp;on&nbsp; <b>{modifiedDate ? moment.unix(modifiedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                            </span>)}

                                                            {_currentStatus === fleetAssignStatus.CANCELLED && (<span>
                                                                Cancelled By: <b>{CommonHelper.getUserNamebyId(_allUser, modifiedBy)}</b>
                                                                &nbsp;on&nbsp; <b>{modifiedDate ? moment.unix(modifiedDate.seconds).format('DD/MM/YYYY') : '--'}</b>
                                                            </span>)}
                                                        </div>
                                                    </div>


                                                </div>

                                                {(name || phone || email) && (<div className="fleetpro-accordion-responsibleinfo">

                                                    <div className="fleetpro-responsibleinfo-head">Responsible Person</div>
                                                    <div className="fleetpro-responsibleinfo-list">
                                                        <ul>
                                                            {
                                                                name && (<li> <i className="ico icon-Contacts"></i> {name}</li>)
                                                            }
                                                            {
                                                                phone && (<li> <i className="ico icon-call"></i> {phone}</li>)
                                                            }
                                                            {
                                                                email && (<li> <i className="ico icon-email"></i> {email}</li>)
                                                            }
                                                            {
                                                                companyName && (<li> <i className="ico icon-company"></i> {companyName}</li>)
                                                            }
                                                            {
                                                                address && (<li> <i className="ico icon-location"></i> {address}</li>)
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>)
                                                }

                                                {
                                                    [fleetAssignTypes.RetailerEvent].includes(assignType) && (<div className="fleetpro-transit-wrap">

                                                        <div className="fleetpro-transit-head">Transit Details</div>

                                                        {
                                                            _BookedClass
                                                                ?
                                                                <>
                                                                    <div className="fleetpro-transit-info">
                                                                        <div className="fleetpro-transit-info-left ">
                                                                            <table>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="label-fleetpro">Method of Transport</td>
                                                                                        <td><span className="fleetpro-info">{CommonHelper.getNameByValue(dealersettings.client?.fleetPro?.methodOfTransport, methodOfTransport, '--')}</span></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="label-fleetpro" >Transport Company</td>
                                                                                        <td><span className="fleetpro-info">{CommonHelper.getNameByValue(dealersettings.client?.fleetPro?.transportCompany, transportCompany, '--')}</span></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="label-fleetpro" >MAL Technical Support Required?</td>
                                                                                        <td><span className="fleetpro-info">{malTechnicalSupportReq ? 'Yes' : 'No'}</span></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                        <div className="fleetpro-transit-info-right">
                                                                            <table>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="label-fleetpro">Departure</td>
                                                                                        <td><span className="fleetpro-info">{departureDate ? moment.unix(departureDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="label-fleetpro">Expected Arrival</td>
                                                                                        <td><span className="fleetpro-info">{expectedArrivalDate ? moment.unix(expectedArrivalDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="label-fleetpro" >Expected Return</td>
                                                                                        <td><span className="fleetpro-info">{expectedReturnDate ? moment.unix(expectedReturnDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className={`fleetpro-transit-info assign-fleet-form`}>
                                                                        <div className="form-row">
                                                                            <div className="form-group col-md-4">
                                                                                <label>Method of Transport</label>
                                                                                <ReactSelect
                                                                                    options={(!_.isEmpty(dealersettings.client?.fleetPro?.methodOfTransport) ?
                                                                                        _.map(dealersettings.client?.fleetPro?.methodOfTransport, function (e) {
                                                                                            return {
                                                                                                label: e.name,
                                                                                                value: e.value,
                                                                                                active: _.isBoolean(e.active) ? e.active : true
                                                                                            }
                                                                                        }) : [])}
                                                                                    name={'methodOfTransport'}
                                                                                    onChange={this.handleReactSelectChange}
                                                                                    value={methodOfTransport ? methodOfTransport : null}
                                                                                    classNamePrefix={`${errors["methodOfTransport"]} basic-select`}
                                                                                    removeClearable={true}
                                                                                    isSearchable={true}
                                                                                ></ReactSelect>
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Departure Date</label>
                                                                                <AntDatePicker
                                                                                    value={departureDate ? moment.unix(departureDate.seconds).format('YYYY-MM-DD') : null}
                                                                                    name={'departureDate'}
                                                                                    onChange={(e) => { this.handleFirebaseDateChange(e, 'departureDate') }}
                                                                                    format='DD/MM/YYYY'
                                                                                    placeholder='DD/MM/YYYY'
                                                                                    className={`form-control ${errors["departureDate"]}`}
                                                                                    allowClear={false}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Departure Time</label>
                                                                                <div className='inner-addon right-addon'>
                                                                                    <i className='bg-icons ico icon-time'></i>
                                                                                    <ReactTimePicker
                                                                                        value={departureDate ? moment.unix(departureDate.seconds)._d : null}
                                                                                        name={'departureDate'}
                                                                                        onChange={this.handleFirebaseTimeChange}
                                                                                        timeIntervals={15}
                                                                                        placeholder='h:mm aa'
                                                                                        className={`form-control`}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Expected Arrival Date</label>
                                                                                <AntDatePicker
                                                                                    value={expectedArrivalDate ? moment.unix(expectedArrivalDate.seconds).format('YYYY-MM-DD') : null}
                                                                                    name={'expectedArrivalDate'}
                                                                                    onChange={(e) => { this.handleFirebaseDateChange(e, 'expectedArrivalDate') }}
                                                                                    format='DD/MM/YYYY'
                                                                                    placeholder='DD/MM/YYYY'
                                                                                    className={`form-control ${errors["expectedArrivalDate"]}`}
                                                                                    allowClear={false}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Expected Arrival Time</label>
                                                                                <div className='inner-addon right-addon'>
                                                                                    <i className='bg-icons ico icon-time'></i>
                                                                                    <ReactTimePicker
                                                                                        value={expectedArrivalDate ? moment.unix(expectedArrivalDate.seconds)._d : null}
                                                                                        name={'expectedArrivalDate'}
                                                                                        onChange={this.handleFirebaseTimeChange}
                                                                                        timeIntervals={15}
                                                                                        placeholder='h:mm aa'
                                                                                        className={`form-control`}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Expected Return Date</label>
                                                                                <AntDatePicker
                                                                                    value={expectedReturnDate ? moment.unix(expectedReturnDate.seconds).format('YYYY-MM-DD') : null}
                                                                                    name={'expectedReturnDate'}
                                                                                    onChange={(e) => { this.handleFirebaseDateChange(e, 'expectedReturnDate') }}
                                                                                    format='DD/MM/YYYY'
                                                                                    placeholder='DD/MM/YYYY'
                                                                                    className={`form-control ${errors["expectedReturnDate"]}`}
                                                                                    allowClear={false}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group col-md-2">
                                                                                <label>Expected Return Time</label>
                                                                                <div className='inner-addon right-addon'>
                                                                                    <i className='bg-icons ico icon-time'></i>
                                                                                    <ReactTimePicker
                                                                                        value={expectedReturnDate ? moment.unix(expectedReturnDate.seconds)._d : null}
                                                                                        name={'expectedReturnDate'}
                                                                                        onChange={this.handleFirebaseTimeChange}
                                                                                        timeIntervals={15}
                                                                                        placeholder='h:mm aa'
                                                                                        className={`form-control`}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group col-md-4">
                                                                                <label>Transport Company</label>
                                                                                <ReactSelect
                                                                                    options={(!_.isEmpty(dealersettings.client?.fleetPro?.transportCompany) ?
                                                                                        _.map(dealersettings.client?.fleetPro?.transportCompany, function (e) {
                                                                                            return {
                                                                                                label: e.name,
                                                                                                value: e.value,
                                                                                                active: _.isBoolean(e.active) ? e.active : true
                                                                                            }
                                                                                        }) : [])}
                                                                                    name={'transportCompany'}
                                                                                    onChange={this.handleReactSelectChange}
                                                                                    value={transportCompany ? transportCompany : null}
                                                                                    classNamePrefix={`${errors["transportCompany"]} basic-select`}
                                                                                    removeClearable={true}
                                                                                    isSearchable={true}
                                                                                ></ReactSelect>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="fleetpro-transit-head ">MAL Technical Support Required?</div>

                                                                    <div className={`fleetpro-transit-info pt-0`}>
                                                                        <div className="radio icheck-success float-left">
                                                                            <InputRadio
                                                                                id="mal_yes"
                                                                                type="radio"
                                                                                value="yes"
                                                                                name="malTechnicalSupportReq"
                                                                                checked={malTechnicalSupportReq === true ? true : false}
                                                                                onChange={this.handleRadioChange}
                                                                            />
                                                                            <label htmlFor="mal_yes"><Translate text={'Yes'} /></label>
                                                                        </div>

                                                                        <div className="radio icheck-success float-left ml-3">
                                                                            <InputRadio
                                                                                id="mal_no"
                                                                                type="radio"
                                                                                value="no"
                                                                                name="malTechnicalSupportReq"
                                                                                checked={malTechnicalSupportReq === false ? true : false}
                                                                                onChange={this.handleRadioChange}
                                                                            />
                                                                            <label htmlFor="mal_no"><Translate text={'No'} /></label>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                        }

                                                    </div>)}


                                                {
                                                    !_.isEmpty(inspection) && !_.isEmpty(inspection.filter(a => !_.isEmpty(a.images))[0])
                                                        ?
                                                        <>
                                                            <div className="fleetpro-history-img-wrap">
                                                                {
                                                                    inspection.map((mark, index) => {
                                                                        return <div key={index}>
                                                                            {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                <div key={_index} className="fleetpro-history-item-image">
                                                                                    <a data-fancybox={`mark-inspection-${index}-${_index}`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                        <img src={data} className="img-object-fit" width="50" height="50" alt="" />
                                                                                    </a>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <div className="fleetpro-action-btn-wrap border-top-none">

                                                    <button className={`btn btn-sm btn-default  ml-1 ${errors["inspection"]}`} onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ showInspection: { show: true, id: 'inspection', marks: inspection, isReadOnly: (_isCompletedOrReleased || (_isApproved && !_.isEmpty(fieldOld.inspection))) ? true : false } })
                                                    }}>
                                                        {!_.isEmpty(inspection) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                        <Translate text={'inspection'} />
                                                    </button>


                                                    <button className={`btn btn-sm btn-default  ml-1 ${errors["terms"]}`} onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleTermsClick();
                                                    }}>
                                                        {!_.isEmpty(terms) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                        {
                                                            isTermsRequested
                                                                ?
                                                                <>
                                                                    <span className="spinner-border spinner-button blue-color mr-2 ml-0 mt-0" role="status" aria-hidden="true"></span>
                                                                    <Translate text={'Waiting for response'} />
                                                                </>
                                                                :
                                                                <><i className="ico icon-terms mr-1"></i><Translate text={'termsAndConditions'} /> </>
                                                        }
                                                    </button>

                                                    {
                                                        [fleetAssignTypes.RetailerEvent].includes(assignType) && requestNotes && (<button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({ notesPopOver: { showpopover: true, targetpopover: e.target, notes: requestNotes } })
                                                        }}>
                                                            <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>
                                                            <i className="ico icon-service-notes mr-1"></i> Request Notes
                                                        </button>)
                                                    }

                                                    <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ notesPopOver: { showpopover: true, targetpopover: e.target, notes: notes } })
                                                    }}>
                                                        {!_.isEmpty(notes) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                        <i className="ico icon-service-notes mr-1"></i> Notes
                                                    </button>

                                                    {/* <div className="float-right">
                                                        <div className="fleetpro-technical-support"> MAL Technical Support
                                                            <img src="images/oktodo-green.svg" alt="" width="20" className="ml-1" />
                                                        </div>
                                                    </div> */}

                                                </div>

                                            </div>)
                                        }

                                        {
                                            (_isApproved && ([fleetAssignTypes.RetailerEvent].includes(assignType) || _isBookedOrReceived)) && (<>
                                                <div className="assign-fleet-middle mt-3">
                                                    <div className="assign-fleet-middle-head">
                                                        <div className=" float-left">
                                                            <h3>Receive Info</h3>
                                                        </div>
                                                        {
                                                            !_BookedClass && (<div className=" float-right">

                                                                <button className={`btn btn-sm btn-default  ml-1 ${errors["preInspection"]}`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showInspection: { show: true, id: 'preInspection', marks: preInspection, isReadOnly: _isBookedOrReceived } })
                                                                }}>
                                                                    {!_.isEmpty(preInspection) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                                    <Translate text={'inspection'} />
                                                                </button>

                                                                <button className={`btn btn-sm btn-default ml-1 ${errors["preSignature"]}`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showSignature: { show: true, id: 'preSignature', sign: preSignature, isReadOnly: _isBookedOrReceived } })
                                                                }}>
                                                                    {preSignature ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                                    <><i className="ico icon-sign mr-1"></i><Translate text={'signature'} /></>

                                                                </button>
                                                            </div>)
                                                        }

                                                    </div>
                                                    {
                                                        !_BookedClass && (<>
                                                            <div className={`assign-fleet-form`}>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-4">
                                                                        <label ><Translate text={'currentOdometerReading'} /></label>
                                                                        <NumberFormat
                                                                            id={'preOdometer'}
                                                                            thousandSeparator={true}
                                                                            placeholder={'current odometer reading'}
                                                                            className={`form-control ${errors["preOdometer"]}`}
                                                                            onValueChange={this.onCurrencyChange.bind(this, 'preOdometer')}
                                                                            value={preOdometer ? preOdometer : null}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                !_.isEmpty(preInspection) && !_.isEmpty(preInspection.filter(a => !_.isEmpty(a.images))[0])
                                                                    ?
                                                                    <>
                                                                        <div className="assign-fleet-section-head"><h4>Vehicle Images</h4></div>
                                                                        <div className="modal-assign-fleet-image-wraper">
                                                                            {
                                                                                preInspection.map((mark, index) => {
                                                                                    return <div key={index}>
                                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                            <div key={_index} className="modal-assign-fleet-item-image">
                                                                                                <a data-fancybox={`mark-preInspection-${index}-${_index}`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <img src={data} className="img-object-fit" width="50" height="50" alt="" />
                                                                                                </a>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            <div className="assign-fleet-section-head"><h4>Receive Notes</h4></div>
                                                            <div className={`assign-fleet-form`}>

                                                                <InputTextArea
                                                                    className={`form-control notes-assign-fleet ${errors["preNotes"]}`}
                                                                    name="preNotes"
                                                                    onChange={this.handleOnAreaChange}
                                                                    value={preNotes ? preNotes : null}
                                                                    maxLength={5000}
                                                                    rows="4">
                                                                </InputTextArea>
                                                            </div>
                                                        </>)
                                                    }
                                                </div>

                                                {_BookedClass && (
                                                    <div className="fleetpro-readonly-received">

                                                        <div className="fleetpro-info-left">

                                                            <div className="fleetpro-info-table">
                                                                <table>
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Received Odometer</td>
                                                                            <td><span className="fleetpro-info">{preOdometer ? <NumberFormat style={{ fontSize: 12 }} value={preOdometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : <span>{'--'}</span>}</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Received Date</td>
                                                                            <td><span className="fleetpro-info">{receivedDate ? moment.unix(receivedDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Received By</td>
                                                                            <td><span className="fleetpro-info">{CommonHelper.getUserNamebyId(_allUser, receivedBy, (receivedByName || '--'))}</span></td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </div>

                                                            {
                                                                !_.isEmpty(preInspection) && !_.isEmpty(preInspection.filter(a => !_.isEmpty(a.images))[0])
                                                                    ?
                                                                    <>
                                                                        <div className="fleetpro-history-img-wrap">
                                                                            {
                                                                                preInspection.map((mark, index) => {
                                                                                    return <div key={index}>
                                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                            <div key={_index} className="fleetpro-history-item-image">
                                                                                                <a data-fancybox={`mark-preInspection-${index}-${_index}`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <img src={data} className="img-object-fit" width="50" height="50" alt="" />
                                                                                                </a>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            <div className="fleetpro-action-btn-wrap">

                                                                <button className={`btn btn-sm btn-default  ml-1 ${errors["preInspection"]}`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showInspection: { show: true, id: 'preInspection', marks: preInspection, isReadOnly: _isBookedOrReceived } })
                                                                }}>
                                                                    {!_.isEmpty(preInspection) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                                    <Translate text={'inspection'} />
                                                                </button>

                                                                <button className={`btn btn-sm btn-default ml-1 ${errors["preSignature"]}`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showSignature: { show: true, id: 'preSignature', sign: preSignature, isReadOnly: _isBookedOrReceived } })
                                                                }}>
                                                                    {preSignature ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                                    <><i className="ico icon-sign mr-1"></i><Translate text={'signature'} /></>

                                                                </button>

                                                                <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ notesPopOver: { showpopover: true, targetpopover: e.target, notes: preNotes } })
                                                                }}>
                                                                    {!_.isEmpty(preNotes) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                    <i className="ico icon-service-notes mr-1"></i> Notes
                                                                </button>

                                                            </div>




                                                        </div>
                                                    </div>
                                                )}
                                            </>)
                                        }

                                        {
                                            (_isBookedOrReceived || (_isApproved && [fleetAssignTypes.LoanVehicle, fleetAssignTypes.ExternalInternalEvent].includes(assignType))) && (<>
                                                <div className="assign-fleet-middle mt-3">
                                                    <div className="assign-fleet-middle-head">
                                                        <div className=" float-left">
                                                            <h3>Release Info</h3>
                                                        </div>

                                                        {
                                                            !_completedClass && (<div className=" float-right">

                                                                <button className={`btn btn-sm btn-default  ml-1 ${errors["postInspection"]}`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showInspection: { show: true, id: 'postInspection', marks: postInspection, isReadOnly: _isCompletedOrReleased } })
                                                                }}>
                                                                    {!_.isEmpty(postInspection) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                                    <Translate text={'inspection'} />
                                                                </button>

                                                                <button className={`btn btn-sm btn-default ml-1 ${errors["postSignature"]}`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showSignature: { show: true, id: 'postSignature', sign: postSignature, isReadOnly: _isCompletedOrReleased } })
                                                                }}>
                                                                    {postSignature ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                                    <><i className="ico icon-sign mr-1"></i><Translate text={'signature'} /></>
                                                                </button>
                                                            </div>)
                                                        }
                                                    </div>
                                                    {
                                                        !_completedClass && (<>
                                                            <div className={`assign-fleet-form ${_completedClass}`}>
                                                                <div className="form-row">
                                                                    {
                                                                        [fleetAssignTypes.LoanVehicle].includes(assignType) && (
                                                                            <>
                                                                                <div className="form-group col-md-2">
                                                                                    <label>End Date</label>
                                                                                    <AntDatePicker
                                                                                        value={endDate ? moment.unix(endDate.seconds).format('YYYY-MM-DD') : null}
                                                                                        name={'endDate'}
                                                                                        onChange={(e) => { this.handleFirebaseDateChange(e, 'endDate') }}
                                                                                        format='DD/MM/YYYY'
                                                                                        placeholder='DD/MM/YYYY'
                                                                                        className={`form-control ${errors["endDate"]}`}
                                                                                        allowClear={false}
                                                                                        disabledDate={this.minEndDate}
                                                                                    />
                                                                                </div>
                                                                                <div className="form-group col-md-2">
                                                                                    <label>End Time</label>
                                                                                    <div className='inner-addon right-addon'>
                                                                                        <i className='bg-icons ico icon-time'></i>
                                                                                        <ReactTimePicker
                                                                                            value={endDate ? moment.unix(endDate.seconds)._d : null}
                                                                                            name={'endDate'}
                                                                                            onChange={this.handleFirebaseTimeChange}
                                                                                            timeIntervals={15}
                                                                                            placeholder='h:mm aa'
                                                                                            className={`form-control`}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                    <div className="form-group col-md-4">
                                                                        <label ><Translate text={'currentOdometerReading'} /></label>
                                                                        <NumberFormat
                                                                            id={'postOdometer'}
                                                                            thousandSeparator={true}
                                                                            placeholder={'current odometer reading'}
                                                                            className={`form-control ${errors["postOdometer"]}`}
                                                                            onValueChange={this.onCurrencyChange.bind(this, 'postOdometer')}
                                                                            value={postOdometer ? postOdometer : null}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                !_.isEmpty(postInspection) && !_.isEmpty(postInspection.filter(a => !_.isEmpty(a.images))[0])
                                                                    ?
                                                                    <>
                                                                        <div className="assign-fleet-section-head"><h4>Vehicle Images</h4></div>
                                                                        <div className="modal-assign-fleet-image-wraper">
                                                                            {
                                                                                postInspection.map((mark, index) => {
                                                                                    return <div key={index}>
                                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                            <div key={_index} className="modal-assign-fleet-item-image">
                                                                                                <a data-fancybox={`mark-postInspection-${index}-${_index}`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <img src={data} className="img-object-fit" width="50" height="50" alt="" />
                                                                                                </a>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            <div className="assign-fleet-section-head"><h4>Release Notes</h4></div>
                                                            <div className={`assign-fleet-form ${_completedClass}`}>
                                                                <InputTextArea
                                                                    className={`form-control notes-assign-fleet ${errors["postNotes"]}`}
                                                                    name="postNotes"
                                                                    onChange={this.handleOnAreaChange}
                                                                    value={postNotes ? postNotes : null}
                                                                    maxLength={5000}
                                                                    rows="4">
                                                                </InputTextArea>
                                                            </div>

                                                        </>)
                                                    }


                                                </div>
                                                {_completedClass && (
                                                    <div className="fleetpro-readonly-received">

                                                        <div className="fleetpro-info-left">

                                                            <div className="fleetpro-info-table">
                                                                <table>
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>Released Odometer</td>
                                                                            <td><span className="fleetpro-info">{postOdometer ? <NumberFormat style={{ fontSize: 12 }} value={postOdometer} displayType={'text'} thousandSeparator={true} suffix={' km'} /> : <span>{'--'}</span>}</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Released Date</td>
                                                                            <td><span className="fleetpro-info">{releasedDate ? moment.unix(releasedDate.seconds).format('DD/MM/YYYY') : '--'}</span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Released By</td>
                                                                            <td><span className="fleetpro-info">{CommonHelper.getUserNamebyId(_allUser, releasedBy, (releasedByName || '--'))}</span></td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </div>

                                                            {
                                                                !_.isEmpty(postInspection) && !_.isEmpty(postInspection.filter(a => !_.isEmpty(a.images))[0])
                                                                    ?
                                                                    <>
                                                                        <div className="fleetpro-history-img-wrap">
                                                                            {
                                                                                postInspection.map((mark, index) => {
                                                                                    return <div key={index}>
                                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, _index) => (
                                                                                            <div key={_index} className="fleetpro-history-item-image">
                                                                                                <a data-fancybox={`mark-postInspection-${index}-${_index}`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                    <img src={data} className="img-object-fit" width="50" height="50" alt="" />
                                                                                                </a>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            <div className="fleetpro-action-btn-wrap">

                                                                <button className={`btn btn-sm btn-default  ml-1 ${errors["postInspection"]}`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showInspection: { show: true, id: 'postInspection', marks: postInspection, isReadOnly: _isCompletedOrReleased } })
                                                                }}>
                                                                    {!_.isEmpty(postInspection) ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                                    <Translate text={'inspection'} />
                                                                </button>

                                                                <button className={`btn btn-sm btn-default ml-1 ${errors["postSignature"]}`} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ showSignature: { show: true, id: 'postSignature', sign: postSignature, isReadOnly: _isCompletedOrReleased } })
                                                                }}>
                                                                    {postSignature ? <div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div> : <></>}
                                                                    <><i className="ico icon-sign mr-1"></i><Translate text={'signature'} /></>

                                                                </button>

                                                                <button className="btn btn-sm btn-default  ml-1" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({ notesPopOver: { showpopover: true, targetpopover: e.target, notes: postInspection } })
                                                                }}>
                                                                    {!_.isEmpty(postInspection) && (<div className="ribbon-corner">   <div className="ribbon-corner-active"></div> </div>)}
                                                                    <i className="ico icon-service-notes mr-1"></i> Notes
                                                                </button>

                                                            </div>




                                                        </div>
                                                    </div>
                                                )}
                                            </>)
                                        }


                                    </div>
                                </div>

                            </div>

                        </Modal.Body>
                        {
                            readOnlyFleetVehicle
                                ?
                                <></>
                                :
                                <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                                    {/* for OEM persons to assign / approve for the new data ( null status) based on permision*/}
                                    {
                                        (!docID || editApprovedBooking) && dealersettings.level !== userLevel.INDIVIDUAL && (accessToAssign || accessToApprove) && ['', null].includes(_currentStatus) && (
                                            <button
                                                type="button"
                                                className="btn btn-primary float-right"
                                                onClick={(e) => { e.preventDefault(); this.handleLoanSubmit() }}
                                            >
                                                {this.isLoading === 'assign' ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={accessToApprove ? 'Assign & Approve' : 'Assign'} upperCase={true} />
                                            </button>
                                        )
                                    }
                                    {/* for INDIVIDUAL persons to Request / approve for the new data ( null status) based on permision*/}
                                    {
                                        (!docID || editApprovedBooking) && dealersettings.level === userLevel.INDIVIDUAL && (accessToRequest || accessToApprove) && ['', null].includes(_currentStatus) && (
                                            <button
                                                type="button"
                                                className="btn btn-primary float-right"
                                                onClick={(e) => { e.preventDefault(); this.handleLoanSubmit() }}
                                            >
                                                {this.isLoading === 'assign' ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={accessToApprove ? 'Request & Approve' : 'Request'} upperCase={true} />
                                            </button>
                                        )
                                    }
                                    {/* Aprroval Process for PENDINGAPPROVAL / REQUESTED status based on permission */}
                                    {
                                        docID && !editApprovedBooking && ['', null, fleetAssignStatus.PENDINGAPPROVAL, fleetAssignStatus.REQUESTED].includes(_currentStatus) && (
                                            <button
                                                type="button"
                                                className="btn btn-green float-right"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    Swal.fire({
                                                        title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                                                        text: CommonHelper.showLocale(this.props, `Do you want to approve this fleet.`),
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                                                        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                                                    }).then(result => {
                                                        if (result.value) {
                                                            this.handleLoanSubmit(true);
                                                        }
                                                    })
                                                }}
                                            >
                                                {this.isLoading === 'assign' ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'approve'} upperCase={true} /></button>
                                        )
                                    }
                                    {/* Decline Process for PENDINGAPPROVAL / REQUESTED status based on permission */}
                                    {
                                        docID && accessToApprove && [fleetAssignStatus.PENDINGAPPROVAL, fleetAssignStatus.REQUESTED].includes(_currentStatus) && (
                                            <button
                                                type="button"
                                                className="btn btn-red float-right mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    Swal.fire({
                                                        title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                                                        text: CommonHelper.showLocale(this.props, `Do you want to decline this fleet.`),
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                                                        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                                                    }).then(result => {
                                                        if (result.value) {
                                                            this.saveAssignField('status', fleetAssignStatus.DECLINED)
                                                        }
                                                    })
                                                }}
                                            >
                                                {this.isLoading === fleetAssignStatus.DECLINED ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'Decline'} upperCase={true} /></button>
                                        )
                                    }
                                    {/*Save Process for the those who (added / owner) of the fleet and its not approved */}
                                    {
                                        [addedBy, fleetPro.fleetOwner].includes(localStorage.uid) && docID && !_isApproved && !editApprovedBooking && _canModify && (
                                            <button type="button" className="btn btn-secondary float-right mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    Swal.fire({
                                                        title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                                                        text: CommonHelper.showLocale(this.props, `Do you want to save this fleet.`),
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                                                        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                                                    }).then(result => {
                                                        if (result.value) {
                                                            this.handleLoanSubmit(true, true);
                                                        }
                                                    })
                                                }}
                                            >
                                                {this.isLoading === 'save' ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'save'} upperCase={true} />
                                            </button>
                                        )
                                    }
                                    {/*cancelling the edit approved Booking*/}
                                    {
                                        editApprovedBooking && (
                                            <button type="button" className="btn btn-default float-right mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    Swal.fire({
                                                        title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                                                        text: CommonHelper.showLocale(this.props, `Do you want to cancel the changes.`),
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                                                        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                                                    }).then(result => {
                                                        if (result.value) {
                                                            this.setState({ editApprovedBooking: false }, () => { this.loadFleet(this.props, true) })
                                                        }
                                                    })
                                                }}
                                            >
                                                {/* {this.isLoading === fleetAssignStatus.CANCELLED ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>} */}
                                                <Translate text={'cancel'} upperCase={true} />
                                            </button>
                                        )
                                    }
                                    {/*cancelling the request / pending approval  by added person only*/}
                                    {
                                        addedBy === localStorage.uid && docID && [fleetAssignStatus.PENDINGAPPROVAL, fleetAssignStatus.REQUESTED].includes(_currentStatus) && (
                                            <button type="button" className="btn btn-default float-right mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    Swal.fire({
                                                        title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                                                        text: CommonHelper.showLocale(this.props, `Do you want to cancel this fleet.`),
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                                                        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                                                    }).then(result => {
                                                        if (result.value) {
                                                            this.saveAssignField('status', fleetAssignStatus.CANCELLED)
                                                        }
                                                    })
                                                }}
                                            >
                                                {this.isLoading === fleetAssignStatus.CANCELLED ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'cancel'} upperCase={true} />
                                            </button>
                                        )
                                    }
                                    {/*reopen the fleet whose status is (DECLINED /CANCELLED) based on permission */}
                                    {
                                        docID && (accessToAssign || accessToApprove || (accessToRequest && dealersettings.level === userLevel.INDIVIDUAL)) && [fleetAssignStatus.DECLINED, fleetAssignStatus.CANCELLED].includes(_currentStatus) && (
                                            <button
                                                type="button"
                                                className="btn btn-common float-right mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    Swal.fire({
                                                        title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                                                        text: CommonHelper.showLocale(this.props, `Do you want to re-open this fleet.`),
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                                                        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                                                    }).then(result => {
                                                        if (result.value) {
                                                            if (dealersettings.level === userLevel.INDIVIDUAL)
                                                                this.saveAssignField('status', (accessToApprove ? fleetAssignStatus.APPROVED : fleetAssignStatus.REQUESTED), '', true)
                                                            else
                                                                this.saveAssignField('status', (accessToApprove ? fleetAssignStatus.APPROVED : fleetAssignStatus.PENDINGAPPROVAL), '', true)
                                                        }
                                                    })
                                                }}
                                            >
                                                {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'reopen'} upperCase={true} /></button>
                                        )
                                    }
                                    {/* receiving the vehicle, which status is APPROVED and assigntype is RetailerEvent based on permission*/}
                                    {
                                        docID && (accessToReceive) && [fleetAssignStatus.APPROVED].includes(_currentStatus) && [fleetAssignTypes.RetailerEvent].includes(assignType) && (
                                            <button
                                                type="button"
                                                className="btn btn-green float-right mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleReceive();
                                                }}
                                            >
                                                {this.isLoading === fleetAssignStatus.RECEIVED ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'receiveVehicle'} upperCase={true} /></button>
                                        )
                                    }
                                    {/* returning the vehicle, which status is RECEIVED or (status is APPROVED with assigntype is LoanVehicle / ExternalInternalEvent) based on permission */}
                                    {
                                        docID && (accessToReturn) &&
                                        ([fleetAssignStatus.RECEIVED].includes(_currentStatus) ||
                                            ([fleetAssignStatus.APPROVED].includes(_currentStatus) && [fleetAssignTypes.LoanVehicle, fleetAssignTypes.ExternalInternalEvent].includes(assignType))
                                        ) && (
                                            <button
                                                type="button"
                                                className="btn btn-secondary float-right mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleReturn();
                                                }}
                                            >
                                                {this.isLoading === fleetAssignStatus.RELEASED ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                <Translate text={'returnVehicle'} upperCase={true} /></button>
                                        )
                                    }
                                </Modal.Footer>

                        }

                    </Modal >



                    <PopUpModal show={showInspection.show}>
                        <ImageCanvas
                            show={showInspection.show}
                            marks={showInspection.marks}
                            fieldID={showInspection.id}
                            isReadOnly={_canModify ? showInspection.isReadOnly : true}
                            clsActive='overlay-modal active'
                            imagePath='fleetProAssign'
                            handleClose={this.handleInspectionClose}
                            dealersettings={dealersettings}
                            showCost={false}
                        ></ImageCanvas>
                    </PopUpModal>

                    <PopUpModal show={showSignature.show}>
                        <AfterSignature
                            show={showSignature.show}
                            signatureURL={showSignature.sign}
                            fieldID={showSignature.id}
                            isDisable={_canModify ? showSignature.isReadOnly : true}
                            imagePath='fleetProAssign'
                            clsActive='overlay-modal active'
                            handleClose={this.handleSignatureClose}
                            dealersettings={dealersettings}
                        ></AfterSignature>
                    </PopUpModal>

                    <PopUpModal show={this.state.showBeforeTerms}>
                        <Terms
                            show={this.state.showBeforeTerms}
                            clsActive='overlay-modal active'
                            imagePath='fleetProAssign'
                            terms={this.state.fields.terms}
                            termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'fleetTC')}
                            title={'termsAndConditions'}
                            isDisable={_canModify ? (_isCompletedOrReleased || (_isApproved && !_.isEmpty(fieldOld.terms))) : true}
                            handleClose={this.handleBeforeTermsClose}
                            dealersettings={dealersettings}

                        ></Terms>
                    </PopUpModal>

                    <PopUpModal show={showEventListModal}>
                        <EventsList
                            {...this.props}
                            show={showEventListModal}
                            title={'Events'}
                            clsActive='overlay-modal active'
                            clientID={clientID}
                            handleClose={this.handleEventClose}
                        ></EventsList>
                    </PopUpModal>

                    <PopUpModal show={showTermsOption}>
                        <SignOptions
                            show={showTermsOption}
                            menu={isTermsRequested ? sign_type.filter(e => e.value !== 'send') : sign_type}
                            handleClose={this.handleTermsChange}
                        />
                    </PopUpModal>

                    <PopoverPanel
                        showpopover={notesPopOver.showpopover}
                        targetpopover={notesPopOver.targetpopover}
                        title="Notes"
                        position="top"
                        id="notes-fleet-assign"
                        className="popover-pipeline-switch"
                        closepopover={() => {
                            this.setState({
                                notesPopOver: { showpopover: false, targetpopover: '', notes: '' }
                            });
                        }}>
                        <AddNotes
                            notes={notesPopOver.notes}
                            handleClose={() => {
                                this.setState({
                                    notesPopOver: { showpopover: false, targetpopover: '', notes: '' }
                                });
                            }}
                            isDisable={true}
                        />
                    </PopoverPanel>


                </>
                :
                <></>

        );
    }
}