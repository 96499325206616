import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import CommonHelper from '../services/common';


am4core.useTheme(am4themes_animated);

const LineChart = (props) => {

    const [chartData, setChart] = useState(null)

    useEffect(() => {
        if (_.isEmpty(chartData))
            return

        if (JSON.stringify(chartData.data) !== JSON.stringify(props.data))
            chartData.data = props.data
        am4core.array.each(chartData.data, (item) => {
            if (_.find(props.data, { value: item.value }))
                item.count = _.find(props.data, { value: item.value }).count
        })

        if (props.calculationType === 'duration') {
            chartData.yAxes.values[0].baseUnit = "second";
        }
        else {
            chartData.yAxes.values[0].min = 0;
            let _maxValue = 0;
            props.columns.forEach(col => {
                if (_.maxBy(props.data, col) && _maxValue < _.maxBy(props.data, col)[col])
                    _maxValue = _.maxBy(props.data, col)[col];
            })
            if (_maxValue > 0 && _maxValue < 10) {
                if (Math.ceil(_maxValue / 5) * 5 === _maxValue) {
                    chartData.yAxes.values[0].max = _maxValue + 5;
                }
                else {
                    chartData.yAxes.values[0].max = Math.ceil(_maxValue / 5) * 5;
                }
                chartData.yAxes.values[0].strictMinMax = true;
            }
            else if (_maxValue >= 10) {
                chartData.yAxes.values[0].max = Math.ceil(_maxValue / 4) * 5;
                chartData.yAxes.values[0].strictMinMax = true;
            }
            else {
                chartData.yAxes.values[0].max = 5;
                chartData.yAxes.values[0].strictMinMax = true;
            }
        }

        chartData.invalidateRawData();

    }, [props.data])

    useEffect(() => {
        if (_.isEmpty(chartData))
            return

        chartData.series.clear()
        chartData.invalidateData();
        props.columns.forEach(rec => {
            createSeries(rec, CommonHelper.getNameByValue(props.dynamicColumns, rec), chartData);
        })
        chartData.exporting.getImage("png").then(rec => {
            props.setChartImg && props.setChartImg(rec)
        })
    }, [props.columns.join(',')])

    useEffect(() => {
        if (_.isEmpty(chartData))
            return
        props.setChartData && props.setChartData(chartData)
    }, [chartData])


    useEffect(() => {
        if (_.isEmpty(chartData) || _.isEmpty(props.title))
            return
        if (chartData?.chartContainer?.children?.values?.[0]?.children?.values?.[0]?.text)
            chartData.chartContainer.children.values[0].children.values[0].text = props.title
    }, [props.title])

    useEffect(() => {
        // Create chart instance
        var chart = am4core.create(props.id, am4charts.XYChart);
        chart.logo.disabled = true;
        if (props.title) {
            let topContainer = chart.chartContainer.createChild(am4core.Container);
            topContainer.layout = "absolute";
            topContainer.toBack();
            topContainer.paddingBottom = 15;
            topContainer.width = am4core.percent(100);

            let label = topContainer.createChild(am4core.Label);
            label.text = props.title;
            label.fontSize = 18;
            label.fontWeight = "bold";
            label.align = "center";
        }

        // Add data
        chart.data = props.data;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = props.xCategory ? props.xCategory : "name";
        categoryAxis.renderer.cellStartLocation = 0.1
        categoryAxis.renderer.cellEndLocation = 0.9
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.cursorTooltipEnabled = false;
        if (props.minGridDistance) {
            categoryAxis.renderer.minGridDistance = props.minGridDistance;
        }
        else {
            categoryAxis.renderer.minGridDistance = 30;
        }

        // Create value axis
        //var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        if (props.calculationType === 'duration') {
            let yAxis = chart.yAxes.push(new am4charts.DurationAxis());
            yAxis.baseUnit = "second";
        }
        else {
            let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
            yAxis.min = 0;
            let _maxValue = 0;
            props.columns.forEach(col => {
                if (_.maxBy(props.data, col) && _maxValue < _.maxBy(props.data, col)[col])
                    _maxValue = _.maxBy(props.data, col)[col];
            })
            if (_maxValue > 0 && _maxValue < 10) {
                if (Math.ceil(_maxValue / 5) * 5 === _maxValue) {
                    yAxis.max = _maxValue + 5;
                }
                else {
                    yAxis.max = Math.ceil(_maxValue / 5) * 5;
                }
                yAxis.strictMinMax = true;
            }
            else if (_maxValue >= 10) {
                yAxis.max = Math.ceil(_maxValue / 4) * 5;
                yAxis.strictMinMax = true;
            }
            else {
                yAxis.max = 5;
                yAxis.strictMinMax = true;
            }
        }


        var label = categoryAxis.renderer.labels.template;
        label.wrap = true;
        label.maxWidth = 120;

        if (props.labelRotation) {
            categoryAxis.events.on("sizechanged", function (ev) {
                var axis = ev.target;
                var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
                if (cellWidth < axis.renderer.labels.template.maxWidth) {
                    axis.renderer.labels.template.rotation = -(props.labelRotation);
                    axis.renderer.labels.template.horizontalCenter = "right";
                    axis.renderer.labels.template.verticalCenter = "middle";
                }
                else {
                    axis.renderer.labels.template.rotation = 0;
                    axis.renderer.labels.template.horizontalCenter = "middle";
                    axis.renderer.labels.template.verticalCenter = "top";
                }
            });
        }

        // Create series
        props.columns.forEach(rec => {
            createSeries(rec, ((rec === 'target' || props.dynamicColumns.find(val => val.value === rec))
                ? CommonHelper.getNameByValue(props.dynamicColumns, rec) : 'In Active'), chart);
        })


        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.itemContainers.template.events.on("over", function (event) {
            let segments = event.target.dataItem.dataContext.segments;
            segments.each(function (segment) {
                segment.isHover = true;
            })
        })

        chart.legend.itemContainers.template.events.on("out", function (event) {
            let segments = event.target.dataItem.dataContext.segments;
            segments.each(function (segment) {
                segment.isHover = false;
            })
        })

        chart.cursor = new am4charts.XYCursor();

        const linechart = chart;
        setChart(chart)
        return () => {
            linechart && linechart.dispose()
        }
    }, [])

    const createSeries = (value, name, chart) => {
        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = value;
        series1.dataFields.categoryX = props.xCategory ? props.xCategory : "name";
        series1.name = value === 'target' ? 'Target' : name;
        series1.labelText = value === 'target' ? 'Target' : name;
        // series1.labels.template.text = "{value}";
        // series1.labels.template.radius = am4core.percent(-30);
        // series1.labels.template.fill = am4core.color("black");
        let bullet = new am4charts.CircleBullet();
        bullet.strokeWidth = 1;
        bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        series1.bullets.push(bullet);

        var labelBullet = new am4charts.LabelBullet();
        if (props.calculationType === 'duration')
            labelBullet.label.text = `{valueY.formatDuration()}`;
        else if (!Boolean(props.hideLabel))
            labelBullet.label.text = `${props.currencySymbol ? props.currencySymbol + ' ' : ''}{valueY}${props.calculationType === 'percentage' ? '%' : ''}`;
        labelBullet.label.dy = -15;
        series1.bullets.push(labelBullet);
        if (props.calculationType === 'duration') {
            series1.tooltipText = `{labelText} : {valueY.formatDuration()}`;
            series1.legendSettings.valueText = "{valueY.formatDuration()}";
        }
        else if (props.calculationType === 'revenue') {
            if (value === 'approvedamount')
                series1.tooltipText = `{labelText} : {approvedcount} \n Total Cost: ${props.currencySymbol}{valueY}`;
            else
                series1.tooltipText = `{labelText} : {declinedcount} \n Total Cost: ${props.currencySymbol}{valueY}`;

            series1.legendSettings.valueText = "{valueY}";
        }
        else {
            series1.tooltipText = `{labelText} : {valueY}${props.calculationType === 'percentage' ? '%' : ''}`;
            series1.legendSettings.valueText = "{valueY}";
        }
        series1.visible = false;
        if (value === 'target') {
            series1.stroke = am4core.color('#ff0000');
            series1.fill = am4core.color('#ff0000');
        }
        else {
            var col = props.dynamicColumns && props.dynamicColumns.find(item => item.value === value);
            if (col) {
                series1.stroke = am4core.color(col.color);
                series1.fill = am4core.color(col.color);
            }
        }

        let hs1 = series1.segments.template.states.create("hover")
        hs1.properties.strokeWidth = 5;
        series1.segments.template.strokeWidth = 1;

        bullet.events.on("doublehit", function (ev) {
            props.handleShowEnquiryList(ev.target.dataItem.component.dataFields.valueY, ev.target.dataItem.dataContext);
        }, this);


        return series1;
    }

    //https://www.amcharts.com/docs/v4/tutorials/chart-legend-in-an-external-container/
    return (
        <div id={props.id} style={{ width: "100%", height: props.height }}></div>
    );
}

export default LineChart;