/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox, AntDateRangePicker,
    ReactSelect, InputText, ReactMultiSelect,
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'
import { firestoreDB } from '../../services/helper';

//#region  ASSIGN VARIABLES
let company_type = [];
let groups = [];

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'addedDate', label: <Translate text={'Created Date'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'Modified Date'} /> }
]

const objDateField = {
    name: null,
    type: null,
    range: null
}
//#endregion
const CompanyAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const [companys, setCompanys] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const { columnFields } = props;

    /*const enableSharedContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedContact) ? true : false);*/

    useEffect(() => {
        if (props.dealersettings) {
            const userRefDoc = firestoreDB(props.dealersettings).firestore().collection('companies')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('isDeleted', '==', false)
                .limit(100)
                .onSnapshot((querySnapshot) => {
                    const _companys = [];
                    querySnapshot.forEach(doc => {
                        const { name } = doc.data();
                        _companys.push({
                            value: doc.id,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: name
                        });
                    });
                    setCompanys(_companys)

                });
            return () => {
                userRefDoc && userRefDoc();
            }
        }

    }, [props.dealersettings])

    useEffect(() => {
        const { companyFilter } = props;

        if (!_.isEmpty(companyFilter))
            setFilterFields(companyFilter.value);
        else
            setFilterFields({});

    }, [props.companyFilter])

    useEffect(() => {
        const { dealersettings, clientUsers, companyFilter } = props;

        // const viewOtherContact = ((!_.isEmpty(dealersettings) &&
        //     !_.isEmpty(dealersettings.rolePermissions) &&
        //     !_.isEmpty(dealersettings.rolePermissions.permissions) &&
        //     dealersettings.rolePermissions.permissions.viewOtherContact) ? true : false);
        const countries = []; const states = [];

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const setttings = dealersettings.client.settings;

            if (localStorage.defaultModule !== 'tradeinPro' && dealersettings.group && dealersettings.group.enableSharedContact && dealersettings.group.clients)
                groups = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: props.dealersettings.group.clients[cid] ? props.dealersettings.group.clients[cid].name : '' } })

            // if (localStorage.defaultModule === 'tradeinPro' && _.isEmpty(groups)) {
            //     let _dealers = [];
            //     dealersettings.clients && dealersettings.clients.filter(c =>
            //         c.moduleSettings
            //         && c.moduleSettings.tradeinPro
            //         && c.moduleSettings.tradeinPro.enabled === true
            //         && c.moduleSettings.tradeinPro.active === true
            //     ).forEach(rec => {
            //         _dealers.push({
            //             label: rec.name,
            //             value: rec.id,
            //             data: rec,
            //             active: true,
            //         })
            //     })
            //     groups = _dealers;
            // }

            let _fields = !_.isEmpty(companyFilter) ? companyFilter.value : {};

            company_type = [];
            setttings.companyType && setttings.companyType.forEach((doc) => {
                company_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('country') && _fields.country && !_.isEmpty(dealersettings.states)) {
                dealersettings.states.filter(e => e.country_code === _fields.country).forEach((data) => {
                    states.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: data.name
                    });
                });
                setStates(states);
            }

        }

        const _clientUsers = [];
        if (clientUsers) {
            let ___clientUsers = clientUsers;
            // if (!viewOtherContact)
            //     ___clientUsers = ___clientUsers.filter(m => m.id === localStorage.uid);

            ___clientUsers.forEach((doc) => {
                _clientUsers.push({
                    value: doc.id,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    searchlabel: doc.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                        {doc.name}
                    </div>
                });
            });
        }

        setClientUsers(_clientUsers);
        setCountries(countries);

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }

    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        let state = Object.assign({}, filterFields);
        state[data.name] = value;
        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        state[data.name] = e.value;

        if (data.name === 'country') {
            if (e)
                handleCountryChange(e.value);
            else
                handleCountryChange('');

            if (state.hasOwnProperty('state'))
                state['state'] = '';
        }

        setFilterFields(state)
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {
            if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];
        }

        setFilterFields(state);

    }

    const handleCountryChange = cCode => {
        const { dealersettings } = props;
        const states = [];
        if (cCode && !_.isEmpty(dealersettings)) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === cCode).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        setStates(states)
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.companyFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {

        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.companyFilter) && !_.isEmpty(props.companyFilter.value) && JSON.stringify(props.companyFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.companyFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }

    }

    const handleSave = () => {
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };
    //#endregion

    // const viewOtherContact = ((!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //     props.dealersettings.rolePermissions.permissions.viewOtherContact) ? true : false);
    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader"> <Translate text={'company'} /></div>

                <div className="advanced-filter-list">

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('owner') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'owner'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'owner'}><Translate text={'owner'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(groups) && !_.isEmpty(columnFields) && columnFields.indexOf('clientID') >= 0)
                            ?
                            <>
                                {/* clientID */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'clientID'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'clientID'}><Translate text={'dealerships'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={groups}
                                                        name={"clientID"}
                                                        placeholder={'select dealer'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('clientID') ? filterFields.clientID : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('type') >= 0)
                            ?
                            <>
                                {/* type */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'type'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('type')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'type'}><Translate text={'companyType'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('type')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={company_type}
                                                        name={"type"}
                                                        placeholder={'select company type'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('type') ? filterFields.type : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('country') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'country'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('country')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'country'}><Translate text={'country&state'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('country')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={countries}
                                                        name={"country"}
                                                        placeholder={'select country'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('country') ? filterFields.country : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={states}
                                                        name={"state"}
                                                        placeholder={'select state'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('state') ? filterFields.state : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('associatedCompany') >= 0)
                            ?
                            <>
                                {/* companyName */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'associatedCompany'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('associatedCompany')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'associatedCompany'}><Translate text={'associatedCompany'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('associatedCompany')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={companys}
                                                        name={"associatedCompany"}
                                                        placeholder={'select associated company'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('associatedCompany') ? filterFields.associatedCompany : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }



                </div>

            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            <button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button>
                            <button type="button" className="btn btn-default float-left ml-2" onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }



            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.companyFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'company'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default CompanyAdvanceFilters;