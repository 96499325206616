/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { ReactSelect, InputText, EvalSearch, PopUpModal } from '../../components';
import { firestoreDB } from "../../services/helper";
import { default as _images } from '../../images';





export default class PpsrScan extends Component {
    constructor(props) {
        super(props);
        this.unsubscribePPSR = null;
        this.state = {
            fields: {
                regNum: (this.props.regNo ? this.props.regNo.toUpperCase() : ''),
                vinNum: (this.props.chassisNo ? this.props.chassisNo.toUpperCase() : ''),
                _state: (this.props.regState ? this.props.regState : ''),
            },
            activeTab: 'reg',
            errors: {},
            license_state: [],
            isLoading: false,
            ppsrList: [],
            certLoader: {}
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = () => {
        const { dealersettings } = this.props;
        let license_state = [];
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const client = dealersettings.client;
            let defaultCountry = client.countryCode ? client.countryCode.toUpperCase() : 'AU';

            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

        }
        this.setState({ license_state: license_state }, () => { this.searchPPSRList(); })
    }

    componentWillUnmount() {
        this.unsubscribePPSR && this.unsubscribePPSR();
    }

    searchPPSRList = (_vinNum) => {
        const { vinNum } = this.state.fields
        const { dealersettings, clientID } = this.props;
        let chassisNo = _vinNum ? _vinNum : vinNum;
        let _clientID = (clientID ? clientID : dealersettings.client.id)
        if (chassisNo && _clientID) {

            this.unsubscribePPSR && this.unsubscribePPSR();
            this.unsubscribePPSR = firestoreDB(dealersettings).firestore().collection(`ppsr`)
                .where('clientID', '==', _clientID)
                .where('vinNo', '==', chassisNo)
                .where('success', '==', true)
                .onSnapshot((querySnapshot) => {
                    let _customFilters = [];
                    querySnapshot.forEach(doc => {
                        const _filt = {
                            ...doc.data(),
                            documentID: doc.id
                        }

                        _filt.stolen = !_.isEmpty(_filt?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.StolenDetails?.StolenDetail) ? 'Yes' : 'No'
                        _filt.wov = !_.isEmpty(_filt?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.WrittenOffDetails?.WrittenOffDetail) ? 'Yes' : 'No'

                        _customFilters.push(_filt);
                    });
                    this.setState({ ppsrList: _.orderBy(_customFilters, ['logDate'], ['desc']) });

                });
        }
        else {
            this.clearList();
        }

    }

    clearList = () => {
        this.unsubscribePPSR && this.unsubscribePPSR();
        this.setState({ ppsrList: [] });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '')).toUpperCase();
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name) });
        if (name === 'vinNum' && _.isEmpty(fields[name])) this.clearList();
    }


    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(data.name) });

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let mandatoryFields = fields.regNum && !fields.vinNum ? ['regNum', '_state'] : ['vinNum']

        for (let [key, value] of Object.entries(fields)) {
            if (mandatoryFields.indexOf(key) >= 0) {
                if (!fields[key]) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }
        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.searchMotorWeb(this);
        }
        else
            return formIsValid;
    };



    searchMotorWeb = (e) => {
        const fields = Object.assign({}, this.state.fields);
        const { dealersettings } = this.props;


        if (fields.vinNum) {
            this.setState({ isLoading: true })
            this.handlePpsrSearch();
            return;
        }
        else {

            const _autograbIntegrations = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
            const autoGrabEnabled = _autograbIntegrations?.active && _autograbIntegrations?.enabled ? true : ((!_.isEmpty(dealersettings?.client?.moduleSettings?.autograb) &&
                dealersettings.client.moduleSettings.autograb.enabled &&
                dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : false);
            if (autoGrabEnabled) {
                this.setState({ isLoading: true })
                var objData = Object.assign({}, { "clientID": dealersettings?.client?.id, upstreamData: true });
                objData.regNo = fields.regNum;
                objData.state = fields._state;

                //console.log('handleAutoGrabSearch', objData);
                const tradeinplussendEmail = window.firebase.functions().httpsCallable('autograb-searchVehicle');
                tradeinplussendEmail(objData).then((response) => {
                    //console.log('autograb-searchVehicle', response, response.data?.success);
                    if (response.data.success && !_.isEmpty(response.data?.data?.vehicle?.vinNo)) {
                        let state = Object.assign({}, this.state.fields);
                        state.vinNum = response.data.data.vehicle.vinNo;
                        this.setState({ fields: state }, () => { this.handlePpsrSearch(); this.saveVIN(state.vinNum) })
                    }
                    else {
                        this.setState({ isLoading: false })
                        Swal.fire(CommonHelper.showLocale(this.props, 'No record found!'), '', 'info')
                        return
                    }
                });
            }
            else {
                Swal.fire(CommonHelper.showLocale(this.props, 'Please enter VIN.'), '', 'info')
                return
            }

        }
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(_param)
        // };

        // let tradeinplusURL = dealersettings && dealersettings.tradeinplusURL ? dealersettings.tradeinplusURL : 'https://demo.fusionsd.net/tradeinplusqa';
        // let url = tradeinplusURL + '/api/TradeInApp/Action/GetNevdisVehicle'
        // fetch(url, requestOptions)
        //     .then(async response => {
        //         const data = await response.json();

        //         //check for success response
        //         if (response.ok && data && data.success && data?.data?.vin) {
        //             let state = Object.assign({}, this.state.fields);
        //             state.vinNum = data.data.vin;
        //             this.setState({ fields: state }, () => { this.handlePpsrSearch(); this.saveVIN(state.vinNum) })
        //         }
        //         else {
        //             this.setState({ isLoading: false })
        //             Swal.fire(CommonHelper.showLocale(this.props, 'No record found!'), '', 'info')
        //         }
        //     })
        //     .catch(error => {
        //         console.error('There was an error!', error);
        //     });

    };

    saveVIN = (_vinNum) => {
        const { dealersettings, tradeinID } = this.props;
        if (_vinNum && tradeinID) {
            firestoreDB(dealersettings).firestore().collection('tradeins').doc(tradeinID).set({ 'chassisNo': _vinNum }, { merge: true })
        }

    }

    handlePpsrSearch = () => {
        const { dealersettings, clientID, tradeinProID, enquiryID, tradeinID, serviceID, fromList } = this.props;
        let state = Object.assign({}, this.state.fields);
        var objData = Object.assign({},
            {
                "vinNo": state.vinNum,
                "tradeinProID": (tradeinProID || null),
                "enquiryID": (enquiryID || null),
                "serviceID": (serviceID || null),
                "owner": localStorage.uid,
                "clientID": clientID ? clientID : dealersettings?.client?.id,
                "tradeinID": (tradeinID || null)
            });

        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        //console.log('handlePpsrSearch', objData);
        const tradeinplussendEmail = window.firebase.functions().httpsCallable('ppsr-search');
        tradeinplussendEmail(objData).then((response) => {
            //console.log('ppsr-search', response, response.data?.success);
            this.setState({ isLoading: false })
            if (response.data.success) {
                if (fromList)
                    this.bindPpsrData(response.data);
                else
                    this.searchPPSRList();

                if (_.isEmpty(this.props.chassisNo) || (!_.isEmpty(this.props.chassisNo) && (this.props.chassisNo !== state.vinNum)))
                    this.saveVIN(state.vinNum)
            }
            else {
                if (response.data?.error) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Fatal error.'), '', 'info')
                }
                else if (response.data?.success === false || response.data?.summary?.NevdisData?.VerificationStatus === "NotFound") {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Data not found!'), 'Search with different data.', 'info')
                }
                this.clearList();
            }

        });
    };

    bindPpsrData = (_filt) => {
        let _customFilters = [];
        _filt.logDate = _filt.logDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_filt.logDate._seconds)._d) : _filt.logDate;
        _filt.stolen = !_.isEmpty(_filt?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.StolenDetails?.StolenDetail) ? 'Yes' : 'No'
        _filt.wov = !_.isEmpty(_filt?.summary?.NevdisData?.NevdisVehicles?.NevdisVehicle?.WrittenOffDetails?.WrittenOffDetail) ? 'Yes' : 'No'
        _customFilters.push(_filt);

        this.setState({ ppsrList: _customFilters });
    }

    getppsrCertificate = (id, number) => {
        const { dealersettings, clientID } = this.props;
        this.setState({
            certLoader: {
                [id]: true
            }
        })
        var objData = Object.assign({},
            {
                documentID: id,
                clientID: clientID ? clientID : dealersettings?.client?.id,
                SearchCertificateNumber: number


            });
        const ppsrcetificate = window.firebase.functions().httpsCallable('ppsr-certificate');
        ppsrcetificate(objData).then((response) => {
            this.setState({
                certLoader: {
                    [id]: false
                }
            })
            if (response.data.success) {
                window.open(response.data.data, '_blank');
            }
            else {
                if (response.data?.message) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Fatal error!'), response.data?.message, 'error')
                }
                else {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Something went wrong!'), 'Certificate not generated.', 'error')
                }
                //this.clearList();
            }

        });
    }

    render() {
        const { regNum, _state, vinNum } = this.state.fields;
        const { show, clientUsers, groupUsers, dealersettings } = this.props;
        const { license_state, isLoading, ppsrList, certLoader } = this.state;

        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        const accessToNewPPSR = (dealersettings?.rolePermissions?.permissions?.accessToNewPPSR) ? true : false;
        const _autograbIntegrations = Object.assign({}, dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
        const autoGrabEnabled = _autograbIntegrations?.active && _autograbIntegrations?.enabled ? true : ((!_.isEmpty(dealersettings?.client?.moduleSettings?.autograb) &&
            dealersettings.client.moduleSettings.autograb.enabled &&
            dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : false);
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`${!_.isEmpty(ppsrList) ? 'modal-dialog-scrollable' : ''} modal-dialog-centered modal-ppsr-manager`}>
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'New PPSR'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="form-style w-100">
                                    {
                                        autoGrabEnabled && (<>
                                            <div className={`form-row ${isLoading ? 'form-disable' : ''}`}>
                                                <div className="form-group col-md-6">
                                                    <label ><Translate text={'vehicleRegNo'} /></label>
                                                    <InputText
                                                        name="regNum"
                                                        value={regNum}
                                                        onChange={this.handleOnChange}
                                                        autoComplete="off"
                                                        placeholder={'registration number'}
                                                        className={`form-control ${this.state.errors["regNum"]}`}
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label > <Translate text={'state'} /></label>
                                                    <ReactSelect
                                                        options={license_state}
                                                        name={"_state"}
                                                        placeholder={'select state'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={_state}
                                                        classNamePrefix={`${this.state.errors["_state"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>

                                            </div>

                                            <div className="ppsr-text-divider">OR</div> </>)
                                    }

                                    <div className={`form-row ${isLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'chassisNo'} /></label>
                                            <InputText
                                                type="text"
                                                name="vinNum"
                                                value={vinNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'chassis/vin number'}
                                                className={`form-control ${this.state.errors["vinNum"]}`}
                                            />
                                        </div>

                                    </div>

                                    {
                                        !_.isEmpty(ppsrList)
                                            ?
                                            <>
                                                <div className="ppsr-table-wrap">
                                                    <h3>PPSR</h3>
                                                    <div className="common-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="head-light" width="35%"><Translate text={'date'} toUpperCase={true} /></th>
                                                                    <th scope="col" className="head-light" width="20%"><Translate text={'Stolen'} toUpperCase={true} /></th>
                                                                    <th scope="col" className="head-light border-right-0" width="20%">WOV</th>
                                                                    <th scope="col" className="head-light border-left-0" width="25%"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    ppsrList.map((_data, index) => {
                                                                        return <tr key={index}>
                                                                            <td>
                                                                                <div className="ppsr-list-items-info pr-0 ">
                                                                                    <h4 title="31/03/2022 01:19 PM">{_data.logDate ? moment.unix(_data.logDate.seconds).format('DD/MM/YYYY hh:mm A') : '--'}</h4>
                                                                                    {_data.owner ? <h5>{CommonHelper.getUserNamebyId(allUsers, _data.owner)}</h5> : <></>}
                                                                                </div>

                                                                            </td>
                                                                            <td>{_data.stolen}</td>
                                                                            <td className="border-right-0">{_data.wov}</td>
                                                                            <td className="border-left-0">
                                                                                {
                                                                                    _data.certURL || _data?.summary?.SearchCertificateNumber
                                                                                        ?
                                                                                        <div className="ppsr-table-width-fix">
                                                                                            <button className="btn btn-sm btn-secondary" style={{ cursor: 'pointer', float: 'right' }}
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    if (_data.certURL) {
                                                                                                        window.open(_data.certURL, '_blank');
                                                                                                    }
                                                                                                    else {
                                                                                                        this.getppsrCertificate(_data.documentID, _data.summary.SearchCertificateNumber)
                                                                                                    }
                                                                                                }}>
                                                                                                {certLoader[_data.documentID] ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <><i className="ico icon-mail-draft"></i></>}
                                                                                                PPSR Report</button>

                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                }

                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }



                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose() }}> <Translate text={'cancel'} /></button>

                        {
                            !_.isEmpty(ppsrList) && (<button type="button" className="btn btn-secondary float-right ml-2" onClick={(e) => { e.preventDefault(); this.props.handleClose() }}>
                                <Translate text={'done'} />
                            </button>)
                        }

                        {
                            accessToNewPPSR && (<button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                                {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <><img src={_images.ppsr_logo} width="14" className="mr-1" style={{ marginTop: '-2px' }} /></>}
                                <Translate text={'Run PPSR'} />
                            </button>)
                        }

                    </Modal.Footer>


                </Modal>


            </>


        );
    }
}

