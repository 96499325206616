import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import moment from 'moment';

import LeadsBucket from '../dashboard/leadsBucket'
import ActivitiesWidget from '../dashboard/activitiesWidget'
import CampaignCounts from '../dashboard/campaignCounts'
import ActivitiesCount from '../dashboard/activitiesCount'
import InboundActivitiesCount from '../dashboard/inboundactivitiesCount'
import OriginCounts from '../dashboard/originCounts'
import EnquiryTypeCounts from '../dashboard/enquiryTypeCounts'
import SalesFunnel from '../dashboard/salesFunnel'
import StatusCounts from '../dashboard/statusCounts';
import SalesTarget from '../dashboard/salesTarget';
import DealerTarget from '../dashboard/dealerTarget';
import LabelCounts from '../dashboard/labelsCounts';
import AllUsersTarget from '../dashboard/allUsersTarget'
import FleetCounts from '../dashboard/fleetCounts'
import LoanBookingsWidget from '../dashboard/loanBookingsWidget'
import InboundActivitiesWidget from '../dashboard/inboundactivitiesWidget'
import PerformanceReview from "../dashboard/performanceReview";
import CampaignPerformance from './CampaignPerformance'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { widgetSettingsNames } from "./viewModel";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default class DragDropLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      layouts: { lg: props.initialLayout }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initialLayout !== this.state.layouts) {
      this.setState({ layouts: { lg: nextProps.initialLayout } });
    }
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onLayoutChange(layout, layouts) {
    //console.log('layout, layouts', layout, layouts)
    this.props.onLayoutChange(layout, this.props.sortedWidgets, this.props.hiddenWidgets);
  }

  calculateHeight(sectionID, layoutArr) {
    let selectedSection = layoutArr.filter(obj => obj.i === sectionID)
    if (selectedSection.length === 0)
      return 30
    let columnHeight = Number(selectedSection[0].h)
    if (columnHeight === 1) {
      return 30
    }
    columnHeight = columnHeight - 1
    columnHeight = columnHeight * 45
    columnHeight = columnHeight + 30

    return columnHeight
  }

  filterLayouts = (layouts) => {

    if (!this.props.pipeline.some(item => item.value === 'LeadsBucket') || (this.props.clientLostApproval && this.props.managerLostApproval)) {
      if (layouts.lg[0].i === '0') {
        layouts.lg.shift()
      }
    }
    //console.log('layout passed to the grid', layouts)

    // settings height and width to 0 of hidden widgets
    // let layoutWithDashboardSettingsApplied = {...layouts}
    let layoutWithDashboardSettingsApplied = layouts.lg.filter(a => a.i !== 'null').map((rec, index) => {
      return this.props.hiddenWidgets.includes(rec.i) ? {
        ..._.pickBy(rec, v => v !== null && v !== undefined),
        w: 0,
        h: 0
      } : {
        ..._.pickBy(rec, v => v !== null && v !== undefined),
        i: rec.i === 'null' ? `${index}` : rec.i,
        maxH: 16,
        maxW: 12,
        minH: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
        minW: 4,
        h: (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? ((rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget') ? 4 : (rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
        w: (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
        isResizable: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? false : true,
        isBounded: false,
        isDraggable: true,
        resizeHandles: false
      }
    })
    return { lg: layoutWithDashboardSettingsApplied }
  }


  render() {
    const enableLoanBooking = ((!_.isEmpty(this.props.dealersettings) &&
      !_.isEmpty(this.props.dealersettings.client) &&
      !_.isEmpty(this.props.dealersettings.client.clientSettings) &&
      this.props.dealersettings.client.clientSettings.enableLoanBooking) ? true : false);
    const showInboundActivities = ((!_.isEmpty(this.props.dealersettings) &&
      !_.isEmpty(this.props.dealersettings.client) &&
      !_.isEmpty(this.props.dealersettings.client.clientSettings) &&
      this.props.dealersettings.client.clientSettings.separateInbdUserAct) ? true : false);
    const performanceReviewEnabled = ((this.props.dealersettings && !_.isEmpty(this.props.dealersettings.client) && !_.isEmpty(this.props.dealersettings.client.moduleSettings) &&
      !_.isEmpty(this.props.dealersettings.client.moduleSettings.performanceReview) &&
      this.props.dealersettings.client.moduleSettings.performanceReview.enabled) ? true : false);

    const {
      handleShowEnquiryList,
      handleShowActivityList,
      pipelineEnquiries,
      selectedPipeline,
      selectedUser,
      clientLostApproval,
      managerLostApproval,
      dateRange,
      applydateRange,
      loader,
      allActivities,
      testdrives,
      tradeins,
      contacts,
      dealersettings,
      pipeline,
      ownerDashboard,
      hiddenWidgets,
      sortedWidgets,
      removeUnAllowedWidgets,
      staticDashboard
    } = this.props;

    return (pipeline.some(item => item.value === 'LeadsBucket') || (clientLostApproval && managerLostApproval)) ? (
      <ResponsiveReactGridLayout
        {...this.props}
        layouts={staticDashboard ? this.state.layouts : this.filterLayouts(this.state.layouts)}
        onBreakpointChange={this.onBreakpointChange}
        onLayoutChange={this.onLayoutChange}
        measureBeforeMount={false}
        useCSSTransforms={this.state.mounted}
        compactType={this.state.compactType}
        preventCollision={!this.state.compactType}
        margin={[15, 15]}
        isDraggable={!staticDashboard}
        isResizable={!staticDashboard}
        draggableHandle='.draggable-section'
        draggableCancel='.dashboard-box-body'
      >
        {(pipeline.some(item => item.value === 'LeadsBucket') || (clientLostApproval && managerLostApproval)) &&
          sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsBucket') ? (
          <div key={'LeadsBucket'} id={'LeadsBucket'}>
            <LeadsBucket {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('LeadsBucket', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsBucket')}
              refreshData={this.props.refreshData}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'LeadsBucket'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByLabels') ? (
          <div key={'LeadsByLabels'} id={'LeadsByLabels'}>
            <LabelCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByLabels', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByLabels')}
              hideRemoveBtn={staticDashboard}
              minHeight={staticDashboard ? '350px' : ''}
              title={_.find(widgetSettingsNames, { value: 'LeadsByLabels' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByLabels'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('Activities') ? (
          <div key={'Activities'} id={'Activities'}>
            {
              (showInboundActivities && this.props.dealersettings.client.roles.some(e => e.permissions && e.permissions.separateInbdUserAct === true))
                ?
                <InboundActivitiesCount {...this.props}
                  selectedPipeline={selectedPipeline}
                  selectedUser={selectedUser}
                  height={this.calculateHeight('Activities', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  ownerDashboard={ownerDashboard}
                  refreshData={this.props.refreshData}
                  removeWidget={() => this.props.removeWidget('Activities')}
                  roleIDs={this.props.dealersettings.client.roles
                    .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === false)
                    .map(r => { return r.documentID })
                    .join(',')}
                  title={'Activities'}
                  hideRemoveBtn={staticDashboard}
                />
                :
                <ActivitiesCount {...this.props}
                  selectedPipeline={selectedPipeline}
                  selectedUser={selectedUser}
                  height={this.calculateHeight('Activities', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  ownerDashboard={ownerDashboard}
                  refreshData={this.props.refreshData}
                  removeWidget={() => this.props.removeWidget('Activities')}
                  hideRemoveBtn={staticDashboard}
                />
            }

          </div>
        ) : (<Fragment key={'Activities'}></Fragment>)}

        {showInboundActivities && this.props.dealersettings.client.roles.some(e => e.permissions && e.permissions.separateInbdUserAct === true) && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('InboundActivities') ? (
          <div key={'InboundActivities'} id={'InboundActivities'}>
            <InboundActivitiesCount {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('InboundActivities', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('InboundActivities')}
              roleIDs={this.props.dealersettings.client.roles
                .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === true)
                .map(r => { return r.documentID })
                .join(',')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'InboundActivities'}></Fragment>)}


        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByOrigin') ? (
          <div key={'LeadsByOrigin'} id={'LeadsByOrigin'}>
            <OriginCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByOrigin', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByOrigin')}
              hideRemoveBtn={staticDashboard}
              title={_.find(widgetSettingsNames, { value: 'LeadsByOrigin' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByOrigin'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByEnquiryType') ? (
          <div key={'LeadsByEnquiryType'} id={'LeadsByEnquiryType'}>
            <EnquiryTypeCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByEnquiryType', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByEnquiryType')}
              hideRemoveBtn={staticDashboard}
              title={_.find(widgetSettingsNames, { value: 'LeadsByEnquiryType' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByEnquiryType'}></Fragment>)}

        {sortedWidgets
          .filter(a => removeUnAllowedWidgets(a))
          .includes('SalesFunnel') ?
          (
            <div key={'SalesFunnel'} id={'SalesFunnel'}>
              <SalesFunnel
                {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={pipelineEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                height={this.calculateHeight(
                  'SalesFunnel',
                  this.props.initialLayout,
                )}
                reloadData={this.props.reloadData}
                ownerDashboard={ownerDashboard}
                removeWidget={() => this.props.removeWidget('SalesFunnel')}
                enquiryStatus={this.props.enquiryStatus}
                isMclaren={false}
                id={`SalesFunnel`}
                hideRemoveBtn={staticDashboard}
              />
            </div>
          ) : (
            <Fragment key={'SalesFunnel'}></Fragment>
          )}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByCampaign') ? (
          <div key={'LeadsByCampaign'} id={'LeadsByCampaign'}>
            <CampaignCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByCampaign', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByCampaign')}
              hideRemoveBtn={staticDashboard}
              title={_.find(widgetSettingsNames, { value: 'LeadsByCampaign' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByCampaign'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByStatus') ? (
          <div key={'LeadsByStatus'} id={'LeadsByStatus'}>
            <StatusCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('LeadsByStatus', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByStatus')}
              hideRemoveBtn={staticDashboard}
              title={_.find(widgetSettingsNames, { value: 'LeadsByStatus' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByStatus'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('SalesTarget') ? (
          <div key={'SalesTarget'} id={'SalesTarget'}>
            {
              ownerDashboard ? (
                <SalesTarget {...this.props}
                  pipelineEnquiries={pipelineEnquiries}
                  allActivities={allActivities}
                  testdrives={testdrives}
                  tradeins={tradeins}
                  contacts={contacts}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  applydateRange={applydateRange}
                  height={this.calculateHeight('SalesTarget', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  removeWidget={() => this.props.removeWidget('SalesTarget')}
                  hideRemoveBtn={staticDashboard}
                />
              ) : (
                <AllUsersTarget {...this.props}
                  pipelineEnquiries={pipelineEnquiries}
                  allActivities={allActivities}
                  testdrives={testdrives}
                  tradeins={tradeins}
                  contacts={contacts}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  applydateRange={applydateRange}
                  height={this.calculateHeight('SalesTarget', this.props.initialLayout)}
                  removeWidget={() => this.props.removeWidget('SalesTarget')}
                  reloadData={this.props.reloadData}
                  hideRemoveBtn={staticDashboard}
                />
              )
            }
          </div>
        ) : (<Fragment key={'SalesTarget'}></Fragment>)}

        {
          (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.accessDealertarget && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('DealerTarget')) ? (
            <div key={'DealerTarget'} id={'DealerTarget'}>
              <DealerTarget {...this.props}
                pipelineEnquiries={pipelineEnquiries}
                allActivities={allActivities}
                testdrives={testdrives}
                tradeins={tradeins}
                contacts={contacts}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                applydateRange={applydateRange}
                removeWidget={() => this.props.removeWidget('DealerTarget')}
                height={this.calculateHeight('DealerTarget', this.props.initialLayout)}
                hideRemoveBtn={staticDashboard}
              />
            </div>
          ) : (<Fragment key={'DealerTarget'}></Fragment>)
        }

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('ActivitiesWidget') ? (
          <div key={'ActivitiesWidget'} id={'ActivitiesWidget'}>
            <ActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('ActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('ActivitiesWidget')}
              hideRemoveBtn={staticDashboard}
              title={'Enquiries without scheduled activity'}
            />
          </div>
        ) : (<Fragment key={'ActivitiesWidget'}></Fragment>)}


        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FleetCounts') ? (
          <div key={'FleetCounts'} id={'FleetCounts'}>
            <FleetCounts {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('FleetCounts', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('FleetCounts')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'FleetCounts'}></Fragment>)}

        {enableLoanBooking && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LoanBookingsWidget') ? (
          <div key={'LoanBookingsWidget'} id={'LoanBookingsWidget'}>
            <LoanBookingsWidget {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('LoanBookingsWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('LoanBookingsWidget')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'LoanBookingsWidget'}></Fragment>)}

        {showInboundActivities && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('InboundActivitiesWidget') ? (
          <div key={'InboundActivitiesWidget'} id={'InboundActivitiesWidget'}>
            <InboundActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('InboundActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('InboundActivitiesWidget')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'InboundActivitiesWidget'}></Fragment>)}
        {
          performanceReviewEnabled && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('PerformanceReviewWidget') ? (
            <div key={'PerformanceReviewWidget'} id={'PerformanceReviewWidget'}>
              <PerformanceReview {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                handleShowActivityList={handleShowActivityList}
                pipelineEnquiries={pipelineEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                clientLostApproval={clientLostApproval}
                managerLostApproval={managerLostApproval}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('PerformanceReviewWidget', this.props.initialLayout)}
                reloadData={this.props.reloadData}
                ownerDashboard={ownerDashboard}
                refreshData={this.props.refreshData}
                removeWidget={() => this.props.removeWidget('PerformanceReviewWidget')}
                hideRemoveBtn={staticDashboard}
              />
            </div>
          ) : (<Fragment key={'PerformanceReviewWidget'}></Fragment>)
        }
        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('CampaignPerformance') ? (
          <div key={'CampaignPerformance'} id={'CampaignPerformance'}>
            <CampaignPerformance
              {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight(
                'CampaignPerformance',
                this.props.initialLayout,
              )}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('CampaignPerformance')}
              enquiryStatus={this.props.enquiryStatus}
              isMclaren={false}
              id={`CampaignPerformance`}
              kpi={'campaigns'}
              fieldName={'cpgv'}
              title={'Campaign Performance'}
            />
          </div>
        ) : (<Fragment key={'InboundActivitiesWidget'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('OriginPerformance') ? (
          <div key={'OriginPerformance'} id={'OriginPerformance'}>
            <CampaignPerformance
              {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight(
                'OriginPerformance',
                this.props.initialLayout,
              )}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('OriginPerformance')}
              enquiryStatus={this.props.enquiryStatus}
              isMclaren={false}
              id={`OriginPerformance`}
              kpi={'origins'}
              fieldName={'orgv'}
              title={'Origin Performance'}
            />
          </div>
        ) : (<Fragment key={'InboundActivitiesWidget'}></Fragment>)}
      </ResponsiveReactGridLayout>
    ) : (
      <ResponsiveReactGridLayout
        {...this.props}
        layouts={this.filterLayouts(this.state.layouts)}
        onBreakpointChange={this.onBreakpointChange}
        onLayoutChange={this.onLayoutChange}
        measureBeforeMount={false}
        useCSSTransforms={this.state.mounted}
        compactType={this.state.compactType}
        preventCollision={!this.state.compactType}
        margin={[15, 15]}
        isResizable={true}
        draggableHandle='.draggable-section'
        draggableCancel='.dashboard-box-body'
      >
        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByLabels') ? (
          <div key={'LeadsByLabels'} id={'LeadsByLabels'}>
            <LabelCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByLabels', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByLabels')}
              title={_.find(widgetSettingsNames, { value: 'LeadsByLabels' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByLabels'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('Activities') ? (
          <div key={'Activities'} id={'Activities'}>
            <ActivitiesCount {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('Activities', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('Activities')}
            />
          </div>
        ) : (<Fragment key={'Activities'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByOrigin') ? (
          <div key={'LeadsByOrigin'} id={'LeadsByOrigin'}>
            <OriginCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByOrigin', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByOrigin')}
              title={_.find(widgetSettingsNames, { value: 'LeadsByOrigin' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByOrigin'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByEnquiryType') ? (
          <div key={'LeadsByEnquiryType'} id={'LeadsByEnquiryType'}>
            <EnquiryTypeCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByEnquiryType', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByEnquiryType')}
              title={_.find(widgetSettingsNames, { value: 'LeadsByEnquiryType' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByEnquiryType'}></Fragment>)}

        {sortedWidgets
          .filter(a => removeUnAllowedWidgets(a))
          .includes('SalesFunnel') ?
          (
            <div key={'SalesFunnel'} id={'SalesFunnel'}>
              <SalesFunnel
                {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={pipelineEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                height={this.calculateHeight(
                  'SalesFunnel',
                  this.props.initialLayout,
                )}
                reloadData={this.props.reloadData}
                ownerDashboard={ownerDashboard}
                removeWidget={() => this.props.removeWidget('SalesFunnel')}
                enquiryStatus={this.props.enquiryStatus}
                isMclaren={false}
                id={`SalesFunnel`}
              />
            </div>
          ) : (
            <Fragment key={'SalesFunnel'}></Fragment>
          )}


        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByCampaign') ? (
          <div key={'LeadsByCampaign'} id={'LeadsByCampaign'}>
            <CampaignCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByCampaign', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByCampaign')}
              title={_.find(widgetSettingsNames, { value: 'LeadsByCampaign' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByCampaign'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByStatus') ? (
          <div key={'LeadsByStatus'} id={'LeadsByStatus'}>
            <StatusCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('LeadsByStatus', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByStatus')}
              title={_.find(widgetSettingsNames, { value: 'LeadsByStatus' })?.name}
            />
          </div>
        ) : (<Fragment key={'LeadsByStatus'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('SalesTarget') ? (
          <div key={'SalesTarget'} id={'SalesTarget'}>
            {
              ownerDashboard ? (
                <SalesTarget {...this.props}
                  pipelineEnquiries={pipelineEnquiries}
                  allActivities={allActivities}
                  testdrives={testdrives}
                  tradeins={tradeins}
                  contacts={contacts}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  applydateRange={applydateRange}
                  height={this.calculateHeight('SalesTarget', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  removeWidget={() => this.props.removeWidget('SalesTarget')}
                />
              ) : (
                <AllUsersTarget {...this.props}
                  pipelineEnquiries={pipelineEnquiries}
                  allActivities={allActivities}
                  testdrives={testdrives}
                  tradeins={tradeins}
                  contacts={contacts}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  applydateRange={applydateRange}
                  height={this.calculateHeight('SalesTarget', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  removeWidget={() => this.props.removeWidget('SalesTarget')}
                />
              )
            }
          </div>
        ) : (<Fragment key={'SalesTarget'}></Fragment>)}

        {
          (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.accessDealertarget && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('DealerTarget')) ? (
            <div key={'DealerTarget'} id={'DealerTarget'}>
              <DealerTarget {...this.props}
                pipelineEnquiries={pipelineEnquiries}
                allActivities={allActivities}
                testdrives={testdrives}
                tradeins={tradeins}
                contacts={contacts}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('DealerTarget', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('DealerTarget')}
              />
            </div>
          ) : (<Fragment key={'DealerTarget'}></Fragment>)
        }

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('ActivitiesWidget') ? (
          <div key={'ActivitiesWidget'} id={'ActivitiesWidget'}>
            <ActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('ActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('ActivitiesWidget')}
            />
          </div>
        ) : (<Fragment key={'ActivitiesWidget'}></Fragment>)}


        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FleetCounts') ? (
          <div key={'FleetCounts'} id={'FleetCounts'}>
            <FleetCounts {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('FleetCounts', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('FleetCounts')}
            />
          </div>
        ) : (<Fragment key={'FleetCounts'}></Fragment>)}

        {enableLoanBooking && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LoanBookingsWidget') ? (
          <div key={'LoanBookingsWidget'} id={'LoanBookingsWidget'}>
            <LoanBookingsWidget {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('LoanBookingsWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('LoanBookingsWidget')}
            />
          </div>
        ) : (<Fragment key={'LoanBookingsWidget'}></Fragment>)}
        {
          performanceReviewEnabled && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('PerformanceReviewWidget') ? (
            <div key={'PerformanceReviewWidget'} id={'PerformanceReviewWidget'}>
              <PerformanceReview {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                handleShowActivityList={handleShowActivityList}
                pipelineEnquiries={pipelineEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                clientLostApproval={clientLostApproval}
                managerLostApproval={managerLostApproval}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('PerformanceReviewWidget', this.props.initialLayout)}
                reloadData={this.props.reloadData}
                ownerDashboard={ownerDashboard}
                refreshData={this.props.refreshData}
                removeWidget={() => this.props.removeWidget('PerformanceReviewWidget')}
              />
            </div>
          ) : (<Fragment key={'PerformanceReviewWidget'}></Fragment>)
        }

      </ResponsiveReactGridLayout>
    );
  }
}

DragDropLayout.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function () { },
  cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }
};

