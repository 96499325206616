/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
/** COMPONENTS */
import Translate from '../../constants/translate';
import { GoogleMap } from '../../components';

export default class RouteMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    showDuration = () => {
        let duration = this.props.duration;

        var hours = Math.floor(duration / 60 / 60);
        var minutes = Math.floor(duration / 60) - (hours * 60);
        var seconds = duration % 60;

        return (hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0'));
    }
    render() {

        const { show, polylineCoordinates, distance, duration, speed, maxSpeed, dealersettings } = this.props;

        return (
            <>
                <Modal
                    id="view-map"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-view-map">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'mapView'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="map-view-wrapper">
                                    <div className="map-head-popup">
                                        <ul>
                                            <li> <span><Translate text={'avgSpeed'} /> : </span> {speed ? speed.toFixed(2) : '0'} Km/hr</li>
                                            <li> <span><Translate text={'maxSpeed'} /> : </span> {maxSpeed ? maxSpeed.toFixed(2) : '0'} Km/hr</li>
                                            <li> <span><Translate text={'distance'} /> : </span> {distance ? distance.toFixed(2) : '0'} Km</li>
                                            <li> <span><Translate text={'timeDuration'} /> : </span>{duration ? this.showDuration() : '00:00:00'}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="map-content-area">
                                    <GoogleMap
                                        polylineCoordinates={polylineCoordinates}
                                        dealersettings={dealersettings}
                                    >
                                    </GoogleMap>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

