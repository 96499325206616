import React, { useEffect, useState, useContext } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { tableFields } from './viewModel'
import { ContentOverlay, TableView, ReactSelect } from '../../../../components'
import { ClientContext } from '../../../layout/clientContext'

const ErrorLogs = (props) => {
  const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 195) })
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true)
  const [selectedOEM, setSelectedOEM] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [oemOptions, setOEMOptions] = useState([]);

  const { oemSettings, clients } = useContext(ClientContext);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
        windowHeight: (window.innerHeight - 195)
      })
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubAdminClientList && window.unSubAdminClientList();
    }
  }, [])

  useEffect(() => {
    if (_.isEmpty(oemSettings))
      return

    let _oemSettings = [];
    oemSettings.forEach((rec) => {
      _oemSettings.push({ label: rec.name, value: rec.id, data: rec })
    })
    setOEMOptions(_.orderBy(_oemSettings, ['label'], ['asc']));
  }, [oemSettings])

  useEffect(() => {
    let refErrorData = window.firebase.firestore().collection('errorLogs');
    if (selectedClient) {
      refErrorData = refErrorData.where('clientID', '==', selectedClient)
    }
    refErrorData = refErrorData.orderBy('addedDate', 'desc').limit(50)
    let _data = [];
    refErrorData.get().then(rec => {
      rec.docs.forEach(r => {
        _data.push({
          ...r.data(),
          client: _.find(clients, { documentID: r.data().clientID }) ? _.find(clients, { documentID: r.data().clientID }).name : '',
          strDescription: r.data().description ? (<div title={r.data().description}>{r.data().description.substr(0, 100)}</div>) : '',
          logDate: r.data().addedDate ? moment.unix(r.data().addedDate.seconds).format('DD/MM/YYYY hh:mm A') : ''
        })
      })
      setData(_data);
      setLoader(false)
    })
  }, [selectedClient])




  const handleActionClick = (id, data) => {
    return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
    }}>
      <i className="ico icon-edit"></i>
    </div>)
  }

  const handleOEMdetailsClick = (e, id, data) => {
    e.preventDefault();

  }


  return (
    <>
      <h2>Error logs </h2>
      <div className="divider-line"></div>
      <div className="mt-2">
        <div className="settings-head form-style">
          <div className="form-row">
            <div className="col-3">
              <div className="form-group">
                <ReactSelect
                  options={oemOptions}
                  name={"oem"}
                  placeholder={'select oem'}
                  onChange={(e) => {
                    setSelectedOEM(e ? e.value : null)
                  }}
                  value={selectedOEM}
                  classNamePrefix={`cursor-pointer basic-select`}
                  removeClearable={false}
                >
                </ReactSelect>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <ReactSelect
                  options={selectedOEM && clients ? clients.filter(a => a.settingsID === selectedOEM).map(rec => {
                    return {
                      label: rec.name,
                      value: rec.documentID
                    }
                  }) : []}
                  name={"client"}
                  placeholder={'select client'}
                  onChange={(e) => {
                    setSelectedClient(e ? e.value : null)
                  }}
                  value={selectedClient}
                  classNamePrefix={`cursor-pointer basic-select`}
                  removeClearable={false}
                >
                </ReactSelect>
              </div>
            </div>
          </div>
        </div>
        {
          loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
            data.length > 0 ? (
              <div className="common-table">
                <TableView
                  datalist={data}
                  height={windowSize.windowHeight}
                  width={windowSize.windowWidth}
                  columns={tableFields}
                  dynamicFields={tableFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                  handleActionClick={handleActionClick}
                  handleRowSingleClick={handleOEMdetailsClick}
                  isSettings={false}
                  isReorderable={false}
                />
              </div>
            ) : (
              <div className="common-table">
                <div className="text-center p-5">
                  <p>No data found.</p>
                </div>
              </div>
            )
        }
      </div>
    </>
  )
}

export default ErrorLogs