import React from 'react'
import _ from 'lodash'
import { Tabs, Tab } from 'react-bootstrap';
import DealerTargets from './clientTargets'
import UserTargets from './userTargets'
import Translate from '../../../../constants/translate';
import { UpgradePlan } from '../../../../components'
import CommonHelper from '../../../../services/common';

const DealerTarget = (props) => {
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);
    return (
        <>
            {
                liteVersion ? (<UpgradePlan />) : (<></>)
            }
            <h2><Translate text={'Targets'} /></h2>
            <div className={`settings-tab-wrap tabs ${liteVersion ? 'form-lite' : ''}`} style={liteVersion ? { height: `${window.innerHeight - 70}px` } : {}}>
                <div className='tabs-wraper'>
                    <nav>
                        <Tabs
                            defaultActiveKey='usertargets'
                            className='nav-fill'
                            mountOnEnter={true}
                        >
                            <Tab eventKey='usertargets' title={CommonHelper.showLocale(props, 'User Targets')}>
                                <UserTargets {...props} />
                            </Tab>
                            <Tab eventKey='dealertargets' title={CommonHelper.showLocale(props, 'Dealer Targets')}>
                                <DealerTargets {...props} />
                            </Tab>
                        </Tabs>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default DealerTarget