import React from 'react';
export const triggerVM = {
    documentID: null,
    clientID: '',
    category: '',
    title: '',
    subject: '',
    status: '',
    triggerType: '',
    todoType: '',
    templateID: null,
    make: '',
    model: '',
    bodyType: '',
    saleType: '',
    recipientType: '',
    replyType: '',
    ccType: '',
    recipientTo: '',
    mailBcc: '',
    replyTo: '',
    period: '',
    periodValue: 0,
    periodEvery: 0,
    periodEveryType: '',
    startAfter: 0,
    startAfterType: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    isActive: true,
    notes: '',
    triggerFor: '',
    pipeline: '',
    stage: '',
    level: '',
    levelID: '',
    subLevelIDs: '',
    regionIDs: [],
    groupIDs: [],
    clientIDs: [],
    teammates: [],
    oemID: null,
    pipelines: [],
    stages: [],
    lostReasons: [],
    settingsID:null,
    mailCc: '',
    ccType:'',
    module: '',
    serviceStatus: '',
    triggerFeedback: '',
    senderName: null
}


export const triggerCategory = [
    {
        name: "Activity",
        value: "activity",
        class: "ico icon-trigger-activity"
    },
    {
        name: "Contact",
        value: "contact",
        class: "ico icon-trigger-contact"
    },
    {
        name: "Company",
        value: "company",
        class: "ico icon-trigger-company"
    },
    {
        name: "Service",
        value: "service",
        class: "ico icon-service-settings"
    },
    {
        name: "Service RO",
        value: "serviceRO",
        class: "ico icon-service-settings"
    },
];

export const serviceStatuses = [
    { active: true, value: 'scheduled', label: <div className={`badge badge-pill status-scheduled`}>SCHEDULED</div> },
    { active: true, value: 'checkIn', label: <div className={`badge badge-pill status-checkin`}>CHECKIN</div> },
    { active: true, value: 'technicianRequested', label: <div className={`badge badge-pill status-technicianrequested`}>TECHNICIAN REQUESTED</div> },
    { active: true, value: 'partsRequested', label: <div className={`badge badge-pill status-partsrequested`}>PARTS REQUESTED</div> },
    { active: true, value: 'workshopRequested', label: <div className={`badge badge-pill status-workshoprequested`}>WORKSHOP REQUESTED</div> },
    { active: true, value: 'advisorRequested', label: <div className={`badge badge-pill status-advisorrequested`}>ADVISOR REQUESTED</div> },
    { active: true, value: 'ownerRequested', label: <div className={`badge badge-pill status-ownerrequested`}>OWNER REQUESTED</div> },
    { active: true, value: 'ownerApproved', label: <div className={`badge badge-pill status-ownerapproved`}>OWNER APPROVED</div> },
    { active: true, value: 'ownerDeclined', label: <div className={`badge badge-pill status-ownerdeclined`}>OWNER DECLINED</div> },
    { active: true, value: 'workInProgress', label: <div className={`badge badge-pill status-workinprogress`}>WORKIN PROGRESS</div> },
    { active: true, value: 'completed', label: <div className={`badge badge-pill status-completed`}>COMPLETED</div> },

]

export const eventTypes = [
    {
        active: true,
        name: "Contact Created",
        status: "contactCreated",
        value: "contact",
        desc: "Schedule an Activity, Email or SMS once a contact is created.",
        label: "Contact Created Date"
    },
    {
        active: true,
        name: "Contact Birthday",
        status: "contactBirthday",
        value: "contact",
        desc: "Schedule an Activity, Email or SMS to be triggered for the contact’s birthday.",
        label: "Contact Birthday",
    },
    {
        active: true,
        name: "Company Created",
        status: "companyCreated",
        value: "company",
        desc: "Schedule an Activity, Email or SMS once a company is created",
        label: "Company Created Date",
    },
    {
        active: true,
        name: "Service Status",
        status: "serviceStatus",
        value: "service",
        desc: "Schedule an Activity, Email or SMS once an service is created.",
        label: "Service Created Date",
    },
    {
        active: true,
        name: "Pipeline/Stage Update",
        status: "serviceUpdate",
        value: "service",
        desc: "Schedule an Activity, Email or SMS on pipeline/stage update.",
        label: "Service Modified Date",
    },
    {
        active: true,
        name: "Next Service Due Date",
        status: "nextServiceDueDate",
        value: "service",
        desc: "Schedule an Activity, Email or SMS on next service due date.",
        label: "Service Due Date",
    },
    // hidden for now
    // {
    //     name: "Proposal Done",
    //     status: "proposalDone",
    //     value: "enquiry",
    //     desc: "Lorem ipsum dolor sit amet, consectetur.",
    //     label: "Proposal Done Date",
    // },
    // {
    //     active: true,
    //     name: "Service Scheduled",
    //     label: "Service Scheduled Date",
    //     status: "serviceScheduled",
    //     value: "enquiry",
    //     desc: "Schedule an Activity, Email or SMS once a service is scheduled."
    // },
    // {
    //     active: true,
    //     name: "Service Check In",
    //     label: "Service Check In Date",
    //     status: "serviceCheckIn",
    //     value: "enquiry",
    //     desc: "Schedule an Activity, Email or SMS once an service is check in."
    // },
    {
        active: true,
        name: "Estimated Completion",
        status: "estimatedCompletion",
        value: "service",
        desc: "Schedule an Activity, Email or SMS once estimated completion date is set.",
        label: "Estimated Completion Date",
    },
    // {
    //     active: true,
    //     name: "Service Lost",
    //     label: "Service Lost Date",
    //     status: "enquiryLost",
    //     value: "enquiry",
    //     desc: "Schedule an Activity, Email or SMS once an service is marked as Lost."
    // },
    {
        active: true,
        name: "Appointment Scheduled",
        label: "Appointment Scheduled Date",
        status: "appointmentScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once an appointment is scheduled."
    },
    {
        active: true,
        name: "Appointment Completed",
        label: "Appointment Completed Date",
        status: "appointmentCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once an appointment is completed."
    },
    {
        active: true,
        name: "Call Scheduled",
        label: "Call Scheduled Date",
        status: "callScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once a call is scheduled."
    },
    {
        active: true,
        name: "Call Completed",
        label: "Call Completed Date",
        status: "callCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once a call is completed."
    },
    {
        active: true,
        name: "Email Scheduled",
        label: "Email Scheduled Date",
        status: "emailScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once an email is scheduled."
    },
    {
        active: true,
        name: "Email Completed",
        label: "Email Completed Date",
        status: "emailCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once an email is completed."
    },
    {
        active: true,
        name: "Delivery Scheduled",
        label: "Delivery Scheduled Date",
        status: "deliveryScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once a Delivery is scheduled."
    },
    {
        active: true,
        name: "Delivery Completed",
        label: "Delivery Completed Date",
        status: "deliveryCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once a Delivery is completed."
    },
    {
        active: true,
        name: "Service RO Created",
        status: "serviceROCreated",
        value: "serviceRO",
        desc: "Schedule an Email or SMS once an service RO is created.",
        label: "Service Appointment Date",
    },
];

export const saleTypes = [
    { active: true, value: 'New', label: 'New' },
    { active: true, value: 'Demo', label: 'Demo' },
    { active: true, value: 'Preowned', label: 'Preowned' }
]

export const mailtotypes = [
    { active: true, value: 'contact', label: 'Contact' },
    { active: true, value: 'custom', label: 'Custom' },
    { active: true, value: 'user', label: 'Users' }
]

export const tiggerFrequency = [
    { active: true, value: 'instant', label: 'Instant', name: 'Instant' },
    { active: true, value: 'afterMins', label: 'Minutes (No.in Minutes)', name: 'Minutes' },
    { active: true, value: 'afterHours', label: 'Hours (No.in Hours)', name: 'Hours' },
    { active: true, value: 'afterDays', label: 'Days (No.in Days)', name: 'Days' },
    { active: true, value: 'iteration', label: 'Iteration', name: 'Iteration' },
    { active: true, value: 'birthday', label: 'Birthday', name: 'Birthday' }
]

export const frequencyOptions = [
    { active: true, value: 'before', label: 'Before' },
    { active: true, value: 'after', label: 'After' }
]

export const priodEveryTypes = [
    { active: true, value: 'day', label: 'Day' },
    { active: true, value: 'month', label: 'Month' },
    { active: true, value: 'year', label: 'Year' }
]

export const triggerTypes = [
    { active: true, value: 'activity', label: 'Activity' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'sms', label: 'SMS' }
]


export const triggerlistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Event Type',
        value: 'strStatus',
        sortValue: 'strStatus',
        flex: 1,
        default: true
    },
    // {
    //     name: 'Trigger Type',
    //     value: 'strtriggerType',
    //     flex: 1,
    //     default: true
    // },
    {
        name: 'Triggered On',
        value: 'strtriggerOn',
        sortValue: 'strtriggerOn',
        flex: 1,
        default: true,
        subText : 'strtriggerType'
    },
    {
        name: 'OEM/Region/Group/Client',
        value: 'levelsDOM',  
        sortValue: 'strlevels',
        flex: 1,
        default: true
    },
    {
        name: 'Level',
        value: 'levelValue',
        sortValue: 'levelValue',
        flex: 0,
        width: 120,
        default: true
    },
    {
        name: 'Active',
        value: 'isActive',
        sortValue: 'isActive',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const replyTypes = [
    { active: true, value: 'owner', label: 'Owner' },
    { active: true, value: 'custom', label: 'Custom' },
]