/** LIBRARIES */
import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//import toast from 'toasted-notes'
import {Modal} from "react-bootstrap";
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { default as _images } from '../../images';


function ReArrangeQueue(props) {
    const [state, setState] = useState(props.activeUsers);

    const { show } = props;

    const divStyle = {
        height: '400px',
        overflow: 'auto'
    };

    useEffect(() => {
        setState({ "userIds": props.activeUsers })
    }, [props])


    const handleDragStart = useCallback(
        start => {

            setState({
                ...state,
                homeIndex: 0
            });
        },
        [state]
    );

    const handleDragUpdate = useCallback(
        update => {
            // const opacity = update.destination
            //     ? update.destination.index / Object.keys(state.userIds).length
            //     : 0;
        },
        [state]
    );

    const handleDragEnd = useCallback(
        result => {

            if (!result.destination) {
                return;
            }

            const newUserIds = Array.from(state.userIds);
            newUserIds.splice(result.source.index, 1);
            newUserIds.splice(result.destination.index, 0, result.draggableId);

            const newColumn = {
                userIds: newUserIds
            };

            setState(newColumn);
        },
        [state]
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        const { activeTab } = props;
        props.handleClose(activeTab, state.userIds);
        // let clientID = dealersettings.client.id
        // let _department = activeTab;
        // let modifiedBy = dealersettings ? dealersettings.id : '';
        // let modifiedDate = window.firebase.firestore.Timestamp.now();
        // props.handleClose();
        // const updateRef = window.firebase.firestore().doc(`clients/${clientID}/queues/${_department}`)
        // updateRef.update({
        //     'users': state.userIds,
        //     modifiedBy,
        //     modifiedDate
        // }).then((docRef) => {
        //     toast.notify('Queue re-arranged successfully', {
        //         duration: 2000
        //     })
        //     //props.handleClose()
        // }).catch((error) => {
        //     console.error("Error updating contact_details: ", error);
        // });
    }

    const bindActiveUser = (userid, index) => {
        const { clientUsers } = props;
        var user = clientUsers.filter(item => item.id === userid).length > 0 ? clientUsers.filter(item => item.id === userid)[0] : null;
        if (user) {
            return (
                <>
                    <div className="queue-drag">
                        <i className="ico icon-drag"></i>
                    </div>
                    <div className={`frontdesk-rearrange-avatar ${index === 0 ? 'active' : ''}`}>
                        <div className="frontdesk-rearrange-count"> <span className="badge badge-pill badge-frontdesk-queue ml-1">{index + 1}</span> </div>
                        <img src={CommonHelper.showUserAvatar(user.profileImage, user.name)} alt="" className="img-object-fit"/> </div>
                    <div className="frontdesk-rearrange-detail">
                        <h3>{user.name}</h3>
                        <h5>
                            {user.role}
                            {'  |  '}
                            {user.departmentName ? user.departmentName : user.department}
                        </h5>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <Modal
                id="rearrange-queue"
                show={show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-rearrange-queue">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="rearrange-queue-wrap" >

                                <DragDropContext
                                    onDragStart={handleDragStart}
                                    onDragUpdate={handleDragUpdate}
                                    onDragEnd={handleDragEnd}
                                >
                                    {
                                        <Droppable droppableId={'User'} >
                                            {(provided, snapshot) => (
                                                <div style={divStyle} className={(snapshot.isDraggingOver ? "pipeline-dragging-Over" : "")}
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {
                                                        (state && state.userIds && state.userIds.length) > 0
                                                            ?
                                                            <>
                                                                {state.userIds.map((userID, index) =>

                                                                    <Draggable
                                                                        draggableId={userID}
                                                                        index={index}
                                                                        key={userID}
                                                                    >
                                                                        {(provided1, snapshot1) => (
                                                                            <>
                                                                                <div

                                                                                    ref={provided1.innerRef}
                                                                                    {...provided1.draggableProps}
                                                                                    {...provided1.dragHandleProps}
                                                                                    className={`frontdesk-rearrange-box frontdesk-flex-queue ${snapshot1.isDraggingOver ? "pipeline-item-dragging-Over" : ""}`}
                                                                                    id={`enquiry-${userID}`}
                                                                                >
                                                                                    {
                                                                                        bindActiveUser(userID, index)
                                                                                    }

                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Draggable>
                                                                )}
                                                            </>
                                                            :
                                                            <>
                                                                <div className="spinner-loader h-100 p-5">
                                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                        <div className="no-cafe-img">
                                                                            <i className="ico icon-Contacts"></i> </div>
                                                                        <div className="no-data-txt mt-2"> <Translate text={'noUserFound'} /></div>
                                                                    </div>

                                                                </div>
                                                            </>
                                                    }
                                                    <span
                                                        style={{
                                                            display: "none"
                                                        }}
                                                    >
                                                        {provided.placeholder}
                                                    </span>
                                                </div>
                                            )}
                                        </Droppable>
                                    }
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); props.handleClose() }}><Translate text={'cancel'} /></button>
                    <button type="button"
                        className={`btn btn-primary float-right`}
                        onClick={(e) => handleSubmit(e)}><Translate text={'done'} /></button>

                </Modal.Footer>
            </Modal>

        </>
    );
}



export default ReArrangeQueue;