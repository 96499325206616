export const objTestDrive = {
    documentID: null,
    enquiryID: null,
    enquiry: {}, // enquiry model - do save in DB
    oemID: null,
    clientID: null,
    projectId: null,
    contactID: null,
    contact: {}, // contact model - do save in DB
    stockID: null,
    stock: {}, // stock model - do save in DB
    appointmentID: null,
    startDate: null,
    startOdometer: 0,
    endDate: null,
    endOdometer: 0,
    beforeInspection: [],
    afterSignatureURL: '',
    afterInspection: [],
    isVehicleHandOver: false,
    expectedReturn: null,
    distance: 0,
    duration: 0,
    rating: 0,
    speed: 0,
    maxSpeed: 0,
    isLike: false,
    isDamage: false,
    secondaryLicenseFrontURL: '',
    secondaryLicenseBackURL: '',
    secondarySignatureURL: '',
    mapCoordinatesPath: '',
    polylineCoordinates: [],
    testdriveFeedback: [],
    insurance: {},
    terms: {},
    tags: [],
    //keywords: [],
    notes: '',
    status: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};

export const objSecondaryDriver = {
    secondaryLicenseFrontURL: '',
    secondaryLicenseBackURL: '',
    secondarySignatureURL: ''
};

export const objInspection = {
    dx: 0,
    dy: 0,
    markType: '',
    notes: '',
    price: 0,
    currency: '',
    images: []
};

export const objAgreement = {
    signature: '',
    termsAndConditions: ''
};

export const objPolylineCoordinates = {
    geohash: '',
    geopoint: []
};

export const objTerms = {};

// export const mandatoryFields = [
//     'stockID',
//     'startDate',
//     'startOdometer',
//     'endDate',
//     'endOdometer',
//     'afterSignatureURL',
//     'expectedReturn',
//     'insurance',
//     'terms'
// ];

//#region FLEET HISTORY => ON-TESTDRIVE STOCKS

export const objTestDriveHistoryVM = {
    documentID: null,
    clientID: null,
    projectId: null,
    stockID: null,
    stock: {},
    contact: null,
    startDate: null,
    startOdometer: 0,
    endDate: null,
    endOdometer: 0,
    expectedReturn: null,

    status: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false,
    //CONTACT INFO
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    licenseNo: '',
    licenseExpiry: null,
    contactType: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    nationality: '',
    address: '',
    companyName: '',
    designation: '',
    contactMethod: '',
    licenseType: '',
    licenseState: '',
    secondaryContactName: '',
    secondaryContactPhone: '',
    secondaryContactEmail: '',
    contactCreatedBy: '',
    contactModifiedby: '',
    contactCreatedOn: null,
    contactModifiedOn: null,
    //STOCK INFO
    stockNo: '',
    make: '',
    model: '',
    modelDescription: '',
    saleType: '',
    type: '',
    year: '',
    regNo: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    miles: '',
    price: null,
    purchasePrice: null,
    location: '',
    regDate: null,
    warrantyExp: null,
    stockInDate: null,
    stockStatus: '',

    createdOn: null,
    UpdatedOn: null,
};


export const allTestDriveHistoryFieldsVM = [
    {
        name: 'Stock #',
        value: 'stockNo',
        flex: 1,
        group: 'Stock',
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
        sortValue: 'stock."stockNo"'

    },
    {
        name: 'Make',
        value: 'make',
        flex: 1,
        group: 'Stock',
        default: true,
        sortValue: 'testdrive."make"'
    },
    {
        name: 'Model',
        value: 'model',
        flex: 1,
        group: 'Stock',
        default: true,
        sortValue: 'testdrive."model"'
    },
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        group: 'Contact',
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'contactdealerName',
        nestedTextTitle: 'Contact Dealer Name',
        sortValue: 'contact."firstName"'
    },
    {
        name: 'Start Date',
        value: 'startDate',
        flex: 1,
        group: 'Test Drive',
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Added By',
        sortValue: 'testdrive."startDate"'
    },
    {
        name: 'Expected Return',
        value: 'expectedReturn',
        flex: 1,
        group: 'Test Drive',
        default: true,
        sortValue: 'testdrive."expectedReturn"'
    },
    {
        name: 'End Date',
        value: 'endDate',
        flex: 1,
        group: 'Test Drive',
        default: true,
        subText: 'modifiedBy',
        subTextTitle: 'Updated By',
        sortValue: 'testdrive."endDate"'
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        group: 'Test Drive',
        default: true,
        elementName: 'tdstatusName',
        sortValue: 'testdrive."status"'
    },
    {
        name: 'Stock Status',
        value: 'stockStatus',
        flex: 1,
        group: 'Stock',
        default: true,
        elementName: 'stockStatusName',
        subText: 'statusUpdatedBy',
        subTextTitle: 'Stock Status Updated By',
        sortValue: 'stock."status"'
    },
    {
        name: 'Notes',
        value: 'notes',
        group: 'Test Drive',
        flex: 1
    },
    {
        name: 'Start Odometer',
        value: 'startOdometer',
        group: 'Test Drive',
        flex: 1
    },
    {
        name: 'End Odometer',
        value: 'endOdometer',
        group: 'Test Drive',
        flex: 1
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        group: 'Test Drive',
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        group: 'Test Drive',
        subText: 'modifiedBy',
        subTextTitle: 'Updated By'
    },
    {
        name: 'Year',
        value: 'year',
        group: 'Stock',
        flex: 1,
        sortValue: 'stock."year"'
    },
    {
        name: 'Sale Type',
        value: 'saleType',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Reg #',
        value: 'regNo',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'VIN #',
        value: 'vinNo',
        group: 'Stock',
        flex: 1
    },

    {
        name: 'Body Type',
        value: 'type',
        group: 'Stock',
        flex: 1
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        group: 'Contact',
        flex: 1,
        sortValue: 'contact."email"'
    },
    {
        name: 'Contact Phone',
        value: 'contactPhone',
        group: 'Contact',
        flex: 1,
        elementName: 'phone',
        sortValue: 'contact."phone"'
    },
    {
        name: 'Digital Driving License',
        value: 'isDLScan',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License No',
        value: 'licenseNo',
        group: 'Contact',
        flex: 1,
        default: false,
        sortValue: 'contact."licenseNo"'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        group: 'Contact',
        flex: 1,
        default: false,
        sortValue: 'contact."licenseExpiry"'
    },
    {
        name: 'License Type',
        value: 'licenseType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License State',
        value: 'licenseState',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Birthday',
        value: 'dob',
        group: 'Contact',
        flex: 1,
        default: false,
        sortValue: 'contact."dob"'
    },
    {
        name: 'Gender',
        value: 'gender',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Language',
        value: 'language',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Nationality',
        value: 'nationality',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Address',
        value: 'address',
        group: 'Contact',
        flex: 2,
        default: false
    },
    {
        name: 'Company Name',
        value: 'companyName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Designation',
        value: 'designation',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Name',
        value: 'secondaryContactName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Phone',
        value: 'secondaryContactPhone',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Email',
        value: 'secondaryContactEmail',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Created On',
        value: 'contactCreatedOn',
        subText: 'contactCreatedBy',
        group: 'Contact',
        flex: 1,
        default: false,
        subTextTitle: 'Contact Created By',
        sortValue: 'contact."addedDate"'
    },
    {
        name: 'Contact Updated On',
        value: 'contactModifiedOn',
        subText: 'contactModifiedby',
        group: 'Contact',
        flex: 1,
        default: false,
        subTextTitle: 'Contact Updated By',
        sortValue: 'contact."modifiedDate"'
    },
    {
        name: 'Enquiry Number',
        value: 'enqNumber',
        flex: 1,
        group: 'Enquiry',
        sortValue: 'enq."displayID"'
    },
    {
        name: 'Enquiry Status',
        value: 'enqStatus',
        flex: 1,
        group: 'Enquiry',
        elementName: 'enqStatusName',
        sortValue: 'status.name'
    },
    {
        name: 'Requirement Model',
        value: 'reqModel',
        flex: 1,
        group: 'Enquiry',
        subText: 'reqSaletype',
        subTextTitle: 'Requirement Sale Type',
        sortValue: 'vehicleModel'
    },
    {
        name: 'Enquiry Owner',
        value: 'enqOwner',
        flex: 1,
        group: 'Enquiry',
        sortValue: 'owner.name'
    },
    {
        name: 'Enquiry Type',
        value: 'enquiryType',
        flex: 1,
        group: 'Enquiry',
        sortValue: 'enquiryType.name'
    },
    {
        name: 'Origin',
        value: 'origin',
        flex: 1,
        group: 'Enquiry',
        sortValue: 'origin.name'
    },
    {
        name: 'Campaign',
        value: 'campaign',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'campaign.name'
    },
    {
        name: 'Label',
        value: 'label',
        group: 'Enquiry',
        flex: 1,
        elementName: 'labelName',
        sortValue: 'label.name'
    },
    {
        name: 'Pipeline',
        value: 'pipeline',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'pipeline.name'
    },
    {
        name: 'Stage',
        value: 'stage',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'stage.name'
    },
    {
        name: 'Purchase Intention',
        value: 'purchaseIntention',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Lost Reason',
        value: 'lostReason',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'lostReason.name'
    },
    {
        name: 'Lost Sub Reason',
        value: 'lostSubReason',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'lostSubReason.name'
    },
    {
        name: 'Gross Amount',
        value: 'grossAmount',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Deposit Amount',
        value: 'depositAmount',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Deposit Recipt Number',
        value: 'depositReciptNumber',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Lead Source',
        value: 'leadSource',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'leadSource.name'
    },
    {
        name: 'Contract Number',
        value: 'contractNumber',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const inboundTestDriveFields = [
    {
        name: 'Received On',
        value: 'createdOn',
        flex: 1,
        default: true,
    },
    {
        name: 'Name',
        value: 'contactName',
        flex: 1,
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Phone',
        value: 'displayPhone',
        flex: 1,
        default: true,
    },
    {
        name: 'Vehicle',
        value: 'vehicleModel',
        flex: 1,
        default: true

    },
    {
        name: 'Start Date',
        value: 'startDate',
        flex: 1,
        default: true,
    },
    {
        name: 'End Date',
        value: 'endDate',
        flex: 1,
        default: true,
    },
    {
        name: 'Notes',
        value: 'notes',
        default: true,
        flex: 2,
        width: 350,
        limit: 100
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 100,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

//#endregion
