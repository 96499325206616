import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactSelect } from '../../components';
import Translate from '../../constants/translate';

const ActivityStatus = ({ title, show, handleClose, isMandatory, activtyOutcomes, data, isDone }) => {
  const [state, setState] = useState({
    error: false,
    activityOutcome: ''
  })

  const handleSelectChange = (e) => {
    if (e) {
      setState({ ...state, activityOutcome: e.value, error: false })
    } else if (isMandatory) {
      setState({ ...state, activityOutcome: '', error: true })
    }
  }

  const handleSubmit = () => {
    //console.log('handleSubmit')
    if (!isMandatory && !state.activityOutcome) {
      handleClose(isMandatory, null, data, isDone)
    } else if (!isMandatory && state.activityOutcome) {
      handleClose(isMandatory, state.activityOutcome, data, isDone)
    } else if (isMandatory && state.activityOutcome) {
      handleClose(isMandatory, state.activityOutcome, data, isDone)
    } else if (isMandatory && !state.activityOutcome) {
      setState({...state, error: true})
    }
  }

  const handleHide = () => {
    //console.log('handleHide')
    handleClose(isMandatory, null, data, isDone)
  }

	return (
		<Modal
			id='select-act-status'
			show={show}
			onHide={handleHide}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-centered modal-update-password'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title'>
						<Translate text={title} />
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
      <div className="container-fluid">
        <div className="row">
          <div className="form-style w-100">
            <div className="form-row ">
              <div className="form-group col-md-12 mt-3">
                <ReactSelect
                    options={activtyOutcomes}
                    name={"activityOutcome"}
                    placeholder={'Select outcome'}
                    onChange={handleSelectChange}
                    value={state.activityOutcome ? state.activityOutcome : ''}
                    classNamePrefix={`${state.error ? 'input_error' : ''} basic-select`}
                >
                </ReactSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
        <button
          type='button'
          className='btn btn-primary float-right ml-2'
          aria-label='Close'
          onClick={handleSubmit}
        >
          {' '}
          <Translate text={'Save'} />
        </button>
				<button
					type='button'
          className='btn btn-default float-right'
          onClick={() => handleHide()}
				>
					<Translate text={'cancel'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ActivityStatus;
