/** LIBRARIES */
import React from 'react';
import { Spinner } from 'react-bootstrap'

function WidgetOverlay(props) {

    return (
        <div className="dashboard-widget-loader h-100">
            <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>

        </div>
    )
}

export default WidgetOverlay