
export const creditScoreOptions = [
	{ active: true, value: 'poor', label: 'Poor' },
	{ active: true, value: 'good', label: 'Good' },
	{ active: true, value: 'great', label: 'Great' },
	{ active: true, value: 'excellent', label: 'Excellent' },
];

export const termOptions = [
	{ active: true, value: '1', label: '1 year' },
	{ active: true, value: '2', label: '2 years' },
	{ active: true, value: '3', label: '3 years' },
	{ active: true, value: '4', label: '4 years' },
	{ active: true, value: '5', label: '5 years' },
	{ active: true, value: '6', label: '6 years' },
	{ active: true, value: '7', label: '7 years' },
	// { active: true, value: '8', label: '8 years' },
];

export const drivaDoc = {
  request: null,
  response: null,
  status: 'pending',
  type: 'driva',
  enquiryID: null,
  clientID: null,
  documentID: null,
  addedDate: null,
  addedBy: null,
  modifiedDate: null,
  modifiedBy: null,
	modifiedFrom: 'web'
}


// export const emailTemplate = `<div id="summer-editor">
// <div>

// <table style="width:100%; padding:0px; background:#f6f6f6; margin:0px auto 0px auto;" cellSpacing="0" cellPadding="0">
//   <tbody><tr>
//     <td><center>
//         <table width="600" style="border:1px solid #ccc; width:600px; font-family:Arial, sans-serif, &quot;Helvetica Neue&quot;, Helvetica;  font-size:13px; color:#757575; background:#ffffff; margin:20px auto;" cellSpacing="0" cellPadding="0">
//           <tbody><tr>
//             <td><table width="600" border="0" cellSpacing="0" cellPadding="0" style="font-family:Arial, sans-serif, &quot;Helvetica Neue&quot;, Helvetica;  font-size:13px; color:#ccc; border-bottom: 1px solid #ccc; padding:15px 25px; background:#fff; background-color:#fff;">
//                 <tbody><tr>
//                   <td style="text-align:left;" width="600"><img height="51" src="{client.logoURL}"></td>
//                 </tr>
//               </tbody></table></td>
//           </tr>
//           <tr>
//             <td><table cellSpacing="0" cellPadding="0" style="width:100%;padding:25px 25px 25px 25px">
//                 <tbody><tr>
//                   <td style="font-family:Arial, sans-serif, &quot;Helvetica Neue&quot;, Helvetica;"><table width="550" style="width:550px; margin:0 auto; padding:10px 0px 20px 0px; font-family:Arial, sans-serif, &quot;Helvetica Neue&quot;, Helvetica;  font-size:13px; color:#757575;">
//                       <tbody><tr>
//                         <td style="padding:10px 0px 15px 0px;">Dear @FIRST_NAME @LAST_NAME</td>
//                       </tr>
//                       <tr>
//                         <td style="line-height:18px;"> To continue with your finance application request for the <b style="color: #000000;">@VEHICLE_NAME</b>&nbsp;and the request <b style="color: #000000;">@VEHICLE_AMOUNT</b>&nbsp;for finance, please click on the below link to continue. <br>
//                           <br>
//                           <a style="padding: 10px;width:210px;display: block;text-decoration: none;border:0;text-align: center;font-weight: bold;font-size: 15px;font-family: sans-serif;color: #ffffff; background: #4466f2; border: 1px solid #4466f2; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; line-height:17px;" target="_blank" href="{shortURL}">Click Here</a><br>
//                           <br>
//                           Please feel free to contact incase any assistance required during the process. </td>
//                       </tr>
//                     </tbody></table></td>
//                 </tr>
//                 <tr>
//                   <td style="font-family:Arial, sans-serif, &quot;Helvetica Neue&quot;, Helvetica;  font-size:13px; color:#757575;"><br>
//                     <br>
//                     Thank you<br>
//                     <br>
//                     Person name(sp or s manager)<br>
//                     Dealer name<br>
//                     Dealer phone <br>
//                     Dealer website</td>
//                 </tr>
//               </tbody></table></td>
//           </tr>
//           <tr>
//             <td><table width="600" style="text-align:center; width:600px; font-size:11px; background:#f4f4f4; color:#666666; padding:5px 25px;  font-family:Arial, sans-serif, &quot;Helvetica Neue&quot;, Helvetica; ">
//                 <tbody><tr>
//                   <td width="600">Powered by <a style="color:#000; text-decoration:none;" href="https://www.fusionamspro.com/" target="_blank">Fusion SD.</a></td>
//                 </tr>
//               </tbody></table></td>
//           </tr>
//         </tbody></table>
//       </center></td>
//   </tr>
// </tbody></table>

// </div>
// </div>`;

// export const smsTemplateextra = `Testing url sending of driva finance quotes page \n {obj.productURL}`
// export const smsTemplateOld = `To continue with your finance application request for the (vehicle name) and the request (amount) for finance, please click on the below link to continue. 

// (link)

// Please feel free to contact incase any assistance required during the process. 

// Thank you 
// Person name(sp or s manager)
// Dealer name
// Dealer phone 
// Dealer website`

// export const smsTemplate = `Dear (customer name), 

// To continue with your finance application request for the finance amount of (amount), for the (vehicle name), please click on the below link to continue. 

// (link)

// Please contact us if any assistance required during the process. 

// Thank you 
// Person name(sp or s manager)
// Dealer name
// Dealer phone 
// Dealer website`