/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Tabs, Tab } from "react-bootstrap";
import _ from 'lodash';
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { InputTextArea, InputText, InputRadio, PopUpModal, ImageCropHook } from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import MentionEditor from '../../../components/mentionEditor/mentionEditor';

import { objRecommendationSubDetail, objRecommendation } from '../viewModel'
import { recommendationOption, serviceStatus } from '../../../services/enum';
import { firestoreDB, storageBucket } from '../../../services/helper';
export default class AddRecommendation extends Component {
    constructor(props) {
        super(props);
        this.newDocID = null;
        this.isLoading = false;
        this.mandatoryFields = !_.isEmpty(this.props.mandatoryFields) ? this.props.mandatoryFields : ['name'];
        this.state = {
            fields: Object.assign({}, objRecommendationSubDetail),
            errors: {},
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            imageURL: null,
            imageURLs: null,
        }
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, dealersettings, docID, serviceID, recomm } = props;
        const fields = Object.assign({}, objRecommendationSubDetail);
        if (show === true && docID && serviceID && recomm) {
            this.newDocID = docID;
            const objData = Object.assign({}, recomm);
            if (objData && objData.detail) {
                for (let [key, value] of Object.entries(objData.detail)) {
                    if (fields.hasOwnProperty(key))
                        fields[key] = value;
                }
            }
            fields.name = objData.name ? objData.name : '';
            this.setState({ fields: fields });
        }
        else if (show === true && (!docID) && serviceID) {
            this.newDocID = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc().id;
            fields.option = recommendationOption.customer;
            fields.status = 'urgent';
            //fields.oKToDo = true;
            this.setState({ fields: fields });
        }
    }

    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, ext) => {

        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        if (!this.newDocID) {
            this.newDocID = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc().id;
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/recommendations/${this.newDocID}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/recommendations/${this.newDocID}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        //console.log('saveImageToStorage-2', uid);
                        let state = Object.assign({}, this.state.fields);
                        let _data = Object.assign([], state[id]);
                        _data.push(dataURL);
                        state[id] = Object.assign([], _data);
                        this.setState({ fields: state, imageURL: null })
                        if (document.getElementById(id))
                            document.getElementById(id).value = "";
                    })
            })
    };

    saveImagesToStorage = async (filez, id) => {
        let promises = [];
        this.setState({ imageURLs: Object.assign([], filez) });

        let files = [];
        for (let [key, file] of Object.entries(filez)) {
            if (file.name.toLowerCase().includes('.heic')) {
                let _file = await CommonHelper.heic2Image(file);
                files.push(_file)
            } else
                files.push(file);
        }

        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        if (!this.newDocID) {
            this.newDocID = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc().id;
        }
        files.forEach((file, index) => {
            let uid = moment()._d.getTime().toString() + index + '.' + file.name.substr((file.name.lastIndexOf('.') + 1));
            let storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/recommendations/${this.newDocID}/${uid}`);
            promises.push(
                storageRef.put(file).then((snapshot) => {
                    return snapshot.ref.getDownloadURL()
                })
            );
        });
        const snapshots = await Promise.all(promises)
        let fileURLS = [];
        snapshots.forEach(url => {
            fileURLS.push(url);
        })

        if (!_.isEmpty(fileURLS)) {
            let state = Object.assign({}, this.state.fields);
            state[id] = fileURLS
            this.setState({ fields: state, imageURLs: null })
            if (document.getElementById(id))
                document.getElementById(id).value = "";
        }
    }


    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.fields);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                state.images = Object.assign([], _data);
                this.setState({ fields: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }


    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            imageURL: null,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;

        this.saveImageToStorage(src, id, ext);

        this.setState({
            imageURL: src,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = async (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 1) {
            const files = e.target.files;
            for (let [key, file] of Object.entries(files)) {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Maximum size of each file is 10MB'), '', 'info')
                    return;
                }
            }

            this.saveImagesToStorage(files, id)

        } else if (e.target.files && e.target.files.length === 1) {

            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            if (file.name.toLowerCase().includes('.heic')) {
                this.setState({ imageURL: _images.novehicle });
                file = await CommonHelper.heic2Image(file);
            }

            reader.onloadend = () => {
                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }
    //#endregion

    //#region CHANGE EVENT

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''

        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleTypeChange = (e) => {
        const { name, value } = e.target;

        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value;
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (key) {
                if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
                    errors[key] = errorClass;
                else
                    errors[key] = '';
            }
            else {
                errors = {};
                for (let [key, value] of Object.entries(fields)) {
                    if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                        if (!fields[key]) {
                            errors[key] = errorClass;
                        }
                    }
                }
            }
            this.setState({ errors: errors });
        }
    }

    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }

        if (_.isEmpty(fields.name)) {
            formIsValid = false;
            errors.name = errorClass;
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.addRecommendation();
        }
        else {
            console.log('addRecomm-errors', errors)
            return formIsValid;
        }


    };

    addRecommendation = () => {
        this.isLoading = true;
        const { serviceID, docID, recomm, isOwnerResponsed, dealersettings } = this.props;
        const fields = Object.assign({}, this.state.fields);
        const objData = Object.assign({}, recomm);

        const _clientService = (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            !_.isEmpty(dealersettings.client.clientSettings.service)) ? dealersettings.client.clientSettings.service : null;
        const labourCost = (!_.isEmpty(_clientService) && _clientService.labourCost) ? _clientService.labourCost : 0;

        //Remove empty value from object
        for (var propName in fields) {
            if (fields[propName] === null || fields[propName] === undefined || fields[propName] === '' || fields[propName] === [] || (_.isObject(fields[propName]) && _.isEmpty(fields[propName]))) {
                delete fields[propName];
            }
        }

        if (!docID) {
            objData.serviceID = serviceID;
            objData.choice = 'Required';
            objData.type = 'manual';
            objData.category = 'manual';
            fields.labourCost = labourCost ? labourCost : 0;
            fields.oKToDo = true;

            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';

            objData.clientID = dealersettings?.client?.id ? dealersettings?.client?.id : null;
            objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
        }

        if (objData.type === 'manual' && fields['name'])
            objData.name = fields['name'];

        delete fields['name'];
        objData.detail = fields;

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web'

        if (!objData.projectId) objData.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!this.newDocID) {
            this.newDocID = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        if (this.props.isDontSave === true) {
            this.props.handleClose(objData);
        }
        else {
            const updateRef = firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/recommendations/${objData.documentID}`);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                //console.log(docRef);
                this.isLoading = false;
                toast.notify('Recommendation saved successfully', {
                    duration: 2000
                })

                if (this.props.handleStatusReScheduled)
                    this.props.handleStatusReScheduled();

                this.handleModalClose(objData);
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }

    };

    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}, objRecommendationSubDetail) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };

    render() {
        const { images, option, faultReports, oilMiscSub, status, name } = this.state.fields;
        const { imageURL, imageURLs } = this.state
        const { show, serviceVideos, dealersettings, docID, recomm } = this.props;
        const _serviceVideos = serviceVideos.filter(item => item.recordID === this.newDocID)
        return (
            <>
                <Modal
                    id="modal-add-service-recommendation"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-add-service-recommendation`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={this.props.title ? this.props.title : 'Add Recommendation'} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style service-section-recommendation-popup">
                                    <div className="recom-popup-wrap">

                                        <div className='tabs recom-popup-tabs'>
                                            <div className='tabs-wraper'>
                                                <nav className="pl-2 pr-2">
                                                    <Tabs
                                                        defaultActiveKey='imagesRecomm'
                                                        className='nav-fill'
                                                        mountOnEnter={true}
                                                    >
                                                        <Tab eventKey='imagesRecomm' title='Images'>
                                                            <div className="form-group col-md-12">

                                                                <div className={`recom-popup-image-wraper`}>
                                                                    {/* {
        isComplete
            ?
            <></>
            :*/}
                                                                    <div className={`recom-popup-item-image upload ${this.state.errors["images"] ? 'border-error' : ''}`}>
                                                                        <label htmlFor="recommendation_images">
                                                                            <img src={_images.addvehicle} alt="" htmlFor="recommendation_images" />
                                                                            <input className="fileInput"
                                                                                type="file"
                                                                                name="recommendation_images"
                                                                                id="recommendation_images"
                                                                                accept="image/*"
                                                                                style={{ display: 'none' }}
                                                                                multiple={true}
                                                                                onChange={(e) => this.onSelectFile(e, 'recommendation', 'images', 'Recommendation Image')} />
                                                                        </label>
                                                                    </div>
                                                                    {/* } */}
                                                                    {
                                                                        this.state.fields.images
                                                                            ?
                                                                            <>
                                                                                {
                                                                                    this.state.fields.images.map((_img, index) => (
                                                                                        <div className="recom-popup-item-image" key={index}>
                                                                                            {/* {isComplete ? <></> : */}
                                                                                            <>
                                                                                                <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.handleImageDel(index);
                                                                                                }}>
                                                                                                    <i className="ico icon-delete" ></i>
                                                                                                </div>
                                                                                            </>
                                                                                            {/* } */}

                                                                                            <a data-fancybox={`recom-popup_img`} href={_img} onClick={(e) => { e.preventDefault(); }}>
                                                                                                <img src={_img} width="70" height="70" alt="" className="img-object-fit" />
                                                                                            </a>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                            </>
                                                                    }
                                                                    {
                                                                        (imageURL) ?
                                                                            (
                                                                                <div className="recom-popup-item-image">
                                                                                    <div className="img-loader">
                                                                                        <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                            <div className="spinner-border spinner-border-sm" role="status">
                                                                                                <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                        <img src={imageURL} width="70" height="70" alt="" />
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        (imageURLs) ?
                                                                            <>
                                                                                {
                                                                                    imageURLs.map((_img, index) => (
                                                                                        <div key={index} className="recom-popup-item-image">
                                                                                            <div className="img-loader">
                                                                                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                                                                        <span className="sr-only"><Translate text={'Loading...'} /></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                <img src={_images[CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'novehicle')]} width="70" height="70" alt="" />
                                                                                            </a>
                                                                                        </div>
                                                                                    ))
                                                                                }


                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }

                                                                </div>

                                                            </div>


                                                        </Tab>
                                                        <Tab eventKey='videosRecomm' title='Videos'>

                                                            <div className="form-group col-md-12">

                                                                <div className={`recom-popup-image-wraper`}>
                                                                    {/* {
isComplete
?
<></>
:*/}
                                                                    <div className={`recom-popup-item-image upload cursor-pointer ${this.state.errors["videos"] ? 'border-error' : ''}`}
                                                                        onClick={(e) => { e.preventDefault(); this.props.handleVideoOpen(this.newDocID); }}>
                                                                        <img src={_images.addVideo} alt="" />
                                                                    </div>
                                                                    {/* } */}
                                                                    {
                                                                        !_.isEmpty(_serviceVideos)
                                                                            ?
                                                                            <>
                                                                                {
                                                                                    _.orderBy(_serviceVideos, ['addedDate'], ['desc']).map((data, index) => {
                                                                                        return <div className="recom-popup-item-image" key={index}>
                                                                                            {/* {_isCompleted ? <></> : */}
                                                                                            <>
                                                                                                <div className="image-delete img-delete-button" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.props.deleteVideo(data.documentID);
                                                                                                }}>
                                                                                                    <i className="ico icon-delete" ></i>
                                                                                                </div>
                                                                                            </>
                                                                                            {/* } */}

                                                                                            <a data-fancybox={`recom-popup_videos`} href={data.url} onClick={(e) => { e.preventDefault(); }}>
                                                                                                <img src={_images.previewVideo} width="70" height="70" alt="" className="img-object-fit" />
                                                                                            </a>
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </>
                                                                            :
                                                                            <></>

                                                                    }



                                                                </div>

                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </nav>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="recom-popup-middle-wrap">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={'title'} /> </label>
                                                <InputText
                                                    name="name"
                                                    value={name}
                                                    onChange={this.handleOnChange}
                                                    autoComplete="off"
                                                    placeholder={'title'}
                                                    className={`form-control ${this.state.errors["name"]}`}
                                                    disabled={((recomm && recomm.type === 'manual') || !docID) ? false : true}
                                                />
                                            </div>

                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={'option'} /> </label>
                                                <div className="recom-option-buttons">

                                                    <div className={`btn-group btn-group-toggle ${this.state.errors['option'] ? 'input_line_error' : ''}`}>
                                                        <label className={`btn btn-recom-lightblue ${option === recommendationOption.customer ? 'active' : ''}`}>
                                                            <InputRadio
                                                                type="radio"
                                                                value="customer"
                                                                name={'option'}
                                                                checked={option === recommendationOption.customer ? true : false}
                                                                onChange={this.handleTypeChange}
                                                            />
                                                            <Translate text={'customer'} upperCase={true} />
                                                        </label>
                                                        <label className={`btn btn-recom-lightblue ${option === recommendationOption.warranty ? 'active' : ''}`}>
                                                            <InputRadio
                                                                type="radio"
                                                                value="warranty"
                                                                name={'option'}
                                                                checked={option === recommendationOption.warranty ? true : false}
                                                                onChange={this.handleTypeChange}
                                                            />
                                                            <Translate text={'warranty'} upperCase={true} />
                                                        </label>
                                                        <label className={`btn btn-recom-lightblue ${option === recommendationOption.internal ? 'active' : ''}`}>
                                                            <InputRadio
                                                                type="radio"
                                                                value="internal"
                                                                name={'option'}
                                                                checked={option === recommendationOption.internal ? true : false}
                                                                onChange={this.handleTypeChange}
                                                            />
                                                            <Translate text={'internal'} upperCase={true} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={'status'} /> </label>

                                                <div className="recom-status-buttons">

                                                    <div className={`btn-group btn-group-toggle ${this.state.errors['status'] ? 'input_line_error' : ''}`}>

                                                        <label className={`btn btn-recom-urgent ${status === 'urgent' ? 'active' : ''}`}>
                                                            <InputRadio
                                                                type="radio"
                                                                value="urgent"
                                                                name={'status'}
                                                                checked={status === 'urgent' ? true : false}
                                                                onChange={this.handleTypeChange}
                                                            />
                                                            <Translate text={'urgent'} upperCase={true} />
                                                        </label>
                                                        <label className={`btn btn-recom-advisable ${status === 'advisable' ? 'active' : ''}`}>
                                                            <InputRadio
                                                                type="radio"
                                                                value="advisable"
                                                                name={'status'}
                                                                checked={status === 'advisable' ? true : false}
                                                                onChange={this.handleTypeChange}
                                                            />
                                                            <Translate text={'advisable'} upperCase={true} />
                                                        </label>
                                                        <label className={`btn btn-recom-report ${status === 'report' ? 'active' : ''}`}>
                                                            <InputRadio
                                                                type="radio"
                                                                value="report"
                                                                name={'status'}
                                                                checked={status === 'report' ? true : false}
                                                                onChange={this.handleTypeChange}
                                                            />
                                                            <Translate text={'report'} upperCase={true} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>




                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={'faultReports'} /></label>
                                                <InputTextArea
                                                    className={`form-control notes ${this.state.errors["faultReports"]}`}
                                                    name="faultReports"
                                                    onChange={this.handleOnAreaChange}
                                                    value={faultReports}
                                                    maxLength="600"
                                                    rows="3">
                                                </InputTextArea>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={'oilMiscSub'} /> </label>
                                                <InputText
                                                    name="oilMiscSub"
                                                    value={oilMiscSub}
                                                    onChange={this.handleOnChange}
                                                    autoComplete="off"
                                                    placeholder={'Oils / Misc. Sub'}
                                                    className={`form-control ${this.state.errors["oilMiscSub"]}`}
                                                />
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right ${(imageURL || imageURLs) ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'save'} />
                        </button>

                    </Modal.Footer>
                </Modal>

                <PopUpModal show={this.state.cropShow.show}>
                    <ImageCropHook
                        cropShow={this.state.cropShow}
                        handleClose={this.handleCropClose}
                        handleSelect={this.handleCropSelect}
                    ></ImageCropHook>
                </PopUpModal>


            </>
        );
    }
}
