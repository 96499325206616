export const optionsVM = {
    item: '',
    itemNo: '',
    amount: '',
}

export const genuineServicesVM = {
    item: '',
    itemNo: '',
    quantity: '',
    rate: '',
    discountType: 'percentage',
    discount: '',
    amount: '',
}

export const lessDepositVM = {
    date: '',
    reference: '',
    amount: '',
}

export const allQuoteFields = [
    {
        name: 'Quote No',
        value: 'displayID',
        flex: 1,
        default: true,
        elementName: 'csvQuoteNo'
    },
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        default: true
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        subText: 'vehicleSaletype',
        subTextTitle: 'Sale Type',
        default: true
    },
    {
        name: 'Quote Amount',
        value: 'quoteAmount',
        flex: 1,
        default: true,
        elementName: 'csvQuoteAmount'
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        subText: 'addedBy',
        subTextTitle: 'Created By',
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Updated By',
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        flex: 0,
        fixedRight: true
    }
]